import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';

import { withStyles } from '@material-ui/core/styles';

import AppBar from '../components/app-bar';
import Continue from '../components/continue';

import { WaterDrop } from '../lib/icons';

import Page from './page';

import { updatePRO } from '../state/pro-forms';

const styles = {
  button: {
    margin: 5,
    width: 150,
    height: 40,
    fontSize: 16,
  },
  buttonGroup: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  formControl: {
    textAlign: 'center',
  },
  formControlItem: {
    fontSize: '22px',
    textAlign: 'center',
    margin: '1rem',
  },
  iconContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    flexGrow: 0,
    flexShrink: 0,
    width: '100%',
    color: '#000',
    paddingTop: 10,
  },
  iconWrapper: {
    width: 100,
    marginTop: 10,
  },
  instructions: {
    fontSize: '1.3rem',
  },
  titleGroup: {
    margin: 25,
  },
  text: {
    fontSize: '22px',
  },
  nextBtn: {
    textAlign: 'center',
    width: '100%',
    top: '90%',
    position: 'fixed',
  },
};

const StyledInput = withStyles(() => ({
  input: {
    textAlign: 'center',
    fontSize: '22px',
  },
}))(Input);

class DiabetesDaily3 extends Page {
  constructor(props) {
    super(props);

    this.state = {
      todaysBloodSugarInRange: null,
      todaysBloodSugarLevel: null,
    };

    this.handleContinue = this.handleContinue.bind(this);
  }

  handleBloodSugar = (e) => {
    const { value } = e.target;
    this.setState({
      todaysBloodSugarLevel: value,
    });
  };

  handleContinue() {
    this.props.updatePRO({
      type: 'diabetesDaily',
      position: this.getTrackIndex(),
      value: this.state });
    this.forwardWithQuery(this.props.location.query);
  }

  render() {
    const { todaysBloodSugarInRange, todaysBloodSugarLevel } = this.state;

    let disabled;

    if (todaysBloodSugarInRange !== null && todaysBloodSugarLevel > 0) {
      disabled = false;
    } else {
      disabled = true;
    }

    return (
      <Fragment>
        <AppBar
          headerNode="Blood Sugar"
          noLeftNode={true}
        />
        <LinearProgress
          variant="determinate"
          value={(this.getCurrentPage() / this.getTotalPages()) * 100}
        />
        <div style={styles.iconContainer}>
          <div style={styles.iconWrapper}>
            <WaterDrop />
          </div>
        </div>

        <div style={styles.titleGroup}>
          <p style={styles.text}>What is your blood sugar level today?</p>
          <div style={styles.formControl}>
            <FormControl style={styles.formControlItem}>
              <StyledInput
                id="todaysBloodSugarLevel"
                type="tel"
                value={todaysBloodSugarLevel}
                name="todaysBloodSugarLevel"
                placeholder="00"
                onChange={this.handleBloodSugar}
                inputProps={{
                  'aria-label': 'todaysBloodSugarLevel',
                }}
              />
            </FormControl>
            <Typography>mg/dL</Typography>
          </div>
        </div>

        <div style={styles.titleGroup}>
          <p style={styles.text}>Is this within your normal range?</p>
          <div style={styles.buttonGroup}>
            <Button style={styles.button} variant="contained" color={todaysBloodSugarInRange ? 'primary' : 'default'} onClick={() => this.setState({ todaysBloodSugarInRange: true })}>Yes</Button>
            <Button style={styles.button} variant="contained" color={todaysBloodSugarInRange === false ? 'primary' : 'default'} onClick={() => this.setState({ todaysBloodSugarInRange: false })}>No</Button>
          </div>
        </div>

        <div style={styles.nextBtn}>
          <Continue
            disabled={disabled}
            text="Next"
            onClick={this.handleContinue}
          />
        </div>

      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { user, proForms: { diabetesDaily } } = state;
  return { user, diabetesDaily };
}

DiabetesDaily3.proptypes = {
  user: PropTypes.object.isRequired,
  route: PropTypes.object.isRequired,
  updatePRO: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { updatePRO })(DiabetesDaily3
);
