import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get, omit } from 'lodash';
import { Divider, TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import AppBar from '../components/app-bar';
import Continue from '../components/continue';
import Page from './page';
import Layout from '../layouts/common';
import State from '../components/state';
import { custom, colors } from '../lib/styles';

import {
  readPharmacyHx,
  editPharmacyHx,
  addPharmacyHx,
  deletePharmacyHx,
} from '../state/pharmacy-hx';

const baseStyles = {
  sectionTitle: {
    color: colors.black,
    fontSize: '1.6rem',
    marginLeft: '15px',
    textAlign: 'left',
  },
  shrink: {
    color: colors.black,
    fontSize: '2rem',
  },
};

const classStyles = {
  textField: {
    marginBottom: 5,
    marginTop: 5,
    '& input': {
      color: colors.greyText,
      fontSize: 18,
    },
    '& .MuiFormLabel-root:': {
      fontSize: 18,
      background: 'blue !important',
    },
    '& .MuiInput-underline:after': {
      borderBottom: 'none',
    },
    '& .MuiInput-underline:before': {
      borderBottom: 'none',
    },
    '& .MuiInput-underline:hover:before': {
      borderBottom: 'none',
    },
  },
};

class Pharmacy extends Page {
  constructor(props) {
    super(props);
    this.state = {
      pharmacyNameError: '',
      pharmacy: {
        id: null,
        name: '',
        pharmacist: '',
        phone_number: '',
        fax_number: '',
        address: '',
        city: '',
        state: 'none',
        zip_code: '',
        ...props.pharmacy,
      },
    };

    this.handleSave = this.handleSave.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleChangeName = this.handleTextFieldUpdate.bind(this, 'name');
    this.handleChangePharmacist = this.handleTextFieldUpdate.bind(this, 'pharmacist');
    this.handleChangePhone = this.handleTextFieldUpdate.bind(this, 'phone_number');
    this.handleChangeFax = this.handleTextFieldUpdate.bind(this, 'fax_number');
    this.handleChangeAddress = this.handleTextFieldUpdate.bind(this, 'address');
    this.handleChangeCity = this.handleTextFieldUpdate.bind(this, 'city');
    this.handleChangeState = this.handleTextFieldUpdate.bind(this, 'state');
    this.handleChangePostal = this.handleTextFieldUpdate.bind(this, 'zip_code');
    this.handleState = this.handleState.bind(this);
  }

  componentWillMount() {
    const { pharmacyId, pharmacy } = this.props;
    if (pharmacyId && !pharmacy) {
      this.props.readPharmacyHx(pharmacyId);
    }
  }

  componentWillReceiveProps(props, nextProps) {
    if (props.pharmacy !== nextProps.pharmacy) {
      this.setState({
        pharmacy: {
          ...props.pharmacy,
        },
      });
    }
  }

  handleTextFieldUpdate(field, e) {
    let { pharmacyNameError } = this.state;
    if (field === 'name') {
      pharmacyNameError = '';
    }
    this.setState({
      pharmacyNameError,
      pharmacy: {
        ...this.state.pharmacy,
        [field]: e.target.value,
      },
    });
  }

  handleState(e) {
    this.setState({
      pharmacy: {
        ...this.state.pharmacy,
        state: e.target.value,
      },
    });
  }

  handleDateUpdate(field, e, date) {
    this.setState({
      pharmacy: {
        ...this.state.pharmacy,
        [field]: date,
      },
    });
  }

  handleUpdateDate() {
    this.props.deletePharmacyHx(this.state.pharmacy.id)
      .then(() => this.props.router.goBack());
  }

  handleSave() {
    const { pharmacy } = this.state;

    let pharmacyNameError = '';
    if (!pharmacy.name) {
      pharmacyNameError = 'Pharmacy name is required!';
    }
    if (pharmacyNameError) {
      return this.setState({ pharmacyNameError });
    }
    if (pharmacy.id) {
      const updatedPharmacy = omit(pharmacy, ['user_id']);
      this.props.editPharmacyHx(updatedPharmacy)
        .then(() => this.props.router.goBack())
        .catch(() => {});
    } else {
      // Add Pharmacy
      const newPharmacy = omit(pharmacy, ['id', 'user_id']);
      this.props.addPharmacyHx(newPharmacy)
        .then(() => this.props.router.goBack())
        .catch(() => {});
    }
  }

  handleDelete() {
    const { pharmacy } = this.state;

    this.props.deletePharmacyHx(pharmacy.id)
      .then(() => this.props.router.goBack())
      .catch(() => {});
  }

  render() {
    const { pharmacy, pharmacyNameError } = this.state;
    const {
      addError,
      deleteError,
      editError,
      classes,
    } = this.props;
    let deleteButton = null;
    let displayedError;
    if (addError || deleteError || editError) {
      displayedError = (
        <div style={custom.redErrorText}>
          There was an error submitting this page. Please try again.
        </div>
      );
    }
    if (pharmacy.id) {
      deleteButton = (
        <Continue
          text="Delete Pharmacy"
          btnStyle={{ backgroundColor: colors.errorRed }}
          onClick={this.handleDelete}
        />);
    }
    return (
      <Layout>
        <AppBar
          backButtonOnClick={() => this.props.router.goBack()}
          headerNode="Pharmacy"
        />
        <section>
          <p style={baseStyles.sectionTitle}>PHARMACY DETAILS</p>
        </section>
        <section className="form" style={custom.whiteSection}>
          <TextField
            label="Name of Pharmacy*"
            value={pharmacy.name}
            onChange={this.handleChangeName}
            classes={{ root: classes.textField }}
            style={custom.marginLeft15}
            error={!!pharmacyNameError}
            helperText={pharmacyNameError}
          />
          <Divider />
          <TextField
            label="Name of Pharmacist"
            style={custom.marginLeft15}
            value={pharmacy.pharmacist}
            onChange={this.handleChangePharmacist}
            classes={{ root: classes.textField }}
          />
          <Divider />
          <TextField
            label="Phone Number"
            style={custom.marginLeft15}
            type="tel"
            value={pharmacy.phone_number}
            onChange={this.handleChangePhone}
            classes={{ root: classes.textField }}
          />
          <Divider />
          <TextField
            label="Fax Number"
            style={custom.marginLeft15}
            type="tel"
            value={pharmacy.fax_number}
            onChange={this.handleChangeFax}
            classes={{ root: classes.textField }}
          />
          <Divider />
          <TextField
            label="Address"
            style={custom.marginLeft15}
            value={pharmacy.address}
            onChange={this.handleChangeAddress}
            classes={{ root: classes.textField }}
          />
          <Divider />
          <TextField
            label="City"
            style={custom.marginLeft15}
            value={pharmacy.city}
            onChange={this.handleChangeCity}
            classes={{ root: classes.textField }}
          />
          <Divider />
          <div style={custom.flexStart}>
            <State
              onChange={this.handleState}
              style={custom.marginLeft15}
              value={pharmacy.state}
            />
            <TextField
              label="Postal Code"
              style={custom.marginLeft15}
              value={pharmacy.zip_code}
              onChange={this.handleChangePostal}
              classes={{ root: classes.textField }}
            />
          </div>
        </section>
        <div style={custom.marginTop}>
          {deleteButton}
          <Continue
            label="Save Pharmacy"
            hoverColor={custom.highlightDarker}
            onClick={this.handleSave}
          />
          {displayedError}
        </div>
      </Layout>
    );
  }
}

function mapStateToProps(state, props) {
  const { pharmacyHx } = state;
  const pharmacyId = get(props, 'location.query.pharmacy_hx_id', null);
  return {
    pharmacyId,
    pharmacy: pharmacyHx.data[pharmacyId],
    addError: pharmacyHx.addError,
    deleteError: pharmacyHx.deleteError,
    editError: pharmacyHx.editError,
  };
}

Pharmacy.defaultProps = {
  pharmacy: null,
  medicationId: null,
};

Pharmacy.propTypes = {
  pharmacy: PropTypes.object,
  pharmacyId: PropTypes.string,
};

export default connect(mapStateToProps, {
  readPharmacyHx,
  editPharmacyHx,
  addPharmacyHx,
  deletePharmacyHx,
})(withStyles(classStyles)(Pharmacy));
