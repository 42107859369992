import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isNumber, get, includes } from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import RadioButton from '@material-ui/core/Radio';
import RadioButtonGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormHelperText from '@material-ui/core/FormHelperText';

import Page from './page';
import { Osteoporosis } from '../lib/icons';
import AppBar from '../components/app-bar';
import { colors } from '../lib/styles';
import QuestionLayout from '../layouts/question';
import { updatePRO } from '../state/pro-forms';
import LinearProgress from '../components/linear-progress';

const { questionBackground } = colors;

const styles = {
  contentWrapper: {
    flexGrow: 1,
    overflowX: 'hidden',
    position: 'relative',
  },
  image: {
    height: 150,
    padding: '15px 60px',
    textAlign: 'center',
  },
  instructions: {
    color: 'grey',
    fontSize: '1.5rem',
    margin: '20px 40px 0px 40px',
    textAlign: 'center',
  },
  pageIndicator: {
    fontSize: '1.4rem',
  },
  questionnaire: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 30,
    position: 'relative',
  },
  formGroup: {
    margin: '10px',
  },
  radioButtonGroup: {
    display: 'flex',
    flexDirection: 'row',
  },
  radioButton: {
    color: colors.black,
    fontSize: '1.5rem',
    lineHeight: '1.7rem',
    alignItems: 'center',
  },
  helperText: {
    color: colors.black,
    fontSize: '1.2rem',
  },
};

class OsteoporosisRisk1 extends Page {
  constructor(props) {
    super(props);

    this.handleRace = this.handleChange.bind(this, 0);
    this.handleArthritis = this.handleChange.bind(this, 1);
    this.handleFracture = this.handleChange.bind(this, 2);
  }

  handleSubmission = () => {
    this.forwardWithQuery(this.props.location.query);
  }

  handleChange(index, event, value) {
    const trackIndex = this.getTrackIndex();
    const page = get(this.props.osteo, trackIndex, ['', '', '']);
    page[index] = parseInt(value, 10);
    this.props.updatePRO({
      type: 'osteo',
      position: this.getTrackIndex(),
      value: page,
    });
  }

  render() {
    const { osteo } = this.props;
    const page = get(osteo, this.getTrackIndex(), ['', '', '']);
    const value = includes(page, '') ? null : 0;
    const primaryBtnDisabled = !isNumber(value);
    const currentPage = this.getCurrentPage();
    const totalPages = this.getTotalPages();
    return (
      <QuestionLayout
        primaryBtnDisabled={primaryBtnDisabled}
        primaryBtnLabel="Next"
        primaryBtnOnTouchTap={this.handleSubmission}
        style={{ backgroundColor: questionBackground }}
      >
        <AppBar
          backButtonOnClick={() => this.props.router.goBack()}
          headerNode="Osteoporosis Risk"
          rightNode={
            <div style={styles.pageIndicator}>{`${currentPage} of ${totalPages}`}</div>
          }
        />
        <LinearProgress value={(currentPage / totalPages) * 100} />
        <div style={styles.contentWrapper}>
          <div style={styles.instructions}>
            Please select the option that best answers the question.
          </div>
          <div style={styles.image}>
            <Osteoporosis />
          </div>
          <div style={styles.questionnaire}>
            <FormControl style={styles.formGroup} component="fieldset">
              <FormLabel style={styles.radioButton} component="legend">Race:</FormLabel>
              <RadioButtonGroup
                name="race"
                onChange={this.handleRace}
                value={`${page[0]}` || ''}
                style={styles.radioButtonGroup}
              >
                <FormControlLabel value="0" control={<RadioButton style={styles.radioButton} />} label="Black" />
                <FormControlLabel value="5" control={<RadioButton style={styles.radioButton} />} label="Non-Black" />
              </RadioButtonGroup>
            </FormControl>
            <FormControl style={styles.formGroup} component="fieldset">
              <FormLabel style={styles.radioButton} component="legend">Rheumatoid Arthritis:</FormLabel>
              <RadioButtonGroup
                name="arthritis"
                onChange={this.handleArthritis}
                value={`${page[1]}` || ''}
                style={styles.radioButtonGroup}
              >
                <FormControlLabel value="4" control={<RadioButton style={styles.radioButton} />} label="Present" />
                <FormControlLabel value="0" control={<RadioButton style={styles.radioButton} />} label="Absent" />
              </RadioButtonGroup>
            </FormControl>
            <FormControl style={styles.formGroup} component="fieldset">
              <FormLabel style={styles.radioButton} component="legend">Nontraumatic Fracture - </FormLabel>
              <FormHelperText style={styles.helperText}>(Any fracture that was not the result of an accident or injury):</FormHelperText>
              <RadioButtonGroup
                name="arthritis"
                onChange={this.handleFracture}
                value={`${page[2]}` || ''}
                style={styles.radioButtonGroup}
              >
                <FormControlLabel value="0" control={<RadioButton style={styles.radioButton} />} label="0 Fractures" />
                <FormControlLabel value="4" control={<RadioButton style={styles.radioButton} />} label="1 Fractures" />
                <FormControlLabel value="8" control={<RadioButton style={styles.radioButton} />} label="2 Fractures" />
                <FormControlLabel value="12" control={<RadioButton style={styles.radioButton} />} label="3 Fractures" />
              </RadioButtonGroup>
            </FormControl>
          </div>
        </div>
      </QuestionLayout>
    );
  }
}

function mapStateToProps(state) {
  const { proForms: { osteo } } = state;
  return { osteo };
}

OsteoporosisRisk1.propTypes = {
  osteo: PropTypes.array.isRequired,
  updatePRO: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { updatePRO })(withStyles(styles)(OsteoporosisRisk1));
