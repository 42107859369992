import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { filter, map } from 'lodash';

import { withStyles } from '@material-ui/core/styles';

import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';

import AppBar from '../components/app-bar';
import Continue from '../components/continue';

import { Walking2 } from '../lib/icons';

import Page from './page';

import { updatePRO } from '../state/pro-forms';

const styles = {
  checkboxLabel: {
    fontSize: '1.75rem',
  },
  checkboxTitle: {
    display: 'block',
    paddingTop: 15,
    fontSize: '1.75rem',
  },
  checkboxCaption: {
    display: 'block',
    fontSize: '1.5rem',
  },
  iconContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    flexGrow: 0,
    flexShrink: 0,
    width: '100%',
    color: '#000',
    paddingTop: 10,
  },
  iconWrapper: {
    width: 100,
    marginTop: 10,
  },
  titleGroup: {
    margin: 25,
  },
  text: {
    fontSize: '22px',
  },
  nextBtn: {
    textAlign: 'center',
    width: '100%',
    top: '90%',
  },
};

const LargeCheckbox = withStyles(() => ({
  root: {
    transform: 'scale(1.5)',
  },
}))(Checkbox);

class DiabetesDaily7 extends Page {
  constructor(props) {
    super(props);

    this.state = {
      symptoms: [],
    };

    this.handleContinue = this.handleContinue.bind(this);
  }

  handleCheckbox = (e) => {
    const { name, checked } = e.target;
    const { symptoms } = this.state;
    if (!checked && symptoms.includes(name)) {
      this.setState({ symptoms: filter(symptoms, (type) => { return type !== name; }) });
    } else if (checked && !symptoms.includes(name)) {
      this.setState({ symptoms: [...symptoms, name] });
    }
  };

  handleContinue() {
    this.props.updatePRO({
      type: 'diabetesDaily',
      position: this.getTrackIndex(),
      value: this.state });
    this.forwardWithQuery(this.props.location.query);
  }

  render() {
    const { symptoms } = this.state;

    const list = ['Fatigue', 'Shakiness', 'Dizziness', 'Sweating', 'Nausea', 'Weakness', 'Clammy', 'Cold or Hot', 'Heart Palpitations', 'Frequent Urination', 'Frequent Thirst', 'Dry Mouth', 'Blurred Vision', 'Headache'];

    const symptomsList = map(list, symptom => (
      <FormControlLabel
        control={<LargeCheckbox checked={symptoms.includes(symptom)} onChange={this.handleCheckbox} name={symptom} />}
        label={(
          <div style={styles.checkboxLabel}>
            <Typography variant="title">
              {symptom}
            </Typography>
          </div>
        )}
      />
    ));

    return (
      <Fragment>
        <AppBar
          headerNode="Exercise"
          noLeftNode={true}
        />
        <LinearProgress
          variant="determinate"
          value={(this.getCurrentPage() / this.getTotalPages()) * 100}
        />

        <div style={styles.iconContainer}>
          <div style={styles.iconWrapper}>
            <Walking2 />
          </div>
        </div>

        <div style={styles.titleGroup}>
          <p style={styles.text}>Do you currently experience any of the following symptoms?</p>
          <FormControl component="fieldset" style={styles.formControlItem}>
            <FormGroup>
              {symptomsList}
            </FormGroup>
          </FormControl>
        </div>

        <div style={styles.nextBtn}>
          <Continue
            text="Next"
            onClick={this.handleContinue}
          />
        </div>
      </Fragment>
    );
  }
}


function mapStateToProps(state) {
  const { user, proForms: { diabetesDaily } } = state;
  return { user, diabetesDaily };
}

DiabetesDaily7.proptypes = {
  user: PropTypes.object.isRequired,
  route: PropTypes.object.isRequired,
  updatePRO: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { updatePRO })(DiabetesDaily7);
