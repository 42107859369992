import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import LinearProgress from '@material-ui/core/LinearProgress';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { withStyles } from '@material-ui/core/styles';
import { Explore } from '@material-ui/icons';

import AppBar from '../components/app-bar';
import Continue from '../components/continue';

import Page from './page';

import { updatePRO } from '../state/pro-forms';

const styles = {
  formControlLabelRoot: {
    alignItems: 'start',
    marginTop: 5,
    '& .MuiSvgIcon-root': {
      height: 20,
      width: 20,
    },
  },
  iconContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    flexGrow: 0,
    flexShrink: 0,
    width: '100%',
    color: '#000',
    paddingTop: 10,
  },
  iconWrapper: {
    width: 100,
  },
  radio: {
    paddingTop: 4,
  },
  radioLabel: {
    fontSize: '2rem',
  },
  titleGroup: {
    margin: 25,
  },
  text: {
    fontSize: '2rem',
  },
  nextBtn: {
    textAlign: 'center',
    width: '100%',
    top: '90%',
  },
};

class ContactSpo2DataCollection11 extends Page {
  constructor(props) {
    super(props);

    this.state = {
      stability: null,
    };
  }

  handleChange = (e) => {
    const { value } = e.target;
    this.setState({
      stability: value,
    });
  };

  handleContinue = () => {
    const { location: { query }, updatePRO } = this.props;

    updatePRO({
      type: 'contactSpo2DataCollection',
      position: this.getTrackIndex(),
      value: this.state });
    this.forwardWithQuery(query);
  }

  render() {
    const { classes } = this.props;
    const { stability } = this.state;

    return (
      <Fragment>
        <AppBar
          headerNode="Stability"
          noLeftNode={true}
        />
        <LinearProgress
          variant="determinate"
          value={(this.getCurrentPage() / this.getTotalPages()) * 100}
        />
        <div className={classes.iconContainer}>
          <div className={classes.iconWrapper}>
            <Explore style={{ fontSize: 100 }} />
          </div>
        </div>

        <div className={classes.titleGroup}>
          <FormControl component="fieldset">
            <FormLabel className={classes.text} component="legend">How was this device positioned?</FormLabel>
            <RadioGroup aria-label="stability" name="stability" value={stability} onChange={this.handleChange}>
              <FormControlLabel
                value="placed on a stand"
                control={<Radio classes={{ root: classes.radio }} />}
                label="Placed on a stand"
                classes={{ root: classes.formControlLabelRoot, label: classes.radioLabel }}
              />
              <FormControlLabel
                value="propped against something"
                control={<Radio classes={{ root: classes.radio }} />}
                label="Propped against something"
                classes={{ root: classes.formControlLabelRoot, label: classes.radioLabel }}
              />
              <FormControlLabel
                value="placed on a flat surface"
                control={<Radio classes={{ root: classes.radio }} />}
                label="Placed on a flat surface (table/desk)"
                classes={{ root: classes.formControlLabelRoot, label: classes.radioLabel }}
              />
              <FormControlLabel
                value="handheld not-stabilized"
                control={<Radio classes={{ root: classes.radio }} />}
                label="Handheld (Not-Stabilized)"
                classes={{ root: classes.formControlLabelRoot, label: classes.radioLabel }}
              />
              <FormControlLabel
                value="handheld stabilized"
                control={<Radio classes={{ root: classes.radio }} />}
                label="Handheld (Stabilized)"
                classes={{ root: classes.formControlLabelRoot, label: classes.radioLabel }}
              />
            </RadioGroup>
          </FormControl>
        </div>

        <div className={classes.nextBtn}>
          <Continue
            disabled={(stability === null)}
            text="Next"
            onClick={this.handleContinue}
          />
        </div>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { proForms: { contactSpo2DataCollection } } = state;
  return { contactSpo2DataCollection };
}

ContactSpo2DataCollection11.proptypes = {
  classes: PropTypes.object.isRequired,
  updatePRO: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { updatePRO })(withStyles(styles)(ContactSpo2DataCollection11));
