import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { get } from 'lodash';

import { custom } from '../lib/styles';
import AppBar from '../components/app-bar';
import Page from './page';
import Layout from '../layouts/common';
import MigrainePainConfirmPresentation from '../components/migraine-pain-confirm-presentation';
import { updatePRO } from '../state/pro-forms';

class HeadachePain3 extends Page {
  constructor(props) {
    super(props);
    this.handleContinue = this.handleContinue.bind(this);
    this.handleStartDateUpdate = this.handleDateUpdate.bind(this, 'startDate');
    this.handleEndDateUpdate = this.handleDateUpdate.bind(this, 'endDate');
    this.handleTriggersUpdate = this.handleTextFieldUpdate.bind(this, 'triggers');
    this.handleExplanationUpdate = this.handleTextFieldUpdate.bind(this, 'explanation');
    this.handleStartTimeOfDayChange = this.handleTimeUpdate.bind(this, 'startTimeOfDay');
    this.handleEndTimeOfDayChange = this.handleTimeUpdate.bind(this, 'endTimeOfDay');
    const start_time = get(props, 'migraineHx.start_time', null);
    const end_time = get(props, 'migraineHx.end_time', null);

    const dateStart = start_time ? new Date(start_time) : null;
    const dateEnd = this.props.migraineHx ? new Date() : null;

    this.state = {
      migraineStartDateError: '',
      migraineEndDateError: '',
      dateOutOfOrderError: '',
      triggers: '',
      explanation: '',
      ...props.migraineHx,
      ...props.migrainePainHx,
      start_time,
      end_time,
      startDate: dateStart,
      endDate: dateEnd,
      startTimeOfDay: dateStart,
      endTimeOfDay: dateEnd,
    };
  }

  handleTextFieldUpdate(field, e) {
    this.setState({
      [field]: e.target.value,
    });
  }

  handleDateUpdate(field, date) {
    let { migraineStartDateError, migraineEndDateError } = this.state;

    if (field === 'startDate') {
      migraineStartDateError = '';
    }

    if (field === 'endDate') {
      migraineEndDateError = '';
    }

    this.setState({
      migraineStartDateError,
      migraineEndDateError,
      [field]: date,
    });
  }

  handleTimeUpdate(field, time) {
    this.setState({
      [field]: time,
    });
  }

  handleContinue() {
    const { startDate, startTimeOfDay, endDate, endTimeOfDay, triggers, explanation } = this.state;
    let migraineStartDateError = '';
    let migraineEndDateError = '';
    let dateOutOfOrderError = '';
    if (!startDate) {
      migraineStartDateError = 'Please enter a start date!';
    }
    if (!endDate) {
      migraineEndDateError = 'Please enter the date your headache ended!';
    }
    if (endDate < startDate) {
      dateOutOfOrderError = (
        <div style={custom.redErrorText}>End date cannot occur before start date</div>
      );
    }
    if (migraineStartDateError || migraineEndDateError || dateOutOfOrderError) {
      return this.setState({ migraineStartDateError, migraineEndDateError, dateOutOfOrderError });
    }

    const formattedStartTOD = startTimeOfDay
      ? moment(startTimeOfDay).format('HH:mm:ssZ') : moment().format('00:00:00Z');
    const formattedEndTOD = endTimeOfDay
      ? moment(endTimeOfDay).format('HH:mm:ssZ') : moment().format('00:00:00Z');


    const start_time = moment(startDate).format('YYYY-MM-DD') + 'T' + formattedStartTOD;
    const end_time = moment(endDate).format('YYYY-MM-DD') + 'T' + formattedEndTOD;

    this.props.updatePRO({
      type: 'headachePain',
      position: this.getTrackIndex(),
      value: {
        start_time,
        end_time,
      }
    });

    this.props.updatePRO({
      type: 'headachePain',
      position: 1, // Pain information
      value: {
        ...this.props.migrainePainHx,
          triggers,
          explanation,
      }
    });
    this.forwardWithQuery();
  }

  onClickBackButton = () => {
    this.backward();
  }

  render() {
    const {
      triggers,
      explanation,
      migraineStartDateError,
      migraineEndDateError,
      dateOutOfOrderError,
    } = this.state;
    const {
      migrainePending,
      migrainePainPending,
      migrainePainHx,
      addErrorMigraineHx,
      editErrorMigraineHx,
      readErrorMigraineHx,
      addErrorMigrainePainHx,
      editErrorMigrainePainHx,
      readErrorMigrainePainHx,
    } = this.props;
    let displayedError;

    const painType = get(migrainePainHx, 'pain_type');
    const severity = get(migrainePainHx, 'severity');
    const loading = migrainePending || migrainePainPending;

    // eslint-disable-next-line
    if (addErrorMigraineHx || editErrorMigraineHx || readErrorMigraineHx || addErrorMigrainePainHx || editErrorMigrainePainHx || readErrorMigrainePainHx) {
      displayedError = (
        <div style={custom.redErrorText}> There was an error submitting this page. Please try again.</div>
      );
    }
    return (
      <Layout>
        <AppBar
          backButtonOnClick={() => this.props.router.goBack()}
          headerNode="Headache Pain Confirm"
        />
        <MigrainePainConfirmPresentation
          brain_quadrant={this.props.migrainePainHx.brain_quadrant}
          severity={severity}
          painType={painType}
          pain={this.props.migrainePainHx}
          onLocationSelect={this.handlePainLocationUpdate}
          sex={'MALE'}
          loading={loading}
          noData={false}
          startDate={this.state.startDate}
          onChangeStartDate={this.handleStartDateUpdate}
          migraineStartDateError={migraineStartDateError}
          startTimeOfDay={this.state.startTimeOfDay}
          onChangeStartTimeOfDay={this.handleStartTimeOfDayChange}
          dateOutOfOrderError={dateOutOfOrderError}
          onChangeEndDate={this.handleEndDateUpdate}
          endDate={this.state.endDate}
          migraineEndDateError={migraineEndDateError}
          endTimeOfDay={this.state.endTimeOfDay}
          onChangeEndTimeOfDay={this.handleEndTimeOfDayChange}
          onUpdateTriggers={this.handleTriggersUpdate}
          triggers={triggers}
          onUpdateExplanation={this.handleExplanationUpdate}
          explanation={explanation}
          onContinue={this.handleContinue}
          displayedError={displayedError}
        />
      </Layout>
    );
  }
}


function mapStateToProps(state, props) {
  const { user, proForms } = state;
  return {
    user,
    migraineHx: proForms.headachePain[2] || {},
    migrainePainHx: proForms.headachePain[1] || {},
  };
}


export default connect(mapStateToProps, { updatePRO })(HeadachePain3);
