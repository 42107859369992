import React from 'react';
import { connect } from 'react-redux';
import rawr from 'rawr';
import transport from 'rawr/transports/worker';

import LinearProgress from '@material-ui/core/LinearProgress';
import { withStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Badge from '@material-ui/core/Badge';

import Page from './page';
import HiddenContent from '../components/hidden-content';

import { updatePRO } from '../state/pro-forms';
import { colors, fontSizing } from '../lib/styles';
import { throttledReset } from '../initializers/activity';
import { saveMetaData } from '../lib/fetch';
import { VSStatus, VSUnknown, VSVitals } from '../lib/vitalstream-packets';
import { CameraBuddy } from '../lib/cameraBuddy';
import { drawFaceBox, drawReticle, drawNumber } from '../lib/drawing';
import { Graph, GRAPH_POINT_DELAY, getLightingStatus, getImageDataFunc } from '../lib/vitals-spo2';

const styles = {
  patientVideo: {
    minHeight: '450px',
    height: '100%',
    maxWidth: 1000,
    objectFit: 'cover',
    width: '100%',
    display: 'none',
  },
  patientCanvas: {
    minHeight: '450px',
    height: '-webkit-fill-available',
    maxWidth: 1000,
    objectFit: 'cover',
    transform: 'scaleX(1)',
    width: '-webkit-fill-available',
  },
  loadingModel: {
    background: 'rgba(255, 255, 255, 0.5)',
    borderRadius: 5,
    fontSize: 16,
    marginLeft: 10,
    marginRight: 10,
    marginTop: 20,
    padding: 10,
  },
  container: {
    backgroundColor: colors.white,
    height: '100%',
    position: 'relative',
  },
  topSection: {
    backgroundColor: colors.white,
    height: '100%',
    position: 'relative',
    zIndex: 1,
  },
  videoContainer: {
    margin: '0px auto',
    height: '100vh',
    maxWidth: '540px',
    position: 'relative',
    width: '100%',
    zIndex: -1,
  },
  nonVideoContentContainer: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
  },
  calibratingCameraMessage: {
    background: colors.primaryColor,
    color: '#fff',
    fontSize: 16,
    marginTop: 40,
    padding: 10,
    textAlign: 'center',
  },
  instructions: {
    color: '#213864',
    fontSize: fontSizing.body,
    padding: 20,
    position: 'absolute',
    textAlign: 'center',
    zIndex: 0,
  },
  badge: {
    fontSize: '1rem',
    marginTop: '-8px',
    marginRight: '-8px',
  },
  graphCanvas: {
    position: 'absolute',
    left: 0,
    zIndex: 101,
    height: '80%',
    width: '98%',
    paddingTop: 10,
    paddingLeft: 3,
  },
  spo2Video: {
    height: '30%',
    position: 'absolute',
    top: 0,
    left: 0,
    transform: 'scaleX(-1)',
    width: '50%',
    zIndex: 100,
    objectFit: 'cover',
  },
  spo2Canvas: {
    position: 'absolute',
    left: 0,
    zIndex: 101,
    height: '30%',
    objectFit: 'cover',
  },
};

const TOTAL_RECORD_TIME_SECONDS = 120;
const TARGET_FPS = 30;
const COUNTDOWN_MILLIS = 10000;
const GRAPH_DURATION = 5000;
const COLOR_SPACE = 'display-p3'; // default is 'srgb'

class ContinuousBPDataCollection3 extends Page {
  constructor(props) {
    super(props);

    this.state = {
      retries: 0,
      countingDown: false,
      countdownStart: null,
      failed: false,
      cameraError: '',
      workerReady: true,
      savedCount: 0,
      saveRequested: 0,
      finishedRecording: false,
      finalVitals: {
        DIAS: -2,
        SYS: -2,
        HR: -2,
        BR: -2,
      },
      timestamps: [],
      startTimestamp: null,

      deviceError: '',
      unknownPackets: [],
      deviceErrors: [],

      deviceData: {
        respirationStatus: false,
        respiration: 0,

        bpStatus: false,
        diastolic: 0,
        systolic: 0,

        hrStatus: false,
        heartRate: 0,
      },
      deviceStatus: {
        calibrating: false,
        calibrated: false,
        dataValid: false,
        autocalPct: 0,

        clockWrapAround: false,
        pdaEnabled: false,
        simulationEnabled: false,
        batteryVoltageLow: false,
        criticalTemperature: false,
        bleAdv: false,
        chargeComplete: false,
        charging: false,
        invalidDataEntry: false,
        betaProcessing: false,
        recalSoon: false,
        notifyRecalibratingSoon: false,
        recalRecommended: false,
        tooManyFails: false,
        calibrationFailed: false,
        calibrationOffsetFailed: false,
        inflateFailed: false,
        noPulseTimeout: false,
        motionDetected: false,
        cuffTooLoose: false,
        cuffTooTight: false,
        badCuff: false,
        weakSignal: false,
        hemodynamicsEnabled: false,
        cardiacOutputCalibrated: false,
        poorSignal: false,
        motionEvent: false,
        stopButtonPressed: false,
        pumpOverrun: false,
        inflatedIndicator: false,
        pressureControlIndicator: false,
      },
      cropFace: false,
    };

    this.lastLightingTimestamp = Date.now();
    this.deviceReadings = [];
    this.greenChannelCollection = [];
    this.lightingStatus = null;
    this.backCameraData = [];

    this.cameraBuddy = null;
    this.deviceReadings = [];
    this.ctx = null;
    this.spo2Ctx = null;
    this.patientCanvas = React.createRef();
    this.patientVideo = React.createRef();
    this.videoContainer = React.createRef();
    this.graphCanvas = React.createRef();
    this.spo2Canvas = React.createRef();
    this.spo2Video = React.createRef();
  }

  handleDeviceData = (deviceData, deviceError) => {
    if (deviceError) {
      this.setState(state => ({ deviceErrors: state.deviceErrors.push({ deviceData, deviceError }) }));

      switch (deviceError.code) {
        case 910:
        case 911: // just log crc and malformed packets
          return console.error(deviceError.toString());
        case 901: // disconnected
          // if we already have an error, dont replace it with the disconnect
          if (this.state.deviceError) return this.setState({ failed: true }); // eslint-disable-next-line
        default:
          return this.setState({ deviceError: deviceError.toString(), failed: true });
      }
    }

    const { packet } = deviceData;

    if (packet instanceof VSVitals) {
      this.setState({
        deviceData: packet,
      });
      if (!this.state.finishedRecording) {
        this.deviceReadings.push({ ...packet, timestamp: Date.now() });
      }
    } else if (packet instanceof VSStatus) {
      this.setState({
        deviceStatus: packet,
      });
    } else if (packet instanceof VSUnknown) {
      console.error(packet);
      this.setState(state => ({ unknownPackets: state.unknownPackets.push(packet) }));
    }
  }

  componentDidMount = async () => {
    const { continuousBPDataCollection, userId } = this.props;
    const {
      FPS,
      seconds,
      hasDevice,
      imgEncoding,
      jpegQuality,
      cropFace,
      vitalstream,
      collectionMethod = 'front',
    } = this.getDataMap(continuousBPDataCollection);

    const recordMilliseconds = (seconds || TOTAL_RECORD_TIME_SECONDS) * 1000;
    this.setState({ cropFace, hasDevice, recordMilliseconds, collectionMethod });

    if (vitalstream) {
      try {
        await vitalstream.stream(this.handleDeviceData);
      } catch (deviceError) {
        return this.setState({ deviceError: deviceError.toString(), failed: true });
      }
    }

    const cameraBuddy = new CameraBuddy(this.patientVideo, userId, cropFace, imgEncoding, jpegQuality, recordMilliseconds, FPS || TARGET_FPS, collectionMethod === 'rear');
    this.cameraBuddy = cameraBuddy;

    const spo2Buddy = new CameraBuddy(this.spo2Video, userId, cropFace, imgEncoding, jpegQuality, recordMilliseconds, FPS || TARGET_FPS, collectionMethod === 'dual');
    this.spo2Buddy = spo2Buddy;
    try {
      await this.cameraBuddy.startCamera(collectionMethod === 'rear' ? COLOR_SPACE : undefined);
      if (collectionMethod === 'dual') await this.spo2Buddy.startCamera(COLOR_SPACE);
    } catch (err) {
      return this.setState({ failed: true, cameraError: err });
    }

    // set up our canvas
    const canvas = this.patientCanvas.current;
    canvas.height = this.patientVideo.current.videoHeight;
    canvas.width = this.patientVideo.current.videoWidth;
    this.ctx = canvas.getContext('2d', { willReadFrequently: true });

    // trigger countdown and images to start saving
    this.setState({ countingDown: true, countdownStart: Date.now() });
    setTimeout(() => {
      this.cameraBuddy.startSaving();
      this.setState({ countingDown: false });
    }, COUNTDOWN_MILLIS);

    // set up graph
    if (collectionMethod !== 'front') {
      let offscreenCanvas;

      let graphCanvas = null;
      let graphHeight = null;
      let graphWidth = null;

      if (collectionMethod === 'dual') {
        offscreenCanvas = new OffscreenCanvas(this.spo2Video.current.videoWidth / 4, this.spo2Video.current.videoHeight / 4);
        spo2Buddy.getImageData = getImageDataFunc(offscreenCanvas);
        spo2Buddy.startEmittingImgData();

        graphCanvas = this.spo2Canvas.current;
        graphHeight = this.spo2Video.current.videoHeight;
        graphWidth = this.spo2Video.current.videoWidth;
      } else if (collectionMethod === 'rear') {
        offscreenCanvas = new OffscreenCanvas(this.patientVideo.current.videoWidth / 4, this.patientVideo.current.videoHeight / 4);
        cameraBuddy.getImageData = getImageDataFunc(offscreenCanvas);
        cameraBuddy.startEmittingImgData();

        graphCanvas = this.graphCanvas.current;
        graphHeight = this.patientVideo.current.videoHeight;
        graphWidth = this.patientVideo.current.videoWidth;
      }
      this.spo2Ctx = graphCanvas.getContext('2d', { willReadFrequently: true, colorSpace: COLOR_SPACE });

      const graphCv = graphCanvas;
      graphCv.height = graphHeight;
      graphCv.width = graphWidth;

      this.greenChannelCollection = [];
      const greenChannelGraph = new Graph(graphCv, GRAPH_DURATION);
      this.greenChannelGraph = greenChannelGraph;

      this.spo2Buddy.on('frame', (graphCanvas, pvW, pvH) => {
        // draw the camera image in our canvas
        this.spo2Ctx.drawImage(graphCanvas, 0, 0, pvW, pvH);
      });

      this.spo2Buddy.on('imgData', async ({ imgData }) => {
        const now = Date.now();
  
        this.lastLightingTimestamp = now;

        // pull image data and find average red, green, blue values, and standard deviation for red
        // const imgData = this.ctx.getImageData(pvW / 4, pvH / 4, pvW / 2, pvH / 2);
        this.findValues(imgData.data).then((rgbaAvgValues) => {
          const { red, green, blue, redSD } = rgbaAvgValues;
          this.lightingStatus = getLightingStatus(red, green, blue, redSD);

          let yValue = 0;
          if (green > 10) {
            yValue = green;
          }
          // add the green value to our array
          this.greenChannelCollection.push({ x: now, y: yValue });
          if (!this.state.finishedRecording && !this.state.countingDown) {
            this.backCameraData.push({ timestamp: now, rgb: [red, green, blue], redSD });
          }
          // remove any items older than 5s from the array
          this.greenChannelCollection.every((g) => {
            if ((now - g.x) > greenChannelGraph.duration) {
              this.greenChannelCollection.shift();
              return true;
            }
            return false;
          });
        });
        // update our graph
        greenChannelGraph.draw(this.greenChannelCollection);
      });

      this.cameraBuddy.on('imgData', async ({ imgData }) => {
        // if (this.collectionMethod === 'front') return;
        const now = Date.now();

        if ((now - this.lastLightingTimestamp) > GRAPH_POINT_DELAY) {
          this.lastLightingTimestamp = now;

          // pull image data and find average red, green, blue values, and standard deviation for red
          // const imgData = this.ctx.getImageData(pvW / 4, pvH / 4, pvW / 2, pvH / 2);
          this.findValues(imgData.data).then((rgbaAvgValues) => {
            const { red, green, blue, redSD } = rgbaAvgValues;

            this.lightingStatus = getLightingStatus(red, green, blue, redSD);

            let yValue = 0;
            if (green > 10) {
              yValue = green;
            }
            // add the green value to our array
            this.greenChannelCollection.push({ x: now, y: yValue });
            if (!this.state.finishedRecording && !this.state.countingDown) {
              this.backCameraData.push({ timestamp: now, rgb: [red, green, blue], redSD });
            }
            // remove any items older than 5s from the array
            this.greenChannelCollection.every((g) => {
              if ((now - g.x) > greenChannelGraph.duration) {
                this.greenChannelCollection.shift();
                return true;
              }
              return false;
            });
          });
        }
        // update our graph
        greenChannelGraph.draw(this.greenChannelCollection);
      });
    }

    this.cameraBuddy.on('frame', (patientVideoCurrent, pvW, pvH) => {
      // draw the camera image in our canvas
      this.ctx.drawImage(patientVideoCurrent, 0, 0, pvW, pvH);

      if (collectionMethod !== 'rear') {
        const style = this.state.countingDown ? 'red' : 'green';
        drawFaceBox(this.ctx, pvW, pvH, style);
      }

      if (!this.state.countingDown) {
        if (collectionMethod !== 'rear') drawReticle(this.ctx, pvW, pvH);
      } else {
        const countdown = Math.max(Math.round((COUNTDOWN_MILLIS - (Date.now() - this.state.countdownStart)) / 1000), 0);
        const fontSize = Math.round(pvW / 8);
        drawNumber(this.ctx, countdown, pvW, pvH, fontSize, collectionMethod === 'rear');
      }
    });

    this.cameraBuddy.on('finishedSaving', async () => {
      await this.handleContinue();
    }, { once: true });

    this.cameraBuddy.on('saveRequested', (saveRequested, timestamp) => {
      if (!this.state.startTimestamp) {
        this.setState({ startTimestamp: timestamp });
      }
      this.setState({ saveRequested });
    });

    this.cameraBuddy.on('savedCount', (savedCount) => {
      this.setState({ savedCount });
    });

    this.cameraBuddy.on('failedCount', (failedCount, status, error) => {
      console.error('lost batch of frames', failedCount, status, error);
      return this.setState({ failed: true });
    });

    this.cameraBuddy.on('retry', () => {
      this.setState(state => ({ retries: state.retries + 1 }));
    });

    this.cameraBuddy.on('finishedRecording', async (timestamps) => {
      const { finalVitals, deviceData } = this.state;

      if (deviceData.hrStatus) {
        finalVitals.HR = deviceData.heartRate;
      }
      if (deviceData.respirationStatus) {
        finalVitals.BR = deviceData.respiration;
      }
      if (deviceData.bpStatus) {
        finalVitals.SYS = deviceData.systolic;
        finalVitals.DIAS = deviceData.diastolic;
      }

      this.setState({ timestamps, finalVitals, finishedRecording: true });

      if (vitalstream) {
        await vitalstream.stopStreaming();
      }
    }, { once: true });

    this.activityTimer = setInterval(throttledReset, 5000);


    // this all has to travel together or you'll miss the onready
    // eslint-disable-next-line no-undef
    const webWorker = new Worker('/workers/vitals-data-worker.js');
    webWorker.onerror = (err) => {
      console.error('webworker error', err);
    };
    const rawrPeer = rawr({ transport: transport(webWorker) });
    const { findValues } = rawrPeer.methods;
    this.findValues = findValues;
    rawrPeer.notifications.onready(() => {
      this.setState({ workerReady: true });
    });
  }

  componentWillUnmount = () => {
    clearInterval(this.activityTimer);
    if (this.state.collectionMethod === 'dual') {
      this.spo2Buddy.cleanup();
    }
    this.cameraBuddy.cleanup();
  }

  handleContinue = async () => {
    if (this.state.failed) return;

    const { location, userId, continuousBPDataCollection } = this.props;
    const { id } = location.query;
    const { finalVitals: vitals } = this.state;

    const meta = {
      supportedConstraints: navigator.mediaDevices.getSupportedConstraints(),
      ua: navigator.userAgent,
      deviceReadings: this.deviceReadings,
      timestamps: this.state.timestamps,
      formData: [...continuousBPDataCollection],
      backCameraData: this.backCameraData,
    };

    let res;
    let error;
    try {
      res = await saveMetaData(meta, userId, this.state.startTimestamp, () => { this.setState(state => ({ retries: state.retries + 1 })); });
    } catch (e) {
      error = e;
    }

    if (error || !res.ok) {
      console.error('saveMetaData failed: ', error, res);
      return this.setState({ failed: true });
    }

    this.props.updatePRO({
      type: 'continuousBPDataCollection',
      position: this.getTrackIndex(),
      value: {
        proId: id,
        timestamp: this.state.startTimestamp,
        userId,
        vitals,
      },
    });

    this.forwardWithQuery(location.query);
  }

  shouldHideInstructions = () => {
    const {
      deviceError,
      deviceStatus,
      failed,
      hasDevice,
      collectionMethod,
    } = this.state;

    if (collectionMethod === 'rear') return true;

    return hasDevice
      && (
        failed
        || deviceError !== ''
        || (
          deviceStatus.dataValid
          && (
            deviceStatus.hemodynamicsEnabled
            || deviceStatus.motionEvent
            || deviceStatus.poorSignal
            || deviceStatus.simulationEnabled
            || deviceStatus.batteryVoltageLow
            || deviceStatus.criticalTemperature
            || deviceStatus.bleAdv
            || deviceStatus.invalidDataEntry
            || deviceStatus.recalRecommended
            || deviceStatus.tooManyFails
            || deviceStatus.calibrationFailed
            || deviceStatus.calibrationOffsetFailed
            || deviceStatus.betaProcessing
            || deviceStatus.inflateFailed
            || deviceStatus.noPulseTimeout
            || deviceStatus.motionDetected
            || deviceStatus.cuffTooLoose
            || deviceStatus.cuffTooTight
            || deviceStatus.badCuff
            || deviceStatus.weakSignal
          )
        )
      );
  }

  render() {
    const { classes, location } = this.props;
    const {
      cameraError,
      hasDevice,
      collectionMethod,
      finishedRecording,
    } = this.state;
    const secondsRemaining = this.state.startTimestamp ? (this.state.recordMilliseconds / 1000) - Math.round((Date.now() - this.state.startTimestamp) / 1000) : this.state.recordMilliseconds / 1000;

    const hideInstructions = this.shouldHideInstructions();

    let borderColor;

    switch (collectionMethod) {
      // case 'dual':
      //   borderColor = `3px solid ${this.lightingStatus === 'ok' ? colors.successGreen : colors.errorRed}`;
      //   break;
      case 'rear':
        borderColor = `5px solid ${this.lightingStatus === 'ok' ? colors.successGreen : colors.errorRed}`;
        break;
      default:
        borderColor = 'none';
    }

    const primaryVideoOrientation = { transform: collectionMethod === 'rear' ? 'scaleX(1)' : 'scaleX(-1)', border: borderColor };

    return (
      <div className={classes.container}>
        <section className={classes.topSection}>
          {this.state.cropFace && !hideInstructions ? (
            <div className={classes.instructions} style={{ left: collectionMethod === 'dual' ? '50%' : '' }}>
              Please focus on the crosshair in the guide box.  Do not move your head, mouth, or talk during the session.
            </div>
          ) : null}
          <Snackbar
            open={this.state.failed}
            autoHideDuration={7000}
            onClose={async () => {
              // todo is there a way to safely reload THIS page instead?
              const { continuousBPDataCollection } = this.props;
              const { vitalstream } = this.getDataMap(continuousBPDataCollection);
              if (vitalstream) {
                await vitalstream.disconnect();
              }
              this.goToIndexWithQuery(0, location.query);
            }}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            <MuiAlert elevation={6} variant="filled" severity="error" style={{ fontSize: 16 }}>There has been an error. The session will restart.</MuiAlert>
          </Snackbar>

          {/* todo these need to be cssed better */}
          <Snackbar
            open={this.state.deviceError !== ''}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            <MuiAlert elevation={6} variant="filled" severity="error" style={{ fontSize: 16 }}>{this.state.deviceError}</MuiAlert>
          </Snackbar>

          <Snackbar
            open={this.state.deviceStatus.dataValid && this.state.deviceStatus.hemodynamicsEnabled}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            <MuiAlert elevation={6} variant="filled" severity="error" style={{ fontSize: 16 }}>Hemodynamics Enabled</MuiAlert>
          </Snackbar>
          {/* always during calibrate  */}
          {/* <Snackbar
            open={this.state.deviceStatus.dataValid && this.state.deviceStatus.cardiacOutputCalibrated}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            <MuiAlert elevation={6} variant="filled" severity="error" style={{ fontSize: 16 }}>Cardiac Output Calibrated</MuiAlert>
          </Snackbar> */}
          {/* always during calibrate  */}
          {/* <Snackbar
            open={this.state.deviceStatus.dataValid && this.state.deviceStatus.inflatedIndicator}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            <MuiAlert elevation={6} variant="filled" severity="error" style={{ fontSize: 16 }}>An indicator of whether the system has been inflated to pressure..</MuiAlert>
          </Snackbar> */}
          {/* always during calibrate  */}
          {/* <Snackbar
            open={this.state.deviceStatus.dataValid && this.state.deviceStatus.pressureControlIndicator}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            <MuiAlert elevation={6} variant="filled" severity="error" style={{ fontSize: 16 }}>An indicator of whether the system is currently running closed loop pressure control.</MuiAlert>
          </Snackbar> */}
          {/* always during calibrate  */}
          {/* <Snackbar
            open={this.state.deviceStatus.dataValid && this.state.deviceStatus.pumpOverrun}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            <MuiAlert elevation={6} variant="filled" severity="error" style={{ fontSize: 16 }}>The pump has violated an overrun condition.</MuiAlert>
          </Snackbar> */}
          {/* stopButtonPressed seems to come through often */}
          {/* <Snackbar
              open={this.state.deviceStatus.stopButtonPressed}
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
              <MuiAlert elevation={6} variant="filled" severity="error" style={{ fontSize: 16 }}>Indicates that the user pressed stop on the device UI.</MuiAlert>
            </Snackbar> */}

          <Snackbar
            open={this.state.deviceStatus.dataValid && this.state.deviceStatus.motionEvent}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            <MuiAlert elevation={6} variant="filled" severity="error" style={{ fontSize: 16 }}>The system is having trouble getting a good reading due to too much motion.</MuiAlert>
          </Snackbar>

          <Snackbar
            open={this.state.deviceStatus.dataValid && this.state.deviceStatus.poorSignal}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            <MuiAlert elevation={6} variant="filled" severity="error" style={{ fontSize: 16 }}>The system failed to calibrate or timed out process signals so measurements were aborted.</MuiAlert>
          </Snackbar>
          {/* Always? */}
          {/* <Snackbar
          open={this.state.deviceStatus.dataValid && this.state.deviceStatus.pdaEnabled}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <MuiAlert elevation={6} variant="filled" severity="error" style={{ fontSize: 16 }}>An indicator of whether the system Pulse Decomposition Algorithm (PDA) measurement system is enabled.</MuiAlert>
        </Snackbar> */}
          <Snackbar
            open={this.state.deviceStatus.dataValid && this.state.deviceStatus.simulationEnabled}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            <MuiAlert elevation={6} variant="filled" severity="error" style={{ fontSize: 16 }}>An indicator of whether the system is in simulation mode.</MuiAlert>
          </Snackbar>
          {/* always? */}
          {/* <Snackbar
          open={this.state.deviceStatus.dataValid && this.state.deviceStatus.clockWrapAround}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <MuiAlert elevation={6} variant="filled" severity="error" style={{ fontSize: 16 }}>The system clock (time since reset) has wrapped around its index.</MuiAlert>
        </Snackbar> */}
          {/* seems to trigger during calibration? even full battery might be mapped wrong? */}
          <Snackbar
            open={this.state.deviceStatus.dataValid && this.state.deviceStatus.batteryVoltageLow}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            <MuiAlert elevation={6} variant="filled" severity="error" style={{ fontSize: 16 }}>The battery voltage sensor has indicated the battery is near drop-out.</MuiAlert>
          </Snackbar>
          {/* seems to trigger during calibration? might be mapped wrong? */}
          <Snackbar
            open={this.state.deviceStatus.dataValid && this.state.deviceStatus.criticalTemperature}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            <MuiAlert elevation={6} variant="filled" severity="error" style={{ fontSize: 16 }}>The on-board temperature sensor has detected critically high temperature.</MuiAlert>
          </Snackbar>
          <Snackbar
            open={this.state.deviceStatus.dataValid && this.state.deviceStatus.bleAdv}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            <MuiAlert elevation={6} variant="filled" severity="error" style={{ fontSize: 16 }}>The ble module is advertising.</MuiAlert>
          </Snackbar>
          {/* who cares */}
          {/* <Snackbar
          open={this.state.deviceStatus.dataValid && this.state.deviceStatus.chargeComplete}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <MuiAlert elevation={6} variant="filled" severity="error" style={{ fontSize: 16 }}>Charging complete.</MuiAlert>
        </Snackbar> */}
          {/* who cares */}
          {/* <Snackbar
          open={this.state.deviceStatus.dataValid && this.state.deviceStatus.charging}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <MuiAlert elevation={6} variant="filled" severity="error" style={{ fontSize: 16 }}>Currently Charging.</MuiAlert>
        </Snackbar> */}
          <Snackbar
            open={this.state.deviceStatus.dataValid && this.state.deviceStatus.invalidDataEntry}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            <MuiAlert elevation={6} variant="filled" severity="error" style={{ fontSize: 16 }}>Invalid input received in the last command.</MuiAlert>
          </Snackbar>
          {/* <Snackbar
            open={this.state.deviceStatus.dataValid && this.state.deviceStatus.notifyRecalibratingSoon}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            <MuiAlert elevation={6} variant="filled" severity="error" style={{ fontSize: 16 }}>Recalibration will be starting soon.</MuiAlert>
          </Snackbar> */}
          <Snackbar
            open={this.state.deviceStatus.dataValid && this.state.deviceStatus.recalRecommended}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            <MuiAlert elevation={6} variant="filled" severity="error" style={{ fontSize: 16 }}>Signal is not sufficient to have high confidence in the readings.</MuiAlert>
          </Snackbar>
          <Snackbar
            open={this.state.deviceStatus.dataValid && this.state.deviceStatus.tooManyFails}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            <MuiAlert elevation={6} variant="filled" severity="error" style={{ fontSize: 16 }}>Too many consecutive autocalibration failures try manual cal</MuiAlert>
          </Snackbar>
          {/* always */}
          {/* <Snackbar
            open={this.state.deviceStatus.dataValid && this.state.deviceStatus.recalSoon}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            <MuiAlert elevation={6} variant="filled" severity="error" style={{ fontSize: 16 }}>The device requires recalibration soon.</MuiAlert>
          </Snackbar> */}
          <Snackbar
            open={this.state.deviceStatus.dataValid && this.state.deviceStatus.calibrationFailed}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            <MuiAlert elevation={6} variant="filled" severity="error" style={{ fontSize: 16 }}>The calibration values were out of range or oscillometric curve had invalid shape.</MuiAlert>
          </Snackbar>
          <Snackbar
            open={this.state.deviceStatus.dataValid && this.state.deviceStatus.calibrationOffsetFailed}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            <MuiAlert elevation={6} variant="filled" severity="error" style={{ fontSize: 16 }}>Too much movement.</MuiAlert>
          </Snackbar>
          {/* seems to trigger during calibration. Maybe use it as part of detecting calibrated state... */}
          <Snackbar
            open={this.state.deviceStatus.dataValid && this.state.deviceStatus.betaProcessing}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            <MuiAlert elevation={6} variant="filled" severity="error" style={{ fontSize: 16 }}>The system has finished finding the oscillometric curve and is processing the beta (offset) value.</MuiAlert>
          </Snackbar>
          <Snackbar
            open={this.state.deviceStatus.dataValid && this.state.deviceStatus.inflateFailed}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            <MuiAlert elevation={6} variant="filled" severity="error" style={{ fontSize: 16 }}>Cuff did not inflate to expected value within timeout.</MuiAlert>
          </Snackbar>
          <Snackbar
            open={this.state.deviceStatus.dataValid && this.state.deviceStatus.noPulseTimeout}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            <MuiAlert elevation={6} variant="filled" severity="error" style={{ fontSize: 16 }}>The systems has gone greater than 3 minutes without a valid heart beat.</MuiAlert>
          </Snackbar>
          <Snackbar
            open={this.state.deviceStatus.dataValid && this.state.deviceStatus.motionDetected}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            <MuiAlert elevation={6} variant="filled" severity="error" style={{ fontSize: 16 }}>The system is having trouble getting a good reading due to too much motion.</MuiAlert>
          </Snackbar>
          <Snackbar
            open={this.state.deviceStatus.dataValid && this.state.deviceStatus.cuffTooLoose}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            <MuiAlert elevation={6} variant="filled" severity="error" style={{ fontSize: 16 }}>The calibration pump up identified the cuff was too loose.</MuiAlert>
          </Snackbar>
          <Snackbar
            open={this.state.deviceStatus.dataValid && this.state.deviceStatus.cuffTooTight}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            <MuiAlert elevation={6} variant="filled" severity="error" style={{ fontSize: 16 }}>The calibration pump up identified the cuff was too tight.</MuiAlert>
          </Snackbar>
          <Snackbar
            open={this.state.deviceStatus.dataValid && this.state.deviceStatus.badCuff}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            <MuiAlert elevation={6} variant="filled" severity="error" style={{ fontSize: 16 }}>The cuff is not holding pressure as expected.</MuiAlert>
          </Snackbar>
          <Snackbar
            open={this.state.deviceStatus.dataValid && this.state.deviceStatus.weakSignal}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            <MuiAlert elevation={6} variant="filled" severity="error" style={{ fontSize: 16 }}>Calibration oscillometric curve amplitude is too weak to verify reading.</MuiAlert>
          </Snackbar>
          <HiddenContent hidden={finishedRecording}>
            <Snackbar
              open={this.lightingStatus === 'Too Bright'}
              autoHideDuration={5000}
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
              <MuiAlert elevation={6} variant="filled" severity="error" style={{ fontSize: 16 }}>Lighting is too bright, please adjust your finger to better cover the lens.</MuiAlert>
            </Snackbar>
            <Snackbar
              open={this.lightingStatus === 'Too Dark'}
              autoHideDuration={5000}
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
              <MuiAlert elevation={6} variant="filled" severity="error" style={{ fontSize: 16 }}>Lighting is too dark, please adjust your finger to allow more light.</MuiAlert>
            </Snackbar>
            <Snackbar
              open={this.lightingStatus === 'Adjust Finger'}
              autoHideDuration={5000}
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
              <MuiAlert elevation={6} variant="filled" severity="error" style={{ fontSize: 16 }}>Finger position is not in correct position. Please adjust your finger.</MuiAlert>
            </Snackbar>
          </HiddenContent>

          <div id="videoContainer" className={classes.videoContainer} ref={this.videoContainer}>
            <video id="patientVideo" ref={this.patientVideo} playsInline autoPlay className={classes.patientVideo} style={primaryVideoOrientation} muted />
            <canvas id="patientCanvas" ref={this.patientCanvas} className={classes.patientCanvas} style={primaryVideoOrientation} />
            <HiddenContent hidden={collectionMethod !== 'rear'}>
              <canvas id="graphCanvas" ref={this.graphCanvas} className={classes.graphCanvas} />
            </HiddenContent>
            <HiddenContent hidden={collectionMethod !== 'dual'}>
              <video id="spo2Video" ref={this.spo2Video} playsInline autoPlay className={classes.spo2Video} muted style={{ border: `3px solid ${this.lightingStatus === 'ok' ? colors.successGreen : colors.errorRed}`, display: finishedRecording ? 'none' : '' }} />
              <canvas id="spo2Canvas" ref={this.spo2Canvas} className={classes.spo2Canvas} style={{ display: finishedRecording ? 'none' : '' }} />
            </HiddenContent>
          </div>
          <div className={classes.nonVideoContentContainer}>
            {cameraError ? (
              <div><span>Unable to access camera: {cameraError.toString()}</span><br /></div>
            ) : ''}
            <HiddenContent hidden={this.state.finishedRecording || !this.state.workerReady}>
              <div className={classes.calibratingCameraMessage}>
                <div>Please hold still while the camera is recording{secondsRemaining ? `: ${secondsRemaining} seconds remaining` : ''}</div>
                {hasDevice ? (
                  <div>HR: {this.state.deviceData.hrStatus ? this.state.deviceData.heartRate : '--'}  BR: {this.state.deviceData.respirationStatus ? this.state.deviceData.respiration : '--'}  SYS: {this.state.deviceData.bpStatus ? this.state.deviceData.systolic : '--'} DIAS: {this.state.deviceData.bpStatus ? this.state.deviceData.diastolic : '--'}</div>
                ) : ''}
              </div>
            </HiddenContent>
            <HiddenContent hidden={!this.state.workerReady}>
              <div className={classes.loadingModel}>
                <Badge color="error" classes={{ colorError: classes.badge }} badgeContent={this.state.retries}>
                  <span>Images Uploading: {`${this.state.savedCount} of ${this.state.saveRequested}`}</span>
                </Badge>
                <LinearProgress value={Math.round((this.state.savedCount / this.state.saveRequested) * 100)} variant="determinate" />
              </div>
            </HiddenContent>
          </div>
        </section>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { user, proForms: { continuousBPDataCollection } } = state;
  return {
    user,
    userId: user.id,
    continuousBPDataCollection,
  };
}

export default connect(mapStateToProps, { updatePRO })(withStyles(styles)(ContinuousBPDataCollection3));
