import { promiseHandler, resetReducer } from 'cooldux';
import { get } from 'lodash';

import { apiFetch } from '../lib/fetch';

const { dayStart, dayEnd, dayError, dayHandler } = promiseHandler('day', 'calendars');
const { monthlyStart, monthlyEnd, monthlyError, monthlyHandler } = promiseHandler('monthly', 'calendars');
const { yearlyStart, yearlyEnd, yearlyError, yearlyHandler } = promiseHandler('yearly', 'calendars');
const { weeklyStart, weeklyEnd, weeklyError, weeklyHandler } = promiseHandler('weekly', 'calendars');

export function dayCalendar(startDate, endDate) {
  return function dispatcher(dispatch, getState) {
    const selfId = get(getState(), 'user.id', null);
    const promise = apiFetch(`/users/${selfId}/pain_calendar_day_report?pain_start_date=${startDate}&pain_end_date=${endDate}`);
    return dayHandler(promise, dispatch);
  };
}

export function monthlyCalendar(startDate, endDate) {
  return function dispatcher(dispatch, getState) {
    const selfId = get(getState(), 'user.id', null);
    const promise = apiFetch(`/users/${selfId}/pain_calendar_report?pain_start_date=${startDate}&pain_end_date=${endDate}`);
    return monthlyHandler(promise, dispatch);
  };
}

export function yearlyCalendar(startDate, endDate) {
  return function dispatcher(dispatch, getState) {
    const selfId = get(getState(), 'user.id', null);
    const promise = apiFetch(`/users/${selfId}/pain_calendar_report?pain_start_date=${startDate}&pain_end_date=${endDate}`);
    return yearlyHandler(promise, dispatch);
  };
}

export function weeklyCalendar(startDate, endDate) {
  return function dispatcher(dispatch, getState) {
    const selfId = get(getState(), 'user.id', null);
    const promise = apiFetch(`/users/${selfId}/pain_calendar_report?pain_start_date=${startDate}&pain_end_date=${endDate}`);
    return weeklyHandler(promise, dispatch);
  };
}

const initialState = {
  dayError: null,
  dayPending: false,
  dayCalendarData: [],
  monthlyError: null,
  monthlyPending: false,
  monthlyCalendarData: [],
  yearlyError: null,
  yearlyPending: false,
  yearlyCalendarData: [],
  weeklyError: null,
  weeklyPending: false,
  weeklyCalendarData: [],
};

function finishDayCalendar(state, dayCalendarData) {
  return { ...state, dayCalendarData, dayPending: false, dayError: null };
}

function finishMonthlyCalendar(state, monthlyCalendarData) {
  return { ...state, monthlyCalendarData, monthlyPending: false, monthlyError: null };
}

function finishYearlyCalendar(state, yearlyCalendarData) {
  return { ...state, yearlyCalendarData, yearlyPending: false, yearlyError: null };
}

function finishWeeklyCalendar(state, weeklyCalendarData) {
  return { ...state, weeklyCalendarData, weeklyPending: false, weeklyError: null };
}

const calendarReports = resetReducer(initialState, (state = initialState, action) => {
  switch (action.type) {
    case dayStart.type:
      return { ...state, dayPending: true };
    case dayEnd.type:
      return finishDayCalendar(state, action.payload);
    case dayError.type:
      return { ...state, dayPending: false, dayError: action.payload };
    case monthlyStart.type:
      return { ...state, monthlyPending: true };
    case monthlyEnd.type:
      return finishMonthlyCalendar(state, action.payload);
    case monthlyError.type:
      return { ...state, monthlyPending: false, monthlyError: action.payload };
    case yearlyStart.type:
      return { ...state, yearlyPending: true };
    case yearlyEnd.type:
      return finishYearlyCalendar(state, action.payload);
    case yearlyError.type:
      return { ...state, yearlyPending: false, yearlyError: action.payload };
    case weeklyStart.type:
      return { ...state, weeklyPending: true };
    case weeklyEnd.type:
      return finishWeeklyCalendar(state, action.payload);
    case weeklyError.type:
      return { ...state, weeklyPending: false, weeklyError: action.payload };
    default:
      return state;
  }
});

export default calendarReports;
