import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Slider } from '@material-ui/core';
import { round } from 'lodash';
import { painScaleReverseColors } from '../lib/styles';

const styles = {
  contentWrapper: {
    alignItems: 'center',
    color: 'grey',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  slider: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  sliderStyle: {
    flexGrow: 1,
    marginBottom: 24,
  },
  sliderLabel: {
    fontSize: 16,
    maxWidth: 100,
    textAlign: 'center',
  },
};

class HealthSlider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      max: props.max,
      min: props.min,
      sliderBottomLabel: props.sliderBottomLabel,
      sliderTopLabel: props.sliderTopLabel,
      theme: this.getSliderTheme(props.value || props.min),
      value: props.value || props.min,
    };
  }

  getSliderTheme = (value) => {
    return {
      color: painScaleReverseColors[round(value/10)],
    };
  }

  handleValueChange = (event, value) => {
    const theme = this.getSliderTheme(value);
    this.setState({ theme });
    this.props.onChange({
      type: this.props.type,
      position: this.props.trackIndex,
      value,
    });
  }

  render() {
    const {
      max,
      min,
      sliderBottomLabel,
      sliderTopLabel,
      theme,
    } = this.state;

    const { value } = this.props;
    return (
      <div style={styles.contentWrapper}>
        <div style={{ ...styles.sliderLabel, marginBottom: '50px' }}>
          {sliderTopLabel}
        </div>
          <Slider
            orientation="vertical"
            min={min}
            max={max}
            value={value}
            valueLabelDisplay="on"
            onChange={this.handleValueChange}
            style={{ ...styles.slider, ...theme }}
          />
        <div style={{ ...styles.sliderLabel, marginTop: '15px' }}>
          {sliderBottomLabel}
        </div>
      </div>
    );
  }
}

HealthSlider.defaultProps = {
  sliderBottomLabel: 'Worst imaginable health state',
  sliderTopLabel: 'Best imaginable health state',
  step: 1,
  value: undefined,
};

HealthSlider.propTypes = {
  trackIndex: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  min: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  sliderBottomLabel: PropTypes.string,
  sliderTopLabel: PropTypes.string,
  step: PropTypes.number,
  value: PropTypes.number,
};

export default HealthSlider;
