import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { FormControl, Input, TextField } from '@material-ui/core';
import { connect } from 'react-redux';

import Page from './page';
import AppBar from '../components/app-bar';
import IconWrapper from '../components/icon-wrapper';
import BloomkidzLayout from '../layouts/bloomkidz';
import { BabyCarriage } from '../lib/icons';
import { updatePRO } from '../state/pro-forms';
import { fontSizing } from '../lib/styles';
import { bloomkidzStyles, bloomkidzStylesObj } from '../lib/bloomkidz';

const styles = {
  ...bloomkidzStylesObj,
  label: {
    textAlign: 'center',
    width: '50px',
    marginBottom: '5px',
  },
};

class BloomkidzMedicalHistory4 extends Page {
  constructor(props) {
    super(props);

    const stateFromRedux = props.bloomkidzMedicalHistory[this.getTrackIndex()] || {};

    this.state = {
      lengthOfPregnancy: '',
      daysInNICU: '',
      pregnancyComplications: '',
      ...stateFromRedux,
    };
  }

  handleContinue = () => {
    const { location, updatePRO } = this.props;

    updatePRO({
      type: 'bloomkidzMedicalHistory',
      position: this.getTrackIndex(),
      value: { ...this.state },
    });

    this.forwardWithQuery(location.query);
  }

  handleTextFieldChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  shouldDisableContinueButton = () => {
    const {
      lengthOfPregnancy,
      daysInNICU,
      pregnancyComplications,
    } = this.state;

    return !lengthOfPregnancy
      || !daysInNICU
      || !pregnancyComplications;
  }

  render() {
    const { classes, router } = this.props;
    const { state } = this;
    const {
      lengthOfPregnancy,
      daysInNICU,
      pregnancyComplications,
    } = state;

    const continueButtonDisabled = this.shouldDisableContinueButton();

    return (
      <BloomkidzLayout
        primaryBtnOnTouchTap={this.handleContinue}
        primaryBtnLabel="Next"
        primaryBtnDisabled={continueButtonDisabled}
      >
        <AppBar
          headerNode="Medical History"
          backButtonOnClick={() => router.goBack()}
        />
        <IconWrapper>
          <BabyCarriage />
        </IconWrapper>
        <div className={classes.pageContent}>
          <div>Length of pregnancy</div>
          <div className={classes.paddingHorizontal}>
            <div className={classes.textFieldWrapper}>
              <div className={classes.label}>
                <FormControl style={styles.heightItem}>
                  <Input
                    className={classes.label}
                    id="month-adornment"
                    type="tel"
                    value={lengthOfPregnancy}
                    name="lengthOfPregnancy"
                    placeholder="00"
                    onChange={this.handleTextFieldChange}
                    inputProps={{
                      'aria-label': 'weight',
                    }}
                  />
                </FormControl>
              </div>
              <div>months</div>
            </div>
          </div>
          <div>Please explain any pregnancy complications</div>
          <div className={classes.paddingHorizontal}>
            <div className={classes.textFieldWrapper}>
              <TextField
                placeholder="Tap here to type"
                value={pregnancyComplications}
                onChange={this.handleTextFieldChange}
                name="pregnancyComplications"
                variant="standard"
                fullWidth
                inputProps={{ style: { fontSize: fontSizing.body, fontFamily: bloomkidzStyles.fontFamily } }}
              />
            </div>
          </div>
          <div>Length of stay in NICU</div>
          <div className={classes.paddingHorizontal}>
            <div className={classes.textFieldWrapper}>
              <div className={classes.label}>
                <FormControl style={styles.heightItem}>
                  <Input
                    className={classes.label}
                    id="day-adornment"
                    type="tel"
                    value={daysInNICU}
                    name="daysInNICU"
                    placeholder="00"
                    onChange={this.handleTextFieldChange}
                    inputProps={{
                      'aria-label': 'weight',
                    }}
                  />
                </FormControl>
              </div>
              <div>days</div>
            </div>
          </div>
        </div>
      </BloomkidzLayout>
    );
  }
}

BloomkidzMedicalHistory4.propTypes = {
  classes: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  updatePRO: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const {
    proForms: { bloomkidzMedicalHistory },
    user,
  } = state;

  return { user, bloomkidzMedicalHistory };
};

export default connect(mapStateToProps, { updatePRO })(withStyles(styles)(BloomkidzMedicalHistory4));
