import React from 'react';
import PropTypes from 'prop-types';
import { IconButton, LinearProgress } from '@material-ui/core/';
import MenuIcon from '@material-ui/icons/Menu';
import { withStyles } from '@material-ui/core/styles';
import { browserHistory } from 'react-router';
import { connect } from 'react-redux';

import AppBar from '../components/app-bar';
import Page from './page';
import QuestionLayout from '../layouts/question';
import { colors, desktopMaxWidth, fontSizing } from '../lib/styles';
import { submitPRO } from '../state/pro-forms';

const styles = {
  bodyText: {
    fontSize: fontSizing.body,
    marginTop: 20,
    backgroundColor: colors.questionBackground,
  },
  header: {
    fontSize: fontSizing.h3,
    fontWeight: 'bold',
    marginTop: 20,
    textAlign: 'center',
    backgroundColor: colors.questionBackground,
  },
  linearProgress: {
    background: colors.lightBrightBlue,
  },
  pageContent: {
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: desktopMaxWidth,
    paddingLeft: 10,
    paddingRight: 10,
    textAlign: 'center',
    backgroundColor: colors.questionBackground,
  },
  redCircle: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 20,
    width: 100,
  },
};

class VitalCoreRR2 extends Page {
  handleClickAccept = async () => {
    const { location, submitPRO, user } = this.props;
    const { id } = location.query;

    window.localStorage.setItem('vitalCoreAcceptedTerms', true);

    await submitPRO({ userId: user.id, id }, {
      pro_type: 'VITAL-CORE-RR',
      pro_data: {
        data: { declinedTermsAndConditions: false },
      },
      declined: false,
    }, user);

    this.goToIndexWithQuery(2, location.query);
  };

  render() {
    const { classes } = this.props;

    return (
      <QuestionLayout
        primaryBtnLabel="Never mind, I'd like to accept"
        primaryBtnOnTouchTap={this.handleClickAccept}
        style={{ backgroundColor: colors.questionBackground }}
      >
        <AppBar
          headerNode="IVC Terms and Conditions"
          backButtonOnClick={() => this.props.router.goBack()}
          rightNode={(
            <IconButton onClick={() => browserHistory.push('/core-vital-user-manual-menu')}>
              <MenuIcon fontSize="large" />
            </IconButton>
          )}
        />
        <LinearProgress
          variant="determinate"
          value={100}
          classes={{ bar: classes.linearProgress }}
        />
        <div className={classes.pageContent}>
          <img
            src="/img/RedXCircle.png"
            alt=""
            className={classes.redCircle}
          />
          <div className={classes.header}>
            You have declined the IVC Terms and Conditions
          </div>
          <div className={classes.bodyText}>
            You may close your browser window.
          </div>
          <div className={classes.bodyText}>
            Please contact your healthcare provider if you wish to have them monitor your health condition in the future.
          </div>
          <div className={classes.bodyText}>
            Thank you.
          </div>
        </div>
      </QuestionLayout>
    );
  }
}

VitalCoreRR2.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const { user } = state;

  return { user };
};

export default connect(mapStateToProps, { submitPRO })(withStyles(styles)(VitalCoreRR2));
