import React from 'react';
import { connect } from 'react-redux';
import { get, map, omit, pickBy } from 'lodash';

import Page from './page';
import Layout from '../layouts/common';
import AppBar from '../components/app-bar';
import MigrainePreventivePresentation from '../components/migraine-preventive-presentation';

import { migraineRxIfNeeded, migraineSymptomsIfNeeded } from '../state/app-data';
import { updatePRO, submitPRO } from '../state/pro-forms';
import { apiFetch, makeShadowOptions } from '../lib/fetch';

class HeadachePainPro6 extends Page {
  constructor(props) {
    super(props);

    this.state = {
      migraineQuestions: {
        has_migraines: true,
        other_preventive_rx: '',
        ...props.migraineQuestions,
      },
    };

    this.handleAddItem = this.handleAddItem.bind(this);
    this.handleRemoveItem = this.handleRemoveItem.bind(this);
    this.handleContinue = this.handleContinue.bind(this);
    this.handleOtherPreventiveUpdate = this.handleTextFieldUpdate.bind(this, 'other_preventive_rx');
    props.migraineSymptomsIfNeeded();
    props.migraineRxIfNeeded();
  }

  handleTextFieldUpdate(field, e, text) {
    this.setState({
      migraineQuestions: {
        ...this.state.migraineQuestions,
        [field]: text,
      },
    });
  }

  handleAddItem(rx_id) {
    const newItem = {
      rx_id,
    };
    this.props.updatePRO({
      type: 'headachePain',
      position: this.getTrackIndex(),
      value: {
        ...this.props.migrainePreventiveHx,
        [rx_id]: newItem,
      }
    });
  }

  handleRemoveItem(migraineMedId) {
    this.props.updatePRO({
      type: 'headachePain',
      position: this.getTrackIndex(),
      value: omit(this.props.migrainePreventiveHx, migraineMedId),
    });
  }

  async handleContinue() {
    const { id } = this.props.location.query;
    const { headachePain, user, migraineRx, migraineSymptoms } = this.props;
    const { migraineQuestions, submitting } = this.state;
    if(submitting) return;
    this.setState({ submitting: true });
    try {
      const newMigraineData = {
        migraineHx: {
          ...headachePain[2],
          other_abative_rx: migraineQuestions.other_preventive_rx,
        },
        migrainePainHx: {
          ...headachePain[1],
        },
        migrainePreventiveHx: map(headachePain[5], i => i), // convert object to array
        migraineAbortiveHx: map(headachePain[4], i => i), // convert object to array
        migraineSymptomsHx: map(headachePain[3], i => i), // convert object to array
      };
      const addMigraineOptions = makeShadowOptions(newMigraineData, user.token, 'POST');
      const newMigraine = await apiFetch(`/users/${user.id}/migraine_hx_full`, addMigraineOptions);

      const formattedDataForPro = {
        ...newMigraineData,
        migrainePreventiveHx: map(headachePain[5], i => migraineRx[i.rx_id].name),
        migraineAbortiveHx: map(headachePain[4], i => migraineRx[i.rx_id].name),
        migraineSymptomsHx: map(headachePain[3], i => `${migraineSymptoms[i.migraine_symptoms_id].type} - ${migraineSymptoms[i.migraine_symptoms_id].name}`),
        migraine_hx_id: newMigraine.id,
      }

      await this.props.submitPRO({ userId: user.id, id }, {
        pro_type: 'HEADACHE-PAIN',
        pro_data: { data: formattedDataForPro },
      }, user);
      this.forwardWithQuery();
    } catch (e) {
      this.setState({submitting: false, error: e});
      console.error(e);
    }
  }

  onClickBackButton = () => {
    this.backward();
  }

  render() {
    const { migraineAbativeRx } = this.props;

    return (
      <Layout>
        <AppBar
          backButtonOnClick={() => this.props.router.goBack()}
          headerNode="Migraine Preventive Medications"
        />
        <MigrainePreventivePresentation
          migraineRx={migraineAbativeRx}
          selectedItems={this.props.migrainePreventiveHx}
          onAddItem={this.handleAddItem}
          onRemoveItem={this.handleRemoveItem}
          onChangeOtherPreventive={this.handleOtherPreventiveUpdate}
          otherPreventiveValue={this.state.migraineQuestions.other_preventive_rx}
          onContinue={this.handleContinue}
        />
      </Layout>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const {
    appData: {
      migraineRx,
      migraineSymptoms,
    },
    proForms: { headachePain },
    user,
  } = state;

  const migrainePreventiveHx = get(headachePain, '5', {});

  return {
    migraineAbativeRx: migraineRx ? pickBy(migraineRx, { type: 'ABATIVE' }) : null,
    migraineRx,
    headachePain,
    migrainePreventiveHx,
    migraineSymptoms,
    user,
  };
}

export default connect(mapStateToProps, { migraineSymptomsIfNeeded, migraineRxIfNeeded, updatePRO, submitPRO })(HeadachePainPro6);
