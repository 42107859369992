import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isNumber, forEach } from 'lodash';
import LinearProgress from '@material-ui/core/LinearProgress';
import { withStyles } from '@material-ui/core/styles';

import AppBar from './app-bar';
import { colors, fontSizing } from '../lib/styles';
import QuestionLayout from '../layouts/question';
import ButtonSelectGroup from './button-select-group';

const { lightBrightBlue } = colors;
const instructions = 'Please respond to each question or statement by marking one box per row.';

const styles = {
  buttonGroup: {
    minHeight: '150px',
  },
  contentWrapper: {
    flexGrow: 1,
    fontSize: fontSizing.body,
    overflowX: 'hidden',
    paddingLeft: 10,
    paddingRight: 10,
    position: 'relative',
  },
  image: {
    height: 120,
    padding: '15px 60px',
    textAlign: 'center',
  },
  instructions: {
    color: 'grey',
    fontSize: '1.2rem',
    margin: '20px 40px',
    textAlign: 'center',
  },
  nextButton: {
    bottom: 30,
    left: 30,
    position: 'absolute',
    right: 30,
  },
  pageIndicator: {
    fontSize: '1.4rem',
  },
  questionnaire: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    position: 'relative',
  },
  title: {
    color: 'black',
    fontSize: '1.5rem',
    fontWeight: 'strong',
    textTransform: 'uppercase',
  },
  bar: {
    backgroundColor: lightBrightBlue,
  },
};

function buildButtonGroup(props) {
  const buttonGroup = [];
  forEach(props.options, (options, index) => {
    buttonGroup.push(
      <ButtonSelectGroup
        {...props}
        groupIndex={index}
        options={props.options[index]}
        buttonStyles={props.buttonStyles}
        key={index}
      />
    );
  });
  return buttonGroup;
}

class ButtonPro extends Component {
  handleSubmission = () => {
    this.props.onSubmission();
  }

  render() {
    const {
      backgroundColor,
      currentPage,
      header,
      image: Image,
      onClickBackButton,
      title,
      totalPages,
      value,
      classes,
      showInstructions,
      imageStyles,
      titleStyles,
    } = this.props;

    const primaryBtnDisabled = !isNumber(value);
    const background = backgroundColor ? { backgroundColor } : {};
    const buttonGroup = buildButtonGroup(this.props);

    const appliedImageStyles = imageStyles || styles.image;
    const appliedTitleStyles = titleStyles || styles.title;

    return (
      <QuestionLayout
        primaryBtnDisabled={primaryBtnDisabled}
        primaryBtnLabel="Next"
        primaryBtnOnTouchTap={this.handleSubmission}
        style={background}
      >
        <AppBar
          backButtonOnClick={onClickBackButton}
          headerNode={header}
          rightNode={
            <div style={styles.pageIndicator}>{`${currentPage} of ${totalPages}`}</div>
          }
        />
        <LinearProgress
          variant="determinate"
          value={(currentPage / totalPages) * 100}
          classes={{ bar: classes.bar }}
        />
        <div style={styles.contentWrapper}>
          {showInstructions ? (
            <div style={styles.instructions}>
              {this.props.instructions || instructions}
            </div>
          ) : null}
          {this.props.image && (
            <div style={appliedImageStyles}>
              <Image />
            </div>
          )
          }
          <div style={styles.questionnaire}>
            <p style={appliedTitleStyles}>{title}</p>
            <div style={styles.buttonGroup}>{buttonGroup}</div>
          </div>
        </div>
      </QuestionLayout>
    );
  }
}

ButtonPro.defaultProps = {
  image: undefined,
  instructions: '',
  showInstructions: true,
  imageStyles: null,
  titleStyles: null,
  buttonStyles: {
    base: null,
    selected: null,
    unselected: null,
    buttonRow: null,
    buttonRowQuestion: null,
  },
};

ButtonPro.propTypes = {
  currentPage: PropTypes.number.isRequired,
  image: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  onClickBackButton: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmission: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  totalPages: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  instructions: PropTypes.string,
  showInstructions: PropTypes.bool,
  imageStyles: PropTypes.object,
  titleStyles: PropTypes.object,
  buttonStyles: PropTypes.object,
};

export default withStyles(styles)(ButtonPro);
