import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  TextField,
} from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { connect } from 'react-redux';

import Page from './page';
import AppBar from '../components/app-bar';
import IconWrapper from '../components/icon-wrapper';
import BloomkidsLayout from '../layouts/bloomkidz';
import { ClipboardListSolid } from '../lib/icons';
import { updatePRO } from '../state/pro-forms';
import { bloomkidzStylesObj } from '../lib/bloomkidz';
import { fontSizing } from '../lib/styles';

const styles = bloomkidzStylesObj;

class BloomkidzNewClient5 extends Page {
  constructor(props) {
    super(props);

    const stateFromRedux = props.bloomkidzNewClient[this.getTrackIndex()] || {};

    this.state = {
      movement: false,
      strength: false,
      speech: false,
      meetingMilestones: false,
      fineMotor: false,
      behavior: false,
      socialSkills: false,
      feedingEating: false,
      grossMotor: false,
      otherConcerns: false,
      listOfConcerns: '',
      ...stateFromRedux,
    };
  }

  handleChangeConcerns = (e) => {
    this.setState({ [e.target.name]: e.target.checked });
  };

  handleContinue = () => {
    const { location, updatePRO } = this.props;

    updatePRO({
      type: 'bloomkidzNewClient',
      position: this.getTrackIndex(),
      value: { ...this.state },
    });

    this.forwardWithQuery(location.query);
  }

  getContinueButtonText = () => {
    const { state } = this;

    const stateVals = Object.values(state);

    for (let i = 0; i < stateVals.length; i++) {
      if (stateVals[i] === true) return 'Next';
    }

    return 'Skip';
  }

  handleTextFieldChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  render() {
    const { classes, router } = this.props;
    const {
      movement,
      strength,
      speech,
      meetingMilestones,
      fineMotor,
      behavior,
      socialSkills,
      feedingEating,
      grossMotor,
      otherConcerns,
      listOfConcerns,
    } = this.state;

    return (
      <BloomkidsLayout
        primaryBtnOnTouchTap={this.handleContinue}
        primaryBtnLabel={this.getContinueButtonText()}
      >
        <AppBar
          headerNode="Habits"
          backButtonOnClick={() => router.goBack()}
        />
        <IconWrapper>
          <ClipboardListSolid />
        </IconWrapper>
        <div className={classes.pageContent}>
          <div>In what areas do you feel concerned about your child? <i>Check all that apply.</i></div>
          <FormGroup>
            <FormControlLabel
              control={(
                <Checkbox
                  checked={movement}
                  onChange={this.handleChangeConcerns}
                  name="movement"
                  icon={<CheckBoxOutlineBlankIcon />}
                  checkedIcon={<CheckBoxIcon />}
                  className={classes.checkbox}
                />
              )}
              label="Movement"
              classes={{
                label: classes.checkboxLabel,
                root: classes.formControlLabel,
              }}
            />
            <FormControlLabel
              control={(
                <Checkbox
                  checked={strength}
                  onChange={this.handleChangeConcerns}
                  name="strength"
                  icon={<CheckBoxOutlineBlankIcon />}
                  checkedIcon={<CheckBoxIcon />}
                  className={classes.checkbox}
                />
              )}
              label="Strength"
              classes={{
                label: classes.checkboxLabel,
                root: classes.formControlLabel,
              }}
            />
            <FormControlLabel
              control={(
                <Checkbox
                  checked={speech}
                  onChange={this.handleChangeConcerns}
                  name="speech"
                  icon={<CheckBoxOutlineBlankIcon />}
                  checkedIcon={<CheckBoxIcon />}
                  className={classes.checkbox}
                />
              )}
              label="Speech"
              classes={{
                label: classes.checkboxLabel,
                root: classes.formControlLabel,
              }}
            />
            <FormControlLabel
              control={(
                <Checkbox
                  checked={meetingMilestones}
                  onChange={this.handleChangeConcerns}
                  name="meetingMilestones"
                  icon={<CheckBoxOutlineBlankIcon />}
                  checkedIcon={<CheckBoxIcon />}
                  className={classes.checkbox}
                />
              )}
              label="Meeting Milestones"
              classes={{
                label: classes.checkboxLabel,
                root: classes.formControlLabel,
              }}
            />
            <FormControlLabel
              control={(
                <Checkbox
                  checked={fineMotor}
                  onChange={this.handleChangeConcerns}
                  name="fineMotor"
                  icon={<CheckBoxOutlineBlankIcon />}
                  checkedIcon={<CheckBoxIcon />}
                  className={classes.checkbox}
                />
              )}
              label="Fine Motor"
              classes={{
                label: classes.checkboxLabel,
                root: classes.formControlLabel,
              }}
            />
            <FormControlLabel
              control={(
                <Checkbox
                  checked={behavior}
                  onChange={this.handleChangeConcerns}
                  name="behavior"
                  icon={<CheckBoxOutlineBlankIcon />}
                  checkedIcon={<CheckBoxIcon />}
                  className={classes.checkbox}
                />
              )}
              label="Behavior"
              classes={{
                label: classes.checkboxLabel,
                root: classes.formControlLabel,
              }}
            />
            <FormControlLabel
              control={(
                <Checkbox
                  checked={socialSkills}
                  onChange={this.handleChangeConcerns}
                  name="socialSkills"
                  icon={<CheckBoxOutlineBlankIcon />}
                  checkedIcon={<CheckBoxIcon />}
                  className={classes.checkbox}
                />
              )}
              label="Social Skills"
              classes={{
                label: classes.checkboxLabel,
                root: classes.formControlLabel,
              }}
            />
            <FormControlLabel
              control={(
                <Checkbox
                  checked={feedingEating}
                  onChange={this.handleChangeConcerns}
                  name="feedingEating"
                  icon={<CheckBoxOutlineBlankIcon />}
                  checkedIcon={<CheckBoxIcon />}
                  className={classes.checkbox}
                />
              )}
              label="Feeding/Eating"
              classes={{
                label: classes.checkboxLabel,
                root: classes.formControlLabel,
              }}
            />
            <FormControlLabel
              control={(
                <Checkbox
                  checked={grossMotor}
                  onChange={this.handleChangeConcerns}
                  name="grossMotor"
                  icon={<CheckBoxOutlineBlankIcon />}
                  checkedIcon={<CheckBoxIcon />}
                  className={classes.checkbox}
                />
              )}
              label="Gross Motor"
              classes={{
                label: classes.checkboxLabel,
                root: classes.formControlLabel,
              }}
            />
            <FormControlLabel
              control={(
                <Checkbox
                  checked={otherConcerns}
                  onChange={this.handleChangeConcerns}
                  name="otherConcerns"
                  icon={<CheckBoxOutlineBlankIcon />}
                  checkedIcon={<CheckBoxIcon />}
                  className={classes.checkbox}
                />
              )}
              label="Other"
              classes={{
                label: classes.checkboxLabel,
                root: classes.formControlLabel,
              }}
            />
            {otherConcerns ? (
              <div
                className={classes.textFieldWrapper}
                style={{ marginTop: 0, paddingLeft: 33 }}
              >
                <TextField
                  placeholder="Please explain"
                  value={listOfConcerns}
                  onChange={this.handleTextFieldChange}
                  name="listOfConcerns"
                  variant="standard"
                  fullWidth
                  inputProps={{ style: { fontSize: fontSizing.body } }}
                />
              </div>
            ) : null}
          </FormGroup>
        </div>
      </BloomkidsLayout>
    );
  }
}

BloomkidzNewClient5.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  updatePRO: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const {
    proForms: { bloomkidzNewClient },
  } = state;

  return { bloomkidzNewClient };
};

export default connect(mapStateToProps, { updatePRO })(withStyles(styles)(BloomkidzNewClient5));
