import React, { Component } from 'react';
import Slider from 'react-rangeslider';
import { isNumber } from 'lodash';
import PropTypes from 'prop-types';
import { debounce, toUpper } from 'lodash';
import '../index.css';

class ReactSlider extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      horizontal: props.value,
    };
    // This needs to be debounced due to a bug in react-rangeslider
    // ref: https://github.com/whoisandy/react-rangeslider/issues/114
    this.handleChangeComplete = debounce(this.handleChangeComplete.bind(this), 20, { leading: false, trailing: true });
    this.handleShowHorizontal = this.handleShowHorizontal.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    const horizontal = nextProps.value;
    this.setState({ horizontal });
  }

  handleChangeHorizontal = (value) => {
    if (isNaN(value)) {
      value = this.props.min;
    }
    this.setState({
      horizontal: value,
    });
  };

  handleShowHorizontal() {
    let { horizontal } = this.state;
    let { handleLabel, min } = this.props;
    if (isNaN(horizontal)) {
      horizontal = min;
    }
    if (!isNumber(horizontal)) {
      handleLabel = 'Slide Me';
      return (`${handleLabel}`);
    }
    if (handleLabel === 'HRS') {
      if (horizontal === 1) {
        handleLabel = 'HR';
      }
      return (`${horizontal} ${handleLabel}`);
    }
    return horizontal;
  }

  handleChangeComplete() {
    const { horizontal } = this.state;
    if (isNumber(horizontal)) {
      this.props.onChangeComplete(horizontal);
    }
  }

  render() {
    const { horizontal } = this.state;
    const horizontalLabels = {
      1: this.props.minLabel,
      24: this.props.maxLabel,
    };
    const { showTooltip } = this.props;
    let displayedValue = horizontal;
    if (isNaN(horizontal)) {
      displayedValue = 0;
    }
    return (
      <div style={{ margin: '50px 0' }} className="slider custom-labels">
        <Slider
          min={this.props.min}
          max={this.props.max}
          value={displayedValue}
          labels={this.props.showLabels ? horizontalLabels : {}}
          handleLabel={toUpper(this.handleShowHorizontal())}
          onChange={this.handleChangeHorizontal}
          onChangeComplete={this.handleChangeComplete}
          className="react-slider"
          tooltip={showTooltip}
        />
      </div>
    );
  }
}

ReactSlider.defaultProps = {
  handleLabel: '',
  min: 0,
  max: 24,
  minLabel: '1 HR',
  maxLabel: '24 HRS',
  showTooltip: true,
  showLabels: true,
  value: null,
};

ReactSlider.propTypes = {
  handleLabel: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  minLabel: PropTypes.string,
  maxLabel: PropTypes.string,
  onChangeComplete: PropTypes.func.isRequired,
  showTooltip: PropTypes.bool,
  showlabels: PropTypes.bool,
  value: PropTypes.number,
};

export default ReactSlider;
