import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  TextField,
} from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { connect } from 'react-redux';

import Page from './page';
import AppBar from '../components/app-bar';
import IconWrapper from '../components/icon-wrapper';
import BloomkidzLayout from '../layouts/bloomkidz';
import { ClipboardListSolid } from '../lib/icons';
import { updatePRO } from '../state/pro-forms';
import { bloomkidzStylesObj } from '../lib/bloomkidz';
import { fontSizing } from '../lib/styles';

const styles = bloomkidzStylesObj;

const nameLabelMap = {
  frequentTantrums: 'Has frequent meltdowns/tantrums',
  frequentlyTripsOnFeet: 'Frequently trips on his/her own feet',
  walksOnToes: 'Walks on his/her toes',
  frequentlyBumpsIntoThings: 'Frequently bumps in furniture, walls, or other people',
  unawareOfTouchUnlessExtreme: 'Unaware of being touched or bumped unless done with extreme force',
  unawareOfDirtOnFaceOrHands: 'Unaware that face or hands are dirty (i.e., nose is running, food on face)',
  unsureOfBodyMovement: 'Seems unsure of how to move his/her body; is clumsy and awkward',
  slumpsOrSlouches: 'Slumps or slouches when sitting; places head on hand when sitting',
  difficultyLearningMotorTasks: 'Has difficulty learning new motor tasks',
  inConstantMotion: 'Is in constant motion',
  difficultySittingStill: 'Has difficulty sitting still',
  chewsOnThings: 'Chews on pens, straws, shirts, etc.',
  touchesPeopleAndObjects: 'Frequently touches people and objects',
  getsInOthersSpace: "Frequently gets in everyone else's space",
  sensitiveToStimuli: 'Is overly sensitive to touch, noise, smell, etc.',
  avoidsTouchingCertainTextures: 'Avoids touching certain textures',
  eatsCertainFoods: 'Only eats certain foods or food textures',
  sensitiveToClothing: 'Is sensitive to clothing tags or textures',
  complainsAboutHairBrushing: 'Complains about having hair brushed',
  resistsTeethBrushing: 'Resists having teeth brushed',
  fingernailTrimmingDislike: 'Does not like to have fingernails trimmed',
  noBarefootWalking: 'Refuses to walk barefoot',
  troubleWithSleep: 'Has trouble falling asleep or staying asleep',
  difficultyChangingTasks: 'Gets "stuck" on toy or task and has difficulty changing to another task',
  fearfulOnSwings: 'Is fearful on swings',
  fearfulOnPlaygroundThings: 'Is fearful of slide or other playground structures',
};

class BloomkidzNewClient7 extends Page {
  constructor(props) {
    super(props);

    const stateFromRedux = props.bloomkidzNewClient[this.getTrackIndex()] || {};

    this.state = {
      frequentTantrums: false,
      frequentlyTripsOnFeet: false,
      walksOnToes: false,
      frequentlyBumpsIntoThings: false,
      unawareOfTouchUnlessExtreme: false,
      unawareOfDirtOnFaceOrHands: false,
      unsureOfBodyMovement: false,
      slumpsOrSlouches: false,
      difficultyLearningMotorTasks: false,
      inConstantMotion: false,
      difficultySittingStill: false,
      chewsOnThings: false,
      touchesPeopleAndObjects: false,
      getsInOthersSpace: false,
      sensitiveToStimuli: false,
      avoidsTouchingCertainTextures: false,
      avoidsTouchingCertainTexturesList: '',
      eatsCertainFoods: false,
      eatsCertainFoodsList: '',
      sensitiveToClothing: false,
      complainsAboutHairBrushing: false,
      resistsTeethBrushing: false,
      fingernailTrimmingDislike: false,
      noBarefootWalking: false,
      difficultyChangingTasks: false,
      fearfulOnSwings: false,
      fearfulOnPlaygroundThings: false,
      ...stateFromRedux,
    };
  }

  handleChangeConcerns = (e) => {
    this.setState({ [e.target.name]: e.target.checked });
  };

  handleContinue = () => {
    const { location, updatePRO } = this.props;

    updatePRO({
      type: 'bloomkidzNewClient',
      position: this.getTrackIndex(),
      value: { ...this.state },
    });

    this.forwardWithQuery(location.query);
  }

  getContinueButtonText = () => {
    const { state } = this;

    const stateVals = Object.values(state);

    for (let i = 0; i < stateVals.length; i++) {
      if (stateVals[i] === true) return 'Next';
    }

    return 'Skip';
  }

  handleTextFieldChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  render() {
    const { classes, router } = this.props;
    const { state } = this;

    return (
      <BloomkidzLayout
        primaryBtnOnTouchTap={this.handleContinue}
        primaryBtnLabel={this.getContinueButtonText()}
      >
        <AppBar
          headerNode="Habits"
          backButtonOnClick={() => router.goBack()}
        />
        <IconWrapper>
          <ClipboardListSolid />
        </IconWrapper>
        <div className={classes.pageContent}>
          <div>Please check any statements that describe your child</div>
          <FormGroup>
            {Object.entries(nameLabelMap).map(([name, label]) => (
              <>
                <FormControlLabel
                  control={(
                    <Checkbox
                      checked={state[name]}
                      onChange={this.handleChangeConcerns}
                      name={name}
                      icon={<CheckBoxOutlineBlankIcon fontSize="large" />}
                      checkedIcon={<CheckBoxIcon fontSize="large" />}
                      className={classes.checkbox}
                    />
                  )}
                  label={label}
                  classes={{
                    label: classes.checkboxLabel,
                    root: classes.formControlLabel,
                  }}
                />
                {(name === 'avoidsTouchingCertainTextures' || name === 'eatsCertainFoods') && state[name] ? (
                  <div
                    className={classes.textFieldWrapper}
                    style={{ marginTop: 0, paddingLeft: 33 }}
                  >
                    <TextField
                      placeholder="Please list:"
                      value={state[`${name}List`]}
                      onChange={this.handleTextFieldChange}
                      name={`${name}List`}
                      variant="standard"
                      fullWidth
                      inputProps={{ style: { fontSize: fontSizing.body } }}
                    />
                  </div>
                ) : null}
              </>
            ))}
          </FormGroup>
        </div>
      </BloomkidzLayout>
    );
  }
}

BloomkidzNewClient7.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  updatePRO: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const {
    proForms: { bloomkidzNewClient },
  } = state;

  return { bloomkidzNewClient };
};

export default connect(mapStateToProps, { updatePRO })(withStyles(styles)(BloomkidzNewClient7));
