import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get, flatten } from 'lodash';

import { colors } from '../lib/styles';
import Page from './page';
import Question from '../components/question';
import { updatePRO, submitPRO } from '../state/pro-forms';

const options = [
  {
    label: 'Am completely unable to control urination.',
    value: 0,
  },
  {
    label: 'Have marked difficulty controlling urination.',
    value: 1,
  },
  {
    label: 'Have mild to moderate difficulty controlling urination.',
    value: 2,
  },
  {
    label: 'Have no difficulty controlling urination.',
    value: 3,
  },
];
const { questionBackground } = colors;

class Pmjoa4 extends Page {
  state = {
    submitting: false,
    id: {},
    user: {},
  }
  onClickBackButton = () => {
    this.backward();
  }
  onSubmission = () => {
    if (this.state.submitting) return;
    const data = flatten(this.props.pmjoa);
    const { user } = this.props;
    const { id } = this.props.location.query;
    this.setState({ submitting: true });

    submitPRO({ userId: user.id, id }, {
      pro_type: `pmjoa`,
      pro_data: { data },
    }, user)
      .then(() => this.forward())
      .catch(() => this.setState({ submitting: false }));
  }

  render() {
    const value = get(this.props.pmjoa, this.getTrackIndex(), [null, null, null, null]);
    return (
      <Question
        backgroundColor={questionBackground}
        currentPage={this.getCurrentPage()}
        onClickBackButton={() => this.props.router.goBack()}
        onChange={this.props.updatePRO}
        onSubmission={this.onSubmission}
        options={options}
        title="Sphincter Dysfunction"
        totalPages={this.getTotalPages()}
        trackIndex={this.getTrackIndex()}
        type="pmjoa"
        value={value}
      />
    );
  }
}


function mapStateToProps(state) {
  const { proForms: { pmjoa }, user } = state;
  return { pmjoa, user };
}

Pmjoa4.propTypes = {
  pmjoa: PropTypes.array.isRequired,
  updatePRO: PropTypes.func.isRequired,
  submitPRO: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { updatePRO, submitPRO })(Pmjoa4);
