import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get, includes } from 'lodash';

import { colors } from '../lib/styles';
import { PainInterference } from '../lib/icons';
import Page from './page';
import ButtonPRO from '../components/button-pro';
import { updatePRO } from '../state/pro-forms';

const buttonLabels = ['Not at all', 'A little bit', 'Somewhat', 'Quite a bit', 'Very Much'];

const options = [
  {
    question: 'How much did pain interfere with your day to day activities?',
    label: buttonLabels,
  },
  {
    question: 'How much did pain interfere with work around the home?',
    label: buttonLabels,
  },
  {
    question: 'How much did pain interfere with your ability to participate in social activities?',
    label: buttonLabels,
  },
  {
    question: 'How much did pain interfere with your household chores?',
    label: buttonLabels,
  },
];
const { questionBackground } = colors;

class Promis29PainInterference extends Page {
  onClickBackButton = () => {
    this.backward();
  }

  onSubmission = () => {
    this.forwardWithQuery(this.props.location.query);
  }

  render() {
    const page = get(this.props.promis29, this.getTrackIndex(), [null, null, null, null]);
    const value = includes(page, null) ? null : 0;
    return (
      <ButtonPRO
        backgroundColor={questionBackground}
        currentPage={this.getCurrentPage()}
        image={PainInterference}
        onClickBackButton={() => this.props.router.goBack()}
        onChange={this.props.updatePRO}
        onSubmission={this.onSubmission}
        options={options}
        title="In the past 7 days:"
        header="Pain Interference"
        totalPages={this.getTotalPages()}
        trackIndex={this.getTrackIndex()}
        value={value}
        pro={page}
        type="promis29"
      />
    );
  }
}

function mapStateToProps(state) {
  const { proForms: { promis29 } } = state;
  return { promis29 };
}

Promis29PainInterference.propTypes = {
  promis29: PropTypes.array.isRequired,
  updatePRO: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { updatePRO })(Promis29PainInterference);
