import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import Page from './page';
import { colors } from '../lib/styles';
import AppBar from '../components/app-bar';
import Continue from '../components/continue';
import { updatePRO } from '../state/pro-forms';
import QuestionLayout from '../layouts/question';
import brand from '../lib/brand';
import LinearProgress from '../components/linear-progress';

const { lightBrightBlue, questionBackground } = colors;
const styles = {
  date: {
    width: '150px',
    alignSelf: 'center',
    margin: '20px',
  },
  instructions: {
    fontSize: '1.3rem',
  },
  image: {
    height: 120,
    padding: '15px 60px',
    textAlign: 'center',
  },
  linearProgress: {
    borderRadius: 0,
  },
  pageIndicator: {
    color: 'grey',
    display: 'inline-block',
    fontSize: 13,
  },
  questionnaire: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    position: 'relative',
    minHeight: '350px',
  },
  radioButton: {
    color: colors.black,
    fontSize: '1.2rem',
    marginBottom: '10px',
  },
  radioButtonGroup: {
    margin: '0 auto',
    width: '200px',
  },
  title: {
    color: 'black',
    fontSize: '1.5rem',
    fontWeight: 800,
    textTransform: 'uppercase',
    alignSelf: 'center',
  },
  titleGroup: {
    margin: 25,
    textAlign: 'center',
  },
  error: {
    color: colors.errorRed,
    alignSelf: 'center',
  },
  text: {
    fontSize: '1.25rem',
  },
};
const PrgEndo1PartOne = (props) => {
  return (
    <React.Fragment>
      <div style={styles.questionnaire}>
        <div style={styles.titleGroup}>
          <p style={styles.text}>Have you been diagnosed with endometriosis in the last 10 years?</p>
        </div>
        <Continue
          text="No"
          onClick={props.onClickNo}
          btnStyle={{ marginTop: '50px', alignSelf: 'center' }}
        />
        <Continue
          text="Yes"
          onClick={props.onClickYes}
          btnStyle={{ marginTop: '50px', alignSelf: 'center' }}
        />
      </div>
    </React.Fragment>
  );
}

const PrgEndo1PartTwo = (props) => {
  return (
    <React.Fragment>
      <div style={styles.questionnaire}>
        <div style={styles.titleGroup}>
          <p style={styles.text}>Which procedure was used to diagnose your endometriosis?</p>
        </div>
        <RadioGroup
          name="meds"
          onChange={props.onChange}
          valueSelected={props.value}
          style={styles.radioButtonGroup}
        >
          <FormControlLabel
            value="Laparotomy"
            control={<Radio />}
            label="Laparotomy"
          />
          <FormControlLabel
            value="Laparoscopy"
            control={<Radio />}
            label="Laparoscopy"
          />
          <FormControlLabel
            value="Other"
            control={<Radio />}
            label="Other"
          />
        </RadioGroup>
        <Continue
          disabled={!Boolean(props.value)}
          text="Continue"
          onClick={props.onClickContinue}
          btnStyle={{ marginTop: '50px', alignSelf: 'center' }}
        />
      </div>
    </React.Fragment>

  );
}

class PRGEndo1 extends Page {
  state = {
    partTwo: false,
    procedure: null,
  }

  handleClickYes = () => {
    this.setState({ partTwo: true });
  }

  handleClickNo = () => {
    this.props.updatePRO({ type: 'prgendo', position: 0, value: [false, null] });
    this.forwardWithQuery(this.props.location.query);
  }

  handleUpdateProcedure = (e, procedure) => {
    this.setState({ procedure });
  }

  handleClickContinue = () => {
    this.props.updatePRO({ type: 'prgendo', position: 0, value: [true, this.state.procedure] });
    this.forwardWithQuery(this.props.location.query);
  }

  render() {
    const totalPages = this.getTotalPages();
    const currentPage = this.getCurrentPage();

    let displayedPage = (
      <PrgEndo1PartOne
        onClickYes={this.handleClickYes}
        onClickNo={this.handleClickNo}
      />
    );

    if (this.state.partTwo) {
      displayedPage = (
        <PrgEndo1PartTwo
          value={this.state.procedure}
          onChange={this.handleUpdateProcedure}
          onClickContinue={this.handleClickContinue}
        />
      )
    }

    return (
      <QuestionLayout
        style={{ background: questionBackground }}
      >
        <AppBar
          backButtonOnClick={() => this.props.router.goBack()}
          headerNode="Patient Survey"
          rightNode={
            <div style={styles.pageIndicator}>{`${currentPage} of ${totalPages}`}</div>
          }
        />
        <LinearProgress
          color={lightBrightBlue}
          mode="determinate"
          value={(currentPage / totalPages) * 100}
          style={styles.linearProgress}
        />
        <div style={styles.image}>
          <img style={styles.image} src={`/img/${brand.name}/${brand.loginLogo}`} alt="Informed Logo" />
        </div>
        {displayedPage}
      </QuestionLayout>
    );
  }
}


function mapStateToProps(state) {
  const { user } = state;
  return { user };
}

PRGEndo1.proptypes = {
  user: PropTypes.object.isRequired,
  route: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, { updatePRO })(PRGEndo1);
