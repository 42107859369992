import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { noop } from 'lodash';

import AppBar from '../components/app-bar';
import Page from './page';
import { signup } from '../state/user';
import { addPersonalInfo } from '../state/personal-info';
import Continue from '../components/continue';
import { custom, colors } from '../lib/styles';

const baseStyles = {
  btn: {
    backgroundColor: colors.secondaryColor,
    position: 'absolute',
    bottom: '18rem',
    height: '5rem',
  },
  center: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  hana: {
    height: '20rem',
    width: '20rem',
    marginTop: '4rem',
  },
  blueCircle: {
    borderRadius: '50%',
    width: '10.375rem',
    height: '10.4375rem',
    backgroundColor: colors.imageBlue,
    position: 'absolute',
    right: -70,
    top: '40%',
  },
  label: {
    fontSize: '1.4rem',
  },
  limeCircle: {
    borderRadius: '50%',
    width: '7.9rem',
    height: '7.9rem',
    backgroundColor: colors.imageGreen,
    position: 'absolute',
    left: -50,
    top: '50%',
  },
  purpleCircle: {
    borderRadius: '50%',
    width: '22.5rem',
    height: '22.5rem',
    backgroundColor: colors.primaryColor,
    position: 'absolute',
    bottom: -145,
    left: -40,
    zIndex: -1,
  },
  container: {
    marginTop: '3.125rem',
  },
  email: {
    fontSize: '1.4rem',
    color: colors.primaryColor,
    marginTop: '13px',
  },
  p: {
    fontSize: '1.8rem',
    margin: '15px 85px',
    color: colors.theBetterWayBackGreyText,
  },
  subheader: {
    fontSize: '1.5rem',
  },
};

class EmailConfirmation extends Page {
  render() {
    return (
      <div>
        <AppBar
          backButtonOnClick={() => this.backward()}
          headerNode="Registration"
        />
        <section style={baseStyles.center}>
          <img
            src="/img/logo.svg"
            alt="Hana Logo"
            style={baseStyles.hana}
          />
          <div style={baseStyles.container}>
            <section>
              <div style={custom.signupHeaderText}>
                <p style={baseStyles.p}>
                  Register via Email to get started
                </p>
              </div>
            </section>
          </div>
          <Continue
            btnStyle={baseStyles.btn}
            labelStyle={baseStyles.label}
            text="Email Address"
            onClick={this.forward}
          />
        </section>
        <div style={baseStyles.blueCircle} />
        <div style={baseStyles.limeCircle} />
        <div style={baseStyles.purpleCircle} />
      </div>
    );
  }
}

function mapStateToProps() {
  return {};
}

EmailConfirmation.defaultProps = {
  signup: {},
  router: noop,
};

EmailConfirmation.propTypes = {
  signup: PropTypes.object,
  router: PropTypes.func,
  personalInfo: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};


export default connect(mapStateToProps, { signup, addPersonalInfo })(EmailConfirmation);
