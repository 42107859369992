import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import {
  Checkbox,
  FormControlLabel,
  TextField,
} from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import Page from './page';
import AppBar from '../components/app-bar';
import BloomkidzLayout from '../layouts/bloomkidz';
import { updatePRO } from '../state/pro-forms';
import { bloomkidzStylesObj, bloomkidzStyles } from '../lib/bloomkidz';
import { fontSizing } from '../lib/styles';

const styles = {
  ...bloomkidzStylesObj,
  centerText: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
  },
  emphasis: {
    fontWeight: 600,
  },
  spacing: {
    margin: '20px 0 10px',
  },
};

class BloomkidzSignatures5 extends Page {
  constructor(props) {
    super(props);

    const stateFromRedux = props.bloomkidzSignatures[this.getTrackIndex()] || {};

    this.state = {
      hipaaAcknowledgement2: false,
      hipaaGuardian2: '',
      hipaaRelationship2: '',
      ...stateFromRedux,
    };
  }

  handleCheck = (e) => {
    this.setState({ [e.target.name]: e.target.checked });
  };

  handleTextFieldChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleContinue = () => {
    const { location, updatePRO } = this.props;

    updatePRO({
      type: 'bloomkidzSignatures',
      position: this.getTrackIndex(),
      value: { ...this.state },
    });

    this.forwardWithQuery(location.query);
  }

  render() {
    const { classes, router } = this.props;
    const { hipaaAcknowledgement2, hipaaGuardian2, hipaaRelationship2 } = this.state;

    const continueDisabled = !hipaaAcknowledgement2 || !hipaaGuardian2 || !hipaaRelationship2;

    return (
      <BloomkidzLayout
        primaryBtnOnTouchTap={this.handleContinue}
        primaryBtnLabel="Next"
        primaryBtnDisabled={continueDisabled}
      >
        <AppBar
          headerNode="Signature"
          backButtonOnClick={() => router.goBack()}
        />
        <div className={classes.pageContent}>
          <div className={`${classes.emphasis} ${classes.spacing}`}>Patient Consent for Use and Disclosure of Protected Health Information</div>
          <div>
            I hereby give my consent for BloomKidz to use and disclose my child&apos;s protected health information (PHI) to perform treatment, payment and health care operations (TPO). With this consent, BloomKidz may call me, text me or email me and leave a message by voice, text, or email in reference to any items that assist the BloomKidz in carrying out TPO, such as appointment reminders, insurance items and anything pertaining to my child&apos;s clinical care.
          </div>
          <div>
            With this consent, the BloomKidz may mail to my home or other alternative location any items that assist the practice in performing TPO, such as appointment reminder cards, patient statements and anything pertaining to my clinical care as long as they are marked &ldquo;Personal and Confidential.&rdquo;
          </div>
          <FormControlLabel
            control={(
              <Checkbox
                checked={hipaaAcknowledgement2}
                onChange={this.handleCheck}
                name="hipaaAcknowledgement2"
                icon={<CheckBoxOutlineBlankIcon />}
                checkedIcon={<CheckBoxIcon />}
                className={classes.checkbox}
              />
            )}
            label="By signing this form, I am consenting to allow BloomKidz to use and disclose my PHI to carry out TPO. I may revoke my consent in writing except to the extent that BloomKidz has already made disclosures upon my prior consent. If I do not sign this consent, or later revoke it, BloomKidz may decline to provide treatment."
            classes={{
              label: classes.checkboxLabel,
              root: classes.formControlLabel,
            }}
          />
          <div className={`${classes.emphasis} ${classes.spacing}`}>Parent/Guardian Printed Name</div>
          <div className={classes.paddingHorizontal}>
            <div className={classes.textFieldWrapper}>
              <TextField
                placeholder="Full Name"
                value={hipaaGuardian2}
                onChange={this.handleTextFieldChange}
                name="hipaaGuardian2"
                variant="standard"
                fullWidth
                inputProps={{ style: { fontSize: fontSizing.body, fontFamily: bloomkidzStyles.fontFamily } }}
              />
            </div>
          </div>
          <div className={`${classes.emphasis} ${classes.spacing}`}>Relationship to the Client</div>
          <div className={classes.paddingHorizontal}>
            <div className={classes.textFieldWrapper}>
              <TextField
                placeholder="Relationship to client"
                value={hipaaRelationship2}
                onChange={this.handleTextFieldChange}
                name="hipaaRelationship2"
                variant="standard"
                fullWidth
                inputProps={{ style: { fontSize: fontSizing.body, fontFamily: bloomkidzStyles.fontFamily } }}
              />
            </div>
          </div>
        </div>
      </BloomkidzLayout>
    );
  }
}

BloomkidzSignatures5.propTypes = {
  classes: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  updatePRO: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const {
    proForms: { bloomkidzSignatures },
  } = state;

  return { bloomkidzSignatures };
};

export default connect(mapStateToProps, { updatePRO })(withStyles(styles)(BloomkidzSignatures5));
