/* eslint-disable */
import React from 'react';
import { Link } from 'react-router';

const styles = {
  container: {
    textAlign: 'left',
  },
  header: {
    textAlign: 'center',
  },
  underlined: {
    textDecoration: 'underline',
  },
}

const Eula = () => (
  <div style={styles.container}>
    <div style={styles.header}>
      <h3>End User License Agreement</h3>
      <h3>Eula</h3>

      <h3>Mindset Medical Informed TM</h3>
      </div>

      <p>This End User License Agreement ("<b>Agreement</b>") is a binding agreement between you ("<b>End User</b>" or "<b>you</b>") and MINDSET MEDICAL LLC, an Arizona limited liability company ("Mindset"). This Agreement governs your use of Mindset’s Informed mobile application (including all related documentation, collectively the "<b>Application</b>"). The Application is a universal patient engagement platform which connects your personal health information (“<b>PHI</b>”) to your healthcare provider. The Application is intended to allow you to report, store, update, and maintain your PHI and you, the patient, must ensure that the PHI is current, accurate and up-to-date. You can then carry your PHI with you and share it digitally with any participating healthcare provider at any time. Using the Application, you are required to enter your PHI once and after that only when it changes or as requested by your healthcare provider. You inform the healthcare provider about your health so that the healthcare provider can efficiently provide care as needed. You control your PHI because nobody knows or cares more about it than you do. </p>

      <p>BY CLICKING THE "AGREE" BUTTON OR OTHERWISE USING THE APPLICATION, YOU (A) ACKNOWLEDGE THAT YOU HAVE READ AND UNDERSTAND THIS AGREEMENT; (B) REPRESENT THAT YOU ARE 18 YEARS OF AGE OR OLDER; (C) UNDERSTAND THAT THE APPLICTION IS LICENSED, NOT SOLD,TO YOU; AND (D) ACCEPT THIS AGREEMENT AND AGREE THAT YOU ARE LEGALLY BOUND BY ITS TERMS. IF YOU DO NOT AGREE TO THESE TERMS, DO NOT USE THE APPLICATION AND DELETE IT FROM YOUR MOBILE DEVICE.<p>

        <p>YOU EXPRESSLY CONSENT TO MINDSET CONTACTING YOU DIRECTLY BY PHONE, TEXT MESSAGE, E-MAIL OR OTHERWISE THROUGH THE APPLICATION IN ORDER TO PROVIDE YOU WITH THE APPLICATION’S FEATURES, FUNCTIONALITY, AND SERVICES (COLLECTIVELY, THE “SERVICES”) DESCRIBED IN THIS AGREEMENT.</p>

        <p>YOU ACKNOWLEDGE THAT A HEALTHCARE PROVIDER’S ABILITY TO USE OUR APPLICATION OR SERVICES DOES NOT CONSTITUTE A RECOMMENDATION OR ENDORSEMENT OF THAT HEALTHCARE PROVIDER BY MINDSET. FURTHER, IF ANY MEDICAL ADVICE, DIAGNOSIS, OR TREATMENT IS PROVIDED TO YOU THROUGH THE APPLICATION, SUCH MEDICAL ADVICE, DIAGNOSIS, OR TREATMENT ORIGINATES FROM YOUR HEALTHCARE PROVIDER AND NOT MINDSET. YOU AGREE AND ACKNOWLEDGE THAT ALL MEDICAL ADVICE, DIAGNOSIS, OR TREATMENT IS PROVIDED SOLELY BY YOUR HEALTHCARE PROVIDER AND THAT MINDSET IS NOT LIABLE IN ANY CAPACITY FOR ANY MALPRACTICE OR SUBSTANDARD CARE RECEIVED FROM SUCH HEALTHCARE PROVIDER. </p>

        <p>THE APPLICATION IS NOT APPROPRIATE FOR USE IN EMERGENCIES OR ANY SITUATION IN WHICH THE FAILURE OF THE APPLICATION COULD LEAD TO DEATH OR BODILY INJURY OF ANY TYPE. IF YOU THINK YOU ARE EXPERIENCING A MEDICAL EMERGENCY, CALL 911 OR SEEK IMMEDIATE MEDICAL ATTENTION. YOU ARE SOLELY RESPONSIBLE FOR USING THE APPLICATION IN A MANNER WHICH IS CONSISTENT WITH ALL APPLICABLE LAWS.</p>
      </p>
      </p>


       <p><strong><span style={styles.underlined}>License Grant.</span></strong> Subject to the terms of this Agreement, Mindset grants you a limited, non-exclusive and nontransferable license to:
        (a)  download, install and use the Application for your personal, non-commercial use on a single mobile device owned or otherwise controlled by you ("<b>Mobile Device</b>") strictly in accordance with the Application's documentation; and
        (b) access, stream, download and use on such Mobile Device the Content and Services (as defined in <b>Section 6</b>) made available in or otherwise accessible through the Application, strictly in accordance with this Agreement and the Terms of Use applicable to such Content and Services as set forth in <b>Section 6</b>.</p>
       <p><strong><span style={styles.underlined}>License Restrictions.</span></strong> You shall not:
          (a) copy the Application, except as expressly permitted by this license;
          (b) modify, translate, adapt or otherwise create derivative works or improvements, whether or not patentable, of the Application;
          (c) reverse engineer, disassemble, decompile, decode or otherwise attempt to derive or gain access to the source code of the Application or any part thereof;
          (d) remove, delete, alter or obscure any trademarks or any copyright, trademark, patent or other intellectual property or proprietary rights notices from the Application, including any copy thereof;
          (e) rent, lease, lend, sell, sublicense, assign, distribute, publish, transfer or otherwise make available the Application or any features or functionality of the Application, to any third party for any reason, including by making the Application available on a network where it is capable of being accessed by more than one device at any time; or
        (f) remove, disable, circumvent or otherwise create or implement any workaround to any copy protection, rights management or security features in or protecting the Application. </p>
      <p><strong><span style={styles.underlined}>Third Party Terms and Conditions.</span></strong>

        All Apple iOS-powered mobile device users agree and acknowledge that:
        
This Agreement is between you and Mindset, and not with Apple, Inc. or its affiliated entities (“<b>Apple</b>”);
        
        Your use of the Application must comply with Apple’s then-current Terms of Service found in the Apple App Store;
        
        Mindset, not Apple, is solely responsible for the Application and all services, features, functionality and content accessible through the Application;
        
        Apple has no obligation to provide maintenance or support services with respect to the Application;
        
        To the maximum extent permitted under applicable law, Apple will have no warranty obligations with respect to the Application;
        
        Mindset, and not Apple, is responsible for addressing any claims by you or any third party relating to the Application or your use of the Application, including, but not limited to: (a) product liability claims, (b) any claim that the Application fails to meet any regulatory or other legal requirement, and (c) claims arising under consumer protection or similar legislation, and all such claims are governed by this Agreement and any law applicable to Mindset as a provider of the Application;
        
        In the event of any third party claim that the Application or your use of the Application infringes upon any third party’s intellectual property rights, you or Mindset, as the case may be, and not Apple, will be solely responsible for the investigation, defense, settlement and discharge of any such intellectual property claim;
        
        You are not located in a country that is subject to a U.S. Government embargo or that has been designated by the U.S. Government as a “terrorist supporting” country and you are not listed on any U.S. Government list of prohibited or restricted parties;
        
        You will comply with applicable third party terms of agreement when using the Application; and
        
        Apple, and Apple’s subsidiaries, are third party beneficiaries of this Agreement, and that, upon your acceptance of the terms and conditions of this Agreement, Apple will have the right (and will be deemed to have accepted the right) to enforce the Agreement against you as a third party beneficiary thereof.
        
        All Android-powered mobile device users agree and acknowledge that:
        
This Agreement is between you and Mindset, and not with Google, Inc. or its affiliated entities (“<b>Google</b>”);
        
        Your use of the Application must comply with Google’s then-current Terms of Service found in the Google Play Store;
        
        Google is only a provider of the Google Play Store where you obtained the Application. Mindset, and not Google, is solely responsible for its obligations under this Agreement, which may include services, features, functionality and content you access through the Application;
        
        Google has no obligation or liability to you with respect to the Application or this Agreement; and
        
Google is a third-party beneficiary of this Agreement with regards to your use of the Application.</p>


      <p><strong><span style={styles.underlined}>Reservation of Rights.</span></strong> You acknowledge and agree that the Application is provided under license, and not sold, to you. You do not acquire any ownership interest in the Application under this Agreement, or any other rights thereto other than to use the Application in accordance with the license granted, and subject to all terms, conditions and restrictions, under this Agreement. Mindset and its licensors and service providers reserve and shall retain their entire right, title and interest in and to the Application, including all copyrights, trademarks and other intellectual property rights therein or relating thereto, except as expressly granted to you in this Agreement.
      
      <span style={styles.underlined}>Collection and Use of Your Information</span> Protected Health Information. You acknowledge that when you download, install or use the Application, Mindset may use automatic means (including, for example, cookies and web beacons) to collect information about your Mobile Device and about your use of the Application. You also may be required to provide certain information about yourself as a condition to downloading, installing or using the Application or certain of its features or functionality, and the Application may provide you with opportunities to share information about yourself with others. All information we collect through or in connection with this Application is subject to our Privacy Policy <span ><Link to="https://patient.mindsetmedical.com/about/privacy">[PRIVACY POLICY LINK].</Link></span> By downloading, installing, using and providing information to or through this Application, you consent to all actions taken by us with respect to your information in compliance with the Privacy Policy.</p>
      <p>Mindset is required to protect the privacy of your health information as well as information that could be used to identify you. PHI includes, among other things, your name, address, date of birth, and medical information. Mindset employs encryption and other safeguards to protect your PHI, whether provided by you or your healthcare provider, via the Application or the Website (defined below).</p>




      <p>By accepting the terms of this Agreement, you authorize Mindset to utilize your PHI, as well as any other information provided to Mindset in connection with your use of the Application or the Website, to collect, use, and disclose your PHI and other information as necessary to provide the Services (as defined in <b>Section 6</b>) in accordance with our Privacy Policy<span ><Link to="https://patient.mindsetmedical.com/about/privacy">[PRIVACY POLICY LINK]</Link></span> and applicable state and federal regulations. </p>
      <p><strong><span style={styles.underlined}>Website Content and Services.</span></strong> The Application may provide you with access to Mindset's website located at Mindsetmedical.com (the "<b>Website</b>") and services accessible thereon, and certain features, functionality and content accessible on or through the Application may be hosted on the Website (collectively, "<b>Content and Services</b>"). Your access to and use of such Content and Services are governed by Website's Terms of Use and Privacy Policy located at  <span ><Link to="https://mindsetmedical.com/termsofuse/">[TERMS OF USE LINK]</Link></span> and  <span ><Link to="https://patient.mindsetmedical.com/about/privacy">[PRIVACY POLICY LINK].</Link></span>, which are incorporated herein by this reference. Your access to and use of such Content and Services may require you to acknowledge your acceptance of such Terms of Use and Privacy Policy and/or to register with the Website and your failure to do so may restrict you from accessing or using certain of the Application's features and functionality. Any violation of such Terms of Use will also be deemed a violation of this Agreement.</p>


      <p><strong><span style={styles.underlined}>Jurisdictional Compliance.</span> </strong>The Application is based in the state of Arizona in the United States and is intended for access and use by persons located in the United States. You acknowledge that you may not be able to access the Application outside of the United States and that access thereto may not be legal by certain persons or in certain countries. If you access the Application from outside the United States, you are responsible for compliance with local laws.</p>
      <p><strong><span style={styles.underlined}>Updates.</span></strong> Mindset may from time to time in its sole discretion develop and provide Application updates, which may include upgrades, bug fixes, patches and other error corrections and/or new features (collectively, including related documentation, "<b>Updates</b>"). Updates may also modify or delete in their entirety certain features and functionality. You agree that Mindset has no obligation to provide any Updates or to continue to provide or enable any particular features or functionality. Based on your Mobile Device settings, when your Mobile Device is connected to the internet either:
      
      the Application will automatically download and install all available Updates; or
      
      you may receive notice of or be prompted to download and install available Updates.
      
You shall promptly download and install all Updates and acknowledge and agree that the Application or portions thereof may not properly operate should you fail to do so. You further agree that all Updates will be deemed part of the Application and be subject to all terms and conditions of this Agreement.</p>

      <p><span style={styles.underlined}>Third Party Materials.</span> The Application may display, include or make available third-party content (including data, information, applications and other products services and/or materials) or provide links to third-party websites or services, including through third-party advertising ("<b>Third Party Materials</b>"). You acknowledge and agree that Mindset is not responsible for Third Party Materials, including their accuracy, completeness, timeliness, validity, copyright compliance, legality, decency, quality or any other aspect thereof. Mindset does not assume and will not have any liability or responsibility to you or any other person or entity for any Third Party Materials. Third Party Materials and links thereto are provided solely as a convenience to you and you access and use them at entirely at your own risk and subject to such third parties' terms and conditions. You acknowledge and understand that any information or PHI provided to third parties might not be subject to the same state and federal regulations governing your information or PHI. Mindset does not endorse any Third Party Materials advertised on the Application or its Website.</p>
      <p><strong><span style={styles.underlined}>Term and Termination.</span></strong>

        The term of Agreement commences when you download the Application and will continue in effect until terminated by you or Mindset as set forth in this Section 10.
        
        You may terminate this Agreement by deleting the Application and all copies thereof from your Mobile Device.
        
        Mindset may terminate this Agreement at any time without notice if it ceases to support the Application, which Mindset may do in its sole discretion. In addition, this Agreement will terminate immediately and automatically without any notice if you violate any of the terms and conditions of this Agreement.
        
        Upon termination:
        
        all rights granted to you under this Agreement will also terminate;
        
        Mindset will either return or permanently destroy any copies it maintains of your PHI in accordance with its obligations under federal or state law; and
        
        you must cease all use of the Application and delete all copies of the Application from your Mobile Device and account.
        
Termination will not limit any of Mindset's rights or remedies at law or in equity.</p>
      <p><strong><span style={styles.underlined}>Disclaimer of Warranties.</span></strong> THE APPLICATION IS PROVIDED TO LICENSEE "AS IS" AND WITH ALL FAULTS AND DEFECTS WITHOUT WARRANTY OF ANY KIND. TO THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW, MINDSET, ON ITS OWN BEHALF AND ON BEHALF OF ITS AFFILIATES AND ITS AND THEIR RESPECTIVE LICENSORS AND SERVICE PROVIDERS, EXPRESSLY DISCLAIMS ALL WARRANTIES, WHETHER EXPRESS, IMPLIED, STATUTORY OR OTHERWISE, WITH RESPECT TO THE APPLICATION, INCLUDING ALL IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT, AND WARRANTIES THAT MAY ARISE OUT OF COURSE OF DEALING, COURSE OF PERFORMANCE, USAGE OR TRADE PRACTICE. WITHOUT LIMITATION TO THE FOREGOING, MINDSET PROVIDES NO WARRANTY OR UNDERTAKING, AND MAKES NO REPRESENTATION OF ANY KIND THAT THE APPLICATION WILL MEET YOUR REQUIREMENTS, ACHIEVE ANY INTENDED RESULTS, BE COMPATIBLE OR WORK WITH ANY OTHER SOFTWARE, APPLICATIONS, SYSTEMS OR SERVICES, OPERATE WITHOUT INTERRUPTION, MEET ANY PERFORMANCE OR RELIABILITY STANDARDS OR BE ERROR FREE OR THAT ANY ERRORS OR DEFECTS CAN OR WILL BE CORRECTED.</p>

      <p>SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF OR LIMITATIONS ON IMPLIED WARRANTIES OR THE LIMITATIONS ON THE APPLICABLE STATUTORY RIGHTS OF A CONSUMER, SO SOME OR ALL OF THE ABOVE EXCLUSIONS AND LIMITATIONS MAY NOT APPLY TO YOU. </p>
      <p><strong><span style={styles.underlined}>Limitation of Liability.</span></strong> TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL MINDSET OR ITS AFFILIATES, OR ANY OF ITS OR THEIR RESPECTIVE LICENSORS OR SERVICE PROVIDERS, HAVE ANY LIABILITY ARISING FROM OR RELATED TO YOUR USE OF OR INABILITY TO USE THE APPLICATION OR THE CONTENT AND SERVICES FOR:
      
      PERSONAL INJURY, PROPERTY DAMAGE, LOST PROFITS, COST OF SUBSTITUTE GOODS OR SERVICES, LOSS OF DATA, LOSS OF GOODWILL, BUSINESS INTERRUPTION, COMPUTER FAILURE OR MALFUNCTION OR ANY OTHER CONSEQUENTIAL, INCIDENTAL, INDIRECT, EXEMPLARY, SPECIAL OR PUNITIVE DAMAGES
      
DIRECT DAMAGES IN AMOUNTS THAT IN THE AGGREGATE EXCEED THE AMOUNT ACTUALLY PAID BY YOU FOR THE APPLICATION. </p>
      <p>THE FOREGOING LIMITATIONS WILL APPLY WHETHER SUCH DAMAGES ARISE OUT OF BREACH OF CONTRACT, TORT (INCLUDING NEGLIGENCE) OR OTHERWISE AND REGARDLESS OF WHETHER SUCH DAMAGES WERE FORESEEABLE OR MINDSET WAS ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. SOME JURISDICTIONS DO NOT ALLOW CERTAIN LIMITATIONS OF LIABILITY SO SOME OR ALL OF THE ABOVE LIMITATIONS OF LIABILITY MAY NOT APPLY TO YOU.</p>
      <p><strong><span style={styles.underlined}>Indemnification.</span></strong> You agree to indemnify, defend and hold harmless Mindset and its officers, directors, employees, agents, affiliates, successors and assigns from and against any and all losses, damages, liabilities, deficiencies, claims, actions, judgments, settlements, interest, awards, penalties, fines, costs, or expenses of whatever kind, including attorneys' fees, arising from or relating to your use or misuse of the Application or your breach of this Agreement. Furthermore, you agree that Mindset assumes no responsibility for the content you submit or make available through this Application.</p>

      <p><strong><span style={styles.underlined}>Export Regulation.</span></strong> The Application may be subject to US export control laws, including the US Export Administration Act and its associated regulations. You shall not, directly or indirectly, export, re-export or release the Application to, or make the Application accessible from, any jurisdiction or country to which export, re-export or release is prohibited by law, rule or regulation. You shall comply with all applicable federal laws, regulations and rules, and complete all required undertakings (including obtaining any necessary export license or other governmental approval), prior to exporting, re-exporting, releasing or otherwise making the Application available outside the US.</p>

      <p><strong><span style={styles.underlined}>US Government Rights.</span></strong> The Application is commercial computer software, as such term is defined in 48 C.F.R. §2.101. Accordingly, if you are an agency of the US Government or any contractor therefor, you receive only those rights with respect to the Application as are granted to all other end users under license, in accordance with (a) 48 C.F.R. §227.7201 through 48 C.F.R. §227.7204, with respect to the Department of Defense and their contractors, or (b) 48 C.F.R. §12.212, with respect to all other US Government licensees and their contractors.</p>

      <p><strong><span style={styles.underlined}>Severability.</span></strong> If any provision of this Agreement is illegal or unenforceable under applicable law, the remainder of the provision will be amended to achieve as closely as possible the effect of the original term and all other provisions of this Agreement will continue in full force and effect.</p>

      <p><strong><span style={styles.underlined}>Governing Law.</span></strong> This Agreement is governed by and construed in accordance with the internal laws of the State of Arizona without giving effect to any choice or conflict of law provision or rule. Any legal suit, action or proceeding arising out of or related to this Agreement or the Application shall be instituted exclusively in the federal courts of the United States or the courts of the State of Arizona in each case located in Phoenix and Maricopa County. You waive any and all objections to the exercise of jurisdiction over you by such courts and to venue in such courts. </p>

      <p><strong><span style={styles.underlined}>Limitation of Time to File Claims.</span></strong> ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR RELATING TO THIS AGREEMENT OR THE APPLICATION MUST BE COMMENCED WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES, OTHERWISE, SUCH CAUSE OF ACTION OR CLAIM IS PERMANENTLY BARRED.</p>

      <p><strong><span style={styles.underlined}>Entire Agreement.</span></strong> This Agreement and our Privacy Policy constitute the entire agreement between you and Mindset with respect to the Application and supersede all prior or contemporaneous understandings and agreements, whether written or oral, with respect to the Application. </p>

      <p><strong><span style={styles.underlined}>Waiver.</span></strong> No failure to exercise, and no delay in exercising, on the part of either party, any right or any power hereunder shall operate as a waiver thereof, nor shall any single or partial exercise of any right or power hereunder preclude further exercise of that or any other right hereunder. In the event of a conflict between this Agreement and any applicable purchase or other terms, the terms of this Agreement shall govern.</p>
  </div>
);

export default Eula;

