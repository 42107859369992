import React from 'react';
import PropTypes from 'prop-types';
import { find } from 'lodash';
import { connect } from 'react-redux';
import MenuIcon from '@material-ui/icons/Menu';
import { IconButton } from '@material-ui/core';
import { browserHistory } from 'react-router';

import Page from '../pages/page';
import { colors } from '../lib/styles';
import AppBar from './app-bar';
import Continue from './continue';

import notificationTracks from '../notification-pages';
import buildTrackFromBundledEvents from '../lib/build-track-from-bundled-events';
import config from '../config';
import brand from '../lib/brand';
import i18nTranslator from '../lib/i18next';
import { addProInNewTabListener, emitProInNewTab } from '../lib/pro-in-new-tab';
import { bloomkidzStyles, bloomkidzPros } from '../lib/bloomkidz';
import IvcVersionInfo from './ivc-version-info';

const styles = {
  instructions: {
    fontSize: '1.3rem',
  },
  linearProgress: {
    borderRadius: 0,
  },
  pageIndicator: {
    color: 'grey',
    display: 'inline-block',
    fontSize: 13,
  },
  questionnaire: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    position: 'relative',
    minHeight: '350px',
  },
  title: {
    color: 'black',
    fontSize: '1.5rem',
    fontWeight: 800,
    textTransform: 'uppercase',
    alignSelf: 'center',
  },
  date: {
    width: '150px',
    alignSelf: 'center',
    margin: '20px',
  },
  titleGroup: {
    margin: 25,
    textAlign: 'center',
  },
  error: {
    color: colors.errorRed,
    alignSelf: 'center',
  },
  text: {
    fontSize: '2rem',
  },
  logoContainer: {
    padding: '15px 60px',
    textAlign: 'center',
    margin: 'auto',
  },
  logo: {
    maxHeight: '150px',
  },
};

class NotificationIntro extends Page {
  constructor(props) {
    super(props);

    emitProInNewTab();
    addProInNewTabListener();
  }

  render() {
    const { clinicName, type, id, text, headerText, clinic_id } = this.props;

    const fontStyle = bloomkidzPros.includes(type)
      ? { fontFamily: bloomkidzStyles.fontFamily }
      : { fontFamily: 'inherit' };

    const continueButtonStyles = bloomkidzPros.includes(type)
      ? { background: bloomkidzStyles.primaryColor, color: 'white' }
      : {};

    return (
      <div style={fontStyle}>
        <AppBar
          headerNode={headerText}
          noLeftNode={true}
          rightNode={type === 'VITAL-CORE' || type === 'VITAL-CORE-BENCH-HR' ? (
            <IconButton onClick={() => browserHistory.push('/core-vital-user-manual-menu')}>
              <MenuIcon fontSize="large" />
            </IconButton>
          ) : null}
        />
        <div style={styles.logoContainer}>
          <img
            alt="Clinic Logo"
            style={styles.logo}
            src={`${config.API_URL}/clinics/${clinic_id}/logo/400`}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = clinic_id === null ? '' : `/img/${brand.name}/${brand.loginLogo}`;
            }}
          />
        </div>
        <div style={styles.questionnaire}>
          <div style={styles.titleGroup}>
            <p style={styles.text}>{clinicName} {text}</p>
          </div>
          <Continue
            text={i18nTranslator('buttonLabel', 'notificationIntro')}
            onClick={() => {
              const { notification_id, dob } = this.props;
              if (type !== 'BUNDLED_EVENT') {
                return this.setTrack(`notification-${type}`, { id, type }, { ...this.props.notificationMeta, notification_id });
              }
              const track = buildTrackFromBundledEvents(this.props.notificationMeta.bundled_events, notification_id, dob);
              if (!track.length) {
                return this.props.router.push(`/notification-success/BUNDLED_EVENT?notification_id=${notification_id}`);
              }
              if (track[0].query.redirect) {
                window.location.replace(`${config.IVC_URL}/notifications/${notification_id}?dob=${dob}`);
              }
              if (track[0].query.redirectToRR) {
                window.location.replace(`${config.IVC_RR_URL}/notifications/${notification_id}?dob=${dob}`);
              }
              this.setRailroad(track);
            }}
            btnStyle={{
              marginTop: '25px',
              alignSelf: 'center',
              maxWidth: '200px',
              fontFamily: fontStyle.fontFamily,
              ...continueButtonStyles,
            }}
          />
        </div>
        {type === 'VITAL-CORE-RR' ? (
          <IvcVersionInfo />
        ) : null}
      </div>
    );
  }
}


function mapStateToProps(state, ownProps) {
  const { user } = state;

  const { id, type, clinic_id } = ownProps.location.state;
  const defaults = find(notificationTracks, { type });
  return {
    id,
    type,
    clinic_id,
    text: defaults.introBodyText,
    headerText: defaults.introHeaderText,
    clinicName: user.requesting_clinic_name,
    notificationMeta: user.meta,
    notification_id: user.notification_id,
    dob: user.dob,
  };
}

NotificationIntro.proptypes = {
  clinicName: PropTypes.string.isRequired,
  route: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, {})(NotificationIntro);
