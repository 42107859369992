const en = {
  pisaInjection2: {
    title: 'Pre-Injection',
    question1: 'What was your level of pain experienced <strong>before</strong> your procedure?',
    question2: 'Did the procedure help reduce your pain?',
    question3: 'Was your quality of life improved?',
    noPain: 'No pain',
    worstPain: 'Worst imaginable pain',
  },
};

const es = {
  pisaInjection2: {
    title: 'Inyección Previa',
    question1: '¿Cuál fue su nivel de dolor experimentado <strong>antes</strong> de su procedimiento?',
    question2: '¿El procedimiento ayudó a reducir su dolor?',
    question3: '¿Mejoró su calidad de vida?',
    noPain: 'Sin dolor',
    worstPain: 'El peor dolor imaginable',
  },
};

export default { en, es };
