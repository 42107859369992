import React from 'react';

import { DatePicker, TimePicker } from '@material-ui/pickers';
import { TextField } from '@material-ui/core';
import { colors, custom } from '../lib/styles';
import Continue from '../components/continue';
import Subtitle from '../components/subtitle';
import PainHead from '../components/pain-head';

const baseStyles = {
  bigArrowsGray: {
    width: '60px',
    height: '60px',
    color: colors.highlightDark,
  },
  bigArrowsLightBlue: {
    width: '60px',
    height: '60px',
    color: colors.primaryHighlight,
  },
  centerHorizontal: {
    justifyContent: 'center',
    display: 'flex',
  },
  column: {
    flex: 'column',
  },
  confirmationText: {
    fontSize: '1.2rem',
  },
  evenSpacing: {
    display: 'flex',
    justifyContent: 'space-around',
    color: colors.primaryColor,
  },
  headachePicSection: {
    backgroundColor: colors.primaryHighlight,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  input: {
    width: '100%',
  },
  inputContainer: {
    maxWidth: 256,
    width: '100%',
  },
  inputWrapper: {
    marginBottom: 10,
    marginTop: 10,
    width: '100%',
  },
  largeIconButton: {
    width: '120px',
    height: '120px',
    padding: '30px',
  },
  redBanner: {
    backgroundColor: colors.errorRed,
    color: colors.white,
    textTransform: 'uppercase',
  },
  select: {
    backgroundColor: colors.darkAccent,
    color: colors.white,
  },
  span: {
    color: colors.white,
    fontWeight: 'bold',
  },
  title: {
    backgroundColor: colors.primaryColor,
    padding: '2.5rem',
    fontSize: '2rem',
  },
  whiteSection: {
    backgroundColor: colors.white,
    marginTop: '5px',
  },
};
const MigrainePainConfirmPresentation = (props) => {
  return (
    <div>
      <div style={baseStyles.title}>{props.painType} ● LEVEL {props.severity}</div>
      <Subtitle label="SCROLL DOWN TO COMPLETE" />
      <div style={baseStyles.confirmationText}>Here is the pain point you entered.</div>
      <div style={baseStyles.confirmationText}>Review, confirm or edit, and continue.</div>
      {(props.loading || props.noData) ? null : (
        <div>
          <section>
            <PainHead
              lockView={true}
              currentView={props.brain_quadrant}
              pain={[props.pain]}
              onPainSelect={props.onLocationSelect}
              style={custom.center}
              sex={props.sex || 'MALE'}
            />
          </section>
          <section>
            <div style={baseStyles.redBanner}>When did it start?</div>
            <div style={baseStyles.centerHorizontal}>
              <div style={baseStyles.inputContainer}>
                <div style={baseStyles.inputWrapper}>
                  <DatePicker
                    style={baseStyles.input}
                    autoOk={true}
                    format="MM/dd/yyyy"
                    emptyLabel="Pain Start Date"
                    maxDate={new Date()}
                    value={props.startDate}
                    onChange={props.onChangeStartDate}
                  />
                </div>
                <div style={baseStyles.inputWrapper}>
                  <TimePicker
                    style={baseStyles.input}
                    emptyLabel="Example: 5:35pm"
                    value={props.startTimeOfDay}
                    onChange={props.onChangeStartTimeOfDay}
                  />
                </div>
              </div>
            </div>
          </section>
          <section>
            <div style={baseStyles.redBanner}>When did it end?</div>
            <div style={baseStyles.centerHorizontal}>
              <div style={baseStyles.inputContainer}>
                {props.dateOutOfOrderError}
                <div style={baseStyles.inputWrapper}>
                  <DatePicker
                    style={baseStyles.input}
                    autoOk={true}
                    format="MM/dd/yyyy"
                    emptyLabel="Pain End Date"
                    maxDate={new Date()}
                    onChange={props.onChangeEndDate}
                    value={props.endDate}
                  />
                </div>
                <div style={baseStyles.inputWrapper}>
                  <TimePicker
                    style={baseStyles.input}
                    emptyLabel="5:35pm"
                    value={props.endTimeOfDay}
                    onChange={props.onChangeEndTimeOfDay}
                  />
                </div>
              </div>
            </div>
          </section>
          <section>
            <div style={baseStyles.redBanner}>Have you identified any of the triggers?</div>
            <div style={baseStyles.centerHorizontal}>
              <div style={baseStyles.inputContainer}>
                <div style={baseStyles.inputWrapper}>
                  <TextField
                    style={baseStyles.input}
                    margin="none"
                    label="Example: Moving My Head"
                    onChange={props.onUpdateTriggers}
                    value={props.triggers}
                  />
                </div>
              </div>
            </div>
          </section>
          <section>
            <div style={baseStyles.redBanner}>Any other comments on this pain?</div>
            <div style={baseStyles.centerHorizontal}>
              <div style={baseStyles.inputContainer}>
                <div style={baseStyles.inputWrapper}>
                  <TextField
                    style={baseStyles.input}
                    margin="none"
                    label="Example: Sometimes Stabbing"
                    onChange={props.onUpdateExplanation}
                    value={props.explanation}
                  />
                </div>
              </div>
            </div>
          </section>
          <section style={baseStyles.whiteSection}>
            <Continue
              onClick={props.onContinue}
            />
            {props.displayedError}
          </section>
        </div>)}
    </div>
  )
}

export default MigrainePainConfirmPresentation;
