const inactiveRoutes = [
  '/',
  '/login',
  '/notifications',
  '/pain-history',
  '/patientcontact',
  '/personalinfo',
  '/picture',
  '/reason',
  '/resetpin',
  '/settings',
  '/share-data',
  '/signup',
  '/swipe-pain-log',
  '/which-pain',
];

export default inactiveRoutes;
