import React from 'react';
import { connect } from 'react-redux';
import { Divider, TextField } from '@material-ui/core';
import { omit } from 'lodash';

import AppBar from '../components/app-bar';
import ToggleTitle from '../components/toggle-title';
import Page from './page';
import Layout from '../layouts/common';
import Continue from '../components/continue';
import MultilineTextField from '../components/multiline-textfield';
import { colors } from '../lib/styles';

import { browseGeneralHx, editGeneralHx, addGeneralHx } from '../state/general-hx';

const baseStyles = {
  marginLeft: {
    marginLeft: 15,
  },
  subtitle: {
    textAlign: 'left',
  },
  section: {
    backgroundColor: colors.white,
    marginTop: '3px',
  },
  textField: {
    marginLeft: 15,
    maxWidth: 256,
    marginBottom: 10,
    marginTop: 10,
    width: '100%',
  },
};

class Reactions extends Page {
  constructor(props) {
    super(props);

    this.state = {
      drugAllergies: false,
      steroidInjections: false,
      anesthesia: false,
      generalHx: {
        drug_allergies: false,
        drug_allergies_list: '',
        drug_allergies_reaction: '',
        steroid_injections: false,
        steroid_injections_area: '',
        steroid_response: '',
        anesthesia: false,
        anesthesia_reaction: '',
        ...props.generalHx,
      },
    };

    this.toggleDrugAllergies = this.toggleExpansion.bind(this, 'drug_allergies');
    this.toggleSteroidInjections = this.toggleExpansion.bind(this, 'steroid_injections');
    this.toggleAnesthesia = this.toggleExpansion.bind(this, 'anesthesia');
    this.handleChangeDrugAllergiesList = this.handleTextFieldUpdateNewMUI.bind(this, 'drug_allergies_list');
    this.handleChangeDrugAllergiesReaction = this.handleChangeDrugAllergiesReaction.bind(this);

    this.handleChangeSteroidInjectionsArea = this.handleTextFieldUpdateNewMUI.bind(this, 'steroid_injections_area');
    this.handleChangeSteroidResponse = this.handleTextFieldUpdate.bind(this, 'steroid_response');
    this.handleChangeAnesthesiaReaction = this.handleTextFieldUpdate.bind(this, 'anesthesia_reaction');
    this.handleUpdate = this.handleUpdate.bind(this);
  }

  componentWillMount() {
    if (!this.props.generalHx.id) {
      this.props.browseGeneralHx();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.generalHx !== this.props.generalHx) {
      this.setState({
        generalHx: {
          drug_allergies: false,
          drug_allergies_list: '',
          drug_allergies_reaction: '',
          steroid_injections: false,
          steroid_injections_area: '',
          steroid_response: '',
          anesthesia: false,
          anesthesia_reaction: '',
          ...nextProps.generalHx,
        },
      });
    }
  }

  toggleExpansion(field, e, value) {
    this.setState({
      generalHx: {
        ...this.state.generalHx,
        [field]: value,
      },
    });
  }

  handleChangeDrugAllergiesReaction(e) {
    this.setState({
      generalHx: {
        ...this.state.generalHx,
        drug_allergies_reaction: e.target.value,
      },
    });
  }

  handleTextFieldUpdate(field, e, text) {
    this.setState({
      generalHx: {
        ...this.state.generalHx,
        [field]: text,
      },
    });
  }

  handleTextFieldUpdateNewMUI(field, e) {
    this.setState({
      generalHx: {
        ...this.state.generalHx,
        [field]: e.target.value,
      },
    });
  }

  handleUpdate() {
    const { generalHx } = this.state;
    if (generalHx.id) {
      const newHx = omit(generalHx, ['user_id']);
      this.props.editGeneralHx(newHx)
        .then(() => this.forward());
    } else {
      const newHx = omit(generalHx, ['id', 'user_id']);
      this.props.addGeneralHx(newHx)
        .then(() => this.forward());
    }
  }

  render() {
    const { generalHx } = this.state;
    return (
      <Layout>
        <div>
          <AppBar
            backButtonOnClick={() => this.props.router.goBack()}
            headerNode="Reactions"
          />
          <ToggleTitle
            label="Do you have any drug allergies?"
            onToggle={this.toggleDrugAllergies}
            toggled={generalHx.drug_allergies}
          />
          <section style={baseStyles.section}>
            {generalHx.drug_allergies ? (
              <div>
                <TextField
                  label="What drugs are you allergic to? *"
                  style={baseStyles.textField}
                  value={generalHx.drug_allergies_list}
                  onChange={this.handleChangeDrugAllergiesList}
                />
                <Divider />
                <MultilineTextField
                  hintText="Describe adverse reactions..."
                  rows={3}
                  value={generalHx.drug_allergies_reaction}
                  onChange={this.handleChangeDrugAllergiesReaction}
                />
              </div>
            ) : null}
          </section>
          <ToggleTitle
            label="Ever received steroid injections?"
            onToggle={this.toggleSteroidInjections}
            toggled={generalHx.steroid_injections}
          />
          <section style={baseStyles.section}>
            {generalHx.steroid_injections ? (
              <div>
                <TextField
                  label="Area of Treatment"
                  style={baseStyles.textField}
                  value={generalHx.steroid_injections_area}
                  onChange={this.handleChangeSteroidInjectionsArea}
                />
                <Divider />
                <MultilineTextField
                  hintText="What was your response to this treatment?"
                  rows={3}
                  value={generalHx.steroid_response}
                  onChange={this.handleChangeSteroidResponse}
                />
              </div>
            ) : null}
          </section>
          <ToggleTitle
            label="Ever had problems with Anesthesia?"
            onToggle={this.toggleAnesthesia}
            toggled={generalHx.anesthesia}
          />
          <section style={baseStyles.section}>
            {generalHx.anesthesia ? (
              <MultilineTextField
                hintText="Please explain..."
                rows={3}
                value={generalHx.anesthesia_reaction}
                onChange={this.handleChangeAnesthesiaReaction}
              />
            ) : null}
          </section>
          <Continue text="Update" onClick={this.handleUpdate} />
        </div>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  const { generalHx, user } = state;
  return {
    generalHx: generalHx.data,
    user,
  };
}

export default connect(mapStateToProps, {
  browseGeneralHx,
  editGeneralHx,
  addGeneralHx,
})(Reactions);
