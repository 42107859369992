import React from 'react';
import { connect } from 'react-redux';
import { get, reduce } from 'lodash';
import { Button } from '@material-ui/core';

import { colors, custom } from '../lib/styles';
import AppBar from '../components/app-bar';
import Continue from '../components/continue';
import Page from './page';
import Layout from '../layouts/common';
import Subtitle from '../components/subtitle';
import PainTypeSelector from '../components/pain-type-selector';
import PainHead from '../components/pain-head';
import PainSlider from '../components/pain-slider';

import { updatePRO } from '../state/pro-forms';

const baseStyles = {
  acheLevel: {
    marginTop: '2.5rem',
    color: colors.primaryColor,
    fontSize: '1.2rem',
  },
  btn: {
    width: '95vw',
    backgroundColor: colors.secondaryColor,
    marginTop: '2rem',
  },
  evenSpacing: {
    display: 'flex',
    justifyContent: 'space-around',
    color: colors.primaryColor,
  },
  headachePicSection: {
    backgroundColor: colors.primaryHighlight,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  headachePainTypeSect: {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: colors.lightBrightBlue,
    alignItems: 'center',
  },
  instructions: {
    fontSize: '1.4rem',
    marginTop: '1rem',
  },
  largeIconButton: {
    width: '12rem',
    height: '12rem',
    padding: '3rem',
  },
  painHeadMargin: {
    margin: '0 auto',
  },
  painLevelSection: {
    backgroundColor: colors.white,
    marginTop: '0.5rem',
  },
  paperCircles: {
    height: '4.5rem',
    width: '4.5rem',
    margin: '1rem',
    textAlign: 'center',
    border: '#596097 solid 2px',
    backgroundSize: 'contain',
  },
  sliderSection: {
    color: colors.white,
    fontSize: '1.2rem',
  },
  select: {
    backgroundColor: colors.darkAccent,
    color: colors.white,
  },
  span: {
    color: colors.white,
    fontWeight: 'bold',
  },
  slider: {
    background: colors.primaryColor,
    padding: '1rem',
  },
  title: {
    fontSize: '1.2rem',
  },
};

const painLocations = [
  {
    x: 82,
    y: 15,
    name: 'TOP',
  },
  {
    x: 45,
    y: 35,
    name: 'LEFT',
  },

  {
    x: 82,
    y: 35,
    name: 'MIDDLE',
  },
  {
    x: 120,
    y: 35,
    name: 'RIGHT',
  },
  {
    x: 82,
    y: 70,
    name: 'BOTTOM',
  },
  {
    x: 82,
    y: 135,
    name: 'NECK',
  },
];

function calculateDistance(click, point) {
  return ((click.x - point.x) ** 2) + ((click.y - point.y) ** 2);
}

class HeadachePain2 extends Page {
  constructor(props) {
    super(props);
    this.handleHeadView = this.handleHeadView.bind(this);
    this.handlePainLevel = this.handlePainLevel.bind(this);
    this.handlePainType = this.handlePainType.bind(this);
    this.handleSliderUpdate = this.handleSliderUpdate.bind(this);
    this.handleContinue = this.handleContinue.bind(this);
    this.handlePainLocationUpdate = this.handlePainLocationUpdate.bind(this);
    const { headachePain } = props;

    this.state = {
      sliderError: '',
      painLocationError: '',
      currentView: get(headachePain, 'brain_quadrant', 'FRONT'),
      brain_quadrant: get(headachePain, 'brain_quadrant', 'FRONT'),
      brain_locations: get(headachePain, 'brain_locations', ''),
      pain_type: get(headachePain, 'pain_type', 'ACHE'),
      severity: get(headachePain, 'severity', 0),
    };
  }

  handleSliderUpdate(e, severity) {
    this.setState({
      severity,
      sliderError: '',
    });
  }

  handleHeadView(e) {
    this.setState({ currentView: e.currentTarget.value });
  }

  handlePainLevel(e) {
    this.setState({
      painLevel: e.currentTarget.value,
    });
  }

  handlePainType(pain_type) {
    this.setState({ pain_type });
  }

  handlePainLocationUpdate(x, y, brain_quadrant) {
    const click = { x, y };

    const closestPoint = reduce(painLocations, (result, value) => {
      const distance = calculateDistance(click, value);

      if (distance < result.distance) {
        return {
          distance,
          ...value,
        };
      }

      return result;
    }, { distance: 99999999 });
    this.setState({
      painLocationError: '',
      brain_quadrant,
      brain_locations: closestPoint.name,
    });
  }

  handleContinue() {
    const { brain_locations, brain_quadrant, pain_type, severity, submitting } = this.state;
    if (submitting) return;
    this.setState({ submitting: true });
    let painLocationError = '';
    let sliderError = '';
    if (!brain_locations || !brain_quadrant) {
    this.setState({ submitting: false });
      painLocationError = (
        <div style={custom.redErrorText}>Please enter a pain location</div>
      );
    }
    if (!severity) {
      this.setState({ submitting: false });
      sliderError = (
        <div style={custom.redErrorText}>Please select a pain level</div>
      );
    }
    if (painLocationError || sliderError) {
      return this.setState({ painLocationError, sliderError, submitting: false });
    }

    this.props.updatePRO({
      type: 'headachePain',
      position: this.getTrackIndex(),
      value: {
        brain_quadrant,
        brain_locations,
        severity,
        pain_type,
      }
    });
    this.setState({ submitting: false}, () => this.forwardWithQuery())
  }

  onClickBackButton = () => {
    this.backward();
  }


  render() {
    const { brain_quadrant, brain_locations, severity, pain_type, sliderError, painLocationError } = this.state;

    return (
      <Layout>
        <AppBar
          backButtonOnClick={() => this.props.router.goBack()}
          headerNode="Headache Pain"
        />
        <div>
          <Subtitle label="SCROLL DOWN TO COMPLETE" />
          <div style={baseStyles.instructions}>Tap anywhere to add a pain point</div>
          <section>
            {painLocationError}
            {sliderError}
            <PainHead
              currentView={this.state.currentView}
              pain={[{ brain_quadrant, brain_locations }]}
              onPainSelect={this.handlePainLocationUpdate}
              style={baseStyles.painHeadMargin}
              sex="MALE"
            />
            <div style={baseStyles.evenSpacing}>
              <Button onClick={this.handleHeadView} color="primary" value="FRONT">FRONT</Button>
              <Button onClick={this.handleHeadView} color="primary" value="LEFT">LEFT</Button>
              <Button onClick={this.handleHeadView} color="primary" value="BACK">BACK</Button>
              <Button onClick={this.handleHeadView} color="primary" value="RIGHT">RIGHT</Button>
            </div>
            <div style={baseStyles.select}>Select the type of pain you are experiencing below</div>
          </section>
          <PainTypeSelector
            onUpdatePainType={this.handlePainType}
            painType={pain_type}
          />
          <section style={baseStyles.slider}>
            <p style={baseStyles.sliderSection}>Use slider to give a pain on a scale from 1-10
              <br />
              <span style={baseStyles.span}>1 being very light discomfort - 10 being extreme agony</span>
            </p>
            <PainSlider
              propFunc={this.handleSliderUpdate}
              value={Number(severity)}
            />
          </section>
          <section style={baseStyles.painLevelSection}>
            <div style={baseStyles.acheLevel}>{pain_type} ● LEVEL {severity}</div>
            <Continue
              disabled={this.state.submitting}
              onClick={this.handleContinue}
              btnStyle={baseStyles.btn}
            />
          </section>
        </div>
      </Layout>
    );
  }
}
function mapStateToProps(state, ownProps) {
  const { user, proForms: { headachePain } } = state;
  return {
    user,
    pain: headachePain[1] || {},
  };
}

export default connect(mapStateToProps, { updatePRO })(HeadachePain2);
