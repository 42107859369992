import React from 'react';
import { connect } from 'react-redux';
import { get, map, omit } from 'lodash';
import { DatePicker } from '@material-ui/pickers';
import { Switch, TextField } from '@material-ui/core';

import AppBar from '../components/app-bar';
import Page from './page';
import Layout from '../layouts/common';
import Continue from '../components/continue';
import Subtitle from '../components/subtitle';
import SocialItem from '../components/social-usage-item';
import { colors } from '../lib/styles';
import { logEvent } from '../lib/amplitude';

import { browseGeneralHx, editGeneralHx, addGeneralHx } from '../state/general-hx';
import { browseSocialHx, editSocialHx } from '../state/social-hx';
import { browseSocial } from '../state/app-data';

const baseStyles = {
  continueBtn: {
    marginTop: '10px',
  },
  circles: {
    color: colors.highlightMedium,
  },
  datePickerSect: {
    backgroundColor: colors.highlightMedium,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  number: {
    marginRight: 15,
    marginLeft: 15,
    color: colors.primaryColor,
  },
  question: {
    marginLeft: 15,
    width: '65%',
    fontSize: '1.4rem',
    textAlign: 'left',
  },
  question2: {
    color: colors.primaryColor,
    width: 500,
    textAlign: 'left',
    fontSize: '1.8rem',
  },
  textField: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginLeft: 15,
  },
  toggle: {
    marginLeft: 40,
    width: '10%',
    marginRight: 15,
  },
  thumbSwitched: {
    backgroundColor: colors.secondaryColor,
  },
  trackSwitched: {
    backgroundColor: 'rgba(24, 183, 155, 0.7)',
  },
  topSection: {
    backgroundColor: colors.primaryColor,
    color: colors.white,
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    paddingTop: 20,
    paddingBottom: 20,
  },
};

class SocialHx2 extends Page {
  constructor(props) {
    super(props);
    const subAbuseDate = get(props, 'generalHx.data.sub_abuse_date', null);
    this.state = {
      generalHx: {
        sub_abuse: false,
        sub_abuse_type: '',
        ...props.generalHx.data,
        sub_abuse_date: subAbuseDate ? new Date(subAbuseDate) : null,
      },
    };

    this.handleSubAbuse = this.handleUpdateSubAbuse.bind(this, 'sub_abuse');
    this.handleSubAbuseType = this.handleUpdate.bind(this, 'sub_abuse_type');
    this.handleSubAbuseDate = this.handleUpdateDate.bind(this, 'sub_abuse_date');
    this.handleContinue = this.handleContinue.bind(this);
  }

  componentWillMount() {
    this.props.browseSocial();
    this.props.browseSocialHx();
    this.props.browseGeneralHx();
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.generalHx !== nextProps.generalHx) {
      const subAbuseDate = get(nextProps, 'generalHx.data.sub_abuse_date', null);
      this.setState({
        generalHx: {
          sub_abuse: false,
          sub_abuse_type: '',
          ...nextProps.generalHx.data,
          sub_abuse_date: subAbuseDate ? new Date(subAbuseDate) : null,
        },
      });
    }
  }

  handleUpdate(field, e) {
    this.setState({
      generalHx: {
        ...this.state.generalHx,
        [field]: e.target.value,
      },
    });
  }

  handleUpdateSubAbuse(field, e) {
    this.setState({
      generalHx: {
        ...this.state.generalHx,
        [field]: e.target.checked,
      },
    });
  }

  handleUpdateDate(field, newDate) {
    this.setState({
      generalHx: {
        ...this.state.generalHx,
        [field]: newDate,
      },
    });
  }

  handleContinue() {
    logEvent('Medical History Updated (Finish)');
    const { generalHx } = this.state;

    const newData = omit(generalHx, ['user_id']);

    if (generalHx.id) {
      this.props.editGeneralHx(newData)
        .then(() => this.forward());
    } else {
      this.props.addGeneralHx(newData)
        .then(() => this.forward());
    }
  }

  render() {
    const socialItems = map(this.props.socialHx.data, (item) => {
      const name = get(this, `props.social.${item.social_id}.name`, '');
      return (
        <SocialItem
          key={`${item.id}-socialitem`}
          handleEdit={this.props.editSocialHx}
          name={name}
          {...item}
        />
      );
    });
    return (
      <Layout>
        <div>
          <AppBar
            backButtonOnClick={() => this.props.router.goBack()}
            headerNode="Social History"
          />
          <Subtitle label="LIST FREQUENCY OF USE (PER WEEK):" />
          <section>
            {socialItems}
          </section>
          <section style={baseStyles.topSection}>
            <div style={baseStyles.question}>Have you ever been treated for drug/alcohol abuse?</div>
            <Switch
              onChange={this.handleSubAbuse}
              checked={!!this.state.generalHx.sub_abuse}
            />
          </section>
          {this.state.generalHx.sub_abuse ? (
            <div>
              <section style={{ backgroundColor: colors.white, textAlign: 'left' }}>
                <TextField
                  disabled={!this.state.generalHx.sub_abuse}
                  label="What substance were you treated for?"
                  placeholder="Alcohol"
                  style={{ margin: 15, width: 256 }}
                  value={this.state.generalHx.sub_abuse_type}
                  onChange={this.handleSubAbuseType}
                />
              </section>
              <section style={baseStyles.datePickerSect}>
                <DatePicker
                  autoOk={true}
                  disabled={!this.state.generalHx.sub_abuse}
                  openTo="year"
                  format="MM/dd/yyyy"
                  label="When were you treated?"
                  style={{ margin: 15, width: 256 }}
                  onChange={this.handleSubAbuseDate}
                  value={this.state.generalHx.sub_abuse_date}
                />
              </section>
            </div>) : null}
          <Continue btnStyle={baseStyles.continueBtn} text="Update" onClick={this.handleContinue} />
        </div>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  const { appData: { social }, generalHx, socialHx, user } = state;
  return {
    generalHx,
    social,
    socialHx,
    user,
  };
}

export default connect(mapStateToProps, {
  browseGeneralHx,
  editGeneralHx,
  addGeneralHx,
  browseSocialHx,
  editSocialHx,
  browseSocial,
})(SocialHx2);
