import React from 'react';
import { omit } from 'lodash';
import { connect } from 'react-redux';

import AppBar from '../components/app-bar';
import Page from './page';
import Layout from '../layouts/common';
import Continue from '../components/continue';
import MultilineTextField from '../components/multiline-textfield';
import { custom, colors } from '../lib/styles';

import { browseGeneralHx, editGeneralHx, addGeneralHx } from '../state/general-hx';

const baseStyles = {
  btn: {
    marginTop: '2rem',
    backgroundColor: colors.secondaryColor,
    width: '55%',
    height: '5rem',
  },
  complaint: {
    fontSize: '1rem',
  },
  label: {
    fontSize: '1.4rem',
  },
  skipBtn: {
    width: '55%',
    height: '5rem',
  },
  skipLabel: {
    fontSize: '1.4rem',
  },
  title: {
    fontSize: '1.8rem',
    color: colors.darkGrayText,
  },
};

class PrimaryComplaint extends Page {
  constructor(props) {
    super(props);

    this.state = {
      chiefComplaintError: '',
      generalHx: {
        chief_complaint: '',
        ...props.generalHx.data,
      },
    };


    this.handleSave = this.handleSave.bind(this);
    this.handleChiefComplaint = this.handleText.bind(this, 'chief_complaint');
  }

  componentWillMount() {
    this.props.browseGeneralHx();
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.generalHx !== nextProps.generalHx) {
      this.setState({
        generalHx: {
          chief_complaint: '',
          ...nextProps.generalHx.data,
        },
      });
    }
  }

  handleText(field, e) {
    this.setState({
      generalHx: {
        ...this.state.generalHx,
        [field]: e.target.value,
      },
    });
  }

  handleSave() {
    const { generalHx } = this.state;
    const newData = omit(generalHx, ['user_id', 'id']);
    let chiefComplaintError = '';
    if (!generalHx.chief_complaint) {
      chiefComplaintError = (
        <div style={custom.redErrorText}>Please describe your primary health complaint</div>
      );
    }
    if (chiefComplaintError) {
      return this.setState({ chiefComplaintError });
    }
    if (!generalHx.id) {
      this.props.addGeneralHx(newData)
        .then(() => this.forward())
        .catch(() => {});
    } else {
      this.props.editGeneralHx(newData)
        .then(() => this.props.router.push('/'))
        .catch(() => {});
    }
  }

  render() {
    const { chiefComplaintError } = this.state;
    return (
      <Layout>
        <div>
          <AppBar
            backButtonOnClick={() => this.props.router.goBack()}
            headerNode="Primary Complaint"
          />
          <p style={baseStyles.title}> Reason for Visit </p>
          <img alt="a clipboard" src="/img/clipboard-question-gray.png" style={custom.clipboard} />
          <section style={custom.whiteBackground}>
            <MultilineTextField
              hintText="Please describe your primary health complaint"
              onChange={this.handleChiefComplaint}
              value={this.state.generalHx.chief_complaint}
              style={baseStyles.complaint}
            />
          </section>
          {chiefComplaintError}
          <Continue
            onClick={this.handleSave}
            text="Submit and Continue"
            btnStyle={baseStyles.btn}
            labelStyle={baseStyles.label}
          />
          <Continue
            onClick={() => this.props.router.push('/')}
            text="Skip This For Now"
            btnStyle={baseStyles.skipBtn}
            labelStyle={baseStyles.skipLabel}
          />
        </div>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  const { generalHx, user } = state;
  return {
    generalHx,
    user,
  };
}

export default connect(mapStateToProps, {
  browseGeneralHx,
  editGeneralHx,
  addGeneralHx,
})(PrimaryComplaint);
