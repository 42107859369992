import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import Page from './page';
import { submitPRO } from '../state/pro-forms';
import DisableSessionTimeout from '../components/disable-session-timeout';
import i18nTranslator from '../lib/i18next';
import { colors, fontSizing } from '../lib/styles';

const styles = {
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '0px 20px',
    flexGrow: 1,
    textAlign: 'center',
  },
  link: {
    color: colors.white,
    fontSize: '1.4rem',
    backgroundColor: colors.primaryColor,
    borderRadius: '0.3125rem',
    minWidth: '17.1875rem',
    marginBottom: '0.625rem',
    marginTop: '2rem',
    textAlign: 'center',
    textDecoration: 'none',
    width: '85vw',
    lineHeight: '4rem',
  },
  question: {
    fontSize: '1.5rem',
    margin: '60px 40px 0px 40px',
    textAlign: 'center',
  },
  greenCheckMark: {
    marginTop: 30,
    marginBottom: 30,
    width: '8rem',
    height: '8rem',
  },
  instructionText: {
    fontSize: '1.25rem',
    marginTop: 20,
  },
  text: {
    fontSize: fontSizing.body,
    marginBottom: 20,
  },
};

class EducationAssessment2 extends Page {
  render() {
    const { classes } = this.props;

    return (
      <div className={classes.contentWrapper}>
        <DisableSessionTimeout />
        <img
          src="/img/GreenCheckmarkCircle.png"
          className={classes.greenCheckMark}
          alt="Green check mark"
        />
        <div className={classes.text}>{i18nTranslator('line1', 'education2')}</div>
        <div className={classes.text}>{i18nTranslator('line2', 'education2')}</div>
        <div className={classes.text}>{i18nTranslator('line3', 'education2')}</div>
        <div className={classes.text}>{i18nTranslator('line4', 'education2')}</div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state;
  return { user };
}

export default connect(mapStateToProps, { submitPRO })(withStyles(styles)(EducationAssessment2));
