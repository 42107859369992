import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { includes } from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import Page from './page';
import { colors } from '../lib/styles';
import AppBar from '../components/app-bar';
import Continue from '../components/continue';
import Hidden from '../components/hidden-content';
import { submitPRO } from '../state/pro-forms';
import brand from '../lib/brand';

const styles = {
	instructions: {
		fontSize: '1.3rem',
	},
	image: {
		height: 120,
		padding: '15px 60px',
		textAlign: 'center',
	},
	linearProgress: {
		borderRadius: 0,
	},
	pageIndicator: {
		color: 'grey',
		display: 'inline-block',
		fontSize: 13,
	},
	questionnaire: {
		display: 'flex',
		flexDirection: 'column',
		position: 'relative',
		minHeight: '350px',
		width: '80%',
		margin: 'auto',
	},
	title: {
		color: 'black',
		fontSize: '1.5rem',
		fontWeight: 800,
		textTransform: 'uppercase',
	},
	date: {
		width: '150px',
	},
	itemStyles: {
		color: colors.black,
		fontSize: '1.5rem',
		textAlign: 'left',
		textTransform: 'uppercase',
	},
	titleGroup: {
		margin: '10px 0px',
	},
	error: {
		color: colors.errorRed,
	},
	text: {
		fontSize: '22px',
	},
	checkbox: {
		color: colors.primaryColor,
		fontSize: '1.2rem',
	},
	radioButton: {
		color: colors.black,
		fontSize: '1.2rem',
	},
	radioButtonGroup: {
		display: 'flex',
		flexDirection: 'row',
		width: '200px',
	},
	hidden: {
		position: 'relative',
		top: '25px',
		color: colors.errorRed,
		fontSize: '1.2rem'
	}
};

class DigestiveHealthPRO2 extends Page {
	constructor(props) {
		super(props);

		this.state = {
			colonoscopy: null,
			meds: null,
			diarrhea: false,
			bleeding: false,
			pain: false,
			surgery: null,
			hidden: true,
			submitting: false,
		};
	}
	handleSubmit = () => {
		const { id } = this.props.location.query;
		const { user } = this.props;
		const { colonoscopy, meds, diarrhea, bleeding, pain, submitting, surgery } = this.state;
		if (submitting) return;
		if (includes(this.state, null)) {
			return this.setState({ hidden: false });
		}
		this.setState({ submitting: true });
		this.props.submitPRO({ userId: user.id, id }, {
			pro_type: 'DIGESTIVE-HEALTH',
			pro_data: {
				data: [
					'Yes',
					colonoscopy,
					meds,
					`${diarrhea ? diarrhea : "No"}`,
					`${bleeding ? bleeding : "No"}`,
					`${pain ? pain : "No"}`,
					surgery
				]
			},
		}, user)
			.then(() => this.forwardWithQuery(this.props.location.query))
			.catch(() => this.setState({ submitting: false }));
	}
	render() {
		return (
			<div>
				<AppBar
					headerNode="Digestive Health"
					noLeftNode={true}
				/>
				<div style={styles.image}>
					<img src={`/img/${brand.name}/${brand.loginLogo}`} alt="Informed Logo" />
				</div>
				<div style={styles.questionnaire}>
					<div style={styles.titleGroup}>
						<p style={styles.text}>When was your last colonoscopy?</p>
					</div>
					<TextField
						id="date"
						type="date"
						style={styles.date}
						inputProps={{ style: { marginLeft: '20px' } }}
						onChange={(event) => {
							this.setState({ colonoscopy: event.target.value });
						}}
					/>
					<div style={styles.titleGroup}>
						<p style={styles.text}> Have you taken medications for your IBD condition that stopped working, didn’t ever work, or that you had a reaction to?</p>
					</div>
					<RadioGroup
						name="meds"
						onChange={(event, value) => {
							this.setState({ meds: value });
						}}
						valueSelected={this.state.meds}
						style={styles.radioButtonGroup}
					>
						<FormControlLabel value="No" control={<Radio color="primary" />} label="No" />
						<FormControlLabel value="Yes" control={<Radio color="primary" />} label="Yes" />
					</RadioGroup>
					<div style={styles.titleGroup}>
						<p style={styles.text}>What are your symptoms now?</p>
					</div>
					<FormGroup>
						<FormControlLabel
							control={
								<Checkbox
									checked={this.state.diarrhea}
									onChange={(event, value) => {
										this.setState({ diarrhea: value ? 'Yes' : false });
									}}
									style={styles.checkbox}
								/>
							}
							classes={{ label: this.props.classes.checkbox }}
							label="Diarrhea"
						/>
						<FormControlLabel
							control={
								<Checkbox
									checked={this.state.bleeding}
									onChange={(event, value) => {
										this.setState({ bleeding: value ? 'Yes' : false });
									}}
									style={styles.checkbox}
								/>
							}
							classes={{ label: this.props.classes.checkbox }}
							label="Bleeding or blood in your stool"
						/>
						<FormControlLabel
							control={
								<Checkbox
									checked={this.state.pain}
									onChange={(event, value) => {
										this.setState({ pain: value ? 'Yes' : false });
									}}
									style={styles.checkbox}
								/>
							}
							classes={{ label: this.props.classes.checkbox }}
							label="Abdominal pain"
						/>
					</FormGroup>
					<div style={styles.titleGroup}>
						<p style={styles.text}>Have you ever had surgery on your intestines?</p>
					</div>
					<RadioGroup
						name="surgery"
						onChange={(event, value) => {
							this.setState({ surgery: value });
						}}
						valueSelected={this.state.surgery}
						style={styles.radioButtonGroup}
					>
						<FormControlLabel value="No" control={<Radio color="primary" />} label="No" />
						<FormControlLabel value="Yes" control={<Radio color="primary" />} label="Yes" />
					</RadioGroup>
					<Hidden hidden={this.state.hidden}><div style={styles.hidden}>Please fill out all fields.</div></Hidden>
					<Continue
						text="Submit"
						disabled={this.state.submitting}
						onClick={this.handleSubmit}
						btnStyle={{ marginTop: '50px', alignSelf: 'center' }}
					/>
				</div>
			</div>
		);
	}
}


function mapStateToProps(state) {
	const { user } = state;
	return { user };
}

DigestiveHealthPRO2.proptypes = {
	user: PropTypes.object.isRequired,
	route: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, { submitPRO })(withStyles({ checkbox: { fontSize: '1.2rem' } })(DigestiveHealthPRO2));
