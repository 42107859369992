const en = {
  telemedicineAppointmentNotificationPage: {
    introHeaderText: 'Virtual Consultation',
    introBodyText1: 'has a virtual health room waiting for you.',
    introBodyText2: 'Virtual exams are best experienced using Chrome or Safari. You may experience connection difficulties using Firefox or Edge.',
    introBodyText3: 'You may copy and paste the URL into Chrome or Safari if needed.',
    loggedInSuccessText: '',
    loggedOutSuccessText: 'Thank you for speaking with us. You may now close this window.',
  },
};

const es = {
  telemedicineAppointmentNotificationPage: {
    introHeaderText: 'Consulta Virtual',
    introBodyText1: 'tiene una sala de salud virtual esperando para usted.',
    introBodyText2: 'Se puede tener una mejor experiencia con los examines virtuales usando Chrome o Safari. Se podrían experimentar dificultades de conexión si usa Firefox o Edge.',
    introBodyText3: 'Puede copiar y pegar la dirección URL en Chrome o Safari si necesita.',
    loggedInSuccessText: '',
    loggedOutSuccessText: 'Gracias por hablar con nosotros. Ahora puede cerrar esta ventana.',
  },
};

export default { en, es };
