const en = {
  eqSelfCare: {
    title: 'Self Care',
    instructions: 'Please select the option that best answers the question relating to your pain.',
    healthy: 'I have no problems with self-care',
    mild: 'I have some problems washing or dressing myself',
    severe: 'I am unable to wash or dress myself',
  },
};

const es = {
  eqSelfCare: {
    title: 'Cuidados Personales',
    instructions: 'Seleccione la opción que mejor responda a la pregunta relacionada con su dolor.',
    healthy: 'No tengo problemas con el cuidado personal',
    mild: 'Tengo algunos problemas para lavarme o vestirme',
    severe: 'No puedo lavarme ni vestirme solo',
  },
};

export default { en, es };
