import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';
import { connect } from 'react-redux';
import Joi from 'joi-browser';

import Page from './page';
import AppBar from '../components/app-bar';
import IconWrapper from '../components/icon-wrapper';
import BloomkidzLayout from '../layouts/bloomkidz';
import { UserGroup } from '../lib/icons';
import { fontSizing } from '../lib/styles';
import { updatePRO } from '../state/pro-forms';
import { bloomkidzStylesObj } from '../lib/bloomkidz';
import { email, phone } from '../lib/validator';

const styles = bloomkidzStylesObj;

const formSchema = Joi.object({
  guardianTwoEmail: Joi.string().regex(email),
  guardianTwoTelephone: Joi.string().regex(phone),
  guardianTwoCell: Joi.string().regex(phone),
});

class BloomkidzNewClient3 extends Page {
  constructor(props) {
    super(props);

    const stateFromRedux = props.bloomkidzNewClient[this.getTrackIndex()] || {};

    this.state = {
      guardianTwoFirstName: '',
      guardianTwoLastName: '',
      guardianTwoEmail: '',
      guardianTwoTelephone: '',
      guardianTwoCell: '',
      errors: {
        guardianTwoEmail: '',
        guardianTwoTelephone: '',
        guardianTwoCell: '',
      },
      ...stateFromRedux,
    };
  }

  handleTextFieldChange = (e) => {
    const stateUpdate = { [e.target.name]: e.target.value };

    if (this.state.errors[e.target.name]) {
      stateUpdate.errors = {
        ...this.state.errors,
        [e.target.name]: '',
      };
    }

    this.setState(stateUpdate);
  };

  handleContinue = () => {
    const { location, updatePRO } = this.props;
    const {
      guardianTwoEmail,
      guardianTwoTelephone,
      guardianTwoCell,
    } = this.state;

    const skip = this.shouldDisableContinueButton();
    const fieldsToValidate = { guardianTwoEmail, guardianTwoCell };
    if (guardianTwoTelephone) fieldsToValidate.guardianTwoTelephone = guardianTwoTelephone;

    const { error } = formSchema.validate(fieldsToValidate, { abortEarly: false });

    if (error && skip) {
      const errors = {};

      error.details.forEach((error) => {
        errors[error.context.key] = 'Invalid value';
      });

      this.setState({ errors });
      return;
    }

    updatePRO({
      type: 'bloomkidzNewClient',
      position: this.getTrackIndex(),
      value: { ...this.state },
    });

    this.forwardWithQuery(location.query);
  }

  getButtonText = () => {
    const skip = this.shouldDisableContinueButton();

    if (skip) {
      return 'Next';
    }

    return 'Skip';
  }

  shouldDisableContinueButton = () => {
    const { state } = this;
    const { errors } = state;
    // if at least one of the required fields is filled out, and at least one
    // is empty, disable the continue button.
    const requiredFields = [
      'guardianTwoFirstName',
      'guardianTwoLastName',
      'guardianTwoEmail',
      'guardianTwoCell',
    ];
    let requiredFieldCount = 0;

    requiredFields.forEach((field) => {
      if (state[field]) requiredFieldCount++;
    });

    if (requiredFieldCount === 0) {
      return false;
    }
    if (requiredFieldCount !== requiredFields.length && requiredFieldCount !== 0) {
      return true;
    }

    if (errors.guardianTwoCell || errors.guardianTwoEmail || errors.guardianTwoTelephone) {
      return true;
    }

    return false;
  };

  render() {
    const { classes, router } = this.props;
    const {
      guardianTwoFirstName,
      guardianTwoLastName,
      guardianTwoEmail,
      guardianTwoTelephone,
      guardianTwoCell,
      errors,
    } = this.state;

    return (
      <BloomkidzLayout
        primaryBtnOnTouchTap={this.handleContinue}
        primaryBtnLabel={this.getButtonText()}
        primaryBtnDisabled={this.shouldDisableContinueButton()}
      >
        <AppBar
          headerNode="Guardian Information"
          backButtonOnClick={() => router.goBack()}
        />
        <IconWrapper>
          <UserGroup />
        </IconWrapper>
        <div className={classes.pageContent}>
          <div>Parent/Guardian 2</div>
          <div className={classes.paddingHorizontal}>
            <div className={classes.textFieldWrapper}>
              <TextField
                placeholder="First Name"
                value={guardianTwoFirstName}
                onChange={this.handleTextFieldChange}
                name="guardianTwoFirstName"
                variant="standard"
                fullWidth
                inputProps={{ style: { fontSize: fontSizing.body } }}
              />
            </div>
            <div className={classes.textFieldWrapper}>
              <TextField
                placeholder="Last Name"
                value={guardianTwoLastName}
                onChange={this.handleTextFieldChange}
                name="guardianTwoLastName"
                variant="standard"
                fullWidth
                inputProps={{ style: { fontSize: fontSizing.body } }}
              />
            </div>
          </div>
          <div className={classes.inputSectionTitle}>
            Contact
          </div>
          <div className={classes.paddingHorizontal}>
            <div className={classes.textFieldWrapper}>
              <TextField
                placeholder="Email"
                value={guardianTwoEmail}
                onChange={this.handleTextFieldChange}
                name="guardianTwoEmail"
                variant="standard"
                fullWidth
                inputProps={{ style: { fontSize: fontSizing.body } }}
                error={errors.guardianTwoEmail}
                helperText={errors.guardianTwoEmail}
              />
            </div>
            <div className={classes.textFieldWrapper}>
              <TextField
                placeholder="Telephone Number (optional)"
                value={guardianTwoTelephone}
                onChange={this.handleTextFieldChange}
                name="guardianTwoTelephone"
                variant="standard"
                fullWidth
                inputProps={{ style: { fontSize: fontSizing.body } }}
                error={errors.guardianTwoTelephone}
                helperText={errors.guardianTwoTelephone}
              />
            </div>
            <div className={classes.textFieldWrapper}>
              <TextField
                placeholder="Cell Number"
                value={guardianTwoCell}
                onChange={this.handleTextFieldChange}
                name="guardianTwoCell"
                variant="standard"
                fullWidth
                inputProps={{ style: { fontSize: fontSizing.body } }}
                error={errors.guardianTwoCell}
                helperText={errors.guardianTwoCell}
              />
            </div>
          </div>
        </div>
      </BloomkidzLayout>
    );
  }
}

BloomkidzNewClient3.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  updatePRO: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const {
    proForms: { bloomkidzNewClient },
  } = state;

  return { bloomkidzNewClient };
};

export default connect(mapStateToProps, { updatePRO })(withStyles(styles)(BloomkidzNewClient3));
