import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  iconRow: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 20,
    marginTop: 20,
  },
  iconWrapper: {
    height: 100,
    width: 100,
  },
};

const CenteredIcon = ({ children, classes }) => (
  <div className={classes.iconRow}>
    <div className={classes.iconWrapper}>
      {children}
    </div>
  </div>
);

CenteredIcon.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CenteredIcon);
