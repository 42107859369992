import React from 'react';
import {
  FormControlLabel,
  LinearProgress,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import AppBar from '../components/app-bar';
import Page from './page';
import QuestionLayout from '../layouts/question';
import { title, instructions, options, nextBtn } from '../lib/i18next';
import { colors } from '../lib/styles';
import { updatePRO } from '../state/pro-forms';

const styles = {
  descriptionText: {
    fontSize: 25,
    fontStyle: 'italic',
  },
  formControlLabelRoot: {
    alignItems: 'start',
    marginTop: 5,
    '& .MuiSvgIcon-root': {
      height: 20,
      width: 20,
    },
  },
  instructionText: {
    fontSize: 29,
    fontWeight: 'bold',
  },
  questionnaireContainer: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 20,
  },
  radio: {
    paddingTop: 10,
  },
  radioLabel: {
    fontSize: 29,
  },
  textField: {
    marginLeft: 28,
    '& input': {
      fontSize: 29,
    },
    '& label': {
      fontSize: 29,
    },
  },
};

class PatientDemographics4 extends Page {
  constructor(props) {
    super(props);

    this.state = {
      occupationStatus: '',
    };
  }

  handleChangeOccupationStatus = (e) => {
    this.setState({ occupationStatus: e.target.value });
  };

  handleClickNext = () => {
    const { occupationStatus } = this.state;

    this.props.updatePRO({
      type: 'patientDemographics',
      position: this.getTrackIndex(),
      value: { occupationStatus },
    });

    this.forwardWithQuery(this.props.location.query);
  }

  render() {
    const { classes } = this.props;
    const { occupationStatus } = this.state;

    const continueDisabled = !occupationStatus;

    return (
      <QuestionLayout
        primaryBtnLabel={nextBtn()}
        primaryBtnOnTouchTap={this.handleClickNext}
        primaryBtnDisabled={continueDisabled}
        style={{ backgroundColor: colors.questionBackground }}
      >
        <AppBar
          headerNode={title('demographics4')}
          backButtonOnClick={() => this.props.router.goBack()}
        />
        <LinearProgress
          variant="determinate"
          value={(this.getCurrentPage() / this.getTotalPages()) * 100}
          classes={{ bar: classes.bar }}
        />
        <div className={classes.questionnaireContainer}>
          <div className={classes.instructionText}>
            {instructions('demographics4')}
          </div>
          <RadioGroup value={occupationStatus} onChange={this.handleChangeOccupationStatus}>
            <FormControlLabel
              value="Employed 40 hrs per week"
              label={(
                <div>
                  <div>{options('employed', 'demographics4')}</div>
                  <div className={classes.descriptionText}>({options('employedFull', 'demographics4')})</div>
                </div>
              )}
              control={<Radio classes={{ root: classes.radio }} />}
              classes={{ root: classes.formControlLabelRoot, label: classes.radioLabel }}
            />
            <FormControlLabel
              value="Employed 1-39 hrs per week"
              label={(
                <div>
                  <div>{options('employed', 'demographics4')}</div>
                  <div className={classes.descriptionText}>({options('employedPart', 'demographics4')})</div>
                </div>
              )}
              control={<Radio classes={{ root: classes.radio }} />}
              classes={{ root: classes.formControlLabelRoot, label: classes.radioLabel }}
            />
            <FormControlLabel
              value="Not employed but looking"
              label={(
                <div>
                  <div>{options('notEmployed', 'demographics4')}</div>
                  <div className={classes.descriptionText}>({options('notEmployedLooking', 'demographics4')})</div>
                </div>
              )}
              control={<Radio classes={{ root: classes.radio }} />}
              classes={{ root: classes.formControlLabelRoot, label: classes.radioLabel }}
            />
            <FormControlLabel
              value="Not employed and not looking"
              label={(
                <div>
                  <div>{options('notEmployed', 'demographics4')}</div>
                  <div className={classes.descriptionText}>({options('notEmployedNotLooking', 'demographics4')})</div>
                </div>
              )}
              control={<Radio classes={{ root: classes.radio }} />}
              classes={{ root: classes.formControlLabelRoot, label: classes.radioLabel }}
            />
            <FormControlLabel
              value="Retired"
              label={options('retired', 'demographics4')}
              control={<Radio classes={{ root: classes.radio }} />}
              classes={{ root: classes.formControlLabelRoot, label: classes.radioLabel }}
            />
            <FormControlLabel
              value="Disabled"
              label={(
                <div>
                  <div>{options('disabled', 'demographics4')}</div>
                  <div className={classes.descriptionText}>({options('disabledDetails', 'demographics4')})</div>
                </div>
              )}
              control={<Radio classes={{ root: classes.radio }} />}
              classes={{ root: classes.formControlLabelRoot, label: classes.radioLabel }}
            />
          </RadioGroup>
        </div>
      </QuestionLayout>
    );
  }
}

export default connect(null, { updatePRO })(withStyles(styles)(PatientDemographics4));
