import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Switch,
} from '@material-ui/core';

import { colors } from '../lib/styles';
import { logEvent } from '../lib/amplitude';

const baseStyles = {
  listItem: {
    color: colors.primaryColor,
  },
};

class ClinicListItem extends Component {
  constructor(props) {
    super(props);
    this.handleToggle = this.handleToggle.bind(this);
  }

  handleToggle() {
    logEvent('Toggle to Disable data Sharing');
    const { allow_data_access, clinic_id } = this.props.item;
    this.props.onEditClinic({ clinic_id, allow_data_access: !allow_data_access });
  }

  render() {
    const { item } = this.props;
    return (
      <ListItem>
        <ListItemIcon>
          <Switch
            checked={item.allow_data_access}
            color="primary"
            onChange={this.handleToggle}
          />
        </ListItemIcon>
        <ListItemText style={baseStyles.listItem} primary={item.name} />
      </ListItem>
    );
  }
}

ClinicListItem.propTypes = {
  item: PropTypes.object.isRequired,
  onEditClinic: PropTypes.func.isRequired,
  router: PropTypes.object.isRequired,
};

export default ClinicListItem;
