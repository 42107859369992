import React from 'react';
import PropTypes from 'prop-types';
import { IconButton, LinearProgress } from '@material-ui/core/';
import MenuIcon from '@material-ui/icons/Menu';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { browserHistory } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';

import AppBar from '../components/app-bar';
import Page from './page';
import { colors, fontSizing } from '../lib/styles';
import Logo from '../components/logo';
import { submitPRO } from '../state/pro-forms';
import QuestionLayout from '../layouts/question';

const styles = {
  bodyText: {
    marginTop: 20,
  },
  header: {
    fontSize: fontSizing.h3,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  linearProgress: {
    background: colors.lightBrightBlue,
  },
  pageContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    backgroundColor: colors.questionBackground,
  },
  pageContent: {
    fontSize: fontSizing.body,
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: 700,
    paddingLeft: 10,
    paddingRight: 10,
    backgroundColor: colors.questionBackground,
  },
};

class VitalCoreBenchHr7 extends Page {
  constructor(props) {
    super(props);

    const vitalCoreVisitedInstructions = window.localStorage.getItem('vitalCoreVisitedInstructions');
    const historyAction = props.router.location.action;

    if (props.user.skip_vital_core_instructions && !vitalCoreVisitedInstructions && historyAction === 'PUSH') {
      this.forwardWithQuery(props.location.query);
    } else if (props.user.skip_vital_core_instructions && !vitalCoreVisitedInstructions && historyAction === 'POP') {
      this.props.router.goBack();
    }
  }

  render() {
    const { classes } = this.props;

    return (
      <QuestionLayout
        primaryBtnLabel="Next"
        primaryBtnOnTouchTap={() => { this.forwardWithQuery(this.props.location.query); }}
        style={{ backgroundColor: colors.questionBackground }}
      >
        <div className={classes.pageContainer}>
          <div className={classes.pageContentContainer}>
            <AppBar
              headerNode="Instructions"
              backButtonOnClick={() => this.props.router.goBack()}
              rightNode={(
                <IconButton onClick={() => browserHistory.push('/core-vital-user-manual-menu')}>
                  <MenuIcon fontSize="large" />
                </IconButton>
              )}
            />
            <LinearProgress
              variant="determinate"
              value={(this.getCurrentPage() / this.getTotalPages()) * 100}
              classes={{ bar: classes.linearProgress }}
            />
            <div
              className={classes.pageContent}
            >
              <Logo />
              <div className={classes.header}>
                Important
              </div>
              <p>
                {'Do not use the browser navigation buttons '}
                <span className={classes.noLineWrap}>(<FontAwesomeIcon icon={faAngleLeft} className={classes.arrowIcons} />  <FontAwesomeIcon icon={faAngleRight} className={classes.arrowIcons} />)</span>
                {' once you are on the video collection screen.'}
              </p>
            </div>
          </div>
        </div>
      </QuestionLayout>
    );
  }
}

VitalCoreBenchHr7.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const { user } = state;

  return { user };
};

export default connect(mapStateToProps, { submitPRO })(withStyles(styles)(VitalCoreBenchHr7));
