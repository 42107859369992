const en = {
  eq5D3LNotificationPage: {
    introHeaderText: 'Patient Reported Outcomes',
    introBodyText: 'requests that you complete the EQ5D-3L',
    loggedOutSuccessText: `<p>Thank you for submitting your EQ5D-3L Assessment. This information is important to your care. You may now close this window.</p>
    
    <p style={{ fontSize: '1.2rem', marginTop: '50px' }}>EQ-5D™ is a trade mark of the EuroQol Research Foundation.</p>`,
    loggedInSuccessText: `Successful EQ5D-3L Report!
    
    <p style={{ fontSize: '1.2rem', marginTop: '50px' }}>EQ-5D™ is a trade mark of the EuroQol Research Foundation.</p>`,
  },
};

const es = {
  eq5D3LNotificationPage: {
    introHeaderText: 'Acceso Reportados por la/el Paciente',
    introBodyText: 'solicita que completes el cuestionario de calidad de vida relacionada con la salud.',
    loggedOutSuccessText: `
   <p>Gracias por enviar su cuestionario de calidad de vida relacionada con la salud.</p>

   <p>Esta información solo se utiliza con fines de investigación para obtener más información sobre la población en riesgo de sufrir complicaciones por heridas en las extremidades inferiores.</p>

   <p>Si experimenta dolor o malestar extremo, <strong>comuníquese con su médico de atención primaria o busque atención médica de emergencia.</strong></p>

   <p>Ahora puedes cerrar esta ventana.</p> 
    `,
    loggedInSuccessText: `
    <p>Gracias por enviar su cuestionario de calidad de vida relacionada con la salud.</p>
 
    <p>Esta información solo se utiliza con fines de investigación para obtener más información sobre la población en riesgo de sufrir complicaciones por heridas en las extremidades inferiores.</p>
 
    <p>Si experimenta dolor o malestar extremo, <strong>comuníquese con su médico de atención primaria o busque atención médica de emergencia.</strong></p>
     `,
  },
};

export default { en, es };
