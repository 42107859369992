import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { TripOrigin, Lens } from '@material-ui/icons';
import { includes } from 'lodash';

import Page from './page';
import { colors } from '../lib/styles';
import QuestionLayout from '../layouts/question';
import { updatePRO } from '../state/pro-forms';
import DisableSessionTimeout from '../components/disable-session-timeout';

const { questionBackground } = colors;

const styles = {
  contentWrapper: {
    flexGrow: 1,
    overflowX: 'hidden',
    position: 'relative',
  },
  instructions: {
    color: colors.errorRed,
    fontSize: '18px',
    margin: '20px 40px 0px 40px',
    textAlign: 'center',
  },
  pageIndicator: {
    fontSize: '1.4rem',
  },
  questionnaire: {
    display: 'flex',
    flexDirection: 'column',
    margin: '50px auto',
    width: '80%',
    position: 'relative',
    fontSize: '16px',
  },
  formGroup: {
    margin: '25px',
  },
  helperText: {
    color: colors.black,
    fontSize: '20px',
    textAlign: 'center',
  },
  mainPoint: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '20px',
  },
  subPoint: {
    display: 'flex',
    marginLeft: '20px',
    marginBottom: '20px',
    alignItems: 'center',
  },
  icon: {
    marginRight: '10px',
  },
};

class Covid19_7 extends Page {
  state = {
    flag: false,
  };

  componentDidMount() {
    const checkAffirmation = includes(this.props.covid19, 'Yes');
    const checkAffirmation2 = includes(this.props.covid19[5], 'Yes');
    const checkSymptoms = this.props.covid19[3][0] !== 'None';
    const checkTravel = this.props.covid19[5][2].length > 0;
    if (checkAffirmation || checkAffirmation2 || checkSymptoms || checkTravel) {
      this.setState({ flag: true });
    }
  }

  render() {
    let body = (
      <div style={styles.contentWrapper}>
        <div style={styles.questionnaire}>
          <div style={{ fontWeight: 600, marginBottom: '20px' }}>You currently aren’t displaying known symptoms of COVID-19.  Please continue to take recommended preventive actions:</div>
          <div style={styles.mainPoint}><Lens style={styles.icon} />Wash your hands frequently with soap and water for a minimum of 20 seconds.</div>
          <div style={styles.mainPoint}><Lens style={styles.icon} />Avoid touching your eyes, nose, and mouth.</div>
          <div style={styles.mainPoint}><Lens style={styles.icon} />Stay home when you are sick.</div>
          <div style={styles.mainPoint}><Lens style={styles.icon} />Cover your cough or sneeze with a tissue, then throw the tissue in the trash.</div>
          <div style={styles.mainPoint}><Lens style={styles.icon} />Clean and disinfect frequently touch objects and surfaces.</div>
          <div style={{ fontWeight: 600, marginBottom: '20px' }}>In case of an outbreak in your community, protect yourself and others:</div>
          <div style={styles.mainPoint}><Lens style={styles.icon} />Stay home and speak to your healthcare provider if you develop, fever, cough, or shortness of breath.</div>
          <div style={styles.mainPoint}><Lens style={styles.icon} />If you develop emergency warning signs for COVID-19 get medical attention immediately*.</div>
          <div style={styles.subPoint}><TripOrigin style={styles.icon} />Difficulty of breathing or shortness of breath</div>
          <div style={styles.subPoint}><TripOrigin style={styles.icon} />Persistent pain or pressure in the chest</div>
          <div style={styles.subPoint}><TripOrigin style={styles.icon} />New confusion or inability to arouse</div>
          <div style={styles.subPoint}><TripOrigin style={styles.icon} />Bluish lips or face</div>
          <div style={styles.subPoint}><TripOrigin style={styles.icon} />*This list not all inclusive.  Please consult your medical provider for any other symptom that is sever or concerning.</div>
          <div style={styles.mainPoint}><Lens style={styles.icon} />Keep away from others who are sick.</div>
          <div style={styles.mainPoint}><Lens style={styles.icon} />Limit close contact with others as much as possible (about 6 feet).</div>
        </div>
      </div>
    );
    if (this.state.flag) {
      body = (
        <div style={styles.contentWrapper}>
          <div style={styles.instructions}>
            STOP! ACTION REQUIRED
          </div>
          <div style={styles.questionnaire}>
            <div style={{ fontWeight: 600, marginBottom: '20px' }}>You are currently displaying known symptoms of COVID-19.  Please do the following:</div>
            <div style={styles.mainPoint}><Lens style={styles.icon} />Call your healthcare provider for medical advice.</div>
            <div style={styles.subPoint}><TripOrigin style={styles.icon} />Call ahead before visiting your doctor</div>
            <div style={styles.mainPoint}><Lens style={styles.icon} />If you’re mildly ill with COVID-19, stay home, you should be able to recover at home.</div>  
            <div style={styles.subPoint}><TripOrigin style={styles.icon} />Do not leave, except to get medical care.</div>
            <div style={styles.subPoint}><TripOrigin style={styles.icon} />Do not visit public places.</div>
            <div style={styles.mainPoint}><Lens style={styles.icon} />Avoid public transportation, including ridesharing, or taxis.</div>
          </div>
        </div>
      );
    }
    return (
      <QuestionLayout
        style={{ backgroundColor: questionBackground }}
      >
        <DisableSessionTimeout />
        {body}
      </QuestionLayout>
    );
  }
}

function mapStateToProps(state) {
  const { proForms: { covid19 } } = state;
  return { covid19 };
}

Covid19_7.propTypes = {
  covid19: PropTypes.array.isRequired,
  updatePRO: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { updatePRO })(withStyles(styles)(Covid19_7));
