import React from 'react';
import PropTypes from 'prop-types';

import { calendarStyles as styles } from '../lib/styles';

const CalendarDayLabel = ({ day }) => {
  return (
    <div style={styles.calendarItemWrapper}>
      <div style={styles.heightExpander} />
      <div style={{ ...styles.calendarItem, ...styles.dayNames }}>{day}</div>
    </div>
  );
};
CalendarDayLabel.propTypes = {
  day: PropTypes.string.isRequired,
};

export default CalendarDayLabel;
