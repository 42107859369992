import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, IconButton, Button, Typography, LinearProgress } from '@material-ui/core';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import AddIcon from '@material-ui/icons/Add';

import moment from 'moment';
import DashboardTop from '../components/dashboard-top';
import AppBar from '../components/app-bar';
import Page from './page';
import Layout from '../layouts/common';
import Continue from '../components/continue';
import { colors } from '../lib/styles';

import { updatePRO } from '../state/pro-forms';

const baseStyles = {
  appBar: {
    height: '8.75rem',
    paddingLeft: '1.5rem',
    paddingRight: '1.5rem',
  },
  backButton: {
    left: '-1.25rem',
    height: '3.6875rem',
    padding: 0,
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    width: '3.6875rem',
  },
  backButtonIcon: {
    height: '3.6875rem',
    width: '3.6875rem',
  },
  bigArrowsLightBlue: {
    width: '60px',
    height: '60px',
    color: colors.primaryHighlight,
  },
  confirmBtn: {
    marginBottom: '7.5vw',
  },
  errorText: {
    fontSize: '1.5rem',
  },
  datePickerTextField: {
    color: 'black',
    width: '80vw',
  },
  headachePainTypeSect: {
    alignItems: 'center',
    backgroundColor: colors.lightBrightBlue,
    display: 'flex',
    height: '95px',
    justifyContent: 'space-between',
  },
  header: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    width: '100%',
  },
  headerTitle: {
    color: colors.black,
    display: 'inline-block',
    fontWeight: 'bold',
    fontSize: '1.8rem',
    textAlign: 'left',
    width: '100%',
  },
  intensityHeader: {
    background: colors.primaryColor,
    color: colors.white,
    fontSize: '2rem',
    padding: '2rem 0',
    textAlign: 'center',
  },
  mainContent: {
    flexGrow: 1,
  },
  content: {
    textAlign: 'left',
    padding: '20px',
  },
  nextButton: {
    position: 'absolute',
    right: 5,
    textAlign: 'right',
    top: '50%',
    transform: 'translateY(-50%)',
  },
  nextButtonLabel: {
    color: colors.black,
    fontSize: '1.6rem',
    padding: 0,
    textTransform: 'none',
  },
  red: {
    color: colors.errorRed,
  },
  rootContainer: {
    display: 'flex',
    minHeight: '100%',
  },
  slider: {
    background: colors.primaryColor,
    marginBottom: '2rem',
    paddingBottom: '1rem',
  },
  sliderHeader: {
    color: colors.white,
    fontSize: '1.5rem',
  },
  sliderWrapper: {
    paddingTop: '2.5rem',
  },
  sliderWrapperContainer: {
    height: '6.5rem',
    marginTop: '2rem',
    position: 'relative',
  },
  triggersQuestion: {
    fontSize: '1.5rem',
    marginBottom: '1rem',
    marginTop: '4rem',
  },
  list: {
    borderRadius: 0,
    width: '100%',
    backgroundColor: 'white',
  },
};

const AppBarContinueButton = props => (
  <Button
    onClick={props.onClick}
    style={{ ...baseStyles.nextButton, ...baseStyles.nextButtonLabel }}
  >Next
  </Button>
);
AppBarContinueButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

class BodyPainPro6 extends Page {
  constructor(props) {
    super(props);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleNewPain = this.handleNewPain.bind(this);
    this.handleContinue = this.handleContinue.bind(this);
    this.handleClickBack = this.handleClickBack.bind(this);
  }

  componentWillMount() {
    const { bodyPain } = this.props;
    const painObject = {
      new_pain: bodyPain[0] === 'newPain',
      pain_types: bodyPain[2],
      intensity: bodyPain[3],
      ...bodyPain[4],
      locations: bodyPain[1].locations,
      pain_locations: bodyPain[1].pain_locations,
      pain_start_date: moment(),
      pain_end_date: moment(),
    };

    const oldPain = bodyPain[5] ? bodyPain[5] : [];
    this.setState({
      painArray: [...oldPain, painObject],
    });
  }

  handleDelete(obj) {
    const { painArray } = this.state;
    const array = [...painArray];

    const index = array.indexOf(obj);

    if (index > -1) {
      window.confirm(`Are you sure you want to delete this pain recording: ${obj.name}`) && array.splice(index, 1);

      this.setState({
        painArray: array,
      });
    }
  }

  async handleNewPain() {
    this.props.updatePRO({ type: 'bodyPain', position: this.getTrackIndex(), value: this.state.painArray });
    this.goToIndexWithQuery(1, this.props.location.query);
  }

  handleClickBack() {
    this.backwardWithQuery(this.props.location.query);
  }

  handleContinue() {
    this.props.updatePRO({ type: 'bodyPain', position: this.getTrackIndex(), value: this.state.painArray });
    this.forwardWithQuery(this.props.location.query);
  }

  render() {
    const { painArray } = this.state;
    
    return (
      <Layout style={baseStyles.rootContainer}>
        <div style={baseStyles.mainContent}>
          <AppBar
            backButtonOnClick={() => this.props.router.goBack()}
            headerNode="Pain Locations"
            rightNode={(
              <AppBarContinueButton
                onClick={this.handleContinue}
              />
            )}
          />
          <LinearProgress
            variant="determinate"
            value={(this.getCurrentPage() / this.getTotalPages()) * 100}
          />
          <DashboardTop
            title=""
            paragraph='Tap the "+" sign to add another pain location'
          >
            <List style={baseStyles.list}>
              <ListItem key={0} role={undefined} button onClick={() => { this.handleNewPain(); }}>
                <ListItemIcon>
                  <AddIcon style={{ fontSize: 28 }} />
                </ListItemIcon>
                <ListItemText
                  id="checkbox-list-label-0"
                  primary={(
                    <Fragment>
                      <Typography
                        component="span"
                        variant="h4"
                        color="textPrimary"
                      >
                              Add a Pain Location
                      </Typography>
                    </Fragment>
                    )}
                />
              </ListItem>
              {painArray.map((value, index) => {
                const labelId = `checkbox-list-label-${index + 1}`;

                return (
                  <ListItem key={value.name} role={undefined} button style={{ borderTop: '1px solid rgba(0, 0, 0, 0.12)' }}>
                    <ListItemIcon />
                    <ListItemText
                      id={labelId}
                      primary={(
                        <React.Fragment>
                          <Typography
                            component="span"
                            variant="h4"
                            color="textPrimary"
                          >
                            { value.locations.length === 1 ? `${value.locations[0].name}` : `${value.locations[0].name} to ${value.locations[1].name}` }
                          </Typography>
                        </React.Fragment>
                        )}
                    />
                    <ListItemSecondaryAction>
                      <IconButton edge="end" aria-label="comments" onClick={() => { this.handleDelete(value); }}>
                        <RemoveCircleIcon style={{ color: 'red', fontSize: 28 }} />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                );
              })}
            </List>

            <Continue
              text="Confirm"
              onClick={() => {
                this.handleContinue();
              }}
              btnStyle={{ marginTop: '50px', alignSelf: 'center' }}
            />
          </DashboardTop>
        </div>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  const { user, proForms: { bodyPain } } = state;
  return { user, bodyPain };
}

BodyPainPro6.proptypes = {
  user: PropTypes.object.isRequired,
  bodyPain: PropTypes.array.isRequired,
  route: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, { updatePRO })(BodyPainPro6);
