import React from 'react';
import { connect } from 'react-redux';
import { get, pickBy } from 'lodash';
import Page from './page';
import Layout from '../layouts/common';
import AppBar from '../components/app-bar';
import MigraineSymptomsPresentation from '../components/migraine-symptoms-presentation';

import { browseMigraineSymptoms } from '../state/app-data';
import {
  browseMigraineSymptomsHx,
  addMigraineSymptomsHx,
  deleteMigraineSymptomsHx,
  editMigraineSymptomsHx,
} from '../state/migraine-symptoms-hx';
import { updateAvatarUrl } from '../state/user';

class MigraineSymptoms extends Page {
  constructor(props) {
    super(props);

    const { migraine_hx_id, migraineSymptomsHx } = props;
    const selectedMigraineSymptoms = migraine_hx_id ? { ...migraineSymptomsHx } : {};

    this.handleAddItem = this.handleAddItem.bind(this);
    this.handleRemoveItem = this.handleRemoveItem.bind(this);
    this.handleContinue = this.handleContinue.bind(this);
    this.handleBack = this.handleBack.bind(this);

    this.state = {
      selectedMigraineSymptoms,
    };
  }

  componentWillMount() {
    const { migraine_hx_id } = this.props.location.query;
    this.props.browseMigraineSymptomsHx(migraine_hx_id);
    if (!this.props.migraineSymptoms) {
      this.props.browseMigraineSymptoms();
    }
  }

  componentWillReceiveProps(nextProps) {
    const { selectedMigraineSymptoms } = this.state;
    const { migraineSymptomsHx } = nextProps;
    if (migraineSymptomsHx !== this.props.migraineSymptomsHx) {
      this.setState({
        selectedMigraineSymptoms,
      });
    }
  }

  handleAddItem(migraine_symptoms_id) {
    const { migraine_hx_id } = this.props.location.query;
    const newItem = {
      migraine_hx_id,
      migraine_symptoms_id,
    };
    this.props.addMigraineSymptomsHx(newItem);
  }

  handleRemoveItem(migraineSymptomId) {
    const { migraine_hx_id } = this.props.location.query;
    this.props.deleteMigraineSymptomsHx(migraine_hx_id, migraineSymptomId);
  }

  handleContinue() {
    this.forwardWithQuery(this.props.location.query);
  }

  handleBack() {
    this.backwardWithQuery(this.props.location.query);
  }

  onClickBackButton = () => {
    this.backward();
  }

  render() {
    const { migraineSymptoms } = this.props;
    return (
      <Layout>
        <AppBar
          backButtonOnClick={() => this.props.router.goBack()}
          headerNode="Migraine Symptoms"
        />
        <MigraineSymptomsPresentation
          migraineSymptoms={migraineSymptoms}
          selectedItems={this.props.migraineSymptomsHx}
          onAddItem={this.handleAddItem}
          onRemoveItem={this.handleRemoveItem}
          onContinue={this.handleContinue}
        />

      </Layout>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const {
    migraineSymptomsHx,
    appData: {
      migraineSymptoms,
    },
    user,
  } = state;

  const migraine_hx_id = Number(get(ownProps, 'location.query.migraine_hx_id', null));

  return {
    migraine_hx_id,
    migraineSymptoms,
    migraineSymptomsHx: pickBy(migraineSymptomsHx.data, i => i.migraine_hx_id === migraine_hx_id),
    user,
  };
}

MigraineSymptoms.defaultProps = {
  aggravating_factors: {},
  painHxMigraineSymptoms: {},
};

export default connect(mapStateToProps, {
  browseMigraineSymptoms,
  browseMigraineSymptomsHx,
  addMigraineSymptomsHx,
  editMigraineSymptomsHx,
  deleteMigraineSymptomsHx,
  updateAvatarUrl,
})(MigraineSymptoms);
