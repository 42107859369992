import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  LinearProgress,
  Select,
  MenuItem,
  Radio,
  RadioGroup,
  Slider,
  FormControlLabel,
  IconButton,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';

import AppBar from '../components/app-bar';
import Continue from '../components/continue';

import { Camera } from '../lib/icons';
import { HrReader } from '../lib/massimo';

import Page from './page';

import { updatePRO } from '../state/pro-forms';

const styles = {
  alert: {
    fontSize: '2rem',
    color: 'red',
  },
  connecting: {
    fontSize: '2rem',
    color: 'blue',
  },
  formControlLabelRoot: {
    alignItems: 'start',
    marginTop: 5,
    '& .MuiSvgIcon-root': {
      height: 20,
      width: 20,
    },
  },
  iconContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    flexGrow: 0,
    flexShrink: 0,
    width: '100%',
    color: '#000',
    paddingTop: 10,
  },
  iconWrapper: {
    width: 100,
  },
  radioLabel: {
    fontSize: '2rem',
  },
  select: {
    marginBottom: 20,
    marginTop: 10,
  },
  titleGroup: {
    margin: 25,
  },
  text: {
    fontSize: '2rem',
  },
  sliderValueLabel: {
    fontSize: 12,
  },
  smallerText: {
    fontSize: '1.5rem',
  },
  nextBtn: {
    textAlign: 'center',
    width: '100%',
    top: '80%',
  },
  optionsButton: {
    marginTop: 20,
    fontSize: '3rem',
  },
};

class FullDataCollection2 extends Page {
  constructor(props) {
    super(props);
    this.state = {
      connecting: false,
      deviceError: null,
      seconds: 120,
      FPS: 'S-METHOD',
      resolution: '640x480',
      hasDevice: true,
      reader: null,
      imgEncoding: 'image/jpeg',
      jpegQuality: 0.95,
      cropFace: true,
      showOptions: false,
    };
  }

  handleContinue = async () => {
    const { updatePRO, location: { query } } = this.props;

    let reader;
    let deviceError;
    try {
      this.setState({ deviceError: null, connecting: true });
      reader = await new HrReader();
      this.setState({ reader, connecting: false });
      updatePRO({
        type: 'fullDataCollection',
        position: this.getTrackIndex(),
        value: { ...this.state, hasDevice: true },
      });
      this.forwardWithQuery(query);
    } catch (error) {
      if (error.code && error.code === 902) {
        deviceError = 'Bluetooth pairing failed.  Please tap the I\'M READY button again.';
      } else {
        deviceError = error.toString();
      }
    }

    if (deviceError) {
      if (reader) {
        await reader.disconnect();
      }
      return this.setState({ deviceError, connecting: false });
    }
  }

  handleContinueNoDevice = async () => {
    const { updatePRO, location: { query } } = this.props;
    this.setState({ deviceError: null, connecting: false, hasDevice: false });
    updatePRO({
      type: 'fullDataCollection',
      position: this.getTrackIndex(),
      value: { ...this.state, hasDevice: false },
    });
    this.forwardWithQuery(query);
  }

  render() {
    const { classes } = this.props;
    const {
      connecting,
      imgEncoding,
      FPS,
      seconds,
      jpegQuality,
      cropFace,
      showOptions,
    } = this.state;

    return (
      <Fragment>
        <AppBar
          headerNode="Lighting Conditions"
          noLeftNode={true}
        />
        <LinearProgress
          variant="determinate"
          value={(this.getCurrentPage() / this.getTotalPages()) * 100}
        />
        <div className={classes.iconContainer}>
          <div className={classes.iconWrapper}>
            <Camera />
          </div>
        </div>

        <div className={classes.titleGroup}>
          <div className={classes.text}>We will be recording for
            {' '}
            <span>{showOptions ? (
              <Select
                value={seconds}
                onChange={(evt) => {
                  this.setState({ seconds: evt.target.value });
                }}
                className={classes.select}
              >
                <MenuItem value={120}><span className={classes.text}>120 seconds</span></MenuItem>
                <MenuItem value={90}><span className={classes.text}>90 seconds</span></MenuItem>
                <MenuItem value={60}><span className={classes.text}>60 seconds</span></MenuItem>
                <MenuItem value={30}><span className={classes.text}>30 seconds</span></MenuItem>
              </Select>) : (
              <span>{seconds} seconds</span>
            )}
            </span> <br />please make sure to do the following:
          </div>
          <ul className={classes.text}>
            <li>Sit comfortably</li>
            <li>Stay as still as possible</li>
            <li>Good lighting</li>
            <li>MightySat device on finger and turned on</li>
          </ul>
          {showOptions ? (
            <div className={classes.smallerText}>Record at
              {' '}
              <span>
                <Select
                  value={FPS}
                  onChange={(evt) => {
                    this.setState({ FPS: evt.target.value });
                  }}
                >
                  <MenuItem value="S-METHOD"><span className={classes.smallerText}>S -Method (video frame callback)</span></MenuItem>
                  <MenuItem value={60}><span className={classes.smallerText}>60 frames/sec</span></MenuItem>
                  <MenuItem value={45}><span className={classes.smallerText}>45 frames/sec</span></MenuItem>
                  <MenuItem value={30}><span className={classes.smallerText}>30 frames/sec</span></MenuItem>
                  <MenuItem value={25}><span className={classes.smallerText}>25 frames/sec</span></MenuItem>
                  <MenuItem value={20}><span className={classes.smallerText}>20 frames/sec</span></MenuItem>
                  <MenuItem value={15}><span className={classes.smallerText}>15 frames/sec</span></MenuItem>
                  <MenuItem value={10}><span className={classes.smallerText}>10 frames/sec</span></MenuItem>
                </Select>
              </span>
              <br />
            </div>
          ) : ''}

          <p className={classes.alert}>{this.state.deviceError}</p>
          <p className={classes.connecting}>{connecting ? 'connecting...' : ''}</p>
          {showOptions ? (<>
            <div>
              <span className={classes.text}>Image encoding</span>
              <RadioGroup
                aria-label="image encoding"
                value={imgEncoding}
                onChange={(evt) => {
                  this.setState({ imgEncoding: evt.target.value });
                }}
                row
              >
                <FormControlLabel
                  value="image/png"
                  control={<Radio />}
                  label="png"
                  classes={{ root: classes.formControlLabelRoot, label: classes.radioLabel }}
                />
                <FormControlLabel
                  value="image/jpeg"
                  control={<Radio />}
                  label="jpg"
                  classes={{ root: classes.formControlLabelRoot, label: classes.radioLabel }}
                />
              </RadioGroup>
              <div style={{ display: imgEncoding === 'image/jpeg' ? '' : 'none', marginTop: 10 }}>
                <span className={classes.smallerText}>JPG Quality</span>
                <Slider
                  aria-label="JPG Quality"
                  defaultValue={jpegQuality}
                  value={jpegQuality}
                  valueLabelDisplay="auto"
                  onChange={(evt, newValue) => {
                    this.setState({ jpegQuality: newValue });
                  }}
                  step={0.05}
                  min={0.2}
                  max={1.0}
                  classes={{ valueLabel: classes.sliderValueLabel }}
                />
              </div>
            </div>

            <div>
              <span className={classes.text}>Crop face</span>
              <RadioGroup
                aria-label="crop face"
                value={cropFace}
                onChange={(evt) => {
                  this.setState({ cropFace: evt.target.value === 'true' });
                }}
                row
              >
                <FormControlLabel
                  value={true}
                  control={<Radio />}
                  label="Yes"
                  classes={{ root: classes.formControlLabelRoot, label: classes.radioLabel }}
                />
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label="No"
                  classes={{ root: classes.formControlLabelRoot, label: classes.radioLabel }}
                />
              </RadioGroup>
            </div>
          </>) : ''}
        </div>

        <div className={classes.nextBtn}>
          {!connecting ? (
            <Continue
              text="I'm Ready"
              onClick={this.handleContinue}
            />
          ) : ''}
          {!connecting && showOptions ? (
            <Continue
              text="Continue without a Device"
              onClick={this.handleContinueNoDevice}
            />
          ) : ''}
        </div>
        <IconButton
          aria-label="show/hide"
          className={classes.optionsButton}
          onClick={() => {
            this.setState({ showOptions: !showOptions });
          }}
        >
          {showOptions ? (
            <CloseIcon />
          ) : (
            <AddIcon />
          )}
        </IconButton>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { user, proForms: { fullDataCollection } } = state;
  return { user, fullDataCollection };
}

FullDataCollection2.proptypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  updatePRO: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { updatePRO })(withStyles(styles)(FullDataCollection2));
