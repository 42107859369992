import React from 'react';
import { LinearProgress, TextField } from '@material-ui/core';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import PropTypes from 'prop-types';

import AppBar from '../components/app-bar';
import Page from './page';
import QuestionLayout from '../layouts/question';

import YesNoButtonSet from '../components/yes-no-button-set';
import { colors } from '../lib/styles';
import { updatePRO } from '../state/pro-forms';
import { Pills } from '../lib/icons';

const styles = {
  bar: {
    backgroundColor: colors.lightBrightBlue,
  },
  contentWrapper: {
    flexGrow: 1,
    overflowX: 'hidden',
    position: 'relative',
  },
  imgRow: {
    display: 'flex',
    justifyContent: 'center',
    padding: '15px 60px',
  },
  imageWrapper: {
    width: 100,
  },
  marginTop40: {
    marginTop: 40,
  },
  questionnaireContainer: {
    paddingLeft: 20,
    paddingRight: 20,
  },
  questionText: {
    fontSize: '2rem',
  },
  textFieldRoot: {
    marginTop: 10,
    width: 256,
    '& input': {
      fontSize: 18,
    },
    '& label': {
      fontSize: 18,
    },
  },
  textFieldWrapper: {
    justifyContent: 'center',
    display: 'flex',
    paddingTop: 20,
  },
};

const StyledTextField = withStyles(() => ({
  root: {
    '& .MuiInputBase-root': {
      fontSize: '16px',
    },
  },
}))(TextField);

class HypertensionAssessment5 extends Page {
  constructor(props) {
    super(props);

    this.state = {
      noMedicationsComment: '',
      timeMedicationsTaken: '08:00',
      tookMedications: null,
    };
  }

  handleChangeNoMedicationsComment = (e) => {
    this.setState({ noMedicationsComment: e.target.value });
  }

  handleChangeTimeMedicationsTaken = (e) => {
    const { value } = e.target;
    this.setState({
      timeMedicationsTaken: value,
    });
  };

  handleChangeTookMedications = (value) => {
    this.setState({ tookMedications: value });
  };

  handleClickNext = () => {
    const {
      noMedicationsComment,
      timeMedicationsTaken,
      tookMedications,
    } = this.state;

    const value = { tookMedications };

    if (tookMedications === 'Y') {
      const hour = timeMedicationsTaken.substring(0, 2);
      const minute = timeMedicationsTaken.substring(3);
      const time = moment().hour(hour).minute(minute);
      value.timeMedicationsTaken = time;
    } else if (noMedicationsComment) {
      value.noMedicationsComment = noMedicationsComment;
    }

    this.props.updatePRO({
      type: 'hypertensionAssessmentDaily',
      position: this.getTrackIndex(),
      value,
    });

    this.forwardWithQuery(this.props.location.query);
  }

  render() {
    const { classes } = this.props;
    const {
      noMedicationsComment,
      timeMedicationsTaken,
      tookMedications,
    } = this.state;

    const continueDisabled = tookMedications === null
      || (tookMedications === 'N' && !noMedicationsComment);

    return (
      <QuestionLayout
        primaryBtnLabel="Next"
        primaryBtnOnTouchTap={this.handleClickNext}
        primaryBtnDisabled={continueDisabled}
        style={{ backgroundColor: colors.questionBackground }}
      >
        <AppBar
          headerNode="Medication"
          backButtonOnClick={() => this.props.router.goBack()}
        />
        <LinearProgress
          variant="determinate"
          value={(this.getCurrentPage() / this.getTotalPages()) * 100}
          classes={{ bar: classes.bar }}
        />
        <div className={classes.contentWrapper}>
          <div className={classes.imgRow}>
            <div className={classes.imageWrapper}>
              <Pills />
            </div>
          </div>
          <div className={classes.questionnaireContainer}>
            <YesNoButtonSet
              isYes={tookMedications === 'Y'}
              isNo={tookMedications === 'N'}
              onClickNo={() => this.handleChangeTookMedications('N')}
              onClickYes={() => this.handleChangeTookMedications('Y')}
              questionText="Did you take your medications today?"
            />
            {tookMedications === 'Y' ? (
              <>
                <div className={`${classes.questionText} ${classes.marginTop40}`}>What time?</div>
                <div className={classes.textFieldWrapper}>
                  <StyledTextField
                    value={timeMedicationsTaken}
                    type="time"
                    defaultValue="08:00"
                    onChange={this.handleChangeTimeMedicationsTaken}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
              </>
            ) : null}
            {tookMedications === 'N' ? (
              <>
                <div className={`${classes.questionText} ${classes.marginTop40}`}>Why not?</div>
                <TextField
                  label="Tap here to answer"
                  onChange={this.handleChangeNoMedicationsComment}
                  classes={{ root: classes.textFieldRoot }}
                  value={noMedicationsComment}
                />
              </>
            ) : null}
          </div>
        </div>
      </QuestionLayout>
    );
  }
}

HypertensionAssessment5.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect(null, { updatePRO })(withStyles(styles)(HypertensionAssessment5));
