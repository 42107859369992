import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get, isNumber } from 'lodash';

import Page from './page';
import AppBar from '../components/app-bar';
import { colors } from '../lib/styles';
import QuestionLayout from '../layouts/question';
import ReactSlider from '../components/react-slider';
import { updatePRO, submitPRO } from '../state/pro-forms';
import LinearProgress from '../components/linear-progress';

const { questionBackground } = colors;

const styles = {
  image: {
    display: 'block',
    height: '150px',
    margin: '30px auto 45px',
  },
  instructions: {
    color: 'grey',
    fontSize: 13,
    margin: '30px 30px 10px',
    textAlign: 'center',
  },
  pageIndicator: {
    color: 'grey',
    display: 'inline-block',
    fontSize: 13,
  },
  slider: {
    margin: '0 20px',
  },
};

const instructions1 = 'Please rate your agreememt with the following statement on a scale of 0-10 '
  + '(0 being I wouldn\'t recommend the treatments and 10 being I would absolutely recommend): '
  + 'I would recommend MUA treatment to a friend or family member.';

const instructions2 = 'On a scale of 0-10 (0 being not satisfied at all '
  + 'and 10 being totally satisfied), how satisfied are you '
  + 'with your overall experience after receiving MUA?';

class MUA3 extends Page {
  state = {
    submitting: false,
  }

  onClickBackButton = () => {
    this.props.router.push('/notifications');
  }

  onSubmission = () => {
    if (this.state.submitting) return;
    const { user } = this.props;
    const { id } = this.props.location.query;
    this.setState({ submitting: true });
    submitPRO({ userId: user.id, id }, {
      pro_type: 'MUA',
      pro_data: { data: this.props.mua },
    }, user)
      .then(() => this.forward())
      .catch(() => this.setState({ submitting: false }));
  }

  handleChange = (value, position) => {
    this.props.updatePRO({
      type: 'mua',
      position,
      value,
    });
  }

  render() {
    const currentPage = this.getCurrentPage();
    const totalPages = this.getTotalPages();
    const value1 = get(this.props.mua, 4, null);
    const value2 = get(this.props.mua, 5, null);

    return (
      <QuestionLayout
        primaryBtnDisabled={!isNumber(value1) || !isNumber(value2)}
        primaryBtnLabel="Next"
        primaryBtnOnTouchTap={this.onSubmission}
        style={{ backgroundColor: questionBackground }}
      >
        <AppBar
          backButtonOnClick={() => this.props.router.goBack()}
          headerNode="Manipulation Under Anesthesia"
          rightNode={
            <div style={styles.pageIndicator}>{currentPage} of {totalPages}</div>
          }
        />
        <LinearProgress value={(currentPage / totalPages) * 100} />
        <div style={styles.instructions}>
          {instructions1}
        </div>
        <div style={styles.slider}>
          <ReactSlider
            max={10}
            min={0}
            minLabel="No Pain"
            maxLabel="Unbearable Pain"
            onChangeComplete={val => this.handleChange(val, 4)}
            showLabels={false}
            value={value1}
          />
        </div>
        <div style={styles.instructions}>
          {instructions2}
        </div>
        <div style={styles.slider}>
          <ReactSlider
            max={10}
            min={0}
            minLabel="No Pain"
            maxLabel="Unbearable Pain"
            onChangeComplete={val => this.handleChange(val, 5)}
            showLabels={false}
            value={value2}
          />
        </div>
      </QuestionLayout>
    );
  }
}

function mapStateToProps(state) {
  const { user, proForms: { mua } } = state;
  return { user, mua };
}

MUA3.propTypes = {
  mua: PropTypes.array.isRequired,
  updatePRO: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { updatePRO, submitPRO })(MUA3);
