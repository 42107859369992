import { promiseHandler, resetReducer } from 'cooldux';
import { get, isUndefined, isNil, omit, omitBy, pick } from 'lodash';

import { apiFetch } from '../lib/fetch';

const handlerOptions = { namespace: 'migraineHx', throwErrors: true };
const { browseStart, browseEnd, browseError, browseHandler } = promiseHandler('browse', 'migraineHx');
const { readStart, readEnd, readError, readHandler } = promiseHandler('read', 'migraineHx');
const { editStart, editEnd, editError, editHandler } = promiseHandler('edit', handlerOptions);
const { addStart, addEnd, addError, addHandler } = promiseHandler('add', handlerOptions);
const { deleteStart, deleteEnd, deleteError, deleteHandler } = promiseHandler('delete', 'migraineHx');

export function browseMigraineHx(start_time, end_time, opts = {}) {
  return function dispatcher(dispatch, getState) {
    const { limit } = opts;
    const selfId = get(getState(), 'user.id', null);
    const queryOptions = {
      query: omitBy({ start_time, end_time, populate: true, limit }, isNil),
    };
    const promise = apiFetch(`/users/${selfId}/migraine_hx`, queryOptions);
    return browseHandler(promise, dispatch);
  };
}

export function readMigraineHx(id) {
  return function dispatcher(dispatch, getState) {
    const filter = { query: { id } };
    const selfId = get(getState(), 'user.id', null);
    const promise = apiFetch(`/users/${selfId}/migraine_hx`, filter)
      .then(([res]) => res);
    return readHandler(promise, dispatch);
  };
}

export function editMigraineHx(update) {
  return function dispatcher(dispatch, getState) {
    const selfId = get(getState(), 'user.id', null);
    const body = pick(update, [
      'start_time',
      'end_time',
      'other_abative_rx',
      'other_abortive_rx',
      'rx_successful',
    ]);
    const options = {
      method: 'PUT',
      body: omitBy(body, isUndefined),
    };
    const promise = apiFetch(`/users/${selfId}/migraine_hx/${update.id}`, options);
    return editHandler(promise, dispatch);
  };
}

export function addMigraineHx(newMigraineHx) {
  return function dispatcher(dispatch, getState) {
    const selfId = get(getState(), 'user.id', null);
    const options = {
      method: 'POST',
      body: omitBy(newMigraineHx, isUndefined),
    };
    const promise = apiFetch(`/users/${selfId}/migraine_hx`, options);
    return addHandler(promise, dispatch);
  };
}

export function deleteMigraineHx(migraineHxId) {
  return function dispatcher(dispatch, getState) {
    const selfId = get(getState(), 'user.id', null);
    const options = {
      method: 'DELETE',
    };
    const promise = apiFetch(`/users/${selfId}/migraine_hx/${migraineHxId}`, options)
      .then(() => migraineHxId);

    return deleteHandler(promise, dispatch);
  };
}

const initialState = {
  browseError: null,
  readError: null,
  addError: null,
  editError: null,
  deleteError: null,
  browsePending: false,
  readPending: false,
  addPending: false,
  editPending: false,
  deletePending: false,
  data: {},
};

function finishBrowse(state, migraineHx) {
  const data = { ...state.data };
  migraineHx.forEach((u) => {
    data[u.id] = u;
  });
  return { ...state, data, browsePending: false, browseError: null };
}

function finishRead(state, item) {
  const data = { ...state.data, [item.id]: item };
  return { ...state, readPending: false, readError: null, data };
}

function finishEdit(state, item) {
  const data = { ...state.data, [item.id]: item };
  return { ...state, editPending: false, editError: null, data };
}

function finishAdd(state, item) {
  const data = { ...state.data, [item.id]: item };
  return { ...state, addPending: false, addError: null, data };
}

function finishDelete(state, migraineHxId) {
  return { ...state, deletePending: false, deleteError: null, data: omit(state.data, migraineHxId) };
}

const migraineHx = resetReducer(initialState, (state = initialState, action) => {
  switch (action.type) {
    case browseStart.type:
      return { ...state, browsePending: true };
    case browseEnd.type:
      return finishBrowse(state, action.payload);
    case browseError.type:
      return { ...state, browsePending: false, browseError: action.payload };
    case readStart.type:
      return { ...state, readPending: true };
    case readEnd.type:
      return finishRead(state, action.payload);
    case readError.type:
      return { ...state, readPending: false, readError: action.payload };
    case addStart.type:
      return { ...state, addPending: true };
    case addEnd.type:
      return finishAdd(state, action.payload);
    case addError.type:
      return { ...state, addPending: false, addError: action.payload };
    case deleteStart.type:
      return { ...state, deletePending: true };
    case deleteEnd.type:
      return finishDelete(state, action.payload);
    case deleteError.type:
      return { ...state, deletePending: false, deleteError: action.payload };
    case editStart.type:
      return { ...state, editPending: true };
    case editEnd.type:
      return finishEdit(state, action.payload);
    case editError.type:
      return { ...state, editPending: false, editError: action.payload };
    default:
      return state;
  }
});

export default migraineHx;
