import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { concat, filter } from 'lodash';

import { withStyles } from '@material-ui/core/styles';

import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';

import AppBar from '../components/app-bar';
import Continue from '../components/continue';

import { WaterDrop } from '../lib/icons';

import Page from './page';

import { submitPRO } from '../state/pro-forms';

const styles = {
  checkboxLabel: {
    fontSize: '22px',
  },
  checkboxTitle: {
    display: 'block',
    paddingTop: 15,
    fontSize: '22px',
  },
  checkboxCaption: {
    display: 'block',
    fontSize: '1.5rem',
  },
  iconContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    flexGrow: 0,
    flexShrink: 0,
    width: '100%',
    color: '#000',
    paddingTop: 10,
  },
  iconWrapper: {
    width: 100,
    marginTop: 10,
  },
  titleGroup: {
    margin: 25,
  },
  text: {
    fontSize: '22px',
  },
  nextBtn: {
    textAlign: 'center',
    width: '100%',
    top: '90%',
    position: 'fixed',
  },
};

const LargeCheckbox = withStyles(() => ({
  root: {
    transform: 'scale(1.5)',
  },
}))(Checkbox);


class Diabetes16 extends Page {
  constructor(props) {
    super(props);

    this.state = {
      symptoms: [],
    };

    this.handleContinue = this.handleContinue.bind(this);
  }

  handleCheckbox = (e) => {
    const { name, checked } = e.target;
    const { symptoms } = this.state;
    if (!checked && symptoms.includes(name)) {
      this.setState({ symptoms: filter(symptoms, (type) => { return type !== name; }) });
    } else if (checked && !symptoms.includes(name)) {
      this.setState({ symptoms: [...symptoms, name] });
    }
  };

  async handleContinue() {
    const { id } = this.props.location.query;
    const { user, diabetesInitial } = this.props;
    const { symptoms, submitting } = this.state;
    if (submitting) return;
    this.setState({ submitting: true });
    try {
      let data = [...diabetesInitial];
      data = concat(data, [symptoms]);
      await this.props.submitPRO({ userId: user.id, id }, {
        pro_type: 'DIABETES-INITIAL',
        pro_data: { data },
      }, user);
      this.forwardWithQuery();
    } catch (e) {
      this.setState({ submitting: false, error: e });
      console.error(e);
    }
  }

  render() {
    const { symptoms } = this.state;

    return (
      <Fragment>
        <AppBar
          headerNode="Symptoms"
          noLeftNode={true}
        />
        <LinearProgress
          variant="determinate"
          value={(this.getCurrentPage() / this.getTotalPages()) * 100}
        />

        <div style={styles.iconContainer}>
          <div style={styles.iconWrapper}>
            <WaterDrop />
          </div>
        </div>

        <div style={styles.titleGroup}>
          <p style={styles.text}>Do you currently experience any of the following?</p>
          <FormControl component="fieldset" style={styles.formControlItem}>
            <FormGroup>
              <FormControlLabel
                control={<LargeCheckbox checked={symptoms.includes('Paresthesia')} onChange={this.handleCheckbox} name="Paresthesia" />}
                label={(
                  <div style={styles.checkboxTitle}>
                    <Typography variant="title">
                    Paresthesia
                    </Typography><br />
                    <Typography style={styles.checkboxCaption} variant="caption">
                    (pins and needles, tingling)
                    </Typography>
                  </div>
               )}
              />
              <FormControlLabel
                control={<LargeCheckbox checked={symptoms.includes('Numbness')} onChange={this.handleCheckbox} name="Numbness" />}
                label={(
                  <div style={styles.checkboxLabel}>
                    <Typography variant="title">
                      Numbness
                    </Typography>
                  </div>
                )}
              />
              <FormControlLabel
                control={<LargeCheckbox checked={symptoms.includes('Neuropathy')} onChange={this.handleCheckbox} name="Neuropathy" />}
                label={(
                  <div style={styles.checkboxTitle}>
                    <Typography variant="title">
                    Neuropathy
                    </Typography><br />
                    <Typography style={styles.checkboxCaption} variant="caption">
                    (nerve pain)
                    </Typography>
                  </div>
               )}
              />
              <FormControlLabel
                control={<LargeCheckbox checked={symptoms.includes('Skin Changes')} onChange={this.handleCheckbox} name="Skin Changes" />}
                label={(
                  <div style={styles.checkboxTitle}>
                    <Typography variant="title">
                    Skin Changes
                    </Typography><br />
                    <Typography style={styles.checkboxCaption} variant="caption">
                    (ie. open wounds)
                    </Typography>
                  </div>
               )}
              />
              <FormControlLabel
                control={<LargeCheckbox checked={symptoms.includes('Vision Changes')} onChange={this.handleCheckbox} name="Vision Changes" />}
                label={(
                  <div style={styles.checkboxLabel}>
                    <Typography variant="title">
                    Vision Changes
                    </Typography>
                  </div>
                )}
              />
            </FormGroup>
          </FormControl>
        </div>

        <div style={styles.nextBtn}>
          <Continue
            text="Next"
            onClick={this.handleContinue}
          />
        </div>
      </Fragment>
    );
  }
}


function mapStateToProps(state) {
  const { user, proForms: { diabetesInitial } } = state;
  return { user, diabetesInitial };
}

Diabetes16.proptypes = {
  user: PropTypes.object.isRequired,
  route: PropTypes.object.isRequired,
  updatePRO: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { submitPRO })(Diabetes16);
