import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get } from 'lodash';

import { colors } from '../lib/styles';
import Page from './page';
import PainScaleTwoSliders from '../components/pain-scale-two-sliders';
import { updatePRO } from '../state/pro-forms';

const instructions = 'On a scale from 0 to 10, '
  + 'mark the intensity of your left leg and '
  + 'right leg pain during the past week, with 0 '
  + 'being \'None\' and 10 being \'Unbearable Pain\'';

const { questionBackground } = colors;

class OdiBackLegPainScale extends Page {
  componentWillMount() {
    const currentValues = get(this.props.odi, [this.getTrackIndex()], []);
    if (!currentValues.length) {
      this.props.updatePRO({
        type: 'odi',
        position: this.getTrackIndex(),
        value: [0, 0],
      });
    }
  }
  handleChangeTopSlider = (value) => {
    const currentValues = get(this.props.odi, [this.getTrackIndex()], []);
    const newValues = [...currentValues];
    newValues[0] = value;
    this.props.updatePRO({
      type: 'odi',
      position: this.getTrackIndex(),
      value: newValues,
    });
  }

  handleChangeBottomSlider = (value) => {
    const currentValues = get(this.props.odi, [this.getTrackIndex()], []);
    const newValues = [...currentValues];
    newValues[1] = value;
    this.props.updatePRO({
      type: 'odi',
      position: this.getTrackIndex(),
      value: newValues,
    });
  }

  onClickBackButton = () => {
    this.backward();
  }

  onSubmission = () => {
    this.forwardWithQuery(this.props.location.query);
  }

  render() {
    const topValue = get(this.props.odi, [this.getTrackIndex(), 0], 0);
    const bottomValue = get(this.props.odi, [this.getTrackIndex(), 1], 0);
    return (
      <PainScaleTwoSliders
        backgroundColor={questionBackground}
        bottomValue={bottomValue}
        currentPage={this.getCurrentPage()}
        instructions={instructions}
        onClickBackButton={() => this.props.router.goBack()}
        onChangeBottom={this.handleChangeBottomSlider}
        onChangeTop={this.handleChangeTopSlider}
        onSubmission={this.onSubmission}
        sliderLabelBottom="Left Leg Pain"
        sliderLabelTop="Right Leg Pain"
        sliderMax={10}
        sliderMin={0}
        title="Back & Leg Pain Scale"
        topValue={topValue}
        totalPages={this.getTotalPages()}
      />
    );
  }
}

function mapStateToProps(state) {
  const { proForms: { odi } } = state;
  return { odi };
}

OdiBackLegPainScale.propTypes = {
  odi: PropTypes.array.isRequired,
  updatePRO: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { updatePRO })(OdiBackLegPainScale);
