import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import AppBar from '../components/app-bar';
import Page from './page';
import QuestionLayout from '../layouts/question';
import YesNoButtonSet from '../components/yes-no-button-set';
import { colors } from '../lib/styles';
import { updatePRO } from '../state/pro-forms';
import { Swallowing } from '../lib/icons';

const styles = {
  contentWrapper: {
    flexGrow: 1,
    overflowX: 'hidden',
    position: 'relative',
  },
  imgRow: {
    display: 'flex',
    justifyContent: 'center',
    padding: '15px 60px',
  },
  imageWrapper: {
    width: 100,
  },
  instructions: {
    color: 'grey',
    fontSize: '1.5rem',
    margin: '20px 40px 0px 40px',
    textAlign: 'center',
  },
  mainQuestionText: {
    fontSize: '2rem',
    fontWeight: 'bold',
  },
  questionnaireContainer: {
    paddingLeft: 20,
    paddingRight: 20,
  },
};

class HeadAndNeckCancerSymptoms3 extends Page {
  constructor(props) {
    super(props);

    this.state = {
      chokeWhenSwallowing: null,
      painfulSwallowing: null,
      soresInMouth: null,
    };
  }

  handleChangeChokeWhenSwallowing = (value) => {
    this.setState({ chokeWhenSwallowing: value });
  }

  handleChangePainfulSwallowing = (value) => {
    this.setState({ painfulSwallowing: value });
  };

  handleChangeSoresInMouth = (value) => {
    this.setState({ soresInMouth: value });
  };

  handleClickNext = () => {
    const {
      chokeWhenSwallowing,
      painfulSwallowing,
      soresInMouth,
    } = this.state;

    this.props.updatePRO({
      type: 'headAndNeckCancerSymptoms',
      position: this.getTrackIndex(),
      value: {
        chokeWhenSwallowing,
        painfulSwallowing,
        soresInMouth,
      },
    });
    this.forwardWithQuery(this.props.location.query);
  }

  render() {
    const { classes } = this.props;
    const {
      chokeWhenSwallowing,
      painfulSwallowing,
      soresInMouth,
    } = this.state;

    const continueDisabled = chokeWhenSwallowing === null
      || painfulSwallowing === null
      || soresInMouth === null;

    return (
      <QuestionLayout
        primaryBtnLabel="Next"
        primaryBtnOnTouchTap={this.handleClickNext}
        primaryBtnDisabled={continueDisabled}
        style={{ backgroundColor: colors.questionBackground }}
      >
        <AppBar
          headerNode="Swallowing"
          backButtonOnClick={() => this.props.router.goBack()}
        />
        <LinearProgress
          variant="determinate"
          value={(this.getCurrentPage() / this.getTotalPages()) * 100}
          classes={{ bar: classes.bar }}
        />
        <div className={classes.contentWrapper}>
          <div className={classes.instructions}>
            Please select the option that best answers the questions relating to swallowing
          </div>
          <div className={classes.imgRow}>
            <div className={classes.imageWrapper}>
              <Swallowing />
            </div>
          </div>
          <div className={classes.questionnaireContainer}>
            <div className={classes.mainQuestionText}>Is the following true?</div>
            <YesNoButtonSet
              isYes={chokeWhenSwallowing === 'Y'}
              isNo={chokeWhenSwallowing === 'N'}
              onClickNo={() => this.handleChangeChokeWhenSwallowing('N')}
              onClickYes={() => this.handleChangeChokeWhenSwallowing('Y')}
              questionText="I frequently choke when swallowing"
            />
            <YesNoButtonSet
              isYes={painfulSwallowing === 'Y'}
              isNo={painfulSwallowing === 'N'}
              onClickNo={() => this.handleChangePainfulSwallowing('N')}
              onClickYes={() => this.handleChangePainfulSwallowing('Y')}
              questionText="Swallowing is painful"
            />
            <YesNoButtonSet
              isYes={soresInMouth === 'Y'}
              isNo={soresInMouth === 'N'}
              onClickNo={() => this.handleChangeSoresInMouth('N')}
              onClickYes={() => this.handleChangeSoresInMouth('Y')}
              questionText="I have sores in my mouth"
            />
          </div>
        </div>
      </QuestionLayout>
    );
  }
}

export default connect(null, { updatePRO })(withStyles(styles)(HeadAndNeckCancerSymptoms3));
