import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';

import { calendarStyles as styles, colors } from '../lib/styles';

const CalendarCurrentMonthItem = ({ day, dayCount, month, painLevel, year }) => {
  const spaceOnRight = (dayCount % 7) ? styles.spaceOnRight : {};
  let painLevelStyles;

  if (painLevel === 0 || painLevel === undefined) {
    painLevelStyles = {};
  } else if (painLevel < 4) {
    painLevelStyles = styles.painLevel1;
  } else if (painLevel < 8) {
    painLevelStyles = styles.painLevel2;
  } else {
    painLevelStyles = styles.painLevel3;
  }

  const calendarItemStyles = {
    ...painLevelStyles,
    ...styles.calendarItem,
    ...spaceOnRight,
    color: colors.black,
    textDecoration: 'none',
  };

  const date = day;

  return (
    <div style={styles.calendarItemWrapper}>
      <div style={styles.heightExpander} />
      <Link
        style={calendarItemStyles}
        to={`/pain-log-details/${month}/${date}/${year}`}
      >
        {day}
      </Link>
    </div>
  );
};

CalendarCurrentMonthItem.defaultProps = {
  painLevel: 0,
};

CalendarCurrentMonthItem.propTypes = {
  day: PropTypes.number.isRequired,
  dayCount: PropTypes.number.isRequired,
  month: PropTypes.number.isRequired,
  painLevel: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  year: PropTypes.number.isRequired,
};

export default CalendarCurrentMonthItem;
