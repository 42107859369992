/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';

import Page from './page';
import AppBar from '../components/app-bar';
import IconWrapper from '../components/icon-wrapper';
import BloomkidzLayout from '../layouts/bloomkidz';
import { Information } from '../lib/icons';
import { updatePRO } from '../state/pro-forms';
import { bloomkidzStylesObj } from '../lib/bloomkidz';

const styles = {
  ...bloomkidzStylesObj,
  centerText: {
    textAlign: 'center',
  },
  marginTop20: {
    marginTop: 20,
  },
};

class BloomkidzMeanAndNutrition2 extends Page {
  handleContinue = () => {
    const { location, updatePRO } = this.props;

    updatePRO({
      type: 'bloomkidzNewClient',
      position: this.getTrackIndex(),
      value: { ...this.state },
    });

    this.forwardWithQuery(location.query);
  }

  render() {
    const { classes, router } = this.props;

    return (
      <BloomkidzLayout
        primaryBtnOnTouchTap={this.handleContinue}
        primaryBtnLabel="Next"
      >
        <AppBar
          headerNode="Information"
          backButtonOnClick={() => router.goBack()}
        />
        <IconWrapper>
          <Information />
        </IconWrapper>
        <div className={classes.pageContent}>
          <div className={classes.centerText}>
            Please be aware that families are required to provide adequate snacks and a lunch for their child daily.
          </div>
          <div className={`${classes.marginTop20} ${classes.centerText}`}>
            Additionally, please provide a larger quantity of preferred snacks in an extra bag to be kept at the clinic.
          </div>
        </div>
      </BloomkidzLayout>
    );
  }
}

BloomkidzMeanAndNutrition2.propTypes = {
  classes: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  updatePRO: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
};

export default connect(null, { updatePRO })(withStyles(styles)(BloomkidzMeanAndNutrition2));
