const en = {
  demographics8: {
    title: 'Physician',
    question1: 'Do you have a primary care provider?',
    question2: 'When did you last have an appointment with your primary care provider?',
    question3: 'What is the reason you do not have a primary care provider?',
    options: {
      threeMonths: 'Within the last 3 months',
      sixMonths: '6 months ago',
      oneYear: '1 year ago',
      overOneYear: 'Over a year ago',
      time: 'No Time',
      insurance: 'No Insurance',
      availability: 'No Availability',
      cost: 'Cost',
      other: 'Other',
    },
  },
};

const es = {
  demographics8: {
    title: 'Médico/a',
    question1: '¿Tiene un proveedor de atención primaria?',
    question2: '¿Cuándo fue la última vez que tuvo una cita con su proveedor de atención primaria?',
    question3: '¿Cuál es la razón por la que no tiene un proveedor de atención primaria?',
    options: {
      threeMonths: 'En los últimos 3 meses',
      sixMonths: 'Hace 6 meses',
      oneYear: 'Hace un año',
      overOneYear: 'Hace más de un año',
      time: 'No hay tiempo',
      insurance: 'No tengo aseguranza',
      availability: 'Disponibilidad',
      cost: 'Costo',
      other: 'Otra razon',
    },
  },
};

export default { en, es };
