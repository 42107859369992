import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { assign } from 'lodash';

import { colors } from '../lib/styles';

const baseStyles = {
  subtitle: {
    backgroundColor: colors.darkGrayText,
    color: colors.white,
    fontSize: '16px',
    padding: '2px',
  },
};

class Subtitle extends Component {
  render() {
    const style = assign({}, baseStyles.subtitle, this.props.style);
    return (
      <div style={style}>{this.props.label}</div>
    );
  }
}

Subtitle.defaultProps = {
  label: 'Subtitle',
  style: {},
};

Subtitle.propTypes = {
  label: PropTypes.string,
  style: PropTypes.object,
};

export default Subtitle;
