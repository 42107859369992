import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import resources from './locale-text';

i18n
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    resources: {
      en: resources.en,
      es: resources.es,
    },
    react: {
      transKeepBasicHtmlNodesFor: ['span', 'br', 'strong', 'p', 'div'],
    },
  });

export default i18n;
