import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ListItem, ListItemSecondaryAction, ListItemText } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import Checkbox from './round-checkbox';
import { colors } from '../lib/styles';

const baseStyles = {
  item: {
    color: colors.black,
    fontSize: '1.5rem',
    textAlign: 'left',
    textTransform: 'uppercase',
  },
};

class FamilyRow extends Component {
  constructor(props) {
    super(props);
    this.handleCheck = this.handleCheck.bind(this);
  }

  handleCheck(checked) {
    const { onChange, condition, hx } = this.props;
    onChange(condition.id, checked, hx);
  }

  render() {
    const {
      classes,
      condition,
      hx,
      disabled,
    } = this.props;

    return (
      <ListItem>
        <ListItemText primary={condition.name} classes={{ primary: classes.item }} />
        <ListItemSecondaryAction>
          <Checkbox
            key={condition.id}
            checked={!!hx}
            disabled={disabled}
            onChange={(cb, checked) => {
              this.handleCheck(checked);
            }}
          />
        </ListItemSecondaryAction>
      </ListItem>
    );
  }
}

FamilyRow.defaultProps = {
  hx: null,
};

FamilyRow.propTypes = {
  condition: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  hx: PropTypes.object,
  onChange: PropTypes.func.isRequired,
};
export default withStyles(baseStyles)(FamilyRow);
