import React from 'react';
import { connect } from 'react-redux';
import { assign, capitalize, get, omit, pickBy, reduce, round } from 'lodash';
import moment from 'moment';
import { Button } from '@material-ui/core';

import AppBar from '../components/app-bar';
import { addPainHx, editPainHx, readPainHx } from '../state/pain-hx';
import { browsePainLocationsIfNeeded } from '../state/app-data';
import { fetchUserReport, updateAvatarUrl } from '../state/user';
import { colors, mainDashboard } from '../lib/styles';
import Continue from '../components/continue';
import Layout from '../layouts/common';
import { logEvent } from '../lib/amplitude';
import { activityGoal, stepGoal, calorieGoal } from '../lib/constants';
import Page from './page';
import PainBody from '../components/pain-body';
import BodyPainModal from '../components/body-pain-modal';

const baseStyles = {
  appBar: {
    zIndex: 1001,
  },
  backButton: {
    left: '-1.25rem',
    padding: 0,
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
  },
  bottomBtnMargin: {
    marginBottom: '7.5vw',
  },
  confirmButtonLabel: {
    fontSize: '1.75rem',
    textTransform: 'none',
  },
  continueButton: {
    background: colors.secondaryColor,
  },
  painBodyBtnContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    left: '7.5%',
    justifyContent: 'center',
    position: 'fixed',
    textAlign: 'center',
    top: '85%',
  },
  painBody: {
    minWidth: '85%',
  },
  painBodyAndCirculars: {
    display: 'flex',
    marginBottom: '45px',
    marginLeft: '10px',
  },
  header: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    width: '100%',
  },
  headerTitle: {
    alignItems: 'center',
    color: colors.black,
    display: 'flex',
    fontSize: '2.625rem',
  },
  height: {
    height: '750px',
  },
  helpButton: {
    padding: 0,
    position: 'absolute',
    right: -16,
    top: '50%',
    transform: 'translateY(-50%)',
  },
  helpButtonLabel: {
    fontSize: '1.75rem',
    textTransform: 'none',
  },
  message: {
    color: colors.black,
    fontSize: '1.5rem',
  },
};

function calculateDistance(click, point) {
  return ((click.x - point.x) ** 2) + ((click.y - point.y) ** 2);
}

function scrollToTop() {
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
}

class BodyPain extends Page {
  constructor(props) {
    super(props);

    const pain = props.pain_hx_id ? { ...props.painHx } : {};

    this.state = {
      currentBody: 'BACK',
      maxPoints: 1,
      modalOpen: false,
      pain,
      points: [],
      showConfirmButton: false,
      unconfirmedPoint: null,
    };

    this.cancelPoint = this.cancelPoint.bind(this);
    this.clearPoints = this.clearPoints.bind(this);
    this.confirmPoint = this.confirmPoint.bind(this);
    this.handleBodyClick = this.handleBodyClick.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleContinue = this.handleContinue.bind(this);
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.painMoves = this.painMoves.bind(this);
    this.painStationary = this.painStationary.bind(this);
    this.updateCurrentBody = this.updateCurrentBody.bind(this);
  }

  componentWillMount() {
    const {
      browsePainLocationsIfNeeded,
      pain_hx_id,
      readPainHx,
    } = this.props;

    browsePainLocationsIfNeeded();

    if (pain_hx_id) {
      readPainHx(pain_hx_id);
    }
  }

  componentWillReceiveProps(nextProps) {
    const {
      painHx,
      painLocations: painLocationOptions,
    } = nextProps;

    if (painHx && painLocationOptions) {
      const { pain_locations } = painHx;
      const painLocationsLength = pain_locations.length;

      if (painLocationsLength !== 0) {
        const points = [];

        pain_locations.forEach((painLocation) => {
          const painPoint = painLocationOptions[painLocation];
          points.push(painPoint);
        });

        this.setState({
          maxPoints: painLocationsLength,
          moveDecision: true,
          points,
        });
      }
    }
  }

  componentDidMount() {
    scrollToTop();
  }

  cancelPoint() {
    this.setState({
      unconfirmedPoint: null,
    });
  }

  clearPoints() {
    this.setState({
      points: [],
      moveDecision: false,
      maxPoints: 1,
    });
    scrollToTop();
  }

  confirmPoint() {
    logEvent('Confirm Body Pain - Yes');
    let { unconfirmedPoint } = this.state;

    unconfirmedPoint = { ...unconfirmedPoint, confirmed: true };

    this.setState({
      points: this.state.points.concat(unconfirmedPoint),
      showConfirmButton: false,
      unconfirmedPoint: null,
    });
    scrollToTop();
  }

  painMoves() {
    let { points } = this.state;

    points = points.map(point => omit(point, ['painStart']));

    logEvent('Pain Move - Yes');
    this.setState({
      moveDecision: true,
      maxPoints: 2,
      points,
    });
  }

  painStationary() {
    this.setState({
      moveDecision: true,
      maxPoints: 1,
    });
  }

  handleBodyClick(x, y, body) {
    const { maxPoints, unconfirmedPoint, points } = this.state;
    const { painLocations } = this.props;
    if (unconfirmedPoint || points.length >= maxPoints) {
      return;
    }

    const validLocations = pickBy(painLocations, l => l.body_location === body);

    const click = { x, y };

    let closestPoint = reduce(validLocations, (result, value) => {
      const distance = calculateDistance(click, value);

      if (distance < result.distance) {
        return {
          distance,
          ...value,
        };
      }

      return result;
    }, { distance: 99999999 });

    closestPoint = { ...closestPoint, painStart: true };

    this.setState({
      showConfirmButton: true,
      unconfirmedPoint: closestPoint,
    });
  }

  handleCloseModal() {
    this.setState({ modalOpen: false });
  }

  handleContinue() {
    const {
      addPainHx,
      editPainHx,
      pain_hx_id,
    } = this.props;
    const pain = omit(this.state.pain, ['user_id']);
    const pain_locations = this.state.points.map(p => p.id);

    logEvent('Confirm This Pain Entry (Finish)');

    if (pain_hx_id) {
      editPainHx({ ...pain, pain_locations, id: pain_hx_id })
        .then(record => this.forwardWithQuery({ pain_hx_id: record.id }))
        .catch(() => {});
    } else {
      addPainHx({ pain_locations })
        .then(record => this.forwardWithQuery({ pain_hx_id: record.id }));
    }
  }

  handleOpenModal() {
    this.setState({ modalOpen: true });
  }

  updateCurrentBody(currentBody) {
    this.setState({ currentBody });
  }

  render() {
    let clearButton = null;
    let confirmButton = null;
    let continueButton = null;
    const {
      currentBody,
      maxPoints,
      modalOpen,
      moveDecision,
      points,
      showConfirmButton,
      unconfirmedPoint,
    } = this.state;
    let visiblePoints = points;
    let bottomBtnStyle = baseStyles.painBodyBtnContainer;
    let bottomMargin = 0;
    let message = 'Select the location of your pain';
    if (!unconfirmedPoint && points.length && points.length < 2) {
      message = 'Select the approximate location of your pain';
      clearButton = null;
    }

    if (unconfirmedPoint) {
      bottomBtnStyle = assign({}, bottomBtnStyle, { top: '92%' });
      bottomMargin = 4.5;
      message = 'Confirm your pain point below';
      visiblePoints = points.concat(unconfirmedPoint);
      confirmButton = <Continue btnStyle={baseStyles.continueButton} onClick={this.confirmPoint} text="Confirm" />;
    }

    if (points.length === 1 && !moveDecision) {
      bottomBtnStyle = assign({}, bottomBtnStyle, { top: '85%' });
      bottomMargin = 9;
      message = 'Does this pain Move?';
      clearButton = <Continue btnStyle={baseStyles.continueButton} onClick={this.painMoves} text="Yes - this pain moves" />;
      continueButton = <Continue btnStyle={baseStyles.continueButton} onClick={this.handleContinue} text="No - This pain does not move" />;
    } else
    if (points.length >= maxPoints) {
      bottomBtnStyle = assign({}, bottomBtnStyle, { top: '85%' });
      bottomMargin = 9;
      message = 'Select an option below to continue';
      clearButton = <Continue btnStyle={baseStyles.continueButton} onClick={this.clearPoints} text="Start Over" />;
      continueButton = <Continue btnStyle={baseStyles.continueButton} onClick={this.handleContinue} />;
    }

    return (
      <div>
        <Layout>
          <section style={mainDashboard.container}>
            <AppBar
              backButtonOnClick={() => this.props.router.goBack()}
              headerNode={`View ${capitalize(currentBody)}`}
              rightNode={
                showConfirmButton
                  ? (
                    <Button
                      onClick={this.confirmPoint}
                      variant="text"
                      style={baseStyles.confirmButtonLabel}
                    >
                      Confirm
                    </Button>
                  ) : (
                    <Button
                      onClick={this.handleOpenModal}
                      variant="text"
                      style={baseStyles.confirmButtonLabel}
                    >
                      Help ?
                    </Button>
                  )
              }
            />
            <div style={baseStyles.painBody}>
              <p style={baseStyles.message}>{message}</p>
              <div style={{ marginBottom: `${bottomMargin}rem` }}>
                <PainBody
                  onBodyClick={this.handleBodyClick}
                  onClickCancel={this.cancelPoint}
                  selectedLocations={visiblePoints}
                  updateCurrentBody={this.updateCurrentBody}
                />
              </div>
              <div style={bottomBtnStyle}>
                {confirmButton}
                {continueButton}
                {clearButton}
              </div>
            </div>
          </section>
        </Layout>
        <BodyPainModal
          modalOpen={modalOpen}
          onCloseModal={this.handleCloseModal}
        />
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const {
    appData: {
      painLocations,
    },
    cordova,
    painHx,
    user,
  } = state;
  const pain_hx_id = get(ownProps, 'location.query.pain_hx_id', null);

  const { year, month, date } = ownProps.params;
  const viewedDate = moment(new Date(year, month, date)).format('YYYY-MM-DD');

  const activity = round(cordova.activityData[viewedDate] || 0);
  const steps = round(cordova.stepsData[viewedDate] || 0);
  const calories = round(cordova.caloriesData[viewedDate] || 0);


  return {
    user,
    painLocations,
    pain_hx_id,
    painHx: painHx.data[pain_hx_id],
    stepGoal,
    calorieGoal,
    activityGoal,
    steps,
    calories,
    activity,
  };
}

export default connect(mapStateToProps, {
  addPainHx,
  editPainHx,
  readPainHx,
  browsePainLocationsIfNeeded,
  fetchUserReport,
  updateAvatarUrl,
})(BodyPain);
