import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { TextareaAutosize } from '@material-ui/core';
import { connect } from 'react-redux';

import Page from './page';
import AppBar from '../components/app-bar';
import IconWrapper from '../components/icon-wrapper';
import BloomkidzLayout from '../layouts/bloomkidz';
import { FaceLaughBeam } from '../lib/icons';
import { updatePRO } from '../state/pro-forms';
import { bloomkidzStylesObj } from '../lib/bloomkidz';

const styles = bloomkidzStylesObj;

class BloomkidzNewClient3 extends Page {
  constructor(props) {
    super(props);

    const stateFromRedux = props.bloomkidzNewClient[this.getTrackIndex()] || {};

    this.state = { happyPlace: '', ...stateFromRedux };
  }

  handleChangeHappyPlace = (e) => {
    this.setState({ happyPlace: e.target.value });
  };

  handleContinue = () => {
    const { location, updatePRO } = this.props;

    updatePRO({
      type: 'bloomkidzNewClient',
      position: this.getTrackIndex(),
      value: { ...this.state },
    });

    this.forwardWithQuery(location.query);
  }

  render() {
    const { classes, router } = this.props;
    const { happyPlace } = this.state;

    return (
      <BloomkidzLayout
        primaryBtnOnTouchTap={this.handleContinue}
        primaryBtnLabel="Next"
        primaryBtnDisabled={!happyPlace}
      >
        <AppBar
          headerNode="Mood"
          backButtonOnClick={() => router.goBack()}
        />
        <IconWrapper>
          <FaceLaughBeam />
        </IconWrapper>
        <div className={classes.pageContent}>
          <div>When your child is most relaxed/happy, what does that look like for them? What is their &ldquo;happy place&rdquo;?</div>
          <TextareaAutosize
            value={happyPlace}
            onChange={this.handleChangeHappyPlace}
            minRows={10}
            className={classes.textAreaAutosize}
          />
        </div>
      </BloomkidzLayout>
    );
  }
}

BloomkidzNewClient3.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  updatePRO: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const {
    proForms: { bloomkidzNewClient },
  } = state;

  return { bloomkidzNewClient };
};

export default connect(mapStateToProps, { updatePRO })(withStyles(styles)(BloomkidzNewClient3));
