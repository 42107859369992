const en = {
  demographics3: {
    title: 'About Me',
    instructions: 'Please select your race or ethnicity',
    options: {
      hispanic: 'Hispanic',
      caucasian: 'Caucasian / Non-Hispanic',
      blackAmerican: 'Black or African American',
      nativeAmerican: 'Native American',
      asianAmerican: 'Asian or Asian American',
      pacificIslander: 'Native Hawaiian / Pacific Islander',
      other: 'Other',
      otherLabel: 'Tap to enter',
    },
  },
};

const es = {
  demographics3: {
    title: 'Sobre Mí',
    instructions: 'Por favor seleccione su raza o etnica',
    options: {
      hispanic: 'Hispano',
      caucasian: 'Blanco / No hispano',
      blackAmerican: 'Negro / Afroamericano',
      nativeAmerican: 'Indio Americano',
      asianAmerican: 'Asiático',
      pacificIslander: 'Hawaiano Nativo / Isleño del Pacífico',
      other: 'Otro',
      otherLabel: 'Toca aquí para entrar',
    },
  },
};

export default { en, es };
