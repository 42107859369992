/* eslint-disable */
import React from 'react';

import { colors } from '../lib/styles';
import { cordovaOpen } from '../lib/cordova-helpers';

const styles = {
  container: {
    textAlign: 'left',
  },
  header: {
    textAlign: 'center',
  },
  link: {
    background: 'transparent',
    border: 0,
    color: colors.primaryColor,
    fontFamily: 'inherit',
    fontSize: 'inherit',
    margin: 0,
    padding: 0,
    textDecoration: 'underline',
  },
};

const onInfoButtonClick = (e) => {
  e.preventDefault();
  cordovaOpen('mailto:info@mindsetmedical.com');
};

const PrivacyPolicy = () => (
  <div style={styles.container}>
    <h1 style={styles.header}>
      Privacy Policy
    </h1>
    <p>
      MINDSET MEDICAL LLC, an Arizona limited liability company ("Mindset" or "We"), respects your privacy and is committed to protecting it through our compliance with this policy. This policy describes:
    </p>
    <ul>
      <li>The types of information we may collect or that you may provide when you download, install, register with, access, or use Mindset’s Informed mobile application (the "App").</li>
      <li>Our practices for collecting, using, maintaining, protecting, and disclosing that information.</li>
    </ul>
    <p>
      This policy applies only to information we collect in this App and in email, text, and other electronic communications sent through or in connection with this App.
    </p>

    <p>
      This policy DOES NOT apply to information that:
    </p>

    <ul>
      <li>We collect offline or on any other Mindset apps or websites, including websites you may access through this App.</li>
      <li>You provide to or is collected by any third party (see LINK TO "THIRD-PARTY INFORMATION COLLECTION").</li>
      <li>Third party websites and applications may have their own privacy policies which we encourage you to read before providing information on or through them.</li>
    </ul>

    <p>
      Please read this policy carefully to understand our policies and practices regarding your information and how we will treat it. If you do not agree with our policies and practices, do not download, register with, or use this App. By downloading, registering with, or using this App, you agree to this privacy policy. This policy may change from time to time (see LINK TO "CHANGES TO OUR PRIVACY POLICY”). Your continued use of this App after we make changes is deemed to be acceptance of those changes, so please check the policy periodically for updates.
    </p>

    <h3>Persons Under the Age of 18</h3>

    <p>
      The App is not intended for persons under 18 years of age, and we do not knowingly collect information from persons under 18 without verification of parental consent. If we learn we have collected or received information from a person under 18 without verification of parental consent, we will delete that information. If you believe we might have any information from or about a person under 18, please contact us at info@mindsetmedical.com.
    </p>

    <h3>Information We Collect and How We Collect It</h3>
    <p>
      We collect information from and about users of our App:
    </p>

    <ul>
      <li>Directly from you when you provide it to us.</li>
      <li>Automatically when you use the App.</li>
    </ul>

    <h3>Information You Provide to Us</h3>
    <p>
      When you download, register with, or use this App, we may ask you provide information:
    </p>

    <ul>
      <li>By which you may be personally identified, such as name, postal address, email address, telephone number, social security number, as well as information related to your medical history, including information that is considered “protected health information” under certain state laws and federal laws such as HIPAA and HITECH (collectively, "PHI").</li>
      <li>That is about you but individually does not identify you such as certain demographic information.</li>
    </ul>


    <p>
      This information includes:
    </p>

    <ul>
      <li>Information that you provide by filling in forms in the App. This includes information provided at the time of registering to use the App, and requesting further services. We may also ask you for information when you report a problem with the App.</li>
      <li>Information collected from you during your use of the App.</li>
      <li>Information submitted by your approved health care providers.</li>
      <li>Information you provide related to persons you consent to share your PHI with, such as family members, care-takers, or medical professionals, as well as any communications between you and such persons.</li>
      <li>Records and copies of your correspondence (including email addresses and phone numbers), if you contact us.</li>
      <li>Your responses to surveys that we might ask you to complete for research purposes.</li>
      <li>Details of transactions you carry out through the App and of the fulfillment of your requests.</li>
      <li>Your search queries on the App.</li>
    </ul>

    <h3>Automatic Information Collection and Tracking</h3>

    <p>
      When you download, access, and use the App, it may use technology to automatically collect:
    </p>

    <ul>
      <li><b>Usage Details.</b> When you access and use the App, we may automatically collect certain details of your access to and use of the App, including traffic data, logs, and other communication data and the resources that you access and use on or through the App.</li>
      <li><b>Device Information.</b> We may collect information about your mobile device and internet connection, including the device's unique device identifier, IP address, operating system, browser type, mobile network information, and the device's telephone number.</li>
      <li><b>Stored Information and Files.</b> The App also may access metadata and other information associated with other files stored on your device. This may include, for example, photographs, audio and video clips, personal contacts, and address book information.</li>
      <li><b>Location Information.</b> This App does not collect real-time information about the location of your device.</li>
    </ul>

    <p>
      If you do not want us to collect this information, do not download the App or delete it from your device. For more information, see LINK TO CHOICES ABOUT HOW WE USE AND DISCLOSE YOUR INFORMATION.
    </p>

    <p>
      We also may use these technologies to collect information about your activities over time and across third-party websites, apps, or other online services (behavioral tracking). For more information, see LINK TO “DO NOT TRACK DISCLOSURES” for information on how you can opt out of behavioral tracking on or through this app and how we respond to browser signals and other mechanisms that enable consumers to exercise choice about behavioral tracking.
    </p>

    <h3>Information Collection and Tracking Technologies</h3>

    <p>
      The technologies we use for automatic information collection may include:
    </p>

    <ul>
      <li>Cookies (or mobile cookies). A cookie is a small file placed on your smartphone. It may be possible to refuse to accept mobile cookies by activating the appropriate setting on your smartphone. However, if you select this setting you may be unable to access certain parts of our App.</li>
      <li>Web Beacons. Pages of the App and our emails may contain small electronic files known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) that permit Mindset, for example, to count users who have visited those pages or opened an email and for other related app statistics (for example, recording the popularity of certain app content and verifying system and server integrity).</li>
    </ul>

    <h3>Third-Party Information Collection</h3>

    <p>
      When you use the App or its content, certain third parties may use automatic information collection technologies to collect information about you or your device. These third parties may include:
    </p>

    <ul>
      <li>Analytics companies.</li>
      <li>Your mobile device manufacturer.</li>
      <li>Your mobile service provider.</li>
    </ul>
    <p>
      These third parties may use tracking technologies to collect information about you when you use this App. The information they collect may be associated with your PHI or they may collect information, including PHI, about your online activities over time and across different websites, apps, and other online services websites. They may use this information to provide you with interest-based (behavioral) advertising or other targeted content.
    </p>

    <p>
      We do not control these third parties' tracking technologies or how they may be used and we do not monitor your interactions with third parties. Any interactions you have with third parties are solely your responsibility. If you have any questions about an advertisement or other targeted content, you should contact the responsible provider directly. For information about how you can opt out of receiving targeted advertising from many providers, see LINK TO "CHOICES ABOUT HOW WE USE AND DISCLOSE YOUR INFORMATION".
    </p>



    <h3>How We Use Your Information</h3>

    <p>
      We use information that we collect about you or that you provide to us, including any PHI, to:
    </p>

    <ul>
      <li>Provide you with the App and its contents, and any other information, products or services that you request from us.</li>
      <li>Fulfill any other purpose for which you provide it.</li>
      <li>Give you notices about your account, including expiration and renewal notices.</li>
      <li>Carry out our obligations and enforce our rights arising from any contracts or agreements entered into between you and us.</li>
      <li>Notify you when App updates are available, and of changes to any products or services we offer or provide though it.</li>
    </ul>

    <p>
      The usage information we collect helps us to improve our App and to deliver a better and more personalized experience by enabling us to:
    </p>

    <ul>
      <li>Estimate our audience size and usage patterns.</li>
      <li>Store information about your preferences, allowing us to customize our App according to your individual preferences.</li>
      <li>Speed up your searches.</li>
      <li>Recognize you when you use the App.</li>
    </ul>

    <p>
      We may also use your information to contact you about our own and third parties' goods and services that may be of interest to you. If you do not want us to use your information in this way, please check the relevant box located on the form on which we collect your data. For more information, see LINK TO "CHOICES ABOUT HOW WE USE AND DISCLOSE YOUR INFORMATION".
    </p>

    <h3>Aggregated and De-identified Data</h3>

    <p>Subject to applicable state and federal law, including but not limited to our obligations under HIPAA and HITECH, we may license, sell, or otherwise share aggregated, de-identified versions of your PHI and other data (“De-identified Information”) with our subsidiaries, affiliates, partners, customers, investors, and contractors for any purpose. You agree and acknowledge that Mindset is the sole and exclusive owner of any De-identified Information created by Mindset and that you have no ownership or other intellectual property rights in or to such De-identified Information.</p>



    <h3>Disclosure of Your Information</h3>

    <p>We may disclose PHI or other personal information that we collect or you provide:</p>

    <ul>
      <li>To our subsidiaries and affiliates who are bound by contractual obligations to keep PHI and other personal information confidential and use it only for the purposes for which we disclose it to them.</li>
      <li>To contractors, service providers, and other third parties we use to support our business and who are bound by contractual obligations to keep PHI and other personal information confidential and use it only for the purposes for which we disclose it to them.</li>
      <li>To fulfill the purpose for which you provide it.</li>
      <li>For any other purpose disclosed by us when you provide the information.</li>
      <li>With your consent.</li>
      <li>To comply with any court order, law, or legal process, including to respond to any government or regulatory request.</li>
      <li>To enforce our rights arising from any contracts entered into between you and us, including the End User License Agreement, and for billing and collection.</li>
      <li>Subject to applicable laws, if we believe disclosure is necessary or appropriate to protect the rights, property, or safety of Mindset Medical LLC, our customers or others. This includes exchanging information with other companies and organizations for the purposes of fraud protection.</li>
    </ul>

    <h3>Your Choices About Our Collection, Use, and Disclosure of Your Information</h3>

    <p>
      We strive to provide you with choices regarding the PHI and other personal information you provide to us. This section describes mechanisms we provide for you to control certain uses and disclosures of over your information.
    </p>

    <ul>
      <li>Tracking Technologies. You can set your browser to refuse all or some browser cookies, or to alert you when cookies are being sent. If you disable or refuse cookies or block the use of other tracking technologies, some parts of the App may then be inaccessible or not function properly.</li>
      <li>Promotion by Mindset. If you do not want us to use your email address to promote our own or third parties' products or services, you can opt-out by emailing us at info@mindsetmedical.com. You can also always opt-out by logging into the App and adjusting your user preferences in your account profile by checking or unchecking the relevant boxes or by sending us an email stating your request to info@mindsetmedical.com.</li>
    </ul>

    <p>
      We do not control third parties' collection or use of your information to serve interest-based advertising. However these third parties may provide you with ways to choose not to have your information collected or used in this way. You can opt out of receiving targeted ads from members of the Network Advertising Initiative ("NAI") on the NAI's website.
    </p>

    <h3>Accessing and Correcting Your PHI and Other Personal Information</h3>

    <p>
      You can review and change your PHI or other information by logging into the App and visiting your account profile page. We cannot change your PHI or other information nor can we delete your PHI or other information except by also deleting your user account. We may deny access to your PHI or personal information when required by law or if we believe such access would cause the PHI or other information of a third party to be revealed.
    </p>

    <h3>Your California Privacy Rights</h3>

    <p>
      California Civil Code Section 1798.83 permits users of our App that are California residents to request certain information regarding our disclosure of information to third parties for their direct marketing purposes. To make such a request, please send an email to info@mindsetmedical.com.
    </p>

    <h3>
      Data Security
    </h3>

    <p>
      We have implemented technical, physical, administrative and organizational measures designed to secure your PHI and other personal information from accidental loss and from unauthorized access, use, alteration, and disclosure. All information you provide to us is stored on our secure servers behind firewalls which conform to applicable state and federal regulations.
    </p>

    <p>
      The safety and security of your information also depends on you. Where we have given you (or where you have chosen) a password or security pin number for access to certain parts of our App, you are responsible for keeping this password confidential. Do not to share your password with anyone.
    </p>

    <p>
      Unfortunately, the transmission of information via the internet and mobile platforms is not completely secure. Although we do our best to protect your PHI and other personal information, we cannot guarantee the security of your PHI and other personal information transmitted through our App. Any transmission of PHI and other personal information is at your own risk. We are not responsible for circumvention of any privacy settings or security measures we provide.
    </p>

    <h3>Changes to Our Privacy Policy</h3>

    <p>We may update our privacy policy from time to time. If we make material changes to how we treat our users' PHI, we will post the new privacy policy on this page with a notice that the privacy policy has been updated and notify you by an in-App alert the first time you use the App after we make the change.</p>

    <h3>Contact Information</h3>

    <p>To ask questions or comment about this privacy policy and our privacy practices, contact us at:</p>

    <button
      style={styles.link}
      onClick={onInfoButtonClick}
    >
      info@mindsetmedical.com
    </button>
  </div>
);

export default PrivacyPolicy;
