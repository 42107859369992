import React from 'react';
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  LinearProgress,
  TextField,
} from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { omit } from 'lodash';

import AppBar from '../components/app-bar';
import Page from './page';
import QuestionLayout from '../layouts/question';
import { colors } from '../lib/styles';
import { submitPRO, updatePRO } from '../state/pro-forms';
import { apiFetch, makeShadowOptions } from '../lib/fetch';
import { Frown } from '../lib/icons';

const styles = {
  contentWrapper: {
    flexGrow: 1,
    overflowX: 'hidden',
    position: 'relative',
  },
  imgRow: {
    display: 'flex',
    justifyContent: 'center',
    padding: '15px 60px',
  },
  imageWrapper: {
    width: 100,
  },
  marginTop20: {
    marginTop: 20,
  },
  questionnaireContainer: {
    paddingLeft: 20,
    paddingRight: 20,
  },
  questionText: {
    fontSize: '2rem',
  },
  textFieldRoot: {
    marginTop: 10,
    width: 256,
    '& input': {
      fontSize: 18,
    },
    '& label': {
      fontSize: 18,
    },
  },
};

class HypertensionAssessment14 extends Page {
  constructor(props) {
    super(props);

    this.state = {
      changeInHealth: false,
      family: false,
      financial: false,
      other: false,
      otherSymptom: '',
      work: false,
    };
  }

  handleChangeAnotherSymptom = (e) => {
    this.setState({ otherSymptom: e.target.value });
  }

  handleChangeSourceOfStress = (e) => {
    this.setState({ [e.target.name]: e.target.checked });
  };

  handleClickNext = () => {
    const { hypertensionAssessment, user } = this.props;
    const {
      changeInHealth,
      family,
      financial,
      other,
      otherSymptom,
      work,
    } = this.state;

    const sourcesOfStress = [];

    if (changeInHealth) sourcesOfStress.push('Change in health condition');
    if (family) sourcesOfStress.push('Family');
    if (financial) sourcesOfStress.push('Financial');
    if (work) sourcesOfStress.push('Work');
    if (other && otherSymptom) sourcesOfStress.push(`Other: ${otherSymptom}`);

    if (hypertensionAssessment[1].medications && hypertensionAssessment[1].medications.length) {
      const { medications } = hypertensionAssessment[1];
      const formattedMedications = medications.map((med) => {
        return omit(med, ['id', 'user_id']);
      });

      const options = makeShadowOptions({ medication_hx: formattedMedications }, user.token, 'POST');

      apiFetch(`/users/${user.id}/medication_hx_bulk`, options)
        .then(() => { this.handleSubmitPro([...hypertensionAssessment, { sourcesOfStress }]); });
    } else {
      this.handleSubmitPro([...hypertensionAssessment, { sourcesOfStress }]);
    }
  }

  handleSubmitPro = (hypertensionAssessment) => {
    const { user } = this.props;
    const { id } = this.props.location.query;

    delete hypertensionAssessment[1].medications;

    this.props.submitPRO({ userId: user.id, id }, {
      pro_type: 'HYPERTENSION-ASSESSMENT-INITIAL',
      pro_data: { data: hypertensionAssessment },
    }, user)
      .then(() => this.finishTrack());
  };

  render() {
    const { classes } = this.props;
    const {
      changeInHealth,
      family,
      financial,
      other,
      otherSymptom,
      work,
    } = this.state;

    return (
      <QuestionLayout
        primaryBtnLabel="Next"
        primaryBtnOnTouchTap={this.handleClickNext}
        style={{ backgroundColor: colors.questionBackground }}
      >
        <AppBar
          headerNode="Stress"
          backButtonOnClick={() => this.props.router.goBack()}
        />
        <LinearProgress
          variant="determinate"
          value={(this.getCurrentPage() / this.getTotalPages()) * 100}
          classes={{ bar: classes.bar }}
        />
        <div className={classes.contentWrapper}>
          <div className={classes.imgRow}>
            <div className={classes.imageWrapper}>
              <Frown />
            </div>
          </div>
          <div className={classes.questionnaireContainer}>
            <div className={classes.questionText}>What is the source of your stress?</div>
            <FormGroup className={classes.marginTop20}>
              <FormControlLabel
                control={(
                  <Checkbox
                    checked={changeInHealth}
                    onChange={this.handleChangeSourceOfStress}
                    name="changeInHealth"
                    icon={<CheckBoxOutlineBlankIcon fontSize="large" />}
                    checkedIcon={<CheckBoxIcon fontSize="large" />}
                  />
                )}
                label="A change in your health condition"
                classes={{ label: classes.questionText }}
              />
              <FormControlLabel
                control={(
                  <Checkbox
                    checked={family}
                    onChange={this.handleChangeSourceOfStress}
                    name="family"
                    icon={<CheckBoxOutlineBlankIcon fontSize="large" />}
                    checkedIcon={<CheckBoxIcon fontSize="large" />}
                  />
                )}
                label="Family"
                classes={{ label: classes.questionText }}
              />
              <FormControlLabel
                control={(
                  <Checkbox
                    checked={work}
                    onChange={this.handleChangeSourceOfStress}
                    name="work"
                    icon={<CheckBoxOutlineBlankIcon fontSize="large" />}
                    checkedIcon={<CheckBoxIcon fontSize="large" />}
                  />
                )}
                label="Work"
                classes={{ label: classes.questionText }}
              />
              <FormControlLabel
                control={(
                  <Checkbox
                    checked={financial}
                    onChange={this.handleChangeSourceOfStress}
                    name="financial"
                    icon={<CheckBoxOutlineBlankIcon fontSize="large" />}
                    checkedIcon={<CheckBoxIcon fontSize="large" />}
                  />
                )}
                label="Financial"
                classes={{ label: classes.questionText }}
              />
              <FormControlLabel
                control={(
                  <Checkbox
                    checked={other}
                    onChange={this.handleChangeSourceOfStress}
                    name="other"
                    icon={<CheckBoxOutlineBlankIcon fontSize="large" />}
                    checkedIcon={<CheckBoxIcon fontSize="large" />}
                  />
                )}
                label="Other"
                classes={{ label: classes.questionText }}
              />
            </FormGroup>
            {other ? (
              <TextField
                label="Another Symptom"
                onChange={this.handleChangeAnotherSymptom}
                classes={{ root: classes.textFieldRoot }}
                value={otherSymptom}
              />
            ) : null}
          </div>
        </div>
      </QuestionLayout>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    proForms: {
      hypertensionAssessment,
    },
    user,
  } = state;

  return { hypertensionAssessment, user };
};

export default connect(mapStateToProps, { submitPRO, updatePRO })(withStyles(styles)(HypertensionAssessment14));
