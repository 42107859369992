import React from 'react';
import { LinearProgress, TextField } from '@material-ui/core';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import AppBar from '../components/app-bar';
import Page from './page';
import QuestionLayout from '../layouts/question';
import YesNoButtonSet from '../components/yes-no-button-set';
import { colors, fontSizing } from '../lib/styles';
import { submitPRO, updatePRO } from '../state/pro-forms';
import { WeightScale } from '../lib/icons';
import CenteredIcon from '../components/centered-icon';

const styles = {
  breathingText: {
    fontSize: '2rem',
    fontWeight: 'bold',
  },
  pageContent: {
    fontSize: fontSizing.body,
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 20,
  },
  inputField: {
    width: '6rem',
    '& input': {
      textAlign: 'center',
    },
  },
  questionText: {
    marginTop: 25,
  },
  textFieldContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  textFieldWrapper: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  unitsWrapper: {
    fontSize: fontSizing.smallX,
    fontWeight: 'bold',
    marginTop: 5,
  },
};

class GIOncology5 extends Page {
  constructor(props) {
    super(props);

    this.state = {
      weightChange: '',
      weightChanged: null,
      weightGainOrLoss: null,
    };
  }

  handleYesNoChange = (field, value) => {
    this.setState({ [field]: value });
  }

  handleWeightChange = (e) => {
    const weightChange = e.target.value.replace(/[^0-9.]/g, '');
    this.setState({ weightChange });
  };

  getCallPageMessage = (giOncology) => {
    const {
      threeOrMoreLiquidStools,
      threeDaysSinceBowelMovement,
      ableToEatOrDrink,
      ableToTakeMedications,
      nauseaControlledByMedications,
      weightChange,
    } = giOncology;

    const messages = [];

    if (threeOrMoreLiquidStools === 'Y') messages.push('3 or more liquid stools in the past 24 hours');
    if (threeDaysSinceBowelMovement === 'Y') messages.push('3 days since last bowel movement');
    if (ableToEatOrDrink === 'N') messages.push('not able to eat or drink');
    if (ableToTakeMedications === 'N') messages.push('not able to take medications');
    if (nauseaControlledByMedications === 'N') messages.push('nausea or vomiting not controlled by medications');
    if (weightChange && parseFloat(weightChange) > 3) messages.push('weight change greater than 3lbs');

    if (messages.length) {
      return messages.join(',\n') + '.';
    }

    return '';
  }

  handleClickNext = () => {
    const {
      giOncology,
      location,
      submitPRO,
      updatePRO,
      user,
    } = this.props;
    const { id } = location.query;
    const {
      weightChange,
      weightChanged,
      weightGainOrLoss,
    } = this.state;

    const value = { weightChanged };

    if (weightChanged === 'Y') {
      value.weightGainOrLoss = weightGainOrLoss;
      value.weightChange = weightChange;
    }

    const data = {
      ...giOncology[0],
      ...giOncology[1],
      ...giOncology[2],
      ...giOncology[3],
      ...value,
    };

    const callPageMessage = this.getCallPageMessage(data);

    submitPRO(
      { userId: user.id, id },
      {
        pro_data: { data },
        pro_type: 'GI-ONCOLOGY',
      },
      user,
    )
      .then(() => {
        if (callPageMessage) {
          updatePRO({
            type: 'giOncology',
            position: this.getTrackIndex(),
            value: callPageMessage,
          });
          this.forwardWithQuery(location.query);
          return;
        }
        this.finishTrack();
      });
  }

  render() {
    const { classes } = this.props;
    const {
      weightChange,
      weightChanged,
      weightGainOrLoss,
    } = this.state;

    const continueDisabled = weightChanged === null
      || (weightChanged === 'Y' && (weightGainOrLoss === null || weightChange === ''));

    return (
      <QuestionLayout
        primaryBtnLabel="Next"
        primaryBtnOnTouchTap={this.handleClickNext}
        primaryBtnDisabled={continueDisabled}
        style={{ backgroundColor: colors.questionBackground }}
      >
        <AppBar
          headerNode="Weight"
          backButtonOnClick={() => this.props.router.goBack()}
        />
        <LinearProgress
          variant="determinate"
          value={(this.getCurrentPage() / this.getTotalPages()) * 100}
          classes={{ bar: classes.bar }}
        />
        <div className={classes.pageContent}>
          <CenteredIcon>
            <WeightScale />
          </CenteredIcon>
          <YesNoButtonSet
            isYes={weightChanged === 'Y'}
            isNo={weightChanged === 'N'}
            onClickNo={() => this.handleYesNoChange('weightChanged', 'N')}
            onClickYes={() => this.handleYesNoChange('weightChanged', 'Y')}
            questionText="Has your weight changed in the last 4 days?"
          />
          {weightChanged === 'Y' ? (
            <>
              <YesNoButtonSet
                isYes={weightGainOrLoss === 'Lose'}
                isNo={weightGainOrLoss === 'Gain'}
                onClickNo={() => this.handleYesNoChange('weightGainOrLoss', 'Gain')}
                onClickYes={() => this.handleYesNoChange('weightGainOrLoss', 'Lose')}
                labelForYes="Lost"
                labelForNo="Gained"
                questionText="Did you lose or gain weight?"
              />
              <div className={classes.questionText}>How much?</div>
              <div className={classes.textFieldContainer}>
                <div className={classes.textFieldWrapper}>
                  <TextField
                    className={classes.inputField}
                    onChange={this.handleWeightChange}
                    type="number"
                    inputProps={{
                      inputMode: 'numeric',
                      type: 'text',
                      style: { fontSize: fontSizing.body } }}
                    value={weightChange}
                    placeholder="0"
                  />
                  <span className={classes.unitsWrapper}>lbs</span>
                </div>
              </div>
            </>
          ) : null}
        </div>
      </QuestionLayout>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    proForms: {
      giOncology,
    },
    user,
  } = state;
  return { giOncology, user };
};

export default connect(mapStateToProps, { submitPRO, updatePRO })(withStyles(styles)(GIOncology5));
