import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { withTranslation } from 'react-i18next';
import i18nTranslator, { title, instructions, nextBtn } from '../lib/i18next';

import HealthState from '../components/health-state';
import Page from './page';
import { submitPRO, updatePRO } from '../state/pro-forms';

class EqHealthState extends Page {
  state = {
    submitting: false,
  }

  onSubmission = () => {
    if (this.state.submitting) return;
    if (this.getCurrentPage() === 7) return this.forwardWithQuery(this.props.location.query);

    const { user } = this.props;
    const { id } = this.props.location.query;
    this.setState({ submitting: true });

    this.props.submitPRO({ userId: user.id, id }, {
      pro_type: 'EQ5D-3L',
      pro_data: { data: this.props.eq5d3l },
    }, user)
      .then(() => this.forward())
      .catch(() => this.setState({ submitting: false }));
  }

  render() {
    const value = get(this.props.eq5d3l, this.getTrackIndex(), null);
    return (
      <HealthState
        currentPage={this.getCurrentPage()}
        onBack={() => this.props.router.goBack()}
        onChange={this.props.updatePRO}
        onSubmission={this.onSubmission}
        title={title('eqHealthState')}
        buttonLabel={nextBtn()}
        instructions={instructions('eqHealthState')}
        secondaryInstructions={i18nTranslator('secondaryInstructions', 'eqHealthState')}
        sliderTopLabel={i18nTranslator('sliderTopLabel', 'eqHealthState')}
        sliderBottomLabel={i18nTranslator('sliderBottomLabel', 'eqHealthState')}
        primaryBtnDisabled={this.state.submitting}
        totalPages={this.getTotalPages()}
        trackIndex={this.getTrackIndex()}
        type="eq5d3l"
        value={value || 100}
      />
    );
  }
}

function mapStateToProps(state) {
  const {
    user,
    proForms: {
      eq5d3l,
    },
  } = state;
  return {
    user,
    eq5d3l,
  };
}

EqHealthState.proptypes = {
  eq5d3l: PropTypes.array.isRequired,
  updatePRO: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { submitPRO, updatePRO })(withTranslation('eqHealthState')(EqHealthState));
