import React from 'react';
import { get } from 'lodash';
import { connect } from 'react-redux';

import { submitPRO } from '../state/pro-forms';
import Page from './page';

import i18nTranslator from '../lib/i18next';
import { apiFetch } from '../lib/fetch';

import AppBar from '../components/app-bar';
import Continue from '../components/continue';
import NotificationVideo from '../components/notification-video';

const styles = {
  continueBtn: {
    textAlign: 'center',
    alignSelf: 'center',
    margin: '10px',
  },
  continueBtnWrapper: {
    textAlign: 'center',
    width: '100%',
    bottom: 0,
    position: 'absolute',
    height: '8rem',
  },
  continueBtnWrapperTop: {
    textAlign: 'center',
    width: '100%',
  },
  mainContainer: {
    position: 'relative',
    minHeight: '100vh',
    marginBottom: '50px',
  },
  wrapper: {
    paddingBottom: '8rem',
  },
};

class VideoNotification extends Page {
  constructor(props) {
    super(props);

    this.state = {
      playedBroadcast: false,
      startTime: new Date(),
      url: '',
    };
  }

  componentDidMount = async () => {
    const videoSrc = get(this.props, 'location.query.videoSrc')
      || get(this.props, 'user.meta.videoSrc')
      || get(this.props, 'location.state.videoSrc');
    const videoName = videoSrc.split('com/')[1];
    const clinicId = videoName.split('_')[0];
    const broadcastId = videoName.split('_')[3];
    const { url } = await apiFetch(`/clinics/${clinicId}/video_broadcasts/${broadcastId}`);
    this.setState({ url });
  }

  handlePlayedVideo = () => {
    this.setState({ playedBroadcast: true });
  }

  handleDismissNotification = () => {
    const { playedBroadcast, startTime, submitting } = this.state;
    if (submitting) return;
    this.setState({ submitting: true });
    const { submitPRO, user, location } = this.props;
    const { query } = location;
    const { id, video_broadcast_id: queryVideoBroadcastId } = query;
    let broadcastId = {};
    if (user.meta && !user.meta.bundled_events.length) {
      const { video_broadcast_id } = user.meta;
      broadcastId = video_broadcast_id;
    } else if (user.meta && user.meta.bundled_event) {
      const broadcast = user.meta.bundled_events.find((b) => {
        return b.pro_submission_id === Number(id);
      });
      const { video_broadcast_id } = broadcast;
      broadcastId = video_broadcast_id;
    } else {
      broadcastId = queryVideoBroadcastId;
    }
    const timeOnPage = Math.round((new Date().getTime() - startTime.getTime()) / 1000);
    const body = {
      broadcastId,
      dismissed: true,
      isBroadcast: true,
      playedBroadcast,
      timeOnPage,
    };
    submitPRO({ userId: user.id, id }, {
      pro_type: 'VIDEO_BROADCAST',
      pro_data: { data: body },
    }, user)
      .then(() => this.setState({ submitting: false }))
      .then(() => this.forward())
      .catch((e) => {
        this.setState({ error: e.message, submitting: false });
      });
  }

  render() {
    return (
      <div style={styles.mainContainer}>
        <AppBar
          headerNode="Video"
          noLeftNode={true}
        />
        <div style={styles.continueBtnWrapperTop}>
          <Continue
            text={i18nTranslator('finishBtn', 'videoBroadcast2')}
            onClick={() => {
              this.handleDismissNotification();
            }}
            btnStyle={styles.continueBtn}
          />
        </div>
        <div style={styles.wrapper}>
          <NotificationVideo
            videoSrc={this.state.url}
            onDismissNotification={this.handleDismissNotification}
            onPlayVideo={this.handlePlayedVideo}
            onEndVideo={this.handleDismissNotification}
          />
        </div>
        <div style={styles.continueBtnWrapper}>
          <Continue
            text={i18nTranslator('finishBtn', 'videoBroadcast2')}
            onClick={() => {
              this.handleDismissNotification();
            }}
            btnStyle={styles.continueBtn}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state;
  return {
    userId: user.id,
    user,
  };
}

export default connect(mapStateToProps, { submitPRO })(VideoNotification);
