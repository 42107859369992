import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import { noop } from 'lodash';

import { colors } from '../lib/styles';

class SignupTextField extends Component {
  render() {
    const {
      errorText,
      label,
      type,
      value,
      onChange,
      placeholder,
    } = this.props;
    return (
      <TextField
        label={errorText || label}
        fullWidth={true}
        InputProps={{
          style: { fontSize: '13px' },
        }}
        InputLabelProps={{
          style: { fontSize: '16px', color: errorText ? colors.errorRed : colors.formTextBlue },
        }}
        type={type}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        style={{ color: colors.black, marginBottom: '10px' }}
      />
    );
  }
}

SignupTextField.defaultProps = {
  errorText: '',
  label: 'First Name',
  type: '',
  onChange: noop,
};

SignupTextField.propTypes = {
  errorText: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string.isRequired,
};

export default SignupTextField;
