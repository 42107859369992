import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { get } from 'lodash';
import DashboardTop from './dashboard-top';
import Continue from './continue';
import { colors } from '../lib/styles';

const baseStyles = {
  btns: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    marginBottom: '5px',
  },
  label: {
    fontSize: '1rem',
  },
  newPain: {
    backgroundColor: colors.secondaryColor,
    width: '38vw',
    minWidth: '129px',
    padding: '2px',
    marginRight: '25px',
    lineHeight: '0px',
    borderRadius: '25px',
  },
  samePain: {
    backgroundColor: colors.lightGreyText,
    width: '38vw',
    minWidth: '129px',
    marginLeft: '25px',
    lineHeight: '0px',
    borderRadius: '25px',
  },
};

class DashboardPain extends Component {
  updatePainEntry = () => {
    const { mostRecentPain, router } = this.props;
    const { id } = mostRecentPain;
    let route;
    if (mostRecentPain.pain) {
      route = `/migrainepain?id=${id}`;
    } else {
      route = `/pain?pain_hx_id=${id}`;
    }
    router.push(route);
  };

  render() {
    const {
      headData,
      painDisplayDate,
      painDisplayTime,
      painDisplayIntensity,
      painType,
      router,
      mostRecentPain,
    } = this.props;
    let paragraph = 'You do not have a previous pain entry';
    let headachePain, bodyPain;

    if (mostRecentPain && mostRecentPain.id) {
      if (painType === 'body') {
        bodyPain = mostRecentPain;
        paragraph = `You logged a LEVEL ${painDisplayIntensity} pain entry on ${painDisplayDate} at ${painDisplayTime}.
        Would you like to update this pain or enter a new one?`;
      } else {
        headachePain = get(headData, 'pain.0', {});
        if(headachePain) {
          const migrainePainDate = moment(headachePain.created_at).format('MM/DD/YYYY');
          const migrainePainTime = new Date(headachePain.created_at);
          const formatedMigrainePainTime = moment(migrainePainTime).format("hh:mm A");
          paragraph = `You logged a LEVEL ${mostRecentPain.pain[0].severity} migraine entry on ${migrainePainDate} at ${formatedMigrainePainTime}.
        Would you like to update this migraine or enter a new one ? `;
        }
      }
    }

    return (
      <DashboardTop
        title="How are you feeling?"
        paragraph={paragraph}
      >
        <div style={baseStyles.btns}>
          <Continue
            text="Update Pain Entry"
            btnStyle={baseStyles.samePain}
            labelStyle={baseStyles.label}
            onClick={this.updatePainEntry}
            disabled={!bodyPain && !headachePain}
          />
          <Continue
            text="New Pain Entry"
            btnStyle={baseStyles.newPain}
            labelStyle={baseStyles.label}
            onClick={() => router.push('/which-pain')}
          />
        </div>
      </DashboardTop>
    );
  }
}
DashboardPain.defaultProps = {
  headData: {},
  mostRecentPain: {},
  painDisplayDate: undefined,
  painDisplayTime: undefined,
  painDisplayIntensity: undefined,
  painType: '',
};
DashboardPain.propTypes = {
  headData: PropTypes.object,
  mostRecentPain: PropTypes.object,
  painDisplayDate: PropTypes.string,
  painDisplayTime: PropTypes.string,
  painDisplayIntensity: PropTypes.number,
  painType: PropTypes.string,
  router: PropTypes.object.isRequired,
};

export default DashboardPain;
