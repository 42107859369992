import { apiFetch } from './fetch';

const MIN_DOWNLOAD_SPEED = 1.5; // Mbps
const MIN_UPLOAD_SPEED = 10; // Mbps
const BYTE_SIZE = 3000000;

export const downloadSpeedTest = async () => {
  const image = 'https://mindsetmedical.com/wp-content/uploads/2021/07/Video-Vitals-1.png';

  const downloadSrc = new Image();
  const time_start = new Date().getTime();
  let time_end = 0;
  let time_diff = 0;

  downloadSrc.src = `${image}?nn=${time_start}`;

  try {
    return await new Promise((resolve, _) => {
      // eslint-disable-next-line no-return-assign
      return downloadSrc.onload = async () => {
        time_end = new Date().getTime();
        time_diff = (time_end - time_start) / 1000;

        const speed = ((BYTE_SIZE / time_diff) / 1024 / 1024).toFixed(2);

        resolve(speed >= MIN_DOWNLOAD_SPEED);
      };
    });
  } catch (error) {
    throw new Error(error);
  }
};

export const uploadSpeedTest = async () => {
  const buffer = new ArrayBuffer(BYTE_SIZE);

  let time_end = 0;
  let time_diff = 0;

  const options = {
    method: 'POST',
    body: buffer,
  };
  try {
    return await new Promise(async (resolve, _) => {
      const time_start = new Date().getTime();
      await apiFetch('/network', options).then(() => {
        time_end = new Date().getTime();
        return time_end;
      });

      time_diff = (time_end - time_start) / 1000;
      const speed = ((BYTE_SIZE / time_diff) / 1024 / 1024).toFixed(2);

      resolve(speed >= MIN_UPLOAD_SPEED);
    });
  } catch (error) {
    throw new Error(error);
  }
};

const runNetworkSpeedTest = async () => {
  const upload = await uploadSpeedTest();
  const download = await downloadSpeedTest();

  return { download, upload };
};

export default runNetworkSpeedTest;
