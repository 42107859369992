import { throttle } from 'lodash';
import { browserHistory } from 'react-router';

import { logout } from '../state/user';
import store from '../store';
import { setErrorScreenData } from '../state/error-screens';

const logoutTime = 300000;
const throttleTime = 1500;
let lastActivity;
let lastActivityInterval;

function setLastActivity() {
  lastActivity = Date.now();
}

function checkForExpiredTimestamp() {
  if (Date.now() - lastActivity >= logoutTime) {
    const { loggedIn } = store.getState().user;

    if (loggedIn) {
      store.dispatch(logout(true));
      return;
    }
  
    store.dispatch(setErrorScreenData({
      header: 'Inactivity',
      messageOne: 'Your session has timed out due to 5 minutes of inactivity.',
      hideRetry: true,
    }));
  
    browserHistory.push('/timeout');
  }
}

export const throttledReset = throttle(setLastActivity, throttleTime, {
  leading: true,
  trailing: true,
});

export default function activityInitializer() {

  lastActivityInterval = setInterval(checkForExpiredTimestamp, 2000);
  window.onload = throttledReset;
  document.onmousemove = throttledReset;
  document.onmousedown = throttledReset;
  document.ontouchstart = throttledReset;
  document.onclick = throttledReset;
  document.onscroll = throttledReset;
  document.onkeypress = throttledReset;
}
