import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Checkbox from '@material-ui/core/Checkbox';
import CheckedCircle from '@material-ui/icons/CheckCircle';
import UncheckedCircle from '@material-ui/icons/RadioButtonUnchecked';

const baseStyles = {
  largeIcon: {
    width: '36px',
    height: '36px',
  },
};

class RoundCheckbox extends Component {
  render() {
    const style = Object.assign({ ...this.props.style } || {}, baseStyles.largeIcon, { top: '5px' });
    return (
      <Checkbox
        style={style}
        icon={<UncheckedCircle style={baseStyles.largeIcon} />}
        checkedIcon={<CheckedCircle style={baseStyles.largeIcon} />}
        {...this.props}
      />
    );
  }
}

RoundCheckbox.defaultProps = {
  style: {},
};

RoundCheckbox.propTypes = {
  style: PropTypes.object,
};

export default RoundCheckbox;
