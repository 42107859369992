import { promiseHandler, resetReducer } from 'cooldux';
import { get, isUndefined, omit, omitBy } from 'lodash';

import { apiFetch } from '../lib/fetch';

const { browseStart, browseEnd, browseError, browseHandler } = promiseHandler('browse', 'migraineAbortiveHx');
const { editStart, editEnd, editError, editHandler } = promiseHandler('edit', 'migraineAbortiveHx');
const { addStart, addEnd, addError, addHandler } = promiseHandler('add', 'migraineAbortiveHx');
const { deleteStart, deleteEnd, deleteError, deleteHandler } = promiseHandler('delete', 'migraineAbortiveHx');

export function browseMigraineAbortiveHx(migraineId) {
  return function dispatcher(dispatch, getState) {
    const selfId = get(getState(), 'user.id', null);
    const promise = apiFetch(`/users/${selfId}/migraine_hx/${migraineId}/migraine_abortive_hx`);
    return browseHandler(promise, dispatch);
  };
}

export function editMigraineAbortiveHx(update) {
  return function dispatcher(dispatch, getState) {
    const selfId = get(getState(), 'user.id', null);
    const body = omit(update, ['id', 'migraine_id']);
    const options = {
      method: 'PUT',
      body: omitBy(body, isUndefined),
    };
    const promise = apiFetch(
      `/users/${selfId}/migraine_hx/${update.migraine_hx_id}/migraine_abortive_hx/${update.id}`, options,
    );
    return editHandler(promise, dispatch);
  };
}

export function addMigraineAbortiveHx(newMigraineAbortiveHx) {
  return function dispatcher(dispatch, getState) {
    const selfId = get(getState(), 'user.id', null);
    const body = omit(newMigraineAbortiveHx, 'migraine_hx_id');
    const options = {
      method: 'POST',
      body: omitBy(body, isUndefined),
    };
    const promise = apiFetch(
      `/users/${selfId}/migraine_hx/${newMigraineAbortiveHx.migraine_hx_id}/migraine_abortive_hx`, options,
    );
    return addHandler(promise, dispatch);
  };
}

export function deleteMigraineAbortiveHx(migraineId, rxId) {
  return function dispatcher(dispatch, getState) {
    const state = getState();
    const selfId = get(state, 'user.id', null);
    const targetId = get(state, `migraineAbortiveHx.data.${rxId}.id`, null);
    const options = {
      method: 'DELETE',
    };
    const promise = apiFetch(
      `/users/${selfId}/migraine_hx/${migraineId}/migraine_abortive_hx/${targetId}`, options,
    )
      .then(() => rxId);

    return deleteHandler(promise, dispatch);
  };
}

const initialState = {
  browseError: null,
  addError: null,
  editError: null,
  deleteError: null,
  browsePending: false,
  addPending: false,
  editPending: false,
  deletePending: false,
  data: {},
};

function finishBrowse(state, migraineAbortiveHx) {
  const data = {};
  migraineAbortiveHx.forEach((u) => {
    data[u.rx_id] = u;
  });
  return { ...state, data, browsePending: false, browseError: null };
}

function finishEdit(state, item) {
  const data = { ...state.data, [item.rx_id]: item };
  return { ...state, editPending: false, editError: null, data };
}

function finishAdd(state, item) {
  const data = { ...state.data, [item.rx_id]: item };
  return { ...state, addPending: false, addError: null, data };
}

function finishDelete(state, rxId) {
  return { ...state, deletePending: false, deleteError: null, data: omit(state.data, rxId) };
}

const migraineAbortiveHx = resetReducer(initialState, (state = initialState, action) => {
  switch (action.type) {
    case browseStart.type:
      return { ...state, browsePending: true };
    case browseEnd.type:
      return finishBrowse(state, action.payload);
    case browseError.type:
      return { ...state, browsePending: false, browseError: action.payload };
    case addStart.type:
      return { ...state, addPending: true };
    case addEnd.type:
      return finishAdd(state, action.payload);
    case addError.type:
      return { ...state, addPending: false, addError: action.payload };
    case deleteStart.type:
      return { ...state, deletePending: true };
    case deleteEnd.type:
      return finishDelete(state, action.payload);
    case deleteError.type:
      return { ...state, deletePending: false, deleteError: action.payload };
    case editStart.type:
      return { ...state, editPending: true };
    case editEnd.type:
      return finishEdit(state, action.payload);
    case editError.type:
      return { ...state, editPending: false, editError: action.payload };
    default:
      return state;
  }
});

export default migraineAbortiveHx;
