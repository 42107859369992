import React from 'react';

import { colors, fontSizes } from '../lib/styles';

const styles = {
  container: {
    display: 'flex',
    color: colors.darkGrayText,
    fontSize: fontSizes.smaller,
    fontWeight: 600,
    justifyContent: 'space-between',
  },
};

const PainSliderLabels = () => (
  <div style={styles.container}>
    <div>
      No Pain
    </div>
    <div>
      Unbearable Pain
    </div>
  </div>
);

export default PainSliderLabels;
