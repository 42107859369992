/* eslint-disable no-bitwise */
import { toLE16, toLE64, toLE32 } from './bits';

export class VSVitals {
  systolic;

  diastolic

  map;

  heartRate;

  respiration;

  arterialStiffness;

  signalQualityEstimate;

  timestamp;

  bpStatus;

  mapStatus;

  hrStatus;

  respirationStatus;

  integrationError;

  differentiationError;

  p12FinderError;

  p3FinderError;

  minIndexOutOfRange;

  maxIndexOutOfRange;

  slopeOutOfRange;

  value;

  // size   status  sys  dia  map   hr resp  art  sqe     time
  // 1600 07000000 8100 4e00 5f00 5900 0000 0000 0000 c88d1800
  constructor(buffer) {
    const size = toLE16(buffer.slice(0, 2));
    if (size !== 22) {
      throw new Error();
    }

    this.value = toLE32(buffer.slice(2, 6));
    this.bpStatus = (this.value & 1) !== 0;
    this.mapStatus = ((this.value >> 1) & 1) !== 0;
    this.hrStatus = ((this.value >> 2) & 1) !== 0;
    this.respirationStatus = ((this.value >> 3) & 1) !== 0;
    this.integrationError = ((this.value >> 4) & 1) !== 0;
    this.differentiationError = ((this.value >> 5) & 1) !== 0;
    this.p12FinderError = ((this.value >> 6) & 1) !== 0;
    this.p3FinderError = ((this.value >> 7) & 1) !== 0;
    this.minIndexOutOfRange = ((this.value >> 8) & 1) !== 0;
    this.maxIndexOutOfRange = ((this.value >> 9) & 1) !== 0;
    this.slopeOutOfRange = ((this.value >> 10) & 1) !== 0;

    this.systolic = toLE16(buffer.slice(6, 8));
    this.diastolic = toLE16(buffer.slice(8, 10));
    this.map = toLE16(buffer.slice(10, 12));
    this.heartRate = toLE16(buffer.slice(12, 14));
    this.respiration = toLE16(buffer.slice(14, 16));
    this.arterialStiffness = toLE16(buffer.slice(16, 18));
    this.signalQualityEstimate = toLE16(buffer.slice(18, 20));
    this.timestamp = toLE32(buffer.slice(-4));
  }
}

export class VSBattery {
  voltage;

  timestamp;

  // size volt     time
  // 0600 2858 20811800
  constructor(buffer) {
    const size = toLE16(buffer.slice(0, 2));
    if (size !== 6) {
      throw new Error();
    }

    this.voltage = toLE16(buffer.slice(2, 4));
    this.timestamp = toLE32(buffer.slice(-4));
  }
}

export class VSStatus {
  timestamp;

  cardiacOutputCalibrated;

  hemodynamicsEnabled;

  recalRecommended;

  invalidDataEntry;

  posture;

  chargeComplete;

  charging;

  autocalPct;

  tooManyFails;

  recalSoon;

  bleAdv;

  badCuff;

  weakSignal;

  cuffTooTight;

  cuffTooLoose;

  noPulseTimeout;

  calibrationOffsetFailed;

  calibrationFailed;

  inflateFailed;

  betaProcessing;

  calibrated;

  calibrating;

  dataValid;

  poorSignal;

  motionEvent;

  manualCalMode;

  autoCalMode;

  stopButtonPressed;

  blePulseOxConnected;

  bleThermometerConnected;

  pumpOverrun;

  criticalTemperature;

  batteryVoltageLow;

  clockWrapAround;

  inflatedIndicator;

  pressureControlIndicator;

  simulationEnabled;

  pdaEnabled;

  value;

  // size           status     time
  // 0c00 012018008c092000 06851800
  // 0c00 1520680090092000 ee881800
  constructor(buffer) {
    const size = toLE16(buffer.slice(0, 2));
    if (size !== 12) {
      throw new Error();
    }

    this.value = toLE64(buffer.slice(2, 10));
    this.timestamp = toLE32(buffer.slice(-4));

    this.pdaEnabled = (this.value & 1) !== 0;
    this.simulationEnabled = ((this.value >> 1) & 1) !== 0;
    this.pressureControlIndicator = ((this.value >> 2) & 1) !== 0;
    this.inflatedIndicator = ((this.value >> 3) & 1) !== 0;
    this.clockWrapAround = ((this.value >> 4) & 1) !== 0;
    this.batteryVoltageLow = ((this.value >> 5) & 1) !== 0;
    this.criticalTemperature = ((this.value >> 6) & 1) !== 0;
    this.pumpOverrun = ((this.value >> 7) & 1) !== 0;
    this.bleThermometerConnected = ((this.value >> 8) & 1) !== 0;
    this.blePulseOxConnected = ((this.value >> 9) & 1) !== 0;
    // 10 and 11 reserved
    this.stopButtonPressed = ((this.value >> 12) & 1) !== 0;
    this.autoCalMode = ((this.value >> 13) & 1) !== 0;
    this.manualCalMode = ((this.value >> 14) & 1) !== 0;
    // 15 16?
    this.motionEvent = ((this.value >> 17) & 1) !== 0;
    this.poorSignal = ((this.value >> 18) & 1) !== 0;
    this.dataValid = ((this.value >> 19) & 1) !== 0;
    this.calibrating = ((this.value >> 20) & 1) !== 0;
    this.calibrated = ((this.value >> 21) & 1) !== 0;
    this.betaProcessing = ((this.value >> 22) & 1) !== 0;
    this.inflateFailed = ((this.value >> 23) & 1) !== 0;
    this.calibrationFailed = (this.value >> 24) !== 0;
    this.calibrationOffsetFailed = ((this.value >> 25) & 1) !== 0;
    this.noPulseTimeout = ((this.value >> 26) & 1) !== 0;
    this.cuffTooLoose = ((this.value >> 27) & 1) !== 0;
    this.cuffTooTight = ((this.value >> 28) & 1) !== 0;
    this.weakSignal = ((this.value >> 29) & 1) !== 0;
    this.badCuff = ((this.value >> 30) & 1) !== 0;
    this.bleAdv = ((this.value >> 31) & 1) !== 0;

    this.recalSoon = ((this.value >> 32) & 1) !== 0;
    this.tooManyFails = ((this.value >> 33) & 1) !== 0;

    const autocalPct = ((this.value >> 34) & 0x7f); // 34,35,36,37,38,39,40
    this.autocalPct = autocalPct > 100 ? 100 : autocalPct;

    this.charging = ((this.value >> 41) & 1) !== 0;
    this.chargeComplete = ((this.value >> 42) & 1) !== 0;
    // TODO still feels wrong, should be 1? seeing like 5
    this.posture = ((this.value >> 43) & 0xf); // 43,44,45,46
    // 47?
    this.invalidDataEntry = ((this.value >> 48) & 1) !== 0;
    this.recalRecommended = ((this.value >> 49) & 1) !== 0;
    this.hemodynamicsEnabled = ((this.value >> 50) & 1) !== 0;
    this.cardiacOutputCalibrated = ((this.value >> 51) & 1) !== 0;
  }
}

export class VSCuffData {
  unimplemented
}

export class VSCalibrationData {
  unimplemented
}

export class VSSecondaryVitals {
  unimplemented
}

export class VSUnknown {
  buffer;

  type;

  constructor(buffer, type) {
    this.buffer = buffer;
    this.type = type;
  }
}
