const en = {
  demographics4: {
    title: 'About Me',
    instructions: 'Current Occupational Status',
    options: {
      employed: 'Employed',
      employedFull: 'working 40 hours per week',
      employedPart: 'working 1-39 hours per week',
      notEmployed: 'Not Employed',
      notEmployedLooking: 'looking for work',
      notEmployedNotLooking: 'not looking for work',
      retired: 'Retired',
      disabled: 'Disabled',
      disabledDetails: 'not able to work',
    },
  },
};

const es = {
  demographics4: {
    title: 'Sobre Mí',
    instructions: 'Estado Ocupacional Actual',
    options: {
      employed: 'Empleado',
      employedFull: 'trabajando 40 horas a la semana',
      employedPart: 'trabajando 1-39 horas por semana',
      notEmployed: 'Sin Empleo',
      notEmployedLooking: 'buscando trabajo',
      notEmployedNotLooking: 'sin buscando trabajo',
      retired: 'Retirada/o',
      disabled: 'Discapacitada/o',
      disabledDetails: 'incapaz de trabajar',
    },
  },
};

export default { en, es };
