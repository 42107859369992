const formatPhoneNumber = (number) => {
  number = number || '';
  const sanitized = number.replace(/\D/g, '');

  if (sanitized.length !== 10) return number;

  return `${sanitized.substring(0, 3)}.${sanitized.substring(3, 6)}.${sanitized.substring(6)}`;
};

export default formatPhoneNumber;
