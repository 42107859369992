import { promiseHandler, resetReducer } from 'cooldux';
import { get, isUndefined, omitBy } from 'lodash';

import { apiFetch } from '../lib/fetch';

const { bodyPainDetailsStart, bodyPainDetailsEnd, bodyPainDetailsError, bodyPainDetailsHandler } = promiseHandler('bodyPainDetails', 'bodyPainDetails');
const { headPainDetailsStart, headPainDetailsEnd, headPainDetailsError, headPainDetailsHandler } = promiseHandler('headPainDetails', 'bodyPainDetails');

export function bodyPainDetailsBrowse(pain_start_date, pain_end_date) {
  return function dispatcher(dispatch, getState) {
    const selfId = get(getState(), 'user.id', null);
    const query = omitBy({
      pain_start_date,
      pain_end_date,
    }, isUndefined);
    const promise = apiFetch(`/users/${selfId}/pain_log_details`, { query });
    return bodyPainDetailsHandler(promise, dispatch);
  };
}

export function headPainDetailsBrowse(pain_start_date, pain_end_date) {
  return function dispatcher(dispatch, getState) {
    const selfId = get(getState(), 'user.id', null);
    const query = omitBy({
      pain_start_date,
      pain_end_date,
    }, isUndefined);
    const promise = apiFetch(`/users/${selfId}/head_pain_details`, { query });
    return headPainDetailsHandler(promise, dispatch);
  };
}

const initialState = {
  bodyPainDetailsError: null,
  bodyPainDetailsPending: false,
  bodyPainData: {
    aggravating_factors: [],
    alleviating_factors: [],
    functional_limitations: [],
    pain_locations: [],
  },
  headPainData: {
    symptoms: [],
    preventitive_medications: [],
    abortive_medications: [],
    pain_locations: [],
  },
};

function finishPainLogDetailsCalendar(state, data, dataType) {
  return { ...state, [dataType]: data, bodyPainDetailsPending: false, bodyPainDetailsError: null };
}

const painLogDetails = resetReducer(initialState, (state = initialState, action) => {
  switch (action.type) {
    case bodyPainDetailsStart.type:
      return { ...state, bodyPainDetailsPending: true };
    case bodyPainDetailsEnd.type:
      return finishPainLogDetailsCalendar(state, action.payload, 'bodyPainData');
    case bodyPainDetailsError.type:
      return { ...state, headPainDetailsPending: false, headPainDetailsError: action.payload };
    case headPainDetailsStart.type:
      return { ...state, headPainDetailsPending: true };
    case headPainDetailsEnd.type:
      return finishPainLogDetailsCalendar(state, action.payload, 'headPainData');
    case headPainDetailsError.type:
      return { ...state, bodyPainDetailsPending: false, bodyPainDetailsError: action.payload };
    default:
      return state;
  }
});

export default painLogDetails;
