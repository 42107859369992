import React from 'react';
import PropTypes from 'prop-types';

const styles = {
  fill: 'none',
  strokeLinejoin: 'round',
  strokeWidth: 6,
  width: '5vh',
  height: '2em',
};

const SupportIcon = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 69.15 79.67"
    style={{ ...styles, stroke: color }}
  >
    <title>Support1x</title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path d="M66.15,34.58A31.58,31.58,0,0,0,3,34.58C3,52,18.93,63,34.58,62.15V76.67S66.15,65.92,66.15,34.58" />
      </g>
    </g>
  </svg>
);

SupportIcon.defaultProps = {
  color: '#b2b2b2',
};

SupportIcon.propTypes = {
  color: PropTypes.string,
};

export default SupportIcon;
