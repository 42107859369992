import React from 'react';
import { connect } from 'react-redux';
import { filter, orderBy, forEach } from 'lodash';
import { List } from '@material-ui/core';

import AppBar from '../components/app-bar';
import Page from './page';
import Layout from '../layouts/common';
import { conditionsIfNeeded } from '../state/app-data';
import { browseConditionsHx, addConditionHx, deleteConditionHx } from '../state/conditions-hx';
import Continue from '../components/continue';
import Subtitle from '../components/subtitle';
import SimpleListItem from '../components/simple-list-item';

import { logEvent } from '../lib/amplitude';
import { colors } from '../lib/styles';

const baseStyles = {
  font: {
    color: colors.primaryColor,
    fontSize: 10,
  },
  list: {
    backgroundColor: colors.white,
    textTransform: 'capitalize',
  },
  secondarySubtitle: {
    fontSize: '14px',
  },
  text: {
    color: colors.primaryColor,
  },
  underline: {
    display: 'none',
  },
};

class Conditions extends Page {
  constructor(props) {
    super(props);
    this.handleCheck = this.handleCheck.bind(this);
    this.handleContinue = this.handleContinue.bind(this);
    this.getHx = this.getHx.bind(this);
  }

  componentWillMount() {
    logEvent('Entered - Medical Conditions');
    this.props.conditionsIfNeeded();
    this.props.browseConditionsHx();
  }

  handleCheck(conditions_id, checked) {
    if (checked) {
      return this.props.addConditionHx({ conditions_id, relationship: 'SELF' });
    }
    const hx = this.getHx(conditions_id, 'SELF');
    if (hx) {
      this.props.deleteConditionHx(hx.id);
    }
  }

  handleContinue() {
    logEvent('Submitted Medical Conditions');
    this.forward();
  }

  getHx(id, relationship) {
    const { data } = this.props.conditionsHx;
    return filter(data, { conditions_id: id, relationship })[0];
  }

  render() {
    const { conditions } = this.props.appData;
    const sortedHx = orderBy(conditions, [conditions => conditions.name.toLowerCase()], ['desc']);
    const listItems = [];

    forEach(sortedHx, (obj) => {
      const hx = this.getHx(obj.id, 'SELF');

      const simpleListItem = (
        <SimpleListItem
          key={obj.id}
          item={obj}
          checked={!!hx}
          onAddItem={this.handleCheck}
          onRemoveItem={this.handleCheck}
        />
      );
      // eslint-disable-next-line no-unused-expressions
      (obj.name.toLowerCase() === 'none reported') ? listItems.push(simpleListItem) : listItems.unshift(simpleListItem);
    });

    return (
      <Layout>
        <div>
          <AppBar
            backButtonOnClick={() => this.props.router.goBack()}
            headerNode="Medical Conditions"
          />
          <Subtitle label="Check if you've ever been treated for:" />
          <Subtitle label="SCROLL TO SEE ALL OPTIONS" style={baseStyles.secondarySubtitle} />
          <List style={baseStyles.list}>
            {listItems}
          </List>
          <section style={{ backgroundColor: colors.white }}>
            <Continue onClick={this.handleContinue} />
          </section>
        </div>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  const { user, appData, conditionsHx } = state;
  return {
    user,
    appData,
    conditionsHx,
  };
}

export default connect(mapStateToProps, {
  conditionsIfNeeded,
  browseConditionsHx,
  addConditionHx,
  deleteConditionHx,
})(Conditions);
