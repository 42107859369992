import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get, isNumber } from 'lodash';

import { TextField } from '@material-ui/core';
import { colors } from '../lib/styles';
import Page from './page';
import { SocialLife2 } from '../lib/icons';
import { updatePRO } from '../state/pro-forms';
import AppBar from '../components/app-bar';
import QuestionLayout from '../layouts/question';
import LinearProgress from '../components/linear-progress';

const styles = {
  image: {
    height: 120,
    padding: '15px 60px',
    textAlign: 'center',
  },
  pageIndicator: {
    color: 'black',
    display: 'inline-block',
    fontSize: 15,
  },
  questionnaire: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginLeft: 15,
    position: 'relative',
    minHeight: '350px',
  },
  title: {
    color: 'black',
    fontSize: '1.5rem',
    fontWeight: 800,
    textTransform: 'uppercase',
  },
  number: {
    width: '50px',
    alignSelf: 'center',
  },
  titleGroup: {
    display: 'flex',
    flexDirection: 'column',
  },
};

const { questionBackground } = colors;

class MidasSocial extends Page {
  onClickBackButton = () => {
    this.backward();
  }

  onSubmission = () => {
    this.forwardWithQuery(this.props.location.query);
  }

  handleChange = (event) => {
    let value = parseInt(event.target.value, 10);
    if (value > 90) {
      value = 90;
    }
    if (value < 0) {
      value = 0;
    }
    this.props.updatePRO({
      type: 'midas',
      position: this.getTrackIndex(),
      value,
    });
  }

  render() {
    const currentPage = this.getCurrentPage();
    const totalPages = this.getTotalPages();
    const page = get(this.props.midas, this.getTrackIndex(), null);
    return (
      <QuestionLayout
        primaryBtnDisabled={!isNumber(page)}
        primaryBtnLabel="Next"
        primaryBtnOnTouchTap={this.onSubmission}
        style={{ background: questionBackground }}
      >
        <AppBar
          backButtonOnClick={() => this.props.router.goBack()}
          headerNode="Social Life"
          rightNode={
            <div style={styles.pageIndicator}>{`${currentPage} of ${totalPages}`}</div>
          }
        />
        <LinearProgress value={(currentPage / totalPages) * 100} />
        <div style={styles.image}>
          <SocialLife2 />
        </div>
        <div style={styles.questionnaire}>
          <div style={styles.titleGroup}>
            <div style={styles.title}>Over the last 3 months</div>
            <div className="instructions">How many days did you miss family, social or leisure activities because of your headaches?</div>
          </div>
          <TextField
            style={styles.number}
            value={isNumber(page) ? page : ''}
            onChange={this.handleChange}
            type="number"
            margin="normal"
            inputProps={{
              inputMode: 'decimal',
              pattern: '[0-9]*',
              type: 'tel',
              style: { fontSize: '16px', textAlign: 'center' } }}
          />
        </div>
      </QuestionLayout>
    );
  }
}

function mapStateToProps(state) {
  const { proForms: { midas } } = state;
  return { midas };
}

MidasSocial.propTypes = {
  midas: PropTypes.array.isRequired,
  updatePRO: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { updatePRO })(MidasSocial);
