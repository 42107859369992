import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Page from './page';
import { colors } from '../lib/styles';
import AppBar from '../components/app-bar';
import Continue from '../components/continue';
import { submitPRO, updatePRO } from '../state/pro-forms';

const styles = {
  instructions: {
    fontSize: '1.3rem',
  },
  image: {
    height: 120,
    padding: '15px 60px',
    textAlign: 'center',
  },
  linearProgress: {
    borderRadius: 0,
  },
  pageIndicator: {
    color: 'grey',
    display: 'inline-block',
    fontSize: 13,
  },
  questionnaire: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    position: 'relative',
    minHeight: '350px',
  },
  title: {
    color: 'black',
    fontSize: '1.8rem',
    fontWeight: 800,
    textTransform: 'uppercase',
    alignSelf: 'center',
  },
  date: {
    width: '150px',
    alignSelf: 'center',
    margin: '20px',
  },
  titleGroup: {
    margin: 25,
    textAlign: 'center',
  },
  error: {
    color: colors.errorRed,
    alignSelf: 'center',
  },
  text: {
    fontSize: '1.8rem',
  },
  buttonGroup: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '20px',
  },
};

class BodyPainPro1 extends Page {
  render() {
    const { id } = this.props.location.query;
    const { user } = this.props;
    return (
      <div>
        <AppBar
          headerNode="Clinic Access"
          noLeftNode={true}
        />
        <div style={styles.questionnaire}>
          <div style={styles.titleGroup}>
            <p style={styles.text}>{`${user.requesting_clinic_name} would like to send you assessments as part of your health treatment plan. By clicking the Agree button below, you agree to share your responses with ${user.requesting_clinic_name}.`}</p>
          </div>
          <div style={styles.buttonGroup}>
            <Continue
              text="Decline"
              onClick={() => {
                this.props.submitPRO(
                  { userId: user.id, id },
                  {
                    pro_type: 'CLINIC-ACCESS',
                    pro_data: { data: [false] },
                  },
                  user,
                );
                this.forward();
              }}
              btnStyle={{ maxWidth: '14rem', minWidth: '10rem', margin: '50px 10px 0', alignSelf: 'center', padding: '10px' }}
            />
            <Continue
              text="Agree"
              onClick={() => {
                this.props.submitPRO(
                  { userId: user.id, id },
                  {
                    pro_type: 'CLINIC-ACCESS',
                    pro_data: { data: [true, { user_id: user.id, clinic_id: user.requesting_clinic_id }] },
                  },
                  user,
                );
                this.forward();
              }}
              btnStyle={{ maxWidth: '14rem', minWidth: '10rem', margin: '50px 10px 0', alignSelf: 'center' }}
            />
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state;
  return { user };
}

BodyPainPro1.proptypes = {
  user: PropTypes.object.isRequired,
  route: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, { submitPRO, updatePRO })(BodyPainPro1);
