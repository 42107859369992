import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import i18nTranslator from '../lib/i18next';
import DisableSessionTimeout from '../components/disable-session-timeout';
import Page from './page';

const styles = {
  boldText: {
    fontSize: '2rem',
    fontWeight: 'bold',
    marginBottom: 20,
  },
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '0px 20px',
    flexGrow: 1,
    textAlign: 'center',
  },
  greenCheckMark: {
    marginTop: 30,
    marginBottom: 30,
    width: '8rem',
    height: '8rem',
  },
  text: {
    fontSize: '2rem',
    marginBottom: 20,
  },
};

class WoundCasaColina5 extends Page {
  render() {
    const { classes } = this.props;

    return (
      <div className={classes.contentWrapper}>
        <DisableSessionTimeout />
        <img
          src="/img/GreenCheckmarkCircle.png"
          className={classes.greenCheckMark}
          alt="Green check mark"
        />
        <div className={classes.boldText}>{i18nTranslator('line1', 'woundCasaColina5')}</div>
        <div className={classes.text}>{i18nTranslator('line2', 'woundCasaColina5')}</div>
        <div className={classes.text}>{i18nTranslator('line3', 'woundCasaColina5')}</div>
        <div className={classes.text}>{i18nTranslator('line4', 'woundCasaColina5')}</div>
      </div>
    );
  }
}

export default withStyles(styles)(WoundCasaColina5);
