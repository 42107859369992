import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { Button, LinearProgress } from '@material-ui/core';

import AppBar from '../components/app-bar';
import Continue from '../components/continue';

import { Camera } from '../lib/icons';

import Page from './page';

import { updatePRO, submitPRO } from '../state/pro-forms';

const styles = {
  button: {
    margin: 5,
    width: 150,
    height: 40,
    fontSize: 16,
  },
  buttonGroup: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  iconContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    flexGrow: 0,
    flexShrink: 0,
    width: '100%',
    color: '#000',
    paddingTop: 10,
  },
  iconWrapper: {
    width: 100,
  },
  input: {
    display: 'none',
  },
  titleGroup: {
    margin: 25,
  },
  text: {
    fontSize: '1.75rem',
  },
  nextBtn: {
    textAlign: 'center',
    width: '100%',
    top: '90%',
    position: 'fixed',
  },
  cameraNext: {
    backgroundColor: 'rgb(32, 123, 204)',
    borderRadius: '0.3125rem',
    minWidth: '17.1875rem',
    marginBottom: '0.625rem',
    marginTop: '0.0625rem',
    width: '85vw',
    height: '4rem',
    fontSize: '1.4rem',
  },
};

class VitalsDataCollection6 extends Page {
  constructor(props) {
    super(props);

    this.state = {
      cameraPermissions: null,
      image: null,
      file: null,
    };

    this.handleContinue = this.handleContinue.bind(this);
    this.handleComplete = this.handleComplete.bind(this);
  }

  handleContinue() {
    this.props.updatePRO({
      type: 'vitalsDataCollection',
      position: this.getTrackIndex(),
      value: this.state });
    this.forwardWithQuery(this.props.location.query);
  }

  handleComplete = async () => {
    const { cameraPermissions } = this.state;
    const { id } = this.props.location.query;
    const { user, vitalsDataCollection: vc } = this.props;

    try {
      const data = {...vc[0], ...vc[1], ...vc[2], ...vc[3], ...vc[4]};
      data.proId = id;
      data.userId = user.id;
      data.vitals = {};
      data.cameraPermissions = cameraPermissions;
      await this.props.submitPRO({ userId: user.id, id }, {
        pro_type: 'VITALS-DATA-COLLECTION',
        pro_data: { data },
      }, user);
      this.finishTrack();
    } catch (e) {
      this.setState({ submitting: false, error: e });
      console.error(e);
    }
  }

  render() {
    const { cameraPermissions } = this.state;

    let btn = (
      <div style={styles.nextBtn}>
        <Continue
          disabled={cameraPermissions === null}
          text="Next"
          onClick={this.handleComplete}
        />
      </div>
    );

    if (cameraPermissions === true) {
      btn = (
        <div style={styles.nextBtn}>
          <Continue
            disabled={cameraPermissions === null}
            text="Next"
            onClick={this.handleContinue}
          />
        </div>
      );
    }

    return (
      <Fragment>
        <AppBar headerNode="Lighting Conditions" noLeftNode={true} />
        <LinearProgress
          variant="determinate"
          value={(this.getCurrentPage() / this.getTotalPages()) * 100}
        />
        <div style={styles.iconContainer}>
          <div style={styles.iconWrapper}>
            <Camera />
          </div>
        </div>

        <div style={styles.titleGroup}>
          <p style={styles.text}>
            We're interested in the lighting conditions while you took your
            vitals. Can we take photos of you?
          </p>
          <div style={styles.buttonGroup}>
            <Button
              style={styles.button}
              variant="contained"
              color={cameraPermissions ? 'primary' : 'default'}
              onClick={() => this.setState({ cameraPermissions: true })}
            >
              Yes
            </Button>
            <Button
              style={styles.button}
              variant="contained"
              color={cameraPermissions === false ? 'primary' : 'default'}
              onClick={() => this.setState({ cameraPermissions: false })}
            >
              No
            </Button>
          </div>
        </div>
        {btn}
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const {
    user,
    proForms: { vitalsDataCollection },
  } = state;
  return { user, vitalsDataCollection };
}

VitalsDataCollection6.proptypes = {
  user: PropTypes.object.isRequired,
  route: PropTypes.object.isRequired,
  updatePRO: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, {
  updatePRO,
  submitPRO,
})(withStyles(styles)(VitalsDataCollection6));
