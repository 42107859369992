import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Page from './page';
import Layout from '../layouts/common';
import { colors } from '../lib/styles';
import { updatePRO, submitPRO } from '../state/pro-forms';
import Continue from '../components/continue';

const baseStyles = {
  paragraph: {
    textAlign: 'center',
    marginLeft: 15,
    marginRight: 15,
    color: colors.primaryColor,
    width: '300px',
    fontSize: '16px',
    margin: '20px auto',
  },
  button: {
    backgroundColor: colors.secondaryColor,
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

class RothmanDisclaimer extends Page {
  onSubmission = () => {
    const { user } = this.props;
    const { id } = this.props.location.query;
    this.setState({ submitting: true });
    this.props.submitPRO({ userId: user.id, id }, {
      pro_type: 'ROTHMAN-DISCLAIMER',
      pro_data: { data: { accepted: true } },
    }, user)
      .then(() => this.forward())
      .catch(() => this.setState({ submitting: false }));
  }

  render() {
    return (
      <Layout>
        <div>
          <h1 style={{ color: colors.primaryColor, fontSize: '30px', margin: '40px auto' }}>Disclaimer</h1>
          <div style={baseStyles.paragraph}>
            {'Alex Vaccaro, MD is an advisor and has equity in entities aligned with passive and active patient data collection.'}
          </div>
          <div style={baseStyles.center}>
            <Continue
              text="Accept"
              btnStyle={baseStyles.button}
              onClick={this.onSubmission}
            />
          </div>
        </div>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state;
  return { user };
}

RothmanDisclaimer.propTypes = {
  user: PropTypes.object.isRequired,
  updatePRO: PropTypes.func.isRequired,
  submitPRO: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { submitPRO, updatePRO })(RothmanDisclaimer);
