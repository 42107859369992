import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { TextareaAutosize } from '@material-ui/core';
import { connect } from 'react-redux';

import Page from './page';
import AppBar from '../components/app-bar';
import IconWrapper from '../components/icon-wrapper';
import BloomkidzLayout from '../layouts/bloomkidz';
import { ClipboardListSolid } from '../lib/icons';
import { updatePRO } from '../state/pro-forms';
import { bloomkidzStylesObj } from '../lib/bloomkidz';

class BloomkidzMedicalHistory7 extends Page {
  constructor(props) {
    super(props);

    this.state = {
      experienceElaboration: '',
    };
  }

  handleContinue = () => {
    const { location, updatePRO } = this.props;

    updatePRO({
      type: 'bloomkidzMedicalHistory',
      position: this.getTrackIndex(),
      value: { ...this.state },
    });

    this.forwardWithQuery(location.query);
  }

  handleTextAreaChange = (e) => {
    this.setState({ experienceElaboration: e.target.value });
  };

  render() {
    const { classes, router } = this.props;
    const { experienceElaboration } = this.state;

    return (
      <BloomkidzLayout
        primaryBtnOnTouchTap={this.handleContinue}
        primaryBtnLabel="Next"
        primaryBtnDisabled={!experienceElaboration}
      >
        <AppBar
          headerNode="Experience"
          backButtonOnClick={() => router.goBack()}
        />
        <IconWrapper>
          <ClipboardListSolid />
        </IconWrapper>
        <div className={classes.pageContent}>
          <div>Please elaborate on any checked boxes:</div>
          <TextareaAutosize
            value={experienceElaboration}
            onChange={this.handleTextAreaChange}
            minRows={10}
            className={classes.textAreaAutosize}
            placeholder="Tap here to type"
          />
        </div>
      </BloomkidzLayout>
    );
  }
}

BloomkidzMedicalHistory7.propTypes = {
  classes: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  updatePRO: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const {
    proForms: { bloomkidzMedicalHistory },
    user,
  } = state;

  return { user, bloomkidzMedicalHistory };
};

export default connect(mapStateToProps, { updatePRO })(withStyles(bloomkidzStylesObj)(BloomkidzMedicalHistory7));
