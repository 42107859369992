import React, { Component } from 'react';
import { isNumber } from 'lodash';
import PropTypes from 'prop-types';
import LinearProgress from '@material-ui/core/LinearProgress';
import { withStyles } from '@material-ui/core/styles';

import AppBar from './app-bar';
import { colors } from '../lib/styles';
import QuestionLayout from '../layouts/question';
import ReactSlider from './react-slider';
import PainSliderLabels from './pain-slider-labels';

const { lightBrightBlue } = colors;

const styles = {
  image: {
    display: 'block',
    height: '150px',
    margin: '30px auto 45px',
  },
  instructions: {
    color: 'grey',
    fontSize: 13,
    margin: '10px 65px',
    textAlign: 'center',
  },
  pageIndicator: {
    color: 'grey',
    display: 'inline-block',
    fontSize: 13,
  },
  slider: {
    margin: '20px 20px 10px',
  },
  bar: {
    backgroundColor: lightBrightBlue,
  },
};

class PainScaleSingleSlider extends Component {
  render() {
    const {
      backgroundColor,
      currentPage,
      image: Image,
      instructions,
      onClickBackButton,
      onSubmission,
      sliderMax,
      sliderMin,
      title,
      totalPages,
      value,
      classes,
    } = this.props;
    const background = backgroundColor ? { backgroundColor } : {};

    return (
      <QuestionLayout
        primaryBtnDisabled={!isNumber(value)}
        primaryBtnLabel="Next"
        primaryBtnOnTouchTap={onSubmission}
        style={background}
      >
        <AppBar
          backButtonOnClick={onClickBackButton}
          headerNode={title}
          rightNode={
            <div style={styles.pageIndicator}>{`${currentPage} of ${totalPages}`}</div>
          }
        />
        <LinearProgress
          variant="determinate"
          value={(currentPage / totalPages) * 100}
          classes={{ bar: classes.bar }}
        />
        <div style={styles.instructions}>
          {instructions}
        </div>
        <div style={styles.image}>
        <Image style={styles.image} alt="" />
        </div>
        <div style={styles.slider}>
          <ReactSlider
            max={sliderMax}
            min={sliderMin}
            minLabel="No Pain"
            maxLabel="Unbearable Pain"
            onChangeComplete={this.props.onChange}
            showLabels={false}
            value={value}
          />
          <PainSliderLabels />
        </div>
      </QuestionLayout>
    );
  }
}

PainScaleSingleSlider.defaultProps = {
  backgroundColor: undefined,
};

PainScaleSingleSlider.propTypes = {
  backgroundColor: PropTypes.string,
  image: PropTypes.node.isRequired,
  instructions: PropTypes.string.isRequired,
  currentPage: PropTypes.number.isRequired,
  onClickBackButton: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmission: PropTypes.func.isRequired,
  sliderMax: PropTypes.number.isRequired,
  sliderMin: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  totalPages: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default withStyles(styles)(PainScaleSingleSlider);
