import React from 'react';
import { LinearProgress, TextField } from '@material-ui/core';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import AppBar from '../components/app-bar';
import Page from './page';
import QuestionLayout from '../layouts/question';

import YesNoButtonSet from '../components/yes-no-button-set';
import { colors } from '../lib/styles';
import { submitPRO } from '../state/pro-forms';
import { Pills } from '../lib/icons';

const styles = {
  bar: {
    backgroundColor: colors.lightBrightBlue,
  },
  contentWrapper: {
    flexGrow: 1,
    overflowX: 'hidden',
    position: 'relative',
  },
  imgRow: {
    display: 'flex',
    justifyContent: 'center',
    padding: '15px 60px',
  },
  imageWrapper: {
    width: 100,
  },
  marginTop40: {
    marginTop: 40,
  },
  questionnaireContainer: {
    paddingLeft: 20,
    paddingRight: 20,
  },
  questionText: {
    fontSize: '2rem',
  },
  textFieldRoot: {
    marginTop: 10,
    width: 256,
    '& input': {
      fontSize: 18,
    },
    '& label': {
      fontSize: 18,
    },
  },
};

class HypertensionAssessmentDaily8 extends Page {
  constructor(props) {
    super(props);

    this.state = {
      experiencingNewSymptoms: null,
      newSymptoms: '',
    };
  }

  handleChangeNewSymptoms = (e) => {
    this.setState({ newSymptoms: e.target.value });
  }

  handleChangeTimeMedicationsTaken = (value) => {
    this.setState({ timeMedicationsTaken: value });
  };

  handleChangeExperiencingNewSymptoms = (value) => {
    this.setState({ experiencingNewSymptoms: value });
  };

  handleClickNext = () => {
    const { hypertensionAssessmentDaily, user } = this.props;
    const {
      experiencingNewSymptoms,
      newSymptoms,
    } = this.state;
    const { id } = this.props.location.query;

    const value = { experiencingNewSymptoms };

    if (experiencingNewSymptoms === 'Y') {
      value.newSymptoms = newSymptoms;
    }

    this.props.submitPRO({ userId: user.id, id }, {
      pro_type: 'HYPERTENSION-ASSESSMENT-DAILY-NO-VITALS',
      pro_data: {
        data: [
          ...hypertensionAssessmentDaily,
          value,
        ],
        recorded_at: new Date().toISOString(),
      },
    }, user)
      .then(() => this.finishTrack());
  }

  render() {
    const { classes } = this.props;
    const {
      experiencingNewSymptoms,
      newSymptoms,
    } = this.state;

    const continueDisabled = experiencingNewSymptoms === null
      || (experiencingNewSymptoms === 'Y' && !newSymptoms);

    return (
      <QuestionLayout
        primaryBtnLabel="Next"
        primaryBtnOnTouchTap={this.handleClickNext}
        primaryBtnDisabled={continueDisabled}
        style={{ backgroundColor: colors.questionBackground }}
      >
        <AppBar
          headerNode="Symptoms"
          backButtonOnClick={() => this.props.router.goBack()}
        />
        <LinearProgress
          variant="determinate"
          value={(this.getCurrentPage() / this.getTotalPages()) * 100}
          classes={{ bar: classes.bar }}
        />
        <div className={classes.contentWrapper}>
          <div className={classes.imgRow}>
            <div className={classes.imageWrapper}>
              <Pills />
            </div>
          </div>
          <div className={classes.questionnaireContainer}>
            <YesNoButtonSet
              isYes={experiencingNewSymptoms === 'Y'}
              isNo={experiencingNewSymptoms === 'N'}
              onClickNo={() => this.handleChangeExperiencingNewSymptoms('N')}
              onClickYes={() => this.handleChangeExperiencingNewSymptoms('Y')}
              questionText="Are you experiencing any new symptoms today?"
            />
            {experiencingNewSymptoms === 'Y' ? (
              <>
                <div className={`${classes.questionText} ${classes.marginTop40}`}>Please list them:</div>
                <TextField
                  label="Tap here to enter"
                  onChange={this.handleChangeNewSymptoms}
                  classes={{ root: classes.textFieldRoot }}
                  value={newSymptoms}
                />
              </>
            ) : null}
          </div>
        </div>
      </QuestionLayout>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    proForms: {
      hypertensionAssessmentDaily,
    },
    user,
  } = state;

  return { hypertensionAssessmentDaily, user };
};

HypertensionAssessmentDaily8.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, { submitPRO })(withStyles(styles)(HypertensionAssessmentDaily8));
