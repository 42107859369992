const en = {
  videoBroadcast2: {
    finishBtn: "I'm Finished",
  },
};

const es = {
  videoBroadcast2: {
    finishBtn: 'Terminé',
  },
};

export default { en, es };
