import React from 'react';
import { capitalize, get } from 'lodash';
import { Divider, TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

import Page from './page';
import Layout from '../layouts/common';
import { colors } from '../lib/styles';
import AppBar from '../components/app-bar';
import Continue from '../components/continue';
import ProviderSelection from '../components/provider-selection';

import { apiFetch } from '../lib/fetch';

import State from '../components/state';

const baseStyles = {
  center: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  flexStart: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  marginLeft: {
    marginLeft: '15px',
  },
  marginTop: {
    marginTop: '30px',
  },
  menuItem: {
    color: colors.primaryColor,
  },
  highlightDark: {
    color: colors.highlightDark,
  },
  physicianDetails: {
    fontSize: '10px',
    color: colors.primaryColor,
    textAlign: 'left',
    marginLeft: '15px',
  },
  redErrorText: {
    color: colors.errorRed,
    marginLeft: '15px',
    marginRight: '15px',
    textAlign: 'center',
  },
  text: {
    color: colors.primaryColor,
  },
  underline: {
    display: 'none',
  },
  whiteBackgroundSection: {
    backgroundColor: colors.white,
    textAlign: 'left',
  },
};

const classStyles = {
  textField: {
    marginBottom: 5,
    marginTop: 5,
    '& input': {
      color: colors.greyText,
    },
    '& .MuiInput-underline:before': {
      borderBottom: 'none',
    },
  },
};

const initialState = {
  selection: null,
  errors: {},
  physician: {
    first_name: '',
    last_name: '',
    city: '',
    state: 'none',
  },
};

class NpiSearch extends Page {
  constructor(props) {
    super(props);
    this.state = initialState;
    this.handleUpdateFirstName = this.handleTextNewMUI.bind(this, 'first_name');
    this.handleUpdateLastName = this.handleTextNewMUI.bind(this, 'last_name');
    this.handleUpdateCity = this.handleTextNewMUI.bind(this, 'city');
    this.handleUpdateState = this.handleTextNewMUI.bind(this, 'state');
  }

  handleText(field, e, text, value) {
    this.setState({
      errors: {
        ...this.state.errors,
        form: '',
        [field]: '',
      },
      physician: {
        ...this.state.physician,
        [field]: value || text,
      },
    });
  }

  handleTextNewMUI(field, e) {
    this.setState({
      errors: {
        ...this.state.errors,
        form: '',
        [field]: '',
      },
      physician: {
        ...this.state.physician,
        [field]: e.target.value,
      },
    });
  }

  handleMoveOn = () => {
    this.props.router.push({ pathname: '/physician', query: { city: this.state.physician.city, state: this.state.physician.state, first_name: this.state.physician.first_name, last_name: this.state.physician.last_name } });
  }

  handleSelectProvider = (provider = {}) => {
    const prefix = get(provider, 'basic.name_prefix', '');
    const first = get(provider, 'basic.first_name', '');
    const last = get(provider, 'basic.last_name', '');
    const physician_name = `${capitalize(prefix)} ${capitalize(first)} ${capitalize(last)}`;
    const description = capitalize(get(provider, 'taxonomies.0.desc', ''));

    const street = get(provider, 'addresses.0.address_1', '');
    const city = capitalize(get(provider, 'addresses.0.city', ''));
    const state = capitalize(get(provider, 'addresses.0.state', ''));

    const zip_code = get(provider, 'addresses.0.postal_code', '');
    const fax = get(provider, 'addresses.0.fax_number', '');
    const phone = get(provider, 'addresses.0.telephone_number', '');

    const npi_number = provider.number;
    const query = {
      physician_name,
      description,
      npi_number,
      phone,
      fax,
      street,
      city,
      zip_code,
      state,
    };
    const params = new URLSearchParams(query).toString();
    this.props.router.push('/physician?' + params);
  }

  handleStartOver = () => {
    this.setState(initialState);
  }

  handleSearch = () => {
    const { first_name, last_name, city, state } = this.state.physician;
    const errors = {};
    if (!city) {
      errors.city = 'City is required!';
    }

    if (!last_name) {
      errors.last_name = 'Last Name is required';
    }

    if (Object.keys(errors).length) {
      return this.setState({ errors });
    }

    const query = {
      first_name,
      last_name,
      city,
      state: state === 'none' ? null : state,
    };

    this.setState({ searching: true });
    apiFetch('/npi-search', { query })
      .then(({ results }) => {
        this.setState({
          searching: false,
          selection: results,
        });
      })
      .catch(() => {
        this.setState({
          selection: null,
          searching: false,
          errorMessage: 'An Error Occured',
        });
      });
  }

  render() {
    const { errors, searching, selection, physician, physicianSearchError } = this.state;
    const { classes } = this.props;

    if (physicianSearchError || (selection && !selection.length)) {
      return (
        <Layout>
          <AppBar
            backButtonOnClick={() => this.props.router.push('/personalinfo')}
            headerNode="Physician Search"
          />
          <section style={{ margin: '20px', height: '250px' }}>
            {physicianSearchError || 'No Physicians found.'}
          </section>
          <section>
            <Continue onClick={this.handleMoveOn} text="Manual Entry" />
            <Continue onClick={this.handleStartOver} text="Start Over" />
          </section>
        </Layout>
      );
    }
    if (selection) {
      return (
        <Layout>
          <AppBar
            backButtonOnClick={this.handleStartOver}
            headerNode="Select Your Physician"
          />
          <ProviderSelection
            providers={selection}
            onStartOver={this.handleStartOver}
            onSelectProvider={this.handleSelectProvider}
            onGiveUp={this.handleGiveUp}
          />
        </Layout>
      );
    }
    return (
      <Layout>
        <AppBar
          backButtonOnClick={() => this.props.router.push('/personalinfo')}
          headerNode="Physician Search"
        />
        <section>
          <p
            style={baseStyles.physicianDetails}
          >Physician Search
          </p>
        </section>
        <section className="form" style={baseStyles.whiteBackgroundSection}>
          <TextField
            label="Physician First Name"
            onChange={this.handleUpdateFirstName}
            style={baseStyles.marginLeft}
            value={physician.first_name}
            error={!!errors.first_name}
            helperText={errors.first_name}
            classes={{ root: classes.textField }}
          />
          <Divider />
          <TextField
            label="Physician Last Name *"
            onChange={this.handleUpdateLastName}
            style={baseStyles.marginLeft}
            value={physician.last_name}
            error={!!errors.last_name}
            helperText={errors.last_name}
            classes={{ root: classes.textField }}
          />
          <Divider />
          <section style={{ alignItems: 'center', display: 'flex', justifyContent: 'space-between' }}>
            <TextField
              label="Practice City *"
              onChange={this.handleUpdateCity}
              style={baseStyles.marginLeft}
              value={physician.city}
              error={!!errors.city}
              helperText={errors.city}
              classes={{ root: classes.textField }}
            />
            <State
              onChange={this.handleUpdateState}
              errorText={errors.state}
              text="Practice State"
              value={physician.state}
            />
          </section>
          <Divider />
        </section>
        <div style={baseStyles.marginTop}>
          <Continue disabled={searching} onClick={this.handleSearch} text="Find my Doctor" />
          {this.state.errors.form}
        </div>
      </Layout>
    );
  }
}

export default withStyles(classStyles)(NpiSearch);
