import React from 'react';
import {
  FormControlLabel,
  LinearProgress,
  Radio,
  RadioGroup,
  TextField,
} from '@material-ui/core';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import AppBar from '../components/app-bar';
import Page from './page';
import QuestionLayout from '../layouts/question';
import { title, instructions, options, nextBtn } from '../lib/i18next';
import { colors } from '../lib/styles';
import { updatePRO } from '../state/pro-forms';

const styles = {
  formControlLabelRoot: {
    alignItems: 'start',
    marginTop: 5,
    '& .MuiSvgIcon-root': {
      height: 20,
      width: 20,
    },
  },
  instructionText: {
    fontSize: 29,
    fontWeight: 'bold',
  },
  questionnaireContainer: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 20,
  },
  radio: {
    paddingTop: 10,
  },
  radioLabel: {
    fontSize: 29,
  },
  textField: {
    marginLeft: 28,
    '& input': {
      fontSize: 29,
    },
    '& label': {
      fontSize: 29,
    },
  },
};

class PatientDemographics3 extends Page {
  constructor(props) {
    super(props);

    this.state = {
      ethnicity: '',
      ethnicityOther: '',
    };
  }

  handleChangeAnotherSymptom = (e) => {
    this.setState({ ethnicityOther: e.target.value });
  }

  handleChangeEthnicity = (e) => {
    this.setState({ ethnicity: e.target.value });
  };

  handleClickNext = () => {
    const { ethnicity, ethnicityOther } = this.state;

    const value = ethnicity === 'other' ? ethnicityOther : ethnicity;

    this.props.updatePRO({
      type: 'patientDemographics',
      position: this.getTrackIndex(),
      value: { ethnicity: value },
    });

    this.forwardWithQuery(this.props.location.query);
  }

  render() {
    const { classes, user: { primary_language } } = this.props;
    const { ethnicity, ethnicityOther } = this.state;

    const continueDisabled = !ethnicity
    || (ethnicity === 'other' && !ethnicityOther);

    const placeholder = primary_language === 'en' ? 'Tap to enter' : 'Toca aquí para entrar';

    return (
      <QuestionLayout
        primaryBtnLabel={nextBtn()}
        primaryBtnOnTouchTap={this.handleClickNext}
        primaryBtnDisabled={continueDisabled}
        style={{ backgroundColor: colors.questionBackground }}
      >
        <AppBar
          headerNode={title('demographics3')}
          backButtonOnClick={() => this.props.router.goBack()}
        />
        <LinearProgress
          variant="determinate"
          value={(this.getCurrentPage() / this.getTotalPages()) * 100}
          classes={{ bar: classes.bar }}
        />
        <div className={classes.questionnaireContainer}>
          <div className={classes.instructionText}>
            {instructions('demographics3')}
          </div>
          <RadioGroup value={ethnicity} onChange={this.handleChangeEthnicity}>
            <FormControlLabel
              value="Hispanic"
              label={options('hispanic', 'demographics3')}
              control={<Radio classes={{ root: classes.radio }} />}
              classes={{ root: classes.formControlLabelRoot, label: classes.radioLabel }}
            />
            <FormControlLabel
              value="Caucasian/Non-Hispanic"
              label={options('caucasian', 'demographics3')}
              control={<Radio classes={{ root: classes.radio }} />}
              classes={{ root: classes.formControlLabelRoot, label: classes.radioLabel }}
            />
            <FormControlLabel
              value="Black or African American"
              label={options('blackAmerican', 'demographics3')}
              control={<Radio classes={{ root: classes.radio }} />}
              classes={{ root: classes.formControlLabelRoot, label: classes.radioLabel }}
            />
            <FormControlLabel
              value="Native American"
              label={options('nativeAmerican', 'demographics3')}
              control={<Radio classes={{ root: classes.radio }} />}
              classes={{ root: classes.formControlLabelRoot, label: classes.radioLabel }}
            />
            <FormControlLabel
              value="Asian"
              label={options('asianAmerican', 'demographics3')}
              control={<Radio classes={{ root: classes.radio }} />}
              classes={{ root: classes.formControlLabelRoot, label: classes.radioLabel }}
            />
            <FormControlLabel
              value="Native Hawaiian/Pacific Islander"
              label={options('pacificIslander', 'demographics3')}
              control={<Radio classes={{ root: classes.radio }} />}
              classes={{ root: classes.formControlLabelRoot, label: classes.radioLabel }}
            />
            <FormControlLabel
              value="other"
              label={options('other', 'demographics3')}
              control={<Radio classes={{ root: classes.radio }} />}
              classes={{ root: classes.formControlLabelRoot, label: classes.radioLabel }}
            />
          </RadioGroup>
          {ethnicity === 'other' ? (
            <TextField
              placeholder={placeholder}
              onChange={this.handleChangeAnotherSymptom}
              classes={{ root: classes.textField }}
              value={ethnicityOther}
            />
          ) : null}
        </div>
      </QuestionLayout>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state;
  return { user };
}

export default connect(mapStateToProps, { updatePRO })(withStyles(styles)(PatientDemographics3));
