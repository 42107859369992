import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { reduce } from 'lodash';

import { colors, custom } from '../lib/styles';
import Continue from './continue';
import Subtitle from './subtitle';
import PainTypeSelector from './pain-type-selector';
import PainHead from './pain-head';
import PainSlider from './pain-slider';

const styles = {
  acheLevel: {
    marginTop: '2.5rem',
    color: colors.primaryColor,
    fontSize: '1.2rem',
  },
  btn: {
    width: '95vw',
    backgroundColor: colors.secondaryColor,
    marginTop: '2rem',
  },
  buttonStyle: {
    color: colors.primaryColor,
    fontSize: '14px',
  },
  evenSpacing: {
    display: 'flex',
    justifyContent: 'space-around',
    color: colors.primaryColor,
  },
  instructions: {
    fontSize: '1.4rem',
    marginTop: '1rem',
  },
  painHeadMargin: {
    margin: '0 auto',
  },
  painLevelSection: {
    backgroundColor: colors.white,
    marginTop: '0.5rem',
  },
  sliderSection: {
    color: colors.white,
    fontSize: '1.2rem',
  },
  select: {
    backgroundColor: colors.darkAccent,
    color: colors.white,
  },
  span: {
    color: colors.white,
    fontWeight: 'bold',
  },
  slider: {
    background: colors.primaryColor,
    padding: '1rem',
  },
  title: {
    fontSize: '1.2rem',
  },
};

const painLocations = [
  {
    x: 82,
    y: 15,
    name: 'TOP',
  },
  {
    x: 45,
    y: 35,
    name: 'LEFT',
  },

  {
    x: 82,
    y: 35,
    name: 'MIDDLE',
  },
  {
    x: 120,
    y: 35,
    name: 'RIGHT',
  },
  {
    x: 82,
    y: 70,
    name: 'BOTTOM',
  },
  {
    x: 82,
    y: 135,
    name: 'NECK',
  },
];

function calculateDistance(click, point) {
  return ((click.x - point.x) ** 2) + ((click.y - point.y) ** 2);
}

const MigrainePain = (props) => {
    const { brain_quadrant, brain_locations, severity, pain_type, sliderError, painLocationError, error, personalInfo } = props;
    let displayedError;

  const handleUpdatePainLocation = (x, y, brain_quadrant) => {
    const click = { x, y };

    const closestPoint = reduce(painLocations, (result, value) => {
      const distance = calculateDistance(click, value);

      if (distance < result.distance) {
        return {
          distance,
          ...value,
        };
      }

      return result;
    }, { distance: 99999999 });

    props.onUpdatePainLocation(brain_quadrant, closestPoint.name);
  }

    if (error) {
      displayedError = (
        <div style={custom.redErrorText}> There was an error submitting this page. Please try again.</div>
      );
    }
  return (
    <div>
      <Subtitle label="SCROLL DOWN TO COMPLETE" />
      <div style={styles.instructions}>Tap anywhere to add a pain point</div>
      <section>
        {painLocationError}
        {sliderError}
        <PainHead
          currentView={props.currentView}
          pain={[{ brain_quadrant, brain_locations }]}
          onPainSelect={handleUpdatePainLocation}
          style={styles.painHeadMargin}
          sex={personalInfo.data.sex}
        />
        <div style={styles.evenSpacing}>
          <Button onClick={props.onHeadView} style={styles.buttonStyle} value="FRONT" primary>FRONT</Button>
          <Button onClick={props.onHeadView} style={styles.buttonStyle} value="LEFT" primary>LEFT</Button>
          <Button onClick={props.onHeadView} style={styles.buttonStyle} value="BACK" primary>BACK</Button>
          <Button onClick={props.onHeadView} style={styles.buttonStyle} value="RIGHT" primary>RIGHT</Button>
        </div>
        <div style={styles.select}>Select the type of pain you are experiencing below</div>
      </section>
      <PainTypeSelector
        onUpdatePainType={props.onPainType}
        painType={pain_type}
      />
      <section style={styles.slider}>
        <p style={styles.sliderSection}>Use slider to give a pain on a scale from 1-10
          <br />
          <span style={styles.span}>1 being very light discomfort - 10 being extreme agony</span>
        </p>
        <PainSlider
          propFunc={props.onSliderUpdate}
          value={Number(severity)}
        />
      </section>
      <section style={styles.painLevelSection}>
        <div style={styles.acheLevel}>{pain_type} ● LEVEL {severity}</div>
        <Continue
          disabled={props.submitting}
          onClick={props.onContinue}
          btnStyle={styles.btn}
        />
        {displayedError}
      </section>
    </div>
  );
}

MigrainePain.defaultProps = {};

MigrainePain.propTypes = {
  addMigrainePainHx: PropTypes.func.isRequired,
  addMigraineHx: PropTypes.func.isRequired,
};

export default MigrainePain;
