import React from 'react';
import { connect } from 'react-redux';
import { omitBy, isUndefined } from 'lodash';

import AppBar from '../components/app-bar';
import Page from './page';
import Layout from '../layouts/common';
import BasicInfo from '../components/basic-info';
import StreetAddress from '../components/street-address';
import Continue from '../components/continue';
import { update, submitPRO } from '../state/pro-forms';
import { apiFetch, makeShadowOptions } from '../lib/fetch';
import { colors } from '../lib/styles';

const styles = {
  errorText: {
    color: colors.secondaryAccent,
    height: '18px',
    paddingTop: '18px',
  },
};

class AboutMePro1 extends Page {
  constructor(props) {
    super(props);

    this.state = {
      patientContactInfo: {
        address: '',
        city: '',
        state: 'none',
        zip_code: '',
      },
      personalInfo: {
        height: '',
        weight: '',
        primary_language: '',
        marital_status: null,
        sex: '',
      },
    };

    this.handleChangeDob = this.handleChangeDate.bind(this, 'personalInfo', 'birth_date');
    this.handleChangeSex = this.handleChangeDropDownNewMUI.bind(this, 'personalInfo', 'sex');
    this.handleChangeLanguage = this.handleChangeDropDownNewMUI.bind(this, 'personalInfo', 'primary_language');
    this.handleChangeMaritalStatus = this.handleChangeDropDownNewMUI.bind(this, 'personalInfo', 'marital_status');
    this.handleChangeWeight = this.handleChangeTextNewMUI.bind(this, 'personalInfo', 'weight');
    this.handleChangeHeight = this.handleChangeDropDownNewMUI.bind(this, 'personalInfo', 'height');

    this.handleChangeAddress = this.handleChangeTextNewMUI.bind(this, 'patientContactInfo', 'address');
    this.handleChangeCity = this.handleChangeTextNewMUI.bind(this, 'patientContactInfo', 'city');
    this.handleChangeState = this.handleChangeDropDownNewMUI.bind(this, 'patientContactInfo', 'state');
    this.handleChangeZip = this.handleChangeTextNewMUI.bind(this, 'patientContactInfo', 'zip_code');
  }

  handleChangeText(table, field, e, value) {
    this.setState({
      [table]: {
        ...this.state[table],
        [field]: value,
      },
    });
  }

  handleChangeTextNewMUI(table, field, e) {
    const { value } = e.target;
    this.setState(prevState => ({
      [table]: {
        ...prevState[table],
        [field]: value,
      },
    }));
  }

  handleChangeDate(table, field, value) {
    this.setState({
      [table]: {
        ...this.state[table],
        [field]: value,
      },
    });
  }

  handleChangeDropDown(table, field, e, idx, value) {
    this.setState({
      [table]: {
        ...this.state[table],
        [field]: value,
      },
    });
  }

  handleChangeDropDownNewMUI(table, field, e) {
    this.setState({
      [table]: {
        ...this.state[table],
        [field]: e.target.value,
      },
    });
  }

  handleContinue = () => {
    const { submitting, patientContactInfo, personalInfo } = this.state;
    if (submitting) return;
    this.setState({ submitting: true });

    const { update, submitPRO, user } = this.props;
    const { id, type } = this.props.location.query;

    const options = makeShadowOptions({ personal_info: omitBy(personalInfo, isUndefined), patient_contact_info: omitBy(patientContactInfo, isUndefined) }, this.props.user.token, 'POST');
    update({ patientContactInfo, personalInfo });

    apiFetch(`/users/${user.id}/about_me`, options)
      .then(() => {
        return submitPRO({ userId: user.id, id }, {
          pro_type: type,
          pro_data: { data: this.state },
        }, user);
      })
      .then(() => this.forward())
      .catch(e => this.setState({ error: e.message, submitting: false }));
  }

  isContinueDisabled = () => {
    const {
      patientContactInfo: {
        address,
        city,
        state,
        zip_code,
      },
      personalInfo: {
        height,
        weight,
        primary_language,
        marital_status,
        sex,
      },
      submitting,
    } = this.state;

    if (!address || !city || !state || !zip_code || state === 'none') {
      return true;
    }

    if (!height || !weight || !primary_language || !marital_status || !sex) {
      return true;
    }

    if (submitting) {
      return true;
    }

    return false;
  }

  render() {
    const { personalInfo, patientContactInfo } = this.state;
    return (
      <Layout>
        <div>
          <AppBar
            backButtonOnClick={() => this.props.router.goBack()}
            headerNode="About Me"
          />
          <BasicInfo
            hideDob={true}
            hidePhone={true}
            patientContactInfo={patientContactInfo}
            personalInfo={personalInfo}
            onChangeSex={this.handleChangeSex}
            onChangeLanguage={this.handleChangeLanguage}
            onChangeMaritalStatus={this.handleChangeMaritalStatus}
            onChangeWeight={this.handleChangeWeight}
            onChangeHeight={this.handleChangeHeight}
          />
          <StreetAddress
            patientContactInfo={patientContactInfo}
            onChangeAddress={this.handleChangeAddress}
            onChangeCity={this.handleChangeCity}
            onChangeState={this.handleChangeState}
            onChangeZip={this.handleChangeZip}
          />

          <Continue disabled={this.isContinueDisabled()} onClick={this.handleContinue} />
          <div style={styles.errorText}>{this.state.error}</div>
        </div>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  const { user, proForms } = state;

  return {
    proForms,
    user,
  };
}

export default connect(mapStateToProps, {
  update,
  submitPRO,
})(AboutMePro1);
