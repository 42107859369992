import brand from './brand';

export const colors = brand.styles.colors;

export const fontSizes = brand.styles.fontSizes;

export const calendarPainColors = brand.styles.calendarPainColors;

export const checkbox = brand.styles.checkbox;

export const blueBtnStyle = brand.styles.blueBtnStyle;

export const custom = brand.styles.custom;

export const tableStyles = brand.styles.tableStyles;

export const backgroundImageStyle = brand.styles.backgroundImageStyle;

export const emrPageStyles = brand.styles.emrPageStyles;

export const btnLink = brand.styles.btnLink;

// export const calendarHeaderColors = brand.styles.calendarHeaderColors;

// export const calendarStyles = brand.styles.calendarStyles;

// export const iconColors  = brand.styles.iconColors;

// export const mainDashboard = brand.styles.mainDashboard;

// export const painLogDetailsColors = brand.styles.painLogDetailsColors;

// export const painScaleReverseColors = brand.styles.painScaleReverseColors;

export const desktopMaxWidth = 700;

export const fontSizing = {
    h1: 44, // 2rem
    h2: 33, // 1.5rem
    h3: 27, // 1.125rem
    body: 22, // body text, pro titles
    small: 18, // 0.83rem, small text in question answers
    smallX: 16, // 0.75rem, small text instructions at top of page, text field units, validation error messages
    smallXX: 14, // 0.625rem, radio buttons arranged horizontally
};

// Note: Reference px based on Average Zeplin page of 750 width however some are oddly sized
// Check the width of the zeplin page and reduce/enlarge the pixels by ratio difference accordingly
export const painScaleReverseColors = {
    10: '#22b83c',
    9: '#37c51d',
    8: '#72d217',
    7: '#b9df0f',
    6: '#edca07',
    5: '#eda807',
    4: '#ed8607',
    3: '#ed6407',
    2: '#ed4207',
    1: '#ed2007',
    0: '#ed2007',
};

export const painScaleColors = {
    0: '#22b83c',
    1: '#22b83c',
    2: '#22b83c',
    3: '#22b83c',
    4: '#22b83c',
    5: '#edca07',
    6: '#edca07',
    7: '#edca07',
    8: '#ed2007',
    9: '#ed2007',
    10: '#ed2007',
};

export const painScaleColorsDashboard = {
    1: '#dada52',
    2: '#c2de3e',
    3: '#a2e32e',
    4: '#75e727',
    5: '#12eb2e',
    6: '#00d3a5',
    7: '#00bdcc',
    8: '#00a0fa',
    9: '#007bff',
    10: '#073aee',
};

// export const calendarPainColors = {
//     painLevel1: '#309940',
//     painLevel2: '#eea321',
//     painLevel3: '#e54353',
// };

export const calendarHeaderColors = {
    text: '#2c3135',
};

export const painLogColors = {
    optionsBorder: '#979797',
    titles: '#000000',
};

export const painLogDetailsColors = {
    greyBorder: '#ceced2',
    greyText: '#959595',
    lightGrey: '#fafafa',
    mediumGrey: '#f5f5f5',
    titles: colors.primaryColor,
    titlesMuted: '#b7b7b7',
};

export const iconColors = {
    iconGreyLine: '#eaeaea',
    dashboardBlue: '#4097cb',
};

export const centerWithWrapping = {
    marginLeft: '15px',
    marginRight: '15px',
    display: 'flex',
    flexFlow: 'row wrap',
    alignItems: 'center',
};

export const optionsListTextStyle = {
    width: '325px',
    textAlign: 'left',
    marginTop: '16px',
    marginBottom: '16px',
    flex: 5,
};

export const mainDashboard = {
    avatar: {
        marginLeft: '15px',
        cursor: 'pointer',
    },
    bodyPainButton: {
        backgroundColor: colors.successGreen,
        color: colors.white,
        borderRadius: '35px',
        marginTop: '10px',
        marginBottom: '10px',
        marginLeft: '15px',
        marginRight: '15px',
        width: '90%',
        backgroundImage: 'url("/img/Pain-black.png")',
        backgroundPosition: '17px 5px',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        textAlign: 'center',
    },
    chart: {
        color: colors.darkGrayText,
        marginLeft: '20px',
        flex: '.5',
        textAlign: 'left',
        fontSize: '1.4rem',
    },
    checkIn: {
        cursor: 'pointer',
        fontSize: '10px',
        textAlign: 'center',
        marginLeft: '55px',
        marginRight: '55px',
        paddingTop: '5px',
        paddingBottom: '30px',
    },
    checkInBold: {
        fontSize: '10px',
    },
    checkInPosition: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '-8vh',
    },
    checkInTitle: {
        fontSize: '4vh',
        marginTop: '1vh',
        textAlign: 'center',
        padding: '0px',
    },
    colors: {
        optionsBorder: '#979797',
        titles: colors.primaryColor,
        titlesMuted: '#b7b7b7',
    },
    container: {
        backgroundColor: colors.white,
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
        alignItems: 'space-around',
        width: '100%',
    },
    dashboard: {
        color: colors.white,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingTop: '10px',
        paddingBottom: '10px',
    },
    flex: {
        flex: 1,
    },
    font12: {
        fontSize: '12px',
    },
    footer: {
        overflow: 'visible',
        height: '6vh',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: colors.white,
        color: colors.white,
        paddingLeft: '5vw',
        paddingRight: '5vw',
        paddingTop: '.5vh',
        paddingBottom: '2vh',
        borderTop: '1px solid #d8d8d8',
        textAlign: 'center',
    },
    logo: {
        height: '4.1125rem',
        width: '17.5rem',
        cursor: 'pointer',
        backgroundSize: 'contain',
    },
    icon: {
        paddingTop: '10px',
    },
    checkInInfo: {
        color: colors.white,
        backgroundColor: colors.lightBrightBlue,
    },
    shareHealthButton: {
        backgroundColor: colors.primaryColor,
        color: colors.white,
        borderRadius: '5px',
        marginTop: '10px',
        marginBottom: '10px',
        marginLeft: '15px',
        marginRight: '15px',
        width: '90%',
        textAlign: 'center',
        fontSize: '1.4rem',
        height: '5rem',
    },
    mediumIcon: {
        width: '96px',
        height: '96px',
    },
    paperRowBlue: {
        backgroundColor: colors.primaryHighlight,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        cursor: 'pointer',
    },
    paperRowWhite: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        cursor: 'pointer',
    },
    paragraph: {
        color: colors.primaryColor,
        fontSize: '10px',
    },
    rowTextColor: {
        color: colors.primaryColor,
    },
    stripedRow: {
        backgroundColor: colors.highlightBright,
    },
};


export const calendarStyles = {
    calendar: {
        color: '#2c3135',
        display: 'flex',
        flexWrap: 'wrap',
        fontSize: 12,
        margin: '0 20px',
    },
    calendarItem: {
        alignItems: 'center',
        borderRadius: 3,
        bottom: 8,
        display: 'flex',
        justifyContent: 'center',
        left: 0,
        position: 'absolute',
        right: 0,
        top: 0,
    },
    calendarItemWrapper: {
        boxSizing: 'border-box',
        position: 'relative',
        width: `${(1 / 7) * 100}%`,
    },
    dayNames: {
        color: '#7f8fa4',
    },
    heightExpander: {
        paddingBottom: 'calc(100% + 8px)',
    },
    otherMonthDays: {
        background: '#edf2f5',
        color: '#bac2c7',
    },
    spaceOnRight: {
        right: 1,
    },
    painLevel1: {
        background: calendarPainColors.painLevel1,
        color: 'white',
    },
    painLevel2: {
        background: calendarPainColors.painLevel2,
        color: 'white',
    },
    painLevel3: {
        background: calendarPainColors.painLevel3,
        color: 'white',
    },
};

// pain intensity bar chart styles
export const barChartColors = [
    '#ebe617',
    '#b9d04a',
    '#8cbd78',
    '#63aba2',
    '#3296d5',
    '#0c98d8',
    '#10b1a7',
    '#15ce6c',
    '#19e73b',
    '#1cff09',
];

export const barChartLabelProps = {
    fill: 'black',
    fontFamily: 'Circrular Std, sans-serif',
    fontSize: 15,
    textAnchor: 'middle',
};

export const barChartTickLabelProps = () => ({
    dy: '0.1em',
    textAnchor: 'middle',
    fontFamily: 'Circular Std, sans-serif',
    fontSize: 12,
    fill: 'black',
});

export const barChartStyles = {
    labelOffset: 8,
    tickLength: 8,
    marginBottom: 55,
    marginLeft: 20,
    marginRight: 20,
    marginTop: 20,
    strokeColor: '#1b1a1e',
};
