import React from 'react';
import { LinearProgress, TextField } from '@material-ui/core';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import AppBar from '../components/app-bar';
import Page from './page';
import QuestionLayout from '../layouts/question';
import YesNoButtonSet from '../components/yes-no-button-set';
import { colors, fontSizing } from '../lib/styles';
import { submitPRO, updatePRO } from '../state/pro-forms';
import { ThermometerHalfFull } from '../lib/icons';
import CenteredIcon from '../components/centered-icon';

const styles = {
  bodyText: {
    fontSize: fontSizing.body,
  },
  breathingText: {
    fontSize: '2rem',
    fontWeight: 'bold',
  },
  inputField: {
    width: '6rem',
    '& input': {
      textAlign: 'center',
    },
  },
  pageContent: {
    fontSize: fontSizing.body,
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 20,
  },
  questionText: {
    marginTop: 25,
  },
  textFieldContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  textFieldWrapper: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  unitsWrapper: {
    fontSize: fontSizing.smallX,
    fontWeight: 'bold',
    marginTop: 5,
  },
};

class RespiratoryOncology3 extends Page {
  constructor(props) {
    super(props);

    this.state = {
      hasFever: null,
      temperature: '',
    };
  }

  handleYesNoChange = (field, value) => {
    this.setState({ [field]: value });
  }

  handleTemperature = (e) => {
    const temperature = e.target.value.replace(/[^0-9.]/g, '');
    this.setState({ temperature });
  }

  getCallPageMessage = (respiratoryOncology) => {
    const { SPO2, temperature } = respiratoryOncology;

    const messages = [];

    if (SPO2 !== undefined && parseFloat(SPO2) < 90) messages.push('oxygen saturation below 90%');
    if (temperature !== undefined && parseFloat(temperature) > 100.4) messages.push('temperature greater than 100.4');

    if (messages.length) {
      return messages.join(',\n') + '.';
    }

    return '';
  }

  handleClickNext = () => {
    const {
      respiratoryOncology,
      location,
      submitPRO,
      updatePRO,
      user,
    } = this.props;
    const { id } = location.query;
    const {
      hasFever,
      temperature,
    } = this.state;

    const value = { hasFever };

    if (hasFever) {
      value.temperature = temperature;
    }

    const data = {
      ...respiratoryOncology[0],
      ...respiratoryOncology[1],
      ...value,
    };

    const callPageMessage = this.getCallPageMessage(data);

    submitPRO(
      { userId: user.id, id },
      {
        pro_data: {
          data,
          recorded_at: new Date().toISOString(),
        },
        pro_type: 'RESPIRATORY-ONCOLOGY',
      },
      user,
    )
      .then(() => {
        if (callPageMessage) {
          updatePRO({
            type: 'respiratoryOncology',
            position: this.getTrackIndex(),
            value: callPageMessage,
          });
          this.forwardWithQuery(location.query);
          return;
        }
        this.finishTrack();
      });
  }

  render() {
    const { classes } = this.props;
    const { hasFever, temperature } = this.state;

    const continueDisabled = hasFever === null
      || (hasFever === 'Y' && temperature === '');

    return (
      <QuestionLayout
        primaryBtnLabel="Next"
        primaryBtnOnTouchTap={this.handleClickNext}
        primaryBtnDisabled={continueDisabled}
        style={{ backgroundColor: colors.questionBackground }}
      >
        <AppBar
          headerNode="Respiratory"
          backButtonOnClick={() => this.props.router.goBack()}
        />
        <LinearProgress
          variant="determinate"
          value={(this.getCurrentPage() / this.getTotalPages()) * 100}
          classes={{ bar: classes.bar }}
        />
        <div className={classes.pageContent}>
          <CenteredIcon>
            <ThermometerHalfFull />
          </CenteredIcon>
          <YesNoButtonSet
            isYes={hasFever === 'Y'}
            isNo={hasFever === 'N'}
            onClickNo={() => this.handleYesNoChange('hasFever', 'N')}
            onClickYes={() => this.handleYesNoChange('hasFever', 'Y')}
            questionText="Do you have a fever?"
          />
          {hasFever === 'Y' ? (
            <>
              <div className={classes.questionText}>What is your temperature?</div>
              <div className={classes.textFieldContainer}>
                <div className={classes.textFieldWrapper}>
                  <TextField
                    className={classes.inputField}
                    onChange={this.handleTemperature}
                    type="number"
                    inputProps={{
                      inputMode: 'decimal',
                      type: 'text',
                      style: { fontSize: fontSizing.body } }}
                    value={temperature}
                    placeholder="0"
                  />
                  <span className={classes.unitsWrapper}>&#8457;</span>
                </div>
              </div>
            </>
          ) : null}
        </div>
      </QuestionLayout>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    proForms: {
      respiratoryOncology,
    },
    user,
  } = state;
  return { respiratoryOncology, user };
};

export default connect(mapStateToProps, { submitPRO, updatePRO })(withStyles(styles)(RespiratoryOncology3));
