const en = {
  bundledEventNotifications: {
    introHeaderText: 'Patient Reported Outcomes',
    introBodyText: 'requests that you review several items',
    loggedInSuccessText: 'Successful Patient Report!',
    loggedOutSuccessText: 'Thank you for submitting your responses. You may now close this window.',
  },
};

const es = {
  bundledEventNotifications: {
    introHeaderText: 'Acceso Reportados por la/el Paciente',
    introBodyText: 'solicita que revise varios elementos.',
    loggedInSuccessText: `
    <p>Gracias por enviar su evaluación de peso.</p>

    <p>Esta información solo se utiliza con fines de investigación para obtener más información sobre la población en riesgo de sufrir complicaciones por heridas en las extremidades inferiores.</p>

    <p>Como esto es solo una encuesta, no podremos ayudarlo a encontrar recursos.</p>
    `,
    loggedOutSuccessText: `
    <p>Gracias por enviar su evaluación de peso.</p>

    <p>Esta información solo se utiliza con fines de investigación para obtener más información sobre la población en riesgo de sufrir complicaciones por heridas en las extremidades inferiores.</p>

    <p>Como esto es solo una encuesta, no podremos ayudarlo a encontrar recursos.</p>
    
    <p>Ahora puedes cerrar esta ventana.</p>
    `,
  },
};

export default { en, es };
