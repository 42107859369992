import React from 'react';
import { connect } from 'react-redux';

import AppBar from '../components/app-bar';
import Page from './page';
import Layout from '../layouts/common';
import Continue from '../components/continue';
import { colors } from '../lib/styles';

class FormsIntro extends Page {
  render() {
    return (
      <Layout>
        <div>
          <AppBar
            backButtonOnClick={() => this.props.router.goBack()}
            headerNode="Forms"
          />
          <h1 style={{ color: colors.primaryColor }}> {'Now, let\'s get rid of your medical forms forever'} </h1>
          <section style={{ backgroundColor: colors.white }} />
          <img src="/img/get-started-intro.png" style={{ marginTop: 25, marginBottom: 25 }} alt="" />
          <h3 style={{ color: colors.primaryColor }}>You will never need to fill out medical paperwork ever again!</h3>
          <Continue
            text="Let's Get Started"
            btnStyle={{ backgroundColor: colors.secondaryColor }}
            onClick={() => this.props.router.push('/signup_primarycomplaint')}
          />
          <Continue
            text="Skip For Now"
            onClick={() => this.props.router.push('/')}
          />
        </div>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state;
  return {
    user,
  };
}

export default connect(mapStateToProps, { })(FormsIntro);
