import { get, uniq } from 'lodash';
import painCalendarFormatter from './pain-calendar-formatter';

export default function buildBodyData(data) {
  const defaultData = { painOrigin: [], aggravatingFactors: [], alleviatingFactors: [], limitations: [], painLocations: [], total: 0 };
  if(!data) return defaultData;
  const bodyData = data.reduce((allData, item) => {
    const { worstPain } = allData;
    const currentPain = item;
    allData.worstPain = (worstPain && worstPain.intensity > currentPain.intensity) ? worstPain : currentPain;
    allData.painOrigin = uniq([...allData.painOrigin, ...item.pain_origin]);
    allData.aggravatingFactors = uniq([...allData.aggravatingFactors, ...item.aggravating_factors]);
    allData.alleviatingFactors = uniq([...allData.alleviatingFactors, ...item.alleviating_factors]);
    allData.limitations = uniq([...allData.limitations, ...item.limitations]);
    allData.painLocations = uniq([...allData.painLocations, ...item.pain_locations_full.map(i => get(i, 'name'))]);
    allData.total += 1;

    return allData;
  }, defaultData);

  bodyData.barChart = painCalendarFormatter(data, { interval: 'hour' });
  return bodyData;
}
