const en = {
  woundCasaColina5: {
    line1: 'Thank you for submitting your response.',
    line2: 'This information is only used for research purposes to learn more about the population at risk for lower limb wound complications.',
    line3: 'If you experience extreme pain or discomfort please contact your primary care physician or seek emergency medical care.',
    line4: 'You may now close this window.',
  },
};

const es = {
  woundCasaColina5: {
    line1: 'Gracias por enviar su cuestionario de calidad de vida relacionada con la salud.',
    line2: 'Esta información solo se utiliza con fines de investigación para obtener más información sobre la población en riesgo de sufrir complicaciones por heridas en las extremidades inferiores.',
    line3: 'Si tienes dolor o malestar extremo, comuníquese con su médico de atención primaria o busque atención médica de emergencia.',
    line4: 'Ahora puedes cerrar esta ventana.',
  },
};

export default { en, es };
