import React from 'react';
import { LinearProgress, TextField } from '@material-ui/core';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import AppBar from '../components/app-bar';
import Page from './page';
import QuestionLayout from '../layouts/question';
import { colors } from '../lib/styles';
import { updatePRO } from '../state/pro-forms';
import { Heart } from '../lib/icons';

const styles = {
  contentWrapper: {
    flexGrow: 1,
    overflowX: 'hidden',
    position: 'relative',
  },
  imgRow: {
    display: 'flex',
    justifyContent: 'center',
    padding: '15px 60px',
  },
  imageWrapper: {
    width: 100,
  },
  bloodPressureInputField: {
    width: '6rem',
    '& input': {
      fontSize: '2rem',
      textAlign: 'center',
    },
    '& label': {
      fontSize: '2rem',
    },
  },
  marginRight40: {
    marginRight: 40,
  },
  marginTop10: {
    marginTop: 10,
  },
  marginTop20: {
    marginTop: 20,
  },
  notesInputField: {
    '& input': {
      fontSize: '2rem',
    },
    '& label': {
      fontSize: '2rem',
    },
  },
  questionnaireContainer: {
    paddingLeft: 20,
    paddingRight: 20,
  },
  bodyText: {
    fontSize: '2rem',
  },
  textFieldContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  textFieldWrapper: {
    alignItems: 'center',
    display: 'flex',
  },
  unitsWrapper: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    marginLeft: 5,
  },
};

class VitalsDataCollectionPro8 extends Page {
  constructor(props) {
    super(props);

    this.state = {
      bloodPressure: '',
      manufacturer: '',
      modelNumber: '',
    };
  }

  handleChangeBloodPressure = (e) => {
    this.setState({ bloodPressure: e.target.value });
  };

  handleChangeManufacturer = (e) => {
    this.setState({ manufacturer: e.target.value });
  };

  handleChangeModelNumber = (e) => {
    this.setState({ modelNumber: e.target.value });
  };

  handleClickNext = () => {
    const {
      bloodPressure,
      manufacturer,
      modelNumber,
    } = this.state;

    this.props.updatePRO({
      type: 'vitalsDataCollection',
      position: this.getTrackIndex(),
      value: {
        manualBloodPressureFirst: bloodPressure,
        manufacturer,
        modelNumber,
      },
    });

    this.forwardWithQuery(this.props.location.query);
  }

  render() {
    const { classes } = this.props;
    const {
      bloodPressure,
      manufacturer,
      modelNumber,
    } = this.state;

    return (
      <QuestionLayout
        primaryBtnLabel="Next"
        primaryBtnOnTouchTap={this.handleClickNext}
        style={{ backgroundColor: colors.questionBackground }}
      >
        <AppBar
          headerNode="Measurements"
          backButtonOnClick={() => this.props.router.goBack()}
        />
        <LinearProgress
          variant="determinate"
          value={(this.getCurrentPage() / this.getTotalPages()) * 100}
          classes={{ bar: classes.bar }}
        />
        <div className={classes.contentWrapper}>
          <div className={classes.imgRow}>
            <div className={classes.imageWrapper}>
              <Heart />
            </div>
          </div>
          <div className={classes.questionnaireContainer}>
            <div className={classes.bodyText}>Please enter your current blood pressure measurement:</div>
            <div className={classes.textFieldContainer}>
              <div className={`${classes.textFieldWrapper} ${classes.marginTop20}`}>
                <TextField
                  className={classes.bloodPressureInputField}
                  onChange={this.handleChangeBloodPressure}
                  value={bloodPressure}
                  placeholder="0/0"
                />
                <span className={classes.unitsWrapper}>mmHG</span>
              </div>
            </div>
            <div className={`${classes.bodyText} ${classes.marginTop20}`}>Enter the manufacturer of your blood pressure monitor:</div>
            <TextField
              className={`${classes.notesInputField} ${classes.marginTop10}`}
              onChange={this.handleChangeManufacturer}
              value={manufacturer}
              placeholder="tap here to enter"
            />
            <div className={`${classes.bodyText} ${classes.marginTop20}`}>Enter the model number of your blood pressure monitor:</div>
            <TextField
              className={`${classes.notesInputField} ${classes.marginTop10}`}
              onChange={this.handleChangeModelNumber}
              value={modelNumber}
              placeholder="tap here to enter"
            />
          </div>
        </div>
      </QuestionLayout>
    );
  }
}

export default connect(null, { updatePRO })(withStyles(styles)(VitalsDataCollectionPro8));
