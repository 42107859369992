
import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import AppBar from '../components/app-bar';
import Page from './page';
import Layout from '../layouts/common';

import { updatePRO } from '../state/pro-forms';
import { BpReader } from '../lib/qardiobp-webble';
import Continue from '../components/continue';

import { colors, fontSizing } from '../lib/styles';

const styles = {
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
  contentWrapper: {
    flexGrow: 1,
    fontSize: fontSizing.body,
    overflowX: 'hidden',
    paddingLeft: 20,
    paddingRight: 20,
    position: 'relative',
    textAlign: 'start',
  },
  alert: {
    color: colors.errorRed,
  },
  bar: {
    backgroundColor: colors.lightBrightBlue,
  },
  instructionImages: {
    width: '100%',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    margin: 10,
  },
  title: {
    fontWeight: 'bold',
    textAlign: 'center',
  },
  small: {
    fontStyle: 'italic',
    fontSize: fontSizing.small,
  },
  nextBtn: {
    textAlign: 'center',
    width: '100%',
    top: '90%',
  },
};

class HypertensionAssessment3 extends Page {
  constructor(props) {
    super(props);

    this.state = {
      reading: false,
      connecting: false,
      deviceError: null,
      systolic: null,
      diastolic: null,
      rawPacket: null,
    };
  }

  handleClickNext = async () => {
    let reader;
    let deviceError;
    let rawPacket;
    try {
      this.setState({ deviceError: null, connecting: true, reading: false });
      reader = await new BpReader();
    } catch (error) {
      if (error.code && error.code === 902) {
        deviceError = 'Bluetooth pairing failed.  Please tap the I\'M READY button again.';
      } else {
        rawPacket = error.rawPacket;
        deviceError = error.toString();
      }
    }

    if (deviceError) {
      if (reader) {
        reader.disconnect();
      }
      return this.setState({ deviceError, systolic: null, reading: false, connecting: false, rawPacket });
    }

    try {
      this.setState({ connecting: false, reading: true });

      const reading = await reader.takeReading();

      this.setState({ reading: false, systolic: reading.systolic, diastolic: reading.diastolic, rawPacket: reading.rawPacket });
      this.props.updatePRO({
        type: 'hypertensionAssessmentDaily',
        position: this.getTrackIndex(),
        value: {
          systolic: reading.systolic,
          diastolic: reading.diastolic,
        },
      });

      this.forwardWithQuery(this.props.location.query);
    } catch (error) {
      this.setState({ deviceError: error.toString(), systolic: null, reading: false, connecting: false, rawPacket: error.rawPacket });
    }

    if (reader) {
      reader.disconnect();
    }
  }

  render() {
    const { classes } = this.props;

    return (
      <Layout>
        <AppBar
          backButtonOnClick={() => this.props.router.goBack()}
          headerNode="Your vital signs today"
        />
        <LinearProgress
          variant="determinate"
          value={(this.getCurrentPage() / this.getTotalPages()) * 100}
          classes={{ bar: classes.bar }}
        />

        <div className={classes.contentWrapper}>
          <p className={classes.title}>Take your blood pressure</p>

          <ol>
            <li>When you are ready to begin, tap the <strong>I&apos;M READY</strong> button to allow Chrome to list nearby bluetooth devices.</li>
            <li>Tap QardioARM from the list and tap the Pair button.
              <img
                className={classes.instructionImages}
                src="/img/bp-pair-button.png"
                alt=""
              />
            </li>
            <li>You may see an Android bluetooth pairing prompt, if so tap Pair.
              <img
                className={classes.instructionImages}
                src="/img/bp-request.png"
                alt=""
              />
              <p className={classes.small}>You only need to do this the first time you pair your QardioARM or if you have unpaired the cuff from your device</p>
            </li>
            <li>The cuff will begin to inflate and collect the reading. Sit still and do not flex your arm while measurement is in progress.</li>
          </ol>

          <p className={classes.alert}>{this.state.deviceError}</p>
          <strong>{this.state.connecting ? 'Connecting...' : ''}</strong>
          <strong>{this.state.reading ? 'Measuring your blood pressure.  Please hold still.' : ''}</strong>

          <div className={classes.nextBtn}>
            {!this.state.connecting && !this.state.reading ? (
              <div className={classes.buttonWrapper}>
                <Continue
                  text="I'M READY"
                  onClick={this.handleClickNext}
                />
              </div>
            ) : ''}

          </div>
        </div>
      </Layout>
    );
  }
}

HypertensionAssessment3.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect(null, { updatePRO })(withStyles(styles)(HypertensionAssessment3));
