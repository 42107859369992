import React from 'react';
import { connect } from 'react-redux';
import memoize from 'memoize-one';
import { without } from 'lodash';

import AppBar from '../components/app-bar';
import Page from './page';
import Layout from '../layouts/common';
import Continue from '../components/continue';
import { colors } from '../lib/styles';
import SimpleList from '../components/simple-list';
import Subtitle from '../components/subtitle';

import { makeShadowOptions } from '../lib/fetch';
import { symptomsIfNeeded } from '../state/app-data';
import { updatePRO } from '../state/pro-forms';

class SystemsReview extends Page {
  componentWillMount() {
    const options = makeShadowOptions(undefined, this.props.user.token, 'GET');
    this.props.symptomsIfNeeded(options);
  }

  handleAddItem = (id) => {
    const { selectedSymptoms } = this.props;
    const update = without(selectedSymptoms.concat(id));

    this.props.updatePRO({
      type: 'systemsReview',
      position: this.getTrackIndex(),
      value: update,
    });
  }

  handleRemoveItem = (symptomId) => {
    const { selectedSymptoms } = this.props;
    const updated = without(selectedSymptoms, symptomId);
    this.props.updatePRO({
      type: 'systemsReview',
      position: this.getTrackIndex(),
      value: updated,
    });
  }

  handleContinue = () => {
    this.forward();
  }

  handleBack = () => {
    this.backwardWithQuery(this.props.location.query);
  }

  memoFormatData = memoize((symptoms) => {
    return symptoms.reduce((all, symptomId) => {
      all[symptomId] = { id: symptomId };
      return all;
    }, {});
  })

  render() {
    const { symptoms, selectedSymptoms } = this.props;
    const data = this.memoFormatData(selectedSymptoms);

    return (
      <Layout>
        <div>
          <AppBar
            backButtonOnClick={() => this.props.router.goBack()}
            headerNode="Review of Systems"
          />
          <Subtitle label="Scroll to see all symptoms and check all that apply." />
          {symptoms ? (
            <SimpleList
              items={symptoms}
              categoryField="type"
              selectedItems={data}
              onAddItem={this.handleAddItem}
              onRemoveItem={this.handleRemoveItem}
            />
          ) : null}
          <section style={{ backgroundColor: colors.white }}>
            <Continue onClick={this.handleContinue} />
          </section>
        </div>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  const {
    appData: {
      symptoms,
    },
    proForms: { systemsReview },
    user,
  } = state;

  return {
    symptoms,
    selectedSymptoms: systemsReview[0] || [],
    user,
  };
}

export default connect(mapStateToProps, {
  symptomsIfNeeded,
  updatePRO,
})(SystemsReview);
