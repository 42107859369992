import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import AppBar from '../components/app-bar';
import Page from './page';
import QuestionLayout from '../layouts/question';
import YesNoButtonSet from '../components/yes-no-button-set';
import { colors, fontSizing } from '../lib/styles';
import { updatePRO } from '../state/pro-forms';
import { Ache } from '../lib/pain-entry-icons';
import CenteredIcon from '../components/centered-icon';

const styles = {
  breathingText: {
    fontSize: '2rem',
    fontWeight: 'bold',
  },
  pageContent: {
    fontSize: fontSizing.body,
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 20,
  },
  imgRow: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 20,
    marginTop: 20,
  },
  imageWrapper: {
    width: 100,
  },
};

class GIOncology4 extends Page {
  constructor(props) {
    super(props);

    this.state = {
      abdominalPain: null,
    };
  }

  handleYesNoChange = (field, value) => {
    this.setState({ [field]: value });
  }

  handleClickNext = () => {
    const { abdominalPain } = this.state;

    const value = { abdominalPain };

    this.props.updatePRO({
      type: 'giOncology',
      position: this.getTrackIndex(),
      value,
    });

    this.forwardWithQuery(this.props.location.query);
  }

  render() {
    const { classes } = this.props;
    const { abdominalPain } = this.state;

    const continueDisabled = abdominalPain === null;

    return (
      <QuestionLayout
        primaryBtnLabel="Next"
        primaryBtnOnTouchTap={this.handleClickNext}
        primaryBtnDisabled={continueDisabled}
        style={{ backgroundColor: colors.questionBackground }}
      >
        <AppBar
          headerNode="Abdominal Pain"
          backButtonOnClick={() => this.props.router.goBack()}
        />
        <LinearProgress
          variant="determinate"
          value={(this.getCurrentPage() / this.getTotalPages()) * 100}
          classes={{ bar: classes.bar }}
        />
        <div className={classes.pageContent}>
          <CenteredIcon>
            <Ache color="black" />
          </CenteredIcon>
          <YesNoButtonSet
            isYes={abdominalPain === 'Y'}
            isNo={abdominalPain === 'N'}
            onClickNo={() => this.handleYesNoChange('abdominalPain', 'N')}
            onClickYes={() => this.handleYesNoChange('abdominalPain', 'Y')}
            questionText="Are you having new abdominal pain?"
          />
        </div>
      </QuestionLayout>
    );
  }
}

export default connect(null, { updatePRO })(withStyles(styles)(GIOncology4));
