import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import AppBar from '../components/app-bar';
import Page from './page';
import QuestionLayout from '../layouts/question';
import YesNoButtonSet from '../components/yes-no-button-set';
import { colors, fontSizing } from '../lib/styles';
import { updatePRO } from '../state/pro-forms';
import { Toilet } from '../lib/icons';
import CenteredIcon from '../components/centered-icon';

const styles = {
  breathingText: {
    fontSize: '2rem',
    fontWeight: 'bold',
  },
  pageContent: {
    fontSize: fontSizing.body,
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 20,
  },
  iconWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 20,
  },
};

class GIOncology3 extends Page {
  constructor(props) {
    super(props);

    this.state = {
      ableToEatOrDrink: null,
      ableToTakeMedications: null,
      nauseaControlledByMedications: null,
      nauseaOrVomiting: null,
    };
  }

  handleYesNoChange = (field, value) => {
    this.setState({ [field]: value });
  }

  handleClickNext = () => {
    const {
      ableToEatOrDrink,
      ableToTakeMedications,
      nauseaOrVomiting,
      nauseaControlledByMedications,
    } = this.state;

    const value = { nauseaOrVomiting };

    if (nauseaOrVomiting === 'Y') {
      value.nauseaControlledByMedications = nauseaControlledByMedications;
      value.ableToEatOrDrink = ableToEatOrDrink;
      value.ableToTakeMedications = ableToTakeMedications;
    }

    this.props.updatePRO({
      type: 'giOncology',
      position: this.getTrackIndex(),
      value,
    });

    this.forwardWithQuery(this.props.location.query);
  }

  render() {
    const { classes } = this.props;
    const {
      ableToEatOrDrink,
      ableToTakeMedications,
      nauseaControlledByMedications,
      nauseaOrVomiting,
    } = this.state;

    const continueDisabled = nauseaOrVomiting === null
      || (nauseaOrVomiting === 'Y' && (nauseaControlledByMedications === null || ableToEatOrDrink === null || ableToTakeMedications === null));

    return (
      <QuestionLayout
        primaryBtnLabel="Next"
        primaryBtnOnTouchTap={this.handleClickNext}
        primaryBtnDisabled={continueDisabled}
        style={{ backgroundColor: colors.questionBackground }}
      >
        <AppBar
          headerNode="Nausea or Vomiting"
          backButtonOnClick={() => this.props.router.goBack()}
        />
        <LinearProgress
          variant="determinate"
          value={(this.getCurrentPage() / this.getTotalPages()) * 100}
          classes={{ bar: classes.bar }}
        />
        <div className={classes.pageContent}>
          <CenteredIcon>
            <Toilet />
          </CenteredIcon>
          <YesNoButtonSet
            isYes={nauseaOrVomiting === 'Y'}
            isNo={nauseaOrVomiting === 'N'}
            onClickNo={() => this.handleYesNoChange('nauseaOrVomiting', 'N')}
            onClickYes={() => this.handleYesNoChange('nauseaOrVomiting', 'Y')}
            questionText="Do you have nausea or vomiting?"
          />
          {nauseaOrVomiting === 'Y' ? (
            <>
              <YesNoButtonSet
                isYes={nauseaControlledByMedications === 'Y'}
                isNo={nauseaControlledByMedications === 'N'}
                onClickNo={() => this.handleYesNoChange('nauseaControlledByMedications', 'N')}
                onClickYes={() => this.handleYesNoChange('nauseaControlledByMedications', 'Y')}
                questionText="Is your nausea or vomiting controlled by medications?"
              />
              <YesNoButtonSet
                isYes={ableToEatOrDrink === 'Y'}
                isNo={ableToEatOrDrink === 'N'}
                onClickNo={() => this.handleYesNoChange('ableToEatOrDrink', 'N')}
                onClickYes={() => this.handleYesNoChange('ableToEatOrDrink', 'Y')}
                questionText="Are you able to eat and/or drink?"
              />
              <YesNoButtonSet
                isYes={ableToTakeMedications === 'Y'}
                isNo={ableToTakeMedications === 'N'}
                onClickNo={() => this.handleYesNoChange('ableToTakeMedications', 'N')}
                onClickYes={() => this.handleYesNoChange('ableToTakeMedications', 'Y')}
                questionText="Are you able to take your medications?"
              />
            </>
          ) : null}
        </div>
      </QuestionLayout>
    );
  }
}

export default connect(null, { updatePRO })(withStyles(styles)(GIOncology3));
