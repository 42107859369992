import React from 'react';
import { connect } from 'react-redux';

import AppBar from '../components/app-bar';
import Page from './page';
import Layout from '../layouts/common';
import Continue from '../components/continue';
import { colors } from '../lib/styles';
import { logEvent } from '../lib/amplitude';
import SimpleList from '../components/simple-list';
import Subtitle from '../components/subtitle';

import { browseSymptoms } from '../state/app-data';
import {
  browseSymptomsHx,
  addSymptomsHx,
  deleteSymptomsHx,
} from '../state/symptoms-hx';

class Symptoms extends Page {
  constructor(props) {
    super(props);

    this.handleAddItem = this.handleAddItem.bind(this);
    this.handleRemoveItem = this.handleRemoveItem.bind(this);
    this.handleContinue = this.handleContinue.bind(this);
    this.handleBack = this.handleBack.bind(this);
  }

  componentWillMount() {
    const { migraine_hx_id } = this.props.location.query;
    this.props.browseSymptomsHx(migraine_hx_id);
    if (!this.props.symptoms) {
      this.props.browseSymptoms();
    }
  }

  handleAddItem(symptoms_id) {
    const newItem = {
      symptoms_id,
    };
    this.props.addSymptomsHx(newItem);
  }

  handleRemoveItem(symptomId) {
    this.props.deleteSymptomsHx(symptomId);
  }

  handleContinue() {
    logEvent('Symptoms Updated (Finish)');
    this.forwardWithQuery(this.props.location.query);
  }

  handleBack() {
    this.backwardWithQuery(this.props.location.query);
  }

  render() {
    const { symptoms, symptomsHx } = this.props;

    return (
      <Layout>
        <div>
          <AppBar
            backButtonOnClick={() => this.props.router.goBack()}
            headerNode="Review of Symptoms"
          />
          <Subtitle label="SCROLL TO SEE ALL OPTIONS" />
          {symptoms ? (
            <SimpleList
              items={symptoms}
              categoryField="type"
              selectedItems={symptomsHx.data}
              onAddItem={this.handleAddItem}
              onRemoveItem={this.handleRemoveItem}
            />
          ) : null}
          <section style={{ backgroundColor: colors.white }}>
            <Continue onClick={this.handleContinue} />
          </section>
        </div>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  const {
    symptomsHx,
    appData: {
      symptoms,
    },
    user,
  } = state;

  return {
    symptoms,
    symptomsHx,
    user,
  };
}

Symptoms.defaultProps = {
  aggravating_factors: {},
  painHxSymptoms: {},
};

export default connect(mapStateToProps, {
  browseSymptoms,
  browseSymptomsHx,
  addSymptomsHx,
  deleteSymptomsHx,
})(Symptoms);
