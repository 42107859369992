import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import TextField from '@material-ui/core/TextField';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import AppBar from '../components/app-bar';
import Page from './page';
import QuestionLayout from '../layouts/question';
import YesNoButtonSet from '../components/yes-no-button-set';
import { colors } from '../lib/styles';
import { updatePRO } from '../state/pro-forms';
import { Lungs } from '../lib/icons';

const styles = {
  answerButtonContainer: {
    display: 'flex',
    marginLeft: -10,
    marginTop: 8,
  },
  bar: {
    background: colors.lightBrightBlue,
  },
  contentWrapper: {
    flexGrow: 1,
    overflowX: 'hidden',
    position: 'relative',
  },
  imgRow: {
    display: 'flex',
    justifyContent: 'center',
    padding: '15px 60px',
  },
  imageWrapper: {
    width: 100,
  },
  instructions: {
    color: 'grey',
    fontSize: '1.5rem',
    margin: '20px 40px 0px 40px',
    textAlign: 'center',
  },
  questionnaireContainer: {
    paddingLeft: 20,
    paddingRight: 20,
  },
  breathingText: {
    fontSize: '2rem',
    fontWeight: 'bold',
  },
  leaveBlankText: {
    fontSize: '1.25rem',
    fontStyle: 'italic',
    marginTop: 10,
  },
  marginRight10: {
    marginRight: 10,
  },
  marginRight40: {
    marginRight: 40,
  },
  questionText: {
    fontSize: '2rem',
    marginTop: 25,
  },
  inputField: {
    width: '6rem',
  },
  textFieldContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  textFieldRow: {
    display: 'flex',
  },
  textFieldWrapper: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 20,
  },
  unitsWrapper: {
    fontSize: '1.23rem',
    fontWeight: 'bold',
    marginTop: 5,
  },
};

class HeadAndNeckCancerSymptoms12 extends Page {
  constructor(props) {
    super(props);

    this.state = {
      diastolic: '',
      dizzyOrLightheaded: null,
      systolic: '',
      worseWithChange: null,
    };
  }

  handleChangeDiastolic = (e) => {
    this.setState({ diastolic: e.target.value });
  }

  handleChangeDizzyOrLightheaded = (value) => {
    this.setState({ dizzyOrLightheaded: value });
  }

  handleChangeWorseWithChange = (value) => {
    this.setState({ worseWithChange: value });
  }

  handleChangeSystolic = (e) => {
    this.setState({ systolic: e.target.value });
  }

  handleClickNext = () => {
    const {
      diastolic,
      dizzyOrLightheaded,
      systolic,
      worseWithChange,
    } = this.state;

    const value = { dizzyOrLightheaded, worseWithChange };

    if (diastolic && systolic) {
      value.diastolic = diastolic;
      value.systolic = systolic;
    }

    this.props.updatePRO({
      type: 'headAndNeckCancerSymptoms',
      position: this.getTrackIndex(),
      value,
    });
    this.forwardWithQuery(this.props.location.query);
  }

  render() {
    const { classes } = this.props;
    const {
      diastolic,
      dizzyOrLightheaded,
      worseWithChange,
      systolic,
    } = this.state;

    const continueDisabled = dizzyOrLightheaded === null
      || worseWithChange === null
      || (systolic === '' && diastolic !== '')
      || (systolic !== '' && diastolic === '');

    return (
      <QuestionLayout
        primaryBtnLabel="Next"
        primaryBtnOnTouchTap={this.handleClickNext}
        primaryBtnDisabled={continueDisabled}
        style={{ backgroundColor: colors.questionBackground }}
      >
        <AppBar
          headerNode="Breathing"
          backButtonOnClick={() => this.props.router.goBack()}
        />
        <LinearProgress
          variant="determinate"
          value={(this.getCurrentPage() / this.getTotalPages()) * 100}
          classes={{ bar: this.props.classes.bar }}
        />
        <div className={classes.contentWrapper}>
          <div className={classes.instructions}>
            Please select the option that best answers the questions relating to breathing
          </div>
          <div className={classes.imgRow}>
            <div className={classes.imageWrapper}>
              <Lungs />
            </div>
          </div>
          <div className={classes.questionnaireContainer}>
            <div className={classes.breathingText}>In the last 12 hours...</div>
            <YesNoButtonSet
              isYes={dizzyOrLightheaded === 'Y'}
              isNo={dizzyOrLightheaded === 'N'}
              onClickNo={() => this.handleChangeDizzyOrLightheaded('N')}
              onClickYes={() => this.handleChangeDizzyOrLightheaded('Y')}
              questionText="Have you felt dizzy or lightheaded?"
            />
            <YesNoButtonSet
              isYes={worseWithChange === 'Y'}
              isNo={worseWithChange === 'N'}
              onClickNo={() => this.handleChangeWorseWithChange('N')}
              onClickYes={() => this.handleChangeWorseWithChange('Y')}
              questionText="Does it get worse when standing or changing positions?"
            />
            <div className={classes.questionText}>What is your blood pressure?</div>
            <div className={classes.textFieldContainer}>
              <div className={classes.leaveBlankText}>Leave blank if you don't know your blood pressure.</div>
              <div className={classes.textFieldRow}>
                <div className={`${classes.textFieldWrapper} ${classes.marginRight10}`}>
                  <TextField
                    className={classes.inputField}
                    onChange={this.handleChangeSystolic}
                    type="number"
                    inputProps={{
                      inputMode: 'decimal',
                      pattern: '[0-9]*',
                      type: 'tel',
                      style: { fontSize: '16px' } }}
                    value={systolic}
                  />
                  <span className={classes.unitsWrapper}>Systolic</span>
                </div>
                <div className={classes.textFieldWrapper}>
                  <TextField
                    className={classes.inputField}
                    onChange={this.handleChangeDiastolic}
                    type="number"
                    inputProps={{
                      inputMode: 'decimal',
                      pattern: '[0-9]*',
                      type: 'tel',
                      style: { fontSize: '16px' } }}
                    value={diastolic}
                  />
                  <span className={classes.unitsWrapper}>Diastolic</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </QuestionLayout>
    );
  }
}

export default connect(null, { updatePRO })(withStyles(styles)(HeadAndNeckCancerSymptoms12));
