import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import brand from '../lib/brand';

class Informed extends Component {
  render() {
    return (
      <div style={{ width: this.props.width }}>
        <img
          src={`/img/${brand.name}/${brand.loginLogo}`}
          onClick={this.props.onClick}
          alt="informed logo"
          style={{ width: this.props.width }}
        />
      </div>
    );
  }
}

Informed.defaultProps = {
  onClick: noop,
  width: '12rem',
};

Informed.propTypes = {
  onClick: PropTypes.func,
  width: PropTypes.string,
};

export default Informed;
