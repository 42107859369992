const en = {
  woundCasaColina2: {
    title: 'Wound',
    instructions: 'Please make sure your image isn\'t blurry and it has a border of healthy skin (if possible)',
    addImageBtn: 'Add Image',
    retakeImageBtn: 'Retake/Re-upload',
  },
};

const es = {
  woundCasaColina2: {
    title: 'Herida',
    instructions: 'Tome una foto de su herida o cargue una desde el carrete de su cámara.',
    addImageBtn: 'Agregar Imagen',
    retakeImageBtn: 'Comenzar de nuevo',
  },
};

export default { en, es };
