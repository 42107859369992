import { promiseHandler } from 'cooldux';
import { keyBy } from 'lodash';
import { apiFetch, makeShadowOptions } from '../lib/fetch';

const {
  browseClinicsStart,
  browseClinicsEnd,
  browseClinicsError,
  browseClinicsHandler,
} = promiseHandler('browseClinics', 'application');
const {
  browseConditionsStart,
  browseConditionsEnd,
  browseConditionsError,
  browseConditionsHandler,
} = promiseHandler('browseConditions', 'application');
const {
  browseMigraineSymptomsStart,
  browseMigraineSymptomsEnd,
  browseMigraineSymptomsError,
  browseMigraineSymptomsHandler,
} = promiseHandler('browseMigraineSymptoms', 'application');
const {
  browseProviderSpecialtiesStart,
  browseProviderSpecialtiesEnd,
  browseProviderSpecialtiesError,
  browseProviderSpecialtiesHandler,
} = promiseHandler('browseProviderSpecialties', 'application');
const {
  browsePqrsStart,
  browsePqrsEnd,
  browsePqrsError,
  browsePqrsHandler,
} = promiseHandler('browsePqrs', 'application');
const {
  browseSocialStart,
  browseSocialEnd,
  browseSocialError,
  browseSocialHandler,
} = promiseHandler('browseSocial', 'application');
const {
  browseSurgeryStart,
  browseSurgeryEnd,
  browseSurgeryError,
  browseSurgeryHandler,
} = promiseHandler('browseSurgery', 'application');
const {
  browseSymptomsStart,
  browseSymptomsEnd,
  browseSymptomsError,
  browseSymptomsHandler,
} = promiseHandler('browseSymptoms', 'application');
const {
  browsePainLocationsStart,
  browsePainLocationsEnd,
  browsePainLocationsError,
  browsePainLocationsHandler,
} = promiseHandler('browsePainLocations', 'application');
const {
  browseAggravatingFactorsStart,
  browseAggravatingFactorsEnd,
  browseAggravatingFactorsError,
  browseAggravatingFactorsHandler,
} = promiseHandler('browseAggravatingFactors', 'application');
const {
  browseAlleviatingFactorsStart,
  browseAlleviatingFactorsEnd,
  browseAlleviatingFactorsError,
  browseAlleviatingFactorsHandler,
} = promiseHandler('browseAlleviatingFactors', 'application');
const {
  browseFunctionalLimitationsStart,
  browseFunctionalLimitationsEnd,
  browseFunctionalLimitationsError,
  browseFunctionalLimitationsHandler,
} = promiseHandler('browseFunctionalLimitations', 'application');
const {
  browseMigraineRxStart,
  browseMigraineRxEnd,
  browseMigraineRxError,
  browseMigraineRxHandler,
} = promiseHandler('browseMigraineRx', 'application');
const {
  readClinicStart,
  readClinicEnd,
  readClinicError,
  readClinicHandler,
} = promiseHandler('readClinic', 'application');

const requestOptions = {
  credentials: 'include',
  method: 'GET',
};

export function browseClinics() {
  return (dispatch) => {
    const promise = apiFetch('/clinics', requestOptions);
    return browseClinicsHandler(promise, dispatch);
  };
}

export function browseConditions() {
  return (dispatch) => {
    const promise = apiFetch('/conditions', requestOptions);
    return browseConditionsHandler(promise, dispatch);
  };
}

export function conditionsIfNeeded() {
  return function dispatcher(dispatch, getState) {
    const { appData } = getState();
    if (!appData.conditions) {
      dispatch(browseConditions());
    }
  };
}

export function browseMigraineSymptoms() {
  return (dispatch, getState) => {
    const { user } = getState();
    const options = makeShadowOptions(undefined, user.token, 'GET');
    const promise = apiFetch('/migraine_symptoms', options);
    return browseMigraineSymptomsHandler(promise, dispatch);
  };
}
export function migraineSymptomsIfNeeded() {
  return function dispatcher(dispatch, getState) {
    const { appData } = getState();
    if (!appData.migraineSymptoms) {
      dispatch(browseMigraineSymptoms());
    }
  };
}

export function browseProviderSpecialties() {
  return (dispatch) => {
    const promise = apiFetch('/provider_specialties', requestOptions);
    return browseProviderSpecialtiesHandler(promise, dispatch);
  };
}

export function browsePqrs() {
  return (dispatch, getState) => {
    const { user } = getState();
    const options = makeShadowOptions(undefined, user.token, 'GET');
    const promise = apiFetch('/pqrs', options);
    return browsePqrsHandler(promise, dispatch);
  };
}

export function pqrsIfNeeded() {
  return function dispatcher(dispatch, getState) {
    const { appData } = getState();
    if (!appData.pqrs) {
      dispatch(browsePqrs());
    }
  };
}

export function browseSocial() {
  return (dispatch) => {
    const promise = apiFetch('/social', requestOptions);
    return browseSocialHandler(promise, dispatch);
  };
}

export function socialIfNeeded() {
  return function dispatcher(dispatch, getState) {
    const { appData } = getState();
    if (!appData.social) {
      dispatch(browseSocial());
    }
  };
}

export function browseSurgery() {
  return (dispatch) => {
    const promise = apiFetch('/surgery', requestOptions);
    return browseSurgeryHandler(promise, dispatch);
  };
}

export function surgeryIfNeeded() {
  return function dispatcher(dispatch, getState) {
    const { appData } = getState();
    if (!appData.surgery) {
      dispatch(browseSurgery());
    }
  };
}

export function browseSymptoms() {
  return (dispatch, getState) => {
    const { user } = getState();
    const options = makeShadowOptions(undefined, user.token, 'GET');
    const promise = apiFetch('/symptoms', options);
    return browseSymptomsHandler(promise, dispatch);
  };
}

export function symptomsIfNeeded() {
  return function dispatcher(dispatch, getState) {
    const { appData } = getState();
    if (!appData.symptoms) {
      dispatch(browseSymptoms());
    }
  };
}

export function browsePainLocations() {
  return (dispatch) => {
    const promise = apiFetch('/pain_locations', requestOptions);
    return browsePainLocationsHandler(promise, dispatch);
  };
}

export function browsePainLocationsIfNeeded() {
  return function dispatcher(dispatch, getState) {
    const { appData } = getState();
    if (!appData.painLocations) {
      dispatch(browsePainLocations());
    }
  };
}

export function browseAggravatingFactors() {
  return (dispatch) => {
    const promise = apiFetch('/aggravating_factors', requestOptions);
    return browseAggravatingFactorsHandler(promise, dispatch);
  };
}

export function browseAlleviatingFactors() {
  return (dispatch) => {
    const promise = apiFetch('/alleviating_factors', requestOptions);
    return browseAlleviatingFactorsHandler(promise, dispatch);
  };
}

export function browseFunctionalLimitations() {
  return (dispatch) => {
    const promise = apiFetch('/functional_limitations', requestOptions);
    return browseFunctionalLimitationsHandler(promise, dispatch);
  };
}

export function browseMigraineRx() {
  return (dispatch, getState) => {
    const { user } = getState();
    const options = makeShadowOptions(undefined, user.token, 'GET');
    const promise = apiFetch('/migraine_rx', options);
    return browseMigraineRxHandler(promise, dispatch);
  };
}

export function migraineRxIfNeeded() {
  return function dispatcher(dispatch, getState) {
    const { appData } = getState();
    if (!appData.migraineRx) {
      dispatch(browseMigraineRx());
    }
  };
}

export function readClinicInfo(clinic_id) {
  return function dispatcher(dispatch) {
    const promise = apiFetch(`/clinics/${clinic_id}`);
    return readClinicHandler(promise, dispatch);
  };
}

export function fetchClinicsIfNecessary() {
  return function dispatcher(dispatch, getState) {
    const { appData } = getState();
    if (!appData.clinics) {
      dispatch(browseClinics());
    }
  };
}

const initialState = {
  browseAggravatingFactorsError: null,
  browseAlleviatingFactorsError: null,
  browseFunctionalLimitationsError: null,
  browseClinicsError: null,
  browseConditionsError: null,
  browseMigraineSymptomsError: null,
  browseMigraineRxError: null,
  browsePainLocationsError: null,
  browseProviderSpecialtiesError: null,
  browsePqrsError: null,
  browseSocialError: null,
  browseSurgeryError: null,
  browseSymptomsError: null,
  readClinicError: null,

  browseAggravatingFactorsPending: false,
  browseAlleviatingFactorsPending: false,
  browseFunctionalLimitationsPending: false,
  browseClinicsPending: false,
  browseConditionsPending: false,
  browseMigraineSymptomsPending: false,
  browseMigraineRxPending: false,
  browsePainLocationsPending: false,
  browseProviderSpecialtiesPending: false,
  browsePqrsPending: false,
  browseSocialPending: false,
  browseSurgeryPending: false,
  browseSymptomsPending: false,
  readClinicPending: false,

  aggravatingFactors: null,
  alleviatingFactors: null,
  clinics: null,
  conditions: null,
  functionalLimitations: null,
  migraineSymptoms: null,
  migraineRx: null,
  providerSpecialties: null,
  pqrs: null,
  readClinic: null,
  social: null,
  surgery: null,
  symptoms: null,
};


function finishBrowse(state, response, table, method) {
  return {
    ...state,
    [table]: keyBy(response, 'id'),
    [`${method}Error`]: null,
    [`${method}Pending`]: false,
  };
}
function finishRead(state, clinicInfo) {
  const clinics = Object.assign({}, state.clinics, { [clinicInfo.id]: clinicInfo });
  return { ...state, clinics, readClinicPending: false, readClinicError: null };
}

function user(state = initialState, { type, payload }) {
  switch (type) {
    case browseClinicsStart.type:
      return { ...state, browseClinicsError: null, browseClinicsPending: true };
    case browseClinicsEnd.type:
      return finishBrowse(state, payload, 'clinics', 'browseClinics');
    case browseClinicsError.type:
      return { ...state, browseClinicsError: payload };

    case browseConditionsStart.type:
      return { ...state, browseConditionsError: null, browseConditionsPending: true };
    case browseConditionsEnd.type:
      return finishBrowse(state, payload, 'conditions', 'browseConditions');
    case browseConditionsError.type:
      return { ...state, browseConditionsError: payload };

    case browseMigraineSymptomsStart.type:
      return { ...state, browseMigraineSymptomsError: null, browseMigraineSymptomsPending: true };
    case browseMigraineSymptomsEnd.type:
      return finishBrowse(state, payload, 'migraineSymptoms', 'browseMigraineSymptoms');
    case browseMigraineSymptomsError.type:
      return { ...state, browseMigraineSymptomsError: payload };

    case browseProviderSpecialtiesStart.type:
      return { ...state, browseProviderSpecialtiesError: null, browseProviderSpecialtiesPending: true };
    case browseProviderSpecialtiesEnd.type:
      return finishBrowse(state, payload, 'providerSpecialties', 'browseProviderSpecialties');
    case browseProviderSpecialtiesError.type:
      return { ...state, browseProviderSpecialtiesError: payload };

    case browsePqrsStart.type:
      return { ...state, browsePqrsError: null, browsePqrsPending: true };
    case browsePqrsEnd.type:
      return finishBrowse(state, payload, 'pqrs', 'browsePqrs');
    case browsePqrsError.type:
      return { ...state, browsePqrsError: payload };

    case browseSocialStart.type:
      return { ...state, browseSocialError: null, browseSocialPending: true };
    case browseSocialEnd.type:
      return finishBrowse(state, payload, 'social', 'browseSocial');
    case browseSocialError.type:
      return { ...state, browseSocialError: payload };

    case browseSurgeryStart.type:
      return { ...state, browseSurgeryError: null, browseSurgeryPending: true };
    case browseSurgeryEnd.type:
      return finishBrowse(state, payload, 'surgery', 'browseSurgery');
    case browseSurgeryError.type:
      return { ...state, browseSurgeryError: payload };

    case browseSymptomsStart.type:
      return { ...state, browseSymptomsError: null, browseSymptomsPending: true };
    case browseSymptomsEnd.type:
      return finishBrowse(state, payload, 'symptoms', 'browseSymptoms');
    case browseSymptomsError.type:
      return { ...state, browseSymptomsError: payload };

    case browsePainLocationsStart.type:
      return { ...state, browsePainLocationsError: null, browsePainLocationsPending: true };
    case browsePainLocationsEnd.type:
      return finishBrowse(state, payload, 'painLocations', 'browsePainLocations');
    case browsePainLocationsError.type:
      return { ...state, browsePainLocationsError: payload };

    case browseAggravatingFactorsStart.type:
      return { ...state, browseAggravatingFactorsError: null, browseAggravatingFactorsPending: true };
    case browseAggravatingFactorsEnd.type:
      return finishBrowse(state, payload, 'aggravatingFactors', 'browseAggravatingFactors');
    case browseAggravatingFactorsError.type:
      return { ...state, browseAggravatingFactorsError: payload };

    case browseAlleviatingFactorsStart.type:
      return { ...state, browseAlleviatingFactorsError: null, browseAlleviatingFactorsPending: true };
    case browseAlleviatingFactorsEnd.type:
      return finishBrowse(state, payload, 'alleviatingFactors', 'browseAlleviatingFactors');
    case browseAlleviatingFactorsError.type:
      return { ...state, browseAlleviatingFactorsError: payload };

    case browseFunctionalLimitationsStart.type:
      return { ...state, browseFunctionalLimitationsError: null, browseFunctionalLimitationsPending: true };
    case browseFunctionalLimitationsEnd.type:
      return finishBrowse(state, payload, 'functionalLimitations', 'browseFunctionalLimitations');
    case browseFunctionalLimitationsError.type:
      return { ...state, browseFunctionalLimitationsError: payload };

    case browseMigraineRxStart.type:
      return { ...state, browseMigraineRxError: null, browseMigraineRxPending: true };
    case browseMigraineRxEnd.type:
      return finishBrowse(state, payload, 'migraineRx', 'browseMigraineRx');
    case browseMigraineRxError.type:
      return { ...state, browseMigraineRxError: payload };

    case readClinicStart.type:
      return { ...state, readClinicError: null, readClinicPending: true };
    case readClinicEnd.type:
      return finishRead(state, payload, 'readClinic', 'readClinicInfo');
    case readClinicError.type:
      return { ...state, readClinicPending: false, readClinicError: payload };

    default:
      return state;
  }
}

export default user;
