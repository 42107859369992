const en = {
  woundPro3: {
    addPainLocation: 'Add a pain location',
    areYouSureYouWantToDelete: 'Are you sure you want to delete this wound recording: ',
    confirm: 'Confirm',
    headerNode: 'Wound Locations',
    tapThePlusSign: 'Tap the "+" sign to add another pain location',
  },
};

const es = {
  woundPro3: {
    addPainLocation: 'Add a pain location',
    areYouSureYouWantToDelete: 'Are you sure you want to delete this wound recording: ',
    confirm: 'Confirm',
    headerNode: 'Wound Locations',
    tapThePlusSign: 'Tap the "+" sign to add another pain location',
  },
};

export default { en, es };
