import React from 'react';
import Informed from '../components/informed';

const styles = {
  page: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: 700,
    height: '100vh',
    maxHeight: '100vh',
    textAlign: 'center',
  },
  banner: {
    marginTop: 10,
    width: '90%',
  },
  pageContent: {
    flexGrow: 1,
    fontSize: '2rem',
    padding: 15,
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'red',
    fontWeight: 'bold',
  },
};

function InactivePage() {
  return (
    <div style={styles.page}>
      <div style={styles.banner}>
        <Informed width="100%" />
      </div>
      <p style={styles.pageContent}>
        This page is no longer active. Please contact your healthcare provider.
      </p>
    </div>
  );
}

export default InactivePage;
