import React from 'react';
import { get } from 'lodash';
import { connect } from 'react-redux';

import { submitPRO } from '../state/pro-forms';
import Page from './page';
import NotificationAudio from '../components/notification-audio';
import { apiFetch } from '../lib/fetch';

class AudioNotification extends Page {
  constructor(props) {
    super(props);

    this.state = {
      playedBroadcast: false,
      startTime: new Date(),
      url: '',
    };
  }

  componentDidMount = async () => {
    const audioSrc = get(this.props, 'location.query.audioSrc')
      || get(this.props, 'user.meta.audioSrc')
      || get(this.props, 'location.state.audioSrc');
    const videoName = audioSrc.split('com/')[1];
    const clinicId = videoName.split('_')[0];
    const broadcastId = videoName.split('_')[3];
    const { url } = await apiFetch(`/clinics/${clinicId}/voice_broadcasts/${broadcastId}`);
    this.setState({ url });
  }

  handlePlayedAudio = () => {
    this.setState({ playedBroadcast: true });
  }

  handleDismissNotification = () => {
    const { playedBroadcast, startTime, submitting } = this.state;
    if (submitting) return;
    this.setState({ submitting: true });
    const { submitPRO, user, location } = this.props;
    const { query, state } = location;
    const { id, voice_broadcast_id: queryVoiceBroadcastId } = query;
    let broadcast_data = {};
    if (!query.railroad) {
      const { voice_broadcast_id } = state;
      broadcast_data = { voice_broadcast_id };
    } else if (user.meta && user.meta.bundled_events) {
      const broadcast = user.meta.bundled_events.find((b) => {
        return b.pro_submission_id === Number(id);
      });
      const { voice_broadcast_id } = broadcast;
      broadcast_data = { voice_broadcast_id };
    } else {
      broadcast_data = { voice_broadcast_id: queryVoiceBroadcastId };
    }
    const timeOnPage = Math.round((new Date().getTime() - startTime.getTime()) / 1000);
    const body = {
      broadcastId: broadcast_data.voice_broadcast_id,
      dismissed: true,
      isBroadcast: true,
      playedBroadcast,
      timeOnPage,
    };
    submitPRO({ userId: user.id, id }, {
      pro_type: 'VOICE_BROADCAST',
      pro_data: { data: body },
    }, user)
      .then(() => this.setState({ submitting: false }))
      .then(() => this.forward())
      .catch((e) => {
        this.setState({ error: e.message, submitting: false });
      });
  }

  render() {
    return (
      <NotificationAudio
        src={this.state.url}
        onDismissNotification={this.handleDismissNotification}
        onPlayAudio={this.handlePlayedAudio}
      />
    );
  }
}

function mapStateToProps(state) {
  const { user } = state;
  return {
    userId: user.id,
    user,
  };
}

export default connect(mapStateToProps, { submitPRO })(AudioNotification);
