import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get } from 'lodash';

import { colors } from '../lib/styles';
import Page from './page';
import Question from '../components/question';
import { Recreation } from '../lib/icons';
import { updatePRO, submitPRO } from '../state/pro-forms';

const options = [
  {
    label: 'I am able to engage in all my recreation activities with no neck pain at all',
    value: 0,
  },
  {
    label: 'I am able to engage in all my recreation activities with some pain in my neck',
    value: 1,
  },
  {
    label: 'I am able to engage in most, but not all of my usual recreation activities because of pain in my neck',
    value: 2,
  },
  {
    label: 'I am able to engage in few of my usual recreation activities because of pain in my neck',
    value: 3,
  },
  {
    label: 'I can hardly do any recreation activities because of pain in my neck',
    value: 4,
  },
  {
    label: 'I can\'t do any recreation activites at all',
    value: 5,
  },
];
const { questionBackground } = colors;

class NdiPainIntensity extends Page {
  state = {
    submitting: false,
  }
  onClickBackButton = () => {
    this.backward();
  }

  onSubmission = () => {
    if(this.state.submitting) return;

    const { user } = this.props;
    const { id } = this.props.location.query;
    this.setState({ submitting: true });

    this.props.submitPRO({ userId: user.id, id }, {
      pro_type: 'NDI',
      pro_data: { data: this.props.ndi },
    }, user)
      .then(() => this.forward())
      .catch(() => this.setState({ submitting: false }));
  }

  render() {
    const value = get(this.props.ndi, this.getTrackIndex(), null);
    return (
      <Question
        backgroundColor={questionBackground}
        currentPage={this.getCurrentPage()}
        image={Recreation}
        onClickBackButton={() => this.props.router.goBack()}
        onChange={this.props.updatePRO}
        onSubmission={this.onSubmission}
        options={options}
        primaryBtnDisabled={this.state.submitting}
        title="Recreation"
        totalPages={this.getTotalPages()}
        trackIndex={this.getTrackIndex()}
        type="ndi"
        value={value}
      />
    );
  }
}


function mapStateToProps(state) {
  const {
    user,
    proForms: {
      ndi,
    },
  } = state;
  return {
    user,
    ndi,
  };
}

NdiPainIntensity.propTypes = {
  ndi: PropTypes.array.isRequired,
  updatePRO: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { updatePRO, submitPRO })(NdiPainIntensity);
