import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get, isNumber } from 'lodash';

import Slider from '../components/horizontal-pain-slider';
import Page from './page';
import { updatePRO, submitPRO } from '../state/pro-forms';

class PISAFollowup1 extends Page {
  state = {
    submitting: false,
  }

  componentWillMount() {
    const value = get(this.props.pisaFollowup, this.getTrackIndex());
    if (!isNumber(value)) {
      this.props.updatePRO({
        type: 'pisaFollowup',
        position: this.getTrackIndex(),
        value: 0,
      });
    }
  }

  onSubmission = () => {
    this.forwardWithQuery(this.props.location.query);
  }

  render() {
    const value = get(this.props.pisaFollowup, this.getTrackIndex(), 0);
    return (
      <Slider
        currentPage={this.getCurrentPage()}
        onBack={() => this.props.router.goBack()}
        onChange={(e, sliderValue) => {
          this.props.updatePRO({
            type: 'pisaFollowup',
            position: this.getTrackIndex(),
            value: sliderValue,
          });
        }}
        onSubmission={this.onSubmission}
        title="Pain Average"
        primaryBtnDisabled={this.state.submitting}
        totalPages={this.getTotalPages()}
        trackIndex={this.getTrackIndex()}
        type="pisaFollowup"
        value={value}
        lowLabel=" "
        highLabel=" "
      />
    );
  }
}

function mapStateToProps(state) {
  const {
    user,
    proForms: {
      pisaFollowup,
    },
  } = state;
  return {
    user,
    pisaFollowup,
  };
}

PISAFollowup1.proptypes = {
  pisaFollowup: PropTypes.array.isRequired,
  updatePRO: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { updatePRO, submitPRO })(PISAFollowup1);
