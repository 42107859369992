import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import LinearProgress from '@material-ui/core/LinearProgress';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { withStyles } from '@material-ui/core/styles';

import AppBar from '../components/app-bar';
import Continue from '../components/continue';

import { User } from '../lib/icons';

import Page from './page';

import { updatePRO } from '../state/pro-forms';

const styles = {
  formControlLabelRoot: {
    alignItems: 'start',
    marginTop: 5,
    '& .MuiSvgIcon-root': {
      height: 20,
      width: 20,
    },
    '& .MuiIconButton-root': {
      paddingLeft: 0,
    },
  },
  iconContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    flexGrow: 0,
    flexShrink: 0,
    width: '100%',
    color: '#000',
    paddingTop: 10,
  },
  iconWrapper: {
    width: 100,
  },
  titleGroup: {
    margin: 25,
  },
  text: {
    fontSize: '1.75rem',
  },
  alert: {
    fontSize: '1.75rem',
    color: 'red',
  },
  nextBtn: {
    textAlign: 'center',
    width: '100%',
  },
  radio: {
    paddingTop: 4,
  },
  radioLabel: {
    fontSize: '2rem',
  },
  swatchLabel: {
    width: '75px',
    fontSize: '0.85em',
  },
  swatchTd: {
    verticalAlign: 'top',
  },
};

class ContinuousBPDataCollection6 extends Page {
  constructor(props) {
    super(props);

    this.state = {
      fitzpatrickScale: null,
    };

    this.handleContinue = this.handleContinue.bind(this);
  }

  handleContinue() {
    this.props.updatePRO({
      type: 'continuousBPDataCollection',
      position: this.getTrackIndex(),
      value: this.state });
    this.forwardWithQuery(this.props.location.query);
  }

  handleChange = (e) => {
    const { value } = e.target;
    this.setState({
      fitzpatrickScale: value,
    });
  };

  render() {
    const { classes } = this.props;
    const { fitzpatrickScale } = this.state;

    return (
      <Fragment>
        <AppBar
          headerNode="Patient Information"
          backButtonOnClick={() => this.props.router.goBack()}
        />
        <LinearProgress
          variant="determinate"
          value={(this.getCurrentPage() / this.getTotalPages()) * 100}
        />
        <div style={styles.iconContainer}>
          <div style={styles.iconWrapper}>
            <User />
          </div>
        </div>

        <div style={styles.titleGroup}>
          <FormControl component="fieldset">
            <h1>Fitzpatrick Scale</h1>
            <p style={styles.text}>Tap the color that best represents your skin tone.</p>
            <RadioGroup row aria-label="fitzpatrickScale" name="fitzpatrickScale" value={fitzpatrickScale} onChange={this.handleChange}>
              <table>
                <tbody>
                  <tr>
                    <td style={styles.swatchTd}>
                      <FormControlLabel
                        value="type1"
                        labelPlacement="top"
                        control={<Radio />}
                        label={(<div><img className="lazypreload lazyloaded" src="/img/skintones/1.png" alt="Lightest Skin Tone" title="Lightest Skin Tone" width="75" height="75" /><br/><div style={styles.swatchLabel}>Light, Pale White</div></div>)}
                        classes={{ root: classes.formControlLabelRoot, label: classes.radioLabel }}
                      />
                    </td>
                    <td style={styles.swatchTd}>
                      <FormControlLabel
                        value="type2"
                        labelPlacement="top"
                        control={<Radio />}
                        label={(<div><img className="lazypreload lazyloaded" src="/img/skintones/2.png" alt="Light Skin Tone" title="Light Skin Tone" width="75" height="75" /><br/><div style={styles.swatchLabel}>White, Fair</div></div>)}
                        classes={{ root: classes.formControlLabelRoot, label: classes.radioLabel }}
                      />
                    </td>
                    <td style={styles.swatchTd}>
                      <FormControlLabel
                        value="type3"
                        labelPlacement="top"
                        control={<Radio />}
                        label={(<div><img className="lazypreload lazyloaded" src="/img/skintones/3.png" alt="Medium-Light Skin Tone" title="Medium-Light Skin Tone" width="75" height="75" /><br/><div style={styles.swatchLabel}>Medium, White to Olive</div></div>)}
                        classes={{ root: classes.formControlLabelRoot, label: classes.radioLabel }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td style={styles.swatchTd}>
                      <FormControlLabel
                        value="type4"
                        labelPlacement="top"
                        control={<Radio />}
                        label={(<div><img className="lazypreload lazyloaded" src="/img/skintones/4.png" alt="Medium Skin Tone" title="Medium Skin Tone" width="75" height="75" /><br/><div style={styles.swatchLabel}>Olive, Moderate Brown</div></div>)}
                        classes={{ root: classes.formControlLabelRoot, label: classes.radioLabel }}
                      />
                    </td>
                    <td style={styles.swatchTd}>
                      <FormControlLabel
                        value="type5"
                        labelPlacement="top"
                        control={<Radio />}
                        label={(<div><img className="lazypreload lazyloaded" src="/img/skintones/5.png" alt="Medium-Dark Skin Tone" title="Medium-Dark Skin Tone" width="75" height="75" /><br/><div style={styles.swatchLabel}>Brown, Dark Brown</div></div>)}
                        classes={{ root: classes.formControlLabelRoot, label: classes.radioLabel }}
                      />
                    </td>
                    <td style={styles.swatchTd}>
                      <FormControlLabel
                        value="type6"
                        labelPlacement="top"
                        control={<Radio />}
                        label={(<div><img className="lazypreload lazyloaded" src="/img/skintones/6.png" alt="Dark Skin Tone" title="Dark Skin Tone" width="75" height="75" /><br/><div style={styles.swatchLabel}>Black, Very dark Brown to Black</div></div>)}
                        classes={{ root: classes.formControlLabelRoot, label: classes.radioLabel }}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </RadioGroup>
          </FormControl>
        </div>

        <div style={styles.nextBtn}>
          <Continue
            disabled={fitzpatrickScale === null}
            text="Next"
            onClick={this.handleContinue}
          />
        </div>

      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { user, proForms: { continuousBPDataCollection } } = state;
  return { user, continuousBPDataCollection };
}

ContinuousBPDataCollection6.proptypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  route: PropTypes.object.isRequired,
  updatePRO: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { updatePRO })(withStyles(styles)(ContinuousBPDataCollection6));
