import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import AppBar from '../components/app-bar';
import Page from './page';
import QuestionLayout from '../layouts/question';
import YesNoButtonSet from '../components/yes-no-button-set';
import { colors } from '../lib/styles';
import { updatePRO } from '../state/pro-forms';
import { WeightScale } from '../lib/icons';

const styles = {
  contentWrapper: {
    flexGrow: 1,
    overflowX: 'hidden',
    position: 'relative',
  },
  imgRow: {
    display: 'flex',
    justifyContent: 'center',
    padding: '15px 60px',
  },
  imageWrapper: {
    width: 100,
  },
  instructions: {
    color: 'grey',
    fontSize: '1.5rem',
    margin: '20px 40px 0px 40px',
    textAlign: 'center',
  },
  questionnaireContainer: {
    paddingLeft: 20,
    paddingRight: 20,
  },
  sectionText: {
    fontSize: '2rem',
    fontWeight: 'bold',
  },
};

class HeadAndNeckCancerSymptoms8 extends Page {
  constructor(props) {
    super(props);

    this.state = {
      bowelMovement: null,
      decreaseInAppetite: null,
      nauseaOrVomiting: null,
    };
  }

  handleChangeBowelMovement = (value) => {
    this.setState({ bowelMovement: value });
  }

  handleChangeDecreaseInAppetite = (value) => {
    this.setState({ decreaseInAppetite: value });
  }

  handleChangeNauseaOrVomiting = (value) => {
    this.setState({ nauseaOrVomiting: value });
  }

  handleClickNext = () => {
    const { bowelMovement, decreaseInAppetite, nauseaOrVomiting } = this.state;

    this.props.updatePRO({
      type: 'headAndNeckCancerSymptoms',
      position: this.getTrackIndex(),
      value: {
        bowelMovement,
        decreaseInAppetite,
        nauseaOrVomiting,
      },
    });

    this.goToIndexWithQuery(10, this.props.location.query);
  }

  render() {
    const { classes } = this.props;
    const { bowelMovement, decreaseInAppetite, nauseaOrVomiting } = this.state;

    const continueDisabled = bowelMovement === null
      || decreaseInAppetite === null
      || nauseaOrVomiting === null;

    return (
      <QuestionLayout
        primaryBtnLabel="Next"
        primaryBtnOnTouchTap={this.handleClickNext}
        primaryBtnDisabled={continueDisabled}
        style={{ backgroundColor: colors.questionBackground }}
      >
        <AppBar
          headerNode="Weight Change"
          backButtonOnClick={() => this.props.router.goBack()}
        />
        <LinearProgress
          variant="determinate"
          value={(this.getCurrentPage() / this.getTotalPages()) * 100}
          classes={{ bar: classes.bar }}
        />
        <div className={classes.contentWrapper}>
          <div className={classes.instructions}>
            Please select the option that best answers the questions relating to weight
          </div>
          <div className={classes.imgRow}>
            <div className={classes.imageWrapper}>
              <WeightScale />
            </div>
          </div>
          <div className={classes.questionnaireContainer}>
            <div className={classes.sectionText}>Is the following true?</div>
            <YesNoButtonSet
              isYes={nauseaOrVomiting === 'Y'}
              isNo={nauseaOrVomiting === 'N'}
              onClickNo={() => this.handleChangeNauseaOrVomiting('N')}
              onClickYes={() => this.handleChangeNauseaOrVomiting('Y')}
              questionText="Do you have nausea or vomiting?"
            />
            <YesNoButtonSet
              isYes={decreaseInAppetite === 'Y'}
              isNo={decreaseInAppetite === 'N'}
              onClickNo={() => this.handleChangeDecreaseInAppetite('N')}
              onClickYes={() => this.handleChangeDecreaseInAppetite('Y')}
              questionText="Have you noticed a decrease in appetite?"
            />
            <YesNoButtonSet
              isYes={bowelMovement === 'Y'}
              isNo={bowelMovement === 'N'}
              onClickNo={() => this.handleChangeBowelMovement('N')}
              onClickYes={() => this.handleChangeBowelMovement('Y')}
              questionText="Have you had a bowel movement in the last 2 days?"
            />
          </div>
        </div>
      </QuestionLayout>
    );
  }
}

export default connect(null, { updatePRO })(withStyles(styles)(HeadAndNeckCancerSymptoms8));
