import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import Page from './page';
import { submitPRO } from '../state/pro-forms';
import DisableSessionTimeout from '../components/disable-session-timeout';
import { colors } from '../lib/styles';

const styles = {
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '0px 20px',
    flexGrow: 1,
    textAlign: 'center',
  },
  link: {
    color: colors.white,
    fontSize: '1.4rem',
    backgroundColor: colors.primaryColor,
    borderRadius: '0.3125rem',
    minWidth: '17.1875rem',
    marginBottom: '0.625rem',
    marginTop: '2rem',
    textAlign: 'center',
    textDecoration: 'none',
    width: '85vw',
    lineHeight: '4rem',
  },
  question: {
    fontSize: '1.5rem',
    margin: '60px 40px 0px 40px',
    textAlign: 'center',
  },
  greenCheckMark: {
    marginTop: 30,
    marginBottom: 30,
    width: '8rem',
    height: '8rem',
  },
  instructionText: {
    fontSize: '1.25rem',
    marginTop: 20,
  },
  apologyText: {
    fontSize: '2rem',
    fontWeight: 'bold',
  },
};

class DrJamesonReview2 extends Page {
  render() {
    const { classes } = this.props;

    return (
      <div className={classes.contentWrapper}>
        <DisableSessionTimeout />
        <img
          src="/img/GreenCheckmarkCircle.png"
          className={classes.greenCheckMark}
          alt="Green check mark"
        />
        <div className={classes.apologyText}>Thank you for letting us know. We are very sorry to hear that you had a negative experience</div>
        <div className={classes.instructionText}>Please use the link below to give an explanation as to why you were not satisfied with your experience. Your opinion is valuable and helps us to improve our practice.</div>
        <div className={classes.instructionText}>Thank you for the feedback</div>
        <a
          className={classes.link}
          href="mailto:info@axisspinecenter.com"
        >
          Email Axis Spine
        </a>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state;
  return { user };
}

export default connect(mapStateToProps, { submitPRO })(withStyles(styles)(DrJamesonReview2));
