import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';

class MultilineTextField extends Component {
  render() {
    return (
      <TextField
        placeholder={this.props.hintText}
        multiline
        rows={this.props.rows}
        onChange={this.props.onChange}
        value={this.props.value}
        fullWidth
        InputProps={{
          disableUnderline: true,
          style: { fontSize: '16px' },
        }}
        style={{ marginLeft: '15px'}}
      />
    );
  }
}

MultilineTextField.defaultProps = {
  hintText: '',
  value: '',
  rows: {},
};

MultilineTextField.propTypes = {
  hintText: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  rows: PropTypes.number,
};

export default MultilineTextField;
