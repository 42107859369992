import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get, includes } from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import LinearProgress from '../components/linear-progress';
import QuestionLayout from '../layouts/question';
import Page from './page';
import { colors } from '../lib/styles';
import AppBar from '../components/app-bar';
import Hidden from '../components/hidden-content';
import { updatePRO } from '../state/pro-forms';

const { questionBackground } = colors;

const styles = {
  instructions: {
    fontSize: '1.3rem',
  },
  image: {
    height: 120,
    padding: '15px 60px',
    textAlign: 'center',
  },
  pageIndicator: {
    color: 'grey',
    display: 'inline-block',
    fontSize: 13,
  },
  questionnaire: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    minHeight: '350px',
    width: '80%',
    margin: 'auto',
  },
  title: {
    color: 'black',
    fontSize: '1.5rem',
    fontWeight: 800,
    textTransform: 'uppercase',
  },
  date: {
    width: '150px',
  },
  itemStyles: {
    color: colors.black,
    fontSize: '1.5rem',
    textAlign: 'left',
    textTransform: 'uppercase',
  },
  titleGroup: {
    margin: '10px 0px',
  },
  error: {
    color: colors.errorRed,
  },
  text: {
    fontSize: '1.25rem',
  },
  checkbox: {
    color: colors.primaryColor,
    fontSize: '1.2rem',
  },
  radioButton: {
    color: colors.black,
    fontSize: '1.2rem',
  },
  radioButtonGroup: {
    display: 'flex',
    width: '200px',
  },
  hidden: {
    position: 'relative',
    top: '25px',
    color: colors.errorRed,
    fontSize: '1.2rem',
  }
};

const validList = [
  'None',
  'Headache',
  'Back pain',
  'Joint pain',
  'Nerve pain',
];

class PrgEndo3 extends Page {
  constructor(props) {
    super(props);

    this.state = {
      other: '',
      selectedItems: [],
      showOther: false,
    };
  }
  componentWillMount() {
    const values = get(this.props.prgendo, this.getTrackIndex(), []);
    const otherVal = values.filter(i => !validList.includes(i));

    if (otherVal.length) {
      this.setState({ other: otherVal[0], showOther: true });
    }

  }
  handleUpdate = (item) => {
    const values = get(this.props.prgendo, this.getTrack(), []);
    if (values.includes(item)) {
      this.props.updatePRO({
        type: 'prgendo',
        position: this.getTrackIndex(),
        value: values.filter(i => i !== item),
      });
    } else if (item === 'None') {
      this.setState({ other: '', showOther: false });
      this.props.updatePRO({
        type: 'prgendo',
        position: this.getTrackIndex(),
        value: [item],
      });
    } else {
      this.props.updatePRO({
        type: 'prgendo',
        position: this.getTrackIndex(),
        value: values.filter(i => i !== 'None').concat(item),
      });
    }

  }
  handleSubmit = () => {
    const { submitting } = this.state;
    const values = get(this.props.prgendo, this.getTrackIndex(), []);
    if (submitting) return;
    if (includes(this.state, null)) {
      return this.setState({ hidden: false });
    }
    this.setState({ submitting: true });
    this.props.updatePRO({
      type: 'prgendo',
      position: this.getTrackIndex(),
      value: values.concat(this.state.other),
    });
    this.forwardWithQuery(this.props.location.query);
  }
  render() {
    const values = get(this.props.prgendo, this.getTrackIndex(), []);
    const currentPage = this.getCurrentPage();
    const totalPages = this.getTotalPages();
    return (
      <QuestionLayout
        primaryBtnDisabled={!values.length && !this.state.other}
        primaryBtnLabel="Next"
        primaryBtnOnTouchTap={this.handleSubmit}
        style={{ background: questionBackground }}
      >
        <AppBar
          backButtonOnClick={() => this.props.router.goBack()}
          headerNode="Patient Survey"
          rightNode={
            <div style={styles.pageIndicator}>{`${currentPage} of ${totalPages}`}</div>
          }
        />
        <LinearProgress
          value={(currentPage / totalPages) * 100}
        />
        <div style={styles.questionnaire}>
          <div style={styles.titleGroup}>
            <p style={styles.text}>Do you have a physician diagnosed chronic pain condition that is not related to your endometriosis?</p>
          </div>
          <FormGroup>
            {validList.map((i) => (
              <FormControlLabel
                key={i}
                control={
                  <Checkbox
                    checked={values.includes(i)}
                    onChange={(event, value) => {
                      this.handleUpdate(i);
                    }}
                    style={styles.checkbox}
                  />
                }
                classes={{ label: this.props.classes.checkbox }}
                label={i}
              />
            ))}
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.showOther}
                  onChange={(event, value) => {
                    this.setState({ showOther: !this.state.showOther });
                  }}
                  style={styles.checkbox}
                />
              }
              classes={{ label: this.props.classes.checkbox }}
              label="Other"
            /><Hidden hidden={!this.state.showOther}>
              <TextField
                inputProps={{ style: { marginLeft: '20px' } }}
                label="Other Pains"
                value={this.state.other}
                onChange={(event) => {
                  this.setState({ other: event.target.value });
                }}
              />
            </Hidden>
          </FormGroup>
        </div>
      </QuestionLayout>
    );
  }
}


function mapStateToProps(state) {
  const { user, proForms: { prgendo } } = state;
  return { prgendo, user };
}

PrgEndo3.proptypes = {
  user: PropTypes.object.isRequired,
  route: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, { updatePRO })(withStyles({ checkbox: { fontSize: '1.2rem' } })(PrgEndo3));

