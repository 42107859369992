import React from 'react';
import { connect } from 'react-redux';

import Page from './page';
import { colors } from '../lib/styles';
import { resetPin, submitPin, setPin, setConfirmPin, setCause } from '../state/pin';
import PinKey from '../components/pin-key';
import Continue from '../components/continue';

const baseStyles = {
  button: {
    backgroundColor: colors.primaryHighlight,
  },
  label: {
    color: colors.primaryColor,
  },
  pinShow: {
    minHeight: '40px',
  },
  bottomButton: {
    minHeight: '60px',
  },
  sectionStyle: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '20px',
  },
  title: {
    fontSize: '1.5rem',
  },
  background: {
    backgroundColor: colors.primaryColor,
    height: '100vh',
    textAlign: 'center',
    color: colors.white,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
    margin: 'auto',
  },
  circle: {
    backgroundColor: colors.primaryColor,
    border: '1px solid white',
    height: '4rem',
    width: '4rem',
    textAlign: 'center',
    color: colors.white,
    margin: '5px',
    fontSize: '3rem',
  },
  deleteButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '1rem',
    marginTop: '30px',
  },
  errorStyle: {
    color: 'red',
    backgroundColor: 'white',
    width: '50vw',
    borderRadius: '10px',
    margin: '0 auto',
  },
};

class SetPin extends Page {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleConfirm = this.handleConfirm.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.toggleSubmit = this.toggleSubmit.bind(this);
    this.toggleContinue = this.toggleContinue.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.initialState = {
      pin: this.props.pin.pin || '',
      confirmPin: '',
      error: '',
      title: 'Enter PIN',
      showContinue: false,
      showSubmit: false,
    };
    this.state = this.initialState;
  }

  toggleSubmit() {
    this.setState({ showSubmit: !this.state.showSubmit });
  }

  toggleContinue() {
    this.setState({ showContinue: !this.state.showContinue });
  }

  handleConfirm() {
    this.props.setPin(this.state.pin);
    this.props.setCause('confirm');
    this.setState({ title: 'Confirm PIN' });
  }

  handleSubmit() {
    if (!this.props.pin.edit) {
      this.props.submitPin(this.props.pin.pin);
    }
    this.forward();
  }

  handleDelete() {
    if (!this.props.pin.cause) {
      this.setState({ pin: this.state.pin.slice(0, -1) });
    }
    if (this.props.pin.cause === 'confirm') {
      this.setState({ confirmPin: this.state.confirmPin.slice(0, -1) });
    }
  }

  handleReset() {
    this.setState(this.initialState);
    this.props.resetPin();
  }

  componentWillUnmount() {
    this.handleReset();
  }

  handleChange(e) {
    if (!this.props.pin.cause) {
      if (this.state.pin.length < 4) {
        this.setState({ pin: this.state.pin + e });
      }
    }
    if (this.props.pin.cause === 'confirm') {
      if (this.state.confirmPin.length < 4) {
        this.setState({ confirmPin: this.state.confirmPin + e });
      }
    }
  }

  render() {
    const { confirmPin, pin } = this.state;
    const { cause } = this.props.pin;
    let buttonControl = '';
    const continueButton = (
      <Continue
        text="Confirm PIN"
        btnStyle={baseStyles.button}
        labelStyle={baseStyles.label}
        onClick={this.handleConfirm}
      />
    );

    let errorMsg;

    const submitButton = (
      <Continue
        text="Submit PIN"
        btnStyle={baseStyles.button}
        labelStyle={baseStyles.label}
        onClick={this.handleSubmit}
      />
    );
    if (pin.length === 4 && !cause) {
      buttonControl = continueButton;
    }

    if (this.props.pin.pin === confirmPin) {
      buttonControl = submitButton;
    }

    if (confirmPin.length === 4 && this.props.pin.pin !== confirmPin) {
      errorMsg = <div style={baseStyles.errorStyle}>Pin Code Does Not Match</div>;
    }

    const title = <div style={baseStyles.title}>{this.state.title}</div>;
    let pinHeader;
    if (!cause) {
      pinHeader = this.state.pin.replace(/./g, '*');
    } else {
      pinHeader = this.state.confirmPin.replace(/./g, '*');
    }
    return (
      <div style={baseStyles.background}>
        {title}
        <div style={baseStyles.pinShow}>
          {pinHeader}
        </div>
        <section style={baseStyles.sectionStyle}>
          <div>
            <PinKey event={() => this.handleChange(1)}>1</PinKey>
            <PinKey event={() => this.handleChange(4)}>4</PinKey>
            <PinKey event={() => this.handleChange(7)}>7</PinKey>
            <div onClick={this.handleReset} style={baseStyles.deleteButton}>Reset</div>
          </div>
          <div>
            <PinKey event={() => this.handleChange(2)}>2</PinKey>
            <PinKey event={() => this.handleChange(5)}>5</PinKey>
            <PinKey event={() => this.handleChange(8)}>8</PinKey>
            <PinKey event={() => this.handleChange(0)}>0</PinKey>
          </div>
          <div>
            <PinKey event={() => this.handleChange(3)}>3</PinKey>
            <PinKey event={() => this.handleChange(6)}>6</PinKey>
            <PinKey event={() => this.handleChange(9)}>9</PinKey>
            <div onClick={this.handleDelete} style={baseStyles.deleteButton}>Delete</div>
          </div>
        </section>
        <section style={baseStyles.bottomButton}>
          {buttonControl}
          {errorMsg}
        </section>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { user, pin } = state;
  return {
    user,
    pin,
  };
}

export default connect(mapStateToProps, {
  resetPin,
  setPin,
  setConfirmPin,
  setCause,
  submitPin,
})(SetPin);
