const en = {
  general: {
    yes: 'Yes',
    accept: 'Accept',
    no: 'No',
    decline: 'Decline',
    unsure: "Don't know",
    notApplicable: 'N/A',
    nextBtn: 'Next',
    selectDefault: 'Select option',
    state: 'State',
    startBtn: 'Start',
  },
};

const es = {
  general: {
    yes: 'Sí',
    accept: 'Acuerdo',
    no: 'No',
    decline: 'Desacuerdo',
    notApplicable: 'No aplicable',
    unsure: 'No sé',
    nextBtn: 'Próxima',
    selectDefault: 'Seleccione opción',
    state: 'Estado',
    startBtn: 'Comienzo',
  },
};

export default { en, es };
