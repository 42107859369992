import React from 'react';
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  LinearProgress,
  TextField,
} from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import AppBar from '../components/app-bar';
import Page from './page';
import QuestionLayout from '../layouts/question';
import i18nTranslator, { title, subtitle, nextBtn, options } from '../lib/i18next';
import { colors } from '../lib/styles';
import { updatePRO } from '../state/pro-forms';
import { Smile } from '../lib/icons';

const styles = {
  boldText: {
    fontSize: 29,
    fontWeight: 'bold',
  },
  checkbox: {
    paddingTop: 3,
  },
  contentWrapper: {
    flexGrow: 1,
    overflowX: 'hidden',
    position: 'relative',
  },
  formControlLabel: {
    alignItems: 'start',
    marginBottom: 10,
  },
  imgRow: {
    display: 'flex',
    justifyContent: 'center',
    padding: '15px 60px',
  },
  imageWrapper: {
    width: 100,
  },
  marginTop20: {
    marginTop: 20,
  },
  painScaleText: {
    fontSize: '1.5rem',
    marginTop: 10,
  },
  painScaleWrapper: {
    marginBottom: 30,
    marginTop: 50,
  },
  questionnaireContainer: {
    paddingLeft: 20,
    paddingRight: 20,
  },
  smallText: {
    fontSize: 16,
  },
  text: {
    fontSize: 22,
  },
  textFieldRoot: {
    marginTop: 10,
    '& input': {
      fontSize: 18,
    },
    '& label': {
      fontSize: 18,
    },
  },
};

class PisaInjection3 extends Page {
  constructor(props) {
    super(props);

    this.state = {
      symptoms: [],
      increasedEnergy: false,
      increasedActivity: false,
      increasedMood: false,
      increasedSleep: false,
      increasedThink: false,
      increasedSocial: false,
      reducedPainActivity: false,
      reducedPainWalking: false,
      reducedPainStanding: false,
      reducedPainSitting: false,
      reducedAnxietyDepression: false,
      other: false,
      otherDetails: '',
    };
  }

  handleChangeOther = (e) => {
    this.setState({ otherDetails: e.target.value });
  }

  handleChangeStymptoms = (e) => {
    const { name, checked, value } = e.target;
    const { symptoms } = this.state;
    this.setState({ [name]: checked });

    if (checked && value !== '') {
      symptoms.push(value);
    } else if (!checked) {
      this.setState({ symptoms: symptoms.filter(symptom => symptom !== value) });
    }
  };

  handleClickNext = () => {
    const { symptoms, other, otherDetails } = this.state;

    if (other && otherDetails) {
      symptoms.push(otherDetails);
    }

    this.props.updatePRO({
      type: 'pisaInjection',
      position: this.getTrackIndex(),
      value: { symptoms },
    });

    this.forwardWithQuery(this.props.location.query);
  }


  render() {
    const { classes, user: { primary_language } } = this.props;
    const {
      increasedEnergy,
      increasedActivity,
      increasedMood,
      increasedSleep,
      increasedThink,
      increasedSocial,
      reducedPainActivity,
      reducedPainWalking,
      reducedPainStanding,
      reducedPainSitting,
      reducedAnxietyDepression,
      other,
      otherDetails,
    } = this.state;

    const placeholder = primary_language === 'en' ? 'Tap to enter' : 'Toca aquí para entrar';

    return (
      <QuestionLayout
        primaryBtnLabel={nextBtn()}
        primaryBtnOnTouchTap={this.handleClickNext}
        primaryBtnDisabled={false}
        style={{ backgroundColor: colors.questionBackground }}
      >
        <AppBar
          headerNode={title('pisaInjection3')}
          backButtonOnClick={() => this.props.router.goBack()}
        />
        <LinearProgress
          variant="determinate"
          value={(this.getCurrentPage() / this.getTotalPages()) * 100}
          classes={{ bar: classes.bar }}
        />
        <div className={classes.contentWrapper}>
          <div className={classes.imgRow}>
            <div className={classes.imageWrapper}>
              <Smile />
            </div>
          </div>
          <div className={classes.questionnaireContainer}>
            <div className={classes.text}>{i18nTranslator('question1', 'pisaInjection3')}</div>
            <div className={classes.smallText}>{subtitle('pisaInjection3')}</div>
            <FormGroup className={classes.marginTop20}>
              <FormControlLabel
                control={(
                  <Checkbox
                    checked={increasedEnergy}
                    value="Increased/Improved my energy"
                    onChange={this.handleChangeStymptoms}
                    name="increasedEnergy"
                    icon={<CheckBoxOutlineBlankIcon fontSize="large" />}
                    checkedIcon={<CheckBoxIcon fontSize="large" />}
                    className={classes.checkbox}
                  />
                )}
                label={options('increasedEnergy', 'pisaInjection3')}
                classes={{
                  label: classes.text,
                  root: classes.formControlLabel,
                }}
              />

              <FormControlLabel
                control={(
                  <Checkbox
                    checked={increasedActivity}
                    onChange={this.handleChangeStymptoms}
                    name="increasedActivity"
                    value="Increased/Improved my activity"
                    icon={<CheckBoxOutlineBlankIcon fontSize="large" />}
                    checkedIcon={<CheckBoxIcon fontSize="large" />}
                    className={classes.checkbox}
                  />
                  )}
                label={options('increasedActivity', 'pisaInjection3')}
                classes={{
                  label: classes.text,
                  root: classes.formControlLabel,
                }}
              />

              <FormControlLabel
                control={(
                  <Checkbox
                    checked={increasedMood}
                    onChange={this.handleChangeStymptoms}
                    name="increasedMood"
                    value="Increased/Improved my mood"
                    icon={<CheckBoxOutlineBlankIcon fontSize="large" />}
                    checkedIcon={<CheckBoxIcon fontSize="large" />}
                    className={classes.checkbox}
                  />
                )}
                label={options('increasedMood', 'pisaInjection3')}
                classes={{
                  label: classes.text,
                  root: classes.formControlLabel,
                }}
              />

              <FormControlLabel
                control={(
                  <Checkbox
                    checked={increasedSleep}
                    onChange={this.handleChangeStymptoms}
                    name="increasedSleep"
                    value="Increased/Improved my sleeping at night"
                    icon={<CheckBoxOutlineBlankIcon fontSize="large" />}
                    checkedIcon={<CheckBoxIcon fontSize="large" />}
                    className={classes.checkbox}
                  />
                )}
                label={options('increasedSleep', 'pisaInjection3')}
                classes={{
                  label: classes.text,
                  root: classes.formControlLabel,
                }}
              />

              <FormControlLabel
                control={(
                  <Checkbox
                    checked={increasedThink}
                    onChange={this.handleChangeStymptoms}
                    name="increasedThink"
                    value="Increased/Improved my ability to think"
                    icon={<CheckBoxOutlineBlankIcon fontSize="large" />}
                    checkedIcon={<CheckBoxIcon fontSize="large" />}
                    className={classes.checkbox}
                  />
                )}
                label={options('increasedThink', 'pisaInjection3')}
                classes={{
                  label: classes.text,
                  root: classes.formControlLabel,
                }}
              />

              <FormControlLabel
                control={(
                  <Checkbox
                    checked={increasedSocial}
                    onChange={this.handleChangeStymptoms}
                    name="increasedSocial"
                    value="Increased/Improved my ability to engage in social activities and relationships"
                    icon={<CheckBoxOutlineBlankIcon fontSize="large" />}
                    checkedIcon={<CheckBoxIcon fontSize="large" />}
                    className={classes.checkbox}
                  />
                )}
                label={options('increasedSocial', 'pisaInjection3')}
                classes={{
                  label: classes.text,
                  root: classes.formControlLabel,
                }}
              />

              <FormControlLabel
                control={(
                  <Checkbox
                    checked={reducedPainActivity}
                    onChange={this.handleChangeStymptoms}
                    name="reducedPainActivity"
                    value="Reduced my pain during prolonged activity"
                    icon={<CheckBoxOutlineBlankIcon fontSize="large" />}
                    checkedIcon={<CheckBoxIcon fontSize="large" />}
                    className={classes.checkbox}
                  />
                )}
                label={options('reducedPainActivity', 'pisaInjection3')}
                classes={{
                  label: classes.text,
                  root: classes.formControlLabel,
                }}
              />

              <FormControlLabel
                control={(
                  <Checkbox
                    checked={reducedPainWalking}
                    onChange={this.handleChangeStymptoms}
                    name="reducedPainWalking"
                    value="Reduced my pain while walking"
                    icon={<CheckBoxOutlineBlankIcon fontSize="large" />}
                    checkedIcon={<CheckBoxIcon fontSize="large" />}
                    className={classes.checkbox}
                  />
                )}
                label={options('reducedPainWalking', 'pisaInjection3')}
                classes={{
                  label: classes.text,
                  root: classes.formControlLabel,
                }}
              />

              <FormControlLabel
                control={(
                  <Checkbox
                    checked={reducedPainStanding}
                    onChange={this.handleChangeStymptoms}
                    name="reducedPainStanding"
                    value="Reduced my pain while standing"
                    icon={<CheckBoxOutlineBlankIcon fontSize="large" />}
                    checkedIcon={<CheckBoxIcon fontSize="large" />}
                    className={classes.checkbox}
                  />
                )}
                label={options('reducedPainStanding', 'pisaInjection3')}
                classes={{
                  label: classes.text,
                  root: classes.formControlLabel,
                }}
              />

              <FormControlLabel
                control={(
                  <Checkbox
                    checked={reducedPainSitting}
                    onChange={this.handleChangeStymptoms}
                    name="reducedPainSitting"
                    value="Reduced my pain while sitting"
                    icon={<CheckBoxOutlineBlankIcon fontSize="large" />}
                    checkedIcon={<CheckBoxIcon fontSize="large" />}
                    className={classes.checkbox}
                  />
                )}
                label={options('reducedPainSitting', 'pisaInjection3')}
                classes={{
                  label: classes.text,
                  root: classes.formControlLabel,
                }}
              />

              <FormControlLabel
                control={(
                  <Checkbox
                    checked={reducedAnxietyDepression}
                    onChange={this.handleChangeStymptoms}
                    name="reducedAnxietyDepression"
                    value="Reduced my anxiousness and/or feeling depressed"
                    icon={<CheckBoxOutlineBlankIcon fontSize="large" />}
                    checkedIcon={<CheckBoxIcon fontSize="large" />}
                    className={classes.checkbox}
                  />
                )}
                label={options('reducedAnxietyDepression', 'pisaInjection3')}
                classes={{
                  label: classes.text,
                  root: classes.formControlLabel,
                }}
              />

              <FormControlLabel
                control={(
                  <Checkbox
                    checked={other}
                    onChange={this.handleChangeStymptoms}
                    name="other"
                    icon={<CheckBoxOutlineBlankIcon fontSize="large" />}
                    checkedIcon={<CheckBoxIcon fontSize="large" />}
                    className={classes.checkbox}
                  />
                )}
                label={options('other', 'pisaInjection3')}
                classes={{
                  label: classes.text,
                  root: classes.formControlLabel,
                }}
              />
              {other ? (
                <TextField
                  fullWidth
                  placeholder={placeholder}
                  onChange={this.handleChangeOther}
                  className={classes.textFieldRoot}
                  value={otherDetails}
                />
              ) : null}
            </FormGroup>
          </div>
        </div>
      </QuestionLayout>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    proForms: {
      pisaInjection,
    },
    user,
  } = state;

  return { user, pisaInjection };
};

export default connect(mapStateToProps, { updatePRO })(withStyles(styles)(PisaInjection3));
