import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Input from '@material-ui/core/Input';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import LinearProgress from '@material-ui/core/LinearProgress';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { withStyles } from '@material-ui/core/styles';
import { Highlight } from '@material-ui/icons';

import AppBar from '../components/app-bar';
import Continue from '../components/continue';

import Page from './page';

import { updatePRO } from '../state/pro-forms';

const styles = {
  formControlLabelRoot: {
    alignItems: 'start',
    marginTop: 5,
    '& .MuiSvgIcon-root': {
      height: 20,
      width: 20,
    },
  },
  iconContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    flexGrow: 0,
    flexShrink: 0,
    width: '100%',
    color: '#000',
    paddingTop: 10,
  },
  iconWrapper: {
    width: 100,
  },
  radio: {
    paddingTop: 4,
  },
  radioLabel: {
    fontSize: '2rem',
  },
  titleGroup: {
    margin: 25,
  },
  text: {
    fontSize: '2rem',
  },
  nextBtn: {
    textAlign: 'center',
    width: '100%',
    top: '90%',
  },
};

class ContactSpo2DataCollection12 extends Page {
  constructor(props) {
    super(props);

    this.state = {
      light_source: null,
      light_direction: null,
      light_coverage: null,
      light_direction_other: '',
    };
  }

  handleChange = (e) => {
    const { value } = e.target;
    this.setState({
      light_source: value,
    });
  };

  handleChangeDirection = (e) => {
    const { value } = e.target;
    this.setState({
      light_direction: value,
    });
  };

  handleChangeDirectionOther = (e) => {
    const { value } = e.target;
    this.setState({
      light_direction_other: value,
    });
  };

  handleChangeCoverage = (e) => {
    const { value } = e.target;
    this.setState({
      light_coverage: value,
    });
  };

  handleContinue = () => {
    const { location: { query }, updatePRO } = this.props;

    updatePRO({
      type: 'contactSpo2DataCollection',
      position: this.getTrackIndex(),
      value: this.state });
    this.forwardWithQuery(query);
  }

  render() {
    const { classes } = this.props;
    const {
      light_coverage,
      light_source,
      light_direction,
      light_direction_other,
    } = this.state;

    return (
      <Fragment>
        <AppBar
          headerNode="Lighting"
          noLeftNode={true}
        />
        <LinearProgress
          variant="determinate"
          value={(this.getCurrentPage() / this.getTotalPages()) * 100}
        />
        <div className={classes.iconContainer}>
          <div className={classes.iconWrapper}>
            <Highlight style={{ fontSize: 100 }} />
          </div>
        </div>

        <div className={classes.titleGroup}>
          <p className={classes.text}>
            Please describe your current lighting conditions:
            <i>(check all that apply)</i>
          </p>
          <FormControl component="fieldset">
            <FormLabel className={classes.text} component="legend">Light Source</FormLabel>
            <RadioGroup aria-label="light_source" name="light_source" value={light_source} onChange={this.handleChange}>
              <FormControlLabel
                value="natural direct sunlight"
                control={<Radio classes={{ root: classes.radio }} />}
                label="Natural (direct sunlight)"
                classes={{ root: classes.formControlLabelRoot, label: classes.radioLabel }}
              />
              <FormControlLabel
                value="natural indirect"
                control={<Radio classes={{ root: classes.radio }} />}
                label="Natural (indirect sunlight/shade)"
                classes={{ root: classes.formControlLabelRoot, label: classes.radioLabel }}
              />
              <FormControlLabel
                value="led bulb"
                control={<Radio classes={{ root: classes.radio }} />}
                label="LED bulb"
                classes={{ root: classes.formControlLabelRoot, label: classes.radioLabel }}
              />
              <FormControlLabel
                value="fluorescent bulb"
                control={<Radio classes={{ root: classes.radio }} />}
                label="Fluorescent bulb"
                classes={{ root: classes.formControlLabelRoot, label: classes.radioLabel }}
              />
            </RadioGroup>
          </FormControl>
        </div>

        <div className={classes.titleGroup}>
          <FormControl component="fieldset">
            <FormLabel className={classes.text} component="legend">Direction</FormLabel>
            <RadioGroup aria-label="light_direction" name="light_direction" value={light_direction} onChange={this.handleChangeDirection}>
              <FormControlLabel
                value="front"
                control={<Radio classes={{ root: classes.radio }} />}
                label="In Front"
                classes={{ root: classes.formControlLabelRoot, label: classes.radioLabel }}
              />
              <FormControlLabel
                value="behind"
                control={<Radio classes={{ root: classes.radio }} />}
                label="Behind"
                classes={{ root: classes.formControlLabelRoot, label: classes.radioLabel }}
              />
              <FormControlLabel
                value="above"
                control={<Radio classes={{ root: classes.radio }} />}
                label="Above"
                classes={{ root: classes.formControlLabelRoot, label: classes.radioLabel }}
              />
              <FormControlLabel
                value="other"
                control={<Radio classes={{ root: classes.radio }} />}
                label="Other"
                classes={{ root: classes.formControlLabelRoot, label: classes.radioLabel }}
              />
            </RadioGroup>
            {light_direction === 'other' ? (
              <Input
                type="text"
                value={light_direction_other}
                name="other"
                placeholder="tap here to enter"
                onChange={this.handleChangeDirectionOther}
                inputProps={{
                  'aria-label': 'other',
                }}
              />
            ) : ''}
          </FormControl>
        </div>

        <div className={classes.titleGroup}>
          <FormControl component="fieldset">
            <FormLabel className={classes.text} component="legend">Coverage</FormLabel>
            <RadioGroup aria-label="light_coverage" name="light_coverage" value={light_coverage} onChange={this.handleChangeCoverage}>
              <FormControlLabel
                value="uniform"
                control={<Radio classes={{ root: classes.radio }} />}
                label="Uniform"
                classes={{ root: classes.formControlLabelRoot, label: classes.radioLabel }}
              />
              <FormControlLabel
                value="non-uniform"
                control={<Radio classes={{ root: classes.radio }} />}
                label="Non-uniform"
                classes={{ root: classes.formControlLabelRoot, label: classes.radioLabel }}
              />
            </RadioGroup>
          </FormControl>
        </div>

        <div className={classes.nextBtn}>
          <Continue
            disabled={(light_coverage === null) || (light_source === null) || (light_direction === null)}
            text="Next"
            onClick={this.handleContinue}
          />
        </div>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { proForms: { contactSpo2DataCollection } } = state;
  return { contactSpo2DataCollection };
}

ContactSpo2DataCollection12.proptypes = {
  classes: PropTypes.object.isRequired,
  updatePRO: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { updatePRO })(withStyles(styles)(ContactSpo2DataCollection12));
