import React from 'react';

import Page from './page';
import { colors } from '../lib/styles';
import AppBar from '../components/app-bar';
import Continue from '../components/continue';

const styles = {
  instructions: {
    fontSize: '1.3rem',
  },
  image: {
    height: 120,
    padding: '15px 60px',
    textAlign: 'center',
  },
  linearProgress: {
    borderRadius: 0,
  },
  pageIndicator: {
    color: 'grey',
    display: 'inline-block',
    fontSize: 13,
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    position: 'relative',
    minHeight: '350px',
  },
  title: {
    color: 'black',
    fontSize: '1.5rem',
    fontWeight: 800,
    textTransform: 'uppercase',
    alignSelf: 'center',
  },
  date: {
    width: '150px',
    alignSelf: 'center',
    margin: '20px',
  },
  titleGroup: {
    margin: 25,
    textAlign: 'center',
  },
  error: {
    color: colors.errorRed,
    alignSelf: 'center',
  },
  text: {
    fontSize: '1.25rem',
  },
  mindsetLogo: {
    width: 350,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: 100,
    marginTop: 20,
  },
  consentHeader: {
    fontSize: '2rem',
    fontWeight: 'bold',
    marginLeft: 20,
    marginRight: 20,
    marginTop: 50,
  },
  consentText: {
    fontSize: '1.5rem',
    marginRight: 20,
  },
  agreementText: {
    fontSize: '1.5rem',
    marginLeft: 20,
    marginRight: 20,
  },
};

class VideoVitals1 extends Page {
  render() {
    return (
      <div>
        <AppBar
          backButtonOnClick={() => this.props.router.goBack()}
          headerNode="Virtual Consultation Informed Consent"
        />
        <div style={styles.contentContainer}>
          <img src="/img/mindset/informed.svg" style={styles.mindsetLogo} alt="" />
          <div style={styles.agreementText}>
            By clicking accept, I acknowledge and agree that I have fully read the Virtual Healthcare Informed Consent and agree to have my vital signs recorded and sent to my provider.
          </div>
          <Continue
            text="Agree"
            onClick={() => {
              this.forward();
            }}
            btnStyle={{ marginTop: '50px', alignSelf: 'center' }}
          />
          <div style={styles.consentHeader}>Consent to Record Vital Signs</div>
          <ul style={styles.consentText}>
            <li>The transmission of your health information may experience technical failure and result in the loss of information obtained.</li>
            <li>I hold harmless my provider and all parties involved in the recording my vital signs against any liability, damages, loss, attorney fees and costs of any type due to the loss of information due to a technical failure.</li>
            <li>If you’re not feeling well, you should talk to your provider. Symptoms such as a rapid, pounding, or fluttering heartbeat, dizziness, or fainting, can indicate a serious condition.</li>
            <li>I authorize the use of my information obtained to be used for treatment, payment, and healthcare operations.</li>
            <li>I understand that I will not have contact with the provider while my vital signs are recorded.</li>
            <li>I have the right to withhold, withdraw my consent or refuse to have my vital signs recorded at any time.</li>
            <li>I understand that my withdrawal of consent will not affect any future care or treatment, nor will it subject me to the risk of loss or withdrawal of any health benefits to which I am otherwise receiving.</li>
            <li>I have the right to inspect all information obtained and recorded and may receive copies of this information upon written request.</li>
            <li>No video or personal image is transmitted to your provider as part of recording your vital signs.</li>
            <li>My personal health information may be shared through electronic communication with other medical providers involved in my treatment.</li>
            <li>A copy of this consent will become a part of my medical record.</li>
          </ul>
        </div>
      </div>
    );
  }
}

export default VideoVitals1;
