import React from 'react';
import { uniqueId, get, omit } from 'lodash';
import { browserHistory } from 'react-router';
import { makeActionCreator, promiseHandler, reset, resetReducer } from 'cooldux';
import Joi from 'joi-browser';
import { NoEulaError, NotPatientLoginError } from '../lib/errors';
import { apiFetch } from '../lib/fetch';
import validator from '../lib/validator';
import { logEvent, setUser } from '../lib/amplitude';
import config from '../config';
import { cordovaOpen } from '../lib/cordova-helpers';

import { subscribeToUser, subscribeWithToken } from '../websocket';
import i18n from '../i18n';
import { setErrorScreenData } from './error-screens';

const ADD_REQUESTING_CLINIC_NUMBER = 'ADD_REQUESTING_CLINIC_NUMBER';
export const addRequestingClinicNumber = (clinicNumber) => {
  return {
    payload: clinicNumber,
    type: ADD_REQUESTING_CLINIC_NUMBER,
  };
};

const ADD_REQUESTING_CLINIC_ID = 'ADD_REQUESTING_CLINIC_ID';
export const addRequestingClinicId = (clinicId) => {
  return {
    payload: clinicId,
    type: ADD_REQUESTING_CLINIC_ID,
  };
};

const loginOpts = {
  throwErrors: true,
  namespace: 'user',
};

const signupOpts = {
  throwErrors: true,
  namespace: 'user',
};

const { loginStart, loginEnd, loginError, loginHandler } = promiseHandler('login', loginOpts);
const { logoutStart, logoutEnd, logoutError, logoutHandler } = promiseHandler('logout', 'user');
const { qrHandler, qrReducer, qrInitialState } = promiseHandler('qr', 'user');
const { infoHandler, infoInitialState, infoReducer } = promiseHandler('info', 'user');
const {
  editEnd,
  editHandler,
  editInitialState,
  editReducer,
} = promiseHandler('edit', 'user');
const { verifyStart, verifyEnd, verifyError, verifyHandler } = promiseHandler('verify', 'user');
const { signupStart, signupEnd, signupError, signupHandler } = promiseHandler('signup', signupOpts);
const {
  addPictureStart,
  addPictureEnd,
  addPictureError,
  addPictureHandler,
} = promiseHandler('addPicture', 'user');

const {
  avatarUrlHandler,
  avatarUrlReducer,
  avatarUrlInitialState,
} = promiseHandler('avatarUrl', 'user');

const requestOptions = {
  credentials: 'include',
  method: 'GET',
};

export const saveUser = makeActionCreator('saveUser', 'user');
export const userSchema = {
  username: Joi.string(),
  email: Joi.string().email().required(),
  password: Joi.string()
    .min(8)
    .regex(/[A-Z]/)
    .regex(/\d/)
    .required()
    .error(new Error('Password needs at least 8 Characters, 1 Uppercase & 1 Number')),
  confirmPassword: Joi.string()
    .min(8)
    .regex(/[A-Z]/)
    .regex(/\d/)
    .required()
    .error(new Error('Password needs at least 8 Characters, 1 Uppercase & 1 Number')),
};

const signupSchema = {
  ...userSchema,
  first_name: Joi.string().required(),
  last_name: Joi.string().required(),
  source_id: Joi.number().required(),
  signup_token: Joi.string().uuid().allow(null),
  primary_language: Joi.string().required(),
};

export function fetchUserReport() {
  return (dispatch, getState) => {
    const selfId = get(getState(), 'user.id', null);
    apiFetch(`/users/${selfId}/form_token`, requestOptions)
      .then(({ token }) => {
        const reportUrl = `${config.API_URL}/users/${selfId}/tokens/${token}/forms.pdf`;
        return cordovaOpen(reportUrl);
      });
  };
}

const defaultVerifyOptions = {
  allowedRoles: ['PATIENT'],
};

function verifyRoles(user, allowedRoles) {
  const roleIntersection = allowedRoles.filter(role => user.roles.includes(role)); 
  if(!roleIntersection.length) {
    throw new Error('User does not have permission to access this route');
  }

  return user;
}

export function verifyUser(opts = defaultVerifyOptions) {
  return (dispatch, getState) => {
    const { user } = getState();
    if (user && user.id) {
      if (!user.terms_and_conditions) {
        return Promise.reject(new NoEulaError());
      }
      return Promise.resolve(user);
    }
    const promise = apiFetch('/users/me', requestOptions)
      .then((res) => verifyRoles(res, opts.allowedRoles))
      .then((res) => {
        i18n.changeLanguage(res.primary_language);
        dispatch(reset());
        setUser(res.id);
        dispatch(loginEnd(res));
        if (!res.terms_and_conditions) {
          throw new NoEulaError();
        }
        return res;
      })
      .catch((err) => {
        if (!(err instanceof NoEulaError)) {
          // If the error isn't a eula problem clear their user data
          dispatch(reset());
        }
        throw err;
      });

    verifyHandler(promise, dispatch);
    return promise;
  };
}

export function attemptVerifyUser() {
  return (dispatch) => {
    try {
      return dispatch(verifyUser({ allowedRoles: ['PATIENT', 'SHADOW_PATIENT'] }));
    } catch (err) {
      return Promise.resolve();
    }
  }
}

export function updateAvatarUrl() {
  return (dispatch, getState) => {
    const selfId = get(getState(), 'user.id', null);
    const imgUrl = `${config.API_URL}/users/${selfId}/picture/100?cache_id=${uniqueId('avatar')}`;
    return avatarUrlHandler(Promise.resolve(imgUrl), dispatch);
  };
}

export function loginShadow(notificationId, dob) {
  return (dispatch, getState) => {
    const options = {
      ...requestOptions,
      method: 'POST',
      body: { dob },
    };

    const promise = apiFetch(`/notifications/${notificationId}/auth`, options)
      .then((res) => {
        const { notificationAuthLogoutRoute } = getState().user;
        dispatch(reset());
        setUser(res.id);
        i18n.changeLanguage(res.primary_language);
        return {
          ...res,
          dob,
          name: '',
          requesting_clinic_name: res.name,
          requesting_clinic_id: res.clinic_id,
          notificationAuthLogoutRoute,
        };
      });

    return loginHandler(promise, dispatch);
  };
}

export function login(username, password) {
  const payload = { password };
  if (username.includes('@')) {
    payload.email = username;
  } else {
    payload.username = username;
  }
  return (dispatch) => {
    const options = {
      ...requestOptions,
      method: 'POST',
      body: JSON.stringify(payload),
    };

    const promise = apiFetch('/auth', options)
      .then((res) => {
        if (!res.roles.includes('PATIENT')) {
          throw new NotPatientLoginError();
        }
        i18n.changeLanguage(res.primary_language);
        dispatch(reset());
        setUser(res.id);
        logEvent('Patient Login');
        return res;
      })
      .catch((err) => {
        if (err instanceof NotPatientLoginError) throw err;
        throw new Error(`Login Failed status ${err.status}`);
      });

    return loginHandler(promise, dispatch);
  };
}

export function logout(redirectToTimeout = false) {
  return (dispatch, getState) => {
    const promise = apiFetch('/logout', requestOptions)
      .then(() => {
        const { notificationAuthLogoutRoute } = getState().user;
        dispatch(reset());
        logEvent('Patient Logout');
        if (redirectToTimeout) {
          dispatch(setErrorScreenData({
            header: 'Inactivity',
            messageOne: 'Your session has timed out due to 5 minutes of inactivity.',
            messageTwo: <>Please tap <strong>RETRY.</strong></>,
            notificationAuthLogoutRoute,
          }));

          return browserHistory.push('/timeout');
        }
        return browserHistory.push('/login');
      });
    return logoutHandler(promise, dispatch);
  };
}

export function logoutWithoutRedirect() {
  return (dispatch) => {
    const promise = apiFetch('/logout', requestOptions)
      .then(() => {
        dispatch(reset());
        logEvent('Patient Logout');
      });
    return logoutHandler(promise, dispatch);
  };
}

export function signup(user) {
  return (dispatch, getState) => {
    const promise = new Promise((resolve, reject) => {
      const { user: oldUser } = getState();
      if (oldUser && oldUser.id) {
        // if gets called multiple times because of personal_info call
        return resolve(oldUser);
      }
      const valid = validator(user, signupSchema);
      if (valid.error) {
        return reject(valid.error);
      }
      if (user.password !== user.confirmPassword) {
        return reject(new Error('passwords don\'t match'));
      }
      user.terms_and_conditions = true;
      user.source_id = 1; // for Informed
      const data = apiFetch('/signup', { ...requestOptions, body: omit(user, 'confirmPassword'), method: 'POST' })
        .then((res) => {
          res.email = user.email;
          return res;
        });
      return resolve(data);
    });
    logEvent('Patient Sign Up');
    return signupHandler(promise, dispatch);
  };
}

export function generateQR(user_id) {
  return (dispatch) => {
    const options = { ...requestOptions, method: 'POST' };
    const promise = apiFetch(`/users/${user_id}/qrcode`, options);
    return qrHandler(promise, dispatch);
  };
}

export function editUser(editedUser) {
  return (dispatch, getState) => {
    const selfId = get(getState(), 'user.id', null);
    const options = { ...requestOptions, method: 'PUT', body: JSON.stringify(editedUser) };
    const promise = apiFetch(`/users/${selfId}`, options);
    return editHandler(promise, dispatch);
  };
}

export function fetchUserInfo(user_id) {
  return (dispatch) => {
    const promise = apiFetch(`/users/${user_id}/personal_info`, requestOptions);
    return infoHandler(promise, dispatch);
  };
}

export function addPhoto(img) {
  return function dispatcher(dispatch, getState) {
    logEvent('Update Photo');
    const selfId = get(getState(), 'user.id', null);
    const promise = window.fetch(img)
      .then(res => res.blob())
      .then((blob) => {
        const formData = new FormData();
        formData.append('picture', blob);
        const options = {
          method: 'POST',
          body: formData,
        };
        return apiFetch(`/users/${selfId}/picture`, options);
      })
      .then(({ picture }) => {
        return { picture };
      });
    return addPictureHandler(promise, dispatch)
      .then(() => {
        const imgUrl = `${config.API_URL}/users/${selfId}/picture/100?cache_id=${uniqueId('avatar')}`;
        return avatarUrlHandler(Promise.resolve(imgUrl), dispatch);
      });
  };
}

const SAVE_SKIP_VITAL_CORE_INSTRUCTIONS = 'SKIP_VITAL_CORE_INSTRUCTIONS';
export const saveSkipVitalCoreInstructions = (skipInstructions, userId) => {
  const options = {
    method: 'PUT',
    body: { skip_vital_core_instructions: skipInstructions },
  };

  apiFetch(`/users/${userId}/personal_info`, options);

  return {
    type: SAVE_SKIP_VITAL_CORE_INSTRUCTIONS,
    payload: skipInstructions,
  };
};

export const setNotificationAuthLogoutRoute = makeActionCreator('setNotificationAuthLogoutRoute', 'user');

export const setTrackType = makeActionCreator('setTrackType', 'user');

const initialState = {
  ...qrInitialState,
  ...infoInitialState,
  ...editInitialState,
  ...avatarUrlInitialState,
  loggedIn: false,
  loginError: null,
  isFetching: false,
  verifyPending: false,
  isFetchingInfo: false,
  isUpdatingInfo: false,
  signupPending: false,
  signupError: null,
  addPicturePending: false,
  addPictureError: null,
  requestingClinicNumber: null,
  requesting_clinic_id: null,
  clinics: [],
  skip_vital_core_instructions: false,
  notificationAuthLogoutRoute: '',
  trackType: '',
};

const user = resetReducer(initialState, (state = initialState, { type, payload }) => {
  state = qrReducer(state, { type, payload });
  state = infoReducer(state, { type, payload });
  state = editReducer(state, { type, payload });
  state = avatarUrlReducer(state, { type, payload });
  switch (type) {
    case verifyStart.type:
      return { ...state, verifyPending: true };
    case verifyEnd.type:
      return { ...state, loggedIn: true, verifyPending: false, ...payload };
    case verifyError.type:
      return { ...state, verifyPending: false };
    case loginStart.type:
      return { ...state, isFetching: true };
    case loginEnd.type:
      if(payload.token) {
        subscribeWithToken(payload.token, payload.id);
      } else {
        subscribeToUser(payload.id);
      }
      return { ...state, loggedIn: true, isFetching: false, ...payload };
    case loginError.type:
      return { ...initialState, loginError: payload };
    case logoutStart.type:
      return state;
    case logoutEnd.type:
      return { ...initialState };
    case logoutError.type:
      return state;
    case editEnd.type:
      return { ...state, ...payload };
    case signupStart.type:
      return { ...state, signupError: null, signupPending: true };
    case signupEnd.type:
      return { ...state, signupPending: false, ...payload };
    case signupError.type:
      return { ...state, signupError: payload };
    case addPictureStart.type:
      return { ...state, addPictureError: null, addPicturePending: true };
    case addPictureEnd.type:
      return { ...state, addPictureError: null, addPicturePending: false, ...payload };
    case addPictureError.type:
      return { ...state, addPictureError: payload, addPicturePending: false };
    case saveUser.type:
      return { ...state, ...payload };
    case ADD_REQUESTING_CLINIC_NUMBER:
      return { ...state, requestingClinicNumber: payload };
    case ADD_REQUESTING_CLINIC_ID:
      return { ...state, requesting_clinic_id: payload };
    case SAVE_SKIP_VITAL_CORE_INSTRUCTIONS:
      return { ...state, skip_vital_core_instructions: payload };
    case setNotificationAuthLogoutRoute.type:
      return { ...state, notificationAuthLogoutRoute: payload };
    case setTrackType.type:
      return { ...state, trackType: payload };
    default:
      return state;
  }
});

export default user;
