const en = {
  notificationIntro: {
    buttonLabel: 'Start',
  },
};

const es = {
  notificationIntro: {
    buttonLabel: 'Comienzo',
  },
};

export default { en, es };
