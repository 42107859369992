import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get, includes } from 'lodash';

import { colors } from '../lib/styles';
import { SleepDisturbance } from '../lib/icons';
import Page from './page';
import ButtonPRO from '../components/button-pro';
import { updatePRO } from '../state/pro-forms';

const buttonLabels = ['Not at all', 'A little bit', 'Somewhat', 'Quite a bit', 'Very Much'];
const sleepQualityLabels = ['Very poor', 'poor', 'fair', 'good', 'very good'];

// The first and last questions of sleep disturbance are rated Bad to Good unlike all other promis29
// questions. This was a UI design decision so we reverse the values of those selectboxes
// rather than creating custom api logic that would be tied to a particular implemention
// of this PRO
const options = [
  {
    question: 'My sleep was refreshing',
    label: buttonLabels,
    reverseValues: true,
  },
  {
    question: 'I had a problem with my sleep',
    label: buttonLabels,
  },
  {
    question: 'I had difficulty falling asleep',
    label: buttonLabels,
  },
  {
    question: 'My sleep quality was',
    label: sleepQualityLabels,
    reverseValues: true,
  },
];
const { questionBackground } = colors;

class Promis29SleepDisturbance extends Page {
  onClickBackButton = () => {
    this.backward();
  }

  onSubmission = () => {
    this.forwardWithQuery(this.props.location.query);
  }

  render() {
    const page = get(this.props.promis29, this.getTrackIndex(), [null, null, null, null]);
    const value = includes(page, null) ? null : 0;
    return (
      <ButtonPRO
        backgroundColor={questionBackground}
        currentPage={this.getCurrentPage()}
        image={SleepDisturbance}
        onClickBackButton={() => this.props.router.goBack()}
        onChange={this.props.updatePRO}
        onSubmission={this.onSubmission}
        options={options}
        title="In the past 7 days:"
        header="Sleep Disturbance"
        totalPages={this.getTotalPages()}
        trackIndex={this.getTrackIndex()}
        value={value}
        pro={page}
        type="promis29"
      />
    );
  }
}


function mapStateToProps(state) {
  const { proForms: { promis29 } } = state;
  return { promis29 };
}

Promis29SleepDisturbance.propTypes = {
  promis29: PropTypes.array.isRequired,
  updatePRO: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { updatePRO })(Promis29SleepDisturbance);
