import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { colors } from '../lib/styles';

const {
  primaryColor,
  painSelectionGrey,
  painSelectionHeaderBackground,
  white,
} = colors;

const styles = {
  circle: {
    alignItems: 'center',
    background: painSelectionGrey,
    borderRadius: '50%',
    display: 'flex',
    height: '8rem',
    justifyContent: 'center',
    width: '8rem',
  },
  icon: {
    height: '5.6rem',
    width: '5.6rem',
  },
  label: {
    color: painSelectionGrey,
    fontSize: '1.5rem',
    fontWeight: 'bold',
    marginTop: '2rem',
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  rootContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
};

class PainTypeEntryOption extends Component {
  render() {
    const {
      changeBackground,
      icon: Icon,
      label,
      onClick,
      selected,
    } = this.props;

    const circleStyles = selected ? { ...styles.circle, background: primaryColor } : styles.circle;
    const iconColor = selected ? white : painSelectionHeaderBackground;
    const labelStyles = selected ? { ...styles.label, color: primaryColor } : styles.label;

    let svgBackground;
    if (changeBackground) {
      svgBackground = selected ? primaryColor : painSelectionGrey;
    }

    return (
      <div
        onClick={onClick}
        tabIndex={0}
        onKeyPress={onClick}
        role="button"
        style={styles.rootContainer}
      >
        <div style={circleStyles}>
          <div style={styles.icon}>
            {changeBackground
              ? <Icon background={svgBackground} color={iconColor} />
              : <Icon color={iconColor} />
            }
          </div>
        </div>
        <div style={labelStyles}>
          {label}
        </div>
      </div>
    );
  }
}

PainTypeEntryOption.defaultProps = {
  changeBackground: false,
  selected: false,
};

PainTypeEntryOption.propTypes = {
  changeBackground: PropTypes.bool,
  icon: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  selected: PropTypes.bool,
};

export default PainTypeEntryOption;
