import { FormControl, Input, InputAdornment } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import { WeightScale } from '../lib/icons';

import Page from './page';
import AppBar from '../components/app-bar';
import Continue from '../components/continue';

import { submitPRO } from '../state/pro-forms';

const styles = {
  root: {
    padding: '25px',
    textAlign: 'left',
    width: '100%',
  },
  height: {
    display: 'inline-flex',
    justifyContent: 'space-between',
  },
  heightItem: {
    fontSize: '22px',
    textAlign: 'center',
    margin: '1rem',
  },
  questionnaire: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    position: 'relative',
    minHeight: '350px',
  },
  title: {
    color: 'black',
    fontSize: '1.5rem',
    fontWeight: 400,
    alignSelf: 'center',
  },
  titleGroup: {
    margin: 25,
    textAlign: 'center',
  },
  weight: {
    textAlign: 'center',
  },
  wrapper: {
    width: '80%',
    overflowX: 'hidden',
    position: 'relative',
    padding: '20px',
  },
};

const StyledInput = withStyles(() => ({
  input: {
    textAlign: 'center',
  },
}))(Input);

class BMI extends Page {
  constructor(props) {
    super(props);
    this.state = {
      feet: '',
      inches: '',
      weight: '',
    };

    this.handleComplete = this.handleComplete.bind(this);
  }

  handleChange = (e) => {
    this.setState({
      ...this.state,
      [e.target.name]: e.target.value,
    });
  };

  isContinueDisabled = () => {
    const { weight, feet, inches, submitting } = this.state;

    if (!weight || !feet || !inches) {
      return true;
    }

    if (submitting) {
      return true;
    }

    return false;
  };

  handleComplete() {
    const { submitting } = this.state;
    if (submitting) return;
    this.setState({ submitting: true });

    const { submitPRO, user } = this.props;
    const { id } = this.props.location.query;

    submitPRO(
      { userId: user.id, id },
      {
        pro_type: 'BMI',
        pro_data: { data: this.state },
      },
      user
    )
      .then(() => this.forward())
      .catch((e) => this.setState({ error: e.message, submitting: false }));
  }

  render() {
    const { feet, inches, weight } = this.state;

    return (
      <div>
        <AppBar headerNode="BMI" noLeftNode={true} />
        <div style={styles.questionnaire}>
          <div style={styles.titleGroup}>
            <p style={styles.title}>What is your current height and weight?</p>
            <div style={{ width: '100px', margin: 'auto' }}>
              <WeightScale />
            </div>
          </div>
          <div style={styles.wrapper}>
            <Typography>Weight</Typography>
            <div style={styles.weight}>
              <FormControl style={styles.heightItem}>
                <StyledInput
                  id="weight-adornment"
                  type="tel"
                  value={weight}
                  name="weight"
                  placeholder="000"
                  onChange={this.handleChange}
                  inputProps={{
                    'aria-label': 'weight',
                  }}
                />
              </FormControl>
              <Typography>lbs</Typography>
            </div>

            <Typography>Height</Typography>
            <div style={styles.height}>
              <FormControl style={styles.heightItem}>
                <StyledInput
                  id="feet-adornment"
                  type="tel"
                  value={feet}
                  name="feet"
                  placeholder="00"
                  onChange={this.handleChange}
                  endAdornment={
                    <InputAdornment position="end">ft</InputAdornment>
                  }
                  aria-describedby="feet-helper-text"
                  inputProps={{
                    'aria-label': 'feet',
                  }}
                />
              </FormControl>
              <FormControl style={styles.heightItem}>
                <StyledInput
                  id="inches-adornment"
                  type="tel"
                  value={inches}
                  name="inches"
                  placeholder="00"
                  onChange={this.handleChange}
                  endAdornment={
                    <InputAdornment position="end">in</InputAdornment>
                  }
                  aria-describedby="inches-helper-text"
                  inputProps={{
                    'aria-label': 'inches',
                  }}
                />
              </FormControl>
            </div>
          </div>
          <Continue
            text="Submit"
            disabled={this.isContinueDisabled()}
            onClick={this.handleComplete}
            btnStyle={{ marginTop: '50px', alignSelf: 'center' }}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state;
  return { user };
}

BMI.propTypes = {
  user: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  forwardWithQuery: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { submitPRO })(BMI);
