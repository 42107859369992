import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import { colors, fontSizing } from '../lib/styles';
import { CircleExclamationSolid } from '../lib/icons';
import Continue from './continue';

const styles = {
  continueButtonWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
  exclamationWrapper: {
    marginTop: 25,
    marginBottom: 5,
    width: '8rem',
    height: '8rem',
    display: 'inline-block',
    marginRight: 10,
    '& path': {
      fill: colors.errorRed,
    },
  },
  header: {
    fontSize: fontSizing.h3,
    fontWeight: 'bold',
  },
  iconWrapper: {
    marginTop: 25,
    marginBottom: 5,
    width: '8rem',
    height: '8rem',
    display: 'inline-block',
    marginRight: 10,
  },
  page: {
    background: colors.questionBackground,
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 700,
    marginLeft: 'auto',
    marginRight: 'auto',
    minHeight: '100%',
    paddingTop: 100,
    textAlign: 'center',
  },
  pageContent: {
    flexGrow: 1,
    fontSize: fontSizing.body,
    paddingLeft: 10,
    paddingRight: 10,
  },
};

class IvcTelemedicineTimeout extends React.Component {
  render() {
    const {
      classes,
      header,
      hideRetry,
      icon,
      messageOne,
      messageTwo,
      onRetry,
    } = this.props;

    return (
      <div className={classes.page}>
        <div className={classes.pageContent}>
          {icon ? (
            <div className={classes.iconWrapper}>
              {icon}
            </div>
          ) : (
            <div className={classes.exclamationWrapper}>
              <CircleExclamationSolid />
            </div>
          )}
          <p><strong>{header}</strong></p>
          <p>{messageOne}</p>
          <p>{messageTwo}</p>
        </div>
        {!hideRetry ? (
          <div className={classes.continueButtonWrapper}>
            <Continue
              text="Retry"
              onClick={onRetry}
            />
          </div>
        ) : null}
      </div>
    );
  }
}

IvcTelemedicineTimeout.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(IvcTelemedicineTimeout);
