import React from 'react';
import PropTypes from 'prop-types';
import { yesLabel, noLabel } from '../lib/i18next';


import { colors, fontSizing } from '../lib/styles';

const styles = {
  answerButtonContainer: {
    display: 'flex',
    marginLeft: -10,
    marginTop: 8,
  },
  button: {
    border: 'none',
    borderRadius: 5,
    flexGrow: 1,
    marginLeft: 10,
    paddingBottom: 15,
    paddingTop: 15,
  },
  selected: {
    background: colors.primaryColor,
    color: 'white',
  },
  questionText: {
    marginTop: 25,
  },
  questionSubText: {
    display: 'block',
    marginTop: 5,
  },
  unselected: {
    background: '#d8d8d8',
    color: 'black',
  },
};

const YesNoButtonSet = (props) => {
  const {
    isNo,
    isYes,
    labelForNo,
    labelForYes,
    onClickNo,
    onClickYes,
    questionText,
    questionTextSize,
    questionSubText,
    questionSubTextSize,
  } = props;

  const yesButtonStyle = isYes
    ? { ...styles.button, ...styles.selected, fontSize: questionTextSize }
    : { ...styles.button, ...styles.unselected, fontSize: questionTextSize };

  const noButtonStyle = isNo
    ? { ...styles.button, ...styles.selected, fontSize: questionTextSize }
    : { ...styles.button, ...styles.unselected, fontSize: questionTextSize };

  const questionSubTextStyles = { ...styles.questionSubText, fontSize: questionSubTextSize };
  const questionTextStyles = { ...styles.questionText, fontSize: questionTextSize };

  return (
    <div>
      <div style={questionTextStyles}>{questionText}</div>
      {questionSubText ? (
        <i style={questionSubTextStyles}>{questionSubText}</i>
      ) : null}
      <div style={styles.answerButtonContainer}>
        <button
          onClick={onClickYes}
          style={yesButtonStyle}
          type="button"
        >
          {labelForYes || yesLabel()}
        </button>
        <button
          onClick={onClickNo}
          style={noButtonStyle}
          type="button"
        >
          {labelForNo || noLabel()}
        </button>
      </div>
    </div>
  );
};

YesNoButtonSet.defaultProps = {
  isNo: null,
  isYes: null,
  labelForNo: null,
  labelForYes: null,
  questionSubText: '',
  questionSubTextSize: fontSizing.smallX,
  questionTextSize: fontSizing.body,
};

YesNoButtonSet.propTypes = {
  isNo: PropTypes.bool,
  isYes: PropTypes.bool,
  labelForNo: PropTypes.string,
  labelForYes: PropTypes.string,
  onClickNo: PropTypes.func.isRequired,
  onClickYes: PropTypes.func.isRequired,
  questionText: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
  ]).isRequired,
  questionTextSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  questionSubText: PropTypes.string,
  questionSubTextSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default YesNoButtonSet;
