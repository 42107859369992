import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Button, LinearProgress } from '@material-ui/core';
import Page from './page';
import i18nTranslator, { title } from '../lib/i18next';
import AppBar from '../components/app-bar';
import { submitPRO, updatePRO } from '../state/pro-forms';

const styles = {
  button: {
    margin: '5px',
    fontSize: 29,
    flexGrow: 1,
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
  linearProgress: {
    borderRadius: 0,
  },
  titleGroup: {
    margin: 25,
    textAlign: 'center',
  },
  text: {
    fontSize: 29,
  },
};

class PatientDemographics1 extends Page {
  handleAgree = () => {
    this.props.updatePRO({ type: 'patientDemographics', position: this.getTrackIndex(), value: { agree: true } });
    this.forwardWithQuery(this.props.location.query);
  }

  handleDisagree = () => {
    const { id } = this.props.location.query;
    const { user } = this.props;

    this.props.submitPRO(
      { userId: user.id, id },
      {
        pro_type: 'PATIENT-DEMOGRAPHICS',
        pro_data: { data: [false] },
      },
      user,
    );
    this.finishTrack();
  }

  render() {
    return (
      <Fragment>
        <AppBar
          headerNode={title('demographics1')}
          noLeftNode={true}
        />
        <LinearProgress
          variant="determinate"
          value={(this.getCurrentPage() / this.getTotalPages()) * 100}
        />
        <div style={styles.titleGroup}>
          <p style={styles.text}>{i18nTranslator('disclaimer', 'demographics1')}</p>
          <div style={styles.buttonWrapper}>
            <Button style={styles.button} variant="contained" color="default" onClick={this.handleAgree}>{i18nTranslator('agree', 'demographics1')}</Button>
            <Button style={styles.button} variant="contained" color="default" onClick={this.handleDisagree}>{i18nTranslator('disagree', 'demographics1')}</Button>
          </div>
        </div>
      </Fragment>
    );
  }
}


function mapStateToProps(state) {
  const { user } = state;
  return { user };
}

PatientDemographics1.proptypes = {
  user: PropTypes.object.isRequired,
  route: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, { submitPRO, updatePRO })(PatientDemographics1);
