import React from 'react';
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  LinearProgress,
} from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import AppBar from '../components/app-bar';
import Page from './page';
import QuestionLayout from '../layouts/question';
import YesNoButtonSet from '../components/yes-no-button-set';
import { colors } from '../lib/styles';
import { updatePRO } from '../state/pro-forms';
import { Smoking } from '../lib/icons';

const styles = {
  contentWrapper: {
    flexGrow: 1,
    overflowX: 'hidden',
    position: 'relative',
  },
  imgRow: {
    display: 'flex',
    justifyContent: 'center',
    padding: '15px 60px',
  },
  imageWrapper: {
    width: 100,
  },
  marginTop40: {
    marginTop: 40,
  },
  questionnaireContainer: {
    paddingLeft: 20,
    paddingRight: 20,
  },
  questionText: {
    fontSize: '2rem',
  },
};

class HeartFailure12 extends Page {
  constructor(props) {
    super(props);

    this.state = {
      cigars: false,
      cigs: false,
      eCigs: false,
      smokes: null,
    };
  }

  handleChangeSmokes = (value) => {
    this.setState({ smokes: value });
  };

  handleChangeSmokingType = (e) => {
    this.setState({ [e.target.name]: e.target.checked });
  };

  handleClickNext = () => {
    const {
      cigars,
      cigs,
      eCigs,
      smokes,
    } = this.state;

    const smokingTypes = [];

    if (smokes === 'Y') {
      if (cigars) smokingTypes.push('Cigars');
      if (cigs) smokingTypes.push('Cigarettes');
      if (eCigs) smokingTypes.push('E-cigarettes');
    }

    const value = { smokes, smokingTypes };

    this.props.updatePRO({
      type: 'heartFailureInitial',
      position: this.getTrackIndex(),
      value,
    });

    this.forwardWithQuery(this.props.location.query);
  }

  render() {
    const { classes } = this.props;
    const {
      cigars,
      cigs,
      eCigs,
      smokes,
    } = this.state;

    const continueDisabled = smokes === null;

    return (
      <QuestionLayout
        primaryBtnLabel="Next"
        primaryBtnOnTouchTap={this.handleClickNext}
        primaryBtnDisabled={continueDisabled}
        style={{ backgroundColor: colors.questionBackground }}
      >
        <AppBar
          headerNode="Smoking"
          backButtonOnClick={() => this.props.router.goBack()}
        />
        <LinearProgress
          variant="determinate"
          value={(this.getCurrentPage() / this.getTotalPages()) * 100}
          classes={{ bar: classes.bar }}
        />
        <div className={classes.contentWrapper}>
          <div className={classes.imgRow}>
            <div className={classes.imageWrapper}>
              <Smoking />
            </div>
          </div>
          <div className={classes.questionnaireContainer}>
            <YesNoButtonSet
              isYes={smokes === 'Y'}
              isNo={smokes === 'N'}
              onClickNo={() => this.handleChangeSmokes('N')}
              onClickYes={() => this.handleChangeSmokes('Y')}
              questionText="Do you smoke?"
            />
            {smokes === 'Y' ? (
              <>
                <div className={`${classes.questionText} ${classes.marginTop40}`}>What do you smoke?</div>
                <FormGroup>
                  <FormControlLabel
                    control={(
                      <Checkbox
                        checked={cigs}
                        onChange={this.handleChangeSmokingType}
                        name="cigs"
                        icon={<CheckBoxOutlineBlankIcon fontSize="large" />}
                        checkedIcon={<CheckBoxIcon fontSize="large" />}
                      />
                    )}
                    label="Cigarettes"
                    classes={{
                      label: classes.questionText,
                    }}
                  />
                  <FormControlLabel
                    control={(
                      <Checkbox
                        checked={eCigs}
                        onChange={this.handleChangeSmokingType}
                        name="eCigs"
                        icon={<CheckBoxOutlineBlankIcon fontSize="large" />}
                        checkedIcon={<CheckBoxIcon fontSize="large" />}
                      />
                    )}
                    label="E-Cigarettes"
                    classes={{
                      label: classes.questionText,
                    }}
                  />
                  <FormControlLabel
                    control={(
                      <Checkbox
                        checked={cigars}
                        onChange={this.handleChangeSmokingType}
                        name="cigars"
                        icon={<CheckBoxOutlineBlankIcon fontSize="large" />}
                        checkedIcon={<CheckBoxIcon fontSize="large" />}
                      />
                    )}
                    label="Cigars"
                    classes={{
                      label: classes.questionText,
                    }}
                  />
                </FormGroup>
              </>
            ) : null}
          </div>
        </div>
      </QuestionLayout>
    );
  }
}

export default connect(null, { updatePRO })(withStyles(styles)(HeartFailure12));
