import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { startCase, toLower } from 'lodash';

import { painLogDetailsColors } from '../lib/styles';

const {
  greyBorder,
  greyText,
} = painLogDetailsColors;

const styles = {
  container: {
    borderBottom: `1px solid ${greyBorder}`,
    paddingBottom: '3.635rem',
    paddingLeft: '2rem',
    paddingTop: '3.625rem',
  },
  subtitle: {
    color: greyText,
    fontSize: '1.75rem',
  },
  itemText: {
    color: greyText,
    fontSize: '1.75rem',
  },
  title: {
    fontSize: '1.875rem',
    fontWeight: 'bold',
    marginBottom: '0.5rem',
  },
};

const getLocations = (locations) => {
  const locationsArray = [];

  locations.forEach((location) => {
    const locationStr = startCase(toLower(location.name));
    locationsArray.push(<div key={`${locationStr}-label`} style={styles.itemText}>{locationStr}</div>);
  });

  return locationsArray;
};

const getSelections = (selections) => {
  const selectionsArray = [];

  selections.forEach((selection) => {
    const selectionStr = startCase(toLower(selection));
    selectionsArray.push(<div key={selection} style={styles.itemText}>{selectionStr}</div>);
  });

  return selectionsArray;
};

class PainLogDetailsSectionLabel extends Component {
  render() {
    const {
      background,
      locations,
      selections,
      title,
    } = this.props;
    let subtitle;

    return (
      <div style={{ ...styles.container, background }}>
        <div style={styles.title}>{title}</div>
        <div style={styles.subtitle}>{subtitle}</div>
        <div>
          {!!selections && getSelections(selections)}
          {!!locations && getLocations(locations)}
          {(!selections && !locations)
            && <div>No selections</div>
          }
        </div>
      </div>
    );
  }
}

PainLogDetailsSectionLabel.defaultProps = {
  background: 'lightgrey',
  locations: undefined,
  selections: undefined,
};

PainLogDetailsSectionLabel.propTypes = {
  background: PropTypes.string,
  locations: PropTypes.array,
  selections: PropTypes.array,
  title: PropTypes.string.isRequired,
};

export default PainLogDetailsSectionLabel;
