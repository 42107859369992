import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { capitalize, get, map } from 'lodash';
import {
  List,
  ListItem,
  ListItemText,
  ListSubheader,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { colors } from '../lib/styles';

const baseStyles = {
  itemStyles: {
    color: colors.primaryColor,
    fontSize: '1.5rem',
    textAlign: 'left',
  },
  listSubheader: {
    fontSize: '1.5rem',
  },
  secondaryText: {
    color: colors.black,
  },
};

class ProviderRow extends Component {
  onSelectProvider = () => {
    this.props.onSelect(this.props.provider);
  }

  render() {
    const { props } = this;
    const prefix = capitalize(get(props, 'provider.basic.name_prefix', ''));
    const first = capitalize(get(props, 'provider.basic.first_name', ''));
    const last = capitalize(get(props, 'provider.basic.last_name', ''));

    const desc = capitalize(get(props, 'provider.taxonomies.0.desc', ''));
    const address = get(props, 'provider.addresses.0.address_1', '');
    const city = capitalize(get(props, 'provider.addresses.0.city', ''));
    const state = get(props, 'provider.addresses.0.state', '');

    return (
      <ListItem
        button
        onClick={this.onSelectProvider}
        style={baseStyles.itemStyles}
      >
        <ListItemText
          primary={(
            <div style={baseStyles.listItem}>
              {`${prefix} ${first} ${last}`}
            </div>
          )}
          secondary={(
            <div style={baseStyles.secondaryText}>
              {`${desc} - ${address} ${city} ${state}`}
            </div>
          )}
          disableTypography
          onClick={this.onSelectProvider}
        />
      </ListItem>
    );
  }
}

ProviderRow.propTypes = {
  onSelect: PropTypes.func.isRequired,
  provider: PropTypes.object.isRequired,
};


class ProviderTable extends Component {
  render() {
    return (
      <List>
        <ListSubheader style={baseStyles.listSubheader}>Search Results</ListSubheader>
        {map(this.props.providers, p => <ProviderRow onSelect={this.props.onSelectProvider} key={p.number} provider={p} />)}
      </List>
    );
  }
}

ProviderTable.propTypes = {
  providers: PropTypes.array.isRequired,
  onSelectProvider: PropTypes.func.isRequired,
};

export default withStyles(baseStyles)(ProviderTable);
