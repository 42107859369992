import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get, flatten, includes } from 'lodash';

import { colors } from '../lib/styles';
import { Standing } from '../lib/icons';
import Page from './page';
import ButtonPRO from '../components/button-pro';
import { updatePRO, submitPRO } from '../state/pro-forms';

const buttonLabels = ['Not at all', 'Slightly', 'Moderately', 'Very Much', 'Extremely'];

const options = [
  {
    question: 'Overall, how bothersome has your back pain been in the last 2 weeks?',
    label: buttonLabels,
  },
];
const { questionBackground } = colors;

class StartBack3 extends Page {
  state = {
    submitting: false
  }

  onClickBackButton = () => {
    this.backward();
  }

  onSubmission = () => {
    const { user } = this.props;
    const { id } = this.props.location.query;
    this.setState({ submitting: true });
    this.props.submitPRO({ userId: user.id, id }, {
      pro_type: 'START-BACK',
      pro_data: { data: flatten(this.props.startBack) },
    }, user)
      .then(() => this.forward())
      .catch(() => this.setState({ submitting: false }));
  }

  render() {
    const page = get(this.props.startBack, this.getTrackIndex(), [null]);
    const value = includes(page, null) ? null : 0;
    return (
      <ButtonPRO
        backgroundColor={questionBackground}
        currentPage={this.getCurrentPage()}
        header="Patient Health"
        image={Standing}
        onChange={this.props.updatePRO}
        onClickBackButton={() => this.props.router.goBack()}
        onSubmission={this.onSubmission}
        options={options}
        primaryBtnDisabled={this.state.submitting}
        pro={page}
        title=""
        totalPages={this.getTotalPages()}
        trackIndex={this.getTrackIndex()}
        type="startBack"
        value={value}
      />
    );
  }
}


function mapStateToProps(state) {
  const { proForms: { startBack }, user } = state;
  return { startBack, user };
}

StartBack3.propTypes = {
  startBack: PropTypes.array.isRequired,
  updatePRO: PropTypes.func.isRequired,
  submitPRO: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { submitPRO, updatePRO })(StartBack3);
