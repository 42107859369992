import React from 'react';
import PropTypes from 'prop-types';
import { get, omit } from 'lodash';
import { connect } from 'react-redux';
import {
  FormControl,
  FormHelperText,
  Divider,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import AppBar from '../components/app-bar';
import Page from './page';
import Layout from '../layouts/common';
import { colors } from '../lib/styles';
import Continue from '../components/continue';
import {
  browsePatientProviders,
  editPatientProvider,
  addPatientProvider,
  deletePatientProvider,
} from '../state/patient-provider';

const baseStyles = {
  center: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  flexStart: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  marginLeft: {
    marginLeft: '15px',
  },
  marginTop: {
    marginTop: '30px',
  },
  menuItem: {
    color: colors.primaryColor,
  },
  highlightDark: {
    color: colors.highlightDark,
  },
  physicianDetails: {
    fontSize: '10px',
    color: colors.primaryColor,
    textAlign: 'left',
    marginLeft: '15px',
  },
  redErrorText: {
    color: colors.errorRed,
    marginLeft: '15px',
    marginRight: '15px',
    textAlign: 'center',
  },
  text: {
    color: colors.primaryColor,
  },
  underline: {
    display: 'none',
  },
  whiteBackgroundSection: {
    backgroundColor: colors.white,
    textAlign: 'left',
  },
};

const classStyles = {
  providerTypeError: {
    fontSize: 14,
  },
  select: {
    fontSize: 16,
  },
  selectWrapper: {
    marginTop: 10,
  },
  textField: {
    marginBottom: 10,
    marginTop: 10,
    width: 256,
    '& input': {
      color: colors.primaryColor,
      fontSize: 16,
    },
    '& label': {
      color: colors.primaryColor,
      fontSize: 16,
    },
    '& .MuiInput-underline:before': {
      borderBottom: 'none',
    },
    '& .MuiInput-underline:before:focus': {
      borderBottom: 'none',
    },
  },
};

class Physician extends Page {
  constructor(props) {
    super(props);
    this.state = {
      physicianNameError: '',
      providerTypeError: '',
      patientProvider: {
        provider_name: '',
        physician_name: get(props, 'location.query.physician_name', ''),
        phone_number: get(props, 'location.query.phone', ''),
        fax_number: get(props, 'location.query.fax', ''),
        npi_number: get(props, 'location.query.npi_number', null),
        address: get(props, 'location.query.street', ''),
        city: get(props, 'location.query.city', ''),
        state: get(props, 'location.query.state', ''),
        zip_code: get(props, 'location.query.zip_code', ''),
        description: get(props, 'location.query.description', ''),
        provider_type: '',
        ...props.patientProvider,
      },
    };
    this.handleSave = this.handleSave.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleUpdateProviderName = this.handleTextNewMUI.bind(this, 'provider_name');
    this.handleUpdatePhysicianName = this.handleTextNewMUI.bind(this, 'physician_name');
    this.handleUpdatePhone = this.handleTextNewMUI.bind(this, 'phone_number');
    this.handleUpdateType = this.handleUpdateType.bind(this);
  }

  componentWillMount() {
    if (this.props.patientProviderId) {
      if (!this.props.patientProvider) {
        // TODO: This should be a read
        this.props.browsePatientProviders();
      }
    }
    if (this.props.location.query.physician_name !== undefined) {
      this.setState({
        patientProvider: {
          physician_name: get(this.props, 'location.query.physician_name')
        }
      });
    } else {
      this.setState({
        patientProvider: {
          physician_name: get(this.props, 'location.query.first_name') + ' ' + get(this.props, 'location.query.last_name'),
        }
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.patientProvider !== nextProps.patientProvider) {
      this.setState({
        patientProvider: {
          provider_name: '',
          physician_name: '',
          phone_number: '',
          provider_type: '',
          ...nextProps.patientProvider,
        },
      });
    }
  }

  handleText(field, e, text) {
    let { physicianNameError } = this.state;

    if (field === 'physician_name') {
      physicianNameError = '';
    }

    this.setState({
      physicianNameError,
      patientProvider: {
        ...this.state.patientProvider,
        [field]: text,
      },
    });
  }

  handleTextNewMUI(field, e) {
    let { physicianNameError } = this.state;

    if (field === 'physician_name') {
      physicianNameError = '';
    }

    this.setState({
      physicianNameError,
      patientProvider: {
        ...this.state.patientProvider,
        [field]: e.target.value,
      },
    });
  }

  handleUpdateType(e) {
    this.setState({
      providerTypeError: '',
      patientProvider: {
        ...this.state.patientProvider,
        provider_type: e.target.value,
      },
    });
  }

  handleSave() {
    const { patientProvider } = this.state;
    let physicianNameError = '';
    let providerTypeError = '';
    if (!patientProvider.physician_name) {
      physicianNameError = 'Physician name is required!';
    }
    if (!patientProvider.provider_type || patientProvider.provider_type === 'none') {
      providerTypeError = 'Provider type is required!';
    }
    if (physicianNameError || providerTypeError) {
      return this.setState({ physicianNameError, providerTypeError });
    }
    if (patientProvider.id) {
      const updatedPhysician = omit(patientProvider, ['user_id']);
      this.props.editPatientProvider(updatedPhysician)
        .then(() => this.props.router.push('/personalinfo'))
        .catch(() => {});
    } else {
      const newPhysician = omit(patientProvider, ['id', 'user_id']);
      this.props.addPatientProvider(newPhysician)
        .then(() => this.props.router.push('/personalinfo'))
        .catch(() => {});
    }
  }

  handleDelete() {
    const { patientProvider } = this.state;

    this.props.deletePatientProvider(patientProvider.id)
      .then(() => this.props.router.push('/personalinfo'))
      .catch(() => {});
  }

  render() {
    let deleteButton;
    let displayedError;
    const { patientProvider, physicianNameError, providerTypeError } = this.state;
    const {
      addError,
      deleteError,
      editError,
      classes,
    } = this.props;
    if (addError || deleteError || editError) {
      displayedError = (
        <div style={baseStyles.redErrorText}>
          There was an error submitting this page. Please try again.
        </div>);
    }
    if (patientProvider.id) {
      deleteButton = (
        <Continue
          text="Delete Physician"
          btnStyle={{ backgroundColor: colors.errorRed }}
          onClick={this.handleDelete}
        />);
    }
    return (
      <Layout>
        <AppBar
          backButtonOnClick={() => this.props.router.push('/npi-search')}
          headerNode="Physician"
        />
        <section>
          <p
            style={baseStyles.physicianDetails}
          >PHYSICIAN DETAILS
          </p>
        </section>
        <section className="form" style={baseStyles.whiteBackgroundSection}>
          <TextField
            label="Physician Name *"
            onChange={this.handleUpdatePhysicianName}
            style={baseStyles.marginLeft}
            value={patientProvider.physician_name || ''}
            error={!!physicianNameError}
            helperText={physicianNameError}
            classes={{ root: classes.textField }}
          />
          <Divider />
          <TextField
            label="Practice Name"
            onChange={this.handleUpdateProviderName}
            style={baseStyles.marginLeft}
            value={patientProvider.provider_name || ''}
            classes={{ root: classes.textField }}
          />
          <Divider />
          <TextField
            label="Practice Phone"
            onChange={this.handleUpdatePhone}
            style={baseStyles.marginLeft}
            value={patientProvider.phone_number || ''}
            classes={{ root: classes.textField }}
          />
          <Divider />
          <FormControl error={!!providerTypeError} className={classes.selectWrapper}>
            <Select
              label="Provider Type *"
              style={baseStyles.marginLeft}
              value={patientProvider.provider_type || 'none'}
              onChange={this.handleUpdateType}
              classes={{ root: classes.select }}
            >
              <MenuItem value="none"><em>Provider Type *</em></MenuItem>
              <MenuItem value="PRIMARY">Primary</MenuItem>
              <MenuItem value="REFERRING">Referring</MenuItem>
              <MenuItem value="SPECIALTY">Specialty</MenuItem>
            </Select>
            <FormHelperText
              className={classes.providerTypeError}
              style={baseStyles.marginLeft}
            >{
              providerTypeError}
            </FormHelperText>
          </FormControl>
        </section>
        <div style={baseStyles.marginTop}>
          <Continue onClick={this.handleSave} text="Save Physician" />
          {displayedError}
          {deleteButton}
        </div>
      </Layout>
    );
  }
}

function mapStateToProps(state, props) {
  const { patientProvider } = state;
  const patientProviderId = get(props, 'location.query.patient_provider_id', null);

  return {
    patientProvider: patientProvider.data[patientProviderId],
    addError: patientProvider.addError,
    deleteError: patientProvider.deleteError,
    editError: patientProvider.editError,
    patientProviderId,
  };
}

Physician.propTypes = {
  patientProviderId: PropTypes.string,
  patientProvider: PropTypes.object,
};

export default connect(mapStateToProps, {
  browsePatientProviders,
  editPatientProvider,
  addPatientProvider,
  deletePatientProvider,
})(withStyles(classStyles)(Physician));
