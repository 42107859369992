import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isAndroid } from 'common-ui';
import { Avatar, Fab } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import base64ToBlob from 'b64-to-blob';

import { colors } from '../lib/styles';
import { cordovaTakePicture } from '../state/cordova';
import CropModal from './crop-modal';

const baseStyles = {
  container: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    margin: '10px',
  },
  fabButton: {
    margin: '20px',
  },
  fileInputStyle: {
    display: 'none', // hides some default grossness
  },
  img: {
    backgroundColor: 'rgb(188, 188, 188)',
    height: '25rem',
    width: '30rem',
  },
};

const classes = {
  fab: {
    backgroundColor: colors.secondaryColor,
    margin: 20,
  },
};

/* global FileReader */
class ImageUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      android: isAndroid,
      file: '',
      cropModalOpen: false,
    };
    this.onFileChange = this.onFileChange.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
    this.handleAndroidImage = this.handleAndroidImage.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.image && nextProps.image !== this.props.image) {
      const fakeEvent = {
        target: {
          files: [base64ToBlob(nextProps.image)],
        },
      };
      this.onFileChange(fakeEvent);
    }
  }

  onFileChange(e) {
    if (e.preventDefault) {
      e.preventDefault();
    }
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files; // eslint-disable-line prefer-destructuring
    } else if (e.target) {
      files = e.target.files; // eslint-disable-line prefer-destructuring
    }

    const reader = new FileReader();
    reader.onload = () => {
      this.setState({
        cropModalOpen: true,
        file: reader.result,
      });
    };
    reader.readAsDataURL(files[0]);
  }

  handleAndroidImage(e) {
    e.preventDefault();
    this.props.cordovaTakePicture();
  }

  handleModalClose() {
    this.setState({
      cropModalOpen: false,
    });
  }

  render() {
    const {
      alt,
      classes,
      displayImage,
      imageHeight,
      imageTypeName,
      uploadText,
    } = this.props;
    const containerStyle = Object.assign({}, baseStyles.container, this.props.containerStyle);
    return (
      <div style={containerStyle}>
        <CropModal
          aspectRatio={this.props.aspectRatio}
          handleModalClose={this.handleModalClose}
          handleUpload={this.props.handleUpload}
          open={this.state.cropModalOpen}
          imageTypeName={this.props.imageTypeName}
          src={this.state.file}
        />
        {(imageTypeName === 'Profile')
          && (
          <Avatar
            alt={alt}
            size={imageHeight}
            src={displayImage}
            style={baseStyles.img}
            imgProps={{ style: { objectFit: 'fill' } }}
          />
          )
        }
        {(imageTypeName === 'Insurance')
          && (
          <img
            alt={alt}
            src={displayImage}
            style={baseStyles.img}
          />
          )
        }
        <div>
          <Fab
            classes={{ root: classes.fab }}
            component="label"
            label={uploadText}
          >
            <i className="material-icons">camera_alt</i>
            {!this.state.android ? (
              <input
                accept="image/*"
                capture="camera"
                type="file"
                onChange={this.onFileChange}
                style={baseStyles.fileInputStyle}
              />
            ) : (
              <input
                onClick={this.handleAndroidImage}
                style={baseStyles.fileInputStyle}
              />
            )
            }
          </Fab>
        </div>
      </div>
    );
  }
}

ImageUpload.defaultProps = {
  alt: 'An Image',
  aspectRatio: undefined, // E.g. 16 / 9
  containerStyle: {},
  image: '',
  imageHeight: '150px',
  imageTypeName: 'Logo',
  uploadText: 'Upload',

};

ImageUpload.propTypes = {
  alt: PropTypes.string,
  aspectRatio: PropTypes.number,
  classes: PropTypes.object.isRequired,
  containerStyle: PropTypes.object,
  cordovaTakePicture: PropTypes.func.isRequired,
  displayImage: PropTypes.string.isRequired,
  handleUpload: PropTypes.func.isRequired,
  image: PropTypes.string,
  imageHeight: PropTypes.string,
  imageTypeName: PropTypes.string,
  uploadText: PropTypes.string,
};

function mapStateToProps(state) {
  const { image } = state.cordova;

  return { image };
}

export default connect(mapStateToProps, {
  cordovaTakePicture,
})(withStyles(classes)(ImageUpload));
