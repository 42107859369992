const en = {
  videoCallPro2: {
    waiting: 'Thank you for waiting. Someone will be with you momentarily.',
    alert: 'Waiting for your Provider to join...',
    cameraError: '...Unable to access camera.',
    poorConnection: 'Unable to perform video call due to a poor connection.',
    providerReady: 'Your provider is ready now. Click to join.',
    clinicIsTyping: 'Clinic is typing...',
  },
};

const es = {
  videoCallPro2: {
    waiting: 'Gracias por esperar. Alguien estará con usted momentáneamente.',
    alert: 'Esperando que su proveedor se una a la llamada...',
    cameraError: '...No se puede acceder a la cámara.',
    poorConnection: '...No se puede realizar la videollamada debido a una mala conexión.',
    providerReady: 'Su proveedor esta listo. Puede hacer clic para unirse.',
    clinicIsTyping: 'La clínica está escribiendo...',
  },
};

export default { en, es };
