const en = {
  pisaInjection3: {
    title: 'Quality of Life',
    question1: '<strong>How was the quality of your life improved?</strong>',
    subtitle: 'Select all that apply',
    options: {
      increasedEnergy: 'Increased/Improved my energy',
      increasedActivity: 'Increased/Improved my activity',
      increasedMood: 'Increased/Improved my mood',
      increasedSleep: 'Increased/Improved my sleeping at night',
      increasedThink: 'Increased/Improved my ability to think',
      increasedSocial: 'Increased/Improved my ability to engage in social activities and relationships',
      reducedPainActivity: 'Reduced my pain during prolonged activity',
      reducedPainWalking: 'Reduced my pain while walking',
      reducedPainStanding: 'Reduced my pain while standing',
      reducedPainSitting: 'Reduced my pain while sitting',
      reducedAnxietyDepression: 'Reduced my anxiousness and/or feeling depressed',
      other: 'Other',
    },
  },
};

const es = {
  pisaInjection3: {
    title: 'Calidad de Vida',
    question1: '<strong>¿Cómo mejoró la calidad de su vida?</strong>',
    subtitle: 'Seleccione todas las que correspondan',
    options: {
      increasedEnergy: 'Aumenté/Mejoré mi energía',
      increasedActivity: 'Aumenté/Mejoré mi actividad',
      increasedMood: 'Aumentó/Mejoró mi estado de ánimo',
      increasedSleep: 'Aumentó/Mejoró mi sueño por la noche',
      increasedThink: 'Aumentó/Mejoró mi capacidad de pensar',
      increasedSocial: 'Aumenté/mejoré mi capacidad para participar en actividades y relaciones sociales',
      reducedPainActivity: 'Redujo mi dolor durante la actividad prolongada',
      reducedPainWalking: 'Redujo mi dolor al caminar.',
      reducedPainStanding: 'Redujo mi dolor mientras estaba de pie',
      reducedPainSitting: 'Redujo mi dolor mientras estaba sentado',
      reducedAnxietyDepression: 'Redujo mi ansiedad y/o sensación de depresión.',
      other: 'Otro',
    },
  },
};

export default { en, es };
