import { promiseHandler, resetReducer } from 'cooldux';
import { get, isUndefined, omit, omitBy } from 'lodash';

import { apiFetch } from '../lib/fetch';

const handlerOptions = {
  namespace: 'pharmacyHx',
  throwErrors: true,
};
const { browseStart, browseEnd, browseError, browseHandler } = promiseHandler('browse', 'pharmacyHx');
const { readStart, readEnd, readError, readHandler } = promiseHandler('read', handlerOptions);
const { editStart, editEnd, editError, editHandler } = promiseHandler('edit', handlerOptions);
const { addStart, addEnd, addError, addHandler } = promiseHandler('add', handlerOptions);
const { deleteStart, deleteEnd, deleteError, deleteHandler } = promiseHandler('delete', handlerOptions);

export function browsePharmacyHx() {
  return function dispatcher(dispatch, getState) {
    const selfId = get(getState(), 'user.id', null);
    const promise = apiFetch(`/users/${selfId}/pharmacy_hx`);
    return browseHandler(promise, dispatch);
  };
}

export function readPharmacyHx() {
  return function dispatcher(dispatch, getState) {
    const selfId = get(getState(), 'user.id', null);
    const promise = apiFetch(`/users/${selfId}/pharmacy_hx`)
      .then(([item]) => {
        if (!item) {
          throw new Error('Not Found');
        }
        return item;
      });
    return readHandler(promise, dispatch);
  };
}

export function editPharmacyHx(update) {
  return function dispatcher(dispatch, getState) {
    const selfId = get(getState(), 'user.id', null);
    const body = omit(update, 'id');
    const options = {
      method: 'PUT',
      body: omitBy(body, isUndefined),
    };
    const promise = apiFetch(`/users/${selfId}/pharmacy_hx/${update.id}`, options);
    return editHandler(promise, dispatch);
  };
}

export function addPharmacyHx(newPharmacyHx) {
  return function dispatcher(dispatch, getState) {
    const selfId = get(getState(), 'user.id', null);
    const options = {
      method: 'POST',
      body: omitBy(newPharmacyHx, isUndefined),
    };
    const promise = apiFetch(`/users/${selfId}/pharmacy_hx`, options);
    return addHandler(promise, dispatch);
  };
}

export function deletePharmacyHx(pharmacyHxId) {
  return function dispatcher(dispatch, getState) {
    const selfId = get(getState(), 'user.id', null);
    const options = {
      method: 'DELETE',
    };
    const promise = apiFetch(`/users/${selfId}/pharmacy_hx/${pharmacyHxId}`, options)
      .then(() => pharmacyHxId);

    return deleteHandler(promise, dispatch);
  };
}

const initialState = {
  browseError: null,
  readError: null,
  addError: null,
  editError: null,
  deleteError: null,
  browsePending: false,
  readPending: false,
  addPending: false,
  editPending: false,
  deletePending: false,
  data: {},
};

function finishBrowse(state, pharmacyHx) {
  const data = {};
  pharmacyHx.forEach((u) => {
    data[u.id] = u;
  });
  return { ...state, data, browsePending: false, browseError: null };
}

function finishRead(state, item) {
  const data = { ...state.data, [item.id]: item };
  return { ...state, editPending: false, editError: null, data };
}

function finishEdit(state, item) {
  const data = { ...state.data, [item.id]: item };
  return { ...state, editPending: false, editError: null, data };
}

function finishAdd(state, item) {
  const data = { ...state.data, [item.id]: item };
  return { ...state, addPending: false, addError: null, data };
}

function finishDelete(state, pharmacyHxId) {
  return { ...state, deletePending: false, deleteError: null, data: omit(state.data, pharmacyHxId) };
}

const pharmacyHx = resetReducer(initialState, (state = initialState, action) => {
  switch (action.type) {
    case browseStart.type:
      return { ...state, browsePending: true, browseError: null };
    case browseEnd.type:
      return finishBrowse(state, action.payload);
    case browseError.type:
      return { ...state, browsePending: false, browseError: action.payload };
    case readStart.type:
      return { ...state, readPending: true, readError: null };
    case readEnd.type:
      return finishRead(state, action.payload);
    case readError.type:
      return { ...state, readPending: false, readError: action.payload };
    case addStart.type:
      return { ...state, addPending: true, addError: null };
    case addEnd.type:
      return finishAdd(state, action.payload);
    case addError.type:
      return { ...state, addPending: false, addError: action.payload };
    case deleteStart.type:
      return { ...state, deletePending: true, deleteError: null };
    case deleteEnd.type:
      return finishDelete(state, action.payload);
    case deleteError.type:
      return { ...state, deletePending: false, deleteError: action.payload };
    case editStart.type:
      return { ...state, editPending: true, editError: null };
    case editEnd.type:
      return finishEdit(state, action.payload);
    case editError.type:
      return { ...state, editPending: false, editError: action.payload };
    default:
      return state;
  }
});

export default pharmacyHx;
