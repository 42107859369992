import 'isomorphic-fetch';
import React from 'react';
import { render } from 'react-dom';
import { Router, Route, browserHistory } from 'react-router';
import { Provider } from 'react-redux';
import { createGenerateClassName, MuiThemeProvider, StylesProvider } from '@material-ui/core/styles';
import { forEach } from 'lodash';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import cordovaInitializer from './initializers/cordova';

import activityInitializer from './initializers/activity';
import amplitudeInitializer from './initializers/amplitude';
import navigationManifest from './pages/navigation';
import ConnectedApp from './pages/swipeable-dashboard';
import ConnectedGoodRx from './pages/goodrx';
import ConnectedSettings from './pages/settings';
import ConnectedLogin from './pages/login';
import ConnectedSignup from './pages/signup';
import ConnectedResetPassword from './pages/reset-password';
import ConnectedForgot from './pages/forgot';
import ConnectedQR from './pages/qr';
import ConnectedChiefComplaint from './pages/primary-complaint';
import ConnectedShareData from './pages/share-data';
import ConnectedInsurance from './pages/insurance';
import ConnectedEditPatientInfo from './pages/edit-patient-info';
import ConnectedEmergencyContact from './pages/emergency-contact';
import ConnectedEmployer from './pages/employer';
import ConnectedPhysician from './pages/physician';
import ConnectedClinics from './pages/add-clinic';
import ConnectedClinicInfo from './pages/clinic-info';
import ConnectedClinicShareInfo from './pages/clinic-splash-screen';
import ConnectedAbout from './pages/about';
import ConnectedEnterPin from './pages/enter-pin';
import ConnectedPharmacy from './pages/pharmacy';
import ConnectedMedication from './pages/medication';
import ConnectedNotifications from './pages/notifications';
import ConnectedPainOrigin from './pages/pain-origin';
import ConnectedPainIntro from './pages/general-pain-intro';
import ConnectedPicture from './pages/picture';
import CoreVitalFaq from './pages/core-vital-faq';
import CoreVitalHowToVideo from './pages/core-vital-how-to-video';
import CoreVitalPrivacyPolicy from './pages/core-vital-privacy-policy';
import CoreVitalReleaseNotes from './pages/core-vital-release-notes';
import CoreVitalTermsAndConditions from './pages/core-vital-terms-and-conditions';
import CoreVitalHowToUse from './pages/core-vital-how-to-use';
import CoreVitalUserManual from './pages/core-vital-user-manual';
import CoreVitalUserManualMenu from './pages/core-vital-user-manual-menu';
import Timeout from './pages/timeout';
import InfoUpdatedSuccessfully from './pages/info-updated-successfully';
import ShadowConfirmation from './pages/shadow-confirmation';
import Esign from './pages/esign';
import { attemptVerifyUser, verifyUser } from './state/user';
import { NoEulaError } from './lib/errors';
import ConnectedRegistration from './pages/registration';
import PainCarousel2 from './pages/pain-carousel';
import MigraineOrHeadachePain from './pages/migraine-or-headache-pain';
import RecentPain from './pages/recent-pain';
import Eula from './pages/eula';
import VideoDisplay from './pages/video';
import SessionConflict from './pages/session-conflict';
import InvalidPage from './pages/invalid-page';
import InactivePage from './pages/inactive-page';

import PainLogDetails from './pages/pain-log-details';
import GenericNotification from './pages/generic-notification';
import NotificationIntro from './components/notification-intro';

import store from './store';
import { socketInitializer, socketEvents } from './websocket';
import brand from './lib/brand';
import inactiveRoutes from './lib/inactive-routes';

import './i18n';
import './index.css';

const generateClassName = createGenerateClassName({
  productionPrefix: 'patient-app',
});

socketInitializer(store);
activityInitializer();
cordovaInitializer(store);
amplitudeInitializer();
function requireAuth(nextState, replace, cb) {
  return store.dispatch(verifyUser())
    .then(() => cb())
    .catch((err) => {
      if (err instanceof NoEulaError) {
        replace({ pathname: '/signup_eula' });
      } else {
        replace({
          pathname: '/login',
          query: {
            ...nextState.location.query,
            originalPath: nextState.location.pathname,
          },
        });
      }
      cb();
    });
}

window.addEventListener('beforeinstallprompt', (e) => {
  e.preventDefault();
});

function attemptAuth(nextState, replace, cb) {
  return store.dispatch(attemptVerifyUser())
    .then(() => cb());
}

socketEvents.on('OFFERER_CALL_REQUEST', () => {
  browserHistory.push('/video-call');
});

const mainRoutes = [
  { path: '/', component: ConnectedApp, onEnter: requireAuth, pageIndex: 1 },
  { path: '/pain-history', component: ConnectedApp, onEnter: requireAuth, pageIndex: 0 },
  { path: '/swipe-pain-log', component: ConnectedApp, onEnter: requireAuth, pageIndex: 2 },
  { path: '/insurance', component: ConnectedInsurance, onEnter: requireAuth },
  { path: '/settings', component: ConnectedSettings, onEnter: requireAuth },
  { path: '/login', component: ConnectedLogin },
  { path: '/signup', component: ConnectedSignup },
  { path: '/signup/:signupToken', component: ConnectedSignup },
  { path: '/forgot', component: ConnectedForgot },
  { path: '/reset', component: ConnectedResetPassword, ios: false },
  { path: '/reset-ios', component: ConnectedResetPassword, ios: true },
  { path: '/qr', component: ConnectedQR, onEnter: requireAuth },
  { path: '/reason', component: ConnectedChiefComplaint },
  { path: '/share-data', component: ConnectedShareData },
  { path: '/editpatientinfo', component: ConnectedEditPatientInfo, onEnter: requireAuth },
  { path: '/emergencycontact', component: ConnectedEmergencyContact, onEnter: requireAuth },
  { path: '/physician', component: ConnectedPhysician, onEnter: requireAuth },
  { path: '/clinics/:clinicId', component: ConnectedClinicInfo, onEnter: requireAuth },
  { path: '/clinics', component: ConnectedClinics, onEnter: requireAuth },
  { path: '/clinics/:clinicId/splash', component: ConnectedClinicShareInfo, onEnter: requireAuth },
  { path: '/employer', component: ConnectedEmployer, onEnter: requireAuth },
  { path: '/about/:pageSelection', component: ConnectedAbout },
  { path: '/enterpin', component: ConnectedEnterPin, onEnter: requireAuth },
  { path: '/infoupdated', component: InfoUpdatedSuccessfully, onEnter: requireAuth },
  { path: '/medication', component: ConnectedMedication, onEnter: requireAuth },
  { path: '/pharmacy', component: ConnectedPharmacy, onEnter: requireAuth },
  { path: '/painorigin', component: ConnectedPainOrigin, onEnter: requireAuth },
  { path: '/painintro', component: ConnectedPainIntro, onEnter: requireAuth },
  { path: '/picture', component: ConnectedPicture, onEnter: requireAuth },
  { path: '/goodrx', component: ConnectedGoodRx, onEnter: requireAuth },
  { path: '/notifications', component: ConnectedNotifications, onEnter: requireAuth },
  { path: '/notifications/:notificationId', component: ShadowConfirmation, noauth: true }, // Handles Text based notifications
  { path: '/notifications/:notificationId/process', component: GenericNotification }, // Handles notifications from menu
  { path: '/pain-log-details/:month/:date/:year', component: PainLogDetails, onEnter: requireAuth },
  { path: '/carousel2', component: PainCarousel2, onEnter: requireAuth },
  { path: '/which-pain', component: MigraineOrHeadachePain, onEnter: requireAuth },
  { path: '/recent-pain', component: RecentPain, onEnter: requireAuth },
  { path: '/registration', component: ConnectedRegistration },
  { path: '/signup_eula', component: Eula },
  { path: '/esign/:esignRequestId', component: Esign },
  { path: '/notification-success/:notificationType', component: InfoUpdatedSuccessfully },
  { path: '/video', component: VideoDisplay },
  { path: '/esign/:esignRequestId', component: Esign },
  { path: '/core-vital-user-manual-menu', component: CoreVitalUserManualMenu },
  { path: '/core-vital-how-to-video', component: CoreVitalHowToVideo },
  { path: '/core-vital-faq', component: CoreVitalFaq },
  { path: '/core-vital-terms-and-conditions', component: CoreVitalTermsAndConditions },
  { path: '/core-vital-how-to-use', component: CoreVitalHowToUse },
  { path: '/core-vital-user-manual', component: CoreVitalUserManual },
  { path: '/core-vital-privacy-policy', component: CoreVitalPrivacyPolicy },
  { path: '/core-vital-release-notes', component: CoreVitalReleaseNotes },
  { path: '/timeout', component: Timeout },
  { path: '/session-conflict', component: SessionConflict },
  { path: '/invalid-page', component: InvalidPage },
  { path: '/inactive', component: InactivePage },
];

const handleInactiveRoutes = (path) => {
  return inactiveRoutes.some(route => route === path);
};

const routes = [];
function getAuth(page) {
  if (page.attemptAuth) {
    return attemptAuth;
  }
  if (page.noauth) {
    return null;
  }
  return requireAuth;
}
forEach(navigationManifest, (track, key) => {
  forEach(track.pages, (page, innerKey) => {
    routes.push(<Route {...page} onEnter={getAuth(page)} track={key} key={page.path + innerKey} />);
  });
});

forEach(mainRoutes, (route) => {
  routes.push(<Route {...route} key={route.path} />);
});

routes.push(<Route path="/notification-intro" component={NotificationIntro} isPro={true} key="/notification-intro" />);

window.browserHistory = browserHistory;

render(
  (
    <Provider store={store}>
      <StylesProvider generateClassName={generateClassName}>
        <MuiThemeProvider theme={brand.nextTheme}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Router onUpdate={() => window.scrollTo(0, 0)} history={browserHistory}>
              {routes.map((route) => {
                if (handleInactiveRoutes(route.props.path)) {
                  return <Route path={route.props.path} component={InactivePage} key={route.props.path} />;
                }
                return route;
              })}
            </Router>
          </MuiPickersUtilsProvider>
        </MuiThemeProvider>
      </StylesProvider>
    </Provider>
  ), document.getElementById('root'),
);

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/sw.js').then(() => {
      // Registration was successful
    }, (err) => {
      // registration failed :(
      console.log('ServiceWorker registration failed: ', err);
    });
  });
}
