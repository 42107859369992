import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { noop } from 'lodash';

import AppBar from '../components/app-bar';
import Page from './page';
import { signup } from '../state/user';
import { addPersonalInfo } from '../state/personal-info';
import Continue from '../components/continue';
import { custom, colors } from '../lib/styles';

const baseStyles = {
  btn: {
    backgroundColor: colors.secondaryColor,
    marginBottom: '25px',
  },
  btnLabel: {
    fontSize: '1.3rem',
  },
  center: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  checkmark: {
    height: '10rem',
    width: '10rem',
    marginTop: '4rem',
  },
  container: {
    marginTop: '3.125rem',
  },
  email: {
    fontSize: '1.4rem',
    color: colors.primaryColor,
    marginTop: '13px',
  },
  p: {
    fontSize: '1.4rem',
    margin: '15px 80px',
  },
  subheader: {
    fontSize: '1.5rem',
  },
};

class EmailConfirmation extends Page {
  render() {
    return (
      <div>
        <AppBar
          headerNode="Registration Success!"
          noLeftNode={true}
        />
        <section style={baseStyles.center}>
          <img
            src="/img/checkmark.svg"
            alt="checkmark"
            style={baseStyles.checkmark}
          />
          <div style={baseStyles.container}>
            <section>
              <div style={custom.signupHeaderText}>
                <p style={baseStyles.p}>
                  Continue to finish setting up your account and setting your goals.
                </p>
              </div>
            </section>
          </div>
          <Continue
            btnStyle={baseStyles.btn}
            labelStyle={baseStyles.btnLabel}
            onClick={this.forward}
          />
        </section>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { user, personalInfo } = state;

  return { user, personalInfo };
}

EmailConfirmation.defaultProps = {
  signup: {},
  router: noop,
};

EmailConfirmation.propTypes = {
  signup: PropTypes.object,
  router: PropTypes.func,
  personalInfo: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};


export default connect(mapStateToProps, { signup, addPersonalInfo })(EmailConfirmation);
