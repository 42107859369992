const en = {
  appointmentConfirmation4: {
    title: 'Connection Check',
    pass: 'Everything looks good!',
    fail: 'Oh No!',
    camera: 'Camera',
    microphone: 'Microphone',
    network: 'Network Connection',
    unstableHeader: 'Unstable Network Connection',
    unstableMessage: 'Please make sure you have a strong wifi connection',
    permissionsBlocked: 'Your camera and/or microphone access may have previously been blocked. Please close this window, check your browser\'s permission settings for this site, and try again.',
  },
};

const es = {
  appointmentConfirmation4: {
    title: 'Comprobación de conexión',
    pass: '¡Todo se ve bien!',
    fail: '¡Ay, no!',
    camera: 'Cámara',
    microphone: 'Micrófono',
    network: 'Conexión de red',
    unstableHeader: 'Conexión de red inestable',
    unstableMessage: 'Por favor, asegúrese de tener una conexión de wifi fuerte',
    permissionsBlocked: 'Es posible que el acceso a su cámara y/o micrófono haya sido bloqueado anteriormente. Cierre esta ventana, verifique la configuración de permisos de su navegador para este sitio e intente nuevamente.',
  },
};

export default { en, es };
