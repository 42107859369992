const en = {
  eqUsualActivities: {
    title: 'Usual Activities',
    subtitle: 'Usual Activities (e.g. work, study, housework, family or leisure activities)',
    instructions: 'Please select the option that best answers the question relating to your pain.',
    healthy: 'I have no problems with performing my usual activities',
    mild: 'I have some problems performing my usual activities',
    severe: 'I am unable to perform my usual activities',
  },
};

const es = {
  eqUsualActivities: {
    title: 'Actividades Habituales',
    subtitle: 'Actividades Habituales (por ejemplo, trabajo, estudio, tareas del hogar, actividades familiares o de ocio)',
    instructions: 'Seleccione la opción que mejor responda a la pregunta relacionada con su dolor.',
    healthy: 'No tengo problemas para realizer mis actividades habituales',
    mild: 'Tengo algunos problemas al realizer mis actividades habituales',
    severe: 'No puedo realizer mis actividades habituales',
  },
};

export default { en, es };
