import React from 'react';
import { get } from 'lodash';
import { connect } from 'react-redux';
import SwipeableViews from 'react-swipeable-views';
import { Badge } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import icons from '../components/icons';
import AppBar from '../components/app-bar';
import UserAvatar from '../components/user-avatar';
import BottomNavigationSection from '../components/bottom-navigation-section';
import PainHistory from './pain-history';
import DashboardView from '../components/dashboard-view';
import RecentPain from './recent-pain';

import { apiFetch } from '../lib/fetch';
import { fetchUserReport, updateAvatarUrl } from '../state/user';
import { fetchActivity, fetchCalories, fetchSteps } from '../state/cordova';
import { addPainHx, browsePainHx, editPainHx, readPainHx } from '../state/pain-hx';
import { browseMigraineHx } from '../state/migraine-hx';
import { browsePainLocationsIfNeeded } from '../state/app-data';
import { colors, mainDashboard } from '../lib/styles';
import Page from './page';
import brand from '../lib/brand';

const { DashboardIcon, PainEntryIcon, InformedIcon } = icons;

const baseStyles = {
  bell: {
    height: '3.75rem',
    width: '3.75rem',
    color: colors.charcoal,
    cursor: 'pointer',
  },
  container: {
    position: 'fixed',
    bottom: 0,
    width: '100%',
  },
  logo: {
    height: '5.1125rem',
    width: '18.5rem',
    cursor: 'pointer',
    backgroundSize: 'contain',
  },
};

const classStyles = {
  badge: {
    fontSize: '1rem',
    marginTop: 2,
  },
};

const pageRoutes = {
  0: '/pain-history',
  1: '/',
  2: '/swipe-pain-log',
};
class Swipe extends Page {
  constructor(props) {
    super(props);

    this.handleChangeIndex = this.handleChangeIndex.bind(this);
    this.onTouchPainHistory = this.handleChange.bind(this, 0);
    this.onTouchDashboard = this.handleChange.bind(this, 1);
    this.onTouchPainEntry = this.handleChange.bind(this, 2);
    const pageIndex = get(props, 'route.pageIndex', 1); // Default to main view if no index
    this.state = {
      index: pageIndex,
      totalNotifications: 0,
      bodyData: null,
      headData: null,
      mostRecentPain: {},
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.pageIndex !== this.props.pageIndex) {
      this.setState({
        index: nextProps.pageIndex,
      });
    }
  }

  componentWillMount() {
    const { user, browsePainHx, browseMigraineHx } = this.props;

    apiFetch(`/users/${user.id}/notifications`)
      .then((notifications) => {
        this.setState({
          totalNotifications: notifications.length,
        });
      }).catch(() => {}); // Not the worst problem if they don't load
    browsePainHx(null, null, { limit: 1 })
      .then(([bodyData]) => this.setState({ bodyData, fetchingBodyData: false }));

    browseMigraineHx(null, null, { limit: 1 })
      .then(([headData]) => this.setState({ headData, fetchingHeadData: false }));
  }

  componentDidUpdate(prevProps, prevState) {
    const { bodyData, headData } = this.state;
    let recentPain;
    if ((prevState.bodyData !== bodyData) || (prevState.headData !== headData)) {
      recentPain = bodyData || headData;
      if (bodyData && headData) {
        recentPain = (bodyData.pain_end_date > headData.end_time) ? bodyData : headData;
      }
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        mostRecentPain: recentPain,
      });
    }
  }

  handleChange = (index) => {
    this.props.router.push(pageRoutes[index]);
    this.setState({
      index,
    });
  };

  handleChangeIndex = (index) => {
    this.props.router.push(pageRoutes[index]);
    this.setState({
      index,
    });
  };

  render() {
    const { index, totalNotifications } = this.state;
    const { classes } = this.props;
    return (
      <section style={mainDashboard.container}>
        <div style={baseStyles.topWrapper}>
          <AppBar
            leftNode={(
              <UserAvatar
                updateAvatarUrl={this.props.updateAvatarUrl}
                user={this.props.user}
                router={this.props.router}
              />
)}
            headerNode={(
              <img
                alt="informed logo"
                onClick={() => this.props.router.push('/settings')}
                onKeyPress={() => this.props.router.push('/settings')}
                src={`/img/${brand.name}/${brand.loginLogo}`}
                style={baseStyles.logo}
                role="button" // eslint-disable-line jsx-a11y/no-noninteractive-element-to-interactive-role
                tabIndex={0}
              />
)}
            rightNode={(
              <Badge
                badgeContent={totalNotifications}
                color="error"
                classes={{ colorError: classes.badge }}
              >
                <img
                  alt="notification bell"
                  onClick={() => this.props.router.push('/notifications')}
                  onKeyPress={() => this.props.router.push('/notifications')}
                  src="/img/alerts.svg"
                  style={baseStyles.bell}
                  role="button" // eslint-disable-line jsx-a11y/no-noninteractive-element-to-interactive-role
                  tabIndex={0}
                />
              </Badge>
)}
          />
        </div>
        <SwipeableViews
          enableMouseEvents
          index={index}
          onChangeIndex={this.handleChangeIndex}
        >
          <PainHistory
            router={this.props.router}
          />
          <DashboardView
            {...this.props}
            mostRecentPain={this.state.mostRecentPain}
            headData={this.state.headData}
            painType={this.state.mostRecentPain === this.state.bodyData ? 'body' : 'head'}
          />
          <RecentPain
            {...this.props}
            active={index === 2}
          />
        </SwipeableViews>
        <div style={baseStyles.container}>
          <footer style={mainDashboard.footer}>
            <BottomNavigationSection
              active={index === 0}
              icon={InformedIcon}
              label="Pain History"
              onClick={this.onTouchPainHistory}
            />
            <BottomNavigationSection
              active={index === 1}
              icon={DashboardIcon}
              label="Dashboard"
              onClick={this.onTouchDashboard}
            />
            <BottomNavigationSection
              active={index === 2}
              icon={PainEntryIcon}
              label="Pain Log Details"
              onClick={this.onTouchPainEntry}
            />
          </footer>
        </div>
      </section>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state;

  return {
    user,
  };
}

export default connect(mapStateToProps, {
  fetchActivity,
  fetchCalories,
  fetchSteps,
  updateAvatarUrl,
  fetchUserReport,
  addPainHx,
  browsePainHx,
  editPainHx,
  readPainHx,
  browseMigraineHx,
  browsePainLocationsIfNeeded,
})(withStyles(classStyles)(Swipe));
