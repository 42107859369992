import React, { Component } from 'react';
import { isNumber } from 'lodash';
import PropTypes from 'prop-types';
import LinearProgress from '@material-ui/core/LinearProgress';
import { withStyles } from '@material-ui/core/styles';

import AppBar from './app-bar';
import { colors, fontSizes } from '../lib/styles';
import QuestionLayout from '../layouts/question';
import ReactSlider from './react-slider';
import PainSliderLabels from './pain-slider-labels';

const { lightBrightBlue } = colors;

const styles = {
  instructions: {
    color: 'grey',
    fontSize: 13,
    margin: '20px 65px',
    textAlign: 'center',
  },
  linearProgress: {
    borderRadius: 0,
  },
  pageIndicator: {
    color: 'grey',
    display: 'inline-block',
    fontSize: 13,
  },
  slider: {
    margin: '15px',
  },
  sliderLabel: {
    fontSize: fontSizes.medium,
    fontWeigth: 'strong',
    marginBottom: 45,
    textAlign: 'center',
  },
  bar: {
    backgroundColor: lightBrightBlue,
  },
};

class PainScaleTwoSliders extends Component {
  render() {
    const {
      backgroundColor,
      bottomValue,
      currentPage,
      instructions,
      onClickBackButton,
      onSubmission,
      sliderLabelBottom,
      sliderLabelTop,
      sliderMax,
      sliderMin,
      title,
      topValue,
      totalPages,
      classes,
    } = this.props;
    const background = backgroundColor ? { backgroundColor } : {};

    return (
      <QuestionLayout
        primaryBtnDisabled={!isNumber(topValue) || !isNumber(bottomValue)}
        primaryBtnLabel="Next"
        primaryBtnOnTouchTap={onSubmission}
        style={background}
      >
        <AppBar
          backButtonOnClick={onClickBackButton}
          headerNode={title}
          rightNode={
            <div style={styles.pageIndicator}>{`${currentPage} of ${totalPages}`}</div>
          }
        />
        <LinearProgress
          variant="determinate"
          value={(currentPage / totalPages) * 100}
          classes={{ bar: classes.bar }}
        />
        <div style={styles.instructions}>
          {instructions}
        </div>
        <div style={styles.slider}>
          <div style={styles.sliderLabel}>{sliderLabelTop}</div>
          <ReactSlider
            max={sliderMax}
            min={sliderMin}
            minLabel="No Pain"
            maxLabel="Unbearable Pain"
            onChangeComplete={this.props.onChangeTop}
            showLabels={false}
            value={topValue}
          />
          <PainSliderLabels />
        </div>
        <div style={styles.slider}>
          <div style={styles.sliderLabel}>{sliderLabelBottom}</div>
          <ReactSlider
            max={sliderMax}
            min={sliderMin}
            minLabel="No Pain"
            maxLabel="Unbearable Pain"
            onChangeComplete={this.props.onChangeBottom}
            showLabels={false}
            value={bottomValue}
          />
          <PainSliderLabels />
        </div>
      </QuestionLayout>
    );
  }
}

PainScaleTwoSliders.defaultProps = {
  backgroundColor: undefined,
  bottomValue: 0,
  topValue: 0,
};

PainScaleTwoSliders.propTypes = {
  backgroundColor: PropTypes.string,
  bottomValue: PropTypes.number,
  instructions: PropTypes.string.isRequired,
  currentPage: PropTypes.number.isRequired,
  onClickBackButton: PropTypes.func.isRequired,
  onChangeBottom: PropTypes.func.isRequired,
  onChangeTop: PropTypes.func.isRequired,
  onSubmission: PropTypes.func.isRequired,
  sliderLabelBottom: PropTypes.string.isRequired,
  sliderLabelTop: PropTypes.string.isRequired,
  sliderMax: PropTypes.number.isRequired,
  sliderMin: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  topValue: PropTypes.number,
  totalPages: PropTypes.number.isRequired,
};

export default withStyles(styles)(PainScaleTwoSliders);
