import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get, isNumber, omit, split } from 'lodash';
import { DatePicker } from '@material-ui/pickers';
import {
  Divider,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import AppBar from '../components/app-bar';
import { apiFetch } from '../lib/fetch';
import Continue from '../components/continue';
import Page from './page';
import Layout from '../layouts/common';
import { colors, custom } from '../lib/styles';

import {
  readMedicationHx,
  editMedicationHx,
  addMedicationHx,
  deleteMedicationHx,
} from '../state/medication-hx';

const baseStyles = {
  buttonHover: {
    color: colors.highlightDarker,
  },
  center: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  flexStart: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-start',
  },
  highlightDark: {
    color: colors.highlightDark,
  },
  marginLeft: {
    marginLeft: '15px',
  },
  marginTop: {
    marginTop: '30px',
  },
  marginBottomTop5: {
    marginBottom: 5,
    marginTop: 5,
  },
  menuItem: {
    color: colors.primaryColor,
  },
  redErrorText: {
    color: colors.errorRed,
    marginLeft: '15px',
    marginRight: '15px',
    textAlign: 'center',
  },
  sectionTitle: {
    color: colors.black,
    fontSize: '1.6rem',
    marginLeft: '15px',
    textAlign: 'left',
  },
  selectFrequencyStyle: {
    ...custom.selectFields,
    marginLeft: '15px',
    width: '65px',
  },
  shrink: {
    color: colors.black,
    fontSize: '2rem',
  },
  text: {
    color: colors.primaryColor,
  },
  underline: {
    display: 'none',
  },
  whiteLeftSection: {
    backgroundColor: colors.white,
    textAlign: 'left',
  },
};

const classStyles = {
  select: {
    margin: 15,
    '& .MuiInput-underline:before': {
      borderBottom: 'none',
    },
    '& .MuiInput-underline:hover:before': {
      borderBottom: 'none',
    },
    '& .MuiInput-underline:after': {
      borderBottom: 'none',
    },
  },
  textField: {
    marginBottom: 5,
    marginTop: 5,
    '& .MuiFormLabel-root': {
      color: colors.primaryColor,
    },
    '& .MuiInput-underline:after': {
      borderBottom: 'none',
    },
    '& .MuiInput-underline:before': {
      borderBottom: 'none',
    },
    '& .MuiInput-underline:hover:before': {
      borderBottom: 'none',
    },
  },
};

const timingOptions = ['daily', 'weekly', 'monthly'];
const frequencyTimingMenuOptions = timingOptions.map(o => (
  <MenuItem key={o} value={o} style={{ textTransform: 'capitalize' }}>{o}</MenuItem>
));

const frequencyAmountMenuOptions = [];

for (let i = 0; i < 12; i++) {
  frequencyAmountMenuOptions.push(<MenuItem key={i} value={i}>{i}</MenuItem>);
}

const StyledTextField = withStyles(() => ({
  root: {
    '& .MuiInputBase-root': {
      fontSize: '16px',
    },
  },
}))(TextField);

const StyledDatePicker = withStyles(() => ({
  root: {
    '& .MuiInputBase-root': {
      fontSize: '16px',
    },
  },
}))(DatePicker);

class Medication extends Page {
  constructor(props) {
    super(props);

    const startDate = get(props, 'medication.start_date', null);
    const name = get(props, 'router.location.query.name', '');
    this.state = {
      medicationNameError: '',
      dosageError: '',
      frequencyError: '',
      dosageOptions: [],
      medication: {
        id: null,
        name,
        dosage: '',
        frequency: '1 daily',
        prescribing_physician: '',
        category: 'OTHER',
        ...props.medication,
        start_date: startDate ? new Date(startDate) : null,
      },
    };

    this.handleSave = this.handleSave.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleChangeName = this.handleTextFieldUpdate.bind(this, 'name');
    this.handleChangeDosage = this.handleTextFieldUpdate.bind(this, 'dosage');
    this.handleChangeFrequency = this.handleTextFieldUpdate.bind(this, 'frequency');
    this.handleChangePhysician = this.handleTextFieldUpdate.bind(this, 'prescribing_physician');
    this.handleChangeStartDate = this.handleDateUpdate.bind(this, 'start_date');

    this.handleChangeFrequencyTiming = this.handleChangeFrequencySplit.bind(this, 'timing');
    this.handleChangeFrequencyAmount = this.handleChangeFrequencySplit.bind(this, 'amount');
  }

  componentWillMount() {
    const { medicationId, medication } = this.props;
    const name = get(this.props, 'router.location.query.name', '');
    if (name || medication.name) {
      apiFetch('/goodrx_dosages', { query: { name: name || medication.name } })
        .then(dosageOptions => this.setState({ dosageOptions }))
        .catch(() => {});
    }
    if (medicationId) {
      if (!medication) {
        this.props.readMedicationHx(medicationId);
      }
    }
  }

  componentWillReceiveProps(props, nextProps) {
    if (props.medication !== nextProps.medication) {
      const startDate = get(nextProps, 'medication.start_date', null);
      this.setState({
        medication: {
          ...nextProps.medication,
          start_date: startDate ? new Date(startDate) : null,
        },
      });
    }
  }

  handleChangeFrequencySplit(type, e) {
    const [amount, timing] = this.state.medication.frequency.split(' ');
    let frequency;
    if (type === 'amount') {
      frequency = `${e.target.value} ${timing}`;
    } else {
      frequency = `${amount} ${e.target.value}`;
    }
    this.setState({
      medication: {
        ...this.state.medication,
        frequency,
      },
    });
  }

  handleTextFieldUpdate(field, e) {
    let { medicationNameError, dosageError, frequencyError } = this.state;

    if (field === 'name') {
      medicationNameError = '';
    }

    if (field === 'dosage') {
      dosageError = '';
    }

    if (field === 'frequency') {
      frequencyError = '';
    }

    this.setState({
      medicationNameError,
      dosageError,
      frequencyError,
      medication: {
        ...this.state.medication,
        [field]: e.target.value,
      },
    });
  }

  handleDateUpdate(field, date) {
    this.setState({
      medication: {
        ...this.state.medication,
        [field]: date,
      },
    });
  }

  handleUpdateDate() {
    this.props.deleteMedicationHx(this.state.medication.id)
      .then(() => this.props.router.go(-2));
  }

  handleSave() {
    const { medication } = this.state;
    let medicationNameError = '';
    let dosageError = '';
    let frequencyError = '';
    if (!medication.name) {
      medicationNameError = 'Medication Name is required!';
    }
    if (!medication.dosage) {
      dosageError = 'Dosage is required!';
    }
    if (!medication.frequency) {
      frequencyError = 'Frequency is required!';
    }
    if (medicationNameError || dosageError || frequencyError) {
      return this.setState({ medicationNameError, dosageError, frequencyError });
    }
    if (medication.id) {
      const updatedMedication = omit(medication, ['user_id']);
      this.props.editMedicationHx(updatedMedication)
        .then(() => this.props.router.goBack());
    } else {
      const newMedication = omit(medication, ['id', 'user_id']);
      if (this.props.diabetesInitial.length) {
        this.props.diabetesInitial[2].autocompleteInput = '';
        this.props.diabetesInitial[2].medicationList.push(newMedication);
        this.props.router.go(-1);
      } else {
        this.props.addMedicationHx(newMedication)
          .then(() => this.props.router.go(-2))
          .catch(() => {});
      }
    }
  }

  handleDelete() {
    const { medication } = this.state;

    this.props.deleteMedicationHx(medication.id)
      .then(() => this.props.router.goBack())
      .catch(() => {});
  }

  render() {
    let deleteButton = null;
    let showFrequencyDropdowns = false;
    let displayedError;
    const qsName = get(this.props, 'router.location.query.name', '');
    const { dosageOptions, medication, medicationNameError, dosageError, frequencyError } = this.state;
    const { addError, classes, deleteError, editError } = this.props;

    const [frequencyAmount, frequencyTiming] = split(medication.frequency, ' ');

    const numAmount = Number(frequencyAmount);
    if (isNumber(numAmount) && timingOptions.includes(frequencyTiming)) {
      showFrequencyDropdowns = true;
    }
    if (addError || deleteError || editError) {
      displayedError = (
        <div style={baseStyles.redErrorText}>
          There was an error submitting this page. Please try again.
        </div>);
    }
    if (medication.id) {
      deleteButton = (
        <Continue
          text="Delete Medication"
          btnStyle={{ backgroundColor: colors.errorRed }}
          onClick={this.handleDelete}
        />);
    }
    return (
      <Layout>
        <AppBar
          backButtonOnClick={() => this.props.router.goBack()}
          headerNode="Medication"
        />
        <section>
          <p style={baseStyles.sectionTitle}>
            MEDICATION DETAILS
          </p>
        </section>
        <section className="form" style={baseStyles.whiteLeftSection}>
          <StyledTextField
            label="Medication Name *"
            style={baseStyles.marginLeft}
            value={medication.name}
            onChange={this.handleChangeName}
            error={!!medicationNameError}
            helperText={medicationNameError}
            classes={{ root: classes.textField }}
            inputProps={{ readOnly: !!qsName }}
          />
          <Divider />
          {dosageOptions.length ? (
            <FormControl className={classes.select}>
              <InputLabel
                shrink
                id="dosage-label"
                style={baseStyles.text}
              >
                Dosage *
              </InputLabel>
              <Select
                labelId="dosage-label"
                fullWidth
                displayEmpty
                value={medication.dosage}
                onChange={this.handleChangeDosage}
              >
                <MenuItem value=""><em>Select</em></MenuItem>
                {dosageOptions.map(d => <MenuItem value={d}>{d}</MenuItem>)}
              </Select>
            </FormControl>
          ) : (
            <TextField
              label="Dosage *"
              style={baseStyles.marginLeft}
              value={medication.dosage}
              onChange={this.handleChangeDosage}
              error={!!dosageError}
              helperText={dosageError}
              classes={{ root: classes.textField }}
            />
          )}
          <Divider />

          {showFrequencyDropdowns ? (
            <div>
              <FormControl
                shrink
                error={!!frequencyError}
                className={classes.select}
              >
                <InputLabel id="amount-label" style={baseStyles.text}>Amount</InputLabel>
                <Select
                  labelId="amount-label"
                  displayEmpty
                  value={numAmount}
                  onChange={this.handleChangeFrequencyAmount}
                >
                  {frequencyAmountMenuOptions}
                </Select>
                <FormHelperText>{frequencyError}</FormHelperText>
              </FormControl>
              <FormControl
                shrink
                error={!!frequencyError}
                className={classes.select}
              >
                <InputLabel id="frequency-label" style={baseStyles.text}>Frequency</InputLabel>
                <Select
                  labelId="frequency-label"
                  displayEmpty
                  value={frequencyTiming}
                  onChange={this.handleChangeFrequencyTiming}
                >
                  {frequencyTimingMenuOptions}
                </Select>
                <FormHelperText>{frequencyError}</FormHelperText>
              </FormControl>
            </div>
          ) : (
            <TextField
              label="Frequency *"
              style={baseStyles.marginLeft}
              value={medication.frequency}
              onChange={this.handleChangeFrequency}
              classes={{ root: classes.textField }}
              error={!!frequencyError}
              helperText={frequencyError}
            />
          )}
          <Divider />
          <StyledDatePicker
            autoOk={true}
            format="MM/dd/yyyy"
            label="Start Date"
            onChange={this.handleChangeStartDate}
            openTo="year"
            value={medication.start_date}
            style={{ ...baseStyles.marginLeft, ...baseStyles.marginBottomTop5 }}
          />
          <StyledTextField
            label="Prescribing Physician"
            style={{ ...baseStyles.marginLeft, ...baseStyles.marginBottomTop5 }}
            value={medication.prescribing_physician}
            onChange={this.handleChangePhysician}
            classes={{ root: classes.textField }}
          />
          <Divider />
        </section>
        <div style={baseStyles.marginTop}>
          {deleteButton}
          <Continue
            text="Save Medication"
            hoverColor={colors.highlightDarker}
            onClick={this.handleSave}
          />
          {displayedError}
        </div>
      </Layout>
    );
  }
}

function mapStateToProps(state, props) {
  const { medicationHx, proForms: { diabetesInitial } } = state;
  const medicationId = get(props, 'location.query.medication_hx_id', null);

  return {
    medicationId,
    medication: medicationHx.data[medicationId],
    addError: medicationHx.addError,
    deleteError: medicationHx.deleteError,
    editError: medicationHx.editError,
    diabetesInitial,
  };
}

Medication.defaultProps = {
  medication: null,
  medicattionId: null,
};

Medication.propTypes = {
  medication: PropTypes.object,
  medicationId: PropTypes.string,
};

export default connect(mapStateToProps, {
  readMedicationHx,
  editMedicationHx,
  addMedicationHx,
  deleteMedicationHx,
})(withStyles(classStyles)(Medication));
