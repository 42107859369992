import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { flatten } from 'lodash';

import Page from './page';
import { colors } from '../lib/styles';
import AppBar from '../components/app-bar';
import Continue from '../components/continue';
import { updatePRO, submitPRO } from '../state/pro-forms';

const styles = {
  instructions: {
    fontSize: '1.3rem',
  },
  image: {
    height: 120,
    padding: '15px 60px',
    textAlign: 'center',
  },
  linearProgress: {
    borderRadius: 0,
  },
  pageIndicator: {
    color: 'grey',
    display: 'inline-block',
    fontSize: 13,
  },
  questionnaire: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    position: 'relative',
    minHeight: '350px',
  },
  title: {
    color: 'black',
    fontSize: '1.5rem',
    fontWeight: 800,
    textTransform: 'uppercase',
    alignSelf: 'center',
  },
  date: {
    width: '150px',
    alignSelf: 'center',
    margin: '20px',
  },
  titleGroup: {
    margin: 25,
    textAlign: 'center',
  },
  error: {
    color: colors.errorRed,
    alignSelf: 'center',
  },
  text: {
    fontSize: '1.25rem',
  },
};

class ANSPE4 extends Page {
  state = {
    submitting: false,
  }

  onSubmission = (last) => {
    if (this.state.submitting) return;
    const recommend = flatten(this.props.anspe6);
    recommend.push(last);
    const { user } = this.props;
    const { id } = this.props.location.query;
    this.setState({ submitting: true });
    submitPRO({ userId: user.id, id }, {
      pro_type: 'ANSPE-6',
      pro_data: { data: recommend },
    }, user)
      .then(() => this.forward())
      .catch(() => this.setState({ submitting: false }));
  }

  render() {
    return (
      <div>
        <AppBar
          headerNode="ANSPE-6"
          noLeftNode={true}
        />
        <div style={styles.questionnaire}>
          <div style={styles.titleGroup}>
            <p style={styles.text}>Would you recommend this procedure to other people suffering similar pain symptoms?</p>
          </div>
          <Continue
            text="Yes"
            onClick={() => {
              this.onSubmission(1);
            }}
            btnStyle={{ marginTop: '50px', alignSelf: 'center' }}
          />
          <Continue
            text="No"
            onClick={() => {
              this.onSubmission(0);
            }}
            btnStyle={{ marginTop: '50px', alignSelf: 'center' }}
          />
        </div>
      </div>
    );
  }
}


function mapStateToProps(state) {
  const { user, proForms: { anspe6 } } = state;
  return { user, anspe6 };
}

ANSPE4.proptypes = {
  anspe6: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
  updatePRO: PropTypes.func.isRequired,
  submitPRO: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { updatePRO, submitPRO })(ANSPE4);
