import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { noop } from 'lodash';

import Informed from '../components/informed';
import AppBar from '../components/app-bar';

import { login } from '../state/user';
import { colors } from '../lib/styles';
import { apiFetch } from '../lib/fetch';
import brand from '../lib/brand';

import AuthLayout from '../layouts/auth';

function mapStateToProps(state) {
  const { user } = state;
  return { user };
}

function mapDispatchToProps(dispatch) {
  const actionCreators = { login };

  return bindActionCreators(actionCreators, dispatch);
}

const baseStyles = {
  bottomText: {
    color: colors.primaryColor,
    cursor: 'pointer',
    textDecoration: 'none',
    fontSize: '14px',
  },
  btnStyle: {
    marginTop: '20px',
    width: '80vw',
    maxWidth: '445px',
    marginBottom: '30px',
    borderRadius: '5px',
    backgroundColor: colors.primaryColor,
    fontSize: '15px',
    color: colors.white,
  },
  field: {
    height: '70px',
    padding: '10px',
  },
  shrinkStyle: {
    display: 'none',
  },
  textFieldLabel: {
    color: colors.highlightMedium,
    fontSize: '18px',
    marginLeft: '10px',
  },
  textFieldInput: {
    backgroundColor: colors.white,
    color: colors.primaryColor,
    padding: '10px',
  },
  link: {
    color: colors.primaryColor,
  },
  h2: {
    margin: '0px auto 60px',
    fontSize: '1.5rem',
  },
  logo: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '30px',
    position: 'relative',
    top: '45px',
    width: '100%',
  },
};

export class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: '',
      msg: '',
      email: '',
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleLogin = this.handleLogin.bind(this);
    this.handleForgotPassword = this.handleForgotPassword.bind(this);
    this.handleUpdateEmail = this.handleChange.bind(this, 'email');
  }

  handleChange(field, evt) {
    this.setState({ [field]: evt.target.value });
  }

  handleLogin() {
    this.setState({
      error: '',
      msg: '',
      password: '',
    });
    this.props.login(this.state.username, this.state.password)
      .then(() => {
        this.props.router.push('/app');
      })
      .catch(() => {
        this.setState({ error: 'Invalid Username or Password' });
      });
  }

  handleForgotPassword() {
    const resetPayload = {
      reset_type: 'PATIENT',
      email: this.state.email,
      source_id: brand.source_id,
    };
    const requestOptions = {
      body: resetPayload,
      method: 'POST',
    };

    return apiFetch('/reset', requestOptions)
      .then((res) => {
        return this.setState({
          email: '',
          error: '',
          msg: 'Please check your email for additional instructions',
        });
      })
      .catch(() => {
        this.setState({
          email: '',
          error: 'An error occurred. Please try again',
        });
      });
  }

  render() {
    return (
      <div>
        <AppBar
          backButtonOnClick={() => this.props.router.goBack()}
          headerNode="Reset Password"
        />
        <div style={baseStyles.logo}>
          <Informed width="20rem" />
        </div>
        <AuthLayout
          contentBoxStyle={baseStyles.contentBox}
          error={this.state.error}
          msg={this.state.msg}
        >
          <h2 style={baseStyles.h2}>Get Reset Link</h2>
          <TextField
            placeholder="email address"
            InputProps={{
              style: { fontSize: '13px' },
            }}
            onChange={this.handleUpdateEmail}
            fullWidth
            style={baseStyles.field}
            value={this.state.email}
            type="email"
          />
          <Button
            style={baseStyles.btnStyle}
            onClick={this.handleForgotPassword}
            disabled={false}
          >
            Reset Password
          </Button>
          <p onClick={this.showLogin} style={baseStyles.bottomText}>
            <Link
              style={baseStyles.link}
              to="/"
            >Cancel
            </Link>
          </p>
        </AuthLayout>
      </div>
    );
  }
}

Login.defaultProps = {
  login: noop,
  router: {},
};

Login.propTypes = {
  login: PropTypes.func,
  router: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
