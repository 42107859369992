import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get, includes, flatten } from 'lodash';

import { colors } from '../lib/styles';
import Page from './page';
import ButtonPRO from '../components/button-pro';
import { updatePRO, submitPRO } from '../state/pro-forms';

const buttonLabels = ['None', 'Mild', 'Moderate', 'Severe', 'Extreme'];

const options = [
  {
    question: 'Sitting',
    label: buttonLabels,
  },
  {
    question: 'Getting on/off toilet',
    label: buttonLabels,
  },
  {
    question: 'Heavy domestic duties (mowing the lawn, lifting heavy grocery bags)',
    label: buttonLabels,
  },
  {
    question: 'Light domestic duties (such as tidying a room, dusting, cooking)',
    label: buttonLabels,
  },
];
const { questionBackground } = colors;

class WOMAC6 extends Page {
  state = {
    submitting: false,
  }

  onClickBackButton = () => {
    this.backward();
  }

  onSubmission = () => {
    if (this.state.submitting) return;
    const data = flatten(this.props.womac);
    const { user } = this.props;
    const { id } = this.props.location.query;
    this.setState({ submitting: true });
    this.props.submitPRO({ userId: user.id, id }, {
      pro_type: `WOMAC-${this.props.route.type.toUpperCase()}`,
      pro_data: { data },
    }, user)
      .then(() => this.forward())
      .catch(() => this.setState({ submitting: false }));
  }

  render() {
    const page = get(this.props.womac, this.getTrackIndex(), [null, null, null, null]);
    const value = includes(page, null) ? null : 0;
    return (
      <ButtonPRO
        backgroundColor={questionBackground}
        currentPage={this.getCurrentPage()}
        onClickBackButton={() => this.props.router.goBack()}
        onChange={this.props.updatePRO}
        onSubmission={this.onSubmission}
        options={options}
        instructions={`The following questions concern your physical function.  By this we mean your ability to move around and to look after yourself. For each of the following activities, please indicate the degree of difficulty you have experienced in the last 24 hours, in your ${this.props.route.type}.`}
        title="What degree of difficulty do you have with:"
        header="Physical Function"
        totalPages={this.getTotalPages()}
        trackIndex={this.getTrackIndex()}
        value={value}
        pro={page}
        type={this.props.type}
      />
    );
  }
}

function mapStateToProps(state, ownProps) {
  const { proForms, user } = state;
  const { type } = ownProps.route;
  const womacType = `womac${type}`;
  return { womac: proForms[womacType], user, type: womacType };
}

WOMAC6.propTypes = {
  womac: PropTypes.array.isRequired,
  updatePRO: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { updatePRO, submitPRO })(WOMAC6);
