import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';

import { withStyles } from '@material-ui/core/styles';
import AppBar from '../components/app-bar';

import Page from './page';
import { updatePRO } from '../state/pro-forms';

const styles = {
  titleGroup: {
    margin: 25,
  },
  text: {
    fontSize: '2rem',
    textAlign: 'center',
  },
  button: {
    margin: 5,
    width: '100%',
    height: 40,
    fontSize: 16,
  },
};

class ContinuousBPDataCollectionCameraMode extends Page {
  handleContinue = (method) => {
    this.props.updatePRO({
      type: 'continuousBPDataCollection',
      position: this.getTrackIndex(),
      value: { collectionMethod: method },
    });

    this.forwardWithQuery(this.props.location.query);
  }

  render() {
    return (
      <Fragment>
        <AppBar
          headerNode="Measurements"
          noLeftNode={true}
        />
        <LinearProgress
          variant="determinate"
          value={(this.getCurrentPage() / this.getTotalPages()) * 100}
        />

        <div style={styles.titleGroup}>
          <p style={styles.text}>Please select which camera(s) you would like to use for this data collection session.</p>
          <div style={styles.titleGroup}>
            <Button style={styles.button} variant="contained" color="primary" onClick={() => this.handleContinue('front')}>Front Camera BP</Button>
            <Button style={styles.button} variant="contained" color="primary" onClick={() => this.handleContinue('rear')}>Rear Camera BP</Button>
            <Button style={styles.button} variant="contained" color="primary" onClick={() => this.handleContinue('dual')}>Both Camera BP</Button>
          </div>
        </div>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { user, proForms: { continuousBPDataCollection } } = state;
  return {
    user,
    userId: user.id,
    continuousBPDataCollection,
  };
}

ContinuousBPDataCollectionCameraMode.proptypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  route: PropTypes.object.isRequired,
  updatePRO: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { updatePRO })(withStyles(styles)(ContinuousBPDataCollectionCameraMode));
