import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { replace } from 'lodash';

import Page from './page';
import { colors } from '../lib/styles';
import AppBar from '../components/app-bar';
import Continue from '../components/continue';
import { updatePRO } from '../state/pro-forms';

const styles = {
  instructions: {
    fontSize: '1.3rem',
  },
  linearProgress: {
    borderRadius: 0,
  },
  pageIndicator: {
    color: 'grey',
    display: 'inline-block',
    fontSize: 13,
  },
  questionnaire: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    position: 'relative',
    minHeight: '350px',
  },
  title: {
    color: 'black',
    fontSize: '1.5rem',
    fontWeight: 800,
    textTransform: 'uppercase',
    alignSelf: 'center',
  },
  date: {
    width: '150px',
    alignSelf: 'center',
    margin: '20px',
  },
  titleGroup: {
    margin: 25,
    textAlign: 'center',
  },
  error: {
    color: colors.errorRed,
    alignSelf: 'center',
  },
  text: {
    fontSize: '1.25rem',
  },
};

class Covid19_2 extends Page {
  render() {
    return (
      <div>
        <AppBar
          backButtonOnClick={() => this.props.router.goBack()}
          headerNode="COVID-19 Assessment"
        />
        <div style={styles.questionnaire}>
          <div style={styles.titleGroup}>
            <p style={styles.text}>Do you have a fever?</p>
          </div>
          <Continue
            text="No"
            onClick={() => {
              this.props.updatePRO({ position: 1, type: 'covid19', value: 'No' });
              this.props.updatePRO({ position: 2, type: 'covid19', value: 'N/A' });
              const path = replace(this.props.location.pathname, 'COVID-19-2', 'COVID-19-4');
              this.props.router.push(`${path}${this.props.location.search}`);
            }}
            btnStyle={{ marginTop: '50px', alignSelf: 'center' }}
          />
          <Continue
            text="Yes"
            onClick={() => {
              this.props.updatePRO({ position: 1, type: 'covid19', value: 'Yes' });
              this.forwardWithQuery(this.props.location.query);
            }}
            btnStyle={{ marginTop: '50px', alignSelf: 'center' }}
          />
        </div>
      </div>
    );
  }
}


function mapStateToProps(state) {
  const { proForms: { covid19 } } = state;
  return { covid19 };
}

Covid19_2.propTypes = {
  covid19: PropTypes.array.isRequired,
  updatePRO: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { updatePRO })(Covid19_2);
