import React from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeadset, faBookOpen, faWifi } from '@fortawesome/free-solid-svg-icons';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import 'moment/min/locales';
import { isAndroid } from 'common-ui';

import AppBar from '../components/app-bar';
import Page from './page';
import StickyHeader from '../components/sticky-header';
import Layout from '../layouts/common';
import { colors } from '../lib/styles';
import { apiFetch } from '../lib/fetch';
import i18nTranslator, { title, instructions, subtitle } from '../lib/i18next';

import { submitPRO } from '../state/pro-forms';

const getVirtualExamLink = (notificationId) => {
  return `${window.location.origin}/notifications/${notificationId}`;
};

const styles = {
  page: {
    padding: '60px 5px 5px 5px',
    color: colors.primaryColor,
    backgroundColor: colors.white,
  },
  button: {
    width: '100%',
    margin: '10px 0',
    fontSize: '19px',
  },
  gridBtn: {
    fontSize: '19px',
    padding: '8px 0',
  },
  text: {
    color: colors.black,
    fontSize: '19px',
  },
  icon: {
    width: '100px',
    height: '100px',
    color: 'black',
  },
  btnText: {
    fontSize: '15px',
  },
  btnIcon: {
    width: '50px',
    height: '50px',
  },
};

class AppointmentConfirmation2 extends Page {
  constructor(props) {
    super(props);
    this.state = {
      event: {},
      ical: '',
      start_date: '',
      end_date: '',
      user_id: '',
      notification_id: '',
      clinic_name: '',
    };
  }

  componentDidMount() {
    const { appointmentConfirmation } = this.props;

    this.setState(appointmentConfirmation[0]);
  }

  handleNetworkConnection = async () => {
    this.forwardWithQuery(this.props.location.query);
  };

  handleContinue = async () => {
    const { user_id, notification_id } = this.state;
    const options = { method: 'PUT', body: { dismissed: true } };
    await apiFetch(`/users/${user_id}/notifications/${notification_id}`, options);
    this.finishTrack();
  };

  handleOpenInstructions = async () => {
    const { primary_language } = this.props;
    const baseURL = process.env.REACT_APP_PUBLIC_URL || 'http://localhost:3010';
    const fileName = `TelemedicineAppointmentInstructionsForUse${primary_language === 'es' ? '_es' : ''}.pdf`;
    const fileURL = `${baseURL}/img/${fileName}`;

    try {
      const res = await fetch(fileURL);
      const blob = await res.blob();

      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = fileName;
      link.click();
    } catch (err) {
      console.error('Error fetching instructions:', err);
    }
  };

  render() {
    const { appointment_notification_id, event } = this.state;
    const start_date = moment(this.state.start_date).format('YYYYMMDDTHHmmssZ');
    const end_date = moment(this.state.end_date).format('YYYYMMDDTHHmmssZ');
    const androidLink = `https://www.google.com/calendar/render?action=TEMPLATE&text=${event.title}&dates=${start_date}/${end_date}&details=${encodeURIComponent(getVirtualExamLink(appointment_notification_id))}`;
    const icsLink = encodeURI(`data:text/calendar,${this.state.ical}`);

    return (
      <Layout>
        <div style={styles.page}>
          <StickyHeader>
            <AppBar
              backButtonOnClick={() => this.props.router.goBack()}
              headerNode={title('appointmentConfirmation2')}
            />
          </StickyHeader>
          <div style={{ marginTop: '10%' }}>
            <FontAwesomeIcon icon={faHeadset} style={styles.icon} />
            <p style={styles.text}>{subtitle('appointmentConfirmation2')}</p>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <Button
                  style={styles.gridBtn}
                  variant="outlined"
                  color="primary"
                  onClick={() => this.handleOpenInstructions()}
                >
                  <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                    <FontAwesomeIcon icon={faBookOpen} style={styles.btnIcon} />
                    {instructions('appointmentConfirmation2')}
                  </div>
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  style={styles.gridBtn}
                  variant="outlined"
                  color="primary"
                  onClick={() => this.handleNetworkConnection()}
                >
                  <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                    <FontAwesomeIcon icon={faWifi} style={styles.btnIcon} />
                    {i18nTranslator('connection', 'appointmentConfirmation2')}
                  </div>
                </Button>
              </Grid>
            </Grid>
            <Button
              style={styles.button}
              color="primary"
              target="_blank"
              href={isAndroid ? androidLink : icsLink}
            >
              <span style={styles.btnText}>{i18nTranslator('download', 'appointmentConfirmation2')}</span>
            </Button>
            <Button
              variant="contained"
              color="primary"
              style={styles.button}
              onClick={() => this.handleContinue()}
            >
              {i18nTranslator('continue', 'appointmentConfirmation2')}
            </Button>
          </div>
        </div>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  const { user: { primary_language }, proForms: { appointmentConfirmation } } = state;
  return {
    appointmentConfirmation,
    primary_language,
  };
}

export default connect(mapStateToProps, { submitPRO })(AppointmentConfirmation2);
