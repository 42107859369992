import React from 'react';
import { LinearProgress } from '@material-ui/core';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import AppBar from '../components/app-bar';
import Page from './page';
import QuestionLayout from '../layouts/question';
import YesNoButtonSet from '../components/yes-no-button-set';
import { colors } from '../lib/styles';
import { updatePRO } from '../state/pro-forms';
import { SaltShaker } from '../lib/icons';

const styles = {
  contentWrapper: {
    flexGrow: 1,
    overflowX: 'hidden',
    position: 'relative',
  },
  imgRow: {
    display: 'flex',
    justifyContent: 'center',
    padding: '15px 60px',
  },
  imageWrapper: {
    width: 100,
  },
  questionnaireContainer: {
    paddingLeft: 20,
    paddingRight: 20,
  },
  questionText: {
    fontSize: '2rem',
  },
};

class HypertensionAssessment12 extends Page {
  constructor(props) {
    super(props);

    this.state = {
      limitsSodium: null,
      monitorsSodium: null,
    };
  }

  handleChangeLimitsSodium = (value) => {
    this.setState({ limitsSodium: value });
  };

  handleChangeMonitorsSodium = (value) => {
    this.setState({ monitorsSodium: value });
  };

  handleClickNext = () => {
    const {
      limitsSodium,
      monitorsSodium,
    } = this.state;

    const value = {
      monitorsSodium,
    };

    if (monitorsSodium === 'Y') value.limitsSodium = limitsSodium;

    this.props.updatePRO({
      type: 'hypertensionAssessment',
      position: this.getTrackIndex(),
      value,
    });

    this.forwardWithQuery(this.props.location.query);
  }

  render() {
    const { classes } = this.props;
    const {
      limitsSodium,
      monitorsSodium,
    } = this.state;

    const continueDisabled = monitorsSodium === null
      || (monitorsSodium === 'Y' && limitsSodium === null);

    return (
      <QuestionLayout
        primaryBtnLabel="Next"
        primaryBtnOnTouchTap={this.handleClickNext}
        primaryBtnDisabled={continueDisabled}
        style={{ backgroundColor: colors.questionBackground }}
      >
        <AppBar
          headerNode="Hypertension"
          backButtonOnClick={() => this.props.router.goBack()}
        />
        <LinearProgress
          variant="determinate"
          value={(this.getCurrentPage() / this.getTotalPages()) * 100}
          classes={{ bar: classes.bar }}
        />
        <div className={classes.contentWrapper}>
          <div className={classes.imgRow}>
            <div className={classes.imageWrapper}>
              <SaltShaker />
            </div>
          </div>
          <div className={classes.questionnaireContainer}>
            <YesNoButtonSet
              isYes={monitorsSodium === 'Y'}
              isNo={monitorsSodium === 'N'}
              onClickNo={() => this.handleChangeMonitorsSodium('N')}
              onClickYes={() => this.handleChangeMonitorsSodium('Y')}
              questionText="Do you monitor your sodium intake?"
            />
            {monitorsSodium === 'Y' ? (
              <YesNoButtonSet
                isYes={limitsSodium === 'Y'}
                isNo={limitsSodium === 'N'}
                onClickNo={() => this.handleChangeLimitsSodium('N')}
                onClickYes={() => this.handleChangeLimitsSodium('Y')}
                questionText="Do you limit your sodium consumption to the guidelines of less than 2,000 mg per day?"
              />
            ) : null}
          </div>
        </div>
      </QuestionLayout>
    );
  }
}

export default connect(null, { updatePRO })(withStyles(styles)(HypertensionAssessment12));
