import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Notification as NotificationIcon } from '../lib/icons';
import { colors } from '../lib/styles';

const styles = {
  container: {
    cursor: 'pointer',
    height: '8.5625rem',
    boxShadow: `inset 0 -1px 0 0 ${colors.boxShadowOutline}`,
    backgroundColor: colors.white,
    display: 'flex',
  },
  icon: {
    margin: '3.2rem 3.0625rem 0px 0px',
    height: '2.1875rem',
    width: '3.25rem',
  },
  secondaryText: {
    color: colors.secondaryColor,
    fontSize: '1.25rem',
    height: '2rem',
    lineHeight: 1.46,
    letterSpacing: '-0.3px',
    textAlign: 'left',
  },
  primaryText: {
    height: '2.5rem',
    fontSize: '1.75rem',
    lineHeight: 1.29,
    letterSpacing: '-0.8px',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    color: colors.black,
    textAlign: 'left',
  },
  textContainer: {
    flex: 1,
    margin: '2rem 0px 0px 1.9375rem',
  },
};

class Notification extends PureComponent {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    const { id, type, meta } = this.props;
    this.props.onClickNotification(id, type, meta);
  }

  render() {
    const { message, secondary_message } = this.props;
    return (
      <div
        onKeyPress={this.handleClick}
        onClick={this.handleClick}
        role="button"
        style={styles.container}
        tabIndex={0}
      >
        <div style={styles.textContainer}>
          <div style={styles.secondaryText}>{secondary_message}</div>
          <div style={styles.primaryText}>{message}</div>
        </div>
        <NotificationIcon style={styles.icon} />
      </div>
    );
  }
}

Notification.propTypes = {
  message: PropTypes.string.isRequired,
  secondary_message: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

export default Notification;
