import React from 'react';
import { connect } from 'react-redux';
import { debounce } from 'lodash';
import rawr from 'rawr';
import transport from 'rawr/transports/worker';
import MobileDetect from 'mobile-detect';
import LinearProgress from '@material-ui/core/LinearProgress';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';

import Page from './page';
import HiddenContent from '../components/hidden-content';

import { updatePRO } from '../state/pro-forms';
import { startVideo } from '../lib/createAnswer';
import { colors } from '../lib/styles';
import { throttledReset } from '../initializers/activity';

const styles = {
  fab: {
    margin: '0px',
    top: '20px',
    right: '20px',
    bottom: 'auto',
    left: 'auto',
    position: 'fixed',
  },
  heartIcon: {
    color: colors.healthyRed,
    fontSize: '25px',
  },
  logo: {
    height: '200px',
    paddingTop: '100px',
    width: '200px',
  },
  divider: {
    margin: '30px 0px',
  },
  waitingView: {
    height: '450px',
    maxHeight: '75vh',
    maxWidth: '80%',
    margin: '0px auto 10rem',
    textAlign: 'center',
    width: '100%',
  },
  patientVideo: {
    minHeight: '450px',
    height: '100%',
    maxWidth: 1000,
    objectFit: 'cover',
    transform: 'scaleX(-1)',
    width: '100%',
  },
  readingRate: {
    background: 'rgba(255, 255, 255, 0.5)',
    borderRadius: 5,
    fontSize: 16,
    marginBottom: 20,
    marginLeft: 10,
    marginRight: 10,
    padding: 10,
    textAlign: 'center',
  },
  loadingModel: {
    background: 'rgba(255, 255, 255, 0.5)',
    borderRadius: 5,
    fontSize: 16,
    marginLeft: 10,
    marginRight: 10,
    marginTop: 40,
    padding: 10,
  },
  heart: {
    marginLeft: '5px',
    position: 'relative',
    top: '-34px',
  },
  heartRate: {
    flex: 1,
    fontSize: '1em',
    margin: '0px 20px',
    width: '20%',
  },
  disabledVital: {
    flex: 1,
    color: '#9D9D9D',
    fontSize: '1em',
    margin: '0px 20px',
    width: '20%',
  },
  vitals: {
    display: 'flex',
    fontSize: '1.75em',
    padding: '0px 10px',
    justifyContent: 'center',
  },
  vitalsLoadingIndicator: {
    fontSize: '24px',
    height: '10px',
    marginTop: '-20px',
  },
  breath: {
    position: 'relative',
    top: '-63px',
  },
  breathRate: {
    flex: 1,
    fontSize: '1em',
    marginRight: '20px',
    color: 'blue',
    width: '20%',
  },
  container: {
    backgroundColor: colors.white,
    height: '100%',
  },
  hidden: {
    display: 'none',
  },
  topSection: {
    backgroundColor: colors.white,
    height: '100%',
    position: 'relative',
  },
  progressBad: {
    color: 'red',
  },
  progressGood: {
    color: 'green',
  },
  progressNone: {
    color: 'grey',
  },
  vitalsDescription: {
    fontSize: '.5em',
  },
  patientCanvas: {
    display: 'none',
    transform: 'scaleX(-1)',
    width: '100%',
  },
  videoContainer: {
    margin: '0px auto',
    height: '100%',
    maxWidth: '540px',
    width: '100%',
  },
  waitingInstructions: {
    fontSize: '2rem',
    fontWeight: 600,
  },
  icon: {
    width: 25,
    height: 25,
    position: 'absolute',
  },
  iconWrapper: {
    alignItems: 'center',
    background: '#fff',
    borderRadius: '50%',
    boxShadow: '0 2px 3px lightgrey',
    display: 'flex',
    height: 60,
    justifyContent: 'center',
    position: 'absolute',
    left: 0,
    top: 0,
    transform: 'translateX(10px)',
    width: 60,
    zIndex: 2,
  },
  iconBackground: {
    alignItems: 'center',
    background: '#fff',
    borderRadius: '50%',
    boxShadow: '0 0 4px lightgrey',
    display: 'flex',
    height: 50,
    justifyContent: 'center',
    left: 5,
    position: 'absolute',
    top: 5,
    width: 50,
    zIndex: 2,
  },
  vitalDisplay: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 35,
  },
  vitalDisplayUnits: {
    fontSize: '1.25rem',
    marginBottom: 5,
    marginTop: 5,
  },
  vitalDisplayValue: {
    fontSize: '2rem',
    fontWeight: 'bold',
  },
  vitalDisplayWrapper: {
    backgroundColor: 'rgba(0,0,0,0)',
    marginLeft: 5,
    marginRight: 5,
    paddingTop: 30,
    position: 'relative',
    width: 80,
  },
  vitalsBorderBlue: {
    borderColor: '#1e7bcd',
  },
  vitalsBorderRed: {
    borderColor: '#cd3636',
  },
  vitalsIconBlue: {
    color: '#1e7bcd',
  },
  vitalsIconRed: {
    color: '#cd3636',
  },
  nonVideoContentContainer: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
  },
  circularProgress: {
    position: 'absolute',
    zIndex: 1,
  },
  calibratingCameraMessage: {
    background: colors.primaryColor,
    color: '#fff',
    fontSize: 16,
    marginTop: 40,
    padding: 10,
    textAlign: 'center',
  },
};

class VideoVitals2 extends Page {
  constructor(props) {
    super(props);
    this.state = {
      cameraState: '',
      cameraError: '',
      me: {},
      signalPercent: 0,
      connecting: false,
      workerPct: 0,
      workerReady: false,
      md: null,
      brSignalPercent: 0,
      hrSignalPercent: 0,
      BR: null,
      HR: null,
    };

    this.patientCanvas = React.createRef();
    this.patientVideo = React.createRef();
    this.videoContainer = React.createRef();
  }

  componentDidMount = async () => {
    const md = new MobileDetect(window.navigator.userAgent);
    this.setState({ md });
    this.startCamera();

    this.activityTimer = setInterval(throttledReset, 5000);

    const webWorker = new Worker('/workers-4vitals/patient-worker.js');
    webWorker.onerror = (err) => {
      console.error('webworker error', err);
    };
    const rawrPeer = rawr({ transport: transport(webWorker) });
    const { detectVitals } = rawrPeer.methods;
    this.detectVitals = detectVitals;

    rawrPeer.notifications.onready(() => {
      this.setState({ workerReady: true });
    });

    rawrPeer.notifications.ondownloadingPct((data) => {
      this.setState({ workerPct: data.pct });
    });
  }

  componentWillUnmount = () => {
    this.endCall();
  }

  endCall = () => {
    try {
      clearInterval(this.heartbeatInterval);
      clearInterval(this.activityTimer);
      const videoElement = this.patientVideo.current;
      this.state.stream.getTracks().forEach(t => t.stop());
      videoElement.srcObject.getTracks().forEach(t => t.stop());
      videoElement.srcObject = null;
    } catch (e) {
      console.error('error ending call properly: ', e);
    }
  }

  startCamera = async () => {
    const { me } = this.state;

    try {
      const stream = await startVideo(me, { audio: false });
      this.setState({ cameraState: 'started', stream, me });
      const patientVideo = this.patientVideo.current; // document.createElement('video');

      patientVideo.addEventListener('loadeddata', () => {
        const canvas = this.patientCanvas.current;
        canvas.height = patientVideo.videoHeight;
        canvas.width = patientVideo.videoWidth;
        let lastTracking;

        const onframe = async () => {
          try {
            const curCanvas = this.patientCanvas.current;
            const ctx = curCanvas.getContext('2d');
            if (patientVideo.videoWidth > 0) {
              ctx.drawImage(patientVideo, 0, 0, patientVideo.videoWidth, patientVideo.videoHeight);
              const imgData = ctx.getImageData(0, 0, patientVideo.videoWidth, patientVideo.videoHeight);
              if (lastTracking) {
                lastTracking.forEach((t) => {
                  if (t.boxes) {
                    t.boxes.forEach((b) => {
                      if (t.type === 'HR_FACE') {
                        ctx.strokeStyle = '#f00';
                      } else if (t.type === 'BR_FACE') {
                        ctx.strokeStyle = '#00f';
                      }
                      ctx.beginPath();
                      ctx.moveTo(b.xmin, b.ymin);
                      ctx.lineTo(b.xmax, b.ymin);
                      ctx.lineTo(b.xmax, b.ymax);
                      ctx.lineTo(b.xmin, b.ymax);
                      ctx.closePath();
                      ctx.stroke();
                    });
                  }
                });
              }
              if (this.state.workerReady) {
                const start = Date.now();
                const frameInfo = {
                  width: patientVideo.videoWidth,
                  height: patientVideo.videoHeight,
                  timestamp: start,
                };
                // pass true to draw on image
                // eslint-disable-next-line
                const result = await this.detectVitals(frameInfo, imgData);
                const { signs, trackingInfo } = result.data;
                let { brSignalPercent, hrSignalPercent } = signs;
                if (signs) {
                  if (hrSignalPercent === 1 && brSignalPercent === 1) {
                    this.props.updatePRO({
                      type: 'videoVitals',
                      position: this.getTrackIndex(),
                      value: {
                        BR: signs.BR,
                        HR: signs.HR,
                      },
                    });
                    this.forward();
                    return;
                  }
                  const signalPercent = Math.round(signs.hrSignalPercent * 100);
                  brSignalPercent = Math.round(brSignalPercent * 100);
                  hrSignalPercent = Math.round(hrSignalPercent * 100);
                  this.debouncedUpdateVitals({
                    signalPercent,
                    brSignalPercent,
                    hrSignalPercent,
                    HR: signs.HR,
                    BR: signs.BR,
                  });
                  lastTracking = trackingInfo;
                } else {
                  this.setState({ signalPercent: 0 });
                }
              }

              ctx.putImageData(imgData, 0, 0, 0, 0, patientVideo.videoWidth, patientVideo.videoHeight);
            }
          } catch (err) {
            // This is happening... like a lot
          }
          requestAnimationFrame(onframe);
        };

        // wait half a second because first few frames are always black when turning on camera.
        setTimeout(() => {
          requestAnimationFrame(onframe);
        }, 500);
      });

      patientVideo.srcObject = me.stream;

      try {
        if (this.state.md.os() === 'iOS' && this.state.md.mobile()) {
          console.info('not manually playing video because', this.state.md.os(), this.state.md.mobile());
        } else {
          await patientVideo.play();
        }
      } catch (err) {
        console.error('error starting video', err);
      }
    } catch (err) {
      this.setState({ cameraError: err });
    }
  }

  debouncedUpdateVitals = debounce((update) => {
    this.setState(update);
  }, 2000, { leading: true, maxWait: 2000 })

  render() {
    const { classes } = this.props;
    const {
      cameraError,
      signalPercent,
      brSignalPercent,
      hrSignalPercent,
    } = this.state;
    const readingVitals = signalPercent < 100;
    const vitalsStyle = styles.vitals;

    return (
      <div className={classes.container}>
        <section className={classes.topSection}>
          <div id="videoContainer" className={classes.videoContainer} ref={this.videoContainer} >
            <video id="patientVideo" ref={this.patientVideo} playsInline autoPlay className={classes.patientVideo} muted />
            <canvas id="patientCanvas" ref={this.patientCanvas} style={styles.patientCanvas} />
          </div>
          <div className={classes.nonVideoContentContainer}>
            {cameraError ? (
              <div><span>Unable to access camera: {cameraError.toString()}</span><br /></div>
            ) : ''}
            <div style={vitalsStyle}>
              <div className={classes.vitalDisplayWrapper}>
                <div className={`${classes.iconWrapper}`}>
                  <CircularProgress
                    className={`${classes.circularProgress} ${classes.vitalsIconRed}`}
                    size={62}
                    thickness={5}
                    variant="determinate"
                    value={hrSignalPercent}
                    style={{ transform: 'none' }}
                  />
                  <div className={classes.iconBackground}>
                    <img
                      className={classes.icon}
                      src="/img/heartbeat-solid.png"
                      alt=""
                    />
                  </div>
                </div>
                <Paper className={`${classes.vitalDisplay} ${classes.vitalsIconRed}`}>
                  <div className={classes.vitalDisplayValue}>
                    {this.state.HR ? this.state.HR : '--'}
                  </div>
                  <div className={classes.vitalDisplayUnits}>
                    HR
                  </div>
                </Paper>
              </div>
              <div className={classes.vitalDisplayWrapper}>
                <div className={`${classes.iconWrapper}`}>
                  <CircularProgress
                    className={`${classes.circularProgress} ${classes.vitalsIconBlue}`}
                    size={62}
                    thickness={5}
                    variant="determinate"
                    value={brSignalPercent}
                    style={{ transform: 'none' }}
                  />
                  <div className={classes.iconBackground}>
                    <img
                      className={classes.icon}
                      src="/img/lungs-solid.png"
                      alt=""
                    />
                  </div>
                </div>
                <Paper className={`${classes.vitalDisplay} ${classes.vitalsIconBlue}`}>
                  <div className={classes.vitalDisplayValue}>
                    {this.state.BR ? this.state.BR : '--'}
                  </div>
                  <div className={classes.vitalDisplayUnits}>
                    BR
                  </div>
                </Paper>
              </div>
            </div>
            <HiddenContent hidden={this.state.workerReady}>
              <div className={classes.loadingModel}>
                <span>Loading Model</span>
                <LinearProgress value={Math.round(this.state.workerPct * 100)} variant="determinate" />
              </div>
            </HiddenContent>
            <HiddenContent hidden={!readingVitals || !this.state.workerReady}>
              <div className={classes.calibratingCameraMessage}>
                Please hold still while the camera is calibrating.
              </div>
            </HiddenContent>
          </div>
        </section>
      </div>
    );
  }
}

export default connect(null, { updatePRO })(withStyles(styles)(VideoVitals2));
