import React from 'react';
import { connect } from 'react-redux';

import AppBar from '../components/app-bar';
import Page from './page';
import Layout from '../layouts/common';
import QuestionHeader from '../components/question-header';
import { colors } from '../lib/styles';

const baseStyles = {
  circles: {
    width: 75,
    height: 75,
    margin: 30,
    cursor: 'pointer',
  },
  paragraph: {
    fontSize: '2.2rem',
    color: colors.darkGrayText,
  },
};

class SurgeryQuestion extends Page {
  constructor(props) {
    super(props);
    this.handleSkip = this.handleSkip.bind(this);
  }

  componentWillMount() {
    // get this page's data
  }

  handleSkip() {
    this.props.router.push('');
  }

  render() {
    return (
      <Layout>
        <div>
          <AppBar
            backButtonOnClick={() => this.props.router.goBack()}
            headerNode="Surgery"
          />
          <QuestionHeader>Have You Ever Had Heart Surgery?</QuestionHeader>
          <div>
            <img src="/img/HeartSurgeryIcon.png" alt="" />
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <img
              src="/img/RedXCircle.png"
              style={baseStyles.circles}
              onClick={() => {
                this.skipNext();
              }}
              alt=""
            />
            <img
              src="/img/GreenCheckmarkCircle.png"
              style={baseStyles.circles}
              onClick={this.forward}
              alt=""
            />
          </div>
        </div>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state;
  return {
    user,
  };
}

export default connect(mapStateToProps, { })(SurgeryQuestion);
