import { promiseHandler, resetReducer } from 'cooldux';
import { get, isUndefined, omit, omitBy } from 'lodash';

import { apiFetch } from '../lib/fetch';

const { browseStart, browseEnd, browseError, browseHandler } = promiseHandler('browse', 'conditionsHx');
const { editStart, editEnd, editError, editHandler } = promiseHandler('edit', 'conditionsHx');
const { addStart, addEnd, addError, addHandler } = promiseHandler('add', 'conditionsHx');
const { deleteStart, deleteEnd, deleteError, deleteHandler } = promiseHandler('delete', 'conditionsHx');

export function browseConditionsHx() {
  return function dispatcher(dispatch, getState) {
    const selfId = get(getState(), 'user.id', null);
    const promise = apiFetch(`/users/${selfId}/conditions_hx`);
    return browseHandler(promise, dispatch);
  };
}

export function editConditionHx(update) {
  return function dispatcher(dispatch, getState) {
    const selfId = get(getState(), 'user.id', null);
    const body = omit(update, ['id', 'condition_id', 'user_id']);
    const options = {
      method: 'PUT',
      body: omitBy(body, isUndefined),
    };
    const promise = apiFetch(`/users/${selfId}/conditions_hx/${update.id}`, options);
    return editHandler(promise, dispatch);
  };
}

export function addConditionHx(newConditionHx) {
  return function dispatcher(dispatch, getState) {
    const selfId = get(getState(), 'user.id', null);
    const options = {
      method: 'POST',
      body: omitBy(newConditionHx, isUndefined),
    };
    const promise = apiFetch(`/users/${selfId}/conditions_hx`, options);
    return addHandler(promise, dispatch);
  };
}

export function deleteConditionHx(conditionHxId) {
  return function dispatcher(dispatch, getState) {
    const selfId = get(getState(), 'user.id', null);
    const options = {
      method: 'DELETE',
    };
    const promise = apiFetch(`/users/${selfId}/conditions_hx/${conditionHxId}`, options)
      .then(() => conditionHxId);

    return deleteHandler(promise, dispatch);
  };
}

const initialState = {
  browseError: null,
  addError: null,
  editError: null,
  deleteError: null,
  browsePending: false,
  addPending: false,
  editPending: false,
  deletePending: false,
  data: [],
};

function finishBrowse(state, conditionsHx) {
  const data = {};
  conditionsHx.forEach((i) => {
    data[i.id] = i;
  });
  return { ...state, data, browsePending: false, browseError: null };
}

function finishEdit(state, item) {
  const data = { ...state.data, [item.id]: item };
  return { ...state, editPending: false, editError: null, data };
}

function finishAdd(state, item) {
  const data = { ...state.data, [item.id]: item };
  return { ...state, addPending: false, addError: null, data };
}

function finishDelete(state, conditionHxId) {
  return { ...state, deletePending: false, deleteError: null, data: omit(state.data, conditionHxId) };
}

const conditionsHx = resetReducer(initialState, (state = initialState, action) => {
  switch (action.type) {
    case browseStart.type:
      return { ...state, browsePending: true };
    case browseEnd.type:
      return finishBrowse(state, action.payload);
    case browseError.type:
      return { ...state, browsePending: false, browseError: action.payload };
    case addStart.type:
      return { ...state, addPending: true };
    case addEnd.type:
      return finishAdd(state, action.payload);
    case addError.type:
      return { ...state, addPending: false, addError: action.payload };
    case deleteStart.type:
      return { ...state, deletePending: true };
    case deleteEnd.type:
      return finishDelete(state, action.payload);
    case deleteError.type:
      return { ...state, deletePending: false, deleteError: action.payload };
    case editStart.type:
      return { ...state, editPending: true };
    case editEnd.type:
      return finishEdit(state, action.payload);
    case editError.type:
      return { ...state, editPending: false, editError: action.payload };
    default:
      return state;
  }
});

export default conditionsHx;
