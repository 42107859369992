
import React from 'react';
import PropTypes from 'prop-types';
import LinearProgress from '@material-ui/core/LinearProgress';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import AppBar from '../components/app-bar';
import Page from './page';
import QuestionLayout from '../layouts/question';

import { updatePRO } from '../state/pro-forms';

import { colors, fontSizing } from '../lib/styles';

const styles = {
  bar: {
    backgroundColor: colors.lightBrightBlue,
  },
  contentWrapper: {
    flexGrow: 1,
    fontSize: fontSizing.body,
    overflowX: 'hidden',
    paddingLeft: 20,
    paddingRight: 20,
    position: 'relative',
    textAlign: 'start',
  },
  title: {
    fontWeight: 'bold',
    textAlign: 'center',
  },
  instructionImages: {
    width: '100%',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    margin: 10,
  },
};

class HypertensionAssessment1 extends Page {
  handleClickNext = () => {
    this.props.updatePRO({
      type: 'hypertensionAssessmentDaily',
      position: this.getTrackIndex(),
      value: {
      },
    });
    this.forwardWithQuery(this.props.location.query);
  }

  render() {
    const { classes } = this.props;

    return (

      <QuestionLayout
        primaryBtnLabel="Next"
        primaryBtnOnTouchTap={this.handleClickNext}
        style={{ backgroundColor: colors.questionBackground }}
      >
        <AppBar
          headerNode="Instructions"
          backButtonOnClick={() => this.props.router.goBack()}
        />
        <LinearProgress
          variant="determinate"
          value={(this.getCurrentPage() / this.getTotalPages()) * 100}
          classes={{ bar: classes.bar }}
        />
        <div className={classes.contentWrapper}>
          <p className={classes.title}>Apply the Arm Cuff</p>
          <ol>
            <li>Remove any clothing from your upper left arm.</li>
            <li>Place the cuff about <strong>1 or 2 inches above</strong> your elbow. With the logo facing your elbow, align the monitor with your middle finger.</li>
            <li>Once in position, wrap the cuff around your upper arm to secure in place.</li>
          </ol>

          <img
            className={classes.instructionImages}
            src="/img/bp-arm.png"
            alt=""
          />

        </div>
      </QuestionLayout>
    );
  }
}

HypertensionAssessment1.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect(null, { updatePRO })(withStyles(styles)(HypertensionAssessment1));
