import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  Select,
} from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import { withStyles } from '@material-ui/core/styles';
import { map } from 'lodash';
import moment from 'moment';

import { colors } from '../lib/styles';

const baseStyles = {
  yearRow: {
    backgroundColor: 'lightGrey',
    textAlign: 'left',
    color: colors.primaryColor,
    fontSize: '1.4rem',
    textTransform: 'uppercase',
  },
};
let thisYear = new Date().getFullYear();
const years = map(Array(120), (a, i) => String(thisYear - i));
thisYear = String(thisYear);

const yearOptions = years.map((year) => {
  return <MenuItem key={year} value={year}>{year}</MenuItem>;
});

class SurgeryYearRow extends Component {
  constructor(props) {
    super(props);
    this.handleYearChange = this.handleYearChange.bind(this);
  }

  handleYearChange(evt, idx, val) {
    if (this.props.hx) {
      this.props.hx.year = evt.target.value;
      this.props.onChange(this.props.surgery.id, true, this.props.hx);
    }
  }

  render() {
    const { classes } = this.props;
    const selectedYear = moment.utc(this.props.hx.year).format('YYYY');

    return (
      <ListItem style={baseStyles.yearRow}>
        <ListItemText
          classes={{ primary: classes.yearRow }}
          primary={`Year of ${this.props.surgery.name} Surgery`}
        />
        <ListItemSecondaryAction>
          <FormControl disabled={this.props.disabled}>
            <Select
              onChange={this.handleYearChange}
              value={selectedYear}
            >
              {yearOptions}
            </Select>
          </FormControl>
        </ListItemSecondaryAction>
      </ListItem>
    );
  }
}

SurgeryYearRow.propTypes = {
  disabled: PropTypes.bool.isRequired,
  hx: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  surgery: PropTypes.object.isRequired,
};

export default withStyles(baseStyles)(SurgeryYearRow);
