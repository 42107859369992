import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { KeypadDate } from 'common-ui';

import LinearProgress from '../components/linear-progress';
import { colors } from '../lib/styles';
import Page from './page';
import { Headaches } from '../lib/icons';
import { updatePRO, submitPRO } from '../state/pro-forms';
import AppBar from '../components/app-bar';
import QuestionLayout from '../layouts/question';

const styles = {
  instructions: {
    fontSize: '1.3rem',
  },
  image: {
    height: 120,
    padding: '15px 60px',
    textAlign: 'center',
  },
  pageIndicator: {
    color: 'grey',
    display: 'inline-block',
    fontSize: 13,
  },
  questionnaire: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginLeft: 15,
    position: 'relative',
    minHeight: '150px',
  },
  slideContainer: {
    display: 'flex',
    flexDirection: 'column',
    color: colors.darkGrayText,
    fontSize: '12px',
  },
};

const { questionBackground } = colors;

class PrgEndo2 extends Page {
  state = {
    submitting: false,
  }

  onClickBackButton = () => {
    this.backward();
  }

  handleChangeSlider = (value) => {
    this.props.updatePRO({
      type: 'prgendo',
      position: this.getTrackIndex(),
      value,
    });
  }

  handleSubmission = () => {
    if (this.state.submitting) return;
    const { user } = this.props;
    const { id } = this.props.location.query;
    this.setState({ submitting: true });
    submitPRO({ userId: user.id, id }, {
      pro_type: 'PRG-ENDO',
      pro_data: { data: this.props.prgendo },
    }, user)
      .then(() => this.forward())
      .catch(() => this.setState({ submitting: false }));
  }

  render() {
    const currentPage = this.getCurrentPage();
    const totalPages = this.getTotalPages();
    const value = get(this.props.prgendo, this.getTrackIndex(), '');
    return (
      <QuestionLayout
        primaryBtnDisabled={value.replace(/[^0-9]/g, '').length < 8}
        primaryBtnLabel="Next"
        primaryBtnOnTouchTap={this.handleSubmission}
        style={{ background: questionBackground }}
      >
        <AppBar
          backButtonOnClick={() => this.props.router.goBack()}
          headerNode="Patient Survey"
          rightNode={
            <div style={styles.pageIndicator}>{`${currentPage} of ${totalPages}`}</div>
          }
        />
        <LinearProgress
          value={(currentPage / totalPages) * 100}
        />
        <div style={styles.image}>
          <Headaches />
        </div>
        <div style={styles.questionnaire}>
          <p style={styles.instructions}>When was your last menstrual cycle? (please estimate if not known)</p>
          <KeypadDate
            onChange={(value) => {
              this.props.updatePRO({
                type: 'prgendo',
                value,
                position: this.getTrackIndex(),
              });
            }}
            value={value}
          />
        </div>
      </QuestionLayout>
    );
  }
}


function mapStateToProps(state) {
  const {
    user,
    proForms: {
      prgendo,
    },
  } = state;
  return {
    user,
    prgendo,
  };
}

PrgEndo2.propTypes = {
  prgendo: PropTypes.array.isRequired,
  updatePRO: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { updatePRO, submitPRO })(PrgEndo2);
