import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { Slider } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

import Page from './page';
import { PainIntensity } from '../lib/icons';
import QuestionLayout from '../layouts/question';
import AppBar from '../components/app-bar';
import { updatePRO } from '../state/pro-forms';
import { painScaleColors, colors } from '../lib/styles';

const { lightBrightBlue } = colors;

const styles = {
  contentWrapper: {
    flexGrow: 1,
    overflowX: 'hidden',
    position: 'relative',
    justifyContent: 'space-between',
    backgroundColor: colors.highlightBright,
  },
  slider: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '50px',
    fontSize: '14px',
  },
  sliderStyle: {
    flexGrow: 1,
    marginBottom: 24,
  },
  sliderLabel: {
    fontSize: 14,
    maxWidth: 100,
    textAlign: 'center',
  },
  image: {
    height: 100,
    padding: '15px 60px',
    textAlign: 'center',
  },
  instructions: {
    color: 'grey',
    fontSize: '1.8rem',
    margin: '20px 40px 0px 40px',
    textAlign: 'center',
  },
  nextButton: {
    bottom: 30,
    left: 30,
    position: 'absolute',
    right: 30,
  },
  pageIndicator: {
    fontSize: '1.8rem',
  },
  questionnaire: {
    margin: '0px 30px',
    position: 'relative',
  },
  title: {
    color: 'black',
    fontSize: '1.8rem',
    fontWeight: 'strong',
    textTransform: 'uppercase',
  },
  bar: {
    backgroundColor: lightBrightBlue,
  },
};

class NRS1 extends Page {
  constructor(props) {
    super(props);

    this.state = {
      slide1: { theme: this.getSliderTheme(0), value: 0 },
      slide2: { theme: this.getSliderTheme(0), value: 0 },
    };

    this.handleValueChange1 = this.handleValueChange.bind(this, 'slide1');
    this.handleValueChange2 = this.handleValueChange.bind(this, 'slide2');
  }

  getSliderTheme = (value) => {
    return {
      color: painScaleColors[value],
    };
  }

  handleValueChange(slider, event, value) {
    const theme = this.getSliderTheme(value);
    this.setState({ [slider]: { theme, value } });

    this.props.updatePRO({
      type: 'nrs',
      position: this.getTrackIndex(),
      value: { ...this.props.nrs[this.getTrackIndex()], [slider]: value },
    });
  }

  render() {
    const { nrs } = this.props;
    const index = this.getTrackIndex();
    const value1 = get(nrs, `[${index}].slide1`) || this.state.slide1.value;
    const theme1 = this.state.slide1.theme;
    const value2 = get(nrs, `[${index}].slide2`) || this.state.slide2.value;
    const theme2 = this.state.slide2.theme;
    return (
      <div style={{ height: '100%' }}>
        <QuestionLayout
          primaryBtnLabel="Next"
          primaryBtnOnTouchTap={() => {
            this.forwardWithQuery(this.props.location.query);
          }}
          primaryBtnStyle={styles.continueBtn}
          style={{ backgroundColor: colors.highlightBright }}
        >
          <AppBar
            headerNode="Pain Intensity Range"
            noLeftNode={true}
          />
          <LinearProgress
            variant="determinate"
            value={(this.getCurrentPage() / this.getTotalPages()) * 100}
            classes={{ bar: this.props.classes.bar }}
          />
          <div style={styles.contentWrapper}>
            <div style={styles.instructions}>
              Please select the option that best answers the question relating to your pain.
            </div>
            <div style={styles.image}>
              <PainIntensity />
            </div>
            <div style={styles.questionnaire}>
              <p style={styles.title}>What is your current pain level?</p>
              <Slider
                min={0}
                max={10}
                value={value1}
                valueLabelDisplay="on"
                onChange={this.handleValueChange1}
                style={{ ...styles.slider, ...theme1 }}
              />
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <div style={{ ...styles.sliderLabel, marginBottom: '50px' }}>
                  No pain
                </div>
                <div style={{ ...styles.sliderLabel, marginBottom: '50px' }}>
                  Worst imaginable pain
                </div>
              </div>
            </div>
            <div style={styles.questionnaire}>
              <p style={styles.title}>What has been your lowest level of pain?</p>
              <Slider
                min={0}
                max={10}
                value={value2}
                valueLabelDisplay="on"
                onChange={this.handleValueChange2}
                style={{ ...styles.slider, ...theme2 }}
              />
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <div style={{ ...styles.sliderLabel, marginBottom: '50px' }}>
                  No pain
                </div>
                <div style={{ ...styles.sliderLabel, marginBottom: '50px' }}>
                  Worst imaginable pain
                </div>
              </div>
            </div>
          </div>
        </QuestionLayout>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { user, proForms: { nrs } } = state;
  return { user, nrs };
}

NRS1.proptypes = {
  user: PropTypes.object.isRequired,
  nrs: PropTypes.array.isRequired,
  route: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, { updatePRO })(withStyles(styles)(NRS1));
