const en = {
  pisaInjection4: {
    title: 'Quality of Life',
    question1: 'Did you experience at least 50% pain relief?',
    question2: 'Would you like to discuss a follow-up appointment?',
    painReliefSlider: 'What percentage of pain relief did you experience?',
    currentPainSlider: 'What is your level of pain right now?',
    noPain: 'No pain',
    worstPain: 'Worst imaginable pain',
  },
};

const es = {
  pisaInjection4: {
    title: 'Calidad de Vida',
    question1: '¿Experimentó al menos un 50% de alivio del dolor?',
    question2: '¿Le gustaría discutir una cita de seguimiento?',
    painReliefSlider: '¿Qué porcentaje de alivio del dolor experimentó?',
    currentPainSlider: '¿Cuál es su nivel de dolor en este momento?',
    noPain: 'Sin dolor',
    worstPain: 'El peor dolor imaginable',
  },
};

export default { en, es };
