import React, { Fragment } from 'react';
import PainLogDetailsSectionLabel from './pain-log-details-section-label';

import { painLogDetailsColors } from '../lib/styles';

const BodyPainDetails = props => (
  <Fragment>
    <PainLogDetailsSectionLabel
      background={painLogDetailsColors.lightGrey}
      selections={props.painLocations}
      title="Pain Locations"
    />
    <PainLogDetailsSectionLabel
      background="white"
      selections={props.functionalLimitations}
      title="Functional Limitations"
    />
    <PainLogDetailsSectionLabel
      background={painLogDetailsColors.lightGrey}
      selections={props.aggravatingFactors}
      title="Aggravating Factors"
    />
    <PainLogDetailsSectionLabel
      background="white"
      selections={props.alleviatingFactors}
      title="Alleviating Factors"
    />
  </Fragment>
);

export default BodyPainDetails;
