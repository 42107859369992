const en = {
  demographics9: {
    title: 'Specialist',
    question1: 'If you have been diagnosed with diabetes or peripheral vascular disease, have you ever been seen by a foot specialist (podiatrist)?',
    question2: 'When did you last have an appointment with your foot specialist?',
    question3: 'What was the reason?',
    options: {
      threeMonths: 'Within the last 3 months',
      sixMonths: '6 months ago',
      oneYear: '1 year ago',
      overOneYear: 'Over a year ago',
      time: 'No Time',
      insurance: 'No Insurance',
      availability: 'No Availability',
      cost: 'Cost',
      other: 'Other',
    },
  },
};

const es = {
  demographics9: {
    title: 'Especialista',
    question1: '¿Si le han diagnosticado diabetes o enfermedad vascular periférica, ¿alguna vez lo ha visto un especialista en pies (podólogo)?',
    question2: '¿Cuándo fue la última vez que tuvo una cita con su especialista en pies?',
    question3: '¿Cuál fue la razón?',
    options: {
      threeMonths: 'En los últimos 3 meses',
      sixMonths: 'Hace 6 meses',
      oneYear: 'Hace un año',
      overOneYear: 'Hace más de un año',
      time: 'No hay tiempo',
      insurance: 'No tengo aseguranza',
      availability: 'Disponibilidad',
      cost: 'Costo',
      other: 'Otra razon',
    },
  },
};

export default { en, es };
