import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get, includes } from 'lodash';

import { colors } from '../lib/styles';
import Page from './page';
import ButtonPRO from '../components/button-pro';
import { updatePRO } from '../state/pro-forms';

const buttonLabels = ['Disagree', 'Agree'];

const options = [
  {
    question: 'It\'s not really safe for a person with a condition like mine to be physically active',
    label: buttonLabels,
  },
  {
    question: 'Worrying thoughts have been going through my mind a lot of the time',
    label: buttonLabels,
  },
  {
    question: 'I feel that my back pain is terrible and it\'s never going to get any better',
    label: buttonLabels,
  },
  {
    question: 'In general I have not enjoyed all the things I used to enjoy',
    label: buttonLabels,
  },
];
const { questionBackground } = colors;

class StartBack2 extends Page {
  onClickBackButton = () => {
    this.backward();
  }

  onSubmission = () => {
    this.forwardWithQuery(this.props.location.query);
  }

  render() {
    const page = get(this.props.startBack, this.getTrackIndex(), [null, null, null, null]);
    const value = includes(page, null) ? null : 0;
    return (
      <ButtonPRO
        backgroundColor={questionBackground}
        currentPage={this.getCurrentPage()}
        onClickBackButton={() => this.props.router.goBack()}
        onChange={this.props.updatePRO}
        onSubmission={this.onSubmission}
        options={options}
        title="Thinking about the last 2 weeks tick your responses to the following questions:"
        header="Patient Health"
        totalPages={this.getTotalPages()}
        trackIndex={this.getTrackIndex()}
        value={value}
        pro={page}
        type="startBack"
      />
    );
  }
}


function mapStateToProps(state) {
  const { proForms: { startBack } } = state;
  return { startBack };
}

StartBack2.propTypes = {
  startBack: PropTypes.array.isRequired,
  updatePRO: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { updatePRO })(StartBack2);
