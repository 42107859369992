import React from 'react';
import { connect } from 'react-redux';
import Page from './page';
import Layout from '../layouts/common';
import hellosign from '../lib/hellosign';

import { apiFetch } from '../lib/fetch';

const baseStyle = {
  layout: {
    height: '100%',
  },
};

const isProduction = process.env.NODE_ENV === 'production';

class Esign extends Page {
  componentWillMount() {
    const { user, esignRequestId } = this.props;
    apiFetch(`/users/${user.id}/esign_requests/${esignRequestId}`)
      .then((res) => {
        const config = {
          url: res.signUrl,
          testMode: !isProduction,
          allowCancel: false,
          skipDomainVerification: !isProduction,
          messageListener: () => {
            // Various events can get thrown here, but all mean we're done.
            this.props.router.push('/');
          },
        };
        hellosign.open(config);
      })
      .catch(() => {
        this.props.router.push('/notifications?msg=error');
      });
  }
  componentWillUnmount() {
    hellosign.close();
  }
  render() {
    return (
      <Layout style={baseStyle.layout} />
    );
  }
}

function mapStateToProps(state, ownProps) {
  const { user } = state;
  const { esignRequestId } = ownProps.params;
  return {
    user,
    esignRequestId,
  };
}

export default connect(mapStateToProps, {})(Esign);
