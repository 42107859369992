import React from 'react';
import { LinearProgress } from '@material-ui/core';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import AppBar from '../components/app-bar';
import Page from './page';
import QuestionLayout from '../layouts/question';
import YesNoButtonSet from '../components/yes-no-button-set';
import { colors } from '../lib/styles';
import { updatePRO } from '../state/pro-forms';
import { Utensils } from '../lib/icons';

const styles = {
  contentWrapper: {
    flexGrow: 1,
    overflowX: 'hidden',
    position: 'relative',
  },
  imgRow: {
    display: 'flex',
    justifyContent: 'center',
    padding: '15px 60px',
  },
  imageWrapper: {
    width: 100,
  },
  questionnaireContainer: {
    paddingLeft: 20,
    paddingRight: 20,
  },
  questionText: {
    fontSize: '2rem',
  },
};

class HypertensionAssessment6 extends Page {
  constructor(props) {
    super(props);

    this.state = { healthyDiet: null };
  }

  handleChangeHealthyDiet = (value) => {
    this.setState({ healthyDiet: value });
  };

  handleClickNext = () => {
    const { healthyDiet } = this.state;

    this.props.updatePRO({
      type: 'hypertensionAssessment',
      position: this.getTrackIndex(),
      value: {
        healthyDiet,
      },
    });

    this.forwardWithQuery(this.props.location.query);
  }

  render() {
    const { classes } = this.props;
    const { healthyDiet } = this.state;

    const continueDisabled = healthyDiet === null;

    return (
      <QuestionLayout
        primaryBtnLabel="Next"
        primaryBtnOnTouchTap={this.handleClickNext}
        primaryBtnDisabled={continueDisabled}
        style={{ backgroundColor: colors.questionBackground }}
      >
        <AppBar
          headerNode="Diet"
          backButtonOnClick={() => this.props.router.goBack()}
        />
        <LinearProgress
          variant="determinate"
          value={(this.getCurrentPage() / this.getTotalPages()) * 100}
          classes={{ bar: classes.bar }}
        />
        <div className={classes.contentWrapper}>
          <div className={classes.imgRow}>
            <div className={classes.imageWrapper}>
              <Utensils />
            </div>
          </div>
          <div className={classes.questionnaireContainer}>
            <YesNoButtonSet
              isYes={healthyDiet === 'Y'}
              isNo={healthyDiet === 'N'}
              onClickNo={() => this.handleChangeHealthyDiet('N')}
              onClickYes={() => this.handleChangeHealthyDiet('Y')}
              questionText="Do you follow a heart healthy diet consisting of plenty of fruits, vegetables, lean meats, low fat dairy, low sugar, low sodium, healthy fats and whole grains?"
            />
          </div>
        </div>
      </QuestionLayout>
    );
  }
}

export default connect(null, { updatePRO })(withStyles(styles)(HypertensionAssessment6));
