import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { CircularProgress } from '@material-ui/core';

import { colors, fontSizing } from '../lib/styles';
import { bloomkidzStyles } from '../lib/bloomkidz';

const styles = {
  btn: {
    background: bloomkidzStyles.primaryColor,
    border: 'none',
    borderRadius: '0.3125rem',
    minWidth: '17.1875rem',
    color: 'white',
    marginBottom: '0.625rem',
    marginTop: '0.0625rem',
    width: '85vw',
    minHeight: '4rem',
    fontFamily: bloomkidzStyles.fontFamily,
    fontSize: fontSizing.body,
  },
  button: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: 20,
  },
  buttonDisabled: {
    background: '#9b9b9b',
    color: 'white',
  },
  circularProgress: {
    marginRight: 10,
    marginTop: 3,
  },
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100%',
    justifyContent: 'space-between',
    fontFamily: bloomkidzStyles.fontFamily,
    backgroundColor: colors.questionBackground,
  },
  mainContent: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
};

class BloomkidzLayout extends Component {
  render() {
    const {
      children,
      primaryBtnDisabled,
      primaryBtnLabel,
      primaryBtnOnTouchTap,
      primaryBtnShowCircularProgress,
    } = this.props;

    const disabledStyle = primaryBtnDisabled ? styles.buttonDisabled : {};

    return (
      <div style={styles.contentWrapper}>
        <div style={styles.mainContent}>
          {children}
        </div>
        <div>
          {!!primaryBtnOnTouchTap
            && (
            <div style={styles.button}>
              <button
                onClick={primaryBtnOnTouchTap}
                disabled={primaryBtnDisabled}
                style={{ ...styles.btn, ...disabledStyle }}
                type="button"
              >
                {primaryBtnShowCircularProgress ? (
                  <CircularProgress style={styles.circularProgress} size={20} />
                ) : null}
                {primaryBtnLabel}
              </button>
            </div>
            )
          }
        </div>
      </div>
    );
  }
}

BloomkidzLayout.defaultProps = {
  primaryBtnDisabled: false,
  primaryBtnLabel: undefined,
  primaryBtnOnTouchTap: undefined,
};

BloomkidzLayout.propTypes = {
  children: PropTypes.node.isRequired,
  primaryBtnDisabled: PropTypes.bool,
  primaryBtnLabel: PropTypes.string,
  primaryBtnOnTouchTap: PropTypes.func,
};

export default BloomkidzLayout;
