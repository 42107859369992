import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get, isNumber, includes } from 'lodash';

import { TextField } from '@material-ui/core';
import { colors } from '../lib/styles';
import Page from './page';
import { Housework } from '../lib/icons';
import { updatePRO } from '../state/pro-forms';
import AppBar from '../components/app-bar';
import QuestionLayout from '../layouts/question';
import LinearProgress from '../components/linear-progress';

const styles = {
  image: {
    height: 120,
    padding: '15px 60px',
    textAlign: 'center',
  },
  pageIndicator: {
    color: 'black',
    display: 'inline-block',
    fontSize: 15,
  },
  questionnaire: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    marginLeft: 15,
    position: 'relative',
    minHeight: '350px',
  },
  title: {
    color: 'black',
    fontSize: '1.5rem',
    fontWeight: 800,
    textTransform: 'uppercase',
  },
  number: {
    width: '50px',
    alignSelf: 'center',
  },
  titleGroup: {
    display: 'flex',
    flexDirection: 'column',
  },
};

const { questionBackground } = colors;

class MidasHousehold extends Page {
  constructor(props) {
    super(props);
    this.handleDaysMissed = this.handleChange.bind(this, 0);
    this.handleDaysLost = this.handleChange.bind(this, 1);
  }

  onClickBackButton = () => {
    this.backward();
  }

  onSubmission = () => {
    this.forwardWithQuery(this.props.location.query);
  }

  handleChange(index, event) {
    const value = [...get(this.props.midas, this.getTrackIndex(), [null, null])];
    const val = event.target.value;

    let days = Number(val);
    if (!val && !isNumber(val)) {
      days = null;
    }

    if (days > 90) {
      days = 90;
    }
    if (days < 0) {
      days = 0;
    }

    value[index] = days;

    this.props.updatePRO({
      type: 'midas',
      position: this.getTrackIndex(),
      value,
    });
  }

  render() {
    const totalPages = this.getTotalPages();
    const currentPage = this.getCurrentPage();
    const page = get(this.props.midas, this.getTrackIndex(), [null, null]);
    const value = includes(page, null) ? null : 0;
    return (
      <QuestionLayout
        primaryBtnDisabled={!isNumber(value)}
        primaryBtnLabel="Next"
        primaryBtnOnTouchTap={this.onSubmission}
        style={{ background: questionBackground }}
      >
        <AppBar
          backButtonOnClick={() => this.props.router.goBack()}
          headerNode="Household Activities"
          rightNode={
            <div style={styles.pageIndicator}>{`${currentPage} of ${totalPages}`}</div>
          }
        />
        <LinearProgress value={(currentPage / totalPages) * 100} />
        <div style={styles.image}>
          <Housework />
        </div>
        <div style={styles.questionnaire}>
          <div style={styles.titleGroup}>
            <div style={styles.title}>Over the last 3 months</div>
            <div className="instructions">How many days have you skipped performing household chores or regular household activities because of your headaches?</div>
          </div>
          <TextField
            style={styles.number}
            value={isNumber(page[0]) ? page[0] : ''}
            onChange={this.handleDaysMissed}
            type="number"
            margin="normal"
            inputProps={{
              inputMode: 'decimal',
              pattern: '[0-9]*',
              type: 'tel',
              style: { fontSize: '16px', textAlign: 'center' } }}
          />
          <div style={styles.titleGroup}>
            <div className="instructions">Not including the days reported above...</div>
            <div className="instructions">How many days was your productivity in performing household chores or activities reduced by at least half?</div>
          </div>
          <TextField
            style={styles.number}
            value={isNumber(page[1]) ? page[1] : ''}
            onChange={this.handleDaysLost}
            type="number"
            margin="normal"
            inputProps={{ inputMode: 'decimal', style: { fontSize: '16px', textAlign: 'center' } }}
          />
        </div>
      </QuestionLayout>
    );
  }
}

function mapStateToProps(state) {
  const { proForms: { midas } } = state;
  return { midas };
}

MidasHousehold.propTypes = {
  midas: PropTypes.array.isRequired,
  updatePRO: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { updatePRO })(MidasHousehold);
