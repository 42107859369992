import React from 'react';
import moment from 'moment';
import { get, omit } from 'lodash';
import { connect } from 'react-redux';
import { TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import AppBar from '../components/app-bar';
import { colors } from '../lib/styles';
import Page from './page';
import Layout from '../layouts/common';
import UserAvatar from '../components/user-avatar';
import BasicInfo from '../components/basic-info';
import StreetAddress from '../components/street-address';
import Continue from '../components/continue';
import { browsePersonalInfo, addPersonalInfo, editPersonalInfo } from '../state/personal-info';
import { browsePatientContactInfo, addPatientContactInfo, editPatientContactInfo } from '../state/patient-contact-info';
import { updateAvatarUrl } from '../state/user';

const baseStyles = {
  avatar: {
    marginRight: '3rem',
    marginLeft: '2rem',
  },
  user: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginLeft: '1.5rem',
  },
  hide: {
    display: 'none',
  },
  floatingLabelFocusStyle: {
    color: colors.primaryColor,
  },
  primaryText: {
    color: colors.primaryColor,
    fontSize: '1.4rem',
    marginRight: '4rem',
  },
};

const classStyles = {
  textField: {
    width: 256,
    '& input': {
      color: colors.greyText,
      fontSize: 18,
    },
    '& label': {
      color: colors.greyText,
      fontSize: 16,
    },
    '& .MuiInput-underline:before': {
      borderBottom: 'none',
    },
  },
};

class PatientContact extends Page {
  constructor(props) {
    super(props);

    const { patientContactInfo, personalInfo } = props;
    const birthDateObject = moment(personalInfo.birth_date).format('MM/DD/YYYY');
    const birthDateString = personalInfo.birth_date ? `${birthDateObject}` : '';
    this.state = {
      patientContactInfo: {
        address: patientContactInfo.address || '',
        city: patientContactInfo.city || '',
        state: patientContactInfo.state || 'none',
        zip_code: patientContactInfo.zip_code || '',
        cell_phone: patientContactInfo.cell_phone || '',
      },
      personalInfo: {
        dl_number: personalInfo.dl_number || '',
        height: personalInfo.height || '',
        weight: personalInfo.weight || '',
        primary_language: personalInfo.primary_language || '',
        marital_status: personalInfo.marital_status || null,
        birth_date: birthDateString,
        sex: personalInfo.sex || '',
      },
    };

    this.handleContinue = this.handleContinue.bind(this);
    this.handleChangeDl = this.handleChangeTextNewMUI.bind(this, 'personalInfo', 'dl_number');
    this.handleChangeDob = this.handleChangeDate.bind(this, 'personalInfo', 'birth_date');
    this.handleChangeSex = this.handleChangeDropDownNewMUI.bind(this, 'personalInfo', 'sex');
    this.handleChangeLanguage = this.handleChangeDropDownNewMUI.bind(this, 'personalInfo', 'primary_language');
    this.handleChangeMaritalStatus = this.handleChangeDropDownNewMUI.bind(this, 'personalInfo', 'marital_status');
    this.handleChangeWeight = this.handleChangeTextNewMUI.bind(this, 'personalInfo', 'weight');
    this.handleChangeHeight = this.handleChangeDropDownNewMUI.bind(this, 'personalInfo', 'height');
    this.handleChangePhone = this.handleChangeTextNewMUI.bind(this, 'patientContactInfo', 'cell_phone');

    this.handleChangeAddress = this.handleChangeTextNewMUI.bind(this, 'patientContactInfo', 'address');
    this.handleChangeCity = this.handleChangeTextNewMUI.bind(this, 'patientContactInfo', 'city');
    this.handleChangeState = this.handleChangeDropDownNewMUI.bind(this, 'patientContactInfo', 'state');
    this.handleChangeZip = this.handleChangeTextNewMUI.bind(this, 'patientContactInfo', 'zip_code');
  }

  componentWillMount() {
    const personalInfoId = get(this.props, 'personalInfo.id', null);
    const contactInfoId = get(this.props, 'patientContactInfo.id', null);
    if (!personalInfoId) {
      this.props.browsePersonalInfo();
    }

    if (!contactInfoId) {
      this.props.browsePatientContactInfo();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.patientContactInfo !== this.props.patientContactInfo) {
      this.setState({
        patientContactInfo: {
          address: nextProps.patientContactInfo.address || '',
          city: nextProps.patientContactInfo.city || '',
          state: nextProps.patientContactInfo.state || 'none',
          zip_code: nextProps.patientContactInfo.zip_code || '',
          cell_phone: nextProps.patientContactInfo.cell_phone || '',
        },
      });
    }

    if (nextProps.personalInfo !== this.props.personalInfo) {
      const birthDateObject = moment(nextProps.personalInfo.birth_date).format('MM/DD/YYYY');
      const birthDateString = nextProps.personalInfo.birth_date ? `${birthDateObject}` : '';
      this.setState({
        personalInfo: {
          dl_number: nextProps.personalInfo.dl_number || '',
          height: nextProps.personalInfo.height || '',
          weight: nextProps.personalInfo.weight || '',
          primary_language: nextProps.personalInfo.primary_language || '',
          marital_status: nextProps.personalInfo.marital_status || '',
          birth_date: birthDateString,
          sex: nextProps.personalInfo.sex || null,
        },
      });
    }
  }

  handleChangeText(table, field, e, value) {
    this.setState({
      [table]: {
        ...this.state[table],
        [field]: value,
      },
    });
  }

  handleChangeTextNewMUI(table, field, e) {
    const { value } = e.target;
    this.setState(prevState => ({
      [table]: {
        ...prevState[table],
        [field]: value,
      },
    }));
  }

  handleChangeDate(table, field, value) {
    this.setState({
      [table]: {
        ...this.state[table],
        [field]: value,
      },
    });
  }

  handleChangeDropDown(table, field, e, idx, value) {
    this.setState({
      [table]: {
        ...this.state[table],
        [field]: value,
      },
    });
  }

  handleChangeDropDownNewMUI(table, field, e) {
    this.setState({
      [table]: {
        ...this.state[table],
        [field]: e.target.value,
      },
    });
  }

  handleChangeAddress(e) {
    const address = e.target.value;
    this.setState(prevState => ({
      patientContactInfo: {
        ...prevState.patientContactInfo,
        address,
      },
    }));
  }

  handleChangeCity(e) {
    const city = e.target.value;
    this.setState(prevState => ({
      patientContactInfo: {
        ...prevState.patientContactInfo,
        city,
      },
    }));
  }

  handleChangeZip(e) {
    const zip_code = e.target.value;
    this.setState(prevState => ({
      patientContactInfo: {
        ...prevState.patientContactInfo,
        zip_code,
      },
    }));
  }

  handleContinue() {
    const {
      addPatientContactInfo,
      editPatientContactInfo,
      addPersonalInfo,
      editPersonalInfo,
      patientContactInfo,
      personalInfo,
    } = this.props;

    const infoQuery = personalInfo.id ? editPersonalInfo : addPersonalInfo;
    const contactQuery = patientContactInfo.id ? editPatientContactInfo : addPatientContactInfo;

    const infoPayload = omit(this.state.personalInfo, ['user_id']);
    infoPayload.birth_date = moment(infoPayload.birth_date).isValid() ? moment(infoPayload.birth_date).format() : null;
    const contactPayload = omit(this.state.patientContactInfo, ['user_id']);

    infoQuery(infoPayload)
      .then(() => contactQuery(contactPayload))
      .then(() => this.forward());
  }

  render() {
    const {
      personalInfo,
      patientContactInfo,
    } = this.state;
    return (
      <Layout>
        <div>
          <AppBar
            backButtonOnClick={() => this.props.router.goBack()}
            headerNode="About Me"
          />
          <div style={baseStyles.user}>
            <UserAvatar
              updateAvatarUrl={updateAvatarUrl}
              user={this.props.user}
              router={this.props.router}
              avatarStyle={baseStyles.avatar}
            />
            <div style={baseStyles.primaryText}>
              {this.props.personalInfo.first_name}<br />
              {this.props.personalInfo.last_name}
            </div>
            <TextField
              label="Driver's License Number"
              onChange={this.handleChangeDl}
              value={personalInfo.dl_number}
              classes={{ root: this.props.classes.textField }}
              style={{ margin: 15 }}
            />
          </div>
          <BasicInfo
            patientContactInfo={patientContactInfo}
            personalInfo={personalInfo}
            onChangeDob={this.handleChangeDob}
            onChangeSex={this.handleChangeSex}
            onChangeLanguage={this.handleChangeLanguage}
            onChangeMaritalStatus={this.handleChangeMaritalStatus}
            onChangeWeight={this.handleChangeWeight}
            onChangeHeight={this.handleChangeHeight}
            onChangePhone={this.handleChangePhone}
          />
          <StreetAddress
            patientContactInfo={patientContactInfo}
            onChangeAddress={this.handleChangeAddress}
            onChangeCity={this.handleChangeCity}
            onChangeState={this.handleChangeState}
            onChangeZip={this.handleChangeZip}
          />
          <Continue onClick={this.handleContinue} />
        </div>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  const { patientContactInfo, personalInfo, user } = state;
  return {
    patientContactInfo: patientContactInfo.data,
    personalInfo: personalInfo.data,
    user,
  };
}

export default connect(mapStateToProps, {
  browsePersonalInfo,
  addPersonalInfo,
  editPersonalInfo,
  browsePatientContactInfo,
  addPatientContactInfo,
  editPatientContactInfo,
  updateAvatarUrl,
})(withStyles(classStyles)(PatientContact));
