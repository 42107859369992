import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';

import Page from './page';
import AppBar from '../components/app-bar';
import BloomkidzLayout from '../layouts/bloomkidz';
import { updatePRO } from '../state/pro-forms';
import { bloomkidzStylesObj, bloomkidzStyles } from '../lib/bloomkidz';

const styles = {
  ...bloomkidzStylesObj,
  centerText: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
  },
  emphasis: {
    textWeight: 600,
  },
  paragraph: {
    textAlign: 'left',
    width: '90vw',
    maxWidth: '700px',
    height: '80vh',
    fontSize: '16px',
    margin: '20px auto',
    backgroundColor: '#FFF',
    overflowY: 'scroll',
    boxShadow: 'inset 1px 1px 5px #9B9B9B',
    padding: '10px',
    borderRadius: '2px',
    display: 'flex',
    flexDirection: 'column',
  },
};

class BloomkidzSignatures3 extends Page {
  state = {
    formBottom: false,
  }

  handleScroll = (e) => {
    if (e.target.scrollTop / e.target.scrollHeight > 0.90) {
      this.setState({ formBottom: true });
    }
  }

  handleContinue = () => {
    const { location, updatePRO } = this.props;

    updatePRO({
      type: 'bloomkidzSignatures',
      position: this.getTrackIndex(),
      value: { ...this.state },
    });

    this.forwardWithQuery(location.query);
  }

  render() {
    const { classes, router } = this.props;

    return (
      <BloomkidzLayout
        primaryBtnOnTouchTap={this.handleContinue}
        primaryBtnLabel="Next"
      >
        <AppBar
          headerNode="HIPAA Notice of Privacy Practices"
          backButtonOnClick={() => router.goBack()}
        />
        <div style={{ backgroundColor: '#FFF', height: '85vh', marginBottom: '20px' }}>
          <div
            ref={(form) => {
              this.myForm = form;
            }}
            onScroll={this.handleScroll}
            className={classes.paragraph}
          >
            <img alt="logo" style={{ width: '90%', alignSelf: 'center', maxWidth: '400px', padding: '15px' }} src="/img/Bloomkidz.png" />
            <h4 color={bloomkidzStyles.primaryColor}>HIPAA Notice of Privacy Practices</h4>
            <div>
              In order to protect the privacy of your health information and your child&apos;s health information, the Health Insurance Portability and Accountability Act of 1966 (HIPAA) was enacted. HIPAA protections apply to individually identifiable “protected health information” that is created or received by us and that relates to the past, present or future of physical or mental health or condition of an individual; the provision of health care to an individual; or the past, present, or future payment for the provision of health care to an individual; and that identifies the individual (hereinafter referred to as “protected health information””). This notice of our privacy practices is intended to inform you of the ways we may use your information and the occasions on which we may disclose this information to others.
            </div>
            <ol>
              <li>
                Uses and disclosures for treatment, payment, and health care operations. BloomKidz may use or disclose your protected health information (PHI) for treatment, payment, and health care operations purposes with your written authorization. To help clarify these terms, the following definitions apply:
                <ol>
                  <li>
                    <span className={classes.emphasis}>Public Health Information (PHI)</span>: information in your health record that could identify you.
                  </li>
                  <li>
                    Treatment: actions we take to provide, coordinate, or manage your health care services related to your health care. An example of treatment is when we consult with another health care provider, such as your family physician or another psychologist.
                  </li>
                  <li>
                    Payment: Actions to obtain reimbursement for your health care.
                  </li>
                  <li>
                    Health care operations: activities that relate to the performance and operations of BloomKidz, such as quality assessment, business related matters, audits and administrative services, case management, and care coordination.
                  </li>
                  <li>
                    Use: activities within BloomKidz such as sharing,employing, using, and analyzing information that identifies your child.
                  </li>
                  <li>
                    Disclosure: activities outside of BloomKidz, such as sharing, employing, using, and analyzing information that identifies your child.
                  </li>
                  <li>
                    Authorization: your written permission to disclose confidential mental-health information. This requires your signature on a specific legally required form.
                  </li>
                </ol>
              </li>
              <li>
                Other uses and disclosures requiring authorization:
                a. BloomKidz may use or disclose PHI for purposes outside of treatment, payment, or healthcare operations, when your appropriate authorization is obtained. In such cases, we will obtain an authorization from you before releasing your child&apos;s program information. This also includes assessment data and associated written reports.
                b. You may revoke all such authorizations (PHI and/or Psychotherapy Notes) at any time, provided each revocation is in writing. You may not revoke an authorization to the extent that we have relied on that authorization for treatment and/or assessment or if the authorization was obtained as a condition of obtaining insurance coverage; the law provides the insurer the right to contest the claim under the policy.
              </li>
              <li>
                Uses and disclosures without authorization
                <ol>
                  <li>
                    Child Abuse and Adult/Domestic Abuse
                  </li>
                  <li>
                    Health Oversight Activities: if BloomKidz receives a subpoena from a regulation board, BloomKidz must disclose any PHI requested by the board.
                  </li>
                  <li>
                    Judicial and Administrative Proceedings: if you are involved in court proceedings, and we receive a court order for your private information, BloomKidz must provide all court ordered information. We will attempt to inform you first. This also applies to situations when disclosure is necessary to arrange for legal services to enforce or defend BloomKidz&apos;s legal rights.
                  </li>
                  <li>
                    Serious Threat to Health or Safety of Self or Others
                  </li>
                  <li>
                    National Security
                  </li>
                </ol>
              </li>
              Patient Rights
              <li>
                Patient&apos;s Rights and BloomKidz Duties
                <ol>
                  <li>
                    Right to request restrictions: you have the right to request restrictions on certain uses and disclosures of PHI. However, BloomKidz is not required to agree to a restriction you request.
                  </li>
                  <li>
                    Right to receive confidential communications by alternative means and at alternative locations. You have the right to request and receive confidential communications of PHI by alternative means at alternative locations. For example, you can request that your bills be sent to a location other than your home address.
                  </li>
                  <li>
                    Right to inspect and copy: you have the right to inspect and/or obtain a copy of the PHI as long as the PHI is maintained in the record. BloomKidz may deny your access to PHI under certain circumstances, but in some cases, you may have this decision reviewed. You have the right to inspect and/or obtain a copy of your child&apos;s program, unless we believe the disclosure of the record will be injurious to your health. On your request, BloomKidz will discuss with you the details of the request and denial process.
                  </li>
                  <li>
                    Right to amend: you have the right to request an amendment of PHI for as long as the PHI is maintained in the record. BloomKidz may denyyour request. At your request, we will discuss with you the details of the amendment process.
                  </li>
                  <li>
                    Right to an accounting: you generally have the right to receive an accounting of disclosures of PHI. Please ask if you would like BloomKidz to discuss the details of the accounting process.
                  </li>
                  <li>
                    Right to paper copy: a copy of this HIPAA notice can be provided in paper copy upon request.
                  </li>
                </ol>
              </li>
              <li>
                BloomKidz&apos;s Duties & Responsibilities
                <ol>
                  <li>
                    BloomKidz Duties
                    <ol>
                      <li>
                        BloomKidz is required by law to maintain the privacy of PHI and to provide you with notive of our legal duties and privacy practices with respect to PHI.
                      </li>
                      <li>
                        BloomKidz reserves the right to change the privacy policies and practices described in this Notice. Unless BloomKidz notifies you of such changes, however, we are required to abide by the terms currently in effect.
                      </li>
                      <li>
                        If BloomKidz revises our policies and procedures, we will notify you at your next scheduled appointment unless you request notification by mail.
                      </li>
                    </ol>
                  </li>
                  <li>
                    Complaints: if you are concerned that BloomKidz has violated your privacy rights, or you disagree with a decision we made about access to your records, please discuss it with us; we will work together to resolve the issue. If we cannot reach an agreement, BloomKidz will refer you to someone who can help you. You may also send a written complaint to the Secretary of the U.S. Department of Health and Human Services.
                  </li>
                  <li>
                    Effective Date, Restrictions, and Changes to privacy policy: This Notice will go into effectJanuary 1, 2022. BloomKidz reserves the right to change the terms of this Notice and to make the new notice provisions effective for all PHI that we maintain. BloomKidz will inform you verbally and we will post a written copy of any new notices. You may also request a written copy.
                  </li>
                </ol>
              </li>
            </ol>
          </div>
        </div>
      </BloomkidzLayout>
    );
  }
}

BloomkidzSignatures3.propTypes = {
  classes: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  updatePRO: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
};

export default connect(null, { updatePRO })(withStyles(styles)(BloomkidzSignatures3));
