import React from 'react';
import PropTypes from 'prop-types';
import { Button, IconButton, LinearProgress } from '@material-ui/core/';
import MenuIcon from '@material-ui/icons/Menu';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { browserHistory } from 'react-router';

import AppBar from '../components/app-bar';
import Page from './page';
import { colors, desktopMaxWidth, fontSizing } from '../lib/styles';
import Logo from '../components/logo';
import { submitPRO } from '../state/pro-forms';
import env from '../config';

const { vitalCoreConfig } = env;

const disabledBackground = '#d9d8d8';
const disabledText = '#9b9b9b';

const styles = {
  agreeButton: {
    background: colors.primaryColor,
    borderRadius: 5,
    borderWidth: 0,
    color: 'white',
    flexGrow: 1,
    fontSize: fontSizing.body,
    marginRight: 5,
    padding: 10,
    '&:disabled:': {
      background: disabledBackground,
      color: disabledText,
    },
    '@media (hover: none)': {
      '&:hover': {
        background: colors.primaryColor,
        color: 'white',
      },
    },
  },
  buttonRow: {
    display: 'flex',
    paddingBottom: 20,
    paddingTop: 20,
    width: 'calc(100% - 20px)',
  },
  declineButton: {
    background: '#707070',
    borderRadius: 5,
    borderWidth: 0,
    color: 'white',
    flexGrow: 1,
    fontSize: fontSizing.body,
    padding: 10,
    '&:disabled:': {
      background: disabledBackground,
      color: disabledText,
    },
    '@media (hover: none)': {
      '&:hover': {
        background: '#707070',
        color: 'white',
      },
    },
  },
  header: {
    fontSize: fontSizing.h3,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  linearProgress: {
    background: colors.lightBrightBlue,
  },
  marginTop20: {
    marginTop: 20,
  },
  noWrap: {
    whiteSpace: 'nowrap',
  },
  listItem: {
    '& div + div': {
      marginBottom: 20,
    },
  },
  pageContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    backgroundColor: colors.questionBackground,
  },
  pageContent: {
    boxSizing: 'border-box',
    fontSize: fontSizing.body,
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: desktopMaxWidth,
    paddingLeft: 10,
    paddingRight: 10,
    width: '100%',
    backgroundColor: colors.questionBackground,
  },
  pageContentContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    fontSize: fontSizing.body,
    overflowX: 'scroll',
    backgroundColor: colors.questionBackground,
  },
  scrollHorizontal: {
    overflowX: 'scroll',
  },
};

class VitalCore1 extends Page {
  constructor(props) {
    super(props);

    const acceptedTerms = window.localStorage.getItem('vitalCoreAcceptedTerms');
    const vitalCoreVisitedTerms = window.localStorage.getItem('vitalCoreVisitedTerms');
    const termsAndConditionsVersion = window.localStorage.getItem('termsAndConditionsVersion');
    const historyAction = props.router.location.action;

    const sameTermsVersion = termsAndConditionsVersion && termsAndConditionsVersion === vitalCoreConfig.termsAndConditionsVersion;

    if (acceptedTerms && !vitalCoreVisitedTerms && historyAction === 'PUSH' && sameTermsVersion) {
      this.goToIndexWithQuery(2, props.location.query);
    } else if (acceptedTerms && !vitalCoreVisitedTerms && historyAction === 'POP') {
      this.props.router.goBack();
    }
  }

  declinedTermsAndConditions = async () => {
    const { location, submitPRO, user } = this.props;
    const { id } = location.query;

    window.localStorage.setItem('vitalCoreAcceptedTerms', false);
    window.localStorage.setItem('vitalCoreVisitedTerms', true);

    await submitPRO({ userId: user.id, id }, {
      pro_type: 'VITAL-CORE',
      pro_data: {
        data: { declinedTermsAndConditions: true },
      },
      declined: true,
    }, user);

    this.goToIndexWithQuery(1, location.query);
  }

  handleAgree = () => {
    const { location } = this.props;

    window.localStorage.setItem('vitalCoreAcceptedTerms', true);
    window.localStorage.setItem('vitalCoreVisitedTerms', true);
    window.localStorage.setItem('termsAndConditionsVersion', vitalCoreConfig.termsAndConditionsVersion);

    this.goToIndexWithQuery(2, location.query);
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.pageContainer}>
        <div className={classes.pageContentContainer}>
          <AppBar
            headerNode="IVC Terms and Conditions"
            backButtonOnClick={() => this.props.router.goBack()}
            rightNode={(
              <IconButton onClick={() => browserHistory.push('/core-vital-user-manual-menu')}>
                <MenuIcon fontSize="large" />
              </IconButton>
            )}
          />
          <LinearProgress
            variant="determinate"
            value={(this.getCurrentPage() / this.getTotalPages()) * 100}
            classes={{ bar: classes.linearProgress }}
          />
          <div className={classes.pageContent}>
            <Logo />
            <div className={classes.header}>
              Informed Vital Core Terms and Conditions
            </div>
            <p>Informed  Vital  Core  Application  (“App”)  is  a  Software  as  a  Medical  Device  application  developed  by Mindset Medical (“we”, “us”, or “our”).  The App is designed to measure your pulse rate using the camera on your smart-phone, tablet, laptop, or desktop computer (“Device”).  After taking your pulse rate, the App  will  send  your  results  to  your  healthcare  professional.   Access  to  the  App  is  through  an  Internet browser,  requires  access  to  your  Messaging  App,  and  does  not  include  the  transfer  or  license  of  any software to you.</p>
            <p><strong>PLEASE READ THE TERMS AND CONDITIONS (“Terms”) CAREFULLY.  BY DOWNLOADING, INSTALLING, OR OTHERWISE ACCESSING OR USING THE APP, YOU (“YOU OR “YOUR”) AGREE THAT YOU HAVE READ, UNDERSTAND, AND AGREE TO BE BOUND BY THESE TERMS.</strong></p>
            <p>The App is licensed by your healthcare provider, not sold to you, and that you may use the App only as set forth in these Terms.</p>
            <ol>
              <li className={classes.listItem}>
                <div><strong>We Do Not Provide Medical Advice</strong></div>
                <div><strong>THE  APP  SHOULD  NOT  BE  USED  DURING  A  MEDICAL  EMERGENCY  OR  FOR  THE  DIAGNOSIS  OR TREATMENT  OF  ANY  MEDICAL  CONDITION.  CALL  911  OR  YOUR  DOCTOR  FOR  ALL  MEDICAL EMERGENCIES.</strong>   Please  consult  your  doctor  or  other  qualified  healthcare  provider  if  you  have  any questions about your medical condition. Do not ignore or delay obtaining professional medical advice because of information provided through the App. </div>
                <div>THE   APP   CANNOT   AND   IS   NOT   DESIGNED,   INTENDED   OR   APPROPRIATE   TO   REPLACE   THE RELATIONSHIP YOU HAVE WITH YOUR PROVIDERS OR TO ADDRESS SERIOUS, EMERGENCY, OR LIFE-THREATENING MEDICAL CONDITIONS AND SHOULD NOT BE USED IN THOSE CIRCUMSTANCES.  YOU SHOULD  ALWAYS  TALK  TO  YOUR  HEALTHCARE  PROVIDERS  FOR  DIAGNOSIS  AND  TREATMENT  OF YOUR CONDITIONS.</div>
                <div>WE  DO  NOT  RECOMMEND,  REFER,  ENDORSE,  VERIFY,  EVALUATE  OR  GUARANTEE  ANY  ADVICE, INFORMATION,  TREATMENT,  INSTITUTION,  PRODUCT,  PHYSICIAN,  SURGEON,  OPINION  OR  OTHER INFORMATION OR SERVICES PROVIDED BY ANY HEALTHCARE PROFESSIONAL USING THE APP, AND NOTHING  SHALL  BE  CONSIDERED  AS  OUR  REFERRAL,  ENDORSEMENT,  RECOMMENDATION  OR GUARANTEE OF ANY MEDICAL PROFESSIONAL OR COURSE OF TREATMENT. </div>
                <div>WE DO NOT WARRANT THE VALIDITY, ACCURACY, COMPLETENESS, SAFETY, LEGALITY, QUALITY, OR APPLICABILITY OF THE CONTENT OR ANYTHING SAID OR WRITTEN BY ANY PHYSICIAN OR ANY ADVICE PROVIDED. WE WILL NOT BE LIABLE FOR ANY DAMAGES SUSTAINED DUE TO RELIANCE BY YOU ON SUCH INFORMATION OR ADVICE PROVIDED BY ANY PHYSICIAN IN CONNECTION WITH THE USE OF THIS APP.  WE ARE NOT LIABLE IN ANY WAY FOR ANY MALPRACTICE OR SUBSTANDARD TREATMENT YOUR PROVIDER MAY PROVIDE. YOU ARE USING THE SERVICES AT YOUR OWN RISK.</div>
              </li>
              <li className={classes.listItem}>
                <div><strong>Your Use of App</strong></div>
                <div>You must be at least 18 years old to use this App.</div>
              </li>
              <li className={classes.listItem}>
                <div><strong>Device, Browser Access, and Internet Service</strong></div>
                <div>You must have Internet access to use the IVC App.  You are responsible for the data security of the Device  used  to  operate  this  App  by  enabling  biometric  security  measures  or  a  passcode  on  your Device (i.e., touch ID, Face ID, PIN, or password).  You are responsible for safeguarding your Device.</div>
              </li>
              <li className={classes.listItem}>
                <div><strong>SMS Text Messaging</strong></div>
                <div>You must be capable of receiving a text or SMS message on your Device to use the App.  Your mobile operator  may  charge  standard  and  other  text  messaging  fees  for  messages.  Neither  we  nor  any mobile network operator shall be liable for delayed or undelivered messages.</div>
                <div>Do  not  respond  to  text  messages  with  personal  health  information.  Such  messages  will  not  reach your healthcare professional.  Call your healthcare professional to directly communicate with them.</div>
              </li>
              <li className={classes.listItem}>
                <div><strong>Disclaimer of Warranties</strong></div>
                <div>YOU EXPRESSLY UNDERSTAND AND AGREE THAT:  YOUR USE OF THE APP IS AT YOUR SOLE RISK. THIS APP  IS  PROVIDED  ON  AN  "AS  IS"  AND  "AS  AVAILABLE"  BASIS.  MINDSET  MEDICAL  AND  ITS SUBSIDIARIES,  AFFILIATES,  OFFICERS,  EMPLOYEES,  AGENTS,  AND  LICENSORS  EXPRESSLY  DISCLAIM ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT.  MINDSET  MEDICAL  AND  ITS  SUBSIDIARIES,  AFFILIATES,  OFFICERS,  EMPLOYEES, AGENTS, AND LICENSORS MAKE NO WARRANTY THAT (i) THIS APP WILL BE UNINTERRUPTED, TIMELY, SECURE OR ERROR-FREE; (ii) THE RESULTS THAT MAY BE OBTAINED WILL BE ACCURATE OR RELIABLE; (iii) ANY ERRORS IN THIS APP WILL BE CORRECTED.</div>
              </li>
              <li className={classes.listItem}>
                <div><strong>Indemnification</strong></div>
                <div>You  agree  to  indemnify,  defend,  and  hold  harmless  Mindset  Medical,  its  clients,  its  suppliers  and their  respective  affiliates,  and  all  respective  employees,  officers,  directors,  agents,  servants, shareholders, and representatives of each from any liability, loss, claim, suit, damage, and expense (including reasonable attorneys' fees and expenses) related to (i) Your violation of these Terms and (ii) Your use, or misuse, of this App.</div>
              </li>
              <li className={classes.listItem}>
                <div><strong>Force Majeure.</strong></div>
                <div>We shall not be liable for any actions or failure to act due to causes beyond its reasonable control, or due to acts of God.</div>
              </li>
              <li className={classes.listItem}>
                <div><strong>Right to Change Terms</strong></div>
                <div>We may amend these Terms.  Any changes to these Terms will be effective immediately upon posting of  the  changed  Terms  in  the  App  and  on  our  website.  Any  use  of  the  App  following  such  changes constitutes your agreement to be bound by the new Terms.</div>
              </li>
              <li className={classes.listItem}>
                <div><strong>Miscellaneous</strong></div>
                <div>If any part of these Terms shall be determined to be invalid, illegal, or unenforceable by any valid act of any legislature or by any regulation duly promulgated or declared null and void by any court of competent jurisdiction, then such part shall be reformed, if possible, to conform to the law and, and in  any  event,  the  remaining  parts  of  these  Terms  shall  be  fully  effective  and  operative  insofar  as reasonably possible.</div>
              </li>
              <li>
                <div><strong>Contact Information</strong></div>
                <div>Questions  or  comments  regarding  these  Terms  should  be  directed  to  Mindset  Medical  Using  the following contact information:</div>
                <div className={classes.scrollHorizontal}>
                  <address className={classes.marginTop20}>
                    <div>Postal Address:</div>
                    <div>Mindset Medical, LLC</div>
                    <div>12439 N 32nd St.</div>
                    <div>Phoenix, AZ 85032</div>
                  </address>
                  <address className={classes.marginTop20}>
                    <div>Attention: <span className={classes.noWrap}>Customer Service</span></div>
                    <div>Email: <span className={classes.noWrap}><a href="mailto:ivcsupport@mindsetmedical.com">ivcsupport@mindsetmedical.com</a></span></div>
                    <div>Telephone: <span className={classes.noWrap}><a href="tel:4803823179">480-382-3179</a></span></div>
                  </address>
                </div>
              </li>
            </ol>
          </div>
        </div>
        <div className={`${classes.buttonRow} ${classes.pageContent}`}>
          <Button
            className={classes.agreeButton}
            variant="contained"
            onClick={this.handleAgree}
          >
            Agree
          </Button>
          <Button
            className={classes.declineButton}
            variant="contained"
            onClick={this.declinedTermsAndConditions}
          >
            Decline
          </Button>
        </div>
      </div>
    );
  }
}

VitalCore1.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const {
    user,
  } = state;

  return { user };
};

export default connect(mapStateToProps, { submitPRO })(withStyles(styles)(VitalCore1));
