import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get } from 'lodash';

import Page from './page';
import PainScaleTwoSliders from '../components/pain-scale-two-sliders';
import { colors } from '../lib/styles';
import { updatePRO } from '../state/pro-forms';

const instructions = 'On a scale from 0 to 10, '
  + 'mark the intensity of your right arm and '
  + 'left arm pain during the past week, with 0 '
  + 'being \'None\' and 10 being \'Unbearable Pain\'';

const { questionBackground } = colors;
class NdiNeckArmPainScale extends Page {
  componentWillMount() {
    const currentValues = get(this.props.ndi, [this.getTrackIndex()], []);
    if(!currentValues.length) {
      this.props.updatePRO({
        type: 'ndi',
        position: this.getTrackIndex(),
        value: [0, 0],
      });
    }
  }
  onClickBackButton = () => {
    this.backward();
  }

  onSubmission = () => {
    this.forwardWithQuery(this.props.location.query);
  }

  handleChangeTopSlider = (value) => {
    const currentValues = get(this.props.ndi, [this.getTrackIndex()], []);
    const newValues = [...currentValues];
    newValues[0] = value;
    this.props.updatePRO({
      type: 'ndi',
      position: this.getTrackIndex(),
      value: newValues,
    });
  }

  handleChangeBottomSlider = (value) => {
    const currentValues = get(this.props.ndi, [this.getTrackIndex()], []);
    const newValues = [...currentValues];
    newValues[1] = value;
    this.props.updatePRO({
      type: 'ndi',
      position: this.getTrackIndex(),
      value: newValues,
    });
  }

  render() {
    const topValue = get(this.props.ndi, [this.getTrackIndex(), 0], 0);
    const bottomValue = get(this.props.ndi, [this.getTrackIndex(), 1], 0);
    return (
      <PainScaleTwoSliders
        backgroundColor={questionBackground}
        bottomValue={bottomValue}
        currentPage={this.getCurrentPage()}
        instructions={instructions}
        onClickBackButton={() => this.props.router.goBack()}
        onChangeTop={this.handleChangeTopSlider}
        onChangeBottom={this.handleChangeBottomSlider}
        onSubmission={this.onSubmission}
        sliderLabelBottom="Left Arm Pain"
        sliderLabelTop="Right Arm Pain"
        sliderMax={10}
        sliderMin={0}
        title="Neck & Arm Pain Scale"
        totalPages={this.getTotalPages()}
        trackIndex={this.getTrackIndex()}
        topValue={topValue}
      />
    );
  }
}

function mapStateToProps(state) {
  const { proForms: { ndi } } = state;
  return { ndi };
}

NdiNeckArmPainScale.propTypes = {
  ndi: PropTypes.array.isRequired,
  updatePRO: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { updatePRO })(NdiNeckArmPainScale);
