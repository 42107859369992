import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';
import { connect } from 'react-redux';

import Page from './page';
import AppBar from '../components/app-bar';
import IconWrapper from '../components/icon-wrapper';
import BloomkidzLayout from '../layouts/bloomkidz';
import { UserGroup } from '../lib/icons';
import { updatePRO } from '../state/pro-forms';
import { fontSizing } from '../lib/styles';
import { bloomkidzStyles, bloomkidzStylesObj } from '../lib/bloomkidz';

const styles = {
  ...bloomkidzStylesObj,
  marginTop20: {
    marginTop: 20,
  },
};

class BloomkidzParentGuardianInformation3 extends Page {
  constructor(props) {
    super(props);

    const stateFromRedux = props.bloomkidzParentGuardianInformation[this.getTrackIndex()] || {};

    this.state = {
      changesAtHome: '',
      changesAtHomeExplanation: '',
      ...stateFromRedux,
    };
  }

  handleClickButton = (changesAtHome) => {
    this.setState({ changesAtHome });
  }

  handleContinue = () => {
    const { location, updatePRO } = this.props;

    updatePRO({
      type: 'bloomkidzParentGuardianInformation',
      position: this.getTrackIndex(),
      value: { ...this.state },
    });

    this.forwardWithQuery(location.query);
  }

  handleTextFieldChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  render() {
    const { classes, router } = this.props;
    const { state } = this;
    const {
      changesAtHome,
      changesAtHomeExplanation,
    } = state;

    const continueButtonDisabled = !changesAtHome
      || (changesAtHome === 'yes' && !changesAtHomeExplanation);

    return (
      <BloomkidzLayout
        primaryBtnOnTouchTap={this.handleContinue}
        primaryBtnLabel="Next"
        primaryBtnDisabled={continueButtonDisabled}
      >
        <AppBar
          headerNode="Family Information"
          backButtonOnClick={() => router.goBack()}
        />
        <IconWrapper>
          <UserGroup />
        </IconWrapper>
        <div className={classes.pageContent}>
          <div>
            Have there been any recent changes at home?
          </div>
          <div className={classes.buttonRow}>
            <button
              className={`${classes.button} ${changesAtHome === 'yes' ? classes.buttonSelected : classes.buttonUnselected}`}
              onClick={() => this.handleClickButton('yes')}
              type="button"
            >
              Yes
            </button>
            <button
              className={`${classes.button} ${changesAtHome === 'no' ? classes.buttonSelected : classes.buttonUnselected}`}
              onClick={() => this.handleClickButton('no')}
              type="button"
            >
              No
            </button>
          </div>
          {changesAtHome === 'yes' ? (
            <div className={classes.textFieldWrapper}>
              <div>Please explain:</div>
              <TextField
                placeholder="Tap here to type"
                value={changesAtHomeExplanation}
                onChange={this.handleTextFieldChange}
                name="changesAtHomeExplanation"
                variant="standard"
                fullWidth
                inputProps={{ style: { fontSize: fontSizing.body, fontFamily: bloomkidzStyles.fontFamily } }}
              />
            </div>
          ) : null}
        </div>
      </BloomkidzLayout>
    );
  }
}

BloomkidzParentGuardianInformation3.propTypes = {
  classes: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  updatePRO: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const {
    proForms: { bloomkidzParentGuardianInformation },
    user,
  } = state;

  return { user, bloomkidzParentGuardianInformation };
};

export default connect(mapStateToProps, { updatePRO })(withStyles(styles)(BloomkidzParentGuardianInformation3));
