import { promiseHandler, resetReducer } from 'cooldux';
import { get, isUndefined, omit, omitBy } from 'lodash';

import { apiFetch } from '../lib/fetch';

const { browseStart, browseEnd, browseError, browseHandler } = promiseHandler('browse', 'symptomsHx');
const { editStart, editEnd, editError, editHandler } = promiseHandler('edit', 'symptomsHx');
const { addStart, addEnd, addError, addHandler } = promiseHandler('add', 'symptomsHx');
const { deleteStart, deleteEnd, deleteError, deleteHandler } = promiseHandler('delete', 'symptomsHx');

export function browseSymptomsHx() {
  return function dispatcher(dispatch, getState) {
    const selfId = get(getState(), 'user.id', null);
    const promise = apiFetch(`/users/${selfId}/symptoms_hx`);
    return browseHandler(promise, dispatch);
  };
}

export function editSymptomsHx(update) {
  return function dispatcher(dispatch, getState) {
    const selfId = get(getState(), 'user.id', null);
    const body = omit(update, 'id');
    const options = {
      method: 'PUT',
      body: omitBy(body, isUndefined),
    };
    const promise = apiFetch(`/users/${selfId}/symptoms_hx/${update.id}`, options);
    return editHandler(promise, dispatch);
  };
}

export function addSymptomsHx(newSymptomsHx) {
  return function dispatcher(dispatch, getState) {
    const selfId = get(getState(), 'user.id', null);
    const options = {
      method: 'POST',
      body: omitBy(newSymptomsHx, isUndefined),
    };
    const promise = apiFetch(`/users/${selfId}/symptoms_hx`, options);
    return addHandler(promise, dispatch);
  };
}

export function deleteSymptomsHx(symptomsHxId) {
  return function dispatcher(dispatch, getState) {
    const state = getState();
    const selfId = get(state, 'user.id', null);
    const symptomId = get(state, `symptomsHx.data[${symptomsHxId}].id`, null);
    const options = {
      method: 'DELETE',
    };
    const promise = apiFetch(`/users/${selfId}/symptoms_hx/${symptomId}`, options)
      .then(() => symptomsHxId);

    return deleteHandler(promise, dispatch);
  };
}

const initialState = {
  browseError: null,
  addError: null,
  editError: null,
  deleteError: null,
  browsePending: false,
  addPending: false,
  editPending: false,
  deletePending: false,
  data: {},
};

function finishBrowse(state, symptomsHx) {
  const data = {};
  symptomsHx.forEach((u) => {
    data[u.symptoms_id] = u;
  });
  return { ...state, data, browsePending: false, browseError: null };
}

function finishEdit(state, item) {
  const data = { ...state.data, [item.symptoms_id]: item };
  return { ...state, editPending: false, editError: null, data };
}

function finishAdd(state, item) {
  const data = { ...state.data, [item.symptoms_id]: item };
  return { ...state, addPending: false, addError: null, data };
}

function finishDelete(state, symptomsHxId) {
  return { ...state, deletePending: false, deleteError: null, data: omit(state.data, symptomsHxId) };
}

const symptomsHx = resetReducer(initialState, (state = initialState, action) => {
  switch (action.type) {
    case browseStart.type:
      return { ...state, browsePending: true, browseError: null };
    case browseEnd.type:
      return finishBrowse(state, action.payload);
    case browseError.type:
      return { ...state, browsePending: false, browseError: action.payload };
    case addStart.type:
      return { ...state, addPending: true, addError: null };
    case addEnd.type:
      return finishAdd(state, action.payload);
    case addError.type:
      return { ...state, addPending: false, addError: action.payload };
    case deleteStart.type:
      return { ...state, deletePending: true, deleteError: null };
    case deleteEnd.type:
      return finishDelete(state, action.payload);
    case deleteError.type:
      return { ...state, deletePending: false, deleteError: action.payload };
    case editStart.type:
      return { ...state, editPending: true, editError: null };
    case editEnd.type:
      return finishEdit(state, action.payload);
    case editError.type:
      return { ...state, editPending: false, editError: action.payload };
    default:
      return state;
  }
});

export default symptomsHx;
