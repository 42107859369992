import React from 'react';
import PropTypes from 'prop-types';
import {
  FormControlLabel,
  LinearProgress,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { flatten } from 'lodash';

import AppBar from '../components/app-bar';
import Page from './page';
import QuestionLayout from '../layouts/question';
import { colors, fontSizing } from '../lib/styles';
import { submitPRO } from '../state/pro-forms';
import { Frown } from '../lib/icons';
import CenteredIcon from '../components/centered-icon';

const styles = {
  pageContent: {
    fontSize: fontSizing.body,
    paddingLeft: 10,
    paddingRight: 10,
  },
  radioGroup: {
    marginTop: 10,
  },
};

class Phq92 extends Page {
  constructor(props) {
    super(props);

    this.state = { difficulty: null };
  }

  handleChangeDifficulty = (e) => {
    this.setState({ difficulty: Number(e.target.value) });
  }

  handleClickNext = async () => {
    const {
      phq9,
      location,
      submitPRO,
      user,
      userId,
    } = this.props;
    const { difficulty } = this.state;
    const { id } = location.query;

    const data = flatten(phq9);
    data.push(difficulty);

    await submitPRO({ userId, id }, {
      pro_type: 'phq9',
      pro_data: { data },
    }, user);

    this.finishTrack();
  }

  render() {
    const { classes } = this.props;
    const { difficulty } = this.state;

    const submitDisabled = difficulty === null;

    return (
      <QuestionLayout
        primaryBtnDisabled={submitDisabled}
        primaryBtnLabel="Submit"
        primaryBtnOnTouchTap={this.handleClickNext}
        style={{ backgroundColor: colors.questionBackground }}
      >
        <AppBar
          headerNode="Patient Health"
          backButtonOnClick={() => this.props.router.goBack()}
        />
        <LinearProgress
          variant="determinate"
          value={(this.getCurrentPage() / this.getTotalPages()) * 100}
          classes={{ bar: classes.bar }}
        />
        <div className={classes.pageContent}>
          <CenteredIcon>
            <Frown />
          </CenteredIcon>
          <div>If you have reported <u>any</u> problems, how <u>difficult</u> have these problems made it for you to do your work, take care of things at home, or get along with other people?</div>
          <RadioGroup
            name="difficulty"
            onChange={this.handleChangeDifficulty}
            value={difficulty}
            className={classes.radioGroup}
          >
            <FormControlLabel
              control={<Radio color="primary" />}
              label="Not difficult at all"
              value={0}
              classes={{
                label: classes.radioLabel,
              }}
            />
            <FormControlLabel
              control={<Radio color="primary" />}
              label="Somewhat difficult"
              value={1}
              classes={{
                label: classes.radioLabel,
              }}
            />
            <FormControlLabel
              control={<Radio color="primary" />}
              label="Very difficult"
              value={2}
              classes={{
                label: classes.radioLabel,
              }}
            />
            <FormControlLabel
              control={<Radio color="primary" />}
              label="Extremely difficult"
              value={3}
              classes={{
                label: classes.radioLabel,
              }}
            />
          </RadioGroup>
        </div>
      </QuestionLayout>
    );
  }
}

Phq92.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  const { user, proForms: { phq9 } } = state;
  return {
    user,
    userId: user.id,
    phq9,
  };
}

export default connect(mapStateToProps, { submitPRO })(withStyles(styles)(Phq92));
