import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, Button } from '@material-ui/core';

import { colors } from '../lib/styles';

const baseStyles = {
  modal: {
    borderRadius: '10px',
    padding: 0,
    textAlign: 'center',
  },
  modalButton: {
    borderRadius: 0,
    borderTop: `1px solid ${colors.lightGrayText}`,
    height: '5rem',
  },
  modalButtonLabel: {
    color: colors.black,
    fontSize: '1.75rem',
    fontWeight: 'bold',
    textTransform: 'none',
  },
  modalDescription: {
    color: colors.black,
    display: 'inline-block',
    fontSize: '1.5rem',
    margin: '0 1rem 1rem 1rem',
    maxWidth: '25rem',
    textAlign: 'center',
  },
  modalTitle: {
    color: colors.black,
    fontSize: '1.75rem',
    fontWeight: 'bold',
    marginBottom: '1rem',
    textAlign: 'center',
  },
  painBigImg: {
    height: '6.4921875rem',
    marginBottom: '1.5rem',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '1.5rem',
    width: '6.2578125rem',
  },
};

const BodyPainModal = (props) => {
  const {
    onCloseModal,
    modalOpen,
  } = props;

  return (
    <Dialog
      open={modalOpen}
      bodyStyle={baseStyles.modal}
    >
      <img
        alt="body with pain points"
        src="/img/PainBig.png"
        style={baseStyles.painBigImg}
      />
      <div style={baseStyles.modalTitle}>
        General Pain
      </div>
      <div style={baseStyles.modalDescription}>
        Tap on the body part chart to select the location of your pain. Use the arrows to rotate different views
      </div>
      <Button
        fullWidth={true}
        onClick={onCloseModal}
        style={{ ...baseStyles.modalButton, ...baseStyles.modalButtonLabel }}
      >{"OK - Got it!"}</Button>
    </Dialog>
  );
};

BodyPainModal.propTypes = {
  onCloseModal: PropTypes.func.isRequired,
  modalOpen: PropTypes.bool.isRequired,
};

export default BodyPainModal;
