import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { IconButton, LinearProgress } from '@material-ui/core/';
import MenuIcon from '@material-ui/icons/Menu';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import { browserHistory } from 'react-router';

import Page from './page';
import AppBar from '../components/app-bar';
import Continue from '../components/continue';
import { submitPRO } from '../state/pro-forms';
import { colors, desktopMaxWidth, fontSizing } from '../lib/styles';
import { removeNewTabOpened } from '../lib/pro-in-new-tab';
import {
  BloodPressure,
  CircleExclamationSolid,
  HeartBeat,
  Lungs,
  NoInternet,
  Spo2,
  VitalCoreLoadingError,
} from '../lib/icons';
import env from '../config';
import HttpErrorScreen from '../components/http-error-screen';
import { setErrorScreenData } from '../state/error-screens';

const primaryBlue = '#0c63aa';

const styles = {
  bodyText: {
    fontSize: fontSizing.body,
  },
  boldText: {
    fontWeight: 'bold',
  },
  continueButtonWrapper: {
    paddingLeft: 20,
    paddingRight: 20,
  },
  errorIconInnerWrapper: {
    width: 100,
  },
  errorIconRedWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 25,
    marginBottom: 25,
    '& path': {
      fill: colors.errorRed,
    },
  },
  errorIconWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 25,
    marginBottom: 25,
  },
  flexGrowOne: {
    flexGrow: 1,
  },
  icon: {
    objectFit: 'contain',
    width: 25,
    height: 25,
  },
  iconWrapper: {
    alignItems: 'center',
    boxShadow: '0 0 8px lightgrey',
    borderRadius: '50%',
    display: 'flex',
    height: 50,
    justifyContent: 'center',
    width: 50,
  },
  linearProgress: {
    background: colors.lightBrightBlue,
  },
  list: {
    listStyleType: "''",
    marginBottom: 0,
    marginTop: 0,
    paddingLeft: 0,
  },
  marginBottom0: {
    marginBottom: 0,
  },
  page: {
    background: colors.questionBackground,
    display: 'flex',
    flexDirection: 'column',
    fontSize: fontSizing.body,
    height: '100%',
  },
  pageContent: {
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: desktopMaxWidth,
    width: '100%', // needed to make buttons on the bottom of the page span up to the maxWidth
  },
  text: {
    fontSize: fontSizing.body,
    padding: '0 20px',
    textAlign: 'center',
  },
  textCentered: {
    textAlign: 'center',
  },
  textNotCentered: {
    fontSize: fontSizing.body,
    padding: '0 20px',
  },
  vitalMeasurementContainer: {
    boxShadow: '0 0 8px lightgrey',
    display: 'flex',
    marginBottom: 10,
    marginTop: 10,
    marginLeft: 20,
    marginRight: 20,
    padding: 5,
    alignItems: 'flex-end',
  },
  vitalMeasurementTitleContainer: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: fontSizing.body,
    width: 150,
    marginLeft: 10,
  },
  vitalMeasurementValue: {
    fontSize: fontSizing.h1,
  },
  vitalMeasurementUnit: {
    fontSize: fontSizing.small,
    lineHeight: 1.6,
    marginLeft: 5,
  },
  vitalsRed: {
    color: '#cd3636',
  },
};

const VitalsValueDisplay = ({
  classes,
  color,
  icon: Icon,
  labelOne,
  labelTwo,
  units,
  value,
  invalidMeasurement,
}) => (
  <Paper className={classes.vitalMeasurementContainer} style={{ color }}>
    <Paper className={classes.iconWrapper}>
      <Icon className={classes.icon} style={{ color }} />
    </Paper>
    <div className={classes.vitalMeasurementTitleContainer}>
      <div>{labelOne}</div>
      <div>{labelTwo}</div>
    </div>
    {invalidMeasurement ? (
      <div className={classes.vitalMeasurementValue}>Unable to Measure</div>
    ) : (
      <>
        <div className={classes.vitalMeasurementValue}>{value}</div>
        <div className={classes.vitalMeasurementUnit}>{units}</div>
      </>
    )}
  </Paper>
);
VitalsValueDisplay.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  labelOne: PropTypes.string.isRequired,
  labelTwo: PropTypes.string.isRequired,
  units: PropTypes.string.isRequired,
  invalidMeasurement: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};
const VitalsValueDisplayStyled = withStyles(styles)(VitalsValueDisplay);

const LightingAndMovementErrorMessage = ({ classes }) => (
  <div>
    <div className={classes.textCentered}>
      <i>We are unable to measure your vitals because of a combination of movement and lighting errors.</i>
    </div>
    <p className={`${classes.boldText} ${classes.marginBottom0}`}>Please ensure the following:</p>
    <ul className={classes.list}>
      <li>- Hold the phone still</li>
      <li>- Face is well lit</li>
      <li>- Face is within the guide box</li>
    </ul>
  </div>
);
LightingAndMovementErrorMessage.propTypes = {
  classes: PropTypes.object.isRequired,
};
const LightingAndMovementErrorMessageStyled = withStyles(styles)(LightingAndMovementErrorMessage);

const dataStatusMessageMap = {
  'C-FDM-043': <i>Looks like we encountered an error and need to reload Informed Vital Core again.</i>,
  'C-GEN-003': <i>Looks like we encountered an error and need to reload Informed Vital Core again.</i>,
  'C-HRT-064': <i>Looks like we encountered an error and need to reload Informed Vital Core again.</i>,
  'W-HRT-062': <LightingAndMovementErrorMessageStyled />,
  'W-HRT-063': <LightingAndMovementErrorMessageStyled />,
};

const dataStatusInstructionsMap = {
  'C-FDM-043': <>Please tap <strong>RETRY.</strong></>,
  'C-GEN-003': <>Please tap <strong>RETRY.</strong></>,
  'C-HRT-064': <>Please tap <strong>RETRY.</strong></>,
  'W-HRT-062': <>and then tap <strong>RETRY.</strong></>,
  'W-HRT-063': <>and then tap <strong>RETRY.</strong></>,
};

const dataStatusHeaderMap = {
  'C-FDM-043': 'Loading Failure',
  'C-GEN-003': 'Loading Failure',
  'C-HRT-064': 'Loading Failure',
  'W-HRT-062': 'Lighting and Movement Error',
  'W-HRT-063': 'Lighting and Movement Error',
};

const dataStatusPageTitleMap = {
  'C-FDM-043': 'IVC Error',
  'C-GEN-003': 'IVC Error',
  'C-HRT-064': 'IVC Error',
  'W-HRT-062': 'Error',
  'W-HRT-063': 'Error',
};

const processResults = (results) => {
  const {
    criticalErrorCount,
    sessionCount,
    shouldRestart,
    shouldReload,
    vitalsMeasurements,
    errorCode,
    deviceError,
  } = results;
  const [{ HR }] = vitalsMeasurements;

  let headerText = '';
  let messageText = '';
  let pageTitle = 'Vital Results';
  let instructionsText = 'Please tap Retry';
  let vitalsOutOfRange = false;
  let displayResults = false;
  let actionType = 'continue';

  const maxSessionCount = sessionCount && sessionCount === 3;
  const maxCriticalErrorCount = criticalErrorCount && criticalErrorCount === 2;
  const noResults = HR <= 0;
  const resultsOutOfRange = HR < 40 || HR > 120;

  if (deviceError) {
    headerText = 'Device Error';
    messageText = 'Your vitals cannot be taken on this device at this time. Informed Vital Core may not be compatible with your device hardware.';
    instructionsText = <>Please tap <strong>INFORM</strong> to let your provider know your vitals were not measured.</>;
    pageTitle = 'Device Error';
    actionType = 'inform';
  } else if ((shouldRestart || shouldReload || noResults || resultsOutOfRange) && (maxSessionCount || maxCriticalErrorCount)) {
    headerText = 'Continuous Error';
    messageText = 'Your vitals cannot be taken on this device at this time. Informed Vital Core may not be compatible with your device hardware.';
    instructionsText = <>Please tap <strong>INFORM</strong> to let your provider know your vitals were not measured.</>;
    pageTitle = 'IVC Error';
    actionType = 'inform';
  } else if (shouldRestart || shouldReload) {
    headerText = dataStatusHeaderMap[errorCode];
    messageText = dataStatusMessageMap[errorCode];
    pageTitle = dataStatusPageTitleMap[errorCode];
    instructionsText = dataStatusInstructionsMap[errorCode];
    actionType = 'restart';
  } else if (noResults) {
    headerText = 'Unable to Measure Vitals';
    messageText = <i>Informed Vital Core is unable to measure your vitals at this time.</i>;
    instructionsText = <>Please tap <strong>RETRY.</strong></>;
    actionType = 'restart';
  } else if (resultsOutOfRange) {
    vitalsOutOfRange = true;
    messageText = "Your measures appear outside of normal range. Let's double check your measures.";
    instructionsText = <>Please tap the <strong>RETAKE VITALS</strong> button.</>;
    actionType = 'restart';
    displayResults = true;
  } else {
    displayResults = true;
  }

  return {
    displayResults,
    headerText,
    instructionsText,
    messageText,
    pageTitle,
    actionType,
    vitalsOutOfRange,
  };
};

class VitalCore4VitalsResults extends Page {
  state = {
    showErrorScreen: false,
    submitting: false,
  }

  handleSubmission = (validMeasurements = true) => {
    const { user, vitalCore } = this.props;
    const { id } = this.props.location.query;
    const { vitalsMeasurements, signsMsgs } = this.getDataMap(vitalCore);

    if (this.state.submitting) return;

    const data = {
      vitals: vitalsMeasurements[0],
      prevVitals: vitalsMeasurements.slice(1),
      ivcVersion: env.vitalCoreConfig.ivcVersion,
      webAppVersion: env.vitalCoreConfig.ivcWebAppVersion,
      noValidMeasurements: false,
      signsMsgs,
    };

    if (!validMeasurements) {
      data.noValidMeasurements = true;
    }

    this.setState({ submitting: true });
    submitPRO({ userId: user.id, id }, {
      pro_type: 'VITAL-CORE-4-VITALS',
      pro_data: {
        data,
        recorded_at: new Date().toISOString(),
      },
    }, user)
      .then(() => {
        window.localStorage.removeItem('vitalCoreVisitedTerms');
        window.localStorage.removeItem('vitalCoreVisitedConsentToRecord');
        window.localStorage.removeItem('vitalCoreVisitedInstructions');
        removeNewTabOpened();
        this.forwardWithQuery(this.props.location.query);
      })
      .catch(() => {
        this.setState({ submitting: false });

        if (!navigator.onLine) {
          const { pathname, search } = this.props.location;
          const curTrackIndex = this.getTrackIndex();
          const newPathname = pathname.replace(curTrackIndex + 1, curTrackIndex); // create pathname to previous page in pro

          const handleRetry = () => {
            this.props.router.push(`${newPathname}${search}`);
          };

          this.props.setErrorScreenData({
            header: 'Lost Internet Connection',
            messageOne: <i>Unable to submit your measurements.</i>,
            messageTwo: <>Please reconnect to the Internet and tap <strong>RETRY.</strong></>,
            notificationAuthLogoutRoute: this.props.user.notificationAuthLogoutRoute,
            pageTitle: 'No Internet',
            icon: <NoInternet />,
            onRetry: handleRetry,
            noLogout: true,
          });

          this.props.router.push('/timeout');
        } else {
          this.setState({ showErrorScreen: true });
        }
      });
  }

  handleSubmissionWithError = () => {
    this.handleSubmission(false);
  };

  handleRetake = () => {
    const { location } = this.props;

    this.goToIndexWithQuery(this.getTrackIndex() - 1, location.query);
  }

  render() {
    const { classes, vitalCore } = this.props;
    const { showErrorScreen } = this.state;

    const results = this.getDataMap(vitalCore);

    // Needed to prevent white screen from appearing if a user tries to navigate
    // back to this page from info-updated-successfully. Accessing HR when there are no
    // measurements (like after pro state has been cleared) will throw an error, and
    // prevent backButtonHandler in info-updated-successfully from executing.
    if (!Object.keys(results).length) return null;

    const {
      displayResults,
      headerText,
      instructionsText,
      messageText,
      pageTitle,
      actionType,
      vitalsOutOfRange,
    } = processResults(results);
    const [{
      BP_DIA,
      BP_SYS,
      BR,
      HR,
      SPO2,
    }] = results.vitalsMeasurements;

    let errorIcon = (
      <div className={classes.errorIconRedWrapper}>
        <div className={classes.errorIconInnerWrapper}>
          <CircleExclamationSolid />
        </div>
      </div>
    );

    if (results.errorCode && results.errorCode[0] === 'C') {
      errorIcon = (
        <div className={classes.errorIconWrapper}>
          <div className={classes.errorIconInnerWrapper}>
            <VitalCoreLoadingError />
          </div>
        </div>
      );
    }

    const textContentClass = (results.errorCode && results.errorCode[0] === 'W') ? classes.textNotCentered : classes.text;

    return (
      <>
        {showErrorScreen ? (
          <HttpErrorScreen onClickRetry={this.handleSubmission} vitalCorePageTitle="Vital Results" />
        ) : (
          <div className={classes.page}>
            <div className={classes.flexGrowOne}>
              <AppBar
                backButtonOnClick={() => this.props.router.goBack()}
                headerNode={pageTitle}
                rightNode={(
                  <IconButton onClick={() => browserHistory.push('/core-vital-user-manual-menu')}>
                    <MenuIcon fontSize="large" />
                  </IconButton>
                )}
                noLeftNode={actionType === 'inform'}
              />
              <LinearProgress
                variant="determinate"
                value={(this.getCurrentPage() / this.getTotalPages()) * 100}
                classes={{ bar: classes.linearProgress }}
              />
              <div className={classes.pageContent}>
                {displayResults ? (
                  <>
                    <VitalsValueDisplayStyled
                      color={colors.healthyRed}
                      icon={HeartBeat}
                      labelOne="Pulse"
                      labelTwo="Rate"
                      units="bpm"
                      invalidMeasurement={!HR}
                      value={HR}
                    />
                    <VitalsValueDisplayStyled
                      color={colors.healthyRed}
                      icon={BloodPressure}
                      labelOne="Blood"
                      labelTwo="Pressure"
                      units="mm/Hg"
                      invalidMeasurement={!BP_SYS || !BP_DIA}
                      value={`${BP_SYS}/${BP_DIA}`}
                    />
                    <VitalsValueDisplayStyled
                      color={primaryBlue}
                      icon={Lungs}
                      labelOne="Breathing"
                      labelTwo="Rate"
                      units="bpm"
                      invalidMeasurement={!BR}
                      value={BR}
                    />
                    <VitalsValueDisplayStyled
                      color={primaryBlue}
                      icon={Spo2}
                      labelOne="Oxygen"
                      labelTwo="Saturation"
                      units="SpO2"
                      invalidMeasurement={!SPO2}
                      value={`${SPO2}%`}
                    />
                    {vitalsOutOfRange ? (
                      <>
                        <p className={classes.text}>{messageText}</p>
                        <p className={classes.text}>{instructionsText}</p>
                      </>
                    ) : null}
                  </>
                ) : (
                  <>
                    {errorIcon}
                    <p className={`${classes.text} ${classes.boldText}`}>{headerText}</p>
                    <p className={textContentClass}>{messageText}</p>
                    <p className={textContentClass}>{instructionsText}</p>
                  </>
                )}
              </div>
            </div>
            <div className={classes.pageContent}>
              {displayResults && vitalsOutOfRange ? (
                <div className={classes.continueButtonWrapper}>
                  <Continue
                    text="Retake Vitals"
                    onClick={this.handleRetake}
                    btnStyle={{ marginBottom: 20, marginTop: '20px', alignSelf: 'center', width: '100%' }}
                  />
                </div>
              ) : null}
              {displayResults && !vitalsOutOfRange ? (
                <div className={classes.continueButtonWrapper}>
                  <Continue
                    text="Submit Results"
                    onClick={this.handleSubmission}
                    btnStyle={{ marginBottom: 20, marginTop: '20px', alignSelf: 'center', width: '100%' }}
                  />
                </div>
              ) : null}
              {!displayResults && actionType === 'restart' ? (
                <div className={classes.continueButtonWrapper}>
                  <Continue
                    text="Retry"
                    onClick={this.handleRetake}
                    btnStyle={{ marginBottom: 20, marginTop: '20px', alignSelf: 'center', width: '100%' }}
                  />
                </div>
              ) : null}
              {!displayResults && actionType === 'inform' ? (
                <div className={classes.continueButtonWrapper}>
                  <Continue
                    text="Inform"
                    onClick={this.handleSubmissionWithError}
                    btnStyle={{ marginBottom: 20, marginTop: '20px', alignSelf: 'center', width: '100%' }}
                  />
                </div>
              ) : null}
            </div>
          </div>
        )}
      </>
    );
  }
}

VitalCore4VitalsResults.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const {
    proForms: { vitalCore },
    user,
  } = state;

  return { user, vitalCore };
};

export default connect(mapStateToProps, { setErrorScreenData, submitPRO })(withStyles(styles)(VitalCore4VitalsResults));
