/* eslint-disable no-console */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import rawr from 'rawr';
import transport from 'rawr/transports/worker';

import LinearProgress from '@material-ui/core/LinearProgress';
import Snackbar from '@material-ui/core/Snackbar';
import { withStyles } from '@material-ui/core/styles';

import MuiAlert from '@material-ui/lab/Alert';
import Badge from '@material-ui/core/Badge';

import Page from './page';
import HiddenContent from '../components/hidden-content';

import { updatePRO } from '../state/pro-forms';
import { colors } from '../lib/styles';
import { throttledReset } from '../initializers/activity';
import { saveMetaData } from '../lib/fetch';
import { CameraBuddy } from '../lib/cameraBuddy';
import { drawNumber } from '../lib/drawing';
import { Graph, GRAPH_POINT_DELAY, getLightingStatus, getImageDataFunc } from '../lib/vitals-spo2';

const styles = {
  patientVideo: {
    minHeight: '450px',
    height: '100%',
    maxWidth: 1000,
    objectFit: 'cover',
    transform: 'scaleX(1)',
    width: '100%',
    display: 'none',
  },
  patientCanvas: {
    minHeight: '450px',
    height: '-webkit-fill-available',
    maxWidth: 1000,
    objectFit: 'cover',
    transform: 'scaleX(1)',
    width: '-webkit-fill-available',
  },
  loadingModel: {
    background: 'rgba(255, 255, 255, 0.5)',
    borderRadius: 5,
    fontSize: 16,
    marginLeft: 10,
    marginRight: 10,
    marginTop: 20,
    padding: 10,
  },
  container: {
    backgroundColor: colors.white,
    height: '100%',
  },
  topSection: {
    backgroundColor: colors.white,
    height: '100%',
    position: 'relative',
  },
  videoContainer: {
    margin: '0px auto',
    height: '100vh',
    maxWidth: '540px',
    width: '100%',
  },
  nonVideoContentContainer: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
  },
  calibratingCameraMessage: {
    background: colors.primaryColor,
    color: '#fff',
    fontSize: 16,
    marginTop: 40,
    padding: 10,
    textAlign: 'center',
  },
  graphCanvas: {
    position: 'absolute',
    zIndex: 100,
    height: '80%',
    width: '98%',
    paddingTop: 10,
    paddingLeft: 3,
  },
  badge: {
    fontSize: '1rem',
    marginTop: '-8px',
    marginRight: '-8px',
  }
};

const TOTAL_RECORD_TIME_SECONDS = 120;
const TARGET_FPS = 30;
const COUNTDOWN_MILLIS = 5000;
const GRAPH_DURATION = 5000;
const COLOR_SPACE = 'display-p3'; // default is 'srgb'

class ContactSpo2DataCollection5 extends Page {
  constructor(props) {
    super(props);

    this.state = {
      retries: 0,
      countingDown: false,
      countdownStart: null,
      failed: false,
      cameraError: '',
      cameraObject: {},
      workerReady: false,
      SPO2: undefined,
      savedCount: 0,
      saveRequested: 0,
      finishedRecording: false,
      hasDevice: false,
      startTimestamp: null,
      recordMilliseconds: null,
      deviceError: null,
      deviceErrors: [],
    };

    this.timestamps = null;
    this.lastLightingTimestamp = Date.now();
    this.deviceReadings = [];
    this.greenChannelCollection = [];
    this.lightingStatus = null;

    this.activityTimer = null;
    this.patientCanvas = React.createRef();
    this.patientVideo = React.createRef();
    this.videoContainer = React.createRef();
    this.graphCanvas = React.createRef();
  }

  handleDeviceData = (deviceData, deviceError) => {

    if (deviceError) {
      this.setState(state => ({ deviceErrors: state.deviceErrors.push({ deviceData, deviceError }) }));

      switch (deviceError.code) {
        case 910:
        case 911: // just log crc and malformed packets
          return console.error(deviceError.toString());
        case 901: // disconnected
          // if we already have an error, dont replace it with the disconnect
          if (this.state.deviceError) return this.setState({ failed: true }); // eslint-disable-next-line
        default:
          return this.setState({ deviceError: deviceError.toString(), failed: true });
      }
    }

    this.setState({ SPO2: deviceData.SPO2 });
    if (!this.state.finishedRecording) {
      this.deviceReadings.push({ ...deviceData, timestamp: Date.now() });
    }
  }

  componentDidMount = async () => {
    const { contactSpo2DataCollection, userId } = this.props;
    const { patientVideo } = this;
    const {
      FPS,
      seconds,
      hasDevice,
      imgEncoding,
      jpegQuality,
      reader,
      cropFace,
    } = this.getDataMap(contactSpo2DataCollection);

    const recordMilliseconds = (seconds || TOTAL_RECORD_TIME_SECONDS) * 1000;
    this.setState({ hasDevice, recordMilliseconds });

    if (reader) {
      try {
        await reader.stream(this.handleDeviceData);
      } catch (deviceError) {
        return this.setState({ deviceError: deviceError.toString(), failed: true });
      }
    }

    const cameraBuddy = new CameraBuddy(patientVideo, userId, cropFace, imgEncoding, jpegQuality, recordMilliseconds, FPS || TARGET_FPS, true);
    this.cameraBuddy = cameraBuddy;
    try {
      const cameraObject = await this.cameraBuddy.startCamera(COLOR_SPACE);
      this.setState({ cameraObject });
    } catch (err) {
      return this.setState({ failed: true, cameraError: err });
    }

    const offscreenCanvas = new OffscreenCanvas(patientVideo.current.videoWidth / 4, patientVideo.current.videoHeight / 4);
    cameraBuddy.getImageData = getImageDataFunc(offscreenCanvas);
    cameraBuddy.startEmittingImgData();
    
    // set up our canvases
    const canvas = this.patientCanvas.current;
    canvas.height = this.patientVideo.current.videoHeight;
    canvas.width = this.patientVideo.current.videoWidth;
    this.ctx = canvas.getContext('2d', { willReadFrequently: true, colorSpace: COLOR_SPACE });

    const graphCv = this.graphCanvas.current;
    graphCv.height = this.patientVideo.current.videoHeight;
    graphCv.width = this.patientVideo.current.videoWidth;

    this.greenChannelCollection = [];
    const greenChannelGraph = new Graph(graphCv, GRAPH_DURATION);
    this.greenChannelGraph = greenChannelGraph;

    // trigger countdown and images to start saving
    this.setState({ countingDown: true, countdownStart: Date.now() });
    setTimeout(() => {
      this.cameraBuddy.startSaving();
      this.setState({ countingDown: false });
    }, COUNTDOWN_MILLIS);

    this.cameraBuddy.on('frame', (patientVideoCurrent, pvW, pvH) => {
      // draw the camera image in our canvas
      this.ctx.drawImage(patientVideoCurrent, 0, 0, pvW, pvH);
    });

    this.cameraBuddy.on('imgData', async ({ pvW, pvH, imgData }) => {
      const now = Date.now();

      if (this.state.countingDown) {
        const countdown = Math.max(Math.round((COUNTDOWN_MILLIS - (now - this.state.countdownStart)) / 1000), 0);
        const fontSize = Math.round(pvW / 8);
        drawNumber(this.ctx, countdown, pvW, pvH, fontSize, true);
      } else if ((now - this.lastLightingTimestamp) > GRAPH_POINT_DELAY) {
        this.lastLightingTimestamp = now;

        // pull image data and find average red, green, blue values, and standard deviation for red
        // const imgData = this.ctx.getImageData(pvW / 4, pvH / 4, pvW / 2, pvH / 2);
        this.findValues(imgData.data).then((rgbaAvgValues) => {
          const { red, green, blue, redSD } = rgbaAvgValues;

          this.lightingStatus = getLightingStatus(red, green, blue, redSD);

          let yValue = 0;
          if (green > 10) {
            yValue = green;
          }
          // add the green value to our array
          this.greenChannelCollection.push({ x: now, y: yValue });
          // remove any items older than 5s from the array
          this.greenChannelCollection.every((g) => {
            if ((now - g.x) > greenChannelGraph.duration) {
              this.greenChannelCollection.shift();
              return true;
            }
            return false;
          });
        });
      }
      // update our graph
      greenChannelGraph.draw(this.greenChannelCollection);
    });

    this.cameraBuddy.on('finishedSaving', async () => {
      await this.handleContinue();
    }, { once: true });

    this.cameraBuddy.on('saveRequested', (saveRequested, timestamp) => {
      if (!this.state.startTimestamp) {
        this.setState({ startTimestamp: timestamp });
      }
      this.setState({ saveRequested });
    });

    this.cameraBuddy.on('savedCount', (savedCount) => {
      this.setState({ savedCount });
    });

    this.cameraBuddy.on('failedCount', (failedCount, status, error) => {
      console.error('lost batch of frames', failedCount, status, error);
      return this.setState({ failed: true });
    });

    this.cameraBuddy.on('retry', () => {
      this.setState(state => ({ retries: state.retries + 1 }));
    });

    this.cameraBuddy.on('finishedRecording', async (timestamps) => {
      const { contactSpo2DataCollection } = this.props;
      const { reader } = this.getDataMap(contactSpo2DataCollection);
      this.setState({ timestamps, finishedRecording: true });
      if (reader) {
        await reader.stopStreaming();
      }
    }, { once: true });


    this.activityTimer = setInterval(throttledReset, 5000);

    // this all has to travel together or you'll miss the onready
    // eslint-disable-next-line no-undef
    const webWorker = new Worker('/workers/vitals-data-worker.js');
    webWorker.onerror = (err) => {
      console.error('webworker error', err);
    };
    const rawrPeer = rawr({ transport: transport(webWorker) });
    const { findValues } = rawrPeer.methods;
    this.findValues = findValues;
    rawrPeer.notifications.onready(() => {
      this.setState({ workerReady: true });
    });
    // end
  }

  componentWillUnmount = () => {
    this.endCall();
  }

  componentWillUnmount = () => {
    clearInterval(this.activityTimer);
    this.cameraBuddy.cleanup();
  }

  setDevMode = (e) => {
    // mobile workaround for double clicks
    const touchTap = {
      time: Date.now(),
      target: e.currentTarget,
    };
    const { latestTouchTap: ltt } = this.state;
    if (ltt && (ltt.target === touchTap.target) && (touchTap.time - ltt.time < 300)) {
      if (this.greenChannelGraph) {
        this.greenChannelGraph.toggleDevMode();
      }
    }
    this.setState({ latestTouchTap: touchTap });
  };

  handleContinue = async () => {
    if (this.state.failed) return;

    const { location, updatePRO, contactSpo2DataCollection, userId } = this.props;
    const { cameraObject } = this.state;

    const meta = {
      supportedConstraints: navigator.mediaDevices.getSupportedConstraints(),
      videoSettings: cameraObject.rearVideoTrack.getSettings(),
      ua: navigator.userAgent,
      deviceReadings: this.deviceReadings,
      timestamps: this.state.timestamps,
      formData: [...contactSpo2DataCollection],
    };

    let res;
    let error;
    try {
      res = await saveMetaData(meta, userId, this.state.startTimestamp, () => { this.setState(state => ({ retries: state.retries + 1 })); });
    } catch (e) {
      error = e;
    }

    if (error || !res.ok) {
      console.error('saveMetaData failed: ', error, res);
      return this.setState({ failed: true });
    }

    updatePRO({
      type: 'contactSpo2DataCollection',
      position: this.getTrackIndex(),
      value: {
        backCameraTimestamp: this.state.startTimestamp,
        userId,
        spo2: this.state.SPO2,
      },
    });

    this.forwardWithQuery(location.query);
  }

  render() {
    const { classes } = this.props;
    const {
      cameraError,
      hasDevice,
      finishedRecording,
      workerReady,
      SPO2,
      savedCount,
      saveRequested,
      startTimestamp,
      recordMilliseconds,
      failed,
    } = this.state;
    const secondsRemaining = startTimestamp ? (recordMilliseconds / 1000) - Math.round((Date.now() - startTimestamp) / 1000) : recordMilliseconds / 1000;
    return (
      <div className={classes.container}>
        <section className={classes.topSection}>
          <div id="videoContainer" className={classes.videoContainer} ref={this.videoContainer}>
            <video id="patientVideo" ref={this.patientVideo} playsInline autoPlay className={classes.patientVideo} muted />
            <canvas id="graphCanvas" ref={this.graphCanvas} className={classes.graphCanvas} />
            <canvas id="patientCanvas" ref={this.patientCanvas} className={classes.patientCanvas} style={{ border: `5px solid ${this.lightingStatus === 'ok' ? colors.successGreen : colors.errorRed}` }} />
          </div>
          <Snackbar
            open={failed}
            autoHideDuration={7000}
            onClose={async () => {
              // todo is there a way to safely reload THIS page instead?
              const { contactSpo2DataCollection } = this.props;
              const { reader } = this.getDataMap(contactSpo2DataCollection);
              if (reader) {
                await reader.disconnect();
              }
              this.goToIndexWithQuery(0, this.props.location.query);
            }}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            <MuiAlert elevation={6} variant="filled" severity="error" style={{ fontSize: 16 }}>There has been an error. The session will restart.</MuiAlert>
          </Snackbar>
          {/* todo these need to be cssed better */}
          <Snackbar
            open={this.state.deviceError}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            <MuiAlert elevation={6} variant="filled" severity="error" style={{ fontSize: 16 }}>{this.state.deviceError}</MuiAlert>
          </Snackbar>
          <Snackbar
            open={this.lightingStatus === 'Too Bright'}
            autoHideDuration={5000}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            <MuiAlert elevation={6} variant="filled" severity="error" style={{ fontSize: 16 }}>Lighting is too bright, please adjust your finger to better cover the lens.</MuiAlert>
          </Snackbar>
          <Snackbar
            open={this.lightingStatus === 'Too Dark'}
            autoHideDuration={5000}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            <MuiAlert elevation={6} variant="filled" severity="error" style={{ fontSize: 16 }}>Lighting is too dark, please adjust your finger to allow more light.</MuiAlert>
          </Snackbar>
          <Snackbar
            open={this.lightingStatus === 'Adjust Finger'}
            autoHideDuration={5000}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            <MuiAlert elevation={6} variant="filled" severity="error" style={{ fontSize: 16 }}>Finger position is not in correct position. Please adjust your finger.</MuiAlert>
          </Snackbar>
          <div className={classes.nonVideoContentContainer}>
            {cameraError ? (
              <div><span>Unable to access camera: {cameraError.toString()}</span><br /></div>
            ) : ''}
            <HiddenContent hidden={finishedRecording || !workerReady}>
              <div className={classes.calibratingCameraMessage}>
                <div>Please hold still while the camera is recording{secondsRemaining ? `: ${secondsRemaining} seconds remaining` : ''}</div>
                {hasDevice ? (
                  <div>SPO2: {SPO2 && SPO2 !== 255 ? SPO2 : '--'}</div>
                ) : ''}
              </div>
            </HiddenContent>
            <HiddenContent hidden={!workerReady}>
              <div className={classes.loadingModel} onClick={this.setDevMode}>
                <Badge color="error" classes={{ colorError: classes.badge }} badgeContent={this.state.retries} >
                  <span>Images Uploading: {`${savedCount} of ${saveRequested}`}</span>
                </Badge>
                <LinearProgress value={Math.round((savedCount / saveRequested) * 100)} variant="determinate" />
              </div>
            </HiddenContent>
          </div>
        </section >
      </div >
    );
  }
}

function mapStateToProps(state) {
  const { user, proForms: { contactSpo2DataCollection } } = state;
  return {
    userId: user.id,
    contactSpo2DataCollection,
  };
}

ContactSpo2DataCollection5.proptypes = {
  updatePRO: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, { updatePRO })(withStyles(styles)(ContactSpo2DataCollection5));
