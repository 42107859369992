/* eslint max-len: 0 */
/* eslint react/jsx-indent: 0 */
import React from 'react';
import { connect } from 'react-redux';
import memoize from 'memoize-one';

import Calendar from '../components/calendar';
import { browseFormCompletion } from '../state/form-completion';
import { personalInfoIfNeeded } from '../state/personal-info';
import { fetchUserReport, updateAvatarUrl } from '../state/user';
import { browseMigraineHx } from '../state/migraine-hx';
import { browsePainHx } from '../state/pain-hx';
import painCalendarFormatter from '../lib/pain-calendar-formatter';
import Page from './page';
import { colors, mainDashboard } from '../lib/styles';

const baseStyles = {
  alert: {
    color: colors.primaryColor,
  },
  bell: {
    height: '3.75rem',
    width: '3.75rem',
    color: colors.charcoal,
    cursor: 'pointer',
  },
  btn: {
    height: '5rem',
    backgroundColor: colors.secondaryColor,
    marginTop: '1.5rem',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  calendarWrapper: {
    padding: '20px 30px',
  },
  check: {
    color: colors.successGreen,
  },
  headerText: {
    fontSize: '1.8rem',
  },
  label: {
    fontSize: '1.4rem',
  },
  leftIcon: {
    display: 'none',
  },
  paragraph: {
    marginBottom: '0px',
    textAlign: 'center',
    marginLeft: '15px',
    marginRight: '15px',
    fontSize: '1.4rem',
  },
  paragraph2: {
    marginBottom: '0px',
    textAlign: 'center',
    marginLeft: '15px',
    marginRight: '15px',
    fontSize: '1.4rem',
    marginTop: '2rem',
  },
  shareHealthBtn: {
    marginTop: '1.5rem',
    marginBottom: '1.5rem',
    height: '5rem',
    alignSelf: 'center',
  },
  shareHealthLabel: {
    fontSize: '1.4rem',
  },
  title: {
    color: colors.black,
    fontWeight: 400,
    margintop: '-4rem',
    fontSize: '1.875rem',
    textAlign: 'center',
  },
};

const getCurrentMonth = () => {
  const currDate = new Date();
  return currDate.getMonth();
};

const getCurrentYear = () => {
  const currDate = new Date();
  return currDate.getFullYear();
};

class PainHistoryCalendar extends Page {
  constructor(props) {
    super(props);

    this.state = {
      calendarData: [],
      month: getCurrentMonth(),
      section: 'painLogs',
      year: getCurrentYear(),
    };

    this.onClickCalendarBackButton = this.onClickCalendarBackButton.bind(this);
    this.onClickCalendarForwardButton = this.onClickCalendarForwardButton.bind(this);
    this.updatePainLogCalendar = this.updatePainLogCalendar.bind(this);
  }

  componentWillMount() {
    this.props.browseFormCompletion();
    this.props.personalInfoIfNeeded();
  }

  buildCalendarData = memoize((migraineHxData, painHxData) => {
    return painCalendarFormatter([...Object.values(migraineHxData), ...Object.values(painHxData)]);
  });

  onClickCalendarBackButton = () => {
    let { month, year } = this.state;

    month = (month === 0) ? 11 : (month - 1);
    year = (month === 11) ? (year - 1) : year;

    this.setState({ month, year });
    this.updatePainLogCalendar(month, year);
  }

  onClickCalendarForwardButton = () => {
    let { month, year } = this.state;

    month = (month + 1) % 12;
    year = (month === 0) ? (year + 1) : year;

    this.setState({ month, year });
    this.updatePainLogCalendar(month, year);
  }

  updatePainLogCalendar(currMonth, currYear) {
    const startDate = new Date(currYear, currMonth, 1);
    let endDate = new Date(currYear, currMonth + 1, 0);
    endDate = new Date(endDate.getTime() + 86399999); // 1 second before end of day
    this.props.browsePainHx(startDate.toISOString(), endDate.toISOString());
    this.props.browseMigraineHx(startDate.toISOString(), endDate.toISOString());
  }

  render() {
    const calendarData = this.buildCalendarData(this.props.migraineHxData, this.props.painHxData);
    return (
      <section style={mainDashboard.container}>
        <div style={baseStyles.calendarWrapper}>
        <h1 style={baseStyles.title}>My Pain History</h1>
          <Calendar
            data={calendarData}
            month={this.state.month}
            onClickBackButton={this.onClickCalendarBackButton}
            onClickForwardButton={this.onClickCalendarForwardButton}
            year={this.state.year}
            updatePainLogCalendar={this.updatePainLogCalendar}
          />
        </div>
      </section>
    );
  }
}

function mapStateToProps(state) {
  const {
    migraineHx: { data: migraineHxData },
    painHx: { data: painHxData },
    formCompletion, personalInfo, user,
  } = state;

  return {
    formCompletion,
    migraineHxData,
    painHxData,
    personalInfo,
    updateAvatarUrl,
    user,
  };
}

export default connect(mapStateToProps, {
  browsePainHx,
  browseMigraineHx,
  fetchUserReport,
  browseFormCompletion,
  personalInfoIfNeeded,
  updateAvatarUrl,
})(PainHistoryCalendar);
