/* eslint-disable react/no-multi-comp */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { get, forEach, map, noop } from 'lodash';

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import { colors } from '../lib/styles';

const bodies = ['FRONT', 'RIGHT', 'BACK', 'LEFT'];

const baseStyles = {
  absolute: {
    position: 'absolute',
  },
  container: {
    alignItems: 'stretch',
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  iconButtonWrapper: {
    position: 'relative',
  },
  iconLeft: {
    marginRight: -15,
    position: 'relative',
    top: '37%',
    fontSize: 50,
    color: 'red',
  },
  iconRight: {
    marginLeft: -15,
    position: 'relative',
    top: '37%',
    fontSize: 50,
    color: 'red',
  },
  imgWrapper: {
    flexGrow: 1,
    flexShrink: 1,
    position: 'relative',
  },
  painIndicator: {
    backgroundColor: colors.secondaryColor,
    borderRadius: '50%',
    height: '1.5rem',
    position: 'absolute',
    width: '1.5rem',
  },
  painPointCloseTopRight: {
    alignItems: 'center',
    background: '#848484',
    borderRadius: '50%',
    display: 'flex',
    height: '2rem',
    justifyContent: 'center',
    position: 'absolute',
    right: 0,
    top: 0,
    transform: 'translate(30%, -30%)',
    width: '2rem',
    zIndex: 999,
  },
  painPointCloseTopLeft: {
    alignItems: 'center',
    background: '#848484',
    borderRadius: '50%',
    display: 'flex',
    height: '2rem',
    justifyContent: 'center',
    left: 0,
    position: 'absolute',
    top: 0,
    transform: 'translate(-30%, -30%)',
    width: '2rem',
    zIndex: 999,
  },
  painPointCloseBackSlash: {
    background: colors.white,
    height: '0.2rem',
    position: 'absolute',
    transform: 'rotate(45deg)',
    width: '1rem',
  },
  painPointCloseForwardSlash: {
    background: colors.white,
    height: '0.2rem',
    position: 'absolute',
    transform: 'rotate(-45deg)',
    width: '1rem',
  },
  svgStyle: {
    bottom: 0,
    left: 0,
    position: 'absolute',
    height: '100%',
    right: 0,
    top: 0,
    width: '100%',
  },
  tooltipBottom: {
    alignItems: 'center',
    backgroundColor: 'rgb(34, 34, 34)',
    borderRadius: '5px',
    color: 'rgb(255, 255, 255)',
    display: 'flex',
    fontSize: '1.5rem',
    height: '1.75rem',
    justifyContent: 'center',
    marginLeft: '50%',
    opacity: '0.9',
    padding: '8px 21px',
    position: 'relative',
    textAlign: 'center',
    transform: 'translate(-50%, 100%)',
    zIndex: 998,
    minWidth: '10rem',
  },
  tooltipArrowBottom: {
    backgroundColor: 'rgb(34, 34, 34)',
    borderRadius: '3px',
    left: '50%',
    height: '2rem',
    position: 'absolute',
    bottom: '90%',
    transform: 'translate(-50%, 50%) rotate(45deg)',
    width: '2rem',
    zIndex: 998,
  },
  tooltipLeft: {
    alignItems: 'center',
    backgroundColor: 'rgb(34, 34, 34)',
    borderRadius: '5px',
    color: 'rgb(255, 255, 255)',
    display: 'flex',
    fontSize: '1.5rem',
    height: '1.75rem',
    justifyContent: 'center',
    opacity: '0.9',
    padding: '8px 21px',
    position: 'relative',
    textAlign: 'center',
    transform: 'translate(-115%, -30%)',
    zIndex: 998,
    minWidth: '10rem',
  },
  tooltipArrowLeft: {
    backgroundColor: 'rgb(34, 34, 34)',
    borderRadius: '3px',
    height: '2rem',
    right: 0,
    position: 'absolute',
    transform: 'translate(40%, -0%) rotate(45deg)',
    width: '2rem',
    zIndex: 998,
  },
  tooltipRight: {
    alignItems: 'center',
    backgroundColor: 'rgb(34, 34, 34)',
    borderRadius: '5px',
    color: 'rgb(255, 255, 255)',
    display: 'flex',
    fontSize: '1.5rem',
    height: '1.75rem',
    justifyContent: 'center',
    opacity: '0.9',
    padding: '8px 21px',
    position: 'relative',
    textAlign: 'center',
    transform: 'translate(25%, -30%)',
    zIndex: 998,
    minWidth: '10rem',
  },
  tooltipArrowRight: {
    backgroundColor: 'rgb(34, 34, 34)',
    borderRadius: '3px',
    height: '2rem',
    left: 0,
    position: 'absolute',
    transform: 'translate(-40%, -0%) rotate(45deg)',
    width: '2rem',
    zIndex: 998,
  },
  tooltipTop: {
    alignItems: 'center',
    backgroundColor: 'rgb(34, 34, 34)',
    borderRadius: '5px',
    color: 'rgb(255, 255, 255)',
    display: 'flex',
    fontSize: '1.5rem',
    height: '1.75rem',
    justifyContent: 'center',
    marginLeft: '50%',
    opacity: '0.9',
    padding: '8px 21px',
    position: 'relative',
    textAlign: 'center',
    transform: 'translate(-50%, -160%)',
    zIndex: 999,
    minWidth: '10rem',
  },
  tooltipArrowTop: {
    backgroundColor: 'rgb(34, 34, 34)',
    borderRadius: '3px',
    left: '50%',
    height: '2rem',
    position: 'absolute',
    top: '90%',
    transform: 'translate(-50%, -50%) rotate(45deg)',
    width: '2rem',
    zIndex: 998,
  },
  tooltipLabel: {
    zIndex: 999,
  },
};

const getPainPointStyles = (labelLocation, closeLocation) => {
  const painPointClose = baseStyles[`painPointClose${closeLocation}`];
  const toolTip = baseStyles[`tooltip${labelLocation}`];
  const toolTipArrow = baseStyles[`tooltipArrow${labelLocation}`];

  return {
    painPointClose,
    toolTip,
    toolTipArrow,
  };
};

const nativePainBodyHeight = 525;
const nativePainBodyWidth = 250;

// TODO: Handle tooltip overlaps
// Temporarily leaving these in the same file
// until body is put in generic component repo
class PainPoint extends Component {
  render() {
    const {
      location,
      locations,
      onClickCancel,
      usersPrimaryLanguage,
    } = this.props;
    const { x, y } = location;
    const normalizedX = (x / nativePainBodyWidth) * 100;
    const normalizedY = (y / nativePainBodyHeight) * 100;
    let painPointClose;
    let toolTip;
    let toolTipArrow;
    let otherPoint;

    if (locations.length === 2) {
      [otherPoint] = locations.filter((currLocation) => {
        return currLocation.confirmed === true;
      });
    }

    /*
     * lowerX, upperX, lowerY, and upperY are used to estimates the boundaries that a label
     * would potentially have with its default placement. The height of 12 and width of 50
     * were found through experimentation
     */

    if (otherPoint && ((location.x !== otherPoint.x) || (location.y !== otherPoint.y))) {
      const otherNormalizedX = (otherPoint.x / nativePainBodyWidth) * 100;
      const otherNormalizedY = (otherPoint.y / nativePainBodyHeight) * 100;

      if (normalizedX < 27) {
        const lowerX = normalizedX;
        const upperX = normalizedX + 50;
        const lowerY = normalizedY - 6;
        const upperY = normalizedY + 6;

        if ((otherNormalizedX > lowerX) && (otherNormalizedX < upperX) && (otherNormalizedY > lowerY) && (otherNormalizedY < upperY)) {
          ({ painPointClose, toolTip, toolTipArrow } = getPainPointStyles('Top', 'TopRight'));
        } else {
          ({ painPointClose, toolTip, toolTipArrow } = getPainPointStyles('Right', 'TopRight'));
        }
      } else if (normalizedY < 12) {
        const lowerX = normalizedX - 25;
        const upperX = normalizedX + 25;
        const lowerY = normalizedY;
        const upperY = normalizedY + 12;

        if ((otherNormalizedX > lowerX) && (otherNormalizedX < upperX) && (otherNormalizedY > lowerY) && (otherNormalizedY < upperY)) {
          ({ painPointClose, toolTip, toolTipArrow } = getPainPointStyles('Right', 'TopLeft'));
        } else {
          ({ painPointClose, toolTip, toolTipArrow } = getPainPointStyles('Bottom', 'TopRight'));
        }
      } else if (normalizedX > 74) {
        const lowerX = normalizedX - 50;
        const upperX = normalizedX;
        const lowerY = normalizedY - 6;
        const upperY = normalizedY + 6;

        if ((otherNormalizedX > lowerX) && (otherNormalizedX < upperX) && (otherNormalizedY > lowerY) && (otherNormalizedY < upperY)) {
          ({ painPointClose, toolTip, toolTipArrow } = getPainPointStyles('Top', 'TopRight'));
        } else {
          ({ painPointClose, toolTip, toolTipArrow } = getPainPointStyles('Left', 'TopLeft'));
        }
      } else {
        const lowerX = normalizedX - 17.5;
        const upperX = normalizedX + 17.5;
        const lowerY = normalizedY - 12;
        const upperY = normalizedY;

        if ((otherNormalizedX > lowerX) && (otherNormalizedX < upperX) && (otherNormalizedY > lowerY) && (otherNormalizedY < upperY)) {
          ({ painPointClose, toolTip, toolTipArrow } = getPainPointStyles('Right', 'TopLeft'));
        } else {
          ({ painPointClose, toolTip, toolTipArrow } = getPainPointStyles('Top', 'TopRight'));
        }
      }
    } else if (normalizedX < 27) {
      ({ painPointClose, toolTip, toolTipArrow } = getPainPointStyles('Right', 'TopRight'));
    } else if (normalizedY < 12) {
      ({ painPointClose, toolTip, toolTipArrow } = getPainPointStyles('Bottom', 'TopRight'));
    } else if (normalizedX > 74) {
      ({ painPointClose, toolTip, toolTipArrow } = getPainPointStyles('Left', 'TopLeft'));
    } else {
      ({ painPointClose, toolTip, toolTipArrow } = getPainPointStyles('Top', 'TopRight'));
    }

    const style = Object.assign({
      left: `calc(${normalizedX}% - 0.75rem)`,
      top: `calc(${normalizedY}% - 0.75rem)`,
    }, baseStyles.painIndicator);

    // get pain location name in user's primary language
    let languageKey = 'name';
    if (usersPrimaryLanguage !== 'en') languageKey = `${usersPrimaryLanguage}_name`;

    return (
      /* eslint-disable */
      <div style={style}>
        {location.painStart &&
          <div  style={toolTip}>
            <div style={baseStyles.tooltipLabel}>
              {location[languageKey]}
            </div>
            {!location.confirmed &&
            <div onClick={onClickCancel} style={painPointClose}>
              <div style={baseStyles.painPointCloseBackSlash} />
              <div style={baseStyles.painPointCloseForwardSlash} />
            </div>
            }
            <div style={toolTipArrow} />
          </div>
        }
      </div>
      /* eslint-disable */
    );
  }
}

PainPoint.propTypes = {
  location: PropTypes.object.isRequired,
  locations: PropTypes.array.isRequired,
  onClickCancel: PropTypes.func.isRequired,
  usersPrimaryLanguage: PropTypes.string.isRequired,
};

class PainLine extends Component {
  render() {
    const { startLocation, endLocation } = this.props;

    const x1 = (startLocation.x / nativePainBodyWidth) * 100;
    const y1 = (startLocation.y / nativePainBodyHeight) * 100;
    const x2 = (endLocation.x / nativePainBodyWidth) * 100;
    const y2 = (endLocation.y / nativePainBodyHeight) * 100;

    return (
      <line
        x1={`${x1}%`}
        y1={`${y1}%`}
        x2={`${x2}%`}
        y2={`${y2}%`}
        strokeDasharray="0.3125rem, 0.625rem"
        stroke={colors.secondaryColor}
        strokeWidth="0.3125rem"
      />
    );
  }
}

PainLine.propTypes = {
  endLocation: PropTypes.object.isRequired,
  startLocation: PropTypes.object.isRequired,
};

function makeLines(painLocations) {
  if (painLocations.length < 2) {
    return [];
  }

  const painLines = [];

  forEach(painLocations, (startLocation, idx) => {
    const endLocation = painLocations[idx + 1];
    if (!endLocation) {
      return;
    }

    painLines.push(<PainLine
      key={`${startLocation.id}-${endLocation.id}`}
      startLocation={startLocation}
      endLocation={endLocation}
      />);
  });

  return painLines;
}

class PainBody extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentBody: 'BACK',
    };
    this.nextBody = this.nextBody.bind(this);
    this.prevBody = this.prevBody.bind(this);
    this.imgClick = this.imgClick.bind(this);
  }
  imgClick(e) {
    const currentHeight = this.painBodyImg.clientHeight;
    const currentWidth = this.painBodyImg.clientWidth;

    const heightScaleFactor = nativePainBodyHeight / currentHeight;
    const widthScaleFactor = nativePainBodyWidth / currentWidth;
    const x = e.nativeEvent.offsetX * heightScaleFactor;
    const y = e.nativeEvent.offsetY * widthScaleFactor;

    this.props.onBodyClick(x, y, this.state.currentBody);
  }
  nextBody() {
    const { currentBody } = this.state;
    const idx = bodies.indexOf(currentBody);
    const body = bodies[(idx + 1) % 4];
    this.setState({ currentBody: body });
    this.props.updateCurrentBody(body);
  }
  prevBody() {
    const { currentBody } = this.state;
    const idx = bodies.indexOf(currentBody);
    let newIndex = idx - 1;
    if (newIndex < 0) {
      newIndex = 3;
    }
    const body = bodies[newIndex];
    this.setState({ currentBody: body });
    this.props.updateCurrentBody(body);
  }
  render() {
    const {
      onClickCancel,
      selectedLocations,
      usersPrimaryLanguage,
    } = this.props;
    const validLocations = selectedLocations.filter(l => get(l, 'body_location') === this.state.currentBody);

    const painPoints = map(validLocations, (l) => {
      return (
        <PainPoint
          key={l.id}
          location={l}
          locations={validLocations}
          onClickCancel={onClickCancel}
          usersPrimaryLanguage={usersPrimaryLanguage}
        />
      );
    });

    const painLines = makeLines(validLocations);

    // eslint disabled due to a11y errors stemming from the div with an onClick prop
    /* eslint-disable */
    return (
      <div style={baseStyles.container}>
        <div style={baseStyles.iconButtonWrapper}>
          <ChevronLeftIcon
            onClick={this.prevBody}
            style={baseStyles.iconLeft}
          />
        </div>
        <div style={baseStyles.imgWrapper}>
          <img
            alt="The human body"
            height="auto"
            width="100%"
            src={`/img/body-pain-${this.state.currentBody}.png`}
          />
          <div style={baseStyles.svgStyle}>
            <svg height="100%" width="100%">
              {painLines}
            </svg>
          </div>
          <div
            onClick={this.imgClick}
            ref={(el) => { this.painBodyImg = el; }}
            style={baseStyles.svgStyle}
          >
            {painPoints}
          </div>
        </div>
        <div style={baseStyles.iconButtonWrapper}>
            <ChevronRightIcon
              onClick={this.nextBody}
              style={baseStyles.iconRight}
            />
        </div>
      </div>
    );
    /* eslint-enable */
  }
}

PainBody.defaultProps = {
  iconMarginTop: '35vw',
  onBodyClick: noop,
  onClickCancel: noop,
  selectedLocations: [],
  updateCurrentBody: noop,
};

PainBody.propTypes = {
  onBodyClick: PropTypes.func,
  onClickCancel: PropTypes.func,
  selectedLocations: PropTypes.array,
  updateCurrentBody: PropTypes.func,
  usersPrimaryLanguage: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
  const { user } = state;

  return { usersPrimaryLanguage: user.primary_language };
}

export default connect(mapStateToProps)(PainBody);
