import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get, reject, split, uniqueId, find } from 'lodash';
import Divider from '@material-ui/core/Divider';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import { DatePicker } from '@material-ui/pickers';
import { withStyles } from '@material-ui/core/styles';

import AppBar from '../components/app-bar';
import { apiFetch, makeShadowOptions } from '../lib/fetch';
import Continue from '../components/continue';
import Page from './page';
import Layout from '../layouts/common';
import { colors } from '../lib/styles';
import { updatePRO } from '../state/pro-forms';

const baseStyles = {
  marginLeft: {
    marginLeft: '15px',
    fontSize: '12px',
    width: '93%',
  },
  marginTop: {
    marginTop: '30px',
  },
  redErrorText: {
    color: colors.errorRed,
    marginLeft: '15px',
    marginRight: '15px',
    textAlign: 'center',
  },
  sectionTitle: {
    color: colors.black,
    fontSize: '1.6rem',
    marginLeft: '15px',
    marginBottom: '25px',
    textAlign: 'left',
  },
  whiteLeftSection: {
    backgroundColor: colors.white,
    textAlign: 'left',
  },
  divider: {
    marginBottom: '10px',
  },
  medicationName: {
    padding: '0px',
    position: 'relative',
    width: '100%',
    border: 'none',
    outline: 'none',
    backgroundColor: 'rgba(0, 0, 0, 0)',
    color: 'black',
    cursor: 'inherit',
    font: 'inherit',
    opacity: '1',
    height: '100%',
    boxSizing: 'border-box',
    marginTop: '14px',
    marginLeft: '15px',
    fontSize: '16px',
  },
  dosageStyle: {
    width: '100%',
    left: '15px',
  },
  amountStyle: {
    width: '25%',
    marginLeft: '14px',
    color: 'rgb(155, 155, 155)',
    height: '56px',
    lineHeight: '56px',
    overflow: 'hidden',
    opacity: '1',
    position: 'relative',
    top: '34px',
    left: '-14.5vh',
    whiteSpace: 'nowrap',
  },
  frequencyStyle: {
    width: '100%',
    marginLeft: '14px',
    color: 'rgb(155, 155, 155)',
    height: '56px',
    lineHeight: '56px',
    overflow: 'hidden',
    opacity: '1',
    position: 'relative',
    top: '34px',
    left: '-14vh',
    whiteSpace: 'nowrap',
  },
  fieldError: {
    color: 'red',
  },
  secondDosage: {
    marginLeft: '13px',
  },
  blueHeader: {
    color: 'rgb(32, 123, 204)',
  },
  drawer: {
    width: '95%',
  },
  textField: {
    '& input': {
      fontSize: 16,
    },
  },
};

const timingOptions = ['daily', 'weekly', 'monthly'];
const frequencyTimingMenuOptions = timingOptions.map(o => (
  <MenuItem key={o} value={o} primaryText={o} style={{ textTransform: 'capitalize' }} />
));

const frequencyAmountMenuOptions = [];

for (let i = 0; i < 12; i++) {
  frequencyAmountMenuOptions.push(<MenuItem key={i} value={i} primaryText={i} />);
}

class HypertensionAssessment3 extends Page {
  constructor(props) {
    super(props);

    const startDate = get(props, 'medication.start_date', null);
    const name = get(props, 'router.location.query.name', '');
    this.state = {
      medicationNameError: '',
      dosageError: '',
      frequencyError: '',
      dosageOptions: [],
      medication: {
        id: null,
        name,
        dosage: '',
        frequency: '1 daily',
        prescribing_physician: '',
        category: 'OTHER',
        ...props.medication,
        start_date: startDate ? new Date(startDate) : null,
      },
    };

    this.handleSave = this.handleSave.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleChangeName = this.handleTextFieldUpdate.bind(this, 'name');
    this.handleChangeDosage = this.handleTextFieldUpdate.bind(this, 'dosage');
    this.handleChangeFrequency = this.handleTextFieldUpdate.bind(this, 'frequency');
    this.handleChangePhysician = this.handleTextFieldUpdate.bind(this, 'prescribing_physician');
    this.handleChangeStartDate = this.handleDateUpdate.bind(this, 'start_date');

    this.handleChangeFrequencyTiming = this.handleChangeFrequencySplit.bind(this, 'timing');
    this.handleChangeFrequencyAmount = this.handleChangeFrequencySplit.bind(this, 'amount');
  }

  componentWillMount() {
    const { medication } = this.props;
    const name = get(this.props, 'router.location.query.name', '');
    if (name || (medication && medication.name)) {
      const options = makeShadowOptions({ name: name || medication.name }, this.props.user.token, 'GET');
      apiFetch('/goodrx_dosages', options)
        .then((dosageOptions) => {
          this.setState({ dosageOptions });
        });
    }
  }

  handleChangeFrequencySplit(type, e) {
    const [amount, timing] = this.state.medication.frequency.split(' ');
    let frequency;
    if (type === 'amount') {
      frequency = `${e.target.value} ${timing}`;
    } else {
      frequency = `${amount} ${e.target.value}`;
    }
    this.setState({
      medication: {
        ...this.state.medication,
        frequency,
      },
    });
  }

  handleTextFieldUpdate(field, e) {
    let { medicationNameError, dosageError, frequencyError } = this.state;

    if (field === 'name') {
      medicationNameError = '';
    }
    if (field === 'dosage') {
      dosageError = '';
    }
    if (field === 'frequency') {
      frequencyError = '';
    }

    this.setState({
      medicationNameError,
      dosageError,
      frequencyError,
      medication: {
        ...this.state.medication,
        [field]: e.target.value,
      },
    });
  }

  handleDateUpdate(field, e) {
    this.setState({
      medication: {
        ...this.state.medication,
        [field]: e,
      },
    });
  }

  handleSave() {
    const { medication } = this.state;
    const { hypertensionAssessment, medications } = this.props;
    let medicationNameError = '';
    let dosageError = '';
    let frequencyError = '';
    if (!medication.name) {
      medicationNameError = 'Medication Name is required!';
    }
    if (!medication.dosage) {
      dosageError = 'Dosage is required!';
    }
    if (!medication.frequency) {
      frequencyError = 'Frequency is required!';
    }
    if (medicationNameError || dosageError || frequencyError) {
      return this.setState({ medicationNameError, dosageError, frequencyError });
    }

    let update;
    if (medication.id) {
      update = reject(medications, { id: medication.id }).concat(medication);
    } else {
      const newMedication = { ...medication, id: uniqueId('medication') }; // Generate an id that can be shared between screens
      update = medications.concat(newMedication);
    }
    this.props.updatePRO({ type: 'hypertensionAssessment', position: 1, value: { ...hypertensionAssessment[1], medications: update } });
    this.goToIndexWithQuery(1, { ...this.props.location.query, name: undefined });
  }

  handleDelete() {
    const { hypertensionAssessment, medication, medications } = this.props;

    const updatedMedications = reject(medications, { id: medication.id });
    this.props.updatePRO({
      type: 'hypertensionAssessment',
      position: 1,
      value: {
        ...hypertensionAssessment[1],
        medications: updatedMedications,
      },
    });

    this.props.updatePRO({ type: 'hypertensionAssessment', position: 1, value: reject(medications, { id: medication.id }) });
    // this.props.router.goBack();
    const query = { ...this.props.location.query };
    delete query.medication_id;

    this.goToIndexWithQuery(1, query);
  }

  render() {
    let deleteButton = null;
    let displayedError;
    const { dosageOptions, medication, medicationNameError, dosageError, frequencyError } = this.state;
    const fieldError = medicationNameError || dosageError || frequencyError;
    const { addError, deleteError, editError } = this.props;
    const [frequencyAmount, frequencyTiming] = split(medication.frequency, ' ');
    const numAmount = Number(frequencyAmount);
    if (addError || deleteError || editError) {
      displayedError = (
        <div style={baseStyles.redErrorText}>
          There was an error submitting this page. Please try again.
        </div>);
    }
    if (medication.id) {
      deleteButton = (
        <Continue
          text="Delete Medication"
          btnStyle={{ backgroundColor: colors.errorRed }}
          onClick={this.handleDelete}
        />);
    }
    return (
      <Layout>
        <AppBar
          backButtonOnClick={() => this.props.router.goBack()}
          headerNode="Medication"
        />
        <section>
          <p style={baseStyles.sectionTitle}>
            MEDICATION DETAILS
          </p>
        </section>
        <section className="form" style={baseStyles.whiteLeftSection}>
          <div
            style={baseStyles.divider}
          >
            <InputLabel
              style={baseStyles.medicationName}
              InputProps={{ disableUnderline: true }}
            >
              Medication Name *
            </InputLabel>
            <TextField
              style={{ ...baseStyles.marginLeft, ...baseStyles.blueHeader }}
              value={medication.name}
              onChange={this.handleChangeName}
              InputProps={{ disableUnderline: true }}
            />
          </div>
          <Divider />
          {(dosageOptions.length && (!medication.id || dosageOptions.includes(medication.dosage)) ? (
            <div>
              <InputLabel
                style={{ ...baseStyles.medicationName, ...baseStyles.blueHeader }}
              >
                Dosage *
              </InputLabel>
              <TextField
                id="Dosage"
                select
                placeholder="Dosage *"
                root={{ marginLeft: '10px' }}
                style={{ ...baseStyles.dosageStyle, ...baseStyles.blueHeader, ...baseStyles.drawer }}
                value={medication.dosage}
                onChange={this.handleChangeDosage}
                InputProps={{ disableUnderline: true }}
                SelectProps={{
                  native: true,
                }}
              >
                <option key={0} value="">
                  Select
                </option>
                {dosageOptions.map(d => (
                  <option key={d} value={d}>
                    {d}
                  </option>
                ))}
              </TextField>
            </div>
          ) : (
            <div>
              <InputLabel
                style={{ ...baseStyles.medicationName, ...baseStyles.blueHeader }}
              >
                Dosage *
              </InputLabel>
              <TextField
                id="Dosage"
                value={medication.dosage}
                style={{ ...baseStyles.secondDosage }}
                placeholder="Dosage *"
                onChange={this.handleChangeDosage}
                InputProps={{ disableUnderline: true }}
              />
            </div>
          ))}
          <Divider />
          <div style={{ display: 'flex', marginBottom: '14px' }}>
            <InputLabel
              style={{ ...baseStyles.medicationName, ...baseStyles.blueHeader }}
            >
              Amount
            </InputLabel>
            <TextField
              id="Amount"
              select
              placeholder="Amount"
              style={baseStyles.amountStyle}
              value={numAmount}
              onChange={this.handleChangeFrequencyAmount}
              InputProps={{ disableUnderline: true }}
              SelectProps={{
                native: true,
              }}
            >
              {frequencyAmountMenuOptions.map(option => (
                <option key={option.key} value={option.key}>
                  {option.key}
                </option>
              ))}
            </TextField>
            <InputLabel
              style={{ ...baseStyles.medicationName, ...baseStyles.blueHeader }}
            >
              Frequency
            </InputLabel>
            <TextField
              id="Frequency"
              select
              placeholder="Frequency"
              style={baseStyles.frequencyStyle}
              value={frequencyTiming}
              onChange={this.handleChangeFrequencyTiming}
              InputProps={{ disableUnderline: true }}
              SelectProps={{
                native: true,
              }}
            >
              {frequencyTimingMenuOptions.map(option => (
                <option key={option.props.value} value={option.props.value}>
                  {option.props.value}
                </option>
              ))}
            </TextField>
          </div>
          <Divider />
          <DatePicker
            autoOk
            margin="normal"
            id="date-picker-dialog"
            label="Start Date"
            format="MM/dd/yyyy"
            value={medication.start_date}
            style={baseStyles.marginLeft}
            onChange={this.handleChangeStartDate}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
            openTo="year"
          />
          <InputLabel
            style={baseStyles.medicationName}
          >
            Prescribing Physician
          </InputLabel>
          <TextField
            placeholder="Physician Name"
            style={{ ...baseStyles.marginLeft, ...baseStyles.fontSize16 }}
            value={medication.prescribing_physician}
            onChange={this.handleChangePhysician}
            InputProps={{ disableUnderline: true }}
            className={this.props.classes.textField}
          />
          <Divider />
        </section>
        <br />
        <h4 style={baseStyles.fieldError}>{fieldError}</h4>
        <div style={baseStyles.marginTop}>
          {deleteButton}
          <Continue
            text="Save Medication"
            hoverColor={colors.highlightDarker}
            onClick={this.handleSave}
          />
          {displayedError}
        </div>
      </Layout>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const { proForms: { hypertensionAssessment }, user } = state;

  const medicationId = ownProps.router.location.query.medication_id;

  const medication = medicationId ? find(hypertensionAssessment[1].medications, { id: medicationId }) : null;

  const medications = hypertensionAssessment[1] && hypertensionAssessment[1].medications
    ? hypertensionAssessment[1].medications
    : [];

  return {
    hypertensionAssessment,
    medications,
    medication,
    user,
  };
}

HypertensionAssessment3.defaultProps = {
  medication: null,
  medicattionId: null,
};

HypertensionAssessment3.propTypes = {
  medication: PropTypes.object,
  medicationId: PropTypes.string,
};

export default connect(mapStateToProps, { updatePRO })(withStyles(baseStyles)(HypertensionAssessment3));
