import DashboardIcon from './dashboard';
import PainEntryIcon from './pain-entry';
import InformedIcon from './informed';
import SupportIcon from './support';

const icons = {
  DashboardIcon,
  PainEntryIcon,
  InformedIcon,
  SupportIcon,
};

export default icons;
