import { makeDuck, resetReducer } from 'cooldux';
import { apiFetch, makeShadowOptions } from '../lib/fetch';

const duck = makeDuck({
  updateAppointment: (clinic_id, user_id, id, token, status) => { 
    const options = makeShadowOptions({status}, token, 'PUT');
    return apiFetch(`/clinics/${clinic_id}/users/${user_id}/appointments/${id}/status_update`, options);
  }
});

export const { updateAppointment, initialStateCombined } = duck;

const reducer = resetReducer(initialStateCombined, (state = initialStateCombined, action) => {
  return duck.reducerCombined(state, action);
});

export default reducer;
