import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
} from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { connect } from 'react-redux';

import Page from './page';
import AppBar from '../components/app-bar';
import IconWrapper from '../components/icon-wrapper';
import BloomkidzLayout from '../layouts/bloomkidz';
import { Doctor } from '../lib/icons';
import { updatePRO } from '../state/pro-forms';
import { bloomkidzStylesObj } from '../lib/bloomkidz';

const styles = bloomkidzStylesObj;

const nameLabelMap = {
  strepThroat: 'Strep Throat',
  earInfections: 'Ear Infections',
  croup: 'Croup',
  febrileSeizure: 'Febrile Seizure',
  headachesMigraine: 'Headaches/Migraine',
  eczema: 'Eczema',
};

class BloomkidzClientWellnessProfile3 extends Page {
  constructor(props) {
    super(props);

    const stateFromRedux = props.bloomkidzClientWellnessProfile[this.getTrackIndex()] || {};

    this.state = {
      strepThroat: false,
      earInfections: false,
      croup: false,
      febrileSeizure: false,
      headachesMigraine: false,
      eczema: false,
      ...stateFromRedux,
    };
  }

  handleChangeConcerns = (e) => {
    this.setState({ [e.target.name]: e.target.checked });
  };

  handleContinue = () => {
    const { location, updatePRO } = this.props;

    updatePRO({
      type: 'bloomkidzClientWellnessProfile',
      position: this.getTrackIndex(),
      value: { ...this.state },
    });

    this.forwardWithQuery(location.query);
  }

  getContinueButtonText = () => {
    const { state } = this;

    const stateVals = Object.values(state);

    for (let i = 0; i < stateVals.length; i++) {
      if (stateVals[i] === true) return 'Next';
    }

    return 'Skip';
  }

  handleTextFieldChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  render() {
    const { classes, router } = this.props;
    const { state } = this;

    return (
      <BloomkidzLayout
        primaryBtnOnTouchTap={this.handleContinue}
        primaryBtnLabel={this.getContinueButtonText()}
      >
        <AppBar
          headerNode="Medical Information"
          backButtonOnClick={() => router.goBack()}
        />
        <IconWrapper>
          <Doctor />
        </IconWrapper>
        <div className={classes.pageContent}>
          <div>Is your child prone to any of the following?</div>
          <div className={classes.subtext}><i>Check all that apply</i></div>
          <FormGroup>
            {Object.entries(nameLabelMap).map(([name, label]) => (
              <>
                <FormControlLabel
                  control={(
                    <Checkbox
                      checked={state[name]}
                      onChange={this.handleChangeConcerns}
                      name={name}
                      icon={<CheckBoxOutlineBlankIcon fontSize="large" />}
                      checkedIcon={<CheckBoxIcon fontSize="large" />}
                      className={classes.checkbox}
                    />
                  )}
                  label={label}
                  classes={{
                    label: classes.checkboxLabel,
                    root: classes.formControlLabel,
                  }}
                />
              </>
            ))}
          </FormGroup>
        </div>
      </BloomkidzLayout>
    );
  }
}

BloomkidzClientWellnessProfile3.propTypes = {
  bloomkidzClientWellnessProfile: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  updatePRO: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const {
    proForms: { bloomkidzClientWellnessProfile },
    user,
  } = state;

  return { user, bloomkidzClientWellnessProfile };
};

export default connect(mapStateToProps, { updatePRO })(withStyles(styles)(BloomkidzClientWellnessProfile3));
