import React from 'react';
import PropTypes from 'prop-types';

import SimpleList from '../components/simple-list';
import Subtitle from '../components/subtitle';
import Continue from '../components/continue';
import { colors } from '../lib/styles';

const MigraineSymptomsPresentation = (props) => {
  return (
    <div>
      <Subtitle label="SCROLL TO SEE ALL OPTIONS" />
      {props.migraineSymptoms ? (
        <SimpleList
          items={props.migraineSymptoms}
          categoryField="type"
          selectedItems={props.selectedItems}
          onAddItem={props.onAddItem}
          onRemoveItem={props.onRemoveItem}
        />
      ) : null}
      <section style={{ backgroundColor: colors.white }}>
        <Continue onClick={props.onContinue} />
      </section>
    </div>
  );
}

MigraineSymptomsPresentation.propTypes = {
  migraineSymptoms: PropTypes.object,
  selectedItems: PropTypes.object.isRequired,
  onAddItem: PropTypes.func.isRequired,
  onRemoveItem: PropTypes.func.isRequired,
  onContinue: PropTypes.func.isRequired
}

export default MigraineSymptomsPresentation;
