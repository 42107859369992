import React from 'react';
import { get, omit } from 'lodash';
import { connect } from 'react-redux';
import { DatePicker } from '@material-ui/pickers';
import { Divider, TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import AppBar from '../components/app-bar';
import Page from './page';
import Layout from '../layouts/common';
import State from '../components/state';
import Continue from '../components/continue';
import { colors } from '../lib/styles';
import { logEvent } from '../lib/amplitude';

import { browseInsuranceImages, addInsuranceImage } from '../state/insurance-images';
import { getImageUrl, browseInsurance, addInsurance, editInsurance } from '../state/insurance';

const baseStyles = {
  cardImages: {
    borderRadius: '5px',
    margin: '5px',
  },
  linkDeorations: {
    textDecoration: 'none',
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  marginLeft: {
    marginLeft: 15,
  },
  flexStart: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  text: {
    color: colors.primaryColor,
  },
  datePicker: {
    marginBottom: 5,
    marginTop: 5,
    '& input': {
      color: colors.greyText,
    },
    '& .MuiInput-underline:before': {
      borderBottom: 'none',
    },
  },
  textField: {
    marginBottom: 5,
    marginTop: 5,
    '& input': {
      color: colors.greyText,
    },
    '& .MuiInput-underline:before': {
      borderBottom: 'none',
    },
  },
};

class Insurance extends Page {
  constructor(props) {
    super(props);
    const {
      primary_effective_date,
      secondary_effective_date,
      workers_comp_injury_date,
    } = props.insurance;

    const primaryDate = primary_effective_date ? new Date(primary_effective_date) : null;
    const secondaryDate = secondary_effective_date ? new Date(secondary_effective_date) : null;
    const workersDate = workers_comp_injury_date ? new Date(workers_comp_injury_date) : null;

    this.state = {
      insurance: {
        primary_insurance_name: '',
        secondary_insurance_name: '',
        primary_name_insured: '',
        secondary_name_insured: '',
        primary_address: '',
        secondary_address: '',
        primary_city: '',
        secondary_city: '',
        primary_state: '',
        secondary_state: '',
        primary_zip: '',
        secondary_zip: '',
        primary_phone: '',
        secondary_phone: '',
        primary_policy_number: '',
        secondary_policy_number: '',
        primary_policy_group_number: '',
        secondary_policy_group_number: '',
        primary_policy_claim_number: '',
        secondary_policy_claim_number: '',
        primary_policy_copay_number: '',
        secondary_policy_copay_number: '',
        workers_comp_pre_auth: '',
        ...props.insurance, // Can't be last as we need to manipulate dates a bit
        primary_effective_date: primaryDate,
        secondary_effective_date: secondaryDate,
        workers_comp_injury_date: workersDate,
      },
      frontCardUrl: '',
      backCardUrl: '',
    };

    this.handleInsuranceName = this.handleTextNewMUI.bind(this, 'insurance_name');
    this.handleNameInsured = this.handleTextNewMUI.bind(this, 'name_insured');
    this.handleAddress = this.handleTextNewMUI.bind(this, 'address');
    this.handleCity = this.handleTextNewMUI.bind(this, 'city');
    this.handleState = this.handleState.bind(this);
    this.handleZip = this.handleTextNewMUI.bind(this, 'zip');
    this.handlePhone = this.handleTextNewMUI.bind(this, 'phone');
    this.handlePolicyNumber = this.handleTextNewMUI.bind(this, 'policy_number');
    this.handlePolicyGroupNumber = this.handleTextNewMUI.bind(this, 'policy_group_number');
    this.handlePolicyClaimNumber = this.handleText.bind(this, 'policy_claim_number');
    this.handlePolicyCopayNumber = this.handleText.bind(this, 'policy_copay_number');
    this.handleEffectiveDate = this.handleDate.bind(this, 'effective_date');
    this.handleInjurydate = this.handleInjuryDate.bind(this);
    this.handleCompPreAuth = this.handleCompPreAuth.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleInsurancePictureFront = this.handleInsurancePictureFront.bind(this);
    this.handleInsurancePictureBack = this.handleInsurancePictureBack.bind(this);
  }

  componentDidMount() {
    this.props.getImageUrl()
      .catch(() => {});

    this.props.browseInsurance()
      .catch((e) => {
        if (e.status === 404) {
          return this.props.addInsurance();
        }
      })
      .then((res) => {
        this.props.browseInsuranceImages(res.id);
      });
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.insurance !== nextProps.insurance) {
      const {
        primary_effective_date,
        secondary_effective_date,
        workers_comp_injury_date,
      } = nextProps.insurance;
      const primaryDate = primary_effective_date ? new Date(primary_effective_date) : null;
      const secondaryDate = secondary_effective_date ? new Date(secondary_effective_date) : null;
      const workersDate = workers_comp_injury_date ? new Date(workers_comp_injury_date) : null;
      this.setState({
        insurance: {
          primary_insurance_name: '',
          secondary_insurance_name: '',
          primary_name_insured: '',
          secondary_name_insured: '',
          primary_address: '',
          secondary_address: '',
          primary_city: '',
          secondary_city: '',
          primary_state: '',
          secondary_state: '',
          primary_zip: '',
          secondary_zip: '',
          primary_phone: '',
          secondary_phone: '',
          primary_policy_number: '',
          secondary_policy_number: '',
          primary_policy_group_number: '',
          secondary_policy_group_number: '',
          primary_policy_claim_number: '',
          secondary_policy_claim_number: '',
          primary_policy_copay_number: '',
          secondary_policy_copay_number: '',
          workers_comp_pre_auth: '',
          ...nextProps.insurance, // Can't be last as we need to manipulate dates a bit
          primary_effective_date: primaryDate,
          secondary_effective_date: secondaryDate,
          workers_comp_injury_date: workersDate,
        },
      });
    }
  }

  handleText(field, e, text) {
    const { isSecondary } = this.props;
    const prefix = isSecondary ? 'secondary_' : 'primary_';
    this.setState({
      insurance: {
        ...this.state.insurance,
        [`${prefix}${field}`]: text,
      },
    });
  }

  handleTextNewMUI(field, e) {
    const { isSecondary } = this.props;
    const prefix = isSecondary ? 'secondary_' : 'primary_';
    this.setState({
      insurance: {
        ...this.state.insurance,
        [`${prefix}${field}`]: e.target.value,
      },
    });
  }

  handleDate(field, date) {
    const { isSecondary } = this.props;
    const prefix = isSecondary ? 'secondary_' : 'primary_';
    this.setState({
      insurance: {
        ...this.state.insurance,
        [`${prefix}${field}`]: date,
      },
    });
  }

  handleInjuryDate(e, workers_comp_injury_date) {
    this.setState({
      insurance: {
        ...this.state.insurance,
        workers_comp_injury_date,
      },
    });
  }

  handleCompPreAuth(e, workers_comp_pre_auth) {
    this.setState({
      insurance: {
        ...this.state.insurance,
        workers_comp_pre_auth,
      },
    });
  }

  handleState(e) {
    const { isSecondary } = this.props;
    const prefix = isSecondary ? 'secondary_' : 'primary_';
    this.setState({
      insurance: {
        ...this.state.insurance,
        [`${prefix}state`]: e.target.value,
      },
    });
  }

  handleInsurancePictureFront(mode) {
    this.props.router.push(`/insurancefront/${mode}?secondary`);
  }

  handleInsurancePictureBack(mode) {
    this.props.router.push(`/insuranceback/${mode}`);
  }

  handleSave() {
    const { insurance } = this.state;
    const update = omit(insurance, ['id', 'user_id']);
    this.props.editInsurance(update)
      .then(() => this.props.router.push('/personalinfo'));
    const newInsurance = omit(insurance, ['id', 'user_id']);
    this.props.addInsurance(newInsurance)
      .then(() => this.props.router.push('/personalinfo'));
  }

  handleDelete() {
    // Because primary and secondary are the same record
    // Delete is actually just an edit that clears a set.
    if (this.props.isSecondary) {
      const clearUpdate = {
        secondary_insurance_name: '',
        secondary_name_insured: '',
        secondary_address: '',
        secondary_city: '',
        secondary_state: '',
        secondary_zip: '',
        secondary_phone: '',
        secondary_policy_number: '',
        secondary_policy_group_number: '',
        secondary_policy_claim_number: '',
        secondary_policy_copay_number: '',
        workers_comp_pre_auth: '',
        secondary_effective_date: null,
        workers_comp_injury_date: null,
      };

      this.props.editInsurance(clearUpdate)
        .then(() => this.props.router.push('/personalinfo'));
    } else {
      const clearUpdate = {
        primary_insurance_name: '',
        primary_name_insured: '',
        primary_address: '',
        primary_city: '',
        primary_state: '',
        primary_zip: '',
        primary_phone: '',
        primary_policy_number: '',
        primary_policy_group_number: '',
        primary_policy_claim_number: '',
        primary_policy_copay_number: '',
        primary_effective_date: null,
      };
      this.props.editInsurance(clearUpdate)
        .then(() => this.props.router.push('/personalinfo'));
    }
  }

  render() {
    const { classes, insuranceData } = this.props;
    let deleteButton;
    let pageSelection = 'primary';
    const { insurance } = this.state;
    const { isSecondary } = this.props;
    const prefix = isSecondary ? 'secondary_' : 'primary_';
    if (prefix === 'secondary_') {
      pageSelection = 'secondary';
    }
    const frontId = pageSelection === 'primary' ? insuranceData.primaryFrontUrl : insuranceData.secondaryFrontUrl;
    const backId = pageSelection === 'primary' ? insuranceData.primaryBackUrl : insuranceData.secondaryBackUrl;
    if (insurance[`${prefix}insurance_name`]) {
      deleteButton = (
        <Continue
          text="Delete Insurance"
          btnStyle={{ backgroundColor: colors.errorRed }}
          onClick={this.handleDelete}
        />);
    }
    return (
      <Layout>
        <AppBar
          backButtonOnClick={() => this.props.router.push('/personalinfo')}
          headerNode="Insurance"
        />
        <section className="pictures-of-ins-card">
          <p style={baseStyles.text}>Tap pictures to set or retake</p>
          <div style={baseStyles.center}>
            <img
              alt="Insurance Card Front"
              onClick={() => {
                logEvent('Insurance Card Photo');
                this.handleInsurancePictureFront(pageSelection);
              }}
              src={frontId || '/img/front-card-btn.png'}
              onError={e => (e.target.setAttribute('src', '/img/front-card-btn.png'))}
              style={baseStyles.cardImages}
              width="128"
              height="75"
            />
            <img
              alt="Insurance Card Back"
              onClick={() => {
                logEvent('Insurance Card Photo');
                this.handleInsurancePictureBack(pageSelection);
              }}
              src={backId || '/img/back-card-btn.png'}
              onError={e => (e.target.setAttribute('src', '/img/back-card-btn.png'))}
              style={baseStyles.cardImages}
              width="128"
              height="75"
            />
          </div>
          <p
            style={{ fontSize: 10, color: colors.primaryColor, textAlign: 'left', marginLeft: 15 }}
          >INSURANCE DETAILS
          </p>
        </section>
        <section className="form" style={{ backgroundColor: colors.white, textAlign: 'left' }}>
          <TextField
            label="Insurance Co."
            onChange={this.handleInsuranceName}
            value={insurance[`${prefix}insurance_name`]}
            style={baseStyles.marginLeft}
            classes={{ root: classes.textField }}
          />
          <Divider />
          <TextField
            label="Name of Insured"
            onChange={this.handleNameInsured}
            value={insurance[`${prefix}name_insured`]}
            style={baseStyles.marginLeft}
            classes={{ root: classes.textField }}
          />
          <Divider />
          <TextField
            label="Phone Number"
            onChange={this.handlePhone}
            value={insurance[`${prefix}phone`]}
            style={baseStyles.marginLeft}
            classes={{ root: classes.textField }}
          />
          <Divider />
          <TextField
            label="Address"
            onChange={this.handleAddress}
            value={insurance[`${prefix}address`]}
            style={baseStyles.marginLeft}
            classes={{ root: classes.textField }}
          />
          <Divider />
          <TextField
            label="City Name"
            onChange={this.handleCity}
            value={insurance[`${prefix}city`]}
            style={baseStyles.marginLeft}
            classes={{ root: classes.textField }}
          />
          <Divider />
          <div style={baseStyles.flexStart}>
            <State
              onChange={this.handleState}
              style={baseStyles.marginLeft}
              value={insurance[`${prefix}state`]}
            />
            <TextField
              label="Postal Code"
              onChange={this.handleZip}
              value={insurance[`${prefix}zip`]}
              style={baseStyles.marginLeft}
              classes={{ root: classes.textField }}
            />
          </div>
          <Divider />
          <TextField
            label="Policy Number"
            onChange={this.handlePolicyNumber}
            value={insurance[`${prefix}policy_number`]}
            style={baseStyles.marginLeft}
            classes={{ root: classes.textField }}
          />
          <Divider />
          <div style={baseStyles.center}>
            <TextField
              label="Group Number"
              onChange={this.handlePolicyGroupNumber}
              value={insurance[`${prefix}policy_group_number`]}
              style={baseStyles.marginLeft}
              classes={{ root: classes.textField }}
            />
            <DatePicker
              autoOk={true}
              format="dd/MM/yyyy"
              label="Effective Date"
              onChange={this.handleEffectiveDate}
              openTo="year"
              value={insurance[`${prefix}effective_date`]}
              className={classes.datePicker}
            />
          </div>
        </section>
        <div style={{ marginTop: 30 }}>
          <Continue text="Save Insurance" onClick={this.handleSave} />
          {deleteButton}
        </div>
      </Layout>
    );
  }
}

function mapStateToProps(state, props) {
  const isSecondary = get(props, 'location.query.secondary', false);
  const {
    insuranceImages,
    insurance,
  } = state;

  return {
    insuranceData: insurance,
    insuranceImages,
    insurance: insurance.data,
    isSecondary,
  };
}

Insurance.defaultProps = {
  insurance: {},
  isSecondary: false,
};

export default connect(mapStateToProps, {
  browseInsurance,
  editInsurance,
  addInsurance,
  browseInsuranceImages,
  addInsuranceImage,
  getImageUrl,
})(withStyles(baseStyles)(Insurance));
