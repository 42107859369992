import React from 'react';
import { map } from 'lodash';
import { connect } from 'react-redux';
import AddIcon from '@material-ui/icons/Add';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import AppBar from '../components/app-bar';
import PharmacyListItem from '../components/pharmacy-list-item';
import MedicationListItem from '../components/medication-list-item';
import Page from './page';
import Layout from '../layouts/common';
import Continue from '../components/continue';
import Subtitle from '../components/subtitle';
import { colors } from '../lib/styles';
import { logEvent } from '../lib/amplitude';

import { browsePharmacyHx } from '../state/pharmacy-hx';
import { browseMedicationHx } from '../state/medication-hx';

const baseStyles = {
  listItem: {
    borderBottom: `1px solid ${colors.primaryHighlight}`,
    color: colors.primaryColor,
    textAlign: 'left',
  },
};

class Medications extends Page {
  constructor(props) {
    super(props);

    this.handleAddMedication = this.handleAddMedication.bind(this);
    this.handleAddPharmacy = this.handleAddPharmacy.bind(this);
  }

  componentWillMount() {
    this.props.browseMedicationHx();
    this.props.browsePharmacyHx();
  }

  handleAddMedication() {
    logEvent('Add Medication');
    this.props.router.push('/goodrx');
  }

  handleAddPharmacy() {
    this.props.router.push('/pharmacy');
  }

  render() {
    const medications = map(this.props.medicationHx.data, (m) => {
      return (
        <MedicationListItem
          item={m}
          key={m.id}
          router={this.props.router}
        />
      );
    });

    const pharmacies = map(this.props.pharmacyHx.data, (p) => {
      return (
        <PharmacyListItem
          item={p}
          key={p.id}
          router={this.props.router}
        />
      );
    });
    return (
      <Layout>
        <div>
          <AppBar
            backButtonOnClick={() => this.props.router.goBack()}
            headerNode="Medications"
          />
          <Subtitle label="My Medications" />
          <section style={{ backgroundColor: colors.white }}>
            <List>
              <ListItem
                button
                onClick={this.handleAddMedication}
                style={baseStyles.listItem}
              >
                <ListItemIcon>
                  <AddIcon />
                </ListItemIcon>
                <ListItemText
                  primary="Add a Medication"
                />
              </ListItem>
              {medications}
            </List>
          </section>
          <Subtitle label="My Pharmacies" />
          <section style={{ backgroundColor: colors.white }}>
            <List>
              <ListItem
                button
                onClick={this.handleAddPharmacy}
                style={baseStyles.listItem}
              >
                <ListItemIcon>
                  <AddIcon />
                </ListItemIcon>
                <ListItemText
                  primary="Add a Pharmacy"
                />
              </ListItem>
              {pharmacies}
            </List>
          </section>
          <section>
            <Continue
              onClick={() => {
                logEvent('Medications Updated (Finish)');
                this.forward();
              }}
              text="Update"
            />
          </section>
        </div>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  const { user, medicationHx, pharmacyHx } = state;
  return {
    medicationHx,
    pharmacyHx,
    user,
  };
}

export default connect(mapStateToProps, { browsePharmacyHx, browseMedicationHx })(Medications);
