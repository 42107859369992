import React from 'react';
import { connect } from 'react-redux';

import { apiFetch } from '../lib/fetch';
import Page from './page';
import NotificationClinicShare from '../components/notification-clinic-share';
import NotificationVideo from '../components/notification-video';
import NotificationAudio from '../components/notification-audio';

class GenericNotification extends Page {
  state = {
    loading: false,
    notification: {},
  }

  componentWillMount() {
    const { userId } = this.props;
    const { notificationId } = this.props.routeParams;

    this.setState({ loading: true });

    apiFetch(`/users/${userId}/notifications/${notificationId}`)
      .then(notification => this.setState({ notification, loading: false }))
      .catch((err) => {
        console.log('error fetching notification', err);
      });
  }

  handleDismissNotification = () => {
    const { userId } = this.props;
    const { notificationId } = this.props.routeParams;
    const dismissOpts = {
      method: 'PUT',
      body: {
        dismissed: true,
      },
    };
    apiFetch(`/users/${userId}/notifications/${notificationId}`, dismissOpts)
      .then(() => this.props.router.push('/notifications'));
  }

  render() {
    if (this.state.loading) {
      return <div />;
    }

    if (this.state.notification.notification_type === 'VIDEO_BROADCAST') {
      return (
        <NotificationVideo
          clinicName={this.state.notification.meta.clinic_name}
          clinicId={this.state.notification.meta.id}
          userId={this.props.userId}
          videoSrc={this.state.notification.meta.videoSrc}
          onDismissNotification={this.handleDismissNotification}
          router={this.props.router}
        />
      );
    }

    if (this.state.notification.notification_type === 'VOICE_BROADCAST') {
      return (
        <NotificationAudio
          clinicName={this.state.notification.meta.clinic_name}
          clinicId={this.state.notification.meta.id}
          userId={this.props.userId}
          src={this.state.notification.meta.audioSrc}
          onDismissNotification={this.handleDismissNotification}
          router={this.props.router}
        />
      );
    }
    if (this.state.notification.notification_type === 'CLINIC-ACCESS') {
      return (
        <NotificationClinicShare
          clinicName={this.state.notification.meta.clinic_name}
          clinicId={this.state.notification.meta.id}
          userId={this.props.userId}
          onDismissNotification={this.handleDismissNotification}
        />
      );
    }

    return null;
  }
}

function mapStateToProps(state) {
  const { user } = state;
  return {
    userId: user.id,
  };
}

GenericNotification.defaultProps = {};

export default connect(mapStateToProps, { })(GenericNotification);
