import React from 'react';
import { LinearProgress } from '@material-ui/core';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { get } from 'lodash';

import AppBar from '../components/app-bar';
import Page from './page';
import QuestionLayout from '../layouts/question';
import HealthSliderHorizontal from '../components/health-slider-horizontal';
import { colors } from '../lib/styles';
import { updatePRO } from '../state/pro-forms';
import { Smile, Meh, Frown } from '../lib/icons';

const styles = {
  contentWrapper: {
    flexGrow: 1,
    overflowX: 'hidden',
    position: 'relative',
  },
  healthSliderWrapper: {
    marginTop: 60,
    paddingLeft: 40,
    paddingRight: 40,
  },
  imgRow: {
    display: 'flex',
    justifyContent: 'center',
    padding: '15px 60px',
  },
  imageWrapper: {
    width: 100,
  },
  questionnaireContainer: {
    paddingLeft: 20,
    paddingRight: 20,
  },
  questionText: {
    fontSize: '2rem',
  },
};

class HypertensionAssessment13 extends Page {
  handleClickNext = () => {
    this.forwardWithQuery(this.props.location.query);
  }

  render() {
    const { classes, updatePRO } = this.props;
    const stressLevel = get(this.props.hypertensionAssessment, this.getTrackIndex(), null);

    return (
      <QuestionLayout
        primaryBtnLabel="Next"
        primaryBtnOnTouchTap={this.handleClickNext}
        style={{ backgroundColor: colors.questionBackground }}
      >
        <AppBar
          headerNode="Stress"
          backButtonOnClick={() => this.props.router.goBack()}
        />
        <LinearProgress
          variant="determinate"
          value={(this.getCurrentPage() / this.getTotalPages()) * 100}
          classes={{ bar: classes.bar }}
        />
        <div className={classes.contentWrapper}>
          <div className={classes.imgRow}>
            <div className={classes.imageWrapper}>
              {stressLevel < 5 ? <Smile /> : null}
              {stressLevel >= 5 && stressLevel < 8 ? <Meh /> : null}
              {stressLevel >= 8 ? <Frown /> : null}
            </div>
          </div>
          <div className={classes.questionnaireContainer}>
            <div className={classes.questionText}>On a scale from 1-10, how would you rate your current level of stress?</div>
            <div className={classes.healthSliderWrapper}>
              <HealthSliderHorizontal
                value={stressLevel || 0}
                onChange={updatePRO}
                max={10}
                min={0}
                trackIndex={this.getTrackIndex()}
                type="hypertensionAssessment"
                sliderLeftLabel="No stress"
                sliderRightLabel="Worst imaginable stress"
              />
            </div>
          </div>
        </div>
      </QuestionLayout>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    proForms: {
      hypertensionAssessment,
    },
  } = state;
  return { hypertensionAssessment };
};

export default connect(mapStateToProps, { updatePRO })(withStyles(styles)(HypertensionAssessment13));
