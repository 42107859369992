import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';
import { connect } from 'react-redux';

import Page from './page';
import AppBar from '../components/app-bar';
import IconWrapper from '../components/icon-wrapper';
import BloomkidzLayout from '../layouts/bloomkidz';
import { Physician } from '../lib/icons';
import { updatePRO } from '../state/pro-forms';
import { fontSizing } from '../lib/styles';
import { bloomkidzStyles, bloomkidzStylesObj } from '../lib/bloomkidz';

const styles = {
  ...bloomkidzStylesObj,
  marginTop20: {
    marginTop: 20,
  },
};

class BloomkidzMedicalHistory8 extends Page {
  constructor(props) {
    super(props);

    const stateFromRedux = props.bloomkidzMedicalHistory[this.getTrackIndex()] || {};

    this.state = {
      existingTherapies: '',
      existingTherapiesExplanation: '',
      ...stateFromRedux,
    };
  }

  handleClickButton = (existingTherapies) => {
    const explanation = {};
    if (existingTherapies === 'no') {
      explanation.existingTherapiesExplanation = '';
    }
    this.setState({ existingTherapies, ...explanation });
  }

  handleContinue = () => {
    const { location, updatePRO } = this.props;

    updatePRO({
      type: 'bloomkidzMedicalHistory',
      position: this.getTrackIndex(),
      value: { ...this.state },
    });

    this.forwardWithQuery(location.query);
  }

  handleTextFieldChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  shouldDisableContinueButton = () => {
    const {
      existingTherapies,
      existingTherapiesExplanation,
    } = this.state;

    return !existingTherapies
      || (existingTherapies === 'yes' && !existingTherapiesExplanation);
  }

  render() {
    const { classes, router } = this.props;
    const { state } = this;
    const {
      existingTherapies,
      existingTherapiesExplanation,
    } = state;

    const continueButtonDisabled = this.shouldDisableContinueButton();

    return (
      <BloomkidzLayout
        primaryBtnOnTouchTap={this.handleContinue}
        primaryBtnLabel="Next"
        primaryBtnDisabled={continueButtonDisabled}
      >
        <AppBar
          headerNode="Therapy"
          backButtonOnClick={() => router.goBack()}
        />
        <IconWrapper>
          <Physician />
        </IconWrapper>
        <div className={classes.pageContent}>
          <div>
            Does your child have past/existing therapies or school accomodations?
          </div>
          <div className={classes.buttonRow}>
            <button
              className={`${classes.button} ${existingTherapies === 'yes' ? classes.buttonSelected : classes.buttonUnselected}`}
              onClick={() => this.handleClickButton('yes')}
              type="button"
            >
              Yes
            </button>
            <button
              className={`${classes.button} ${existingTherapies === 'no' ? classes.buttonSelected : classes.buttonUnselected}`}
              onClick={() => this.handleClickButton('no')}
              type="button"
            >
              No
            </button>
          </div>
          {existingTherapies === 'yes' ? (
            <div className={classes.textFieldWrapper}>
              <div>Please explain:</div>
              <TextField
                placeholder="Tap here to type"
                value={existingTherapiesExplanation}
                onChange={this.handleTextFieldChange}
                name="existingTherapiesExplanation"
                variant="standard"
                fullWidth
                inputProps={{ style: { fontSize: fontSizing.body, fontFamily: bloomkidzStyles.fontFamily } }}
              />
            </div>
          ) : null}
        </div>
      </BloomkidzLayout>
    );
  }
}

BloomkidzMedicalHistory8.propTypes = {
  classes: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  updatePRO: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const {
    proForms: { bloomkidzMedicalHistory },
    user,
  } = state;

  return { user, bloomkidzMedicalHistory };
};

export default connect(mapStateToProps, { updatePRO })(withStyles(styles)(BloomkidzMedicalHistory8));
