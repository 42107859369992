const en = {
  demographics5: {
    title: 'About Me',
    instructions: 'Where did you sleep last night?',
    options: {
      shelter: 'In an emergency shelter, safe haven, or transitional housing project',
      institution: 'In an institution',
      institutionDetails: 'including hospital, jail, prison, juvenile detention facility, long-term care facility, or nursing home',
      uninhabitable: 'In a place not meant for human habitation',
      uninhabitableDetails: 'including in a car, unsheltered on the street or under a bridge, etc.',
      houseShare: 'In housing you shared with others, but did not own?',
      houseRent: 'In housing you rented',
      houseOwn: 'In housing you own',
      question: 'Could you continue to stay there - permanently or temporarily?',
      yesStay: 'Yes',
      noStay: 'No',
    },
  },
};

const es = {
  demographics5: {
    title: 'Sobre Mí',
    instructions: '¿Donde dormiste anoche?',
    options: {
      shelter: '¿En un refugio de emergencia, un refugio seguro o un proyecto de vivienda de transición?',
      institution: 'En una institución',
      institutionDetails: 'incluyendo hospital, cárcel, centro de detención juvenil, centro de atención a largo plazo o hogar de ancianos',
      uninhabitable: 'En un lugar no destinado a la habitación humana',
      uninhabitableDetails: 'incluso en un automóvil, sin protección en la calle o debajo de un puente, etc.',
      houseShare: '¿En la vivienda que compartió con otros, pero no era propietaria?',
      houseRent: 'Casa que alquilaste',
      houseOwn: 'En la casa eres dueña/o',
      question: '¿Podría continuar quedándose allí, de formapermanente o temporal?',
      yesStay: 'Sí',
      noStay: 'No',
    },
  },
};

export default { en, es };
