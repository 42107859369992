import React from 'react';
import PropTypes from 'prop-types';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MenuIcon from '@material-ui/icons/Menu';
import { withStyles } from '@material-ui/core/styles';
import { browserHistory } from 'react-router';

import AppBar from '../components/app-bar';
import { colors, fontSizing } from '../lib/styles';

const styles = {
  accordionContainer: {
    fontSize: fontSizing.body,
    marginTop: 20,
  },
  accordianDetails: {
    flexDirection: 'column',
  },
  accordionText: {
    fontSize: fontSizing.body,
  },
  header: {
    fontSize: fontSizing.h3,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  pageContent: {
    marginTop: 20,
    paddingLeft: 20,
    paddingRight: 20,
  },
  pageWrapper: {
    background: colors.white,
    minHeight: '100vh',
  },
  questionText: {
    fontWeight: 'bold',
  },
};

class CoreVitalFaq extends React.Component {
  render() {
    const { classes, router } = this.props;

    return (
      <div className={classes.pageWrapper}>
        <AppBar
          backButtonOnClick={() => router.goBack()}
          headerNode="User Manual"
          rightNode={(
            <IconButton onClick={() => browserHistory.push('/core-vital-user-manual-menu')}>
              <MenuIcon fontSize="large" />
            </IconButton>
          )}
        />
        <div className={classes.pageContent}>
          <div className={classes.header}>
            Informed Vital Core (IVC App) – Frequently Asked Questions
          </div>
          <div className={classes.accordionContainer}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
              >
                <div className={classes.questionText}>
                  Do I need to download an App to use the IVC App
                </div>
              </AccordionSummary>
              <AccordionDetails classes={{ root: classes.accordianDetails }}>
                <p className={classes.accordionText}>
                  No. You do not need to download any app from the Apple App Store or the Google Play Store to use the IVC App.
                </p>
                <p>
                  The IVC App can be used on a mobile phone or tablet with iOS 14.0 (or newer) or Android 10.0 (or newer). It also works on a laptop or desktop computer with a camera, running Windows 10 (or newer) or MacOS Big Sur (or newer). The camera should be able to stream video at a frame rate of 20 frames per second, which is a common feature in most phones and computers made after 2017. Note: The IVC App may not work on all devices.
                </p>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
              >
                <div className={classes.questionText}>
                  How do I use the IVC App on my phone or computer?
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <p className={classes.accordionText}>
                  When your healthcare provider needs your vital signs, they'll send you a text message with a special link. Clicking the link opens your usual web browser (like Safari, Chrome, Firefox, or Edge). Confirm your birthdate and give consent to use IVC App. A small module will install automatically in the background. The IVC App only works while the web browser is open and can access your device camera. Follow the provided instructions, click START, and it'll measure your vital signs. After submitting the results, you can close the browser, and IVC App is removed from your device memory.
                </p>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
              >
                <div className={classes.questionText}>
                  Do I need an account or password to use the IVC App
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <p className={classes.accordionText}>
                  No, you don't need an account. Your healthcare provider prescribes the IVC App through their electronic medical record system. No password is required; just confirm your birthdate matching your provider's records.
                </p>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
              >
                <div className={classes.questionText}>
                  When can I use the IVC App?
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <p className={classes.accordionText}>
                  Your healthcare provider will decide when to use the IVC App. They'll send a request through when they need vital signs.
                </p>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
              >
                <div className={classes.questionText}>
                  How do I start using the IVC App?
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <p className={classes.accordionText}>
                  Click on the link sent by your healthcare professional in your messaging app.
                </p>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
              >
                <div className={classes.questionText}>
                  How do I measure my vitals?
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <p className={classes.accordionText}>
                  Sit comfortably for five minutes, uncross your legs, and keep your feet flat on the floor. Ensure good lighting and a steady position. Click the link, follow instructions, and when started, sit still while the IVC App measures your pulse.
                </p>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
              >
                <div className={classes.questionText}>
                  How do I know if my lighting is good?
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <p className={classes.accordionText}>
                  Position your face within the red guide on the screen. Your face should have the same amount of light on it on both sides (no shadows on any part of your face). Make sure that the light is not too bright or you will have shiny, bright spots on your face. You will see a warning message if your lighting is too low or too bright. When the IVC App detects correct lighting, the guide will turn from red to green.
                </p>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
              >
                <div className={classes.questionText}>
                  What do you mean by too much movement?
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <p className={classes.accordionText}>
                  Find a comfortable location to sit down. Place your device in a stand or on a firm surface where it won't move. After you position your face within the guide on your screen and hold still. If the IVC App detects too much movement, the guide will turn from green to red.
                </p>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
              >
                <div className={classes.questionText}>
                  How do I know if my vital measurements are accurate?
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <p className={classes.accordionText}>
                  The IVC App is clinically tested and FDA-approved. Results are accurate when used correctly.
                </p>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
              >
                <div className={classes.questionText}>
                  What if my measurements seem wrong?
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <p className={classes.accordionText}>
                  Contact your healthcare provider if you believe your measurements are inaccurate and use an alternative device to measure.
                </p>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
              >
                <div className={classes.questionText}>
                  Can I use the IVC App if I’m having a medical emergency?
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <p className={classes.accordionText}>
                  No, dial 911 for emergencies. The IVC App is not for urgent situations.
                </p>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
              >
                <div className={classes.questionText}>
                  How does my healthcare provider get my vital measurements?
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <p className={classes.accordionText}>
                  After measuring, click SUBMIT RESULTS. Your healthcare provider receives encrypted results with a date and time stamp.
                </p>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
              >
                <div className={classes.questionText}>
                  What do you do with my vital signs?
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <p className={classes.accordionText}>
                  Your completed vital signs are entered into your patient record in your healthcare provider’s system with a date and time stamp.
                </p>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
              >
                <div className={classes.questionText}>
                  Do you record any video while I take my vitals?
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <p className={classes.accordionText}>
                  No. Video is needed for real-time measurements but is not recorded or stored anywhere. The camera turns off after sending results.
                </p>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
              >
                <div className={classes.questionText}>
                  Why does the IVC App keep asking for permission to use my camera?
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <p className={classes.accordionText}>
                  It's normal; give permission for the IVC App to access your camera. Settings may allow permanent camera access.
                </p>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
              >
                <div className={classes.questionText}>
                  I can’t submit my results.  What do I do?
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <p className={classes.accordionText}>
                  Ensure internet connection, use Wi-Fi or cellular data, and restart by tapping the original link if needed.
                </p>
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
      </div>
    );
  }
}

CoreVitalFaq.propTypes = {
  classes: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
};

export default withStyles(styles)(CoreVitalFaq);
