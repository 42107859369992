import React from 'react';
import { connect } from 'react-redux';

import AppBar from '../components/app-bar';
import Page from './page';
import Layout from '../layouts/common';
import Continue from '../components/continue';
import { colors } from '../lib/styles';
import { logEvent } from '../lib/amplitude';

class FormsComplete extends Page {
  componentWillMount() {
    logEvent('Forms Complete');
  }

  render() {
    return (
      <Layout>
        <div>
          <AppBar
            backButtonOnClick={() => this.props.router.goBack()}
            headerNode="Forms Complete"
          />
          <h1 style={{ color: colors.primaryColor }}>Forms Completed!</h1>
          <Continue
            text="Back to the Dashboard"
            onClick={() => this.props.router.push('/')}
          />
        </div>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state;
  return {
    user,
  };
}

export default connect(mapStateToProps, { })(FormsComplete);
