import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Fab } from '@material-ui/core';
import { colors } from '../lib/styles';

const baseStyles = {
  circle: {
    color: colors.white,
    fontSize: '3rem',
  },
  fabStyle: {
    color: colors.white,
    fontSize: '3rem',
    margin: '5px',
  },
};

export default class PinKey extends Component {
  render() {
    const { children, event } = this.props;
    return (
      <div>
        <Fab
          color="primary"
          onClick={event}
          style={baseStyles.fabStyle}
        >
          {children}
        </Fab>
      </div>
    );
  }
}

PinKey.propTypes = {
  children: PropTypes.string.isRequired,
  event: PropTypes.func.isRequired,
};
