import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get } from 'lodash';

import { colors } from '../lib/styles';
import Page from './page';
import Question from '../components/question';
import { updatePRO } from '../state/pro-forms';

const options = [
  {
    label: 'Am completely unable to move my legs at all, and have no feeling in them.',
    value: 0,
  },
  {
    label: 'Have feeling in my legs but am not able to move them at all.',
    value: 1,
  },
  {
    label: 'Am able to move my legs but am unable to walk.',
    value: 2,
  },
  {
    label: 'Am able to walk on flat floors with a walking aid (cane or crutch).',
    value: 3,
  },
  {
    label: 'Am able to walk up or down stairs with the aid of a handrail.',
    value: 4,
  },
  {
    label: 'Am able to walk up or down stairs without a  handrail but have some lack of stability or balance.',
    value: 5,
  },
  {
    label: 'Am able to walk without crutches, cain or walker, with a moderate lack of stability or balance.',
    value: 6,
  },
  {
    label: 'Am able to walk without any problems of imbalance or instability.',
    value: 7,
  }
];
const { questionBackground } = colors;

class Pmjoa2 extends Page {
  onClickBackButton = () => {
    this.backward();
  }

  onSubmission = () => {
    this.forwardWithQuery(this.props.location.query);
  }

  render() {
    const value = get(this.props.pmjoa, this.getTrackIndex(), [null, null, null, null]);
    return (
      <Question
        backgroundColor={questionBackground}
        currentPage={this.getCurrentPage()}
        onClickBackButton={() => this.props.router.goBack()}
        onChange={this.props.updatePRO}
        onSubmission={this.onSubmission}
        options={options}
        title="Motor Dysfunction"
        totalPages={this.getTotalPages()}
        trackIndex={this.getTrackIndex()}
        type="pmjoa"
        value={value}
      />
    );
  }
}


function mapStateToProps(state) {
  const { proForms: { pmjoa } } = state;
  return { pmjoa };
}

Pmjoa2.propTypes = {
  pmjoa: PropTypes.array.isRequired,
  updatePRO: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { updatePRO })(Pmjoa2);
