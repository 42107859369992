import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { IconButton, TextField } from '@material-ui/core';
import { connect } from 'react-redux';

import Page from './page';
import AppBar from '../components/app-bar';
import IconWrapper from '../components/icon-wrapper';
import BloomkidzLayout from '../layouts/bloomkidz';
import { UserGroup, XInCircle } from '../lib/icons';
import { fontSizing } from '../lib/styles';
import { updatePRO } from '../state/pro-forms';
import { bloomkidzStyles, bloomkidzStylesObj } from '../lib/bloomkidz';

const styles = {
  ...bloomkidzStylesObj,
  siblingButton: {
    marginBottom: 30,
    marginTop: 10,
  },
  siblingListText: {
    marginBottom: 10,
  },
  siblingListItem: {
    alignItems: 'center',
    background: 'white',
    display: 'flex',
    marginBottom: 10,
    marginTop: 10,
  },
  siblingListItemText: {
    flexGrow: 1,
    paddingLeft: 10,
  },
  iconButton: {
    height: 50,
    width: 50,
  },
};

class BloomkidzParentGuardianInformation5 extends Page {
  constructor(props) {
    super(props);

    const stateFromRedux = props.bloomkidzParentGuardianInformation[this.getTrackIndex()] || {};

    this.state = {
      siblings: [],
      curSibling: {
        name: '',
        age: '',
      },
      ...stateFromRedux,
    };
  }

  handleTextFieldChange = (e) => {
    const { name, value } = e.target;
    this.setState((curState) => {
      return {
        curSibling: {
          ...curState.curSibling,
          [name]: value,
        },
      };
    });
  };

  handleContinue = () => {
    const { location, updatePRO } = this.props;

    updatePRO({
      type: 'bloomkidzParentGuardianInformation',
      position: this.getTrackIndex(),
      value: { ...this.state },
    });

    this.forwardWithQuery(location.query);
  }

  addSibling = () => {
    const { siblings, curSibling } = this.state;

    if (curSibling) {
      this.setState({
        siblings: [...siblings, curSibling],
        curSibling: { name: '', age: '' },
      });
    }
  }

  removeSibling = (sibling) => {
    this.setState(curState => ({
      siblings: curState.siblings.filter(curSibling => curSibling !== sibling),
    }));
  }

  render() {
    const { classes, router } = this.props;
    const { siblings, curSibling } = this.state;

    const buttonClass = curSibling
      ? `${classes.buttonGreenBackground} ${classes.siblingButton}`
      : `${classes.buttonWithBorder} ${classes.siblingButton}`;

    return (
      <BloomkidzLayout
        primaryBtnOnTouchTap={this.handleContinue}
        primaryBtnLabel={siblings.length ? 'Next' : 'Skip'}
      >
        <AppBar
          headerNode="Family Information"
          backButtonOnClick={() => router.goBack()}
        />
        <IconWrapper>
          <UserGroup />
        </IconWrapper>
        <div className={classes.pageContent}>
          <div>Siblings</div>
          <div className={classes.paddingHorizontal}>
            <div className={classes.textFieldWrapper}>
              <TextField
                placeholder="Name"
                value={curSibling.name}
                onChange={this.handleTextFieldChange}
                name="name"
                variant="standard"
                fullWidth
                inputProps={{ style: { fontSize: fontSizing.body, fontFamily: bloomkidzStyles.fontFamily } }}
              />
            </div>
            <div className={classes.textFieldWrapper}>
              <TextField
                placeholder="Age"
                value={curSibling.age}
                onChange={this.handleTextFieldChange}
                name="age"
                variant="standard"
                fullWidth
                inputProps={{ style: { fontSize: fontSizing.body, fontFamily: bloomkidzStyles.fontFamily } }}
                type="tel"
              />
            </div>
          </div>
          <button
            className={buttonClass}
            onClick={this.addSibling}
            type="button"
          >
            + Add Sibling
          </button>
          <div className={classes.siblingListText}>
            Sibling List
          </div>
          {siblings.length ? (
            <div className={classes.paddingHorizontal}>
              {siblings.map(sibling => (
                <div className={classes.siblingListItem}>
                  <div className={classes.siblingListItemText} key={sibling.name}>{`${sibling.name} (${sibling.age})`}</div>
                  <IconButton
                    className={classes.iconButton}
                    onClick={() => this.removeSibling(sibling)}
                  >
                    <XInCircle />
                  </IconButton>
                </div>
              ))}
            </div>
          ) : (
            <div className={classes.greyText}><i>No Siblings</i></div>
          )}
        </div>
      </BloomkidzLayout>
    );
  }
}

BloomkidzParentGuardianInformation5.propTypes = {
  classes: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  updatePRO: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const {
    proForms: { bloomkidzParentGuardianInformation },
  } = state;

  return { bloomkidzParentGuardianInformation };
};

export default connect(mapStateToProps, { updatePRO })(withStyles(styles)(BloomkidzParentGuardianInformation5));
