import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Avatar } from '@material-ui/core';
import { logEvent } from '../lib/amplitude';

const baseStyles = {
  avatar: {
    cursor: 'pointer',
    height: '3.75rem',
    width: '3.75rem',
  },
};

class UserAvatar extends Component {
  componentWillMount() {
    if (!this.props.user.avatarUrl) {
      this.props.updateAvatarUrl();
    }
  }

  render() {
    const { user } = this.props;
    const avatarStyle = Object.assign({}, baseStyles.avatar, this.props.avatarStyle);
    return (
      <Avatar
        style={avatarStyle}
        src={user.picture ? user.avatarUrl : '/img/profile.png'}
        onClick={() => {
          logEvent('Dashboard - Photo');
          this.props.router.push('/picture');
        }}
      />
    );
  }
}

UserAvatar.propTypes = {
  avatarStyle: PropTypes.object,
  updateAvatarUrl: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
};

UserAvatar.defaultProps = {
  avatarStyle: {},
};

export default UserAvatar;
