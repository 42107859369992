import React from 'react';
import { connect } from 'react-redux';
import AppBar from '../components/app-bar';

import { CheckmarkInCircle } from '../lib/icons';
import Continue from '../components/continue';
import { colors } from '../lib/styles';
import Layout from '../layouts/common';
import Page from './page';

const {
  painEntryGreyBackground,
  painEntryGreyButton,
  painEntryGreyText,
} = colors;

const styles = {
  anotherPainEntryBtn: {
    background: painEntryGreyButton,
  },
  appBar: {
    height: '8.75rem',
  },
  checkmark: {
    display: 'flex',
    height: '15rem',
    justifyContent: 'center',
    margin: '10rem 0',
  },
  header: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    width: '100%',
  },
  headerTitle: {
    color: colors.black,
    display: 'inline-block',
    fontWeight: 'bold',
    fontSize: '2.625rem',
  },
  imDoneBtn: {
    marginBottom: '7.5vw',
  },
  mainContent: {
    flexGrow: 1,
  },
  rootContainer: {
    background: painEntryGreyBackground,
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100%',
  },
  thankYouMessage: {
    color: painEntryGreyText,
    fontSize: '1.8rem',
    margin: '0rem 5rem 8rem 4rem',
  },
};

class PainEntrySuccessful extends Page {
  render() {
    return (
      <Layout style={styles.rootContainer}>
        <div style={styles.mainContent}>
          <AppBar
            headerNode="Successful Pain Entry!"
            noLeftNode={true}
          />
          <div style={styles.checkmark}>
            <CheckmarkInCircle />
          </div>
          <div style={styles.thankYouMessage}>
                Thank you for logging a pain entry! Please remember to do this regularly to better understand how your pain changes over time.
          </div>
        </div>
        <div>
          <Continue
            text="return to dashboard"
            onClick={() => this.props.router.push('/')}
          />
        </div>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state;
  return {
    user,
  };
}

export default connect(mapStateToProps, { })(PainEntrySuccessful);
