/* eslint-disable no-bitwise */
import { fromHexString, toLE16 } from './bits';

export const deviceName = 'QardioARM';
export const bloodPressureServiceId = '00001810-0000-1000-8000-00805f9b34fb';
export const writeCharId = '583cb5b3-875d-40ed-9098-c39eb0c1983d';
export const readCharId = '00002a35-0000-1000-8000-00805f9b34fb';

export const resetReadingCommand = fromHexString('f102');
export const requestReadingCommand = fromHexString('f101');

export class BPError extends Error {
  constructor(message, cause) {
    super(message);
    this.name = 'BPError';
    if (cause) {
      this.cause = cause;
    }
  }

  toString() {
    let errorString = super.toString();
    if (this.cause) {
      errorString += ' : ' + this.cause.toString();
    }
    return errorString;
  }
}

export class BpData {
    systolic;

    diastolic;

    iskPa;

    rawPacket;

    constructor(systolic, diastolic, iskPa, rawPacket) {
      this.systolic = systolic;
      this.diastolic = diastolic;
      this.iskPa = iskPa;
      this.rawPacket = rawPacket;
    }
}

export class BpIntermediateData {
    bp;

    iskPa;

    rawPacket;

    constructor(bp, iskPa, rawPacket) {
      this.bp = bp;
      this.iskPa = iskPa;
      this.rawPacket = rawPacket;
    }
}

// 14 8a00 5100 6400 4800 0000
//  |  |    |    |    |     `--- additional fields LE ( status in this case )
//  |  |    |    |    |
//  |  |    |    |     `-------- additional fields LE ( pulse in this case )
//  |  |    |    |
//  |  |    |     `------------- mean LE
//  |  |    |
//  |  |     `------------------ dias LE
//  |  |
//  |   `----------------------- sys LE
//  |
//   `-------------------------- flags field

// Flags and 3 measurements sys, dias, mean always present in that order

// Flags says whichever other fields might be present, in the case above both
// status field and the pulse field will be found

// Flags field
// [7|6|5|4|3|2|1|0]
//  | | | | | | | `--- isKpa
//  | | | | | | `----- timestampPresent
//  | | | | | `------- pulsePresent
//  | | | | `--------- userIdPresent
//  | | | `----------- statusPresent
//  | | `------------- good data
//  | `--------------- ?
//  `----------------- ?

// Status field (errors)
// [7|6|5|43|2|1|0]
//  | | |  | | |  `--- body Movement
//  | | |  | |  `----- cuff too loose
//  | | |  |  `------- irregular pulse
//  | | |   `--------- two bits pulse status
//  | | |              00 pulse within range, 01 pulse exceeds upper limit, 11 pulse less than lower limit
//  | |  `------------ improper measurement position
//  |  `-------------- ?
//   `---------------- ?

// Note even if no status, diastolic and systolic could be invalid set as ff07 LE (0x07ff)
export function bpEvent(notificationVal) {
  // byte 0 is the flags denoting which extra fields are present
  const flags = notificationVal[0];
  // console.log(notificationVal.toString('hex'));
  // console.log('flags', flags);

  // measurements are always there
  const systolic = toLE16(notificationVal.slice(1, 3));
  const diastolic = toLE16(notificationVal.slice(3, 5));
  // const mean = toLE16(notificationVal.slice(5, 7))

  const iskPa = (flags & 0x01) === 0x01;
  const timestampPresent = (flags & 0x02) === 0x02;
  const pulsePresent = (flags & 0x04) === 0x04;
  const userIdPresent = (flags & 0x08) === 0x08;
  const statusPresent = (flags & 0x10) === 0x10;

  // calculate the status field index
  let statusIdx = 7;
  statusIdx += timestampPresent ? 7 : 0;
  statusIdx += pulsePresent ? 2 : 0;
  statusIdx += userIdPresent ? 1 : 0;
  const status = toLE16(notificationVal.slice(statusIdx, statusIdx + 2));

  // intermediate results exit first
  if (flags === 0x00) {
    // console.log('intermediate bp', systolic);

    return new BpIntermediateData(systolic, iskPa, notificationVal);
  }

  // good data
  if (statusPresent && (status === 0x00) && (systolic !== 0x07ff) && (diastolic !== 0x07ff)) {
    // console.log('good data', systolic, diastolic);

    return new BpData(systolic, diastolic, iskPa, notificationVal);
  }

  // error cases
  let error;
  if (statusPresent && (status & 0x01) === 0x01) {
    error = new BPError('Body movement during measurement');
    error.code = 101;
  } else if (statusPresent && (status & 0x02) === 0x02) {
    error = new BPError('Cuff too loose');
    error.code = 102;
  } else if (statusPresent && (status & 0x04) === 0x04) {
    error = new BPError('Irregular pulse');
    error.code = 103;
  } else if (statusPresent && (status & 0x08) === 0x08) {
    error = new BPError('Pulse exceeds upper limit');
    error.code = 104;
  } else if (statusPresent && (status & 0x10) === 0x10) {
    error = new BPError('Pulse less than lower limit');
    error.code = 105;
  } else if (statusPresent && (status & 0x18) === 0x18) {
    // reserved bits only happen if theres an update to the spec
    // console.log('Reserved Pulse Rate Range Detection')
    error = new BPError('Unknown reserved error code');
    error.code = 201;
  } else if (statusPresent && (status & 0x20) === 0x20) {
    error = new BPError('Improper measurement position');
    error.code = 106;
  } else if (statusPresent && (status & 0xE0)) {
    // any other status bit set reserved bits only happen if theres an update to the spec
    // console.log('Reserved Measurement Status')
    error = new BPError('Unknown reserved error code');
    error.code = 202;
  } else if (systolic === 0x07ff || diastolic === 0x07ff) {
    // anything else, I've seen this if the cuff is just sitting on the desk
    error = new BPError('Unreliable measurement');
    error.code = 100;
  }

  error.rawPacket = notificationVal;
  return error;
}
