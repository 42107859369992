import React from 'react';
import { connect } from 'react-redux';
import Page from '../pages/page';
import Layout from '../layouts/common';

class Vid extends Page {
  render() {
    return (
      <Layout>
        <video
          key={this.props.videoSrc}
          style={{width: '100%'}}
          controls
          autoPlay
          onPlay={this.props.onPlayVideo}
          onEnded={this.props.onEndVideo}
        >
            <source src={this.props.videoSrc} type="video/mp4"/>
        </video>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state;
  return {
    user,
  };
}

export default connect(mapStateToProps, { })(Vid);
