import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';

import Page from './page';
import AppBar from '../components/app-bar';
import { colors } from '../lib/styles';
import QuestionLayout from '../layouts/question';
import { updatePRO } from '../state/pro-forms';

const { questionBackground } = colors;

const styles = {
  contentWrapper: {
    flexGrow: 1,
    overflowX: 'hidden',
    position: 'relative',
  },
  instructions: {
    color: 'grey',
    fontSize: '1.5rem',
    margin: '20px 40px 0px 40px',
    textAlign: 'center',
  },
  pageIndicator: {
    fontSize: '1.4rem',
  },
  questionnaire: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '50px',
    position: 'relative',
    alignItems: 'center',
  },
  formGroup: {
    margin: '25px',
  },
  helperText: {
    color: colors.black,
    fontSize: '20px',
    textAlign: 'center',
  },
};

class Covid19_3 extends Page {
  state = {
    temperature: '',
  };

  componentDidMount() {
    let temperature = this.props.covid19[this.getTrackIndex()] || '';
    if (temperature === 'N/A') {
      temperature = '';
    }
    this.setState({ temperature });
  }

  handleSubmission = () => {
    const trackIndex = this.getTrackIndex();
    this.props.updatePRO({
      type: 'covid19',
      position: trackIndex,
      value: this.state.temperature,
    });
    this.forwardWithQuery(this.props.location.query);
  }

  handleChange = (event) => {
    this.setState({ temperature: event.target.value });
  }

  render() {
    const primaryBtnDisabled = !(this.state.temperature.match(/^\d+(\.\d{1,2})?$/) || this.state.temperature === '');
    return (
      <QuestionLayout
        primaryBtnDisabled={primaryBtnDisabled}
        primaryBtnLabel="Next"
        primaryBtnOnTouchTap={this.handleSubmission}
        style={{ backgroundColor: questionBackground }}
      >
        <AppBar
          backButtonOnClick={() => this.props.router.goBack()}
          headerNode="COVID-19 Assessment"
        />
        <div style={styles.contentWrapper}>
          <div style={styles.instructions}>
            Please enter your temperature.
          </div>
          <div style={styles.questionnaire}>
            <FormControl style={styles.formGroup}>
              <Input
                id="standard-adornment-temperature"
                value={this.state.temperature}
                onChange={this.handleChange}
                aria-describedby="standard-temperature-helper-text"
                type="tel"
                inputProps={{
                  inputMode: 'numeric',
                  pattern: '[0-9]*',
                  'aria-label': 'weight',
                  style: { fontSize: '16px', textAlign: 'center' },
                }}
                style={{ width: '100px' }}
              />
              <FormHelperText style={styles.helperText} id="standard-temperature-helper-text">temperature</FormHelperText>
            </FormControl>
          </div>
        </div>
      </QuestionLayout>
    );
  }
}

function mapStateToProps(state) {
  const { proForms: { covid19 } } = state;
  return { covid19 };
}

Covid19_3.propTypes = {
  covid19: PropTypes.array.isRequired,
  updatePRO: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { updatePRO })(withStyles(styles)(Covid19_3));
