import React, { Component } from 'react';

import { calendarPainColors } from '../lib/styles';

const {
  painLevel1,
  painLevel2,
  painLevel3,
} = calendarPainColors;

const styles = {
  painLegend: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '35px 20px 25px 20px',
  },
  painLegendItem: {
    display: 'flex',
    alignItems: 'center',
  },
  painLevel1Circle: {
    backgroundColor: painLevel1,
    borderRadius: '50%',
    height: 20,
    marginRight: 5,
    width: 20,
  },
  painLevel1Label: {
    alignItems: 'center',
    color: painLevel1,
    display: 'flex',
    fontSize: 12,
  },
  painLevel2Circle: {
    backgroundColor: painLevel2,
    borderRadius: '50%',
    height: 20,
    marginRight: 5,
    width: 20,
  },
  painLevel2Label: {
    alignItems: 'center',
    color: painLevel2,
    display: 'flex',
    fontSize: 12,
  },
  painLevel3Circle: {
    backgroundColor: painLevel3,
    borderRadius: '50%',
    height: 20,
    marginRight: 5,
    width: 20,
  },
  painLevel3Label: {
    alignItems: 'center',
    color: painLevel3,
    display: 'flex',
    fontSize: 12,
  },
};

class CalendarPainLegend extends Component {
  render() {
    return (
      <div style={styles.painLegend}>
        <div style={styles.painLegendItem}>
          <div style={styles.painLevel1Circle} />
          <div style={styles.painLevel1Label}>LIGHT</div>
        </div>
        <div style={styles.painLegendItem}>
          <div style={styles.painLevel2Circle} />
          <div style={styles.painLevel2Label}>MODERATE</div>
        </div>
        <div style={styles.painLegendItem}>
          <div style={styles.painLevel3Circle} />
          <div style={styles.painLevel3Label}>SEVERE</div>
        </div>
      </div>
    );
  }
}

export default CalendarPainLegend;
