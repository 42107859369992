const en = {
  woundCasaColina3: {
    title: 'Wound',
    instructions: 'Tap the "+" to add another wound.',
    addImageBtn: 'Add Wound',
    options: {
      'Left Leg': 'Left Leg',
      'Right Leg': 'Right Leg',
      'Left Foot': 'Left Foot',
      'Right Foot': 'Right Foot',
      'Lower Torso': 'Lower Torso',
      'Upper Torso': 'Upper Torso',
      Neck: 'Neck',
      'Left Arm': 'Left Arm',
      'Right Arm': 'Right Arm',
    },
  },
};

const es = {
  woundCasaColina3: {
    title: 'Herida',
    instructions: 'Toque el "+" para agregar otra herida.',
    addImageBtn: 'Agregar Herida',
    options: {
      'Left Leg': 'Pierna Izquierda',
      'Right Leg': 'Pierna Derecha',
      'Left Foot': 'Pie Izquierdo',
      'Right Foot': 'Pie Derecho',
      'Lower Torso': 'Torso Inferior',
      'Upper Torso': 'Torso Superior',
      Neck: 'Cuello',
      'Left Arm': 'Brazo Izquierdo',
      'Right Arm': 'Brazo Derecho',
    },
  },
};

export default { en, es };
