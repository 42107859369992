import React from 'react';
import PropTypes from 'prop-types';
import { LinearProgress, TextField } from '@material-ui/core';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import AppBar from '../components/app-bar';
import Page from './page';
import QuestionLayout from '../layouts/question';
import { colors } from '../lib/styles';
import { updatePRO } from '../state/pro-forms';
import { Heart } from '../lib/icons';

const styles = {
  contentWrapper: {
    flexGrow: 1,
    overflowX: 'hidden',
    position: 'relative',
  },
  imgRow: {
    display: 'flex',
    justifyContent: 'center',
    padding: '15px 60px',
  },
  imageWrapper: {
    width: 100,
  },
  bloodPressureInputField: {
    width: '6rem',
    '& input': {
      fontSize: '2rem',
      textAlign: 'center',
    },
    '& label': {
      fontSize: '2rem',
    },
  },
  marginRight40: {
    marginRight: 40,
  },
  marginTop10: {
    marginTop: 10,
  },
  marginTop20: {
    marginTop: 20,
  },
  notesInputField: {
    '& input': {
      fontSize: '2rem',
    },
    '& label': {
      fontSize: '2rem',
    },
  },
  questionnaireContainer: {
    paddingLeft: 20,
    paddingRight: 20,
  },
  bodyText: {
    fontSize: '2rem',
  },
  textFieldContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  textFieldWrapper: {
    alignItems: 'center',
    display: 'flex',
  },
  unitsWrapper: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    marginLeft: 5,
  },
};

class ContinuousBPDataCollectionManualBp extends Page {
  constructor(props) {
    super(props);

    this.state = {
      manualDiastolic: '',
      manualSystolic: '',
      bpDeviceManufacturer: '',
      bpDeviceModelNumber: '',
    };
  }

  handleChangeDiastolic = (e) => {
    this.setState({ manualDiastolic: e.target.value });
  };

  handleChangeSystolic = (e) => {
    this.setState({ manualSystolic: e.target.value });
  };

  handleChangeManufacturer = (e) => {
    this.setState({ bpDeviceManufacturer: e.target.value });
  };

  handleChangeModelNumber = (e) => {
    this.setState({ bpDeviceModelNumber: e.target.value });
  };

  handleClickNext = () => {
    const value = this.state;
    const secondaryValues = {};

    const {
      continuousBPDataCollection,
    } = this.props;

    const dataCollection = this.getDataMap(continuousBPDataCollection);

    // the score string checks for empty string ''
    if (value.manualSystolic === '' && value.manualDiastolic === '') {
      value.manualBloodPressure = '';
    } else {
      value.manualBloodPressure = value.manualSystolic + '/' + value.manualDiastolic;
    }

    if (dataCollection.manualSystolic) {
      secondaryValues.secondarySystolic = value.manualSystolic;
      secondaryValues.secondaryDiastolic = value.manualDiastolic;
      secondaryValues.secondaryBloodPressure = value.manualBloodPressure;
      secondaryValues.secondaryBPDeviceManufacturer = value.bpDeviceManufacturer;
      secondaryValues.secondaryBPDeviceModelNumber = value.bpDeviceModelNumber;
    }

    this.props.updatePRO({
      type: 'continuousBPDataCollection',
      position: this.getTrackIndex(),
      value: Object.keys(secondaryValues).length > 0 ? secondaryValues : value,
    });

    this.forwardWithQuery(this.props.location.query);
  }

  render() {
    const { classes } = this.props;
    const {
      manualDiastolic,
      manualSystolic,
      bpDeviceManufacturer,
      bpDeviceModelNumber,
    } = this.state;

    return (
      <QuestionLayout
        primaryBtnLabel="Next"
        primaryBtnOnTouchTap={this.handleClickNext}
        style={{ backgroundColor: colors.questionBackground }}
      >
        <AppBar
          headerNode="Measurements"
          noLeftNode={true}
        />
        <LinearProgress
          variant="determinate"
          value={(this.getCurrentPage() / this.getTotalPages()) * 100}
          classes={{ bar: classes.bar }}
        />
        <div className={classes.contentWrapper}>
          <div className={classes.imgRow}>
            <div className={classes.imageWrapper}>
              <Heart />
            </div>
          </div>
          <div className={classes.questionnaireContainer}>
            <div className={classes.bodyText}>Please enter your current blood pressure measurement:</div>
            <div className={classes.textFieldContainer}>
              <div className={`${classes.textFieldWrapper} ${classes.marginTop20}`}>
                <span className={classes.bodyText}>Systolic</span>
                <TextField
                  className={classes.bloodPressureInputField}
                  onChange={this.handleChangeSystolic}
                  value={manualSystolic}
                  inputProps={{ inputMode: 'numeric' }}
                />
                <span className={classes.unitsWrapper}>mmHG</span>
              </div>
              <div className={`${classes.textFieldWrapper} ${classes.marginTop20}`}>
                <span className={classes.bodyText}>Diastolic</span>
                <TextField
                  className={classes.bloodPressureInputField}
                  onChange={this.handleChangeDiastolic}
                  value={manualDiastolic}
                  inputProps={{ inputMode: 'numeric' }}
                />
                <span className={classes.unitsWrapper}>mmHG</span>
              </div>
            </div>
            <div className={`${classes.bodyText} ${classes.marginTop20}`}>Enter the manufacturer of your blood pressure monitor:</div>
            <TextField
              className={`${classes.notesInputField} ${classes.marginTop10}`}
              onChange={this.handleChangeManufacturer}
              value={bpDeviceManufacturer}
              placeholder="tap here to enter"
            />
            <div className={`${classes.bodyText} ${classes.marginTop20}`}>Enter the model number of your blood pressure monitor:</div>
            <TextField
              className={`${classes.notesInputField} ${classes.marginTop10}`}
              onChange={this.handleChangeModelNumber}
              value={bpDeviceModelNumber}
              placeholder="tap here to enter"
            />
          </div>
        </div>
      </QuestionLayout>
    );
  }
}

function mapStateToProps(state) {
  const { user, proForms: { continuousBPDataCollection } } = state;
  return { user, continuousBPDataCollection };
}

ContinuousBPDataCollectionManualBp.proptypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  route: PropTypes.object.isRequired,
  updatePRO: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { updatePRO })(withStyles(styles)(ContinuousBPDataCollectionManualBp));
