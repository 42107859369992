const en = {
  eqAnxietyDepression: {
    title: 'Anxiety / Depression',
    instructions: 'Please select the option that best answers the question relating to your pain.',
    healthy: 'I am not anxious or depressed',
    mild: 'I am moderately anxious or depressed',
    severe: 'I am extremely anxious or depressed',
  },
};

const es = {
  eqAnxietyDepression: {
    title: 'Ansiedad / Depresión',
    instructions: 'Seleccione la opción que mejor responda a la pregunta relacionada con su dolor.',
    healthy: 'No estoy ansiosa/o o deprimida/o',
    mild: 'Estoy moderadamente ansiosa/o o deprimida/o',
    severe: 'Estoy extremadamente ansiosa/o o deprimida/o',
  },
};

export default { en, es };
