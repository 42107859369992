import React from 'react';
import { LinearProgress } from '@material-ui/core';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsUp } from '@fortawesome/free-solid-svg-icons';

import AppBar from '../components/app-bar';
import Page from './page';
import QuestionLayout from '../layouts/question';
import FivePointRatingScale from '../components/five-point-rating-scale';
import { colors, fontSizing } from '../lib/styles';
import { updatePRO } from '../state/pro-forms';

const styles = {
  pageContent: {
    fontSize: fontSizing.body,
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 20,
  },
  iconWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 20,
  },
  instructionsText: {
    fontWeight: 'bold',
  },
};

class HumanFactorsForIvcApp1 extends Page {
  constructor(props) {
    super(props);

    this.state = {
      easyToFollow: null,
      easyToNavigate: null,
      instructionsClear: null,
      questionsEasyToUnderstand: null,
      ableToSubmit: null,
      reasonableAmountOfTime: null,
      feltComfortable: null,
    };
  }

  handleClickNext = () => {
    this.props.updatePRO({
      type: 'humanFactorsForIvcApp',
      position: this.getTrackIndex(),
      value: { ...this.state },
    });

    this.forwardWithQuery(this.props.location.query);
  };

  handleChangeScaleValue = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  render() {
    const { classes } = this.props;
    const {
      easyToFollow,
      easyToNavigate,
      instructionsClear,
      questionsEasyToUnderstand,
      ableToSubmit,
      reasonableAmountOfTime,
      feltComfortable,
    } = this.state;

    const continueDisabled = Object.values(this.state).some(value => value === null);

    return (
      <QuestionLayout
        primaryBtnLabel="Next"
        primaryBtnOnTouchTap={this.handleClickNext}
        primaryBtnDisabled={continueDisabled}
        style={{ backgroundColor: colors.questionBackground }}
      >
        <AppBar
          headerNode="Test Survey"
          backButtonOnClick={() => this.props.router.goBack()}
        />
        <LinearProgress
          variant="determinate"
          value={(this.getCurrentPage() / this.getTotalPages()) * 100}
        />
        <div className={classes.pageContent}>
          <div className={classes.iconWrapper}>
            <FontAwesomeIcon icon={faThumbsUp} size="5x" />
          </div>
          <div className={classes.instructionsText}>
            Please rate how much you agree with the following statements:
          </div>
          <FivePointRatingScale
            subject="The process was easy to follow along from start to finish."
            value={easyToFollow}
            onChange={this.handleChangeScaleValue}
            name="easyToFollow"
            showOptionDescription
          />
          <FivePointRatingScale
            subject="The app was easy to navigate."
            value={easyToNavigate}
            onChange={this.handleChangeScaleValue}
            name="easyToNavigate"
          />
          <FivePointRatingScale
            subject="The instructions and guide box clearly explained where to position my face on the screen."
            value={instructionsClear}
            onChange={this.handleChangeScaleValue}
            name="instructionsClear"
          />
          <FivePointRatingScale
            subject="The instructions were easy to understand."
            value={questionsEasyToUnderstand}
            onChange={this.handleChangeScaleValue}
            name="questionsEasyToUnderstand"
          />
          <FivePointRatingScale
            subject="I was able to record and submit my vitals without any complications."
            value={ableToSubmit}
            onChange={this.handleChangeScaleValue}
            name="ableToSubmit"
          />
          <FivePointRatingScale
            subject="I was able to measure my vitals in a reasonable amount of time."
            value={reasonableAmountOfTime}
            onChange={this.handleChangeScaleValue}
            name="reasonableAmountOfTime"
          />
          <FivePointRatingScale
            subject="I felt comfortable using the Informed Vital Core application to record my vital signs."
            value={feltComfortable}
            onChange={this.handleChangeScaleValue}
            name="feltComfortable"
          />
        </div>
      </QuestionLayout>
    );
  }
}

export default connect(null, { updatePRO })(withStyles(styles)(HumanFactorsForIvcApp1));
