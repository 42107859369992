import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import AppBar from '../components/app-bar';
import Page from './page';
import QuestionLayout from '../layouts/question';
import YesNoButtonSet from '../components/yes-no-button-set';
import { title, instructions, nextBtn, options } from '../lib/i18next';
import { colors, fontSizing } from '../lib/styles';
import { submitPRO } from '../state/pro-forms';
import { ClipboardListSolid } from '../lib/icons';

const styles = {
  contentWrapper: {
    flexGrow: 1,
    overflowX: 'hidden',
    position: 'relative',
  },
  imgRow: {
    display: 'flex',
    justifyContent: 'center',
    padding: '15px 60px',
  },
  imageWrapper: {
    width: 100,
  },
  questionnaireContainer: {
    paddingLeft: 20,
    paddingRight: 20,
  },
  questionText: {
    fontSize: fontSizing.body,
    fontWeight: 'bold',
  },
};

class EducationAssessment1 extends Page {
  constructor(props) {
    super(props);

    this.state = {
      appropriate: null,
      helpful: null,
    };
  }

  handleChangeAppropriate = (value) => {
    this.setState({ appropriate: value });
  }

  handleChangeHelpful = (value) => {
    this.setState({ helpful: value });
  }

  handleClickNext = () => {
    const { user } = this.props;
    const {
      appropriate,
      helpful,
    } = this.state;
    const { id } = this.props.location.query;

    this.props.submitPRO({ userId: user.id, id }, {
      pro_type: 'EDUCATION-ASSESSMENT',
      pro_data: {
        data: [{ appropriate, helpful }],
      },
    }, user)
      .then(() => this.forwardWithQuery(this.props.location.query));
  }

  render() {
    const { classes } = this.props;
    const {
      appropriate,
      helpful,
    } = this.state;

    const continueDisabled = appropriate === null
      || helpful === null;

    return (
      <QuestionLayout
        primaryBtnLabel={nextBtn()}
        primaryBtnOnTouchTap={this.handleClickNext}
        primaryBtnDisabled={continueDisabled}
        style={{ backgroundColor: colors.questionBackground }}
      >
        <AppBar
          headerNode={title('education1')}
          backButtonOnClick={() => this.props.router.goBack()}
        />
        <LinearProgress
          variant="determinate"
          value={(this.getCurrentPage() / this.getTotalPages()) * 100}
          classes={{ bar: classes.bar }}
        />
        <div className={classes.contentWrapper}>
          <div className={classes.imgRow}>
            <div className={`${classes.imageWrapper} ${classes.redHeart}`}>
              <ClipboardListSolid />
            </div>
          </div>
          <div className={classes.questionnaireContainer}>
            <div className={classes.questionText}>{instructions('education1')}</div>
            <YesNoButtonSet
              isYes={helpful === 'Y'}
              isNo={helpful === 'N'}
              onClickNo={() => this.handleChangeHelpful('N')}
              onClickYes={() => this.handleChangeHelpful('Y')}
              questionText={options('question1', 'education1')}
              questionTextSize={fontSizing.body}
            />
            <YesNoButtonSet
              isYes={appropriate === 'Y'}
              isNo={appropriate === 'N'}
              onClickNo={() => this.handleChangeAppropriate('N')}
              onClickYes={() => this.handleChangeAppropriate('Y')}
              questionText={options('question2', 'education1')}
              questionTextSize={fontSizing.body}
            />
          </div>
        </div>
      </QuestionLayout>
    );
  }
}

const mapStateToProps = (state) => {
  const { user } = state;

  return { user };
};


export default connect(mapStateToProps, { submitPRO })(withStyles(styles)(EducationAssessment1));
