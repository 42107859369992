/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { assign, capitalize, pickBy, reduce } from 'lodash';
import { Trans, withTranslation } from 'react-i18next';
import LinearProgress from '@material-ui/core/LinearProgress';
import Button from '@material-ui/core/Button';

import Page from './page';
import AppBar from '../components/app-bar';
import Continue from '../components/continue';
import { updatePRO } from '../state/pro-forms';

import { browsePainLocationsIfNeeded } from '../state/app-data';
import { fetchUserReport, updateAvatarUrl } from '../state/user';
import { colors, mainDashboard } from '../lib/styles';
import Layout from '../layouts/common';
import { logEvent } from '../lib/amplitude';
import PainBody from '../components/pain-body';
import BodyPainModal from '../components/body-pain-modal';


const styles = {
  bottomBtnMargin: {
    marginBottom: '7.5vw',
  },
  confirmButtonLabel: {
    fontSize: '22px',
    textTransform: 'none',
  },
  linearProgress: {
    borderRadius: 0,
  },
  title: {
    color: 'black',
    fontSize: '1.5rem',
    fontWeight: 800,
    textTransform: 'uppercase',
    alignSelf: 'center',
  },
  titleGroup: {
    margin: 25,
    textAlign: 'center',
  },
  text: {
    fontSize: '22px',
  },
  button: {
    margin: '5px',
  },
  nextBtn: {
    textAlign: 'center',
    width: '100%',
    Top: '90%',
    position: 'fixed',
  },
  painBodyBtnContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    left: '7.5%',
    justifyContent: 'center',
    position: 'fixed',
    textAlign: 'center',
    top: '85%',
  },
  painBody: {
    minWidth: '85%',
  },
  painBodyAndCirculars: {
    display: 'flex',
    marginBottom: '45px',
    marginLeft: '10px',
  },
  helpButtonLabel: {
    fontSize: '22px',
    textTransform: 'none',
  },
  message: {
    color: colors.black,
    fontSize: '1.5rem',
  },
};

function calculateDistance(click, point) {
  return ((click.x - point.x) ** 2) + ((click.y - point.y) ** 2);
}

function scrollToTop() {
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
}

class WoundPro1 extends Page {
  constructor(props) {
    super(props);

    const pain = props.pain_hx_id ? { ...props.painHx } : {};

    this.state = {
      currentBody: 'BACK',
      maxPoints: 1,
      modalOpen: false,
      pain,
      points: [],
      showConfirmButton: false,
      unconfirmedPoint: null,
    };

    this.cancelPoint = this.cancelPoint.bind(this);
    this.clearPoints = this.clearPoints.bind(this);
    this.confirmPoint = this.confirmPoint.bind(this);
    this.handleBodyClick = this.handleBodyClick.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleContinue = this.handleContinue.bind(this);
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleFootLocation = this.handleFootLocation.bind(this);
    this.updateCurrentBody = this.updateCurrentBody.bind(this);
  }

  componentWillMount() {
    const {
      browsePainLocationsIfNeeded,
    } = this.props;

    browsePainLocationsIfNeeded();
  }

  componentDidMount() {
    scrollToTop();
    const {
      wound,
      painLocations,
    } = this.props;

    const locations = wound[this.getTrackIndex()];

    if (locations) {
      const painLocationsLength = locations.length;
      const points = [];

      locations.forEach((painLocation) => {
        const painPoint = painLocations[painLocation.id];
        points.push(painPoint);
      });

      this.setState({
        maxPoints: painLocationsLength,
        moveDecision: true,
        points,
      });
    }
  }

  cancelPoint() {
    this.setState({
      unconfirmedPoint: null,
    });
  }

  clearPoints() {
    this.setState({
      points: [],
      moveDecision: false,
      maxPoints: 1,
    });
    scrollToTop();
  }

  async confirmPoint() {
    logEvent('Confirm Body Pain - Yes');
    let { unconfirmedPoint } = this.state;

    unconfirmedPoint = { ...unconfirmedPoint, confirmed: true };

    await this.setState({
      points: this.state.points.concat(unconfirmedPoint),
      showConfirmButton: false,
      unconfirmedPoint: null,
    });
    await this.handleContinue();
  }

  handleFootLocation(val) {
    let { unconfirmedPoint } = this.state;

    unconfirmedPoint = { ...unconfirmedPoint, foot_location: val };
    this.setState({
      unconfirmedPoint,
    });
  }

  handleBodyClick(x, y, body) {
    const { maxPoints, unconfirmedPoint, points } = this.state;
    const { painLocations } = this.props;
    if (unconfirmedPoint || points.length >= maxPoints) {
      return;
    }

    const validLocations = pickBy(painLocations, l => l.body_location === body);
    const click = { x, y };

    let closestPoint = reduce(validLocations, (result, value) => {
      const distance = calculateDistance(click, value);
      if (distance < result.distance) {
        return {
          distance,
          ...value,
        };
      }

      return result;
    }, { distance: 99999999 });

    closestPoint = { ...closestPoint, painStart: true };

    this.setState({
      showConfirmButton: true,
      unconfirmedPoint: closestPoint,
    });
  }

  handleCloseModal() {
    this.setState({ modalOpen: false });
  }

  handleContinue() {
    const { points } = this.state;
    const value = points.map((p) => { return p.foot_location ? { name: p.name, foot_location: p.foot_location, body_point: p.id } : { name: p.name, body_point: p.id }; });
    this.props.updatePRO({ type: 'wound', position: this.getTrackIndex(), value });
    this.clearPoints();
    this.forwardWithQuery(this.props.location.query);
  }

  handleOpenModal() {
    this.setState({ modalOpen: true });
  }

  updateCurrentBody(currentBody) {
    this.setState({ currentBody });
  }

  render() {
    const { t } = this.props;
    let clearButton = null;
    let confirmButton = null;
    let continueButton = null;
    const {
      currentBody,
      modalOpen,
      points,
      showConfirmButton,
      unconfirmedPoint,
    } = this.state;
    let visiblePoints = points;
    let bottomBtnStyle = styles.painBodyBtnContainer;
    let bottomMargin = 0;
    let message = (
      <p
        dangerouslySetInnerHTML={{
          __html: t('tapOnBodyImageToSelectLocation', { interpolation: { escapeValues: false } }),
        }}
      />
    );

    if (unconfirmedPoint) {
      if (unconfirmedPoint.name.includes('Foot') && unconfirmedPoint.foot_location === undefined) {
        bottomBtnStyle = assign({}, bottomBtnStyle, { top: '85%' });
        bottomMargin = 9;
        message = <p>{t('isTopOrBottomOfFoot')}</p>;
        visiblePoints = points.concat(unconfirmedPoint);
        clearButton = <Continue btnStyle={styles.continueButton} onClick={() => this.handleFootLocation('Bottom')} text={t('bottom')} />;
        continueButton = <Continue btnStyle={styles.continueButton} onClick={() => this.handleFootLocation('Top')} text={t('top')} />;
      }
      if (!unconfirmedPoint.name.includes('Foot') || unconfirmedPoint.foot_location !== undefined) {
        bottomBtnStyle = assign({}, bottomBtnStyle, { top: '90%' });
        bottomMargin = 4.5;
        message = (
          <p>
            <Trans
              i18nKey="tapToConfirmOrErase"
              ns="woundPro1"
            />
          </p>
        );
        visiblePoints = points.concat(unconfirmedPoint);
        confirmButton = <Continue btnStyle={styles.continueButton} onClick={this.confirmPoint} text={t('confirm')} />;
      }
    }

    return (
      <div onLoad={() => this.clearPoints()}>
        <Layout>
          <section style={mainDashboard.container}>
            <AppBar
              backButtonOnClick={() => this.props.router.goBack()}
              headerNode={t(`View ${capitalize(currentBody)}`)}
              rightNode={
                showConfirmButton
                  ? (
                    <Button
                      onClick={this.confirmPoint}
                      style={styles.helpButtonLabel}
                    >
                      {t('confirm')}
                    </Button>
                  ) : (
                    <Button
                      onClick={this.handleOpenModal}
                      style={styles.helpButtonLabel}
                    >
                      {t('help')}
                    </Button>
                  )
              }
            />
            <LinearProgress
              variant="determinate"
              value={(this.getCurrentPage() / this.getTotalPages()) * 100}
            />
            <div style={styles.painBody}>
              <div style={styles.message}>{message}</div>
              <div style={{ marginBottom: `${bottomMargin}rem` }}>
                <PainBody
                  onBodyClick={this.handleBodyClick}
                  onClickCancel={this.cancelPoint}
                  selectedLocations={visiblePoints}
                  updateCurrentBody={this.updateCurrentBody}
                />
              </div>
              <div style={bottomBtnStyle}>
                {confirmButton}
                {continueButton}
                {clearButton}
              </div>
            </div>
          </section>
        </Layout>
        <BodyPainModal
          modalOpen={modalOpen}
          onCloseModal={this.handleCloseModal}
        />
      </div>
    );
  }
}


function mapStateToProps(state) {
  const {
    proForms: { wound },
    appData: { painLocations },
  } = state;

  return {
    wound,
    painLocations,
  };
}

WoundPro1.proptypes = {
  route: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, {
  updatePRO,
  browsePainLocationsIfNeeded,
  fetchUserReport,
  updateAvatarUrl,
})(withTranslation('woundPro1')(WoundPro1));
