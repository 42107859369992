import React from 'react';
import { get, debounce } from 'lodash';
import { connect } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

import AppBar from '../components/app-bar';
import Page from './page';
import Layout from '../layouts/common';
import { colors } from '../lib/styles';

import { apiFetch } from '../lib/fetch';

const baseStyles = {
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
    minHeight: '600px',
    margin: '5px',
    width: '100%',
  },
  searchText: {
    color: colors.lightBrightBlue,
    fontSize: '14px',
    paddingTop: '10px',
  },
};

class GoodRx extends Page {
  constructor(props) {
    super(props);

    this.state = {
      autocompleteInput: '',
      data: [],
    };

    this.handleUpdateInput = debounce(this.handleUpdateInput.bind(this), 250, { trailing: true });
    this.handleSelectItem = this.handleSelectItem.bind(this);
  }

  handleUpdateInput(name) {
    if (!name) {
      return this.setState({
        data: [],
      });
    }

    apiFetch('/goodrx', { query: { name } })
      .then((response) => {
        this.setState({
          data: get(response, 'data.candidates', []),
        });
      });
  }

  handleSelectItem(item) {
    this.props.router.push(`/medication?name=${item}`);
  }

  render() {
    return (
      <Layout>
        <AppBar
          backButtonOnClick={() => this.props.router.goBack()}
          headerNode="Medication Search"
        />
        <div style={baseStyles.mainContainer}>
          <Autocomplete
            onChange={(event, newValue) => {
              this.handleSelectItem(newValue);
            }}
            inputValue={this.state.autocompleteInput}
            onInputChange={(event, newInputValue) => {
              this.handleUpdateInput(newInputValue);
              this.setState({ autocompleteInput: newInputValue });
            }}
            options={this.state.data}
            style={{ width: 300 }}
            renderInput={params => <TextField {...params} label="Search" />}
          />
          <section>
            <div>
              <img alt="Search Icon" height="64px" width="64px" src="/img/search-icon.png" />
              <div style={baseStyles.searchText}>Search by medication name</div>
            </div>
          </section>
          <div>
            <img
              alt="GoodRx Logo"
              height="75px"
              src="/img/goodrx.png"
              width="180px"
            />
          </div>
        </div>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state;

  return {
    user,
  };
}

GoodRx.defaultProps = {};

export default connect(mapStateToProps, {})(GoodRx);
