import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { TextareaAutosize, TextField } from '@material-ui/core';
import { connect } from 'react-redux';

import Page from './page';
import AppBar from '../components/app-bar';
import IconWrapper from '../components/icon-wrapper';
import BloomkidzLayout from '../layouts/bloomkidz';
import { SchoolBuilding } from '../lib/icons';
import { updatePRO } from '../state/pro-forms';
import { bloomkidzStylesObj, bloomkidzStyles } from '../lib/bloomkidz';
import { fontSizing } from '../lib/styles';

class BloomkidzMedicalHistory7 extends Page {
  constructor(props) {
    super(props);

    this.state = {
      currentSchool: '',
      grade: '',
      schoolAddress: '',
      typePlacement: '',
      strengthsConcerns: '',
      supports: '',
      schoolTransfer: '',
    };
  }

  handleContinue = () => {
    const { location, updatePRO } = this.props;

    updatePRO({
      type: 'bloomkidzMedicalHistory',
      position: this.getTrackIndex(),
      value: { ...this.state },
    });

    this.forwardWithQuery(location.query);
  }

  handleTextFieldChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleClickButton = (schoolTransfer) => {
    this.setState({ schoolTransfer });
  }

  shouldDisableContinueButton = () => {
    const {
      currentSchool,
      grade,
      schoolAddress,
      typePlacement,
      strengthsConcerns,
      supports,
      schoolTransfer,
    } = this.state;

    return !currentSchool
      || !grade
      || !schoolAddress
      || !typePlacement
      || !strengthsConcerns
      || !supports
      || !schoolTransfer;
  }

  render() {
    const { classes, router } = this.props;
    const {
      currentSchool,
      grade,
      schoolAddress,
      typePlacement,
      strengthsConcerns,
      supports,
      schoolTransfer,
    } = this.state;

    const continueButtonDisabled = this.shouldDisableContinueButton();

    return (
      <BloomkidzLayout
        primaryBtnOnTouchTap={this.handleContinue}
        primaryBtnLabel="Next"
        primaryBtnDisabled={continueButtonDisabled}
      >
        <AppBar
          headerNode="Child&apos;s Educational History"
          backButtonOnClick={() => router.goBack()}
        />
        <IconWrapper>
          <SchoolBuilding />
        </IconWrapper>
        <div className={classes.pageContent}>
          <div>Educational History</div>
          <div className={classes.paddingHorizontal}>
            <div className={classes.textFieldWrapper}>
              <TextField
                placeholder="Current School"
                value={currentSchool}
                onChange={this.handleTextFieldChange}
                name="currentSchool"
                variant="standard"
                fullWidth
                inputProps={{ style: { fontSize: fontSizing.body, fontFamily: bloomkidzStyles.fontFamily } }}
              />
            </div>
            <div className={classes.textFieldWrapper}>
              <TextField
                placeholder="Grade"
                value={grade}
                onChange={this.handleTextFieldChange}
                name="grade"
                variant="standard"
                fullWidth
                inputProps={{ style: { fontSize: fontSizing.body, fontFamily: bloomkidzStyles.fontFamily } }}
              />
            </div>
            <div className={classes.textFieldWrapper}>
              <TextField
                placeholder="School Address"
                value={schoolAddress}
                onChange={this.handleTextFieldChange}
                name="schoolAddress"
                variant="standard"
                fullWidth
                inputProps={{ style: { fontSize: fontSizing.body, fontFamily: bloomkidzStyles.fontFamily } }}
              />
            </div>
            <div className={classes.textFieldWrapper}>
              <TextField
                placeholder="Type of Placement"
                value={typePlacement}
                onChange={this.handleTextFieldChange}
                name="typePlacement"
                variant="standard"
                fullWidth
                inputProps={{ style: { fontSize: fontSizing.body, fontFamily: bloomkidzStyles.fontFamily } }}
              />
            </div>
          </div>
          <div>What strengths and/or concerns does your child&apos;s teacher report?</div>
          <TextareaAutosize
            value={strengthsConcerns}
            name="strengthsConcerns"
            onChange={this.handleTextFieldChange}
            minRows={10}
            className={classes.textAreaAutosize}
            placeholder="Tap here to type"
          />
          <div>What supports are currently in place for your child?</div>
          <TextareaAutosize
            value={supports}
            name="supports"
            onChange={this.handleTextFieldChange}
            minRows={10}
            className={classes.textAreaAutosize}
            placeholder="Tap here to type"
          />
          <div>
            Has your child transfered schools for reasons other than regular academic progression?
          </div>
          <div className={classes.buttonRow}>
            <button
              className={`${classes.button} ${schoolTransfer === 'yes' ? classes.buttonSelected : classes.buttonUnselected}`}
              onClick={() => this.handleClickButton('yes')}
              type="button"
            >
              Yes
            </button>
            <button
              className={`${classes.button} ${schoolTransfer === 'no' ? classes.buttonSelected : classes.buttonUnselected}`}
              onClick={() => this.handleClickButton('no')}
              type="button"
            >
              No
            </button>
          </div>
        </div>
      </BloomkidzLayout>
    );
  }
}

BloomkidzMedicalHistory7.propTypes = {
  classes: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  updatePRO: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const {
    proForms: { bloomkidzMedicalHistory },
    user,
  } = state;

  return { user, bloomkidzMedicalHistory };
};

export default connect(mapStateToProps, { updatePRO })(withStyles(bloomkidzStylesObj)(BloomkidzMedicalHistory7));
