import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import AppBar from '../components/app-bar';
import Page from './page';
import QuestionLayout from '../layouts/question';
import YesNoButtonSet from '../components/yes-no-button-set';
import i18nTranslator, { title, nextBtn } from '../lib/i18next';
import { colors } from '../lib/styles';
import { updatePRO } from '../state/pro-forms';

const styles = {
  contentWrapper: {
    flexGrow: 1,
    overflowX: 'hidden',
    position: 'relative',
  },
  questionnaireContainer: {
    paddingLeft: 20,
    paddingRight: 20,
  },
};

class PatientDemographics7 extends Page {
  constructor(props) {
    super(props);

    this.state = {
      currentPatient: null,
      footParticipant: null,
    };
  }

  handleChangeCurrentPatient = (value) => {
    this.setState({ currentPatient: value });
  };

  handleChangeFootParticipant = (value) => {
    this.setState({ footParticipant: value });
  };

  handleClickNext = () => {
    const {
      currentPatient,
      footParticipant,
    } = this.state;

    this.props.updatePRO({
      type: 'patientDemographics',
      position: this.getTrackIndex(),
      value: {
        currentPatient,
        footParticipant,
      },
    });

    this.forwardWithQuery(this.props.location.query);
  }

  render() {
    const { classes } = this.props;
    const {
      currentPatient,
      footParticipant,
    } = this.state;

    const continueDisabled = currentPatient === null
      || footParticipant === null;

    return (
      <QuestionLayout
        primaryBtnLabel={nextBtn()}
        primaryBtnOnTouchTap={this.handleClickNext}
        primaryBtnDisabled={continueDisabled}
        style={{ backgroundColor: colors.questionBackground }}
      >
        <AppBar
          headerNode={title('demographics7')}
          backButtonOnClick={() => this.props.router.goBack()}
        />
        <LinearProgress
          variant="determinate"
          value={(this.getCurrentPage() / this.getTotalPages()) * 100}
          classes={{ bar: classes.bar }}
        />
        <div className={classes.contentWrapper}>
          <div className={classes.questionnaireContainer}>
            <YesNoButtonSet
              isYes={footParticipant === 'Y'}
              isNo={footParticipant === 'N'}
              onClickNo={() => this.handleChangeFootParticipant('N')}
              onClickYes={() => this.handleChangeFootParticipant('Y')}
              questionText={i18nTranslator('ffhs', 'demographics7')}
              questionTextSize={29}
            />
            <YesNoButtonSet
              isYes={currentPatient === 'Y'}
              isNo={currentPatient === 'N'}
              onClickNo={() => this.handleChangeCurrentPatient('N')}
              onClickYes={() => this.handleChangeCurrentPatient('Y')}
              questionText={i18nTranslator('ccwc', 'demographics7')}
              questionTextSize={29}
            />
          </div>
        </div>
      </QuestionLayout>
    );
  }
}

export default connect(null, { updatePRO })(withStyles(styles)(PatientDemographics7));
