import React from 'react';
import { connect } from 'react-redux';
import { Button } from '@material-ui/core';
import { Link } from 'react-router';

import AppBar from '../components/app-bar';
import Page from './page';
import Layout from '../layouts/common';
import { logout } from '../state/user';
import UserRow from '../components/user-row';
import { colors } from '../lib/styles';
import { logEvent } from '../lib/amplitude';

const baseStyles = {
  appBarContainer: {
    color: colors.primaryColor,
    fontSize: '1.6rem',
  },
  signOutBtn: {
    backgroundColor: colors.secondaryColor,
    color: colors.white,
    width: '90%',
    borderRadius: 20,
    marginBottom: 25,
    marginTop: 40,
  },
  background: {
    backgroundColor: colors.white,
    marginTop: 20,
    display: 'flex',
    justifyContent: 'flex-start',
    padding: 15,
    fontSize: '1.6rem',
  },
  linkDecoration: {
    textDecoration: 'none',
    color: colors.primaryColor,
  },
};

class Settings extends Page {
  render() {
    return (
      <Layout>
        <div style={{ height: '100vh' }}>
          <div style={baseStyles.appBarContainer}>
            <AppBar
              backButtonOnClick={() => this.props.router.push('/')}
              headerNode="Account Settings"
            />
            <section>
              <div style={{ backgroundColor: colors.white, display: 'flex', marginLeft: '2rem' }} className="avatar-card">
                <UserRow {...this.props} />
              </div>
              <Link to="/resetpin" style={baseStyles.linkDecoration}>
                <div style={baseStyles.background}>Reset Pin</div>
              </Link>
              <Link to="/personalinfo" style={baseStyles.linkDecoration}>
                <div style={baseStyles.background}>Personal Info</div>
              </Link>
              <Link to="/about/eula" style={baseStyles.linkDecoration}>
                <div style={baseStyles.background}>End User License Agreement</div>
              </Link>
              <Link to="/about/privacy" style={baseStyles.linkDecoration}>
                <div style={baseStyles.background}>Privacy Policy</div>
              </Link>
              <Link to="/about/about" style={baseStyles.linkDecoration}>
                <div style={baseStyles.background}>About Informed</div>
              </Link>
            </section>
          </div>
          <section>
            <Button
              onClick={() => {
                logEvent('Sign Out (Settings)');
                this.props.logout();
              }}
              style={baseStyles.signOutBtn}
              variant="contained"
            >
              Sign Out
            </Button>
          </section>
        </div>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state;
  return {
    user,
  };
}

export default connect(mapStateToProps, { logout })(Settings);
