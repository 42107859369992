import React from 'react';
import { connect } from 'react-redux';
import { get, pickBy } from 'lodash';

import Page from './page';
import Layout from '../layouts/common';
import AppBar from '../components/app-bar';

import MigraineAbortivePresentation from '../components/migraine-abortive-presentation';
import { browseMigraineRx } from '../state/app-data';
import { readMigraineHx, editMigraineHx } from '../state/migraine-hx';
import {
  browseMigraineAbortiveHx,
  addMigraineAbortiveHx,
  deleteMigraineAbortiveHx,
} from '../state/migraine-abortive-hx';
import { updateAvatarUrl } from '../state/user';

class MigraineAbortive extends Page {
  constructor(props) {
    super(props);

    const { migraine_hx_id, migraineAbortiveHx } = props;  
    const selectedMigraineAbortive = migraine_hx_id ? { ...migraineAbortiveHx } : {};

    this.state = {
      other_abortive_rx: '',
      selectedMigraineAbortive,
    };

    this.handleAddItem = this.handleAddItem.bind(this);
    this.handleRemoveItem = this.handleRemoveItem.bind(this);
    this.handleContinueWork = this.handleContinue.bind(this, true);
    this.handleContinueNotWork = this.handleContinue.bind(this, false);
    this.handleOtherAbortiveUpdate = this.handleTextFieldUpdate.bind(this, 'other_abortive_rx');
    this.handleBack = this.handleBack.bind(this);
  }

  componentWillMount() {
    const { migraine_hx_id } = this.props.location.query;
    this.props.browseMigraineAbortiveHx(migraine_hx_id);
    if (!this.props.migraineRx) {
      this.props.browseMigraineRx();
    }

    if (!this.props.migraineHx) {
      this.props.readMigraineHx(migraine_hx_id);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.migraineHx !== this.props.migraineHx) {
      this.setState({
        other_abortive_rx: get(nextProps, 'migraineHx.other_abortive_rx', ''),
        selectedItems: get(nextProps, 'migraineHx.data', ''),
      });
    }
  }

  handleTextFieldUpdate(field, e) {
    this.setState({
      [field]: e.target.value,
    });
  }

  handleAddItem(rx_id) {
    const { migraine_hx_id } = this.props;
    const newItem = {
      migraine_hx_id,
      rx_id,
    };
    this.props.addMigraineAbortiveHx(newItem);
  }

  handleRemoveItem(migraineSymptomId) {
    const { migraine_hx_id } = this.props;
    this.props.deleteMigraineAbortiveHx(migraine_hx_id, migraineSymptomId);
  }

  handleContinue(rx_successful) {
    const { other_abortive_rx } = this.state;
    const { migraine_hx_id } = this.props.location.query;

    const update = {
      id: migraine_hx_id,
      rx_successful,
      other_abortive_rx,
    };

    this.props.editMigraineHx(update)
      .then(() => this.forwardWithQuery(this.props.location.query));
  }

  handleBack() {
    this.backwardWithQuery(this.props.location.query);
  }

  onClickBackButton = () => {
    this.backward();
  }

  render() {
    const { migraineRx } = this.props;
    return (
      <Layout>
        <AppBar
          backButtonOnClick={() => this.props.router.goBack()}
          headerNode="Migraine Abortive Medications"
        />
        <MigraineAbortivePresentation
          migraineRx={migraineRx}
          selectedItems={this.props.migraineAbortiveHx}
          onAddItem={this.handleAddItem}
          onRemoveItem={this.handleRemoveItem}
          onChangeOtherAbortive={this.handleOtherAbortiveUpdate}
          otherAbortiveValue={this.state.other_abortive_rx}
          onMedicationsWorked={this.handleContinueWork}
          onMedicationsNotWork={this.handleContinueNotWork}
        />
      </Layout>
    );
  }
}

function mapStateToProps(state, props) {
  const {
    migraineAbortiveHx,
    migraineHx,
    appData: {
      migraineRx,
    },
    user,
  } = state;

  const migraine_hx_id = Number(get(props, 'location.query.migraine_hx_id'));

  return {
    migraineRx: migraineRx ? pickBy(migraineRx, { type: 'ABORTIVE' }) : null,
    migraineAbortiveHx: pickBy(migraineAbortiveHx.data, (i, a) => {
      return i.migraine_hx_id === migraine_hx_id
    }),
    migraineHx: migraineHx.data[migraine_hx_id],
    migraine_hx_id,
    user,
  };
}

MigraineAbortive.defaultProps = {
  aggravating_factors: {},
  painHxMigraineAbortive: {},
};

export default connect(mapStateToProps, {
  browseMigraineRx,
  browseMigraineAbortiveHx,
  addMigraineAbortiveHx,
  deleteMigraineAbortiveHx,
  editMigraineHx,
  readMigraineHx,
  updateAvatarUrl,
})(MigraineAbortive);
