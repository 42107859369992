import { promiseHandler, resetReducer } from 'cooldux';
import { get, isUndefined, omit, omitBy } from 'lodash';

import { apiFetch } from '../lib/fetch';

const handlerOptions = { namespace: 'migrainePainHx', throwErrors: true };
const { browseStart, browseEnd, browseError, browseHandler } = promiseHandler('browse', 'migrainePainHx');
const { readStart, readEnd, readError, readHandler } = promiseHandler('read', 'migrainePainHx');
const { editStart, editEnd, editError, editHandler } = promiseHandler('edit', handlerOptions);
const { addStart, addEnd, addError, addHandler } = promiseHandler('add', handlerOptions);
const { deleteStart, deleteEnd, deleteError, deleteHandler } = promiseHandler('delete', 'migrainePainHx');

export function browseMigrainePainHx(migraineId) {
  return function dispatcher(dispatch, getState) {
    const selfId = get(getState(), 'user.id', null);
    const promise = apiFetch(`/users/${selfId}/migraine_hx/${migraineId}/migraine_pain_hx`);
    return browseHandler(promise, dispatch);
  };
}

export function readMigrainePainHx(migraineId, id) {
  return function dispatcher(dispatch, getState) {
    const filter = { query: { id } };
    const selfId = get(getState(), 'user.id', null);
    const promise = apiFetch(`/users/${selfId}/migraine_hx/${migraineId}/migraine_pain_hx`, filter)
      .then(([res]) => res);
    return readHandler(promise, dispatch);
  };
}

export function editMigrainePainHx(update) {
  return function dispatcher(dispatch, getState) {
    const selfId = get(getState(), 'user.id', null);
    const body = omit(update, ['id', 'migraine_hx_id']);
    const options = {
      method: 'PUT',
      body: omitBy(body, isUndefined),
    };
    const promise = apiFetch(
      `/users/${selfId}/migraine_hx/${update.migraine_hx_id}/migraine_pain_hx/${update.id}`, options,
    );
    return editHandler(promise, dispatch);
  };
}

export function addMigrainePainHx(newMigrainePainHx) {
  return function dispatcher(dispatch, getState) {
    const selfId = get(getState(), 'user.id', null);
    const body = omit(newMigrainePainHx, 'migraine_hx_id');
    const options = {
      method: 'POST',
      body: omitBy(body, isUndefined),
    };
    const promise = apiFetch(
      `/users/${selfId}/migraine_hx/${newMigrainePainHx.migraine_hx_id}/migraine_pain_hx`, options,
    );
    return addHandler(promise, dispatch);
  };
}

export function deleteMigrainePainHx(migraineId, migrainePainHxId) {
  return function dispatcher(dispatch, getState) {
    const selfId = get(getState(), 'user.id', null);
    const options = {
      method: 'DELETE',
    };
    const promise = apiFetch(
      `/users/${selfId}/migraine_hx/${migraineId}/migraine_pain_hx/${migrainePainHxId}`, options,
    )
      .then(() => migrainePainHxId);

    return deleteHandler(promise, dispatch);
  };
}

const initialState = {
  browseError: null,
  readError: null,
  addError: null,
  editError: null,
  deleteError: null,
  browsePending: false,
  readPending: false,
  addPending: false,
  editPending: false,
  deletePending: false,
  data: {},
  readMostRecentMigraineHx: [],
};

function finishBrowse(state, migrainePainHx) {
  const data = {};
  migrainePainHx.forEach((u) => {
    data[u.id] = u;
  });
  return { ...state, data, browsePending: false, browseError: null };
}

function finishRead(state, item) {
  const data = { ...state.data, [item.id]: item };
  return { ...state, readPending: false, readError: null, data };
}

function finishEdit(state, item) {
  const data = { ...state.data, [item.id]: item };
  return { ...state, editPending: false, editError: null, data };
}

function finishAdd(state, item) {
  const data = { ...state.data, [item.id]: item };
  return { ...state, addPending: false, addError: null, data };
}

function finishDelete(state, migrainePainHxId) {
  return { ...state, deletePending: false, deleteError: null, data: omit(state.data, migrainePainHxId) };
}

const migrainePainHx = resetReducer(initialState, (state = initialState, action) => {
  switch (action.type) {
    case browseStart.type:
      return { ...state, browsePending: true };
    case browseEnd.type:
      return finishBrowse(state, action.payload);
    case browseError.type:
      return { ...state, browsePending: false, browseError: action.payload };
    case readStart.type:
      return { ...state, readPending: true };
    case readEnd.type:
      return finishRead(state, action.payload);
    case readError.type:
      return { ...state, readPending: false, readError: action.payload };
    case addStart.type:
      return { ...state, addPending: true };
    case addEnd.type:
      return finishAdd(state, action.payload);
    case addError.type:
      return { ...state, addPending: false, addError: action.payload };
    case deleteStart.type:
      return { ...state, deletePending: true };
    case deleteEnd.type:
      return finishDelete(state, action.payload);
    case deleteError.type:
      return { ...state, deletePending: false, deleteError: action.payload };
    case editStart.type:
      return { ...state, editPending: true };
    case editEnd.type:
      return finishEdit(state, action.payload);
    case editError.type:
      return { ...state, editPending: false, editError: action.payload };
    default:
      return state;
  }
});

export default migrainePainHx;
