import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Button, LinearProgress } from '@material-ui/core';
import Page from './page';
import { colors } from '../lib/styles';
import AppBar from '../components/app-bar';
import Continue from '../components/continue';
import { submitPRO, updatePRO } from '../state/pro-forms';

const styles = {
  instructions: {
    fontSize: '1.3rem',
  },
  image: {
    height: 120,
    padding: '15px 60px',
    textAlign: 'center',
  },
  linearProgress: {
    borderRadius: 0,
  },
  pageIndicator: {
    color: 'grey',
    display: 'inline-block',
    fontSize: 13,
  },
  questionnaire: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    position: 'relative',
    minHeight: '350px',
  },
  title: {
    color: 'black',
    fontSize: '1.5rem',
    fontWeight: 800,
    textTransform: 'uppercase',
    alignSelf: 'center',
  },
  date: {
    width: '150px',
    alignSelf: 'center',
    margin: '20px',
  },
  titleGroup: {
    margin: 25,
    textAlign: 'center',
  },
  error: {
    color: colors.errorRed,
    alignSelf: 'center',
  },
  text: {
    fontSize: '1.25rem',
  },
  button: {
    margin: '5px',
  },
  nextBtn: {
    textAlign: 'center',
    width: '100%',
    top: '85%',
    position: 'fixed',
  },
};

class BodyPainPro1 extends Page {
  state = {
    currentlyInPain: '',
    painType: '',
  };

  handleHide = () => {
    this.setState({
      currentlyInPain: false,
    });
  }

  handleShow = () => {
    this.setState({
      currentlyInPain: true,
    });
  }

  handleNewPain = () => {
    this.setState({
      painType: 'newPain',
    });
  };

  handleChronicPain = () => {
    this.setState({
      painType: 'chronicPain',
    });
  };


  render() {
    const { id } = this.props.location.query;
    const { user } = this.props;
    const { currentlyInPain, painType } = this.state;

    const handleContinue = async () => {
      if (currentlyInPain) {
        this.props.updatePRO({ type: 'bodyPain', position: this.getTrackIndex(), value: painType });
        this.forwardWithQuery(this.props.location.query);
      } else {
        this.props.submitPRO(
          { userId: user.id, id },
          {
            pro_type: 'BODY-PAIN',
            pro_data: { data: [false] },
          },
          user,
        );
        this.finishTrack();
      }
    };


    return (
      <Fragment>
        <AppBar
          headerNode="Body Pain"
          noLeftNode={true}
        />
        <LinearProgress
          variant="determinate"
          value={(this.getCurrentPage() / this.getTotalPages()) * 100}
        />
        <div style={styles.titleGroup}>
          <p style={styles.text}>Are you currently experiencing body pain?</p>
          <Button style={styles.button} variant="contained" color={currentlyInPain ? 'primary' : 'default'} onClick={this.handleShow}>Yes</Button>
          <Button style={styles.button} variant="contained" color={currentlyInPain === false ? 'primary' : 'default'} onClick={this.handleHide}>No</Button>
        </div>

        <div className={`${currentlyInPain ? '' : 'hidden'}`} style={styles.titleGroup}>
          <p style={styles.text}>Is there a new pain or chronic pain (more than 6 months old?)</p>

          <Button
            style={styles.button}
            variant="contained"
            color={painType === 'newPain' ? 'primary' : 'default'}
            onClick={this.handleNewPain}
          >
            New
          </Button>

          <Button
            style={styles.button}
            variant="contained"
            color={painType === 'chronicPain' ? 'primary' : 'default'}
            onClick={this.handleChronicPain}
          >
              Chronic
          </Button>
        </div>

        <div style={styles.nextBtn}>
          <Continue
            text="Next"
            onClick={() => { handleContinue(); }}
          />
        </div>

      </Fragment>
    );
  }
}


function mapStateToProps(state) {
  const { user } = state;
  return { user };
}

BodyPainPro1.proptypes = {
  user: PropTypes.object.isRequired,
  route: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, { submitPRO, updatePRO })(BodyPainPro1);
