import React from 'react';
import PropTypes from 'prop-types';

const styles = {
  fill: 'none',
  strokeLinejoin: 'round',
  strokeWidth: 6,
  width: '5vh',
  height: '2em',
};

const InformedIcon = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 63.35 80.17"
    style={{ ...styles, stroke: color }}
  >
    <title>Informed1x</title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <rect x="3" y="3" width="57.35" height="74.17" rx="5.67" ry="5.67" />
        <rect x="19.19" y="3" width="24.96" height="10.58" />
        <line x1="12.98" y1="46.79" x2="50.36" y2="46.79" />
        <line x1="12.98" y1="56.42" x2="50.36" y2="56.42" />
        <line x1="19.97" y1="65.75" x2="43.38" y2="65.75" />
        <line x1="22.07" y1="30.42" x2="40.61" y2="30.42" />
        <line x1="31.34" y1="39.69" x2="31.34" y2="21.14" />
      </g>
    </g>
  </svg>
);

InformedIcon.defaultProps = {
  color: '#b2b2b2',
};

InformedIcon.propTypes = {
  color: PropTypes.string,
};

export default InformedIcon;
