const en = {
  videoCallPro1: {
    title: 'Virtual Consultation Informed Consent',
    subtitle: 'By clicking accept, I acknowledge and agree that I have fully read the Virtual Healthcare Informed Consent and agree to use virtual healthcare to be treated with by my provider.',
    agree: 'AGREE/JOIN SESSION',
    title2: 'Consent to Use Virtual Healthcare',
    instructions: `
    <p>The protections of medical information guaranteed by HIPAA also apply to virtual healthcare. This information is provided for your benefit. After reviewing this information, please provide your consent to continue with the virtual healthcare appointment.</p>

    <p>If you do not wish to provide consent, please contact your provider to reschedule your appointment.</p>
    `,
    options: {
      option1: 'This virtual healthcare consultation may experience technical failure and result in the loss of any information obtained.',
      option2: 'I hold harmless the virtual healthcare provider and all parties involved in the encounter against any liability, damages, loss, attorney fees and costs of any type due to the loss of information due to a technical failure.',
      option3: 'I authorize the use of my information obtained during the virtual healthcare consultation to be used for treatment, payment, and healthcare operations.',
      option4: 'I understand that I will not have direct, physical contact with the provider during a virtual healthcare consultation.',
      option5: 'I have the right to withhold, withdraw my consent or refuse the use of virtual healthcare at any time.',
      option6: 'I understand that my withdrawal of consent will not affect any future care or treatment, nor will it subject me to the risk of loss or withdrawal of any health benefits to which I am otherwise receiving.',
      option7: 'I have the right to inspect all information obtained and recorded during my virtual healthcare consultation and may receive copies of this information upon written request.',
      option8: 'Providers may have access to any and all relevant information necessary related to the reason for this encounter. If this service is related to alcohol, drug or HIV status, no information will be re-disclosed unless permitted under 42 CFR Part 2 or state law.',
      option9: 'I understand that the virtual healthcare consultation is voluntary and that a variety of alternative methods of medical care may be available to me, and I may choose one or more of these at any time.',
      option10: 'My provider will determine if my condition being diagnosed and/or treated is appropriate for a virtual healthcare consultation.',
      option11: 'My personal health information may be shared through electronic communication with other medical providers involved in my treatment.',
      option12: 'I may expect the anticipated benefits from the use of virtual healthcare in my care, but that no results can be guaranteed or assured. My condition may not be cured or improved, and in some cases, may get worse.',
      option13: 'A copy of this consent will become a part of my medical record.',
    }
  },
};

const es = {
  videoCallPro1: {
    title: 'Instrucciones',
    subtitle: 'Al seleccionar aceptar, yo reconozco y estoy de acuerdo que leí completamente el consentimiento informativo de cuidado de salud virtual y estoy de acuerdo en usa cuidado de salud virtual para ser tratado por mi proveedor de salud.',
    agree: 'ACEPTAR/UNIRSE A LA SESIÓN',
    title2: 'Consentimiento para usar Cuidado de Salud Virtual',
    instructions: `
    <p>La protección de información medica garantizada por HIPAA también aplica para los cuidados de salud virtual. Esta información esta proveída para su beneficio. Después de revisar esta información, por favor de su consentimiento para continuar con su cita de cuidado de salud virtual.</p>

    <p>Si desea no proveer su consentimiento, por favor contacte su proveedor para reagendar su cita.</p>
    `,
    options: {
      option1: 'Al seleccionar aceptar, yo reconozco y estoy de acuerdo que leí completamente el consentimiento informativo de cuidado de               salud virtual y estoy de acuerdo en usa cuidado de salud virtual para ser tratado por mi proveedor de salud.',
      option2: 'Considero indemne al proveedor de atención médica virtual y a todas las partes involucradas en el encuentro contra cualquier responsabilidad, daños, pérdidas, honorarios de abogados y costos de cualquier tipo debido a la pérdida de información debido a una falla técnica.',
      option3: 'Autorizo el uso de mi información obtenida durante la consulta virtual de atención médica para ser utilizada para tratamiento, pago y operaciones de atención médica.',
      option4: 'Entiendo que no tendré contacto directo y físico con el proveedor durante una consulta virtual de atención médica.',
      option5: 'Tengo derecho a retener, retirar mi consentimiento o rechazar el uso de la atención médica virtual en cualquier momento.',
      option6: 'Entiendo que mi retiro de consentimiento no afectará ninguna atención o tratamiento futuro, ni me someterá al riesgo de pérdida o retiro de ningún beneficio de salud que esté recibiendo de otra manera.',
      option7: 'Tengo derecho a inspeccionar toda la información obtenida y registrada durante mi consulta virtual de atención médica y puedo recibir copias de esta información previa solicitud por escrito.',
      option8: 'Los proveedores pueden tener acceso a toda la información relevante necesaria relacionada con el motivo de este encuentro. Si este servicio está relacionado con el estado de alcohol, drogas o VIH, no se volverá a divulgar ninguna información a menos que esté permitido por 42 CFR Parte 2 o la ley estatal.',
      option9: 'Entiendo que la consulta virtual de atención médica es voluntaria y que una variedad de métodos alternativos de atención médica puede estar disponibles para mí, y puedo elegir uno o más de estos en cualquier momento.',
      option10: 'Mi proveedor determinará si mi condición diagnosticada y/o tratada es apropiada para una consulta virtual de atención médica.',
      option11: 'Mi información personal de salud puede ser compartida a través de la comunicación electrónica con otros proveedores médicos involucrados en mi tratamiento.',
      option12: 'Puedo esperar los beneficios anticipados del uso de la atención médica virtual en mi cuidado, pero no se pueden garantizar ni asegurar resultados. Es posible que mi afección no se cure o mejore y, en algunos casos, que empeore.',
      option13: 'Una copia de este Consentimiento se convertirá en parte de mi registro médico.',
    }
  },
};

export default { en, es };
