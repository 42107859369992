import { promiseHandler, resetReducer } from 'cooldux';
import { get, omit } from 'lodash';

import { apiFetch } from '../lib/fetch';
import { logEvent } from '../lib/amplitude';

const { browseStart, browseEnd, browseError, browseHandler } = promiseHandler('browse', 'insuranceImage');
const { addStart, addEnd, addError, addHandler } = promiseHandler('add', 'insuranceImage');
const { deleteStart, deleteEnd, deleteError, deleteHandler } = promiseHandler('delete', 'insuranceImage');

export function browseInsuranceImages(insuranceId) {
  return function dispatcher(dispatch, getState) {
    const selfId = get(getState(), 'user.id', null);
    const promise = apiFetch(`/users/${selfId}/insurance/${insuranceId}/images`);
    return browseHandler(promise, dispatch);
  };
}

export function addInsuranceImage(insuranceId, imageType, newImage) {
  return function dispatcher(dispatch, getState) {
    logEvent('Update Insurance card Photo');
    const selfId = get(getState(), 'user.id', null);
    const promise = window.fetch(newImage)
      .then(res => res.blob())
      .then((blob) => {
        const formData = new FormData();
        formData.append('image', blob);
        formData.append('image_type', imageType);
        const options = {
          method: 'POST',
          body: formData,
        };
        return apiFetch(`/users/${selfId}/insurance/${insuranceId}/images`, options);
      })
      .then(res => res);

    return addHandler(promise, dispatch);
  };
}

export function deleteInsuranceImage(insuranceId, imageId) {
  return function dispatcher(dispatch, getState) {
    const selfId = get(getState(), 'user.id', null);
    const options = {
      method: 'DELETE',
    };
    const promise = apiFetch(`/users/${selfId}/insurance/${insuranceId}/images/${imageId}`, options)
      .then(() => imageId);

    return deleteHandler(promise, dispatch);
  };
}

const initialState = {
  browseError: null,
  addError: null,
  deleteError: null,
  browsePending: false,
  addPending: false,
  deletePending: false,
  data: {},
};

function finishBrowse(state, insuranceImage) {
  const data = insuranceImage;
  /*
   *insuranceImage.forEach((u) => {
   *  data[u.id] = u;
   *});
   */
  return { ...state, data, browsePending: false, browseError: null };
}

function finishAdd(state, item) {
  const data = { ...state.data, [item.id]: item };
  return { ...state, addPending: false, addError: null, data };
}

function finishDelete(state, insuranceImageId) {
  return { ...state, deletePending: false, deleteError: null, data: omit(state.data, insuranceImageId) };
}

const insuranceImage = resetReducer(initialState, (state = initialState, action) => {
  switch (action.type) {
    case browseStart.type:
      return { ...state, browsePending: true };
    case browseEnd.type:
      return finishBrowse(state, action.payload);
    case browseError.type:
      return { ...state, browsePending: false, browseError: action.payload };
    case addStart.type:
      return { ...state, addPending: true };
    case addEnd.type:
      return finishAdd(state, action.payload);
    case addError.type:
      return { ...state, addPending: false, addError: action.payload };
    case deleteStart.type:
      return { ...state, deletePending: true };
    case deleteEnd.type:
      return finishDelete(state, action.payload);
    case deleteError.type:
      return { ...state, deletePending: false, deleteError: action.payload };
    default:
      return state;
  }
});

export default insuranceImage;
