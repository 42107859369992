import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { IconButton, TextField } from '@material-ui/core';
import { connect } from 'react-redux';

import Page from './page';
import AppBar from '../components/app-bar';
import IconWrapper from '../components/icon-wrapper';
import BloomkidzLayout from '../layouts/bloomkidz';
import { Pills, XInCircle } from '../lib/icons';
import { fontSizing } from '../lib/styles';
import { updatePRO } from '../state/pro-forms';
import { bloomkidzStyles, bloomkidzStylesObj } from '../lib/bloomkidz';

const styles = {
  ...bloomkidzStylesObj,
  supplementButton: {
    marginBottom: 30,
    marginTop: 10,
  },
  supplementListText: {
    marginBottom: 10,
  },
  supplementListItem: {
    alignItems: 'center',
    background: 'white',
    display: 'flex',
    marginBottom: 10,
    marginTop: 10,
  },
  supplementListItemText: {
    flexGrow: 1,
    paddingLeft: 10,
  },
  iconButton: {
    height: 50,
    width: 50,
  },
};

class BloomkidzClientWellness5 extends Page {
  constructor(props) {
    super(props);

    const stateFromRedux = props.bloomkidzClientWellnessProfile[this.getTrackIndex()] || {};

    this.state = {
      supplements: [],
      curSupplement: '',
      ...stateFromRedux,
    };
  }

  handleTextFieldChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleContinue = () => {
    const { location, updatePRO } = this.props;

    updatePRO({
      type: 'bloomkidzClientWellnessProfile',
      position: this.getTrackIndex(),
      value: { ...this.state },
    });

    this.forwardWithQuery(location.query);
  }

  addSupplement = () => {
    const { supplements, curSupplement } = this.state;

    if (curSupplement) {
      this.setState({
        supplements: [...supplements, curSupplement],
        curSupplement: '',
      });
    }
  }

  removeSupplement = (supplement) => {
    this.setState(curState => ({
      supplements: curState.supplements.filter(curSupplement => curSupplement !== supplement),
    }));
  }

  render() {
    const { classes, router } = this.props;
    const { supplements, curSupplement } = this.state;

    const buttonClass = curSupplement
      ? `${classes.buttonGreenBackground} ${classes.supplementButton}`
      : `${classes.buttonWithBorder} ${classes.supplementButton}`;

    return (
      <BloomkidzLayout
        primaryBtnOnTouchTap={this.handleContinue}
        primaryBtnLabel={supplements.length ? 'Next' : 'Skip'}
      >
        <AppBar
          headerNode="Supplements"
          backButtonOnClick={() => router.goBack()}
        />
        <IconWrapper>
          <Pills />
        </IconWrapper>
        <div className={classes.pageContent}>
          <div>Please list any non-prescription supplements you give your child on a regular basis:</div>
          <div className={classes.inputSectionTitle}>
            Non-Prescription Supplements
          </div>
          <div className={classes.paddingHorizontal}>
            <div className={classes.textFieldWrapper}>
              <TextField
                placeholder="Non-prescription Supplement"
                value={curSupplement}
                onChange={this.handleTextFieldChange}
                name="curSupplement"
                variant="standard"
                fullWidth
                inputProps={{ style: { fontSize: fontSizing.body, fontFamily: bloomkidzStyles.fontFamily } }}
              />
            </div>
          </div>
          <button
            className={buttonClass}
            onClick={this.addSupplement}
            type="button"
          >
            + Add Supplement
          </button>
          <div className={classes.supplementListText}>
            Supplements List
          </div>
          {supplements.length ? (
            <div className={classes.paddingHorizontal}>
              {supplements.map(supplement => (
                <div className={classes.supplementListItem}>
                  <div className={classes.supplementListItemText} key={supplement}>{supplement}</div>
                  <IconButton
                    className={classes.iconButton}
                    onClick={() => this.removeSupplement(supplement)}
                  >
                    <XInCircle />
                  </IconButton>
                </div>
              ))}
            </div>
          ) : (
            <div className={classes.greyText}><i>No Supplements</i></div>
          )}
        </div>
      </BloomkidzLayout>
    );
  }
}

BloomkidzClientWellness5.propTypes = {
  classes: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  updatePRO: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const {
    proForms: { bloomkidzClientWellnessProfile },
  } = state;

  return { bloomkidzClientWellnessProfile };
};

export default connect(mapStateToProps, { updatePRO })(withStyles(styles)(BloomkidzClientWellness5));