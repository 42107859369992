import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';

import Page from './page';
import AppBar from '../components/app-bar';
import BloomkidzLayout from '../layouts/bloomkidz';
import { updatePRO } from '../state/pro-forms';
import { bloomkidzStylesObj, bloomkidzStyles } from '../lib/bloomkidz';

const styles = {
  ...bloomkidzStylesObj,
  centerText: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
  },
  emphasis: {
    fontWeight: 600,
  },
  spacing: {
    margin: '20px 0 10px',
  },
  paragraph: {
    textAlign: 'left',
    width: '90vw',
    maxWidth: '700px',
    height: '80vh',
    fontSize: '16px',
    margin: '20px auto',
    backgroundColor: '#FFF',
    overflowY: 'scroll',
    boxShadow: 'inset 1px 1px 5px #9B9B9B',
    padding: '10px',
    borderRadius: '2px',
    display: 'flex',
    flexDirection: 'column',
  },
};

class BloomkidzSignatures18 extends Page {
  state = {
    formBottom: false,
  }

  handleScroll = (e) => {
    if (e.target.scrollTop / e.target.scrollHeight > 0.90) {
      this.setState({ formBottom: true });
    }
  }

  handleContinue = () => {
    const { location, updatePRO } = this.props;

    updatePRO({
      type: 'bloomkidzSignatures',
      position: this.getTrackIndex(),
      value: { ...this.state },
    });

    this.forwardWithQuery(location.query);
  }

  render() {
    const { classes, router } = this.props;

    return (
      <BloomkidzLayout
        primaryBtnOnTouchTap={this.handleContinue}
        primaryBtnLabel="Next"
      >
        <AppBar
          headerNode="Client Rights"
          backButtonOnClick={() => router.goBack()}
        />
        <div style={{ backgroundColor: '#FFF', height: '85vh', marginBottom: '20px' }}>
          <div
            ref={(form) => {
              this.myForm = form;
            }}
            onScroll={this.handleScroll}
            className={classes.paragraph}
          >
            <img alt="logo" style={{ width: '90%', alignSelf: 'center', maxWidth: '400px', padding: '15px' }} src="/img/Bloomkidz.png" />
            <h4 color={bloomkidzStyles.primaryColor}>Client Rights</h4>
            <div color={bloomkidzStyles.primaryColor}>
              Electronic Health Records
            </div>
            <ol>
              <li>
                Patients have the right to obtain access to their PHI contained in an electronic health record, (as well as PHI contained in MS Word, Excel, PDF, HTML, plain text and other formats),  and to direct us to send a copy of their electronic health record to a third party.
              </li>
              <li>
                If a patient requests an electronic copy of PHI that is maintained electronically in one or more designated record sets, we must provide the individual with access to the electronic information in the electronic form and format requested by the individual, if that is readily producible, or if not, in an electronic form and format as agreed to by us and the individual, such as MS Word, Excel, text, HTML or text-based PDF.
              </li>
              <li>
                Patients have the right to receive an accounting of disclosures of electronic health records including those for treatment, payment, and healthcare operations during the three year period preceding the request.
              </li>
              <li>
                The above rights for electronic health records are effective January 1, 2014. For electronic health records acquired after January 1, 2009, the rights are effective January 1, 2011.
              </li>
            </ol>
            <div color={bloomkidzStyles.primaryColor}>
              Right to Inspect & Copy
            </div>
            <ol>
              <li>
                Patients have the right to inspect and obtain a copy of their designated record set.  A Designated Record Set is simply protected health information records that are used, in whole or in part, to make decisions about patients, their treatment, or billing for services rendered.  For many practices, this mainly includes medical and billing records for a patient.
              </li>
              <li>
                Patients wishing to inspect and/or copy their designated record set must submit their request in writing to the Privacy Officer at our mailing address. Patients will be informed in the Notice of Privacy Practices of the requirement that a request for access be in writing.
              </li>
              <li>
                Patients maybe denied access for the following reasons:
                <ol type="a">
                  <li>
                    Access is reasonably likely to endanger the life or physical safety of the patient or another person
                  </li>
                  <li>
                    The information requested constitutes Psychotherapy notes
                  </li>
                  <li>
                    The information requested was compiled in reasonable anticipation of, or for use in, a civil, criminal, or administrative action or proceeding
                  </li>
                  <li>
                    The information is subject to (and access is denied under applicable provisions) or exempted from the Clinical Laboratory Improvements Amendments of 1988 (CLIA)
                  </li>
                  <li>
                    As an inmate, the patient&apos;s access can be denied by a correctional institution or us, as a provider acting under the direction of a correctional institution, if such access would jeopardize the health, safety, security, custody or the rehabilitation of the patient or other inmates, or the safety of any officer, employee or other person at the correctional institution or responsible for the patient&apos;s transportation
                  </li>
                  <li>
                    The information  was obtained in the course of research that includes the patient&apos;s treatment  and access will be denied while research is in progress
                  </li>
                  <li>
                    The information requested that is also subject to the Privacy Act, 5 U.S.C. 552a.
                  </li>
                  <li>
                    The requested PHI was obtained from someone other than a health care provider under a promise of confidentiality and such access would be reasonably likely to reveal the source of the information
                  </li>
                </ol>
              </li>
              <li>
                We will respond within thirty (30) calendar days to any written request for access to PHI. If additional time is required to satisfy the request, we will request an extension from the patient in writing. After that, we will have an additional 30 calendar days to fulfill the request.
              </li>
              <li>
                Denials for access will be communicated via a written letter to the address specified by the patient.
              </li>
              <li>
                If access is granted a copy of the patient&apos;s requested PHI will be made available for the patient to review or will be mailed to an address designated by the patient.
              </li>
              <li>
                Patients have the right to inspect and obtain a copy of our Notice of Privacy Practices which documents our use and disclosure practices.
              </li>
              <li>
                If Patients request a copy of their PHI, we will charge a reasonable cost-based fee for the costs of copying, mailing or other supplies associated with the request.   The fee schedule is based on our costsfor copy supplies and labor costs for copying.  We currently charge $0.______ per page for copies plus the actual cost of postage for mailing.   For faxing documents, we charge $0._____ per page for the cost of fax supplies and telephone line costs.
              </li>
              <li>
                We will notify the patient of the cost involved and the patient may choose to withdraw or modify the request at that time before any costs are incurred.
              </li>
              <li>
                Patients must pay the fee in full before they can obtain a copy of the information; however, patients havea right to inspect their PHI without paying any fee.
              </li>
              <li>
                The Notice of Privacy Practices is required to be provided during the first encounter with the patient.
              </li>
              <li>
                The Notice of Privacy Practices must always be available upon request.
              </li>
              <li>
                The Notice of Privacy Practices will be posted in the place where services are provided.
              </li>
              <li>
                The Notice of Privacy Practices will be placed on this practice&apos;s web site (if we have one).
              </li>
              <li>
                When the Notice of Privacy Practices is revised, the revised copy will be posted and available upon request.
              </li>
            </ol>
            <div color={bloomkidzStyles.primaryColor}>
              Right to Amend
            </div>
            <ol>
              <li>
                If the patient feels that the information we have about them in their designated record set is incorrect or incomplete, they may ask us to amend the information.   A Designated Record Set is simply protected health information records that are used, in whole or in part, to make decisions about patients, their treatment, or billing for services rendered.  For many practices, this mainly includes medical and billing records for a patient.
              </li>
              <li>
                Patients have the right to request an amendment for as long as the information is kept.
              </li>
              <li>
                To request an amendment, the patient request must be made in writing and submitted to the Privacy Officer at our mailing address. The patient should include the reason that supports the request. Patients will be informed in the Notice of Privacy Practices of the requirement that an amendment request be in writing.
              </li>
              <li>
                A written response will be mailed to the patient within sixty (60) calendar days on the disposition of their amendment request.    If additional time is needed, this practice will inform the patient within the sixty (60) days in writing of the delay, the reason for the delay, and the date the accounting will be provided that will be no later than 90 days from the original request.
              </li>
              <li>
                If the amendment request is accepted by this practice we will:
                <ol type="a">
                  <li>
                    Add the amendment to the patients PHI
                  </li>
                  <li>
                    Inform the patient that the amendment was accepted
                  </li>
                  <li>
                    Ask the patient in writing to identify entities that should be notified of the amendment
                  </li>
                  <li>
                    Obtain the patient&apos;s permission to contact those entities
                  </li>
                  <li>
                    This practice will make a reasonable effort to inform entities, including business associates, to whom we have disclosed the information and who could be predicted to use the information to the extent that the patient agrees that we may notify these entities
                  </li>
                </ol>
              </li>
              <li>
                We may deny a patient&apos;s request for an amendment if it is not in writing or does not include a reason to support the request. In addition, we may deny a patient&apos;s request if they ask to amend information that:
                <ol type="a">
                  <li>
                    Was not created by us, unless the person or entity that createdthe information is no longer available to make the amendment;
                  </li>
                  <li>
                    Is not part of the designated record set kept by our office
                  </li>
                  <li>
                    Is not part of the designated record set which they would be permitted to inspect and copy (see item 2 previous section); or
                  </li>
                  <li>
                    Is accurate and/or complete
                  </li>
                  <li>
                    The information  constitutes psychotherapy notes
                  </li>
                </ol>
              </li>
              <li>
                If the amendment request is denied by this practice we will:
                <ol type="a">
                  <li>
                    Provide a written denial notice within sixty (60) calendar days
                  </li>
                  <li>
                    Permit the individual to submit a written statement disagreeing with the denial and to give the basis for the disagreement
                  </li>
                  <li>
                    May write a rebuttal to the disagreement and provide a copy of the rebuttal to the patient
                  </li>
                  <li>
                    Will append the following to the record containing the disputed information:
                    <ul>
                      <li>
                        Request for amendment
                      </li>
                      <li>
                        Denial of amendment
                      </li>
                      <li>
                        Statement of disagreement
                      </li>
                      <li>
                        Written rebuttal
                      </li>
                    </ul>
                  </li>
                </ol>
              </li>
              <li>
                This practice will include in any subsequent disclosure of the PHI contained in the disputed record:
                <ol type="a">
                  <li>
                    The request for amendment that was submitted by the patient (or an accurate summary of the request)
                  </li>
                  <li>
                    The denial of request for amendment
                  </li>
                  <li>
                    Any statement of disagreement (or an accurate summary of the statement) submitted by the patient
                  </li>
                  <li>
                    This practice&apos;s rebuttal of the statement of disagreement
                  </li>
                </ol>
              </li>
            </ol>
            <div color={bloomkidzStyles.primaryColor}>
              Right to an Accounting of Disclosures
            </div>
            <ol>
              <li>
                Patients have the right to request an &ldquo;accounting of disclosures.&rdquo; This is alist of the disclosures we made of PHI about the patient, that were not made to the patient, pursuant to an authorization by the patient, was not an incidental disclosure or part of a limited data set (data that does not include directly identifiable information), used for research, used for public health purposes, to persons involved in the patient&apos;s care, for national security or intelligence purposes, to correctional institutions or law enforcement, for treatment, payment or health care operations, or for disclosures made prior to the date of compliance with privacy standards.
                &NewLine;An incidental use or disclosure is described as a secondary use or disclosure that cannot reasonably be prevented, is limited in nature, and that occurs as a by-product of an otherwise permitted use or disclosure. Such incidental uses or disclosures are not considered a violation of the Privacy Rule provided that the covered entity has met the reasonable safeguards and minimum necessary requirements. For example, if these requirements are met, doctors&apos; offices may use waiting room sign-in sheets, hospitals may keep patient charts at bedside, doctors can talk to patients in semi-private rooms, and doctors can confer at nurse&apos;s stations without fear of violating the rule if inadvertently overheard by a passerby.
              </li>
              <li>
                Disclosures and requests for an accounting of disclosures will be tracked in the Log of PHI Disclosures form that will be maintained in the patients file.
              </li>
              <li>
                To request an accounting of disclosures, the patient must submit arequest in writing to the Privacy Officer at our mailing address. Patient request must state a time period that may not be longer than six years.
              </li>
              <li>
                Patient request should indicate in what form they want the list (for example, on paper, electronically).   The first list they request within a 12-month period will be free.   We will charge the patient a reasonable cost-based fee for providing any additional lists within a 12 month period.  The fee schedule is based on our costs for copy supplies and labor costs for preparation of the accounting.  The estimated cost for the second accounting within a 12 month period will be calculated prior to preparing the accounting.
              </li>
              <li>
                We will notify the patient of the cost involved and the patient may choose to withdraw or modify their request at that time before any costs are incurred.
              </li>
              <li>
                Patients must pay the fee in full before they can obtain the requested accounting
              </li>
              <li>
                This practice will respond to requests for accounting disclosures within sixty (60) calendar days.
              </li>
              <li>
                If additional time is needed, this practice  will inform the patient within the sixty (60) days in writing of the delay, the reason for the delay, and the date the accounting will be provided that will be no later than 90 days from the original request.
              </li>
            </ol>
            <div color={bloomkidzStyles.primaryColor}>
              Right to Request Restrictions
            </div>
            <ol>
              <li>
                Patients have the right to request us to not disclose PHI to a health plan for payment or health care operations if the patient has paid out of pocket for the service.
              </li>
              <li>
                Patients have the right to request a restriction or limitation on the PHI we may use or disclose about them for treatment, payment or health care operations. They also have the right to request a limit on the PHI we disclose about them to someone who is involved in their care or the payment for their care.
              </li>
              <li>
                Other than the request for not disclosing to a health plan if the patient has paid out of pocket for the services, we are not required to agree to the patient&apos;s request for restriction. If we do agree, we will comply with the request unless the information is needed to provide the patient emergency treatment.
              </li>
              <li>
                To request restrictions, the patient must make their request in writing to the Privacy Officer at our mailing address.
              </li>
              <li>
                Each request must state the following:
                <ol type="a">
                  <li>
                    What information the patient wants to limit
                  </li>
                  <li>
                    Whether the patient wants to limit our use, disclosure or both
                  </li>
                  <li>
                    To whom the patient wants the limits to apply
                  </li>
                </ol>
              </li>
              <li>
                If the Privacy Officer agrees to the restrictions, the request with an approval stamp will be attached to the front of the patient&apos;s file to allow anyone using or disclosing the patient&apos;s PHI to see the restrictions.
              </li>
              <li>
                The Privacy Officer is authorized to agree to such restrictions on the practice&apos;s behalf.
              </li>
              <li>
                This practice may terminate its agreement to restrict the uses and disclosures of an individual&apos;s information under the following conditions:
                <ol type="a">
                  <li>
                    If the individual agrees to or requests the termination in writing;
                  </li>
                  <li>
                    If the individual orally agrees to the termination and the oral agreement is documented;
                  </li>
                  <li>
                    If the covered entity informs the individual that it is terminating the agreement, but the termination is only effective with respect to PHI created or received after the termination notification date.
                  </li>
                </ol>
              </li>
              <li>
                In the event this practice believes that a use or disclosure of restricted PHI is necessary for emergency treatment, the covered entity may use or disclose the PHI to provide such treatment.  If PHI is disclosed to a health care provider for emergency treatment, the practice will request that the health care provider not further disclose the PHI.
              </li>
              <li>
                Any agreement to restrict PHI must be retained for a period of six (6) years from the date of its creation or from the date it was last in effect, whichever is later.
              </li>
            </ol>
            <div color={bloomkidzStyles.primaryColor}>
              Right to Request Confidential Communication
            </div>
            <ol>
              <li>
                Patients have the right to request that we communicate with them in a certain way or at a certain location.
              </li>
              <li>
                To request confidential communications, patients must make their request in writing to the Privacy Officer.
              </li>
              <li>
                We will not ask the patient the reason for the request.
              </li>
              <li>
                We will accommodate all reasonable requests.
              </li>
              <li>
                Patients must specify how or where they wish to be contacted.
              </li>
              <li>
                The request with an approval stamp will be attached to the front of the patient&apos;s file to allow anyone communicating with thepatient to see and honor the request for confidential communication.
              </li>
            </ol>
          </div>
        </div>
      </BloomkidzLayout>
    );
  }
}

BloomkidzSignatures18.propTypes = {
  classes: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  updatePRO: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
};

export default connect(null, { updatePRO })(withStyles(styles)(BloomkidzSignatures18));
