/* eslint-disable */
import React from 'react';

const styles = {
  container: {
    textAlign: 'left',
  },
  header: {
    textAlign: 'center',
  }
}

const AboutMindset = () => (
  <div style={styles.container}>
    <h1 style={styles.header}>
      Mindset Medical, LLC
    </h1>

    <p>
      Mindset Medical, LLC (mindsetmedical.com) is a healthcare information company headquartered in Phoenix, AZ, dedicated to improving the access and quality of patient healthcare information.  Like you, we are patients.  And as patients, we value the access and availability of our healthcare information.  We believe that you should be at the center of your healthcare and that you should have the information, tools and choices to live a healthy life.
    </p>

    <p>
      Mindset Medical Informed™ is a universal patient engagement platform, connecting your personal health information to your healthcare provider.  Using your smart phone and the Mindset Medical Informed™ app, you report, store, update and maintain your personal health and demographic information and you, the patient, insure that the information is current, accurate and up to date.  You can then carry your health information with you and share it digitally with any participating healthcare provider at any time.  No more forms to fill out, no more gaps in your health history, no more questions about your current health condition.  Enter the information once and after that only when it changes.  You inform the doctor about your health so the Doctor can efficiently begin diagnosis and treatment. You control this information because nobody knows or cares about it more than you do.
    </p>

    <p>
      Entering patient demographic, insurance, medications, current symptoms and medical history information is easy and intuitive.  Your personal Health Information can be updated over time as needed or as requested by your healthcare provider.  Current and relevant health information is presented to the healthcare provider.  You present your smart phone with your unique Quick Response (QR) Code at check-in, eliminating most of the intake paperwork requested when checking in for a visit.
    </p>

    <p>
      Mindset Medical, LLC contracts with healthcare providers to accept the patient provided personal healthcare information through a proprietary platform connecting HIPAA compliant cloud based patient data to your approved provider. Because the information is presented digitally, it is available and accessible without delay to inform the current visit.
    </p>

    <p>
      The current version of Mindset Medical Informed contains features to document and report your health outside the scheduled Doctor visit with the goal of providing clinical tools to help drive patients to pain-free outcomes. Patients who suffer from recurring or chronic pain, including headaches, can report their pain and share this information with their healthcare provider.
    </p>

    <p>
      Mindset Medical Informed™ - universal personal health information for the engaged patient.
    </p>
  </div>
);

export default AboutMindset;
