import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Divider, Checkbox, FormControlLabel, TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import Page from './page';
import Layout from '../layouts/common';
import { colors } from '../lib/styles';
import { updatePRO, submitPRO } from '../state/pro-forms';
import Continue from '../components/continue';

const classes = {
  checkbox: {
    fontSize: '1.2rem',
    paddingTop: '5px',
  },
  checkboxDisabled: {
    fontSize: '1.2rem',
    paddingTop: '5px',
    color: '#D8D8D8',
  },
};

const baseStyles = {
  paragraph: {
    textAlign: 'left',
    width: '90vw',
    maxWidth: '700px',
    height: '50vh',
    fontSize: '16px',
    margin: '20px auto',
    backgroundColor: '#FFF',
    overflowY: 'scroll',
    boxShadow: 'inset 1px 1px 5px #9B9B9B',
    padding: '10px',
    borderRadius: '2px',
  },
  buttonDisabled: {
    backgroundColor: '#9B9B9B',
    width: '200px',
  },
  button: {
    width: '200px',
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  checkbox: {
    color: colors.primaryColor,
    fontSize: '1.2rem',
    paddingTop: '7px',
    margin: '0 auto',
  },
  checkboxDisabled: {
    color: '#D8D8D8',
    fontSize: '1.2rem',
    paddingTop: '7px',
    margin: '0 auto',
  },
};

class JohnsHopkinsConsent1 extends Page {
  state = {
    submitting: false,
    formBottom: false,
    first: '',
    middle: '',
    last: '',
    accepted: false,
  }

  onSubmission = () => {
    const { user } = this.props;
    const { id } = this.props.location.query;
    const { first, middle, last, accepted } = this.state;
    if (!accepted || (first === '') || (last === '')) {
      return;
    }
    const date = { year: 'numeric', month: 'numeric', day: 'numeric' };
    const signature = `Signed ${new Date().toLocaleDateString('en-US', date)}\n${first} ${middle !== '' ? middle : ''} ${last}`;
    this.setState({ submitting: true });
    this.props.submitPRO({ userId: user.id, id }, {
      pro_type: 'JOHNS-HOPKINS-CONSENT',
      pro_data: { data: { accepted: true, signature } },
    }, user)
      .then((res) => {
        this.props.updatePRO({
          type: 'jhconsent',
          position: this.getTrackIndex(),
          value: res,
        });
      })
      .then(() => this.forward())
      .catch(() => this.setState({ submitting: false }));
  }

  handleScroll = (e) => {
    if (e.target.scrollTop / e.target.scrollHeight > 0.90) {
      this.setState({ formBottom: true });
    }
  }

  render() {
    const { first, last, accepted } = this.state;
    let buttonColor = false;
    if (accepted && (first !== '') && (last !== '')) {
      buttonColor = true;
    }
    return (
      <Layout>
        <div style={{backgroundColor: '#FFF', height: '100vh'}}>
          <img alt='logo' style={{width: '90%', maxWidth: '400px', padding: '15px'}} src='/img/Hopkins.png' />
          <div
            ref={(form) => {
              this.myForm = form;
            }}
            onScroll={this.handleScroll}
            style={baseStyles.paragraph}
          >
            <h4>RESEARCH PARTICIPANT INFORMED CONSENT AND PRIVACY AUTHORIZATION FORM</h4>

            <Divider />
            <div style={{display: 'flex', margin: '20px 0', fontWeight: '600'}}><div style={{flex: 1}}>Protocol Title:</div><div style={{flex: 2}}>Mobile Application to Track Quality of Life in Patients with Spinal Pathology</div></div>

            <div style={{display: 'flex', margin: '20px 0', fontWeight: '600'}}><div style={{flex: 1}}>Application No.:</div><div style={{flex: 2}}>IRB00214585</div></div>
            <div style={{display: 'flex', fontWeight: '600', flexDirection: 'column', alignItems: 'flex-start', margin: '20px 0'}}>
              <div>Principal Investigator: Richard Skolasky MA, ScD</div>
              <div>The Johns Hopkins University School of Medicine</div>
              <div>Department of Orthopaedic Surgery</div>
              <div>601 North Caroline Street / Room 5244</div>
              <div>Baltimore, Maryland 21287</div>
              <div>Tel: 410-502-7975</div>
              <div>Fax: 410-614-1451</div>
              <div>E-mail: rskolas1@jhmi.edu</div>
            </div>
            <Divider />
            <p>
            You are being asked to join a research study. Participation in this study is voluntary. Even if you decide to join now, you can change your mind later.
            </p>
            <div style={{display: 'flex', marginTop: '20px', fontWeight: '600'}}>
            1. Research Summary (Key Information):
            </div>
            <div>
            The information in this section is intended to be an introduction to the study only.  Complete details of the study are listed in the sections below. If you are considering participation in the study, the entire document should be discussed with you before you make your final decision.  You can ask questions about the study now and at any time in the future.
            </div>
            <div style={{display: 'flex', margin: '20px 0'}}>
            The present study aims to evaluate the use of a mobile medical application to enable physicians to track a patient’s quality of life using validated questionnaires both before and after surgery. We hope to show that by using this tool, the outpatient visits are more efficient and engaging for the patient and physician. We also hope to be able to gain a better understanding of the impact of the spine surgery on the patient’s life and well-being. This is not a treatment study.
            </div>
            <div style={{display: 'flex', margin: '20px 0'}}>
            Participation in this study includes completing surveys on a mobile app 2-4 weeks before surgery and after surgery (at 2 and 6 weeks, and at 3, 6, 12, and 24 months after). The risks associated with the study are feeling uncomfortable or bored when answering questions and the potential loss of confidentiality. There is no benefit to you from being in this study. There are no costs or compensation for participation.
            </div>
            <div style={{display: 'flex', marginTop: '20px', fontWeight: '600'}}>
            2. Why is this research being done?
            </div>
            <div>
            This research is being done to better evaluate the use of mobile medical application in patient evaluation and following spine surgery. Use of this application will also enable providing physicians the ability to track quality of life scores using validated metrics both before and after surgery. We hope to demonstrate that the use of these applications enables patients to have a more convenient and streamlined outpatient experience as well as better care when their physicians understand their quality of life outcomes.
            </div>
            <div style={{fontWeight: '600', margin: '20px 0', fontStyle: 'italic', textDecoration: 'underline'}}>
            Who can join this study?
            </div>
            <div>
            People 18 years or older who are being evaluated for spinal surgery may join. 
            </div>
            <div style={{display: 'flex', marginTop: '20px', fontWeight: '600'}}>
            3. What will happen if you join this study?
            </div>
            <div>
            If you agree to be in this study, we will ask you to do the following things:
            </div>
            <div style={{display: 'flex', margin: '20px 0'}}>
            Participation in this study does not impact your surgical or clinical care at Johns Hopkins. You will receive standard care, irrespective of your decision to participate or not participate in this study.
            </div>
            <div style={{display: 'flex', margin: '20px 0'}}>
            Your pre-surgical evaluation, surgery and any follow-up will not be affected by your participation in this study.
            </div>
            <div style={{display: 'flex', margin: '20px 0'}}>
            If you agree to be in this study, we will ask you to:
            </div>
            <ul>
            <li>
            Download the mobile medical application to your smartphone, record information about your current health status and your symptoms and complete the quality of life assessments before and/or after surgery.
            </li>
            <li>
            Surveys will be completed according to the following schedule:
            <ol>
            <li>Screening/before Surgery</li>
            <li>After surgery, before you are discharged from the hospital</li>
            <li>1.5-month follow-up appointment</li>
            <li>3-month follow-up appointment</li>
            <li>6-month follow-up appointment.</li>
            <li>12-month follow-up appointment.</li>
            <li>24-month follow-up appointment.</li>
            </ol>
            </li>
            <li>
            If you do not have a follow-up appointment at Johns Hopkins during those times or are unable to complete the surveys at the time of your appointment, you will be able to complete it using the mobile application.
            </li>
            </ul>
            <div style={{fontWeight: '600', textDecoration: 'underline'}}>
            How long will you be in the study?
            </div>
            <div>
            You will be in this study for about 25 months (about 1 month before and 24 months after surgery).
            </div>
            <div style={{display: 'flex', marginTop: '20px', fontWeight: '600'}}>
            4. What happens to data that are collected in the study? 
            </div>
            <div>
            Johns Hopkins and our research partners work to advance science and public health.  The data we collect about you are important to this effort.
            </div>
            <div style={{display: 'flex', margin: '20px 0'}}>
            If you join this study, you should understand that you will not own your research data. If researchers use your data to create a new product or idea, including those that may have commercial value, you will not benefit financially from those efforts.
            </div>
            <div style={{ fontStyle: 'italic', textDecoration: 'underline'}}>
            How will your data be shared now and in the future?
            </div>
            <div>
            Sharing data is part of research and may increase what we can learn from each study.
            </div>
            <div>
            Often, data sharing is required as a condition of funding or for publishing study results.  It also is needed to allow other researchers to validate study findings and to come up with new ideas. 
            </div>
            <div style={{display: 'flex', margin: '20px 0', flexDirection: 'column'}}>
            Your data may be shared:
            <ul>
            <li>
            directly with research collaborators, other researchers, sponsors, government agencies, publishers of papers and other research partners 
            </li>
            <li>
            through government or other databases/repositories 
            </li>
            </ul>
            </div>
            <div style={{display: 'flex', margin: '20px 0'}}>
            Data sharing could change over time and may continue after the study ends.
            </div>
            <div style={{display: 'flex', margin: '20px 0'}}>
            We will do our best to protect and maintain your data in a safe way.  Generally, if we share your data without identifiers (such as your name, address, date of birth) no further review and approval by an Institutional Review Board (IRB) is needed.  If data are shared with identifiers, further IRB review and approval may be needed. The IRB will determine whether additional consent is required.
            </div>
            <div style={{display: 'flex', margin: '20px 0'}}>
            If you are not comfortable with the use of your data in future research, you may not want to participate in this study.
            </div>
            <div style={{display: 'flex', margin: '20px 0', fontWeight: '600'}}>
            5. What are the risks or discomforts of the study?
            </div>
            <div style={{fontWeight: '600', textDecoration: 'underline'}}>
            Interviews or questionnaires 
            </div>
            <div>
            You may get tired or bored when we are asking you questions, or you are completing questionnaires. You do not have to answer any question you do not want to answer.
            </div>
            <div style={{display: 'flex', margin: '20px 0'}}>
            Physicians will review your answers to the questionnaires that are entered into the medical application at the time of your outpatient visit. Therefore, if you enter the data several weeks prior to the visit, the data may not be viewed by your physician in real time, but rather only when the physician logs into the system to review the data – typically at the time of the outpatient visit. You may want to contact your physician if you have any questions or issues that come up when you are completing the questionnaires. 
            </div>
            <div style={{fontWeight: '600', textDecoration: 'underline'}}>
            Identifiable private information
            </div>
            <div>
            There is the risk that information about you may become known to people outside this study.
            </div>
            <div style={{display: 'flex', marginTop: '20px', fontWeight: '600'}}>
            6. Are there benefits to being in the study?
            </div>
            <div>
            There is no direct benefit to you from being in this study. 
            </div>
            <div style={{display: 'flex', margin: '20px 0'}}>
            If you take part in this study, you may help others in the future. Findings from this study can be used to enable more targeted and effective patient centered care for patients with spinal disease. 
            </div>
            <div style={{display: 'flex', marginTop: '20px', fontWeight: '600'}}>
            7. What are your options if you do not want to be in the study?
            </div>
            <div>
            You do not have to join this study. If you do not join, your care at Johns Hopkins will not be affected.
            </div>
            <div style={{display: 'flex', marginTop: '20px', fontWeight: '600'}}>
            8. Will it cost you anything to be in this study?  
            </div>
            <div>
            There is no cost to you being in the study. When using the mobile medical application, you will be responsible for any data charges incurred. Use while in the hospital, outpatient office or at home with a wireless internet (WiFi) system should allow you to not incur data related charges.
            </div>
            <div style={{display: 'flex', marginTop: '20px', fontWeight: '600'}}>
            9. Will you be paid if you join this study?
            </div>
            <div>
            No.
            </div>
            <div style={{display: 'flex', marginTop: '20px', fontWeight: '600'}}>
            10. Can you leave the study early?
            </div>
            <ul>
            <li>
            You can agree to be in the study now and change your mind later.
            </li>
            <li>
            If you wish to stop, please tell us right away.
            </li>
            <li>
            Leaving this study early will not stop you from getting regular medical care.
            </li>
            </ul>
            <div style={{display: 'flex', margin: '20px 0'}}>
            If you leave the study early, Johns Hopkins may use your health information that it has already collected if the information is needed for this study or any follow-up activities.
            </div>
            <div style={{display: 'flex', marginTop: '20px', fontWeight: '600'}}>
            11. Why might we take you out of the study early? 
            </div>
            <div>
            You may be taken out of the study if:
            </div>
            <ul style={{display: 'flex', flexDirection: 'column', margin: '20px 0'}}>
            <li>
            Staying in the study would be harmful.
            </li>
            <li>
            You need treatment not allowed in the study.
            </li>
            <li>
            You fail to follow instructions.
            </li>
            <li>
            The study is cancelled.
            </li>
            <li>
            There may be other reasons to take you out of the study that we do not know at this time. 
            </li>
            </ul>
            <div style={{display: 'flex', margin: '20px 0'}}>
            If you are taken out of the study early, Johns Hopkins may use or give out your health information that it has already collected if the information is needed for this study or any follow-up activities.
            </div>
            <div style={{display: 'flex', margin: '20px 0', fontWeight: '600'}}>
            12. How will your privacy be maintained and how will the confidentiality of your data be protected? 
            </div>
            <div style={{display: 'flex', margin: '20px 0', fontWeight: '600'}}>
            HIPAA Authorization for Disclosure of Protected Health Information 
            </div>
            <div style={{display: 'flex', fontWeight: '600'}}>
            What information is being collected, used, or shared?
            </div>
            <div>
            To do this research, we will need to collect, use, and share your private health information.  By signing this document, you agree that your health care providers (including both Johns Hopkins Medicine and others) may release your private health information to us, and that we may use any and all of your information that the study team believes it needs to conduct the study.  Your private information may include things learned from the procedures described in this consent form, as well as information from your medical record (which may include information such as HIV status, drug, alcohol or STD treatment, genetic test results, or mental health treatment).
            </div>
            <div style={{display: 'flex', marginTop: '20px', fontWeight: '600'}}>
            Who will see, use or share the information?
            </div>
            <div>
            The people who may request, receive, or use your private health information include the researchers and their staff.  Additionally, we may share your information with other people at Johns Hopkins, for example if needed for your clinical care or study oversight.  To improve coordination of your research and clinical care, some information about the study you join will be included in your electronic medical record.  
            </div>
            <div style={{display: 'flex', margin: '20px 0',}}>
            By signing this form, you give permission to the research team to share your information with others outside of Johns Hopkins.  This may include the sponsor of the study and its agents or contractors, outside providers, study safety monitors, government agencies, other sites in the study, data managers and other agents and contractors used by the study team.  
            </div>
            <div style={{display: 'flex', margin: '20px 0',}}>
            We try to make sure that everyone who sees your information keeps it confidential, but we cannot guarantee that your information will not be shared with others.  If your information is disclosed by your health care providers or the research team to others, federal and state confidentiality laws may no longer protect it.  
            </div>
            <div style={{display: 'flex', fontWeight: '600'}}>
            Do you have to accept this Authorization?
            </div>
            <div>
            You do not have to accept this Authorization, but if you do not, you may not join the study.
            </div>
            <div style={{display: 'flex', marginTop: '20px', fontWeight: '600'}}>
            How long will your information be used or shared? 
            </div>
            <div>
            Your Authorization for the collection, use, and sharing of your information does not expire. Additionally, you agree that your information may be used for similar or related future research studies.
            </div>
            <div style={{display: 'flex', marginTop: '20px', fontWeight: '600'}}>
            What if you change your mind?
            </div>
            <div>
            You may change your mind and cancel this Authorization at any time.  If you cancel, you must contact the Principal Investigator in writing to let them know by using the contact information provided in this consent form.  Your cancellation will not affect information already collected in the study, or information that has already been shared with others before you cancelled your authorization.
            </div>
            <div style={{display: 'flex', marginTop: '20px', fontWeight: '600'}}>
            How will your information be protected?
            </div>
            <div>
            To protect your information, your research record will be handled as confidentially as possible. Data collected from this study will be transmitted and stored in a secure encrypted, HIPAA compliant cloud-based database. No identification, including name, initial, date of birth, address, will be used in any reports or publications resulting from this study. Reports or publications from this study will not be identifiable to you. Mindset Medical will delete study data from its servers and storage systems after completion of the study.
            </div>
            <div style={{display: 'flex', marginTop: '20px', fontWeight: '600'}}>
            13. What does a conflict of interest mean to you as a participant in this study?
            </div>
            <div>
            Dr. Theodore, a co-investigator of this study, is a consultant for Mindset Medical- the developer of the Informed mobile application. He receives no financial benefit from your involvement in the study or use of the application.
            </div>
            <div style={{display: 'flex', margin: '20px 0'}}>
            In some situations, the results of this study may lead to a financial gain for the researcher and/or Johns Hopkins University.  This financial interest has been reviewed in keeping with Johns Hopkins’ policies.  It has been approved with certain conditions, which are intended to guard against bias in how the study is conducted, how the results are analyzed, and how participants are protected.
            </div>
            <div style={{display: 'flex', margin: '20px 0'}}>
            If you have any questions about this financial interest, please talk to Dr. Richard Skolasky at 410-502-7975. This person is a member of the study team but does not have a financial interest related to the study. You may also call the Office of Policy Coordination 410-361-8667 for more information. The Office of Policy Coordination reviews financial interests of researchers and/or Johns Hopkins.
            </div>
            <div style={{display: 'flex', marginTop: '20px', fontWeight: '600'}}>
            14. What other things should you know about this research study?
            </div>
            <div>
            During the study, we will tell you if we learn any new information that might affect whether you wish to continue to participate.
            </div>
            <div style={{display: 'flex', marginTop: '20px', fontWeight: '600'}}>
            What is the Institutional Review Board (IRB) and how does it protect you? 
            </div>
            <div>
            This study has been reviewed by an Institutional Review Board (IRB), a group of people that reviews human research studies. The IRB can help you if you have questions about your rights as a research participant or if you have other questions, concerns or complaints about this research study.  You may contact the IRB at 410-502-2092 or jhmeirb@jhmi.edu. 
            </div>
            <div style={{display: 'flex', marginTop: '20px', fontWeight: '600'}}>
            What should you do if you have questions about the study, or are injured or ill as a result of being in this study? 
            </div>
            <div>
            Call the principal investigator, Dr. Richard Skolasky at (410) 502-7975. If you wish, you may contact the principal investigator by letter. The address is on page one of this consent form. If you cannot reach the principal investigator or wish to talk to someone else, call the IRB office at 410-502-2092.  
            </div>
            <div style={{display: 'flex', marginTop: '20px', fontWeight: '600'}}>
            15. What does your acceptance of this Authorization form mean?
            </div>
            <div>
            Your acceptance of this Authorization means that you have reviewed the information in this form, you have had a chance to ask questions, and you agree to join the study. You will not give up any legal rights by signing this consent form. 
            </div>
            <div style={{display: 'flex', margin: '20px 0'}}>
            I have agreed to submit this authorization by electronic means.  By signing this authorization electronically, I have read and understand the questions and statements on this Research Participant Informed Consent and Privacy Authorization.  
            </div>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'left', maxWidth: '500px', width: '90%', margin: '0 auto' }}>
            <FormControlLabel
              style={{ alignItems: 'flex-start'}}
              control={
                <Checkbox
                  checked={this.state.accepted}
                  onChange={() => {
                    this.setState({ accepted: !this.state.accepted });
                  }}
                  disabled={!this.state.formBottom}
                  style={this.state.formBottom ? baseStyles.checkbox : baseStyles.checkboxDisabled}
                />
              }
              classes={{ label: this.state.formBottom ? this.props.classes.checkbox : this.props.classes.checkboxDisabled }}
              label="By checking this box and typing my name below, I am electronically signing this Research Participant Informed Consent and Privacy Authorization."
            />
            <div style={{ display: 'flex'}}>
              <TextField
                disabled={!this.state.accepted}
                style={{ flex: 1, margin: '0 5px', color: this.state.formBottom ? colors.primaryColor : '#D8D8D8' }}
                inputProps={{ style: { marginLeft: '20px', fontSize: '16px' } }}
                label="First Name"
                value={this.state.first}
                onChange={(event) => {
                  this.setState({ first: event.target.value });
                }}
              />
              <TextField
                disabled={!this.state.accepted}
                style={{ flex: 1, margin: '0 5px', color: this.state.formBottom ? colors.primaryColor : '#D8D8D8' }}
                inputProps={{ style: { marginLeft: '20px', fontSize: '16px' } }}
                label="Middle Name"
                value={this.state.middle}
                onChange={(event) => {
                  this.setState({ middle: event.target.value });
                }}
              />
            </div>
            <TextField
              disabled={!this.state.accepted}
              style={{ margin: '20px 5px', color: this.state.formBottom ? colors.primaryColor : '#D8D8D8' }}
              inputProps={{ style: { marginLeft: '20px', fontSize: '16px' } }}
              label="Last Name"
              value={this.state.last}
              onChange={(event) => {
                this.setState({ last: event.target.value });
              }}
            />
          </div>
          <div style={baseStyles.center}>
            <Continue
              text="Accept"
              btnStyle={buttonColor ? baseStyles.button : baseStyles.buttonDisabled}
              onClick={this.onSubmission}
            />
          </div>
        </div>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state;
  return { user };
}

JohnsHopkinsConsent1.propTypes = {
  user: PropTypes.object.isRequired,
  updatePRO: PropTypes.func.isRequired,
  submitPRO: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { submitPRO, updatePRO })(withStyles(classes)(JohnsHopkinsConsent1));
