import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import AppBar from '../components/app-bar';
import Page from './page';
import QuestionLayout from '../layouts/question';
import YesNoButtonSet from '../components/yes-no-button-set';
import { colors } from '../lib/styles';
import { updatePRO } from '../state/pro-forms';
import { Lifting } from '../lib/icons';

const styles = {
  answerButtonContainer: {
    display: 'flex',
    marginLeft: -10,
    marginTop: 8,
  },
  bar: {
    background: colors.lightBrightBlue,
  },
  contentWrapper: {
    flexGrow: 1,
    overflowX: 'hidden',
    position: 'relative',
  },
  img: {
    height: '100%',
    objectFit: 'contain',
  },
  imageWrapper: {
    height: 100,
    padding: '15px 60px',
    textAlign: 'center',
  },
  instructions: {
    color: 'grey',
    fontSize: '1.5rem',
    margin: '20px 40px 0px 40px',
    textAlign: 'center',
  },
  questionnaireContainer: {
    paddingLeft: 20,
    paddingRight: 20,
  },
  breathingText: {
    fontSize: '2rem',
    fontWeight: 'bold',
  },
  questionText: {
    fontSize: '2rem',
    marginTop: 25,
  },
  continueButtonWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 80,
  },
  inputField: {
    width: '6rem',
  },
};

class ThoracicCancerSymptoms7 extends Page {
  constructor(props) {
    super(props);

    this.state = {
      newWeakness: null,
      falls: null,
    };
  }

  handleChangeNewWeakness = (selected) => {
    this.setState({ newWeakness: selected });
  };

  handleChangeFalls = (selected) => {
    this.setState({ falls: selected });
  };

  handleClickNext = () => {
    const { newWeakness, falls } = this.state;
    this.props.updatePRO({
      type: 'thoracicCancerSymptoms',
      position: this.getTrackIndex(),
      value: { newWeakness, falls },
    });
    this.forwardWithQuery(this.props.location.query);
  };

  render() {
    const { classes } = this.props;
    const { newWeakness, falls } = this.state;

    const continueDisabled = newWeakness === null || falls === null;

    return (
      <QuestionLayout
        primaryBtnDisabled={continueDisabled}
        primaryBtnLabel="Next"
        primaryBtnOnTouchTap={this.handleClickNext}
        style={{ backgroundColor: colors.questionBackground }}
      >
        <AppBar
          headerNode="Weakness"
          backButtonOnClick={() => this.props.router.goBack()}
        />
        <LinearProgress
          variant="determinate"
          value={(this.getCurrentPage() / this.getTotalPages()) * 100}
          classes={{ bar: this.props.classes.bar }}
        />
        <div className={classes.contentWrapper}>
          <div className={classes.instructions}>
            Please select the option that best answers the question relating to weakness
          </div>
          <div className={classes.imageWrapper}>
            <Lifting />
          </div>
          <div className={classes.questionnaireContainer}>
            <div className={classes.breathingText}>Weakness</div>
            <YesNoButtonSet
              isNo={newWeakness === false}
              isYes={newWeakness}
              onClickNo={() => this.handleChangeNewWeakness(false)}
              onClickYes={() => this.handleChangeNewWeakness(true)}
              questionText="Do you have any new weakness in your arms or legs?"
            />
            <YesNoButtonSet
              isNo={falls === false}
              isYes={falls}
              onClickNo={() => this.handleChangeFalls(false)}
              onClickYes={() => this.handleChangeFalls(true)}
              questionText="Have you had any falls?"
            />
          </div>
        </div>
      </QuestionLayout>
    );
  }
}

export default connect(null, { updatePRO })(withStyles(styles)(ThoracicCancerSymptoms7));
