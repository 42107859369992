import React from 'react';
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  LinearProgress,
} from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
// import moment from 'moment';

import AppBar from '../components/app-bar';
import HealthSliderHorizontal from '../components/health-slider-horizontal';
import Page from './page';
import QuestionLayout from '../layouts/question';
import i18nTranslator, { title, subtitle, instructions, nextBtn, options } from '../lib/i18next';
import { colors } from '../lib/styles';
import { submitPRO, updatePRO } from '../state/pro-forms';
import { ClipboardListSolid } from '../lib/icons';
// import { apiFetch } from '../lib/fetch';

const styles = {
  boldText: {
    fontSize: '2rem',
    fontWeight: 'bold',
  },
  checkbox: {
    paddingTop: 3,
  },
  contentWrapper: {
    flexGrow: 1,
    overflowX: 'hidden',
    position: 'relative',
  },
  formControlLabel: {
    alignItems: 'start',
  },
  imgRow: {
    display: 'flex',
    justifyContent: 'center',
    padding: '15px 60px',
  },
  imageWrapper: {
    width: 100,
  },
  marginTop20: {
    marginTop: 20,
  },
  painScaleText: {
    fontSize: '1.5rem',
    marginTop: 10,
  },
  painScaleWrapper: {
    marginBottom: 30,
    marginTop: 50,
  },
  questionnaireContainer: {
    paddingLeft: 20,
    paddingRight: 20,
  },
  smallText: {
    fontSize: '1.5rem',
  },
  text: {
    fontSize: '2rem',
  },
  textFieldRoot: {
    marginTop: 10,
    width: 256,
    '& input': {
      fontSize: 18,
    },
    '& label': {
      fontSize: 18,
    },
  },
};

class WoundCasaColina4 extends Page {
  constructor(props) {
    super(props);

    this.state = {
      discharge: false,
      redness: false,
      none: false,
      painLevel: 0,
    };
  }

  handleChangeAnotherSymptom = (e) => {
    this.setState({ otherSymptom: e.target.value });
  }

  handleChangePainLevel = ({ value }) => {
    this.setState({ painLevel: value });
  }

  handleChangeStymptoms = (e) => {
    const { name, checked } = e.target;
    this.setState({ [name]: checked });

    if (name === 'none' && checked === true) {
      this.setState({
        discharge: false,
        redness: false,
      });
    } else if (name !== 'none' && checked === true) {
      this.setState({
        none: false,
      });
    }
  };

  handleClickNext = () => {
    const {
      location,
      submitPRO,
      user,
      woundCasaColina,
    } = this.props;
    const { id } = location.query;
    const {
      discharge,
      redness,
      none,
      painLevel,
    } = this.state;

    const woundSymptoms = [];

    if (discharge) woundSymptoms.push('Discharge/Puss');
    if (redness) woundSymptoms.push('Redness around the wound');
    if (none) woundSymptoms.push('None');

    const data = [woundCasaColina[0], { painLevel, woundSymptoms }];

    // const wounds = woundCasaColina[0];
    // const images = woundCasaColina[1];

    // wounds.forEach((wound, index) => {
    //   const formData = new FormData();

    //   formData.append('image', images[index]);
    //   formData.append('image_name', `patient_image_${moment().format('MMDDYYYY_HHmmss')}_${index + 1}_${index + 1}_${user.id}`);
    //   formData.append('primary_location', wound.region);
    //   formData.append('secondary_location', wound.bodyPart);
    //   formData.append('tertiary_location', wound.location);

    //   apiFetch(`/users/${user.id}/wounds`, {
    //     method: 'POST',
    //     body: formData,
    //   });
    // });

    submitPRO(
      { userId: user.id, id },
      {
        pro_data: { data },
        pro_type: 'WOUND-CASA-COLINA',
      },
      user,
    )
      .then(() => {
        this.forwardWithQuery(location.query);
      });
  }

  render() {
    const { classes } = this.props;
    const {
      discharge,
      redness,
      none,
      painLevel,
    } = this.state;

    return (
      <QuestionLayout
        primaryBtnLabel={nextBtn()}
        primaryBtnOnTouchTap={this.handleClickNext}
        primaryBtnDisabled={discharge === false && redness === false && none === false}
        style={{ backgroundColor: colors.questionBackground }}
      >
        <AppBar
          headerNode={title('woundCasaColina4')}
          backButtonOnClick={() => this.props.router.goBack()}
        />
        <LinearProgress
          variant="determinate"
          value={(this.getCurrentPage() / this.getTotalPages()) * 100}
          classes={{ bar: classes.bar }}
        />
        <div className={classes.contentWrapper}>
          <div className={classes.imgRow}>
            <div className={classes.imageWrapper}>
              <ClipboardListSolid />
            </div>
          </div>
          <div className={classes.questionnaireContainer}>
            <div className={classes.boldText}>{subtitle('woundCasaColina4')}</div>
            <div className={classes.painScaleText}>
              {instructions('woundCasaColina4')}
            </div>
            <div className={classes.painScaleWrapper}>
              <HealthSliderHorizontal
                value={painLevel}
                onChange={this.handleChangePainLevel}
                max={10}
                min={0}
                trackIndex={this.getTrackIndex()}
                type="hypertensionAssessment"
                sliderLeftLabel={options('noPain', 'woundCasaColina4')}
                sliderRightLabel={options('worstPain', 'woundCasaColina4')}
              />
            </div>
            <div className={classes.text}>{i18nTranslator('instructions2', 'woundCasaColina4')}</div>
            <div className={classes.smallText}>({i18nTranslator('instructions3', 'woundCasaColina4')})</div>
            <FormGroup className={classes.marginTop20}>
              <FormControlLabel
                control={(
                  <Checkbox
                    checked={discharge}
                    onChange={this.handleChangeStymptoms}
                    name="discharge"
                    icon={<CheckBoxOutlineBlankIcon fontSize="large" />}
                    checkedIcon={<CheckBoxIcon fontSize="large" />}
                    className={classes.checkbox}
                  />
                )}
                label={options('discharge', 'woundCasaColina4')}
                classes={{
                  label: classes.text,
                  root: classes.formControlLabel,
                }}
              />
              <FormControlLabel
                control={(
                  <Checkbox
                    checked={redness}
                    onChange={this.handleChangeStymptoms}
                    name="redness"
                    icon={<CheckBoxOutlineBlankIcon fontSize="large" />}
                    checkedIcon={<CheckBoxIcon fontSize="large" />}
                    className={classes.checkbox}
                  />
                )}
                label={options('redness', 'woundCasaColina4')}
                classes={{
                  label: classes.text,
                  root: classes.formControlLabel,
                }}
              />
              <FormControlLabel
                control={(
                  <Checkbox
                    checked={none}
                    onChange={this.handleChangeStymptoms}
                    name="none"
                    icon={<CheckBoxOutlineBlankIcon fontSize="large" />}
                    checkedIcon={<CheckBoxIcon fontSize="large" />}
                    className={classes.checkbox}
                  />
                )}
                label={options('none', 'woundCasaColina4')}
                classes={{
                  label: classes.text,
                  root: classes.formControlLabel,
                }}
              />
            </FormGroup>
          </div>
        </div>
      </QuestionLayout>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    proForms: {
      woundCasaColina,
    },
    user,
  } = state;

  return { user, woundCasaColina };
};

export default connect(mapStateToProps, { submitPRO, updatePRO })(withStyles(styles)(WoundCasaColina4));
