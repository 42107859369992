/* eslint-disable no-bitwise */
import { fromHexString, toLE16, fletcher } from './bits';
import { VSStatus, VSBattery, VSVitals, VSSecondaryVitals, VSCalibrationData, VSCuffData, VSUnknown } from './vitalstream-packets';

export class VSError extends Error {
  constructor(message, cause) {
    super(message);
    this.name = 'VSError';
    if (cause) {
      this.cause = cause;
    }
  }

  toString() {
    let errorString = super.toString();
    if (this.cause) {
      errorString += ' : ' + this.cause.toString();
    }
    return errorString;
  }
}

// head  len ty size           status     time  crc
// ff88 0f00 00 0c00 012018008c092000 06851800 36dd
function vsEvent(evt, readerClass) {
  const view = evt.target.value;
  const notificationVal = new Uint8Array(view.buffer);
  let buffer = notificationVal;

  const header = toLE16(buffer.slice(0, 2));
  if (header !== 0x88ff) {
    // either an unknown packet type or a fragment of a packet, try to concat them
    buffer = new Uint8Array([...readerClass.lastBuffer, ...buffer]);
  }

  const len = toLE16(buffer.slice(2, 4));

  if (buffer.length === (len + 6)) {
    const crc = toLE16(buffer.slice(-2));
    let packet;
    let error;

    if (fletcher(buffer.slice(0, -2)) === crc) {
      const type = buffer[4];
      switch (type) {
        case 0x0:
          try {
            packet = new VSStatus(buffer.slice(5, -2));
          } catch (e) {
            error = new VSError('VSStatus packet poorly formed');
            error.code = 910;
          }
          break;
        case 0x2:
          try {
            packet = new VSSecondaryVitals();
          } catch (e) {
            error = new VSError('VSSecondaryVitals packet poorly formed');
            error.code = 910;
          }
          break;
        case 0x3:
          try {
            packet = new VSBattery(buffer.slice(5, -2));
          } catch (e) {
            error = new VSError('VSBattery packet poorly formed');
            error.code = 910;
          }
          break;
        case 0x7:
          try {
            packet = new VSVitals(buffer.slice(5, -2));
          } catch (e) {
            error = new VSError('VSVitals packet poorly formed');
            error.code = 910;
          }
          break;
        case 0x8:
          packet = new VSCuffData();
          break;
        case 0x09:
          packet = new VSCalibrationData();
          break;
        default:
          packet = new VSUnknown(buffer.slice(5, -2), type);
      }
    } else {
      error = new VSError('bad crc');
      error.code = 911;
    }

    // only handling packets of 2 incoming frames currently, so wipe out assuming it was valid or bad data
    readerClass.lastBuffer = new Uint8Array();

    if (readerClass.intermediateFn) {
      return readerClass.intermediateFn({ packet, rawPacket: buffer }, error);
    }
  } else {
    // header intact, might be a partial, save for later
    readerClass.lastBuffer = notificationVal;
  }
}

export class VSReader {
  lastBuffer;

  intermediateFn;

  device;

  server;

  service;

  streamDataChar;

  applicationSettingsChar;

  startInternalCalibration;

  applicationSettingsValueChar;

  streamControlChar;

  startBPCalibrationChar;

  firmwareVersionChar;

  displayUpdateTimeChar;

  readPulseWaveformChar;

  initateDisconnectChar;

  initResponse = {};

  // throws bluetooth errors
  constructor() {
    return (async () => {
      try {
        // throws if couldnt get bluetooth peripheral? perhaps no ble present or permissions problem
        this.device = await navigator.bluetooth.requestDevice({
          filters: [
            {
              namePrefix: ['Vitalstream'],
              services: ['7f568682-f7e7-4efa-abad-eee88c61b6e4'],
            },
          ],
        });

        this.device.addEventListener('gattserverdisconnected', this._disconnected.bind(this));

        // throws if couldnt connect, perhaps low battery or device too far
        this.server = await this.device.gatt.connect();

        // these throw if couldnt talk to device during initial setup
        this.service = await this.server.getPrimaryService('7f568682-f7e7-4efa-abad-eee88c61b6e4');

        this.applicationSettingsChar = await this.service.getCharacteristic('00000034-a2b1-11e4-89d3-123b93f75cba');
        this.applicationSettingsValueChar = await this.service.getCharacteristic('00000035-a2b1-11e4-89d3-123b93f75cba');
        this.streamControlChar = await this.service.getCharacteristic('00000010-a2b1-11e4-89d3-123b93f75cba');
        this.streamDataChar = await this.service.getCharacteristic('0000000b-a2b1-11e4-89d3-123b93f75cba');
        this.firmwareVersionChar = await this.service.getCharacteristic('00000002-a2b1-11e4-89d3-123b93f75cba');
        this.startInternalCalibration = await this.service.getCharacteristic('00000012-a2b1-11e4-89d3-123b93f75cba');
        this.displayUpdateTimeChar = await this.service.getCharacteristic('00000026-a2b1-11e4-89d3-123b93f75cba');
        this.startBPCalibrationChar = await this.service.getCharacteristic('00000018-a2b1-11e4-89d3-123b93f75cba');
        this.readPulseWaveformChar = await this.service.getCharacteristic('00000032-a2b1-11e4-89d3-123b93f75cba');
        this.initateDisconnectChar = await this.service.getCharacteristic('0000000c-a2b1-11e4-89d3-123b93f75cba');

        await this.applicationSettingsChar.writeValue(fromHexString('ff00'));
        let value = await this.applicationSettingsValueChar.readValue();
        this.initResponse.serial = new Uint8Array(value.buffer);

        await this.applicationSettingsChar.writeValue(fromHexString('0501'));
        value = await this.applicationSettingsValueChar.readValue();
        this.initResponse.mask = new Uint8Array(value.buffer);

        await this.applicationSettingsChar.writeValue(fromHexString('0101'));
        value = await this.applicationSettingsValueChar.readValue();
        this.initResponse.mac = new Uint8Array(value.buffer);

        await this.applicationSettingsChar.writeValue(fromHexString('0001'));
        value = await this.applicationSettingsValueChar.readValue();
        this.initResponse.ip = new Uint8Array(value.buffer);

        value = await this.displayUpdateTimeChar.readValue();
        this.initResponse.displayUpdateTime = new Uint8Array(value.buffer);

        value = await this.firmwareVersionChar.readValue();
        this.initResponse.firmwareVersion = new Uint8Array(value.buffer);

        await this.applicationSettingsChar.writeValue(fromHexString('0200'));
        value = await this.applicationSettingsValueChar.readValue();
        this.initResponse.recalibrationInterval = new Uint8Array(value.buffer);

        // select what types of packets to stream, doesnt have an effect?
        await this.streamControlChar.writeValue(fromHexString('e803dd03'));
      } catch (e) {
        const error = new VSError("Couldn't connect", e);
        error.code = 902;
        throw error;
      }
      return this;
    })();
  }

  async autocalibrate() {
    if (this.device && this.device.gatt && this.device.gatt.connected) {
      // select posture attribute 0500
      await this.applicationSettingsChar.writeValue(fromHexString('0500'));
      // sitting = 01, reclining = 02, supine = 03, standing = 05
      await this.applicationSettingsValueChar.writeValue(fromHexString('0100'));

      await this.startInternalCalibration.writeValue(fromHexString('01'));
    } else {
      throw new VSError('Not connected');
    }
  }

  async recalibrate() {
    if (this.device && this.device.gatt && this.device.gatt.connected) {
      await this.startBPCalibrationChar.writeValue(fromHexString('00'));
    } else {
      throw new VSError('Not connected');
    }
  }

  async stream(intermediateFn) {
    if (this.streamDataChar && this.device && this.device.gatt && this.device.gatt.connected) {
      // if we have a live connection from somewhere, try to shut it down first
      if (typeof this.intermediateFn !== 'undefined') {
        await this.stopStreaming();
      }

      this.lastBuffer = new Uint8Array();
      this.intermediateFn = intermediateFn;
      await this.streamDataChar.startNotifications();
      this.streamDataChar.addEventListener('characteristicvaluechanged', (evt) => { vsEvent(evt, this); });

      // seem to need to read or well be disconnected, changing streamcontrol to
      // remove pulse streams doesnt have effect?
      this.keepalive = setInterval(async () => {
        if (this.readPulseWaveformChar && this.device && this.device.gatt && this.device.gatt.connected) {
          // checking for connected isnt good enough, it can still fail between the check and the readvalue
          try {
            await this.readPulseWaveformChar.readValue();
          } catch (e) { console.log(e); }
        }
      }, 1000);
    } else {
      throw new VSError('Not connected');
    }
  }

  async stopStreaming() {
    clearInterval(this.keepalive);

    if (this.streamDataChar) {
      this.streamDataChar.removeEventListener('characteristicvaluechanged', (evt) => { vsEvent(evt, this); });

      // DOMException: Failed to execute 'stopNotifications' on 'BluetoothRemoteGATTCharacteristic': GATT Server is disconnected. Cannot perform GATT operations. (Re)connect first with `device.gatt.connect`.
      // uncatchable? just guard
      if (this.device && this.device.gatt && this.device.gatt.connected) {
        try {
          await this.streamDataChar.stopNotifications();
        } catch (e) {
          // stopNotifications finicky and also not in firefox
        }
      }
    }
    this.intermediateFn = undefined;
  }

  async _disconnected() {
    if (this.intermediateFn) {
      const error = new VSError('Device Disconnected');
      error.code = 901;
      this.intermediateFn(null, error);
    }

    await this.stopStreaming();

    if (this.device) {
      this.device.removeEventListener('gattserverdisconnected', this.disconnect.bind(this));
    }
  }

  async disconnect() {
    if (this.device && this.device.gatt && this.device.gatt.connected) {
      try {
        await this.startInternalCalibration.writeValue(fromHexString('00'));

        await this.initateDisconnectChar.writeValue(fromHexString('00'));

        // it should do it itself? but if it doesnt we will
        this.device.gatt.disconnect();
      } catch (e) {
        console.log(e);
      }
    }
  }
}
