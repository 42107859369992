import React from 'react';
import { connect } from 'react-redux';
import Page from './page';

import AppBar from '../components/app-bar';
import Continue from '../components/continue';
import BottomNavigation from '../components/bottom-navigation';
import { fetchUserReport, updateAvatarUrl } from '../state/user';

import { colors, mainDashboard } from '../lib/styles';

const baseStyles = {
  painButton: {
    backgroundColor: colors.primaryGreen,
    backgroundPosition: '0.75rem',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    textAlign: 'center',
    marginTop: '20px',
  },
  btns: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    marginTop: '5rem',
  },
  question: {
    color: colors.primaryColor,
    fontSize: '2.75rem',
    width: '25rem',
    margin: '0 auto',
  },
};


class PainType extends Page {
  onClickBackButton = () => {
    this.backward();
  }

  render() {
    return (
      <div style={mainDashboard.container}>
        <AppBar
          backButtonOnClick={() => this.props.router.goBack()}
          headerNode="Pain Report"
        />
        <section style={baseStyles.content}>
          <div style={baseStyles.question}>What type of pain are you reporting?</div>
          <div style={baseStyles.btns}>
            <Continue
              text="Report Headache Pain"
              btnStyle={{ ...baseStyles.painButton, backgroundImage: 'url("/img/MigraineTracker-black.png")' }}
              onClick={() => this.setTrack('MIGRAINES')}
            />
            <Continue
              text="Report Body Pain"
              btnStyle={{ ...baseStyles.painButton, backgroundImage: 'url("/img/Pain-black.png")' }}
              onClick={() => this.props.router.push('/carousel2')}
            />
          </div>
        </section>
        <BottomNavigation
          router={this.props.router}
          highlightedIcon="none"
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state;
  return { user };
}

export default connect(mapStateToProps, {
  updateAvatarUrl,
  fetchUserReport,
})(PainType);
