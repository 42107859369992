import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';

import Continue from './continue';
import { submitPRO } from '../state/pro-forms';
import { colors, fontSizing } from '../lib/styles';
import HttpErrorScreen from './http-error-screen';
import { setErrorScreenData } from '../state/error-screens';
import {
  BloodPressure,
  CircleExclamationSolid,
  HeartBeat,
  Lungs,
  Spo2,
  VitalCoreLoadingError,
} from '../lib/icons';

const primaryBlue = '#0c63aa';

const styles = {
  bodyText: {
    fontSize: fontSizing.body,
  },
  boldText: {
    fontWeight: 'bold',
  },
  continueButtonWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  errorIconInnerWrapper: {
    width: 100,
  },
  errorIconRedWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 25,
    marginBottom: 25,
    '& path': {
      fill: colors.errorRed,
    },
  },
  errorIconWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 25,
    marginBottom: 25,
  },
  flexGrowOne: {
    flexGrow: 1,
  },
  icon: {
    objectFit: 'contain',
    width: 25,
    height: 25,
  },
  iconWrapper: {
    alignItems: 'center',
    boxShadow: '0 0 8px lightgrey',
    borderRadius: '50%',
    display: 'flex',
    height: 50,
    justifyContent: 'center',
    width: 50,
  },
  linearProgress: {
    background: colors.lightBrightBlue,
  },
  list: {
    listStyleType: "''",
    marginBottom: 0,
    marginTop: 0,
    paddingLeft: 0,
  },
  marginBottom0: {
    marginBottom: 0,
  },
  page: {
    background: colors.questionBackground,
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    fontSize: fontSizing.body,
    height: '100%',
    paddingTop: 100,
  },
  text: {
    fontSize: fontSizing.body,
    padding: '0 20px',
    textAlign: 'center',
  },
  textCentered: {
    textAlign: 'center',
  },
  textNotCentered: {
    fontSize: fontSizing.body,
    padding: '0 20px',
  },
  vitalMeasurementContainer: {
    boxShadow: '0 0 8px lightgrey',
    display: 'flex',
    marginBottom: 10,
    marginTop: 10,
    marginLeft: 20,
    marginRight: 20,
    padding: 5,
    alignItems: 'flex-end',
  },
  vitalMeasurementTitleContainer: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: fontSizing.body,
    width: 150,
    marginLeft: 10,
  },
  vitalMeasurementValue: {
    fontSize: fontSizing.h1,
  },
  vitalMeasurementUnit: {
    fontSize: fontSizing.small,
    lineHeight: 1.6,
    marginLeft: 5,
  },
};

const VitalsValueDisplay = ({
  classes,
  color,
  icon: Icon,
  labelOne,
  labelTwo,
  units,
  value,
}) => (
  <Paper className={classes.vitalMeasurementContainer} style={{ color }}>
    <Paper className={classes.iconWrapper}>
      <Icon className={classes.icon} style={{ color }} />
    </Paper>
    <div className={classes.vitalMeasurementTitleContainer}>
      <div>{labelOne}</div>
      <div>{labelTwo}</div>
    </div>
    <div className={classes.vitalMeasurementValue}>{value}</div>
    <div className={classes.vitalMeasurementUnit}>{units}</div>
  </Paper>
);
VitalsValueDisplay.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  labelOne: PropTypes.string.isRequired,
  labelTwo: PropTypes.string.isRequired,
  units: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};
const VitalsValueDisplayStyled = withStyles(styles)(VitalsValueDisplay);

const LightingAndMovementErrorMessage = ({ classes }) => (
  <div>
    <div className={classes.textCentered}>
      <i>We are unable to measure your vitals because of a combination of movement and lighting errors.</i>
    </div>
    <p className={`${classes.boldText} ${classes.marginBottom0}`}>Please ensure the following:</p>
    <ul className={classes.list}>
      <li>- Hold the phone still</li>
      <li>- Face is well lit</li>
      <li>- Face is within the guide box</li>
    </ul>
  </div>
);
LightingAndMovementErrorMessage.propTypes = {
  classes: PropTypes.object.isRequired,
};
const LightingAndMovementErrorMessageStyled = withStyles(styles)(LightingAndMovementErrorMessage);

const dataStatusMessageMap = {
  'C-FDM-043': <i>Looks like we encountered an error and need to reload Informed Vital Core again.</i>,
  'C-GEN-003': <i>Looks like we encountered an error and need to reload Informed Vital Core again.</i>,
  'C-HRT-064': <i>Looks like we encountered an error and need to reload Informed Vital Core again.</i>,
  'W-HRT-062': <LightingAndMovementErrorMessageStyled />,
  'W-HRT-063': <LightingAndMovementErrorMessageStyled />,
};

const dataStatusInstructionsMap = {
  'C-FDM-043': <>Please tap <strong>RETRY.</strong></>,
  'C-GEN-003': <>Please tap <strong>RETRY.</strong></>,
  'C-HRT-064': <>Please tap <strong>RETRY.</strong></>,
  'W-HRT-062': <>and then tap <strong>RETRY.</strong></>,
  'W-HRT-063': <>and then tap <strong>RETRY.</strong></>,
};

const dataStatusHeaderMap = {
  'C-FDM-043': 'Loading Failure',
  'C-GEN-003': 'Loading Failure',
  'C-HRT-064': 'Loading Failure',
  'W-HRT-062': 'Lighting and Movement Error',
  'W-HRT-063': 'Lighting and Movement Error',
};

const dataStatusPageTitleMap = {
  'C-FDM-043': 'IVC Error',
  'C-GEN-003': 'IVC Error',
  'C-HRT-064': 'IVC Error',
  'W-HRT-062': 'Error',
  'W-HRT-063': 'Error',
};

const processResults = (results) => {
  const {
    shouldRestart,
    vitalsMeasurements,
    errorCode,
  } = results;
  const [{ HR }] = vitalsMeasurements;

  let headerText = '';
  let messageText = '';
  let pageTitle = 'Vital Results';
  let instructionsText = 'Please tap Retry';
  let vitalsOutOfRange = false;
  let displayResults = false;
  let actionType = 'continue';

  const noResults = HR <= 0;
  const resultsOutOfRange = HR < 40 || HR > 120;

  if (shouldRestart) {
    headerText = dataStatusHeaderMap[errorCode];
    messageText = dataStatusMessageMap[errorCode];
    pageTitle = dataStatusPageTitleMap[errorCode];
    instructionsText = dataStatusInstructionsMap[errorCode];
    actionType = 'restart';
  } else if (noResults) {
    headerText = 'Unable to Measure Vitals';
    messageText = <i>Informed Vital Core is unable to measure your vitals at this time.</i>;
    instructionsText = <>Please tap <strong>RETRY.</strong></>;
    actionType = 'restart';
  } else if (resultsOutOfRange) {
    vitalsOutOfRange = true;
    messageText = "Your measures appear outside of normal range. Let's double check your measures.";
    instructionsText = <>Please tap the <strong>RETAKE VITALS</strong> button.</>;
    actionType = 'restart';
    displayResults = true;
  } else {
    displayResults = true;
  }

  return {
    displayResults,
    headerText,
    instructionsText,
    messageText,
    pageTitle,
    actionType,
    vitalsOutOfRange,
  };
};

class IvcResults extends React.Component {
  state = {
    showErrorScreen: false,
  }

  render() {
    const {
      classes,
      criticalErrorCount,
      sessionCount,
      shouldRestart,
      vitalsMeasurements,
      errorCode,
      onRestart,
      onSubmit,
    } = this.props;
    const { showErrorScreen } = this.state;

    const {
      displayResults,
      headerText,
      instructionsText,
      messageText,
      actionType,
      vitalsOutOfRange,
    } = processResults({
      criticalErrorCount,
      sessionCount,
      shouldRestart,
      vitalsMeasurements,
      errorCode,
    });
    const [{
      BP_DIA,
      BP_SYS,
      BR,
      HR,
      SPO2,
    }] = vitalsMeasurements;

    let errorIcon = (
      <div className={classes.errorIconRedWrapper}>
        <div className={classes.errorIconInnerWrapper}>
          <CircleExclamationSolid />
        </div>
      </div>
    );

    if (errorCode && errorCode[0] === 'C') {
      errorIcon = (
        <div className={classes.errorIconWrapper}>
          <div className={classes.errorIconInnerWrapper}>
            <VitalCoreLoadingError />
          </div>
        </div>
      );
    }

    const textContentClass = (errorCode && errorCode[0] === 'W') ? classes.textNotCentered : classes.text;

    return (
      <>
        {showErrorScreen ? (
          <HttpErrorScreen onClickRetry={this.handleSubmission} vitalCorePageTitle="Vital Results" />
        ) : (
          <div className={classes.page}>
            <div className={classes.flexGrowOne}>
              {displayResults ? (
                <>
                  <VitalsValueDisplayStyled
                    color={colors.healthyRed}
                    icon={HeartBeat}
                    labelOne="Pulse"
                    labelTwo="Rate"
                    units="bpm"
                    value={HR}
                  />
                  <VitalsValueDisplayStyled
                    color={colors.healthyRed}
                    icon={BloodPressure}
                    labelOne="Blood"
                    labelTwo="Pressure"
                    units="mm/Hg"
                    value={`${BP_SYS}/${BP_DIA}`}
                  />
                  <VitalsValueDisplayStyled
                    color={primaryBlue}
                    icon={Lungs}
                    labelOne="Breathing"
                    labelTwo="Rate"
                    units="bpm"
                    value={BR}
                  />
                  <VitalsValueDisplayStyled
                    color={primaryBlue}
                    icon={Spo2}
                    labelOne="Oxygen"
                    labelTwo="Saturation"
                    units="SpO2"
                    value={`${SPO2}%`}
                  />
                  {vitalsOutOfRange ? (
                    <>
                      <p className={classes.text}>{messageText}</p>
                      <p className={classes.text}>{instructionsText}</p>
                    </>
                  ) : null}
                </>
              ) : (
                <>
                  {errorIcon}
                  <p className={`${classes.text} ${classes.boldText}`}>{headerText}</p>
                  <p className={textContentClass}>{messageText}</p>
                  <p className={textContentClass}>{instructionsText}</p>
                </>
              )}
            </div>
            <div>
              {displayResults && vitalsOutOfRange ? (
                <div className={classes.continueButtonWrapper}>
                  <Continue
                    text="Retake Vitals"
                    onClick={onRestart}
                    btnStyle={{ marginBottom: 20, marginTop: '20px', alignSelf: 'center' }}
                  />
                </div>
              ) : null}
              {displayResults && !vitalsOutOfRange ? (
                <div className={classes.continueButtonWrapper}>
                  <Continue
                    text="Submit Results"
                    onClick={onSubmit}
                    btnStyle={{ marginBottom: 20, marginTop: '20px', alignSelf: 'center' }}
                  />
                </div>
              ) : null}
              {!displayResults && actionType === 'restart' ? (
                <div className={classes.continueButtonWrapper}>
                  <Continue
                    text="Retry"
                    onClick={onRestart}
                    btnStyle={{ marginBottom: 20, marginTop: '20px', alignSelf: 'center' }}
                  />
                </div>
              ) : null}
            </div>
          </div>
        )}
      </>
    );
  }
}

IvcResults.propTypes = {
  classes: PropTypes.object.isRequired,
  criticalErrorCount: PropTypes.number.isRequired,
  sessionCount: PropTypes.number.isRequired,
  shouldRestart: PropTypes.bool.isRequired,
  vitalsMeasurements: PropTypes.array.isRequired,
  errorCode: PropTypes.string.isRequired,
  onRestart: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { user } = state;

  return { user };
};

export default connect(mapStateToProps, { setErrorScreenData, submitPRO })(withStyles(styles)(IvcResults));
