import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';
import { connect } from 'react-redux';

import Page from './page';
import AppBar from '../components/app-bar';
import IconWrapper from '../components/icon-wrapper';
import BloomkidzLayout from '../layouts/bloomkidz';
import { ClipboardListSolid } from '../lib/icons';
import { updatePRO } from '../state/pro-forms';
import { fontSizing } from '../lib/styles';
import { bloomkidzStyles, bloomkidzStylesObj } from '../lib/bloomkidz';

const styles = {
  ...bloomkidzStylesObj,
  marginTop20: {
    marginTop: 20,
  },
};

class BloomkidzMedicalHistory5 extends Page {
  constructor(props) {
    super(props);

    const stateFromRedux = props.bloomkidzMedicalHistory[this.getTrackIndex()] || {};

    this.state = {
      illness: '',
      visionHearing: '',
      visionHearingExplanation: '',
      sleep: '',
      diet: '',
      allergies: '',
      allergiesExplanation: '',
      aversions: '',
      ...stateFromRedux,
    };
  }

  handleClickButton = (name, value) => {
    const explanation = {};
    if (value === 'no') {
      explanation[`${name}Explanation`] = '';
    }
    this.setState({ [name]: value, ...explanation });
  }

  handleContinue = () => {
    const { location, updatePRO } = this.props;

    updatePRO({
      type: 'bloomkidzMedicalHistory',
      position: this.getTrackIndex(),
      value: { ...this.state },
    });

    this.forwardWithQuery(location.query);
  }

  handleTextFieldChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  shouldDisableContinueButton = () => {
    const {
      illness,
      visionHearing,
      visionHearingExplanation,
      sleep,
      diet,
      allergies,
      allergiesExplanation,
      aversions,
    } = this.state;

    return !illness
      || !visionHearing
      || (visionHearing === 'yes' && !visionHearingExplanation)
      || !sleep
      || !diet
      || !allergies
      || (allergies === 'yes' && !allergiesExplanation)
      || !aversions;
  }

  render() {
    const { classes, router } = this.props;
    const { state } = this;
    const {
      illness,
      visionHearing,
      visionHearingExplanation,
      sleep,
      diet,
      allergies,
      allergiesExplanation,
      aversions,
    } = state;

    const continueButtonDisabled = this.shouldDisableContinueButton();

    return (
      <BloomkidzLayout
        primaryBtnOnTouchTap={this.handleContinue}
        primaryBtnLabel="Next"
        primaryBtnDisabled={continueButtonDisabled}
      >
        <AppBar
          headerNode="Medical History"
          backButtonOnClick={() => router.goBack()}
        />
        <IconWrapper>
          <ClipboardListSolid />
        </IconWrapper>
        <div className={classes.pageContent}>
          <div>List any serious illnesses/injuries your child has experienced.</div>
          <div className={classes.paddingHorizontal}>
            <div className={classes.textFieldWrapper}>
              <TextField
                placeholder="Tap here to type"
                value={illness}
                onChange={this.handleTextFieldChange}
                name="illness"
                variant="standard"
                fullWidth
                inputProps={{ style: { fontSize: fontSizing.body, fontFamily: bloomkidzStyles.fontFamily } }}
              />
            </div>
          </div>
          <div className={classes.marginTop20}>
            Does your child have difficulties with vision or hearing?
          </div>
          <div className={classes.buttonRow}>
            <button
              className={`${classes.button} ${visionHearing === 'yes' ? classes.buttonSelected : classes.buttonUnselected}`}
              onClick={() => this.handleClickButton('visionHearing', 'yes')}
              type="button"
            >
              Yes
            </button>
            <button
              className={`${classes.button} ${visionHearing === 'no' ? classes.buttonSelected : classes.buttonUnselected}`}
              onClick={() => this.handleClickButton('visionHearing', 'no')}
              type="button"
            >
              No
            </button>
          </div>
          {visionHearing === 'yes' ? (
            <div className={classes.textFieldWrapper}>
              <div>Please explain:</div>
              <TextField
                placeholder="Tap here to type"
                value={visionHearingExplanation}
                onChange={this.handleTextFieldChange}
                name="visionHearingExplanation"
                variant="standard"
                fullWidth
                inputProps={{ style: { fontSize: fontSizing.body, fontFamily: bloomkidzStyles.fontFamily } }}
              />
            </div>
          ) : null}
          <div className={classes.marginTop20}>Describe your child&apos;s sleep habits:</div>
          <div className={classes.paddingHorizontal}>
            <div className={classes.textFieldWrapper}>
              <TextField
                placeholder="Tap here to type"
                value={sleep}
                onChange={this.handleTextFieldChange}
                name="sleep"
                variant="standard"
                fullWidth
                inputProps={{ style: { fontSize: fontSizing.body, fontFamily: bloomkidzStyles.fontFamily } }}
              />
            </div>
          </div>
          <div className={classes.marginTop20}>Describe your child&apos;s diet/eating habits:</div>
          <div className={classes.paddingHorizontal}>
            <div className={classes.textFieldWrapper}>
              <TextField
                placeholder="Tap here to type"
                value={diet}
                onChange={this.handleTextFieldChange}
                name="diet"
                variant="standard"
                fullWidth
                inputProps={{ style: { fontSize: fontSizing.body, fontFamily: bloomkidzStyles.fontFamily } }}
              />
            </div>
          </div>
          <div className={classes.marginTop20}>
            Does your child have any food allergies?
          </div>
          <div className={classes.buttonRow}>
            <button
              className={`${classes.button} ${allergies === 'yes' ? classes.buttonSelected : classes.buttonUnselected}`}
              onClick={() => this.handleClickButton('allergies', 'yes')}
              type="button"
            >
              Yes
            </button>
            <button
              className={`${classes.button} ${allergies === 'no' ? classes.buttonSelected : classes.buttonUnselected}`}
              onClick={() => this.handleClickButton('allergies', 'no')}
              type="button"
            >
              No
            </button>
          </div>
          {allergies === 'yes' ? (
            <div className={classes.textFieldWrapper}>
              <div className={classes.marginTop20}>Please explain:</div>
              <TextField
                placeholder="Tap here to type"
                value={allergiesExplanation}
                onChange={this.handleTextFieldChange}
                name="allergiesExplanation"
                variant="standard"
                fullWidth
                inputProps={{ style: { fontSize: fontSizing.body, fontFamily: bloomkidzStyles.fontFamily } }}
              />
            </div>
          ) : null}
          <div className={classes.marginTop20}>Describe any aversions/sensitivities your child has:</div>
          <div className={classes.paddingHorizontal}>
            <div className={classes.textFieldWrapper}>
              <TextField
                placeholder="Tap here to type"
                value={aversions}
                onChange={this.handleTextFieldChange}
                name="aversions"
                variant="standard"
                fullWidth
                inputProps={{ style: { fontSize: fontSizing.body, fontFamily: bloomkidzStyles.fontFamily } }}
              />
            </div>
          </div>
        </div>
      </BloomkidzLayout>
    );
  }
}

BloomkidzMedicalHistory5.propTypes = {
  classes: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  updatePRO: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const {
    proForms: { bloomkidzMedicalHistory },
    user,
  } = state;

  return { user, bloomkidzMedicalHistory };
};

export default connect(mapStateToProps, { updatePRO })(withStyles(styles)(BloomkidzMedicalHistory5));
