import React from 'react';
import {
  LinearProgress,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Joi from 'joi-browser';

import AppBar from '../components/app-bar';
import Page from './page';
import QuestionLayout from '../layouts/question';
import i18nTranslator, { title, selectDefault, nextBtn } from '../lib/i18next';
import { colors } from '../lib/styles';
import { updatePRO } from '../state/pro-forms';

const styles = {
  label: {
    flexBasis: 0,
    flexGrow: 1,
    fontSize: 29,
    fontWeight: 'bold',
    paddingRight: 10,
  },
  marginRight10: {
    marginRight: 30,
  },
  marginTop20: {
    marginTop: 20,
  },
  personalInfoRow: {
    alignItems: 'center',
    background: 'white',
    borderTop: `2px solid ${colors.questionBackground}`,
    display: 'flex',
    paddingBottom: 2,
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 2,
  },
  select: {
    fontSize: 29,
    marginBottom: 15,
    marginTop: 15,
    minWidth: 120,
  },
  selectWrapper: {
    flexBasis: 0,
    flexGrow: 1,
    '& .MuiInput-underline:before': {
      borderBottom: 'none',
    },
  },
  textField: {
    '& input': {
      fontSize: 29,
    },
    '& label': {
      fontSize: 29,
    },
    '& .MuiFormHelperText-root': {
      fontSize: 21,
      lineHeight: 1.2,
    },
  },
  textFieldAddress: {
    marginTop: 5,
    '& label': {
      fontStyle: 'italic',
    },
  },
  textFieldHeight: {
    flexBasis: 0,
    flexGrow: 1,
    width: 60,
    '& .MuiInput-underline:before': {
      borderBottom: 'none',
    },
    '& label': {
      fontStyle: 'italic',
    },
  },
  textFieldPersonalInfo: {
    flexBasis: 0,
    flexGrow: 1,
    width: '13.5rem',
    '& .MuiInput-underline:before': {
      borderBottom: 'none',
    },
    '& label': {
      fontStyle: 'italic',
    },
  },
};

const demographicsSchema = Joi.object({
  height_feet: Joi.number().min(1).max(9),
  height_inches: Joi.number().min(0).max(12),
  postalCode: Joi.string().regex(/^[-0-9]*$/),
  state: Joi.string().length(2).regex(/^[a-zA-Z]*$/),
  weight: Joi.number().min(1).max(1500),
});

class PatientDemographics2 extends Page {
  constructor(props) {
    super(props);

    this.state = {
      gender: '',
      height_feet: '',
      height_inches: '',
      maritalStatus: '',
      postalCode: '',
      primaryLanguage: '',
      weight: '',
      errors: {
        height_feet: '',
        height_inches: '',
        postalCode: '',
        state: '',
        weight: '',
      },
    };
  }

  handleChangeValue = (e) => {
    const stateUpdate = { [e.target.name]: e.target.value };

    if (this.state.errors[e.target.name]) {
      stateUpdate.errors = {
        ...this.state.errors,
        [e.target.name]: '',
      };
    }

    this.setState(stateUpdate);
  };

  handleState = (e) => {
    const stateUpdate = { state: e.target.value };

    if (this.state.errors.state) {
      stateUpdate.errors = {
        ...this.state.errors,
        state: '',
      };
    }

    this.setState(stateUpdate);
  };

  handleClickNext = () => {
    const {
      height_feet,
      height_inches,
      postalCode,
      weight,
    } = this.state;

    const { error } = demographicsSchema.validate({
      height_feet,
      height_inches,
      postalCode,
      weight,
    }, { abortEarly: false });

    if (error) {
      const errors = {};

      error.details.forEach((error) => {
        errors[error.context.key] = 'Invalid value';
      });

      this.setState({ errors });
      return;
    }

    this.props.updatePRO({
      type: 'patientDemographics',
      position: this.getTrackIndex(),
      value: { ...this.state },
    });

    this.forwardWithQuery(this.props.location.query);
  }

  render() {
    const { classes } = this.props;
    const {
      errors,
      gender,
      height_feet,
      height_inches,
      maritalStatus,
      postalCode,
      primaryLanguage,
      weight,
    } = this.state;

    const continueDisabled = !gender
      || !height_feet
      || !height_inches
      || !maritalStatus
      || !postalCode
      || !primaryLanguage
      || !weight;

    return (
      <QuestionLayout
        primaryBtnLabel={nextBtn()}
        primaryBtnOnTouchTap={this.handleClickNext}
        primaryBtnDisabled={continueDisabled}
        style={{ backgroundColor: colors.questionBackground }}
      >
        <AppBar
          headerNode={title('demographics2')}
          backButtonOnClick={() => this.props.router.goBack()}
        />
        <LinearProgress
          variant="determinate"
          value={(this.getCurrentPage() / this.getTotalPages()) * 100}
          classes={{ bar: classes.bar }}
        />
        <div className={classes.marginTop20}>
          <div className={classes.personalInfoRow}>
            <div className={classes.label}>{i18nTranslator('gender', 'demographics2')}</div>
            <div className={classes.selectWrapper}>
              <Select
                value={gender}
                name="gender"
                onChange={this.handleChangeValue}
                displayEmpty
                className={classes.select}
              >
                <MenuItem value="" disabled><i>{selectDefault()}</i></MenuItem>
                <MenuItem value="Male">{i18nTranslator('genderOptions.male', 'demographics2')}</MenuItem>
                <MenuItem value="Female">{i18nTranslator('genderOptions.female', 'demographics2')}</MenuItem>
                <MenuItem value="Other">{i18nTranslator('genderOptions.other', 'demographics2')}</MenuItem>
              </Select>
            </div>
          </div>
          <div className={classes.personalInfoRow}>
            <div className={classes.label}>{i18nTranslator('language', 'demographics2')}</div>
            <div className={classes.selectWrapper}>
              <Select
                value={primaryLanguage}
                name="primaryLanguage"
                onChange={this.handleChangeValue}
                displayEmpty
                className={classes.select}
              >
                <MenuItem value="" disabled><i>{selectDefault()}</i></MenuItem>
                <MenuItem value="en">{i18nTranslator('languageOptions.english', 'demographics2')}</MenuItem>
                <MenuItem value="es">{i18nTranslator('languageOptions.spanish', 'demographics2')}</MenuItem>
              </Select>
            </div>
          </div>
          <div className={classes.personalInfoRow}>
            <div className={classes.label}>{i18nTranslator('marital', 'demographics2')}</div>
            <div className={classes.selectWrapper}>
              <Select
                value={maritalStatus}
                name="maritalStatus"
                onChange={this.handleChangeValue}
                displayEmpty
                className={classes.select}
              >
                <MenuItem value="" disabled><i>{selectDefault()}</i></MenuItem>
                <MenuItem value="Single">{i18nTranslator('maritalOptions.single', 'demographics2')}</MenuItem>
                <MenuItem value="Married">{i18nTranslator('maritalOptions.married', 'demographics2')}</MenuItem>
                <MenuItem value="Divorced">{i18nTranslator('maritalOptions.divorced', 'demographics2')}</MenuItem>
              </Select>
            </div>
          </div>
          <div className={classes.personalInfoRow}>
            <div className={classes.label}>{i18nTranslator('weight', 'demographics2')}</div>
            <TextField
              label={i18nTranslator('weightLabel', 'demographics2')}
              type="tel"
              onChange={this.handleChangeValue}
              classes={{ root: `${classes.textField} ${classes.textFieldPersonalInfo}` }}
              value={weight}
              name="weight"
              error={errors.weight}
              helperText={errors.weight}
            />
          </div>
          <div className={classes.personalInfoRow}>
            <div className={classes.label}>{i18nTranslator('height', 'demographics2')}</div>
            <div className={classes.selectWrapper}>
              <TextField
                label={i18nTranslator('heightLabels.feet', 'demographics2')}
                type="tel"
                onChange={this.handleChangeValue}
                classes={{ root: `${classes.textField} ${classes.textFieldHeight} ${classes.marginRight10}` }}
                value={height_feet}
                name="height_feet"
                error={errors.height_feet}
                helperText={errors.height_feet}
              />
              <TextField
                label={i18nTranslator('heightLabels.inches', 'demographics2')}
                type="tel"
                onChange={this.handleChangeValue}
                classes={{ root: `${classes.textField} ${classes.textFieldHeight}` }}
                value={height_inches}
                name="height_inches"
                error={errors.height_inches}
                helperText={errors.height_inches}
              />
            </div>
          </div>
          <div className={classes.personalInfoRow}>
            <div className={classes.label}>{i18nTranslator('address', 'demographics2')}</div>
            <div className={classes.selectWrapper}>
              <TextField
                label={i18nTranslator('addressOptions.zip', 'demographics2')}
                onChange={this.handleChangeValue}
                classes={{ root: `${classes.textField} ${classes.textFieldAddress}` }}
                value={postalCode}
                name="postalCode"
                fullWidth
                error={errors.postalCode}
                helperText={errors.postalCode}
              />
            </div>
          </div>
        </div>
      </QuestionLayout>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state;
  return { user };
}

export default connect(mapStateToProps, { updatePRO })(withStyles(styles)(PatientDemographics2));
