import { promiseHandler, resetReducer } from 'cooldux';
import { get, isUndefined, omit, omitBy, keyBy } from 'lodash';

import { apiFetch } from '../lib/fetch';

const { browseStart, browseEnd, browseError, browseHandler } = promiseHandler('browse', 'surgeryHx');
const { editStart, editEnd, editError, editHandler } = promiseHandler('edit', 'surgeryHx');
const { addStart, addEnd, addError, addHandler } = promiseHandler('add', 'surgeryHx');
const { deleteStart, deleteEnd, deleteError, deleteHandler } = promiseHandler('delete', 'surgeryHx');

export function browseSurgeryHx() {
  return function dispatcher(dispatch, getState) {
    const selfId = get(getState(), 'user.id', null);
    const promise = apiFetch(`/users/${selfId}/surgery_hx`);
    return browseHandler(promise, dispatch);
  };
}

export function editSurgeryHx(update) {
  return function dispatcher(dispatch, getState) {
    const selfId = get(getState(), 'user.id', null);
    const body = omit(update, ['id', 'surgery_id', 'user_id']);
    const options = {
      method: 'PUT',
      body: omitBy(body, isUndefined),
    };
    const promise = apiFetch(`/users/${selfId}/surgery_hx/${update.id}`, options);
    return editHandler(promise, dispatch);
  };
}

export function addSurgeryHx(newSurgeryHx) {
  return function dispatcher(dispatch, getState) {
    const selfId = get(getState(), 'user.id', null);
    const options = {
      method: 'POST',
      body: omitBy(newSurgeryHx, isUndefined),
    };
    const promise = apiFetch(`/users/${selfId}/surgery_hx`, options);
    return addHandler(promise, dispatch);
  };
}

export function deleteSurgeryHx(surgeryHxId) {
  return function dispatcher(dispatch, getState) {
    const selfId = get(getState(), 'user.id', null);
    const options = {
      method: 'DELETE',
    };
    const promise = apiFetch(`/users/${selfId}/surgery_hx/${surgeryHxId}`, options)
      .then(() => surgeryHxId);

    return deleteHandler(promise, dispatch);
  };
}

const initialState = {
  browseError: null,
  addError: null,
  editError: null,
  deleteError: null,
  browsePending: false,
  addPending: false,
  editPending: false,
  deletePending: false,
  data: {},
};

function finishBrowse(state, surgeryHx) {
  const data = keyBy(surgeryHx, 'surgery_id');
  return { ...state, data, browsePending: false, browseError: null };
}

function finishEdit(state, item) {
  const data = { ...state.data, [item.id]: item };
  return { ...state, editPending: false, editError: null, data };
}

function finishAdd(state, item) {
  const data = { ...state.data, [item.id]: item };
  return { ...state, addPending: false, addError: null, data };
}

function finishDelete(state, surgeryHxId) {
  return { ...state, deletePending: false, deleteError: null, data: omit(state.data, surgeryHxId) };
}

const surgeryHx = resetReducer(initialState, (state = initialState, action) => {
  switch (action.type) {
    case browseStart.type:
      return { ...state, browsePending: true, browseError: null };
    case browseEnd.type:
      return finishBrowse(state, action.payload);
    case browseError.type:
      return { ...state, browsePending: false, browseError: action.payload };
    case addStart.type:
      return { ...state, addPending: true, addError: null };
    case addEnd.type:
      return finishAdd(state, action.payload);
    case addError.type:
      return { ...state, addPending: false, addError: action.payload };
    case deleteStart.type:
      return { ...state, deletePending: true, deleteError: null };
    case deleteEnd.type:
      return finishDelete(state, action.payload);
    case deleteError.type:
      return { ...state, deletePending: false, deleteError: action.payload };
    case editStart.type:
      return { ...state, editPending: true, editError: null };
    case editEnd.type:
      return finishEdit(state, action.payload);
    case editError.type:
      return { ...state, editPending: false, editError: action.payload };
    default:
      return state;
  }
});

export default surgeryHx;
