import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWifi } from '@fortawesome/free-solid-svg-icons';
import Button from '@material-ui/core/Button';

import AppBar from '../components/app-bar';
import Page from './page';
import StickyHeader from '../components/sticky-header';
import Layout from '../layouts/common';
import { colors } from '../lib/styles';
import { title, instructions, startBtn } from '../lib/i18next';

const styles = {
  page: {
    padding: '60px 5px 5px 5px',
    color: colors.primaryColor,
    backgroundColor: colors.white,
  },
  button: {
    width: '100%',
    margin: '10px 0',
    fontSize: '19px',
  },
  text: {
    color: colors.black,
    fontSize: '19px',
  },
  icon: {
    width: '100px',
    height: '100px',
    color: 'black',
  },
};

class AppointmentConfirmation3 extends Page {
  handleContinue = async () => {
    this.forwardWithQuery(this.props.location.query);
  };

  render() {
    return (
      <Layout>
        <div style={styles.page}>
          <StickyHeader>
            <AppBar
              backButtonOnClick={() => this.props.router.goBack()}
              headerNode={title('appointmentConfirmation3')}
            />
          </StickyHeader>
          <div style={{ marginTop: '10%' }}>
            <FontAwesomeIcon icon={faWifi} style={styles.icon} />
            <p style={styles.text}>{instructions('appointmentConfirmation3')}</p>

            <Button
              variant="contained"
              color="primary"
              style={styles.button}
              onClick={() => this.handleContinue()}
            >
              {startBtn()}
            </Button>
          </div>
        </div>
      </Layout>
    );
  }
}

export default AppointmentConfirmation3;
