import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Slider } from '@material-ui/core';

const painScaleColors = {
  0: '#22b83c',
  1: '#37c51d',
  2: '#72d217',
  3: '#b9df0f',
  4: '#edca07',
  5: '#eda807',
  6: '#ed8607',
  7: '#ed6407',
  8: '#ed4207',
  9: '#ed2007',
  10: '#ed2007',
};

const styles = {
  contentWrapper: {
    alignItems: 'center',
    color: 'grey',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  slider: {
    flexGrow: 1,
  },
  sliderStyle: {
    flexGrow: 1,
    marginBottom: 24,
  },
  sliderLabel: {
    fontSize: 10,
    maxWidth: 100,
    textAlign: 'center',
  },
  labelContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
};

class HealthSlider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      max: props.max,
      min: props.min,
      sliderRightLabel: props.sliderRightLabel,
      sliderLeftLabel: props.sliderLeftLabel,
      theme: this.getSliderTheme(props.value || props.min),
    };

    this.props.onChange({
      type: this.props.type,
      position: this.props.trackIndex,
      value: this.props.value,
    });
  }

  getSliderTheme = (value) => {
    return {
      color: painScaleColors[value],
    };
  }

  handleValueChange = (event, value) => {
    const theme = this.getSliderTheme(value);
    this.setState({ theme });
    this.props.onChange({
      type: this.props.type,
      position: this.props.trackIndex,
      value,
    });
  }

  render() {
    const {
      max,
      min,
      sliderRightLabel,
      sliderLeftLabel,
      theme,
    } = this.state;

    const { value } = this.props;

    return (
      <div style={styles.contentWrapper}>
        <Slider
          orientation="horizontal"
          min={min}
          max={max}
          value={value}
          valueLabelDisplay="on"
          onChange={this.handleValueChange}
          style={{ ...styles.slider, ...theme }}
        />
        <div style={styles.labelContainer}>
          <div style={styles.sliderLabel}>
            {sliderLeftLabel}
          </div>
          <div style={styles.sliderLabel}>
            {sliderRightLabel}
          </div>
        </div>
      </div>
    );
  }
}

HealthSlider.defaultProps = {
  sliderRightLabel: 'Best imaginable health state',
  sliderLeftLabel: 'Worst imaginable health state',
  value: undefined,
};

HealthSlider.propTypes = {
  trackIndex: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  min: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  sliderRightLabel: PropTypes.string,
  sliderLeftLabel: PropTypes.string,
  value: PropTypes.number,
};

export default HealthSlider;
