import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Page from './page';

import AppBar from '../components/app-bar';
import { colors } from '../lib/styles';
import QuestionLayout from '../layouts/question';
import SimpleList from '../components/simple-list';

import { browseFunctionalLimitations } from '../state/app-data';
import {
  browsePainHxFunctionalLimitations,
  addPainHxFunctionalLimitations,
  deletePainHxFunctionalLimitations,
} from '../state/pain-hx-functional-limitations';

const styles = {
  appBar: {
    height: '8.75rem',
    paddingLeft: '1.5rem',
    paddingRight: '1.5rem',
  },
  backButton: {
    left: '-1.25rem',
    height: '3.6875rem',
    padding: 0,
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    width: '3.6875rem',
  },
  backButtonIcon: {
    height: '3.6875rem',
    width: '3.6875rem',
  },
  continueBtn: {
    background: colors.secondaryColor,
    marginBottom: '7.5vw',
  },
  header: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    width: '100%',
  },
  headerTitle: {
    color: colors.black,
    display: 'inline-block',
    fontWeight: 'bold',
    fontSize: '1.8rem',
  },
  nextButton: {
    position: 'absolute',
    right: 5,
    textAlign: 'right',
    top: '50%',
    transform: 'translateY(-50%)',
  },
  nextButtonLabel: {
    color: colors.black,
    fontSize: '1.6rem',
    padding: 0,
    textTransform: 'none',
  },
  subtitle: {
    alignItems: 'center',
    backgroundColor: colors.darkGrayText,
    color: colors.white,
    display: 'flex',
    fontSize: '1.5rem',
    height: '3rem',
    justifyContent: 'center',
  },
};

const { questionBackground } = colors;

const AppBarContinueButton = props => (
  <Button
    onClick={props.onClick}
    style={{ ...styles.nextButton, ...styles.nextButtonLabel }}
  >Next</Button>
);
AppBarContinueButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

class FunctionalLimitations extends Page {
  constructor(props) {
    super(props);

    this.handleAddItem = this.handleAddItem.bind(this);
    this.handleRemoveItem = this.handleRemoveItem.bind(this);
    this.handleContinue = this.handleContinue.bind(this);
    this.handleBack = this.handleBack.bind(this);
  }

  componentWillMount() {
    const { pain_hx_id } = this.props.location.query;
    this.props.browsePainHxFunctionalLimitations(pain_hx_id);
    if (!this.props.functionalLimitations) {
      this.props.browseFunctionalLimitations();
    }
  }

  handleAddItem(id) {
    const { pain_hx_id } = this.props.location.query;
    const newItem = {
      functional_limitations_id: id,
      pain_hx_id,
    };
    this.props.addPainHxFunctionalLimitations(newItem);
  }

  handleRemoveItem(id) {
    const { pain_hx_id } = this.props.location.query;
    this.props.deletePainHxFunctionalLimitations(pain_hx_id, id);
  }

  handleContinue() {
    this.forwardWithQuery(this.props.location.query);
  }

  handleBack() {
    this.backwardWithQuery(this.props.location.query);
  }

  render() {
    const { functionalLimitations } = this.props;

    return (
      <QuestionLayout
        primaryBtnLabel="Next"
        primaryBtnOnTouchTap={this.handleContinue}
        primaryBtnStyle={styles.continueBtn}
        style={{ background: questionBackground }}
      >
        <div>
          <AppBar
            backButtonOnClick={() => this.props.router.goBack()}
            headerNode="Functional Limitations"
            rightNode={(
              <AppBarContinueButton
                onClick={this.handleContinue}
              />
            )}
          />
          <div style={styles.subtitle}>
            Select the limitations your pain has caused
          </div>
          {functionalLimitations ? (
            <SimpleList
              items={functionalLimitations}
              selectedItems={this.props.painHxFunctionalLimitations.data}
              onAddItem={this.handleAddItem}
              onRemoveItem={this.handleRemoveItem}
            />
          ) : null}
        </div>
      </QuestionLayout>
    );
  }
}

function mapStateToProps(state) {
  const {
    painHxFunctionalLimitations,
    appData: {
      functionalLimitations,
    },
    user,
  } = state;

  return {
    functionalLimitations,
    painHxFunctionalLimitations,
    user,
  };
}

FunctionalLimitations.defaultProps = {
  functional_limitations: {},
  painHxFunctionalLimitations: {},
};

export default connect(mapStateToProps, {
  browseFunctionalLimitations,
  browsePainHxFunctionalLimitations,
  addPainHxFunctionalLimitations,
  deletePainHxFunctionalLimitations,
})(FunctionalLimitations);
