import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isNumber, get, includes, flatten } from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import RadioButton from '@material-ui/core/Radio';
import RadioButtonGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';

import Page from './page';
import { Osteoporosis } from '../lib/icons';
import AppBar from '../components/app-bar';
import { colors } from '../lib/styles';
import QuestionLayout from '../layouts/question';
import { updatePRO, submitPRO } from '../state/pro-forms';
import LinearProgress from '../components/linear-progress';

const { questionBackground } = colors;

const styles = {
  contentWrapper: {
    flexGrow: 1,
    overflowX: 'hidden',
    position: 'relative',
  },
  image: {
    height: 150,
    padding: '15px 60px',
    textAlign: 'center',
  },
  instructions: {
    color: 'grey',
    fontSize: '1.5rem',
    margin: '20px 40px 0px 40px',
    textAlign: 'center',
  },
  pageIndicator: {
    fontSize: '1.4rem',
  },
  questionnaire: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 30,
    position: 'relative',
  },
  formGroup: {
    margin: '25px',
  },
  radioButtonGroup: {
    display: 'flex',
    flexDirection: 'row',
  },
  radioButton: {
    color: colors.black,
    fontSize: '1.5rem',
    lineHeight: '1.7rem',
    alignItems: 'center',
  },
  helperText: {
    color: colors.black,
    fontSize: '1.2rem',
  },
};

class OsteoporosisRisk2 extends Page {
  constructor(props) {
    super(props);

    this.handleAge = this.handleChange.bind(this, 0);
    this.handleEstrogen = this.handleChange.bind(this, 1);
    this.handleWeight = this.handleChange.bind(this, 2);
  }

  handleSubmission = () => {
    const data = flatten(this.props.osteo);
    const { user } = this.props;
    const { id } = this.props.location.query;
    submitPRO({ userId: user.id, id }, {
      pro_type: 'OSTEOPOROSIS-RISK',
      pro_data: { data },
    }, user)
      .then(() => this.forward());
  }

  handleChange(index, event) {
    const trackIndex = this.getTrackIndex();
    const page = get(this.props.osteo, trackIndex, ['', '', '']);
    page[index] = parseInt(event.target.value, 10);
    this.props.updatePRO({
      type: 'osteo',
      position: this.getTrackIndex(),
      value: page,
    });
  }

  render() {
    const { osteo } = this.props;
    const page = get(osteo, this.getTrackIndex(), ['', '', '']);
    const value = includes(page, '') ? null : 0;
    const primaryBtnDisabled = !isNumber(value);
    const currentPage = this.getCurrentPage();
    const totalPages = this.getTotalPages();
    return (
      <QuestionLayout
        primaryBtnDisabled={primaryBtnDisabled}
        primaryBtnLabel="Next"
        primaryBtnOnTouchTap={this.handleSubmission}
        style={{ backgroundColor: questionBackground }}
      >
        <AppBar
          backButtonOnClick={() => this.props.router.goBack()}
          headerNode="Osteoporosis Risk"
          rightNode={
            <div style={styles.pageIndicator}>{`${currentPage} of ${totalPages}`}</div>
          }
        />
        <LinearProgress value={(currentPage / totalPages) * 100} />
        <div style={styles.contentWrapper}>
          <div style={styles.instructions}>
            Please select the option that best answers the question.
          </div>
          <div style={styles.image}>
            <Osteoporosis />
          </div>
          <div style={styles.questionnaire}>
            <div>
              <FormControl style={styles.formGroup}>
                <Input
                  id="standard-adornment-age"
                  value={page[0] || ''}
                  onChange={this.handleAge}
                  endAdornment={<InputAdornment position="end">Years</InputAdornment>}
                  aria-describedby="standard-age-helper-text"
                  type="tel"
                  inputProps={{
                    inputMode: 'numeric',
                    pattern: '[0-9]*',
                    type: 'tel',
                    'aria-label': 'age',
                    style: { fontSize: '16px' },
                  }}
                  style={{ width: '100px' }}
                />
                <FormHelperText style={styles.helperText} id="standard-age-helper-text">Age</FormHelperText>
              </FormControl>
              <FormControl style={styles.formGroup}>
                <Input
                  id="standard-adornment-weight"
                  value={page[2] || ''}
                  onChange={this.handleWeight}
                  endAdornment={<InputAdornment position="end">Lbs</InputAdornment>}
                  aria-describedby="standard-weight-helper-text"
                  type="tel"
                  inputProps={{
                    inputMode: 'numeric',
                    pattern: '[0-9]*',
                    type: 'tel',
                    'aria-label': 'weight',
                    style: { fontSize: '16px' },
                  }}
                  style={{ width: '100px' }}
                />
                <FormHelperText style={styles.helperText} id="standard-weight-helper-text">Weight</FormHelperText>
              </FormControl>
            </div>
            <FormControl style={styles.formGroup} component="fieldset">
              <FormLabel style={styles.radioButton} component="legend">Estrogen:</FormLabel>
              <RadioButtonGroup
                name="estrogen"
                onChange={this.handleEstrogen}
                value={`${page[1]}` || ''}
                style={styles.radioButtonGroup}
              >
                <FormControlLabel value="0" control={<RadioButton style={styles.radioButton} />} label="Prior use" />
                <FormControlLabel value="1" control={<RadioButton style={styles.radioButton} />} label="NO prior use" />
              </RadioButtonGroup>
            </FormControl>
          </div>
        </div>
      </QuestionLayout>
    );
  }
}

function mapStateToProps(state) {
  const { proForms: { osteo }, user } = state;
  return { osteo, user };
}

OsteoporosisRisk2.propTypes = {
  osteo: PropTypes.array.isRequired,
  updatePRO: PropTypes.func.isRequired,
  submitPRO: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { updatePRO, submitPRO })(withStyles(styles)(OsteoporosisRisk2));
