import React from 'react';
import PropTypes from 'prop-types';

const baseStyles = {
  stickyHeader: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '60px',
  },
};

const StickyHeader = ({ children }) => (
  <div style={baseStyles.stickyHeader}>
    {children}
  </div>
);

StickyHeader.propTypes = {
  children: PropTypes.object.isRequired,
};

export default StickyHeader;
