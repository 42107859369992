import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

import Page from './page';
import formatPhoneNumber from '../lib/format-phone-number';
import { colors, fontSizing } from '../lib/styles';
import QuestionLayout from '../layouts/question';

const styles = {
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100%',
    justifyContent: 'space-between',
  },
  enteredValueText: {
    fontWeight: 'bold',
    marginTop: 30,
  },
  iconWrapper: {
    color: colors.errorRed,
    marginTop: 40,
    marginBottom: 40,
  },
  mainContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '0px 10px',
    flexGrow: 1,
    fontSize: fontSizing.body,
    textAlign: 'center',
  },
  messages: {
    whiteSpace: 'pre-line',
  },
  phoneText: {
    fontWeight: 'bold',
  },
};

class RespiratoryOncology4 extends Page {
  render() {
    const { classes, respiratoryOncology, user } = this.props;

    let clinicNumber;
    if (user.requestingClinicNumber) {
      clinicNumber = formatPhoneNumber(user.requestingClinicNumber);
    }

    const message = respiratoryOncology[this.getTrackIndex() - 1];

    return (
      <QuestionLayout
        primaryBtnLabel="Next"
        primaryBtnOnTouchTap={() => { this.forwardWithQuery(this.props.location.query); }}
        style={{ backgroundColor: colors.questionBackground }}
      >
        <div className={classes.contentWrapper}>
          <div className={classes.mainContent}>
            <div className={classes.iconWrapper}>
              <FontAwesomeIcon icon={faExclamationCircle} size="3x" />
            </div>
            {clinicNumber ? (
              <div>
                Please contact the nurse navigator, who works with your oncologist, at <span className={classes.phoneText}>{clinicNumber}</span> as soon as possible.
              </div>
            ) : (
              <div>
                Please contact the nurse navigator, who works with your oncologist, as soon as possible
              </div>
            )}
            <div className={classes.enteredValueText}>You have entered a value of...</div>
            <div className={classes.messages}>
              {message}
            </div>
          </div>
        </div>
      </QuestionLayout>
    );
  }
}

function mapStateToProps(state) {
  const {
    proForms: {
      respiratoryOncology,
    },
    user,
  } = state;
  return { respiratoryOncology, user };
}

export default connect(mapStateToProps)(withStyles(styles)(RespiratoryOncology4));
