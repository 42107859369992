import React from 'react';
import { connect } from 'react-redux';
import { Paper } from '@material-ui/core';
import { get } from 'lodash';

import AppBar from '../components/app-bar';
import Page from './page';
import Layout from '../layouts/common';
import Continue from '../components/continue';

import { colors } from '../lib/styles';
import { deleteUserClinic } from '../state/patient-clinics';
import { readClinicInfo } from '../state/app-data';

const baseStyles = {
  listItem: {
    borderBottom: `1px solid ${colors.primaryHighlight}`,
    color: colors.primaryColor,
    fontWeight: 400,
    textAlign: 'left',
  },
  toggleMsg: {
    textAlign: 'center',
    fontSize: '12px',
    color: colors.primaryColor,
    marginBottom: '10px',
  },
  paperMargin: {
    paddingBottom: '60px',
    paddingTop: '10px',
    width: '350px',
    margin: '0 auto',
    marginTop: '40px',
  },
  textColor: {
    color: colors.primaryColor,
    margin: '0px',
    paddingRight: '5px',
  },
  divStyle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  deleteButton: {
    backgroundColor: colors.errorRed,
    marginTop: '25px',
    boxShadow: '10px 10px 5px #888888',
  },
  topDiv: {
    backgroundColor: colors.primaryHighlight,
  },
};

class ClinicInfoPage extends Page {
  constructor(props) {
    super(props);

    this.handleBackToPersonalInfo = this.handleBackToPersonalInfo.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
  }

  componentWillMount() {
    if (!this.props.clinic) {
      this.props.readUserClinic(this.props.routeParams.clinicId);
    }
  }

  handleBackToPersonalInfo() {
    this.props.router.push('/personalinfo');
  }

  handleDelete() {
    const { clinicId } = this.props.routeParams;

    this.props.deleteUserClinic(clinicId)
      .then(() => this.props.router.push('/personalinfo'));
  }

  render() {
    const clinicInfo = this.props.clinic;
    return (
      <Layout>
        <div style={baseStyles.topDiv}>
          <AppBar
            backButtonOnClick={this.handleBackToPersonalInfo}
            headerNode="Clinic Info"
          />
          <Paper style={baseStyles.paperMargin}>
            <h3>{clinicInfo.name}</h3>
            <p style={baseStyles.textColor}>{clinicInfo.address}</p>
            {clinicInfo.address_2 ? (<p style={baseStyles.textColor}>{clinicInfo.address_2}</p>) : null}
            <div style={baseStyles.divStyle}>
              <p style={baseStyles.textColor}>{clinicInfo.city}</p>
              <p style={baseStyles.textColor}>{clinicInfo.state}</p>
              <p style={baseStyles.textColor}>{clinicInfo.zip_code}</p>
            </div>
            {clinicInfo.phone ? (<p style={baseStyles.textColor}>Phone: {clinicInfo.phone}</p>) : null}
            {clinicInfo.fax ? (<p style={baseStyles.textColor}>Fax: {clinicInfo.fax}</p>) : null}
          </Paper>
          <Continue
            text="Remove from list"
            btnStyle={baseStyles.deleteButton}
            onClick={this.handleDelete}
          />
        </div>
      </Layout>
    );
  }
}

function mapStateToProps(state, props) {
  const { appData } = state;
  const { clinicId } = props.routeParams;
  const myClinic = get(appData, ['clinics', clinicId], {});
  return {
    clinic: myClinic,
  };
}

export default connect(mapStateToProps, {
  readClinicInfo,
  deleteUserClinic,
})(ClinicInfoPage);
