const en = {
  appointmentConfirmation1: {
    title: 'Appointment Confirmation',
    subtitle: 'Virtual Appointment Confirmation',
    instructions: 'An appointment has been scheduled for you with',
    disclaimer: 'If you are unable to keep this appointment, please call our office.',
    download: 'Download Event to Calendar',
  },
};

const es = {
  appointmentConfirmation1: {
    title: 'Confirmación de cita virtual',
    subtitle: 'Confirmación de cita virtual',
    instructions: 'Una cita ha sido programada para usted con',
    disclaimer: 'Si no puede atender su cita, por favor llame nuestra oficina.',
    download: 'Descargar',
  },
};

export default { en, es };
