import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get } from 'lodash';

import { colors } from '../lib/styles';
import { Lifting } from '../lib/icons';
import Page from './page';
import Question from '../components/question';
import { updatePRO } from '../state/pro-forms';

const options = [
  {
    label: 'I can lift heavy weights without extra pain',
    value: 0,
  },
  {
    label: 'I can lift heavy weights but it gives extra pain',
    value: 1,
  },
  {
    label: 'Pain prevents me from lifting heavy weights off the floor, '
      + 'but I can manage if they are conveniently positioned for example on a table',
    value: 2,
  },
  {
    label: 'Pain prevents me from lifting heavy weights, '
      + 'but I can manage light to medium weights if they are conveniently positioned',
    value: 3,
  },
  {
    label: 'I can lift very light weights',
    value: 4,
  },
  {
    label: 'I cannot lift or carry anything at all',
    value: 5,
  },
];
const { questionBackground } = colors;

class NdiLifting extends Page {
  onClickBackButton = () => {
    this.backward();
  }

  onSubmission = () => {
    this.forwardWithQuery(this.props.location.query);
  }

  render() {
    const value = get(this.props.ndi, this.getTrackIndex(), null);
    return (
      <Question
        backgroundColor={questionBackground}
        currentPage={this.getCurrentPage()}
        image={Lifting}
        onClickBackButton={() => this.props.router.goBack()}
        onChange={this.props.updatePRO}
        onSubmission={this.onSubmission}
        options={options}
        title="Lifting"
        totalPages={this.getTotalPages()}
        trackIndex={this.getTrackIndex()}
        type="ndi"
        value={value}
      />
    );
  }
}


function mapStateToProps(state) {
  const { proForms: { ndi } } = state;
  return { ndi };
}

NdiLifting.propTypes = {
  ndi: PropTypes.array.isRequired,
  updatePRO: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { updatePRO })(NdiLifting);
