import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';
import { connect } from 'react-redux';
import Joi from 'joi-browser';

import Page from './page';
import AppBar from '../components/app-bar';
import IconWrapper from '../components/icon-wrapper';
import BloomkidzLayout from '../layouts/bloomkidz';
import { UserGroup } from '../lib/icons';
import { fontSizing } from '../lib/styles';
import { updatePRO } from '../state/pro-forms';
import { bloomkidzStyles, bloomkidzStylesObj } from '../lib/bloomkidz';
import { phone } from '../lib/validator';

const styles = bloomkidzStylesObj;

const formSchema = Joi.object({
  emergencyContactNumber: Joi.string().regex(phone),
});

class BloomkidzParentGuardianInformation extends Page {
  constructor(props) {
    super(props);

    const stateFromRedux = props.bloomkidzParentGuardianInformation[this.getTrackIndex()] || {};

    this.state = {
      emergencyContactFirstName: '',
      emergencyContactLastName: '',
      emergencyContactNumber: '',
      emergencyContactRelationship: '',
      errors: {
        emergencyContactNumber: '',
      },
      ...stateFromRedux,
    };
  }

  handleTextFieldChange = (e) => {
    const stateUpdate = { [e.target.name]: e.target.value };

    if (this.state.errors[e.target.name]) {
      stateUpdate.errors = {
        ...this.state.errors,
        [e.target.name]: '',
      };
    }

    this.setState(stateUpdate);
  };

  handleContinue = () => {
    const { location, updatePRO } = this.props;
    const { emergencyContactNumber } = this.state;

    const { error } = formSchema.validate({ emergencyContactNumber }, { abortEarly: false });

    if (error) {
      this.setState({
        errors: { emergencyContactNumber: 'Invalid value' },
      });
      return;
    }

    updatePRO({
      type: 'bloomkidzParentGuardianInformation',
      position: this.getTrackIndex(),
      value: { ...this.state },
    });

    this.forwardWithQuery(location.query);
  }

  handleClickButton = (gender) => {
    this.setState({ gender });
  }

  shouldDisableContinueButton = () => {
    const {
      emergencyContactFirstName,
      emergencyContactLastName,
      emergencyContactNumber,
      emergencyContactRelationship,
      errors,
    } = this.state;

    if (errors.emergencyContactNumber) return true;

    return !emergencyContactFirstName
      || !emergencyContactLastName
      || !emergencyContactNumber
      || !emergencyContactRelationship;
  }

  render() {
    const { classes, router } = this.props;
    const {
      emergencyContactFirstName,
      emergencyContactLastName,
      emergencyContactNumber,
      emergencyContactRelationship,
      errors,
    } = this.state;

    return (
      <BloomkidzLayout
        primaryBtnOnTouchTap={this.handleContinue}
        primaryBtnLabel="Next"
        primaryBtnDisabled={this.shouldDisableContinueButton()}
      >
        <AppBar
          headerNode="Emergency Contact"
          backButtonOnClick={() => router.goBack()}
        />
        <IconWrapper>
          <UserGroup />
        </IconWrapper>
        <div className={classes.pageContent}>
          <div>Emergency Contact</div>
          <div className={classes.paddingHorizontal}>
            <div className={classes.textFieldWrapper}>
              <TextField
                placeholder="First Name"
                value={emergencyContactFirstName}
                onChange={this.handleTextFieldChange}
                name="emergencyContactFirstName"
                variant="standard"
                fullWidth
                inputProps={{ style: { fontSize: fontSizing.body, fontFamily: bloomkidzStyles.fontFamily } }}
              />
            </div>
            <div className={classes.textFieldWrapper}>
              <TextField
                placeholder="Last Name"
                value={emergencyContactLastName}
                onChange={this.handleTextFieldChange}
                name="emergencyContactLastName"
                variant="standard"
                fullWidth
                inputProps={{ style: { fontSize: fontSizing.body, fontFamily: bloomkidzStyles.fontFamily } }}
              />
            </div>
          </div>
          <div className={classes.inputSectionTitle}>
            Contact
          </div>
          <div className={classes.paddingHorizontal}>
            <div className={classes.textFieldWrapper}>
              <TextField
                placeholder="Contact Number"
                value={emergencyContactNumber}
                onChange={this.handleTextFieldChange}
                name="emergencyContactNumber"
                variant="standard"
                fullWidth
                inputProps={{ style: { fontSize: fontSizing.body, fontFamily: bloomkidzStyles.fontFamily } }}
                error={errors.emergencyContactNumber}
                helperText={errors.emergencyContactNumber}
              />
            </div>
          </div>
          <div className={classes.inputSectionTitle}>
            Relationship
          </div>
          <div className={classes.paddingHorizontal}>
            <div className={classes.textFieldWrapper}>
              <TextField
                placeholder="Tap here to type"
                value={emergencyContactRelationship}
                onChange={this.handleTextFieldChange}
                name="emergencyContactRelationship"
                variant="standard"
                fullWidth
                inputProps={{ style: { fontSize: fontSizing.body, fontFamily: bloomkidzStyles.fontFamily } }}
              />
            </div>
          </div>
        </div>
      </BloomkidzLayout>
    );
  }
}

BloomkidzParentGuardianInformation.propTypes = {
  classes: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  updatePRO: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const {
    proForms: { bloomkidzParentGuardianInformation },
  } = state;

  return { bloomkidzParentGuardianInformation };
};

export default connect(mapStateToProps, { updatePRO })(withStyles(styles)(BloomkidzParentGuardianInformation));
