/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';
import { connect } from 'react-redux';
import Joi from 'joi-browser';

import Page from './page';
import AppBar from '../components/app-bar';
import IconWrapper from '../components/icon-wrapper';
import BloomkidzLayout from '../layouts/bloomkidz';
import { Physician } from '../lib/icons';
import { fontSizing } from '../lib/styles';
import { updatePRO } from '../state/pro-forms';
import { bloomkidzStyles, bloomkidzStylesObj } from '../lib/bloomkidz';
import { email, phone } from '../lib/validator';

const formSchema = Joi.object({
  physicianEmail: Joi.string().regex(email),
  physicianPhone: Joi.string().regex(phone),
});
class BloomkidzMedicalHistory1 extends Page {
  constructor(props) {
    super(props);

    const stateFromRedux = props.bloomkidzMedicalHistory[this.getTrackIndex()] || {};

    this.state = {
      physicianName: '',
      physicianEmail: '',
      physicianPhone: '',
      errors: {
        physicianPhone: false,
        physicianEmail: false,
      },
      ...stateFromRedux,
    };
  }

  handleTextFieldChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      errors: {
        physicianPhone: false,
        physicianEmail: false,
      },
    });
  };

  handleContinue = () => {
    const { location, updatePRO } = this.props;
    const {
      physicianEmail,
      physicianPhone,
    } = this.state;

    const { error } = formSchema.validate({ physicianEmail, physicianPhone }, { abortEarly: false });

    if (error) {
      const errors = {};

      error.details.forEach((error) => {
        errors[error.context.key] = 'Invalid value';
      });

      this.setState({ errors });
      return;
    }

    updatePRO({
      type: 'bloomkidzMedicalHistory',
      position: this.getTrackIndex(),
      value: { ...this.state },
    });

    this.forwardWithQuery(location.query);
  }

  shouldDisableContinueButton = () => {
    const { state } = this;
    const { errors } = state;
    const requiredFields = [
      'physicianName',
      'physicianPhone',
      'physicianEmail',
    ];
    let requiredFieldCount = 0;

    requiredFields.forEach((field) => {
      if (state[field]) requiredFieldCount++;
    });

    if (requiredFieldCount !== requiredFields.length) {
      return true;
    }

    if (errors.physicianPhone || errors.physicianEmail) {
      return true;
    }

    return false;
  };

  render() {
    const { classes, router } = this.props;
    const { errors, physicianName, physicianPhone, physicianEmail } = this.state;

    const continueDisabled = this.shouldDisableContinueButton();

    return (
      <BloomkidzLayout
        primaryBtnOnTouchTap={this.handleContinue}
        primaryBtnLabel="Next"
        primaryBtnDisabled={continueDisabled}
      >
        <AppBar
          headerNode="Physicians"
          backButtonOnClick={() => router.goBack()}
        />
        <IconWrapper>
          <Physician />
        </IconWrapper>
        <div className={classes.pageContent}>
          <div>Child&apos;s Primary Physician</div>
          <div className={classes.paddingHorizontal}>
            <div className={classes.textFieldWrapper}>
              <TextField
                placeholder="Name"
                value={physicianName}
                onChange={this.handleTextFieldChange}
                name="physicianName"
                variant="standard"
                fullWidth
                inputProps={{ style: { fontSize: fontSizing.body, fontFamily: bloomkidzStyles.fontFamily } }}
              />
            </div>
          </div>
          <div>Contact Information for Primary Physician</div>
          <div className={classes.paddingHorizontal}>
            <div className={classes.textFieldWrapper}>
              <TextField
                placeholder="Email"
                value={physicianEmail}
                onChange={this.handleTextFieldChange}
                name="physicianEmail"
                variant="standard"
                fullWidth
                inputProps={{ style: { fontSize: fontSizing.body } }}
                error={errors.physicianEmail}
                helperText={errors.physicianEmail}
              />
            </div>
            <div className={classes.textFieldWrapper}>
              <TextField
                placeholder="Telephone Number"
                value={physicianPhone}
                onChange={this.handleTextFieldChange}
                name="physicianPhone"
                variant="standard"
                fullWidth
                inputProps={{ style: { fontSize: fontSizing.body } }}
                error={errors.physicianPhone}
                helperText={errors.physicianPhone}
                type="tel"
              />
            </div>
          </div>
        </div>
      </BloomkidzLayout>
    );
  }
}

BloomkidzMedicalHistory1.propTypes = {
  classes: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  updatePRO: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const {
    proForms: { bloomkidzMedicalHistory },
  } = state;

  return { bloomkidzMedicalHistory };
};

export default connect(mapStateToProps, { updatePRO })(withStyles(bloomkidzStylesObj)(BloomkidzMedicalHistory1));
