import React from 'react';
import { connect } from 'react-redux';
import { filter, forEach, orderBy } from 'lodash';
import { List } from '@material-ui/core';
import AppBar from '../components/app-bar';
import Page from './page';
import Layout from '../layouts/common';
import { conditionsIfNeeded } from '../state/app-data';
import { browseConditionsHx, addConditionHx, deleteConditionHx, editConditionHx } from '../state/conditions-hx';
import RelationRow from '../components/relation-row';
import Continue from '../components/continue';
import Subtitle from '../components/subtitle';
import FamilyRow from '../components/family-row';
import QuestionHeader from '../components/question-header';

class Conditions extends Page {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.getHx = this.getHx.bind(this);
    this.handleContinue = this.handleContinue.bind(this);
  }

  componentWillMount() {
    this.props.conditionsIfNeeded();
    this.props.browseConditionsHx();
  }

  onChange(conditions_id, checked, hx) {
    if (hx && checked) {
      return this.props.editConditionHx(hx);
    }
    if (!hx) {
      return this.props.addConditionHx({ conditions_id, relationship: 'MOTHER' });
    }

    if (hx && !checked) {
      this.props.deleteConditionHx(hx.id);
    }
  }

  getHx(id) {
    const { data } = this.props.conditionsHx;
    return filter(data, (condition) => {
      return condition.conditions_id === id && condition.relationship !== 'SELF';
    })[0];
  }

  handleContinue() {
    this.forward();
  }

  render() {
    const { conditions } = this.props.appData;
    const { addPending, deletePending, editPending } = this.props.conditionsHx;
    const listItems = [];
    const sortedHx = orderBy(conditions, [conditions => conditions.name.toLowerCase()], ['desc']);

    forEach(sortedHx, (condition) => {
      const hx = this.getHx(condition.id);
      const disabled = !!editPending || !!addPending || !!deletePending;
      const simpleListItem = (
        <FamilyRow
          key={`familyRow-${condition.id}`}
          onChange={this.onChange}
          hx={hx}
          condition={condition}
          disabled={disabled}
        />
      );
      const sortListItem = (condition.name.toLowerCase() === 'none reported') ? listItems.push(simpleListItem) : listItems.unshift(simpleListItem);
      if (hx && condition.id !== 1) {
        listItems.unshift(<RelationRow
          condition={sortListItem}
          disabled={disabled}
          hx={hx}
          key={`relationRow-${condition.id}`}
          onChange={this.onChange}
        />);
      }
    });

    return (
      <Layout>
        <div>
          <AppBar
            backButtonOnClick={() => this.props.router.goBack()}
            headerNode="Family History"
          />
          <QuestionHeader>Has Any Immediate Family Been Diagnosed With</QuestionHeader>
          <Subtitle label="SCROLL TO SEE ALL OPTIONS" />
          <List>
            {listItems}
          </List>
          <section>
            <Continue onClick={this.handleContinue} />
          </section>
        </div>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  const { user, appData, conditionsHx } = state;
  return {
    user,
    appData,
    conditionsHx,
  };
}

export default connect(mapStateToProps, {
  conditionsIfNeeded,
  browseConditionsHx,
  addConditionHx,
  deleteConditionHx,
  editConditionHx,
})(Conditions);
