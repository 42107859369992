import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import EventIcon from '@material-ui/icons/Event';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeadset } from '@fortawesome/free-solid-svg-icons';

import Button from '@material-ui/core/Button';
import moment from 'moment';
import 'moment/min/locales';
import { isAndroid } from 'common-ui';
import { map } from 'lodash';

import AppBar from '../components/app-bar';
import Page from './page';
import StickyHeader from '../components/sticky-header';
import Layout from '../layouts/common';
import { colors } from '../lib/styles';
import i18nTranslator, { title, instructions, nextBtn } from '../lib/i18next';

import { updatePRO } from '../state/pro-forms';

const ics = require('ics');

const getVirtualExamLink = (notificationId) => {
  return `${window.location.origin}/notifications/${notificationId}`;
};

const styles = {
  page: {
    padding: '60px 5px 5px 5px',
    color: colors.primaryColor,
    backgroundColor: colors.white,
  },
  button: {
    width: '100%',
    margin: '10px 0',
    fontSize: '19px',
  },
  h1: {
    color: colors.black,
  },
  text: {
    color: colors.black,
    fontSize: '19px',
  },
  icon: {
    width: '100px',
    height: '100px',
    color: 'black',
  },
  downloadIcon: {
    width: '50px',
    height: '50px',
  },
};

class AppointmentConfirmation1 extends Page {
  constructor(props) {
    super(props);
    this.state = {
      event: {},
      ical: '',
      start_date: '',
      end_date: '',
      user_id: '',
      notification_id: '',
      clinic_name: '',
      appointment_date: '',
    };
  }

  componentDidMount() {
    const { start_date, end_date, user_id, notification_id, clinicName, appointment_notification_id } = this.props.location.state;
    const { language } = this.props;

    moment.locale(language);
    const appointment_date = moment(start_date).format('LL');

    const clinic_name = this.props.clinicName || clinicName;
    const calEvent = {
      title: `Appointment with ${clinic_name}`,
      description: getVirtualExamLink(appointment_notification_id),
      start: map(moment(start_date).format('YYYY-M-D-H-m').split('-'), (val) => { return Number(val); }),
      end: map(moment(end_date).format('YYYY-M-D-H-m').split('-'), (val) => { return Number(val); }),
      location: '',
    };
    ics.createEvent(calEvent, (error, value) => {
      if (error) {
        return;
      }
      this.setState({
        ical: value,
        event: calEvent,
        start_date,
        end_date,
        user_id,
        notification_id,
        clinic_name,
        appointment_notification_id,
        appointment_date,
      });
    });
  }

  handleContinue() {
    this.props.updatePRO({
      type: 'appointmentConfirmation',
      position: this.getTrackIndex(),
      value: this.state });
    this.forwardWithQuery(this.props.location.query);
  }

  render() {
    const { appointment_notification_id, event } = this.state;
    const start_date = moment(this.state.start_date).format('YYYYMMDDTHHmmssZ');
    const end_date = moment(this.state.end_date).format('YYYYMMDDTHHmmssZ');
    const androidLink = `https://www.google.com/calendar/render?action=TEMPLATE&text=${event.title}&dates=${start_date}/${end_date}&details=${encodeURIComponent(getVirtualExamLink(appointment_notification_id))}`;
    const icsLink = encodeURI(`data:text/calendar,${this.state.ical}`);
    return (
      <Layout>
        <div style={styles.page}>
          <StickyHeader>
            <AppBar
              backButtonOnClick={() => this.props.router.goBack()}
              headerNode={title('appointmentConfirmation1')}
            />
          </StickyHeader>
          <div style={{ marginTop: '10%' }}>
            <FontAwesomeIcon icon={faHeadset} style={styles.icon} />
            <p style={styles.text}>{instructions('appointmentConfirmation1')} {this.state.clinic_name}</p>
            <h1 style={styles.h1}>{moment(this.state.start_date).format('dddd, MMMM DD, YYYY')}</h1>
            <h1 style={styles.h1}>{moment(this.state.start_date).format('h:mm A')}</h1>
            <p style={styles.text}>{i18nTranslator('disclaimer', 'appointmentConfirmation1')}</p>
            <Button
              style={styles.button}
              variant="outlined"
              color="primary"
              target="_blank"
              href={isAndroid ? androidLink : icsLink}
            >
              <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                <EventIcon style={styles.downloadIcon} />
                {i18nTranslator('download', 'appointmentConfirmation1')}
              </div>
            </Button>
            <Button
              variant="contained"
              color="primary"
              style={styles.button}
              onClick={() => this.handleContinue()}
            >
              {nextBtn()}
            </Button>
          </div>
        </div>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state;
  return { clinicName: user.requesting_clinic_name, language: user.primary_language };
}

AppointmentConfirmation1.proptypes = {
  clinicName: PropTypes.string.isRequired,
};

export default connect(mapStateToProps, { updatePRO })(AppointmentConfirmation1);
