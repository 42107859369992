import { makeDuck, makeActionCreator } from 'cooldux';
import { omit } from 'lodash';

import { apiFetch, makeShadowOptions } from '../lib/fetch';

export const updatePRO = makeActionCreator('updatePRO');
export const update = makeActionCreator('update');
export const createProData = makeActionCreator('createProData');
export const clearPros = makeActionCreator('clearPros');

const PROs = makeDuck({
  submitPRO: (ids, body, user) => {
    const reqOptions = makeShadowOptions(body, user.token);
    return apiFetch(`/users/${ids.userId}/${user.token ? 'pro_lite_submissions' : 'pro_submissions'}/${ids.id}`, reqOptions);
  },
}, { throwErrors: true });

export const { submitPRO, submitPROEnd } = PROs;

const vitalCoreRR8InitialState = {
  currentVitalsMeasurementValues: { BR: -1, HR: -1, needBR: true, needHR: true },
  brOutOfRangeCount: 0,
  hrOutOfRangeCount: 0,
};

const initialState = {
  anspe6: [],
  appointmentConfirmation: [],
  bloomkidzClientWellnessProfile: [],
  bloomkidzNewClient: [],
  bloomkidzMealAndNutrition: [],
  bloomkidzMedicalHistory: [],
  bloomkidzSignatures: [],
  bloomkidzParentGuardianInformation: [],
  bmi: [],
  bodyPain: [],
  captureInsuranceCard: [],
  contactSpo2DataCollection: [],
  continuousBPDataCollection: [],
  covid19: [],
  diabetesInitial: [],
  diabetesDaily: [],
  digestiveHealth: [],
  eq5d3l: [],
  eq5d5l: [],
  fullDataCollection: [],
  gad7: [],
  giOncology: [],
  headache: [],
  headachePain: [],
  heartFailureDaily: [],
  heartFailureInitial: [],
  hit6: [],
  humanFactorsForIvcApp: [],
  hypertensionAssessment: [],
  hypertensionAssessmentDaily: [],
  jhconsent: [],
  medications: [],
  midas: [],
  mua: [],
  ndi: [],
  neutropenia: [],
  nrs: [],
  odi: [],
  osteo: [],
  pain: [],
  painLog: [],
  patientDemographics: [],
  pisaFollowup: [],
  pisaInjection: [],
  phq9: [],
  pmjoa: [],
  prgendo: [],
  promis29: [],
  reasonForVisit: [],
  respiratoryOncology: [],
  startBack: [],
  systemsReview: [],
  videoBroadcast: [],
  videoVitals: [],
  vitalsDataCollection: [],
  vitalCore: [],
  vitalCoreRR: [null, null, null, null, null, null, null, vitalCoreRR8InitialState, null],
  vitalCoreBenchHr: [],
  ivcRRClinicalStudy: [],
  voiceBroadcast: [],
  womacknees: [],
  womachips: [],
  wound: [],
  woundCasaColina: [],
  thoracicCancerSymptoms: [],
  headAndNeckCancerSymptoms: [],
};

function proForms(state = initialState, { type, payload }) {
  switch (type) {
    case updatePRO.type: {
      const pro = [...state[payload.type]];
      pro[payload.position] = payload.value;
      return { ...state, [payload.type]: pro };
    }
    case update.type: {
      let newState = { ...state };
      const initialKeys = Object.keys(initialState);
      newState = omit(newState, initialKeys);
      Object.keys(payload).forEach((key) => {
        newState[key] = payload[key];
      });
      return newState;
    }
    case submitPROEnd.type:
    case clearPros.type: {
      return initialState;
    }
    default:
      return state;
  }
}

export default {
  proSubmit: PROs.reducerCombined,
  proForms,
};
