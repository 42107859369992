import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import { omit } from 'lodash';

import AppBar from '../components/app-bar';
import ToggleTitle from '../components/toggle-title';
import Page from './page';
import Layout from '../layouts/common';
import Continue from '../components/continue';
import MultilineTextField from '../components/multiline-textfield';
import { colors } from '../lib/styles';

import { apiFetch, makeShadowOptions } from '../lib/fetch';
import { updatePRO, submitPRO } from '../state/pro-forms';

const baseStyles = {
  marginLeft: {
    marginLeft: 15,
  },
  subtitle: {
    textAlign: 'left',
  },
  section: {
    backgroundColor: colors.white,
    marginTop: '3px',
  },
};

class Reactions extends Page {
  constructor(props) {
    super(props);
    this.state = { submitting: false }
    this.toggleDrugAllergies = this.handleUpdateChecked.bind(this, 'drug_allergies');
    this.toggleSteroidInjections = this.handleUpdateChecked.bind(this, 'steroid_injections');
    this.toggleAnesthesia = this.handleUpdateChecked.bind(this, 'anesthesia');
    this.handleChangeDrugAllergiesList = this.handleUpdate.bind(this, 'drug_allergies_list');
    this.handleChangeDrugAllergiesReaction = this.handleUpdate.bind(this, 'drug_allergies_reaction');

    this.handleChangeSteroidInjectionsArea = this.handleUpdate.bind(this, 'steroid_injections_area');
    this.handleChangeSteroidResponse = this.handleUpdate.bind(this, 'steroid_response');
    this.handleChangeAnesthesiaReaction = this.handleUpdate.bind(this, 'anesthesia_reaction');
    this.handleUpdate = this.handleUpdate.bind(this);
  }

  handleUpdate(field, e) {
    const generalHx = {
      ...this.props.generalHx,
      [field]: e.target.value,
    };
    this.props.updatePRO({ type: 'medications', position: 1, value: generalHx });
  }

  handleUpdateChecked(field, e, text) {
    const generalHx = {
      ...this.props.generalHx,
      [field]: text,
    };
    this.props.updatePRO({ type: 'medications', position: 1, value: generalHx });
  }

  handleComplete = () => {
    if (this.state.submitting) return;
    this.setState({ submitting: true });

    const { medications, generalHx } = this.props;
    const { user } = this.props;
    const { id } = this.props.location.query;
    const formattedMedications = medications.map((med) => {
      // Id in this case is just something generated locally
      return omit(med, ['id', 'user_id']);
    });

    const options = makeShadowOptions({ medication_hx: formattedMedications }, this.props.user.token, 'POST');

    apiFetch(`/users/${user.id}/medication_hx_bulk`, options)
      .then(() => {
        const generalHxOptions = makeShadowOptions({ ...generalHx }, this.props.user.token, 'POST');
        return apiFetch(`/users/${user.id}/general_hx_pro`, generalHxOptions)
      })
      .then(() => {
        const proFormattedMeds = formattedMedications.length ? formattedMedications.map(m => `${m.name} - ${m.dosage} ${m.frequency}`).concat(', ') : 'None Reported';
        const allergicTo = generalHx.drug_allergies ? `Allergic to: ${generalHx.drug_allergies_list} | Reactions: ${generalHx.drug_allergies_reaction}` : 'No listed drug allergies.';
        const steroidInjections = generalHx.steroid_injections ? `Steroid injection area of treatment: ${generalHx.steroid_injections_area} | Response: ${generalHx.steroid_response}` : 'No steroid Injections.';
        const anesthesiaProblems = generalHx.anesthesia ? `Anesthesia Problems: ${generalHx.anesthesia_reaction}` : 'No anesthesia problems reported.';

        const proFormattedData = `${allergicTo} ${steroidInjections} ${anesthesiaProblems} | Medications: ${proFormattedMeds}`;

        return this.props.submitPRO({ userId: user.id, id }, {
          pro_type: 'MEDICATIONS',
          pro_data: { data: [proFormattedData] },
        }, user);
      })
      .then(() => this.finishTrack())
      .catch(e => this.setState({ error: e, submitting: false }));
  }

  render() {
    const { generalHx } = this.props;
    return (
      <Layout>
        <div>
          <AppBar
            backButtonOnClick={() => this.props.router.goBack()}
            headerNode="Reactions"
          />
          <ToggleTitle
            label="Do you have any drug allergies?"
            onToggle={this.toggleDrugAllergies}
            toggled={generalHx.drug_allergies}
          />
          <section style={baseStyles.section}>
            {generalHx.drug_allergies ? (
              <div>
                <TextField
                  placeholder="What drugs are you allergic to? *"
                  label="What drugs are you allergic to? *"
                  fullWidth
                  style={baseStyles.marginLeft}
                  value={generalHx.drug_allergies_list || ''}
                  onChange={this.handleChangeDrugAllergiesList}
                  InputProps={{
                    disableUnderline: true,
                    style: { fontSize: '13px' },
                  }}
                />
                <Divider />
                <MultilineTextField
                  hintText="Describe adverse reactions..."
                  rows={3}
                  value={generalHx.drug_allergies_reaction}
                  onChange={this.handleChangeDrugAllergiesReaction}
                />
              </div>
            ) : null}
          </section>
          <ToggleTitle
            label="Ever received steroid injections?"
            onToggle={this.toggleSteroidInjections}
            toggled={generalHx.steroid_injections}
          />
          <section style={baseStyles.section}>
            {generalHx.steroid_injections ? (
              <div>
                <TextField
                  placeholder="Area of Treatment"
                  label="Area of Treatment"
                  fullWidth
                  style={baseStyles.marginLeft}
                  value={generalHx.steroid_injections_area}
                  onChange={this.handleChangeSteroidInjectionsArea}
                  InputProps={{
                    disableUnderline: true,
                    style: { fontSize: '13px' },
                  }}
                />
                <Divider />
                <MultilineTextField
                  hintText="What was your response to this treatment?"
                  rows={3}
                  value={generalHx.steroid_response}
                  onChange={this.handleChangeSteroidResponse}
                />
              </div>
            ) : null}
          </section>
          <ToggleTitle
            label="Ever had problems with Anesthesia?"
            onToggle={this.toggleAnesthesia}
            toggled={generalHx.anesthesia}
          />
          <section style={baseStyles.section}>
            {generalHx.anesthesia ? (
              <Fragment>
                <MultilineTextField
                  hintText="Please explain..."
                  rows={3}
                  value={generalHx.anesthesia_reaction}
                  onChange={this.handleChangeAnesthesiaReaction}
                />
                <Divider style={{ marginBottom: '15px' }} />
              </Fragment>
            ) : null}
          </section>
          <Continue text="Update" onClick={this.handleComplete} />
        </div>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  const { user, proForms } = state;
  return {
    medications: proForms.medications[0] || [],
    generalHx: proForms.medications[1] || {},
    user,
  };
}

export default connect(mapStateToProps, {
  submitPRO,
  updatePRO,
})(Reactions);
