const en = {
  woundPro1: {
    bottom: 'Bottom',
    confirm: 'Confirm',
    help: 'Help ?',
    tapOnBodyImageToSelectLocation: 'Tap on the body image to select the location of your wound <br /> Tap the <span style="color:red;">&lt; &gt;</span> to rotate the image to the side or front of the body',
    tapOnBodyImageToSelectLocationPainTravels: 'Tap on the body image to select the location to where your pain travels <br /> Tap the <span style="color:red;">&lt; &gt;</span> to rotate the image to the side or front of the body',
    tapToConfirmOrErase: `Tap the Confirm button below to confirm this location <br /> or <br /> Tap the <span>${String.fromCharCode('9746')}</span> to erase the incorrect selection and choose a different location.`,
    top: 'Top',
    isTopOrBottomOfFoot: 'Is this on the top or bottom of the foot?',
    'View Back': 'View Back',
    'View Front': 'View Front',
    'View Left': 'View Left',
    'View Right': 'View Right',
  },
};

const es = {
  woundPro1: {
    bottom: 'Bottom',
    confirm: 'Confirm',
    help: 'Help ?',
    tapOnBodyImageToSelectLocation: 'Tap on the body image to select the location of your wound <br /> Tap the <span style="color:red;">&lt; &gt;</span> to rotate the image to the side or front of the body',
    tapOnBodyImageToSelectLocationPainTravels: 'Tap on the body image to select the location to where your pain travels <br /> Tap the <span style="color:red;">&lt; &gt;</span> to rotate the image to the side or front of the body',
    tapToConfirmOrErase: `Tap the Confirm button below to confirm this location <br /> or <br /> Tap the <span>${String.fromCharCode('9746')}</span> to erase the incorrect selection and choose a different location.`,
    top: 'Top',
    isTopOrBottomOfFoot: 'Is this on the top or bottom of the foot?',
    'View Back': 'View Back',
    'View Front': 'View Front',
    'View Left': 'View Left',
    'View Right': 'View Right',
  },
};

export default { en, es };
