import React from 'react';
import { LinearProgress, Slider } from '@material-ui/core';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import AppBar from '../components/app-bar';
import YesNoButtonSet from '../components/yes-no-button-set';

import QuestionLayout from '../layouts/question';

import i18nTranslator, { title, nextBtn } from '../lib/i18next';
import { Smile } from '../lib/icons';
import { colors, painScaleColors } from '../lib/styles';

import Page from './page';

import { submitPRO, updatePRO } from '../state/pro-forms';

const styles = {
  contentWrapper: {
    flexGrow: 1,
    overflowX: 'hidden',
    position: 'relative',
  },
  imgRow: {
    display: 'flex',
    justifyContent: 'center',
    padding: '15px 60px',
  },
  imageWrapper: {
    width: 100,
  },
  paragraph: {
    marginLeft: '15px',
    marginRight: '15px',
    marginBottom: '15px',
    fontSize: '22px',
  },
  sliderContainer: {
    margin: '0px 30px',
    position: 'relative',
  },
  questionnaireContainer: {
    paddingLeft: 20,
    paddingRight: 20,
  },
  questionText: {
    fontSize: 29,
    margin: '25px 10px 0px',
  },
  select: {
    fontSize: 29,
    marginTop: 5,
  },
  slider: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '50px',
    fontSize: '14px',
  },
  sliderStyle: {
    flexGrow: 1,
    marginBottom: 24,
  },
  sliderLabel: {
    fontSize: 16,
    maxWidth: 100,
    textAlign: 'center',
  },
};

class PisaInjection4 extends Page {
  constructor(props) {
    super(props);

    this.state = {
      painRelief: '',
      followUpAppt: '',
      theme: this.getSliderTheme(0),
      painReliefPercentage: 50,
      currentPainLevel: 0,
    };
  }

  getSliderTheme = (value) => {
    return {
      color: painScaleColors[value],
    };
  }

  handlePainLevelSlider = (e, value) => {
    const theme = this.getSliderTheme(value);
    this.setState({ theme, currentPainLevel: value });
  }

  handlePainReliefSlider = (e, value) => {
    this.setState({ painReliefPercentage: value });
  }

  handleChangeFollowUpAppt = (value) => {
    this.setState({ followUpAppt: value });
  };

  handleChangeReducePain = (value) => {
    this.setState({ painRelief: value });
  };


  handleClickNext = () => {
    const { pisaInjection, user } = this.props;
    const { id } = this.props.location.query;

    const {
      painRelief,
      followUpAppt,
      painReliefPercentage,
      currentPainLevel,
    } = this.state;

    const value = { painRelief, followUpAppt, painReliefPercentage, currentPainLevel };

    this.props.submitPRO({ userId: user.id, id }, {
      pro_type: 'PISA-INJECTION',
      pro_data: {
        data: [
          ...pisaInjection,
          value,
        ],
      },
    }, user)
      .then(() => this.finishTrack());
  }

  render() {
    const { classes } = this.props;
    const {
      painRelief,
      followUpAppt,
      painReliefPercentage,
      currentPainLevel,
      theme,
    } = this.state;

    let continueDisabled = true;

    if (painRelief === 'N' && followUpAppt !== '') {
      continueDisabled = false;
    }

    if (painRelief === 'Y') {
      continueDisabled = false;
    }

    return (
      <QuestionLayout
        primaryBtnLabel={nextBtn()}
        primaryBtnOnTouchTap={this.handleClickNext}
        primaryBtnDisabled={continueDisabled}
        style={{ backgroundColor: colors.questionBackground }}
      >
        <AppBar
          headerNode={title('pisaInjection4')}
          backButtonOnClick={() => this.props.router.goBack()}
        />
        <LinearProgress
          variant="determinate"
          value={(this.getCurrentPage() / this.getTotalPages()) * 100}
          classes={{ bar: classes.bar }}
        />
        <div className={classes.contentWrapper}>
          <div className={classes.imgRow}>
            <div className={classes.imageWrapper}>
              <Smile />
            </div>
          </div>
          <div className={classes.questionnaireContainer}>
            <YesNoButtonSet
              isYes={painRelief === 'Y'}
              isNo={painRelief === 'N'}
              onClickNo={() => this.handleChangeReducePain('N')}
              onClickYes={() => this.handleChangeReducePain('Y')}
              questionText={i18nTranslator('question1', 'pisaInjection4')}
              questionTextSize={29}
            />
            {
              painRelief === 'Y' && (
                <div style={styles.contentWrapper}>
                  <p style={styles.paragraph}>{i18nTranslator('painReliefSlider', 'pisaInjection4')}</p>
                  <div style={styles.sliderContainer}>
                    <Slider
                      min={50}
                      max={100}
                      value={painReliefPercentage}
                      valueLabelDisplay="on"
                      onChange={this.handlePainReliefSlider}
                      style={{ ...styles.slider }}
                    />
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                      <div style={{ ...styles.sliderLabel, marginBottom: '50px' }}>
                        50%
                      </div>
                      <div style={{ ...styles.sliderLabel, marginBottom: '50px' }}>
                        100%
                      </div>
                    </div>
                  </div>
                  <p style={styles.paragraph}>{i18nTranslator('currentPainSlider', 'pisaInjection4')}</p>
                  <div style={styles.sliderContainer}>
                    <Slider
                      min={0}
                      max={10}
                      value={currentPainLevel}
                      valueLabelDisplay="on"
                      onChange={this.handlePainLevelSlider}
                      style={{ ...styles.slider, ...theme }}
                    />
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                      <div style={{ ...styles.sliderLabel, marginBottom: '50px' }}>
                        {i18nTranslator('noPain', 'pisaInjection4')}
                      </div>
                      <div style={{ ...styles.sliderLabel, marginBottom: '50px' }}>
                        {i18nTranslator('worstPain', 'pisaInjection4')}
                      </div>
                    </div>
                  </div>
                </div>
              )
            }
            {
              painRelief === 'N' && (
                <YesNoButtonSet
                  isYes={followUpAppt === 'Y'}
                  isNo={followUpAppt === 'N'}
                  onClickNo={() => this.handleChangeFollowUpAppt('N')}
                  onClickYes={() => this.handleChangeFollowUpAppt('Y')}
                  questionText={i18nTranslator('question2', 'pisaInjection4')}
                  questionTextSize={29}
                />
              )
            }
          </div>
        </div>
      </QuestionLayout>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    proForms: {
      pisaInjection,
    },
    user,
  } = state;

  return { pisaInjection, user };
};

export default connect(mapStateToProps, { submitPRO, updatePRO })(withStyles(styles)(PisaInjection4));
