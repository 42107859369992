const en = {
  demographics2: {
    title: 'About Me',
    gender: 'Gender',
    genderOptions: {
      male: 'Male',
      female: 'Female',
      other: 'Other',
    },
    language: 'Primary Language',
    languageOptions: {
      english: 'English',
      spanish: 'Spanish',
    },
    marital: 'Marital Status',
    maritalOptions: {
      single: 'Single',
      married: 'Married',
      divorced: 'Divorced',
    },
    weight: 'Weight',
    height: 'Height',
    heightLabels: {
      feet: 'feet',
      inches: 'inches',
    },
    weightLabel: 'lbs',
    address: 'Primary Address',
    addressOptions: {
      street: 'Street Address',
      city: 'City',
      state: 'State',
      zip: 'Postal Code',
    },
  },
};

const es = {
  demographics2: {
    title: 'Sobre Mí',
    gender: 'Género',
    genderOptions: {
      male: 'Hombre',
      female: 'Mujer',
      other: 'Otro',
    },
    language: 'Lenguaje Primario',
    languageOptions: {
      english: 'Inglés',
      spanish: 'Español',
    },
    marital: 'Estado Civil',
    maritalOptions: {
      single: 'Única/o',
      married: 'Casada/o',
      divorced: 'Divorciada/o',
    },
    weight: 'Peso',
    height: 'Estatura',
    weightLabel: 'libras',
    heightLabels: {
      feet: 'pies',
      inches: 'pulgadas',
    },
    address: 'Dirección Principal',
    addressOptions: {
      street: 'Dirección',
      city: 'Nombre De La Cuidad',
      state: 'Estado',
      zip: 'Código Postal',
    },
  },
};

export default { en, es };
