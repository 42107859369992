import React from 'react';
import { connect } from 'react-redux';

import AppBar from '../components/app-bar';
import Page from './page';
import Layout from '../layouts/common';
import Continue from '../components/continue';
import { colors } from '../lib/styles';
import { logEvent } from '../lib/amplitude';

const baseStyles = {
  blueText: {
    color: colors.primaryColor,
  },
  mainContent: {
    flexGrow: 1,
  },
  margins: {
    marginLeft: '25px',
    marginRight: '25px',
  },
  noPainBtn: {
    marginBottom: '7.5vw',
  },
  orange: {
    backgroundColor: colors.secondaryColor,
  },
  rootContainer: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: '100%',
  },
};

class GeneralPainIntro extends Page {
  render() {
    return (
      <Layout style={baseStyles.rootContainer}>
        <div style={baseStyles.mainContent}>
          <AppBar
            backButtonOnClick={() => this.props.router.goBack()}
            headerNode="General Pain Intro"
          />
          <h1 style={baseStyles.blueText}> Are you in Pain? </h1>
          <img src="/img/PainBig.png" alt="Body Pain Pic" style={baseStyles.margins} />
          <h5 style={baseStyles.blueText}>{'Time to get to the bottom of what kind of pain you\'re experiencing.'}</h5>
        </div>
        <div>
          <Continue
            text="Yes, I currently have pain"
            btnStyle={baseStyles.orange}
            onClick={() => {
              logEvent('Are You in Pain - Yes');
              this.forward();
            }}
          />
          <Continue
            btnStyle={baseStyles.noPainBtn}
            text="No Pain"
            onClick={() => this.setTrack('SIGNUP_HEADACHES')}
          />
        </div>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state;
  return {
    user,
  };
}

export default connect(mapStateToProps, { })(GeneralPainIntro);
