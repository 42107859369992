import React, { Component } from 'react';
import { get, padStart } from 'lodash';
import PropTypes from 'prop-types';
import { Paper } from '@material-ui/core';

import CalendarCurrentMonthItem from './calendar-current-month-item';
import CalendarDayLabel from './calendar-day-label';
import CalendarHeader from './calendar-header';
import CalendarOtherMonthItem from './calendar-other-month-item';
import { calendarStyles as styles } from '../lib/styles';
import CalendarPainLegend from './calendar-pain-legend';

const dayNames = [
  'SU',
  'M',
  'TU',
  'W',
  'TH',
  'F',
  'SA',
];

const renderDayLabels = () => {
  return dayNames.map(day => (
    <CalendarDayLabel day={day} key={`dayLabel${day}`} />
  ));
};

const getCalendarItems = (painLogCalendarData, month, year) => {
  const calendarItems = [];
  let dayCount = 0;

  // pad the beginning of the first row with the last days of the previous month
  const firstDayDate = new Date(year, month, 1);
  const firstDay = firstDayDate.getDay();

  if (firstDay !== 0) {
    for (let i = firstDay; i > 0; i--) {
      const prevDay = 1 - i;
      const prevDate = new Date(year, month, prevDay).getDate();

      dayCount++;
      calendarItems.push(<CalendarOtherMonthItem day={prevDate} dayCount={dayCount} key={`calendarStartPadding-${i}`} />);
    }
  }

  // render calendar items with pain information
  const lastDayDate = new Date(year, month + 1, 0);
  const lastDay = lastDayDate.getDate();

  for (let i = 1; i <= lastDay; i++) {
    const formattedDate = `${year}-${padStart(month + 1, 2, '0')}-${padStart(i, 2, '0')}`;
    const painLevel = get(painLogCalendarData, [formattedDate, 'level'], 0);

    dayCount++;
    calendarItems.push(<CalendarCurrentMonthItem
      day={i}
      dayCount={dayCount}
      key={`curr${i}`}
      month={month}
      painLevel={painLevel}
      year={year}
    />);
  }

  // pad the end of the last row with the first days of the next month
  if (dayCount % 7) {
    const remainingDays = 7 - (dayCount % 7);

    for (let i = 1; i <= remainingDays; i++) {
      dayCount++;
      calendarItems.push(<CalendarOtherMonthItem day={i} dayCount={dayCount} key={`CalendarEndPadding-${i}`} />);
    }
  }

  return calendarItems;
};

class Calendar extends Component {
  componentWillMount() {
    const currDate = new Date();
    const currMonth = currDate.getMonth();
    const currYear = currDate.getFullYear();

    this.props.updatePainLogCalendar(currMonth, currYear);
  }

  render() {
    const {
      month,
      onClickBackButton,
      onClickForwardButton,
      data,
      year,
    } = this.props;

    return (
      <Paper>
        <CalendarHeader
          month={month}
          onClickBackButton={onClickBackButton}
          onClickForwardButton={onClickForwardButton}
          year={year}
        />
        <div style={styles.calendar}>
          {renderDayLabels()}
          {getCalendarItems(data, month, year)}
        </div>
        <CalendarPainLegend />
      </Paper>
    );
  }
}

Calendar.propTypes = {
  data: PropTypes.object.isRequired,
  month: PropTypes.number.isRequired,
  onClickBackButton: PropTypes.func.isRequired,
  onClickForwardButton: PropTypes.func.isRequired,
  updatePainLogCalendar: PropTypes.func.isRequired,
  year: PropTypes.number.isRequired,
};

export default Calendar;
