import { browserHistory } from 'react-router';
import { promiseHandler, resetReducer } from 'cooldux';

const { takePictureStart, takePictureError } = promiseHandler('takePicture', 'cordova');
const { fetchCaloriesStart, fetchCaloriesError } = promiseHandler('fetchCalories', 'cordova');
const { fetchStepsStart, fetchStepsError } = promiseHandler('fetchSteps', 'cordova');
const { fetchActivityStart, fetchActivityError } = promiseHandler('fetchActivity', 'cordova');
const { takePicture, setActivityDate, setStepDate, setCalorieDate } = require('../lib/cordova-helpers');

export function navigateToDeepLink(path) {
  browserHistory.push(path);
}

export function fetchCaloriesEnd(calories, date) {
  return {
    type: 'FETCH_CALORIES_END',
    calories,
    date,
  };
}

export function fetchStepsEnd(steps, date) {
  return {
    type: 'FETCH_STEPS_END',
    steps,
    date,
  };
}

export function fetchActivityEnd(activity, date) {
  return {
    type: 'FETCH_ACTIVITY_END',
    activity,
    date,
  };
}

export function takePictureEnd(image) {
  return {
    type: 'CORDOVA_TAKE_PICTURE_END',
    image,
  };
}

export function cordovaTakePicture() {
  return (dispatch) => {
    dispatch(takePictureStart());

    return takePicture();
  };
}
export function fetchCalories(date) {
  return (dispatch) => {
    dispatch(fetchCaloriesStart());

    return setCalorieDate(date);
  };
}

export function fetchSteps(date) {
  return (dispatch) => {
    dispatch(fetchStepsStart());

    return setStepDate(date);
  };
}

export function fetchActivity(date) {
  return (dispatch) => {
    dispatch(fetchActivityStart());

    return setActivityDate(date);
  };
}


const initialState = {
  fetchActivityPending: false,
  fetchCaloriesPending: false,
  fetchStepsPending: false,
  takePicturePending: false,

  fetchActivityError: null,
  fetchCaloriesError: null,
  fetchStepsError: null,
  takePictureError: null,

  activityData: {},
  stepsData: {},
  caloriesData: {},
  image: null,
};

function finishFetchCalories(state, action) {
  return {
    ...state,
    fetchCaloriesPending: false,
    fetchCaloriesError: null,
    caloriesData: {
      ...state.caloriesData,
      [action.date]: action.calories,
    },
  };
}

function finishFetchSteps(state, action) {
  return {
    ...state,
    fetchStepsPending: false,
    fetchStepsError: null,
    stepsData: {
      ...state.stepsData,
      [action.date]: action.steps,
    },
  };
}

function finishFetchActivity(state, action) {
  return {
    ...state,
    fetchActivityPending: false,
    fetchActivityError: null,
    activityData: {
      ...state.activityData,
      [action.date]: action.activity,
    },
  };
}

const cordova = resetReducer(initialState, (state = initialState, action) => {
  switch (action.type) {
    case takePictureStart.type:
      return { ...state, takePicturePending: true, takePictureError: null };
    case 'CORDOVA_TAKE_PICTURE_END':
      return { ...state, takePicturePending: false, image: action.image };
    case takePictureError.type:
      return { ...state, takePicturePending: true, takePictureError: null };
    case fetchActivityStart.type:
      return { ...state, fetchActivityPending: true, fetchActivityError: null };
    case fetchActivityError.type:
      return { ...state, fetchActivityPending: false, fetchActivityError: action.payload };
    case 'FETCH_ACTIVITY_END':
      return finishFetchActivity(state, action);
    case fetchCaloriesStart.type:
      return { ...state, fetchCaloriesPending: true, fetchCaloriesError: null };
    case 'FETCH_CALORIES_END':
      return finishFetchCalories(state, action);
    case fetchCaloriesError.type:
      return { ...state, fetchCaloriesPending: false, fetchCaloriesError: action.payload };
    case fetchStepsStart.type:
      return { ...state, fetchStepsPending: true, fetchStepsError: null };
    case 'FETCH_STEPS_END':
      return finishFetchSteps(state, action);
    case fetchStepsError.type:
      return { ...state, fetchStepsPending: false, fetchStepsError: action.payload };
    default:
      return state;
  }
});

export default cordova;
