import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get } from 'lodash';

import { colors } from '../lib/styles';
import { Headaches } from '../lib/icons';
import Page from './page';
import Question from '../components/question';
import { updatePRO } from '../state/pro-forms';

const options = [
  {
    label: 'I have no headaches at all',
    value: 0,
  },
  {
    label: 'I have slight headaches which come infrequently',
    value: 1,
  },
  {
    label: 'I have moderate headaches, which come infrequently',
    value: 2,
  },
  {
    label: 'I have moderate headaches, which come frequently',
    value: 3,
  },
  {
    label: 'I have severe headaches, which come freqently',
    value: 4,
  },
  {
    label: 'I have headaches almost all the time',
    value: 5,
  },
];
const { questionBackground } = colors;

class NdiHeadaches extends Page {
  onClickBackButton = () => {
    this.backward();
  }

  onSubmission = () => {
    this.forwardWithQuery(this.props.location.query);
  }

  render() {
    const value = get(this.props.ndi, this.getTrackIndex(), null);
    return (
      <Question
        backgroundColor={questionBackground}
        currentPage={this.getCurrentPage()}
        image={Headaches}
        onClickBackButton={() => this.props.router.goBack()}
        onChange={this.props.updatePRO}
        onSubmission={this.onSubmission}
        options={options}
        title="Headaches"
        totalPages={this.getTotalPages()}
        trackIndex={this.getTrackIndex()}
        type="ndi"
        value={value}
      />
    );
  }
}


function mapStateToProps(state) {
  const { proForms: { ndi } } = state;
  return { ndi };
}

NdiHeadaches.propTypes = {
  ndi: PropTypes.array.isRequired,
};

export default connect(mapStateToProps, { updatePRO })(NdiHeadaches);
