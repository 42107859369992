const en = {
  videoBroadcastNotificationPage: {
    introHeaderText: 'Patient Information',
    introBodyText: 'requests that you view an important message',
    loggedInSuccessText: '',
    loggedOutSuccessText: 'You may now close this window.',
  },
};

const es = {
  videoBroadcastNotificationPage: {
    introHeaderText: 'Información Del Paciente',
    introBodyText: 'solicita que vea un mensaje importante.',
    loggedInSuccessText: '',
    loggedOutSuccessText: 'Ahora puedes cerrar esta ventana.',
  },
};

export default { en, es };
