import { colors } from './styles';

export const GRAPH_POINT_DELAY = 100;

export function getLightingStatus (red, green, blue, redSD) {
    // Uses Rasool's algorithm to determine if the lighting is good enough
    if (redSD < 40 && (red > (green * 2)) && (red > (blue * 2)) && green > 10) {
      return 'ok';
    } else if (green < 10) {
      return 'Too Dark';
    } else if (redSD > 40) {
      return 'Too Bright';
    }

    return 'Adjust Finger';
}

export class Graph {
  constructor(canvas, duration = 5000) {
    this.context = canvas.getContext('2d');

    this.canvasH = canvas.height;
    this.canvasW = canvas.width;

    this.duration = duration; // default to 5 second duration
    this.delay = 300; // 0.3 second delay
    this.devMode = false;
  }

  toggleDevMode() {
    this.devMode = !this.devMode;
  }

  draw(data, text = null) {
    const { context } = this;
    this.now = Date.now();
    this.config(data);

    context.clearRect(0, 0, this.canvasW, this.canvasH);
    context.beginPath();

    for (let i = 0; i < data.length; i++) {
      const { x, y } = data[i];
      const initialX = this.now - x;

      const pointX = this.convertX(this.duration - initialX);
      const pointY = this.convertY(y);

      if (i === 0) {
        context.moveTo(pointX, pointY);
      } else {
        context.lineTo(pointX, pointY);
      }
    }

    context.lineJoin = 'round';
    context.lineWidth = 5;
    context.strokeStyle = 'white';
    context.stroke();

    if (text) {
      context.lineWidth = 10;
      context.strokeStyle = text.content === 'ok' ? colors.successGreen : colors.errorRed;
      context.strokeRect(0, 0, this.canvasW, this.canvasH);

      if (text.content !== 'ok') {
        this.drawTextBG(text);
      }
    }
    if (this.devMode) {
      this.drawMinMax();
    }
  }

  config(data) {
    const yValues = data.map(value => value.y);

    this.minX = this.now - this.duration;
    this.maxX = this.now - this.delay;

    this.minY = Math.min(...yValues);
    this.maxY = Math.max(...yValues);

    this.rangeX = this.maxX - this.minX;
    this.rangeY = this.maxY - this.minY;
  }

  drawTextBG(text) {
    const { context, canvasW } = this;
    const { content, font } = text;

    context.save();
    context.font = `bold ${font}px serif`;
    context.textBaseline = 'top';
    context.fillStyle = 'rgba(0, 0, 0, 0.5)';
    const { width } = context.measureText(content);
    context.fillRect(5, 5, canvasW - 10, parseInt(font, 10) + 5);
    context.fillStyle = colors.white;
    context.fillText(content, (canvasW - width) / 2, 10);
    context.restore();
  }

  drawMinMax() {
    const { context, canvasW, canvasH } = this;

    context.save();
    const size = Math.round(canvasH / 8);
    context.font = `bold ${size}px serif`;
    context.textBaseline = 'top';
    context.fillStyle = colors.white;
    const topText = Number(this.maxY).toFixed(3);
    const bottomText = Number(this.minY).toFixed(3);
    const { width } = context.measureText(topText);
    context.fillText(topText, (canvasW / 2) - (width / 2), size * 2);
    context.fillText(bottomText, (canvasW / 2) - (width / 2), size * 4);
    context.restore();
  }

  convertX = (point) => {
    let res = point / this.rangeX;
    res *= this.canvasW;
    return res;
  };

  convertY = (point) => {
    let res = (point - this.minY) / this.rangeY;
    res *= this.canvasH;
    res = this.canvasH - res;
    return res;
  };

}

export function getImageDataFunc(offCanvas) {
  const getImageData = (cBuddyCtx, pvW, pvH) => {
    //crop center quarter of video
    const imgData = cBuddyCtx.getImageData(pvW / 4, pvH / 4, pvW / 2, pvH / 2);
    // and then another quarter of that size
    const imgDataSmall = new ImageData(offCanvas.width, offCanvas.height);
    let px = 0;
    for (let j = 0; j < imgData.data.length; j += 4) {
      const row = Math.floor(j / (imgData.width * 4));
      const col = (j / 4) % imgData.width;
      // odd row/column - every 4th pixel in a checker board pattern
      if (((row % 2) === 1) && ((col % 2) === 1)) {
        imgDataSmall.data[(px * 4)] = imgData.data[j];
        imgDataSmall.data[(px * 4) + 1] = imgData.data[j+1];
        imgDataSmall.data[(px * 4) + 2] = imgData.data[j+2];
        imgDataSmall.data[(px * 4) + 3] = imgData.data[j+3];
        px++;
      }
    }
    return imgDataSmall;
  };
  return getImageData;
}
