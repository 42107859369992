import { promiseHandler, resetReducer } from 'cooldux';
import { get, isUndefined, omit, omitBy } from 'lodash';

import { apiFetch } from '../lib/fetch';

const { browseStart, browseEnd, browseError, browseHandler } = promiseHandler('browse', 'painHxFunctionalLimitations');
const { addStart, addEnd, addError, addHandler } = promiseHandler('add', 'painHxFunctionalLimitations');
const { deleteStart, deleteEnd, deleteError, deleteHandler } = promiseHandler('delete', 'painHxFunctionalLimitations');

export function browsePainHxFunctionalLimitations(painHxId) {
  return function dispatcher(dispatch, getState) {
    const selfId = get(getState(), 'user.id', null);
    const promise = apiFetch(`/users/${selfId}/pain_hx/${painHxId}/functional_limitations`);
    return browseHandler(promise, dispatch);
  };
}

export function addPainHxFunctionalLimitations(newPainHxFunctionalLimitations) {
  return function dispatcher(dispatch, getState) {
    const selfId = get(getState(), 'user.id', null);
    const body = omit(newPainHxFunctionalLimitations, 'pain_hx_id');
    const fetchUrl = `/users/${selfId}/pain_hx/${newPainHxFunctionalLimitations.pain_hx_id}/functional_limitations`;
    const options = {
      method: 'POST',
      body: omitBy(body, isUndefined),
    };
    const promise = apiFetch(fetchUrl, options);
    return addHandler(promise, dispatch);
  };
}

export function deletePainHxFunctionalLimitations(painHxId, painHxFunctionalLimitationsId) {
  return function dispatcher(dispatch, getState) {
    const selfId = get(getState(), 'user.id', null);
    const fetchUrl = `/users/${selfId}/pain_hx/${painHxId}/functional_limitations/${painHxFunctionalLimitationsId}`;
    const options = {
      method: 'DELETE',
    };
    const promise = apiFetch(fetchUrl, options)
      .then(() => painHxFunctionalLimitationsId);

    return deleteHandler(promise, dispatch);
  };
}

const initialState = {
  browseError: null,
  addError: null,
  deleteError: null,
  browsePending: false,
  addPending: false,
  deletePending: false,
  data: {},
};

function finishBrowse(state, painHxFunctionalLimitations) {
  const data = {};
  painHxFunctionalLimitations.forEach((u) => {
    data[u.id] = u;
  });
  return { ...state, data, browsePending: false, browseError: null };
}

function finishAdd(state, item) {
  const data = { ...state.data, [item.functional_limitations_id]: item };
  return { ...state, addPending: false, addError: null, data };
}

function finishDelete(state, painHxFunctionalLimitationsId) {
  return { ...state, deletePending: false, deleteError: null, data: omit(state.data, painHxFunctionalLimitationsId) };
}

const painHxFunctionalLimitations = resetReducer(initialState, (state = initialState, action) => {
  switch (action.type) {
    case browseStart.type:
      return { ...state, browsePending: true };
    case browseEnd.type:
      return finishBrowse(state, action.payload);
    case browseError.type:
      return { ...state, browsePending: false, browseError: action.payload };
    case addStart.type:
      return { ...state, addPending: true };
    case addEnd.type:
      return finishAdd(state, action.payload);
    case addError.type:
      return { ...state, addPending: false, addError: action.payload };
    case deleteStart.type:
      return { ...state, deletePending: true };
    case deleteEnd.type:
      return finishDelete(state, action.payload);
    case deleteError.type:
      return { ...state, deletePending: false, deleteError: action.payload };
    default:
      return state;
  }
});

export default painHxFunctionalLimitations;
