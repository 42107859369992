import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import Page from './page';
import formatPhoneNumber from '../lib/format-phone-number';
import { colors } from '../lib/styles';
import DisableSessionTimeout from '../components/disable-session-timeout';

const styles = {
  mainContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '0px 10px',
    flexGrow: 1,
    textAlign: 'center',
  },
  greenCheckMark: {
    marginTop: 30,
    marginBottom: 30,
    width: '8rem',
    height: '8rem',
  },
  enteredValueText: {
    fontSize: '2rem',
    fontWeight: 'bold',
  },
  valueText: {
    fontSize: '1.5rem',
    marginTop: 5,
  },
  contactText: {
    fontSize: '1.5rem',
    marginTop: 30,
  },
  phoneText: {
    fontSize: '2rem',
    fontWeight: 'bold',
  },
  remotelyMonitoredText: {
    fontSize: '2rem',
    fontWeight: 'bold',
    marginTop: 30,
  },
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100%',
    justifyContent: 'space-between',
  },
  callButton: {
    backgroundColor: colors.primaryColor,
    borderRadius: '0.3125rem',
    minWidth: '17.1875rem',
    color: colors.white,
    marginBottom: '0.625rem',
    marginTop: '0.0625rem',
    width: '85vw',
    height: '4rem',
    display: 'block',
    lineHeight: '4rem',
    textDecoration: 'none',
    fontSize: '1.5rem',
    textAlign: 'center',
  },
  callButtonWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
};

class ThoracicCancerSymptoms14 extends Page {
  render() {
    const { classes, headAndNeckCancerSymptoms, user } = this.props;

    const {
      lowOxygen,
      hadFever,
      difficultySwallowing,
      gainedWeight,
      lostWeight,
      liquidStools,
      lowSystolic,
    } = headAndNeckCancerSymptoms[this.getTrackIndex() - 1];

    const lowOxygenPunctuation = hadFever || difficultySwallowing || gainedWeight || lostWeight || liquidStools || lowSystolic
      ? ','
      : '.';
    const hadFeverPunction = difficultySwallowing || gainedWeight || lostWeight || liquidStools || lowSystolic ? ',' : '.';
    const difficultySwallowingPunction = gainedWeight || lostWeight || liquidStools || lowSystolic ? ',' : '.';
    const weightChangePuncuation = liquidStools || lowSystolic ? ',' : '.';
    const liquidStoolsPunctuation = lowSystolic ? ',' : '.';

    let clinicNumber;
    if (user.requestingClinicNumber) {
      clinicNumber = formatPhoneNumber(user.requestingClinicNumber);
    }

    return (
      <div className={classes.contentWrapper}>
        <DisableSessionTimeout />
        <div className={classes.mainContent}>
          <img
            src="/img/GreenCheckmarkCircle.png"
            className={classes.greenCheckMark}
            alt="Green check mark"
          />
          <div className={classes.enteredValueText}>You have entered a value of...</div>
          {lowOxygen ? (
            <div className={classes.valueText}>{`a Sp02 value of 90% or less${lowOxygenPunctuation}`}</div>
          ) : null}
          {hadFever ? (
            <div className={classes.valueText}>{`a temperature of 100.4 or higher${hadFeverPunction}`}</div>
          ) : null}
          {difficultySwallowing ? (
            <div className={classes.valueText}>{`yes for difficulty swallowing${difficultySwallowingPunction}`}</div>
          ) : null}
          {gainedWeight ? (
            <div className={classes.valueText}>{`weight gain of 2lbs or more${weightChangePuncuation}`}</div>
          ) : null}
          {lostWeight ? (
            <div className={classes.valueText}>{`weight loss of 2lbs or more${weightChangePuncuation}`}</div>
          ) : null}
          {liquidStools ? (
            <div className={classes.valueText}>{`3 or more liquid stools in the past 24 hours${liquidStoolsPunctuation}`}</div>
          ) : null}
          {lowSystolic ? (
            <div className={classes.valueText}>90 or less for systolic blood pressure.</div>
          ) : null}
          {clinicNumber ? (
            <div className={classes.contactText}>
              Please contact the nurse navigator, who works with your oncologist, at <span className={classes.phoneText}>{clinicNumber}</span> as soon as possible
            </div>
          ) : (
            <div className={classes.contactText}>
              Please contact the nurse navigator, who works with your oncologist, as soon as possible
            </div>
          )}
          <div className={classes.remotelyMonitoredText}>
            Please tell the call center that you are a remotely monitored patient.
          </div>
          <div className={classes.contactText}>Thank you for submitting your symptom assessment. This information is important to your care. You may now close the window</div>
        </div>
        <div className={classes.callButtonWrapper}>
          {clinicNumber ? (
            <a className={classes.callButton} href={`tel:${clinicNumber}`}>CALL NOW</a>
          ) : null}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {
    proForms: {
      headAndNeckCancerSymptoms,
    },
    user,
  } = state;
  return { headAndNeckCancerSymptoms, user };
}

export default connect(mapStateToProps)(withStyles(styles)(ThoracicCancerSymptoms14));
