import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { TextareaAutosize } from '@material-ui/core';
import { connect } from 'react-redux';

import Page from './page';
import AppBar from '../components/app-bar';
import IconWrapper from '../components/icon-wrapper';
import BloomkidzLayout from '../layouts/bloomkidz';
import { ClipboardListSolid } from '../lib/icons';
import { submitPRO } from '../state/pro-forms';
import { bloomkidzStylesObj } from '../lib/bloomkidz';

const styles = {
  ...bloomkidzStylesObj,
  marginTop10: {
    marginTop: 10,
  },
};

class BloomkidzMeanAndNutrition4 extends Page {
  constructor(props) {
    super(props);

    this.state = {
      additionalHealthConcerns: '',
      additionalHealthConcernsDetails: '',
    };
  }

  handleContinue = () => {
    const {
      bloomkidzMealAndNutrition,
      location,
      submitPRO,
      user,
    } = this.props;
    const { id } = location.query;

    submitPRO({ userId: user.id, id }, {
      pro_type: 'BLOOMKIDZ-MEAL-AND-NUTRITION',
      pro_data: {
        data: {
          ...this.getDataMap(bloomkidzMealAndNutrition),
          ...this.state,
        },
        recorded_at: new Date().toISOString(),
      },
    }, user)
      .then(() => {
        this.forwardWithQuery(this.props.location.query);
      });
  }

  handleClickButton = (additionalHealthConcerns) => {
    this.setState({ additionalHealthConcerns });
  }

  handleTextAreaChange = (e) => {
    this.setState({ additionalHealthConcernsDetails: e.target.value });
  };

  render() {
    const { classes, router } = this.props;
    const { additionalHealthConcerns, additionalHealthConcernsDetails } = this.state;

    return (
      <BloomkidzLayout
        primaryBtnOnTouchTap={this.handleContinue}
        primaryBtnLabel="Next"
        primaryBtnDisabled={!additionalHealthConcerns}
      >
        <AppBar
          headerNode="Information"
          backButtonOnClick={() => router.goBack()}
        />
        <IconWrapper>
          <ClipboardListSolid />
        </IconWrapper>
        <div className={classes.pageContent}>
          <div>Does your child have any additional emergent health concerns that our team should be aware of, such as seizures, skin conditions, migraines?</div>
          <div className={classes.marginTop10}>
            If so, our nursing team will be in touch to establish additional medical protocols for your child.
          </div>
          <div className={classes.buttonRow}>
            <button
              className={`${classes.button} ${additionalHealthConcerns === 'yes' ? classes.buttonSelected : classes.buttonUnselected}`}
              onClick={() => this.handleClickButton('yes')}
              type="button"
            >
              Yes
            </button>
            <button
              className={`${classes.button} ${additionalHealthConcerns === 'no' ? classes.buttonSelected : classes.buttonUnselected}`}
              onClick={() => this.handleClickButton('no')}
              type="button"
            >
              No
            </button>
          </div>
          <TextareaAutosize
            value={additionalHealthConcernsDetails}
            onChange={this.handleTextAreaChange}
            minRows={10}
            className={classes.textAreaAutosize}
            placeholder="Tap here to type"
          />
        </div>
      </BloomkidzLayout>
    );
  }
}

BloomkidzMeanAndNutrition4.propTypes = {
  classes: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  submitPRO: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const {
    proForms: { bloomkidzMealAndNutrition },
    user,
  } = state;

  return { user, bloomkidzMealAndNutrition };
};

export default connect(mapStateToProps, { submitPRO })(withStyles(styles)(BloomkidzMeanAndNutrition4));
