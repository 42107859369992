const en = {
  pisaInjection1: {
    title: 'Injection Survey',
    inOffice: 'In Office',
    question1: 'Was your treatment performed in the office or at the ASC?',
    question2: 'What type of injection did you receive?',
    options: {
      neck: 'Neck',
      shoulders: 'Shoulders',
      upperBack: 'Upper back',
      midBack: 'Mid back',
      lowBack: 'Low back',
      hip: 'Hip',
      knee: 'Knee',
      other: 'Other',
    },
  },
};

const es = {
  pisaInjection1: {
    title: 'Encuesta de Inyección',
    inOffice: 'En La Oficina',
    question1: '¿Su tratamiento fue realizado en la oficina o en el ASC?',
    question2: '¿Qué tipo de inyección recibió?',
    options: {
      neck: 'Cuello',
      shoulders: 'Espalda',
      upperBack: 'Superior de la espalda',
      midBack: 'Espalda media',
      lowBack: 'Espalda baja',
      hip: 'Cadera',
      knee: 'Rodilla',
      other: 'Otro',
    },
  },
};

export default { en, es };
