const en = {
  eqDisclaimer: {
    title: 'Agreement',
    disclaimer: 'The information collected by the survey will only be used for research purposes to learn more about the population at risk for lower-limb complications. Participation in this survey is voluntary. If you decide to complete the survey, you will not be compensated. Results from the survey will not be shared with your physician and will not be used for diagnosis or treatment purposes.',
  },
};

const es = {
  eqDisclaimer: {
    introHeaderText: 'Contracto',
    disclaimer: 'La información recopilada por la encuesta solo se utilizará con fines de investigación para obtener más información sobre la población en risego de complicaciones en las extremidades inferiores. La participación en esta encuesta es voluntaria. Si decide completar la encuesta, no será compensado. Los resultados de la encuesta no se compartirán con su médico y no se utilizarán con fines de diagnóstico o tratamiento.',
  },
};

export default { en, es };
