import React, { Fragment } from 'react';
import PainLogDetailsSectionLabel from './pain-log-details-section-label';

import { painLogDetailsColors } from '../lib/styles';

const MigrainePainDetails = props => (
  <Fragment>
    <PainLogDetailsSectionLabel
      background={painLogDetailsColors.lightGrey}
      selections={props.painLocations}
      title={`Pain Location${(props.painLocations.length > 1) ? 's' : ''}`}
    />
    <PainLogDetailsSectionLabel
      background={painLogDetailsColors.lightGrey}
      selections={props.symptoms}
      title="Symptoms"
    />
    <PainLogDetailsSectionLabel
      background="white"
      selections={props.preventitiveMedications}
      title="Preventitive Medications"
    />
    <PainLogDetailsSectionLabel
      background="white"
      selections={props.abortiveMedications}
      title="Abortive Medications"
    />
  </Fragment>
);

export default MigrainePainDetails;
