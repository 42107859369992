import { combinedHandler } from 'cooldux';
import { get } from 'lodash';

import { apiFetch } from '../lib/fetch';

const requestOptions = {
  credentials: 'include',
  method: 'GET',
};

const {
  pinHandler,
  confirmPinHandler,
  causeHandler,
  reducerCombined,
  editHandler,
} = combinedHandler(['pin', 'confirmPin', 'cause', 'edit']);

export function resetPin() {
  return (dispatch) => {
    pinHandler(Promise.resolve(null), dispatch);
    causeHandler(Promise.resolve(null), dispatch);
  };
}

export function setPin(pin) {
  return (dispatch) => {
    return pinHandler(Promise.resolve(pin), dispatch);
  };
}

export function setConfirmPin(pin) {
  return (dispatch) => {
    return confirmPinHandler(Promise.resolve(pin), dispatch);
  };
}

export function setCause(cause) {
  return (dispatch) => {
    return causeHandler(Promise.resolve(cause), dispatch);
  };
}

export function submitPin(pinCode) {
  return (dispatch, getState) => {
    const selfId = get(getState(), 'user.id', null);
    const options = {
      ...requestOptions,
      method: 'PUT',
      body: {
        pin: pinCode,
      },
    };
    const promise = apiFetch(`/users/${selfId}`, options);
    return editHandler(promise, dispatch);
  };
}

export default reducerCombined;
