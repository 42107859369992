import React from 'react';
import { connect } from 'react-redux';
import Page from './page';
import Layout from '../layouts/common';
import EulaComponent from '../components/eula';
import { logEvent } from '../lib/amplitude';
import { colors } from '../lib/styles';
import Continue from '../components/continue';
import { editUser } from '../state/user';

const baseStyle = {
  layout: {
    height: '100%',
  },
  container: {
    padding: '5px 5px 5px 5px',
    fontSize: '.8em',
    color: colors.primaryColor,
    backgroundColor: 'white',
    height: '85%',
    overflowY: 'scroll',
  },
  centerDiv: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

class Eula extends Page {
  constructor(props) {
    super(props);
    this.handleContinue = this.handleContinue.bind(this);
  }

  handleContinue() {
    logEvent('Accept EULA (New User)');
    this.props.router.push('/signup?okTerms=true');
  }

  render() {
    return (
      <Layout style={baseStyle.layout}>
        <div style={baseStyle.container}>
          <EulaComponent />
        </div>
        <div style={baseStyle.centerDiv}>
          <Continue onClick={this.handleContinue} text="Agree" />
        </div>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state;
  return {
    user,
  };
}

export default connect(mapStateToProps, { editUser })(Eula);
