import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import {
  Checkbox,
  FormControlLabel,
  TextField,
  FormGroup,
} from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import Page from './page';
import AppBar from '../components/app-bar';
import BloomkidzLayout from '../layouts/bloomkidz';
import { updatePRO } from '../state/pro-forms';
import { bloomkidzStylesObj, bloomkidzStyles } from '../lib/bloomkidz';
import { fontSizing } from '../lib/styles';

const styles = {
  ...bloomkidzStylesObj,
  centerText: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
  },
  emphasis: {
    fontWeight: 600,
  },
  spacing: {
    margin: '20px 0 10px',
  },
};

const permissionNameLabelMap = {
  mediaDataCollection: 'Data collection',
  mediaTraining: 'Staff training and instruction/quality control of services provided',
  mediaPresentations: 'Presentations/seminars at local, national or international conferences',
};

class BloomkidzSignatures12 extends Page {
  constructor(props) {
    super(props);

    const stateFromRedux = props.bloomkidzSignatures[this.getTrackIndex()] || {};

    this.state = {
      mediaGuardian1: '',
      mediaInitial1: '',
      mediaRelationship1: '',
      mediaAcknowledgement1: false,
      mediaPermission: false,
      mediaNoPermission: false,
      mediaDataCollection: false,
      mediaTraining: false,
      mediaPresentations: false,
      ...stateFromRedux,
    };
  }

  handleCheck = (e) => {
    if (e.target.name === 'mediaNoPermission' && e.target.checked) {
      return this.setState({ mediaNoPermission: true, mediaPermission: false, mediaDataCollection: false, mediaTraining: false, mediaPresentations: false });
    }
    if (e.target.name === 'mediaPermission' && e.target.checked) {
      return this.setState({ mediaNoPermission: false, mediaPermission: true });
    }
    if (e.target.name === 'mediaPermission' && !e.target.checked) {
      return this.setState({ mediaNoPermission: false, mediaPermission: false, mediaDataCollection: false, mediaTraining: false, mediaPresentations: false });
    }
    this.setState({ [e.target.name]: e.target.checked });
  };

  handleTextFieldChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleContinue = () => {
    const { location, updatePRO } = this.props;

    updatePRO({
      type: 'bloomkidzSignatures',
      position: this.getTrackIndex(),
      value: { ...this.state },
    });

    this.forwardWithQuery(location.query);
  }

  render() {
    const { classes, router } = this.props;
    const { state } = this;
    const {
      mediaGuardian1,
      mediaInitial1,
      mediaRelationship1,
      mediaAcknowledgement1,
      mediaPermission,
      mediaNoPermission,
      mediaDataCollection,
      mediaTraining,
      mediaPresentations,
    } = state;

    // permissions must be either no permissions
    let permissionSelection = mediaNoPermission;
    // or permission with at least one of three options
    if (mediaPermission) {
      permissionSelection = mediaDataCollection || mediaTraining || mediaPresentations;
    }

    const continueDisabled = !permissionSelection || !mediaGuardian1 || !mediaInitial1 || !mediaRelationship1 || !mediaAcknowledgement1;

    return (
      <BloomkidzLayout
        primaryBtnOnTouchTap={this.handleContinue}
        primaryBtnLabel="Next"
        primaryBtnDisabled={continueDisabled}
      >
        <AppBar
          headerNode="BloomKidz Media Release"
          backButtonOnClick={() => router.goBack()}
        />
        <div className={classes.pageContent}>
          <div className={`${classes.emphasis} ${classes.spacing}`}>BloomKidz Media Release</div>
          <FormControlLabel
            control={(
              <Checkbox
                checked={mediaAcknowledgement1}
                onChange={this.handleCheck}
                name="mediaAcknowledgement1"
                icon={<CheckBoxOutlineBlankIcon />}
                checkedIcon={<CheckBoxIcon />}
                className={classes.checkbox}
              />
            )}
            label="I acknowledge that BloomKidz may require a single profile photo of my child for the patient portal. Families will have logins and access to their patient portals, similar to a medical practice."
            classes={{
              label: classes.checkboxLabel,
              root: classes.formControlLabel,
            }}
          />
          <div className={classes.paddingHorizontal}>
            <div className={classes.textFieldWrapper}>
              <TextField
                placeholder="Initial"
                value={mediaInitial1}
                onChange={this.handleTextFieldChange}
                name="mediaInitial1"
                variant="standard"
                fullWidth
                inputProps={{ style: { fontSize: fontSizing.body, fontFamily: bloomkidzStyles.fontFamily } }}
              />
            </div>
          </div>
          <div>
            BloomKidz also may use surveillance cameras inside the clinic for staff monitoring and remote therapist supervision. <span className={`${classes.heavy} ${classes.emphasis}`}>ONLY</span> BCBA supervisors and auxiliary supervisors (to be defined as supervisors within occupational, speech and/or physical therapy departments) have access to the cameras on a regular basis. From time to time, it may be necessary to take videotape of clients for the following purposes:
          </div>
          <ul>
            <li>
              To be used for data collection.
            </li>
            <li>
              To be used for staff training and instruction/quality control of services provided.
            </li>
            <li>
              To be used in presentations or seminars at local, regional, national, or international conferences.
            </li>
          </ul>
          <div>
            Your consent is necessary for us to record your child. <span className={classes.emphasis}>Under NO circumstances</span> will identifying personal information (e.g., client names) be used, other than for internal training and instructional purposes, without seeking additional consent from
          you.
          </div>
          <div className={classes.inputSectionTitle}>
            Please check the box that best corresponds with vour approval and sign below.
          </div>
          <FormControlLabel
            control={(
              <Checkbox
                checked={mediaPermission}
                onChange={this.handleCheck}
                name="mediaPermission"
                icon={<CheckBoxOutlineBlankIcon />}
                checkedIcon={<CheckBoxIcon />}
                className={classes.checkbox}
              />
            )}
            label="I hereby do give my permission to BloomKids to use videotaped materials from sessions for the following selected items:"
            classes={{
              label: classes.checkboxLabel,
              root: classes.formControlLabel,
            }}
          />
          <FormGroup>
            {Object.entries(permissionNameLabelMap).map(([name, label]) => (
              <>
                <FormControlLabel
                  control={(
                    <Checkbox
                      checked={state[name]}
                      onChange={this.handleCheck}
                      name={name}
                      icon={<CheckBoxOutlineBlankIcon fontSize="large" />}
                      checkedIcon={<CheckBoxIcon fontSize="large" />}
                      className={classes.checkbox}
                      disabled={!mediaPermission}
                    />
                  )}
                  label={label}
                  classes={{
                    label: classes.checkboxLabel,
                    root: classes.formControlLabel,
                  }}
                />
              </>
            ))}
          </FormGroup>
          <FormControlLabel
            control={(
              <Checkbox
                checked={mediaNoPermission}
                onChange={this.handleCheck}
                name="mediaNoPermission"
                icon={<CheckBoxOutlineBlankIcon />}
                checkedIcon={<CheckBoxIcon />}
                className={classes.checkbox}
              />
            )}
            label="I DO NOT give my permission to BloomKidz to use videotaped materials from sessions for the reasons stated above."
            classes={{
              label: classes.checkboxLabel,
              root: classes.formControlLabel,
            }}
          />
          <div className={`${classes.emphasis} ${classes.spacing}`}>Parent/Guardian Printed Name</div>
          <div className={classes.paddingHorizontal}>
            <div className={classes.textFieldWrapper}>
              <TextField
                placeholder="Full Name"
                value={mediaGuardian1}
                onChange={this.handleTextFieldChange}
                name="mediaGuardian1"
                variant="standard"
                fullWidth
                inputProps={{ style: { fontSize: fontSizing.body, fontFamily: bloomkidzStyles.fontFamily } }}
              />
            </div>
          </div>
          <div className={`${classes.emphasis} ${classes.spacing}`}>Relationship to the Client</div>
          <div className={classes.paddingHorizontal}>
            <div className={classes.textFieldWrapper}>
              <TextField
                placeholder="Relationship to Client"
                value={mediaRelationship1}
                onChange={this.handleTextFieldChange}
                name="mediaRelationship1"
                variant="standard"
                fullWidth
                inputProps={{ style: { fontSize: fontSizing.body, fontFamily: bloomkidzStyles.fontFamily } }}
              />
            </div>
          </div>
        </div>
      </BloomkidzLayout>
    );
  }
}

BloomkidzSignatures12.propTypes = {
  classes: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  updatePRO: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const {
    proForms: { bloomkidzSignatures },
  } = state;

  return { bloomkidzSignatures };
};

export default connect(mapStateToProps, { updatePRO })(withStyles(styles)(BloomkidzSignatures12));
