import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import {
  Checkbox,
  FormControlLabel,
  TextField,
} from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import Page from './page';
import AppBar from '../components/app-bar';
import BloomkidzLayout from '../layouts/bloomkidz';
import { updatePRO } from '../state/pro-forms';
import { bloomkidzStylesObj, bloomkidzStyles } from '../lib/bloomkidz';
import { fontSizing } from '../lib/styles';

const styles = {
  ...bloomkidzStylesObj,
  emphasis: {
    fontWeight: 600,
  },
  spacing: {
    margin: '20px 0 10px',
  },
};

class BloomkidzSignatures11 extends Page {
  constructor(props) {
    super(props);

    const stateFromRedux = props.bloomkidzSignatures[this.getTrackIndex()] || {};

    this.state = {
      consentInitial2: '',
      consentInitial3: '',
      consentInitial4: '',
      consentInitial5: '',
      consentAcknowledgement3: false,
      consentAcknowledgement4: false,
      consentAcknowledgement5: false,
      consentAcknowledgement6: false,
      ...stateFromRedux,
    };
  }

  handleCheck = (e) => {
    this.setState({ [e.target.name]: e.target.checked });
  };

  handleTextFieldChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleContinue = () => {
    const { location, updatePRO } = this.props;

    updatePRO({
      type: 'bloomkidzSignatures',
      position: this.getTrackIndex(),
      value: { ...this.state },
    });

    this.forwardWithQuery(location.query);
  }

  render() {
    const { classes, router } = this.props;
    const {
      consentInitial2,
      consentInitial3,
      consentInitial4,
      consentInitial5,
      consentAcknowledgement3,
      consentAcknowledgement4,
      consentAcknowledgement5,
      consentAcknowledgement6,
    } = this.state;

    const continueDisabled = !consentInitial2 || !consentInitial3 || !consentInitial4 || !consentInitial5 || !consentAcknowledgement3 || !consentAcknowledgement4 || !consentAcknowledgement5 || !consentAcknowledgement6;

    return (
      <BloomkidzLayout
        primaryBtnOnTouchTap={this.handleContinue}
        primaryBtnLabel="Next"
        primaryBtnDisabled={continueDisabled}
      >
        <AppBar
          headerNode="Signature"
          backButtonOnClick={() => router.goBack()}
        />
        <div className={classes.pageContent}>
          <div className={`${classes.emphasis} ${classes.spacing}`}>ACKNOWLEDGE OF NOTICE OF PRIVACY PRACTICES</div>
          <FormControlLabel
            control={(
              <Checkbox
                checked={consentAcknowledgement3}
                onChange={this.handleCheck}
                name="consentAcknowledgement3"
                icon={<CheckBoxOutlineBlankIcon />}
                checkedIcon={<CheckBoxIcon />}
                className={classes.checkbox}
              />
            )}
            label="I understand that I have certain rights to privacy regarding my protected health information. These rights are given to me under the Health Insurance Portability and Accountability Act of 1996 (HIPAA). I understand that by signing this consent I authorize BloomKidz to use and disclose my child's protected health information to carry out:"
            classes={{
              label: classes.checkboxLabel,
              root: classes.formControlLabel,
            }}
          />
          <ul>
            <li>
              Treatment (including direct or indirect treatment by other healthcare providers involved in my child&apos;s treatment)
            </li>
            <li>
              Obtaining payment from third party payers (e.g. my insurance company and billing services);
            </li>
            <li>
              The day-to-day healthcare operations of BloomKidz.
            </li>
          </ul>
          <div className={classes.paddingHorizontal}>
            <div className={classes.textFieldWrapper}>
              <TextField
                placeholder="Initial"
                value={consentInitial2}
                onChange={this.handleTextFieldChange}
                name="consentInitial2"
                variant="standard"
                fullWidth
                inputProps={{ style: { fontSize: fontSizing.body, fontFamily: bloomkidzStyles.fontFamily } }}
              />
            </div>
          </div>
          <FormControlLabel
            control={(
              <Checkbox
                checked={consentAcknowledgement4}
                onChange={this.handleCheck}
                name="consentAcknowledgement4"
                icon={<CheckBoxOutlineBlankIcon />}
                checkedIcon={<CheckBoxIcon />}
                className={classes.checkbox}
              />
            )}
            label="I understand that I have the right to request restrictions on how my protected health information is used and disclosed to carry out treatment, payment, and health care operations, but that BloomKidz is not required to agree to these requested restrictions. However, if BloomKidz does agree, BloomKidz is then bound to comply with this restriction."
            classes={{
              label: classes.checkboxLabel,
              root: classes.formControlLabel,
            }}
          />
          <div className={classes.paddingHorizontal}>
            <div className={classes.textFieldWrapper}>
              <TextField
                placeholder="Initial"
                value={consentInitial3}
                onChange={this.handleTextFieldChange}
                name="consentInitial3"
                variant="standard"
                fullWidth
                inputProps={{ style: { fontSize: fontSizing.body, fontFamily: bloomkidzStyles.fontFamily } }}
              />
            </div>
          </div>
          <FormControlLabel
            control={(
              <Checkbox
                checked={consentAcknowledgement5}
                onChange={this.handleCheck}
                name="consentAcknowledgement5"
                icon={<CheckBoxOutlineBlankIcon />}
                checkedIcon={<CheckBoxIcon />}
                className={classes.checkbox}
              />
            )}
            label="I understand that I may revoke the consent, in writing, at any time. However, any use or disclosure that occurred prior to the date I revoke consent is not affected."
            classes={{
              label: classes.checkboxLabel,
              root: classes.formControlLabel,
            }}
          />
          <div className={classes.paddingHorizontal}>
            <div className={classes.textFieldWrapper}>
              <TextField
                placeholder="Initial"
                value={consentInitial4}
                onChange={this.handleTextFieldChange}
                name="consentInitial4"
                variant="standard"
                fullWidth
                inputProps={{ style: { fontSize: fontSizing.body, fontFamily: bloomkidzStyles.fontFamily } }}
              />
            </div>
          </div>
          <FormControlLabel
            control={(
              <Checkbox
                checked={consentAcknowledgement6}
                onChange={this.handleCheck}
                name="consentAcknowledgement6"
                icon={<CheckBoxOutlineBlankIcon />}
                checkedIcon={<CheckBoxIcon />}
                className={classes.checkbox}
              />
            )}
            label="I understand that the complete Privacy Policy is available to me at any time."
            classes={{
              label: classes.checkboxLabel,
              root: classes.formControlLabel,
            }}
          />
          <div className={classes.paddingHorizontal}>
            <div className={classes.textFieldWrapper}>
              <TextField
                placeholder="Initial"
                value={consentInitial5}
                onChange={this.handleTextFieldChange}
                name="consentInitial5"
                variant="standard"
                fullWidth
                inputProps={{ style: { fontSize: fontSizing.body, fontFamily: bloomkidzStyles.fontFamily } }}
              />
            </div>
          </div>
        </div>
      </BloomkidzLayout>
    );
  }
}

BloomkidzSignatures11.propTypes = {
  classes: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  updatePRO: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const {
    proForms: { bloomkidzSignatures },
  } = state;

  return { bloomkidzSignatures };
};

export default connect(mapStateToProps, { updatePRO })(withStyles(styles)(BloomkidzSignatures11));
