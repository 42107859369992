import React from 'react';
import { LinearProgress, TextField } from '@material-ui/core';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import AppBar from '../components/app-bar';
import Page from './page';
import QuestionLayout from '../layouts/question';
import YesNoButtonSet from '../components/yes-no-button-set';
import { colors, fontSizing } from '../lib/styles';
import { updatePRO } from '../state/pro-forms';
import { Spo2 } from '../lib/icons';
import CenteredIcon from '../components/centered-icon';

const styles = {
  bodyText: {
    fontSize: fontSizing.body,
  },
  breathingText: {
    fontSize: '2rem',
    fontWeight: 'bold',
  },
  inputField: {
    width: '6rem',
    '& input': {
      textAlign: 'center',
    },
  },
  pageContent: {
    fontSize: fontSizing.body,
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 20,
  },
  questionSubtext: {
    fontSize: fontSizing.smallX,
    fontStyle: 'italic',
  },
  questionText: {
    marginTop: 25,
  },
  textFieldContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  textFieldWrapper: {
    alignItems: 'center',
    display: 'flex',
  },
  unitsWrapper: {
    fontSize: fontSizing.smallX,
    fontWeight: 'bold',
    marginLeft: 5,
  },
};

class RespiratoryOncology1 extends Page {
  constructor(props) {
    super(props);

    this.state = {
      breathingWorseWithActivity: null,
      increasedShortnessOfBreath: null,
      onHomeOxygen: null,
      SPO2: '',
    };
  }

  handleYesNoChange = (field, value) => {
    this.setState({ [field]: value });
  }

  handleSPO2 = (e) => {
    this.setState({ SPO2: e.target.value });
  }

  handleClickNext = () => {
    const {
      breathingWorseWithActivity,
      increasedShortnessOfBreath,
      onHomeOxygen,
      SPO2,
    } = this.state;

    const value = { increasedShortnessOfBreath };

    if (increasedShortnessOfBreath) {
      value.breathingWorseWithActivity = breathingWorseWithActivity;
      value.onHomeOxygen = onHomeOxygen;
      if (SPO2 !== '') value.SPO2 = SPO2;
    }

    this.props.updatePRO({
      type: 'respiratoryOncology',
      position: this.getTrackIndex(),
      value,
    });

    this.forwardWithQuery(this.props.location.query);
  }

  render() {
    const { classes } = this.props;
    const {
      breathingWorseWithActivity,
      increasedShortnessOfBreath,
      onHomeOxygen,
      SPO2,
    } = this.state;

    const continueDisabled = increasedShortnessOfBreath === null
      || (increasedShortnessOfBreath === 'Y' && breathingWorseWithActivity === null)
      || (increasedShortnessOfBreath === 'Y' && onHomeOxygen === null);

    return (
      <QuestionLayout
        primaryBtnLabel="Next"
        primaryBtnOnTouchTap={this.handleClickNext}
        primaryBtnDisabled={continueDisabled}
        style={{ backgroundColor: colors.questionBackground }}
      >
        <AppBar
          headerNode="Respiratory"
          backButtonOnClick={() => this.props.router.goBack()}
        />
        <LinearProgress
          variant="determinate"
          value={(this.getCurrentPage() / this.getTotalPages()) * 100}
          classes={{ bar: classes.bar }}
        />
        <div className={classes.pageContent}>
          <CenteredIcon>
            <Spo2 />
          </CenteredIcon>
          <YesNoButtonSet
            isYes={increasedShortnessOfBreath === 'Y'}
            isNo={increasedShortnessOfBreath === 'N'}
            onClickNo={() => this.handleYesNoChange('increasedShortnessOfBreath', 'N')}
            onClickYes={() => this.handleYesNoChange('increasedShortnessOfBreath', 'Y')}
            questionText={(
              <div className={classes.bodyText}>
                Do you have <strong>increased</strong> shortness of breath?
              </div>
            )}
          />
          {increasedShortnessOfBreath === 'Y' ? (
            <>
              <YesNoButtonSet
                isYes={breathingWorseWithActivity === 'Y'}
                isNo={breathingWorseWithActivity === 'N'}
                onClickNo={() => this.handleYesNoChange('breathingWorseWithActivity', 'N')}
                onClickYes={() => this.handleYesNoChange('breathingWorseWithActivity', 'Y')}
                questionText="Is it worse with activity?"
              />
              <YesNoButtonSet
                isYes={onHomeOxygen === 'Y'}
                isNo={onHomeOxygen === 'N'}
                onClickNo={() => this.handleYesNoChange('onHomeOxygen', 'N')}
                onClickYes={() => this.handleYesNoChange('onHomeOxygen', 'Y')}
                questionText="Are you on home oxygen?"
              />
              <div className={classes.questionText}>What is your current oxygen saturation?</div>
              <div className={classes.questionSubtext}>{'(leave blank if you don\'t know it)'}</div>
              <div className={classes.textFieldContainer}>
                <div className={classes.textFieldWrapper}>
                  <TextField
                    className={classes.inputField}
                    onChange={this.handleSPO2}
                    type="number"
                    inputProps={{
                      inputMode: 'decimal',
                      type: 'text',
                      style: { fontSize: fontSizing.body } }}
                    value={SPO2}
                    placeholder="0"
                  />
                  <span className={classes.unitsWrapper}>%</span>
                </div>
              </div>
             </>
          ) : null}
        </div>
      </QuestionLayout>
    );
  }
}

export default connect(null, { updatePRO })(withStyles(styles)(RespiratoryOncology1));
