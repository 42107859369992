import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { IconButton, TextField } from '@material-ui/core';
import { connect } from 'react-redux';

import Page from './page';
import AppBar from '../components/app-bar';
import IconWrapper from '../components/icon-wrapper';
import BloomkidzLayout from '../layouts/bloomkidz';
import { ClipboardListSolid, XInCircle } from '../lib/icons';
import { fontSizing } from '../lib/styles';
import { updatePRO } from '../state/pro-forms';
import { bloomkidzStyles, bloomkidzStylesObj } from '../lib/bloomkidz';

const styles = {
  ...bloomkidzStylesObj,
  allergyButton: {
    marginBottom: 30,
    marginTop: 10,
  },
  allergyListText: {
    marginBottom: 10,
  },
  allergyListItem: {
    alignItems: 'center',
    background: 'white',
    display: 'flex',
    marginBottom: 10,
    marginTop: 10,
  },
  allergyListItemText: {
    flexGrow: 1,
    paddingLeft: 10,
  },
  iconButton: {
    height: 50,
    width: 50,
  },
};

class BloomkidzMeanAndNutrition1 extends Page {
  constructor(props) {
    super(props);

    const stateFromRedux = props.bloomkidzMealAndNutrition[this.getTrackIndex()] || {};

    this.state = {
      allergies: [],
      curAllergy: '',
      ...stateFromRedux,
    };
  }

  handleTextFieldChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleContinue = () => {
    const { location, updatePRO } = this.props;
    updatePRO({
      type: 'bloomkidzMealAndNutrition',
      position: this.getTrackIndex(),
      value: { ...this.state },
    });

    this.forwardWithQuery(location.query);
  }

  addAllergy = () => {
    const { allergies, curAllergy } = this.state;

    if (curAllergy) {
      this.setState({
        allergies: [...allergies, curAllergy],
        curAllergy: '',
      });
    }
  }

  removeAllergy = (allergy) => {
    this.setState(curState => ({
      allergies: curState.allergies.filter(curAllergy => curAllergy !== allergy),
    }));
  }

  render() {
    const { classes, router } = this.props;
    const { allergies, curAllergy } = this.state;

    const buttonClass = curAllergy
      ? `${classes.buttonGreenBackground} ${classes.allergyButton}`
      : `${classes.buttonWithBorder} ${classes.allergyButton}`;

    return (
      <BloomkidzLayout
        primaryBtnOnTouchTap={this.handleContinue}
        primaryBtnLabel={allergies.length ? 'Next' : 'Skip'}
      >
        <AppBar
          headerNode="Allergy"
          backButtonOnClick={() => router.goBack()}
        />
        <IconWrapper>
          <ClipboardListSolid />
        </IconWrapper>
        <div className={classes.pageContent}>
          <div>Please list any relevent allergies by selecting the tabs below.</div>
          <div className={classes.paddingHorizontal}>
            <div className={classes.textFieldWrapper}>
              <TextField
                placeholder="Child allergy"
                value={curAllergy}
                onChange={this.handleTextFieldChange}
                name="curAllergy"
                variant="standard"
                fullWidth
                inputProps={{ style: { fontSize: fontSizing.body, fontFamily: bloomkidzStyles.fontFamily } }}
              />
            </div>
          </div>
          <button
            className={buttonClass}
            onClick={this.addAllergy}
            type="button"
          >
            + Add Allergy
          </button>
          <div className={classes.allergyListText}>
            Allergy List
          </div>
          {allergies.length ? (
            <div className={classes.paddingHorizontal}>
              {allergies.map(allergy => (
                <div className={classes.allergyListItem}>
                  <div className={classes.allergyListItemText} key={allergy}>{allergy}</div>
                  <IconButton
                    className={classes.iconButton}
                    onClick={() => this.removeAllergy(allergy)}
                  >
                    <XInCircle />
                  </IconButton>
                </div>
              ))}
            </div>
          ) : (
            <div className={classes.greyText}><i>No Allergies</i></div>
          )}
        </div>
      </BloomkidzLayout>
    );
  }
}

BloomkidzMeanAndNutrition1.propTypes = {
  classes: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  updatePRO: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const {
    proForms: { bloomkidzMealAndNutrition },
  } = state;

  return { bloomkidzMealAndNutrition };
};

export default connect(mapStateToProps, { updatePRO })(withStyles(styles)(BloomkidzMeanAndNutrition1));
