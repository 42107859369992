import React from 'react';
import PropTypes from 'prop-types';
import Layout from '../layouts/common';
import Button from '@material-ui/core/Button';

const NotificationAudio = (props) => {
  return (
    <Layout>
      <audio
        key={props.src}
        style={{ margin: '40px auto', width: '80%' }}
        src={props.src}
        controls
        onPlay={props.onPlayAudio}
      >
        Your browser does not support audio replay
      </audio>
      <p>When you're finished listening to the audio click the button below</p>
      <Button
        color="primary"
        backgroundColor="rgba(32, 123, 204, 0.2)"
        style={{ margin: '10px' }}
        variant="contained"
        onClick={() => props.onDismissNotification()}
      >Dismiss
      </Button>
    </Layout>
  );
}

NotificationAudio.propTypes = {
  src: PropTypes.string.isRequired,
  onDismissNotification: PropTypes.func.isRequired,
  onPlayAudio: PropTypes.func.isRequired,
}

export default NotificationAudio;
