import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { debounce, filter, forEach, get, map } from 'lodash';

import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';

import { withStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import TextField from '@material-ui/core/TextField';

import Autocomplete from '@material-ui/lab/Autocomplete';

import AppBar from '../components/app-bar';
import Continue from '../components/continue';

import { apiFetch } from '../lib/fetch';
import { WaterDrop } from '../lib/icons';
import { colors } from '../lib/styles';

import Page from './page';

import { updatePRO } from '../state/pro-forms';
import { addMedicationHx } from '../state/medication-hx';

const styles = {
  autoComplete: {
    width: 300,
    margin: 'auto',
  },
  button: {
    margin: 5,
    width: 150,
    height: 40,
    fontSize: 16,
  },
  buttonGroup: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  iconContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    flexGrow: 0,
    flexShrink: 0,
    width: '100%',
    paddingTop: 10,
  },
  iconWrapper: {
    width: 100,
    marginTop: 10,
  },
  titleGroup: {
    margin: 25,
  },
  text: {
    fontSize: '22px',
  },
  nextBtn: {
    textAlign: 'center',
    width: '100%',
    top: '90%',
    position: 'fixed',
  },
  search: {
    position: 'relative',
    borderRadius: 15,
    backgroundColor: '#888',
    '&:hover': {
      backgroundColor: '#444',
    },
    marginRight: 10,
    marginLeft: 10,
    width: '100%',
  },
  searchIcon: {
    padding: 0,
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  listItem: {
    borderBottom: `1px solid ${colors.primaryHighlight}`,
    color: colors.primaryColor,
    textAlign: 'left',
  },
};

const StyledTextField = withStyles(() => ({
  root: {
    '& .MuiInputBase-root': {
      fontSize: '16px',
    },
  },
}))(TextField);

class Diabetes3 extends Page {
  constructor(props) {
    super(props);

    if (this.props.diabetesInitial[2]) {
      this.state = this.props.diabetesInitial[2];
    } else {
      this.state = {
        data: [],
        diabetesRx: null,
        medicationList: [],
      };
    }

    this.handleUpdateInput = debounce(this.handleUpdateInput.bind(this), 250, { trailing: true });
    this.handleSelectItem = this.handleSelectItem.bind(this);
    this.handleContinue = this.handleContinue.bind(this);
  }

  handleUpdateInput(name) {
    if (!name) {
      return this.setState({
        data: [],
      });
    }

    apiFetch('/goodrx', { query: { name } })
      .then((response) => {
        this.setState({
          data: get(response, 'data.candidates', []),
        });
      });
  }

  handleSelectItem(item) {
    this.props.updatePRO({
      type: 'diabetesInitial',
      position: this.getTrackIndex(),
      value: this.state });
    this.props.router.push(`/medication?name=${item}`);
  }

  handleRemoveMedication(e) {
    const { medicationList } = this.state;
    const results = filter(medicationList, (med) => {
      return med.name !== e;
    });
    this.setState({ medicationList: [...results] });
  }

  handleContinue() {
    const { medicationList } = this.state;
    forEach(medicationList, (med) => {
      this.props.addMedicationHx(med).catch(() => {});
    });
    this.props.updatePRO({
      type: 'diabetesInitial',
      position: this.getTrackIndex(),
      value: this.state });
    this.forwardWithQuery(this.props.location.query);
  }

  render() {
    const { diabetesRx, medicationList } = this.state;

    let disabled;

    if (diabetesRx === true && medicationList.length) {
      disabled = false;
    } else if (diabetesRx === false) {
      disabled = false;
    } else {
      disabled = true;
    }

    const medications = map(medicationList, (med) => {
      return (
        <ListItem
          button
          style={styles.listItem}
          value={med.name}
        >
          <ListItemText
            primary={med.name}
          />
          <ListItemIcon onClick={() => this.handleRemoveMedication(med.name)}>
            <RemoveCircleIcon style={{ color: 'red' }} />
          </ListItemIcon>
        </ListItem>
      );
    });

    return (
      <Fragment>
        <AppBar
          headerNode="Medications"
          noLeftNode={true}
        />
        <LinearProgress
          variant="determinate"
          value={(this.getCurrentPage() / this.getTotalPages()) * 100}
        />
        <div style={styles.iconContainer}>
          <div style={styles.iconWrapper}>
            <WaterDrop />
          </div>
        </div>
        <div style={styles.titleGroup}>
          <p style={styles.text}>Are you taking any medication for diabetes?</p>
          <div style={styles.buttonGroup}>
            <Button style={styles.button} variant="contained" color={diabetesRx ? 'primary' : 'default'} onClick={() => this.setState({ diabetesRx: true })}>Yes</Button>
            <Button style={styles.button} variant="contained" color={diabetesRx === false ? 'primary' : 'default'} onClick={() => this.setState({ diabetesRx: false })}>No</Button>
          </div>
        </div>
        <div className={`${diabetesRx ? '' : 'hidden'}`} style={styles.titleGroup}>
          <p style={styles.text}>List all your medications:</p>
          <div style={styles.mainContainer}>
            <Autocomplete
              freeSolo
              onChange={(e, newValue) => {
                this.handleSelectItem(newValue);
              }}
              value={this.state.autocompleteInput}
              inputValue={this.state.autocompleteInput}
              onInputChange={(e, newInputValue) => {
                this.handleUpdateInput(newInputValue);
                this.setState({ autocompleteInput: newInputValue });
              }}
              options={this.state.data}
              filterSelectedOptions
              style={styles.autoComplete}
              renderInput={params => <StyledTextField {...params} placeholder="Search..." />}
            />
          </div>
          <List>
            {medications}
          </List>
        </div>

        <div style={styles.nextBtn}>
          <Continue
            disabled={disabled}
            text="Next"
            onClick={this.handleContinue}
          />
        </div>

      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { user, proForms: { diabetesInitial } } = state;
  return { user, diabetesInitial };
}

Diabetes3.proptypes = {
  user: PropTypes.object.isRequired,
  route: PropTypes.object.isRequired,
  updatePRO: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { addMedicationHx, updatePRO })(Diabetes3);
