import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { colors } from '../lib/styles';

const baseStyles = {
  text: {
    fontSize: '3vh',
    fontWeight: 600,
    color: colors.primaryColor,
  },
};

class QuestionHeader extends Component {
  render() {
    const { children } = this.props;
    return (
      <div style={baseStyles.container}>
        <p style={baseStyles.text}>
          {children}
        </p>
      </div>
    );
  }
}

QuestionHeader.propTypes = {
  children: PropTypes.string.isRequired,
};

export default QuestionHeader;
