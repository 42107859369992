import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get, isNumber } from 'lodash';
import { colors } from '../lib/styles';
import Page from './page';
import { Headaches } from '../lib/icons';
import { updatePRO, submitPRO } from '../state/pro-forms';
import AppBar from '../components/app-bar';
import ReactSlider from '../components/react-slider';
import QuestionLayout from '../layouts/question';
import LinearProgress from '../components/linear-progress';

const styles = {
  instructions: {
    fontSize: '1.3rem',
  },
  image: {
    height: 120,
    padding: '15px 60px',
    textAlign: 'center',
  },
  linearProgress: {
    borderRadius: 0,
  },
  pageIndicator: {
    color: 'grey',
    display: 'inline-block',
    fontSize: 13,
  },
  questionnaire: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginLeft: 15,
    position: 'relative',
    minHeight: '350px',
  },
  slideContainer: {
    display: 'flex',
    flexDirection: 'column',
    color: colors.darkGrayText,
    fontSize: '12px',
  },
};

const { questionBackground } = colors;

class PrgEndo2 extends Page {
  onClickBackButton = () => {
    this.backward();
  }

  handleChangeSlider = (value) => {
    this.props.updatePRO({
      type: 'prgendo',
      position: this.getTrackIndex(),
      value,
    });
  }
  handleSubmission = () => {
    this.forwardWithQuery(this.props.location.query);
  }

  render() {
    const value = get(this.props.prgendo, this.getTrackIndex(), null);
    const totalPages = this.getTotalPages();
    const currentPage = this.getCurrentPage();

    return (
      <QuestionLayout
        primaryBtnDisabled={!isNumber(value)}
        primaryBtnLabel="Next"
        primaryBtnOnTouchTap={this.handleSubmission}
        style={{ background: questionBackground }}
      >
        <AppBar
          backButtonOnClick={() => this.props.router.goBack()}
          headerNode="Patient Survey"
          rightNode={
            <div style={styles.pageIndicator}>{`${currentPage} of ${totalPages}`}</div>
          }
        />
        <LinearProgress value={(currentPage / totalPages) * 100} />
        <div style={styles.image}>
          <Headaches />
        </div>
        <div style={styles.questionnaire}>
          <p style={styles.instructions}>On a scale of 0-10 (0 being no pain and 10 being the worst possible pain) how would you rate your daily endometriosis pain?</p>
          <div style={styles.slideContainer}>
            <ReactSlider
              max={10}
              min={0}
              onChangeComplete={this.handleChangeSlider}
              showLabels={false}
              value={value}
            />
            <div style={{ display: 'flex', justifyContent: 'space-between', fontSize: '1.5rem', margin: '0 20px' }}>
              <p>No Pain</p>
              <p>Worst Imaginable Pain</p>
            </div>
          </div>
        </div>
      </QuestionLayout>
    );
  }
}


function mapStateToProps(state) {
  const {
    user,
    proForms: {
      prgendo,
    },
  } = state;
  return {
    user,
    prgendo,
  };
}

PrgEndo2.propTypes = {
  prgendo: PropTypes.array.isRequired,
  updatePRO: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { updatePRO, submitPRO })(PrgEndo2);
