import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { colors } from '../lib/styles';

const baseStyles = {
  listItem: {
    borderBottom: `1px solid ${colors.primaryHighlight}`,
    color: colors.primaryColor,
    textAlign: 'left',
  },
};

class MedicationListItem extends Component {
  constructor(props) {
    super(props);
    this.navToMedication = this.navToMedication.bind(this);
  }

  navToMedication() {
    if(this.props.onClick) {
      this.props.onClick(this.props.item.id);
    } else {
      this.props.router.push(`/medication?medication_hx_id=${this.props.item.id}`);
    }
  }

  render() {
    const { item } = this.props;
    return (
      <ListItem
        button
        onClick={this.navToMedication}
        style={baseStyles.listItem}
      >
        <ListItemIcon>
          <img alt="Pill Icon" src="/img/MedicationIcon.png" />
        </ListItemIcon>
        <ListItemText
          primary={`${item.name} (${item.dosage})`}
        />
      </ListItem>
    );
  }
}

MedicationListItem.propTypes = {
  item: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
};
export default MedicationListItem;
