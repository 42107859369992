import { bindActionCreators } from 'redux';
import { getCordovaData } from '../lib/cordova-helpers';
import {
  fetchActivityEnd,
  fetchCaloriesEnd,
  fetchStepsEnd,
  navigateToDeepLink,
  takePictureEnd,
} from '../state/cordova';

export default function cordovaInitializer(store) {
  if (!store) {
    return;
  }
  const actions = bindActionCreators({
    fetchActivityEnd,
    fetchCaloriesEnd,
    fetchStepsEnd,
    navigateToDeepLink,
    takePictureEnd,
  }, store.dispatch);

  window.cordovaActions = actions;
  window.getCordovaData = getCordovaData;
}
