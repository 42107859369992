import React from 'react';
import PropTypes from 'prop-types';
import { Button, IconButton, LinearProgress } from '@material-ui/core/';
import MenuIcon from '@material-ui/icons/Menu';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { browserHistory } from 'react-router';

import AppBar from '../components/app-bar';
import Page from './page';
import { colors, desktopMaxWidth, fontSizing } from '../lib/styles';
import Logo from '../components/logo';
import { submitPRO } from '../state/pro-forms';
import env from '../config';

const { vitalCoreConfig } = env;

const disabledBackground = '#d9d8d8';
const disabledText = '#9b9b9b';

const styles = {
  agreeButton: {
    background: colors.primaryColor,
    borderRadius: 5,
    borderWidth: 0,
    color: 'white',
    flexGrow: 1,
    fontSize: fontSizing.body,
    marginRight: 5,
    padding: 10,
    '&:disabled:': {
      background: disabledBackground,
      color: disabledText,
    },
    '@media (hover: none)': {
      '&:hover': {
        background: colors.primaryColor,
        color: 'white',
      },
    },
  },
  buttonRow: {
    display: 'flex',
    paddingBottom: 20,
    paddingTop: 20,
    width: 'calc(100% - 20px)',
  },
  declineButton: {
    background: '#707070',
    borderRadius: 5,
    borderWidth: 0,
    color: 'white',
    flexGrow: 1,
    fontSize: fontSizing.body,
    padding: 10,
    '&:disabled:': {
      background: disabledBackground,
      color: disabledText,
    },
    '@media (hover: none)': {
      '&:hover': {
        background: '#707070',
        color: 'white',
      },
    },
  },
  header: {
    fontWeight: 'bold',
    textAlign: 'center',
  },
  linearProgress: {
    background: colors.lightBrightBlue,
  },
  pageContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    backgroundColor: colors.questionBackground,
  },
  pageContent: {
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: desktopMaxWidth,
    paddingLeft: 10,
    paddingRight: 10,
    backgroundColor: colors.questionBackground,
  },
  pageContentContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    fontSize: fontSizing.body,
    overflowX: 'scroll',
    backgroundColor: colors.questionBackground,
  },
  unorderedList: {
    marginTop: 0,
  },
  unorderedListHeader: {
    marginBottom: 0,
  },
};

class VitalCoreRR3 extends Page {
  constructor(props) {
    super(props);

    const consentedToRecording = window.localStorage.getItem('vitalCoreConsentedToRecording');
    const vitalCoreVisitedConsentToRecord = window.localStorage.getItem('vitalCoreVisitedConsentToRecord');
    const consentToRecordVersion = window.localStorage.getItem('consentToRecordVersion');
    const historyAction = props.router.location.action;

    const sameConsentVersion = consentToRecordVersion
      && consentToRecordVersion === vitalCoreConfig.consentToRecordVersion;

    if (consentedToRecording && !vitalCoreVisitedConsentToRecord && historyAction === 'PUSH' && sameConsentVersion) {
      this.goToIndexWithQuery(4, props.location.query);
    } else if (consentedToRecording && !vitalCoreVisitedConsentToRecord && historyAction === 'POP') {
      this.props.router.goBack();
    }
  }

  declinedConsentToRecord = async () => {
    const { location, user } = this.props;
    const { id } = location.query;

    window.localStorage.setItem('vitalCoreConsentedToRecording', false);
    window.localStorage.setItem('vitalCoreVisitedConsentToRecord', true);

    await submitPRO({ userId: user.id, id }, {
      pro_type: 'VITAL-CORE-RR',
      pro_data: {
        data: { declinedConsentToRecord: true },
      },
      declined: true,
    }, user);

    this.goToIndexWithQuery(3, location.query);
  }

  handleClickAgree = () => {
    window.localStorage.setItem('vitalCoreConsentedToRecording', true);
    window.localStorage.setItem('vitalCoreVisitedConsentToRecord', true);
    window.localStorage.setItem('consentToRecordVersion', vitalCoreConfig.consentToRecordVersion);

    this.goToIndexWithQuery(4, this.props.location.query);
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.pageContainer}>
        <div className={classes.pageContentContainer}>
          <AppBar
            headerNode="Consent to Record Vitals"
            backButtonOnClick={() => this.props.router.goBack()}
            rightNode={(
              <IconButton onClick={() => browserHistory.push('/core-vital-user-manual-menu')}>
                <MenuIcon fontSize="large" />
              </IconButton>
            )}
          />
          <LinearProgress
            variant="determinate"
            value={(this.getCurrentPage() / this.getTotalPages()) * 100}
            classes={{ bar: classes.linearProgress }}
          />
          <div className={classes.pageContent}>
            <Logo />
            <div className={classes.header}>
              INFORMED VITAL CORE™ INFORMED CONSENT TO MEASURE VITALS
            </div>
            <p>
              <strong>Last Updated:</strong> March 17, 2024
            </p>
            <p>
              <strong>PLEASE READ THIS CONSENT CAREFULLY BEFORE USING THE INFORMED VITAL CORE APPLICATION.</strong>
            </p>
            <p>
              By clicking accept, I acknowledge and agree that I have fully read the Informed Consent for Informed Vital Core Application (“IVC App”) and agree to have my vital signs measured and sent to my provider.
            </p>
            <p className={classes.unorderedListHeader}><strong>Consent to Measure Vital Signs</strong></p>
            <ul className={classes.unorderedList}>
              <li>The transmission of my health information may experience a technical failure and result in the loss of information obtained.</li>
              <li>I hold harmless my healthcare provider and all parties involved in measuring of my vital signs against any liability, damages, loss, attorney fees, and costs of any type due to the loss of information caused by a technical failure. I understand that this IVC App is not intended to be diagnostic, nor does it drive treatment decisions, it is up to the healthcare provider overseeing my care.</li>
              <li>I authorize the use of my information obtained to be used for treatment, payment, and healthcare operations.</li>
              <li>I understand that I will not have contact with the healthcare provider while my vital signs are measure.</li>
              <li>I have the right to withhold or withdraw my consent or refuse to have my vital signs recorded at any time.</li>
              <li>I understand that my withdrawal of consent will not affect any future care or treatment, nor will it subject me to the risk of loss or withdrawal of any health benefits to which I am otherwise receiving.</li>
              <li>I have the right to inspect all information recorded and stored and may receive copies of this information upon written request to my healthcare provider.</li>
              <li>I understand that no video or personal image is transmitted to my healthcare provider as part of recording my vital signs.</li>
              <li>My personal health information may be shared through electronic communication with other healthcare providers involved in my treatment.</li>
              <li>A copy of this consent will become a part of my medical record.</li>
            </ul>
            <p>If you are feeling unwell or have a health condition that may impact your use of the IVC App, contact your healthcare provider.</p>
          </div>
        </div>
        <div className={`${classes.buttonRow} ${classes.pageContent}`}>
          <Button
            className={classes.agreeButton}
            variant="contained"
            onClick={this.handleClickAgree}
          >
            Agree
          </Button>
          <Button
            className={classes.declineButton}
            variant="contained"
            onClick={this.declinedConsentToRecord}
          >
            Decline
          </Button>
        </div>
      </div>
    );
  }
}

VitalCoreRR3.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const { user } = state;

  return { user };
};

export default connect(mapStateToProps, { submitPRO })(withStyles(styles)(VitalCoreRR3));
