import React from 'react';
import { connect } from 'react-redux';
import { omit, upperFirst } from 'lodash';
import { Divider, TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import AppBar from '../components/app-bar';
import Page from './page';
import Layout from '../layouts/common';
import { colors } from '../lib/styles';
import Continue from '../components/continue';

import {
  browsePatientContactInfo,
  editPatientContactInfo,
} from '../state/patient-contact-info';

const baseStyles = {
  text: {
    color: colors.primaryColor,
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  marginLeft: {
    marginLeft: 15,
  },
  flexStart: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
};

const classStyles = {
  textField: {
    marginBottom: 5,
    marginTop: 5,
    '& input': {
      color: colors.greyText,
      fontSize: 18,
    },
    '& .MuiFormLabel-root:': {
      fontSize: 18,
      background: 'blue !important',
    },
    '& .MuiInput-underline:after': {
      borderBottom: 'none',
    },
    '& .MuiInput-underline:before': {
      borderBottom: 'none',
    },
    '& .MuiInput-underline:hover:before': {
      borderBottom: 'none',
    },
  },
};

class EmergencyContact extends Page {
  constructor(props) {
    super(props);

    this.state = {
      patientContactInfo: {
        emergency_contact: '',
        emergency_relationship: '',
        emergency_phone: '',
        emergency_email: '',
        ...props.patientContactInfo,
      },
    };
    this.handleSave = this.handleSave.bind(this);
    this.handleName = this.handleText.bind(this, 'emergency_contact');
    this.handlePhone = this.handleText.bind(this, 'emergency_phone');
    this.handleRelationship = this.handleText.bind(this, 'emergency_relationship');
    this.handleEmail = this.handleText.bind(this, 'emergency_email');
  }

  componentWillMount() {
    if (!this.props.patientContactInfo.id) {
      this.props.browsePatientContactInfo();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.patientContactInfo !== nextProps.patientContactInfo) {
      this.setState({
        patientContactInfo: {
          emergency_contact: '',
          emergency_relationship: '',
          emergency_phone: '',
          emergency_email: '',
          ...nextProps.patientContactInfo,
        },
      });
    }
  }

  handleText(field, e) {
    let newText = e.target.value;
    if (field === 'emergency_contact') {
      newText = e.target.value.split(' ').map(word => upperFirst(word)).join(' ');
    }
    this.setState({
      patientContactInfo: {
        ...this.state.patientContactInfo,
        [field]: newText,
      },
    });
  }

  handleSave() {
    // Should always have a record at this point
    const { patientContactInfo } = this.state;
    const updatedMedication = omit(patientContactInfo, ['id', 'user_id']);
    this.props.editPatientContactInfo(updatedMedication)
      .then(() => this.props.router.push('/personalinfo'));
  }

  render() {
    const { patientContactInfo } = this.state;
    const { classes } = this.props;

    return (
      <Layout>
        <AppBar
          backButtonOnClick={() => this.props.router.push('/personalinfo')}
          headerNode="Emergency Contact"
        />
        <section>
          <p
            style={{ fontSize: 10, color: colors.primaryColor, textAlign: 'left', marginLeft: 15 }}
          >EMERGENCY CONTACT DETAILS
          </p>
        </section>
        <section className="form" style={{ backgroundColor: colors.white, textAlign: 'left' }}>
          <TextField
            label="First and Last Name"
            onChange={this.handleName}
            value={patientContactInfo.emergency_contact}
            style={baseStyles.marginLeft}
            classes={{ root: classes.textField }}
          />
          <Divider />
          <TextField
            label="Phone Number"
            onChange={this.handlePhone}
            value={patientContactInfo.emergency_phone}
            style={baseStyles.marginLeft}
            classes={{ root: classes.textField }}
            type="tel"
          />
          <Divider />
          <TextField
            label="Relationship to Patient"
            onChange={this.handleRelationship}
            value={patientContactInfo.emergency_relationship}
            style={baseStyles.marginLeft}
            classes={{ root: classes.textField }}
          />
          <Divider />
          <TextField
            label="Email Address"
            onChange={this.handleEmail}
            value={patientContactInfo.emergency_email}
            style={baseStyles.marginLeft}
            classes={{ root: classes.textField }}
            type="email"
          />
          <Divider />
        </section>
        <div style={{ marginTop: 30 }}>
          <Continue
            onClick={this.handleSave}
            text="Save Emergency Contact"
          />
        </div>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  const { patientContactInfo } = state;
  return {
    patientContactInfo: patientContactInfo.data,
  };
}

export default connect(mapStateToProps, {
  browsePatientContactInfo,
  editPatientContactInfo,
})(withStyles(classStyles)(EmergencyContact));
