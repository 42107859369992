import React from 'react';
import { LinearProgress, MenuItem, Select, Button } from '@material-ui/core';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import AppBar from '../components/app-bar';
import Page from './page';
import QuestionLayout from '../layouts/question';
import i18nTranslator, { title, options, nextBtn, selectDefault, yesLabel, noLabel, notApplicableLabel } from '../lib/i18next';
import { colors } from '../lib/styles';
import { submitPRO } from '../state/pro-forms';
import { Doctor } from '../lib/icons';

const styles = {
  button: {
    margin: '5px',
    fontSize: 29,
    flexGrow: 1,
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
  contentWrapper: {
    flexGrow: 1,
    overflowX: 'hidden',
    position: 'relative',
  },
  imgRow: {
    display: 'flex',
    justifyContent: 'center',
    padding: '15px 60px',
  },
  imageWrapper: {
    width: 100,
  },
  questionnaireContainer: {
    paddingLeft: 20,
    paddingRight: 20,
  },
  questionText: {
    fontSize: 29,
    marginTop: 25,
  },
  select: {
    fontSize: 29,
    marginTop: 5,
  },
};

class Patientdemographics9 extends Page {
  constructor(props) {
    super(props);

    this.state = {
      lastSeenPodiatrist: '',
      reasonNotSeenByPodiatrist: '',
      hasSeenPodiatrist: '',
    };
  }

  handleHasSeenPodiatrist = () => {
    this.setState({
      hasSeenPodiatrist: true,
      reasonNotSeenByPodiatrist: '',
    });
  }

  handleNotHasNotSeenPodiatrist = () => {
    this.setState({
      hasSeenPodiatrist: false,
      lastSeenPodiatrist: '',
    });
  }

  handleNotApplicablePodiatrist = () => {
    this.setState({
      hasSeenPodiatrist: null,
      reasonNotSeenByPodiatrist: '',
      lastSeenPodiatrist: '',
    });
  }

  handleChangeReasonNotSeenByPodiatrist = (e) => {
    this.setState({ reasonNotSeenByPodiatrist: e.target.value });
  };

  handleChangeSeenPodiatristDate = (e) => {
    this.setState({ lastSeenPodiatrist: e.target.value });
  }

  handleClickNext = () => {
    const { patientDemographics, user } = this.props;
    const {
      lastSeenPodiatrist,
      reasonNotSeenByPodiatrist,
      hasSeenPodiatrist,
    } = this.state;
    const { id } = this.props.location.query;

    const value = { hasSeenPodiatrist };

    if (hasSeenPodiatrist === true) {
      value.lastSeenPodiatrist = lastSeenPodiatrist;
    } else {
      value.reasonNotSeenByPodiatrist = reasonNotSeenByPodiatrist;
    }

    this.props.submitPRO({ userId: user.id, id }, {
      pro_type: 'PATIENT-DEMOGRAPHICS',
      pro_data: {
        data: [
          ...patientDemographics,
          value,
        ],
      },
    }, user)
      .then(() => this.finishTrack());
  }

  render() {
    const { classes } = this.props;
    const {
      lastSeenPodiatrist,
      reasonNotSeenByPodiatrist,
      hasSeenPodiatrist,
    } = this.state;

    const continueDisabled = hasSeenPodiatrist === ''
      || (hasSeenPodiatrist && !lastSeenPodiatrist)
      || (hasSeenPodiatrist === false && !reasonNotSeenByPodiatrist);

    return (
      <QuestionLayout
        primaryBtnLabel={nextBtn()}
        primaryBtnOnTouchTap={this.handleClickNext}
        primaryBtnDisabled={continueDisabled}
        style={{ backgroundColor: colors.questionBackground }}
      >
        <AppBar
          headerNode={title('demographics9')}
          backButtonOnClick={() => this.props.router.goBack()}
        />
        <LinearProgress
          variant="determinate"
          value={(this.getCurrentPage() / this.getTotalPages()) * 100}
          classes={{ bar: classes.bar }}
        />
        <div className={classes.contentWrapper}>
          <div className={classes.imgRow}>
            <div className={classes.imageWrapper}>
              <Doctor />
            </div>
          </div>
          <div className={classes.questionnaireContainer}>
            <div className={classes.questionText}>{i18nTranslator('question1', 'demographics9')}</div>
            <div className={classes.buttonWrapper}>
              <Button style={styles.button} variant="contained" color={hasSeenPodiatrist ? 'primary' : 'default'} onClick={this.handleHasSeenPodiatrist}>{yesLabel()}</Button>
              <Button style={styles.button} variant="contained" color={hasSeenPodiatrist === false ? 'primary' : 'default'} onClick={this.handleNotHasNotSeenPodiatrist}>{noLabel()}</Button>
              <Button style={styles.button} variant="contained" color={hasSeenPodiatrist === null ? 'primary' : 'default'} onClick={this.handleNotApplicablePodiatrist}>{notApplicableLabel()}</Button>
            </div>

            {hasSeenPodiatrist === true ? (
              <>
                <div className={classes.questionText}>{i18nTranslator('question2', 'demographics9')}</div>
                <Select
                  displayEmpty
                  value={lastSeenPodiatrist}
                  onChange={this.handleChangeSeenPodiatristDate}
                  className={classes.select}
                >
                  <MenuItem value=""><i>{selectDefault()}</i></MenuItem>
                  <MenuItem value="Within the last 3 months">{options('threeMonths', 'demographics9')}</MenuItem>
                  <MenuItem value="6 months ago">{options('sixMonths', 'demographics9')}</MenuItem>
                  <MenuItem value="1 year ago">{options('oneYear', 'demographics9')}</MenuItem>
                  <MenuItem value="Over a year ago">{options('overOneYear', 'demographics9')}</MenuItem>
                </Select>
              </>
            ) : null}
            {hasSeenPodiatrist === false ? (
              <>
                <div className={classes.questionText}>{i18nTranslator('question3', 'demographics9')}</div>
                <Select
                  displayEmpty
                  value={reasonNotSeenByPodiatrist}
                  onChange={this.handleChangeReasonNotSeenByPodiatrist}
                  className={classes.select}
                >
                  <MenuItem value=""><i>{selectDefault()}</i></MenuItem>
                  <MenuItem value="No Time">{options('time', 'demographics9')}</MenuItem>
                  <MenuItem value="No Insurance">{options('insurance', 'demographics9')}</MenuItem>
                  <MenuItem value="No Availability">{options('availability', 'demographics9')}</MenuItem>
                  <MenuItem value="Cost">{options('cost', 'demographics9')}</MenuItem>
                  <MenuItem value="Other">{options('other', 'demographics9')}</MenuItem>
                </Select>
              </>
            ) : null}
          </div>
        </div>
      </QuestionLayout>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    proForms: {
      patientDemographics,
    },
    user,
  } = state;

  return { patientDemographics, user };
};

export default connect(mapStateToProps, { submitPRO })(withStyles(styles)(Patientdemographics9));
