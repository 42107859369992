import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';

import Page from './page';
import AppBar from '../components/app-bar';
import BloomkidzLayout from '../layouts/bloomkidz';
import { updatePRO } from '../state/pro-forms';
import { bloomkidzStylesObj, bloomkidzStyles } from '../lib/bloomkidz';

const styles = {
  ...bloomkidzStylesObj,
  centerText: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
  },
  emphasis: {
    textWeight: 600,
  },
  paragraph: {
    textAlign: 'left',
    width: '90vw',
    maxWidth: '700px',
    height: '80vh',
    fontSize: '16px',
    margin: '20px auto',
    backgroundColor: '#FFF',
    overflowY: 'scroll',
    boxShadow: 'inset 1px 1px 5px #9B9B9B',
    padding: '10px',
    borderRadius: '2px',
    display: 'flex',
    flexDirection: 'column',
  },
};

class BloomkidzSignatures9 extends Page {
  state = {
    formBottom: false,
  }

  handleScroll = (e) => {
    if (e.target.scrollTop / e.target.scrollHeight > 0.90) {
      this.setState({ formBottom: true });
    }
  }

  handleContinue = () => {
    const { location, updatePRO } = this.props;

    updatePRO({
      type: 'bloomkidzSignatures',
      position: this.getTrackIndex(),
      value: { ...this.state },
    });

    this.forwardWithQuery(location.query);
  }

  render() {
    const { classes, router } = this.props;

    return (
      <BloomkidzLayout
        primaryBtnOnTouchTap={this.handleContinue}
        primaryBtnLabel="Next"
      >
        <AppBar
          headerNode="Clinic Policies & Authorization to Treat"
          backButtonOnClick={() => router.goBack()}
        />
        <div style={{ backgroundColor: '#FFF', height: '85vh', marginBottom: '20px' }}>
          <div
            ref={(form) => {
              this.myForm = form;
            }}
            onScroll={this.handleScroll}
            className={classes.paragraph}
          >
            <img alt="logo" style={{ width: '90%', alignSelf: 'center', maxWidth: '400px', padding: '15px' }} src="/img/Bloomkidz.png" />
            <h4 color={bloomkidzStyles.primaryColor}>Clinic Policies & Authorization to Treat</h4>
            <div>
              Thank you for choosing BloomKidz, for your child&apos;s therapy. Our therapists are reserving a weekly time slot for your child&apos;s therapy. Your adherence to our policies will help secure their time slot and ensure timely sessions, continuity of therapy and appropriate transitions out of therapy.
            </div>
            <h5 className={classes.emphasis} color={bloomkidzStyles.primaryColor}>PARKING & PROCEEDURES DURING SESSIONS</h5>
            <ul>
              <li>
                On-site parking is available. Parents are not required to stay at the clinic while their child is participating in therapy sessions or evaluations.
              </li>
              <li>
                Extended Day Pick-Up & Drop-Off
                <ul>
                  <li>
                    Be sure to verify your child&apos;s drop off time. Your child will be transferred to his or her therapist for their session in the Drop-Off Zone on the south side of the building under covered parking.
                  </li>
                  <li>
                    They will be transferred back to you at the same zone at their scheduled pick-up time.
                  </li>
                  <li>
                    Please remain in your vehicle while in the Drop-Off Zone until you see your child&apos;s therapist when dropping off and picking up.
                  </li>
                </ul>
              </li>
              <li>
                For single sessions, please park outside of the Drop-Off zone and enter with your child through the front entrance. You may leave and return once you check your child in, or you are welcome to wait in our parent co-work space.
              </li>
              <li>
                Parent observation is always welcome! Please follow your designated drop-off procedure; park outside of the Drop-Off Zone and enter through the front lobby and speak with someone at the front desk if you&apos;d like to schedule an observation. You may also plan ahead with your child&apos;s team for regular observations.
              </li>
            </ul>
            <h5 className={classes.emphasis} color={bloomkidzStyles.primaryColor}>CANCELLATIONS/NO-SHOWS</h5>
            <ul>
              <li>
                We understand things happen. There is no charge for canceled services when at least 24 hours notice is given. Otherwise, missed sessions may result in a charge for the full cost of the session. Please notify the therapists of planned vacation or other conflicts at least 2 weeks in advance, otherwise sessions may be fully charged. Notice of changes in schedule must be in the form of email or phone call to the therapist. Please email scheduling@bloomkidz.org with any desired changes to the schedule.
              </li>
            </ul>
            <h5 className={classes.emphasis} color={bloomkidzStyles.primaryColor}>TERMINATIONOF THERAPY</h5>
            <ul>
              <li>
                If you terminate therapy, for any reason, we require at least 2 weeks of sessions&apos; prior written notice in order for the therapist to transition the child out of therapy and complete discharge. We reserve the right to terminate our relationship with a client at any time for any reason. Unless circumstances require otherwise, if we terminate the relationship, we will provide at least 2 sessions&apos; prior notice.
              </li>
            </ul>
            <h5 className={classes.emphasis} color={bloomkidzStyles.primaryColor}>CHANGE IN POLICIES</h5>
            <ul>
              <li>
                The terms and conditions in this policy may change from time to time. Such changes will occur with 30 days written notice.
              </li>
            </ul>
            <h5 className={classes.emphasis} color={bloomkidzStyles.primaryColor}>PROCESS/ DESCRIPTION OF SERVICES</h5>
            <ul>
              <li>
                Initial Consultation or &ldquo;Meet & Greet&rdquo;: The therapist and parent(s) discuss concerns related to the child&apos;s functioning. The therapist provides recommendations for an evaluation, treatment, and/or home strategies.
              </li>
              <li>
                Initial Multidisciplinary Evaluation (required for treatment): Approximately 1 to 8 hours of testing using standardized and/or informal measures to assess the child&apos;s strengths/weaknesses and determine the need for weekly therapy. This can be spread over one to two weekly visits.
              </li>
              <li>
                Evaluation Report (required for treatment): Written description of administered tests, the child&apos;s performance, and areas to target in treatment (if applicable). This report includes a treatment plan (when treatment is recommended) with baseline areas of functioning and goals.
              </li>
              <li>
                Treatment Plan Meeting (strongly recommended but optional): Meeting with all members of child&apos;s team and caregivers to review multidisciplinary evaluation reports and review treatment plans/goals.
              </li>
              <li>
                Treatment Sessions (Depending on needs of client): Individual, paired, and small group sessions are available. Co-treatment sessions (speech/language therapy combined with occupational therapy) are also offered when appropriate.
              </li>
              <li>
                Progress Report (required for continued treatment at BloomKidz): This report describes the child&apos;s progress toward targeted goals, and the new treatment plan (if continued treatment is recommended). Progress reports are provided every 4 to 6 months depending on the frequency of treatment sessions.
              </li>
              <li>
                Quarterly Team Meeting: Meeting with all members of child&apos;s team and caregivers to review multidisciplinary progress reports (which will happen quarterly)and review updated treatment plans/goals.
              </li>
              <li>
                Re-Evaluation Team Meeting: Meeting with all members of child&apos;s team and caregivers to review multidisciplinary re-evaluation reports and review updated treatment plans/goals.
              </li>
              <li>
                Consultative Services (Depending on needs of client):The therapist provides recommendations and strategies for home and school settings to optimize the child&apos;s functioning and address areas of concerns.
              </li>
            </ul>
            <h5 className={classes.emphasis} color={bloomkidzStyles.primaryColor}>INSURANCE PAY PRACTICES & POLICIES</h5>
            <ul>
              <li>
                Attendance Policies for Excused Absences
                <ul>
                  <li>
                    We require 24 hour notification for cancellation
                  </li>
                </ul>
              </li>
              <li>
                Violation of Attendance Policies
                <ul>
                  <li>
                    If a child has 2 or more excused absences within a one-month period, your child may lose their scheduled appointment time.
                  </li>
                  <li>
                    If a caregiver is 15 or more minutes late for pick-up, your child may lose their future scheduled appointment times.
                  </li>
                </ul>
              </li>
              <li>
                Termination of Therapy
                <ul>
                  <li>
                    If you terminate therapy, for any reason, we require at least 2 weeks of sessions&apos; prior written notice in order for the therapist to transition the child out of therapy and complete discharge. We reserve the right to terminate our relationship with a client at any time for any reason. Unless circumstances require otherwise, if we terminate the relationship, we will provide at least 2 sessions&apos; prior notice.
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </BloomkidzLayout>
    );
  }
}

BloomkidzSignatures9.propTypes = {
  classes: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  updatePRO: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
};

export default connect(null, { updatePRO })(withStyles(styles)(BloomkidzSignatures9));
