import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get, includes } from 'lodash';

import { colors } from '../lib/styles';
import Page from './page';
import ButtonPRO from '../components/button-pro';
import { updatePRO } from '../state/pro-forms';

const buttonLabels = ['None', 'Mild', 'Moderate', 'Severe', 'Extreme'];

const options = [
  {
    question: 'Walking on flat surface',
    label: buttonLabels,
  },
  {
    question: 'Getting in/out of car',
    label: buttonLabels,
  },
  {
    question: 'Going shopping',
    label: buttonLabels,
  },
  {
    question: 'Putting on socks/stockings',
    label: buttonLabels,
  },
];
const { questionBackground } = colors;

class WOMAC4 extends Page {
  onClickBackButton = () => {
    this.backward();
  }

  onSubmission = () => {
    this.forwardWithQuery(this.props.location.query);
  }

  render() {
    const page = get(this.props.womac, this.getTrackIndex(), [null, null, null, null]);
    const value = includes(page, null) ? null : 0;
    return (
      <ButtonPRO
        backgroundColor={questionBackground}
        currentPage={this.getCurrentPage()}
        onClickBackButton={() => this.props.router.goBack()}
        onChange={this.props.updatePRO}
        onSubmission={this.onSubmission}
        options={options}
        instructions={`The following questions concern your physical function.  By this we mean your ability to move around and to look after yourself. For each of the following activities, please indicate the degree of difficulty you have experienced in the last 24 hours, in your ${this.props.route.type}.`}
        title="What degree of difficulty do you have with:"
        header="Physical Function"
        totalPages={this.getTotalPages()}
        trackIndex={this.getTrackIndex()}
        value={value}
        pro={page}
        type={this.props.type}
      />
    );
  }
}

function mapStateToProps(state, ownProps) {
  const { proForms, user } = state;
  const { type } = ownProps.route;
  const womacType = `womac${type}`;
  return { womac: proForms[womacType], user, type: womacType };
}

WOMAC4.propTypes = {
  womac: PropTypes.array.isRequired,
  updatePRO: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { updatePRO })(WOMAC4);
