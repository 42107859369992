import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get, flatten, isNumber } from 'lodash';

import Slider from '../components/horizontal-pain-slider';
import Page from './page';
import { updatePRO, submitPRO } from '../state/pro-forms';


class PISAFollowup5 extends Page {
  state = {
    submitting: false,
  }

  componentWillMount() {
    const value = get(this.props.pisaFollowup, this.getTrackIndex());
    if (!isNumber(value)) {
      this.props.updatePRO({
        type: 'pisaFollowup',
        position: this.getTrackIndex(),
        value: 0,
      });
    }
  }

  onSubmission = () => {
    if (this.state.submitting) return;

    const { user } = this.props;
    const { id } = this.props.location.query;
    this.setState({ submitting: true });
    submitPRO({ userId: user.id, id }, {
      pro_type: 'PISA-RFA',
      pro_data: { data: flatten(this.props.pisaFollowup) },
    }, user)
      .then(() => this.forward())
      .catch(() => this.setState({ submitting: false }));
  }

  render() {
    const value = get(this.props.pisaFollowup, this.getTrackIndex(), 0);
    return (
      <Slider
        currentPage={this.getCurrentPage()}
        onBack={() => this.props.router.goBack()}
        onChange={(e, sliderValue) => {
          this.props.updatePRO({
            type: 'pisaFollowup',
            position: this.getTrackIndex(),
            value: sliderValue,
          });
        }}
        onSubmission={this.onSubmission}
        title="Pre-procedure Pain Level"
        description="Before the procedure..."
        primaryBtnDisabled={this.state.submitting}
        totalPages={this.getTotalPages()}
        type="pisaFollowup"
        value={value}
        instructions="Use the scale to describe your pain level before the procedure with 0 being no pain and 10 being excruciating pain."
        highLabel="Excruciating Pain"
      />
    );
  }
}

function mapStateToProps(state) {
  const {
    user,
    proForms: {
      pisaFollowup,
    },
  } = state;
  return {
    user,
    pisaFollowup,
  };
}

PISAFollowup5.proptypes = {
  pisaFollowup: PropTypes.array.isRequired,
  updatePRO: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { updatePRO, submitPRO })(PISAFollowup5);
