import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import TextField from '@material-ui/core/TextField';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import AppBar from '../components/app-bar';
import Page from './page';
import QuestionLayout from '../layouts/question';
import YesNoButtonSet from '../components/yes-no-button-set';
import { colors } from '../lib/styles';
import { submitPRO, updatePRO } from '../state/pro-forms';
import { Lungs } from '../lib/icons';

const styles = {
  contentWrapper: {
    flexGrow: 1,
    overflowX: 'hidden',
    position: 'relative',
  },
  imgRow: {
    display: 'flex',
    justifyContent: 'center',
    padding: '15px 60px',
  },
  imageWrapper: {
    width: 100,
  },
  inputField: {
    width: '6rem',
  },
  instructions: {
    color: 'grey',
    fontSize: '1.5rem',
    margin: '20px 40px 0px 40px',
    textAlign: 'center',
  },
  leaveBlankText: {
    fontSize: '1.25rem',
    fontStyle: 'italic',
    marginTop: 10,
  },
  questionnaireContainer: {
    paddingLeft: 20,
    paddingRight: 20,
  },
  questionText: {
    fontSize: '2rem',
    marginTop: 25,
  },
  sectionText: {
    fontSize: '2rem',
    fontWeight: 'bold',
  },
  textFieldContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  textFieldWrapper: {
    alignItems: 'center',
    display: 'flex',
    paddingTop: 20,
  },
  unitsWrapper: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    marginLeft: 5,
  },
};

class HeadAndNeckCancerSymptoms13 extends Page {
  constructor(props) {
    super(props);

    this.state = {
      oxygenSaturation: '',
      shortnessOfBreath: null,
      worseWithActivity: null,
    };
  }

  handleChangeShortnessOfBreath = (value) => {
    this.setState({ shortnessOfBreath: value });
  };

  handleChangeWorseWithActivity = (value) => {
    this.setState({ worseWithActivity: value });
  }

  handleChangeOxygenSaturation = (e) => {
    this.setState({ oxygenSaturation: e.target.value });
  }

  handleClickNext = () => {
    const {
      headAndNeckCancerSymptoms,
      location,
      submitPRO,
      updatePRO,
      user,
    } = this.props;
    const { oxygenSaturation, shortnessOfBreath, worseWithActivity } = this.state;
    const { id } = location.query;
    const weightGain = headAndNeckCancerSymptoms[8];
    const weightLoss = headAndNeckCancerSymptoms[5];
    const weightLossFollowup = headAndNeckCancerSymptoms[6];
    const { temperature } = headAndNeckCancerSymptoms[10];
    const { systolic } = headAndNeckCancerSymptoms[11];
    let { difficultySwallowing } = headAndNeckCancerSymptoms[0];
    let { hadFever } = headAndNeckCancerSymptoms[10];

    difficultySwallowing = difficultySwallowing === 'Y';
    const gainedWeight = weightGain && weightGain.weightGain && parseFloat(weightGain.weightGain, 10) >= 2;
    const lostWeight = weightLoss && weightLoss.weightLoss && parseFloat(weightLoss.weightLoss, 10) >= 2;
    const liquidStools = weightLossFollowup && weightLossFollowup.liquidStools && weightLossFollowup.liquidStools === 'Y';
    const lowSystolic = systolic && parseFloat(systolic, 10) <= 90;
    hadFever = (hadFever === 'Y' && temperature && parseFloat(temperature, 10) >= 100.4)
      || (hadFever === 'Y' && !temperature);

    let lowOxygen = false;

    const value = { shortnessOfBreath, worseWithActivity };

    if (oxygenSaturation) {
      value.oxygenSaturation = oxygenSaturation;
      lowOxygen = parseFloat(oxygenSaturation, 10) <= 90;
    }

    const goToCallPage = lowOxygen
      || hadFever
      || difficultySwallowing
      || gainedWeight
      || lostWeight
      || liquidStools
      || lowSystolic;

    submitPRO(
      { userId: user.id, id },
      {
        pro_data: { data: [...headAndNeckCancerSymptoms, value] },
        pro_type: 'HEAD-AND-NECK-CANCER-SYMPTOMS',
      },
      user,
    )
      .then(() => {
        if (!goToCallPage) {
          this.finishTrack();
        } else {
          updatePRO({
            type: 'headAndNeckCancerSymptoms',
            position: this.getTrackIndex(),
            value: {
              lowOxygen,
              hadFever,
              difficultySwallowing,
              gainedWeight,
              lostWeight,
              liquidStools,
              lowSystolic,
            },
          });
          this.forwardWithQuery(this.props.location.query);
        }
      });
  }

  render() {
    const { classes } = this.props;
    const { oxygenSaturation, shortnessOfBreath, worseWithActivity } = this.state;

    const continueDisabled = shortnessOfBreath === null || worseWithActivity === null;

    return (
      <QuestionLayout
        primaryBtnLabel="Next"
        primaryBtnOnTouchTap={this.handleClickNext}
        primaryBtnDisabled={continueDisabled}
        style={{ backgroundColor: colors.questionBackground }}
      >
        <AppBar
          headerNode="Breathing"
          backButtonOnClick={() => this.props.router.goBack()}
        />
        <LinearProgress
          variant="determinate"
          value={(this.getCurrentPage() / this.getTotalPages()) * 100}
          classes={{ bar: classes.bar }}
        />
        <div className={classes.contentWrapper}>
          <div className={classes.instructions}>
            Please select the option that best answers the questions relating to breathing
          </div>
          <div className={classes.imgRow}>
            <div className={classes.imageWrapper}>
              <Lungs />
            </div>
          </div>
          <div className={classes.questionnaireContainer}>
            <div className={classes.sectionText}>In the last 12 hours...</div>
            <YesNoButtonSet
              isYes={shortnessOfBreath === 'Y'}
              isNo={shortnessOfBreath === 'N'}
              onClickNo={() => this.handleChangeShortnessOfBreath('N')}
              onClickYes={() => this.handleChangeShortnessOfBreath('Y')}
              questionText="Have you had shortness of breath?"
            />
            <YesNoButtonSet
              isYes={worseWithActivity === 'Y'}
              isNo={worseWithActivity === 'N'}
              onClickNo={() => this.handleChangeWorseWithActivity('N')}
              onClickYes={() => this.handleChangeWorseWithActivity('Y')}
              questionText="Does it get worse with activity?"
            />
            <div className={classes.questionText}>What is your oxygen saturation?</div>
            <div className={classes.textFieldContainer}>
              <div className={classes.leaveBlankText}>Leave blank if you don't know your oxygen saturation.</div>
              <div className={classes.textFieldWrapper}>
                <TextField
                  className={classes.inputField}
                  onChange={this.handleChangeOxygenSaturation}
                  type="number"
                  inputProps={{
                    inputMode: 'decimal',
                    pattern: '[0-9]*',
                    type: 'tel',
                    style: { fontSize: '16px' } }}
                  value={oxygenSaturation}
                />
                <span className={classes.unitsWrapper}>%</span>
              </div>
            </div>
          </div>
        </div>
      </QuestionLayout>
    );
  }
}

function mapStateToProps(state) {
  const {
    proForms: {
      headAndNeckCancerSymptoms,
    },
    user,
  } = state;
  return { headAndNeckCancerSymptoms, user };
}

export default connect(mapStateToProps, { submitPRO, updatePRO })(withStyles(styles)(HeadAndNeckCancerSymptoms13));
