import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';

import { withStyles } from '@material-ui/core/styles';

import AppBar from '../components/app-bar';
import Continue from '../components/continue';

import { WineGlass } from '../lib/icons';

import Page from './page';

import { updatePRO } from '../state/pro-forms';

const styles = {
  button: {
    margin: 5,
    width: 150,
    height: 40,
    fontSize: 16,
  },
  buttonGroup: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  formControl: {
    textAlign: 'center',
  },
  formControlItem: {
    fontSize: '22px',
    textAlign: 'center',
    margin: '1rem',
  },
  iconContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    flexGrow: 0,
    flexShrink: 0,
    width: '100%',
    color: '#000',
    paddingTop: 10,
  },
  iconWrapper: {
    width: 100,
    marginTop: 10,
  },
  mainContainer: {
    position: 'relative',
    minHeight: '100vh',
    marginBottom: '50px',
  },
  titleGroup: {
    margin: 25,
  },
  text: {
    fontSize: '22px',
  },
  nextBtn: {
    textAlign: 'center',
    width: '100%',
    bottom: 0,
    position: 'absolute',
    height: '8rem',
  },
  wrapper: {
    paddingBottom: '8rem',
  },
};

const StyledInput = withStyles(() => ({
  input: {
    textAlign: 'center',
    fontSize: '22px',
  },
}))(Input);

class Diabetes10 extends Page {
  constructor(props) {
    super(props);

    this.state = {
      drinker: null,
      drinksPerWeek: null,
    };

    this.handleContinue = this.handleContinue.bind(this);
  }

  handleContinue() {
    this.props.updatePRO({
      type: 'diabetesInitial',
      position: this.getTrackIndex(),
      value: this.state });
    this.forwardWithQuery(this.props.location.query);
  }

  handleDrinkCount = (e) => {
    const { value } = e.target;
    this.setState({
      drinksPerWeek: value,
    });
  };

  render() {
    const { drinker, drinksPerWeek } = this.state;

    let disabled;

    if (drinker === true && drinksPerWeek > 0) {
      disabled = false;
    } else if (drinker === false) {
      disabled = false;
    } else {
      disabled = true;
    }

    return (
      <div style={styles.mainContainer}>
        <AppBar
          headerNode="Fluid Intake"
          noLeftNode={true}
        />
        <LinearProgress
          variant="determinate"
          value={(this.getCurrentPage() / this.getTotalPages()) * 100}
        />
        <div style={styles.iconContainer}>
          <div style={styles.iconWrapper}>
            <WineGlass />
          </div>
        </div>
        <div style={styles.wrapper}>
          <div style={styles.titleGroup}>
            <p style={styles.text}>Do you drink alcohol?</p>
            <div style={styles.buttonGroup}>
              <Button style={styles.button} variant="contained" color={drinker ? 'primary' : 'default'} onClick={() => this.setState({ drinker: true })}>Yes</Button>
              <Button style={styles.button} variant="contained" color={drinker === false ? 'primary' : 'default'} onClick={() => this.setState({ drinker: false, drinksPerWeek: '' })}>No</Button>
            </div>
          </div>
        </div>
        <div className={`${drinker ? '' : 'hidden'}`} style={styles.titleGroup}>

          <p style={styles.text}>How many drinks per week?</p>
          <div style={styles.formControl}>
            <FormControl style={styles.formControlItem}>
              <StyledInput
                id="drinksPerWeek"
                type="tel"
                value={drinksPerWeek}
                name="drinksPerWeek"
                placeholder="00"
                onChange={this.handleDrinkCount}
                inputProps={{
                  'aria-label': 'drinksPerWeek',
                }}
              />
            </FormControl>
            <Typography style={styles.text}>drinks</Typography>
          </div>
        </div>

        <div style={styles.nextBtn}>
          <Continue
            disabled={disabled}
            text="Next"
            onClick={this.handleContinue}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { user, proForms: { diabetesInitial } } = state;
  return { user, diabetesInitial };
}

Diabetes10.proptypes = {
  user: PropTypes.object.isRequired,
  route: PropTypes.object.isRequired,
  updatePRO: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { updatePRO })(Diabetes10);
