import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import AppBar from '../components/app-bar';
import Page from './page';
import QuestionLayout from '../layouts/question';
import YesNoButtonSet from '../components/yes-no-button-set';
import { colors, fontSizing } from '../lib/styles';
import { updatePRO } from '../state/pro-forms';
import { Toilet } from '../lib/icons';
import CenteredIcon from '../components/centered-icon';

const styles = {
  breathingText: {
    fontSize: '2rem',
    fontWeight: 'bold',
  },
  pageContent: {
    fontSize: fontSizing.body,
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 20,
  },
};

class GIOncology2 extends Page {
  constructor(props) {
    super(props);

    this.state = {
      constipated: null,
      threeDaysSinceBowelMovement: null,
    };
  }

  handleYesNoChange = (field, value) => {
    this.setState({ [field]: value });
  }

  handleClickNext = () => {
    const { constipated, threeDaysSinceBowelMovement } = this.state;

    const value = { constipated };

    if (constipated === 'Y') value.threeDaysSinceBowelMovement = threeDaysSinceBowelMovement;

    this.props.updatePRO({
      type: 'giOncology',
      position: this.getTrackIndex(),
      value,
    });

    this.forwardWithQuery(this.props.location.query);
  }

  render() {
    const { classes } = this.props;
    const { constipated, threeDaysSinceBowelMovement } = this.state;

    const continueDisabled = constipated === null
      || (constipated === 'Y' && threeDaysSinceBowelMovement === null);

    return (
      <QuestionLayout
        primaryBtnLabel="Next"
        primaryBtnOnTouchTap={this.handleClickNext}
        primaryBtnDisabled={continueDisabled}
        style={{ backgroundColor: colors.questionBackground }}
      >
        <AppBar
          headerNode="Bowel Movement"
          backButtonOnClick={() => this.props.router.goBack()}
        />
        <LinearProgress
          variant="determinate"
          value={(this.getCurrentPage() / this.getTotalPages()) * 100}
          classes={{ bar: classes.bar }}
        />
        <div className={classes.pageContent}>
          <CenteredIcon>
            <Toilet />
          </CenteredIcon>
          <YesNoButtonSet
            isYes={constipated === 'Y'}
            isNo={constipated === 'N'}
            onClickNo={() => this.handleYesNoChange('constipated', 'N')}
            onClickYes={() => this.handleYesNoChange('constipated', 'Y')}
            questionText="Are you feeling constipated?"
          />
          {constipated === 'Y' ? (
            <YesNoButtonSet
              isYes={threeDaysSinceBowelMovement === 'Y'}
              isNo={threeDaysSinceBowelMovement === 'N'}
              onClickNo={() => this.handleYesNoChange('threeDaysSinceBowelMovement', 'N')}
              onClickYes={() => this.handleYesNoChange('threeDaysSinceBowelMovement', 'Y')}
              questionText="Has it been more than 3 days since you last had a bowel movement?"
            />
          ) : null}
        </div>
      </QuestionLayout>
    );
  }
}

export default connect(null, { updatePRO })(withStyles(styles)(GIOncology2));
