import React from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash';

import AppBar from '../components/app-bar';
import Page from './page';
import Layout from '../layouts/common';
import Continue from '../components/continue';
import MultilineTextField from '../components/multiline-textfield';
import { custom, colors } from '../lib/styles';

import { updatePRO, submitPRO } from '../state/pro-forms';
import { apiFetch, makeShadowOptions } from '../lib/fetch';

const baseStyles = {
  btn: {
    marginTop: '2rem',
    backgroundColor: colors.secondaryColor,
    width: '55%',
    height: '5rem',
  },
  complaint: {
    fontSize: '1rem',
  },
  label: {
    fontSize: '1.4rem',
  },
  skipBtn: {
    width: '55%',
    height: '5rem',
  },
  skipLabel: {
    fontSize: '1.4rem',
  },
  title: {
    fontSize: '1.8rem',
    color: colors.darkGrayText,
  },
};

class PrimaryComplaint extends Page {
  constructor(props) {
    super(props);

    this.state = {
      submitting: false,
      chiefComplaintError: '',
      generalHx: {
        chief_complaint: '',
        ...props.generalHx,
      },
    };


    this.handleSave = this.handleSave.bind(this);
    this.handleChiefComplaint = this.handleText.bind(this, 'chief_complaint');
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.generalHx !== nextProps.generalHx) {
      this.setState({
        generalHx: {
          chief_complaint: '',
          ...nextProps.generalHx,
        },
      });
    }
  }

  handleText(field, e) {
    this.setState({
      generalHx: {
        ...this.state.generalHx,
        [field]: e.target.value,
      },
    });
  }

  handleSave() {
    const { generalHx } = this.state;
    const { user, location } = this.props;
    const { id } = location.query;
    let chiefComplaintError = '';
    this.setState({ submitting: false, error: null });
    if (!generalHx.chief_complaint) {
      chiefComplaintError = (
        <div style={custom.redErrorText}>Please describe your primary health complaint</div>
      );
    }
    if (chiefComplaintError) {
      return this.setState({ error: chiefComplaintError, submitting: false });
    }

    const options = makeShadowOptions({ ...generalHx }, user.token, 'POST');
    apiFetch(`/users/${user.id}/general_hx_pro`, options)
      .then(() => {
        // Save the data locally in case the user is currently in a railroad
        updatePRO({ type: 'reasonForVisit', position: this.getTrackIndex(), value: generalHx });

        return submitPRO({ userId: user.id, id }, {
          pro_type: 'REASON-FOR-VISIT',
          pro_data: { data: [generalHx.chief_complaint] },
        }, user);
      })
      .then(() => this.forward())
      .catch(e => this.setState({ error: e.message, submitting: false }));
  }

  render() {
    const { error } = this.state;
    return (
      <Layout>
        <div>
          <AppBar
            backButtonOnClick={() => this.props.router.goBack()}
            headerNode="Reason For Visit"
          />
          <p style={baseStyles.title}> Reason for Visit </p>
          <img alt="a clipboard" src="/img/clipboard-question-gray.png" style={custom.clipboard} />
          <section style={custom.whiteBackground}>
            <MultilineTextField
              hintText="Please describe the primary reason for your visit"
              onChange={this.handleChiefComplaint}
              value={this.state.generalHx.chief_complaint}
              style={baseStyles.complaint}
            />
          </section>
          {error}
          <Continue
            onClick={this.handleSave}
            text="Submit"
            btnStyle={baseStyles.btn}
            labelStyle={baseStyles.label}
          />
        </div>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  const { proForms, user } = state;
  return {
    generalHx: get(proForms, 'generalHx[0]', {}),
    user,
  };
}

export default connect(mapStateToProps, {
  updatePRO,
  submitPRO,
})(PrimaryComplaint);
