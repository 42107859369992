import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { noop } from 'lodash';

import AppBar from '../components/app-bar';
import Page from './page';
import Continue from '../components/continue';
import { Notification } from '../lib/icons';
import { custom, colors } from '../lib/styles';

const baseStyles = {
  btn: {
    height: '5rem',
    width: '60vw',
    maxWidth: '400px',
  },
  container: {
    marginTop: '3.125rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  email: {
    fontSize: '1.4rem',
    color: colors.primaryColor,
    marginTop: '13px',
  },
  label: {
    fontSize: '1.4rem',
  },
  notification: {
    width: '5rem',
    height: '4rem',
    marginBottom: '7rem',
  },
  p: {
    fontSize: '1.4rem',
    margin: '4rem 5rem',
  },
  subheader: {
    fontSize: '1.5rem',
  },
};

class EmailConfirmation extends Page {
  render() {
    const { user } = this.props;
    return (
      <div>
        <AppBar
          backButtonOnClick={() => this.props.router.push('/signup')}
          headerNode="Confirmation"
        />
        <div style={baseStyles.container}>
          <Notification
            style={baseStyles.notification}
          />
          <section>
            <div style={custom.signupHeaderText}>
              <div style={baseStyles.subheader}>A confirmation email has been sent to:</div>
              <div style={baseStyles.email}>{user.email}</div>
              <p style={baseStyles.p}>
                Please check your inbox and confirm your account to continue with the registration process
              </p>
            </div>
          </section>
          <Continue
            btnStyle={baseStyles.btn}
            labelStyle={baseStyles.label}
            onClick={this.forward}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { user, personalInfo } = state;

  return { user, personalInfo };
}

EmailConfirmation.defaultProps = {
  router: noop,
};

EmailConfirmation.propTypes = {
  router: PropTypes.func,
  personalInfo: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};


export default connect(mapStateToProps)(EmailConfirmation);
