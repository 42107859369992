import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';

import Cropper from 'react-cropper';
import { Button, Dialog } from '@material-ui/core';
import 'cropperjs/dist/cropper.css';

const baseStyles = {
  container: {
    maxWidth: 'none',
    width: '100%',
  },
};

class CropModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
    this.onCropImage = this.onCropImage.bind(this);
  }

  onCropImage() {
    if (typeof this.cropper.getCroppedCanvas() === 'undefined') {
      return;
    }

    const cropResult = this.cropper.getCroppedCanvas().toDataURL();
    this.props.handleUpload(cropResult);
    this.props.handleModalClose();
  }

  render() {
    return (
      <Dialog
        autoScrollBodyContent={true}
        contentStyle={baseStyles.container}
        open={this.props.open}
        title="Crop Your Image"
      >
        <Cropper
          style={{ height: 300, width: '100%' }}
          aspectRatio={this.props.aspectRatio}
          guides={false}
          src={this.props.src}
          ref={(cropper) => { this.cropper = cropper; }}
        />
        <div>
          <Button
            color="secondary"
            onClick={this.onCropImage}
            style={{ display: 'block' }}
            fullWidth
            variant="contained"
          >
            {`Set new ${this.props.imageTypeName} image`}
          </Button>
        </div>
      </Dialog>
    );
  }
}
CropModal.defaultProps = {
  aspectRatio: undefined,
  handleModalClose: noop,
  handleUpload: noop,
  imageTypeName: '',
  open: false,
  src: '',
};

CropModal.propTypes = {
  aspectRatio: PropTypes.number,
  handleModalClose: PropTypes.func,
  handleUpload: PropTypes.func,
  imageTypeName: PropTypes.string,
  open: PropTypes.bool,
  src: PropTypes.node,
};

export default CropModal;
