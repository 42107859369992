import React from 'react';
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  LinearProgress,
  TextField,
} from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import AppBar from '../components/app-bar';
import Page from './page';
import QuestionLayout from '../layouts/question';
import YesNoButtonSet from '../components/yes-no-button-set';
import { colors } from '../lib/styles';
import { updatePRO } from '../state/pro-forms';
import { Walking2 } from '../lib/icons';

const styles = {
  contentWrapper: {
    flexGrow: 1,
    overflowX: 'hidden',
    position: 'relative',
  },
  imgRow: {
    display: 'flex',
    justifyContent: 'center',
    padding: '15px 60px',
  },
  imageWrapper: {
    width: 100,
  },
  inputField: {
    width: '6rem',
    '& input': {
      textAlign: 'center',
    },
  },
  marginTop40: {
    marginTop: 40,
  },
  questionnaireContainer: {
    paddingLeft: 20,
    paddingRight: 20,
  },
  questionText: {
    fontSize: '2rem',
  },
  textFieldContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  textFieldWrapper: {
    alignItems: 'center',
    display: 'flex',
    paddingTop: 20,
  },
  unitsWrapper: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    marginLeft: 5,
  },
};

class HypertensionAssessment5 extends Page {
  constructor(props) {
    super(props);

    this.state = {
      cardio: false,
      exercisesDaily: null,
      exerciseMinsPerDay: '',
      other: false,
      strength: false,
    };
  }

  handleChangeExercisesDaily = (value) => {
    this.setState({ exercisesDaily: value });
  };

  handleChangeExerciseMinsPerDay = (e) => {
    this.setState({ exerciseMinsPerDay: e.target.value });
  };

  handleChangeExerciseType = (e) => {
    this.setState({ [e.target.name]: e.target.checked });
  };

  handleClickNext = () => {
    const {
      cardio,
      exercisesDaily,
      exerciseMinsPerDay,
      other,
      strength,
    } = this.state;

    const exerciseTypes = [];

    const value = { exercisesDaily };

    if (exercisesDaily === 'Y') {
      if (cardio) exerciseTypes.push('Cardio');
      if (strength) exerciseTypes.push('Strength');
      if (other) exerciseTypes.push('Yoga/Pilates/Other');
      value.exerciseMinsPerDay = exerciseMinsPerDay;
      value.exerciseTypes = exerciseTypes;
    }

    this.props.updatePRO({
      type: 'hypertensionAssessment',
      position: this.getTrackIndex(),
      value,
    });

    this.forwardWithQuery(this.props.location.query);
  }

  render() {
    const { classes } = this.props;
    const {
      cardio,
      exercisesDaily,
      exerciseMinsPerDay,
      other,
      strength,
    } = this.state;

    const continueDisabled = exercisesDaily === null
      || (exercisesDaily === 'Y' && !exerciseMinsPerDay);

    return (
      <QuestionLayout
        primaryBtnLabel="Next"
        primaryBtnOnTouchTap={this.handleClickNext}
        primaryBtnDisabled={continueDisabled}
        style={{ backgroundColor: colors.questionBackground }}
      >
        <AppBar
          headerNode="Exercise"
          backButtonOnClick={() => this.props.router.goBack()}
        />
        <LinearProgress
          variant="determinate"
          value={(this.getCurrentPage() / this.getTotalPages()) * 100}
          classes={{ bar: classes.bar }}
        />
        <div className={classes.contentWrapper}>
          <div className={classes.imgRow}>
            <div className={classes.imageWrapper}>
              <Walking2 />
            </div>
          </div>
          <div className={classes.questionnaireContainer}>
            <YesNoButtonSet
              isYes={exercisesDaily === 'Y'}
              isNo={exercisesDaily === 'N'}
              onClickNo={() => this.handleChangeExercisesDaily('N')}
              onClickYes={() => this.handleChangeExercisesDaily('Y')}
              questionText="Do you exercise daily?"
            />
            {exercisesDaily === 'Y' ? (
              <>
                <div className={`${classes.questionText} ${classes.marginTop40}`}>How many minutes per day?</div>
                <div className={classes.textFieldContainer}>
                  <div className={classes.textFieldWrapper}>
                    <TextField
                      className={classes.inputField}
                      onChange={this.handleChangeExerciseMinsPerDay}
                      type="number"
                      inputProps={{
                        inputMode: 'decimal',
                        pattern: '[0-9]*',
                        type: 'tel',
                        style: { fontSize: '16px' } }}
                      value={exerciseMinsPerDay}
                      placeholder="0"
                    />
                    <span className={classes.unitsWrapper}>minutes</span>
                  </div>
                </div>
                <FormGroup className={classes.marginTop40}>
                  <FormControlLabel
                    control={(
                      <Checkbox
                        checked={cardio}
                        onChange={this.handleChangeExerciseType}
                        name="cardio"
                        icon={<CheckBoxOutlineBlankIcon fontSize="large" />}
                        checkedIcon={<CheckBoxIcon fontSize="large" />}
                      />
                    )}
                    label="Cardio"
                    classes={{
                      label: classes.questionText,
                    }}
                  />
                  <FormControlLabel
                    control={(
                      <Checkbox
                        checked={strength}
                        onChange={this.handleChangeExerciseType}
                        name="strength"
                        icon={<CheckBoxOutlineBlankIcon fontSize="large" />}
                        checkedIcon={<CheckBoxIcon fontSize="large" />}
                      />)}
                    label="Strength Training"
                    classes={{
                      label: classes.questionText,
                    }}
                  />
                  <FormControlLabel
                    control={(
                      <Checkbox
                        checked={other}
                        onChange={this.handleChangeExerciseType}
                        name="other"
                        icon={<CheckBoxOutlineBlankIcon fontSize="large" />}
                        checkedIcon={<CheckBoxIcon fontSize="large" />}
                      />)}
                    label="Yoga/Pilates/Other"
                    classes={{
                      label: classes.questionText,
                    }}
                  />
                </FormGroup>
              </>
            ) : null}
          </div>
        </div>
      </QuestionLayout>
    );
  }
}

export default connect(null, { updatePRO })(withStyles(styles)(HypertensionAssessment5));
