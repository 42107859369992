import React from 'react';
import { LinearProgress, Slider } from '@material-ui/core';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import AppBar from '../components/app-bar';
import YesNoButtonSet from '../components/yes-no-button-set';

import QuestionLayout from '../layouts/question';

import i18nTranslator, { title, nextBtn } from '../lib/i18next';
import { PainIntensity } from '../lib/icons';
import { colors, painScaleColors } from '../lib/styles';

import Page from './page';

import { submitPRO, updatePRO } from '../state/pro-forms';

const styles = {
  contentWrapper: {
    flexGrow: 1,
    overflowX: 'hidden',
    position: 'relative',
  },
  imgRow: {
    display: 'flex',
    justifyContent: 'center',
    padding: '15px 60px',
  },
  imageWrapper: {
    width: 100,
  },
  paragraph: {
    marginLeft: '15px',
    marginRight: '15px',
    marginBottom: '15px',
    fontSize: '29px',
  },
  sliderContainer: {
    margin: '0px 30px',
    position: 'relative',
  },
  questionnaireContainer: {
    paddingLeft: 20,
    paddingRight: 20,
  },
  questionText: {
    fontSize: 29,
    margin: '25px 10px 0px',
  },
  select: {
    fontSize: 29,
    marginTop: 5,
  },
  slider: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '50px',
    fontSize: 16,
  },
  sliderStyle: {
    flexGrow: 1,
    marginBottom: 24,
  },
  sliderLabel: {
    fontSize: 16,
    maxWidth: 100,
    textAlign: 'center',
  },
};

class PisaInjection2 extends Page {
  constructor(props) {
    super(props);

    this.state = {
      reducePain: '',
      qualityImproved: '',
      theme: this.getSliderTheme(0),
      painLevel: 0,
    };
  }

  getSliderTheme = (value) => {
    return {
      color: painScaleColors[value],
    };
  }

  handlePainLevelSlider = (e, value) => {
    const theme = this.getSliderTheme(value);
    this.setState({ theme, painLevel: value });
  }

  handleChangeQualityImproved = (value) => {
    this.setState({ qualityImproved: value });
  };

  handleChangeReducePain = (value) => {
    this.setState({ reducePain: value });
  };


  handleClickNext = () => {
    const {
      qualityImproved,
      reducePain,
      painLevel,
    } = this.state;

    const value = { painLevel, reducePain, qualityImproved };

    this.props.updatePRO({
      type: 'pisaInjection',
      position: this.getTrackIndex(),
      value,
    });

    if (qualityImproved === 'N') {
      this.props.updatePRO({
        type: 'pisaInjection',
        position: this.getTrackIndex() + 1,
        value: { symptoms: [] },
      });
      this.goToIndexWithQuery(2, this.props.location.query);
    }

    this.forwardWithQuery(this.props.location.query);
  }

  render() {
    const { classes } = this.props;
    const {
      theme,
      painLevel,
      qualityImproved,
      reducePain,
    } = this.state;

    const continueDisabled = !qualityImproved || !reducePain;

    return (
      <QuestionLayout
        primaryBtnLabel={nextBtn()}
        primaryBtnOnTouchTap={this.handleClickNext}
        primaryBtnDisabled={continueDisabled}
        style={{ backgroundColor: colors.questionBackground }}
      >
        <AppBar
          headerNode={title('pisaInjection2')}
          backButtonOnClick={() => this.props.router.goBack()}
        />
        <LinearProgress
          variant="determinate"
          value={(this.getCurrentPage() / this.getTotalPages()) * 100}
          classes={{ bar: classes.bar }}
        />
        <div className={classes.contentWrapper}>
          <div className={classes.imgRow}>
            <div className={classes.imageWrapper}>
              <PainIntensity />
            </div>
          </div>
          <div style={styles.contentWrapper}>
            <p style={styles.paragraph}>{i18nTranslator('question1', 'pisaInjection2')}</p>
            <div style={styles.sliderContainer}>
              <Slider
                min={0}
                max={10}
                value={0 || painLevel}
                valueLabelDisplay="on"
                onChange={this.handlePainLevelSlider}
                style={{ ...styles.slider, ...theme }}
              />
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <div style={{ ...styles.sliderLabel, marginBottom: '50px' }}>
                  {i18nTranslator('noPain', 'pisaInjection2')}
                </div>
                <div style={{ ...styles.sliderLabel, marginBottom: '50px' }}>
                  {i18nTranslator('worstPain', 'pisaInjection2')}
                </div>
              </div>
            </div>
          </div>
          <div className={classes.questionnaireContainer}>
            <YesNoButtonSet
              isYes={reducePain === 'Y'}
              isNo={reducePain === 'N'}
              onClickNo={() => this.handleChangeReducePain('N')}
              onClickYes={() => this.handleChangeReducePain('Y')}
              questionText={i18nTranslator('question2', 'pisaInjection2')}
              questionTextSize={29}
            />
            <YesNoButtonSet
              isYes={qualityImproved === 'Y'}
              isNo={qualityImproved === 'N'}
              onClickNo={() => this.handleChangeQualityImproved('N')}
              onClickYes={() => this.handleChangeQualityImproved('Y')}
              questionText={i18nTranslator('question3', 'pisaInjection2')}
              questionTextSize={29}
            />
          </div>
        </div>
      </QuestionLayout>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    proForms: {
      pisaInjection,
    },
    user,
  } = state;

  return { pisaInjection, user };
};

export default connect(mapStateToProps, { submitPRO, updatePRO })(withStyles(styles)(PisaInjection2));
