import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faCircleXmark } from '@fortawesome/free-regular-svg-icons';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';

import AppBar from '../components/app-bar';
import Page from './page';
import StickyHeader from '../components/sticky-header';
import Layout from '../layouts/common';
import { colors } from '../lib/styles';
import i18nTranslator, { title, nextBtn } from '../lib/i18next';
import runNetworkSpeedTest from '../lib/speed-test';

const styles = {
  container: {
    marginTop: '10%',
    flexGrow: 1,
  },
  results: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  spacer: {
    flexGrow: 1,
    margin: 'auto',
  },
  page: {
    padding: '60px 5px 5px 5px',
    color: colors.primaryColor,
    backgroundColor: colors.white,
    height: '100%',
    display: 'flex',
  },
  button: {
    width: '100%',
    margin: '10px 0',
    fontSize: '19px',
  },
  text: {
    color: colors.black,
    fontSize: '19px',
    fontWeight: 'bold',
  },
  textFail: {
    color: colors.black,
    fontSize: '19px',
  },
  pass: {
    width: '100px',
    height: '100px',
    color: colors.successGreen,
  },
  fail: {
    width: '100px',
    height: '100px',
    color: colors.errorRed,
  },
  pass_sm: {
    color: colors.successGreen,
    marginRight: 5,
  },
  fail_sm: {
    color: colors.errorRed,
    marginRight: 5,
  },
  grid: {
    width: '80%',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  gridItem: {
    fontSize: 'large',
    display: 'flex',
    justifyContent: 'flex-start',
    color: 'black',
  },
};

class AppointmentConfirmation4 extends Page {
  constructor(props) {
    super(props);
    this.state = {
      cameraOK: false,
      microphoneOK: false,
      networkOK: false,
      loading: true,
    };
  }

  async componentDidMount() {
    const permissions = navigator.mediaDevices.getUserMedia({ audio: true, video: true });

    await permissions
      .then((track) => {
        this.setState({ cameraOK: true, microphoneOK: true });
        return track;
      }).then((media) => {
        media.getTracks().forEach(t => t.stop());
      }).catch((err) => {
        if (err.name === 'NotAllowedError') {
          this.setState({ cameraOK: false, microphoneOK: false, permissionsBlocked: true });
        }
      });

    if (!navigator.onLine) {
      return this.setState({ networkOK: false });
    }

    const { download, upload } = await runNetworkSpeedTest();
    this.setState({ loading: false, networkOK: download && upload });
  }

  handleContinue = async () => {
    this.goToIndexWithQuery(1, this.props.location.query);
  };

  render() {
    const { cameraOK, microphoneOK, networkOK, loading } = this.state;

    const allOK = microphoneOK && cameraOK && networkOK;
    return (
      <Layout style={{ height: '100%' }}>
        <div style={styles.page}>
          <StickyHeader>
            <AppBar
              backButtonOnClick={() => this.props.router.goBack()}
              headerNode={title('appointmentConfirmation4')}
            />
          </StickyHeader>
          <div style={styles.container}>
            {
              loading ? (
                <CircularProgress size={100} style={{ marginTop: 100 }} />
              ) : (
                <div style={styles.results}>
                  {
                    allOK ? (
                      <>
                        <FontAwesomeIcon icon={faCircleCheck} style={styles.pass} />
                        <p style={styles.text}>{i18nTranslator('pass', 'appointmentConfirmation4')}</p>
                      </>
                    ) : (
                      <>
                        <FontAwesomeIcon icon={faCircleXmark} style={styles.fail} />
                        <p style={styles.text}>{i18nTranslator('fail', 'appointmentConfirmation4')}</p>
                      </>
                    )
                  }
                  <Grid container spacing={3} style={styles.grid}>
                    <Grid item xs={12} style={styles.gridItem}>
                      {cameraOK ? <FontAwesomeIcon icon={faCircleCheck} style={styles.pass_sm} /> : <FontAwesomeIcon icon={faCircleXmark} style={styles.fail_sm} /> } {i18nTranslator('camera', 'appointmentConfirmation4')}
                    </Grid>
                    <Grid item xs={12} style={styles.gridItem}>
                      {microphoneOK ? <FontAwesomeIcon icon={faCircleCheck} style={styles.pass_sm} /> : <FontAwesomeIcon icon={faCircleXmark} style={styles.fail_sm} /> } {i18nTranslator('microphone', 'appointmentConfirmation4')}
                    </Grid>
                    <Grid item xs={12} style={styles.gridItem}>
                      {networkOK ? <FontAwesomeIcon icon={faCircleCheck} style={styles.pass_sm} /> : <FontAwesomeIcon icon={faCircleXmark} style={styles.fail_sm} /> } {i18nTranslator('network', 'appointmentConfirmation4')}
                    </Grid>
                  </Grid>

                  {
                    !allOK && (
                      <div style={{ marginTop: '50px' }}>
                        <p style={styles.text}>{i18nTranslator('unstableHeader', 'appointmentConfirmation4')}</p>
                        <p style={styles.textFail}>{i18nTranslator('unstableMessage', 'appointmentConfirmation4')}</p>
                      </div>
                    )
                  }
                  {
                    this.state.permissionsBlocked && (
                      <p style={styles.textFail}>{i18nTranslator('permissionsBlocked', 'appointmentConfirmation4')}</p>
                    )
                  }
                  <div style={styles.spacer} />
                  <Button
                    variant="contained"
                    color="primary"
                    style={styles.button}
                    onClick={() => this.handleContinue()}
                  >
                    {nextBtn()}
                  </Button>
                </div>
              )
            }
          </div>
        </div>
      </Layout>
    );
  }
}

export default AppointmentConfirmation4;
