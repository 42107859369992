import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { colors } from '../lib/styles';

const styles = {
  svg: {
    display: 'block',
    transform: 'rotate(-90deg)',
  },
};

class CircularProgress extends Component {
  render() {
    const {
      backgroundColor,
      progressColor,
      progress,
      strokeWidth,
    } = this.props;
    const radius = 50 - (strokeWidth / 2);
    const circumference = 2 * Math.PI * radius;
    const offset = circumference * (1 - progress);

    return (
      <svg style={styles.svg} width="100%" viewBox="0 0 100 100">
        <circle
          cx="50"
          cy="50"
          r={radius}
          fill="none"
          stroke={backgroundColor}
          strokeWidth={strokeWidth}
        />
        <circle
          cx="50"
          cy="50"
          r={radius}
          fill="none"
          stroke={progressColor}
          strokeDasharray={circumference}
          strokeDashoffset={offset}
          strokeWidth={strokeWidth}
        />
      </svg>
    );
  }
}

CircularProgress.defaultProps = {
  backgroundColor: 'lightgrey',
  progressColor: colors.primaryColor,
  progress: 0,
  strokeWidth: 10,
};

CircularProgress.propTypes = {
  backgroundColor: PropTypes.string,
  progressColor: PropTypes.string,
  progress: PropTypes.number,
  strokeWidth: PropTypes.number,
};

export default CircularProgress;
