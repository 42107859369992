const sdpConstraints = {
  optional: [],
  mandatory: {
  },
};

const vitalCoreConfig = {
  termsAndConditionsVersion: '1',
  consentToRecordVersion: '1',
  ivcVersion: '0.9.1',
  ivcRRVersion: '2.0.0',
  ivcRRWebAppVersion: '0.0.1',
  ivcWebAppVersion: process.env.REACT_APP_IVC_WEB_APP_VERSION,
};

const env = {
  // API_URL: 'https://aaaronapi.shiv.to',
  API_URL: process.env.REACT_APP_API_URL || `http://${(new URL(document.location.href)).hostname}:3001`,
  IVC_URL: process.env.REACT_APP_IVC_APP || `http://${(new URL(document.location.href)).hostname}:3011`,
  IVC_RR_URL: process.env.REACT_APP_IVC_RR_APP || `http://${(new URL(document.location.href)).hostname}:3012`,
  VT_URL: process.env.REACT_APP_VT_APP || `http://${(new URL(document.location.href)).hostname}:3013`,
  IOS_AMPLITUDE_API_KEY: '5a2404cd4e403389373cd73a8482ed05',
  ANDROID_AMPLITUDE_API_KEY: '5a2404cd4e403389373cd73a8482ed05',
  WEB_AMPLITUDE_API_KEY: '5a2404cd4e403389373cd73a8482ed05',
  HELLOSIGN_CLIENT_ID: process.env.REACT_APP_HELLOSIGN_CLIENT_ID || 'ee84166a6bb0bee33595b1726064d943', // staging client id
  rtc: {
    cfg: { 'iceServers': [{ 'urls': 'stun:stun.l.google.com:19302' }] },
    options: { 'optional': [{ 'DtlsSrtpKeyAgreement': true }] }
  },
  sdpConstraints,
  vitalCoreConfig,
};

export default env;
