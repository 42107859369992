import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import validator from '../lib/validator';
import { login, userSchema } from '../state/user';
import { colors } from '../lib/styles';
import { apiFetch } from '../lib/fetch';

import Informed from '../components/informed';
import AppBar from '../components/app-bar';

import AuthLayout from '../layouts/auth';

function mapStateToProps(state) {
  const { user } = state;
  return { user };
}

function mapDispatchToProps(dispatch) {
  const actionCreators = { login };

  return bindActionCreators(actionCreators, dispatch);
}

const baseStyles = {
  bottomText: {
    color: colors.highlightMedium,
    cursor: 'pointer',
    textDecoration: 'underline',
  },
  btnLabel: {
    fontSize: '18px',
  },
  btnStyle: {
    marginTop: '20px',
    width: '80vw',
    maxWidth: '445px',
    marginBottom: '30px',
    borderRadius: '5px',
    backgroundColor: colors.primaryColor,
    fontSize: '15px',
    color: colors.white,
  },
  contentBoxIos: {
    height: '250px',
  },
  iosSuccess: {
    color: colors.successGreen,
    fontSize: '28px',
    paddingTop: '10px',
  },
  field: {
    borderRadius: '10px',
    display: 'block',
    height: '50px',
    padding: '10px',
  },
  passwordHintStyle: {
    marginTop: '5px',
  },
  shrinkStyle: {
    display: 'none',
  },
  textFieldLabel: {
    color: colors.highlightMedium,
    fontSize: '18px',
    margin: '5px 15px',
  },
  textFieldInput: {
    backgroundColor: colors.white,
    color: colors.primaryColor,
    padding: '10px',
  },
  logo: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '30px',
    position: 'relative',
    top: '45px',
    width: '100%',
  },
  h2: {
    margin: '0px auto 30px',
    fontSize: '1.5rem',
  },
};

const passwordResetSchema = {
  password: userSchema.password,
  verify_password: userSchema.password,
};

export class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: '',
      msg: '',
      password: '',
      verify_password: '',
      iosResetComplete: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleResetPassword = this.handleResetPassword.bind(this);
    this.handleUpdatePassword = this.handleChange.bind(this, 'password');
    this.handleUpdateVerifyPassword = this.handleChange.bind(this, 'verify_password');
  }

  handleChange(field, evt) {
    this.setState({ [field]: evt.target.value });
  }

  handleResetPassword() {
    const resetId = this.props.location.query.reset_id;
    const { password, verify_password } = this.state;
    const resetPayload = { password, verify_password };
    this.setState({ error: '' });
    if (password !== verify_password) {
      return this.setState({ error: 'Passwords do not match' });
    }
    const valid = validator(resetPayload, passwordResetSchema);
    if (valid.error) {
      return this.setState({ error: valid.error.message });
    }

    const requestOptions = {
      body: resetPayload,
      method: 'PUT',
    };
    return apiFetch(`/reset/${resetId}`, requestOptions)
      .then((res) => {
        this.setState({
          error: '',
          verify_password: '',
          password: '',
        });

        if (this.props.route.ios) {
          return this.setState({
            iosResetComplete: true,
          });
        }
        return this.props.router.push('/?msg=password%20reset%20you%20may%20now%20login');
      })
      .catch(() => {
        this.setState({
          error: 'Error setting new password, please try again',
        });
      });
  }

  render() {
    if (this.state.iosResetComplete) {
      return (
        <AuthLayout
          error={this.state.error}
          msg=""
        >
          <div style={baseStyles.iosSuccess}>
            Your password has been successfully changed.
            You may now login to the iOS application.
          </div>
        </AuthLayout>
      );
    }
    return (
      <div>
        <AppBar
          backButtonOnClick={() => this.props.router.goBack()}
          headerNode="Reset Password"
        />
        <div style={baseStyles.logo}>
          <Informed width="20rem" />
        </div>
        <AuthLayout error={this.state.error} msg={this.state.msg}>
          <h2 style={baseStyles.h2}>Enter New Password</h2>
          <TextField
            placeholder="new password"
            InputProps={{
              style: { fontSize: '13px' },
            }}
            onChange={this.handleUpdatePassword}
            fullWidth
            style={baseStyles.field}
            value={this.state.password}
            type="password"
          />
          <TextField
            placeholder="verify password"
            InputProps={{
              style: { fontSize: '13px' },
            }}
            onChange={this.handleUpdateVerifyPassword}
            fullWidth
            style={baseStyles.field}
            value={this.state.verify_password}
            type="password"
          />
          <div style={baseStyles.passwordHintStyle}>Passwords must be at least 8 characters, contain 1 number, and 1 uppercase letter.</div>
          <Button
            style={baseStyles.btnStyle}
            onClick={this.handleResetPassword}
            disabled={false}
          >
            Reset Password
          </Button>
        </AuthLayout>
      </div>
    );
  }
}

Login.defaultProps = {
  location: {},
  route: {},
  router: {},
};

Login.propTypes = {
  location: PropTypes.object,
  route: PropTypes.object,
  router: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
