import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get, includes } from 'lodash';

import { colors, fontSizing } from '../lib/styles';
import Page from './page';
import ButtonPRO from '../components/button-pro';
import { updatePRO } from '../state/pro-forms';
import { Frown } from '../lib/icons';

const buttonLabels = ['Not at all', 'Several days', 'More than half the days', 'Nearly every day'];

const options = [
  {
    question: 'Little interest or pleasure in doing things',
    label: buttonLabels,
  },
  {
    question: 'Feeling down, depressed, or hopeless',
    label: buttonLabels,
  },
  {
    question: 'Trouble falling or staying asleep, or sleeping too much',
    label: buttonLabels,
  },
  {
    question: 'Feeling tired or having little energy',
    label: buttonLabels,
  },
  {
    question: 'Poor appetite or overeating',
    label: buttonLabels,
  },
  {
    question: 'Feeling bad about yourself - or that you are a failure',
    label: buttonLabels,
  },
  {
    question: 'Trouble concentrating on things such as reading, writing, or watching TV',
    label: buttonLabels,
  },
  {
    question: 'Moving or speaking so slowly that other people have noticed, or being more fidgety or restless than usual',
    label: buttonLabels,
  },
  {
    question: 'Thought that you would be better off dead or hurting yourself',
    label: buttonLabels,
  },
];
const { questionBackground } = colors;

const styles = {
  button: {
    base: {
      display: 'flex',
      justifyContent: 'center',
      flexBasis: 0,
      flexGrow: 1,
      lineHeight: 'normal',
      marginRight: 5,
      fontSize: fontSizing.small,
      textTransform: 'none',
    },
    selected: {
      background: colors.primaryColor,
      border: `solid 1px ${colors.primaryColor}`,
      color: 'white',
    },
    unselected: {
      background: colors.questionBackground,
      border: 'solid 1px #D8D8D8',
      color: 'black',
    },
    buttonRow: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
    },
    buttonRowQuestion: {
      fontSize: fontSizing.body,
      marginBottom: 10,
      marginTop: 25,
    },
  },
  image: {
    height: 100,
    marginTop: 30,
    textAlign: 'center',
  },
  title: {
    fontSize: fontSizing.body,
    fontWeight: 'bold',
    marginBottom: 0,
    marginTop: 30,
  },
};

class Phq91 extends Page {
  onClickBackButton = () => {
    this.backward();
  }

  onSubmission = () => {
    this.forwardWithQuery(this.props.location.query);
  }

  render() {
    const page = get(this.props.phq9, this.getTrackIndex(), [null, null, null, null, null, null, null, null, null]);
    const value = includes(page, null) ? null : 0;
    return (
      <ButtonPRO
        backgroundColor={questionBackground}
        currentPage={this.getCurrentPage()}
        onClickBackButton={() => this.props.router.goBack()}
        onChange={this.props.updatePRO}
        onSubmission={this.onSubmission}
        options={options}
        title={<span>Over the <u>last two weeks</u>, how often have you been bothered by the following problems?</span>}
        header="Patient Health"
        totalPages={this.getTotalPages()}
        trackIndex={this.getTrackIndex()}
        value={value}
        pro={page}
        type="phq9"
        image={Frown}
        imageStyles={styles.image}
        titleStyles={styles.title}
        buttonStyles={styles.button}
        buttonLabelStyles={styles.buttonLabel}
        showInstructions={false}
      />
    );
  }
}


function mapStateToProps(state) {
  const { proForms: { phq9 } } = state;
  return { phq9 };
}

Phq91.propTypes = {
  pqh9: PropTypes.array.isRequired,
  updatePRO: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { updatePRO })(Phq91);
