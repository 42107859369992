import { forEach } from 'lodash';
import queryString from 'query-string';

import SetPin from './set-pin';
import Picture from './picture';
import PatientContact from './patient-contact';
import PersonalInfo from './personal-info';
import PrimaryComplaint from './primary-complaint';
import Symptoms from './symptoms';
import PQRS from './pqrs';
import InforUpdatedSuccessfully from './info-updated-successfully';
import Medications from './medications';
import Reactions from './reactions';
import Conditions from './conditions';
import FamilyHistory from './family-history';
import SocialHistory from './social-history';
import SocialHistory2 from './social-history2';
import PainTypeEntry from './pain-type-entry';
import PainIntro from './general-pain-intro';

import BodyPain from './body-pain';
import PainDetails from './pain-details';
import PainOrigin from './pain-origin';
import PainEntrySuccessful from './pain-entry-successful';
import RegistrationSuccess from './registration-success';
import FunctionalLimitations from './functional-limitations';
import AggravatingFactors from './aggravating-factors';
import AlleviatingFactors from './alleviating-factors';
import Disclaimer from './disclaimer';
import InsuranceFront from './insurance-front';
import InsuranceBack from './insurance-back';
import FormsIntro from './forms-intro';
import FormsComplete from './forms-complete';
import Confirmation from './email-confirmation';
import Login from './login';
import Signup from './signup';

import MigraineAbortive from './migraine-abortive';
import MigrainePain from './migraine-pain';
import MigrainePainConfirm from './migraine-pain-confirm';
import MigrainePreventiveMeds from './migraine-preventive-meds';
import MigraineSymptoms from './migraine-symptoms';

import OtherQuestion from './other-surgeries-question';
import OtherSurgeries from './other-surgeries';
import HeartQuestion from './heart-surgeries-question';
import HeartSurgeries from './heart-surgeries';
import SpineQuestion from './spine-surgeries-question';
import SpineSurgeries from './spine-surgeries';

import notifications from '../notification-pages';

import NpiSearch from './npi-search';

import { createVitalsRunnerWebWorker } from '../lib/vitals-runner';
import store from '../store';
import { setTrackType } from '../state/user';

const tracks = {
  SIGNUP: {
    pages: [
      { component: Signup, path: '/signup', noauth: true },
      { component: Confirmation, path: '/confirmation', noauth: true },
      { component: RegistrationSuccess, path: '/signup_reg', noauth: true },
      { component: Login, path: '/login', noauth: true },
      { component: Disclaimer, path: '/signup_disclaimer', noauth: true },
      { component: SetPin, path: '/signup_setpin' },
      { component: Picture, path: '/signup_picture' },
      { component: InforUpdatedSuccessfully, path: '/signup_infoupdated' },
      { component: PatientContact, path: '/signup_patientcontact' },
      { component: InforUpdatedSuccessfully, path: '/signup_infoupdated2' },
      { component: PersonalInfo, path: '/signup_personalinfo' },

      { component: FormsIntro, path: '/signup_formsintro' },
      { component: PrimaryComplaint, path: '/signup_primarycomplaint' },
      { component: PainIntro, path: '/signup_painintro' },
      { component: BodyPain, path: '/signup_pain' },
      { component: PainTypeEntry, path: '/signup_pain_type' },
      { component: PainDetails, path: '/signup_paindetails' },
      { component: FunctionalLimitations, path: '/signup_functionallimitations' },
      { component: AggravatingFactors, path: '/signup_aggravatingfactors' },
      { component: AlleviatingFactors, path: '/signup_alleviatingfactors' },
      { component: PainOrigin, path: '/signup_painorigin' },

      { component: Medications, path: '/signup_medications' },
      { component: Reactions, path: '/signup_reactions' },
      { component: FormsComplete, path: '/signup_complete' },
    ],
    returnPath: '/',
  },

  RESET_PIN: {
    pages: [
      { component: SetPin, path: '/resetpin' },
    ],
    returnPath: '/',
  },

  SIGNUP_HEADACHES: {
    pages: [
      { component: Medications, path: '/signuph_medications' },
      { component: Reactions, path: '/signuph_reactions' },
      { component: FormsComplete, path: '/signuph_complete' },
    ],
    returnPath: '/',
  },

  SIGNUP_MEDICATIONS: {
    pages: [
      { component: Medications, path: '/signupm_medications' },
      { component: Reactions, path: '/signupm_reactions' },
      { component: FormsComplete, path: '/signupm_complete' },
    ],
    returnPath: '/',
  },

  ABOUT_ME: {
    pages: [
      { component: PatientContact, path: '/patientcontact' },
      { component: PersonalInfo, path: '/personalinfo' },
      { component: InforUpdatedSuccessfully, path: '/ainfoupdated' },
    ],
    returnPath: '/',
  },

  INSURANCE: {
    pages: [
      { component: InsuranceFront, path: '/insurancefront/:pageSelection' },
      { component: InsuranceBack, path: '/insuranceback/:pageSelection' },
      { component: InforUpdatedSuccessfully, path: '/iinfoupdated' },
    ],
    returnPath: '/',
  },

  SYMPTOMS: {
    pages: [
      { component: Symptoms, path: '/symptoms' },
      { component: PQRS, path: '/pqrs' },
      { component: InforUpdatedSuccessfully, path: '/sinfoupdated' },
    ],
    returnPath: '/',
  },

  MEDICATION: {
    pages: [
      { component: Medications, path: '/medications' },
      { component: Reactions, path: '/reactions' },
      { component: InforUpdatedSuccessfully, path: '/minfoupdated' },
    ],
    returnPath: '/',
  },
  // add goodRx api '/medications-search'
  // add pharmacy page '/pharmacy'
  MEDICAL_HISTORY: {
    pages: [
      { component: Conditions, path: '/conditions' },
      { component: SpineQuestion, path: '/spineq' },
      { component: SpineSurgeries, path: '/spinesurgeries' },
      { component: HeartQuestion, path: '/heartq' },
      { component: HeartSurgeries, path: '/heartsurgeries' },
      { component: OtherQuestion, path: '/otherq' },
      { component: OtherSurgeries, path: '/othersurgeries' },
      { component: FamilyHistory, path: '/familyhistory' },
      { component: SocialHistory, path: '/socialhistory' },
      { component: SocialHistory2, path: '/socialhistory2' },
      { component: InforUpdatedSuccessfully, path: '/mhinfoupdated' },
    ],
    returnPath: '/',
  },

  MIGRAINES: {
    pages: [
      { component: MigrainePain, path: '/migrainepain' },
      { component: MigrainePainConfirm, path: '/migrainepainconfirm' },
      { component: MigraineSymptoms, path: '/migrainesymptoms' },
      { component: MigraineAbortive, path: '/migraineabortive' },
      { component: MigrainePreventiveMeds, path: '/migraine-preventive-meds' },
      { component: InforUpdatedSuccessfully, path: '/headacheentrysuccess' },
    ],
    returnPath: '/',
  },

  PAIN_HISTORY: {
    pages: [
      { component: BodyPain, path: '/pain' },
      { component: PainTypeEntry, path: '/pain_type' },
      { component: PainDetails, path: '/paindetails' },
      { component: FunctionalLimitations, path: '/functionallimitations' },
      { component: AggravatingFactors, path: '/aggravatingfactors' },
      { component: AlleviatingFactors, path: '/alleviatingfactors' },
      { component: PainEntrySuccessful, path: '/painentrysuccess' },
    ],
    returnPath: '/',
  },
  PHYSICIAN_ADD: {
    pages: [
      { component: NpiSearch, path: '/npi-search' },
    ],
    returnPath: '/personal-info',
  },
};

notifications.forEach((n) => {
  tracks[`notification-${n.type}`] = {
    pages: n.components.map((c, idx) => ({
      component: c,
      noauth: true,
      path: `/notifications/${n.type}-${idx + 1}`,
      isPro: true,
      ...n.additionalComponentProps,
    })),
    returnPath: `/notification-success/${n.type}`,
  };
});

export default tracks;

let remainingRailroadTracks = [];
let initialRoadroadTracks = [];

function getPath(router) {
  let path = router.location.pathname;
  [path] = path.split('?');
  return path.split('#')[0];
}


export function getTrack(router) {
  const path = getPath(router);
  let retVal;
  if (path) {
    forEach(tracks, (track) => {
      forEach(track.pages, (route) => {
        if (route.path === path) {
          retVal = track;
        }
      });
    });
  }
  return retVal;
}

export function getPathIndex(router) {
  let index = -1;
  const path = getPath(router);
  const track = getTrack(router);
  if (track) {
    forEach(track.pages, (route, idx) => {
      if (route.path === path) {
        index = idx;
      }
    });
  }
  return index;
}

function clearRailroad() {
  remainingRailroadTracks = [];
  initialRoadroadTracks = [];
}

export function setTrack(track, router, query = {}, options = {}) {
  if (!options.retainRailroad) {
    // Clear the railroad unless explicitly asked not too
    clearRailroad();
  }

  if (tracks[track]) {
    if (track === 'notification-VITAL-CORE' || track === 'notification-VITAL-CORE-BENCH-HR') createVitalsRunnerWebWorker();
    store.dispatch(setTrackType(track));
    router.push({ pathname: tracks[track].pages[0].path, query, state: { ...options } });
  } else {
    console.error('Track Not Found: ', track);
  }
}

export function setRailroad(railroad, router, options = {}) {
  if (options.isInitial) {
    initialRoadroadTracks = railroad;
  }
  remainingRailroadTracks = railroad.slice(1);
  setTrack(railroad[0].track, router, railroad[0].query, { retainRailroad: true });
}

export function forward(router, query = {}) {
  const track = getTrack(router);
  const qs = Object.keys(query).length ? `?${queryString.stringify(query)}` : router.location.search;

  if (track) {
    const index = getPathIndex(router);
    if ((track.pages.length - 1) > index) {
      return router.push(track.pages[index + 1].path + qs);
    }

    //If no pages but a railroad exists...
    if (remainingRailroadTracks && remainingRailroadTracks.length) {
      return setRailroad(remainingRailroadTracks, router);
    }

    clearRailroad();
    return router.push(track.returnPath + qs);
  }
  router.push('/');
}

export function skipNext(router) {
  const track = getTrack(router);
  const qs = router.location.search;

  if (track) {
    const index = getPathIndex(router);
    if ((track.pages.length - 1) > (index + 1)) {
      return router.push(track.pages[index + 2].path);
    }
    return router.push(track.returnPath + qs);
  }
  router.push('/');
}

export function finishTrack(router) {
  const track = getTrack(router);
  const qs = router.location.search;
  if (remainingRailroadTracks.length) {
    return setRailroad(remainingRailroadTracks, router);
  }
  if (track && track.returnPath) {
    return router.push(track.returnPath + qs);
  }
  router.push('/');
}

export function backward(router, query = {}) {
  const track = getTrack(router);
  const qs = `?${queryString.stringify(query)}`;

  if (track) {
    const index = getPathIndex(router);
    if (index < 0) {
      return router.push(track.pages[index - 1].path + qs);
    }
    clearRailroad();
    return router.push(track.returnPath + qs);
  }
  router.push('/');
}

export function clear(router) {
  router.push('/');
}

// Returns the total number of pages in either the track
// Or the railroad if a railroad exists
export function getTotalPages(router) {
  if (initialRoadroadTracks.length) {
    let total = 0;

    initialRoadroadTracks.forEach(({ track }) => {
      total += tracks[track].pages.length;
    });

    return total;
  }

  // no railroad exists, just get track total
  const track = getTrack(router);
  if (!track) {
    return 0;
  }
  return track.pages.length;
}

// Returns the current page number of either the track or
// the railroad
export function getCurrentPage(router) {
  let startingPage = 0;
  if (initialRoadroadTracks.length) {
    // adds total of any completed tracks to starting page
    const completedTracks = initialRoadroadTracks.filter((i) => {
      const isCompleted = (!remainingRailroadTracks.includes(i) && (tracks[i.track] !== getTrack(router)));
      return isCompleted;
    });
    completedTracks.forEach(({ track }) => {
      startingPage += tracks[track].pages.length;
    });
  }
  const index = getPathIndex(router);

  return index + 1 + startingPage;
}

export function goToIndex(index, router, query = {}) {
  const track = getTrack(router);
  const qs = Object.keys(query).length ? `?${queryString.stringify(query)}` : router.location.search;

  if (track) {
    return router.push(track.pages[index].path + qs);
  }

  router.push('/');
}
