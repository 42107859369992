import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import {
  Checkbox,
  FormControlLabel,
  TextField,
} from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import Page from './page';
import AppBar from '../components/app-bar';
import BloomkidzLayout from '../layouts/bloomkidz';
import { updatePRO } from '../state/pro-forms';
import { bloomkidzStylesObj, bloomkidzStyles } from '../lib/bloomkidz';
import { fontSizing } from '../lib/styles';

const styles = {
  ...bloomkidzStylesObj,
  emphasis: {
    fontWeight: 600,
  },
  spacing: {
    margin: '20px 0 10px',
  },
};

class BloomkidzSignatures12 extends Page {
  constructor(props) {
    super(props);

    const stateFromRedux = props.bloomkidzSignatures[this.getTrackIndex()] || {};

    this.state = {
      consentGuardian3: '',
      consentChild: '',
      consentRelationship3: '',
      consentAcknowledgement7: false,
      consentAcknowledgement8: false,
      ...stateFromRedux,
    };
  }

  handleCheck = (e) => {
    this.setState({ [e.target.name]: e.target.checked });
  };

  handleTextFieldChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleContinue = () => {
    const { location, updatePRO } = this.props;

    updatePRO({
      type: 'bloomkidzSignatures',
      position: this.getTrackIndex(),
      value: { ...this.state },
    });

    this.forwardWithQuery(location.query);
  }

  render() {
    const { classes, router } = this.props;
    const {
      consentGuardian3,
      consentChild,
      consentRelationship3,
      consentAcknowledgement7,
      consentAcknowledgement8,
    } = this.state;

    const continueDisabled = !consentGuardian3 || !consentChild || !consentRelationship3 || !consentAcknowledgement7 || !consentAcknowledgement8;

    return (
      <BloomkidzLayout
        primaryBtnOnTouchTap={this.handleContinue}
        primaryBtnLabel="Next"
        primaryBtnDisabled={continueDisabled}
      >
        <AppBar
          headerNode="Signature"
          backButtonOnClick={() => router.goBack()}
        />
        <div className={classes.pageContent}>
          <div className={`${classes.emphasis} ${classes.spacing}`}>WAIVER OF LIABILITY</div>
          <div className={classes.paddingHorizontal}>
            <div className={classes.textFieldWrapper} style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ paddingRight: '20px' }}>I,</div>
              <TextField
                placeholder="Guardian Name"
                value={consentGuardian3}
                onChange={this.handleTextFieldChange}
                name="consentGuardian3"
                variant="standard"
                fullWidth
                inputProps={{ style: { fontSize: fontSizing.body, fontFamily: bloomkidzStyles.fontFamily } }}
              />
            </div>
            <div>
              as the parent or legal guardian of {consentChild || '[Child\'s Name]'} do hereby give my full consent and approval for my child to participate in activities and nap at BloomKidz.
            </div>
          </div>
          <FormControlLabel
            control={(
              <Checkbox
                checked={consentAcknowledgement7}
                onChange={this.handleCheck}
                name="consentAcknowledgement7"
                icon={<CheckBoxOutlineBlankIcon />}
                checkedIcon={<CheckBoxIcon />}
                className={classes.checkbox}
              />
            )}
            label="l agree that this Waiver and Release of Liability applies to each day my child attends BloomKidz. I am fully responsible for any and all injuries, losses, or damages that might occur to my child other family members or their belongings while on the premises of the center. I hereby release BloomKidz and its entire staff from any and all liability, on the account that all safety procedures have been taken on behalf of Management and Staff, for any injuries that my child may sustain as a result of any activities taken place at the center for the duration of my child's time enrolled at BloomKidz."
            classes={{
              label: classes.checkboxLabel,
              root: classes.formControlLabel,
            }}
          />
          <FormControlLabel
            control={(
              <Checkbox
                checked={consentAcknowledgement8}
                onChange={this.handleCheck}
                name="consentAcknowledgement8"
                icon={<CheckBoxOutlineBlankIcon />}
                checkedIcon={<CheckBoxIcon />}
                className={classes.checkbox}
              />
            )}
            label="I further agree to hold BloomKidz harmless from any liability whatsoever for future claims presented by my child for any injuries, losses or damages."
            classes={{
              label: classes.checkboxLabel,
              root: classes.formControlLabel,
            }}
          />
          <div className={`${classes.emphasis} ${classes.spacing}`}>Child&apos;s Name</div>
          <div className={classes.paddingHorizontal}>
            <div className={classes.textFieldWrapper}>
              <TextField
                placeholder="Full Name"
                value={consentChild}
                onChange={this.handleTextFieldChange}
                name="consentChild"
                variant="standard"
                fullWidth
                inputProps={{ style: { fontSize: fontSizing.body, fontFamily: bloomkidzStyles.fontFamily } }}
              />
            </div>
          </div>
          <div className={`${classes.emphasis} ${classes.spacing}`}>Parent/Guardian Printed Name</div>
          <div className={classes.paddingHorizontal}>
            <div className={classes.textFieldWrapper}>
              <TextField
                placeholder="Full Name"
                value={consentGuardian3}
                onChange={this.handleTextFieldChange}
                name="consentGuardian3"
                variant="standard"
                fullWidth
                inputProps={{ style: { fontSize: fontSizing.body, fontFamily: bloomkidzStyles.fontFamily } }}
              />
            </div>
          </div>
          <div className={`${classes.emphasis} ${classes.spacing}`}>Relationship to the Client</div>
          <div className={classes.paddingHorizontal}>
            <div className={classes.textFieldWrapper}>
              <TextField
                placeholder="Relationship to Client"
                value={consentRelationship3}
                onChange={this.handleTextFieldChange}
                name="consentRelationship3"
                variant="standard"
                fullWidth
                inputProps={{ style: { fontSize: fontSizing.body, fontFamily: bloomkidzStyles.fontFamily } }}
              />
            </div>
          </div>
        </div>
      </BloomkidzLayout>
    );
  }
}

BloomkidzSignatures12.propTypes = {
  classes: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  updatePRO: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const {
    proForms: { bloomkidzSignatures },
  } = state;

  return { bloomkidzSignatures };
};

export default connect(mapStateToProps, { updatePRO })(withStyles(styles)(BloomkidzSignatures12));
