import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';

import { update } from '../state/pro-forms';
import AppBar from '../components/app-bar';
import QuestionLayout from '../layouts/question';
import Page from './page';
import { colors } from '../lib/styles';

const styles = {
  directions: {
    fontSize: '18px',
    margin: '10px auto 0px 20px',
    justifyContent: 'space-around',
  },
  btnContainer: {
    textAlign: 'center',
    width: '100%',
  },
  continueBtn: {
    maxWidth: '300px',
  },
  logoContainer: {
    padding: '15px 60px',
    textAlign: 'center',
    margin: 'auto',
  },
  logo: {
    maxHeight: '150px',
  },
  bar: {
    backgroundColor: colors.lightBrightBlue,
  },
  formGroup: {
    margin: '15px',
  },
  helperText: {
    color: colors.black,
    fontSize: '20px',
    textAlign: 'left',
  },
};

class SixMWT2 extends Page {
  constructor(props) {
    super(props);
    this.state = {
      submitting: false,
      clinicId: null,
      height: '',
      weight: '',
      inches: '',
      sensitivity: 60,
    };
  }

  handleContinue = async () => {
    const { height, weight, inches, sensitivity } = this.state;
    if (window.DeviceMotionEvent && window.DeviceMotionEvent.requestPermission) {
      window.DeviceMotionEvent.requestPermission().then((response) => {
        console.log('request for motion event', response);
      }).catch((e) => {
        console.log('error getting motion access', e);
      });
    }
    await this.props.update({
      height,
      weight,
      inches,
      sensitivity,
    });
    this.forwardWithQuery(this.props.location.query);
  }

  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  }

  render() {
    const { classes } = this.props;
    const { height, weight, inches } = this.state;
    const validSubmission = (weight && (Number(weight) > 30) && (Number(weight) < 1100))
      && (height && (Number(height) > 0) && (Number(height) <= 9))
      && (inches && (Number(inches) >= 0) && (Number(inches) < 12));
    return (
      <div style={{ backgroundColor: colors.highlightBright, height: '100%' }}>
        <QuestionLayout
          primaryBtnLabel="Start"
          primaryBtnOnTouchTap={this.handleContinue}
          primaryBtnStyle={styles.continueBtn}
          style={{ backgroundColor: colors.highlightBright }}
          primaryBtnDisabled={!validSubmission}
        >
          <AppBar
            noLeftNode={true}
            headerNode="6 Minute Walking Test"
          />
          <LinearProgress
            variant="determinate"
            value={(this.getCurrentPage() / this.getTotalPages()) * 100}
            classes={{ bar: classes.bar }}
          />
          <div className={classes.directions}>
            <div>
              <h2>Directions</h2>
              <p>You will need a clear area where you can take at least 8 steps and be able to turn around. You may do this test outside or around rooms in your house.</p>
              <p>When you are ready to start the test, please tap the start button.</p>
              <p>Walk at a comfortable pace until see the finish button.</p>
              <p>If you need to stop and rest, tap the pause button. When you are ready to resume, tap continue.</p>
              <p>When the Walk Test is complete, tap submit to send your results.</p>
            </div>
          </div>
          <FormControl style={styles.formGroup}>
            <Input
              id="weight"
              value={this.state.weight}
              onChange={this.handleChange}
              aria-describedby="standard-weight-helper-text"
              type="number"
              inputProps={{
                inputMode: 'numeric',
                pattern: '[0-9]*',
                'aria-label': 'weight',
                style: { fontSize: '16px', textAlign: 'center' },
              }}
              style={{ width: '180px' }}
            />
            <FormHelperText style={styles.helperText} id="standard-weight-helper-text">weight (pounds)</FormHelperText>
          </FormControl>
          <div>
            <FormControl style={styles.formGroup}>
              <Input
                id="height"
                value={this.state.height}
                onChange={this.handleChange}
                aria-describedby="standard-height-helper-text"
                type="number"
                inputProps={{
                  inputMode: 'numeric',
                  pattern: '[0-9]*',
                  'aria-label': 'height',
                  style: { fontSize: '16px', textAlign: 'center' },
                }}
                style={{ width: '120px' }}
              />
              <FormHelperText style={styles.helperText} id="standard-height-helper-text">height (feet)</FormHelperText>
            </FormControl>

            <FormControl style={styles.formGroup}>
              <Input
                id="inches"
                value={this.state.inches}
                onChange={this.handleChange}
                aria-describedby="standard-height-helper-text"
                type="number"
                inputProps={{
                  inputMode: 'numeric',
                  pattern: '[0-9]*',
                  'aria-label': 'height',
                  style: { fontSize: '16px', textAlign: 'center' },
                }}
                style={{ width: '120px' }}
              />
              <FormHelperText style={styles.helperText} id="standard-height-helper-text">(inches)</FormHelperText>
            </FormControl>
          </div>
        </QuestionLayout>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { user, proForms } = state;
  return { user, proForms };
}

SixMWT2.proptypes = {
  user: PropTypes.object.isRequired,
  proForms: PropTypes.array.isRequired,
  route: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, { update })(withStyles(styles)(SixMWT2));
