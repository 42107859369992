import React from 'react';
import { connect } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';

import Page from './page';
import AppBar from '../components/app-bar';
import { submitPRO } from '../state/pro-forms';
import QuestionLayout from '../layouts/question';
import { colors } from '../lib/styles';

const styles = {
  continueButtonWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  icon: {
    objectFit: 'contain',
    width: 25,
    height: 25,
  },
  iconWrapper: {
    alignItems: 'center',
    boxShadow: '0 0 8px lightgrey',
    borderRadius: '50%',
    display: 'flex',
    height: 50,
    justifyContent: 'center',
    width: 50,
  },
  page: {
    background: '#f5f5f5',
    height: '100%',
  },
  vitalMeasurementContainer: {
    boxShadow: '0 0 8px lightgrey',
    display: 'flex',
    marginBottom: 10,
    marginTop: 10,
    marginLeft: 20,
    marginRight: 20,
    padding: 5,
    alignItems: 'flex-end',
  },
  vitalMeasurementTitleContainer: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: 20,
    width: 150,
    marginLeft: 10,
  },
  vitalMeasurementValue: {
    fontSize: 40,
  },
  vitalMeasurementUnit: {
    fontSize: 16,
    lineHeight: 1.6,
    marginLeft: 5,
  },
  vitalsBlue: {
    color: '#1e7bcd',
  },
  vitalsRed: {
    color: '#cd3636',
  },
};

class HeartFailureDaily3 extends Page {
  render() {
    const { classes, heartFailureDaily } = this.props;
    const { BR, HR } = heartFailureDaily[1];

    return (
      <QuestionLayout
        primaryBtnLabel="Next"
        primaryBtnOnTouchTap={() => { this.forwardWithQuery(this.props.location.query); }}
        primaryBtnDisabled={false}
        style={{ backgroundColor: colors.questionBackground }}
      >
        <AppBar
          backButtonOnClick={() => this.props.router.goBack()}
          headerNode="Your vital signs today"
        />
        <div>
          <Paper className={`${classes.vitalMeasurementContainer} ${classes.vitalsRed}`}>
            <Paper className={classes.iconWrapper}>
              <img
                className={classes.icon}
                src="/img/heartbeat-solid.png"
                alt=""
              />
            </Paper>
            <div className={classes.vitalMeasurementTitleContainer}>
              <div>Heart</div>
              <div>Rate</div>
            </div>
            <div className={classes.vitalMeasurementValue}>{HR}</div>
            <div className={classes.vitalMeasurementUnit}>bpm</div>
          </Paper>
          <Paper className={`${classes.vitalMeasurementContainer} ${classes.vitalsBlue}`}>
            <Paper className={classes.iconWrapper}>
              <img
                className={classes.icon}
                src="/img/lungs-solid.png"
                alt=""
              />
            </Paper>
            <div className={classes.vitalMeasurementTitleContainer}>
              <div>Breathing</div>
              <div>Rate</div>
            </div>
            <div className={classes.vitalMeasurementValue}>{BR}</div>
          </Paper>
        </div>
      </QuestionLayout>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    proForms: { heartFailureDaily },
    user,
  } = state;

  return { user, heartFailureDaily };
};

export default connect(mapStateToProps, { submitPRO })(withStyles(styles)(HeartFailureDaily3));
