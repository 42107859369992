import React from 'react';
import PropTypes from 'prop-types';

import Continue from '../components/continue';
import { custom } from '../lib/styles';

import MultilineTextField from '../components/multiline-textfield';
import SimpleList from '../components/simple-list';
import Subtitle from '../components/subtitle';

const MigraineAbortivePresentation = (props) => {
  return (
    <div>
      <Subtitle label="WHICH MEDICATIONS/TREATMENTS HAVE YOU TRIED?" />
      {props.migraineRx ? (
        <SimpleList
          items={props.migraineRx}
          selectedItems={props.selectedItems}
          onAddItem={props.onAddItem}
          onRemoveItem={props.onRemoveItem}
          sortBy="name"
        />
      ) : null}
      <section style={custom.primaryHighlight}>
        <MultilineTextField
          hintText="Other medications / treatments?"
          rows={3}
          onChange={props.onChangeOtherAbortive}
          value={props.otherAbortiveValue}
        />
      </section>
      <section style={custom.whiteBackground}>
        Did these medications work?
        <Continue text="Yes" onClick={props.onMedicationsWorked} />
        <Continue text="No" onClick={props.onMedicationsNotWork} />
      </section>
    </div>
  );
}

MigraineAbortivePresentation.propTypes = {
  migraineRx: PropTypes.object.isRequired,
  selectedItems: PropTypes.object.isRequired,
  onAddItem: PropTypes.func.isRequired,
  onRemoveItem: PropTypes.func.isRequired,
  onChangeOtherAbortive: PropTypes.func,
  otherAbortiveValue: PropTypes.string.isRequired,
  onMedicationsWorked: PropTypes.func.isRequired,
  onMedicationsNotWork: PropTypes.func.isRequired,
};

export default MigraineAbortivePresentation;
