import React from 'react';
import { LinearProgress, MenuItem, Select } from '@material-ui/core';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import AppBar from '../components/app-bar';
import Page from './page';
import QuestionLayout from '../layouts/question';
import i18nTranslator, { title, instructions, options, selectDefault, nextBtn } from '../lib/i18next';
import { colors } from '../lib/styles';
import { updatePRO } from '../state/pro-forms';
import { CogSolid } from '../lib/icons';

const styles = {
  contentWrapper: {
    flexGrow: 1,
    overflowX: 'hidden',
    position: 'relative',
  },
  datePicker: {
    '& input': {
      fontSize: '2rem',
    },
  },
  imgRow: {
    display: 'flex',
    justifyContent: 'center',
    padding: '15px 60px',
  },
  imageWrapper: {
    width: 100,
  },
  questionnaireContainer: {
    paddingLeft: 20,
    paddingRight: 20,
  },
  select: {
    fontSize: '2rem',
    marginTop: 5,
  },
  text: {
    fontSize: '2rem',
    marginTop: 25,
  },
};

const bodyLocationMap = {
  'Left Leg': 'Lower Body',
  'Right Leg': 'Lower Body',
  'Left Foot': 'Lower Body',
  'Right Foot': 'Lower Body',
  'Lower Torso': 'Upper Body',
  'Upper Torso': 'Upper Body',
  Neck: 'Upper Body',
  'Left Arm': 'Upper Body',
  'Right Arm': 'Upper Body',
};

class WoundCasaColina1 extends Page {
  constructor(props) {
    super(props);

    this.state = {
      bodyPart: '',
      location: '',
      isOpen: false,
      displayUpper: 'none',
      displayLower: 'none',
      displayHalf: 'block',
    };
  }

  handleBodyHalf = (value) => {
    this.setState({
      displayHalf: 'none',
      displayUpper: value === 'Upper Body' ? 'block' : 'none',
      displayLower: value === 'Lower Body' ? 'block' : 'none',
    });
    this.handleOpen();
  };

  handleOpen = () => {
    this.setState({ isOpen: true });
  };

  handleClose = () => {
    this.setState({
      isOpen: false,
      displayHalf: 'block',
      displayUpper: 'none',
      displayLower: 'none',
    });
  };

  handleChangeBodyPart = (value) => {
    this.setState({ bodyPart: value });
    this.handleClose();
  };

  handleChangeLocation = (e) => {
    this.setState({ location: e.target.value });
  }

  handleClickNext = () => {
    const { woundCasaColina } = this.props;
    const currentWound = { ...this.state };
    const previousWounds = woundCasaColina[0] ? woundCasaColina[0] : [];

    currentWound.region = bodyLocationMap[currentWound.bodyPart];

    this.props.updatePRO({
      type: 'woundCasaColina',
      position: this.getTrackIndex(),
      value: [
        ...previousWounds,
        currentWound,
      ],
    });

    this.forwardWithQuery(this.props.location.query);
  }

  render() {
    const { classes } = this.props;
    const {
      bodyPart,
      location,
      isOpen,
      displayHalf,
      displayUpper,
      displayLower,
    } = this.state;

    const continueDisabled = !bodyPart || !location;

    return (
      <QuestionLayout
        primaryBtnDisabled={continueDisabled}
        primaryBtnLabel={nextBtn()}
        primaryBtnOnTouchTap={this.handleClickNext}
        style={{ backgroundColor: colors.questionBackground }}
      >
        <AppBar
          headerNode={title('woundCasaColina1')}
          backButtonOnClick={() => this.props.router.goBack()}
        />
        <LinearProgress
          variant="determinate"
          value={(this.getCurrentPage() / this.getTotalPages()) * 100}
          classes={{ bar: classes.bar }}
        />
        <div className={classes.contentWrapper}>
          <div className={classes.imgRow}>
            <div className={classes.imageWrapper}>
              <CogSolid />
            </div>
          </div>
          <div className={classes.questionnaireContainer}>
            <div className={classes.text}>
              {instructions('woundCasaColina1')}
            </div>
            <div className={classes.text}>{i18nTranslator('question1', 'woundCasaColina1')}</div>
            <Select
              open={isOpen}
              onOpen={this.handleOpen}
              onClose={this.handleClose}
              displayEmpty
              className={classes.select}
              value={bodyPart}
            >
              <MenuItem value="" disabled><i>{selectDefault()}</i></MenuItem>
              <MenuItem style={{ display: displayHalf }} value="Upper Body" onClick={() => this.handleBodyHalf('Upper Body')}>{options('upperBody', 'woundCasaColina1')}</MenuItem>,
              <MenuItem style={{ display: displayHalf }} value="Lower Body" onClick={() => this.handleBodyHalf('Lower Body')}>{options('lowerBody', 'woundCasaColina1')}</MenuItem>,
              <MenuItem style={{ display: displayUpper }} value="Lower Torso" onClick={() => this.handleChangeBodyPart('Lower Torso')}>{options('lowerTorso', 'woundCasaColina1')}</MenuItem>,
              <MenuItem style={{ display: displayUpper }} value="Upper Torso" onClick={() => this.handleChangeBodyPart('Upper Torso')}>{options('upperTorso', 'woundCasaColina1')}</MenuItem>,
              <MenuItem style={{ display: displayUpper }} value="Neck" onClick={() => this.handleChangeBodyPart('Neck')}>{options('neck', 'woundCasaColina1')}</MenuItem>,
              <MenuItem style={{ display: displayUpper }} value="Left Arm" onClick={() => this.handleChangeBodyPart('Left Arm')}>{options('leftArm', 'woundCasaColina1')}</MenuItem>,
              <MenuItem style={{ display: displayUpper }} value="Right Arm" onClick={() => this.handleChangeBodyPart('Right Arm')}>{options('rightArm', 'woundCasaColina1')}</MenuItem>,
              <MenuItem style={{ display: displayLower }} value="Left Leg" onClick={() => this.handleChangeBodyPart('Left Leg')}>{options('leftLeg', 'woundCasaColina1')}</MenuItem>,
              <MenuItem style={{ display: displayLower }} value="Right Leg" onClick={() => this.handleChangeBodyPart('Right Leg')}>{options('rightLeg', 'woundCasaColina1')}</MenuItem>,
              <MenuItem style={{ display: displayLower }} value="Left Foot" onClick={() => this.handleChangeBodyPart('Left Foot')}>{options('leftFoot', 'woundCasaColina1')}</MenuItem>,
              <MenuItem style={{ display: displayLower }} value="Right Foot" onClick={() => this.handleChangeBodyPart('Right Foot')}>{options('rightFoot', 'woundCasaColina1')}</MenuItem>,
            </Select>
            <div className={classes.text}>{i18nTranslator('question2', 'woundCasaColina1')}</div>
            <Select
              displayEmpty
              value={location}
              onChange={this.handleChangeLocation}
              className={classes.select}
            >
              <MenuItem value="" disabled><i>{selectDefault()}</i></MenuItem>
              <MenuItem value="Anterior">{options('anterior', 'woundCasaColina1')}</MenuItem>
              <MenuItem value="Posterior">{options('posterior', 'woundCasaColina1')}</MenuItem>
              <MenuItem value="Medial">{options('medial', 'woundCasaColina1')}</MenuItem>
              <MenuItem value="Lateral">{options('lateral', 'woundCasaColina1')}</MenuItem>
            </Select>
          </div>
        </div>
      </QuestionLayout>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    proForms: {
      woundCasaColina,
    },
  } = state;

  return { woundCasaColina };
};

export default connect(mapStateToProps, { updatePRO })(withStyles(styles)(WoundCasaColina1));
