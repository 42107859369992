import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import Page from './page';
import { submitPRO } from '../state/pro-forms';
import DisableSessionTimeout from '../components/disable-session-timeout';
import { colors } from '../lib/styles';

const styles = {
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '0px 20px',
    flexGrow: 1,
    textAlign: 'center',
  },
  link: {
    color: colors.white,
    fontSize: '1.4rem',
    backgroundColor: colors.primaryColor,
    borderRadius: '0.3125rem',
    minWidth: '17.1875rem',
    marginBottom: '0.625rem',
    marginTop: '2rem',
    textAlign: 'center',
    textDecoration: 'none',
    width: '85vw',
    lineHeight: '4rem',
  },
  question: {
    fontSize: '1.5rem',
    margin: '60px 40px 0px 40px',
    textAlign: 'center',
  },
  greenCheckMark: {
    marginTop: 30,
    marginBottom: 30,
    width: '8rem',
    height: '8rem',
  },
  shareText: {
    fontSize: '1.25rem',
    marginBottom: 30,
    marginTop: 20,
  },
  thankYouText: {
    fontSize: '2rem',
    fontWeight: 'bold',
  },
};

class DrJamesonReview2 extends Page {
  render() {
    const { classes } = this.props;

    return (
      <div className={classes.contentWrapper}>
        <DisableSessionTimeout />
        <img
          src="/img/GreenCheckmarkCircle.png"
          className={classes.greenCheckMark}
          alt="Green check mark"
        />
        <div className={classes.thankYouText}>Thank you for your feedback</div>
        <div className={classes.shareText}>We are happy to hear you had a positive experience. Your feedback is valuable and important in your care. Please share your evaluation of Dr. Jameson's service using the links below</div>
        <a
          className={classes.link}
          href="https://g.page/r/CTqLAHsCehJjEAg/review"
          target="_blank"
          rel="noopener noreferrer"
        >
          Google Review
        </a>
        <a
          className={classes.link}
          href="https://www.healthgrades.com/physician/dr-jessica-jameson-392kd"
          target="_blank"
          rel="noopener noreferrer"
        >
          Health Grades
        </a>
        <a
          className={classes.link}
          href="https://doctor.webmd.com/doctor/jessica-jameson-9064cb41-cffa-4af8-b408-3ba4c1d526bd-overview"
          target="_blank"
          rel="noopener noreferrer"
        >
          WebMD Care
        </a>
        <a
          className={classes.link}
          href="https://www.vitals.com/doctors/Dr_Jessica_Berryman.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          Vitals
        </a>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state;
  return { user };
}

export default connect(mapStateToProps, { submitPRO })(withStyles(styles)(DrJamesonReview2));
