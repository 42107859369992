import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';

import { colors } from '../lib/styles';

const baseStyles = {
  listItem: {
    borderBottom: `1px solid ${colors.primaryHighlight}`,
    color: colors.primaryColor,
    fontWeight: 400,
    textAlign: 'left',
  },
};

class PhysicianListItem extends Component {
  constructor(props) {
    super(props);
    this.navToPharmacy = this.navToPharmacy.bind(this);
  }

  navToPharmacy() {
    this.props.router.push(`/physician?patient_provider_id=${this.props.item.id}`);
  }

  render() {
    const { item } = this.props;
    return (
      <ListItem
        button
        onClick={this.navToPharmacy}
        style={baseStyles.listItem}
      >
        <ListItemIcon>
          <img alt="Physician Icon" src="/img/PhysicianIcon.png" />
        </ListItemIcon>
        <ListItemText primary={item.physician_name} />
      </ListItem>
    );
  }
}

PhysicianListItem.propTypes = {
  item: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
};

export default PhysicianListItem;
