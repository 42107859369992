/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
// label does have nested control, eslint is just wrong
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { IconButton, TextField } from '@material-ui/core';
import { connect } from 'react-redux';
import Joi from 'joi-browser';
import { KeypadDate } from 'common-ui';


import Page from './page';
import AppBar from '../components/app-bar';
import IconWrapper from '../components/icon-wrapper';
import BloomkidzLayout from '../layouts/bloomkidz';
import { ChildReaching, XInCircle } from '../lib/icons';
import { fontSizing } from '../lib/styles';
import { updatePRO } from '../state/pro-forms';
import { bloomkidzStyles, bloomkidzStylesObj } from '../lib/bloomkidz';

const formSchema = Joi.object({
  date: Joi.date(),
});

const styles = {
  ...bloomkidzStylesObj,
  diagnosisButton: {
    marginBottom: 30,
    marginTop: 10,
  },
  diagnosisListText: {
    marginBottom: 10,
  },
  diagnosisListItem: {
    alignItems: 'center',
    background: 'white',
    display: 'flex',
    marginBottom: 10,
    marginTop: 10,
  },
  diagnosisListItemText: {
    flexGrow: 1,
    paddingLeft: 10,
  },
  iconButton: {
    height: 50,
    width: 50,
  },
  diagnosisLabel: {
    alignItems: 'end',
    display: 'flex',
    width: '100%',
    '& > div > div': {
      margin: 0,
    },
  },
  date: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
    color: '#9c9c9c',
  },
  keypadDateWrapper: {
    marginTop: 10,
    '& > label > div': {
      flexGrow: 1,
    },
    '& > label > div > div': {
      margin: 0,
      width: '100%',
    },
    '& input': {
      margin: 0,
      fontFamily: bloomkidzStyles.fontFamily,
      fontSize: `${fontSizing.body}px !important`,
    },
  },
};

class BloomkidzMedicalHistory2 extends Page {
  constructor(props) {
    super(props);

    const stateFromRedux = props.bloomkidzMedicalHistory[this.getTrackIndex()] || {};

    this.state = {
      diagnoses: [],
      curDiagnosis: {
        name: '',
        date: '',
        physician: '',
      },
      errorDate: '',
      ...stateFromRedux,
    };
  }

  handleTextFieldChange = (e) => {
    const { name, value } = e.target;
    this.setState((curState) => {
      return {
        curDiagnosis: {
          ...curState.curDiagnosis,
          [name]: value,
        },
      };
    });
  };

  handleChangeDate = (date) => {
    this.setState((curState) => {
      return {
        errorDate: '',
        curDiagnosis: {
          ...curState.curDiagnosis,
          date,
        },
      };
    });
  }

  handleContinue = () => {
    const { location, updatePRO } = this.props;

    updatePRO({
      type: 'bloomkidzMedicalHistory',
      position: this.getTrackIndex(),
      value: { ...this.state },
    });

    this.forwardWithQuery(location.query);
  }

  addDiagnosis = () => {
    const { diagnoses, curDiagnosis } = this.state;
    const { error } = formSchema.validate({ date: curDiagnosis.date }, { abortEarly: false });
    if (!curDiagnosis.name || !curDiagnosis.date || !curDiagnosis.physician) {
      return;
    }
    if (error) {
      this.setState({ errorDate: 'Invalid value' });
      return;
    }

    if (curDiagnosis) {
      this.setState({
        diagnoses: [...diagnoses, curDiagnosis],
        curDiagnosis: { name: '', date: '', physician: '' },
      });
    }
  }

  removeDiagnosis = (diagnosis) => {
    this.setState(curState => ({
      diagnoses: curState.diagnoses.filter(curDiagnosis => curDiagnosis !== diagnosis),
    }));
  }

  render() {
    const { classes, router } = this.props;
    const { diagnoses, curDiagnosis, errorDate } = this.state;

    const keypadDateWrapperClass = errorDate
      ? `${classes.keypadDateWrapper} ${classes.keypadDateWrapperError}`
      : classes.keypadDateWrapper;

    const buttonClass = curDiagnosis
      ? `${classes.buttonGreenBackground} ${classes.diagnosisButton}`
      : `${classes.buttonWithBorder} ${classes.diagnosisButton}`;

    return (
      <BloomkidzLayout
        primaryBtnOnTouchTap={this.handleContinue}
        primaryBtnLabel={diagnoses.length ? 'Next' : 'Skip'}
      >
        <AppBar
          headerNode="Diagnosis"
          backButtonOnClick={() => router.goBack()}
        />
        <IconWrapper>
          <ChildReaching />
        </IconWrapper>
        <div className={classes.pageContent}>
          <div>Diagnosis</div>
          <div className={classes.paddingHorizontal}>
            <div className={classes.textFieldWrapper}>
              <TextField
                placeholder="Name of Diagnosis"
                value={curDiagnosis.name}
                onChange={this.handleTextFieldChange}
                name="name"
                variant="standard"
                fullWidth
                inputProps={{ style: { fontSize: fontSizing.body, fontFamily: bloomkidzStyles.fontFamily } }}
              />
            </div>
            <div className={keypadDateWrapperClass}>
              <label className={classes.diagnosisLabel}>
                {!curDiagnosis.date ? (
                  <span className={classes.date}>Date:&nbsp;</span>
                ) : null}
                <KeypadDate onChange={this.handleChangeDate} value={curDiagnosis.date} />
              </label>
            </div>
            {errorDate ? (
              <div className={classes.errorHelperText}>
                {errorDate}
              </div>
            ) : null}
            <div className={classes.textFieldWrapper}>
              <TextField
                placeholder="Diagnosing Physician"
                value={curDiagnosis.physician}
                onChange={this.handleTextFieldChange}
                name="physician"
                variant="standard"
                fullWidth
                inputProps={{ style: { fontSize: fontSizing.body, fontFamily: bloomkidzStyles.fontFamily } }}
              />
            </div>
          </div>
          <button
            className={buttonClass}
            onClick={this.addDiagnosis}
            type="button"
          >
            + Add Diagnosis
          </button>
          <div className={classes.diagnosisListText}>
            Diagnosis List
          </div>
          {diagnoses.length ? (
            <div className={classes.paddingHorizontal}>
              {diagnoses.map(diagnosis => (
                <div className={classes.diagnosisListItem}>
                  <div className={classes.diagnosisListItemText} key={diagnosis.name}>{`${diagnosis.name} | ${diagnosis.date}`}</div>
                  <IconButton
                    className={classes.iconButton}
                    onClick={() => this.removeDiagnosis(diagnosis)}
                  >
                    <XInCircle />
                  </IconButton>
                </div>
              ))}
            </div>
          ) : (
            <div className={classes.greyText}><i>No Diagnosis</i></div>
          )}
        </div>
      </BloomkidzLayout>
    );
  }
}

BloomkidzMedicalHistory2.propTypes = {
  classes: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  updatePRO: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const {
    proForms: { bloomkidzMedicalHistory },
  } = state;

  return { bloomkidzMedicalHistory };
};

export default connect(mapStateToProps, { updatePRO })(withStyles(styles)(BloomkidzMedicalHistory2));
