import React from 'react';
import PropTypes from 'prop-types';
import { LinearProgress as MuiLinearProgress } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { colors } from '../lib/styles';

const styles = {
  bar: {
    backgroundColor: colors.lightBrightBlue,
  },
};

const LinearProgress = ({ classes, value }) => {
  return (
    <MuiLinearProgress
      classes={{ bar: classes.bar }}
      variant="determinate"
      value={value}
    />
  );
};

LinearProgress.propTypes = {
  classes: PropTypes.object.isRequired,
  value: PropTypes.number.isRequired,
};

export default withStyles(styles)(LinearProgress);
