const en = {
  demographics7: {
    title: 'About Me',
    ffhs: 'Are you a participant of the Free Foot Health Screening?',
    ccwc: 'Are you currently a patient at Casa Colina Wound Clinic?',
  },
};

const es = {
  demographics7: {
    title: 'Sobre Mí',
    ffhs: '¿Es usted un/una participante de la Prueba gratuita de salud del pie?',
    ccwc: '¿Es actualmente paciente de Case Colina Wound Clinic?',
  },
};

export default { en, es };
