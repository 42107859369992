import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { get, round } from 'lodash';

import AppBar from '../components/app-bar';
import Page from './page';
import { colors, painScaleReverseColors } from '../lib/styles';
import { submitPRO, updatePRO } from '../state/pro-forms';
import HealthSliderHorizontal from '../components/health-slider-horizontal';
import QuestionLayout from '../layouts/question';
import { Headaches } from '../lib/icons';

const styles = {
  bar: {
    background: colors.lightBrightBlue,
  },
  contentWrapper: {
    flexGrow: 1,
    overflowX: 'hidden',
    position: 'relative',
    paddingLeft: 20,
    paddingRight: 20,
  },
  questionText: {
    fontSize: '1.5rem',
    marginTop: 25,
  },
  healthSliderWrapper: {
    marginTop: 60,
    paddingLeft: 40,
    paddingRight: 40,
  },
  imageWrapper: {
    height: 100,
    padding: '15px 60px',
    textAlign: 'center',
  },
  instructions: {
    color: 'grey',
    fontSize: '1.5rem',
    margin: '20px 40px 0px 40px',
    textAlign: 'center',
  },
  headacheText: {
    fontSize: '2rem',
    fontWeight: 'bold',
  },
};

class ThoracicCancerSymptoms5 extends Page {
  getSliderTheme = (value) => {
    return {
      color: painScaleReverseColors[round(value / 10)],
    };
  }

  handleChange = (value) => {
    this.props.updatePRO({
      type: 'thoracicCancerSymptoms',
      position: this.getTrackIndex(),
      value,
    });
  }

  handleClickContinue = () => {
    this.forwardWithQuery(this.props.location.query);
  }

  render() {
    const { classes } = this.props;
    const value = get(this.props.thoracicCancerSymptoms, this.getTrackIndex(), null);

    return (
      <QuestionLayout
        primaryBtnLabel="Next"
        primaryBtnOnTouchTap={this.handleClickContinue}
      >
        <AppBar
          headerNode="Headaches"
          backButtonOnClick={() => this.props.router.goBack()}
        />
        <LinearProgress
          variant="determinate"
          value={(this.getCurrentPage() / this.getTotalPages()) * 100}
          classes={{ bar: this.props.classes.bar }}
        />
        <div className={classes.contentWrapper}>
          <div className={classes.instructions}>
            Please select the option that best answers the question relating to headaches
          </div>
          <div className={classes.imageWrapper}>
            <Headaches />
          </div>
          <div className={classes.questionnaireContainer}>
            <div className={classes.headacheText}>Headaches</div>
            <div className={classes.questionText}>What is your headache pain on a scale of 0-10</div>
            <div className={classes.healthSliderWrapper}>
              <HealthSliderHorizontal
                value={value || 0}
                onChange={this.props.updatePRO}
                max={10}
                min={0}
                trackIndex={this.getTrackIndex()}
                type="thoracicCancerSymptoms"
                sliderLeftLabel="No pain"
                sliderRightLabel="Worst imaginable pain"
              />
            </div>
          </div>
        </div>
      </QuestionLayout>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    proForms: {
      thoracicCancerSymptoms,
    },
    user,
  } = state;
  return { thoracicCancerSymptoms, user };
};

export default connect(mapStateToProps, { submitPRO, updatePRO })(withStyles(styles)(ThoracicCancerSymptoms5));
