import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { browserHistory } from 'react-router';
import { IconButton } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';

import AppBar from '../components/app-bar';
import { colors, desktopMaxWidth, fontSizing } from '../lib/styles';

const styles = {
  header: {
    fontSize: fontSizing.h3,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  marginBottom0: {
    marginBottom: 0,
  },
  pageContent: {
    fontSize: fontSizing.body,
    marginTop: 20,
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: desktopMaxWidth,
    paddingLeft: 20,
    paddingRight: 20,
  },
  pageWrapper: {
    background: colors.white,
    minHeight: '100vh',
    paddingBottom: 20,
  },
  sectionHeader: {
    fontSize: fontSizing.body,
    fontWeight: 'bold',
    marginTop: 20,
  },
  text: {
    fontSize: fontSizing.body,
    marginTop: 20,
  },
  unorderedList: {
    marginTop: 0,
  },
};

class CoreVitalUserPrivacyPolicy extends React.Component {
  render() {
    const { classes, router } = this.props;

    return (
      <div className={classes.pageWrapper}>
        <AppBar
          backButtonOnClick={() => router.goBack()}
          headerNode="User Manual"
          rightNode={(
            <IconButton onClick={() => browserHistory.push('/core-vital-user-manual-menu')}>
              <MenuIcon fontSize="large" />
            </IconButton>
          )}
        />
        <div className={classes.pageContent}>
          <div className={classes.header}>
            INFORMED VITAL CORE™ PRIVACY POLICY
          </div>
          <div className={classes.sectionHeader}>
            <strong>Last Updated:</strong> August 02, 2024
          </div>
          <div className={classes.sectionHeader}>
            <strong>PLEASE READ THIS POLICY CAREFULLY BEFORE USING THE INFORMED VITAL CORE APPLICATION.</strong>
          </div>
          <div className={classes.text}>
          You must be 22 years of age or older to use the Informed Vital Core Application. The Informed Vital Core Application has not been approved by the Food and Drug Administration (FDA) for use on people under 22 years of age.
          </div>
          <div className={classes.text}>
            Mindset Medical, (<strong>"Mindset"</strong> or <strong>"We"</strong>), respects your privacy and is committed to protecting it through our compliance with this Privacy Policy (“Policy”). This Policy describes the process of how we collect your personal information when you access or use the Informed Vital Core Application (“IVC App, IVC, or App”).
          </div>
          <div className={classes.text}>
            This Policy describes:
          </div>
          <div className={classes.text}>
            This Policy applies to the information we collect in the IVC App, including date of birth, pulse rate measurement result, text, and other electronic communications sent through or in connection with the IVC App.
          </div>
          <div className={classes.text}>
            This policy <strong>DOES NOT</strong> apply to information that:
          </div>
          <div className={classes.text}>
            Please read this Policy carefully to understand our policies and practices regarding your information and how we will treat it. If you do not agree with our policies and practices, do not use the IVC App. By using the IVC App, you agree to this Policy.
          </div>
          <div className={classes.text}>
            <strong>Persons Under the Age of 22</strong><br />
            The IVC App is not intended for persons under 22 years of age, and we do not knowingly collect information from persons under 22 through the App. If we learn we have collected or received information from a person under 22 through the IVC App, we will delete that information.
          </div>
          <div className={classes.text}>
            <strong>Information We Collect and How We Collect It</strong><br />
            When you use the App, we automatically collect personal information (i.e., vital sign measurements), including:
            <ul className={`${classes.unorderedList} ${classes.marginBottom0}`}>
              <li>Vital signs*, which may include, pulse rate, respiratory rate, blood pressure, and blood oxygen saturation (as part of your provider’s medical record)</li>
              <li>Date of Birth (as part of your provider’s medical record)</li>
              <li>First and Last Name (as part of your provider’s medical record)</li>
              <li>Email Address (as part of your provider’s medical record)</li>
              <li>Phone Number (as part of your provider’s medical record)</li>
              <li>Date and Timestamp of when you use the IVC App</li>
              <li>The Operating System of the device used to run the IVC app (i.e., Android 13.0 or iOS 17.0)</li>
              <li>The model of device used to run the IVC App (i.e., iPhone)</li>
              <li>The browser version used to run the IVC App</li>
              <li>The browser engine version to run the IVC App</li>
            </ul>
            *This information is considered “Protected Health Information” under certain state laws and federal laws such as HIPAA and HITECH (collectively, "PHI")
            <div className={classes.text}>
              We do not collect video recordings or your facial image.
            </div>
          </div>
          <div className={classes.text}>
            <strong>How We Use Your Information</strong><br />
            We use information that we collect about you, including any PHI, to:
            <ul className={classes.unorderedList}>
              <li>Provide you with the IVC App and its contents</li>
              <li>Measure your vital signs and send the completed results to your healthcare provider</li>
            </ul>
          </div>
          <div className={classes.text}>
            <strong>Disclosure of Your Information</strong><br />
            We may disclose PHI or other personal information that we collect, or you provide:
            <ul className={classes.unorderedList}>
              <li>To your healthcare provider as part of your normal treatment and care plan</li>
              <li>To our subsidiaries and affiliates who are bound by contractual obligations to keep PHI and other personal information confidential and use it only for the purposes for which we disclose it to them</li>
              <li>To contractors, service providers, and other third parties we use to support our business who are bound by contractual obligations to keep PHI and other personal information confidential, and use it only for the purposes for which we disclose it to them</li>
              <li>To fulfill the purpose for which you have provided it</li>
              <li>With your consent</li>
              <li>To comply with any court order, law, or legal process, including to respond to any government or regulatory request</li>
              <li>Subject to applicable laws, if we believe disclosure is necessary or appropriate to protect the rights, property, or safety of Mindset Medical, our customers, or others. This includes exchanging information with other companies and organizations for the purposes of fraud protection.</li>
            </ul>
          </div>
          <div className={classes.text}>
            <strong>Accessing and Correcting Your PHI and Other Personal Information</strong><br />
            You have the right to request your patient record (containing relevant PHI) or ask that it be deleted. We cannot change your PHI or other information, nor can we delete parts of your PHI or other information except by also deleting your entire patient record. To initiate a request, please contact your healthcare provider.
          </div>
          <div className={classes.text}>
            <strong>Data Security</strong><br />
            <div>
              We have implemented technical, physical, administrative, and organizational measures designed to secure your PHI and other personal information from accidental loss and from unauthorized access, use, alteration, and disclosure. All information you provide to us is stored on our secure servers behind firewalls which conform to applicable state and federal regulations.
            </div>
            <div className={classes.text}>
              The safety and security of your information also depends on you. You are responsible for securing your device and not sharing your information. The IVC App will open a web browser on your device. After your vitals have been submitted to your healthcare provider, you are responsible for closing your web browser.
            </div>
            <div className={classes.text}>
              Unfortunately, the transmission of information via the Internet and mobile platforms is not completely secure. Although we do our best to protect your PHI and other personal information, we cannot guarantee the security of your PHI and other personal information transmitted through the IVC App by your selected Internet or mobile platform. <strong>Do not use the IVC App on any public unsecured Wi-Fi or Internet networks. Any transmission of PHI and other personal information in such unsecured environments is at your own risk.</strong> We are not responsible for circumvention of any privacy settings or security measures we provide.
            </div>
          </div>
          <div className={classes.text}>
            <strong>Aggregated and De-identified Data</strong><br />
            Subject to applicable state and federal law, including but not limited to our obligations under HIPAA and HITECH, we may license, sell, or otherwise share aggregated, de-identified versions of your PHI and other data (“De-identified Information”) with our subsidiaries, affiliates, partners, customers, investors, and contractors for any purpose. You agree and acknowledge that Mindset Medical is the sole and exclusive owner of any de-identified Information created by Mindset Medical and that you have no ownership or other intellectual property rights in or to such De-identified Information.
            <div className={classes.text}>Examples of De-identified Data include:</div>
            <div className={classes.unorderedList}>
              <li>Date and Timestamp of when you use the IVC App</li>
              <li>The Operating System of the device used to run the IVC app (i.e., Android 13.0 or iOS 17.0)</li>
              <li>The model of device used to run the IVC App (i.e., iPhone)</li>
              <li>The browser version used to run the IVC App</li>
              <li>The browser engine version</li>
              <li>Any session codes generated during the use of the IVC App</li>
            </div>
          </div>
          <div className={classes.text}>
            <strong>Changes to Our IVC App Privacy Policy</strong><br />
            We may update our Policy from time to time. If we make material changes to how we treat our users' PHI, we will post the new Policy within the App with a date that the Policy has been updated. Your continued use of the IVC App after we make changes is deemed to be acceptance of those changes. Please check this Policy periodically for any changes in the IVC App.
          </div>
          <div className={classes.text}>
            <strong>Your California Privacy Rights</strong><br />
            California Civil Code Section 1798.83 permits users of the VIC App that are California residents to request certain information regarding our disclosure of information to third parties for their direct marketing purposes. To make such a request, please send an email to <a href="mailto:ivcsupport@mindsetmedical.com">ivcsupport@mindsetmedical.com.</a>
          </div>
          <div className={classes.text}>
            <strong>Contact Information</strong><br />
            To ask questions or comment about this Policy and our privacy practices, contact us at:<br/>
            <a href="mailto:ivcsupport@mindsetmedical.com">ivcsupport@mindsetmedical.com</a>.
          </div>
        </div>
      </div>
    );
  }
}

CoreVitalUserPrivacyPolicy.propTypes = {
  classes: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
};

export default withStyles(styles)(CoreVitalUserPrivacyPolicy);
