import React from 'react';
import { connect } from 'react-redux';
import rawr from 'rawr';
import transport from 'rawr/transports/worker';
import LinearProgress from '@material-ui/core/LinearProgress';
import { withStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import Page from './page';
import HiddenContent from '../components/hidden-content';

import { updatePRO } from '../state/pro-forms';
import { colors } from '../lib/styles';
import { throttledReset } from '../initializers/activity';
import { saveMetaData } from '../lib/fetch';
import { CameraBuddy } from '../lib/cameraBuddy';
import { drawFaceBox, drawReticle, drawNumber } from '../lib/drawing';

const styles = {
  patientVideo: {
    minHeight: '450px',
    height: '100%',
    maxWidth: 1000,
    objectFit: 'cover',
    transform: 'scaleX(-1)',
    width: '100%',
    display: 'none',
  },
  patientCanvas: {
    minHeight: '450px',
    height: '100%',
    maxWidth: 1000,
    objectFit: 'cover',
    transform: 'scaleX(-1)',
    width: '100%',
  },
  loadingModel: {
    background: 'rgba(255, 255, 255, 0.5)',
    borderRadius: 5,
    fontSize: 16,
    marginLeft: 10,
    marginRight: 10,
    marginTop: 20,
    padding: 10,
  },
  container: {
    backgroundColor: colors.white,
    height: '100%',
  },
  topSection: {
    backgroundColor: colors.white,
    height: '100%',
    position: 'relative',
  },
  videoContainer: {
    margin: '0px auto',
    height: '100%',
    maxWidth: '540px',
    width: '100%',
  },
  nonVideoContentContainer: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
  },
  calibratingCameraMessage: {
    background: colors.primaryColor,
    color: '#fff',
    fontSize: 16,
    marginTop: 40,
    padding: 10,
    textAlign: 'center',
  },
};

const TOTAL_RECORD_TIME_SECONDS = 120;
const TARGET_FPS = 30;
const COUNTDOWN_MILLIS = 10000;

class VitalsDataCollection9 extends Page {
  constructor(props) {
    super(props);

    this.state = {
      countingDown: false,
      countdownStart: null,
      cameraError: '',
      BR: null,
      HR: null,
      SPO2: null,
      savedCount: 0,
      failed: false,
      saveRequested: 0,
      finishedRecording: false,
      finalVitals: {
        SPO2: -2,
        HR: -2,
        BR: -2,
      },
      hasDevice: false,
      deviceError: null,
      deviceErrors: [],
      recordMilliseconds: null,
      startTimestamp: null,
      workerReady: false,
      timestamps: null,
    };

    this.cameraBuddy = null;
    this.patientCanvas = React.createRef();
    this.patientVideo = React.createRef();
    this.videoContainer = React.createRef();

    this.deviceReadings = [];
    this.countdownStart = Date.now();
  }

  componentDidMount = async () => {
    const { vitalsDataCollection, userId } = this.props;
    const {
      FPS,
      seconds,
      hasDevice,
      imgEncoding,
      jpegQuality,
      cropFace,
      reader,
    } = this.getDataMap(vitalsDataCollection);

    const recordMilliseconds = (seconds || TOTAL_RECORD_TIME_SECONDS) * 1000;
    this.setState({ hasDevice, recordMilliseconds });

    if (reader) {
      try {
        await reader.stream(this.handleDeviceData);
      } catch (deviceError) {
        return this.setState({ deviceError: deviceError.toString(), failed: true });
      }
    }

    const cameraBuddy = new CameraBuddy(this.patientVideo, userId, cropFace, imgEncoding, jpegQuality, recordMilliseconds, FPS || TARGET_FPS);
    this.cameraBuddy = cameraBuddy;
    try {
      await this.cameraBuddy.startCamera();
    } catch (err) {
      return this.setState({ failed: true, cameraError: err });
    }

    // set up our canvas
    const canvas = this.patientCanvas.current;
    canvas.height = this.patientVideo.current.videoHeight;
    canvas.width = this.patientVideo.current.videoWidth;
    this.ctx = canvas.getContext('2d', { willReadFrequently: true });

    // trigger countdown and images to start saving
    this.setState({ countingDown: true, countdownStart: Date.now() });
    setTimeout(() => {
      this.cameraBuddy.startSaving();
      this.setState({ countingDown: false });
    }, COUNTDOWN_MILLIS);

    this.cameraBuddy.on('frame', (patientVideoCurrent, pvW, pvH) => {
      // draw the camera image in our canvas
      this.ctx.drawImage(patientVideoCurrent, 0, 0, pvW, pvH);

      const style = this.state.countingDown ? 'red' : 'green';
      drawFaceBox(this.ctx, pvW, pvH, style)

      if (!this.state.countingDown) {
        drawReticle(this.ctx, pvW, pvH)
      } else {
        const countdown = Math.max(Math.round((COUNTDOWN_MILLIS - (Date.now() - this.state.countdownStart)) / 1000), 0);
        const fontSize = Math.round(pvW / 8);
        drawNumber(this.ctx, countdown, pvW, pvH, fontSize)
      }
    });

    this.cameraBuddy.on('finishedSaving', async () => {
      await this.handleContinue();
    }, { once: true });

    this.cameraBuddy.on('saveRequested', (saveRequested, timestamp) => {
      if (!this.state.startTimestamp) {
        this.setState({ startTimestamp: timestamp });
      }
      this.setState({ saveRequested });
    });

    this.cameraBuddy.on('savedCount', (savedCount) => {
      this.setState({ savedCount });
    });

    this.cameraBuddy.on('failedCount', (failedCount, status, error) => {
      console.error('lost batch of frames', failedCount, status, error);
      return this.setState({ failed: true });
    });

    this.cameraBuddy.on('finishedRecording', async (timestamps) => {
      this.setState(state => ({
        timestamps,
        finalVitals: {
          SPO2: state.SPO2,
          HR: state.HR,
          BR: state.BR,
        },
        finishedRecording: true
      }));

      if (reader) {
        await reader.stopStreaming();
      }
    }, { once: true });

    this.activityTimer = setInterval(throttledReset, 5000);

    // this all has to travel together or you'll miss the onready
    // eslint-disable-next-line no-undef
    const webWorker = new Worker('/workers/vitals-data-worker.js');
    webWorker.onerror = (err) => {
      console.error('webworker error', err);
    };
    const rawrPeer = rawr({ transport: transport(webWorker) });
    const { sendImages } = rawrPeer.methods;
    rawrPeer.notifications.onready(() => {
      this.cameraBuddy.setSendImages(sendImages);
      this.setState({ workerReady: true });
    });
    // end
  }

  handleDeviceData = (deviceData, deviceError) => {

    if (deviceError) {
      this.setState(state => ({ deviceErrors: state.deviceErrors.push({ deviceData, deviceError }) }));

      switch (deviceError.code) {
        case 910:
        case 911: // just log crc and malformed packets
          return console.error(deviceError.toString());
        case 901: // disconnected
          // if we already have an error, dont replace it with the disconnect
          if (this.state.deviceError) return this.setState({ failed: true }); // eslint-disable-next-line
        default:
          return this.setState({ deviceError: deviceError.toString(), failed: true });
      }
    }

    this.setState({ SPO2: deviceData.SPO2, HR: deviceData.HR, BR: deviceData.BR });
    if (!this.state.finishedRecording) {
      this.deviceReadings.push({ ...deviceData, timestamp: Date.now() });
    }
  }


  componentWillUnmount = () => {
    try {
      clearInterval(this.activityTimer);
      this.cameraBuddy.cleanup();
    } catch (e) {
      console.error('error ending call properly: ', e);
    }
  }

  handleContinue = async () => {
    if (this.state.failed) return;

    const { location, userId, vitalsDataCollection } = this.props;
    const { id } = location.query;

    const meta = {
      supportedConstraints: navigator.mediaDevices.getSupportedConstraints(),
      ua: navigator.userAgent,
      deviceReadings: this.deviceReadings,
      timestamps: this.state.timestamps,
      formData: [vitalsDataCollection],
    };

    let res;
    let error;
    try {
      res = await saveMetaData(meta, userId, this.state.startTimestamp);
    } catch (e) {
      error = e;
    }

    if (error || !res.ok) {
      console.error('saveMetaData failed: ', error, res);
      return this.setState({ failed: true });
    }

    this.props.updatePRO({
      type: 'vitalsDataCollection',
      position: this.getTrackIndex(),
      value: {
        proId: id,
        timestamp: this.state.startTimestamp,
        userId,
        vitals: this.state.finalVitals,
      },
    });

    this.forwardWithQuery(location.query);
  }

  render() {
    const { classes } = this.props;
    const {
      cameraError,
      hasDevice,
    } = this.state;
    const secondsRemaining = this.state.startTimestamp ? (this.state.recordMilliseconds / 1000) - Math.round((Date.now() - this.state.startTimestamp) / 1000) : this.state.recordMilliseconds / 1000;

    return (
      <div className={classes.container}>
        <section className={classes.topSection}>

          <Snackbar
            open={this.state.failed}
            autoHideDuration={7000}
            onClose={async () => {
              // todo is there a way to safely reload THIS page instead?
              const { vitalsDataCollection, location } = this.props;
              const { reader } = this.getDataMap(vitalsDataCollection);
              if (reader) {
                reader.disconnect();
              }
              this.goToIndexWithQuery(0, location.query);
            }}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            <MuiAlert elevation={6} variant="filled" severity="error" style={{ fontSize: 16 }}>Too many network failures. Please restart</MuiAlert>
          </Snackbar>

          {/* todo these need to be cssed better */}
          <Snackbar
            open={this.state.deviceError !== ''}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            <MuiAlert elevation={6} variant="filled" severity="error" style={{ fontSize: 16 }}>{this.state.deviceError}</MuiAlert>
          </Snackbar>

          <div
            id="videoContainer"
            className={classes.videoContainer}
            ref={this.videoContainer}
          >
            <video id="patientVideo" ref={this.patientVideo} playsInline autoPlay className={classes.patientVideo} muted />
            <canvas id="patientCanvas" ref={this.patientCanvas} className={classes.patientCanvas} />
          </div>
          <div className={classes.nonVideoContentContainer}>
            {cameraError ? (
              <div><span>Unable to access camera: {cameraError.toString()}</span><br /></div>
            ) : ''}
            <HiddenContent hidden={this.state.finishedRecording || !this.state.workerReady}>
              <div className={classes.calibratingCameraMessage}>
                <div>Please hold still while the camera is recording{secondsRemaining ? `: ${secondsRemaining} seconds remaining` : ''}</div>
                {hasDevice ? (
                  <div>HR: {this.state.HR ? this.state.HR : '--'}  BR: {this.state.BR ? this.state.BR : '--'}  SPO2: {this.state.SPO2 ? this.state.SPO2 : '--'}</div>
                ) : ''}
              </div>
            </HiddenContent>
            <HiddenContent hidden={!this.state.workerReady}>
              <div className={classes.loadingModel}>
                <span>Images Uploading: {`${this.state.savedCount} of ${this.state.saveRequested}`}</span>
                <LinearProgress value={Math.round((this.state.savedCount / this.state.saveRequested) * 100)} variant="determinate" />
              </div>
            </HiddenContent>
          </div>
        </section>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { user, proForms: { vitalsDataCollection } } = state;
  return {
    user,
    userId: user.id,
    vitalsDataCollection,
  };
}

export default connect(mapStateToProps, { updatePRO })(withStyles(styles)(VitalsDataCollection9));
