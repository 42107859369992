import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { forEach, pickBy, assign, includes } from 'lodash';
import { FormControl, FormGroup, FormLabel, FormControlLabel, Checkbox } from '@material-ui/core';

import Page from './page';
import AppBar from '../components/app-bar';
import { colors } from '../lib/styles';
import QuestionLayout from '../layouts/question';
import { updatePRO } from '../state/pro-forms';

const { questionBackground } = colors;

const styles = {
  contentWrapper: {
    flexGrow: 1,
    overflowX: 'hidden',
    position: 'relative',
  },
  instructions: {
    color: 'grey',
    fontSize: '1.5rem',
    margin: '20px 40px 0px 40px',
    textAlign: 'center',
  },
  pageIndicator: {
    fontSize: '1.4rem',
  },
  questionnaire: {
    display: 'flex',
    width: '80%',
    flexDirection: 'column',
    margin: '50px auto',
    position: 'relative',
  },
  formGroup: {
    margin: '25px',
  },
  helperText: {
    color: colors.black,
    fontSize: '20px',
    textAlign: 'center',
  },
};

class Covid19_5 extends Page {
  state = {
    symptoms: {
      'Shortness of breath at rest.': false,
      'Inability to lie down because of difficulty breathing.': false,
      'Chronic health conditions that you are having difficulty managing because of difficulty breathing.': false,
      'None': false,
    },
  };

  componentDidMount() {
    const symptoms = this.props.covid19[this.getTrackIndex()] || [];
    const oldSymptoms = {};
    if(symptoms === 'N/A') {
      return;
    }
    forEach(symptoms, (value) => {
      oldSymptoms[value] = true;
    });
    this.setState({ symptoms: assign({}, this.state.symptoms, oldSymptoms) });
  }

  handleSubmission = () => {
    const trackIndex = this.getTrackIndex();
    this.props.updatePRO({
      type: 'covid19',
      position: trackIndex,
      value: Object.keys(pickBy(this.state.symptoms, (v) => v)),
    });
    this.forwardWithQuery(this.props.location.query);
  }

  handleChange = (event) => {
    let symptoms = { ...this.state.symptoms };
    if (event.target.name === 'None') {
      forEach(symptoms, (val, key) => {
        symptoms[key] = false;
      });
    }
    if (event.target.name !== 'None') {
      symptoms['None'] = false;
    }
    symptoms[event.target.name] = event.target.checked;
    this.setState({ symptoms });
  }

  render() {
    let primaryBtnDisabled = !includes(this.state.symptoms, true);
    return (
      <QuestionLayout
        primaryBtnDisabled={primaryBtnDisabled}
        primaryBtnLabel="Next"
        primaryBtnOnTouchTap={this.handleSubmission}
        style={{ backgroundColor: questionBackground }}
      >
        <AppBar
          backButtonOnClick={() => this.props.router.goBack()}
          headerNode="COVID-19 Assessment"
        />
        <div style={styles.contentWrapper}>
          <div style={styles.instructions}>
            Are you experiencing any of the following:
          </div>
          <div style={styles.questionnaire}>
            <FormControl component="fieldset">
              <FormLabel component="legend">Choose all that apply</FormLabel>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox checked={this.state.symptoms['Shortness of breath at rest.']} onChange={this.handleChange} name="Shortness of breath at rest." />}
                  label="Shortness of breath at rest."
                />
                <FormControlLabel
                  control={<Checkbox checked={this.state.symptoms['Inability to lie down because of difficulty breathing.']} onChange={this.handleChange} name="Inability to lie down because of difficulty breathing." />}
                  label="Inability to lie down because of difficulty breathing."
                />
                <FormControlLabel
                  control={<Checkbox checked={this.state.symptoms['Chronic health conditions that you are having difficulty managing because of difficulty breathing.']} onChange={this.handleChange} name="Chronic health conditions that you are having difficulty managing because of difficulty breathing." />}
                  label="Chronic health conditions that you are having difficulty managing because of difficulty breathing."
                />
                <FormControlLabel
                  control={<Checkbox checked={this.state.symptoms['None']} onChange={this.handleChange} name="None" />}
                  label="None"
                />
              </FormGroup>
            </FormControl>
          </div>
        </div>
      </QuestionLayout>
    );
  }
}

function mapStateToProps(state) {
  const { proForms: { covid19 } } = state;
  return { covid19 };
}

Covid19_5.propTypes = {
  covid19: PropTypes.array.isRequired,
  updatePRO: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { updatePRO })(withStyles(styles)(Covid19_5));
