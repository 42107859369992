const en = {
  demographicsNotificationPage: {
    introHeaderText: 'Participant Demographics',
    introBodyText: 'is asking you to fill out a voluntary survey.',
    loggedOutSuccessText: 'Thank you for submitting your responses. Information collected in the survey will only be used for research purposes to learn more about the population at risk for lower-limb complications. Results from the survey will not be shared with your physician and will not be used for diagnosis or treatment purposes. You may now close this window.',
    loggedInSuccessText: 'Thank you for submitting your responses. Information collected in the survey will only be used for research purposes to learn more about the population at risk for lower-limb complications. Results from the survey will not be shared with your physician and will not be used for diagnosis or treatment purposes.',
  },
};

const es = {
  demographicsNotificationPage: {
    introHeaderText: 'Datos Demográficos',
    introBodyText: 'le pide que llene una encuesta voluntaria.',
    loggedOutSuccessText: `
    <p>Gracias por enviar sus respuestas. La información recopilada en la encuesta solo se utilizará con fines de investigación para obtener más información sobre la población en riesgo de complicaciones en las extremidades inferiores. Los resultados de la encuesta no se compartirán con su médico y no se utilizarán con fines de diagnóstico o tratamiento.</p>
    
    <p>Ahora puedes cerrar esta ventana.</p>
    `,
    loggedInSuccessText: 'Gracias por enviar sus respuestas. La información recopilada en la encuesta solo se utilizará con fines de investigación para obtener más información sobre la población en riesgo de complicaciones en las extremidades inferiores. Los resultados de la encuesta no se compartirán con su médico y no se utilizarán con fines de diagnóstico o tratamiento.',
  },
};

export default { en, es };
