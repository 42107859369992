import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';

import { colors } from '../lib/styles';

const baseStyles = {
  sideBySide: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 15,
  },
  substance: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: colors.white,
  },
  circles: {
    color: colors.highlightMedium,
  },
  name: {
    marginLeft: 15,
    color: colors.primaryColor,
    fontSize: '1.4rem',
  },
  number: {
    marginRight: 15,
    marginLeft: 15,
    color: colors.primaryColor,
    fontSize: '1.4rem',
  },
};
class SocialUsageItem extends Component {
  constructor(props) {
    super(props);
    this.increaseSubstance = this.increaseSubstance.bind(this);
    this.decreaseSubstance = this.decreaseSubstance.bind(this);
  }

  increaseSubstance() {
    const frequency = this.props.frequency + 1;
    this.props.handleEdit({ id: this.props.id, frequency });
  }

  decreaseSubstance() {
    const frequency = (this.props.frequency - 1);
    if (frequency < 0) {
      return;
    }
    this.props.handleEdit({ id: this.props.id, frequency });
  }

  render() {
    const { name, frequency } = this.props;
    return (
      <div style={baseStyles.substance}>
        <div style={baseStyles.name}>{name}</div>
        <div style={baseStyles.sideBySide}>
          <RemoveCircleIcon onClick={this.decreaseSubstance} style={baseStyles.circles} />
          <p style={baseStyles.number}>{frequency}</p>
          <AddCircleIcon onClick={this.increaseSubstance} style={baseStyles.circles} />
        </div>
      </div>
    );
  }
}

SocialUsageItem.defaultProps = {
  id: null,
  name: '',
  frequency: 0,
};

SocialUsageItem.propTypes = {
  id: PropTypes.number,
  handleEdit: PropTypes.func.isRequired,
  name: PropTypes.string,
  frequency: PropTypes.number,
};

export default SocialUsageItem;
