import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import AppBar from '../components/app-bar';
import Page from './page';
import QuestionLayout from '../layouts/question';
import YesNoButtonSet from '../components/yes-no-button-set';
import { colors } from '../lib/styles';
import { updatePRO } from '../state/pro-forms';
import { Lungs } from '../lib/icons';

const styles = {
  answerButtonContainer: {
    display: 'flex',
    marginLeft: -10,
    marginTop: 8,
  },
  bar: {
    background: colors.lightBrightBlue,
  },
  contentWrapper: {
    flexGrow: 1,
    overflowX: 'hidden',
    position: 'relative',
  },
  imgRow: {
    display: 'flex',
    justifyContent: 'center',
    padding: '15px 60px',
  },
  imageWrapper: {
    width: 100,
  },
  instructions: {
    color: 'grey',
    fontSize: '1.5rem',
    margin: '20px 40px 0px 40px',
    textAlign: 'center',
  },
  questionnaireContainer: {
    paddingLeft: 20,
    paddingRight: 20,
  },
  breathingText: {
    fontSize: '2rem',
    fontWeight: 'bold',
  },
  questionText: {
    fontSize: '2rem',
    marginTop: 25,
  },
  continueButtonWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 80,
  },
};

class ThoracicCancerSymptoms1 extends Page {
  constructor(props) {
    super(props);

    this.state = {
      coughing: null,
    };
  }

  handleChangeCoughing = (selected) => {
    this.setState({ coughing: selected });
  }

  handleClickNext = () => {
    const { coughing } = this.state;
    this.props.updatePRO({
      type: 'thoracicCancerSymptoms',
      position: this.getTrackIndex(),
      value: { coughing },
    });
    this.forwardWithQuery(this.props.location.query);
  }

  render() {
    const { classes } = this.props;
    const { coughing } = this.state;

    return (
      <QuestionLayout
        primaryBtnDisabled={coughing === null}
        primaryBtnLabel="Next"
        primaryBtnOnTouchTap={this.handleClickNext}
        style={{ backgroundColor: colors.questionBackground }}
      >
        <AppBar
          headerNode="Breathing"
          backButtonOnClick={() => this.props.router.goBack()}
        />
        <LinearProgress
          variant="determinate"
          value={(this.getCurrentPage() / this.getTotalPages()) * 100}
          classes={{ bar: this.props.classes.bar }}
        />
        <div className={classes.contentWrapper}>
          <div className={classes.instructions}>
            Please select the option that best answers the question relating to breathing
          </div>
          <div className={classes.imgRow}>
            <div className={classes.imageWrapper}>
              <Lungs />
            </div>
          </div>
          <div className={classes.questionnaireContainer}>
            <div className={classes.breathingText}>Breathing</div>
            <YesNoButtonSet
              isYes={coughing}
              isNo={coughing === false}
              onClickNo={() => this.handleChangeCoughing(false)}
              onClickYes={() => this.handleChangeCoughing(true)}
              questionText="Are you coughing?"
            />
          </div>
        </div>
      </QuestionLayout>
    );
  }
}

export default connect(null, { updatePRO })(withStyles(styles)(ThoracicCancerSymptoms1));
