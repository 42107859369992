import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { apiFetch, makeShadowOptions } from '../lib/fetch';
import Page from './page';
import Layout from '../layouts/common';
import { colors } from '../lib/styles';
import Continue from '../components/continue';

const baseStyles = {
  paragraph: {
    textAlign: 'center',
    color: colors.primaryColor,
    width: '90vw',
    maxWidth: '700px',
    height: '60vh',
    fontSize: '16px',
    margin: '20px auto',
    backgroundColor: '#FFF',
    overflowY: 'scroll',
    boxShadow: 'inset 1px 1px 5px #9B9B9B',
    padding: '10px',
    borderRadius: '2px',
  },
  button: {
    backgroundColor: colors.primaryColor,
    width: '40%',
    maxWidth: '200px',
    margin: '1rem .5rem',
  },
  center: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'left',
    width: '90vw',
    margin: '10px auto',
    fontSize: '18px',
  },
};

class JohnsHopkinsConsent2 extends Page {
  state = {
    url: '',
  }

  async componentDidMount() {
    const { pro_type, user_id, id } = this.props.jhconsent[0];
    const options = makeShadowOptions({}, this.props.user.token, 'GET');
    const res = await apiFetch(`/users/${user_id}/pro_submissions/${id}/releases/${pro_type}`, options);
    
    this.setState({ url: res.url });
  }

  onSubmission = () => {
    this.forward();
  }

  render() {
    return (
      <Layout>
        <div style={{ backgroundColor: '#FFF', height: '100vh' }}>
          <img alt="logo" style={{ width: '90%', maxWidth: '400px', padding: '15px' }} src="/img/Hopkins.png" />
          <div style={baseStyles.center}>
            <div style={{ margin: '20px', fontSize: '1.4rem' }}>
              You may download a copy of the Research Participant Informed Consent and Privacy Authorization by clicking the button below.
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px', flexWrap: 'wrap' }}>
              <Continue
                text="Download"
                btnStyle={baseStyles.button}
                href={this.state.url}
                target="_blank"
              />
              <Continue
                text="Continue"
                btnStyle={baseStyles.button}
                onClick={this.onSubmission}
              />
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  const { user, proForms: { jhconsent }, proSubmit } = state;
  return { user, jhconsent, proSubmit };
}

JohnsHopkinsConsent2.propTypes = {
  user: PropTypes.object.isRequired,
  proSubmit: PropTypes.object.isRequired,
  jhconsent: PropTypes.array.isRequired,
};

export default connect(mapStateToProps, {})(JohnsHopkinsConsent2);
