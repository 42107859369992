import React from 'react';
import { connect } from 'react-redux';
import Page from './page';
import Layout from '../layouts/common';
import DisclaimerComponent from '../components/disclaimer';

class Disclaimer extends Page {
  render() {
    return (
      <Layout>
        <DisclaimerComponent continueAction={() => this.forward()} />
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state;
  return {
    user,
  };
}

export default connect(mapStateToProps, { })(Disclaimer);
