import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Button, LinearProgress } from '@material-ui/core';

import AppBar from '../components/app-bar';
import Continue from '../components/continue';

import { Heart } from '../lib/icons';

import Page from './page';

import { updatePRO } from '../state/pro-forms';

const styles = {
  button: {
    margin: 5,
    width: 150,
    height: 40,
    fontSize: 16,
  },
  buttonGroup: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  iconContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    flexGrow: 0,
    flexShrink: 0,
    width: '100%',
    color: '#D73B3B',
    paddingTop: 10,
  },
  iconWrapper: {
    width: 100,
    marginTop: 10,
  },
  mainContainer: {
    position: 'relative',
    minHeight: '100vh',
    marginBottom: '50px',
  },
  titleGroup: {
    margin: 25,
  },
  text: {
    fontSize: '22px',
  },
  nextBtn: {
    textAlign: 'center',
    width: '100%',
    bottom: 0,
    position: 'absolute',
    height: '8rem',
  },
  wrapper: {
    paddingBottom: '8rem',
  },
};

class Diabetes1 extends Page {
  constructor(props) {
    super(props);

    this.state = {
      familyHxCVD: null,
      highBP: null,
      highCholesterol: null,
    };

    this.handleContinue = this.handleContinue.bind(this);
  }

  handleContinue() {
    this.props.updatePRO({
      type: 'diabetesInitial',
      position: this.getTrackIndex(),
      value: this.state });
    this.forwardWithQuery(this.props.location.query);
  }

  render() {
    const { familyHxCVD, highBP, highCholesterol } = this.state;

    return (
      <div style={styles.mainContainer}>
        <AppBar
          headerNode="Family History"
          noLeftNode={true}
        />
        <LinearProgress
          variant="determinate"
          value={(this.getCurrentPage() / this.getTotalPages()) * 100}
        />
        <div style={styles.iconContainer}>
          <div style={styles.iconWrapper}>
            <Heart />
          </div>
        </div>
        <div style={styles.wrapper}>
          <div style={styles.titleGroup}>
            <p style={styles.text}>Do you have a family history of cardiovascular disease?</p>
            <div style={styles.buttonGroup}>
              <Button style={styles.button} variant="contained" color={familyHxCVD ? 'primary' : 'default'} onClick={() => this.setState({ familyHxCVD: true })}>Yes</Button>
              <Button style={styles.button} variant="contained" color={familyHxCVD === false ? 'primary' : 'default'} onClick={() => this.setState({ familyHxCVD: false })}>No</Button>
            </div>
          </div>
          <div style={styles.titleGroup}>
            <p style={styles.text}>Do you have high blood pressure?</p>
            <div style={styles.buttonGroup}>
              <Button style={styles.button} variant="contained" color={highBP ? 'primary' : 'default'} onClick={() => this.setState({ highBP: true })}>Yes</Button>
              <Button style={styles.button} variant="contained" color={highBP === false ? 'primary' : 'default'} onClick={() => this.setState({ highBP: false })}>No</Button>
            </div>
          </div>
          <div style={styles.titleGroup}>
            <p style={styles.text}>Do you have a family history of high cholesterol?</p>
            <div style={styles.buttonGroup}>
              <Button style={styles.button} variant="contained" color={highCholesterol ? 'primary' : 'default'} onClick={() => this.setState({ highCholesterol: true })}>Yes</Button>
              <Button style={styles.button} variant="contained" color={highCholesterol === false ? 'primary' : 'default'} onClick={() => this.setState({ highCholesterol: false })}>No</Button>
            </div>
          </div>
        </div>

        <div style={styles.nextBtn}>
          <Continue
            disabled={familyHxCVD === null || highBP === null || highCholesterol === null}
            text="Next"
            onClick={this.handleContinue}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { user, proForms: { diabetesInitial } } = state;
  return { user, diabetesInitial };
}

Diabetes1.proptypes = {
  user: PropTypes.object.isRequired,
  route: PropTypes.object.isRequired,
  updatePRO: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { updatePRO })(Diabetes1);
