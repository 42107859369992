import React from 'react';
import { LinearProgress, TextField } from '@material-ui/core';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMessage } from '@fortawesome/free-regular-svg-icons';

import AppBar from '../components/app-bar';
import Page from './page';
import QuestionLayout from '../layouts/question';
import { colors, fontSizing } from '../lib/styles';
import { submitPRO } from '../state/pro-forms';

const styles = {
  boldAndItalic: {
    fontStyle: 'italic',
    fontWeight: 'bold',
  },
  pageContent: {
    fontSize: fontSizing.body,
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 20,
  },
  iconWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 20,
  },
  instructionsText: {
    fontWeight: 'bold',
  },
  questionText: {
    marginTop: 25,
  },
};

class HumanFactorsForIvcApp4 extends Page {
  constructor(props) {
    super(props);

    this.state = {
      suggestions: '',
    };
  }

  handleClickNext = () => {
    const { humanFactorsForIvcApp, user } = this.props;
    const { id } = this.props.location.query;
    const { suggestions } = this.state;

    const data = [...humanFactorsForIvcApp];
    if (suggestions) data.push({ suggestions });

    this.props.submitPRO({ userId: user.id, id }, {
      pro_type: 'HUMAN-FACTORS-FOR-IVC-APP',
      pro_data: { data },
    }, user)
      .then(() => this.finishTrack());
  };

  handleChangeSuggestions = (e) => {
    this.setState({ suggestions: e.target.value });
  }

  render() {
    const { classes } = this.props;
    const { moreStressed, suggestions } = this.state;

    const continueDisabled = moreStressed === null;

    return (
      <QuestionLayout
        primaryBtnLabel="Next"
        primaryBtnOnTouchTap={this.handleClickNext}
        primaryBtnDisabled={continueDisabled}
        style={{ backgroundColor: colors.questionBackground }}
      >
        <AppBar
          headerNode="Feedback"
          backButtonOnClick={() => this.props.router.goBack()}
        />
        <LinearProgress
          variant="determinate"
          value={(this.getCurrentPage() / this.getTotalPages()) * 100}
        />
        <div className={classes.pageContent}>
          <div className={classes.iconWrapper}>
            <FontAwesomeIcon icon={faMessage} size="5x" />
          </div>
          <div className={classes.instructionsText}>
            Please provide feedback on your experience using the app.
          </div>
          <div className={classes.questionText}>Any suggestions to how we can improve the Informed Vital Core application?</div>
          <TextField
            placeholder="tap here to enter"
            onChange={this.handleChangeSuggestions}
            classes={{ root: classes.textFieldRoot }}
            value={suggestions}
            fullWidth
            multiline
          />
        </div>
      </QuestionLayout>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    proForms: {
      humanFactorsForIvcApp,
    },
    user,
  } = state;

  return { humanFactorsForIvcApp, user };
};

export default connect(mapStateToProps, { submitPRO })(withStyles(styles)(HumanFactorsForIvcApp4));
