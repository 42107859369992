import React from 'react';
import { connect } from 'react-redux';
import { filter, forEach, orderBy } from 'lodash';

import AppBar from '../components/app-bar';
import Page from './page';
import Layout from '../layouts/common';
import { socialIfNeeded } from '../state/app-data';
import { browseSocialHx, addSocialHx, deleteSocialHx } from '../state/social-hx';
import Continue from '../components/continue';
import Subtitle from '../components/subtitle';
import QuestionHeader from '../components/question-header';
import SimpleListItem from '../components/simple-list-item';


class SocialHistory extends Page {
  constructor(props) {
    super(props);
    this.handleAdd = this.handleCheck.bind(this, true);
    this.handleRemove = this.handleCheck.bind(this, false);
    this.handleContinue = this.handleContinue.bind(this);
    this.getHx = this.getHx.bind(this);
  }

  componentWillMount() {
    this.props.socialIfNeeded();
    this.props.browseSocialHx();
  }

  handleCheck(checked, social_id) {
    if (checked) {
      return this.props.addSocialHx({ social_id, frequency: 0 })
        .then(() => this.props.browseSocialHx());
    }

    this.props.deleteSocialHx(social_id)
      .then(() => this.props.browseSocialHx());
  }

  handleContinue() {
    this.forward();
  }

  getHx(id) {
    const { data: socialHx } = this.props.socialHx;
    return filter(socialHx, { social_id: id })[0];
  }

  render() {
    const { social } = this.props.appData;
    const { data: socialHx } = this.props.socialHx;
    const sortedHx = orderBy(social, [social => social.name.toLowerCase()], ['desc']);
    const listItems = [];

    forEach(sortedHx, (obj) => {
      const hx = this.getHx(obj.id, 'SELF');

      const simpleListItem = (
        <SimpleListItem
          key={obj.id}
          item={obj}
          checked={!!hx}
          onAddItem={this.handleAdd}
          onRemoveItem={this.handleRemove}
          selectedItems={socialHx}
        />
      );
      // eslint-disable-next-line no-unused-expressions
      (obj.name.toLowerCase() === 'none reported') ? listItems.push(simpleListItem) : listItems.unshift(simpleListItem);
    });

    return (
      <Layout>
        <div>
          <AppBar
            backButtonOnClick={() => this.props.router.goBack()}
            headerNode="Social History"
          />
          <Subtitle label="SCROLL TO SEE ALL OPTIONS" />
          <QuestionHeader>Do You Consume:</QuestionHeader>
          {listItems}
          <section>
            <Continue onClick={this.handleContinue} />
          </section>
        </div>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  const { user, appData, socialHx } = state;
  return {
    user,
    appData,
    socialHx,
  };
}

export default connect(mapStateToProps, { socialIfNeeded, browseSocialHx, addSocialHx, deleteSocialHx })(SocialHistory);
