const en = {
  appointmentConfirmation2: {
    title: 'Appointment Confirmation',
    subtitle: 'Thank you for downloading your appointment!',
    instructions: 'Download Instructions',
    connection: 'Check Connection',
    download: 'Oops! I forgot to download the appointment.',
    continue: 'Confirm',
  },
};

const es = {
  appointmentConfirmation2: {
    title: 'Confirmación de cita virtual',
    subtitle: 'Gracias por descargar su cita!',
    instructions: 'Descargar Instrucciones',
    connection: 'Comprobar Conexión',
    download: '¡Oh, no! Olvidé descargar la cita.',
    continue: 'Confirmar',
  },
};

export default { en, es };
