import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { capitalize, get } from 'lodash';
import Button from '@material-ui/core/Button';

import LoadingContainer from '../components/loading-container';
import PainBody from '../components/pain-body';
import Page from './page';
import { browseMigraineHx } from '../state/migraine-hx';
import { browsePainHx } from '../state/pain-hx';
import BodyPainDetails from '../components/body-pain-details';
import HeadachePainDetails from '../components/headache-pain-details';
import PainHead from '../components/pain-head';
import buildBodyData from '../lib/aggregate-body-data';
import buildHeadData from '../lib/aggregate-head-data';

const styles = {
  headerButtonLabel: {
    fontSize: '1.75rem',
    textTransform: 'none',
  },
  painBody: {
    minWidth: '65%',
  },
  sectionTitle: {
    fontSize: '1.875rem',
    fontWeight: 'bold',
    margin: '2rem 0',
    textAlign: 'center',
  },
};


class RecentPain extends Page {
  state = {
    painView: 'body',
    headData: {},
    bodyData: {},
  }

  componentWillMount() {
    this.setState({
      fetchingBodyData: true,
      fetchingHeadData: true,
    });
    this.props.browsePainHx(null, null, { limit: 1 })
      .then(buildBodyData)
      .then(bodyData => this.setState({ bodyData, fetchingBodyData: false }));

    this.props.browseMigraineHx(null, null, { limit: 1 })
      .then(buildHeadData)
      .then(headData => this.setState({ headData, fetchingHeadData: false }));
  }

  handleSwitchView = () => {
    this.setState(prevState => ({
      painView: prevState.painView === 'head' ? 'body' : 'head',
    }));
  }

  render() {
    const { active } = this.props;
    const { headData, bodyData, fetchingBodyData, fetchingHeadData, painView } = this.state;

    const isBodyPain = painView === 'body';

    const headerButtonText = isBodyPain ? 'View Head Pain' : 'View Body Pain';

    const isFetching = (isBodyPain && fetchingBodyData) || (!isBodyPain && fetchingHeadData);
    const noData = !isFetching && ((isBodyPain && !bodyData.total) || (!isBodyPain && !headData.total));
    const painIntensity = get(bodyData, 'worstPain.intensity');
    const painType = (get(bodyData, 'worstPain.pain_type') || '').split('_').map(capitalize).join(' ');
    const containerStyle = active ? {} : { maxHeight: '85vh' };
    return (
      <div style={containerStyle}>
        <Button
          onClick={this.handleSwitchView}
          style={{ ...styles.helpButton, ...styles.headerButtonLabel }}
        >{headerButtonText}</Button>
        <div style={styles.sectionTitle}>
          Pain Entry Details
        </div>
        <LoadingContainer
          isFetching={isFetching}
          noData={noData}
          noDataMessage="No Pain Found"
        >
          {painView === 'head' && (
            <div style={{ margin: '0 auto', width: '70%' }}>
              <PainHead
                pain={[headData.worstPain]}
              />
            </div>
          )}
          {isBodyPain && (
            <div style={styles.painBody}>
              <h2>
                Level {painIntensity} {painType}
              </h2>
              <PainBody
                selectedLocations={get(bodyData, 'worstPain.pain_locations_full', [])}
              />
            </div>
          )}
          {isBodyPain && (
            <BodyPainDetails
              painLocations={bodyData.painLocations}
              functionalLimitations={bodyData.limitations}
              aggravatingFactors={bodyData.aggravatingFactors}
              alleviatingFactors={bodyData.alleviatingFactors}
            />
          )}
          {painView === 'head' && (
            <HeadachePainDetails
              painLocations={headData.painLocations}
              symptoms={headData.symptoms}
              preventitiveMedications={headData.preventitiveRx}
              abortiveMedications={headData.abortiveRx}
            />
          )}
        </LoadingContainer>
      </div>
    );
  }
}

RecentPain.propTypes = {
  bodyData: PropTypes.shape({
    aggravating_factors: PropTypes.arrayOf(PropTypes.string),
    alleviating_factors: PropTypes.arrayOf(PropTypes.string),
    functional_limitations: PropTypes.arrayOf(PropTypes.string),
    pain_locations: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      x: PropTypes.number,
      y: PropTypes.number,
      body_location: PropTypes.string,
    })),
  }),
  headData: PropTypes.shape({
    pain_locations: PropTypes.array,
    symptoms: PropTypes.arrayOf(PropTypes.string),
  }),
  steps: PropTypes.number,
  stepsProgress: PropTypes.number,
};

function mapStateToProps(state) {
  const { user: { userId } } = state;

  return {
    userId,
  };
}

export default connect(mapStateToProps, {
  browseMigraineHx,
  browsePainHx,
})(RecentPain);
