/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react';
import TextField from '@material-ui/core/TextField';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import AppBar from '../components/app-bar';
import Page from './page';
import QuestionLayout from '../layouts/question';
import { colors } from '../lib/styles';
import { submitPRO, updatePRO } from '../state/pro-forms';
import { addInsurance, browseInsurance, getImageUrl } from '../state/insurance';
import { browseInsuranceImages } from '../state/insurance-images';
import { apiFetch } from '../lib/fetch';

const styles = {
  answerButtonContainer: {
    display: 'flex',
    marginLeft: -10,
    marginTop: 8,
  },
  bar: {
    background: colors.lightBrightBlue,
  },
  centerContent: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    marginTop: 30,
  },
  contentWrapper: {
    flexGrow: 1,
    marginLeft: 40,
    marginRight: 40,
    overflowX: 'hidden',
    position: 'relative',
  },
  imgRow: {
    display: 'flex',
    justifyContent: 'center',
    padding: '15px 60px',
  },
  imageWrapper: {
    width: 100,
  },
  insuranceCardImage: {
    borderRadius: '5px',
    margin: '5px',
  },
  insuranceCardImageButton: {
    border: 'none',
    margin: 0,
    padding: 0,
  },
  insuranceCardImageContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 10,
  },
  insuranceCardTextField: {
    width: 300,
  },
  insuranceCompanyLabel: {
    marginTop: 30,
  },
  pageInstructions: {
    fontSize: '1.5rem',
    marginTop: 30,
  },
  photoCaptureInstructions: {
    color: colors.primaryColor,
  },
  questionnaireContainer: {
    paddingLeft: 20,
    paddingRight: 20,
  },
  breathingText: {
    fontSize: '2rem',
    fontWeight: 'bold',
  },
  questionText: {
    fontSize: '2rem',
    marginTop: 25,
  },
  continueButtonWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 80,
  },
};

class CaptureInsuranceCard1 extends Page {
  handleChangeInsuranceName = (e) => {
    this.props.updatePRO({
      type: 'captureInsuranceCard',
      position: this.getTrackIndex(),
      value: e.target.value,
    });
  }

  handleClickInsuranceCardBack = () => {
    this.goToIndexWithQuery(2, this.props.location.query);
  }

  handleClickInsuranceCardFront = () => {
    this.goToIndexWithQuery(1, this.props.location.query);
  }

  handleSubmitInsuranceCard = async () => {
    const { captureInsuranceCard, location, user } = this.props;
    const insuranceName = captureInsuranceCard[this.getTrackIndex()];
    const frontImage = captureInsuranceCard[1];
    const backImage = captureInsuranceCard[2];

    let frontImageBlob = await window.fetch(frontImage);
    frontImageBlob = await frontImageBlob.blob();

    let backImageBlob = await window.fetch(backImage);
    backImageBlob = await backImageBlob.blob();

    const insuranceCardFrontFormData = new FormData();
    insuranceCardFrontFormData.append('image', frontImageBlob);
    insuranceCardFrontFormData.append('image_type', 'PRIMARY_INSURANCE_CARD_FRONT');
    insuranceCardFrontFormData.append('insurance_name', insuranceName);

    const insuranceCardBackFormData = new FormData();
    insuranceCardBackFormData.append('image', backImageBlob);
    insuranceCardBackFormData.append('image_type', 'PRIMARY_INSURANCE_CARD_BACK');
    insuranceCardBackFormData.append('insurance_name', insuranceName);

    await apiFetch(`/users/${user.id}/primary_insurance_image`, {
      method: 'POST',
      body: insuranceCardFrontFormData,
    });

    await apiFetch(`/users/${user.id}/primary_insurance_image`, {
      method: 'POST',
      body: insuranceCardBackFormData,
    });

    await submitPRO(
      { userId: user.id, id: location.query.id },
      {
        pro_data: { data: { insuranceName } },
        pro_type: 'CAPTURE-INSURANCE-CARD',
      },
      user,
    );

    this.finishTrack();
  }

  render() {
    const { captureInsuranceCard, classes } = this.props;

    const saveInsuranceDisabled = !captureInsuranceCard[this.getTrackIndex()]
      || !captureInsuranceCard[1]
      || !captureInsuranceCard[2];

    const insuranceCardFrontImage = captureInsuranceCard[1];
    const insuranceCardBackImage = captureInsuranceCard[2];

    return (
      <QuestionLayout
        primaryBtnDisabled={saveInsuranceDisabled}
        primaryBtnLabel="Save Insurance"
        primaryBtnOnTouchTap={this.handleSubmitInsuranceCard}
        style={{ backgroundColor: colors.questionBackground }}
      >
        <AppBar
          headerNode="Insurance Card"
          backButtonOnClick={() => this.props.router.goBack()}
        />
        <div className={classes.contentWrapper}>
          <div className={classes.pageInstructions}>
            Please upload photos of your current insurance card
          </div>
          <div className={classes.pageInstructions}>
            You may take a picture of the front and back of your card
          </div>
          <div className={classes.insuranceCompanyLabel}>Enter the name of your insurance company.</div>
          <TextField
            className={classes.insuranceCardTextField}
            onChange={this.handleChangeInsuranceName}
            value={captureInsuranceCard[0] || ''}
          />
          <div className={classes.centerContent}>
            <div className={classes.photoCaptureInstructions}>
              Tap pictures to set or retake
            </div>
            <div className={classes.insuranceCardImageContainer}>
              <button
                className={classes.insuranceCardImageButton}
                role="link"
                type="button"
                onClick={this.handleClickInsuranceCardFront}
              >
                <img
                  alt="Insurance Card Front"
                  src={insuranceCardFrontImage || '/img/front-card-btn.png'}
                  className={classes.insuranceCardImage}
                  width="128"
                  height="75"
                />
              </button>
              <button
                className={classes.insuranceCardImageButton}
                role="link"
                type="button"
                onClick={this.handleClickInsuranceCardBack}
              >
                <img
                  alt="Insurance Card Back"
                  src={insuranceCardBackImage || '/img/back-card-btn.png'}
                  className={classes.insuranceCardImage}
                  width="128"
                  height="75"
                />
              </button>
            </div>
          </div>
        </div>
      </QuestionLayout>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    insurance,
    proForms: { captureInsuranceCard },
    user,
  } = state;

  return { insurance, captureInsuranceCard, user };
};

export default connect(mapStateToProps, {
  addInsurance,
  browseInsurance,
  browseInsuranceImages,
  getImageUrl,
  submitPRO,
  updatePRO,
})(withStyles(styles)(CaptureInsuranceCard1));
