import React, { Component } from 'react';
import PropTypes from 'prop-types';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

import PainType from './pain-type';
import { colors } from '../lib/styles';

const painTypeOptions = [
  'ACHE',
  'BURNING',
  'CONSTANT',
  'CRAMPING',
  'DULL',
  'NUMBNESS',
  'PINCHING',
  'PINS_AND_NEEDLES',
  'SHARP',
  'SHOOTING',
  'STABBING',
  'THROBBING',
  'TINGLING',
];

const painTypeOptionsLength = painTypeOptions.length;

const baseStyles = {
  bigArrowsLightBlue: {
    width: 60,
    height: 60,
    color: colors.primaryHighlight,
  },
  headachePainTypeSect: {
    alignItems: 'center',
    backgroundColor: colors.lightBrightBlue,
    display: 'flex',
    height: '95px',
    justifyContent: 'space-between',
  },
  slider: {
    background: colors.primaryColor,
    padding: 10,
  },
};


class PainTypeSelector extends Component {
  constructor(props) {
    super(props);

    this.handlePainTypeUpdate = this.handlePainTypeUpdate.bind(this);
    this.handleNextPainType = this.handleNextPainType.bind(this);
    this.handlePrevPainType = this.handlePrevPainType.bind(this);
  }

  handlePainTypeUpdate(painType) {
    this.props.onUpdatePainType(painType);
  }

  handleNextPainType() {
    const idx = painTypeOptions.indexOf(this.props.painType);
    let newIdx = idx + 1;

    if (newIdx > painTypeOptionsLength - 1) {
      newIdx = 0;
    }
    this.props.onUpdatePainType(painTypeOptions[newIdx]);
  }

  handlePrevPainType() {
    const idx = painTypeOptions.indexOf(this.props.painType);
    let newIdx = idx - 1;

    if (newIdx < 0) {
      newIdx = painTypeOptionsLength - 1;
    }

    this.props.onUpdatePainType(painTypeOptions[newIdx]);
  }

  render() {
    const { painType } = this.props;

    let currentIdx = painTypeOptions.indexOf(painType);

    currentIdx = currentIdx < 0 ? 0 : currentIdx;
    let displayedOptions;

    if (currentIdx > 0 && currentIdx < (painTypeOptionsLength - 1)) {
      displayedOptions = painTypeOptions.slice(currentIdx - 1, currentIdx + 2);
    }
    if (currentIdx < 1) {
      displayedOptions = [
        painTypeOptions[painTypeOptionsLength - 1],
        painTypeOptions[currentIdx],
        painTypeOptions[currentIdx + 1],
      ];
    }
    if (currentIdx === (painTypeOptionsLength - 1)) {
      displayedOptions = [
        painTypeOptions[currentIdx - 1],
        painTypeOptions[currentIdx],
        painTypeOptions[0],
      ];
    }

    const painTypes = displayedOptions.map((value) => {
      return <PainType active={value === painType} key={value} type={value} onSelect={this.handlePainTypeUpdate} />;
    });
    return (
      <section style={baseStyles.headachePainTypeSect}>
        <KeyboardArrowLeftIcon
          onClick={this.handlePrevPainType}
          style={baseStyles.bigArrowsLightBlue}
        />
        <div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            {painTypes}
          </div>
        </div>
        <KeyboardArrowRightIcon
          onClick={this.handleNextPainType}
          style={baseStyles.bigArrowsLightBlue}
        />
      </section>
    );
  }
}

PainTypeSelector.defaultProps = {
  painType: '',
};

PainTypeSelector.propTypes = {
  painType: PropTypes.string,
  onUpdatePainType: PropTypes.func.isRequired,
};

export default PainTypeSelector;
