import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get } from 'lodash';

import Page from './page';
import PainScaleSingleSlider from '../components/pain-scale-single-slider';
import { BackPain } from '../lib/icons';
import { colors } from '../lib/styles';
import { updatePRO } from '../state/pro-forms';

const instructions = 'On a scale from 0 to 10, '
  + 'mark the intensity of your neck pain, with 0 '
  + 'being \'None\' and 10 being \'Unbearable Pain\'';

const { questionBackground } = colors;
class NdiNeckPainScale extends Page {
  onClickBackButton = () => {
    this.props.router.push('/notifications');
  }

  onSubmission = () => {
    this.forwardWithQuery(this.props.location.query);
  }

  handleChange = (value) => {
    this.props.updatePRO({
      type: 'ndi',
      position: this.getTrackIndex(),
      value,
    });
  }

  render() {
    const value = get(this.props.ndi, this.getTrackIndex(), null);
    return (
      <PainScaleSingleSlider
        backgroundColor={questionBackground}
        currentPage={this.getCurrentPage()}
        image={BackPain}
        instructions={instructions}
        onClickBackButton={() => this.props.router.goBack()}
        onChange={this.handleChange}
        onSubmission={this.onSubmission}
        sliderMax={10}
        sliderMin={0}
        title="Neck Pain Scale"
        totalPages={this.getTotalPages()}
        trackIndex={this.getTrackIndex()}
        value={value}
      />
    );
  }
}

function mapStateToProps(state) {
  const { proForms: { ndi } } = state;
  return { ndi };
}

NdiNeckPainScale.propTypes = {
  ndi: PropTypes.array.isRequired,
  updatePRO: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { updatePRO })(NdiNeckPainScale);
