import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';
import { KeypadDate } from 'common-ui';
import { connect } from 'react-redux';
import Joi from 'joi-browser';

import Page from './page';
import AppBar from '../components/app-bar';
import IconWrapper from '../components/icon-wrapper';
import BloomkidzLayout from '../layouts/bloomkidz';
import { ChildReaching } from '../lib/icons';
import { fontSizing } from '../lib/styles';
import { updatePRO } from '../state/pro-forms';
import { bloomkidzStyles, bloomkidzStylesObj } from '../lib/bloomkidz';

const styles = bloomkidzStylesObj;

const formSchema = Joi.object({
  birthday: Joi.date(),
  zipcode: Joi.string().regex(/^\d{5}([-\s]\d{4})?$/),
});

class BloomkidzNewClient1 extends Page {
  constructor(props) {
    super(props);

    const stateFromRedux = props.bloomkidzNewClient[this.getTrackIndex()] || {};

    this.state = {
      childsFirstName: '',
      childsLastName: '',
      childsNickname: '',
      birthday: '',
      gender: '',
      address: '',
      city: '',
      state: '',
      zipcode: '',
      pediatrician: '',
      insuranceCompany: '',
      errors: {
        birthday: '',
        zipcode: '',
      },
      ...stateFromRedux,
    };
  }

  handleTextFieldChange = (e) => {
    const stateUpdate = { [e.target.name]: e.target.value };

    if (this.state.errors[e.target.name]) {
      stateUpdate.errors = {
        ...this.state.errors,
        [e.target.name]: '',
      };
    }

    this.setState(stateUpdate);
  };

  handleChangeBirthday = (birthday) => {
    this.setState(curState => ({
      birthday,
      errors: { ...curState.errors, birthday: '' },
    }));
  }

  handleContinue = () => {
    const { location, updatePRO } = this.props;
    const { birthday, zipcode } = this.state;

    const { error } = formSchema.validate({
      birthday,
      zipcode,
    }, { abortEarly: false });

    if (error) {
      const errors = {};

      error.details.forEach((error) => {
        errors[error.context.key] = 'Invalid value';
      });

      this.setState({ errors });
      return;
    }

    updatePRO({
      type: 'bloomkidzNewClient',
      position: this.getTrackIndex(),
      value: { ...this.state },
    });

    this.forwardWithQuery(location.query);
  }

  handleClickButton = (gender) => {
    this.setState({ gender });
  }

  shouldDisableContinueButton = () => {
    const { state } = this;

    const stateCopy = { ...state };
    delete stateCopy.childsNickname;
    delete stateCopy.pediatrician;
    delete stateCopy.insuranceCompany;

    if (stateCopy.errors.birthday || stateCopy.errors.zipcode) {
      return true;
    }

    const stateVals = Object.values(stateCopy);

    for (let i = 0; i < stateVals.length; i++) {
      if (!stateVals[i]) return true;
    }

    return false;
  }

  render() {
    const { classes, router } = this.props;
    const {
      childsFirstName,
      childsLastName,
      childsNickname,
      birthday,
      gender,
      address,
      city,
      state,
      zipcode,
      pediatrician,
      insuranceCompany,
      errors,
    } = this.state;

    const keypadDateWrapperClass = errors.birthday
      ? `${classes.keypadDateWrapper} ${classes.keypadDateWrapperError}`
      : classes.keypadDateWrapper;

    return (
      <BloomkidzLayout
        primaryBtnOnTouchTap={this.handleContinue}
        primaryBtnLabel="Next"
        primaryBtnDisabled={this.shouldDisableContinueButton()}
      >
        <AppBar
          headerNode="Patient Information"
          backButtonOnClick={() => router.goBack()}
        />
        <IconWrapper>
          <ChildReaching />
        </IconWrapper>
        <div className={classes.pageContent}>
          <div>Child&apos;s Name</div>
          <div className={classes.paddingHorizontal}>
            <div className={classes.textFieldWrapper}>
              <TextField
                placeholder="First Name"
                value={childsFirstName}
                onChange={this.handleTextFieldChange}
                name="childsFirstName"
                variant="standard"
                fullWidth
                inputProps={{ style: { fontSize: fontSizing.body, fontFamily: bloomkidzStyles.fontFamily } }}
              />
            </div>
            <div className={classes.textFieldWrapper}>
              <TextField
                placeholder="Last Name"
                value={childsLastName}
                onChange={this.handleTextFieldChange}
                name="childsLastName"
                variant="standard"
                fullWidth
                inputProps={{ style: { fontSize: fontSizing.body, fontFamily: bloomkidzStyles.fontFamily } }}
              />
            </div>
            <div className={classes.textFieldWrapper}>
              <TextField
                placeholder="Nickname"
                value={childsNickname}
                onChange={this.handleTextFieldChange}
                name="childsNickname"
                variant="standard"
                fullWidth
                inputProps={{ style: { fontSize: fontSizing.body, fontFamily: bloomkidzStyles.fontFamily } }}
              />
            </div>
          </div>
          <div className={classes.inputSectionTitle}>
            Date of Birth
          </div>
          <div className={classes.paddingHorizontal}>
            <div className={keypadDateWrapperClass}>
              <KeypadDate onChange={this.handleChangeBirthday} value={birthday} />
            </div>
            {errors.birthday ? (
              <div className={classes.errorHelperText}>
                {errors.birthday}
              </div>
            ) : null}
          </div>
          <div className={classes.inputSectionTitle}>
            Gender
          </div>
          <div className={classes.buttonRow}>
            <button
              className={`${classes.button} ${gender === 'male' ? classes.buttonSelected : classes.buttonUnselected}`}
              onClick={() => this.handleClickButton('male')}
              type="button"
            >
              Male
            </button>
            <button
              className={`${classes.button} ${gender === 'female' ? classes.buttonSelected : classes.buttonUnselected}`}
              onClick={() => this.handleClickButton('female')}
              type="button"
            >
              Female
            </button>
          </div>
          <div className={classes.inputSectionTitle}>
            Address
          </div>
          <div className={classes.paddingHorizontal}>
            <div className={classes.textFieldWrapper}>
              <TextField
                placeholder="Address"
                value={address}
                onChange={this.handleTextFieldChange}
                name="address"
                variant="standard"
                fullWidth
                inputProps={{ style: { fontSize: fontSizing.body, fontFamily: bloomkidzStyles.fontFamily } }}
              />
            </div>
            <div className={classes.textFieldWrapper}>
              <TextField
                placeholder="City"
                value={city}
                onChange={this.handleTextFieldChange}
                name="city"
                variant="standard"
                fullWidth
                inputProps={{ style: { fontSize: fontSizing.body, fontFamily: bloomkidzStyles.fontFamily } }}
              />
            </div>
            <div className={classes.textFieldWrapper}>
              <TextField
                placeholder="State"
                value={state}
                onChange={this.handleTextFieldChange}
                name="state"
                variant="standard"
                fullWidth
                inputProps={{ style: { fontSize: fontSizing.body, fontFamily: bloomkidzStyles.fontFamily } }}
              />
            </div>
            <div className={classes.textFieldWrapper}>
              <TextField
                placeholder="Zipcode"
                value={zipcode}
                onChange={this.handleTextFieldChange}
                name="zipcode"
                variant="standard"
                fullWidth
                inputProps={{
                  style: { fontSize: fontSizing.body, fontFamily: bloomkidzStyles.fontFamily },
                  type: 'tel',
                }}
                error={errors.zipcode}
                helperText={errors.zipcode}
              />
            </div>
          </div>
          <div className={classes.inputSectionTitle}>
            Health Care Information
          </div>
          <div className={classes.paddingHorizontal}>
            <div className={classes.textFieldWrapper}>
              <TextField
                placeholder="Pediatrician (optional)"
                value={pediatrician}
                onChange={this.handleTextFieldChange}
                name="pediatrician"
                variant="standard"
                fullWidth
                inputProps={{ style: { fontSize: fontSizing.body, fontFamily: bloomkidzStyles.fontFamily } }}
              />
            </div>
            <div className={classes.textFieldWrapper}>
              <TextField
                placeholder="Insurance Company (optional)"
                value={insuranceCompany}
                onChange={this.handleTextFieldChange}
                name="insuranceCompany"
                variant="standard"
                fullWidth
                inputProps={{ style: { fontSize: fontSizing.body, fontFamily: bloomkidzStyles.fontFamily } }}
              />
            </div>
          </div>
        </div>
      </BloomkidzLayout>
    );
  }
}

BloomkidzNewClient1.propTypes = {
  classes: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  updatePRO: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const {
    proForms: { bloomkidzNewClient },
  } = state;

  return { bloomkidzNewClient };
};

export default connect(mapStateToProps, { updatePRO })(withStyles(styles)(BloomkidzNewClient1));
