/* eslint-disable no-bitwise */
import { fromHexString, toLE16, crc8 } from './bits';

export class HRError extends Error {
  constructor(message, cause) {
    super(message);
    this.name = 'HRError';
    if (cause) {
      this.cause = cause;
    }
  }

  toString() {
    let errorString = super.toString();
    if (this.cause) {
      errorString += ' : ' + this.cause.toString();
    }
    return errorString;
  }
}

// Received Data
// 77 11 050000400014ff14ff14ff3cffff14ff 29
//  |  |                                |   `---- crc8 checksum (drop from crc)
//  |  |                                 `------- packet
//  |   `---------------------------------------- length (17 dec) (drop from crc)
//   `------------------------------------------- start frame (drop from crc)

// Sensors Packet
// 05 00004000 14 ff 14 ff 14 ff 3c ffff 14 ff
//  |        |  |  |  |  |  |  |  |    |  |   `---- Respitory Rate
//  |        |  |  |  |  |  |  |  |    |   `------- Respitory Rate Exception
//  |        |  |  |  |  |  |  |  |     `---------- Perfusion Index
//  |        |  |  |  |  |  |  |  |
//  |        |  |  |  |  |  |  |   `--------------- Perfusion Index Exception
//  |        |  |  |  |  |  |   `------------------ Pleth Variability Index
//  |        |  |  |  |  |   `--------------------- Pleth Variability Exception
//  |        |  |  |  |   `------------------------ Pulse Rate
//  |        |  |  |   `--------------------------- Pulse Rate Exception
//  |        |  |   `------------------------------ SpO2
//  |        |   `--------------------------------- SpO2 Exception
//  |         `------------------------------------ ?
//   `--------------------------------------------- packet type (05 is sensors)


function bpEvent(evt, intermediateFn) {
  const notificationVal = new Uint8Array(evt.target.value.buffer);

  const header = notificationVal[0];
  const len = notificationVal[1];
  const packet = notificationVal.slice(2, -1);
  const crc = notificationVal[notificationVal.length - 1];
  const dataType = packet[0];

  if (header === 0x77 && dataType === 0x05 && intermediateFn) {
    if (len !== notificationVal.length - 2) {
      const error = new HRError('packet wrong length');
      error.code = 910;
      intermediateFn({ packet: notificationVal }, error);
    } else if (crc8(packet) !== crc) {
      const error = new HRError('bad crc');
      error.code = 911;
      intermediateFn({ packet: notificationVal }, error);
    } else {
      const spO2Exc = packet[5];
      const SPO2 = packet[6];
      const pulseRateExc = packet[7];
      const HR = packet[8];
      const plethVariabilityIndexExc = packet[9];
      const plethVariabilityIndex = packet[10];
      const perfusionIndexExc = packet[11];
      const perfusionIndex = toLE16(packet.slice(12, 14)) / 100;
      const respRateExc = packet[14];
      const BR = packet[15];

      intermediateFn({ SPO2, HR, BR, packet: notificationVal, spO2Exc, pulseRateExc, plethVariabilityIndexExc, plethVariabilityIndex, perfusionIndexExc, perfusionIndex, respRateExc });
    }
  }
}

export class HrReader {
  device;

  server;

  service;

  readChar;

  writeChar;

  intermediateFn;

  initResponse = {};

  // throws bluetooth errors
  constructor() {
    return (async () => {
      try {
        // throws if couldnt get bluetooth peripheral? perhaps no ble present or permissions problem
        this.device = await navigator.bluetooth.requestDevice({
          filters: [{
            name: ['MightySat'],
          }],
          optionalServices: ['54c21000-a720-4b4f-11e4-9fe20002a5d5'],
        });

        this.device.addEventListener('gattserverdisconnected', this._disconnected.bind(this));

        // throws if couldnt connect, perhaps low battery or device too far
        this.server = await this.device.gatt.connect();

        // these throw if couldnt talk to device during initial setup
        this.service = await this.server.getPrimaryService('54c21000-a720-4b4f-11e4-9fe20002a5d5');
        this.writeChar = await this.service.getCharacteristic('54c21001-a720-4b4f-11e4-9fe20002a5d5');
        this.readChar = await this.service.getCharacteristic('54c21002-a720-4b4f-11e4-9fe20002a5d5');

        await this._loadDeviceInfo();
      } catch (e) {
        const error = new HRError("Couldn't connect", e);
        error.code = 902;
        throw error;
      }

      return this;
    })();
  }

  async stream(intermediateFn) {
    if (this.device && this.device.gatt && this.device.gatt.connected) {
      if (typeof this.intermediateFn !== 'undefined') {
        await this.stopStreaming();
      }

      this.intermediateFn = intermediateFn;
      this.readChar.addEventListener('characteristicvaluechanged', (evt) => { bpEvent(evt, this.intermediateFn); });

      return this.readChar.startNotifications()
        .then(() => {
          return this.writeChar.writeValueWithoutResponse(fromHexString('7705031f0003d6'));
        });
    }
    throw new HRError('Not connected');
  }

  async stopStreaming() {
    if (this.readChar) {
      this.readChar.removeEventListener('characteristicvaluechanged', (evt) => { bpEvent(evt, this.intermediateFn); });
      // DOMException: Failed to execute 'stopNotifications' on 'BluetoothRemoteGATTCharacteristic': GATT Server is disconnected. Cannot perform GATT operations. (Re)connect first with `device.gatt.connect`.
      // uncatchable? just guard
      if (this.device && this.device.gatt && this.device.gatt.connected) {
        try {
          await this.readChar.stopNotifications();
        } catch (e) {
          // stopNotifications finicky and also not in firefox
        }
      }
    }
    this.intermediateFn = undefined;
  }


  async _disconnected() {
    if (this.intermediateFn) {
      const error = new HRError('Device Disconnected');
      error.code = 901;
      this.intermediateFn(null, error);
    }

    await this.stopStreaming();

    if (this.device) {
      this.device.removeEventListener('gattserverdisconnected', this.disconnect.bind(this));
    }
  }

  disconnect() {
    // checking for connected isnt good enough, it can still fail between the check???
    try {
      this.device.gatt.disconnect();
    } catch (e) {
      // who cares
    }
  }

  connected() {
    return this.device && this.device.gatt && this.device.gatt.connected;
  }

  // readChar ONLY allows notifications so can't just readValue, have to get start and stop notifications...
  async _loadDeviceInfo() {
    const deviceInfoPromise = new Promise((resolve) => {
      // TODO theres a non zero chance that we dont get the packet we want here.. but filtering for packet type
      // means we need to not use once and thus use removeEventListener which is a pain...
      this.readChar.addEventListener('characteristicvaluechanged', (evt) => {
        // i see 77140144101f0003010017000202000000040000
        const notificationVal = new Uint8Array(evt.target.value.buffer);
        // havent fully decoded, so just forward the whole thing
        this.initResponse.deviceInfoResponse = notificationVal;

        // seemingly no crc, only decode further if we know we have our deviceInfo packet
        if (notificationVal.length >= 4) {
          const header = notificationVal[0];
          const dataType = notificationVal[2];
          // 4410 or 1044 or 4164 is firmwareVersion I believe?
          if (header === 0x77 && dataType === 0x01) {
            this.initResponse.firmwareVersion = toLE16(notificationVal.slice(3, 5));
          }
        }
        resolve();
      }, { once: true });
    });

    return this.readChar.startNotifications()
      .then(() => {
        this.writeChar.writeValueWithoutResponse(fromHexString('77020107'));
      })
      .then(() => {
        // if for some reason we dont get a packet, race with a timeout
        return Promise.race([
          deviceInfoPromise,
          new Promise((resolve) => {
            setTimeout(() => resolve, 200);
          }),
        ]);
      }).then(() => {
        // DOMException: Failed to execute 'stopNotifications' on 'BluetoothRemoteGATTCharacteristic': GATT Server is disconnected. Cannot perform GATT operations. (Re)connect first with `device.gatt.connect`.
        // uncatchable? just guard
        if (this.readChar && this.device && this.device.gatt && this.device.gatt.connected) {
          try {
            return this.readChar.stopNotifications();
          } catch (e) {
            // stopNotifications finicky and also not in firefox
          }
        }
      })
      // dont want to throw while just getting debug info, so just log it
      .catch((e) => {
        console.error('could not get deviceInfo packet', e);
      });
  }
}
