import React from 'react';
import { get, omit } from 'lodash';
import { connect } from 'react-redux';
import { Divider, TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import AppBar from '../components/app-bar';
import Page from './page';
import Layout from '../layouts/common';
import { colors } from '../lib/styles';
import Continue from '../components/continue';
import State from '../components/state';

import { browseEmployers, editEmployer, addEmployer, deleteEmployer } from '../state/employer';

const baseStyles = {
  flexStart: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  marginLeft: {
    marginLeft: 15,
  },
  text: {
    color: colors.primaryColor,
  },
};

const classStyles = {
  textField: {
    marginBottom: 5,
    marginTop: 5,
    '& input': {
      color: colors.greyText,
      fontSize: 18,
    },
    '& .MuiFormLabel-root:': {
      fontSize: 18,
      background: 'blue !important',
    },
    '& .MuiInput-underline:after': {
      borderBottom: 'none',
    },
    '& .MuiInput-underline:before': {
      borderBottom: 'none',
    },
    '& .MuiInput-underline:hover:before': {
      borderBottom: 'none',
    },
  },
};

class Employer extends Page {
  constructor(props) {
    super(props);

    this.state = {
      employer: {
        name: '',
        phone_number: '',
        city: '',
        state: 'none',
        zip_code: '',
        ...props.employer,
      },
    };
    this.handleSave = this.handleSave.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleState = this.handleState.bind(this);
    this.handleChangeName = this.handleTextNewMUI.bind(this, 'name');
    this.handleChangeAddress = this.handleTextNewMUI.bind(this, 'address');
    this.handleChangePhone = this.handleTextNewMUI.bind(this, 'phone_number');
    this.handleChangeCity = this.handleTextNewMUI.bind(this, 'city');
    this.handleChangeZip = this.handleTextNewMUI.bind(this, 'zip_code');
  }

  componentWillMount() {
    // TODO: This should be a read
    if (this.props.employerId && !this.props.employer) {
      this.browseEmployers();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.employer !== nextProps.employer) {
      this.setState({
        employer: {
          name: '',
          phone_number: '',
          city: '',
          state: 'none',
          zip_code: '',
          ...nextProps.employer,
        },
      });
    }
  }

  handleText(field, e, text) {
    this.setState({
      employer: {
        ...this.state.employer,
        [field]: text,
      },
    });
  }

  handleTextNewMUI(field, e) {
    this.setState({
      employer: {
        ...this.state.employer,
        [field]: e.target.value,
      },
    });
  }

  handleState(e) {
    this.setState({
      employer: {
        ...this.state.employer,
        state: e.target.value,
      },
    });
  }

  handleSave() {
    const { employer } = this.state;

    if (employer.id) {
      const updatedEmployer = omit(employer, ['user_id']);
      this.props.editEmployer(updatedEmployer)
        .then(() => this.props.router.push('/personalinfo'));
    } else {
      const newEmployer = omit(employer, ['id', 'user_id']);
      this.props.addEmployer(newEmployer)
        .then(() => this.props.router.push('/personalinfo'));
    }
  }

  handleDelete() {
    const { employer } = this.state;

    this.props.deleteEmployer(employer.id)
      .then(() => this.props.router.push('/personalinfo'));
  }

  render() {
    const { classes } = this.props;
    const { employer } = this.state;
    let deleteButton = null;
    if (employer.id) {
      deleteButton = (
        <Continue
          text="Delete Employer"
          btnStyle={{ backgroundColor: colors.errorRed }}
          onClick={this.handleDelete}
        />);
    }
    return (
      <Layout>
        <AppBar
          backButtonOnClick={() => this.props.router.push('/personalinfo')}
          headerNode="Employer"
        />
        <section>
          <p
            style={{ fontSize: 10, color: colors.primaryColor, textAlign: 'left', marginLeft: 15 }}
          >EMPLOYMENT DETAILS
          </p>
        </section>
        <section className="form" style={{ backgroundColor: colors.white, textAlign: 'left' }}>
          <TextField
            label="Name of Employer *"
            classes={{ root: classes.textField }}
            onChange={this.handleChangeName}
            style={baseStyles.marginLeft}
            value={employer.name}
          />
          <Divider />
          <TextField
            label="Employer Number"
            classes={{ root: classes.textField }}
            onChange={this.handleChangePhone}
            style={baseStyles.marginLeft}
            type="tel"
            value={employer.phone_number}
          />
          <Divider />
          <TextField
            label="Address of Employer"
            classes={{ root: classes.textField }}
            onChange={this.handleChangeAddress}
            style={baseStyles.marginLeft}
            value={employer.address}
          />
          <Divider />
          <TextField
            label="City of Employer"
            classes={{ root: classes.textField }}
            onChange={this.handleChangeCity}
            style={baseStyles.marginLeft}
            value={employer.city}
          />
          <Divider />
          <section style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-end' }}>
            <State
              value={employer.state}
              onChange={this.handleState}
            />
            <TextField
              label="Postal Code"
              classes={{ root: classes.textField }}
              onChange={this.handleChangeZip}
              style={baseStyles.marginLeft}
              value={employer.zip_code}
            />
          </section>
          <Divider />
        </section>
        <div style={{ marginTop: 30 }}>
          <Continue text="Save Employer" onClick={this.handleSave} />
          {deleteButton}
        </div>
      </Layout>
    );
  }
}

function mapStateToProps(state, props) {
  const { employer } = state;

  const employerId = get(props, 'location.query.employer_id', null);
  return {
    employer: employer.data[employerId],
  };
}

export default connect(mapStateToProps, {
  browseEmployers,
  editEmployer,
  addEmployer,
  deleteEmployer,
})(withStyles(classStyles)(Employer));
