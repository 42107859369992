import React from 'react';
import {
  LinearProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { get } from 'lodash';

import AppBar from '../components/app-bar';
import Page from './page';
import QuestionLayout from '../layouts/question';
import i18nTranslator, { title, instructions, nextBtn, options } from '../lib/i18next';
import { colors } from '../lib/styles';
import { updatePRO } from '../state/pro-forms';
import { apiFetch, makeShadowOptions } from '../lib/fetch';

const styles = {
  autoComplete: {
    width: 300,
    margin: '20px auto 0 auto',
  },
  autoCompleteInput: {
    fontSize: 16,
  },
  contentWrapper: {
    flexGrow: 1,
    overflowX: 'hidden',
    position: 'relative',
  },
  instructionText: {
    fontSize: '2rem',
    margin: 20,
    textAlign: 'center',
  },
  listItem: {
    background: 'white',
    borderBottom: `1px solid ${colors.primaryHighlight}`,
    textAlign: 'left',
  },
  listItemText: {
    fontSize: '2rem',
  },
  locationText: {
    fontSize: '1.5rem',
    fontStyle: 'italic',
  },
  questionnaireContainer: {
    paddingLeft: 20,
    paddingRight: 20,
  },
};

class WoundCasaColina3 extends Page {
  handleAddNewWound = () => {
    this.goToIndexWithQuery(0, this.props.location.query);
  }

  handleClickNext = () => {
    this.forwardWithQuery(this.props.location.query);
  }

  handleUpdateInput(e, name) {
    if (!name) {
      return this.setState({
        loading: false,
        medicationData: [],
      });
    }
    const options = makeShadowOptions({ name }, this.props.user.token, 'GET');
    this.setState({ loading: true });
    apiFetch('/goodrx', options)
      .then((response) => {
        this.setState({
          loading: false,
          medicationData: get(response, 'data.candidates', []),
        });
      });
  }

  handleRemoveWound(index) {
    const { updatePRO, woundCasaColina } = this.props;

    const wounds = [...woundCasaColina[0]];
    const images = [...woundCasaColina[1]];
    wounds.splice(index, 1);
    images.splice(index, 1);

    updatePRO({
      type: 'woundCasaColina',
      position: 0,
      value: wounds,
    });
  }

  render() {
    const { classes, woundCasaColina } = this.props;

    return (
      <QuestionLayout
        primaryBtnLabel={nextBtn()}
        primaryBtnOnTouchTap={this.handleClickNext}
        style={{ backgroundColor: colors.questionBackground }}
      >
        <AppBar
          headerNode={title('woundCasaColina3')}
          backButtonOnClick={() => this.props.router.goBack()}
        />
        <LinearProgress
          variant="determinate"
          value={(this.getCurrentPage() / this.getTotalPages()) * 100}
          classes={{ bar: classes.bar }}
        />
        <div className={classes.contentWrapper}>
          <div className={classes.instructionText}>
            {instructions('woundCasaColina3')}
          </div>
          <List>
            <ListItem
              button
              onClick={this.handleAddNewWound}
              style={styles.listItem}
            >
              <ListItemIcon>
                <AddIcon style={{ fontSize: 28 }} />
              </ListItemIcon>
              <ListItemText
                primary={i18nTranslator('addImageBtn', 'woundCasaColina3')}
                classes={{
                  primary: classes.listItemText,
                }}
              />
            </ListItem>
            {
              woundCasaColina[0].map((wound, index) => {
                return (
                  <ListItem button style={styles.listItem}>
                    <ListItemIcon />
                    <ListItemText
                      classes={{
                        primary: classes.listItemText,
                      }}
                    >
                      <div>
                        <span>{options(wound.bodyPart, 'woundCasaColina3')}</span> | <span className={classes.locationText}>{wound.location}</span>
                      </div>
                    </ListItemText>
                    <ListItemIcon onClick={() => this.handleRemoveWound(index)}>
                      <RemoveCircleIcon
                        fontSize="large"
                        style={{ color: 'red' }}
                      />
                    </ListItemIcon>
                  </ListItem>
                );
              })
            }
          </List>
        </div>
      </QuestionLayout>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    proForms: {
      woundCasaColina,
    },
  } = state;

  return { woundCasaColina };
};

export default connect(mapStateToProps, { updatePRO })(withStyles(styles)(WoundCasaColina3));
