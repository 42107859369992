import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LinearProgress from '@material-ui/core/LinearProgress';
import { withStyles } from '@material-ui/core/styles';

import AppBar from './app-bar';
import { colors } from '../lib/styles';
import HealthSlider from './health-slider';
import QuestionLayout from '../layouts/question';

const instructions = 'To help people say how good or bad a health state is, '
  + 'we have drawn a scale (rather like a thermometer) on '
  + 'which the best state you can imagine is marked 100 '
  + 'and the worst state you can imagine is marked by 0';

const secondaryInstructions = 'Please Indicate your own health state today';

const {
  lightBrightBlue,
} = colors;

const styles = {
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    overflowX: 'hidden',
  },
  healthSlider: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    height: '100%',
    minHeight: '400px',
  },
  instructions: {
    color: 'grey',
    fontSize: 22,
    margin: '20px 45px',
  },
  linearProgress: {
    borderRadius: 0,
  },
  pageIndicator: {
    color: 'grey',
    display: 'inline-block',
    fontSize: 14,
  },
  pageWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  nextButton: {
    bottom: 30,
    left: 30,
    position: 'absolute',
    right: 30,
  },
  secondaryInstructions: {
    color: 'grey',
    fontSize: 20,
    margin: '0px 45px 20px',
  },
  bar: {
    backgroundColor: lightBrightBlue,
  }
};

class HealthState extends Component {
  render() {
    const {
      buttonLabel,
      backgroundColor,
      currentPage,
      onSubmission,
      title,
      totalPages,
      classes,
      sliderTopLabel,
      sliderBottomLabel,
      primaryBtnDisabled,
      onBack,
      trackIndex,
      value,
      onChange,
      type,
    } = this.props;
    const background = backgroundColor ? { backgroundColor } : {};

    return (
      <QuestionLayout
        primaryBtnLabel={buttonLabel}
        primaryBtnDisabled={primaryBtnDisabled}
        primaryBtnOnTouchTap={onSubmission}
        style={background}
      >
        <div style={styles.pageWrapper}>
          <AppBar
            backButtonOnClick={onBack}
            headerNode={title}
            rightNode={
              <div style={styles.pageIndicator}>{`${currentPage} of ${totalPages}`}</div>
            }
          />
          <LinearProgress
            variant="determinate"
            value={(currentPage / totalPages) * 100}
            classes={{ bar: classes.bar }}
          />
          <div style={styles.contentWrapper}>
            <div style={{ position: 'relative' }} />
            <div style={styles.instructions}>
              {this.props.instructions || instructions}
            </div>
            <div style={styles.secondaryInstructions}>
              {this.props.secondaryInstructions || secondaryInstructions}
            </div>
            <div style={styles.healthSlider}>
              <HealthSlider
                sliderTopLabel={sliderTopLabel}
                sliderBottomLabel={sliderBottomLabel}
                trackIndex={trackIndex}
                value={value}
                onChange={onChange}
                max={100}
                min={1}
                type={type}
              />
            </div>
          </div>
        </div>
      </QuestionLayout>
    );
  }
}

HealthState.defaultProps = {
  buttonLabel: 'Submit',
  backgroundColor: undefined,
  primaryBtnDisabled: false,
  instructions: null,
  secondaryInstructions: null,
  sliderTopLabel: null,
  sliderBottomLabel: null,
  value: undefined,
};

HealthState.propTypes = {
  backgroundColor: PropTypes.string,
  buttonLabel: PropTypes.string,
  currentPage: PropTypes.number.isRequired,
  instructions: PropTypes.string,
  secondaryInstructions: PropTypes.string,
  onBack: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmission: PropTypes.func.isRequired,
  primaryBtnDisabled: PropTypes.bool,
  sliderTopLabel: PropTypes.string,
  sliderBottomLabel: PropTypes.string,
  title: PropTypes.string.isRequired,
  totalPages: PropTypes.number.isRequired,
  trackIndex: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  value: PropTypes.number,
};

export default withStyles(styles)(HealthState);
