import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { floor, round } from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import Divider from '@material-ui/core/Divider';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import rawr from 'rawr';
import transport from 'rawr/transports/worker';

import Page from './page';
import QuestionLayout from '../layouts/question';
import AppBar from '../components/app-bar';
import Continue from '../components/continue';
import { update } from '../state/pro-forms';
import { colors } from '../lib/styles';

const { lightBrightBlue } = colors;

const styles = {
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    overflowX: 'hidden',
    position: 'relative',
    justifyContent: 'space-between',
    backgroundColor: colors.highlightBright,
  },
  bar: {
    backgroundColor: lightBrightBlue,
  },
  timerItem: {
    fontSize: '20px',
    display: 'flex',
    justifyContent: 'space-between',
    width: '80%',
    margin: '5px auto',
    padding: '15px',
  },
  timerLabel: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.white,
    height: '165px',
    width: '165px',
    borderRadius: '50%',
    fontSize: '22px',
  },
  timerHint: {
    fontSize: '15px',
  },
  buttonGroup: {
    display: 'flex',
  },
  divider: {
    width: '88%',
    margin: '0px auto',
    backgroundColor: colors.black,
  },
  timer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '200px',
    width: '200px',
    borderRadius: '50%',
    margin: '20px auto',
  },
  stop: { color: '#CD3636' },
  pause: { color: '#E94E30' },
  play: { color: '#207BCC' },
  finish: { color: '#309940' },
};

const stateSettings = {
  stop: {
    color: '#CD3636',
    hint: 'stopped',
  },
  pause: {
    color: '#E94E30',
    hint: 'paused',
  },
  play: {
    color: '#207BCC',
    hint: 'time left',
  },
  finish: {
    color: '#309940',
    hint: 'complete',
  },
};

let rawrPeer;

class SixMWT3 extends Page {
  constructor(props) {
    super(props);
    this.state = {
      start: 0,
      totalTime: 0,
      timeRemaining: 360000,
      walkTime: 0,
      pauseTime: 0,
      firstPause: 0,
      totalPauseTime: 0,
      pauseSession: 0,
      pauseCount: 0,
      currentState: 'play',
      percent: 0,
      steps: 0,
      workerReady: false,
    };
  }

  componentDidMount() {
    const start = Date.now();
    const { weight, sensitivity } = this.props.proForms;
    const kg = 0.453592 * Number(weight);


    this.setState({ start });
    this.initAccel();

    const webWorker = new Worker('/workers/step-worker.js');
    webWorker.onerror = (err) => {
      console.error('webworker error', err);
    };
    rawrPeer = rawr({ transport: transport(webWorker) });

    rawrPeer.notifications.onready(async () => {
      await rawrPeer.methods.initPedometer(kg, 0, sensitivity);
      this.setState({ workerReady: true });
    });

    rawrPeer.notifications.onstep((count) => {
      if ((count !== this.state.steps) && (this.state.currentState !== 'pause') && (this.state.currentState !== 'finish')) {
        this.setState({ steps: count });
      }
    });
  }

  initAccel() {
    if (window.DeviceOrientationEvent) {
      window.addEventListener('devicemotion', (event) => {
        if (this.state.workerReady && (this.state.currentState !== 'pause') && (this.state.currentState !== 'finish')) {
          rawrPeer.notifiers.addData({
            interval: event.interval,
            accelerationIncludingGravity: {
              x: event.accelerationIncludingGravity.x,
              y: event.accelerationIncludingGravity.y,
              z: event.accelerationIncludingGravity.z,
            }
          });
        }
      }, false);
    }
  }

  handleNext = async () => {
    const { totalPauseTime, pauseCount, walkTime, currentState, firstPause, steps } = this.state;
    const complete = currentState === 'stop' ? 'N' : 'Y';
    await this.props.update({
      complete,
      walkTime,
      totalPauseTime,
      pauseCount,
      firstPause,
      steps,
    });
    this.forwardWithQuery(this.props.location.query);
  }

  parseMilliseconds = (ms) => {
    let minutes = floor(ms / 60000);
    let seconds = round((ms - (minutes * 60000)) / 1000);
    if (seconds === 60) {
      minutes += 1;
      seconds = 0;
    }
    return `${minutes} min ${seconds} sec`;
  };

  updateTime = () => {
    const { pauseSession, totalPauseTime, start, currentState } = this.state;
    if (this.state.currentState === 'finish' || this.state.currentState === 'stop') {
      return;
    }
    if (this.state.walkTime >= 360000) {
      this.setState({ walkTime: 360000, currentState: 'finish', totalTime: 360000 + totalPauseTime, timeRemaining: 0, percent: 100 });
    }
    if (currentState === 'play') {
      const now = Date.now();
      const timeElapsed = now - start;
      const walkTime = timeElapsed - totalPauseTime;
      const percent = floor((walkTime / 360000) * 100);
      const totalTime = timeElapsed;
      const timeRemaining = 360000 - walkTime;
      this.setState({ walkTime, percent, totalTime, timeRemaining });
    }
    if (currentState === 'pause') {
      const now = Date.now();
      const timeElapsed = now - start;
      const totalTime = timeElapsed;
      const pauseTime = now - pauseSession;
      this.setState({ totalTime, pauseTime });
    }
  }

  handlePause = () => {
    const { pauseCount } = this.state;
    const now = Date.now();
    let { firstPause } = this.state;
    if (this.state.pauseCount === 0) { firstPause = now - this.state.start; }
    this.setState({ firstPause, currentState: 'pause', pauseSession: now, pauseCount: pauseCount + 1 });
  }

  handleTimeContinue = () => {
    const { pauseSession, totalPauseTime } = this.state;
    const now = Date.now();
    const pauseTime = now - pauseSession;
    this.setState({ pauseTime: 0, pauseSession: 0, totalPauseTime: totalPauseTime + pauseTime, currentState: 'play' });
  }

  handleStop = () => {
    const { pauseSession, start, totalPauseTime } = this.state;
    const now = Date.now();
    const pauseTime = now - pauseSession;
    const timeElapsed = now - start;
    const walkTime = timeElapsed - totalPauseTime - pauseTime;
    const percent = floor((walkTime / 360000) * 100);
    const totalTime = timeElapsed;
    const timeRemaining = 360000 - walkTime;
    this.setState({
      pauseTime: 0,
      pauseSession: 0,
      totalPauseTime: totalPauseTime + pauseTime,
      currentState: 'stop',
      percent,
      totalTime,
      timeRemaining,
      walkTime,
    });
  }

  render() {
    const { totalTime,
      walkTime,
      pauseTime,
      totalPauseTime,
      pauseCount,
      currentState,
      percent,
      timeRemaining,
    } = this.state;
    setTimeout(this.updateTime, 1000);

    let buttonGroup = (
      <div style={styles.buttonGroup}>
        <Continue
          focused={true}
          text="Pause"
          onClick={this.handlePause}
          btnStyle={{ margin: '10px', backgroundColor: stateSettings.pause.color }}
        />
        <Continue
          text="Stop"
          disabled={true}
          btnStyle={{ margin: '10px', backgroundColor: '#9B9B9B' }}
        />
      </div>
    );

    if (currentState === 'stop' || currentState === 'finish') {
      buttonGroup = (
        <Continue
          focused={true}
          text="Next"
          onClick={this.handleNext}
          btnStyle={{ alignSelf: 'center' }}
        />
      );
    }

    if (currentState === 'pause') {
      buttonGroup = (
        <div style={styles.buttonGroup}>
          <Continue
            text="Continue"
            onClick={this.handleTimeContinue}
            btnStyle={{ margin: '10px', backgroundColor: stateSettings.finish.color }}
          />
          <Continue
            text="Stop"
            onClick={this.handleStop}
            btnStyle={{ margin: '10px', backgroundColor: stateSettings.stop.color }}
          />
        </div>
      );
    }

    return (
      <div style={{ height: '100%' }}>
        <QuestionLayout>
          <AppBar
            headerNode="6 Minute Walking Test"
            noLeftNode={true}
          />
          <LinearProgress
            variant="determinate"
            value={(this.getCurrentPage() / this.getTotalPages()) * 100}
            classes={{ bar: this.props.classes.bar }}
          />
          <div style={styles.contentWrapper}>
            <div style={styles.timer}>
              <Box position="relative" display="inline-flex">
                <CircularProgress classes={{ colorPrimary: this.props.classes[currentState] }} color="primary" variant="static" value={percent} size={200} thickness={40} />
                <Box
                  top={0}
                  left={0}
                  bottom={0}
                  right={0}
                  position="absolute"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Typography style={styles.timerLabel} variant="caption" component="div" color="textSecondary">
                    <span style={{ ...styles.timerHint, color: stateSettings[currentState].color }}>{stateSettings[currentState].hint}</span>
                    <span>{this.parseMilliseconds(timeRemaining)}</span>
                  </Typography>
                </Box>
              </Box>
            </div>
            <div style={{ ...styles.timerItem, fontWeight: '700', backgroundColor: colors.white, borderRadius: '5px' }}><span>Total Time</span><span>{this.parseMilliseconds(totalTime)}</span></div>
            <div style={styles.timerItem}><span>Steps</span><span>{this.state.steps}</span></div>
            <Divider style={styles.divider} />
            <div style={styles.timerItem}><span>Walk Time</span><span>{this.parseMilliseconds(walkTime)}</span></div>
            <Divider style={styles.divider} />
            <div style={styles.timerItem}><span>Pause Time</span><span>{currentState === 'pause' ? this.parseMilliseconds(totalPauseTime + pauseTime) : this.parseMilliseconds(totalPauseTime)}</span></div>
            <Divider style={styles.divider} />
            <div style={styles.timerItem}><span>Pause Count</span><span>{pauseCount}</span></div>
            {buttonGroup}
          </div>
        </QuestionLayout>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { user, proForms } = state;
  return { user, proForms };
}

SixMWT3.proptypes = {
  user: PropTypes.object.isRequired,
  proForms: PropTypes.array.isRequired,
  route: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, { update })(withStyles(styles)(SixMWT3));
