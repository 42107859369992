import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { colors, custom } from '../lib/styles';

const baseStyles = {
  background: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '100vh',
  },
  container: {
    margin: '0 auto',
    width: '80vw',
    maxWidth: '500px',
  },
  headerText: {
    color: colors.white,
    paddingTop: '60px',
    paddingBottom: '25px',
    textAlign: 'center',
  },
  header: {
    paddingBottom: '10px',
  },
  headerImg: {
    height: '180px',
    paddingBottom: '30px',
    width: '180px',
  },
  contentBox: {
    backgroundColor: colors.white,
    height: '350px',
    margin: '0 auto 100px',
    textAlign: 'center',
    width: '500px',
  },
  errorText: {
    alignItems: 'center',
    color: colors.errorRed,
    display: 'flex',
    fontSize: '1.5rem',
    height: '100px',
    justifyContent: 'center',
    marginTop: '5px',
    textAlign: 'center',
  },
  msgText: {
    height: '18px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: colors.errorRed,
    textAlign: 'center',
    fontSize: '1.5rem',
    marginBottom: '10px',
  },
};

export class LoginWrapper extends Component {
  render() {
    return (
      <section style={baseStyles.background}>
        <div style={baseStyles.container}>
          <section>
            <div style={custom.centerWithColumn}>
              <div style={baseStyles.errorText}>
                {this.props.error}
              </div>
              <div style={baseStyles.msgText}>
                {this.props.msg}
              </div>
              {this.props.children}
            </div>
          </section>
        </div>
      </section>
    );
  }
}

LoginWrapper.defaultProps = {
  error: '',
  msg: '',
};

LoginWrapper.propTypes = {
  children: PropTypes.array.isRequired,
  msg: PropTypes.string,
  error: PropTypes.string,
};

export default LoginWrapper;
