const en = {
  pisaInjectionNotificationPage: {
    introHeaderText: 'Login',
    introBodyText: 'requests that you complete the following assessment: PISA-INJECTION SURVEY',
    loggedInSuccessText: 'Thank you for submitting your assessment. This information is important to your care.',
    loggedOutSuccessText: 'Thank you for submitting your assessment. This information is important to your care. You may now close this window.',
  },
};

const es = {
  pisaInjectionNotificationPage: {
    introHeaderText: 'Evaluación De La Educación',
    introBodyText: 'solicita que vea un mensaje importante.',
    loggedInSuccessText: 'Gracias por enviar su cuestionario de calidad de vida relacionada con la salud. Esta información solo se utiliza con fines de investigación para obtener más información sobre la población en riesgo de sufrir complicaciones por heridas en las extremidades inferiores. Si estás experimenta dolor o malestar extremo, comuníquese con su médico de atención primaria o busque atención médica de emergencia.',
    loggedOutSuccessText: 'Gracias por enviar su cuestionario de calidad de vida relacionada con la salud. Esta información solo se utiliza con fines de investigación para obtener más información sobre la población en riesgo de sufrir complicaciones por heridas en las extremidades inferiores. Si estás experimenta dolor o malestar extremo, comuníquese con su médico de atención primaria o busque atención médica de emergencia. Ahora puedes cerrar esta ventana.',
  },
};

export default { en, es };
