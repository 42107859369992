import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import AppBar from './app-bar';
import Layout from '../layouts/common';
import { colors, custom } from '../lib/styles';
import { addUserClinic, editUserClinic } from '../state/patient-clinics';
import Continue from './continue';
import { apiFetch } from '../lib/fetch';

const baseStyles = {
  background: {
    backgroundColor: colors.primaryHighlight,
  },
  btn: {
    height: '50px',
    background: colors.secondaryColor,
  },
  label: {
    fontSize: '1rem',
  },
  link: {
    fontSize: '0.8rem',
  },
  text: {
    margin: '30px 20px',
    textAlign: 'left',
    fontStyle: 'italic',
  },
};

class NotificationClinicShare extends Component {
  handleShare = () => {
    const { clinicId, onAddUserClinic, onEditUserClinic, onDismissNotification, userId } = this.props;
    onAddUserClinic(clinicId, true)
      .catch((e) => {
        if (e.status === 422) {
          return onEditUserClinic({ clinic_id: clinicId, allow_data_access: true });
        }
        throw e;
      })
      .then(() => {
        const invitePayload = {
          method: 'PUT',
          body: {
            status: 'ACCEPTED',
          },
        };
        return apiFetch(`/clinics/${clinicId}/users/${userId}/invitations`, invitePayload);
      })
      .then(() => {
        return onDismissNotification();
      });
  }

  handleDismiss = () => {
    const { userId, clinicId, onDismissNotification } = this.props;
    const invitePayload = {
      method: 'PUT',
      body: {
        status: 'DECLINED',
      },
    };
    return apiFetch(`/clinics/${clinicId}/users/${userId}/invitations`, invitePayload)
      .then(() => onDismissNotification());
  }

  render() {
    return (
      <Layout>
        <div style={baseStyles.background}>
          <AppBar
            backButtonOnClick={() => this.props.router.push('/notifications')}
            headerNode="Clinic"
          />
          <h3 style={custom.blueText}>Share your health information with {this.props.clinicName} now</h3>
          <p style={baseStyles.text}>
            Sharing your health information with your clinics is critical in helping them provide
            the best care. Your clinics can always view your information on the day of the appointment,
            but by sharing it now, they will be better prepared to assist you on the day of your appointment.
          </p>
          <div style={custom.centerWithColumn}>
            <Continue
              text="Share My Data Now"
              btnStyle={baseStyles.btn}
              labelStyle={baseStyles.label}
              onClick={this.handleShare}
            />
            <p onClick={this.handleDismiss} style={custom.loginBottomText}>
                Continue without sharing my health information
            </p>
          </div>
        </div>
      </Layout>
    );
  }
}

function mapStateToProps() {
  return {};
}
NotificationClinicShare.propTypes = {
  clinicId: PropTypes.number.isRequired,
  userId: PropTypes.string.isRequired,
  onAddUserClinic: PropTypes.func.isRequired,
  onEditUserClinic: PropTypes.func.isRequired,
  onDismissNotification: PropTypes.func.isRequired,

};
export default connect(mapStateToProps, {
  onAddUserClinic: addUserClinic,
  onEditUserClinic: editUserClinic,
})(NotificationClinicShare);
