import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { MenuItem, Select } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { assign, forEach, noop } from 'lodash';

import { state } from '../lib/i18next';

import { stateAbbrevs } from '../lib/state-abbrev';
import { colors } from '../lib/styles';

const baseStyles = {
  select: {
    color: colors.primaryColor,
    fontSize: 18,
    width: 110,
  },
  selectWrapper: {
    '& .MuiInput-underline:before': {
      borderBottom: 'none',
    },
  },
};

const items = [];
forEach(stateAbbrevs, (stateAbbrev) => {
  items.push(<MenuItem name={stateAbbrev} value={stateAbbrev} key={stateAbbrev}>{stateAbbrev}</MenuItem>);
});

class State extends Component {
  render() {
    const selectStyle = assign({}, baseStyles.select, this.props.style);
    return (
      <div className={this.props.classes.selectWrapper}>
        <Select
          value={this.props.value}
          onChange={this.props.onChange}
          style={selectStyle}
        >
          <MenuItem value="none">{state()}</MenuItem>
          {items}
        </Select>
      </div>
    );
  }
}

State.defaultProps = {
  value: 'none', // State Abbreviation ie 'AZ'
  onChange: noop,
  style: {},
};

State.propTypes = {
  classes: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  style: PropTypes.object,
  value: PropTypes.string,
};

export default withStyles(baseStyles)(State);
