import React from 'react';
import { LinearProgress } from '@material-ui/core';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import AppBar from '../components/app-bar';
import Page from './page';
import QuestionLayout from '../layouts/question';
import YesNoButtonSet from '../components/yes-no-button-set';
import { colors, fontSizing } from '../lib/styles';
import { updatePRO } from '../state/pro-forms';
import { Spo2 } from '../lib/icons';
import CenteredIcon from '../components/centered-icon';

const styles = {
  bodyText: {
    fontSize: fontSizing.body,
  },
  breathingText: {
    fontSize: '2rem',
    fontWeight: 'bold',
  },
  inputField: {
    width: '6rem',
    '& input': {
      textAlign: 'center',
    },
  },
  pageContent: {
    fontSize: fontSizing.body,
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 20,
  },
};

class RespiratoryOncology2 extends Page {
  constructor(props) {
    super(props);

    this.state = {
      coughIsNew: null,
      coughWorseThanYesterday: null,
      hasCough: null,
    };
  }

  handleYesNoChange = (field, value) => {
    this.setState({ [field]: value });
  }

  handleOxygenSaturation = (e) => {
    this.setState({ oxygenSaturation: e.target.value });
  }

  handleClickNext = () => {
    const {
      coughIsNew,
      coughWorseThanYesterday,
      hasCough,
    } = this.state;

    const value = { hasCough };

    if (hasCough) {
      value.coughIsNew = coughIsNew;
      value.coughWorseThanYesterday = coughWorseThanYesterday;
    }

    this.props.updatePRO({
      type: 'respiratoryOncology',
      position: this.getTrackIndex(),
      value,
    });

    this.forwardWithQuery(this.props.location.query);
  }

  render() {
    const { classes } = this.props;
    const {
      coughIsNew,
      coughWorseThanYesterday,
      hasCough,
    } = this.state;

    const continueDisabled = hasCough === null
      || (hasCough === 'Y' && coughIsNew === null)
      || (hasCough === 'Y' && coughWorseThanYesterday === null);

    return (
      <QuestionLayout
        primaryBtnLabel="Next"
        primaryBtnOnTouchTap={this.handleClickNext}
        primaryBtnDisabled={continueDisabled}
        style={{ backgroundColor: colors.questionBackground }}
      >
        <AppBar
          headerNode="Respiratory"
          backButtonOnClick={() => this.props.router.goBack()}
        />
        <LinearProgress
          variant="determinate"
          value={(this.getCurrentPage() / this.getTotalPages()) * 100}
          classes={{ bar: classes.bar }}
        />
        <div className={classes.pageContent}>
          <CenteredIcon>
            <Spo2 />
          </CenteredIcon>
          <YesNoButtonSet
            isYes={hasCough === 'Y'}
            isNo={hasCough === 'N'}
            onClickNo={() => this.handleYesNoChange('hasCough', 'N')}
            onClickYes={() => this.handleYesNoChange('hasCough', 'Y')}
            questionText="Do you have a cough?"
          />
          {hasCough === 'Y' ? (
            <>
              <YesNoButtonSet
                isYes={coughIsNew === 'Y'}
                isNo={coughIsNew === 'N'}
                onClickNo={() => this.handleYesNoChange('coughIsNew', 'N')}
                onClickYes={() => this.handleYesNoChange('coughIsNew', 'Y')}
                questionText="Is this new?"
              />
              <YesNoButtonSet
                isYes={coughWorseThanYesterday === 'Y'}
                isNo={coughWorseThanYesterday === 'N'}
                onClickNo={() => this.handleYesNoChange('coughWorseThanYesterday', 'N')}
                onClickYes={() => this.handleYesNoChange('coughWorseThanYesterday', 'Y')}
                questionText="Is this worse than yesterday?"
              />
             </>
          ) : null}
        </div>
      </QuestionLayout>
    );
  }
}

export default connect(null, { updatePRO })(withStyles(styles)(RespiratoryOncology2));
