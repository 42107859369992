import React from 'react';
import { LinearProgress, TextField } from '@material-ui/core';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import AppBar from '../components/app-bar';
import Page from './page';
import QuestionLayout from '../layouts/question';
import { colors } from '../lib/styles';
import { updatePRO } from '../state/pro-forms';
import { WeightScaleSolid } from '../lib/icons';

const styles = {
  contentWrapper: {
    flexGrow: 1,
    overflowX: 'hidden',
    position: 'relative',
  },
  imgRow: {
    display: 'flex',
    justifyContent: 'center',
    padding: '15px 60px',
  },
  imageWrapper: {
    width: 100,
  },
  inputField: {
    width: '6rem',
    '& input': {
      textAlign: 'center',
    },
  },
  marginRight40: {
    marginRight: 40,
  },
  marginTop40: {
    marginTop: 40,
  },
  questionnaireContainer: {
    paddingLeft: 20,
    paddingRight: 20,
  },
  questionText: {
    fontSize: '2rem',
  },
  textFieldContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  textFieldRow: {
    display: 'flex',
  },
  textFieldWrapper: {
    alignItems: 'center',
    display: 'flex',
    paddingTop: 20,
  },
  unitsWrapper: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    marginLeft: 5,
  },
};

class HypertensionAssessment4 extends Page {
  constructor(props) {
    super(props);

    this.state = {
      feet: '',
      inches: '',
      weight: '',
    };
  }

  handleChangeFeet = (e) => {
    this.setState({ feet: e.target.value });
  };

  handleChangeInches = (e) => {
    this.setState({ inches: e.target.value });
  }

  handleChangeWeight = (e) => {
    this.setState({ weight: e.target.value });
  };

  handleClickNext = () => {
    const {
      feet,
      inches,
      weight,
    } = this.state;

    this.props.updatePRO({
      type: 'hypertensionAssessment',
      position: this.getTrackIndex(),
      value: {
        feet,
        inches,
        weight,
      },
    });

    this.forwardWithQuery(this.props.location.query);
  }

  render() {
    const { classes } = this.props;
    const {
      feet,
      inches,
      weight,
    } = this.state;

    const continueDisabled = !feet || !inches || !weight;

    return (
      <QuestionLayout
        primaryBtnLabel="Next"
        primaryBtnOnTouchTap={this.handleClickNext}
        primaryBtnDisabled={continueDisabled}
        style={{ backgroundColor: colors.questionBackground }}
      >
        <AppBar
          headerNode="Measurements"
          backButtonOnClick={() => this.props.router.goBack()}
        />
        <LinearProgress
          variant="determinate"
          value={(this.getCurrentPage() / this.getTotalPages()) * 100}
          classes={{ bar: classes.bar }}
        />
        <div className={classes.contentWrapper}>
          <div className={classes.imgRow}>
            <div className={classes.imageWrapper}>
              <WeightScaleSolid />
            </div>
          </div>
          <div className={classes.questionnaireContainer}>
            <div className={`${classes.questionText} ${classes.marginTop40}`}>What is your height?</div>
            <div className={classes.textFieldContainer}>
              <div className={classes.textFieldRow}>
                <div className={`${classes.textFieldWrapper} ${classes.marginRight40}`}>
                  <TextField
                    className={classes.inputField}
                    onChange={this.handleChangeFeet}
                    type="number"
                    inputProps={{
                      inputMode: 'decimal',
                      pattern: '[0-9]*',
                      type: 'tel',
                      style: { fontSize: '16px' } }}
                    value={feet}
                    placeholder="0"
                  />
                  <span className={classes.unitsWrapper}>ft</span>
                </div>
                <div className={classes.textFieldWrapper}>
                  <TextField
                    className={classes.inputField}
                    onChange={this.handleChangeInches}
                    type="number"
                    inputProps={{
                      inputMode: 'decimal',
                      pattern: '[0-9]*',
                      type: 'tel',
                      style: { fontSize: '16px' } }}
                    value={inches}
                    placeholder="0"
                  />
                  <span className={classes.unitsWrapper}>in</span>
                </div>
              </div>
            </div>
            <div className={classes.questionText}>What is your weight today?</div>
            <div className={classes.textFieldContainer}>
              <div className={classes.textFieldWrapper}>
                <TextField
                  className={classes.inputField}
                  onChange={this.handleChangeWeight}
                  type="number"
                  inputProps={{
                    inputMode: 'decimal',
                    pattern: '[0-9]*',
                    type: 'tel',
                    style: { fontSize: '16px' } }}
                  value={weight}
                  placeholder="0"
                />
                <span className={classes.unitsWrapper}>lbs</span>
              </div>
            </div>
          </div>
        </div>
      </QuestionLayout>
    );
  }
}

export default connect(null, { updatePRO })(withStyles(styles)(HypertensionAssessment4));
