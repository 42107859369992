import React from 'react';
import { LinearProgress, TextField } from '@material-ui/core';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMessage } from '@fortawesome/free-regular-svg-icons';

import AppBar from '../components/app-bar';
import Page from './page';
import QuestionLayout from '../layouts/question';
import YesNoButtonSet from '../components/yes-no-button-set';
import { colors, fontSizing } from '../lib/styles';
import { updatePRO } from '../state/pro-forms';

const styles = {
  pageContent: {
    fontSize: fontSizing.body,
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 20,
  },
  iconWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 20,
  },
  instructionsText: {
    fontWeight: 'bold',
  },
  questionText: {
    marginTop: 25,
  },
};

class HumanFactorsForIvcApp2 extends Page {
  constructor(props) {
    super(props);

    this.state = {
      encounteredProblems: null,
      specificProblem: '',
    };
  }

  handleClickNext = () => {
    const { encounteredProblems, specificProblem } = this.state;

    const value = { encounteredProblems };

    if (encounteredProblems === 'Y' && specificProblem) {
      value.specificProblem = specificProblem;
    }

    this.props.updatePRO({
      type: 'humanFactorsForIvcApp',
      position: this.getTrackIndex(),
      value,
    });

    this.forwardWithQuery(this.props.location.query);
  };

  handleChangeEncounteredProblems = (value) => {
    this.setState({ encounteredProblems: value });
  };

  handleChangeSpecificProblem = (e) => {
    this.setState({ specificProblem: e.target.value });
  }

  render() {
    const { classes } = this.props;
    const { encounteredProblems, specificProblem } = this.state;

    const continueDisabled = encounteredProblems === null;

    return (
      <QuestionLayout
        primaryBtnLabel="Next"
        primaryBtnOnTouchTap={this.handleClickNext}
        primaryBtnDisabled={continueDisabled}
        style={{ backgroundColor: colors.questionBackground }}
      >
        <AppBar
          headerNode="Feedback"
          backButtonOnClick={() => this.props.router.goBack()}
        />
        <LinearProgress
          variant="determinate"
          value={(this.getCurrentPage() / this.getTotalPages()) * 100}
        />
        <div className={classes.pageContent}>
          <div className={classes.iconWrapper}>
            <FontAwesomeIcon icon={faMessage} size="5x" />
          </div>
          <div className={classes.instructionsText}>
            Please provide feedback on your experience using the app.
          </div>
          <YesNoButtonSet
            isYes={encounteredProblems === 'Y'}
            isNo={encounteredProblems === 'N'}
            onClickNo={() => this.handleChangeEncounteredProblems('N')}
            onClickYes={() => this.handleChangeEncounteredProblems('Y')}
            questionText="Did you encounter any problems using Informed Vital Core?"
          />
          {encounteredProblems === 'Y' ? (
            <>
              <div className={classes.questionText}>What specific problem did you encounter?</div>
              <TextField
                placeholder="tap here to enter"
                onChange={this.handleChangeSpecificProblem}
                classes={{ root: classes.textFieldRoot }}
                value={specificProblem}
                fullWidth
                multiline
              />
            </>
          ) : null}
        </div>
      </QuestionLayout>
    );
  }
}

export default connect(null, { updatePRO })(withStyles(styles)(HumanFactorsForIvcApp2));
