import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';

import { withStyles } from '@material-ui/core/styles';

import AppBar from '../components/app-bar';
import Continue from '../components/continue';

import { WaterDrop } from '../lib/icons';

import Page from './page';

import { updatePRO } from '../state/pro-forms';

const styles = {
  button: {
    margin: 5,
    width: 150,
    height: 40,
    fontSize: 16,
  },
  buttonGroup: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  formControl: {
    textAlign: 'center',
  },
  formControlItem: {
    fontSize: '22px',
    textAlign: 'center',
    margin: '1rem',
  },
  iconContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    flexGrow: 0,
    flexShrink: 0,
    width: '100%',
    color: '#000',
    paddingTop: 10,
  },
  iconWrapper: {
    width: 100,
    marginTop: 10,
  },
  instructions: {
    fontSize: '1.3rem',
  },
  mainContainer: {
    position: 'relative',
    minHeight: '100vh',
    marginBottom: '50px',
  },
  titleGroup: {
    margin: 25,
  },
  text: {
    fontSize: '22px',
  },
  nextBtn: {
    textAlign: 'center',
    width: '100%',
    bottom: 0,
    position: 'absolute',
    height: '8rem',
  },
  wrapper: {
    paddingBottom: '8rem',
  },
};

const StyledInput = withStyles(() => ({
  input: {
    textAlign: 'center',
    fontSize: '22px',
  },
}))(Input);

class Diabetes13 extends Page {
  constructor(props) {
    super(props);

    this.state = {
      routineBloodSugarChecks: null,
      averageBloodSugarLevel: null,
    };

    this.handleContinue = this.handleContinue.bind(this);
  }


  handleBloodSugar = (e) => {
    const { value } = e.target;
    this.setState({
      averageBloodSugarLevel: value,
    });
  };

  handleContinue() {
    this.props.updatePRO({
      type: 'diabetesInitial',
      position: this.getTrackIndex(),
      value: this.state });
    this.forwardWithQuery(this.props.location.query);
  }


  render() {
    const { routineBloodSugarChecks, averageBloodSugarLevel } = this.state;

    let disabled;

    if (routineBloodSugarChecks === true && averageBloodSugarLevel > 0) {
      disabled = false;
    } else if (routineBloodSugarChecks === false) {
      disabled = false;
    } else {
      disabled = true;
    }

    return (
      <div style={styles.mainContainer}>
        <AppBar
          headerNode="Blood Sugar"
          noLeftNode={true}
        />
        <LinearProgress
          variant="determinate"
          value={(this.getCurrentPage() / this.getTotalPages()) * 100}
        />
        <div style={styles.iconContainer}>
          <div style={styles.iconWrapper}>
            <WaterDrop />
          </div>
        </div>
        <div style={styles.wrapper}>
          <div style={styles.titleGroup}>
            <p style={styles.text}>Do you check your blood sugar routinely?</p>
            <div style={styles.buttonGroup}>
              <Button style={styles.button} variant="contained" color={routineBloodSugarChecks ? 'primary' : 'default'} onClick={() => this.setState({ routineBloodSugarChecks: true })}>Yes</Button>
              <Button style={styles.button} variant="contained" color={routineBloodSugarChecks === false ? 'primary' : 'default'} onClick={() => this.setState({ routineBloodSugarChecks: false, averageBloodSugarLevel: '' })}>No</Button>
            </div>
          </div>
          <div className={`${routineBloodSugarChecks ? '' : 'hidden'}`} style={styles.titleGroup}>
            <p style={styles.text}>What is your average blood sugar level?</p>
            <div style={styles.formControl}>
              <FormControl style={styles.formControlItem}>
                <StyledInput
                  id="averageBloodSugarLevel"
                  type="tel"
                  value={averageBloodSugarLevel}
                  name="averageBloodSugarLevel"
                  placeholder="00"
                  onChange={this.handleBloodSugar}
                  inputProps={{
                    'aria-label': 'averageBloodSugarLevel',
                  }}
                />
              </FormControl>
              <Typography style={styles.text}>mg/dL</Typography>
            </div>
          </div>
        </div>

        <div style={styles.nextBtn}>
          <Continue
            disabled={disabled}
            text="Next"
            onClick={this.handleContinue}
          />
        </div>

      </div>
    );
  }
}

function mapStateToProps(state) {
  const { user, proForms: { diabetesInitial } } = state;
  return { user, diabetesInitial };
}

Diabetes13.proptypes = {
  user: PropTypes.object.isRequired,
  route: PropTypes.object.isRequired,
  updatePRO: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { updatePRO })(Diabetes13);
