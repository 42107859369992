import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@material-ui/core';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

import { calendarHeaderColors } from '../lib/styles';

const { text } = calendarHeaderColors;

const styles = {
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '15px 0',
  },
  headerTitle: {
    alignItems: 'center',
    color: text,
    display: 'flex',
  },
};

const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

class CalendarHeader extends Component {
  render() {
    const {
      month,
      onClickBackButton,
      onClickForwardButton,
      year,
    } = this.props;

    return (
      <div style={styles.header}>
        <IconButton
          onClick={onClickBackButton}
        >
          <KeyboardArrowLeftIcon />
        </IconButton>
        <div style={styles.headerTitle}>
          {monthNames[month]} {year}
        </div>
        <IconButton
          onClick={onClickForwardButton}
        >
          <KeyboardArrowRightIcon />
        </IconButton>
      </div>
    );
  }
}

CalendarHeader.propTypes = {
  month: PropTypes.number.isRequired,
  onClickBackButton: PropTypes.func.isRequired,
  onClickForwardButton: PropTypes.func.isRequired,
  year: PropTypes.number.isRequired,
};

export default CalendarHeader;
