import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get } from 'lodash';

import HealthState from '../components/health-state';
import Page from './page';
import { updatePRO, submitPRO } from '../state/pro-forms';

class EqHealthState extends Page {
  state = {
    submitting: false,
  }

  onSubmission = () => {
    if (this.state.submitting) return;
    const { user } = this.props;
    const { id } = this.props.location.query;
    this.setState({ submitting: true });

    this.props.submitPRO({ userId: user.id, id }, {
      pro_type: 'EQ5D-5L',
      pro_data: { data: this.props.eq5d5l },
    }, user)
      .then(() => this.forward())
      .catch(() => this.setState({ submitting: false }));
  }

  render() {
    const value = get(this.props.eq5d5l, this.getTrackIndex(), null);
    return (
      <HealthState
        currentPage={this.getCurrentPage()}
        onBack={() => this.props.router.goBack()}
        onChange={this.props.updatePRO}
        onSubmission={this.onSubmission}
        title="Your Health State Today"
        primaryBtnDisabled={this.state.submitting}
        totalPages={this.getTotalPages()}
        type="eq5d5l"
        value={value || 100}
        trackIndex={this.getTrackIndex()}
        instructions={
          'Please use the slider to rate your health TODAY with '
          + '100 being the best health you can imagine, and 0 '
          + 'being the worst health you can imagine.'
        }
      />
    );
  }
}

function mapStateToProps(state) {
  const {
    user,
    proForms: {
      eq5d5l,
    },
  } = state;
  return {
    user,
    eq5d5l,
  };
}

EqHealthState.proptypes = {
  eq5d5l: PropTypes.array.isRequired,
  updatePRO: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { updatePRO, submitPRO })(EqHealthState);
