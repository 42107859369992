import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { LinearProgress } from '@material-ui/core';
import Slider from '@material-ui/core/Slider';
import { withStyles } from '@material-ui/core/styles';
import AppBar from './app-bar';
import QuestionLayout from '../layouts/question';
import '../index.css';

const instructions = 'How would you rate your pain on average?';

const styles = {
  footerRight: {
    fontSize: '3vw',
    color: 'grey',
    textAlign: 'right',
    position: 'relative',
    height: '4vw',
    width: '25vw',
    left: '68vw',
    top: '-38vw',
  },
  footerleft: {
    fontSize: '3vw',
    color: 'grey',
    textAlign: 'left',
    position: 'relative',
    height: '4vw',
    width: '25vw',
    left: '6vw',
    top: '-34vw',
  },
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    overflowX: 'hidden',
  },
  h1: {
    backgroundColor: '#000000',
  },
  instructionsBottom: {
    fontSize: '4.5vw',
  },
  instructions: {
    fontSize: 13,
    margin: '1vw 1vw',
    textAlign: 'center',
  },
  linearProgress: {
    borderRadius: 0,
  },
  pageIndicator: {
    color: 'grey',
    display: 'inline-block',
    fontSize: 13,
  },
  pageWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  nextButton: {
    alignItems: 'center',
    botom: '0vw',
  },
  secondaryInstructions: {
    backgroundColor: 'grey',
    fontSize: 13,
    margin: '0vw 45vw 20vw',
    textAlign: 'center',
  },
};

const PrettySlider = withStyles({
  root: {
    color: '#3EBCF9',
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    marginTop: -8,
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
  mark: {
    backgroundColor: '#bfbfbf',
    height: 25,
    width: 5,
    marginTop: -8,
  },
  markActive: {
    opacity: 1,
    backgroundColor: 'currentColor',
  },
})(Slider);

function ThumbComponent(props) {
  // eslint-disable-next-line react/destructuring-assignment
  const val = props['aria-valuenow'];
  return (
    <span {...props}>
      {val}
    </span>
  );
}

class HorizontalSlider extends Component {
  onClickBackButton = () => {
    this.backward();
  }

  handleShowHorizontal = () => {
    const { value } = this.props;

    return value;
  }

  render() {
    const {
      currentPage,
      onBack,
      onSubmission,
      title,
      totalPages,
      classes,
      value,
    } = this.props;

    return (
      <QuestionLayout
        primaryBtnLabel="Next"
        primaryBtnOnTouchTap={onSubmission}
        primaryBtnStyle={styles.nextButton}
      >
        <div className={classes.root}>
          <div style={styles.pageWrapper}>
            <AppBar
              backButtonOnClick={onBack}
              headerNode={title}
              rightNode={
                <div style={styles.pageIndicator}>{`${currentPage} of ${totalPages}`}</div>
              }
            />
            <LinearProgress
              variant="determinate"
              value={(currentPage / totalPages) * 100}
              classes={{ bar: classes.bar }}
            />
            <div style={styles.contentWrapper} />
            <div style={{ position: 'relative' }} />
            <div style={styles.instructions}>
              <h2>{this.props.description || 'IN THE PAST 7 DAYS'}</h2>
              <div style={styles.instructionsBottom}>
                {this.props.instructions || instructions}
              </div>
            </div>
            <br />
            <div className="custom-rangeslider">
              <PrettySlider value={value} onChange={this.props.onChange} min={0} max={10} defaultValue={0} marks ThumbComponent={ThumbComponent} />
            </div>
          </div>
        </div>
        <div style={styles.footerleft}>
          <p>0</p>
          <p>{this.props.lowLabel || 'No Pain'}</p>
        </div>
        <div style={styles.footerRight}>
          <p>10</p>
          <p>{this.props.highLabel || 'Worst Imaginable Pain'}</p>
        </div>
      </QuestionLayout>
    );
  }
}
HorizontalSlider.defaultProps = {
  primaryBtnDisabled: false,
}

HorizontalSlider.propTypes = {
  currentPage: PropTypes.number.isRequired,
  onBack: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmission: PropTypes.func.isRequired,
  primaryBtnDisabled: PropTypes.bool,
  title: PropTypes.string.isRequired,
  totalPages: PropTypes.number.isRequired,
  classes: PropTypes.object.isRequired,
  value: PropTypes.number.isRequired,
};

export default withStyles(styles)(HorizontalSlider);
