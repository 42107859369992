import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  IconButton,
  TextareaAutosize,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import { connect } from 'react-redux';


import Page from './page';
import AppBar from '../components/app-bar';
import IconWrapper from '../components/icon-wrapper';
import BloomkidzLayout from '../layouts/bloomkidz';
import { SchoolBuilding, XInCircle } from '../lib/icons';
import { fontSizing } from '../lib/styles';
import { submitPRO } from '../state/pro-forms';
import { bloomkidzStyles, bloomkidzStylesObj } from '../lib/bloomkidz';

const styles = {
  ...bloomkidzStylesObj,
  scheduleButton: {
    marginBottom: 30,
    marginTop: 10,
  },
  scheduleListText: {
    marginBottom: 10,
  },
  scheduleListItem: {
    alignItems: 'center',
    background: 'white',
    display: 'flex',
    marginBottom: 10,
    marginTop: 10,
  },
  scheduleListItemText: {
    flexGrow: 1,
    paddingLeft: 10,
  },
  iconButton: {
    height: 50,
    width: 50,
  },
  scheduleLabel: {
    alignItems: 'end',
    display: 'flex',
    width: '100%',
    '& > div > div': {
      margin: 0,
    },
  },
  date: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
    color: '#9c9c9c',
  },
  keypadDateWrapper: {
    marginTop: 10,
    '& > label > div': {
      flexGrow: 1,
    },
    '& > label > div > div': {
      margin: 0,
      width: '100%',
    },
    '& input': {
      margin: 0,
      fontFamily: bloomkidzStyles.fontFamily,
      fontSize: `${fontSizing.body}px !important`,
    },
  },
  formControl: {
    minWidth: 150,
  },
};

class BloomkidzMedicalHistory2 extends Page {
  constructor(props) {
    super(props);

    const stateFromRedux = props.bloomkidzMedicalHistory[this.getTrackIndex()] || {};

    this.state = {
      schedule: [],
      curDay: {
        day: '',
        elaboration: '',
      },
      ...stateFromRedux,
    };
  }

  handleChangeDate = (e) => {
    const day = e.target.value;
    this.setState((curState) => {
      return {
        curDay: {
          ...curState.curDay,
          day,
        },
      };
    });
  }

  handleTextAreaChange = (e) => {
    const elaboration = e.target.value;
    this.setState((curState) => {
      return {
        curDay: {
          ...curState.curDay,
          elaboration,
        },
      };
    });
  }

  handleContinue = () => {
    const {
      bloomkidzMedicalHistory,
      location,
      submitPRO,
      user,
    } = this.props;
    const { id } = location.query;

    submitPRO({ userId: user.id, id }, {
      pro_type: 'BLOOMKIDZ-MEDICAL-HISTORY',
      pro_data: {
        data: {
          ...this.getDataMap(bloomkidzMedicalHistory),
          ...this.state,
        },
        recorded_at: new Date().toISOString(),
      },
    }, user)
      .then(() => {
        this.forwardWithQuery(this.props.location.query);
      });
  }

  addSchedule = () => {
    const { schedule, curDay } = this.state;
    if (!curDay.day || !curDay.elaboration) {
      return;
    }
    if (curDay) {
      this.setState({
        schedule: [...schedule, curDay],
        curDay: { day: '', elaboration: '' },
      });
    }
  }

  removeSchedule = (day) => {
    this.setState(curState => ({
      schedule: curState.schedule.filter(curDay => curDay !== day),
    }));
  }

  render() {
    const { classes, router } = this.props;
    const { schedule, curDay } = this.state;

    const buttonClass = curDay
      ? `${classes.buttonGreenBackground} ${classes.scheduleButton}`
      : `${classes.buttonWithBorder} ${classes.scheduleButton}`;

    return (
      <BloomkidzLayout
        primaryBtnOnTouchTap={this.handleContinue}
        primaryBtnLabel={schedule.length ? 'Next' : 'Skip'}
      >
        <AppBar
          headerNode="Child&apos;s Educational History"
          backButtonOnClick={() => router.goBack()}
        />
        <IconWrapper>
          <SchoolBuilding />
        </IconWrapper>
        <div className={classes.pageContent}>
          <div>What is your child&apos;s current school schedule?</div>
          <div className={classes.paddingHorizontal}>
            <FormControl className={classes.formControl}>
              <InputLabel id="weekday">Select Date</InputLabel>
              <Select
                labelId="weekday"
                id="weekdaySelect"
                value={curDay.day}
                onChange={this.handleChangeDate}
              >
                <MenuItem value="Monday">Monday</MenuItem>
                <MenuItem value="Tuesday">Tuesday</MenuItem>
                <MenuItem value="Wednesday">Wednesday</MenuItem>
                <MenuItem value="Thursday">Thursday</MenuItem>
                <MenuItem value="Friday">Friday</MenuItem>
                <MenuItem value="Saturday">Saturday</MenuItem>
                <MenuItem value="Sunday">Sunday</MenuItem>
              </Select>
            </FormControl>
            <TextareaAutosize
              value={curDay.elaboration}
              onChange={this.handleTextAreaChange}
              minRows={10}
              className={classes.textAreaAutosize}
              placeholder="Tap here to type"
            />
          </div>
          <button
            className={buttonClass}
            onClick={this.addSchedule}
            type="button"
          >
            + Add Schedule
          </button>
          <div className={classes.scheduleListText}>
            Schedule List
          </div>
          {schedule.length ? (
            <div className={classes.paddingHorizontal}>
              {schedule.map(day => (
                <div className={classes.scheduleListItem}>
                  <div className={classes.scheduleListItemText} key={day.day}>{`${day.day} | ${day.elaboration.slice(0, 25)}...`}</div>
                  <IconButton
                    className={classes.iconButton}
                    onClick={() => this.removeSchedule(day)}
                  >
                    <XInCircle />
                  </IconButton>
                </div>
              ))}
            </div>
          ) : (
            <div className={classes.greyText}><i>No Schedule</i></div>
          )}
        </div>
      </BloomkidzLayout>
    );
  }
}

BloomkidzMedicalHistory2.propTypes = {
  classes: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  submitPRO: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const {
    proForms: { bloomkidzMedicalHistory },
    user,
  } = state;

  return { user, bloomkidzMedicalHistory };
};

export default connect(mapStateToProps, { submitPRO })(withStyles(styles)(BloomkidzMedicalHistory2));
