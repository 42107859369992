import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ListItem, ListItemSecondaryAction, ListItemText } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import Checkbox from './round-checkbox';

import { colors } from '../lib/styles';

const baseStyles = {
  text: {
    color: colors.primaryColor,
  },
  itemStyles: {
    color: colors.black,
    fontSize: '1.5rem',
    textAlign: 'left',
    textTransform: 'uppercase',
  },
};

class SimpleListItem extends Component {
  constructor(props) {
    super(props);
    this.handleCheck = this.handleCheck.bind(this);
  }

  handleCheck() {
    const { checked, item } = this.props;
    if (checked) {
      return this.props.onRemoveItem(item.id, false, item.name);
    }

    return this.props.onAddItem(item.id, true, item.name);
  }

  render() {
    const { classes, item, checked } = this.props;
    return (
      <ListItem
        key={`checklist-${item.id}`}
      >
        <ListItemText primary={item.name} classes={{ primary: classes.itemStyles }} />
        <ListItemSecondaryAction>
          <Checkbox
            checked={checked}
            onChange={this.handleCheck}
          />
        </ListItemSecondaryAction>
      </ListItem>
    );
  }
}

SimpleListItem.defaultProps = {
  checked: false,
};

SimpleListItem.propTypes = {
  classes: PropTypes.object.isRequired,
  onAddItem: PropTypes.func.isRequired,
  onRemoveItem: PropTypes.func.isRequired,
  checked: PropTypes.bool,
  item: PropTypes.object.isRequired,
};

export default withStyles(baseStyles)(SimpleListItem);
