import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { TextField } from '@material-ui/core';

import Page from './page';
import AppBar from '../components/app-bar';
import BloomkidzLayout from '../layouts/bloomkidz';
import { updatePRO } from '../state/pro-forms';
import { bloomkidzStylesObj, bloomkidzStyles } from '../lib/bloomkidz';
import { fontSizing } from '../lib/styles';

const styles = {
  ...bloomkidzStylesObj,
  centerText: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
  },
  emphasis: {
    fontWeight: 600,
  },
  paragraph: {
    textAlign: 'left',
    width: '90vw',
    maxWidth: '700px',
    height: '80vh',
    fontSize: '16px',
    margin: '20px auto',
    backgroundColor: '#FFF',
    overflowY: 'scroll',
    boxShadow: 'inset 1px 1px 5px #9B9B9B',
    padding: '10px',
    borderRadius: '2px',
    display: 'flex',
    flexDirection: 'column',
  },
};

class BloomkidzSignatures7 extends Page {
  constructor(props) {
    super(props);

    const stateFromRedux = props.bloomkidzSignatures[this.getTrackIndex()] || {};

    this.state = {
      formBottom: false,
      consentGuardian1: '',
      ...stateFromRedux,
    };
  }

  handleScroll = (e) => {
    if (e.target.scrollTop / e.target.scrollHeight > 0.90) {
      this.setState({ formBottom: true });
    }
  }

  handleTextFieldChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleContinue = () => {
    const { location, updatePRO } = this.props;

    updatePRO({
      type: 'bloomkidzSignatures',
      position: this.getTrackIndex(),
      value: { ...this.state },
    });

    this.forwardWithQuery(location.query);
  }

  render() {
    const { classes, router } = this.props;
    const { consentGuardian1 } = this.state;

    const continueDisabled = !consentGuardian1;

    return (
      <BloomkidzLayout
        primaryBtnOnTouchTap={this.handleContinue}
        primaryBtnLabel="Next"
        primaryBtnDisabled={continueDisabled}
      >
        <AppBar
          headerNode="Informed Consent"
          backButtonOnClick={() => router.goBack()}
        />
        <div className={classes.pageContent}>
          <div className={classes.paddingHorizontal}>
            <div className={classes.textFieldWrapper} style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ paddingRight: '20px' }}>I,</div>
              <TextField
                placeholder="Full Name"
                value={consentGuardian1}
                onChange={this.handleTextFieldChange}
                name="consentGuardian1"
                variant="standard"
                fullWidth
                inputProps={{ style: { fontSize: fontSizing.body, fontFamily: bloomkidzStyles.fontFamily } }}
              />
            </div>
          </div>
          <div style={{ backgroundColor: '#FFF', height: '80vh', marginBottom: '20px' }}>
            <div
              ref={(form) => {
                this.myForm = form;
              }}
              onScroll={this.handleScroll}
              className={classes.paragraph}
            >
              <img alt="logo" style={{ width: '90%', alignSelf: 'center', maxWidth: '400px', padding: '15px' }} src="/img/Bloomkidz.png" />
              <h4 color={bloomkidzStyles.primaryColor}>Informed Consent - Assessments and Services</h4>
              <div>
                I, {this.state.consentGuardian1 || 'Placeholder Name'} agree to have my child observed/evaluated/treated by BloomKidz. I understand that these services are based on one or more of the following:
              </div>
              <ul>
                <li>
                  An Applied Behavioral Analysis (ABA) model and will be provided by a professional trained in ABA
                </li>
                <li>
                  Occupational Therapy, Speech Therapy or Physical Therapy, provided by directly qualified professionals
                </li>
              </ul>
              <div>
                I understand my child will be assessed using one or more of the following assessments:
              </div>
              <ul>
                <li>
                  VB-MAPP
                </li>
                <li>
                  ABLLS-R
                </li>
                <li>
                  PEAK
                </li>
                <li>
                  AEPS
                </li>
                <li>
                  PDDBI
                </li>
                <li>
                  PDMS-2
                </li>
                <li>
                  BOT-2
                </li>
                <li>
                  Sensory Profile 2
                </li>
                <li>
                  Beery VMI
                </li>
                <li>
                  EFL
                </li>
                <li>
                  Social Skills Checklist
                </li>
                <li>
                  SSIS-SEL
                </li>
                <li>
                  Socially Savvy
                </li>
                <li>
                  Other
                </li>
              </ul>
            </div>
          </div>
        </div>
      </BloomkidzLayout>
    );
  }
}

BloomkidzSignatures7.propTypes = {
  classes: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  updatePRO: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const {
    proForms: { bloomkidzSignatures },
  } = state;

  return { bloomkidzSignatures };
};

export default connect(mapStateToProps, { updatePRO })(withStyles(styles)(BloomkidzSignatures7));
