import React from 'react';
import {
  FormControlLabel,
  LinearProgress,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import AppBar from '../components/app-bar';
import Page from './page';
import QuestionLayout from '../layouts/question';
import { title, instructions, options, nextBtn, noLabel, yesLabel, maybeLabel } from '../lib/i18next';
import { colors } from '../lib/styles';
import { updatePRO } from '../state/pro-forms';

const styles = {
  descriptionText: {
    fontSize: 29,
    fontStyle: 'italic',
  },
  formControlLabelRoot: {
    alignItems: 'start',
    marginTop: 5,
    '& .MuiSvgIcon-root': {
      height: 20,
      width: 20,
    },
  },
  questionText: {
    fontSize: 29,
    marginBottom: 5,
    marginTop: 10,
  },
  questionTextBold: {
    fontSize: 29,
    fontWeight: 'bold',
    marginTop: 20,
  },
  questionnaireContainer: {
    paddingLeft: 20,
    paddingRight: 20,
  },
  radio: {
    paddingTop: 10,
  },
  radioLabel: {
    fontSize: 29,
  },
  textField: {
    marginLeft: 28,
    '& input': {
      fontSize: 29,
    },
    '& label': {
      fontSize: 29,
    },
  },
};

class PatientDemographics6 extends Page {
  constructor(props) {
    super(props);

    this.state = {
      ateLess: '',
    };
  }

  handleChangeAteLess = (e) => {
    this.setState({ ateLess: e.target.value });
  };

  handleClickNext = () => {
    const { ateLess } = this.state;

    this.props.updatePRO({
      type: 'patientDemographics',
      position: this.getTrackIndex(),
      value: { ateLess },
    });

    this.forwardWithQuery(this.props.location.query);
  }

  render() {
    const { classes } = this.props;
    const { ateLess } = this.state;

    const continueDisabled = !ateLess;

    return (
      <QuestionLayout
        primaryBtnLabel={nextBtn()}
        primaryBtnOnTouchTap={this.handleClickNext}
        primaryBtnDisabled={continueDisabled}
        style={{ backgroundColor: colors.questionBackground }}
      >
        <AppBar
          headerNode={title('demographics6')}
          backButtonOnClick={() => this.props.router.goBack()}
        />
        <LinearProgress
          variant="determinate"
          value={(this.getCurrentPage() / this.getTotalPages()) * 100}
          classes={{ bar: classes.bar }}
        />
        <div className={classes.questionnaireContainer}>
          <div className={classes.questionTextBold}>
            {instructions('demographics6')}
          </div>
          <div className={classes.questionText}>
            {options('question', 'demographics6')}
          </div>
          <RadioGroup value={ateLess} onChange={this.handleChangeAteLess}>
            <FormControlLabel
              value="Yes"
              label={yesLabel()}
              control={<Radio classes={{ root: classes.radio }} />}
              classes={{ root: classes.formControlLabelRoot, label: classes.radioLabel }}
            />
            <FormControlLabel
              value="No"
              label={noLabel()}
              control={<Radio classes={{ root: classes.radio }} />}
              classes={{ root: classes.formControlLabelRoot, label: classes.radioLabel }}
            />
            <FormControlLabel
              value="Don't know"
              label={maybeLabel()}
              control={<Radio classes={{ root: classes.radio }} />}
              classes={{ root: classes.formControlLabelRoot, label: classes.radioLabel }}
            />
          </RadioGroup>
        </div>
      </QuestionLayout>
    );
  }
}

export default connect(null, { updatePRO })(withStyles(styles)(PatientDemographics6));
