import React from 'react';
import { connect } from 'react-redux';
import { capitalize } from 'lodash';

import AppBar from '../components/app-bar';
import Continue from '../components/continue';
import ImageUpload from '../components/image-upload';
import Page from './page';
import Layout from '../layouts/common';
import {
  browseInsurance,
  getImageUrl,
  storeInsurancePrimaryBack,
  storeInsuranceSecondaryBack,
  setPrimaryBackUrl,
  setSecondaryBackUrl,
} from '../state/insurance';
import { addInsuranceImage, browseInsuranceImages } from '../state/insurance-images';
import { colors } from '../lib/styles';

const styles = {
  fabReverseLocation: {
    marginTop: '30px',
    marginRight: '10px',
    position: 'absolute',
    padding: '10px',
    top: '0px',
    right: '0px',
  },
  fabTakePicture: {
    position: 'absolute',
    bottom: '20px',
    marginRight: 'auto',
    marginLeft: 'auto',
    width: '100%',
  },
  fabButton: {
    marginRight: '20px',
  },
  box: {
    fontSize: '1em',
    minHeight: '380px',
    backgroundColor: colors.primaryHighlight,
  },
  button: {
    fontSize: '1em',
  },
  hidden: {
    visibility: 'collapse',
  },
  visible: {
    visibility: 'visible',
  },
  question: {
    paddingBottom: '10px',
    color: '#207BCC',
  },
};

class InsuranceBack extends Page {
  constructor(props) {
    super(props);

    this.state = {
      errorMessage: '',
      photoAdded: false,
    };

    this.submitPhoto = this.submitPhoto.bind(this);
  }

  componentDidMount() {
    this.props.getImageUrl()
      .catch(() => {});

    this.props.browseInsurance()
      .catch((e) => {
        if (e.status === 404) {
          return this.props.addInsurance();
        }
      })
      .then((res) => {
        this.props.browseInsuranceImages(res.id);
      });
  }

  componentWillUnmount() {
    this.setState({
      photoAdded: false,
    });
  }

  submitPhoto(image) {
    const { insurance } = this.props;
    if (this.props.params.pageSelection === 'primary') {
      this.props.storeInsurancePrimaryBack(image)
        .then((res) => {
          this.props.addInsuranceImage(insurance.data.id, 'PRIMARY_INSURANCE_CARD_BACK', res)
            .then((imgData) => {
              this.props.setPrimaryBackUrl(insurance.data.id, imgData.id);
            });
        });
    } else {
      this.props.storeInsuranceSecondaryBack(image)
        .then((res) => {
          this.props.addInsuranceImage(insurance.data.id, 'SECONDARY_INSURANCE_CARD_BACK', res)
            .then((imgData) => {
              this.props.setSecondaryBackUrl(insurance.data.id, imgData.id);
            });
        });
    }
    this.props.router.goBack();
  }

  render() {
    const { errorMessage } = this.state;
    const { insurance } = this.props;

    const pageSelection = this.props.params.pageSelection; // eslint-disable-line prefer-destructuring
    const backid = pageSelection === 'primary' ? insurance.primaryBackUrl : insurance.secondaryBackUrl;

    return (
      <Layout>
        <AppBar
          backButtonOnClick={() => this.props.router.goBack()}
          headerNode={`${capitalize(pageSelection)} Insurance`}
        />
        {errorMessage}
        <ImageUpload
          alt="Insurance Back Photo"
          aspectRatio={128 / 75}
          containerStyle={styles.imageUpload}
          handleUpload={this.submitPhoto}
          imageHeight="25rem"
          imageTypeName="Insurance"
          displayImage={backid || '/img/profile.png'}
          uploadText="Back of insurance card image"
        />
        <section>
          {this.state.photoAdded && (<Continue onClick={() => this.forward()} />)}
        </section>
      </Layout>
    );
  }
}


function mapStateToProps(state) {
  const { user, insurance } = state;
  return {
    user,
    insurance,
  };
}


export default connect(mapStateToProps, {
  browseInsurance,
  browseInsuranceImages,
  getImageUrl,
  setPrimaryBackUrl,
  setSecondaryBackUrl,
  storeInsuranceSecondaryBack,
  storeInsurancePrimaryBack,
  addInsuranceImage,
})(InsuranceBack);
