import React from 'react';
import PropTypes from 'prop-types';

const styles = {
  width: '5vh',
  height: '2em',
  fill: 'none',
  strokeLinejoin: 'round',
  strokeWidth: 6,
};

const PainEntryIcon = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 76.19 79.11"
    style={{ ...styles, stroke: color }}
  >
    <title>Pain Entry1x</title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path d="M3,79.11V61.63S3,51.5,14.87,48.5c10.47-2.65,12.38-2.87,12.38-2.87s3-2.75,2.5-8.63c0,0-8.5-4.87-8.5-16.62C21.24,10,28.74,3,38.09,3" />
        <path d="M73.19,79.11V61.63s0-10.13-11.87-13.13c-10.47-2.65-12.37-2.87-12.37-2.87s-3-2.75-2.5-8.63c0,0,8.5-4.87,8.5-16.62C54.95,10,47.45,3,38.09,3" />
        <line x1="21.31" y1="79.11" x2="21.31" y2="64.55" />
        <line x1="54.86" y1="79.11" x2="54.86" y2="64.55" />
      </g>
    </g>
  </svg>
);

PainEntryIcon.defaultProps = {
  color: '#b2b2b2',
};

PainEntryIcon.propTypes = {
  color: PropTypes.string,
};

export default PainEntryIcon;
