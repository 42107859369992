import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Paper } from '@material-ui/core';
import moment from 'moment';
import { get, round } from 'lodash';
import Button from '@material-ui/core/Button';
import { HourlyBarChart } from 'common-ui';

import AppBar from '../components/app-bar';
import CircularProgressLabeled from '../components/circular-progress-labeled';
import LoadingContainer from '../components/loading-container';
import PainBody from '../components/pain-body';
import { colors, painLogDetailsColors } from '../lib/styles';
import Page from './page';
import { browseMigraineHx } from '../state/migraine-hx';
import { browsePainHx } from '../state/pain-hx';
import { fetchActivity, fetchCalories, fetchSteps } from '../state/cordova';
import BodyPainDetails from '../components/body-pain-details';
import HeadachePainDetails from '../components/headache-pain-details';
import PainHead from '../components/pain-head';
import { activityGoal, stepGoal, calorieGoal } from '../lib/constants';
import buildBodyData from '../lib/aggregate-body-data';
import buildHeadData from '../lib/aggregate-head-data';

const {
  greyBorder,
  mediumGrey,
} = painLogDetailsColors;

const days = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const styles = {
  barChartInnerWrapper: {
    bottom: 0,
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0,
  },
  barChartOuterWrapper: {
    flexGrow: 1,
    position: 'relative',
  },
  circularProgressIndicators: {
    flexGrow: 1,
    paddingRight: '1.875rem',
    paddingTop: '0.625rem',
  },
  circularProgressIndicator: {
    marginBottom: 20,
    minWidth: '100%',
  },
  dailyPainChart: {
    bottom: '1.875rem',
    display: 'flex',
    flexDirection: 'column',
    height: '20.375rem',
    left: '1.875rem',
    right: '1.875rem',
    position: 'absolute',
    top: '1.875rem',
    zIndex: 1,
  },
  dailyPainChartBackground: {
    background: mediumGrey,
    borderTop: `1px solid ${greyBorder}`,
    bottom: 0,
    height: '12.3125rem',
    left: 0,
    position: 'absolute',
    right: 0,
  },
  dailyPainChartTitle: {
    color: colors.black,
    fontSize: '1.75rem',
    paddingTop: '0.75rem',
    textAlign: 'center',
  },
  dailyPainChartWrapper: {
    height: '24.625rem',
    position: 'relative',
  },
  dateDisplay: {
    alignItems: 'center',
    background: mediumGrey,
    borderBottom: `1px solid ${greyBorder}`,
    display: 'flex',
    fontSize: '2.125rem',
    height: '6.3125rem',
    justifyContent: 'center',
  },
  headerButtonLabel: {
    fontSize: '1.75rem',
    textTransform: 'none',
  },
  painBody: {
    minWidth: '65%',
  },
  painBodyAndCirculars: {
    display: 'flex',
  },
  sectionTitle: {
    fontSize: '1.875rem',
    fontWeight: 'bold',
    margin: '2rem 0',
    textAlign: 'center',
  },
};


class PainLogDetails extends Page {
  constructor(props) {
    super(props);

    this.state = {
      painView: 'body',
      headData: {},
      bodyData: {},
    };
  }


  componentWillMount() {
    const {
      viewedDate,
    } = this.props;

    const eodToday = new Date(new Date().getTime() + ((24 * 60 * 60 * 1000) - 1));
    const startDate = new Date(viewedDate);
    const endDate = new Date(startDate.getTime() + ((24 * 60 * 60 * 1000) - 1));
    if (endDate <= eodToday) {
      this.props.fetchCalories(viewedDate);
      this.props.fetchSteps(viewedDate);
      this.props.fetchActivity(viewedDate);
      this.setState({
        fetchingBodyData: true,
        fetchingHeadData: true,
      });
      this.props.browsePainHx(startDate.toISOString(), endDate.toISOString())
        .then(buildBodyData)
        .then(bodyData => this.setState({ bodyData, fetchingBodyData: false }));

      this.props.browseMigraineHx(startDate.toISOString(), endDate.toISOString())
        .then(buildHeadData)
        .then(headData => this.setState({ headData, fetchingHeadData: false }));
    }
  }

  componentWillUpdate(nextProps) {
    const { viewedDate } = nextProps;
    if (this.props.viewedDate !== viewedDate) {
      this.props.fetchCalories(viewedDate);
      this.props.fetchSteps(viewedDate);
      this.props.fetchActivity(viewedDate);
    }
  }

  handleClickBackButton = () => {
    const {
      month,
      year,
    } = this.props.params;

    this.props.router.push({
      // so far, this doesn't work and returns dashboard view
      path: '/pain-history',
      pageIndex: 0,
      state: {
        dashboard: {
          month,
          year,
        },
      },
    });
  }

  handleSwitchView = () => {
    this.setState(prevState => ({
      painView: prevState.painView === 'head' ? 'body' : 'head',
    }));
  }

  render() {
    const {
      activityProgress,
      caloriesProgress,
      fetchingBodyData,
      fetchingHeadData,
      stepsProgress,
    } = this.props;
    const { headData, bodyData, painView } = this.state;
    const {
      date,
      month,
      year,
    } = this.props.params;
    const day = new Date(year, month, date).getDay();

    const isBodyPain = painView === 'body';
    const headerButtonText = isBodyPain ? 'Head Pain' : 'Body Pain';
    const isFetching = (isBodyPain && fetchingBodyData) || (!isBodyPain && fetchingHeadData);
    const noData = !isFetching && ((isBodyPain && !bodyData.total) || (!isBodyPain && !headData.total));
    return (
      <div>
        <AppBar
          backButtonOnClick={() => this.props.router.push('/pain-history')}
          headerNode="Pain Log"
          rightNode={
            (
              <Button
                onClick={this.handleSwitchView}
                style={{ ...styles.helpButton, ...styles.headerButtonLabel}}
              >{headerButtonText}</Button>
            )
          }
        />
        <div style={styles.dateDisplay}>
          {`${days[day]} ${months[month]} ${date}, ${year}`}
        </div>
        <div style={styles.sectionTitle}>
          Pain Entry Details
        </div>
        {isBodyPain && (
          <div style={styles.painBodyAndCirculars}>
            <div style={styles.painBody}>
              <PainBody
                selectedLocations={get(bodyData, 'worstPain.pain_locations_full', [])}
              />
            </div>
            <div style={styles.circularProgressIndicators}>
              <div style={styles.circularProgressIndicator}>
                <CircularProgressLabeled
                  label="Activity"
                  progress={activityProgress}
                  goal={activityGoal}
                />
              </div>
              <div style={styles.circularProgressIndicator}>
                <CircularProgressLabeled
                  label="Steps"
                  progress={stepsProgress}
                  goal={stepGoal}
                />
              </div>
              <div style={styles.circularProgressIndicator}>
                <CircularProgressLabeled
                  label="Calories"
                  progress={caloriesProgress}
                  goal={calorieGoal}
                />
              </div>
            </div>
          </div>
        )}
        <LoadingContainer
          isFetching={isFetching}
          noData={noData}
          noDataMessage="No Pain Found For This Date"
        >
          {painView === 'head' && (
            <div style={{ margin: '0 auto', width: '70%' }}>
              <PainHead
                pain={[headData.worstPain]}
              />
            </div>
          )}
          <div style={styles.dailyPainChartWrapper}>
            <Paper style={styles.dailyPainChart}>
              <div style={styles.dailyPainChartTitle}>
                Pain Intensity Over Time
              </div>
              <div style={styles.barChartOuterWrapper}>
                <div style={styles.barChartInnerWrapper}>
                  <HourlyBarChart viewedDate={this.props.viewedDate} noLabel={true} data={isBodyPain ? bodyData.barChart : headData.barChart} />
                </div>
              </div>
            </Paper>
            <div style={styles.dailyPainChartBackground} />
          </div>
          {isBodyPain && (
            <BodyPainDetails
              painLocations={bodyData.painLocations}
              functionalLimitations={bodyData.limitations}
              aggravatingFactors={bodyData.aggravatingFactors}
              alleviatingFactors={bodyData.alleviatingFactors}
            />
          )}
          {painView === 'head' && (
            <HeadachePainDetails
              painLocations={headData.painLocations}
              symptoms={headData.symptoms}
              preventitiveMedications={headData.preventitiveRx}
              abortiveMedications={headData.abortiveRx}
            />
          )}
        </LoadingContainer>
      </div>
    );
  }
}

PainLogDetails.defaultProps = {
  activityProgress: 0,
  caloriesProgress: 0,
  stepsProgress: 0,
};

PainLogDetails.propTypes = {
  activityProgress: PropTypes.number,
  caloriesProgress: PropTypes.number,
  bodyData: PropTypes.shape({
    aggravating_factors: PropTypes.arrayOf(PropTypes.string),
    alleviating_factors: PropTypes.arrayOf(PropTypes.string),
    functional_limitations: PropTypes.arrayOf(PropTypes.string),
    pain_locations: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      x: PropTypes.number,
      y: PropTypes.number,
      body_location: PropTypes.string,
    })),
  }),
  date: PropTypes.string,
  headData: PropTypes.shape({
    pain_locations: PropTypes.array,
    symptoms: PropTypes.arrayOf(PropTypes.string),
  }),
  params: PropTypes.shape({
    date: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    month: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    year: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }).isRequired,
  steps: PropTypes.number,
  stepsProgress: PropTypes.number,
};

function mapStateToProps(state, ownProps) {
  const { cordova, user: { userId } } = state;
  const { year, month, date } = ownProps.params;
  const viewedDate = moment(new Date(year, month, date)).format('YYYY-MM-DD');

  const activity = (round(cordova.activityData[viewedDate] || 0) / 1000) / 60;
  const steps = round(cordova.stepsData[viewedDate] || 0);
  const calories = round(cordova.caloriesData[viewedDate] || 0);

  const stepsProgress = round(steps, 2);
  const caloriesProgress = round(calories, 2);
  const activityProgress = round(activity, 2);

  return {
    activity,
    viewedDate,
    stepsProgress,
    caloriesProgress,
    activityProgress,
    steps,
    calories,
    userId,
  };
}

export default connect(mapStateToProps, {
  browseMigraineHx,
  browsePainHx,
  fetchActivity,
  fetchCalories,
  fetchSteps,
})(PainLogDetails);
