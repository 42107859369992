import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  LinearProgress,
  Select,
  MenuItem,
  Radio,
  RadioGroup,
  Slider,
  FormControlLabel,
  IconButton,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import NoSleep from 'nosleep.js';

import AppBar from '../components/app-bar';
import Continue from '../components/continue';

import { Camera } from '../lib/icons';
import { VSReader } from '../lib/vitalstream';
import { VSStatus, VSUnknown, VSVitals } from '../lib/vitalstream-packets';

import Page from './page';

import { updatePRO, submitPRO } from '../state/pro-forms';

const styles = {
  alert: {
    fontSize: '2rem',
    color: 'red',
  },
  connecting: {
    fontSize: '2rem',
    color: 'blue',
  },
  formControlLabelRoot: {
    alignItems: 'start',
    marginTop: 5,
    '& .MuiSvgIcon-root': {
      height: 20,
      width: 20,
    },
  },
  iconContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    flexGrow: 0,
    flexShrink: 0,
    width: '100%',
    color: '#000',
    paddingTop: 10,
  },
  iconWrapper: {
    width: 100,
  },
  radioLabel: {
    fontSize: '2rem',
  },
  select: {
    marginBottom: 20,
    marginTop: 10,
  },
  titleGroup: {
    margin: 25,
  },
  text: {
    fontSize: '2rem',
  },
  sliderValueLabel: {
    fontSize: 12,
  },
  smallerText: {
    fontSize: '1.5rem',
  },
  nextBtn: {
    textAlign: 'center',
    width: '100%',
    top: '80%',
  },
  optionsButton: {
    marginTop: 20,
    fontSize: '3rem',
  },
};

class ContinuousBPDataCollection2 extends Page {
  constructor(props) {
    super(props);
    this.state = {
      seconds: 120,
      FPS: 'S-METHOD',
      resolution: '640x480',
      hasDevice: true,
      imgEncoding: 'image/jpeg',
      jpegQuality: 0.95,
      cropFace: true,
      showOptions: false,

      vitalstream: null,
      connecting: false,
      connected: false,
      optimized: false,
      deviceError: null,
      unknownPackets: [],
      deviceErrors: [],

      deviceData: {
        respirationStatus: false,
        respiration: 0,

        bpStatus: false,
        diastolic: 0,
        systolic: 0,

        hrStatus: false,
        heartRate: 0,
      },
      deviceStatus: {
        calibrating: false,
        caliibrated: false,
        dataValid: false,
        autocalPct: 0,

        clockWrapAround: false,
        pdaEnabled: false,
        simulationEnabled: false,
        batteryVoltageLow: false,
        criticalTemperature: false,
        bleAdv: false,
        chargeComplete: false,
        charging: false,
        invalidDataEntry: false,
        betaProcessing: false,
        recalSoon: false,
        notifyRecalibratingSoon: false,
        recalRecommended: false,
        tooManyFails: false,
        calibrationFailed: false,
        calibrationOffsetFailed: false,
        inflateFailed: false,
        noPulseTimeout: false,
        motionDetected: false,
        cuffTooLoose: false,
        cuffTooTight: false,
        badCuff: false,
        weakSignal: false,
        hemodynamicsEnabled: false,
        cardiacOutputCalibrated: false,
        poorSignal: false,
        motionEvent: false,
        stopButtonPressed: false,
        pumpOverrun: false,
        inflatedIndicator: false,
        pressureControlIndicator: false,
      },
    };
  }

  handleContinue2 = async () => {
    await this.state.vitalstream.stopStreaming();

    this.props.updatePRO({
      type: 'continuousBPDataCollection',
      position: this.getTrackIndex(),
      value: this.state,
    });

    this.forwardWithQuery(this.props.location.query);
  }

  handleDeviceData = async (deviceData, deviceError) => {
    if (deviceError) {
      this.setState(state => ({ deviceErrors: state.deviceErrors.push({ deviceData, deviceError }) }));

      switch (deviceError.code) {
        case 910:
        case 911: // just log crc and malformed packets
          return console.error(deviceError.toString());
        case 901: // disconnected
          // if we already have an error, dont replace it with the disconnect
          if (this.state.deviceError) return this.setState({ connecting: false, connected: false, optimized: false }); // eslint-disable-next-line
        default:
          await this.state.vitalstream.disconnect();
          return this.setState({ deviceError: deviceError.toString(), connecting: false, connected: false, optimized: false });
      }
    }

    const { packet } = deviceData;
    const { unknownPackets } = this.state;

    if (packet instanceof VSVitals) {
      this.setState({
        deviceData: packet,
      });
    } else if (packet instanceof VSStatus) {
      this.setState({
        deviceStatus: packet,
      });
    } else if (packet instanceof VSUnknown) {
      console.warn(packet);
      this.setState({
        unknownPackets: unknownPackets.push(packet),
      });
    }

    // continue even though we might not have a respiration status yet
    if (this.state.connected && !this.state.optimized && this.state.deviceData.respirationStatus) {
      this.setState({
        optimized: true,
      });

      await this.handleContinue2();
    }
  }

  handleContinue = async () => {
    const noSleep = new NoSleep();
    noSleep.enable();

    let vitalstream;
    let deviceError;
    try {
      this.setState({ deviceError: null, connecting: true });
      vitalstream = await new VSReader();
      this.setState({ vitalstream, connecting: false, connected: true });
    } catch (error) {
      if (error.code && error.code === 902) {
        deviceError = 'Bluetooth pairing failed.  Please tap the I\'M READY button again.';
      } else {
        deviceError = error.toString();
      }
    }

    if (deviceError) {
      if (vitalstream) {
        await vitalstream.disconnect();
      }
      return this.setState({ deviceError, connecting: false });
    }

    this.setState({ vitalstream });

    try {
      await vitalstream.stream(this.handleDeviceData);
    } catch (deviceError) {
      return this.setState({ deviceError: deviceError.toString(), connecting: false, connected: false });
    }
    if (!this.props.continuousBPDataCollection[0].calibrationMethod || (this.props.continuousBPDataCollection[0].calibrationMethod !== 'manual')) {
      await vitalstream.autocalibrate();
    }
  }

  handleContinueNoDevice = () => {
    this.setState({ deviceError: null, connecting: false, hasDevice: false });
    this.props.updatePRO({
      type: 'continuousBPDataCollection',
      position: this.getTrackIndex(),
      value: { ...this.state, hasDevice: false, deviceError: false },
    });
    this.forwardWithQuery(this.props.location.query);
  }

  handleContinueError = async () => {
    const {
      user,
      userId,
      location,
      submitPRO,
      continuousBPDataCollection,
    } = this.props;

    const {
      vitalstream,
      deviceError,
      unknownPackets,
      deviceStatus,
      deviceData,
    } = this.state;
    const { id } = location.query;

    const data = {
      ...this.getDataMap(continuousBPDataCollection),
      timestamp: Date.now(),
      userId,
      deviceError,
      deviceData,
      deviceStatus,
      initResponse: vitalstream ? vitalstream.initResponse : null,
      unknownPackets,
    };

    let path;

    if (location.pathname.includes('SECONDARY')) {
      path = 'SECONDARY-BP-DATA-COLLECTION';
    } else if (location.pathname.includes('DUAL')) {
      path = 'DUAL-CAMERA-BP-DATA-COLLECTION';
    } else {
      path = 'CONTINUOUS-BP-DATA-COLLECTION';
    }

    await submitPRO({ userId, id }, {
      pro_type: path,
      pro_data: { data },
    }, user);
    this.finishTrack();
  }

  render() {
    const { classes } = this.props;

    return (
      <Fragment>
        <AppBar
          headerNode="Pairing"
          noLeftNode={true}
        />
        <LinearProgress
          variant="determinate"
          value={(this.getCurrentPage() / this.getTotalPages()) * 100}
        />
        <div style={styles.iconContainer}>
          <div style={styles.iconWrapper}>
            <Camera />
          </div>
        </div>

        <Snackbar
          open={this.state.deviceStatus.dataValid && this.state.deviceStatus.hemodynamicsEnabled}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <MuiAlert elevation={6} variant="filled" severity="error" style={{ fontSize: 16 }}>Hemodynamics Enabled</MuiAlert>
        </Snackbar>
        {/* always during calibrate  */}
        {/* <Snackbar
          open={this.state.deviceStatus.dataValid && this.state.deviceStatus.cardiacOutputCalibrated}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <MuiAlert elevation={6} variant="filled" severity="error" style={{ fontSize: 16 }}>Cardiac Output Calibrated</MuiAlert>
        </Snackbar> */}
        {/* always during calibrate  */}
        {/* <Snackbar
          open={this.state.deviceStatus.dataValid && this.state.deviceStatus.inflatedIndicator}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <MuiAlert elevation={6} variant="filled" severity="error" style={{ fontSize: 16 }}>An indicator of whether the system has been inflated to pressure..</MuiAlert>
        </Snackbar> */}
        {/* always during calibrate  */}
        {/* <Snackbar
          open={this.state.deviceStatus.dataValid && this.state.deviceStatus.pressureControlIndicator}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <MuiAlert elevation={6} variant="filled" severity="error" style={{ fontSize: 16 }}>An indicator of whether the system is currently running closed loop pressure control.</MuiAlert>
        </Snackbar> */}
        {/* always during calibrate  */}
        {/* <Snackbar
          open={this.state.deviceStatus.dataValid && this.state.deviceStatus.pumpOverrun}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <MuiAlert elevation={6} variant="filled" severity="error" style={{ fontSize: 16 }}>The pump has violated an overrun condition.</MuiAlert>
        </Snackbar> */}
        {/* stopButtonPressed seems to come through often */}
        {/* <Snackbar
          open={this.state.deviceStatus.stopButtonPressed}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <MuiAlert elevation={6} variant="filled" severity="error" style={{ fontSize: 16 }}>Indicates that the user pressed stop on the device UI.</MuiAlert>
        </Snackbar> */}

        <Snackbar
          open={this.state.deviceStatus.dataValid && this.state.deviceStatus.motionEvent}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <MuiAlert elevation={6} variant="filled" severity="error" style={{ fontSize: 16 }}>The system is having trouble getting a good reading due to too much motion.</MuiAlert>
        </Snackbar>

        <Snackbar
          open={this.state.deviceStatus.dataValid && this.state.deviceStatus.poorSignal}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <MuiAlert elevation={6} variant="filled" severity="error" style={{ fontSize: 16 }}>The system failed to calibrate or timed out process signals so measurements were aborted.</MuiAlert>
        </Snackbar>
        {/* Always? */}
        {/* <Snackbar
          open={this.state.deviceStatus.dataValid && this.state.deviceStatus.pdaEnabled}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <MuiAlert elevation={6} variant="filled" severity="error" style={{ fontSize: 16 }}>An indicator of whether the system Pulse Decomposition Algorithm (PDA) measurement system is enabled.</MuiAlert>
        </Snackbar> */}
        <Snackbar
          open={this.state.deviceStatus.dataValid && this.state.deviceStatus.simulationEnabled}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <MuiAlert elevation={6} variant="filled" severity="error" style={{ fontSize: 16 }}>An indicator of whether the system is in simulation mode.</MuiAlert>
        </Snackbar>
        {/* always? */}
        {/* <Snackbar
          open={this.state.deviceStatus.dataValid && this.state.deviceStatus.clockWrapAround}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <MuiAlert elevation={6} variant="filled" severity="error" style={{ fontSize: 16 }}>The system clock (time since reset) has wrapped around its index.</MuiAlert>
        </Snackbar> */}
        {/* seems to trigger during calibration? even full battery might be mapped wrong? */}
        {/* <Snackbar
          open={this.state.deviceStatus.dataValid && this.state.deviceStatus.batteryVoltageLow}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <MuiAlert elevation={6} variant="filled" severity="error" style={{ fontSize: 16 }}>The battery voltage sensor has indicated the battery is near drop-out.</MuiAlert>
        </Snackbar> */}
        {/* seems to trigger during calibration? might be mapped wrong? */}
        {/* <Snackbar
          open={this.state.deviceStatus.dataValid && this.state.deviceStatus.criticalTemperature}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <MuiAlert elevation={6} variant="filled" severity="error" style={{ fontSize: 16 }}>The on-board temperature sensor has detected critically high temperature.</MuiAlert>
        </Snackbar> */}
        <Snackbar
          open={this.state.deviceStatus.dataValid && this.state.deviceStatus.bleAdv}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <MuiAlert elevation={6} variant="filled" severity="error" style={{ fontSize: 16 }}>The ble module is advertising.</MuiAlert>
        </Snackbar>
        {/* who cares */}
        {/* <Snackbar
          open={this.state.deviceStatus.dataValid && this.state.deviceStatus.chargeComplete}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <MuiAlert elevation={6} variant="filled" severity="error" style={{ fontSize: 16 }}>Charging complete.</MuiAlert>
        </Snackbar> */}
        {/* who cares */}
        {/* <Snackbar
          open={this.state.deviceStatus.dataValid && this.state.deviceStatus.charging}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <MuiAlert elevation={6} variant="filled" severity="error" style={{ fontSize: 16 }}>Currently Charging.</MuiAlert>
        </Snackbar> */}
        <Snackbar
          open={this.state.deviceStatus.dataValid && this.state.deviceStatus.invalidDataEntry}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <MuiAlert elevation={6} variant="filled" severity="error" style={{ fontSize: 16 }}>Invalid input received in the last command.</MuiAlert>
        </Snackbar>
        <Snackbar
          open={this.state.deviceStatus.dataValid && this.state.deviceStatus.notifyRecalibratingSoon}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <MuiAlert elevation={6} variant="filled" severity="error" style={{ fontSize: 16 }}>Recalibration will be starting soon.</MuiAlert>
        </Snackbar>
        <Snackbar
          open={this.state.deviceStatus.dataValid && this.state.deviceStatus.recalRecommended}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <MuiAlert elevation={6} variant="filled" severity="error" style={{ fontSize: 16 }}>Signal is not sufficient to have high confidence in the readings.</MuiAlert>
        </Snackbar>
        <Snackbar
          open={this.state.deviceStatus.dataValid && this.state.deviceStatus.tooManyFails}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <MuiAlert elevation={6} variant="filled" severity="error" style={{ fontSize: 16 }}>Too many consecutive autocalibration failures try manual cal</MuiAlert>
        </Snackbar>
        {/* always */}
        {/* <Snackbar
          open={this.state.deviceStatus.dataValid && this.state.deviceStatus.recalSoon}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <MuiAlert elevation={6} variant="filled" severity="error" style={{ fontSize: 16 }}>The device requires recalibration soon.</MuiAlert>
        </Snackbar> */}
        <Snackbar
          open={this.state.deviceStatus.dataValid && this.state.deviceStatus.calibrationFailed}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <MuiAlert elevation={6} variant="filled" severity="error" style={{ fontSize: 16 }}>The calibration values were out of range or oscillometric curve had invalid shape.</MuiAlert>
        </Snackbar>
        <Snackbar
          open={this.state.deviceStatus.dataValid && this.state.deviceStatus.calibrationOffsetFailed}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <MuiAlert elevation={6} variant="filled" severity="error" style={{ fontSize: 16 }}>Too much movement.</MuiAlert>
        </Snackbar>
        {/* seems to trigger during calibration. Maybe use it as part of detecting calibrated state... */}
        {/* <Snackbar
          open={this.state.deviceStatus.dataValid && this.state.deviceStatus.betaProcessing}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <MuiAlert elevation={6} variant="filled" severity="error" style={{ fontSize: 16 }}>The system has finished finding the oscillometric curve and is processing the beta (offset) value.</MuiAlert>
        </Snackbar> */}
        <Snackbar
          open={this.state.deviceStatus.dataValid && this.state.deviceStatus.inflateFailed}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <MuiAlert elevation={6} variant="filled" severity="error" style={{ fontSize: 16 }}>Cuff did not inflate to expected value within timeout.</MuiAlert>
        </Snackbar>
        <Snackbar
          open={this.state.deviceStatus.dataValid && this.state.deviceStatus.noPulseTimeout}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <MuiAlert elevation={6} variant="filled" severity="error" style={{ fontSize: 16 }}>The systems has gone greater than 3 minutes without a valid heart beat.</MuiAlert>
        </Snackbar>
        <Snackbar
          open={this.state.deviceStatus.dataValid && this.state.deviceStatus.motionDetected}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <MuiAlert elevation={6} variant="filled" severity="error" style={{ fontSize: 16 }}>The system is having trouble getting a good reading due to too much motion.</MuiAlert>
        </Snackbar>
        <Snackbar
          open={this.state.deviceStatus.dataValid && this.state.deviceStatus.cuffTooLoose}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <MuiAlert elevation={6} variant="filled" severity="error" style={{ fontSize: 16 }}>The calibration pump up identified the cuff was too loose.</MuiAlert>
        </Snackbar>
        <Snackbar
          open={this.state.deviceStatus.dataValid && this.state.deviceStatus.cuffTooTight}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <MuiAlert elevation={6} variant="filled" severity="error" style={{ fontSize: 16 }}>The calibration pump up identified the cuff was too tight.</MuiAlert>
        </Snackbar>
        <Snackbar
          open={this.state.deviceStatus.dataValid && this.state.deviceStatus.badCuff}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <MuiAlert elevation={6} variant="filled" severity="error" style={{ fontSize: 16 }}>The cuff is not holding pressure as expected.</MuiAlert>
        </Snackbar>
        <Snackbar
          open={this.state.deviceStatus.dataValid && this.state.deviceStatus.weakSignal}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <MuiAlert elevation={6} variant="filled" severity="error" style={{ fontSize: 16 }}>Calibration oscillometric curve amplitude is too weak to verify reading.</MuiAlert>
        </Snackbar>

        <div style={styles.titleGroup}>

          <h1>Pair the Vital Stream</h1>
          <ul style={styles.text}>
            <li>Turn on the Vital Stream, if not on already</li>
            <li>Tap the gear shift icon</li>
            <li>Tap the blue &#62; until you see the Bluetooth icon</li>
            <li>Tap the Bluetooth icon</li>
            <li>Tap the connect button on the Vital Stream</li>
          </ul>

          <h1>On the phone</h1>
          <ul style={styles.text}>
            <li>Tap the I&apos;M READY to bring up the Bluetooth Pairing Screen</li>
            <li>Tap the Vital Stream from the device list</li>
            <li>Tap PAIR</li>
          </ul>

          <p style={styles.text}>We will be recording for {this.state.showOptions ? (
            <Select
              value={this.state.seconds}
              onChange={(evt) => {
                this.setState({ seconds: evt.target.value });
              }}
              style={styles.select}
            >
              <MenuItem value={120}><span style={styles.text}>120 seconds</span></MenuItem>
              <MenuItem value={90}><span style={styles.text}>90 seconds</span></MenuItem>
              <MenuItem value={60}><span style={styles.text}>60 seconds</span></MenuItem>
              <MenuItem value={30}><span style={styles.text}>30 seconds</span></MenuItem>
            </Select>) : (
            <span>{this.state.seconds} seconds</span>
          )}
          </p>
          {this.state.showOptions ? (
            <p style={styles.smallerText}>Record at <Select
              value={this.state.FPS}
              onChange={(evt) => {
                this.setState({ FPS: evt.target.value });
              }}
            >
              <MenuItem value="S-METHOD"><span className={classes.smallerText}>S -Method (video frame callback)</span></MenuItem>
              <MenuItem value={60}><span style={styles.smallerText}>60 frames/sec</span></MenuItem>
              <MenuItem value={45}><span style={styles.smallerText}>45 frames/sec</span></MenuItem>
              <MenuItem value={30}><span style={styles.smallerText}>30 frames/sec</span></MenuItem>
              <MenuItem value={25}><span style={styles.smallerText}>25 frames/sec</span></MenuItem>
              <MenuItem value={20}><span style={styles.smallerText}>20 frames/sec</span></MenuItem>
              <MenuItem value={15}><span style={styles.smallerText}>15 frames/sec</span></MenuItem>
              <MenuItem value={10}><span style={styles.smallerText}>10 frames/sec</span></MenuItem>
            </Select><br />
            </p>
          ) : ''}

          <p style={styles.alert}>{this.state.deviceError}</p>
          <p style={styles.connecting}>{this.state.connecting ? 'connecting...' : ''}</p>
          <p style={styles.connecting}>{this.state.deviceStatus.calibrating && this.state.deviceStatus.autocalPct !== 100 ? 'calibrating... ' + this.state.deviceStatus.autocalPct + '%' : ''}</p>
          <p style={styles.connecting}>{this.state.deviceStatus.calibrated && !this.state.optimized ? 'optimizing... ' : ''}</p>

          <p style={styles.connecting}>{this.state.deviceStatus.dataValid && this.state.deviceData.bpStatus ? 'systolic: ' + this.state.deviceData.systolic + ' diastolic: ' + this.state.deviceData.diastolic : ''}</p>
          <p style={styles.connecting}>{this.state.deviceStatus.dataValid && this.state.deviceData.hrStatus ? 'heartRate: ' + this.state.deviceData.heartRate : ''}</p>
          <p style={styles.connecting}>{this.state.deviceStatus.dataValid && this.state.deviceData.respirationStatus ? 'respiration: ' + this.state.deviceData.respiration : ''}</p>

          {this.state.showOptions ? (<>
            <div>
              <span style={styles.text}>Image encoding</span>
              <RadioGroup
                aria-label="image encoding"
                value={this.state.imgEncoding}
                onChange={(evt) => {
                  this.setState({ imgEncoding: evt.target.value });
                }}
                row
              >
                <FormControlLabel
                  value="image/png"
                  control={<Radio />}
                  label="png"
                  classes={{ root: classes.formControlLabelRoot, label: classes.radioLabel }}
                />
                <FormControlLabel
                  value="image/jpeg"
                  control={<Radio />}
                  label="jpg"
                  classes={{ root: classes.formControlLabelRoot, label: classes.radioLabel }}
                />
              </RadioGroup>
              <div style={{ display: this.state.imgEncoding === 'image/jpeg' ? '' : 'none', marginTop: 10 }}>
                <span style={styles.smallerText}>JPG Quality</span>
                <Slider
                  aria-label="JPG Quality"
                  defaultValue={this.state.jpegQuality}
                  value={this.state.jpegQuality}
                  valueLabelDisplay="auto"
                  onChange={(evt, newValue) => {
                    this.setState({ jpegQuality: newValue });
                  }}
                  step={0.05}
                  min={0.2}
                  max={1.0}
                  classes={{ valueLabel: classes.sliderValueLabel }}
                />
              </div>
            </div>

            <div>
              <span style={styles.text}>Crop face</span>
              <RadioGroup
                aria-label="crop face"
                value={this.state.cropFace}
                onChange={(evt) => {
                  this.setState({ cropFace: evt.target.value === 'true' });
                }}
                row
              >
                <FormControlLabel
                  value={true}
                  control={<Radio />}
                  label="Yes"
                  classes={{ root: classes.formControlLabelRoot, label: classes.radioLabel }}
                />
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label="No"
                  classes={{ root: classes.formControlLabelRoot, label: classes.radioLabel }}
                />
              </RadioGroup>
            </div>
          </>) : ''}
        </div>


        <div style={styles.nextBtn}>
          {!this.state.connected ? (
            <Continue
              text="I'm Ready"
              onClick={this.handleContinue}
            />
          ) : ''}
          {!this.state.connecting && this.state.showOptions ? (
            <Continue
              text="Continue without a Device"
              onClick={this.handleContinueNoDevice}
            />
          ) : ''}
          {this.state.deviceError ? (
            <Continue
              text="Finish with error"
              onClick={this.handleContinueError}
            />
          ) : ''}
        </div>
        <IconButton aria-label="show/hide" style={styles.optionsButton}>
          {this.state.showOptions ? (
            <CloseIcon onClick={() => {
              this.setState({ showOptions: false });
            }}
            />
          ) : (
            <AddIcon onClick={() => {
              this.setState({ showOptions: true });
            }}
            />
          )}
        </IconButton>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { user, proForms: { continuousBPDataCollection } } = state;
  return {
    user,
    userId: user.id,
    continuousBPDataCollection,
  };
}

ContinuousBPDataCollection2.proptypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  route: PropTypes.object.isRequired,
  updatePRO: PropTypes.func.isRequired,
  submitPRO: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { submitPRO, updatePRO })(withStyles(styles)(ContinuousBPDataCollection2));
