import React from 'react';
import { connect } from 'react-redux';
import { map } from 'lodash';
import { List } from '@material-ui/core';

import AppBar from '../components/app-bar';
import Page from './page';
import Layout from '../layouts/common';
import { pqrsIfNeeded } from '../state/app-data';
import { browsePqrsHx, addPqrsHx, editPqrsHx } from '../state/pqrs-hx';
import Continue from '../components/continue';
import Subtitle from '../components/subtitle';

import SimpleListItem from '../components/simple-list-item';

import { colors } from '../lib/styles';

const baseStyles = {
  list: {
    backgroundColor: colors.white,
    textTransform: 'capitalize',
  },
  text: {
    color: colors.primaryColor,
  },
  font: {
    color: colors.primaryColor,
    fontSize: 10,
  },
  underline: {
    display: 'none',
  },
};

class PQRS extends Page {
  constructor(props) {
    super(props);
    this.handleCheck = this.handleCheck.bind(this);
    this.getHx = this.getHx.bind(this);
    this.handleContinue = this.handleContinue.bind(this);
  }

  componentWillMount() {
    this.props.pqrsIfNeeded();
    this.props.browsePqrsHx();
  }

  handleCheck(pqrs_id, checked) {
    const hx = this.getHx(pqrs_id);
    if (!hx) {
      return this.props.addPqrsHx({ pqrs_id, answer: checked });
    }
    return this.props.editPqrsHx({ pqrs_id, answer: checked });
  }

  getHx(id) {
    const { data } = this.props.pqrsHx;
    return data[id];
  }

  handleContinue() {
    this.forward();
  }

  render() {
    const { pqrs } = this.props.appData;
    const rows = map(pqrs, (obj, i) => {
      const hx = this.getHx(obj.id);
      return (
        <SimpleListItem
          key={i}
          item={obj}
          checked={hx ? hx.answer : false}
          onAddItem={this.handleCheck}
          onRemoveItem={this.handleCheck}
        />
      );
    });
    return (
      <Layout>
        <div>
          <AppBar
            backButtonOnClick={() => this.props.router.goBack()}
            headerNode="Review of Symptoms"
          />
          <Subtitle label="SCROLL TO SEE ALL OPTIONS & CHECK ALL THAT APPLY" />
          <List style={baseStyles.list}>
            {rows}
          </List>
          <section style={{ backgroundColor: colors.white }}>
            <Continue onClick={this.handleContinue} />
          </section>
        </div>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  const { user, appData, pqrsHx } = state;
  return {
    user,
    appData,
    pqrsHx,
  };
}

export default connect(mapStateToProps, { pqrsIfNeeded, browsePqrsHx, addPqrsHx, editPqrsHx })(PQRS);
