/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';
import { KeypadDate } from 'common-ui';
import { connect } from 'react-redux';
import Joi from 'joi-browser';

import Page from './page';
import AppBar from '../components/app-bar';
import IconWrapper from '../components/icon-wrapper';
import BloomkidzLayout from '../layouts/bloomkidz';
import { ChildReaching } from '../lib/icons';
import { fontSizing } from '../lib/styles';
import { updatePRO } from '../state/pro-forms';
import { bloomkidzStyles, bloomkidzStylesObj } from '../lib/bloomkidz';

const formSchema = Joi.object({
  birthday: Joi.date(),
});

const styles = {
  ...bloomkidzStylesObj,
  birthdayLabel: {
    alignItems: 'end',
    display: 'flex',
    width: '100%',
    '& > div > div': {
      margin: 0,
    },
  },
  dob: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
    color: '#9c9c9c',
  },
  keypadDateWrapper: {
    marginTop: 10,
    '& > label > div': {
      flexGrow: 1,
    },
    '& > label > div > div': {
      margin: 0,
      width: '100%',
    },
    '& input': {
      margin: 0,
      fontFamily: bloomkidzStyles.fontFamily,
      fontSize: `${fontSizing.body}px !important`,
    },
  },
};

class BloomkidzMeanAndNutrition1 extends Page {
  constructor(props) {
    super(props);

    const stateFromRedux = props.bloomkidzMealAndNutrition[this.getTrackIndex()] || {};

    this.state = {
      name: '',
      birthday: '',
      errorBirthday: '',
      ...stateFromRedux,
    };
  }

  handleTextFieldChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleChangeBirthday = (birthday) => {
    this.setState({ birthday, errorBirthday: '' });
  }

  handleContinue = () => {
    const { location, updatePRO } = this.props;
    const { birthday } = this.state;

    const { error } = formSchema.validate({ birthday }, { abortEarly: false });

    if (error) {
      this.setState({ errorBirthday: 'Invalid value' });
      return;
    }

    updatePRO({
      type: 'bloomkidzMealAndNutrition',
      position: this.getTrackIndex(),
      value: { ...this.state },
    });

    this.forwardWithQuery(location.query);
  }

  render() {
    const { classes, router } = this.props;
    const { birthday, errorBirthday, name } = this.state;

    const continueDisabled = !birthday || !name;

    const keypadDateWrapperClass = errorBirthday
      ? `${classes.keypadDateWrapper} ${classes.keypadDateWrapperError}`
      : classes.keypadDateWrapper;

    return (
      <BloomkidzLayout
        primaryBtnOnTouchTap={this.handleContinue}
        primaryBtnLabel="Next"
        primaryBtnDisabled={continueDisabled}
      >
        <AppBar
          headerNode="Information"
          backButtonOnClick={() => router.goBack()}
        />
        <IconWrapper>
          <ChildReaching />
        </IconWrapper>
        <div className={classes.pageContent}>
          <div>Child&apos;s Information</div>
          <div className={classes.paddingHorizontal}>
            <div className={classes.textFieldWrapper}>
              <TextField
                placeholder="Name"
                value={name}
                onChange={this.handleTextFieldChange}
                name="name"
                variant="standard"
                fullWidth
                inputProps={{ style: { fontSize: fontSizing.body, fontFamily: bloomkidzStyles.fontFamily } }}
              />
            </div>
            <div className={keypadDateWrapperClass}>
              <label className={classes.birthdayLabel}>
                {!birthday ? (
                  <span className={classes.dob}>DOB:&nbsp;</span>
                ) : null}
                <KeypadDate onChange={this.handleChangeBirthday} value={birthday} />
              </label>
            </div>
            {errorBirthday ? (
              <div className={classes.errorHelperText}>
                {errorBirthday}
              </div>
            ) : null}
          </div>
        </div>
      </BloomkidzLayout>
    );
  }
}

BloomkidzMeanAndNutrition1.propTypes = {
  classes: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  updatePRO: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const {
    proForms: { bloomkidzMealAndNutrition },
  } = state;

  return { bloomkidzMealAndNutrition };
};

export default connect(mapStateToProps, { updatePRO })(withStyles(styles)(BloomkidzMeanAndNutrition1));
