import React from 'react';
import { LinearProgress, MenuItem, Select } from '@material-ui/core';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import AppBar from '../components/app-bar';
import Page from './page';
import QuestionLayout from '../layouts/question';
import YesNoButtonSet from '../components/yes-no-button-set';
import i18nTranslator, { title, options, nextBtn, selectDefault } from '../lib/i18next';
import { colors } from '../lib/styles';
import { submitPRO, updatePRO } from '../state/pro-forms';
import { Doctor } from '../lib/icons';

const styles = {
  contentWrapper: {
    flexGrow: 1,
    overflowX: 'hidden',
    position: 'relative',
  },
  imgRow: {
    display: 'flex',
    justifyContent: 'center',
    padding: '15px 60px',
  },
  imageWrapper: {
    width: 100,
  },
  questionnaireContainer: {
    paddingLeft: 20,
    paddingRight: 20,
  },
  questionText: {
    fontSize: 29,
    marginTop: 25,
  },
  select: {
    fontSize: 29,
    marginTop: 5,
  },
};

class PatientDemographics8 extends Page {
  constructor(props) {
    super(props);

    this.state = {
      hasProvider: '',
      physicianVisitDate: '',
      reasonNoVisit: '',
    };
  }

  handleChangeReasonNoVisit = (e) => {
    this.setState({
      reasonNoVisit: e.target.value,
      physicianVisitDate: '',
    });
  };

  handleChangeHasProvider = (value) => {
    this.setState({ hasProvider: value });
  };

  handleChangePhysicianVisitDate = (e) => {
    this.setState({
      physicianVisitDate: e.target.value,
      reasonNoVisit: '',
    });
  }

  handleClickNext = () => {
    const { patientDemographics, user } = this.props;
    const {
      physicianVisitDate,
      reasonNoVisit,
      hasProvider,
    } = this.state;
    const { id } = this.props.location.query;

    const value = { hasProvider };

    if (hasProvider === 'Y') {
      value.physicianVisitDate = physicianVisitDate;
    } else {
      value.reasonNoVisit = reasonNoVisit;
    }

    if (hasProvider === 'Y') {
      this.props.updatePRO({
        type: 'patientDemographics',
        position: this.getTrackIndex(),
        value,
      });

      this.forwardWithQuery(this.props.location.query);
    } else {
      this.props.submitPRO({ userId: user.id, id }, {
        pro_type: 'PATIENT-DEMOGRAPHICS',
        pro_data: {
          data: [
            ...patientDemographics,
            value,
          ],
        },
      }, user)
        .then(() => this.finishTrack());
    }
  }

  render() {
    const { classes } = this.props;
    const {
      physicianVisitDate,
      reasonNoVisit,
      hasProvider,
    } = this.state;

    const continueDisabled = !hasProvider
      || (hasProvider === 'Y' && !physicianVisitDate)
      || (hasProvider === 'N' && !reasonNoVisit);

    return (
      <QuestionLayout
        primaryBtnLabel={nextBtn()}
        primaryBtnOnTouchTap={this.handleClickNext}
        primaryBtnDisabled={continueDisabled}
        style={{ backgroundColor: colors.questionBackground }}
      >
        <AppBar
          headerNode={title('demographics8')}
          backButtonOnClick={() => this.props.router.goBack()}
        />
        <LinearProgress
          variant="determinate"
          value={(this.getCurrentPage() / this.getTotalPages()) * 100}
          classes={{ bar: classes.bar }}
        />
        <div className={classes.contentWrapper}>
          <div className={classes.imgRow}>
            <div className={classes.imageWrapper}>
              <Doctor />
            </div>
          </div>
          <div className={classes.questionnaireContainer}>
            <YesNoButtonSet
              isYes={hasProvider === 'Y'}
              isNo={hasProvider === 'N'}
              onClickNo={() => this.handleChangeHasProvider('N')}
              onClickYes={() => this.handleChangeHasProvider('Y')}
              questionText={i18nTranslator('question1', 'demographics8')}
              questionTextSize={29}
            />
            {hasProvider === 'Y' ? (
              <>
                <div className={classes.questionText}>{i18nTranslator('question2', 'demographics8')}</div>
                <Select
                  displayEmpty
                  value={physicianVisitDate}
                  onChange={this.handleChangePhysicianVisitDate}
                  className={classes.select}
                >
                  <MenuItem value=""><i>{selectDefault()}</i></MenuItem>
                  <MenuItem value="Within the last 3 months">{options('threeMonths', 'demographics8')}</MenuItem>
                  <MenuItem value="6 months ago">{options('sixMonths', 'demographics8')}</MenuItem>
                  <MenuItem value="1 year ago">{options('oneYear', 'demographics8')}</MenuItem>
                  <MenuItem value="Over a year ago">{options('overOneYear', 'demographics8')}</MenuItem>
                </Select>
              </>
            ) : null}
            {hasProvider === 'N' ? (
              <>
                <div className={classes.questionText}>{i18nTranslator('question3', 'demographics8')}</div>
                <Select
                  displayEmpty
                  value={reasonNoVisit}
                  onChange={this.handleChangeReasonNoVisit}
                  className={classes.select}
                >
                  <MenuItem value=""><i>{selectDefault()}</i></MenuItem>
                  <MenuItem value="No Time">{options('time', 'demographics8')}</MenuItem>
                  <MenuItem value="No Insurance">{options('insurance', 'demographics8')}</MenuItem>
                  <MenuItem value="No Availability">{options('availability', 'demographics8')}</MenuItem>
                  <MenuItem value="Cost">{options('cost', 'demographics8')}</MenuItem>
                  <MenuItem value="Other">{options('other', 'demographics8')}</MenuItem>
                </Select>
              </>
            ) : null}
          </div>
        </div>
      </QuestionLayout>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    proForms: {
      patientDemographics,
    },
    user,
  } = state;

  return { patientDemographics, user };
};

export default connect(mapStateToProps, { submitPRO, updatePRO })(withStyles(styles)(PatientDemographics8));
