import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';

import { colors } from '../lib/styles';
import State from './state';

const baseStyles = {
  blueText: {
    color: colors.primaryColor,
  },
  customWidth: {
    width: 100,
  },
  root: {
    marginBottom: 20,
    marginLeft: 15,
    marginTop: 20,
    margin: '20px 15px 20px 15px !important',
    width: 256,
    '& input': {
      color: colors.greyText,
      fontSize: 18,
    },
    '& label': {
      color: colors.greyText,
      fontSize: 18,
    },
  },
  zipCode: {
    width: 100,
  },
};

class StreetAddress extends Component {
  render() {
    const {
      classes,
      onChangeAddress,
      onChangeCity,
      onChangeState,
      onChangeZip,
      patientContactInfo: {
        address,
        city,
        state,
        zip_code,
      },
    } = this.props;
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          backgroundColor: colors.white,
        }}
      >
        <TextField
          label="Street Address"
          onChange={onChangeAddress}
          classes={{ root: classes.root }}
          value={address}
        />
        <TextField
          label="City Name"
          onChange={onChangeCity}
          classes={{ root: classes.root }}
          value={city}
        />
        <section style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'left', maxWidth: 400, margin: '0px 0px 15px 15px' }}>
          <div style={{ marginTop: 20 }}>
            <State
              value={state}
              onChange={onChangeState}
            />
          </div>
          <TextField
            label="Postal Code"
            onChange={onChangeZip}
            classes={{ root: `${classes.root} ${classes.zipCode}` }}
            value={zip_code}
            type="tel"
          />
        </section>
      </div>
    );
  }
}

StreetAddress.propTypes = {
  classes: PropTypes.object.isRequired,
  onChangeAddress: PropTypes.func.isRequired,
  onChangeCity: PropTypes.func.isRequired,
  onChangeState: PropTypes.func.isRequired,
  onChangeZip: PropTypes.func.isRequired,
  patientContactInfo: PropTypes.object.isRequired,
};

export default withStyles(baseStyles)(StreetAddress);
