import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { omit } from 'lodash';
import moment from 'moment';
import { DatePicker } from '@material-ui/pickers';
import { withStyles } from '@material-ui/core/styles';
import {
  Button,
  FormControl,
  FormControlLabel,
  LinearProgress,
  Radio,
  RadioGroup,
  TextField,
} from '@material-ui/core';

import AppBar from '../components/app-bar';
import Page from './page';
import Layout from '../layouts/common';
import Continue from '../components/continue';
import { colors } from '../lib/styles';
import { updatePRO } from '../state/pro-forms';

const baseStyles = {
  appBar: {
    height: '8.75rem',
    paddingLeft: '1.5rem',
    paddingRight: '1.5rem',
  },
  backButton: {
    left: '-1.25rem',
    height: '3.6875rem',
    padding: 0,
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    width: '3.6875rem',
  },
  backButtonIcon: {
    height: '3.6875rem',
    width: '3.6875rem',
  },
  bigArrowsLightBlue: {
    width: '60px',
    height: '60px',
    color: colors.primaryHighlight,
  },
  confirmBtn: {
    marginBottom: '7.5vw',
  },
  errorText: {
    fontSize: '1.5rem',
  },
  datePickerTextField: {
    color: 'black',
    width: '80vw',
  },
  headachePainTypeSect: {
    alignItems: 'center',
    backgroundColor: colors.lightBrightBlue,
    display: 'flex',
    height: '95px',
    justifyContent: 'space-between',
  },
  header: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    width: '100%',
  },
  headerTitle: {
    color: colors.black,
    display: 'inline-block',
    fontWeight: 'bold',
    fontSize: '1.8rem',
    textAlign: 'left',
    width: '100%',
  },
  intensityHeader: {
    background: colors.primaryColor,
    color: colors.white,
    fontSize: '2rem',
    padding: '2rem 0',
    textAlign: 'center',
  },
  mainContent: {
    flexGrow: 1,
  },
  content: {
    textAlign: 'left',
    padding: '20px',
  },
  nextButton: {
    position: 'absolute',
    right: 5,
    textAlign: 'right',
    top: '50%',
    transform: 'translateY(-50%)',
  },
  nextButtonLabel: {
    color: colors.black,
    fontSize: '1.6rem',
    padding: 0,
    textTransform: 'none',
  },
  red: {
    color: colors.errorRed,
  },
  rootContainer: {
    display: 'flex',
    minHeight: '100%',
  },
  slider: {
    background: colors.primaryColor,
    marginBottom: '2rem',
    paddingBottom: '1rem',
  },
  sliderHeader: {
    color: colors.white,
    fontSize: '1.5rem',
  },
  sliderWrapper: {
    paddingTop: '2.5rem',
  },
  sliderWrapperContainer: {
    height: '6.5rem',
    marginTop: '2rem',
    position: 'relative',
  },
  textField: {
    width: 256,
  },
  triggersQuestion: {
    fontSize: '1.5rem',
    marginBottom: '1rem',
    marginTop: '4rem',
  },
};

const classStyles = {
  datePicker: {
    width: 160,
  },
};

const AppBarContinueButton = props => (
  <Button
    onClick={props.onClick}
    style={{ ...baseStyles.nextButton, ...baseStyles.nextButtonLabel }}
  >Next
  </Button>
);
AppBarContinueButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

class BodyPainPro5 extends Page {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleDurationChange = this.handleChange.bind(this, 'duration');
    this.handlePainOngoingChange = this.handlePainOngoingChange.bind(this, 'painStopped');
    this.handleDateUpdate = this.handleDateUpdate.bind(this, 'pain_resolved');
    this.handleCommentsChange = this.handleChange.bind(this, 'comments');
    this.handleContinue = this.handleContinue.bind(this);
    this.handleClickBack = this.handleClickBack.bind(this);
  }

  handleChange(field, evt) {
    this.setState({
      [field]: evt.target.value,
    });
  }

  handlePainOngoingChange(field, event) {
    const value = event.target.value === 'true';
    this.setState({
      [field]: value,
    });
  }

  // handleDateUpdate(field, e, date) {
  handleDateUpdate(field, date) {
    this.setState({
      [field]: date,
    });
  }

  componentWillMount() {
    this.setState({
      duration: '',
      painStopped: '',
      pain_resolved: new Date(),
      comments: '',
    });
  }

  handleClickBack() {
    this.backwardWithQuery(this.props.location.query);
  }

  handleContinue() {
    const { painStopped } = this.state;
    let pain_resolved = moment.utc(this.state.pain_resolved);

    if (painStopped === false) {
      pain_resolved = null;
    }

    const payload = omit(this.state, ['user_id', 'pain_locations', 'painStopped']);
    this.props.updatePRO({ type: 'bodyPain', position: this.getTrackIndex(), value: { ...payload, pain_resolved } });
    this.forwardWithQuery(this.props.location.query);
  }

  render() {
    const {
      duration,
      painStopped,
      pain_resolved,
      comments,
    } = this.state;

    const now = new Date();

    const { bodyPain, classes } = this.props;
    const { locations } = bodyPain[1];
    const header = locations.length === 1 ? `${locations[0].name}` : `${locations[0].name} to ${locations[1].name}`;

    return (
      <Layout style={baseStyles.rootContainer}>
        <div style={baseStyles.mainContent}>
          <AppBar
            backButtonOnClick={() => this.props.router.goBack()}
            headerNode={header}
            rightNode={(
              <AppBarContinueButton
                onClick={this.handleContinue}
              />
            )}
          />
          <LinearProgress
            variant="determinate"
            value={(this.getCurrentPage() / this.getTotalPages()) * 100}
          />
          <div style={baseStyles.content}>
            <p style={baseStyles.headerTitle}>How long have you had this pain?</p>
            <FormControl component="fieldset">
              <RadioGroup aria-label="painDuration" name="painDuration" value={duration} onChange={this.handleDurationChange}>
                {bodyPain[0] === 'newPain' ? (
                  <Fragment>
                    <FormControlLabel value="less than 24 hours" control={<Radio />} label="24 hours or less" />
                    <FormControlLabel value="1-2 days" control={<Radio />} label="25 - 48 hours (1-2 days)" />
                    <FormControlLabel value="2-3 days" control={<Radio />} label="49 - 72 hours (2-3 days)" />
                    <FormControlLabel value="3-4 days" control={<Radio />} label="73-96 hours (3-4 days)" />
                    <FormControlLabel value="over 4 days" control={<Radio />} label="over 96 hours (4+ days)" />
                  </Fragment>
                ) : (
                  <Fragment>
                    <FormControlLabel value="less than 1 month" control={<Radio />} label="Less than 1 month" />
                    <FormControlLabel value="1-6 months" control={<Radio />} label="1-2 months" />
                    <FormControlLabel value="6-12 months" control={<Radio />} label="6 months - 1 year" />
                    <FormControlLabel value="over 1 year" control={<Radio />} label="Over 1 year" />
                    <FormControlLabel value="over 5 years" control={<Radio />} label="Over 5 years" />
                  </Fragment>
                )}
              </RadioGroup>
            </FormControl>
            <p style={baseStyles.headerTitle}>Did this pain stop?</p>
            <FormControl component="fieldset">
              <RadioGroup aria-label="painStopped" name="painStopped" value={String(painStopped)} onChange={this.handlePainOngoingChange}>
                <Fragment>
                  <FormControlLabel value="false" control={<Radio />} label="No, it is ongoing" defaultValue="top" />
                  <FormControlLabel value="true" control={<Radio />} label="Yes" />
                </Fragment>
              </RadioGroup>
              <div className={`${!painStopped && 'hidden'}`} style={baseStyles.titleGroup}>
                <p style={baseStyles.headerTitle}>When did this pain stop?</p>
                <DatePicker
                  autoOk={true}
                  defaultDate={now}
                  format="MM/dd/yyyy"
                  label="Pain start date (required)"
                  orientation="portrait"
                  maxDate={now}
                  onChange={this.handleDateUpdate}
                  value={pain_resolved}
                  className={classes.datePicker}
                />
              </div>
            </FormControl>
            <p style={baseStyles.headerTitle}>Would you like to add anything else about your pain?</p>
            <TextField
              id="comments"
              label="Tap here to type a comment"
              name="comments"
              value={comments}
              onChange={this.handleCommentsChange}
              style={baseStyles.textField}
            />
          </div>
        </div>
        <section style={baseStyles.confirmBtn}>
          <Continue
            onClick={this.handleContinue}
            text="Confirm"
          />
        </section>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  const { user, proForms: { bodyPain } } = state;
  return { user, bodyPain };
}

BodyPainPro5.proptypes = {
  user: PropTypes.object.isRequired,
  bodyPain: PropTypes.array.isRequired,
  route: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, { updatePRO })(withStyles(classStyles)(BodyPainPro5));
