import React from 'react';
import { Trans } from 'react-i18next';

// pro pages with no predetermined keys. ie. i18nTranslator('[key here]', '[locale-text component here]')

const i18nTranslator = (key, component) => <Trans i18nKey={key} ns={component} />;

export const options = (option, component) => <Trans i18nKey={`options.${option}`} ns={component} />;

// notification page. ie. introHeaderText('[pro]-notification-page')

export const introHeaderText = component => <Trans i18nKey="introHeaderText" ns={component} />;

export const introBodyText = component => <Trans i18nKey="introBodyText" ns={component} />;

export const loggedInSuccessText = component => <Trans i18nKey="loggedInSuccessText" ns={component} />;

export const loggedOutSuccessText = component => <Trans i18nKey="loggedOutSuccessText" ns={component} />;

// pro pages with predetermined keys. ie. title('[locale-text pro here]')

export const title = component => <Trans i18nKey="title" ns={component} />;

export const subtitle = component => <Trans i18nKey="subtitle" ns={component} />;

export const instructions = component => <Trans i18nKey="instructions" ns={component} />;

export const healthy = component => <Trans i18nKey="healthy" ns={component} />;

export const mild = component => <Trans i18nKey="mild" ns={component} />;

export const severe = component => <Trans i18nKey="severe" ns={component} />;

// general use predetermined keys & components. ie. nextBtn()

export const selectDefault = () => <Trans i18nKey="selectDefault" ns="general" />;

export const nextBtn = () => <Trans i18nKey="nextBtn" ns="general" />;

export const startBtn = () => <Trans i18nKey="startBtn" ns="general" />;

export const state = () => <Trans i18nKey="state" ns="general" />;

export const yesLabel = () => <Trans i18nKey="yes" ns="general" />;

export const noLabel = () => <Trans i18nKey="no" ns="general" />;

export const maybeLabel = () => <Trans i18nKey="unsure" ns="general" />;

export const notApplicableLabel = () => <Trans i18nKey="notApplicable" ns="general" />;

export default i18nTranslator;
