import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

import { personalInfoIfNeeded } from '../state/personal-info';
import { browseFormCompletion } from '../state/form-completion';
import { updateAvatarUrl } from '../state/user';
import { colors } from '../lib/styles';
import UserAvatar from './user-avatar';

const baseStyles = {
  headerStyle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  header: {
    color: colors.primaryColor,
  },
  bigArrowsLightBlue: {
    width: 40,
    height: 40,
    color: colors.lightBrightBlue,
    alignSelf: 'flex-end',
  },
  avatar: {
    marginLeft: '15px',
    cursor: 'pointer',
  },
  userInfo: {
    marginLeft: '15px',
  },
  arrowRight: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
};

class UserRow extends Component {
  componentWillMount() {
    this.props.browseFormCompletion();
    this.props.personalInfoIfNeeded();
  }

  render() {
    const { user } = this.props;
    const { data: personalInfo } = this.props.personalInfo;
    return (
      <div style={baseStyles.headerStyle}>
        <header style={baseStyles.header}>
          <UserAvatar
            updateAvatarUrl={this.props.updateAvatarUrl}
            user={this.props.user}
            router={this.props.router}
          />
        </header>
        <div>
          <p style={baseStyles.userInfo}>{personalInfo.first_name} {personalInfo.last_name}</p>
          <p>{user.username}</p>
        </div>
        <div style={baseStyles.arrowRight}>
          <KeyboardArrowRightIcon
            style={baseStyles.bigArrowsLightBlue}
            onClick={() => this.props.router.push('/patientcontact')}
          />
        </div>
      </div>
    );
  }
}

UserRow.propTypes = {
  user: PropTypes.object.isRequired,
  browseFormCompletion: PropTypes.func.isRequired,
  personalInfoIfNeeded: PropTypes.func.isRequired,
  personalInfo: PropTypes.object.isRequired,
  updateAvatarUrl: PropTypes.func.isRequired,
  router: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  const { user, formCompletion, personalInfo } = state;
  return {
    user,
    formCompletion,
    personalInfo,
  };
}

export default connect(mapStateToProps, { browseFormCompletion, personalInfoIfNeeded, updateAvatarUrl })(UserRow);
