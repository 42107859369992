import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import { connect } from 'react-redux';
import { Button, TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { get, noop } from 'lodash';
import Checkbox from '@material-ui/core/Checkbox';

import AuthLayout from '../layouts/auth';
import { logEvent } from '../lib/amplitude';
import { NotPatientLoginError } from '../lib/errors';
import { getCordovaData } from '../lib/cordova-helpers';
import AppBar from '../components/app-bar';
import Page from './page';
import { login } from '../state/user';
import { colors, custom } from '../lib/styles';
import Informed from '../components/informed';
import brand from '../lib/brand';
import { throttledReset } from '../initializers/activity';

const baseStyles = {
  btn: {
    backgroundColor: colors.secondaryColor,
  },
  checkbox: {
    display: 'flex',
    alignSelf: 'flex-start',
    alignItems: 'center',
    fontSize: '16px',
  },
  h2: {
    margin: '30px auto 0px',
    fontSize: '1.5rem',
  },
  link: {
    color: colors.primaryColor,
    fontWeight: 'bold',
    textAlign: 'left',
    fontSize: '1rem',
  },
};

const classStyles = {
  button: {
    fontSize: 16,
    height: '50px',
    width: '80vw',
    maxWidth: '445px',
    marginTop: '40px',
  },
  textField: {
    marginTop: 5,
  },
};

function mapStateToProps(state) {
  const { user } = state;
  return { user };
}

const messageTemplates = {
  inactive: 'You have been logged out due to inactivity',
  notification: 'Please login to view your notifications',
  email: 'Thank you for confirming your email you may now login.',
};

export class Login extends Page {
  constructor(props) {
    super(props);
    const msgType = get(props, 'location.query.msg', '');

    this.state = {
      error: '',
      msg: messageTemplates[msgType] || '',
      email: window.localStorage.getItem('email') || '',
      password: '',
      remember_me: window.localStorage.getItem('remember_me') === 'true',
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleEnter = this.handleEnter.bind(this);
    this.handleLogin = this.handleLogin.bind(this);
    this.handleUpdateEmail = this.handleChange.bind(this, 'email');
    this.handleUpdatePassword = this.handleChange.bind(this, 'password');

    this.activityTimer = setInterval(throttledReset, 10000);
  }

  componentWillMount() {
    document.title = brand.title;
    const favicon = document.getElementById('favicon');
    favicon.href = `img/${brand.name}/favicon.ico`;
    favicon.onError = (e) => {
      e.target.onerror = null;
      favicon.href = 'img/favicon.ico';
    };
  }

  componentWillUnmount() {
    clearInterval(this.activityTimer);
  }

  handleChange(field, e) {
    this.setState({ [field]: e.target.value });
  }

  handleLogin() {
    const { email, password } = this.state;
    if (!email || !password) {
      return this.setState({
        error: 'Missing Email or Password',
        msg: '',
      });
    }
    this.setState({
      error: '',
      msg: '',
      password: '',
    });

    this.props.login(this.state.email, this.state.password)
      .then(() => {
        this.props.router.push({
          pathname: get(this, 'props.router.location.query.originalPath', '/'),
          search: get(this, 'props.router.location.search', ''),
        });
      })
      .catch((e) => {
        this.setState({ error: (e instanceof NotPatientLoginError) ? e.message : 'Invalid Email or Password' });
      });
  }

  handleRemember = (e, value) => {
    window.localStorage.setItem('remember_me', value);
    this.setState({ remember_me: value });
    window.localStorage.setItem('email', '');
    if (value) {
      window.localStorage.setItem('email', this.state.email);
    }
  }

  handleEnter(e) {
    if (e.key === 'Enter') {
      logEvent('Log in (sign in button)');
      this.handleLogin();
    }
  }

  render() {
    const { email, password } = this.state;
    const { classes } = this.props;

    return (
      <div style={{ background: colors.white }}>
        <AppBar
          backButtonOnClick={() => this.props.router.goBack()}
          headerNode={<Informed />}
          noLeftNode={true}
        />
        <AuthLayout error={this.state.error} msg={this.state.msg}>
          <h2 style={baseStyles.h2}>Patient Login</h2>
          <TextField
            label="Email Address"
            fullWidth
            onChange={this.handleUpdateEmail}
            value={this.state.email}
            onKeyPress={this.handleEnter}
            type="email"
            classes={{ root: classes.textField }}
          />
          <TextField
            label="Password"
            fullWidth
            onChange={this.handleUpdatePassword}
            value={this.state.password}
            onKeyPress={this.handleEnter}
            type="password"
            classes={{ root: classes.textField }}
          />
          {email.length && password.length ? (
            <Button
              color="secondary"
              classes={{ root: classes.button }}
              onClick={this.handleLogin}
              variant="contained"
            >
              Login
            </Button>
          ) : (
            <Button
              color="secondary"
              classes={{ root: classes.button }}
              disabled
              variant="contained"
            >
              Login
            </Button>
          )}
          <div style={baseStyles.checkbox}>
            <Checkbox
              checked={this.state.remember_me}
              onChange={this.handleRemember}
              color="default"
            />
            Remember me
          </div>
          <p style={custom.loginBottomText}>
            <Link
              style={baseStyles.link}
              to="/forgot"
            >
              Forgot your password?
            </Link>
          </p>
          <p style={custom.loginBottomText}>
            <Link
              style={baseStyles.link}
              to="/signup"
            >
              {`Signup as a new user${getCordovaData.isCordova ? '.' : '' }`}
            </Link>
          </p>
        </AuthLayout>
      </div>
    );
  }
}

Login.defaultProps = {
  login: noop,
  router: {},
};

Login.propTypes = {
  login: PropTypes.func,
  router: PropTypes.object,
};

export default connect(mapStateToProps, { login })(withStyles(classStyles)(Login));
