import React from 'react';
import { connect } from 'react-redux';
import { omit } from 'lodash';
import Page from './page';
import Layout from '../layouts/common';
import AppBar from '../components/app-bar';
import MigraineSymptomsPresentation from '../components/migraine-symptoms-presentation';

import { browseMigraineSymptoms } from '../state/app-data';
import { updatePRO } from '../state/pro-forms';

class MigraineSymptoms extends Page {
  constructor(props) {
    super(props);

    this.handleAddItem = this.handleAddItem.bind(this);
    this.handleRemoveItem = this.handleRemoveItem.bind(this);
    this.handleContinue = this.handleContinue.bind(this);
    this.handleBack = this.handleBack.bind(this);
  }

  componentWillMount() {
    if (!this.props.migraineSymptoms) {
      this.props.browseMigraineSymptoms();
    }
  }

  handleAddItem(migraine_symptoms_id) {
    const newItem = {
      migraine_symptoms_id,
    };
    this.props.updatePRO({
      type: 'headachePain',
      position: this.getTrackIndex(),
      value: {
        ...this.props.migraineSymptomsHx,
        [migraine_symptoms_id]: newItem,
      }
    });
  }

  handleRemoveItem(migraineSymptomId) {
    this.props.updatePRO({
      type: 'headachePain',
      position: this.getTrackIndex(), // Pain information
      value: omit(this.props.migraineSymptomsHx, migraineSymptomId)
    });
  }

  handleContinue() {
    this.forwardWithQuery(this.props.location.query);
  }

  handleBack() {
    this.backwardWithQuery(this.props.location.query);
  }

  onClickBackButton = () => {
    this.backward();
  }

  render() {
    const { migraineSymptoms } = this.props;
    return (
      <Layout>
        <AppBar
          backButtonOnClick={() => this.props.router.goBack()}
          headerNode="Migraine Symptoms"
        />
        <MigraineSymptomsPresentation
          migraineSymptoms={migraineSymptoms}
          selectedItems={this.props.migraineSymptomsHx}
          onAddItem={this.handleAddItem}
          onRemoveItem={this.handleRemoveItem}
          onContinue={this.handleContinue}
        />

      </Layout>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const {
    appData: {
      migraineSymptoms,
    },
    proForms: {
      headachePain,
    },
  } = state;


  return {
    migraineSymptomsHx: headachePain[3] || {},
    migraineSymptoms,
  };
}

MigraineSymptoms.defaultProps = {
  aggravating_factors: {},
  painHxMigraineSymptoms: {},
};

export default connect(mapStateToProps, { browseMigraineSymptoms, updatePRO })(MigraineSymptoms);
