import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  outerWrapper: {
    display: 'flex',
    justifyContent: 'center',
    padding: '15px 60px',
  },
  innerWrapper: {
    height: 100,
    width: 100,
  },
};

const IconWrapper = ({ children, classes }) => (
  <div className={classes.outerWrapper}>
    <div className={classes.innerWrapper}>
      {children}
    </div>
  </div>
);

IconWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(IconWrapper);
