import React from 'react';
import PropTypes from 'prop-types';
import { Button, IconButton, LinearProgress } from '@material-ui/core/';
import MenuIcon from '@material-ui/icons/Menu';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { browserHistory } from 'react-router';

import AppBar from '../components/app-bar';
import Page from './page';
import { colors, desktopMaxWidth, fontSizing } from '../lib/styles';
import Logo from '../components/logo';
import { submitPRO } from '../state/pro-forms';
import env from '../config';

const { vitalCoreConfig } = env;

const disabledBackground = '#d9d8d8';
const disabledText = '#9b9b9b';

const styles = {
  agreeButton: {
    background: colors.primaryColor,
    borderRadius: 5,
    borderWidth: 0,
    color: 'white',
    flexGrow: 1,
    fontSize: fontSizing.body,
    marginRight: 5,
    padding: 10,
    '&:disabled:': {
      background: disabledBackground,
      color: disabledText,
    },
    '@media (hover: none)': {
      '&:hover': {
        background: colors.primaryColor,
        color: 'white',
      },
    },
  },
  buttonRow: {
    display: 'flex',
    paddingBottom: 20,
    paddingTop: 20,
    width: 'calc(100% - 20px)',
  },
  declineButton: {
    background: '#707070',
    borderRadius: 5,
    borderWidth: 0,
    color: 'white',
    flexGrow: 1,
    fontSize: fontSizing.body,
    padding: 10,
    '&:disabled:': {
      background: disabledBackground,
      color: disabledText,
    },
    '@media (hover: none)': {
      '&:hover': {
        background: '#707070',
        color: 'white',
      },
    },
  },
  header: {
    fontWeight: 'bold',
    marginTop: 20,
  },
  linearProgress: {
    background: colors.lightBrightBlue,
  },
  pageContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    backgroundColor: colors.questionBackground,
  },
  pageContent: {
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: desktopMaxWidth,
    paddingLeft: 10,
    paddingRight: 10,
    backgroundColor: colors.questionBackground,
  },
  pageContentContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    fontSize: fontSizing.body,
    overflowX: 'scroll',
    backgroundColor: colors.questionBackground,
  },
};

class VitalCore3 extends Page {
  constructor(props) {
    super(props);

    const consentedToRecording = window.localStorage.getItem('vitalCoreConsentedToRecording');
    const vitalCoreVisitedConsentToRecord = window.localStorage.getItem('vitalCoreVisitedConsentToRecord');
    const consentToRecordVersion = window.localStorage.getItem('consentToRecordVersion');
    const historyAction = props.router.location.action;

    const sameConsentVersion = consentToRecordVersion
      && consentToRecordVersion === vitalCoreConfig.consentToRecordVersion;

    if (consentedToRecording && !vitalCoreVisitedConsentToRecord && historyAction === 'PUSH' && sameConsentVersion) {
      this.goToIndexWithQuery(4, props.location.query);
    } else if (consentedToRecording && !vitalCoreVisitedConsentToRecord && historyAction === 'POP') {
      this.props.router.goBack();
    }
  }

  declinedConsentToRecord = async () => {
    const { location, user } = this.props;
    const { id } = location.query;

    window.localStorage.setItem('vitalCoreConsentedToRecording', false);
    window.localStorage.setItem('vitalCoreVisitedConsentToRecord', true);

    await submitPRO({ userId: user.id, id }, {
      pro_type: 'VITAL-CORE',
      pro_data: {
        data: { declinedConsentToRecord: true },
      },
      declined: true,
    }, user);

    this.goToIndexWithQuery(3, location.query);
  }

  handleClickAgree = () => {
    window.localStorage.setItem('vitalCoreConsentedToRecording', true);
    window.localStorage.setItem('vitalCoreVisitedConsentToRecord', true);
    window.localStorage.setItem('consentToRecordVersion', vitalCoreConfig.consentToRecordVersion);

    this.goToIndexWithQuery(4, this.props.location.query);
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.pageContainer}>
        <div className={classes.pageContentContainer}>
          <AppBar
            headerNode="Consent to Record Vitals"
            backButtonOnClick={() => this.props.router.goBack()}
            rightNode={(
              <IconButton onClick={() => browserHistory.push('/core-vital-user-manual-menu')}>
                <MenuIcon fontSize="large" />
              </IconButton>
            )}
          />
          <LinearProgress
            variant="determinate"
            value={(this.getCurrentPage() / this.getTotalPages()) * 100}
            classes={{ bar: classes.linearProgress }}
          />
          <div className={classes.pageContent}>
            <Logo />
            <div className={classes.header}>
              PLEASE READ THIS CONSENT CAREFULLY BEFORE USING INFORMED VITAL CORE.
            </div>
            <p>
              By clicking accept, I acknowledge and agree that I have fully read the Informed Consent for Informed Vital Core and agree to have my vital signs recorded and sent to my provider.
            </p>
            <div className={classes.header}>Consent to Record Vital Signs</div>
            <ul>
              <li>The transmission of my health information may experience a technical failure and result in the loss of information obtained.</li>
              <li>I hold harmless my provider and all parties involved in the recording my vital signs against any liability, damages, loss, attorney fees and costs of any type due to the loss of information due to a technical failure.</li>
              <li>I authorize the use of my information obtained to be used for treatment, payment, and health care operations.</li>
              <li>I understand that I will not have contact with the provider while my vital signs are recorded.</li>
              <li>I have the right to withhold or withdraw my consent or refuse to have my vital signs recorded at any time.</li>
              <li>I understand that my withdrawal of consent will not affect any future care or treatment, nor will it subject me to the risk of loss or withdrawal of any health benefits to which I am otherwise receiving.</li>
              <li>I have the right to inspect all information obtained and recorded and may receive copies of this information upon written request.</li>
              <li>I understand that no video or personal image is transmitted to my provider as part of recording your vital signs.</li>
              <li>My personal health information may be shared through electronic communication with other medical providers involved in my treatment.</li>
              <li>A copy of this consent will become a part of my medical record.</li>
            </ul>
            <p>If you’re not feeling well, you should talk to your provider. Symptoms such as a rapid, pounding, or fluttering heartbeat, dizziness, or fainting, can indicate a serious condition.</p>
          </div>
        </div>
        <div className={`${classes.buttonRow} ${classes.pageContent}`}>
          <Button
            className={classes.agreeButton}
            variant="contained"
            onClick={this.handleClickAgree}
          >
            Agree
          </Button>
          <Button
            className={classes.declineButton}
            variant="contained"
            onClick={this.declinedConsentToRecord}
          >
            Decline
          </Button>
        </div>
      </div>
    );
  }
}

VitalCore3.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const { user } = state;

  return { user };
};

export default connect(mapStateToProps, { submitPRO })(withStyles(styles)(VitalCore3));
