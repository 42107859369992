import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import AppBar from '../components/app-bar';
import Page from './page';
import QuestionLayout from '../layouts/question';
import YesNoButtonSet from '../components/yes-no-button-set';
import { colors } from '../lib/styles';
import { submitPRO, updatePRO } from '../state/pro-forms';
import { Lungs } from '../lib/icons';

const styles = {
  contentWrapper: {
    flexGrow: 1,
    overflowX: 'hidden',
    position: 'relative',
  },
  imgRow: {
    display: 'flex',
    justifyContent: 'center',
    padding: '15px 60px',
  },
  imageWrapper: {
    width: 100,
  },
  inputField: {
    width: '6rem',
  },
  instructions: {
    color: 'grey',
    fontSize: '1.5rem',
    margin: '20px 40px 0px 40px',
    textAlign: 'center',
  },
  leaveBlankText: {
    fontSize: '1.25rem',
    fontStyle: 'italic',
    marginTop: 10,
  },
  questionnaireContainer: {
    paddingLeft: 20,
    paddingRight: 20,
  },
  questionText: {
    fontSize: '2rem',
    marginTop: 25,
  },
  sectionText: {
    fontSize: '2rem',
    fontWeight: 'bold',
  },
  textFieldContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  textFieldWrapper: {
    alignItems: 'center',
    display: 'flex',
    paddingTop: 20,
  },
  unitsWrapper: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    marginLeft: 5,
  },
};

class HeadAndNeckCancerSymptomsNoVitals13 extends Page {
  constructor(props) {
    super(props);

    this.state = {
      shortnessOfBreath: null,
      worseWithActivity: null,
    };
  }

  handleChangeShortnessOfBreath = (value) => {
    this.setState({ shortnessOfBreath: value });
  };

  handleChangeWorseWithActivity = (value) => {
    this.setState({ worseWithActivity: value });
  }

  handleClickNext = () => {
    const {
      headAndNeckCancerSymptoms,
      location,
      submitPRO,
      user,
    } = this.props;
    const { shortnessOfBreath, worseWithActivity } = this.state;
    const { id } = location.query;

    const value = { shortnessOfBreath, worseWithActivity };

    submitPRO(
      { userId: user.id, id },
      {
        pro_data: { data: [...headAndNeckCancerSymptoms, value] },
        pro_type: 'HEAD-AND-NECK-CANCER-SYMPTOMS-NO-VITALS',
      },
      user,
    )
      .then(() => {
        this.finishTrack();
      });
  }

  render() {
    const { classes } = this.props;
    const { shortnessOfBreath, worseWithActivity } = this.state;

    const continueDisabled = shortnessOfBreath === null || worseWithActivity === null;

    return (
      <QuestionLayout
        primaryBtnLabel="Next"
        primaryBtnOnTouchTap={this.handleClickNext}
        primaryBtnDisabled={continueDisabled}
        style={{ backgroundColor: colors.questionBackground }}
      >
        <AppBar
          headerNode="Breathing"
          backButtonOnClick={() => this.props.router.goBack()}
        />
        <LinearProgress
          variant="determinate"
          value={(this.getCurrentPage() / this.getTotalPages()) * 100}
          classes={{ bar: classes.bar }}
        />
        <div className={classes.contentWrapper}>
          <div className={classes.instructions}>
            Please select the option that best answers the questions relating to breathing
          </div>
          <div className={classes.imgRow}>
            <div className={classes.imageWrapper}>
              <Lungs />
            </div>
          </div>
          <div className={classes.questionnaireContainer}>
            <div className={classes.sectionText}>In the last 12 hours...</div>
            <YesNoButtonSet
              isYes={shortnessOfBreath === 'Y'}
              isNo={shortnessOfBreath === 'N'}
              onClickNo={() => this.handleChangeShortnessOfBreath('N')}
              onClickYes={() => this.handleChangeShortnessOfBreath('Y')}
              questionText="Have you had shortness of breath?"
            />
            <YesNoButtonSet
              isYes={worseWithActivity === 'Y'}
              isNo={worseWithActivity === 'N'}
              onClickNo={() => this.handleChangeWorseWithActivity('N')}
              onClickYes={() => this.handleChangeWorseWithActivity('Y')}
              questionText="Does it get worse with activity?"
            />
          </div>
        </div>
      </QuestionLayout>
    );
  }
}

function mapStateToProps(state) {
  const {
    proForms: {
      headAndNeckCancerSymptoms,
    },
    user,
  } = state;
  return { headAndNeckCancerSymptoms, user };
}

export default connect(mapStateToProps, { submitPRO, updatePRO })(withStyles(styles)(HeadAndNeckCancerSymptomsNoVitals13));
