import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { includes, forEach, pickBy, replace, assign, omit } from 'lodash';
import {
  FormControl,
  FormGroup,
  FormLabel,
  FormControlLabel,
  Checkbox,
  TextField,
} from '@material-ui/core';

import Page from './page';
import AppBar from '../components/app-bar';
import Hidden from '../components/hidden-content';
import { colors } from '../lib/styles';
import QuestionLayout from '../layouts/question';
import { updatePRO } from '../state/pro-forms';

const { questionBackground } = colors;

const styles = {
  contentWrapper: {
    flexGrow: 1,
    overflowX: 'hidden',
    position: 'relative',
  },
  instructions: {
    color: 'grey',
    fontSize: '1.5rem',
    margin: '20px 40px 0px 40px',
    textAlign: 'center',
  },
  pageIndicator: {
    fontSize: '1.4rem',
  },
  questionnaire: {
    display: 'flex',
    flexDirection: 'column',
    width: '80%',
    margin: '50px auto',
    position: 'relative',
  },
  formGroup: {
    margin: '25px',
  },
  helperText: {
    color: colors.black,
    fontSize: '12px',
  },
};

class Covid19_4 extends Page {
  state = {
    symptoms: {
      'None': false,
      'Difficulty Breathing': false,
      'Cough': false,
      'Sore Throat': false,
      'Shortness of Breath': false,
      'Runny Nose': false,
      'otherDetails': false,
    },
    other: false,
  };

  componentDidMount() {
    const symptoms = this.props.covid19[this.getTrackIndex()] || [];
    const oldSymptoms = {};
    forEach(symptoms, (value) => {
      oldSymptoms[value] = true;
    });
    this.setState({ symptoms: assign({}, this.state.symptoms, oldSymptoms) });
  }

  handleSubmission = () => {
    const trackIndex = this.getTrackIndex();
    let value = pickBy(this.state.symptoms, (v) => v)
    if (value.otherDetails) {
      const other = value.otherDetails;
      value = omit(value, ['otherDetails']);
      value[other] = true;
    }
    this.props.updatePRO({
      type: 'covid19',
      position: trackIndex,
      value: Object.keys(value),
    });
    if (this.state.symptoms['None']) {
      this.props.updatePRO({ position: 4, type: 'covid19', value: 'N/A' });
      const path = replace(this.props.location.pathname, 'COVID-19-4', 'COVID-19-6');
      return this.props.router.push(`${path}${this.props.location.search}`);
    }
    this.forwardWithQuery(this.props.location.query);
  }

  handleChange = (event) => {
    let symptoms = { ...this.state.symptoms };
    let state = {...this.state};
    if (event.target.name === 'None') {
      forEach(symptoms, (val, key) => {
        symptoms[key] = false;
      });
      state.other = false;
    }
    if (event.target.name !== 'None') {
      symptoms['None'] = false;
    }
    symptoms[event.target.name] = event.target.checked;
    state.symptoms = symptoms
    this.setState(state);
  }

  handleOther = (event) => {
    let symptoms = { ...this.state.symptoms };
    symptoms.otherDetails = false;
    symptoms.None = false;
    this.setState({ other: event.target.checked, symptoms })
  }

  handleOtherDetails = (event) => {
    let symptoms = { ...this.state.symptoms };
    symptoms.otherDetails = event.target.value;
    this.setState({ symptoms });
  }

  isBtnDisabled = () => {
    if (this.state.other && !this.state.symptoms.otherDetails) {
      return true;
    }

    if (!includes(this.state.symptoms, true) && !this.state.other) {
      return true
    }

    return false;
  }

  render() {
    const primaryBtnDisabled = this.isBtnDisabled();
    return (
      <QuestionLayout
        primaryBtnDisabled={primaryBtnDisabled}
        primaryBtnLabel="Next"
        primaryBtnOnTouchTap={this.handleSubmission}
        style={{ backgroundColor: questionBackground }}
      >
        <AppBar
          backButtonOnClick={() => this.props.router.goBack()}
          headerNode="COVID-19 Assessment"
        />
        <div style={styles.contentWrapper}>
          <div style={styles.instructions}>
            Do you have respiratory symptoms?
          </div>
          <div style={styles.questionnaire}>
            <FormControl component="fieldset">
              <FormLabel component="legend">Choose all that apply</FormLabel>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox checked={this.state.symptoms['Cough']} onChange={this.handleChange} name="Cough" />}
                  label="Cough"
                />
                <FormControlLabel
                  control={<Checkbox checked={this.state.symptoms['Difficulty Breathing']} onChange={this.handleChange} name="Difficulty Breathing" />}
                  label="Difficulty Breathing"
                />
                <FormControlLabel
                  control={<Checkbox checked={this.state.symptoms['Sore Throat']} onChange={this.handleChange} name="Sore Throat" />}
                  label="Sore Throat"
                />
                <FormControlLabel
                  control={<Checkbox checked={this.state.symptoms['Shortness of Breath']} onChange={this.handleChange} name="Shortness of Breath" />}
                  label="Shortness of Breath"
                />
                <FormControlLabel
                  control={<Checkbox checked={this.state.symptoms['Runny Nose']} onChange={this.handleChange} name="Runny Nose" />}
                  label="Runny Nose"
                />
                <FormControlLabel
                  control={<Checkbox checked={this.state.symptoms['None']} onChange={this.handleChange} name="None" />}
                  label="None"
                />
                <FormControlLabel
                  control={<Checkbox checked={this.state.other} onChange={this.handleOther} name="Other" />}
                  label="Other"
                />
              </FormGroup>
            </FormControl>
            <Hidden hidden={!this.state.other}>
              <TextField
                id="standard-multiline-flexible"
                multiline
                value={this.state.symptoms.otherDetails || ''}
                onChange={this.handleOtherDetails}
                style={{ width: '300px' }}
              />
            </Hidden>
          </div>
        </div>
      </QuestionLayout>
    );
  }
}

function mapStateToProps(state) {
  const { proForms: { covid19 } } = state;
  return { covid19 };
}

Covid19_4.propTypes = {
  covid19: PropTypes.array.isRequired,
  updatePRO: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { updatePRO })(withStyles(styles)(Covid19_4));
