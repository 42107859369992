import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import FormLabel from '@material-ui/core/FormLabel';
import LinearProgress from '@material-ui/core/LinearProgress';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import { PhoneAndroid } from '@material-ui/icons';

import AppBar from '../components/app-bar';
import ContinueWithDisable from '../components/continue-with-disable';

import Page from './page';

import { updatePRO, submitPRO } from '../state/pro-forms';

const styles = {
  iconContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    flexGrow: 0,
    flexShrink: 0,
    width: '100%',
    color: '#000',
    paddingTop: 10,
  },
  iconWrapper: {
    width: 100,
  },
  titleGroup: {
    margin: 25,
  },
  text: {
    fontSize: '2rem',
  },
  nextBtn: {
    textAlign: 'center',
    width: '100%',
    top: '90%',
  },
};

class ContactSpo2DataCollection13 extends Page {
  constructor(props) {
    super(props);

    this.state = {
      ui_device: '',
      notes: '',
    };
  }

  handleChangeDeviceName = (e) => {
    const { value } = e.target;
    this.setState({
      ui_device: value,
    });
  };

  handleChangeNotes = (e) => {
    const { value } = e.target;
    this.setState({
      notes: value,
    });
  };

  handleContinue = async () => {
    const {
      user,
      userId,
      location,
      contactSpo2DataCollection: spo2dc,
      submitPRO,
    } = this.props;
    const { id } = location.query;
    const { ui_device, notes } = this.state;

    const data = {
      ...spo2dc[0],
      seconds: spo2dc[1].seconds,
      FPS: spo2dc[1].FPS,
      ...spo2dc[2],
      ...spo2dc[3],
      ...spo2dc[4],
      ...spo2dc[5],
      ...spo2dc[6],
      ...spo2dc[7],
      ...spo2dc[8],
      ...spo2dc[9],
      ui_device,
      notes,
    };

    await submitPRO({ userId, id }, {
      pro_type: 'CONTACT-SPO2-DATA-COLLECTION',
      pro_data: { data },
    }, user);
    this.finishTrack();
  }

  render() {
    const { ui_device, notes } = this.state;
    const { classes } = this.props;

    return (
      <Fragment>
        <AppBar
          headerNode="Device"
          noLeftNode={true}
        />
        <LinearProgress
          variant="determinate"
          value={(this.getCurrentPage() / this.getTotalPages()) * 100}
        />
        <div className={classes.iconContainer}>
          <div className={classes.iconWrapper}>
            <PhoneAndroid style={{ fontSize: 100 }} />
          </div>
        </div>

        <div className={classes.titleGroup}>
          <p className={classes.text}>
            What kind of device did you use?
          </p>
          <FormControl component="fieldset" fullWidth>
            <FormLabel className={classes.text} component="legend">Device</FormLabel>
            <Input
              type="text"
              value={ui_device}
              name="ui_device"
              placeholder="tap here to enter"
              onChange={this.handleChangeDeviceName}
              inputProps={{
                'aria-label': 'other',
              }}
            />
          </FormControl>
        </div>

        <div className={classes.titleGroup}>
          <p className={classes.text}>
            Notes
          </p>
          <FormControl component="fieldset" fullWidth>
            <TextField
              className={classes.textArea}
              placeholder="Please enter any notes or observations related to this data collection session. Tap to enter"
              multiline
              maxRows={10}
              onChange={this.handleChangeNotes}
              value={notes}
              InputLabelProps={{ shrink: true }}
            />
          </FormControl>
        </div>

        <div className={classes.nextBtn}>
          <ContinueWithDisable
            disabled={(!ui_device)}
            text="Next"
            onClick={this.handleContinue}
          />
        </div>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { user, proForms: { contactSpo2DataCollection } } = state;
  return {
    user,
    userId: user.id,
    contactSpo2DataCollection,
  };
}

ContactSpo2DataCollection13.proptypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  updatePRO: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { updatePRO, submitPRO })(withStyles(styles)(ContactSpo2DataCollection13));
