import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import AppBar from '../components/app-bar';
import Page from './page';
import QuestionLayout from '../layouts/question';
import { colors } from '../lib/styles';
import { updatePRO } from '../state/pro-forms';
import { WeightScale } from '../lib/icons';

const styles = {
  contentWrapper: {
    flexGrow: 1,
    overflowX: 'hidden',
    position: 'relative',
  },
  imgRow: {
    display: 'flex',
    justifyContent: 'center',
    padding: '15px 60px',
  },
  imageWrapper: {
    width: 100,
  },
  inputField: {
    width: '6rem',
  },
  instructions: {
    color: 'grey',
    fontSize: '1.5rem',
    margin: '20px 40px 0px 40px',
    textAlign: 'center',
  },
  leaveBlankText: {
    fontSize: '1.25rem',
    fontStyle: 'italic',
    marginTop: 10,
  },
  mainQuestionText: {
    fontSize: '2rem',
    fontWeight: 'bold',
  },
  marginRight40: {
    marginRight: 40,
  },
  marginTop40: {
    marginTop: 40,
  },
  questionnaireContainer: {
    paddingLeft: 20,
    paddingRight: 20,
  },
  radioGroup: {
    marginTop: 10,
  },
  radioLabel: {
    fontSize: '1.5rem',
  },
  unitsWrapper: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    marginLeft: 5,
  },
};

class HeadAndNeckCancerSymptoms5 extends Page {
  constructor(props) {
    super(props);

    this.state = {
      clothesTighter: '',
      weightChange: '',
    };
  }

  handleChangeClothesTighter = (e) => {
    this.setState({ clothesTighter: e.target.value });
  }

  handleChangeWeightChange = (e) => {
    this.setState({ weightChange: e.target.value });
  }

  handleClickNext = () => {
    const { clothesTighter, weightChange } = this.state;

    const value = { weightChange };

    if (weightChange === 'dontKnow') value.clothesTighter = clothesTighter;

    this.props.updatePRO({
      type: 'headAndNeckCancerSymptoms',
      position: this.getTrackIndex(),
      value,
    });

    if (weightChange === 'lost') {
      this.forwardWithQuery(this.props.location.query);
    } else if (weightChange === 'gained') {
      this.goToIndexWithQuery(8, this.props.location.query);
    } else if (weightChange === 'no') {
      this.goToIndexWithQuery(10, this.props.location.query);
    } else {
      if (clothesTighter === 'yes') {
        return this.goToIndexWithQuery(8, this.props.location.query);
      }
      this.goToIndexWithQuery(10, this.props.location.query);
    }
  };

  render() {
    const { classes } = this.props;
    const { clothesTighter, weightChange } = this.state;

    const continueDisabled = weightChange === '' || (weightChange === 'dontKnow' && clothesTighter === '');

    return (
      <QuestionLayout
        primaryBtnLabel="Next"
        primaryBtnOnTouchTap={this.handleClickNext}
        primaryBtnDisabled={continueDisabled}
        style={{ backgroundColor: colors.questionBackground }}
      >
        <AppBar
          headerNode="Weight Change"
          backButtonOnClick={() => this.props.router.goBack()}
        />
        <LinearProgress
          variant="determinate"
          value={(this.getCurrentPage() / this.getTotalPages()) * 100}
          classes={{ bar: classes.bar }}
        />
        <div className={classes.contentWrapper}>
          <div className={classes.instructions}>
            Please select the option that best answers the questions relating to your weight
          </div>
          <div className={classes.imgRow}>
            <div className={classes.imageWrapper}>
              <WeightScale />
            </div>
          </div>
          <div className={classes.questionnaireContainer}>
            <div className={classes.mainQuestionText}>Has your weight changed in the last 4 days?</div>
            <RadioGroup
              name="weightChange"
              onChange={this.handleChangeWeightChange}
              value={weightChange}
              className={classes.radioGroup}
            >
              <FormControlLabel
                control={<Radio color="primary" />}
                label="Yes, I have lost weight"
                value="lost"
                classes={{
                  label: classes.radioLabel,
                }}
              />
              <FormControlLabel
                control={<Radio color="primary" />}
                label="Yes, I have gained weight"
                value="gained"
                classes={{
                  label: classes.radioLabel,
                }}
              />
              <FormControlLabel
                control={<Radio color="primary" />}
                label="No"
                value="no"
                classes={{
                  label: classes.radioLabel,
                }}
              />
              <FormControlLabel
                control={<Radio color="primary" />}
                label="I don't know"
                value="dontKnow"
                classes={{
                  label: classes.radioLabel,
                }}
              />
            </RadioGroup>
            {weightChange === 'dontKnow' ? (
              <>
                <div className={`${classes.mainQuestionText} ${classes.marginTop40}`}>Do your clothes feel tighter?</div>
                <RadioGroup
                  name="clothesFit"
                  onChange={this.handleChangeClothesTighter}
                  value={clothesTighter}
                  className={classes.radioGroup}
                >
                  <FormControlLabel
                    control={<Radio color="primary" />}
                    label="Yes"
                    value="yes"
                    classes={{
                      label: classes.radioLabel,
                    }}
                  />
                  <FormControlLabel
                    control={<Radio color="primary" />}
                    label="No"
                    value="no"
                    classes={{
                      label: classes.radioLabel,
                    }}
                  />
                </RadioGroup>
              </>
            ) : null}
          </div>
        </div>
      </QuestionLayout>
    );
  }
}

export default connect(null, { updatePRO })(withStyles(styles)(HeadAndNeckCancerSymptoms5));
