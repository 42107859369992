import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get, isNumber, includes, flatten } from 'lodash';

import { TextField } from '@material-ui/core';
import { colors } from '../lib/styles';
import Page from './page';
import { Headaches } from '../lib/icons';
import { updatePRO, submitPRO } from '../state/pro-forms';
import AppBar from '../components/app-bar';
import ReactSlider from '../components/react-slider';
import QuestionLayout from '../layouts/question';
import LinearProgress from '../components/linear-progress';

const styles = {
  image: {
    height: 120,
    padding: '15px 60px',
    textAlign: 'center',
  },
  pageIndicator: {
    color: 'black',
    display: 'inline-block',
    fontSize: 15,
  },
  questionnaire: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginLeft: 15,
    position: 'relative',
    minHeight: '350px',
  },
  title: {
    color: 'black',
    fontSize: '1.5rem',
    fontWeight: 'strong',
    textTransform: 'uppercase',
  },
  number: {
    width: '50px',
    alignSelf: 'center',
  },
  slideContainer: {
    display: 'flex',
    flexDirection: 'column',
    color: colors.darkGrayText,
    fontSize: '12px',
  },
};

const { questionBackground } = colors;

class MidasHeadache extends Page {
  state = {
    submitting: false,
  }

  onClickBackButton = () => {
    this.backward();
  }

  onSubmission = () => {
    if (this.state.submitting) return;
    const { user } = this.props;
    const { id } = this.props.location.query;

    this.setState({ submitting: true });
    this.props.submitPRO({ userId: user.id, id }, {
      pro_type: 'MIDAS',
      pro_data: { data: flatten(this.props.midas) },
    }, user)
      .then(() => this.forward())
      .catch(() => this.setState({ submitting: false }));
  }

  handleChangeInput = (event) => {
    const value = [...get(this.props.midas, this.getTrackIndex(), [null, null])];
    const val = event.target.value;
    let entered = Number(val);
    if (!val && !isNumber(val)) {
      entered = null;
    }

    if (entered > 90) {
      entered = 90;
    }
    if (entered < 0) {
      entered = 0;
    }

    value[0] = entered;

    this.props.updatePRO({
      type: 'midas',
      position: this.getTrackIndex(),
      value,
    });
  }

  handleChangeSlider = (val) => {
    const value = [...get(this.props.midas, this.getTrackIndex(), [null, null])];
    value[1] = val;
    this.props.updatePRO({
      type: 'midas',
      position: this.getTrackIndex(),
      value,
    });
  }

  render() {
    const currentPage = this.getCurrentPage();
    const totalPages = this.getTotalPages();
    const page = get(this.props.midas, this.getTrackIndex(), [null, null]);
    const value = includes(page, null) ? null : 0;
    return (
      <QuestionLayout
        primaryBtnDisabled={!isNumber(value) || this.state.submitting}
        primaryBtnLabel="Next"
        primaryBtnOnTouchTap={this.onSubmission}
        style={{ background: questionBackground }}
      >
        <AppBar
          backButtonOnClick={() => this.props.router.goBack()}
          headerNode="Headache"
          rightNode={
            <div style={styles.pageIndicator}>{`${currentPage} of ${totalPages}`}</div>
          }
        />
        <LinearProgress value={(currentPage / totalPages) * 100} />
        <div style={styles.image}>
          <Headaches />
        </div>
        <div style={styles.questionnaire}>
          <p style={styles.title}>Over the last 3 months</p>
          <p className="instructions">How many days have you had headaches? If your headache lasted more than a day, count each day.</p>
          <TextField
            style={styles.number}
            value={isNumber(page[0]) ? page[0] : ''}
            onChange={this.handleChangeInput}
            type="number"
            margin="normal"
            inputProps={{
              inputMode: 'decimal',
              pattern: '[0-9]*',
              type: 'tel',
              style: { fontSize: '16px', textAlign: 'center' } }}
          />
          <p className="instructions">On a scale of 0 to 10, on average how painful were these headaches?</p>
          <div style={styles.slideContainer}>
            <ReactSlider
              max={10}
              min={0}
              onChangeComplete={this.handleChangeSlider}
              showLabels={false}
              value={page[1]}
            />
            <div style={{ display: 'flex', justifyContent: 'space-between', fontSize: '1.5rem', margin: '0 20px', color: 'black' }}>
              <p>No Pain</p>
              <p>Worst Imaginable Pain</p>
            </div>
          </div>
        </div>
      </QuestionLayout>
    );
  }
}


function mapStateToProps(state) {
  const {
    user,
    proForms: {
      midas,
    },
  } = state;
  return {
    user,
    midas,
  };
}

MidasHeadache.propTypes = {
  midas: PropTypes.array.isRequired,
  updatePRO: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { updatePRO, submitPRO })(MidasHeadache);
