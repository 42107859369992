import React from 'react';
import PropTypes from 'prop-types';

import { calendarStyles as styles } from '../lib/styles';

const CalendarOtherMonthItem = ({ day, dayCount }) => {
  const spaceOnRight = (dayCount % 7) ? styles.spaceOnRight : {};

  const calendarItemStyles = {
    ...styles.calendarItem,
    ...styles.otherMonthDays,
    ...spaceOnRight,
  };

  return (
    <div style={styles.calendarItemWrapper}>
      <div style={styles.heightExpander} />
      <div style={calendarItemStyles}>{day}</div>
    </div>
  );
};
CalendarOtherMonthItem.propTypes = {
  day: PropTypes.number.isRequired,
  dayCount: PropTypes.number.isRequired,
};

export default CalendarOtherMonthItem;
