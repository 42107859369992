import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';
import { connect } from 'react-redux';

import Page from './page';
import AppBar from '../components/app-bar';
import IconWrapper from '../components/icon-wrapper';
import BloomkidzLayout from '../layouts/bloomkidz';
import { ClipboardListSolid } from '../lib/icons';
import { colors, fontSizing } from '../lib/styles';
import { updatePRO } from '../state/pro-forms';
import { bloomkidzStyles, bloomkidzStylesObj } from '../lib/bloomkidz';

const styles = bloomkidzStylesObj;

class BloomkidzNewClient5 extends Page {
  constructor(props) {
    super(props);

    const stateFromRedux = props.bloomkidzNewClient[this.getTrackIndex()] || {};

    this.state = {
      firstHadConcerns: '',
      primaryConcernsToday: '',
      strategiesAddressingConcerns: '',
      desiredSkills: '',
      mostChallengingBehaviors: '',
      eventsBeforeChallengingBehavior: '',
      ...stateFromRedux,
    };
  }

  handleTextFieldChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleContinue = () => {
    const { location, updatePRO } = this.props;

    updatePRO({
      type: 'bloomkidzNewClient',
      position: this.getTrackIndex(),
      value: { ...this.state },
    });

    this.forwardWithQuery(location.query);
  }

  shouldDisableContinueButton = () => {
    const { state } = this;

    const stateVals = Object.values(state);

    for (let i = 0; i < stateVals.length; i++) {
      if (!stateVals[i]) return true;
    }

    return false;
  }

  render() {
    const { classes, router } = this.props;
    const {
      firstHadConcerns,
      primaryConcernsToday,
      strategiesAddressingConcerns,
      desiredSkills,
      mostChallengingBehaviors,
      eventsBeforeChallengingBehavior,
    } = this.state;

    return (
      <BloomkidzLayout
        primaryBtnOnTouchTap={this.handleContinue}
        primaryBtnLabel="Next"
        primaryBtnStyle={{ background: bloomkidzStyles.primaryColor }}
        style={{ backgroundColor: colors.questionBackground }}
        primaryBtnDisabled={this.shouldDisableContinueButton()}
      >
        <AppBar
          headerNode="Habits"
          backButtonOnClick={() => router.goBack()}
        />
        <IconWrapper>
          <ClipboardListSolid />
        </IconWrapper>
        <div className={classes.pageContent}>
          <div>When did you first have concerns about your child?</div>
          <div className={classes.paddingHorizontal}>
            <div className={classes.textFieldWrapper}>
              <TextField
                placeholder="Tap here to type"
                value={firstHadConcerns}
                onChange={this.handleTextFieldChange}
                name="firstHadConcerns"
                variant="standard"
                fullWidth
                inputProps={{ style: { fontSize: fontSizing.body } }}
              />
            </div>
          </div>
          <div className={classes.inputSectionTitle}>
            What are your primary concerns today?
          </div>
          <div className={classes.paddingHorizontal}>
            <div className={classes.textFieldWrapper}>
              <TextField
                placeholder="Tap here to type"
                value={primaryConcernsToday}
                onChange={this.handleTextFieldChange}
                name="primaryConcernsToday"
                variant="standard"
                fullWidth
                inputProps={{ style: { fontSize: fontSizing.body } }}
              />
            </div>
          </div>
          <div className={classes.inputSectionTitle}>
            What strategies or techniques have you been trying independently?
          </div>
          <div className={classes.paddingHorizontal}>
            <div className={classes.textFieldWrapper}>
              <TextField
                placeholder="Tap here to type"
                value={strategiesAddressingConcerns}
                onChange={this.handleTextFieldChange}
                name="strategiesAddressingConcerns"
                variant="standard"
                fullWidth
                inputProps={{ style: { fontSize: fontSizing.body } }}
              />
            </div>
          </div>
          <div className={classes.inputSectionTitle}>
            What specific skills would you like your child to achieve in therapy?
          </div>
          <div className={classes.paddingHorizontal}>
            <div className={classes.textFieldWrapper}>
              <TextField
                placeholder="Tap here to type"
                value={desiredSkills}
                onChange={this.handleTextFieldChange}
                name="desiredSkills"
                variant="standard"
                fullWidth
                inputProps={{ style: { fontSize: fontSizing.body } }}
              />
            </div>
          </div>
          <div className={classes.inputSectionTitle}>
            What behaviors are most challenging for your child?
          </div>
          <div className={classes.paddingHorizontal}>
            <div className={classes.textFieldWrapper}>
              <TextField
                placeholder="Tap here to type"
                value={mostChallengingBehaviors}
                onChange={this.handleTextFieldChange}
                name="mostChallengingBehaviors"
                variant="standard"
                fullWidth
                inputProps={{ style: { fontSize: fontSizing.body } }}
              />
            </div>
          </div>
          <div className={classes.inputSectionTitle}>
            What happens before these challenging behaviors?
          </div>
          <div className={classes.paddingHorizontal}>
            <div className={classes.textFieldWrapper}>
              <TextField
                placeholder="Tap here to type"
                value={eventsBeforeChallengingBehavior}
                onChange={this.handleTextFieldChange}
                name="eventsBeforeChallengingBehavior"
                variant="standard"
                fullWidth
                inputProps={{ style: { fontSize: fontSizing.body } }}
              />
            </div>
          </div>
        </div>
      </BloomkidzLayout>
    );
  }
}

BloomkidzNewClient5.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  updatePRO: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const {
    proForms: { bloomkidzNewClient },
  } = state;

  return { bloomkidzNewClient };
};

export default connect(mapStateToProps, { updatePRO })(withStyles(styles)(BloomkidzNewClient5));
