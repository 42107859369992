import React from 'react';
import { connect } from 'react-redux';
import { forEach, groupBy, orderBy } from 'lodash';
import moment from 'moment';

import { logEvent } from '../lib/amplitude';
import { apiFetch } from '../lib/fetch';
import AppBar from '../components/app-bar';
import Page from './page';
import Layout from '../layouts/common';
import DateHeader from '../components/notification-date-header';
import Notification from '../components/notification';
import { clearPros } from '../state/pro-forms';
import { addRequestingClinicId, addRequestingClinicNumber } from '../state/user';
import buildTrackFromBundledEvents from '../lib/build-track-from-bundled-events';
import { addProInNewTabListener, emitProInNewTab } from '../lib/pro-in-new-tab';

class Notifications extends Page {
  state = {
    notifications: [],
  }

  componentWillMount() {
    const { userId, clearPros } = this.props;
    clearPros();
    apiFetch(`/users/${userId}/notifications`)
      .then((notifications) => {
        const notes = groupBy(orderBy(notifications, 'created_at', 'desc'), 'created_at');
        this.setState({
          notifications: notes,
        });
      })
      .catch((err) => {
        console.log('error fetching notifications', err);
      });
  }

  onClickNotification = async (notificationId, type, meta) => {
    const clinic = await apiFetch(`/notifications_clinic/${notificationId}`);
    this.props.addRequestingClinicNumber(clinic.phone);
    this.props.addRequestingClinicId(clinic.id);
    logEvent('Patient Open Notification', { notificationType: type });

    addProInNewTabListener();
    emitProInNewTab();

    if (type !== 'BUNDLED_EVENT') {
      const id = meta.pro_submission_id || meta.video_broadcast_id || meta.voice_broadcast_id || meta.appointment_id;
      const { clinic_id } = meta;
      return this.setTrack(`notification-${type}`, { id, clinic_id, notification: true }, { ...meta, clinicName: clinic.name, notification_id: notificationId });
    }

    const track = buildTrackFromBundledEvents(meta.bundled_events, notificationId);
    if (!track.length) {
      return this.props.router.push(`/notification-success/BUNDLED_EVENT?notification_id=${notificationId}`);
    }
    this.setRailroad(track);
  }

  render() {
    const notifications = [];
    // TODO: Move the loop out of render when we're fetching real ones
    forEach(this.state.notifications, (day, key) => {
      notifications.push(<DateHeader key={`${key}-date`} text={moment(key).format('dddd MMM D')} />);
      forEach(day, n => notifications.push(<Notification key={n.id} {...n} onClickNotification={this.onClickNotification} />));
    });
    return (
      <Layout>
        <AppBar
          backButtonOnClick={() => this.props.router.push('/')}
          headerNode="Notifications"
        />
        <div>
          {notifications.length ? notifications : (
            <div style={{ fontSize: '2rem', marginTop: '20px' }}>You have no outstanding notifications.</div>
          )}
        </div>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state;
  return {
    userId: user.id,
  };
}

Notifications.defaultProps = {};

export default connect(mapStateToProps, { addRequestingClinicId, addRequestingClinicNumber, clearPros })(Notifications);
