const en = {
  woundCasaColina1: {
    title: 'Wound',
    instructions: 'Select the area on your body that best represents the location of your wound.',
    question1: 'Select body part:',
    question2: 'Select location:',
    options: {
      upperBody: 'Upper Body',
      lowerBody: 'Lower Body',
      leftLeg: 'Left Leg',
      rightLeg: 'Right Leg',
      leftFoot: 'Left Foot',
      rightFoot: 'Right Foot',
      lowerTorso: 'Lower Torso (stomach and below)',
      upperTorso: 'Upper Torso (chest and above)',
      neck: 'Neck',
      leftArm: 'Left Arm',
      rightArm: 'Right Arm',
      anterior: 'Anterior (front/top)',
      posterior: 'Posterior (back/bottom)',
      medial: 'Medial (inner/inside)',
      lateral: 'Lateral (outer/outside)',
    },
  },
};

const es = {
  woundCasaColina1: {
    title: 'Herida',
    instructions: 'Seleccione la zona de su cuerpo que mejor presente la ubicación de su herida.',
    question1: 'Seleccione la parte del cuerpo:',
    question2: 'Seleccione la Ubicación:',
    options: {
      upperBody: 'Parte Superior Del Cuerpo',
      lowerBody: 'Inferior Cuerpo',
      leftLeg: 'Pierna Izquierda',
      rightLeg: 'Pierna Derecha',
      leftFoot: 'Pie Izquierdo',
      rightFoot: 'Pie Derecho',
      lowerTorso: 'Torso Inferior (estómago y abajo)',
      upperTorso: 'Parte Superior Del Cuerpo (pecho y arriba)',
      neck: 'Cuello',
      leftArm: 'Brazo Izquierdo',
      rightArm: 'Brazo Derecho',
      anterior: 'Anterior (frontal/superior)',
      posterior: 'Posterior (atrás/abajo)',
      medial: 'Medial (interior/dentro)',
      lateral: 'Lateral (exterior/afuera)',
    },
  },
};

export default { en, es };
