import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import LinearProgress from '@material-ui/core/LinearProgress';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import Typography from '@material-ui/core/Typography';

import { withStyles } from '@material-ui/core/styles';

import AppBar from '../components/app-bar';
import Continue from '../components/continue';

import { WeightScale } from '../lib/icons';

import Page from './page';

import { updatePRO } from '../state/pro-forms';

const styles = {
  formControlItem: {
    fontSize: '1.75rem',
    textAlign: 'center',
    margin: '1rem',
  },
  height: {
    display: 'inline-flex',
    justifyContent: 'space-between',
  },
  iconContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    flexGrow: 0,
    flexShrink: 0,
    width: '100%',
    color: '#000',
    paddingTop: 10,
  },
  iconWrapper: {
    width: 100,
  },
  text: {
    fontSize: '1.75rem',
  },
  nextBtn: {
    textAlign: 'center',
    width: '100%',
    top: '90%',
  },
  weight: {
    textAlign: 'center',
  },
  wrapper: {
    width: '80%',
    overflowX: 'hidden',
    position: 'relative',
    padding: '20px',
  },
};

const StyledInput = withStyles(() => ({
  input: {
    textAlign: 'center',
  },
}))(Input);

class ContinuousBPDataCollection5 extends Page {
  constructor(props) {
    super(props);

    this.state = {
      feet: '',
      inches: '',
      weight: '',
      age: '',
    };

    this.handleContinue = this.handleContinue.bind(this);
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      ...this.state,
      [name]: value,
    });
  };

  handleContinue() {
    this.props.updatePRO({
      type: 'continuousBPDataCollection',
      position: this.getTrackIndex(),
      value: this.state });
    this.forwardWithQuery(this.props.location.query);
  }

  render() {
    const { feet, inches, weight, age } = this.state;

    return (
      <Fragment>
        <AppBar
          headerNode="BMI"
          backButtonOnClick={() => this.props.router.goBack()}
        />
        <LinearProgress
          variant="determinate"
          value={(this.getCurrentPage() / this.getTotalPages()) * 100}
        />
        <div style={styles.iconContainer}>
          <div style={styles.iconWrapper}>
            <WeightScale />
          </div>
        </div>
        <div style={styles.wrapper}>
        <p style={styles.text}>What is your current age?</p>
          <div style={styles.weight}>
            <FormControl style={styles.formControlItem}>
              <StyledInput
                id="age-adornment"
                type="tel"
                value={age}
                name="age"
                placeholder="000"
                onChange={this.handleChange}
                inputProps={{
                  'aria-label': 'age',
                }}
              />
            </FormControl>
            <Typography>years</Typography>
          </div>
          <p style={styles.text}>What is your current height and weight?</p>
          <p style={styles.text}>Height?</p>
          <div style={styles.height}>
            <FormControl style={styles.formControlItem}>
              <StyledInput
                id="feet-adornment"
                type="tel"
                value={feet}
                name="feet"
                placeholder="00"
                onChange={this.handleChange}
                endAdornment={
                  <InputAdornment position="end">ft</InputAdornment>
                  }
                aria-describedby="feet-helper-text"
                inputProps={{
                  'aria-label': 'feet',
                }}
              />
            </FormControl>
            <FormControl style={styles.formControlItem}>
              <StyledInput
                id="inches-adornment"
                type="tel"
                value={inches}
                name="inches"
                placeholder="00"
                onChange={this.handleChange}
                endAdornment={
                  <InputAdornment position="end">in</InputAdornment>
                  }
                aria-describedby="inches-helper-text"
                inputProps={{
                  'aria-label': 'inches',
                }}
              />
            </FormControl>
          </div>

          <p style={styles.text}>Weight?</p>
          <div style={styles.weight}>
            <FormControl style={styles.formControlItem}>
              <StyledInput
                id="weight-adornment"
                type="tel"
                value={weight}
                name="weight"
                placeholder="000"
                onChange={this.handleChange}
                inputProps={{
                  'aria-label': 'weight',
                }}
              />
            </FormControl>
            <Typography>lbs</Typography>
          </div>
        </div>

        <div style={styles.nextBtn}>
          <Continue
            disabled={feet === null || inches === null || weight === null}
            text="Next"
            onClick={this.handleContinue}
          />
        </div>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { user, proForms: { continuousBPDataCollection } } = state;
  return { user, continuousBPDataCollection };
}

ContinuousBPDataCollection5.proptypes = {
  user: PropTypes.object.isRequired,
  route: PropTypes.object.isRequired,
  updatePRO: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { updatePRO })(ContinuousBPDataCollection5);
