import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Continue from '../components/continue';

const styles = {
  button: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: 20,
  },
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100%',
    justifyContent: 'space-between',
  },
  mainContent: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
};

class QuestionLayout extends Component {
  render() {
    const {
      children,
      primaryBtnDisabled,
      primaryBtnLabel,
      primaryBtnOnTouchTap,
      primaryBtnStyle,
      secondaryBtnLabel,
      secondaryBtnOnTouchTap,
      style,
    } = this.props;

    return (
      <div style={{ ...styles.contentWrapper }}>
        <div style={{ ...styles.mainContent, ...style }}>
          {children}
        </div>
        <div style={style}>
          {!!secondaryBtnOnTouchTap
            && (
            <div style={{ ...styles.button, ...style }}>
              <Continue
                text={secondaryBtnLabel}
                onClick={secondaryBtnOnTouchTap}
              />
            </div>
            )
          }
          {!!primaryBtnOnTouchTap
            && (
            <div style={{ ...styles.button, ...style }}>
              <Continue
                text={primaryBtnLabel}
                onClick={primaryBtnOnTouchTap}
                disabled={primaryBtnDisabled}
                btnStyle={primaryBtnStyle}
              />
            </div>
            )
          }
        </div>
      </div>
    );
  }
}

QuestionLayout.defaultProps = {
  primaryBtnDisabled: false,
  primaryBtnLabel: undefined,
  primaryBtnOnTouchTap: undefined,
  primaryBtnStyle: {},
  secondaryBtnLabel: undefined,
  secondaryBtnOnTouchTap: undefined,
  style: {},
};

QuestionLayout.propTypes = {
  children: PropTypes.node.isRequired,
  primaryBtnDisabled: PropTypes.bool,
  primaryBtnLabel: PropTypes.string,
  primaryBtnOnTouchTap: PropTypes.func,
  primaryBtnStyle: PropTypes.object,
  secondaryBtnLabel: PropTypes.string,
  secondaryBtnOnTouchTap: PropTypes.func,
  style: PropTypes.object,
};

export default QuestionLayout;
