import React from 'react';
import { map, replace } from 'lodash';
import { connect } from 'react-redux';
import AddIcon from '@material-ui/icons/Add';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import AppBar from '../components/app-bar';
import MedicationListItem from '../components/medication-list-item';
import Page from './page';
import Layout from '../layouts/common';
import Continue from '../components/continue';
import Subtitle from '../components/subtitle';
import { colors } from '../lib/styles';
import { logEvent } from '../lib/amplitude';

const baseStyles = {
  listItem: {
    borderBottom: `1px solid ${colors.primaryHighlight}`,
    color: colors.primaryColor,
    textAlign: 'left',
  },
};

class MedicationsPro1 extends Page {
  state = { 
    submitting: false
  }

  handleAddMedication = () => {
    logEvent('Add Medication');
    this.forwardWithQuery();
  }

  handleComplete = () => {
    const { query } = this.props.router.location;
    const path = replace(this.props.location.pathname, 'MEDICATIONS-1', 'MEDICATIONS-4');
    this.props.router.push({ pathname: path, query });
  }

  handleClickMedication = (id) => {
    const path = replace(this.props.location.pathname, 'MEDICATIONS-1', 'MEDICATIONS-3');
    const query = {
      ...this.props.router.location.query,
      medication_id: id,
    }
    this.props.router.push({ pathname: path, query });
  }

  render() {
    const medications = map(this.props.medications, (m) => {
      return (
        <MedicationListItem
          item={m}
          key={m.id}
          onClick={this.handleClickMedication}
          router={this.props.router}
        />
      );
    });
    return (
      <Layout>
        <div>
          <AppBar
            headerNode="Medications"
            noLeftNode={true}
          />
          <Subtitle label="My Medications" />
          <section style={{ backgroundColor: colors.white }}>
            <List>
              <ListItem
                button
                onClick={this.handleAddMedication}
                style={baseStyles.listItem}
              >
                <ListItemIcon>
                  <AddIcon />
                </ListItemIcon>
                <ListItemText
                  primary="Add a Medication"
                />
              </ListItem>
              {medications}
            </List>
          </section>
          <section>
            <Continue
              onClick={this.handleComplete}
              text="Complete"
            />
          </section>
        </div>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  const { user, proForms } = state;
  return {
    medications: proForms.medications[0] || [],
    user,
  };
}

export default connect(mapStateToProps, {})(MedicationsPro1);
