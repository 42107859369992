import React from 'react';
import { connect } from 'react-redux';
import { debounce } from 'lodash';
import rawr from 'rawr';
import transport from 'rawr/transports/worker';
import MobileDetect from 'mobile-detect';
import LinearProgress from '@material-ui/core/LinearProgress';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';

import Page from './page';
import HiddenContent from '../components/hidden-content';

import { updatePRO } from '../state/pro-forms';
import { startVideo, startRearVideo } from '../lib/createAnswer';
import { colors } from '../lib/styles';
import { throttledReset } from '../initializers/activity';

const styles = {
  fab: {
    margin: '0px',
    top: '20px',
    right: '20px',
    bottom: 'auto',
    left: 'auto',
    position: 'fixed',
  },
  heartIcon: {
    color: colors.healthyRed,
    fontSize: '25px',
  },
  logo: {
    height: '200px',
    paddingTop: '100px',
    width: '200px',
  },
  divider: {
    margin: '30px 0px',
  },
  waitingView: {
    height: '450px',
    maxHeight: '75vh',
    maxWidth: '80%',
    margin: '0px auto 10rem',
    textAlign: 'center',
    width: '100%',
  },
  patientVideo: {
    minHeight: '450px',
    height: '100%',
    maxWidth: 1000,
    objectFit: 'cover',
    width: '100%',
    transform: 'rotateY(180deg)',
  },
  rearVideo: {
    height: '10%',
    maxWidth: 300,
    transform: 'rotateY(180deg)',
    zIndex: 10,
    position: 'fixed',
    top: '0px',
    left: '0px',
  },
  readingRate: {
    background: 'rgba(255, 255, 255, 0.5)',
    borderRadius: 5,
    fontSize: 16,
    marginBottom: 20,
    marginLeft: 10,
    marginRight: 10,
    padding: 10,
    textAlign: 'center',
  },
  loadingModel: {
    background: 'rgba(255, 255, 255, 0.5)',
    borderRadius: 5,
    fontSize: 16,
    marginLeft: 10,
    marginRight: 10,
    marginTop: 40,
    padding: 10,
  },
  heart: {
    marginLeft: '5px',
    position: 'relative',
    top: '-34px',
  },
  heartRate: {
    flex: 1,
    fontSize: '1em',
    margin: '0px 20px',
    width: '20%',
  },
  disabledVital: {
    flex: 1,
    color: '#9D9D9D',
    fontSize: '1em',
    margin: '0px 20px',
    width: '20%',
  },
  vitals: {
    display: 'flex',
    fontSize: '1.75em',
    padding: '0px 10px',
    justifyContent: 'center',
  },
  vitalsLoadingIndicator: {
    fontSize: '24px',
    height: '10px',
    marginTop: '-20px',
  },
  breath: {
    position: 'relative',
    top: '-63px',
  },
  breathRate: {
    flex: 1,
    fontSize: '1em',
    marginRight: '20px',
    color: 'blue',
    width: '20%',
  },
  container: {
    backgroundColor: colors.white,
    height: '100%',
  },
  hidden: {
    display: 'none',
  },
  topSection: {
    backgroundColor: colors.white,
    height: '100%',
    position: 'relative',
  },
  progressBad: {
    color: 'red',
  },
  progressGood: {
    color: 'green',
  },
  progressNone: {
    color: 'grey',
  },
  vitalsDescription: {
    fontSize: '.5em',
  },
  patientCanvas: {
    display: 'none',
    transform: 'scaleX(-1)',
    width: '100%',
  },
  rearCanvas: {
    display: 'none',
    transform: 'scaleX(-1)',
    width: '100%',
  },
  videoContainer: {
    margin: '0px auto',
    height: '100%',
    maxWidth: '540px',
    width: '100%',
  },
  waitingInstructions: {
    fontSize: '2rem',
    fontWeight: 600,
  },
  icon: {
    width: 25,
    height: 25,
    position: 'absolute',
  },
  iconWrapper: {
    alignItems: 'center',
    background: '#fff',
    borderRadius: '50%',
    boxShadow: '0 2px 3px lightgrey',
    display: 'flex',
    height: 60,
    justifyContent: 'center',
    position: 'absolute',
    left: 0,
    top: 0,
    transform: 'translateX(10px)',
    width: 60,
    zIndex: 2,
  },
  iconBackground: {
    alignItems: 'center',
    background: '#fff',
    borderRadius: '50%',
    boxShadow: '0 0 4px lightgrey',
    display: 'flex',
    height: 50,
    justifyContent: 'center',
    left: 5,
    position: 'absolute',
    top: 5,
    width: 50,
    zIndex: 2,
  },
  vitalDisplay: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 35,
  },
  vitalDisplayUnits: {
    fontSize: '1.25rem',
    marginBottom: 5,
    marginTop: 5,
  },
  vitalDisplayValue: {
    fontSize: '2rem',
    fontWeight: 'bold',
  },
  vitalDisplayBPValue: {
    fontSize: '1rem',
    fontWeight: 'bold',
  },
  vitalDisplayWrapper: {
    backgroundColor: 'rgba(0,0,0,0)',
    marginLeft: 5,
    marginRight: 5,
    paddingTop: 30,
    position: 'relative',
    width: 80,
  },
  vitalsBorderBlue: {
    borderColor: '#1e7bcd',
  },
  vitalsBorderRed: {
    borderColor: '#cd3636',
  },
  vitalsIconBlue: {
    color: '#1e7bcd',
  },
  vitalsIconRed: {
    color: '#cd3636',
  },
  nonVideoContentContainer: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
  },
  circularProgress: {
    position: 'absolute',
    zIndex: 1,
  },
  calibratingCameraMessage: {
    background: colors.primaryColor,
    color: '#fff',
    fontSize: 16,
    marginTop: 40,
    padding: 10,
    textAlign: 'center',
  },
};

const TIME_THREASHOLD_MS = 30000;
const MIN_FRAME_COUNT = 550;

let start_timestamp_ms = -1;
// let set_frames_to_skip = false;

let cacheFrames = false;
let frameCount = 0;
let cachedFrameCount = 0;
let processedFrameCount = 0;
// eslint-disable-next-line
let processingLeft = 0;

// let frames_to_skip = 0;
let finished = false;


function fixedLengthArray(length) {
  var array = [];
  array.push = function () {
    if (this.length >= length) {
      this.shift();
    }
    return Array.prototype.push.apply(this, arguments);
  }
  return array;
}

const TARGET_FRAME_INTERVAL_MS = 50;
let front_timestamps = fixedLengthArray(30);
let rear_timestamps = fixedLengthArray(30);


class VideoVitals2 extends Page {
  constructor(props) {
    super(props);

    this.state = {
      cameraState: '',
      cameraError: '',
      me: {},
      signalPercent: 0,
      connecting: false,
      workerPct: 0,
      workerReady: false,
      md: null,
      brSignalPercent: 0,
      hrSignalPercent: 0,
      spo2SignalPercent: 0,
      BR: null,
      HR: null,
      BP_DIA: 0,
      BP_SYS: 0,
      SPO2: 0,
    };

    this.patientCanvas = React.createRef();
    this.patientVideo = React.createRef();
    this.rearCanvas = React.createRef();
    this.rearVideo = React.createRef();
    this.videoContainer = React.createRef();
  }

  componentDidMount = async () => {
    const md = new MobileDetect(window.navigator.userAgent);
    this.setState({ md });
    this.startCamera();

    this.activityTimer = setInterval(throttledReset, 5000);

    const webWorker = new Worker('/workers-4vitals/patient-worker.js');
    webWorker.onerror = (err) => {
      console.error('webworker error', err);
    };
    const rawrPeer = rawr({ transport: transport(webWorker) });
    const { detectVitals, detectRedChannel } = rawrPeer.methods;
    this.detectVitals = detectVitals;
    this.detectRedChannel = detectRedChannel;

    rawrPeer.notifications.onready(() => {
      this.setState({ workerReady: true });
    });

    rawrPeer.notifications.ondownloadingPct((data) => {
      this.setState({ workerPct: data.pct });
    });


    rawrPeer.notifications.onsigns((msg) => {

      console.log('onsigns', msg.data.signs);

      const { signs, trackingInfo } = msg.data;

      let { brSignalPercent, hrSignalPercent, bpSignalPercent, spo2SignalPercent, BP_DIA, BP_SYS, BR, HR } = signs;
      if (signs && !finished) {

        const checkFinished = hrSignalPercent === 1 &&
          brSignalPercent === 1 &&
          bpSignalPercent === 1 &&
          spo2SignalPercent === 1 &&
          BP_DIA && BP_SYS && BR && HR;

        const signalPercent = Math.round(signs.hrSignalPercent * 100);
        brSignalPercent = Math.round(brSignalPercent * 100);
        hrSignalPercent = Math.round(hrSignalPercent * 100);
        bpSignalPercent = Math.round(bpSignalPercent * 100) || 0;
        spo2SignalPercent = Math.round(spo2SignalPercent * 100) || 0;

        const updates = {
          signalPercent,
          brSignalPercent,
          bpSignalPercent,
          spo2SignalPercent,
          hrSignalPercent,
          HR: signs.HR,
          BR: signs.BR,
          BP_DIA: signs.BP_DIA,
          BP_SYS: signs.BP_SYS,
          SPO2: signs.SPO2,
        };

        if (checkFinished) {
          this.props.updatePRO({
            type: 'videoVitals',
            position: this.getTrackIndex(),
            value: {
              BR: signs.BR,
              HR: signs.HR,
              BP_DIA: signs.BP_DIA,
              BP_SYS: signs.BP_SYS,
              SPO2: signs.SPO2,
            },
          });
          finished = true;

          this.setState(updates);

          setTimeout(() => {
            this.forward();
          }, 1000);
          return;
        }

        if (!finished) {

          try {
            this.debouncedUpdateVitals(updates);
          } catch (e) {
            console.log('error updating vitals', e);
          }

          this.lastTracking = trackingInfo;
        }

      } else if (!finished) {
        this.setState({ signalPercent: 0 });
      }

      if (!finished) {
        this.processedFrameCount++;

        this.lastSigns = msg.data.signs;
        // console.log(this.lastSigns);

        this.trackingInfo = msg.data.trackingInfo;

        if (this.tracking === false && (this.lastSigns.trackingHR === 1 || this.lastSigns.trackingBR === 1)) {
          console.log("tracking started");
          this.tracking = true;
        }

        if (this.tracking === true && (this.lastSigns.trackingHR === 0 || this.lastSigns.trackingBR === 0)) {
          console.log("tracking lost");
          //reset();   
        }

        if (this.lastSigns) {
          // updateProgressCircles(lastSigns);
        }

        if (trackingInfo) {
          this.lastTrackingInfo = trackingInfo;
        }

        if (!cacheFrames && this.processedFrameCount === this.cachedFrameCount) {
          console.log("done processed all frames");
          //updateVitals(lastSigns);
          //reset();
        }
      }


    });

    rawrPeer.notifications.onredChannel((msg) => {
      // console.log('red', msg.data);
      // cacheFrames = true;
    });


  }

  componentWillUnmount = () => {
    this.endCall();
  }

  endCall = () => {
    try {
      clearInterval(this.heartbeatInterval);
      clearInterval(this.activityTimer);
      const videoElement = this.patientVideo.current;
      this.state.stream.getTracks().forEach(t => t.stop());
      videoElement.srcObject.getTracks().forEach(t => t.stop());
      videoElement.srcObject = null;
    } catch (e) {
      console.error('error ending call properly: ', e);
    }
  }

  detectVitalsJS = (imgData, width, height, timestamp) => {
    if (cachedFrameCount === 0) {
      start_timestamp_ms = timestamp;
    }

    if ((timestamp - start_timestamp_ms) <= TIME_THREASHOLD_MS || frameCount < MIN_FRAME_COUNT) {
      let frameInfo = {
        width: width,
        height: height,
        timestamp: timestamp
      }

      this.detectVitals(frameInfo, imgData); // async from webworker

      cachedFrameCount++; // only keeping count for front camera

      return true; // continue caching
    } else {
      return false; // caching complete
    }
  }

  detectRedChannelJS = (imgData, width, height, timestamp) => {
    if (start_timestamp_ms !== -1) {

      if (timestamp - start_timestamp_ms <= TIME_THREASHOLD_MS) {
        let frameInfo = {
          width: width,
          height: height,
          timestamp: timestamp
        }

        this.detectRedChannel(frameInfo, imgData); // async from webworker

        return true; // continue caching
      } else {
        return false; // caching complete
      }
    }
  }

  startCamera = async () => {
    const { me } = this.state;

    try {
      const stream = await startVideo(me);
      const rearStream = await startRearVideo(me);
      this.setState({ cameraState: 'started', stream, me, rearStream });
      const patientVideo = this.patientVideo.current; // document.createElement('video');
      const rearVideo = this.rearVideo.current; // document.createElement('video');

      patientVideo.addEventListener('loadeddata', () => {
        const canvas = this.patientCanvas.current;
        canvas.height = patientVideo.videoHeight;
        canvas.width = patientVideo.videoWidth;

        const onframe = async () => {
          try {
            const curCanvas = this.patientCanvas.current;
            const ctx = curCanvas.getContext('2d');

            let timestamp = Date.now();

            if (patientVideo.videoWidth > 0 && this.state.workerReady) {
              ctx.drawImage(patientVideo, 0, 0, patientVideo.videoWidth, patientVideo.videoHeight);

              if (cacheFrames) {
                let includeFrame = true;
                if (front_timestamps.length > 0) {
                  //calc ave fps if include this frame
                  let aveFrameInterval = 0;
                  for (var i = 1; i < front_timestamps.length; ++i) {
                    aveFrameInterval += front_timestamps[i] - front_timestamps[i - 1];
                  }
                  aveFrameInterval += timestamp - front_timestamps[front_timestamps.length - 1];
                  aveFrameInterval /= front_timestamps.length;
                  if (aveFrameInterval < TARGET_FRAME_INTERVAL_MS) {
                    includeFrame = false;
                  }
                }

                if (includeFrame) {
                  front_timestamps.push(timestamp);
                  ++frameCount;
                  cacheFrames = this.detectVitalsJS(ctx.getImageData(0, 0, patientVideo.videoWidth, patientVideo.videoHeight), patientVideo.videoWidth, patientVideo.videoHeight, timestamp);

                  processingLeft = cachedFrameCount - processedFrameCount;

                  if (!cacheFrames) {
                    console.log("end_timestamp: " + timestamp
                      + "  ...duration: " + (timestamp - start_timestamp_ms));

                    if (processedFrameCount === cachedFrameCount) {
                      console.log("done processed all frames");
                      //updateVitals(lastSigns);
                      //reset();
                    }
                  }
                }

                // only show ROI boxes while caching frames as a guide. 
                // if (lastTrackingInfo) {
                // drawTrackingBoxes(lastTrackingInfo, ctx); 
                // }
              }


              // ctx.putImageData(imgData, 0, 0, 0, 0, patientVideo.videoWidth, patientVideo.videoHeight);
            }
          } catch (err) {
            // This is happening... like a lot
          }
          requestAnimationFrame(onframe);
        };

        // wait half a second because first few frames are always black when turning on camera.
        setTimeout(() => {
          requestAnimationFrame(onframe);
        }, 500);
      });

      rearVideo.addEventListener('loadeddata', () => {
        const canvas = this.rearCanvas.current;
        canvas.height = rearVideo.videoHeight;
        canvas.width = rearVideo.videoWidth;
        const dtcjs = this.detectRedChannelJS;
        const onframe = async () => {
          try {
            const curCanvas = this.rearCanvas.current;
            const ctx = curCanvas.getContext('2d');
            let timestamp = Date.now();
            if (rearVideo.videoWidth > 0) {
              ctx.drawImage(rearVideo, 0, 0, rearVideo.videoWidth, rearVideo.videoHeight);
              // const imgData = ctx.getImageData(0, 0, rearVideo.videoWidth, rearVideo.videoHeight);
              if (this.state.workerReady) {
                if (cacheFrames) {
                  let includeFrame = true;
                  let aveFrameInterval = 0;
                  if (rear_timestamps.length > 0) {
                    //calc ave fps if include this frame
                    for (var i = 1; i < rear_timestamps.length; ++i) {
                      aveFrameInterval += rear_timestamps[i] - rear_timestamps[i - 1];
                    }
                    aveFrameInterval += timestamp - rear_timestamps[rear_timestamps.length - 1];
                    aveFrameInterval /= rear_timestamps.length;
                    if (aveFrameInterval < TARGET_FRAME_INTERVAL_MS) {
                      includeFrame = false;
                    }
                  }

                  if (includeFrame) {
                    const imgData = ctx.getImageData(0, 0, rearVideo.videoWidth, rearVideo.videoHeight);
                    // console.log('include rear', aveFrameInterval, imgData);
                    rear_timestamps.push(timestamp);
                    // this.detectRedChannelJS(ctx.getImageData(0, 0, rearVideo.width, rearVideo.height), rearVideo.width, rearVideo.height, timestamp);
                    dtcjs(imgData, rearVideo.videoWidth, rearVideo.videoHeight, timestamp);

                  }
                }


              }

              // ctx.putImageData(imgData, 0, 0, 0, 0, rearVideo.videoWidth, rearVideo.videoHeight);
            }
          } catch (err) {
            // This is happening... like a lot
          }
          requestAnimationFrame(onframe);
        };

        // wait half a second because first few frames are always black when turning on camera.
        setTimeout(() => {
          requestAnimationFrame(onframe);
        }, 500);
      });


      patientVideo.srcObject = me.stream;
      rearVideo.srcObject = me.rearStream;

      try {
        if (this.state.md.os() === 'iOS' && this.state.md.mobile()) {
          console.info('not manually playing video because', this.state.md.os(), this.state.md.mobile());
        } else {
          await patientVideo.play();
        }
      } catch (err) {
        console.error('error starting video', err);
      }
    } catch (err) {
      this.setState({ cameraError: err });
    }
  }

  debouncedUpdateVitals = debounce((update) => {
    this.setState(update);
  }, 2000, { leading: true, maxWait: 2000 })

  render() {
    const { classes } = this.props;
    const {
      cameraError,
      // signalPercent,
      brSignalPercent,
      bpSignalPercent,
      hrSignalPercent,
      spo2SignalPercent,
    } = this.state;
    // const readingVitals = signalPercent < 100;
    const vitalsStyle = styles.vitals;

    return (
      <div className={classes.container}>
        <section className={classes.topSection}>
          <div id="videoContainer" className={classes.videoContainer} ref={this.videoContainer} >
            <video id="patientVideo" ref={this.patientVideo} playsInline autoPlay className={classes.patientVideo} muted />
            <video id="rearVideo" ref={this.rearVideo} playsInline autoPlay className={classes.rearVideo} muted />
            <canvas id="patientCanvas" ref={this.patientCanvas} style={styles.patientCanvas} />
            <canvas id="rearCanvas" ref={this.rearCanvas} style={styles.rearCanvas} />
          </div>
          <div className={classes.nonVideoContentContainer}>
            {cameraError ? (
              <div><span>Unable to access camera: {cameraError.toString()}</span><br /></div>
            ) : ''}
            <div style={vitalsStyle}>
              <div className={classes.vitalDisplayWrapper}>
                <div className={`${classes.iconWrapper}`}>
                  <CircularProgress
                    className={`${classes.circularProgress} ${classes.vitalsIconRed}`}
                    size={62}
                    thickness={5}
                    variant="determinate"
                    value={hrSignalPercent}
                    style={{ transform: 'none' }}
                  />
                  <div className={classes.iconBackground}>
                    <img
                      className={classes.icon}
                      src="/img/heartbeat-solid.png"
                      alt=""
                    />
                  </div>
                </div>
                <Paper className={`${classes.vitalDisplay} ${classes.vitalsIconRed}`}>
                  <div className={classes.vitalDisplayValue}>
                    {this.state.HR ? this.state.HR : '--'}
                  </div>
                  <div className={classes.vitalDisplayUnits}>
                    HR
                  </div>
                </Paper>
              </div>
              <div className={classes.vitalDisplayWrapper}>
                <div className={`${classes.iconWrapper}`}>
                  <CircularProgress
                    className={`${classes.circularProgress} ${classes.vitalsIconBlue}`}
                    size={62}
                    thickness={5}
                    variant="determinate"
                    value={brSignalPercent}
                    style={{ transform: 'none' }}
                  />
                  <div className={classes.iconBackground}>
                    <img
                      className={classes.icon}
                      src="/img/lungs-solid.png"
                      alt=""
                    />
                  </div>
                </div>
                <Paper className={`${classes.vitalDisplay} ${classes.vitalsIconBlue}`}>
                  <div className={classes.vitalDisplayValue}>
                    {this.state.BR ? this.state.BR : '--'}
                  </div>
                  <div className={classes.vitalDisplayUnits}>
                    BR
                  </div>
                </Paper>
              </div>
              <div className={classes.vitalDisplayWrapper}>
                <div className={`${classes.iconWrapper}`}>
                  <CircularProgress
                    className={`${classes.circularProgress} ${classes.vitalsIconRed}`}
                    size={62}
                    thickness={5}
                    variant="determinate"
                    value={bpSignalPercent}
                    style={{ transform: 'none' }}
                  />
                  <div className={classes.iconBackground}>
                    <img
                      className={classes.icon}
                      src="/img/bp-solid.png"
                      alt=""
                    />
                  </div>
                </div>
                <Paper className={`${classes.vitalDisplay} ${classes.vitalsIconRed}`}>
                  <div className={classes.vitalDisplayValue}>
                    {this.state.BP_SYS ? this.state.BP_SYS : '--'}/{this.state.BP_DIA ? this.state.BP_DIA : '--'}
                  </div>
                  <div className={classes.vitalDisplayUnits}>
                    BP
                  </div>
                </Paper>
              </div>
              <div className={classes.vitalDisplayWrapper}>
                <div className={`${classes.iconWrapper}`}>
                  <CircularProgress
                    className={`${classes.circularProgress} ${classes.vitalsIconBlue}`}
                    size={62}
                    thickness={5}
                    variant="determinate"
                    value={spo2SignalPercent}
                    style={{ transform: 'none' }}
                  />
                  <div className={classes.iconBackground}>
                    <img
                      className={classes.icon}
                      src="/img/spo2-solid.png"
                      alt=""
                    />
                  </div>
                </div>
                <Paper className={`${classes.vitalDisplay} ${classes.vitalsIconBlue}`}>
                  <div className={classes.vitalDisplayValue}>
                    {this.state.SPO2 ? this.state.SPO2 : '--'}
                  </div>
                  <div className={classes.vitalDisplayUnits}>
                    SP02
                  </div>
                </Paper>
              </div>
            </div>
            <HiddenContent hidden={this.state.workerReady}>
              <div className={classes.loadingModel}>
                <span>Loading Model</span>
                <LinearProgress value={Math.round(this.state.workerPct * 100)} variant="determinate" />
              </div>
            </HiddenContent>
            <HiddenContent hidden={cacheFrames || !this.state.workerReady}>
              <Button variant="contained" color="primary" onClick={() => {
                cacheFrames = true;
                this.setState({ startedRecording: true });
              }}>
                detect vitals
              </Button>
            </HiddenContent>
            <HiddenContent hidden={!this.state.workerReady || !cacheFrames}>
              <div className={classes.calibratingCameraMessage}>
                Please hold still while your vitals are measured.
              </div>
            </HiddenContent>
          </div>
        </section>
      </div>
    );
  }
}

export default connect(null, { updatePRO })(withStyles(styles)(VideoVitals2));
