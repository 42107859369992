import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';
import { connect } from 'react-redux';
import Joi from 'joi-browser';

import Page from './page';
import AppBar from '../components/app-bar';
import IconWrapper from '../components/icon-wrapper';
import BloomkidzLayout from '../layouts/bloomkidz';
import { UserGroup } from '../lib/icons';
import { fontSizing } from '../lib/styles';
import { updatePRO } from '../state/pro-forms';
import { bloomkidzStyles, bloomkidzStylesObj } from '../lib/bloomkidz';
import { email, phone, zipcode } from '../lib/validator';

const styles = bloomkidzStylesObj;

const formSchema = Joi.object({
  guardianTwoEmail: Joi.string().regex(email),
  guardianTwoTelephone: Joi.string().regex(phone),
  guardianTwoCell: Joi.string().regex(phone),
  guardianTwoZipcode: Joi.string().regex(zipcode),
});

const requiredFields = [
  'guardianTwoFirstName',
  'guardianTwoLastName',
  'guardianTwoEmail',
  'guardianTwoCell',
  'guardianTwoAddress',
  'guardianTwoCity',
  'guardianTwoState',
  'guardianTwoZipcode',
  'guardianTwoEducation',
  'guardianTwoOccupation',
  'guardianTwoContactMethod',
];

class BloomkidzParentGuardianInformation2 extends Page {
  constructor(props) {
    super(props);

    const stateFromRedux = props.bloomkidzParentGuardianInformation[this.getTrackIndex()] || {};

    this.state = {
      guardianTwoFirstName: '',
      guardianTwoLastName: '',
      guardianTwoEmail: '',
      guardianTwoTelephone: '',
      guardianTwoCell: '',
      guardianTwoAddress: '',
      guardianTwoCity: '',
      guardianTwoState: '',
      guardianTwoZipcode: '',
      guardianTwoEducation: '',
      guardianTwoOccupation: '',
      guardianTwoContactMethod: '',
      errors: {
        guardianTwoEmail: '',
        guardianTwoTelephone: '',
        guardianTwoCell: '',
        guardianTwoZipcode: '',
      },
      ...stateFromRedux,
    };
  }

  handleTextFieldChange = (e) => {
    const stateUpdate = { [e.target.name]: e.target.value };

    if (this.state.errors[e.target.name]) {
      stateUpdate.errors = {
        ...this.state.errors,
        [e.target.name]: '',
      };
    }

    this.setState(stateUpdate);
  };

  handleContinue = () => {
    const { location, updatePRO } = this.props;
    const {
      guardianTwoEmail,
      guardianTwoTelephone,
      guardianTwoCell,
      guardianTwoZipcode,
    } = this.state;

    const fieldsToValidate = {
      guardianTwoEmail,
      guardianTwoCell,
      guardianTwoZipcode,
    };

    let requiredFieldCount = 0;

    requiredFields.forEach((field) => {
      if (this.state[field]) requiredFieldCount++;
    });
    if (requiredFieldCount === 0) {
      updatePRO({
        type: 'bloomkidzParentGuardianInformation',
        position: this.getTrackIndex(),
        value: { ...this.state },
      });

      this.forwardWithQuery(location.query);
    }

    if (guardianTwoTelephone) fieldsToValidate.guardianTwoTelephone = guardianTwoTelephone;

    const { error } = formSchema.validate(fieldsToValidate, { abortEarly: false });

    if (error) {
      const errors = {};

      error.details.forEach((error) => {
        errors[error.context.key] = 'Invalid value';
      });

      this.setState({ errors });
      return;
    }

    updatePRO({
      type: 'bloomkidzParentGuardianInformation',
      position: this.getTrackIndex(),
      value: { ...this.state },
    });

    this.forwardWithQuery(location.query);
  }

  getButtonText = () => {
    const { state } = this;

    const stateVals = Object.values(state);

    for (let i = 0; i < stateVals.length; i++) {
      if (stateVals[i] && typeof stateVals[i] !== 'object') return 'Next';
    }

    return 'Skip';
  }

  shouldDisableContinueButton = () => {
    const { state } = this;
    const { errors } = state;
    // if at least one of the required fields is filled out, and at least one
    // is empty, disable the continue button.
    let requiredFieldCount = 0;

    requiredFields.forEach((field) => {
      if (state[field]) requiredFieldCount++;
    });

    if (requiredFieldCount !== requiredFields.length && requiredFieldCount !== 0) {
      return true;
    }

    if (errors.guardianTwoCell || errors.guardianTwoEmail || errors.guardianTwoTelephone) {
      return true;
    }

    return false;
  }

  render() {
    const { classes, router } = this.props;
    const {
      guardianTwoFirstName,
      guardianTwoLastName,
      guardianTwoEmail,
      guardianTwoTelephone,
      guardianTwoCell,
      guardianTwoAddress,
      guardianTwoCity,
      guardianTwoState,
      guardianTwoZipcode,
      guardianTwoEducation,
      guardianTwoOccupation,
      guardianTwoContactMethod,
      errors,
    } = this.state;

    return (
      <BloomkidzLayout
        primaryBtnOnTouchTap={this.handleContinue}
        primaryBtnLabel={this.getButtonText()}
        primaryBtnDisabled={this.shouldDisableContinueButton()}
      >
        <AppBar
          headerNode="Guardian Information"
          backButtonOnClick={() => router.goBack()}
        />
        <IconWrapper>
          <UserGroup />
        </IconWrapper>
        <div className={classes.pageContent}>
          <div>Parent/Guardian 2</div>
          <div className={classes.paddingHorizontal}>
            <div className={classes.textFieldWrapper}>
              <TextField
                placeholder="First Name"
                value={guardianTwoFirstName}
                onChange={this.handleTextFieldChange}
                name="guardianTwoFirstName"
                variant="standard"
                fullWidth
                inputProps={{ style: { fontSize: fontSizing.body } }}
              />
            </div>
            <div className={classes.textFieldWrapper}>
              <TextField
                placeholder="Last Name"
                value={guardianTwoLastName}
                onChange={this.handleTextFieldChange}
                name="guardianTwoLastName"
                variant="standard"
                fullWidth
                inputProps={{ style: { fontSize: fontSizing.body } }}
              />
            </div>
          </div>
          <div className={classes.inputSectionTitle}>
            Contact
          </div>
          <div className={classes.paddingHorizontal}>
            <div className={classes.textFieldWrapper}>
              <TextField
                placeholder="Email"
                value={guardianTwoEmail}
                onChange={this.handleTextFieldChange}
                name="guardianTwoEmail"
                variant="standard"
                fullWidth
                inputProps={{ style: { fontSize: fontSizing.body } }}
                error={errors.guardianTwoEmail}
                helperText={errors.guardianTwoEmail}
              />
            </div>
            <div className={classes.textFieldWrapper}>
              <TextField
                placeholder="Telephone Number (optional)"
                value={guardianTwoTelephone}
                onChange={this.handleTextFieldChange}
                name="guardianTwoTelephone"
                variant="standard"
                fullWidth
                inputProps={{ style: { fontSize: fontSizing.body } }}
                error={errors.guardianTwoTelephone}
                helperText={errors.guardianTwoTelephone}
              />
            </div>
            <div className={classes.textFieldWrapper}>
              <TextField
                placeholder="Cell Number"
                value={guardianTwoCell}
                onChange={this.handleTextFieldChange}
                name="guardianTwoCell"
                variant="standard"
                fullWidth
                inputProps={{ style: { fontSize: fontSizing.body } }}
                error={errors.guardianTwoCell}
                helperText={errors.guardianTwoCell}
              />
            </div>
          </div>
          <div className={classes.inputSectionTitle}>
            Address
          </div>
          <div className={classes.paddingHorizontal}>
            <div className={classes.textFieldWrapper}>
              <TextField
                placeholder="Address"
                value={guardianTwoAddress}
                onChange={this.handleTextFieldChange}
                name="guardianTwoAddress"
                variant="standard"
                fullWidth
                inputProps={{ style: { fontSize: fontSizing.body, fontFamily: bloomkidzStyles.fontFamily } }}
              />
            </div>
            <div className={classes.textFieldWrapper}>
              <TextField
                placeholder="City"
                value={guardianTwoCity}
                onChange={this.handleTextFieldChange}
                name="guardianTwoCity"
                variant="standard"
                fullWidth
                inputProps={{ style: { fontSize: fontSizing.body, fontFamily: bloomkidzStyles.fontFamily } }}
              />
            </div>
            <div className={classes.textFieldWrapper}>
              <TextField
                placeholder="State"
                value={guardianTwoState}
                onChange={this.handleTextFieldChange}
                name="guardianTwoState"
                variant="standard"
                fullWidth
                inputProps={{ style: { fontSize: fontSizing.body, fontFamily: bloomkidzStyles.fontFamily } }}
              />
            </div>
            <div className={classes.textFieldWrapper}>
              <TextField
                placeholder="Zipcode"
                value={guardianTwoZipcode}
                onChange={this.handleTextFieldChange}
                name="guardianTwoZipcode"
                variant="standard"
                fullWidth
                inputProps={{
                  style: { fontSize: fontSizing.body, fontFamily: bloomkidzStyles.fontFamily },
                  type: 'tel',
                }}
                error={errors.guardianTwoZipcode}
                helperText={errors.guardianTwoZipcode}
              />
            </div>
          </div>
          <div className={classes.inputSectionTitle}>
            Health Care Information
          </div>
          <div className={classes.paddingHorizontal}>
            <div className={classes.textFieldWrapper}>
              <TextField
                placeholder="Education"
                value={guardianTwoEducation}
                onChange={this.handleTextFieldChange}
                name="guardianTwoEducation"
                variant="standard"
                fullWidth
                inputProps={{ style: { fontSize: fontSizing.body, fontFamily: bloomkidzStyles.fontFamily } }}
              />
            </div>
            <div className={classes.textFieldWrapper}>
              <TextField
                placeholder="Occupation"
                value={guardianTwoOccupation}
                onChange={this.handleTextFieldChange}
                name="guardianTwoOccupation"
                variant="standard"
                fullWidth
                inputProps={{ style: { fontSize: fontSizing.body, fontFamily: bloomkidzStyles.fontFamily } }}
              />
            </div>
          </div>
          <div className={classes.inputSectionTitle}>
            Preferred Method of Contact
          </div>
          <div className={classes.paddingHorizontal}>
            <div className={classes.textFieldWrapper}>
              <TextField
                placeholder="Tap here to type"
                value={guardianTwoContactMethod}
                onChange={this.handleTextFieldChange}
                name="guardianTwoContactMethod"
                variant="standard"
                fullWidth
                inputProps={{ style: { fontSize: fontSizing.body, fontFamily: bloomkidzStyles.fontFamily } }}
              />
            </div>
          </div>
        </div>
      </BloomkidzLayout>
    );
  }
}

BloomkidzParentGuardianInformation2.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  updatePRO: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const {
    proForms: { bloomkidzParentGuardianInformation },
  } = state;

  return { bloomkidzParentGuardianInformation };
};

export default connect(mapStateToProps, { updatePRO })(withStyles(styles)(BloomkidzParentGuardianInformation2));
