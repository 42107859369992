import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';

import AppBar from '../components/app-bar';
import Page from './page';
import Layout from '../layouts/common';
import { colors, custom } from '../lib/styles';
import { editUserClinic } from '../state/patient-clinics';
import Continue from '../components/continue';

const baseStyles = {
  background: {
    backgroundColor: colors.primaryHighlight,
  },
  btn: {
    height: '50px',
    background: colors.secondaryColor,
  },
  label: {
    fontSize: '1rem',
  },
  link: {
    fontSize: '0.8rem',
  },
  text: {
    margin: '30px 20px',
    textAlign: 'left',
    fontStyle: 'italic',
  },
};

class ShareHealthInfo extends Page {
  constructor(props) {
    super(props);
    this.handleShare = this.handleShare.bind(this);
  }

  handleShare() {
    const { clinicId } = this.props.routeParams;
    this.props.editUserClinic({ clinic_id: clinicId, allow_data_access: true });
    this.props.router.push('/personalinfo');
  }

  render() {
    return (
      <Layout>
        <div style={baseStyles.background}>
          <AppBar
            backButtonOnClick={() => this.props.router.push('/personalinfo')}
            headerNode="Clinic"
          />
          <h3 style={custom.blueText}>Share your health information with this clinic now</h3>
          <p style={baseStyles.text}>
            Sharing your health information with your clinics is critical in helping them provide
            the best care. Your clinics can always view your information on the day of the appointment,
            but by sharing it now, they will be better prepared to assist you on the day of your appointment.
          </p>
          <div style={custom.centerWithColumn}>
            <Continue
              text="Share My Data Now"
              btnStyle={baseStyles.btn}
              labelStyle={baseStyles.label}
              onClick={this.handleShare}
            />
            <p style={custom.loginBottomText}>
              <Link
                style={baseStyles.link}
                to="/personalinfo"
              >
                Continue without sharing my health information
              </Link>
            </p>
          </div>
        </div>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  const { user, appData, patientClinics } = state;
  return {
    clinics: appData.clinics,
    patientClinics,
    user,
  };
}

export default connect(mapStateToProps, { editUserClinic })(ShareHealthInfo);
