import React from 'react';
import { LinearProgress, TextField } from '@material-ui/core';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMessage } from '@fortawesome/free-regular-svg-icons';

import AppBar from '../components/app-bar';
import Page from './page';
import QuestionLayout from '../layouts/question';
import YesNoButtonSet from '../components/yes-no-button-set';
import { colors, fontSizing } from '../lib/styles';
import { updatePRO } from '../state/pro-forms';

const styles = {
  boldAndItalic: {
    fontStyle: 'italic',
    fontWeight: 'bold',
  },
  iconWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 20,
  },
  instructionsText: {
    fontWeight: 'bold',
  },
  pageContent: {
    fontSize: fontSizing.body,
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 20,
  },
  questionText: {
    marginTop: 25,
  },
};

class HumanFactorsForIvcApp3 extends Page {
  constructor(props) {
    super(props);

    this.state = {
      stressed: null,
      reasonForStress: '',
    };
  }

  handleClickNext = () => {
    const { stressed, reasonForStress } = this.state;

    const value = { stressed };

    if (stressed === 'more' && reasonForStress) {
      value.reasonForStress = reasonForStress;
    }

    this.props.updatePRO({
      type: 'humanFactorsForIvcApp',
      position: this.getTrackIndex(),
      value,
    });

    this.forwardWithQuery(this.props.location.query);
  };

  handleChangeStressed = (value) => {
    this.setState({ stressed: value });
  }

  handleChangeReasonForStress = (e) => {
    this.setState({ reasonForStress: e.target.value });
  }

  render() {
    const { classes } = this.props;
    const { stressed, reasonForStress } = this.state;

    const continueDisabled = stressed === null;

    return (
      <QuestionLayout
        primaryBtnLabel="Next"
        primaryBtnOnTouchTap={this.handleClickNext}
        primaryBtnDisabled={continueDisabled}
        style={{ backgroundColor: colors.questionBackground }}
      >
        <AppBar
          headerNode="Feedback"
          backButtonOnClick={() => this.props.router.goBack()}
        />
        <LinearProgress
          variant="determinate"
          value={(this.getCurrentPage() / this.getTotalPages()) * 100}
        />
        <div className={classes.pageContent}>
          <div className={classes.iconWrapper}>
            <FontAwesomeIcon icon={faMessage} size="5x" />
          </div>
          <div className={classes.instructionsText}>
            Please provide feedback on your experience using the app.
          </div>
          <YesNoButtonSet
            isYes={stressed === 'more'}
            isNo={stressed === 'less'}
            onClickNo={() => this.handleChangeStressed('less')}
            onClickYes={() => this.handleChangeStressed('more')}
            questionText={(
              <div className={classes.questionText}>
                Were you more or less stressed using the Informed Vital Core application <span className={classes.boldAndItalic}>compared</span> to a doctor or nurse taking your vitals?
              </div>
            )}
            labelForYes="More"
            labelForNo="Less"
          />
          {stressed === 'more' ? (
            <>
              <div className={classes.questionText}>Could you explain why?</div>
              <TextField
                placeholder="tap here to enter"
                onChange={this.handleChangeReasonForStress}
                classes={{ root: classes.textFieldRoot }}
                value={reasonForStress}
                fullWidth
                multiline
              />
            </>
          ) : null}
        </div>
      </QuestionLayout>
    );
  }
}

export default connect(null, { updatePRO })(withStyles(styles)(HumanFactorsForIvcApp3));
