/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Fab,
} from '@material-ui/core';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { isAndroid, isIos } from 'common-ui';

import AppBar from '../components/app-bar';
import CropModal from '../components/crop-modal';
import Page from './page';
import QuestionLayout from '../layouts/question';
import { colors } from '../lib/styles';
import { updatePRO } from '../state/pro-forms';
import {
  setPrimaryFrontUrl,
  storeInsurancePrimaryFront,
} from '../state/insurance';
import { addInsuranceImage } from '../state/insurance-images';

const isMobile = isAndroid || isIos;

const styles = {
  answerButtonContainer: {
    display: 'flex',
    marginLeft: -10,
    marginTop: 8,
  },
  bar: {
    background: colors.lightBrightBlue,
  },
  circularProgressWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 20,
  },
  contentWrapper: {
    flexGrow: 1,
    overflowX: 'hidden',
    position: 'relative',
  },
  fab: {
    margin: '20px',
  },
  imgRow: {
    display: 'flex',
    justifyContent: 'center',
    padding: '15px 60px',
  },
  imageWrapper: {
    width: 100,
  },
  insuranceCardCanvas: {
    position: 'absolute',
    top: 0,
    visibility: 'hidden',
  },
  insuranceCardImage: {
    borderRadius: '5px',
    margin: '5px',
  },
  insuranceCardImageButton: {
    border: 'none',
    margin: 0,
    padding: 0,
  },
  insuranceCardImageContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  pageContent: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  pageInstructions: {
    color: 'grey',
    fontSize: '1.5rem',
    margin: '20px 40px 0px 40px',
    textAlign: 'center',
  },
  photoCaptureInstructions: {
    color: colors.primaryColor,
  },
  questionnaireContainer: {
    paddingLeft: 20,
    paddingRight: 20,
  },
  breathingText: {
    fontSize: '2rem',
    fontWeight: 'bold',
  },
  questionText: {
    fontSize: '2rem',
    marginTop: 25,
  },
  continueButtonWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 80,
  },
  video: {
    bottom: 0,
    height: '100%',
    left: 0,
    objectFit: 'cover',
    position: 'absolute',
    right: 0,
    top: 0,
    width: '100%',
  },
  videoWrapper: {
    paddingTop: '59%', // 75 / 128 aspect ratio
    position: 'relative',
    width: '100%',
  },
  videoWrapperOuter: {
    maxWidth: 500,
    width: '100%',
  },
};

class CaptureInsuranceCard1 extends Page {
  constructor(props) {
    super(props);

    this.state = {
      cropModalOpen: false,
      insuranceCardImage: null,
      insuranceName: '',
      showCaptureButton: false,
      permissionsDialogOpen: false,
    };

    this.insuranceCardVideoRef = React.createRef();
    this.insuranceCardCanvasRef = React.createRef();
  }

  componentDidMount() {
    const insuranceCardVideo = this.insuranceCardVideoRef.current;
    const mediaConstraints = isMobile
      ? { video: { facingMode: { exact: 'environment' } } }
      : { video: true };

    navigator.mediaDevices.getUserMedia(mediaConstraints)
      .then((stream) => {
        insuranceCardVideo.srcObject = stream;

        insuranceCardVideo.addEventListener('loadeddata', () => {
          this.setState({ showCaptureButton: true });
        });
      })
      .catch(() => {
        this.setState({ permissionsDialogOpen: true });
      });
  }

  // eslint-disable-next-line class-methods-use-this
  componentWillUnmount() {
    const insuranceCardVideo = this.insuranceCardVideoRef.current;
    if (insuranceCardVideo.srcObject) {
      insuranceCardVideo.srcObject.getVideoTracks().forEach(track => track.stop());
    }
  }

  handleCaptureImage = () => {
    const insuranceCardVideo = this.insuranceCardVideoRef.current;
    const insuranceCardCanvas = this.insuranceCardCanvasRef.current;
    const canvasContext = insuranceCardCanvas.getContext('2d');
    const { videoHeight, videoWidth } = insuranceCardVideo;
    insuranceCardCanvas.height = videoHeight;
    insuranceCardCanvas.width = videoWidth;

    canvasContext.drawImage(insuranceCardVideo, 0, 0, videoWidth, videoHeight);
    const insuranceCardImage = insuranceCardCanvas.toDataURL();

    this.setState({ cropModalOpen: true, insuranceCardImage });
  }

  handleCropModalClose = () => {
    this.setState({ cropModel: false });
  }

  handleSaveImage = (insuranceCardFrontImage) => {
    this.props.updatePRO({
      type: 'captureInsuranceCard',
      position: this.getTrackIndex(),
      value: insuranceCardFrontImage,
    });

    this.goToIndexWithQuery(0, this.props.location.query);

    // const { insurance } = this.props;

    // this.props.storeInsurancePrimaryFront(insuranceCardFrontImage)
    //   .then((res) => {
    //     return this.props.addInsuranceImage(insurance.data.id, 'PRIMARY_INSURANCE_CARD_FRONT', res);
    //   })
    //   .then((imgData) => {
    //     return this.props.setPrimaryFrontUrl(insurance.data.id, imgData.id);
    //   })
    //   .then(() => {
    //     this.goToIndexWithQuery(0, this.props.location.query);
    //   });
  }

  handleClosePermisionsDialog = () => {
    this.props.router.push('/notifications');
  }

  render() {
    const { classes } = this.props;
    const {
      cropModalOpen,
      insuranceCardImage,
      insuranceName,
      showCaptureButton,
      permissionsDialogOpen,
    } = this.state;

    const saveInsuranceDisabled = !insuranceName;

    return (
      <QuestionLayout
        primaryBtnDisabled={saveInsuranceDisabled}
        primaryBtnLabel="Save Insurance"
        primaryBtnOnTouchTap={this.handleClickNext}
        style={{ backgroundColor: colors.questionBackground }}
      >
        <AppBar
          headerNode="Insurance Card"
          backButtonOnClick={() => this.props.router.goBack()}
        />
        <Dialog
          open={permissionsDialogOpen}
          onClose={this.handleClosePermisionsDialog}
        >
          <DialogContent>
            <DialogContentText>
              Camera permissions need to be granted to take a picture of your insurance card
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClosePermisionsDialog}>
              Close
            </Button>
          </DialogActions>
        </Dialog>
        <CropModal
          aspectRatio={128 / 75}
          handleModalClose={this.handleModalClose}
          handleUpload={this.handleSaveImage}
          open={cropModalOpen}
          src={insuranceCardImage}
        />
        <canvas
          ref={this.insuranceCardCanvasRef}
          className={classes.insuranceCardCanvas}
        />
        <div className={classes.pageContent}>
          <div className={classes.videoWrapperOuter}>
            <div className={classes.videoWrapper}>
              <video
                ref={this.insuranceCardVideoRef}
                id="insuranceCardVideo"
                autoPlay
                playsInline
                className={classes.video}
              />
            </div>
          </div>
          {showCaptureButton ? (
            <Fab
              className={classes.fab}
              color="secondary"
              onClick={this.handleCaptureImage}
            >
              <i className="material-icons">camera_alt</i>
            </Fab>
          ) : (
            <div className={classes.circularProgressWrapper}>
              <CircularProgress />
            </div>
          )}
        </div>
      </QuestionLayout>
    );
  }
}

const mapStateToProps = (state) => {
  const { insurance } = state;

  return { insurance };
};

export default connect(mapStateToProps, {
  addInsuranceImage,
  setPrimaryFrontUrl,
  storeInsurancePrimaryFront,
  updatePRO,
})(withStyles(styles)(CaptureInsuranceCard1));
