import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import Page from './page';
import { updatePRO } from '../state/pro-forms';
import { colors, fontSizing } from '../lib/styles';
import { saveMetaData, sendImages } from '../lib/fetch';
import { startVideo } from '../lib/createAnswer';
import config from '../config';

const styles = {
  patientVideo: {
    minHeight: '450px',
    height: '100%',
    maxWidth: 1000,
    objectFit: 'cover',
    transform: 'scaleX(-1)',
    width: '100%',
    display: 'none',
  },
  patientCanvas: {
    minHeight: '450px',
    height: '100%',
    objectFit: 'cover',
    transform: 'scaleX(-1)',
    width: '100%',
  },
  container: {
    backgroundColor: colors.white,
    height: '100%',
  },
  topSection: {
    backgroundColor: colors.white,
    height: '100%',
    position: 'relative',
  },
  imReadyButton: {
    background: colors.primaryColor,
    borderRadius: 5,
    borderWidth: 0,
    color: 'white',
    flexGrow: 1,
    fontSize: fontSizing.body,
    maxWidth: 450,
    padding: 10,
    '&:disabled': {
      background: '#d9d8d8',
    },
  },
  imReadyButtonWrapper: {
    display: 'flex',
    justifyContent: 'center',
    padding: 20,
  },
  videoContainer: {
    height: '100%',
    width: '100%',
  },
  nonVideoContentContainer: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
  },
  iconContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    flexGrow: 0,
    flexShrink: 0,
    width: '100%',
    color: '#000',
    paddingTop: 10,
  },
  iconWrapper: {
    width: 100,
  },
  text: {
    fontSize: '2rem',
  },
  nextBtn: {
    textAlign: 'center',
    width: '100%',
    top: '80%',
  },
};

class IvcPrClinicalStudy1 extends Page {
  constructor(props) {
    super(props);
    this.state = {
      failed: false,
      cameraError: '',
      cameraObject: {},
      finishedRecording: false,
      startTimestamp: null,
      timestamps: null,
      imgEncoding: 'image/jpeg',
      pictureButtonEnabled: false,
      stream: null,
    };

    this.ctx = null;
    this.patientCanvas = React.createRef();
    this.patientVideo = React.createRef();
    this.videoContainer = React.createRef();
    this.cameraObjectRef = React.createRef();
    this.timestamp = React.createRef();
    this.cameraObjectRef.current = {};

    this.offscreenCanvas = document.createElement('canvas');
    this.offCtx = this.offscreenCanvas.getContext('2d');
    this.frameCallbackSupported = false;
    this.videoFrameCallback = null;
    this.videoSetTimeout = null;

    if ('requestVideoFrameCallback' in HTMLVideoElement.prototype) {
      this.frameCallbackSupported = true;
    }
  }

  handleContinue = async () => {
    const { updatePRO, userId, location: { query } } = this.props;

    const meta = {
      supportedConstraints: navigator.mediaDevices.getSupportedConstraints(),
      videoSettings: this.cameraObjectRef.current.videoTrack.getSettings(),
      ua: navigator.userAgent,
      timestamps: [this.timestamp.current],
    };

    let res;
    let error;
    try {
      res = await saveMetaData(meta, userId, this.timestamp.current);
    } catch (e) {
      error = e;
    }

    if (error || !res.ok) {
      console.error('saveMetaData failed: ', error, res);
      return this.setState({ failed: true });
    }

    updatePRO({
      type: 'ivcRRClinicalStudy',
      position: this.getTrackIndex(),
      value: {
        ...this.state,
        startTimestamp: this.timestamp.current,
      },
    });

    this.forwardWithQuery(query);
  }

  onframe = () => {
    const patientVideoCurrent = this.patientVideo.current;
    const pvH = patientVideoCurrent.videoHeight;
    const pvW = patientVideoCurrent.videoWidth;

    this.ctx.drawImage(patientVideoCurrent, 0, 0, pvW, pvH);

    if (this.frameCallbackSupported) {
      this.videoFrameCallback = patientVideoCurrent.requestVideoFrameCallback(this.onframe);
    } else {
      this.videoSetTimeout = setTimeout(this.onframe, 34);
    }
  }

  componentWillUnmount = () => {
    const videoElement = this.patientVideo.current;

    if (this.frameCallbackSupported) {
      videoElement.cancelVideoFrameCallback(this.videoFrameCallback);
    } else {
      clearTimeout(this.videoSetTimeout);
    }

    if (videoElement && videoElement.srcObject) {
      videoElement.srcObject.getTracks().forEach(t => t.stop());
      videoElement.srcObject = null;
    }
  }

  takePhoto = async () => {
    const patientVideoCurrent = this.patientVideo.current;
    const pvH = patientVideoCurrent.videoHeight;
    const pvW = patientVideoCurrent.videoWidth;
    this.timestamp.current = Date.now();

    this.setState({ pictureButtonEnabled: false });

    const imgData = this.ctx.getImageData(0, 0, pvW, pvH);

    try {
      const resp = await sendImages(
        [{ imgData, timestamp: this.timestamp.current }],
        null,
        null,
        null,
        `${config.API_URL}/users/${this.props.userId}/vital_images/${this.timestamp.current}`,
        'image/jpeg',
        1,
        null,
      );

      if (!resp.ok) throw new Error();

      this.handleContinue();
    } catch (e) {
      this.setState({ failed: true, pictureButtonEnabled: true });
    }
  };

  componentDidMount = async () => {
    const canvas = this.patientCanvas.current;
    this.ctx = canvas.getContext('2d', { willReadFrequently: true });

    startVideo(this.cameraObjectRef.current, { audio: false })
      .then(() => {
        const { stream } = this.cameraObjectRef.current;
        const patientVideoCurrent = this.patientVideo.current;
        patientVideoCurrent.srcObject = stream;
        patientVideoCurrent.addEventListener('loadeddata', () => {
          this.offscreenCanvas.height = patientVideoCurrent.videoHeight;
          this.offscreenCanvas.width = patientVideoCurrent.videoWidth;
          canvas.height = patientVideoCurrent.videoHeight;
          canvas.width = patientVideoCurrent.videoWidth;

          // todo what rate wont slow down?, should this be elsewhere?
          this.sendTimer = setInterval(this.drainFrames, 100);

          // wait half a second because first few frames are always black when turning on camera.
          setTimeout(() => {
            this.onframe();
            this.setState({ pictureButtonEnabled: true });
          }, 500);

          this.setState()
        }, { once: true });
        return patientVideoCurrent.play();
      });
  }

  render() {
    const { classes } = this.props;
    const {
      cameraError,
      pictureButtonEnabled
    } = this.state;
    return (
      <div className={classes.container}>
        <section className={classes.topSection}>
          <div id="videoContainer" className={classes.videoContainer} ref={this.videoContainer} >
            <video id="patientVideo" ref={this.patientVideo} playsInline autoPlay className={classes.patientVideo} muted />
            <canvas id="patientCanvas" ref={this.patientCanvas} className={classes.patientCanvas} />
          </div>
          <div className={classes.nonVideoContentContainer}>
            <div className={classes.imReadyButtonWrapper}>
              <button
                className={classes.imReadyButton}
                onClick={this.takePhoto}
                type="button"
                disabled={!pictureButtonEnabled}
              >
                Take Patient Photo
              </button>
            </div>
            {cameraError ? (
              <div><span>Unable to access camera: {cameraError.toString()}</span><br /></div>
            ) : ''}
          </div>
        </section>

        <Snackbar
          open={this.state.failed}
          autoHideDuration={7000}
          onClose={async () => {
            this.goToIndexWithQuery(0, this.props.location.query);
          }}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <MuiAlert elevation={6} variant="filled" severity="error" style={{ fontSize: 16 }}>There has been an error. The session will restart.</MuiAlert>
        </Snackbar>

      </div>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state;

  return { user, userId: user.id };
}

IvcPrClinicalStudy1.proptypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  updatePRO: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { updatePRO })(withStyles(styles)(IvcPrClinicalStudy1));
