import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  InputLabel,
  MenuItem,
  Select,
  Switch,
} from '@material-ui/core/';
import ActionInfo from '@material-ui/icons/Info';
import { get } from 'lodash';
import AppBar from '../components/app-bar';
import { logEvent } from '../lib/amplitude';
import Continue from '../components/continue';
import SignupTextField from '../components/signup-text-field';
import { custom, colors } from '../lib/styles';
import { saveUser, signup } from '../state/user';
import { apiFetch } from '../lib/fetch';

const styles = {
  bottomBtn: {
    marginTop: '2rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  error: {
    color: colors.errorRed,
    fontSize: '1.5rem',
    martinTop: '5px',
    textAlign: 'center',
  },
  info: {
    color: colors.imageBlue,
    height: '1.3rem',
    width: '1.3rem',
    marginLeft: '5px',
  },
  inputGroup: {
    background: colors.white,
    margin: '0 1rem 2rem',
    padding: '15px',
  },
  mainContent: {
    background: colors.questionBackground,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  passwordHintStyle: {
    margin: '0 10px',
  },
  toggleContainer: {
    height: 50,
  },
  toggleSection: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  span: {
    color: colors.imageBlue,
    flex: '2 2 25%',
    fontSize: '1.1rem',
    cursor: 'pointer',
  },
  languageLabel: {
    color: colors.formTextBlue,
    fontSize: 16,
  },
};

class Signup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: props.user.first_name || '',
      lastName: props.user.last_name || '',
      email: props.user.email || '',
      confirmEmail: props.user.email || '',
      password: props.user.password || '',
      confirmPassword: props.user.password || '',
      primary_language: 'en',
      emailError: undefined,
      passwordError: undefined,
      signupError: undefined,
      okTerms: Boolean(this.props.location.query.okTerms),
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleAgreement = this.handleForward.bind(this, 'agreement');
    this.handleForward = this.handleForward.bind(this);
    this.handleUpdateFirstName = this.handleChange.bind(this, 'firstName');
    this.handleUpdateLastName = this.handleChange.bind(this, 'lastName');
    this.handleUpdateEmail = this.handleChange.bind(this, 'email');
    this.handleUpdateConfirmEmail = this.handleChange.bind(this, 'confirmEmail');
    this.handleUpdatePassword = this.handleChange.bind(this, 'password');
    this.handleUpdateConfirmPassword = this.handleChange.bind(this, 'confirmPassword');
    this.handleUpdatePrimaryLanguage = this.handleChange.bind(this, 'primary_language');
    this.handleEnter = this.handleEnter.bind(this);
    this.handleToggle = this.handleToggle.bind(this);
  }

  componentWillMount() {
    const { signupToken } = this.props;
    if (signupToken) {
      apiFetch(`/users/tokens/${signupToken}/signup_data`)
        .then((res) => {
          this.setState({
            email: res.email,
            confirmEmail: res.email,
            firstName: res.first_name,
            lastName: res.last_name,
            primary_language: res.primary_language,
          });
        })
        .catch(() => {
          this.setState({ signupError: 'Your Invitation has expired, please contact your referring clinic for a new invitation link' });
        });
    }
  }

  handleChange(field, e) {
    let emailError;
    let passwordError;

    if (field === 'email' || field === 'confirmEmail') {
      emailError = undefined;
    }
    if (field === 'password' || field === 'confirmPassword') {
      passwordError = undefined;
    }

    this.setState({
      [field]: e.target.value,
      emailError,
      passwordError,
      signupError: undefined,
    });
  }

  handleForward(agreement) {
    const { saveUser, router, signupToken } = this.props;
    const {
      firstName,
      lastName,
      email,
      confirmEmail,
      password,
      confirmPassword,
      primary_language,
    } = this.state;
    let emailError;
    let passwordError;

    if (agreement === 'agreement') {
      saveUser({
        first_name: firstName,
        last_name: lastName,
        email,
        password,
        confirmPassword,
        primary_language,
      });
      return router.push('/signup_eula');
    }

    if (email.length === 0 && confirmEmail.length === 0) {
      emailError = 'Please enter your email';
    } else if (email !== confirmEmail) {
      emailError = 'Emails do not match';
    }

    if (password.length === 0 && confirmPassword.length === 0) {
      passwordError = 'Please enter a password';
    } else if (password !== confirmPassword) {
      passwordError = 'Passwords do not match';
    }

    if (emailError || passwordError) {
      this.setState({ emailError, passwordError });
      return;
    }

    logEvent('Sign Up for the App (New User)');
    this.props.signup({
      first_name: firstName,
      last_name: lastName,
      email,
      password,
      confirmPassword,
      source_id: 1,
      signup_token: signupToken,
      primary_language,
    })
      .then(() => {
        this.props.router.push('/confirmation');
      })
      .catch((e) => {
        this.setState({
          signupError: get(e, 'content.message') || get(e, 'content.error') || e.message,
        });
      });
  }

  handleEnter(e) {
    if (e.key === 'Enter') {
      this.handleForward();
    }
  }

  handleToggle(event, toggle) {
    this.setState({ okTerms: toggle });
  }

  render() {
    const {
      email,
      confirmEmail,
      firstName,
      lastName,
      password,
      confirmPassword,
      emailError,
      passwordError,
      signupError,
      okTerms,
      primary_language,
    } = this.state;

    return (
      <div>
        <AppBar headerNode="Registration" backButtonOnClick={() => this.props.router.push('/login')} />
        <div style={styles.mainContent}>
          <div style={custom.signupHeaderText}>
            <div style={custom.signupSubHeader}>Register by entering the fields below</div>
          </div>
          <div style={styles.inputGroup}>
            <SignupTextField value={firstName} onChange={this.handleUpdateFirstName} onKeyPress={this.handleEnter} />
            <SignupTextField
              label="Last Name"
              value={lastName}
              onChange={this.handleUpdateLastName}
              onKeyPress={this.handleEnter}
            />
          </div>
          <div style={styles.inputGroup}>
            <SignupTextField
              errorText={emailError}
              label="Email"
              value={email}
              onChange={this.handleUpdateEmail}
              onKeyPress={this.handleEnter}
            />
            <SignupTextField
              errorText={emailError}
              label="Confirm Email"
              value={confirmEmail}
              onChange={this.handleUpdateConfirmEmail}
              onKeyPress={this.handleEnter}
            />
          </div>
          <div style={styles.inputGroup}>
            <InputLabel
              shrink
              id="language-label"
              style={styles.languageLabel}
            >
              Language
            </InputLabel>
            <Select
              labelId="language-label"
              value={primary_language}
              onChange={this.handleUpdatePrimaryLanguage}
            >
              <MenuItem value="en">English</MenuItem>
              <MenuItem value="es">Spanish</MenuItem>
            </Select>
          </div>
          <div style={styles.inputGroup}>
            <SignupTextField
              errorText={passwordError}
              label="Password"
              onChange={this.handleUpdatePassword}
              onKeyPress={this.handleEnter}
              type="password"
              value={password}
              placeholder="Password"
            />
            <SignupTextField
              errorText={passwordError}
              label="Confirm Password"
              onChange={this.handleUpdateConfirmPassword}
              onKeyPress={this.handleEnter}
              type="password"
              value={confirmPassword}
              placeholder="Confirm Password"
            />
          </div>
          <div style={styles.passwordHintStyle}>
            Passwords must be at least 8 characters, contain 1 number, and 1 uppercase letter.
          </div>
          <section style={styles.toggleContainer}>
            <div style={styles.toggleSection}>
              I accept the
              <Switch color="secondary" checked={okTerms} onChange={this.handleToggle} />
              <div onClick={this.handleAgreement}>
                <span style={styles.span}>End User License Agreement</span>
              </div>
              <ActionInfo style={styles.info} />
            </div>
          </section>
          <div style={styles.error}>{signupError}</div>
          <div style={styles.bottomBtn}>
            <Continue
              label="Submit"
              btnStyle={custom.signupBtnStyle}
              labelStyle={custom.signupBtnLabel}
              style={custom.signupBtnSpacing}
              onClick={this.handleForward}
              disabled={!okTerms}
            />
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const { user, personalInfo } = state;
  const { signupToken } = ownProps.params;
  return { user, personalInfo, signupToken };
}

Signup.propTypes = {
  location: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  saveUser: PropTypes.func.isRequired,
  signup: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  { saveUser, signup },
)(Signup);
