import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';

import { colors } from '../lib/styles';

const baseStyles = {
  listItem: {
    borderBottom: `1px solid ${colors.primaryHighlight}`,
    color: colors.primaryColor,
    fontWeight: 400,
    textAlign: 'left',
  },
};

class PharmacyListItem extends Component {
  constructor(props) {
    super(props);
    this.navToPharmacy = this.navToPharmacy.bind(this);
  }

  navToPharmacy() {
    this.props.router.push(`/pharmacy?pharmacy_hx_id=${this.props.item.id}`);
  }

  render() {
    const { item } = this.props;
    const city = item.city ? ` (${item.city})` : '';
    return (
      <ListItem
        button
        onClick={this.navToPharmacy}
        style={baseStyles.listItem}
      >
        <ListItemIcon>
          <img alt="Pharmacy Icon" src="/img/InsuranceIcon.png" />
        </ListItemIcon>
        <ListItemText primary={`${item.name}${city}`} />
      </ListItem>
    );
  }
}

PharmacyListItem.propTypes = {
  item: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
};

export default PharmacyListItem;
