import React from 'react';
import PropTypes from 'prop-types';

import {
  FormControlLabel,
  LinearProgress,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import AppBar from '../components/app-bar';
import Page from './page';
import QuestionLayout from '../layouts/question';
import { title, instructions, options, nextBtn } from '../lib/i18next';
import { colors } from '../lib/styles';
import { submitPRO } from '../state/pro-forms';
import { Walking2 } from '../lib/icons';

const { lightBrightBlue } = colors;

const styles = {
  contentWrapper: {
    flexGrow: 1,
    overflowX: 'hidden',
    position: 'relative',
  },
  descriptionText: {
    fontSize: 18,
    fontStyle: 'italic',
  },
  image: {
    height: 200,
    padding: '15px 60px',
    textAlign: 'center',
  },
  nextButton: {
    bottom: 30,
    left: 30,
    position: 'absolute',
    right: 30,
  },
  pageIndicator: {
    fontSize: 14,
  },
  questionnaire: {
    marginLeft: 40,
    marginRight: 40,
    position: 'relative',
  },
  title: {
    color: 'black',
    fontSize: '22px',
    fontWeight: 'strong',
    textTransform: 'uppercase',
  },
  bar: {
    backgroundColor: lightBrightBlue,
  },
};

const StyledFormControlLabel = withStyles(() => ({
  label: {
    fontSize: 22,
  },
  root: {
    alignItems: 'start',
  },
}))(FormControlLabel);

class EqFunctionality extends Page {
  constructor(props) {
    super(props);

    this.state = {
      functionalityLevel: '',
    };
  }

  handleChangeFunctionLevel = (e) => {
    this.setState({ functionalityLevel: e.target.value });
  };

  handleClickNext = () => {
    const { functionalityLevel } = this.state;
    if (this.state.submitting) return;
    const { user, eq5d3l, location } = this.props;
    const { id } = location.query;
    this.setState({ submitting: true });

    this.props.submitPRO({ userId: user.id, id }, {
      pro_type: 'EQ5D-3L-CASA-COLINA',
      pro_data: { data: [...eq5d3l, functionalityLevel] },
    }, user)
      .then(() => this.forward())
      .catch(() => this.setState({ submitting: false }));
  }

  render() {
    const { classes } = this.props;
    const { functionalityLevel } = this.state;

    return (
      <QuestionLayout
        primaryBtnLabel={nextBtn()}
        primaryBtnOnTouchTap={this.handleClickNext}
        primaryBtnDisabled={!functionalityLevel}
        style={{ backgroundColor: colors.questionBackground }}
      >
        <AppBar
          headerNode={title('eqFunctionality')}
          backButtonOnClick={() => this.props.router.goBack()}
          rightNode={
            <div style={styles.pageIndicator}>{`${this.getCurrentPage()} of ${this.getTotalPages()}`}</div>
          }
        />
        <LinearProgress
          variant="determinate"
          value={(this.getCurrentPage() / this.getTotalPages()) * 100}
          classes={{ bar: classes.bar }}
        />
        <div className={classes.contentWrapper}>
          <div style={styles.image}>
            <Walking2 />
          </div>
          <div style={styles.questionnaire}>
            <p style={styles.title}>
              {instructions('eqFunctionality')}
            </p>
            <RadioGroup value={functionalityLevel} onChange={this.handleChangeFunctionLevel}>
              <FormControlLabel
                value="I can walk in the community"
                label={options('healthy', 'eqFunctionality')}
                control={<Radio classes={{ root: classes.radio }} />}
                classes={{ root: classes.formControlLabelRoot, label: classes.radioLabel }}
              />
              <FormControlLabel
                value="I can walk at home"
                label={options('mild', 'eqFunctionality')}
                control={<Radio classes={{ root: classes.radio }} />}
                classes={{ root: classes.formControlLabelRoot, label: classes.radioLabel }}
              />
              <StyledFormControlLabel
                value="I can transfer"
                label={(
                  <div>
                    <div>{options('medium', 'eqFunctionality')}</div>
                    <div className={classes.descriptionText}>
                    ({options('mediumDetails', 'eqFunctionality')})
                    </div>
                  </div>
              )}
                control={<Radio classes={{ root: classes.radio }} />}
                classes={{ root: classes.formControlLabelRoot, label: classes.radioLabel }}
              />
              <FormControlLabel
                value="I am bed bound"
                label={options('severe', 'eqFunctionality')}
                control={<Radio classes={{ root: classes.radio }} />}
                classes={{ root: classes.formControlLabelRoot, label: classes.radioLabel }}
              />
            </RadioGroup>
          </div>
        </div>
      </QuestionLayout>
    );
  }
}

function mapStateToProps(state) {
  const {
    user,
    proForms: {
      eq5d3l,
    },
  } = state;
  return {
    user,
    eq5d3l,
  };
}

EqFunctionality.proptypes = {
  eq5d3l: PropTypes.array.isRequired,
  updatePRO: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { submitPRO })(withStyles(styles)(EqFunctionality));
