import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get, includes, flatten } from 'lodash';

import { colors } from '../lib/styles';
import { Headaches } from '../lib/icons';
import Page from './page';
import ButtonPRO from '../components/button-pro';
import { updatePRO, submitPRO } from '../state/pro-forms';

const buttonLabels = ['Never', 'Rarely', 'Sometimes', 'Very Often', 'Always'];

const options = [
  {
    question: 'How often have you felt too tired to do work or daily activities because of your headache?',
    label: buttonLabels,
  },
  {
    question: 'How often have you felt fed up or irritated because of your headaches?',
    label: buttonLabels,
  },
  {
    question: 'How often did heachaches limit your ability to concentrate on work or daily activities?',
    label: buttonLabels,
  },
];
const { questionBackground } = colors;

class Hit6HeadacheImpactP2 extends Page {
  state = {
    submitting: false,
  }
  onClickBackButton = () => {
    this.backward();
  }

  onSubmission = () => {
    if(this.state.submitting) return;

    const { user } = this.props;
    const { id } = this.props.location.query;
    this.setState({ submitting: true });
    this.props.submitPRO({ userId: user.id, id }, {
      pro_type: 'HIT-6',
      pro_data: { data: flatten(this.props.hit6) },
    }, user)
      .then(() => this.forward())
      .catch(() => this.setState({ submitting: false }));
  }

  render() {
    const page = get(this.props.hit6, this.getTrackIndex(), [null, null, null]);
    const value = includes(page, null) ? null : 0;
    return (
      <ButtonPRO
        backgroundColor={questionBackground}
        currentPage={this.getCurrentPage()}
        image={Headaches}
        onClickBackButton={() => this.props.router.goBack()}
        onChange={this.props.updatePRO}
        onSubmission={this.onSubmission}
        options={options}
        title="In the past 4 weeks"
        header="Headache Impact"
        primaryBtnDisabled={this.state.submitting}
        totalPages={this.getTotalPages()}
        trackIndex={this.getTrackIndex()}
        value={value}
        pro={page}
        type="hit6"
      />
    );
  }
}


function mapStateToProps(state) {
  const {
    user,
    proForms: {
      hit6,
    },
  } = state;
  return {
    user,
    hit6,
  };
}

Hit6HeadacheImpactP2.propTypes = {
  hit6: PropTypes.array.isRequired,
  updatePRO: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { updatePRO, submitPRO })(Hit6HeadacheImpactP2);
