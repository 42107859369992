import { get, uniq } from 'lodash';
import painCalendarFormatter from './pain-calendar-formatter';

export default function aggregateHeadData(data) {
  const defaultData = { symptoms: [], preventitiveRx: [], abortiveRx: [], painLocations: [], total: 0 };

  if(!data) return defaultData;
  const headData = data.reduce((allData, item) => {
    const { worstPain } = allData;
    const currentPain = get(item, 'pain.0', {});
    if(currentPain) {
      allData.worstPain = (worstPain && worstPain.severity > currentPain.severity) ? worstPain : currentPain;
      allData.symptoms = uniq([...allData.symptoms, ...item.symptoms]);
      allData.preventitiveRx = uniq([...allData.preventitiveRx, ...item.abative_rx]);
      allData.abortiveRx = uniq([...allData.abortiveRx, ...item.abortive_rx]);
      allData.painLocations = uniq([...allData.painLocations, `${currentPain.brain_quadrant} ${currentPain.brain_locations}`]);
      allData.total += 1;
    }
    return allData;
  }, defaultData);

  headData.barChart = painCalendarFormatter(data, { interval: 'hour' });
  return headData;
}
