import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { ListItem, MenuItem, Select } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { colors } from '../lib/styles';

const selectStyles = {
  root: {
    color: colors.white,
  },
  select: {
    '&::before': {
      borderBottomColor: colors.white,
    },
  },
  selectIcon: {
    color: colors.white,
  },
};

const baseStyles = {
  relationRow: {
    backgroundColor: 'lightGrey',
    color: colors.primaryColor,
    display: 'flex',
    fontSize: '1.5rem',
    justifyContent: 'space-between',
    textTransform: 'uppercase',
  },
  relationShowSelect: {
    width: '100px',
  },
  relationRowSelectIcon: {
    fill: colors.white,
    left: '5.5rem',
  },
  selectFieldLabel: {
    color: colors.white,
    padding: 0,
  },
};

const relations = [
  <MenuItem key="mother" value="MOTHER">Mother</MenuItem>,
  <MenuItem key="father" value="FATHER">Father</MenuItem>,
  <MenuItem key="parent" value="PARENT">Both</MenuItem>,
];

class RelationRow extends Component {
  constructor(props) {
    super(props);
    this.handleFamilyChange = this.handleFamilyChange.bind(this);
  }

  handleFamilyChange(e) {
    const { hx, condition, onChange } = this.props;
    if (hx) {
      hx.relationship = e.target.value;
      onChange(condition.id, true, hx);
    }
  }

  render() {
    const { classes, disabled, hx } = this.props;
    return (
      <ListItem
        // primaryText="Relationship"
        style={baseStyles.relationRow}
      >
        {/* <SelectField
          disabled={disabled}
          iconStyle={baseStyles.relationRowSelectIcon}
          labelStyle={baseStyles.selectFieldLabel}
          onChange={this.handleFamilyChange}
          style={baseStyles.relationShowSelect}
          value={hx.relationship}
        >
          {relations}
        </SelectField> */}
        <span>Relationship</span>
        <Select
          classes={{
            icon: classes.selectIcon,
            root: classes.root,
          }}
          className={classes.select}
          disabled={disabled}
          onChange={this.handleFamilyChange}
          style={baseStyles.relationShowSelect}
          value={hx.relationship}
        >
          {relations}
        </Select>
      </ListItem>
    );
  }
}

RelationRow.propTypes = {
  condition: PropTypes.number.isRequired,
  disabled: PropTypes.bool.isRequired,
  hx: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default withStyles(selectStyles)(RelationRow);
