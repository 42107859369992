import React from 'react';
import PropTypes from 'prop-types';
import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import { withStyles } from '@material-ui/core/styles';

import { fontSizing } from '../lib/styles';

const styles = {
  formControlLabel: {
    fontSize: fontSizing.smallX,
  },
  formControlLabelRoot: {
    marginLeft: 8,
    marginRight: 8,
  },
  option: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    fontSize: fontSizing.smallX,
  },
  radioGroup: {
    justifyContent: 'space-between',
    marginTop: 5,
  },
  root: {
    marginBottom: 20,
    marginTop: 20,
  },
  subject: {
    fontSize: fontSizing.body,
  },
};

const FivePointRatingScale = (props) => {
  const {
    classes,
    name,
    onChange,
    showOptionDescription,
    subject,
    value,
  } = props;

  return (
    <div className={classes.root}>
      <div className={classes.subject}>{subject}</div>
      <div>
        <RadioGroup
          row
          className={classes.radioGroup}
          value={value}
          onChange={onChange}
          name={name}
        >
          <FormControlLabel
            value="1"
            control={(
              <Radio
                icon={<RadioButtonUncheckedIcon fontSize="large" />}
                checkedIcon={<RadioButtonCheckedIcon fontSize="large" />}
              />
            )}
            label={showOptionDescription ? (
              <div className={classes.option}>
                <div>1</div>
                <div>Disagree</div>
              </div>
            ) : '1'}
            labelPlacement="bottom"
            classes={{
              label: classes.formControlLabel,
              root: classes.formControlLabelRoot,
            }}
          />
          <FormControlLabel
            value="2"
            control={(
              <Radio
                icon={<RadioButtonUncheckedIcon fontSize="large" />}
                checkedIcon={<RadioButtonCheckedIcon fontSize="large" />}
              />
            )}
            label="2"
            labelPlacement="bottom"
            classes={{
              label: classes.formControlLabel,
              root: classes.formControlLabelRoot,
            }}
          />
          <FormControlLabel
            value="3"
            control={(
              <Radio
                icon={<RadioButtonUncheckedIcon fontSize="large" />}
                checkedIcon={<RadioButtonCheckedIcon fontSize="large" />}
              />
            )}
            label="3"
            labelPlacement="bottom"
            classes={{
              label: classes.formControlLabel,
              root: classes.formControlLabelRoot,
            }}
          />
          <FormControlLabel
            value="4"
            control={(
              <Radio
                icon={<RadioButtonUncheckedIcon fontSize="large" />}
                checkedIcon={<RadioButtonCheckedIcon fontSize="large" />}
              />
            )}
            label="4"
            labelPlacement="bottom"
            classes={{
              label: classes.formControlLabel,
              root: classes.formControlLabelRoot,
            }}
          />
          <FormControlLabel
            value="5"
            control={(
              <Radio
                icon={<RadioButtonUncheckedIcon fontSize="large" />}
                checkedIcon={<RadioButtonCheckedIcon fontSize="large" />}
              />
            )}
            label={showOptionDescription ? (
              <div className={classes.option}>
                <div>5</div>
                <div>Agree</div>
              </div>
            ) : '5'}
            labelPlacement="bottom"
            classes={{
              label: classes.formControlLabel,
              root: classes.formControlLabelRoot,
            }}
          />
        </RadioGroup>
      </div>
    </div>
  );
};

FivePointRatingScale.defaultProps = {
  showOptionDescription: false,
};

FivePointRatingScale.propTypes = {
  classes: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  showOptionDescription: PropTypes.bool,
  subject: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

export default withStyles(styles)(FivePointRatingScale);
