import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { colors } from '../lib/styles';
import CircularProgress from './circular-progress';

const styles = {
  contentWrapper: {
    minWidth: '100%',
    position: 'relative',
  },
  label: {
    fontSize: '1.25rem',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  labelHeader: {
    display: 'flex',
    overflowWrap: 'break-word',
    flexDirection: 'column',
    fontSize: '4vw',
    textAlign: 'center',
    fontWeight: 'bold',
    marginBottom: 10,
    maxWidth: '8rem',
  },
  labelInnerWrapper: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  labelOuterWrapper: {
    alignItems: 'center',
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0,
  },
};

class CircularProgressLabeled extends Component {
  render() {
    const {
      backgroundColor,
      label,
      progressColor,
      progress,
      strokeWidth,
      goal,
    } = this.props;

    const labelHeader = `${progress}/${goal}`;

    return (
      <div style={styles.contentWrapper}>
        <CircularProgress
          backgroundColor={backgroundColor}
          progressColor={progressColor}
          progress={(progress > 1) ? 1 : progress}
          strokeWidth={strokeWidth}
        />
        <div style={styles.labelOuterWrapper}>
          <div style={styles.labelInnerWrapper}>
            <div style={styles.labelHeader}>
              {labelHeader}
            </div>
            <div style={styles.label}>
              <div>{label}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

CircularProgressLabeled.defaultProps = {
  backgroundColor: 'lightgrey',
  progressColor: colors.primaryColor,
  progress: 0,
  strokeWidth: 10,
};

CircularProgressLabeled.propTypes = {
  backgroundColor: PropTypes.string,
  label: PropTypes.string.isRequired,
  goal: PropTypes.number.isRequired,
  progressColor: PropTypes.string,
  progress: PropTypes.number,
  strokeWidth: PropTypes.number,
};

export default CircularProgressLabeled;
