import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { withTranslation } from 'react-i18next';

import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import LinearProgress from '@material-ui/core/LinearProgress';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';

import AddIcon from '@material-ui/icons/Add';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';

import DashboardTop from '../components/dashboard-top';
import AppBar from '../components/app-bar';
import Page from './page';
import Layout from '../layouts/common';
import Continue from '../components/continue';
import { colors } from '../lib/styles';

import { browsePainLocationsIfNeeded } from '../state/app-data';
import { submitPRO, updatePRO } from '../state/pro-forms';

import { apiFetch } from '../lib/fetch';

export const footLocations = {
  en: {
    bottom: 'Bottom',
    top: 'Top',
  },
  es: {
    bottom: 'Fondo',
    top: 'Cima',
  },
};

const getFootLocationInPrimaryLang = (footLocation, primaryLanguage) => {
  const location = footLocation === 'Bottom' ? 'bottom' : 'top';

  return footLocations[primaryLanguage][location];
};

const getPainPointNameInPrimaryLang = (text, options, primaryLanguage) => {
  let primaryLanguageKey = 'name';

  if (primaryLanguage === 'es') primaryLanguageKey = 'es_name';

  const painPoint = Object.values(options).find((option) => {
    return option.name === text;
  });

  return painPoint[primaryLanguageKey];
};

const baseStyles = {
  appBar: {
    height: '8.75rem',
    paddingLeft: '1.5rem',
    paddingRight: '1.5rem',
  },
  backButton: {
    left: '-1.25rem',
    height: '3.6875rem',
    padding: 0,
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    width: '3.6875rem',
  },
  backButtonIcon: {
    height: '3.6875rem',
    width: '3.6875rem',
  },
  bigArrowsLightBlue: {
    width: '60px',
    height: '60px',
    color: colors.primaryHighlight,
  },
  confirmBtn: {
    marginBottom: '7.5vw',
  },
  errorText: {
    fontSize: '1.5rem',
  },
  datePickerTextField: {
    color: 'black',
    width: '80vw',
  },
  headachePainTypeSect: {
    alignItems: 'center',
    backgroundColor: colors.lightBrightBlue,
    display: 'flex',
    height: '95px',
    justifyContent: 'space-between',
  },
  header: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    width: '100%',
  },
  headerTitle: {
    color: colors.black,
    display: 'inline-block',
    fontWeight: 'bold',
    fontSize: '1.8rem',
    textAlign: 'left',
    width: '100%',
  },
  intensityHeader: {
    background: colors.primaryColor,
    color: colors.white,
    fontSize: '2rem',
    padding: '2rem 0',
    textAlign: 'center',
  },
  mainContent: {
    flexGrow: 1,
  },
  content: {
    textAlign: 'left',
    padding: '20px',
  },
  nextButton: {
    position: 'absolute',
    right: 5,
    textAlign: 'right',
    top: '50%',
    transform: 'translateY(-50%)',
  },
  nextButtonLabel: {
    color: colors.black,
    fontSize: '1.6rem',
    padding: 0,
    textTransform: 'none',
  },
  red: {
    color: colors.errorRed,
  },
  rootContainer: {
    display: 'flex',
    minHeight: '100%',
  },
  slider: {
    background: colors.primaryColor,
    marginBottom: '2rem',
    paddingBottom: '1rem',
  },
  sliderHeader: {
    color: colors.white,
    fontSize: '1.5rem',
  },
  sliderWrapper: {
    paddingTop: '2.5rem',
  },
  sliderWrapperContainer: {
    height: '6.5rem',
    marginTop: '2rem',
    position: 'relative',
  },
  triggersQuestion: {
    fontSize: '1.5rem',
    marginBottom: '1rem',
    marginTop: '4rem',
  },
  list: {
    borderRadius: 0,
    width: '100%',
    backgroundColor: 'white',
  },
};

const getWoundLocation = (bodyLocation, name, footLocation) => {
  const anterior = 'Anterior (front/top)';
  const posterior = 'Posterior (back/bottom)';
  const medial = 'Medial (inner/inside)';
  const side = 'Side';

  let location;

  if (footLocation) {
    if (footLocation === 'Top') {
      location = anterior;
    } else {
      location = posterior;
    }
  } else if (bodyLocation === 'BACK') {
    location = posterior;
  } else if (bodyLocation === 'FRONT') {
    location = anterior;
  } else if (bodyLocation === 'LEFT' && name.includes('Right')) {
    location = medial;
  } else if (bodyLocation === 'RIGHT' && name.includes('Left')) {
    location = medial;
  } else {
    location = side;
  }

  return location;
};

const getWoundArea = (bodyPart, y) => {
  let area;

  if (bodyPart) {
    if (y <= 220 || bodyPart.includes('Wrist') || bodyPart.includes('Hand') || bodyPart.includes('Fingers')) {
      area = 'Upper Body';
    } else {
      area = 'Lower Body';
    }
  }

  return area;
};

const AppBarContinueButton = props => (
  <Button
    onClick={props.onClick}
    style={{ ...baseStyles.nextButton, ...baseStyles.nextButtonLabel }}
  >Next
  </Button>
);
AppBarContinueButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

class WoundPro3 extends Page {
  constructor(props) {
    super(props);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleNewWound = this.handleNewWound.bind(this);
    this.handleContinue = this.handleContinue.bind(this);
    this.handleClickBack = this.handleClickBack.bind(this);

    this.state = {
      disabledBtn: false,
    };
  }

  componentWillMount() {
    const { browsePainLocationsIfNeeded, wound } = this.props;

    browsePainLocationsIfNeeded();

    const newWound = {
      ...wound[0][0],
      images: [...wound[1].files],
    };

    const oldWounds = wound[2] ? wound[2] : [];
    this.setState({
      wounds: [...oldWounds, newWound],
    });
  }

  handleDelete(entry) {
    const { painLocations, user, t } = this.props;
    const { wounds } = this.state;
    const array = [...wounds];
    let count = array.length;

    const index = array.indexOf(entry);

    // display wound location in user's primary language
    const locationName = getPainPointNameInPrimaryLang(entry.name, painLocations, user.primary_language);
    let footLocation;

    // display foot location in user's primary language
    if (entry.foot_location) {
      footLocation = getFootLocationInPrimaryLang(entry.foot_location, user.primary_language);
    }

    const message = t('areYouSureYouWantToDelete') + (entry.foot_location ? `${locationName} (${footLocation})` : locationName);

    if (index > -1 && window.confirm(message)) {
      array.splice(index, 1);
      count--;
      this.setState({
        wounds: array,
      });
      if (count === 0) {
        this.setState({
          disabledBtn: true,
        });
      }
    }
  }

  async handleNewWound() {
    this.props.updatePRO({ type: 'wound', position: this.getTrackIndex(), value: this.state.wounds });
    this.goToIndexWithQuery(0, this.props.location.query);
  }

  handleClickBack() {
    this.backwardWithQuery(this.props.location.query);
  }

  async handleContinue() {
    const { id } = this.props.location.query;
    const { painLocations, user } = this.props;
    const { wounds, submitting } = this.state;
    const woundsFormatted = [];
    if (submitting) return;
    this.setState({ submitting: true });
    await wounds.forEach((wound) => {
      const { images } = wound;
      const woundIdx = wounds.indexOf(wound);
      images.forEach(async (image) => {
        const idx = images.indexOf(image);
        const woundFormData = new FormData();

        const woundPainLocation = painLocations[wound.body_point];
        const location = getWoundLocation(woundPainLocation.body_location, woundPainLocation.name, wound.foot_location);
        const area = getWoundArea(woundPainLocation.name, woundPainLocation.y);

        woundFormData.append('primary_location', area);
        woundFormData.append('secondary_location', woundPainLocation.name);
        woundFormData.append('tertiary_location', location);

        woundFormData.append('image', image);
        woundFormData.append('image_name', `patient_image_${moment().format('MMDDYYYY_HHmmss')}_${woundIdx + 1}_${idx + 1}_${user.id}`);

        woundsFormatted.push({
          primary_location: area,
          secondary_location: woundPainLocation.name,
          tertiary_location: location,
        });

        apiFetch(`/users/${user.id}/wounds`, {
          method: 'POST',
          body: woundFormData,
        });
      });
    });

    await submitPRO(
      { userId: user.id, id },
      {
        pro_data: { data: { wounds: woundsFormatted } },
        pro_type: 'WOUND',
      },
      user,
    );
    this.finishTrack();
  }

  render() {
    const { painLocations, user, t } = this.props;
    const { wounds } = this.state;

    return (
      <Layout style={baseStyles.rootContainer}>
        <div style={baseStyles.mainContent}>
          <AppBar
            backButtonOnClick={() => this.props.router.goBack()}
            headerNode={t('headerNode')}
          />
          <LinearProgress
            variant="determinate"
            value={(this.getCurrentPage() / this.getTotalPages()) * 100}
          />
          <DashboardTop
            title=""
            paragraph={t('tapThePlusSign')}
          >
            <List style={baseStyles.list}>
              <ListItem key={0} role={undefined} button onClick={() => { this.handleNewWound(); }}>
                <ListItemIcon>
                  <AddIcon style={{ fontSize: 28 }} />
                </ListItemIcon>
                <ListItemText
                  id="checkbox-list-label-0"
                  primary={(
                    <Fragment>
                      <Typography
                        component="span"
                        variant="h4"
                        color="textPrimary"
                      >
                        {t('addPainLocation')}
                      </Typography>
                    </Fragment>
                    )}
                />
              </ListItem>
              {wounds.map((value, index) => {
                const labelId = `checkbox-list-label-${index + 1}`;

                // display wound location in user's primary language
                const locationName = getPainPointNameInPrimaryLang(value.name, painLocations, user.primary_language);
                let footLocation;

                // display foot location in user's primary language
                if (value.foot_location) {
                  footLocation = getFootLocationInPrimaryLang(value.foot_location, user.primary_language);
                }

                return (
                  <ListItem key={value.foot_location ? `${locationName} (${value.foot_location})` : locationName} role={undefined} button style={{ borderTop: '1px solid rgba(0, 0, 0, 0.12)' }}>
                    <ListItemIcon />
                    <ListItemText
                      id={labelId}
                      primary={(
                        <React.Fragment>
                          <Typography
                            component="span"
                            variant="h4"
                            color="textPrimary"
                          >
                            {value.foot_location ? `${locationName} (${footLocation})` : locationName}
                          </Typography>
                        </React.Fragment>
                        )}
                    />
                    <ListItemSecondaryAction>
                      <IconButton edge="end" aria-label="comments" onClick={() => { this.handleDelete(value); }}>
                        <RemoveCircleIcon style={{ color: 'red', fontSize: 28 }} />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                );
              })}
            </List>

            <Continue
              disabled={this.state.disabledBtn}
              text={t('confirm')}
              onClick={() => {
                this.handleContinue();
              }}
              btnStyle={{ marginTop: '50px', alignSelf: 'center' }}
            />
          </DashboardTop>
        </div>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  const {
    user,
    proForms: { wound },
    appData: { painLocations },
  } = state;
  return { painLocations, user, wound };
}

WoundPro3.proptypes = {
  user: PropTypes.object.isRequired,
  wound: PropTypes.array.isRequired,
  route: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { browsePainLocationsIfNeeded, updatePRO })(withTranslation('woundPro3')(WoundPro3));
