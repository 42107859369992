import React from 'react';
import { LinearProgress, TextField } from '@material-ui/core';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import AppBar from '../components/app-bar';
import Page from './page';
import QuestionLayout from '../layouts/question';

import YesNoButtonSet from '../components/yes-no-button-set';
import { colors } from '../lib/styles';
import { updatePRO } from '../state/pro-forms';
import { Frown } from '../lib/icons';
import HealthSliderHorizontal from '../components/health-slider-horizontal';

const styles = {
  bar: {
    backgroundColor: colors.lightBrightBlue,
  },
  contentWrapper: {
    flexGrow: 1,
    overflowX: 'hidden',
    position: 'relative',
  },
  healthSliderWrapper: {
    marginTop: 60,
    paddingLeft: 40,
    paddingRight: 40,
  },
  imgRow: {
    display: 'flex',
    justifyContent: 'center',
    padding: '15px 60px',
  },
  imageWrapper: {
    width: 100,
  },
  marginTop40: {
    marginTop: 40,
  },
  questionnaireContainer: {
    paddingLeft: 20,
    paddingRight: 20,
  },
  questionText: {
    fontSize: '2rem',
  },
  textFieldRoot: {
    marginTop: 10,
    width: 256,
    '& input': {
      fontSize: 18,
    },
    '& label': {
      fontSize: 18,
    },
  },
};

class HypertensionAssessmentDaily7 extends Page {
  constructor(props) {
    super(props);

    this.state = {
      newPain: null,
      painLevel: 0,
      painLocation: '',
    };
  }

  handleChangeNewPain = (value) => {
    this.setState({ newPain: value });
  };

  handleChangePainLevel = ({ value }) => {
    this.setState({ painLevel: value });
  }

  handleChangePainLocation = (e) => {
    this.setState({ painLocation: e.target.value });
  }

  handleClickNext = () => {
    const {
      newPain,
      painLevel,
      painLocation,
    } = this.state;

    const value = { newPain };

    if (newPain === 'Y') {
      value.painLevel = painLevel;
      value.painLocation = painLocation;
    }

    this.props.updatePRO({
      type: 'hypertensionAssessmentDaily',
      position: this.getTrackIndex(),
      value,
    });

    this.forwardWithQuery(this.props.location.query);
  }

  render() {
    const { classes } = this.props;
    const {
      newPain,
      painLevel,
      painLocation,
    } = this.state;

    const continueDisabled = newPain === null
      || (newPain === 'Y' && !painLocation);

    return (
      <QuestionLayout
        primaryBtnLabel="Next"
        primaryBtnOnTouchTap={this.handleClickNext}
        primaryBtnDisabled={continueDisabled}
        style={{ backgroundColor: colors.questionBackground }}
      >
        <AppBar
          headerNode="Pain"
          backButtonOnClick={() => this.props.router.goBack()}
        />
        <LinearProgress
          variant="determinate"
          value={(this.getCurrentPage() / this.getTotalPages()) * 100}
          classes={{ bar: classes.bar }}
        />
        <div className={classes.contentWrapper}>
          <div className={classes.imgRow}>
            <div className={classes.imageWrapper}>
              <Frown />
            </div>
          </div>
          <div className={classes.questionnaireContainer}>
            <YesNoButtonSet
              isYes={newPain === 'Y'}
              isNo={newPain === 'N'}
              onClickNo={() => this.handleChangeNewPain('N')}
              onClickYes={() => this.handleChangeNewPain('Y')}
              questionText="Are you experiencing any new pain today?"
            />
            {newPain === 'Y' ? (
              <>
                <div className={`${classes.questionText} ${classes.marginTop40}`}>Where is your pain located?</div>
                <TextField
                  label="Tap here to enter"
                  onChange={this.handleChangePainLocation}
                  classes={{ root: classes.textFieldRoot }}
                  value={painLocation}
                />
                <div className={`${classes.questionText} ${classes.marginTop40}`}>
                  On a scale from 1-10, how would you rate your current level of pain?
                </div>
                <div className={classes.healthSliderWrapper}>
                  <HealthSliderHorizontal
                    value={painLevel}
                    onChange={this.handleChangePainLevel}
                    max={10}
                    min={0}
                    trackIndex={this.getTrackIndex()}
                    type="hypertensionAssessmentDaily"
                    sliderLeftLabel="No pain"
                    sliderRightLabel="Worst imaginable pain"
                  />
                </div>
              </>
            ) : null}
          </div>
        </div>
      </QuestionLayout>
    );
  }
}

HypertensionAssessmentDaily7.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect(null, { updatePRO })(withStyles(styles)(HypertensionAssessmentDaily7));
