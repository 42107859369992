/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';
import { KeypadDate, isAndroid } from 'common-ui';
import { connect } from 'react-redux';
import Joi from 'joi-browser';

import Page from './page';
import AppBar from '../components/app-bar';
import IconWrapper from '../components/icon-wrapper';
import BloomkidzLayout from '../layouts/bloomkidz';
import { Shield } from '../lib/icons';
import { fontSizing } from '../lib/styles';
import { updatePRO } from '../state/pro-forms';
import { bloomkidzStyles, bloomkidzStylesObj } from '../lib/bloomkidz';
import { cordovaTakePicture } from '../state/cordova';
import CropModal from '../components/crop-modal';
import { apiFetch } from '../lib/fetch';

const formSchema = Joi.object({
  primaryInsuranceHolderBirthday: Joi.date(),
});

const styles = {
  ...bloomkidzStylesObj,
  birthdayLabel: {
    alignItems: 'end',
    display: 'flex',
    width: '100%',
    '& > div > div': {
      margin: 0,
    },
  },
  dob: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
    color: '#9c9c9c',
  },
  keypadDateWrapper: {
    marginTop: 10,
    '& > label > div': {
      flexGrow: 1,
    },
    '& > label > div > div': {
      margin: 0,
      width: '100%',
    },
    '& input': {
      margin: 0,
      fontFamily: bloomkidzStyles.fontFamily,
      fontSize: `${fontSizing.body}px !important`,
    },
  },
  insuranceCardButton: {
    background: bloomkidzStyles.primaryColorLight,
    border: `5px dashed ${bloomkidzStyles.primaryColor}`,
    borderRadius: 15,
    borderStyle: 'dashed',
    fontSize: fontSizing.body,
    position: 'absolute',
    bottom: 0,
    top: 0,
    left: 0,
    right: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  insuraceCardImage: {
    borderRadius: 15,
    position: 'absolute',
    bottom: 0,
    top: 0,
    left: 0,
    right: 0,
    height: '100%',
    width: '100%',
    objectFit: 'contain',
  },
  insuranceCardButtonInnerWrapper: {
    borderRadius: 15,
    cursor: 'pointer',
    display: 'block',
    maxWidth: 388,
    paddingTop: `${(225 / 388) * 100}%`,
    position: 'relative',
    width: '100%',
  },
  insuranceCardButtonOuterWrapper: {
    maxWidth: 338,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 20,
  },
  fileInput: {
    display: 'none',
  },
};

class BloomkidzParentGuardianInformation8 extends Page {
  constructor(props) {
    super(props);

    const stateFromRedux = props.bloomkidzParentGuardianInformation[this.getTrackIndex()] || {};

    this.state = {
      primaryInsuranceCompany: '',
      primaryInsurancePlan: '',
      primaryInsuranceHolderName: '',
      primaryInsuranceHolderBirthday: '',
      primaryInsuranceHolderSsn: '',
      primaryInsuranceHolderMemberId: '',
      primaryInsuranceHolderGroupId: '',
      errorPrimaryInsuranceHolderBirthday: '',
      insuranceCardFrontImage: '',
      insuranceCardBackImage: '',
      cropModalOpen: false,
      cropModalOpenFor: '',
      hasSecondaryInsurance: '',
      hasAHCCCSorALTCS: '',
      submitting: false,
      ...stateFromRedux,
    };

    this.isAndroid = isAndroid;
  }

  handleTextFieldChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleContinue = async () => {
    // const { location, updatePRO, user } = this.props;
    const { location, updatePRO } = this.props;
    const user = { id: '8c8dac88-4bcd-4bb0-b7fc-7ba149a87cf1' };
    const {
      insuranceCardFrontImage,
      insuranceCardBackImage,
      primaryInsuranceCompany,
      primaryInsuranceHolderBirthday,
      hasSecondaryInsurance,
    } = this.state;

    const { error } = formSchema.validate({ primaryInsuranceHolderBirthday }, { abortEarly: false });

    if (error) {
      this.setState({ errorPrimaryInsuranceHolderBirthday: 'Invalid value' });
      return;
    }

    this.setState({ submitting: true });

    let frontImageBlob = await window.fetch(insuranceCardFrontImage);
    frontImageBlob = await frontImageBlob.blob();

    let backImageBlob = await window.fetch(insuranceCardBackImage);
    backImageBlob = await backImageBlob.blob();

    const insuranceCardFrontFormData = new FormData();
    insuranceCardFrontFormData.append('image', frontImageBlob);
    insuranceCardFrontFormData.append('image_type', 'PRIMARY_INSURANCE_CARD_FRONT');
    insuranceCardFrontFormData.append('insurance_name', primaryInsuranceCompany);

    const insuranceCardBackFormData = new FormData();
    insuranceCardBackFormData.append('image', backImageBlob);
    insuranceCardBackFormData.append('image_type', 'PRIMARY_INSURANCE_CARD_BACK');
    insuranceCardBackFormData.append('insurance_name', primaryInsuranceCompany);

    await apiFetch(`/users/${user.id}/primary_insurance_image`, {
      method: 'POST',
      body: insuranceCardFrontFormData,
    });

    await apiFetch(`/users/${user.id}/primary_insurance_image`, {
      method: 'POST',
      body: insuranceCardBackFormData,
    });

    this.setState({ submitting: false });

    updatePRO({
      type: 'bloomkidzParentGuardianInformation',
      position: this.getTrackIndex(),
      value: { ...this.state },
    });

    if (hasSecondaryInsurance) {
      this.goToIndexWithQuery(this.getTrackIndex() + 1, location.query);
      return;
    }

    this.goToIndexWithQuery(this.getTrackIndex() + 2, location.query);
  }

  handleClickButton = (value, field) => {
    this.setState({ [field]: value });
  }

  handleChangeBirthday = (primaryInsuranceHolderBirthday) => {
    this.setState({ primaryInsuranceHolderBirthday, errorPrimaryInsuranceHolderBirthday: '' });
  }

  handleAndroidImage = (e) => {
    const { cordovaTakePicture } = this.props;

    e.preventDefault();
    cordovaTakePicture();
  }

  onFileChange = (e, imageField) => {
    if (e.preventDefault) {
      e.preventDefault();
    }
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files; // eslint-disable-line prefer-destructuring
    } else if (e.target) {
      files = e.target.files; // eslint-disable-line prefer-destructuring
    }

    const reader = new window.FileReader();
    reader.onload = () => {
      this.setState({
        cropModalOpen: true,
        cropModalOpenFor: imageField,
        [imageField]: reader.result,
      });
    };
    reader.readAsDataURL(files[0]);
  }

  handleModalClose = () => {
    this.setState({
      cropModalOpen: false,
      cropModalOpenFor: '',
    });
  }

  handleSaveInsuraceCardFrontImage = (image, cropModalOpenFor) => {
    this.setState({ [cropModalOpenFor]: image });
  }

  shouldDisableContinueButton = () => {
    const {
      primaryInsuranceCompany,
      primaryInsurancePlan,
      primaryInsuranceHolderName,
      primaryInsuranceHolderBirthday,
      primaryInsuranceHolderSsn,
      primaryInsuranceHolderMemberId,
      primaryInsuranceHolderGroupId,
      errorPrimaryInsuranceHolderBirthday,
      insuranceCardFrontImage,
      insuranceCardBackImage,
      hasSecondaryInsurance,
      hasAHCCCSorALTCS,
      submitting,
    } = this.state;

    return !primaryInsuranceCompany
      || !primaryInsurancePlan
      || !primaryInsuranceHolderName
      || !primaryInsuranceHolderBirthday
      || !primaryInsuranceHolderSsn
      || !primaryInsuranceHolderMemberId
      || !primaryInsuranceHolderGroupId
      || errorPrimaryInsuranceHolderBirthday
      || !insuranceCardFrontImage
      || !insuranceCardBackImage
      || !hasSecondaryInsurance
      || !hasAHCCCSorALTCS
      || submitting;
  }

  render() {
    const { classes, router } = this.props;
    const {
      primaryInsuranceCompany,
      primaryInsurancePlan,
      primaryInsuranceHolderName,
      primaryInsuranceHolderBirthday,
      primaryInsuranceHolderSsn,
      primaryInsuranceHolderMemberId,
      primaryInsuranceHolderGroupId,
      errorPrimaryInsuranceHolderBirthday,
      insuranceCardFrontImage,
      insuranceCardBackImage,
      cropModalOpen,
      cropModalOpenFor,
      hasSecondaryInsurance,
      hasAHCCCSorALTCS,
      submitting,
    } = this.state;

    const keypadDateWrapperClass = errorPrimaryInsuranceHolderBirthday
      ? `${classes.keypadDateWrapper} ${classes.keypadDateWrapperError}`
      : classes.keypadDateWrapper;

    return (
      <BloomkidzLayout
        primaryBtnOnTouchTap={this.handleContinue}
        primaryBtnLabel="Next"
        primaryBtnShowCircularProgress={submitting}
        primaryBtnDisabled={this.shouldDisableContinueButton()}
      >
        <CropModal
          aspectRatio={128 / 75}
          handleModalClose={this.handleModalClose}
          handleUpload={image => this.handleSaveInsuraceCardFrontImage(image, cropModalOpenFor)}
          open={cropModalOpen}
          imageTypeName={this.props.imageTypeName}
          src={this.state[cropModalOpenFor]}
        />
        <AppBar
          headerNode="Insurance Information"
          backButtonOnClick={() => router.goBack()}
        />
        <IconWrapper>
          <Shield />
        </IconWrapper>
        <div className={classes.pageContent}>
          <div>Primary Insurance Company</div>
          <div className={classes.paddingHorizontal}>
            <div className={classes.textFieldWrapper}>
              <TextField
                placeholder="Please list"
                value={primaryInsuranceCompany}
                onChange={this.handleTextFieldChange}
                name="primaryInsuranceCompany"
                variant="standard"
                fullWidth
                inputProps={{ style: { fontSize: fontSizing.body, fontFamily: bloomkidzStyles.fontFamily } }}
              />
            </div>
          </div>
          <div className={classes.inputSectionTitle}>
            Plan
          </div>
          <div className={classes.paddingHorizontal}>
            <div className={classes.textFieldWrapper}>
              <TextField
                placeholder="Name/Type"
                value={primaryInsurancePlan}
                onChange={this.handleTextFieldChange}
                name="primaryInsurancePlan"
                variant="standard"
                fullWidth
                inputProps={{ style: { fontSize: fontSizing.body, fontFamily: bloomkidzStyles.fontFamily } }}
              />
            </div>
          </div>
          <div className={classes.inputSectionTitle}>
            Policy Holder
          </div>
          <div className={classes.paddingHorizontal}>
            <div className={classes.textFieldWrapper}>
              <TextField
                placeholder="Name"
                value={primaryInsuranceHolderName}
                onChange={this.handleTextFieldChange}
                name="primaryInsuranceHolderName"
                variant="standard"
                fullWidth
                inputProps={{ style: { fontSize: fontSizing.body, fontFamily: bloomkidzStyles.fontFamily } }}
              />
            </div>
            <div className={keypadDateWrapperClass}>
              <label className={classes.birthdayLabel}>
                {!primaryInsuranceHolderBirthday ? (
                  <span className={classes.dob}>DOB:&nbsp;</span>
                ) : null}
                <KeypadDate onChange={this.handleChangeBirthday} value={primaryInsuranceHolderBirthday} />
              </label>
            </div>
            {errorPrimaryInsuranceHolderBirthday ? (
              <div className={classes.errorHelperText}>
                {errorPrimaryInsuranceHolderBirthday}
              </div>
            ) : null}
            <div className={classes.textFieldWrapper}>
              <TextField
                placeholder="SSN"
                value={primaryInsuranceHolderSsn}
                onChange={this.handleTextFieldChange}
                name="primaryInsuranceHolderSsn"
                variant="standard"
                fullWidth
                inputProps={{ style: { fontSize: fontSizing.body, fontFamily: bloomkidzStyles.fontFamily } }}
              />
            </div>
            <div className={classes.textFieldWrapper}>
              <TextField
                placeholder="Member ID"
                value={primaryInsuranceHolderMemberId}
                onChange={this.handleTextFieldChange}
                name="primaryInsuranceHolderMemberId"
                variant="standard"
                fullWidth
                inputProps={{ style: { fontSize: fontSizing.body, fontFamily: bloomkidzStyles.fontFamily } }}
              />
            </div>
            <div className={classes.textFieldWrapper}>
              <TextField
                placeholder="Group ID"
                value={primaryInsuranceHolderGroupId}
                onChange={this.handleTextFieldChange}
                name="primaryInsuranceHolderGroupId"
                variant="standard"
                fullWidth
                inputProps={{ style: { fontSize: fontSizing.body, fontFamily: bloomkidzStyles.fontFamily } }}
              />
            </div>
          </div>
          <div className={classes.inputSectionTitle}>
            Front of Card
          </div>
          <div className={classes.insuranceCardButtonOuterWrapper}>
            <label className={classes.insuranceCardButtonInnerWrapper}>
              {insuranceCardFrontImage ? (
                <img
                  src={insuranceCardFrontImage}
                  alt="insurance card front"
                  className={classes.insuraceCardImage}
                />
              ) : (
                <div className={classes.insuranceCardButton}>
                  Tap here to upload photo.
                </div>
              )}
              <input
                accept="image/*"
                capture="camera"
                type="file"
                onChange={e => this.onFileChange(e, 'insuranceCardFrontImage')}
                className={classes.fileInput}
              />
            </label>
          </div>
          <div className={classes.inputSectionTitle}>
            Back of Card
          </div>
          <div className={classes.insuranceCardButtonOuterWrapper}>
            <label className={classes.insuranceCardButtonInnerWrapper}>
              {insuranceCardBackImage ? (
                <img
                  src={insuranceCardBackImage}
                  alt="insurance card front"
                  className={classes.insuraceCardImage}
                />
              ) : (
                <div className={classes.insuranceCardButton}>
                  Tap here to upload photo.
                </div>
              )}
              <input
                accept="image/*"
                capture="camera"
                type="file"
                onChange={e => this.onFileChange(e, 'insuranceCardBackImage')}
                className={classes.fileInput}
              />
            </label>
          </div>
          <div className={classes.inputSectionTitle}>
            Do you have a seconday Insurance Company?
          </div>
          <div className={classes.buttonRow}>
            <button
              className={`${classes.button} ${hasSecondaryInsurance === 'yes' ? classes.buttonSelected : classes.buttonUnselected}`}
              onClick={() => this.handleClickButton('yes', 'hasSecondaryInsurance')}
              type="button"
            >
              Yes
            </button>
            <button
              className={`${classes.button} ${hasSecondaryInsurance === 'no' ? classes.buttonSelected : classes.buttonUnselected}`}
              onClick={() => this.handleClickButton('no', 'hasSecondaryInsurance')}
              type="button"
            >
              No
            </button>
          </div>
          <div className={classes.inputSectionTitle}>
            Do you have AHCCCS/ALTCS coverage?
          </div>
          <div className={classes.buttonRow}>
            <button
              className={`${classes.button} ${hasAHCCCSorALTCS === 'yes' ? classes.buttonSelected : classes.buttonUnselected}`}
              onClick={() => this.handleClickButton('yes', 'hasAHCCCSorALTCS')}
              type="button"
            >
              Yes
            </button>
            <button
              className={`${classes.button} ${hasAHCCCSorALTCS === 'no' ? classes.buttonSelected : classes.buttonUnselected}`}
              onClick={() => this.handleClickButton('no', 'hasAHCCCSorALTCS')}
              type="button"
            >
              No
            </button>
          </div>
        </div>
      </BloomkidzLayout>
    );
  }
}

BloomkidzParentGuardianInformation8.propTypes = {
  classes: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  updatePRO: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const {
    proForms: { bloomkidzParentGuardianInformation },
    user,
  } = state;

  return { bloomkidzParentGuardianInformation, user };
};

export default connect(mapStateToProps, { cordovaTakePicture, updatePRO })(withStyles(styles)(BloomkidzParentGuardianInformation8));
