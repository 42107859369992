import React from 'react';
import PropTypes from 'prop-types';

import { colors, fontSizes } from '../lib/styles';

const styles = {
  container: {
    height: '5.5rem',
    boxShadow: `inset 0 -1px 0 0 ${colors.boxShadowOutline}`,
    backgroundColor: colors.questionBackground,
    display: 'flex',
  },
  text: {
    color: colors.lightGreyText,
    fontSize: fontSizes.small,
    margin: '1.67rem 0px 0px 2rem',
  },
};

const DateHeader = props => (
  <div style={styles.container}>
    <div style={styles.text}>{props.text}</div>
  </div>
);

DateHeader.propTypes = {
  text: PropTypes.string.isRequired,
};

export default DateHeader;
