import React from 'react';
import { connect } from 'react-redux';
import { omit, pickBy } from 'lodash';

import Page from './page';
import Layout from '../layouts/common';
import AppBar from '../components/app-bar';

import MigraineAbortivePresentation from '../components/migraine-abortive-presentation';
import { browseMigraineRx } from '../state/app-data';
import { updatePRO } from '../state/pro-forms';

class HeadachePain5 extends Page {
  constructor(props) {
    super(props);

    this.state = {
      other_abortive_rx: '',
    };

    this.handleAddItem = this.handleAddItem.bind(this);
    this.handleRemoveItem = this.handleRemoveItem.bind(this);
    this.handleContinueWork = this.handleContinue.bind(this, true);
    this.handleContinueNotWork = this.handleContinue.bind(this, false);
    this.handleOtherAbortiveUpdate = this.handleTextFieldUpdate.bind(this, 'other_abortive_rx');
    this.handleBack = this.handleBack.bind(this);
  }

  componentWillMount() {
    if (!this.props.migraineRx) {
      this.props.browseMigraineRx();
    }

  }

  handleTextFieldUpdate(field, e) {
    this.setState({
      [field]: e.target.value,
    });
  }

  handleAddItem(rx_id) {
    const newItem = {
      rx_id,
    };
    this.props.updatePRO({
      type: 'headachePain',
      position: this.getTrackIndex(),
      value: {
        ...this.props.migraineAbortiveHx,
        [rx_id]: newItem,
      }
    });
  }

  handleRemoveItem(migraineSymptomId) {
    this.props.updatePRO({
      type: 'headachePain',
      position: this.getTrackIndex(),
      value: omit(this.props.migraineAbortiveHx, migraineSymptomId),
    });
  }

  handleContinue(rx_successful) {
    const { other_abortive_rx } = this.state;

    this.props.updatePRO({
      type: 'headachePain',
      position: 2, // headache information
      value: {
        ...this.props.headache,
        rx_successful,
        other_abortive_rx,
      }
    });
    this.forwardWithQuery();
  }

  handleBack() {
    this.backwardWithQuery(this.props.location.query);
  }

  onClickBackButton = () => {
    this.backward();
  }

  render() {
    const { migraineRx } = this.props;
    return (
      <Layout>
        <AppBar
          backButtonOnClick={() => this.props.router.goBack()}
          headerNode="Migraine Abortive Medications"
        />
        <MigraineAbortivePresentation
          migraineRx={migraineRx}
          selectedItems={this.props.migraineAbortiveHx}
          onAddItem={this.handleAddItem}
          onRemoveItem={this.handleRemoveItem}
          onChangeOtherAbortive={this.handleOtherAbortiveUpdate}
          otherAbortiveValue={this.state.other_abortive_rx}
          onMedicationsWorked={this.handleContinueWork}
          onMedicationsNotWork={this.handleContinueNotWork}
        />
      </Layout>
    );
  }
}

function mapStateToProps(state, props) {
  const {
    appData: {
      migraineRx,
    },
    proForms: {
      headachePain,
    },
    user,
  } = state;

  return {
    headache: headachePain[2],
    migraineRx: migraineRx ? pickBy(migraineRx, { type: 'ABORTIVE' }) : null,
    migraineAbortiveHx: headachePain[4] || {},
    migraineHx: headachePain[2],
    user,
  };
}

export default connect(mapStateToProps, { browseMigraineRx, updatePRO })(HeadachePain5);
