import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import TextField from '@material-ui/core/TextField';

import AppBar from '../components/app-bar';
import Continue from '../components/continue';

import { WaterDrop } from '../lib/icons';

import Page from './page';

import { updatePRO } from '../state/pro-forms';

const styles = {
  button: {
    margin: 5,
    width: 150,
    height: 40,
    fontSize: 16,
  },
  buttonGroup: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  formControl: {
    textAlign: 'center',
  },
  formControlItem: {
    fontSize: '22px',
    textAlign: 'center',
    margin: '1rem',
  },
  iconContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    flexGrow: 0,
    flexShrink: 0,
    width: '100%',
    color: '#000',
    paddingTop: 10,
  },
  iconWrapper: {
    width: 100,
    marginTop: 10,
  },
  instructions: {
    fontSize: '1.3rem',
  },
  titleGroup: {
    margin: 25,
  },
  text: {
    fontSize: '22px',
  },
  textField: {
    width: '100%',
    fontSize: '1.75rem',
  },
  nextBtn: {
    textAlign: 'center',
    width: '100%',
    top: '90%',
    position: 'fixed',
  },
};

const StyledTextField = withStyles(() => ({
  root: {
    '& .MuiInputBase-root': {
      fontSize: '16px',
    },
  },
}))(TextField);

class DiabetesDaily5 extends Page {
  constructor(props) {
    super(props);

    this.state = {
      simpleSugarIntakeWatchedToday: null,
      reasonSimpleSugarNotWatched: '',
    };

    this.handleContinue = this.handleContinue.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    const { value } = e.target;
    this.setState({
      reasonSimpleSugarNotWatched: value,
    });
  }

  handleContinue() {
    this.props.updatePRO({
      type: 'diabetesDaily',
      position: this.getTrackIndex(),
      value: this.state });
    this.forwardWithQuery(this.props.location.query);
  }

  render() {
    const { simpleSugarIntakeWatchedToday, reasonSimpleSugarNotWatched } = this.state;

    let disabled;

    if (simpleSugarIntakeWatchedToday === false && reasonSimpleSugarNotWatched.length > 0) {
      disabled = false;
    } else if (simpleSugarIntakeWatchedToday === true) {
      disabled = false;
    } else {
      disabled = true;
    }

    return (
      <Fragment>
        <AppBar
          headerNode="Blood Sugar"
          noLeftNode={true}
        />
        <LinearProgress
          variant="determinate"
          value={(this.getCurrentPage() / this.getTotalPages()) * 100}
        />
        <div style={styles.iconContainer}>
          <div style={styles.iconWrapper}>
            <WaterDrop />
          </div>
        </div>

        <div style={styles.titleGroup}>
          <p style={styles.text}>Did you watch your simple sugar intake today?</p>
          <div style={styles.buttonGroup}>
            <Button style={styles.button} variant="contained" color={simpleSugarIntakeWatchedToday ? 'primary' : 'default'} onClick={() => this.setState({ simpleSugarIntakeWatchedToday: true, reasonSimpleSugarNotWatched: '' })}>Yes</Button>
            <Button style={styles.button} variant="contained" color={simpleSugarIntakeWatchedToday === false ? 'primary' : 'default'} onClick={() => this.setState({ simpleSugarIntakeWatchedToday: false })}>No</Button>
          </div>
        </div>

        <div className={`${simpleSugarIntakeWatchedToday === false ? '' : 'hidden'}`} style={styles.titleGroup}>
          <p style={styles.text}>Reason</p>
          <div style={styles.formControl}>
            <StyledTextField
              id="standard-multiline-flexible"
              style={styles.textField}
              multiline
              maxRows={4}
              value={reasonSimpleSugarNotWatched}
              onChange={this.handleChange}
              inputProps={{
                fontSize: '16px',
              }}
            />
          </div>
        </div>

        <div style={styles.nextBtn}>
          <Continue
            disabled={disabled}
            text="Next"
            onClick={this.handleContinue}
          />
        </div>

      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { user, proForms: { diabetesDaily } } = state;
  return { user, diabetesDaily };
}

DiabetesDaily5.proptypes = {
  user: PropTypes.object.isRequired,
  route: PropTypes.object.isRequired,
  updatePRO: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { updatePRO })(DiabetesDaily5);
