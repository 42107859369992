import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';

import Continue from '../components/continue';
import { colors } from '../lib/styles';
import SimpleList from '../components/simple-list';
import Subtitle from '../components/subtitle';

const MigrainePreventivePresentation = (props) => {
  return (
    <div>
      <Subtitle label="WHICH MEDICATIONS/TREATMENTS HAVE YOU TRIED?" />
      {props.migraineRx ? (
        <SimpleList
          items={props.migraineRx}
          selectedItems={props.selectedItems}
          onAddItem={props.onAddItem}
          onRemoveItem={props.onRemoveItem}
          sortBy="name"
        />
      ) : null}
      <section style={{ backgroundColor: colors.primaryHighlight }}>
        <TextField
          label="Other medications / treatments?"
          multiline
          rows={3}
          onChange={props.onChangeOtherPreventive}
          value={props.otherPreventiveValue}
        />
      </section>
      <section>
        <Continue text="Update" onClick={props.onContinue} />
      </section>
    </div>
  );
}

MigrainePreventivePresentation.propTypes = {
  migraineRx: PropTypes.object.isRequired,
  selectedItems: PropTypes.object.isRequired,
  onAddItem: PropTypes.func.isRequired,
  onRemoveItem: PropTypes.func.isRequired,
  onChangeOtherPreventive: PropTypes.func.isRequired,
  otherPreventiveValue: PropTypes.string.isRequired,
  onContinue: PropTypes.func.isRequired,
};

export default MigrainePreventivePresentation;
