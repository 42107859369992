import React, { Component } from 'react';
import PropTypes from 'prop-types';

const baseStyles = {
  divStyle: {
    display: 'flex',
    width: '100%',
    textAlign: 'center',
  },
  endText: {
    width: '10vh',
    marginTop: '10px',
    color: 'white',
  },
  sliderDiv: {
    width: '80vw',
    margin: '0 auto',
  },
  painLevels: [
    'painSlider0',
    'painSlider1',
    'painSlider2',
    'painSlider3',
    'painSlider4',
    'painSlider5',
    'painSlider6',
    'painSlider7',
    'painSlider8',
    'painSlider9',
    'painSlider10',
  ],
};

class PainSlider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.value,
      sliderClass: baseStyles.painLevels[0],
      min: this.props.min,
      max: this.props.max,
      step: this.props.step,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    const painValue = e.target.value;
    this.setState({ value: painValue });
    this.setState({ sliderClass: baseStyles.painLevels[painValue] });
    this.props.propFunc(e, e.target.value);
  }
  // pain levels 0-11 based of this.state.pain.intensity
  // needs to change colors based off of the different schemes

  render() {
    const { min, max, step, sliderClass, value } = this.state;
    return (
      <div style={baseStyles.divStyle}>
        <div style={baseStyles.endText}>
          {min}
        </div>
        <div style={baseStyles.sliderDiv}>
          <input
            className={sliderClass}
            type="range"
            min={min}
            max={max}
            step={step}
            value={value}
            onChange={this.handleChange}
          />
        </div>
        <div style={baseStyles.endText}>
          {max}
        </div>
      </div>
    );
  }
}

PainSlider.propTypes = {
  propFunc: PropTypes.func.isRequired,
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  value: PropTypes.number,
};

PainSlider.defaultProps = {
  min: 1,
  max: 10,
  step: 1,
  value: 1,
};

export default PainSlider;
