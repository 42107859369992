import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';

import Page from './page';
import AppBar from '../components/app-bar';
import IconWrapper from '../components/icon-wrapper';
import BloomkidzLayout from '../layouts/bloomkidz';
import { Information } from '../lib/icons';
import { updatePRO } from '../state/pro-forms';
import { bloomkidzStylesObj } from '../lib/bloomkidz';

const styles = {
  ...bloomkidzStylesObj,
  centerText: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
  },
  emphasis: {
    fontWeight: 600,
  },
};

class BloomkidzSignatures1 extends Page {
  handleContinue = () => {
    const { location, updatePRO } = this.props;

    updatePRO({
      type: 'bloomkidzSignatures',
      position: this.getTrackIndex(),
      value: { ...this.state },
    });

    this.forwardWithQuery(location.query);
  }

  render() {
    const { classes, router } = this.props;

    return (
      <BloomkidzLayout
        primaryBtnOnTouchTap={this.handleContinue}
        primaryBtnLabel="Next"
      >
        <AppBar
          headerNode="Information"
          backButtonOnClick={() => router.goBack()}
        />
        <IconWrapper>
          <Information />
        </IconWrapper>
        <div className={classes.pageContent}>
          <div className={classes.emphasis}>Documents you will be signing:</div>
          <div>- HIPAA Notice of Privacy Practices</div>
          <div>- Informed Consent - Assessments and Services</div>
          <div>- Bloomkidz Media Release</div>
          <div>- BloomKidz Illness Policy</div>
          <div>- Client Rights</div>
        </div>
      </BloomkidzLayout>
    );
  }
}

BloomkidzSignatures1.propTypes = {
  classes: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  updatePRO: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
};

export default connect(null, { updatePRO })(withStyles(styles)(BloomkidzSignatures1));
