import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get, includes } from 'lodash';

import { colors } from '../lib/styles';
import { PhysicalFunction } from '../lib/icons';
import Page from './page';
import ButtonPRO from '../components/button-pro';
import { updatePRO } from '../state/pro-forms';

const buttonLabels = ['Without Any Difficulty', 'With A Little Difficulty', 'With Some Difficulty', 'With Much Difficulty', 'Unable to Do'];

const options = [
  {
    question: 'Are you able to do chores such as vaccuming or yard work?',
    label: buttonLabels,
  },
  {
    question: 'Are you able to go up and down stairs at a normal pace?',
    label: buttonLabels,
  },
  {
    question: 'Are you able to go for a walk of at least 15 minutes?',
    label: buttonLabels,
  },
  {
    question: 'Are you able to run errands and shop?',
    label: buttonLabels,
  },
];
const { questionBackground } = colors;

class Promis29PhysicalFunction extends Page {
  onClickBackButton = () => {
    this.backward();
  }

  onSubmission = () => {
    this.forwardWithQuery(this.props.location.query);
  }

  render() {
    const page = get(this.props.promis29, this.getTrackIndex(), [null, null, null, null]);
    const value = includes(page, null) ? null : 0;
    return (
      <ButtonPRO
        backgroundColor={questionBackground}
        currentPage={this.getCurrentPage()}
        image={PhysicalFunction}
        onClickBackButton={() => this.props.router.goBack()}
        onChange={this.props.updatePRO}
        onSubmission={this.onSubmission}
        options={options}
        title=""
        header="Physical Function"
        totalPages={this.getTotalPages()}
        trackIndex={this.getTrackIndex()}
        value={value}
        pro={page}
        type="promis29"
      />
    );
  }
}


function mapStateToProps(state) {
  const { proForms: { promis29 } } = state;
  return { promis29 };
}

Promis29PhysicalFunction.propTypes = {
  promis29: PropTypes.array.isRequired,
  updatePRO: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { updatePRO })(Promis29PhysicalFunction);
