import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get, includes } from 'lodash';

import { colors } from '../lib/styles';
import Page from './page';
import ButtonPRO from '../components/button-pro';
import { updatePRO } from '../state/pro-forms';

const buttonLabels = ['Disagree', 'Agree'];

const options = [
  {
    question: 'My back pain has spread down my leg(s) at some time in the past 2 weeks',
    label: buttonLabels,
  },
  {
    question: 'I have had pain in the shoulder or neck at some time in the last 2 weeks',
    label: buttonLabels,
  },
  {
    question: 'I have only walked short distances because of my back pain',
    label: buttonLabels,
  },
  {
    question: 'In the last 2 weeks, I  have dressed more slowly than usual because of back pain',
    label: buttonLabels,
  },
];
const { questionBackground } = colors;

class StartBack1 extends Page {
  onClickBackButton = () => {
    this.backward();
  }

  onSubmission = () => {
    this.forwardWithQuery(this.props.location.query);
  }

  render() {
    const page = get(this.props.startBack, this.getTrackIndex(), [null, null, null, null]);
    const value = includes(page, null) ? null : 0;
    return (
      <ButtonPRO
        backgroundColor={questionBackground}
        currentPage={this.getCurrentPage()}
        onClickBackButton={() => this.props.router.goBack()}
        onChange={this.props.updatePRO}
        onSubmission={this.onSubmission}
        options={options}
        title="Thinking about the last 2 weeks tick your response to the following questions:"
        header="Patient Health"
        totalPages={this.getTotalPages()}
        trackIndex={this.getTrackIndex()}
        value={value}
        pro={page}
        type="startBack"
      />
    );
  }
}


function mapStateToProps(state) {
  const { proForms: { startBack } } = state;
  return { startBack };
}

StartBack1.propTypes = {
  startBack: PropTypes.array.isRequired,
  updatePRO: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { updatePRO })(StartBack1);
