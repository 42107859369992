import moment from 'moment';
import { get } from 'lodash';

const defaultOpts = {
  interval: 'day', // can also be set to hour;
};
function painCalendarFormatter(data = {}, opts = defaultOpts) {
  /* eslint-disable no-param-reassign */
  let format = 'YYYY-MM-DD';
  if (opts.interval === 'hour') {
    format = 'YYYY-MM-DDTHH';
  }
  return data.reduce((painDates, currentItem) => {
    const { intensity, pain, pain_start_date, pain_end_date, start_time, end_time } = currentItem;

    // This block basically handles the differences between headaches and body-pain variable naming
    const startDate = moment(start_time || pain_start_date).format(format);
    const endDate = moment(end_time || pain_end_date).format(format);
    const level = intensity || get(pain, '0.severity', 0);

    if (!painDates[startDate] || painDates[startDate].level < level) {
      painDates[startDate] = { level };
    }

    if (startDate !== endDate && moment(startDate) < moment(endDate)) {
      if (!painDates[endDate] || painDates[endDate].level < level) {
        painDates[endDate] = { level };
      }

      const endMoment = moment(endDate);
      // eslint-disable-next-line
      for (let currentDate = startDate; moment(currentDate) < endMoment; currentDate = moment(currentDate).add(1, opts.interval)) {
        const formattedCurrent = moment(currentDate).format(format);
        if (!painDates[formattedCurrent] || !painDates[formattedCurrent].level || painDates[formattedCurrent].level < level) {
          painDates[formattedCurrent] = { level };
        }
      }
    }

    return painDates;
  }, {});
  /* eslint-enable no-param-reassign */
}

export default painCalendarFormatter;
