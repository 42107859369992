import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from '@material-ui/core';
import { connect } from 'react-redux';

import Page from './page';
import AppBar from '../components/app-bar';
import IconWrapper from '../components/icon-wrapper';
import BloomkidzLayout from '../layouts/bloomkidz';
import { UserGroup } from '../lib/icons';
import { updatePRO } from '../state/pro-forms';
import { fontSizing } from '../lib/styles';
import { bloomkidzStyles, bloomkidzStylesObj } from '../lib/bloomkidz';

const styles = {
  ...bloomkidzStylesObj,
  marginTop10: {
    marginTop: 10,
  },
};

class BloomkidzParentGuardianInformation3 extends Page {
  constructor(props) {
    super(props);

    const stateFromRedux = props.bloomkidzParentGuardianInformation[this.getTrackIndex()] || {};

    this.state = {
      maritalStatus: '',
      maritalStatusOther: '',
      whoHasPrimaryCustody: '',
      legalSituationAffectingServices: '',
      legalSituationAffectingServicesExplanation: '',
      ...stateFromRedux,
    };
  }

  handleClickButton = (legalSituationAffectingServices) => {
    this.setState({ legalSituationAffectingServices });
  }

  handleChangeMaritalStatus = (e) => {
    this.setState({ maritalStatus: e.target.value });
  }

  handleContinue = () => {
    const { location, updatePRO } = this.props;

    updatePRO({
      type: 'bloomkidzParentGuardianInformation',
      position: this.getTrackIndex(),
      value: { ...this.state },
    });

    this.forwardWithQuery(location.query);
  }

  handleTextFieldChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  render() {
    const { classes, router } = this.props;
    const { state } = this;
    const {
      maritalStatus,
      maritalStatusOther,
      whoHasPrimaryCustody,
      legalSituationAffectingServices,
      legalSituationAffectingServicesExplanation,
    } = state;

    const continueButtonDisabled = !maritalStatus;

    return (
      <BloomkidzLayout
        primaryBtnOnTouchTap={this.handleContinue}
        primaryBtnLabel="Next"
        primaryBtnDisabled={continueButtonDisabled}
      >
        <AppBar
          headerNode="Guardian Information"
          backButtonOnClick={() => router.goBack()}
        />
        <IconWrapper>
          <UserGroup />
        </IconWrapper>
        <div className={classes.pageContent}>
          <div>
            Marital Status
          </div>
          <RadioGroup
            name="maritalStatus"
            onChange={this.handleChangeMaritalStatus}
            value={maritalStatus}
            className={classes.radioGroup}
          >
            <FormControlLabel
              control={<Radio />}
              label="Not Married"
              value="not married"
              className={classes.formControlLabelRadio}
            />
            <FormControlLabel
              control={<Radio />}
              label="Married"
              value="married"
              className={classes.formControlLabelRadio}
            />
            <FormControlLabel
              control={<Radio />}
              label="Separated"
              value="separated"
              className={classes.formControlLabelRadio}
            />
            {maritalStatus === 'separated' ? (
              <>
                <div
                  className={classes.textFieldWrapper}
                  style={{ marginTop: 0, paddingLeft: 42 }}
                >
                  <div>Who has primary custody?</div>
                  <TextField
                    placeholder="Tap here to type"
                    value={whoHasPrimaryCustody}
                    onChange={this.handleTextFieldChange}
                    name="whoHasPrimaryCustody"
                    variant="standard"
                    fullWidth
                    inputProps={{ style: { fontSize: fontSizing.body, fontFamily: bloomkidzStyles.fontFamily } }}
                  />
                </div>
              </>
            ) : null}
            <FormControlLabel
              control={<Radio />}
              label="Divorced"
              value="divorced"
              className={classes.formControlLabelRadio}
            />
            {maritalStatus === 'divorced' ? (
              <div
                className={classes.textFieldWrapper}
                style={{ marginTop: 0, paddingLeft: 42 }}
              >
                <div>Who has primary custody?</div>
                <TextField
                  placeholder="Tap here to type"
                  value={whoHasPrimaryCustody}
                  onChange={this.handleTextFieldChange}
                  name="whoHasPrimaryCustody"
                  variant="standard"
                  fullWidth
                  inputProps={{ style: { fontSize: fontSizing.body, fontFamily: bloomkidzStyles.fontFamily } }}
                />
              </div>
            ) : null}
            <FormControlLabel
              control={<Radio />}
              label="Other"
              value="other"
              className={classes.formControlLabelRadio}
            />
            {maritalStatus === 'other' ? (
              <div
                className={classes.textFieldWrapper}
                style={{ marginTop: 0, paddingLeft: 42 }}
              >
                <TextField
                  placeholder="Tap here to type"
                  value={maritalStatusOther}
                  onChange={this.handleTextFieldChange}
                  name="maritalStatusOther"
                  variant="standard"
                  fullWidth
                  inputProps={{ style: { fontSize: fontSizing.body, fontFamily: bloomkidzStyles.fontFamily } }}
                />
              </div>
            ) : null}
          </RadioGroup>
          <div className={classes.inputSectionTitle}>
            Are there any legal proceedings or court orders that currently or may affect services in the future?
          </div>
          <div className={classes.buttonRow}>
            <button
              className={`${classes.button} ${legalSituationAffectingServices === 'yes' ? classes.buttonSelected : classes.buttonUnselected}`}
              onClick={() => this.handleClickButton('yes')}
              type="button"
            >
              Yes
            </button>
            <button
              className={`${classes.button} ${legalSituationAffectingServices === 'no' ? classes.buttonSelected : classes.buttonUnselected}`}
              onClick={() => this.handleClickButton('no')}
              type="button"
            >
              No
            </button>
          </div>
          {legalSituationAffectingServices === 'yes' ? (
            <>
              <div className={classes.marginTop10}>Please explain:</div>
              <TextField
                placeholder="Tap here to type"
                value={legalSituationAffectingServicesExplanation}
                onChange={this.handleTextFieldChange}
                name="legalSituationAffectingServicesExplanation"
                variant="standard"
                fullWidth
                inputProps={{ style: { fontSize: fontSizing.body, fontFamily: bloomkidzStyles.fontFamily } }}
              />
            </>
          ) : null}
        </div>
      </BloomkidzLayout>
    );
  }
}

BloomkidzParentGuardianInformation3.propTypes = {
  classes: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  updatePRO: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const {
    proForms: { bloomkidzParentGuardianInformation },
    user,
  } = state;

  return { user, bloomkidzParentGuardianInformation };
};

export default connect(mapStateToProps, { updatePRO })(withStyles(styles)(BloomkidzParentGuardianInformation3));
