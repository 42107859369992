import { combineReducers } from 'redux';

import appData from './app-data';
import appointments from './appointments';
import calendarReports from './calendar-reports';
import conditionsHx from './conditions-hx';
import cordova from './cordova';
import employer from './employer';
import errorScreens from './error-screens';
import formCompletion from './form-completion';
import generalHx from './general-hx';
import insurance from './insurance';
import insuranceImages from './insurance-images';
import medicationHx from './medication-hx';
import migraineAbortiveHx from './migraine-abortive-hx';
import migraineHx from './migraine-hx';
import migrainePainHx from './migraine-pain-hx';
import migrainePreventiveHx from './migraine-preventive-hx';
import migraineQuestions from './migraine-questions';
import migraineSymptomsHx from './migraine-symptoms-hx';
import painHx from './pain-hx';
import painHxAggravatingFactors from './pain-hx-aggravating-factors';
import painHxAlleviatingFactors from './pain-hx-alleviating-factors';
import painHxFunctionalLimitations from './pain-hx-functional-limitations';
import painLogDetails from './pain-log-details';
import painOrigin from './pain-origin';
import patientClinics from './patient-clinics';
import patientContactInfo from './patient-contact-info';
import patientProvider from './patient-provider';
import personalInfo from './personal-info';
import pharmacyHx from './pharmacy-hx';
import PRO from './pro-forms';
import pqrsHx from './pqrs-hx';
import socialHx from './social-hx';
import surgeryHx from './surgery-hx';
import symptomsHx from './symptoms-hx';
import user from './user';
import pin from './pin';
import rtc from './rtc';

const { proForms, proSubmit } = PRO;

const reducers = combineReducers({
  appData,
  appointments,
  calendarReports,
  conditionsHx,
  cordova,
  formCompletion,
  generalHx,
  insurance,
  employer,
  errorScreens,
  insuranceImages,
  medicationHx,
  migraineAbortiveHx,
  migraineHx,
  migrainePainHx,
  migrainePreventiveHx,
  migraineQuestions,
  migraineSymptomsHx,
  painHx,
  painHxAggravatingFactors,
  painHxAlleviatingFactors,
  painHxFunctionalLimitations,
  painLogDetails,
  painOrigin,
  patientClinics,
  patientContactInfo,
  patientProvider,
  personalInfo,
  pharmacyHx,
  proForms,
  proSubmit,
  pqrsHx,
  socialHx,
  surgeryHx,
  symptomsHx,
  user,
  pin,
  rtc
});

export default reducers;
