import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import { fontSizing } from '../lib/styles';
import env from '../config';

const styles = {
  cautionStatementInnerWrapper: {
    border: '2px solid',
    marginLeft: 20,
    marginRight: 20,
    maxWidth: 400,
    paddingLeft: 20,
    paddingRight: 20,
    textAlign: 'center',
  },
  cautionStatementOuterWrapper: {
    display: 'flex',
    fontSize: fontSizing.smallX,
    justifyContent: 'center',
  },
};

class IvcVersionInfo extends React.Component {
  render() {
    const { classes } = this.props;

    return (
      <div className={classes.cautionStatementOuterWrapper}>
        <div className={classes.cautionStatementInnerWrapper}>
          <p>Mindset Medical IVC</p>
          <p>{`Version ${env.vitalCoreConfig.ivcRRVersion}.${env.vitalCoreConfig.ivcRRWebAppVersion}`}</p>
        </div>
      </div>
    );
  }
}

IvcVersionInfo.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(IvcVersionInfo);
