import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import TextField from '@material-ui/core/TextField';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import AppBar from '../components/app-bar';
import Page from './page';
import QuestionLayout from '../layouts/question';
import YesNoButtonSet from '../components/yes-no-button-set';
import { colors } from '../lib/styles';
import { updatePRO } from '../state/pro-forms';
import { Headaches } from '../lib/icons';

const styles = {
  answerButtonContainer: {
    display: 'flex',
    marginLeft: -10,
    marginTop: 8,
  },
  bar: {
    background: colors.lightBrightBlue,
  },
  contentWrapper: {
    flexGrow: 1,
    overflowX: 'hidden',
    position: 'relative',
  },
  img: {
    height: '100%',
    objectFit: 'contain',
  },
  imageWrapper: {
    height: 100,
    padding: '15px 60px',
    textAlign: 'center',
  },
  instructions: {
    color: 'grey',
    fontSize: '1.5rem',
    margin: '20px 40px 0px 40px',
    textAlign: 'center',
  },
  questionnaireContainer: {
    paddingLeft: 20,
    paddingRight: 20,
  },
  breathingText: {
    fontSize: '2rem',
    fontWeight: 'bold',
  },
  questionText: {
    fontSize: '2rem',
    marginTop: 25,
  },
  continueButtonWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 80,
  },
  inputField: {
    marginTop: 20,
    width: '6rem',
  },
  inputFieldWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
};

class ThoracicCancerSymptoms4 extends Page {
  constructor(props) {
    super(props);

    this.state = {
      newHeadaches: null,
      numberOfHeadaches: '',
    };
  }

  handleChangeNewHeadaches = (selected) => {
    this.setState({ newHeadaches: selected });
  };

  handleChangeNumberOfHeadaches = (e) => {
    this.setState({ numberOfHeadaches: e.target.value });
  };

  handleClickNext = () => {
    const { newHeadaches, numberOfHeadaches } = this.state;

    this.props.updatePRO({
      type: 'thoracicCancerSymptoms',
      position: this.getTrackIndex(),
      value: {
        newHeadaches,
        numberOfHeadaches: numberOfHeadaches || 0,
      },
    });
    const headaches = numberOfHeadaches === '' ? 0 : parseInt(numberOfHeadaches, 10);
    if (newHeadaches === false && headaches === 0) {
      this.props.updatePRO({
        type: 'thoracicCancerSymptoms',
        position: this.getTrackIndex() + 1,
        value: '',
      });
      this.props.updatePRO({
        type: 'thoracicCancerSymptoms',
        position: this.getTrackIndex() + 2,
        value: {
          medications: null,
          nausea: null,
        },
      });
      return this.goToIndexWithQuery(6, this.props.location.query);
    }

    this.forwardWithQuery(this.props.location.query);
  };

  render() {
    const { classes } = this.props;
    const { newHeadaches, numberOfHeadaches } = this.state;

    return (
      <QuestionLayout
        primaryBtnDisabled={newHeadaches === null}
        primaryBtnLabel="Next"
        primaryBtnOnTouchTap={this.handleClickNext}
        style={{ backgroundColor: colors.questionBackground }}
      >
        <AppBar
          headerNode="Headaches"
          backButtonOnClick={() => this.props.router.goBack()}
        />
        <LinearProgress
          variant="determinate"
          value={(this.getCurrentPage() / this.getTotalPages()) * 100}
          classes={{ bar: this.props.classes.bar }}
        />
        <div className={classes.contentWrapper}>
          <div className={classes.instructions}>
            Please select the option that best answers the question relating to headaches
          </div>
          <div className={classes.imageWrapper}>
            <Headaches />
          </div>
          <div className={classes.questionnaireContainer}>
            <div className={classes.breathingText}>Headaches</div>
            <YesNoButtonSet
              isNo={newHeadaches === false}
              isYes={newHeadaches}
              onClickNo={() => this.handleChangeNewHeadaches(false)}
              onClickYes={() => this.handleChangeNewHeadaches(true)}
              questionText="Are you having new onset of headaches?"
            />
            <div className={classes.questionText}>How many have you had in the last 24 hours?</div>
            <div className={classes.inputFieldWrapper}>
              <TextField
                className={classes.inputField}
                onChange={this.handleChangeNumberOfHeadaches}
                type="number"
                inputProps={{
                  inputMode: 'decimal',
                  pattern: '[0-9]*',
                  type: 'tel',
                  style: { fontSize: '16px' } }}
                value={numberOfHeadaches}
              />
            </div>
          </div>
        </div>
      </QuestionLayout>
    );
  }
}

export default connect(null, { updatePRO })(withStyles(styles)(ThoracicCancerSymptoms4));
