import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import AppBar from '../components/app-bar';
import Page from './page';
import { submitPRO } from '../state/pro-forms';
import Continue from '../components/continue';

const styles = {
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  question: {
    fontSize: '1.5rem',
    margin: '60px 40px 0px 40px',
    textAlign: 'center',
  },
};

class DrJamesonReview extends Page {
  handleClickNo = () => {
    const { location, submitPRO, user } = this.props;
    const { id } = location.query;

    submitPRO(
      { userId: user.id, id },
      {
        pro_data: { data: { satisfied: 'no' } },
        pro_type: 'DR-JAMESON-REVIEW',
      },
      user,
    )
      .then(() => {
        this.goToIndexWithQuery(2, this.props.location.query);
      });
  }

  handleClickYes = () => {
    const { location, submitPRO, user } = this.props;
    const { id } = location.query;

    submitPRO(
      { userId: user.id, id },
      {
        pro_data: { data: { satisfied: 'yes' } },
        pro_type: 'DR-JAMESON-REVIEW',
      },
      user,
    )
      .then(() => {
        this.goToIndexWithQuery(1, this.props.location.query);
      });
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.contentWrapper}>
        <AppBar
          headerNode="Patient Service Review"
          backButtonOnClick={() => this.props.router.goBack()}
        />
        <div className={classes.question}>
          Are you satisfied with Dr. Jameson's service?
        </div>
        <Continue
          text="No"
          onClick={this.handleClickNo}
          btnStyle={{ marginTop: '50px', alignSelf: 'center' }}
        />
        <Continue
          text="Yes"
          onClick={this.handleClickYes}
          btnStyle={{ marginTop: '20px', alignSelf: 'center' }}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state;
  return { user };
}

export default connect(mapStateToProps, { submitPRO })(withStyles(styles)(DrJamesonReview));
