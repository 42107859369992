import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';
import { connect } from 'react-redux';
import Joi from 'joi-browser';

import Page from './page';
import AppBar from '../components/app-bar';
import IconWrapper from '../components/icon-wrapper';
import BloomkidzLayout from '../layouts/bloomkidz';
import { UserGroup } from '../lib/icons';
import { fontSizing } from '../lib/styles';
import { updatePRO } from '../state/pro-forms';
import { bloomkidzStylesObj } from '../lib/bloomkidz';
import { email, phone } from '../lib/validator';

const styles = bloomkidzStylesObj;

const formSchema = Joi.object({
  guardianOneEmail: Joi.string().regex(email),
  guardianOneTelephone: Joi.string().regex(phone),
  guardianOneCell: Joi.string().regex(phone),
});

class BloomkidzNewClient2 extends Page {
  constructor(props) {
    super(props);

    const stateFromRedux = props.bloomkidzNewClient[this.getTrackIndex()] || {};

    this.state = {
      guardianOneFirstName: '',
      guardianOneLastName: '',
      guardianOneEmail: '',
      guardianOneTelephone: '',
      guardianOneCell: '',
      errors: {
        guardianOneEmail: '',
        guardianOneTelephone: '',
        guardianOneCell: '',
      },
      ...stateFromRedux,
    };
  }

  handleTextFieldChange = (e) => {
    const stateUpdate = { [e.target.name]: e.target.value };

    if (this.state.errors[e.target.name]) {
      stateUpdate.errors = {
        ...this.state.errors,
        [e.target.name]: '',
      };
    }

    this.setState(stateUpdate);
  };

  handleContinue = () => {
    const { location, updatePRO } = this.props;
    const {
      guardianOneEmail,
      guardianOneTelephone,
      guardianOneCell,
    } = this.state;

    const fieldsToValidate = { guardianOneEmail, guardianOneCell };
    if (guardianOneTelephone) fieldsToValidate.guardianOneTelephone = guardianOneTelephone;

    const { error } = formSchema.validate(fieldsToValidate, { abortEarly: false });

    if (error) {
      const errors = {};

      error.details.forEach((error) => {
        errors[error.context.key] = 'Invalid value';
      });

      this.setState({ errors });
      return;
    }

    updatePRO({
      type: 'bloomkidzNewClient',
      position: this.getTrackIndex(),
      value: { ...this.state },
    });

    this.forwardWithQuery(location.query);
  }

  shouldDisableContinueButton = () => {
    const {
      guardianOneFirstName,
      guardianOneLastName,
      guardianOneEmail,
      guardianOneCell,
      errors,
    } = this.state;

    const continueButtonDisabled = !guardianOneFirstName
      || !guardianOneLastName
      || !guardianOneEmail
      || !guardianOneCell
      || errors.guardianOneCell
      || errors.guardianOneEmail
      || errors.guardianOneTelephone;

    return continueButtonDisabled;
  }

  render() {
    const { classes, router } = this.props;
    const {
      guardianOneFirstName,
      guardianOneLastName,
      guardianOneEmail,
      guardianOneTelephone,
      guardianOneCell,
      errors,
    } = this.state;

    return (
      <BloomkidzLayout
        primaryBtnOnTouchTap={this.handleContinue}
        primaryBtnLabel="Next"
        primaryBtnDisabled={this.shouldDisableContinueButton()}
      >
        <AppBar
          headerNode="Guardian Information"
          backButtonOnClick={() => router.goBack()}
        />
        <IconWrapper>
          <UserGroup />
        </IconWrapper>
        <div className={classes.pageContent}>
          <div>Parent/Guardian 1</div>
          <div className={classes.paddingHorizontal}>
            <div className={classes.textFieldWrapper}>
              <TextField
                placeholder="First Name"
                value={guardianOneFirstName}
                onChange={this.handleTextFieldChange}
                name="guardianOneFirstName"
                variant="standard"
                fullWidth
                inputProps={{ style: { fontSize: fontSizing.body } }}
              />
            </div>
            <div className={classes.textFieldWrapper}>
              <TextField
                placeholder="Last Name"
                value={guardianOneLastName}
                onChange={this.handleTextFieldChange}
                name="guardianOneLastName"
                variant="standard"
                fullWidth
                inputProps={{ style: { fontSize: fontSizing.body } }}
              />
            </div>
          </div>
          <div className={classes.inputSectionTitle}>
            Contact
          </div>
          <div className={classes.paddingHorizontal}>
            <div className={classes.textFieldWrapper}>
              <TextField
                placeholder="Email"
                value={guardianOneEmail}
                onChange={this.handleTextFieldChange}
                name="guardianOneEmail"
                variant="standard"
                fullWidth
                inputProps={{ style: { fontSize: fontSizing.body } }}
                error={errors.guardianOneEmail}
                helperText={errors.guardianOneEmail}
              />
            </div>
            <div className={classes.textFieldWrapper}>
              <TextField
                placeholder="Telephone Number (optional)"
                value={guardianOneTelephone}
                onChange={this.handleTextFieldChange}
                name="guardianOneTelephone"
                variant="standard"
                fullWidth
                inputProps={{ style: { fontSize: fontSizing.body } }}
                error={errors.guardianOneTelephone}
                helperText={errors.guardianOneTelephone}
              />
            </div>
            <div className={classes.textFieldWrapper}>
              <TextField
                placeholder="Cell Number"
                value={guardianOneCell}
                onChange={this.handleTextFieldChange}
                name="guardianOneCell"
                variant="standard"
                fullWidth
                inputProps={{ style: { fontSize: fontSizing.body } }}
                error={errors.guardianOneCell}
                helperText={errors.guardianOneCell}
              />
            </div>
          </div>
        </div>
      </BloomkidzLayout>
    );
  }
}

BloomkidzNewClient2.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  updatePRO: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const {
    proForms: { bloomkidzNewClient },
  } = state;

  return { bloomkidzNewClient };
};

export default connect(mapStateToProps, { updatePRO })(withStyles(styles)(BloomkidzNewClient2));
