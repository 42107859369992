import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get } from 'lodash';

import Page from './page';
import Question from '../components/question';
import { colors } from '../lib/styles';
import { updatePRO } from '../state/pro-forms';

const options = [
  {
    label: 'Less than 10% pain reduction',
    value: 0,
  },
  {
    label: 'Between 20% - 30% pain reduction',
    value: 1,
  },
  {
    label: 'Between 30% - 50% pain reduction',
    value: 2,
  },
  {
    label: 'Over 50% pain reduction',
    value: 3,
  },
  {
    label: 'Total pain relief',
    value: 4,
  },
];
const { questionBackground } = colors;

class PISAFollowup3 extends Page {
  onClickBackButton = () => {
    this.backward();
  }

  onSubmission = () => {
    this.forwardWithQuery(this.props.location.query);
  }

  render() {
    const value = get(this.props.pisaFollowup, this.getTrackIndex(), null);
    return (
      <Question
        backgroundColor={questionBackground}
        currentPage={this.getCurrentPage()}
        image={false}
        onClickBackButton={() => this.props.router.goBack()}
        onChange={this.props.updatePRO}
        onSubmission={this.onSubmission}
        options={options}
        title="How much pain relief did you experience?"
        totalPages={this.getTotalPages()}
        trackIndex={this.getTrackIndex()}
        type="pisaFollowup"
        value={value}
      />
    );
  }
}

function mapStateToProps(state) {
  const { proForms: { pisaFollowup } } = state;
  return { pisaFollowup };
}

PISAFollowup3.propTypes = {
  pisaFollowup: PropTypes.array.isRequired,
  updatePRO: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { updatePRO })(PISAFollowup3);
