import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import Page from './page';
import formatPhoneNumber from '../lib/format-phone-number';
import { colors } from '../lib/styles';
import DisableSessionTimeout from '../components/disable-session-timeout';

const styles = {
  mainContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '0px 10px',
    flexGrow: 1,
    textAlign: 'center',
  },
  greenCheckMark: {
    marginTop: 30,
    marginBottom: 30,
    width: '8rem',
    height: '8rem',
  },
  enteredValueText: {
    fontSize: '27px',
    fontWeight: 'bold',
  },
  valueText: {
    fontSize: '22px',
    marginTop: 5,
  },
  contactText: {
    fontSize: '22px',
    marginTop: 30,
  },
  phoneText: {
    fontSize: '27px',
    fontWeight: 'bold',
  },
  remotelyMonitoredText: {
    fontSize: '27px',
    fontWeight: 'bold',
    marginTop: 30,
  },
  content: {
    flexGrow: 1,
    display: 'contents',
  },
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100%',
    justifyContent: 'space-between',
  },
  callButton: {
    backgroundColor: colors.primaryColor,
    borderRadius: '0.3125rem',
    minWidth: '17.1875rem',
    color: colors.white,
    marginBottom: '0.625rem',
    marginTop: '0.0625rem',
    width: '85vw',
    height: '4rem',
    display: 'block',
    lineHeight: '4rem',
    textDecoration: 'none',
    fontSize: '27px',
    textAlign: 'center',
  },
  callButtonWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 'auto',
    marginBottom: 20,
  },
};

class ThoracicCancerSymptoms10 extends Page {
  render() {
    const { classes, thoracicCancerSymptoms, user } = this.props;

    const {
      lowOxygen,
      cantControlBody,
      highTemp,
      newWeakness,
      falls,
      cantControlBowelsBladder,
    } = thoracicCancerSymptoms[this.getTrackIndex() - 1];

    const lowOxygenPunctuation = (cantControlBody || highTemp) ? ',' : '.';
    const highTempPunctuation = cantControlBody ? ',' : '.';
    const newWeaknessPunctuation = (falls || cantControlBowelsBladder) ? ',' : '.';
    const fallsPunctuation = cantControlBowelsBladder ? ',' : '.';

    let clinicNumber;
    if (user.requestingClinicNumber) {
      clinicNumber = formatPhoneNumber(user.requestingClinicNumber);
    }

    return (
      <div className={classes.contentWrapper}>
        <DisableSessionTimeout />
        <div className={classes.mainContent}>
          <img
            src="/img/GreenCheckmarkCircle.png"
            className={classes.greenCheckMark}
            alt="Green check mark"
          />
          {lowOxygen || highTemp || newWeakness || falls || cantControlBowelsBladder ? (
            <div className={classes.content}>
              <div className={classes.enteredValueText}>You have entered a value of...</div>
              {lowOxygen ? (
                <div className={classes.valueText}>{`a Sp02 value of 90% or less${lowOxygenPunctuation}`}</div>
              ) : null}
              {highTemp ? (
                <div className={classes.valueText}>{`a temperature of 100.4 or higher${highTempPunctuation}`}</div>
              ) : null}
              {newWeakness ? (
                <div className={classes.valueText}>{`yes for new weakness${newWeaknessPunctuation}`}</div>
              ) : null}
              {falls ? (
                <div className={classes.valueText}>{`yes for having falls${fallsPunctuation}`}</div>
              ) : null}
              {cantControlBowelsBladder ? (
                <div className={classes.valueText}>yes for difficulty controlling bowels or bladder.</div>
              ) : null}
              {clinicNumber ? (
                <div className={classes.contactText}>
                Please contact the nurse navigator, who works with your oncologist, at <a href={`tel:${clinicNumber}`} className={classes.phoneText}>{clinicNumber}</a> as soon as possible
                </div>
              ) : (
                <div className={classes.contactText}>
                Please contact the nurse navigator, who works with your oncologist, as soon as possible
                </div>
              )}
              <div className={classes.remotelyMonitoredText}>
              Please tell the call center that you are a remotely monitored patient.
              </div>
              <div className={classes.callButtonWrapper}>
                {clinicNumber ? (
                  <a className={classes.callButton} href={`tel:${clinicNumber}`}>CALL NOW</a>
                ) : null}
              </div>
            </div>
          ) : (
            <div className={classes.contactText}>Thank you for submitting your symptom assessment. This information is important to your care. You may now close the window</div>
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {
    proForms: {
      thoracicCancerSymptoms,
    },
    user,
  } = state;
  return { thoracicCancerSymptoms, user };
}

export default connect(mapStateToProps)(withStyles(styles)(ThoracicCancerSymptoms10));
