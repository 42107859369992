import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { assign, capitalize, lowerCase } from 'lodash';
import { Paper } from '@material-ui/core';

const baseStyles = {
  paperCircles: {
    backgroundSize: 'contain',
    border: '#596097 solid 2px',
    borderRadius: '50%',
    cursor: 'pointer',
    height: 50,
    margin: '20px 27px 5px',
    textAlign: 'center',
    width: 50,
  },
};

class PainType extends Component {
  constructor(props) {
    super(props);
    this.handleSelect = this.handleSelect.bind(this);
  }

  handleSelect() {
    this.props.onSelect(this.props.type);
  }

  render() {
    const { active, type } = this.props;
    const style = assign({ backgroundImage: `url("/img/${lowerCase(type)}.png")` }, baseStyles.paperCircles);
    return (
      <div>
        <Paper
          onClick={this.handleSelect}
          style={style}
          elevation={active ? 3 : 0}
        />
        <div style={{ fontSize: '12px', textAlign: 'center' }}>{capitalize(type.replace(/_/g, ' '))}</div>
      </div>
    );
  }
}

PainType.propTypes = {
  active: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
};

export default PainType;
