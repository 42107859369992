const en = {
  appointmentConfirmationNotificationPage: {
    introHeaderText: 'Appointment Confirmation',
    introBodyText: 'has a message for you.',
    loggedInSuccessText: `
    <p><strong>Thank you!</strong></p>
    <p>You'll receive an appointment reminder 24 hours before your scheduled appointment.</p>
    <p>A final text message will be sent 15 minutes before your scheduled exam.</p>
  `,
    loggedOutSuccessText: `
      <p><strong>Thank you!</strong></p>
      <p>You'll receive an appointment reminder 24 hours before your scheduled appointment.</p>
      <p>A final text message will be sent 15 minutes before your scheduled exam.</p>
      <p>You may close this browser window.</p>
    `,
  },
};

const es = {
  appointmentConfirmationNotificationPage: {
    introHeaderText: 'Confirmación de cita virtual',
    introBodyText: 'tiene un mensaje para usted',
    loggedInSuccessText: 'Ahora puedes cerrar esta ventana.',
    loggedOutSuccessText: 'Ahora puedes cerrar esta ventana.',
  },
};

export default { en, es };
