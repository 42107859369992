import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';
import { connect } from 'react-redux';
import Joi from 'joi-browser';

import Page from './page';
import AppBar from '../components/app-bar';
import IconWrapper from '../components/icon-wrapper';
import BloomkidzLayout from '../layouts/bloomkidz';
import { Briefcase } from '../lib/icons';
import { fontSizing } from '../lib/styles';
import { submitPRO } from '../state/pro-forms';
import { bloomkidzStyles, bloomkidzStylesObj } from '../lib/bloomkidz';
import { zipcode } from '../lib/validator';

const styles = bloomkidzStylesObj;

const formSchema = Joi.object({
  employerZipcode: Joi.string().regex(zipcode),
});

class BloomkidzParentGuardianInformation10 extends Page {
  constructor(props) {
    super(props);

    const stateFromRedux = props.bloomkidzParentGuardianInformation[this.getTrackIndex()] || {};

    this.state = {
      employerName: '',
      employerAddress: '',
      employerCity: '',
      employerState: '',
      employerZipcode: '',
      errors: {
        employerZipcode: '',
      },
      ...stateFromRedux,
    };
  }

  handleTextFieldChange = (e) => {
    const stateUpdate = { [e.target.name]: e.target.value };

    if (this.state.errors[e.target.name]) {
      stateUpdate.errors = {
        ...this.state.errors,
        [e.target.name]: '',
      };
    }

    this.setState(stateUpdate);
  };

  handleContinue = () => {
    const {
      bloomkidzParentGuardianInformation,
      location,
      submitPRO,
      user,
    } = this.props;
    const { employerZipcode } = this.state;
    const { id } = location.query;

    const { error } = formSchema.validate({ employerZipcode }, { abortEarly: false });

    if (error) {
      this.setState({
        errors: { employerZipcode: 'Invalid value' },
      });
      return;
    }

    const data = {
      ...this.getDataMap(bloomkidzParentGuardianInformation),
      ...this.state,
    };
    delete data.insuranceCardFrontImage;
    delete data.insuranceCardBackImage;
    delete data.secondaryInsuranceCardFrontImage;
    delete data.secondaryInsuranceCardBackImage;

    submitPRO({ userId: user.id, id }, {
      pro_type: 'BLOOMKIDZ-PARENT-GUARDIAN-INFORMATION',
      pro_data: {
        data,
        recorded_at: new Date().toISOString(),
      },
    }, user)
      .then(() => {
        this.forwardWithQuery(location.query);
      });
  }

  handleClickButton = (gender) => {
    this.setState({ gender });
  }

  shouldDisableContinueButton = () => {
    const {
      employerName,
      employerAddress,
      employerCity,
      employerState,
      employerZipcode,
      errors,
    } = this.state;

    if (errors.emergencyContactNumber) return true;

    return !employerName
      || !employerAddress
      || !employerCity
      || !employerState
      || !employerZipcode;
  }

  render() {
    const { classes, router } = this.props;
    const {
      employerName,
      employerAddress,
      employerCity,
      employerState,
      employerZipcode,
      errors,
    } = this.state;

    return (
      <BloomkidzLayout
        primaryBtnOnTouchTap={this.handleContinue}
        primaryBtnLabel="Next"
        primaryBtnDisabled={this.shouldDisableContinueButton()}
      >
        <AppBar
          headerNode="Employer Information"
          backButtonOnClick={() => router.goBack()}
        />
        <IconWrapper>
          <Briefcase />
        </IconWrapper>
        <div className={classes.pageContent}>
          <div>Employer</div>
          <div className={classes.paddingHorizontal}>
            <div className={classes.textFieldWrapper}>
              <TextField
                placeholder="Name"
                value={employerName}
                onChange={this.handleTextFieldChange}
                name="employerName"
                variant="standard"
                fullWidth
                inputProps={{ style: { fontSize: fontSizing.body, fontFamily: bloomkidzStyles.fontFamily } }}
              />
            </div>
          </div>
          <div className={classes.inputSectionTitle}>
            Address
          </div>
          <div className={classes.paddingHorizontal}>
            <div className={classes.textFieldWrapper}>
              <TextField
                placeholder="Address"
                value={employerAddress}
                onChange={this.handleTextFieldChange}
                name="employerAddress"
                variant="standard"
                fullWidth
                inputProps={{ style: { fontSize: fontSizing.body, fontFamily: bloomkidzStyles.fontFamily } }}
              />
            </div>
            <div className={classes.textFieldWrapper}>
              <TextField
                placeholder="City"
                value={employerCity}
                onChange={this.handleTextFieldChange}
                name="employerCity"
                variant="standard"
                fullWidth
                inputProps={{ style: { fontSize: fontSizing.body, fontFamily: bloomkidzStyles.fontFamily } }}
              />
            </div>
            <div className={classes.textFieldWrapper}>
              <TextField
                placeholder="State"
                value={employerState}
                onChange={this.handleTextFieldChange}
                name="employerState"
                variant="standard"
                fullWidth
                inputProps={{ style: { fontSize: fontSizing.body, fontFamily: bloomkidzStyles.fontFamily } }}
              />
            </div>
            <div className={classes.textFieldWrapper}>
              <TextField
                placeholder="Zipcode"
                value={employerZipcode}
                onChange={this.handleTextFieldChange}
                name="employerZipcode"
                variant="standard"
                fullWidth
                inputProps={{
                  style: { fontSize: fontSizing.body, fontFamily: bloomkidzStyles.fontFamily },
                  type: 'tel',
                }}
                error={errors.employerZipcode}
                helperText={errors.employerZipcode}
              />
            </div>
          </div>
        </div>
      </BloomkidzLayout>
    );
  }
}

BloomkidzParentGuardianInformation10.propTypes = {
  classes: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  submitPRO: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const {
    proForms: { bloomkidzParentGuardianInformation },
    user,
  } = state;

  return { bloomkidzParentGuardianInformation, user };
};

export default connect(mapStateToProps, { submitPRO })(withStyles(styles)(BloomkidzParentGuardianInformation10));
