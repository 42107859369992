import React from 'react';
import { get, debounce, omit } from 'lodash';
import { connect } from 'react-redux';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';

import AppBar from '../components/app-bar';
import Page from './page';
import Layout from '../layouts/common';
import { colors } from '../lib/styles';

import { apiFetch, makeShadowOptions } from '../lib/fetch';

const baseStyles = {
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
    minHeight: '600px',
    margin: '5px',
  },
  searchText: {
    color: colors.lightBrightBlue,
    fontSize: '14px',
    paddingTop: '10px',
  },
};

class MedicationsPro2 extends Page {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      data: [],
      open: false,
    };

    this.handleUpdateInput = debounce(this.handleUpdateInput.bind(this), 250, { trailing: true });
    this.handleSelectItem = this.handleSelectItem.bind(this);
  }

  handleUpdateInput(e, name) {
    if (!name) {
      return this.setState({
        loading: false,
        data: [],
      });
    }
    const options = makeShadowOptions({ name }, this.props.user.token, 'GET');
    this.setState({ loading: true });
    apiFetch('/goodrx', options)
      .then((response) => {
        this.setState({
          loading:false,
          data: get(response, 'data.candidates', []),
        });
      });
  }

  handleSelectItem(e, name) {
    // Add Item to state
    this.forwardWithQuery({ ...omit(this.props.router.location.query, 'medication_id'), name });
  }

  render() {
    const { loading } = this.state;
    return (
      <Layout>
        <AppBar
          backButtonOnClick={() => this.props.router.goBack()}
          headerNode="Medication Search"
        />
        <div style={baseStyles.mainContainer}>

          <Autocomplete
            open={this.state.open}
            onOpen={() => {
              this.setState({ open: true });
            }}
            onClose={() => {
              this.setState({ open: false });
            }}
            onChange={this.handleSelectItem}
            onInputChange={this.handleUpdateInput}
            options={this.state.data}
            freeSolo={true}
            fullWidth={true}
            loading={loading}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Search"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {loading ? <CircularProgress color="inherit" size={20} /> : null}
                    </React.Fragment>
                  ),
                }}
              />
            )}
          />
          <section>
            <div>
              <img alt="Search Icon" height="64px" width="64px" src="/img/search-icon.png" />
              <div style={baseStyles.searchText}>Search by medication name</div>
            </div>
          </section>
          <div>
            <img
              alt="GoodRx Logo"
              height="75px"
              src="/img/goodrx.png"
              width="180px"
            />
          </div>
        </div>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state;

  return {
    user,
  };
}

MedicationsPro2.defaultProps = {};

export default connect(mapStateToProps, {})(MedicationsPro2);
