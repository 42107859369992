import React from 'react';
import { connect } from 'react-redux';
import { CircularProgress } from '@material-ui/core';
import QRCode from 'qrcode.react';
import { generateQR } from '../state/user';
import { colors } from '../lib/styles';
import Page from './page';
import Layout from '../layouts/common';
import Continue from '../components/continue';

const baseStyles = {
  btn: {
    height: '3rem',
    marginTop: '5vh',
    maxWidth: '40vw',
  },
  label: {
    fontSize: '1.4rem',
  },
  updateBtn: {
    margin: '10px',
  },
  qr: {
    margin: '20px',
    maxHeight: '60vh',
    maxWidth: '90vw',
  },
  sectionBackground: {
    backgroundColor: colors.white,
  },
  style: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
  },
};

class QR extends Page {
  constructor(props) {
    super(props);
    this.state = {
      qrCode: null,
      qrSize: window.innerHeight < window.innerWidth ? window.innerHeight * 0.6 : window.innerWidth * 0.9,
    };
    this.handleOrientationListener = this.handleOrientationListener.bind(this);
  }

  componentDidMount() {
    const { user } = this.props;
    if (user.id) {
      this.props.generateQR(user.id)
        .then((res) => {
          this.setState({
            qrCode: res.qrcode,
          });
        });
    }
    window.addEventListener('orientationchange', this.handleOrientationListener);
  }

  componentWillUnmount() {
    window.removeEventListener('orientationchange', this.handleOrientationListener);
  }

  handleOrientationListener() {
    const size = window.innerHeight < window.innerWidth ? window.innerHeight * 0.9 : window.innerWidth * 0.6;
    this.setState({ qrSize: size });
  }

  render() {
    const { router } = this.props;
    return (
      <Layout style={baseStyles.style}>
        {this.state.qrCode ? (
          <QRCode
            value={this.state.qrCode}
            style={baseStyles.qr}
            size={this.state.qrSize}
          />
        ) : <CircularProgress mode="indeterminate" />}
        <Continue
          btnStyle={baseStyles.btn}
          labelStyle={baseStyles.label}
          onClick={() => router.push('/')}
        />
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state;
  return { user };
}

export default connect(mapStateToProps, { generateQR })(QR);
