import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get, includes } from 'lodash';

import { colors } from '../lib/styles';
import Page from './page';
import ButtonPRO from '../components/button-pro';
import { updatePRO } from '../state/pro-forms';

const buttonLabels = ['None', 'Mild', 'Moderate', 'Severe', 'Extreme'];

const options = [
  {
    question: 'How severe is your stiffness after first awakening in the morning?',
    label: buttonLabels,
  },
  {
    question: 'How severe is your stiffness after sitting, lying, or resting later in the day?',
    label: buttonLabels,
  },
];
const { questionBackground } = colors;

class WOMAC2 extends Page {
  onClickBackButton = () => {
    this.backward();
  }

  onSubmission = () => {
    this.forwardWithQuery(this.props.location.query);
  }

  render() {
    const page = get(this.props.womac, this.getTrackIndex(), [null, null]);
    const value = includes(page, null) ? null : 0;
    return (
      <ButtonPRO
        backgroundColor={questionBackground}
        currentPage={this.getCurrentPage()}
        onClickBackButton={() => this.props.router.goBack()}
        onChange={this.props.updatePRO}
        onSubmission={this.onSubmission}
        options={options}
        header="Stiffness"
        totalPages={this.getTotalPages()}
        trackIndex={this.getTrackIndex()}
        value={value}
        pro={page}
        type={this.props.type}
      />
    );
  }
}

function mapStateToProps(state, ownProps) {
  const { proForms, user } = state;
  const { type } = ownProps.route;
  const womacType = `womac${type}`;
  return { womac: proForms[womacType], user, type: womacType };
}

WOMAC2.propTypes = {
  womac: PropTypes.array.isRequired,
  updatePRO: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { updatePRO })(WOMAC2);
