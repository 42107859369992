import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import HeadsetMicIcon from '@material-ui/icons/HeadsetMic';
import { Button } from '@material-ui/core';

import AppBar from '../components/app-bar';
import Page from './page';
import StickyHeader from '../components/sticky-header';
import Layout from '../layouts/common';
import { colors } from '../lib/styles';
import { apiFetch } from '../lib/fetch';

const styles = {
  page: {
    padding: '60px 5px 5px 5px',
    color: colors.primaryColor,
    backgroundColor: colors.white,
  },
  button: {
    width: '100%',
    margin: '10px 0',
    backgroundColor: colors.primaryColor,
    color: colors.white,
    fontSize: '19px',
  },
  h1: {
    color: colors.black,
  },
  icon: {
    width: '100px',
    height: '100px',
    color: 'black',
  },
};

class AppointmentReminder extends Page {
  constructor(props) {
    super(props);
    this.state = {
      start_date: '',
      user_id: '',
      notification_id: '',
      clinic_name: '',
    };
  }

  componentDidMount() {
    const { start_date, user_id, notification_id, clinicName } = this.props.location.state;
    const clinic_name = this.props.clinicName || clinicName;
    this.setState({
      start_date,
      user_id,
      notification_id,
      clinic_name,
    });
  }

  handleDismissNotification = async () => {
    const options = { method: 'PUT', body: { dismissed: true } };
    await apiFetch(`/users/${this.state.user_id}/notifications/${this.state.notification_id}`, options);
    this.forward();
  };

  forwardPage = () => {
    this.props.router.push({ pathname: '/' });
    this.handleDismissNotification();
  }

  render() {
    return (
      <Layout>
        <div
          style={styles.page}
        >
          <StickyHeader>
            <AppBar
              backButtonOnClick={() => this.props.router.goBack()}
              headerNode="Appointment Reminder"
            />
          </StickyHeader>
          <div style={{ marginTop: '10%' }}>
            <HeadsetMicIcon style={styles.icon} />
            <h1 style={styles.h1}>Virtual Appointment</h1>
            <h1 style={styles.h1}>Reminder</h1>
            <h3 style={styles.h1}>An appointment has been scheduled for you</h3>
            <h3 style={styles.h1}>with {this.state.clinic_name}</h3>
            <h1 style={styles.h1}>{moment(this.state.start_date).format('dddd, MMMM DD, YYYY')}</h1>
            <h1 style={styles.h1}>{moment((this.state.start_date)).format('h:mm A')}</h1>
            <h4 style={styles.h1}>If you are unable to keep this appointment, please call our office.</h4>
          </div>
          <Button
            onClick={() => this.handleDismissNotification()}
            style={styles.button}
          >
            Finish
          </Button>
        </div>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state;
  return { clinicName: user.requesting_clinic_name };
}

AppointmentReminder.proptypes = {
  clinicName: PropTypes.string.isRequired,
};

export default connect(mapStateToProps, {})(AppointmentReminder);
