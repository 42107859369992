import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get } from 'lodash';

import { colors } from '../lib/styles';
import Page from './page';
import Question from '../components/question';
import { updatePRO } from '../state/pro-forms';

const options = [
  {
    label: 'Unable to move my hands.',
    value: 0,
  },
  {
    label: 'Unable to eat with a spoon but can move my hands.',
    value: 1,
  },
  {
    label: 'Unable to button my shirt but can eat with a spoon.',
    value: 2,
  },
  {
    label: 'Able to button my shirt with great difficulty.',
    value: 3,
  },
  {
    label: 'Able to button my shirt with slight difficulty.',
    value: 4,
  },
  {
    label: 'Not having any trouble with my hands.',
    value: 5,
  }
];

const { questionBackground } = colors;

class Pmjoa1 extends Page {
  onClickBackButton = () => {
    this.backward();
  }

  onSubmission = () => {
    this.forwardWithQuery(this.props.location.query);
  }

  render() {
    const value = get(this.props.pmjoa, this.getTrackIndex(), [null, null, null, null, null, null]);
    return (
      <Question
        backgroundColor={questionBackground}
        currentPage={this.getCurrentPage()}
        onClickBackButton={() => this.props.router.goBack()}
        onChange={this.props.updatePRO}
        onSubmission={this.onSubmission}
        options={options}
        title="Motor Dysfunction"
        totalPages={this.getTotalPages()}
        trackIndex={this.getTrackIndex()}
        type="pmjoa"
        value={value}
      />
    );
  }
}

function mapStateToProps(state) {
  const { proForms: { pmjoa } } = state;
  return { pmjoa };
}

Pmjoa1.propTypes = {
  pmjoa: PropTypes.array.isRequired,
  updatePRO: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { updatePRO })(Pmjoa1);
