import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { noop, includes, replace, concat } from 'lodash';
import ReactSlider from '../components/react-slider';

import Page from './page';
import { addPainHx, readMostRecentPainHx } from '../state/pain-hx';
import { updatePRO, submitPRO } from '../state/pro-forms';
import { colors } from '../lib/styles';
import Continue from '../components/continue';
import AppBar from '../components/app-bar';
import DashboardTop from '../components/dashboard-top';
import { logEvent } from '../lib/amplitude';

const baseStyles = {
  btns: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    marginBottom: '-2.2rem 0px 5px 0px',
  },
  busy: {
    backgroundColor: colors.lightGreyText,
    width: '38vw',
    marginLeft: '25px',
    lineHeight: '0px',
    minWidth: '129px',
    borderRadius: '25px',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  continue: {
    backgroundColor: colors.secondaryColor,
    width: '38vw',
    padding: '2px',
    marginRight: '25px',
    lineHeight: '0px',
    minWidth: '129px',
    borderRadius: '25px',
  },
  img: {
    marginTop: '10px',
    cursor: 'pointer',
  },
  label: {
    fontSize: '1rem',
  },
  newPain: {
    backgroundColor: colors.secondaryColor,
    width: '38vw',
    minWidth: '129px',
    padding: '2px',
    marginRight: '25px',
    lineHeight: '0px',
    borderRadius: '25px',
  },
  samePain: {
    backgroundColor: colors.lightGreyText,
    width: '38vw',
    minWidth: '129px',
    marginLeft: '25px',
    lineHeight: '0px',
    borderRadius: '25px',
  },
  slider: {
    width: '250px',
  },
};

export const Pain1 = props => (
  <DashboardTop
    title="What is the Pain Intensity"
    paragraph="Use slider to give a pain intensity on a scale from 1-10.
    0 being no pain and 10 being extreme agony."
  >
    <ReactSlider
      key="pain1"
      min={0}
      max={10}
      minLabel="0"
      maxLabel="10"
      onChangeComplete={props.onNextClick}
      value={props.painIntensity}
    />
  </DashboardTop>
);
Pain1.defaultProps = {
  painIntensity: 0,
};
Pain1.propTypes = {
  onNextClick: PropTypes.func.isRequired,
  painIntensity: PropTypes.number,
};

export const Pain2 = props => (
  <DashboardTop
    title="How Long Does the Pain Last?"
    paragraph="Use slider to give a pain duration on a scale from
    1 hour - all day."
  >
    <ReactSlider
      key="pain2"
      handleLabel="HRS"
      min={1}
      max={24}
      minLabel="1 HR"
      maxLabel="All Day"
      onChangeComplete={props.onNextClick}
      value={null}
    />
  </DashboardTop>
);
Pain2.propTypes = {
  onNextClick: PropTypes.func.isRequired,
};

export const Pain3 = props => (
  <DashboardTop
    title="Continue to Complete Pain Entry?"
    paragraph="Would you like to provide additional pain details? In 3
    minutes, you'll be able to deliver a full report."
  >
    <div style={baseStyles.btns}>
      <Continue
        text="I'm Too Busy"
        btnStyle={baseStyles.busy}
        labelStyle={baseStyles.label}
        onClick={props.onTooBusyClick}
      />
      <Continue
        btnStyle={baseStyles.continue}
        labelStyle={baseStyles.label}
        onClick={props.onNextClick}
      />
    </div>
  </DashboardTop>
);
Pain3.propTypes = {
  onNextClick: PropTypes.func.isRequired,
  onTooBusyClick: PropTypes.func.isRequired,
};

const pageItems = [Pain1, Pain2, Pain3];

export class PainCarousel extends Page {
  constructor(props) {
    super(props);
    this.state = {
      intensity: null,
      painDuration: 0,
      page: 0,
      pain_end_date: undefined,
      pain_start_date: undefined,
    };
  }

  nextPage = (stateValues = {}) => {
    const maxPages = pageItems.length;
    const currentPage = this.state.page;
    const nextPage = currentPage + 1;

    if (nextPage === maxPages) {
      return this.props.router.push('/');
    }

    this.setState({
      page: nextPage,
      ...stateValues,
    });
  }

  pain1 = intensity => this.nextPage({ intensity });

  pain2 = (painDuration) => {
    const pain_end_date = new Date();
    const durationInMillSec = painDuration * 60 * 60 * 1000;
    const pain_start_date = new Date(pain_end_date.getTime() - durationInMillSec);
    this.nextPage({ pain_end_date, pain_start_date, painDuration });
  }

  backPain = (painDuration) => {
    const { updatePRO, location, router } = this.props;
    const { intensity } = this.state;
    updatePRO({ value: intensity, position: 2, type: 'pain' });
    updatePRO({ value: painDuration, position: 3, type: 'pain' });
    const path = replace(location.pathname, 'PAIN-3', 'PAIN-4');
    router.push(`${path}${location.search}`);
  }

  neckPain = (painDuration) => {
    const { user, pain, location, submitPRO } = this.props;
    const { id } = location.query;
    const { intensity } = this.state;

    submitPRO(
      { userId: user.id, id },
      {
        pro_type: 'PAIN',
        pro_data: { data: concat(pain, [intensity, painDuration]) },
      },
      user,
    );
    this.finishTrack();
  }

  pain3 = () => {
    const {
      intensity,
      pain_end_date,
      pain_start_date,
    } = this.state;

    logEvent('Confirm This Pain Entry (Finish)');

    this.props.addPainHx({
      intensity,
      pain_end_date,
      pain_start_date,
    })
      .then((record) => {
        this.props.router.push(`/pain?pain_hx_id=${record.id}`);
      });
  }

  pain3TooBusy = () => {
    const {
      intensity,
      pain_end_date,
      pain_start_date,
    } = this.state;

    logEvent('Confirm This Pain Entry (Finish)');

    this.props.addPainHx({
      intensity,
      pain_end_date,
      pain_start_date,
    })
      .then(() => {
        readMostRecentPainHx();
        // reset state to no longer hold previous pain entry data
        this.nextPage({
          intensity: 0,
          pain_end_date: undefined,
          pain_start_date: undefined,
          painHxId: undefined,
        });
      });
  }

  onClickBackButton = () => {
    this.backward();
  }

  render() {
    const {
      intensity,
      page,
    } = this.state;
    const {
      painDisplayIntensity,
      painDisplayDate,
      painDisplayTime,
      route,
    } = this.props;
    let onNextClick = this[`pain${page + 1}`];

    if (includes(route.path, 'PAIN-3') && page === 1) {
      onNextClick = this.backPain;
    }
    if (includes(route.path, 'PAIN-6') && page === 1) {
      onNextClick = this.neckPain;
    }
    const DashboardQuestions = pageItems[page];

    const onTooBusyClick = (page === 2) ? this.pain3TooBusy : noop;

    return (
      <div style={baseStyles.container}>
        <AppBar
          noLeftNode={true}
          headerNode="Body Pain Entry"
        />
        <DashboardQuestions
          onTooBusyClick={onTooBusyClick}
          onNextClick={onNextClick}
          painDisplayIntensity={painDisplayIntensity}
          painDisplayDate={painDisplayDate}
          painDisplayTime={painDisplayTime}
          painIntensity={intensity}
          router={this.props.router}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { user, proForms: { pain } } = state;
  return { user, pain };
}

PainCarousel.defaultProps = {
  intensity: 0,
  painDisplayIntensity: undefined,
  painDisplayDate: undefined,
  painDisplayTime: undefined,
  painHxId: undefined,
};
PainCarousel.propTypes = {
  pain: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
  addPainHx: PropTypes.func.isRequired,
  painDisplayIntensity: PropTypes.number,
  painDisplayDate: PropTypes.string,
  painDisplayTime: PropTypes.string,
  painHxId: PropTypes.number,
  router: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, { addPainHx, submitPRO, readMostRecentPainHx, updatePRO })(PainCarousel);
