import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import AppBar from '../components/app-bar';
import Page from './page';
import QuestionLayout from '../layouts/question';
import YesNoButtonSet from '../components/yes-no-button-set';
import { colors } from '../lib/styles';
import { updatePRO } from '../state/pro-forms';
import { Swallowing } from '../lib/icons';

const styles = {
  contentWrapper: {
    flexGrow: 1,
    overflowX: 'hidden',
    position: 'relative',
  },
  difficultySwallowingText: {
    fontSize: '2rem',
    fontWeight: 'bold',
  },
  imgRow: {
    display: 'flex',
    justifyContent: 'center',
    padding: '15px 60px',
  },
  imageWrapper: {
    width: 100,
  },
  instructions: {
    color: 'grey',
    fontSize: '1.5rem',
    margin: '20px 40px 0px 40px',
    textAlign: 'center',
  },
  questionnaireContainer: {
    paddingLeft: 20,
    paddingRight: 20,
  },
};

class HeadAndNeckCancerSymptoms2 extends Page {
  constructor(props) {
    super(props);

    this.state = {
      difficultySwallowingLiquids: null,
      difficultySwallowingSaliva: null,
      difficultySwallowingSolidFood: null,
    };
  }

  handleChangeLiquids = (value) => {
    this.setState({ difficultySwallowingLiquids: value });
  }

  handleChangeSaliva = (value) => {
    this.setState({ difficultySwallowingSaliva: value });
  };

  handleChangeSolidFood = (value) => {
    this.setState({ difficultySwallowingSolidFood: value });
  };

  handleClickNext = () => {
    const {
      difficultySwallowingLiquids,
      difficultySwallowingSaliva,
      difficultySwallowingSolidFood,
    } = this.state;

    this.props.updatePRO({
      type: 'headAndNeckCancerSymptoms',
      position: this.getTrackIndex(),
      value: {
        difficultySwallowingLiquids,
        difficultySwallowingSaliva,
        difficultySwallowingSolidFood,
      },
    });
    this.forwardWithQuery(this.props.location.query);
  }

  render() {
    const { classes } = this.props;
    const {
      difficultySwallowingLiquids,
      difficultySwallowingSaliva,
      difficultySwallowingSolidFood,
    } = this.state;

    const continueDisabled = difficultySwallowingLiquids === null
      || difficultySwallowingSaliva === null
      || difficultySwallowingSolidFood === null;

    return (
      <QuestionLayout
        primaryBtnLabel="Next"
        primaryBtnOnTouchTap={this.handleClickNext}
        primaryBtnDisabled={continueDisabled}
        style={{ backgroundColor: colors.questionBackground }}
      >
        <AppBar
          headerNode="Swallowing"
          backButtonOnClick={() => this.props.router.goBack()}
        />
        <LinearProgress
          variant="determinate"
          value={(this.getCurrentPage() / this.getTotalPages()) * 100}
          classes={{ bar: classes.bar }}
        />
        <div className={classes.contentWrapper}>
          <div className={classes.instructions}>
            Please select the option that best answers the questions relating to swallowing
          </div>
          <div className={classes.imgRow}>
            <div className={classes.imageWrapper}>
              <Swallowing />
            </div>
          </div>
          <div className={classes.questionnaireContainer}>
            <div className={classes.difficultySwallowingText}>Do you have difficulty swallowing...</div>
            <YesNoButtonSet
              isYes={difficultySwallowingSolidFood === 'Y'}
              isNo={difficultySwallowingSolidFood === 'N'}
              onClickNo={() => this.handleChangeSolidFood('N')}
              onClickYes={() => this.handleChangeSolidFood('Y')}
              questionText="Solid food"
            />
            <YesNoButtonSet
              isYes={difficultySwallowingSaliva === 'Y'}
              isNo={difficultySwallowingSaliva === 'N'}
              onClickNo={() => this.handleChangeSaliva('N')}
              onClickYes={() => this.handleChangeSaliva('Y')}
              questionText="Saliva"
            />
            <YesNoButtonSet
              isYes={difficultySwallowingLiquids === 'Y'}
              isNo={difficultySwallowingLiquids === 'N'}
              onClickNo={() => this.handleChangeLiquids('N')}
              onClickYes={() => this.handleChangeLiquids('Y')}
              questionText="Liquids"
            />
          </div>
        </div>
      </QuestionLayout>
    );
  }
}

export default connect(null, { updatePRO })(withStyles(styles)(HeadAndNeckCancerSymptoms2));
