import React from 'react';
import { connect } from 'react-redux';
import { map } from 'lodash';
import { List } from '@material-ui/core';

import AppBar from '../components/app-bar';
import Page from './page';
import Layout from '../layouts/common';
import { pqrsIfNeeded, symptomsIfNeeded } from '../state/app-data';
import Continue from '../components/continue';
import Subtitle from '../components/subtitle';

import SimpleListItem from '../components/simple-list-item';
import { apiFetch, makeShadowOptions } from '../lib/fetch';
import { updatePRO, submitPRO } from '../state/pro-forms';

import { colors } from '../lib/styles';

const baseStyles = {
  list: {
    backgroundColor: colors.white,
    textTransform: 'capitalize',
  },
  text: {
    color: colors.primaryColor,
  },
  font: {
    color: colors.primaryColor,
    fontSize: 10,
  },
  underline: {
    display: 'none',
  },
};

class PQRS extends Page {
  state = {
    submitting: false,
  }
  componentWillMount() {
    this.props.symptomsIfNeeded();
    this.props.pqrsIfNeeded();
  }

  handleCheck = (pqrs_id, checked) => {
    const update = { ...this.props.selectedPqrs, [pqrs_id]: { pqrs_id, answer: checked }};
    this.props.updatePRO({
      type: 'systemsReview',
      position: this.getTrackIndex(),
      value: update,
    });
  }

  getHx = (id) => {
    const { selectedPqrs } = this.props;
    return selectedPqrs[id];
  }

  handleContinue = () => {
    if (this.state.submitting) return;
    this.setState({ submitting: true });
    const { selectedSymptoms, selectedPqrs } = this.props;
    const { user } = this.props;
    const { id } = this.props.location.query;
    const formattedSymptoms = selectedSymptoms.map((id) => {
      return this.props.symptoms[id].name;
    });
    const formattedPqrs = Object.keys(selectedPqrs).reduce((all, id) => {
      if(selectedPqrs[id]) {
        all.push(this.props.pqrs[id].name);
      }
      return all;
    }, []);

    const systemsReviewResults = formattedSymptoms.concat(formattedPqrs);

    let formattedSymptomsHx = selectedSymptoms.map((id) => {
      return {
        symptoms_id: id,
        explanation: '',
      }
    });

    // default to None Provided if None Provided
    if (!formattedSymptomsHx.length) {
      formattedSymptomsHx = [{ symptoms_id: 1, explanation: '' }];
    }

    const symptomsHxOptions = makeShadowOptions({ symptoms_hx: formattedSymptomsHx }, this.props.user.token, 'POST');
    const pqrsHxOptions = makeShadowOptions({ pqrs_hx: map(selectedPqrs, i => i) }, this.props.user.token, 'POST');
    apiFetch(`/users/${user.id}/symptoms_hx_bulk`, symptomsHxOptions)
      .then(() => apiFetch(`/users/${user.id}/pqrs_hx_bulk`, pqrsHxOptions))
      .then(() => {
        return this.props.submitPRO({ userId: user.id, id }, {
          pro_type: 'SYSTEMS-REVIEW',
          pro_data: { data: systemsReviewResults },
        }, user);
      })
      .then(() => this.forward())
      .catch((e) => this.setState({ error: e, submitting: false }));
  }

  render() {
    const { pqrs } = this.props;
    const rows = map(pqrs, (obj, i) => {
      const hx = this.getHx(obj.id);
      return (
        <SimpleListItem
          key={i}
          item={obj}
          checked={hx ? hx.answer : false}
          onAddItem={this.handleCheck}
          onRemoveItem={this.handleCheck}
        />
      );
    });
    return (
      <Layout>
        <div>
          <AppBar
            backButtonOnClick={() => this.props.router.goBack()}
            headerNode="Review of Systems"
          />
          <Subtitle label="Scroll to see all symptoms and check all that apply." />
          <List style={baseStyles.list}>
            {rows}
          </List>
          <section style={{ backgroundColor: colors.white }}>
            <Continue onClick={this.handleContinue} />
          </section>
        </div>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  const {
    appData: {
      pqrs,
      symptoms,
    },
    proForms: { systemsReview },
    user,
  } = state;

  return {
    user,
    pqrs,
    symptoms,
    selectedSymptoms: systemsReview[0] || [],
    selectedPqrs: systemsReview[1] || {},
  };
}

export default connect(mapStateToProps, { pqrsIfNeeded, symptomsIfNeeded, submitPRO, updatePRO })(PQRS);
