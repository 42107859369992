import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';

import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import TextField from '@material-ui/core/TextField';

import { withStyles } from '@material-ui/core/styles';

import AppBar from '../components/app-bar';
import Continue from '../components/continue';

import { WaterDrop } from '../lib/icons';

import Page from './page';

import { updatePRO } from '../state/pro-forms';

const styles = {
  button: {
    margin: 5,
    width: 150,
    height: 40,
    fontSize: 16,
  },
  buttonGroup: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  formControl: {
    textAlign: 'center',
  },
  formControlItem: {
    fontSize: '22px',
    textAlign: 'center',
    margin: '1rem',
  },
  iconContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    flexGrow: 0,
    flexShrink: 0,
    width: '100%',
    color: '#000',
    paddingTop: 10,
  },
  iconWrapper: {
    width: 100,
    marginTop: 10,
  },
  instructions: {
    fontSize: '1.3rem',
  },
  titleGroup: {
    margin: 25,
  },
  text: {
    fontSize: '22px',
  },
  timePicker: {
    marginLeft: 5,
    marginRight: 5,
    width: 200,
  },
  nextBtn: {
    textAlign: 'center',
    width: '100%',
    top: '90%',
    position: 'fixed',
  },
};

const StyledTextField = withStyles(() => ({
  root: {
    '& .MuiInputBase-root': {
      fontSize: '16px',
    },
  },
}))(TextField);

class DiabetesDaily4 extends Page {
  constructor(props) {
    super(props);

    this.state = {
      wasMedicationTakenToday: null,
      wasMedicationTakenAsPrescribed: null,
      timeMedicationWasTaken: '08:00',
    };

    this.handleContinue = this.handleContinue.bind(this);
    this.handleTime = this.handleTime.bind(this);
  }

  handleTime(e) {
    const { value } = e.target;
    this.setState({
      timeMedicationWasTaken: value,
    });
  }

  handleContinue() {
    const { timeMedicationWasTaken } = this.state;

    const hour = timeMedicationWasTaken.substring(0, 2);
    const minute = timeMedicationWasTaken.substring(3);

    const time = moment().hour(hour).minute(minute);

    this.props.updatePRO({
      type: 'diabetesDaily',
      position: this.getTrackIndex(),
      value: { ...this.state, timeMedicationWasTaken: new Date(time).toLocaleString() } });
    this.forwardWithQuery(this.props.location.query);
  }

  render() {
    const { wasMedicationTakenToday, wasMedicationTakenAsPrescribed, timeMedicationWasTaken } = this.state;

    let disabled;

    if (wasMedicationTakenToday === true && wasMedicationTakenAsPrescribed !== null && timeMedicationWasTaken !== null) {
      disabled = false;
    } else if (wasMedicationTakenToday === false) {
      disabled = false;
    } else {
      disabled = true;
    }

    return (
      <Fragment>
        <AppBar
          headerNode="Blood Sugar"
          noLeftNode={true}
        />
        <LinearProgress
          variant="determinate"
          value={(this.getCurrentPage() / this.getTotalPages()) * 100}
        />
        <div style={styles.iconContainer}>
          <div style={styles.iconWrapper}>
            <WaterDrop />
          </div>
        </div>

        <div style={styles.titleGroup}>
          <p style={styles.text}>Did you take your medication today?</p>
          <div style={styles.buttonGroup}>
            <Button style={styles.button} variant="contained" color={wasMedicationTakenToday ? 'primary' : 'default'} onClick={() => this.setState({ wasMedicationTakenToday: true })}>Yes</Button>
            <Button style={styles.button} variant="contained" color={wasMedicationTakenToday === false ? 'primary' : 'default'} onClick={() => this.setState({ wasMedicationTakenToday: false })}>No</Button>
          </div>
        </div>

        <div className={`${wasMedicationTakenToday ? '' : 'hidden'}`} style={styles.titleGroup}>
          <p style={styles.text}>Did you take them as prescribed?</p>
          <div style={styles.buttonGroup}>
            <Button style={styles.button} variant="contained" color={wasMedicationTakenAsPrescribed ? 'primary' : 'default'} onClick={() => this.setState({ wasMedicationTakenAsPrescribed: true })}>Yes</Button>
            <Button style={styles.button} variant="contained" color={wasMedicationTakenAsPrescribed === false ? 'primary' : 'default'} onClick={() => this.setState({ wasMedicationTakenAsPrescribed: false })}>No</Button>
          </div>

          <p style={styles.text}>Time</p>
          <div style={styles.formControl}>
            <StyledTextField
              id="time"
              value={timeMedicationWasTaken}
              type="time"
              defaultValue="08:00"
              onChange={this.handleTime}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                step: 300, // 5 min
                fontSize: '16px',
              }}
            />
          </div>
        </div>


        <div style={styles.nextBtn}>
          <Continue
            disabled={disabled}
            text="Next"
            onClick={this.handleContinue}
          />
        </div>

      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { user, proForms: { diabetesDaily } } = state;
  return { user, diabetesDaily };
}

DiabetesDaily4.proptypes = {
  user: PropTypes.object.isRequired,
  route: PropTypes.object.isRequired,
  updatePRO: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { updatePRO })(DiabetesDaily4);
