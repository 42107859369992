import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { concat, pickBy, forEach, includes } from 'lodash';
import {
  FormControl,
  FormGroup,
  FormLabel,
  FormControlLabel,
  Checkbox,
  Radio,
  RadioGroup,
} from '@material-ui/core';

import Page from './page';
import AppBar from '../components/app-bar';
import { colors } from '../lib/styles';
import QuestionLayout from '../layouts/question';
import { updatePRO, submitPRO } from '../state/pro-forms';

const { questionBackground } = colors;

const styles = {
  contentWrapper: {
    flexGrow: 1,
    overflowX: 'hidden',
    position: 'relative',
  },
  instructions: {
    color: 'grey',
    fontSize: '1.5rem',
    width: '80%',
    margin: '20px auto',
  },
  pageIndicator: {
    fontSize: '1.4rem',
  },
  questionnaire: {
    display: 'flex',
    width: '80%',
    margin: '20px auto',
    flexDirection: 'column',
    position: 'relative',
  },
  formGroup: {
    margin: '25px',
  },
  helperText: {
    color: colors.black,
    fontSize: '20px',
    textAlign: 'center',
  },
  radioButtonGroup: {
    display: 'flex',
    flexDirection: 'row',
  },
  radioButton: {
    color: colors.black,
    fontSize: '1.5rem',
    lineHeight: '1.7rem',
  },
};

class Covid19_6 extends Page {
  state = {
    contact: '',
    exposure: '',
    travel: {
      'China': false,
      'Italy': false,
      'Iran': false,
      'Japan': false,
      'South Korea': false,
      'Spain': false,
      'None': false,
    },
  };

  onSubmission = () => {
    const trackIndex = this.getTrackIndex();
    const { user } = this.props;
    const { id } = this.props.location.query;
    let data = [...this.props.covid19];
    const travel = Object.keys(pickBy(this.state.travel, v => v));
    this.props.updatePRO({
      type: 'covid19',
      position: trackIndex,
      value: [this.state.contact, this.state.exposure, travel],
    });
    data = concat(data, [this.state.contact, this.state.exposure, travel]);
    this.props.submitPRO({ userId: user.id, id }, {
      pro_type: 'COVID-19',
      pro_data: { data },
    }, user)
      .then(this.forward);
  }

  handleChange = (event) => {
    const travel = { ...this.state.travel };
    if (event.target.name === 'None') {
      forEach(travel, (val, key) => {
        travel[key] = false;
      });
    }
    if (event.target.name !== 'None') {
      travel['None'] = false;
    }
    travel[event.target.name] = event.target.checked;
    this.setState({ travel });
  }

  handleContact = (event, value) => {
    this.setState({ contact: value });
  }

  handleExposure = (event, value) => {
    this.setState({ exposure: value });
  }

  render() {
    const primaryBtnDisabled = (this.state.contact === '' || this.state.exposure === '' || !includes(this.state.travel, true));
    return (
      <QuestionLayout
        primaryBtnDisabled={primaryBtnDisabled}
        primaryBtnLabel="Submit"
        primaryBtnOnTouchTap={this.onSubmission}
        style={{ backgroundColor: questionBackground }}
      >
        <AppBar
          backButtonOnClick={() => this.props.router.goBack()}
          headerNode="COVID-19 Assessment"
        />
        <div style={styles.contentWrapper}>
          <div style={styles.instructions}>
            Have you had close contact with someone with a confirmed case of COVID-19 in the last 21 days?
          </div>
          <div style={styles.questionnaire}>
            <FormControl style={styles.formGroup} component="fieldset">
              <RadioGroup
                name="contact"
                onChange={this.handleContact}
                value={this.state.contact}
                style={styles.radioButtonGroup}
              >
                <FormControlLabel value="Yes" control={<Radio style={styles.radioButton} />} label="Yes" />
                <FormControlLabel value="No" control={<Radio style={styles.radioButton} />} label="No" />
              </RadioGroup>
            </FormControl>
          </div>
          <div style={styles.instructions}>
            Have you been notified by local public health officials that you may have been exposed?
          </div>
          <div style={styles.questionnaire}>
            <FormControl style={styles.formGroup} component="fieldset">
              <RadioGroup
                name="exposure"
                onChange={this.handleExposure}
                value={this.state.exposure}
                style={styles.radioButtonGroup}
              >
                <FormControlLabel value="Yes" control={<Radio style={styles.radioButton} />} label="Yes" />
                <FormControlLabel value="No" control={<Radio style={styles.radioButton} />} label="No" />
              </RadioGroup>
            </FormControl>
          </div>
          <div style={styles.instructions}>
            Have you recently traveled to:
          </div>
          <div style={styles.questionnaire}>
            <FormControl component="fieldset">
              <FormLabel component="legend">Choose all that apply</FormLabel>
              <FormGroup style={{ display: 'flex', flexDirection: 'row' }}>
                <FormControlLabel
                  control={<Checkbox checked={this.state.travel['China']} onChange={this.handleChange} name="China" />}
                  label="China"
                />
                <FormControlLabel
                  control={<Checkbox checked={this.state.travel['Italy']} onChange={this.handleChange} name="Italy" />}
                  label="Italy"
                />
                <FormControlLabel
                  control={<Checkbox checked={this.state.travel['Iran']} onChange={this.handleChange} name="Iran" />}
                  label="Iran"
                />
                <FormControlLabel
                  control={<Checkbox checked={this.state.travel['Japan']} onChange={this.handleChange} name="Japan" />}
                  label="Japan"
                />
                <FormControlLabel
                  control={<Checkbox checked={this.state.travel['South Korea']} onChange={this.handleChange} name="South Korea" />}
                  label="South Korea"
                />
                <FormControlLabel
                  control={<Checkbox checked={this.state.travel['Spain']} onChange={this.handleChange} name="Spain" />}
                  label="Spain"
                />
                <FormControlLabel
                  control={<Checkbox checked={this.state.travel['None']} onChange={this.handleChange} name="None" />}
                  label="None"
                />
              </FormGroup>
            </FormControl>
          </div>
        </div>
      </QuestionLayout>
    );
  }
}

function mapStateToProps(state) {
  const { proForms: { covid19 }, user } = state;
  return { covid19, user };
}

Covid19_6.propTypes = {
  user: PropTypes.object.isRequired,
  covid19: PropTypes.array.isRequired,
  updatePRO: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { updatePRO, submitPRO })(withStyles(styles)(Covid19_6));
