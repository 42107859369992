import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
} from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { connect } from 'react-redux';

import Page from './page';
import AppBar from '../components/app-bar';
import IconWrapper from '../components/icon-wrapper';
import BloomkidzLayout from '../layouts/bloomkidz';
import { ClipboardListSolid } from '../lib/icons';
import { updatePRO } from '../state/pro-forms';
import { bloomkidzStylesObj } from '../lib/bloomkidz';

const styles = bloomkidzStylesObj;

const nameLabelMap = {
  failureToThrive: 'Failure-to-thrive',
  diabetes: 'Diabetes',
  familyConflict: 'Family Conflict',
  bullying: 'Bullying',
  anxiety: 'Anxiety',
  hyperactivity: 'Hyperactivity',
  hitting: 'Hitting',
  foodSelectivity: 'Food Selectivity',
  propertyDestruction: 'Property Destruction',
  febrileSeizures: 'Febrile Seizures',
  asthma: 'Asthma',
  tantrums: 'Tantrums',
  physicalAbuse: 'Physical Abuse',
  dealthLoss: 'Death/Loss',
  depression: 'Depression',
  restrictedInterests: 'Restricted Interests',
  kicking: 'Kicking',
  inappropriateTouch: 'Inappropriate Touch',
  headBanging: 'Head Banging',
  epilepsy: 'Epilepsy',
  eatingDifficulties: 'Eating Difficulties',
  selfInjuriousBehavior: 'Self-Injurious Behavior',
  emotionalAbuse: 'Emotional Abuse',
  abandonment: 'Abandonment',
  seizures: 'Seizures',
  biting: 'Biting',
  runningAway: 'Running Away',
  suicidalIdeation: 'Suicidal Ideation',
  prolongedHospitalization: 'Prolonged Hospitalization',
  otherConcerns: 'Other',
};

class BloomkidzMedicalHistory6 extends Page {
  constructor(props) {
    super(props);

    this.state = {
      failureToThrive: false,
      diabetes: false,
      familyConflict: false,
      bullying: false,
      anxiety: false,
      hyperactivity: false,
      hitting: false,
      foodSelectivity: false,
      propertyDestruction: false,
      febrileSeizures: false,
      asthma: false,
      tantrums: false,
      physicalAbuse: false,
      dealthLoss: false,
      depression: false,
      restrictedInterests: false,
      kicking: false,
      inappropriateTouch: false,
      headBanging: false,
      epilepsy: false,
      eatingDifficulties: false,
      selfInjuriousBehavior: false,
      emotionalAbuse: false,
      abandonment: false,
      seizures: false,
      biting: false,
      runningAway: false,
      suicidalIdeation: false,
      prolongedHospitalization: false,
      otherConcerns: false,
    };
  }

  handleChangeConcerns = (e) => {
    this.setState({ [e.target.name]: e.target.checked });
  };

  handleContinue = () => {
    const { location, updatePRO } = this.props;
    const skip = this.getContinueButtonText();

    if (skip === 'Skip') {
      return this.skipNext();
    }

    updatePRO({
      type: 'bloomkidzMedicalHistory',
      position: this.getTrackIndex(),
      value: { ...this.state },
    });

    this.forwardWithQuery(location.query);
  }

  getContinueButtonText = () => {
    const { state } = this;

    const stateVals = Object.values(state);

    for (let i = 0; i < stateVals.length; i++) {
      if (stateVals[i] === true) return 'Next';
    }

    return 'Skip';
  }

  handleTextFieldChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  render() {
    const { classes, router } = this.props;
    const { state } = this;

    return (
      <BloomkidzLayout
        primaryBtnOnTouchTap={this.handleContinue}
        primaryBtnLabel={this.getContinueButtonText()}
      >
        <AppBar
          headerNode="Experienced"
          backButtonOnClick={() => router.goBack()}
        />
        <IconWrapper>
          <ClipboardListSolid />
        </IconWrapper>
        <div className={classes.pageContent}>
          <div>Has your child engaged in or experienced any of the following?</div>
          <div className={classes.subtext}><i>Check all that apply</i></div>
          <FormGroup>
            {Object.entries(nameLabelMap).map(([name, label]) => (
              <>
                <FormControlLabel
                  control={(
                    <Checkbox
                      checked={state[name]}
                      onChange={this.handleChangeConcerns}
                      name={name}
                      icon={<CheckBoxOutlineBlankIcon fontSize="large" />}
                      checkedIcon={<CheckBoxIcon fontSize="large" />}
                      className={classes.checkbox}
                    />
                  )}
                  label={label}
                  classes={{
                    label: classes.checkboxLabel,
                    root: classes.formControlLabel,
                  }}
                />
              </>
            ))}
          </FormGroup>
        </div>
      </BloomkidzLayout>
    );
  }
}

BloomkidzMedicalHistory6.propTypes = {
  bloomkidzClientWellnessProfile: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  updatePRO: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const {
    proForms: { bloomkidzClientWellnessProfile },
    user,
  } = state;

  return { user, bloomkidzClientWellnessProfile };
};

export default connect(mapStateToProps, { updatePRO })(withStyles(styles)(BloomkidzMedicalHistory6));
