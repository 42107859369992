import { Component } from 'react';
import PropTypes from 'prop-types';
import { browserHistory } from 'react-router';

import store from '../store';
import { logEvent } from '../lib/amplitude';
import config from '../config';
import {
  setRailroad,
  forward,
  backward,
  clear,
  setTrack,
  skipNext,
  getTrack,
  finishTrack,
  getPathIndex,
  getTotalPages,
  getCurrentPage,
  goToIndex,
} from './navigation';

class Page extends Component {
  constructor(props) {
    super(props);
    this.forward = this.forward.bind(this);
    this.forwardWithQuery = this.forwardWithQuery.bind(this);
    this.backward = this.backward.bind(this);
    this.backwardWithQuery = this.backwardWithQuery.bind(this);
    this.clear = this.clear.bind(this);
    this.setTrack = this.setTrack.bind(this);
    this.skipNext = this.skipNext.bind(this);
    this.getTrack = this.getTrack.bind(this);
    this.setRailroad = this.setRailroad.bind(this);
    this.getCurrentPage = this.getCurrentPage.bind(this);
    this.getTotalPages = this.getTotalPages.bind(this);
    this.getDataMap = this.getDataMap.bind(this);

    if (props && props.route && props.route.isPro) {
      const { loggedIn } = store.getState().user;

      if (!loggedIn) {
        browserHistory.push('/invalid-page');
      }
      const { redirect, redirectToRR } = props.location.query;
      if (redirect && redirect !== 'false') {
        window.location.replace(`${config.IVC_URL}/notifications/${props.user.notification_id}?dob=${redirect}`);
      } else if (redirectToRR && redirectToRR !== 'false') {
        window.location.replace(`${config.IVC_RR_URL}/notifications/${props.user.notification_id}?dob=${redirectToRR}`);
      }
    }
  }

  getTrack() {
    return getTrack(this.props.router);
  }

  setTrack(track, query = {}, state = {}) {
    setTrack(track, this.props.router, query, state);
  }

  getTotalPages() {
    return getTotalPages(this.props.router);
  }

  getCurrentPage() {
    return getCurrentPage(this.props.router);
  }

  // Returns the index of the path in a given track
  // Does not take into account railroads
  getTrackIndex() {
    return getPathIndex(this.props.router);
  }

  setRailroad(tracks) {
    setRailroad(tracks, this.props.router, { isInitial: true });
  }

  finishTrack() {
    finishTrack(this.props.router);
  }

  forward() {
    forward(this.props.router);
  }

  forwardWithQuery(query) {
    // Doing this in the main forward function
    // will pick up the event object passed in by
    // default
    forward(this.props.router, query);
  }

  skipNext() {
    skipNext(this.props.router);
  }

  // not currently working on components that bring in page
  backward() {
    logEvent('Back Button (all pages)');
    backward(this.props.router);
  }

  backwardWithQuery(query) {
    // Doing this in the main backward function
    // will pick up the event object passed in by
    // default
    backward(this.props.router, query);
  }

  clear() {
    clear(this.props.router);
  }

  goToIndexWithQuery(index, query) {
    goToIndex(index, this.props.router, query);
  }

  getDataMap(proState) {
    if (Array.isArray(proState)) {
      return proState.reduce((prev, cur) => {
        return {...prev, ...cur};
      }, {});
    }
    return proState;
  }
}

Page.propTypes = {
  route: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default Page;
