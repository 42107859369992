import React from 'react';
import { connect } from 'react-redux';
import { get, map } from 'lodash';
import AddIcon from '@material-ui/icons/Add';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';

import AppBar from '../components/app-bar';
import Page from './page';
import Layout from '../layouts/common';
import Continue from '../components/continue';
import ClinicListItem from '../components/clinic-list-item';
import PhysicianListItem from '../components/physician-list-item';
import EmployerListItem from '../components/employer-list-item';
import Subtitle from '../components/subtitle';
import { colors, custom } from '../lib/styles';
import { logEvent } from '../lib/amplitude';

import { browseEmployers } from '../state/employer';
import { browsePatientProviders } from '../state/patient-provider';
import { addInsurance, browseInsurance } from '../state/insurance';
import { browsePatientContactInfo } from '../state/patient-contact-info';
import { browseUserClinics, editUserClinic } from '../state/patient-clinics';
import { fetchClinicsIfNecessary } from '../state/app-data';

const baseStyles = {
  listItem: {
    borderBottom: `1px solid ${colors.primaryHighlight}`,
    color: colors.primaryColor,
    fontWeight: 400,
    textAlign: 'left',
  },
  toggleMsg: {
    textAlign: 'center',
    fontSize: '12px',
    color: colors.primaryColor,
    background: 'rgba(24, 183, 155, 0.2)',
    marginTop: '5px',
    marginBottom: '5px',
  },
};

class PersonalInfo extends Page {
  constructor(props) {
    super(props);
    this.handleAddEmergencyContact = this.handleAddEmergencyContact.bind(this);
    this.handleAddPrimaryInsurance = this.handleAddPrimaryInsurance.bind(this);
    this.handleAddSecondaryInsurance = this.handleAddSecondaryInsurance.bind(this);
    this.handleAddPhysician = this.handleAddPhysician.bind(this);
    this.handleAddEmployer = this.handleAddEmployer.bind(this);
    this.handleAddClinics = this.handleAddClinics.bind(this);
  }

  componentWillMount() {
    this.props.fetchClinicsIfNecessary();
    this.props.browseUserClinics();
    this.props.browseEmployers();
    this.props.browsePatientProviders();
    this.props.browseInsurance()
      .catch((e) => {
        if (e.status === 404) {
          return this.props.addInsurance();
        }
      });
    this.props.browsePatientContactInfo();
  }

  handleAddEmergencyContact() {
    this.props.router.push('/emergencycontact');
  }

  handleAddPrimaryInsurance() {
    this.props.router.push('/insurance');
  }

  handleAddSecondaryInsurance() {
    this.props.router.push('/insurance?secondary=true');
  }

  handleAddPhysician() {
    logEvent('Add Physician');
    this.props.router.push('/npi-search');
  }

  handleAddEmployer() {
    this.props.router.push('/employer');
  }

  handleAddClinics() {
    logEvent('Add Clinic');
    this.props.router.push('/clinics');
  }

  handleReadClinicInfo() {
    this.props.router.push('/clinicinfo');
  }

  render() {
    const emergencyContact = get(this.props, 'patientContactInfo.data.emergency_contact', null);
    const primaryInsurance = get(this.props, 'insurance.data.primary_insurance_name', null);
    const secondaryInsurance = get(this.props, 'insurance.data.secondary_insurance_name', null);

    const primaryInsIcon = primaryInsurance
      ? <img alt="Insurance Icon" src="/img/InsuranceIcon.png" /> : <AddIcon />;
    const secondaryInsIcon = secondaryInsurance
      ? <img alt="Insurance Icon" src="/img/InsuranceIcon.png" /> : <AddIcon />;

    const primaryInsName = primaryInsurance ? `Primary (${primaryInsurance})` : 'Add Primary Insurance';
    const secondaryInsName = secondaryInsurance ? `Secondary (${secondaryInsurance})` : 'Add Secondary Insurance';
    const emergencyName = emergencyContact || 'Add an Emergency Contact';
    const emergencyIcon = emergencyContact ? <img alt="Contact Icon" src="/img/SilhouetteIcon.png" /> : <AddIcon />;


    const employers = map(this.props.employer.data, (e) => {
      return (
        <EmployerListItem
          item={e}
          key={e.id}
          router={this.props.router}
        />
      );
    });
    const physicians = map(this.props.patientProvider.data, (p) => {
      return (
        <PhysicianListItem
          item={p}
          key={p.id}
          router={this.props.router}
        />
      );
    });
    const clinics = map(this.props.patientClinics.data, (c) => {
      // get helps avoid error from race error (where clinic does not exist)
      const item = Object.assign({}, c, get(this, `props.clinics[${c.clinic_id}]`, {}));

      return (
        <ClinicListItem
          item={item}
          key={c.clinic_id}
          onEditClinic={this.props.editUserClinic}
          router={this.props.router}
        />
      );
    });
    return (
      <Layout>
        <div>
          <AppBar
            backButtonOnClick={() => this.props.router.goBack()}
            headerNode="About Me"
          />
          <Subtitle label="Insurance Details" />
          <section style={custom.whiteBackground}>
            <List>
              <ListItem
                button
                onClick={this.handleAddPrimaryInsurance}
                style={baseStyles.listItem}
                key="Add-Item"
              >
                <ListItemIcon>
                  {primaryInsIcon}
                </ListItemIcon>
                <ListItemText primary={primaryInsName} />
              </ListItem>
              { primaryInsurance ? (
                <ListItem
                  button
                  onClick={this.handleAddSecondaryInsurance}
                  style={baseStyles.listItem}
                  key="Secondary-Insurance-Add-Item"
                >
                  <ListItemIcon>
                    {secondaryInsIcon}
                  </ListItemIcon>
                  <ListItemText primary={secondaryInsName} />
                </ListItem>
              ) : null}
            </List>
          </section>
          <Subtitle label="Clinics" />
          <div style={baseStyles.toggleMsg}>
            *Toggle clinics off to disable sharing your data with selected clinics
          </div>
          <section style={custom.whiteBackground}>
            <List>
              <ListItem
                button
                onClick={this.handleAddClinics}
                style={baseStyles.listItem}
                key="Add-Item"
              >
                <ListItemIcon>
                  <AddIcon />
                </ListItemIcon>
                <ListItemText primary="Add Clinics" />
              </ListItem>
              {clinics}
            </List>
          </section>
          <Subtitle label="Physicians" />
          <section style={custom.whiteBackground}>
            <List>
              <ListItem
                button
                onClick={this.handleAddPhysician}
                style={baseStyles.listItem}
                key="Add-Item"
              >
                <ListItemIcon>
                  <AddIcon />
                </ListItemIcon>
                <ListItemText primary="Add Physician" />
              </ListItem>
              {physicians}
            </List>
          </section>
          <Subtitle label="Emergency Contact" />
          <section style={custom.whiteBackground}>
            <List>
              <ListItem
                button
                onClick={this.handleAddEmergencyContact}
                style={baseStyles.listItem}
                key="Add-Item"
              >
                <ListItemIcon>
                  {emergencyIcon}
                </ListItemIcon>
                <ListItemText primary={emergencyName} />
              </ListItem>
            </List>
          </section>
          <Subtitle label="Employment" />
          <section style={custom.whiteBackground}>
            <List>
              <ListItem
                button
                onClick={this.handleAddEmployer}
                style={baseStyles.listItem}
                key="Add-Item"
              >
                <ListItemIcon>
                  <AddIcon />
                </ListItemIcon>
                <ListItemText primary="Add Employer" />
              </ListItem>
              {employers}
            </List>
          </section>
          <section>
            <Continue
              onClick={() => {
                logEvent('About Me Info Updated (Finish)');
                this.forward();
              }}
              text="Update"
            />
          </section>
        </div>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  const { appData, employer, insurance, patientClinics, patientContactInfo, patientProvider } = state;
  return {
    clinics: appData.clinics,
    employer,
    insurance,
    patientClinics,
    patientContactInfo,
    patientProvider,
  };
}

export default connect(mapStateToProps, {
  fetchClinicsIfNecessary,
  browseEmployers,
  addInsurance,
  browseInsurance,
  browsePatientProviders,
  browsePatientContactInfo,
  browseUserClinics,
  editUserClinic,
})(PersonalInfo);
