
import React from 'react';
import PropTypes from 'prop-types';
import LinearProgress from '@material-ui/core/LinearProgress';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

import AppBar from '../components/app-bar';
import Page from './page';
import QuestionLayout from '../layouts/question';

import { updatePRO } from '../state/pro-forms';

import { colors, fontSizing } from '../lib/styles';

const styles = {
  bar: {
    backgroundColor: colors.lightBrightBlue,
  },
  vitalMeasurementContainer: {
    boxShadow: '0 0 8px lightgrey',
    display: 'flex',
    marginBottom: 10,
    marginTop: 10,
    marginLeft: 20,
    marginRight: 20,
    padding: 5,
    alignItems: 'flex-end',
  },
  vitalMeasurementTitleContainer: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: fontSizing.body,
    width: 150,
    marginLeft: 10,
  },
  contentTitle: {
    fontWeight: 'bold',
    textAlign: 'center',
    fontSize: fontSizing.h3,
  },
  vitalMeasurementValue: {
    fontSize: fontSizing.h1,
  },
  vitalMeasurementUnit: {
    fontSize: fontSizing.small,
    lineHeight: 1.6,
    marginLeft: 5,
  },
  vitalsRed: {
    color: '#cd3636',
  },
  mainContent: {
    margin: '0px 10px',
    flexGrow: 1,
    textAlign: 'center',
    fontSize: fontSizing.body,
  },
  greenCheckMark: {
    marginTop: '10rem',
    marginBottom: '8rem',
    width: '8rem',
    height: '8rem',
  },
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100%',
    justifyContent: 'space-between',
  },
  icon: {
    objectFit: 'contain',
    width: 25,
    height: 25,
  },
  iconWrapper: {
    alignItems: 'center',
    boxShadow: '0 0 8px lightgrey',
    borderRadius: '50%',
    display: 'flex',
    height: 50,
    justifyContent: 'center',
    width: 50,
  },
};

class HypertensionAssessment4 extends Page {
  handleClickNext = async () => {
    this.props.updatePRO({
      type: 'hypertensionAssessmentDaily',
      position: this.getTrackIndex(),
      value: {},
    });

    this.forwardWithQuery(this.props.location.query);
  }

  render() {
    const { classes, hypertensionAssessmentDaily } = this.props;

    const { systolic, diastolic } = hypertensionAssessmentDaily[2];

    return (
      <QuestionLayout
        primaryBtnLabel="Next"
        primaryBtnOnTouchTap={this.handleClickNext}
        style={{ backgroundColor: colors.questionBackground }}
      >
        <AppBar
          backButtonOnClick={() => this.props.router.goBack()}
          headerNode="Your vital signs today"
        />
        <LinearProgress
          variant="determinate"
          value={(this.getCurrentPage() / this.getTotalPages()) * 100}
          classes={{ bar: classes.bar }}
        />

        <div className={classes.contentWrapper}>
          <div className={classes.mainContent}>
            <img
              src="/img/GreenCheckmarkCircle.png"
              className={classes.greenCheckMark}
              alt="Green check mark"
            />
            <p className={classes.contentTitle}>Your blood pressure this session:</p>
          </div>
        </div>

        <Paper className={`${classes.vitalMeasurementContainer} ${classes.vitalsRed}`}>
          <Paper className={classes.iconWrapper}>
            <img
              className={classes.icon}
              src="/img/bloodpressure.png"
              alt=""
            />
          </Paper>
          <div className={classes.vitalMeasurementTitleContainer}>
            <div>Blood</div>
            <div>Pressure</div>
          </div>
          <div className={classes.vitalMeasurementValue}>{systolic}/{diastolic}</div>
          <div className={classes.vitalMeasurementUnit}>mm/Hg</div>
        </Paper>

      </QuestionLayout>
    );
  }
}

function mapStateToProps(state) {
  const {
    user,
    proForms: { hypertensionAssessmentDaily },
  } = state;
  return { user, hypertensionAssessmentDaily };
}

HypertensionAssessment4.proptypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  route: PropTypes.object.isRequired,
  updatePRO: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { updatePRO })(withStyles(styles)(HypertensionAssessment4));
