import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  TextField,
} from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { connect } from 'react-redux';

import Page from './page';
import AppBar from '../components/app-bar';
import IconWrapper from '../components/icon-wrapper';
import BloomkidzLayout from '../layouts/bloomkidz';
import { ChildReaching } from '../lib/icons';
import { fontSizing } from '../lib/styles';
import { updatePRO } from '../state/pro-forms';
import { bloomkidzStylesObj, bloomkidzStyles } from '../lib/bloomkidz';

const styles = {
  ...bloomkidzStylesObj,
  marginTop20: {
    marginTop: 20,
  },
};
const nameLabelMap = {
  handLeading: 'Hand Leading',
  gesturePointing: 'Gesture/Pointing',
  soundsVocalization: 'Sounds/Vocalization',
  singleWords: 'Single Words',
  shortPhrases: 'Short Phrases',
  communicationDeviceLowTech: 'Communication Device (low tech)',
  communicationDeviceTablet: 'Communication Device (tablet)',
};

class BloomkidzMedicalHistory10 extends Page {
  constructor(props) {
    super(props);

    this.state = {
      regression: '',
      regressionExplanation: '',
      primaryLanguage: '',
      handLeading: false,
      gesturePointing: false,
      soundsVocalization: false,
      singleWords: false,
      shortPhrases: false,
      communicationDeviceLowTech: false,
      communicationDeviceTablet: false,
      toiletTrained: '',
      toiletTrainedExplanation: '',
      ongoingConcerns: '',
    };
  }

  handleChangeConcerns = (e) => {
    this.setState({ [e.target.name]: e.target.checked });
  };

  handleContinue = () => {
    const { location, updatePRO } = this.props;

    updatePRO({
      type: 'bloomkidzMedicalHistory',
      position: this.getTrackIndex(),
      value: { ...this.state },
    });

    this.forwardWithQuery(location.query);
  }

  getContinueButtonText = () => {
    const { state } = this;

    const stateVals = Object.values(state);

    for (let i = 0; i < stateVals.length; i++) {
      if (stateVals[i] === true) return 'Next';
    }

    return 'Skip';
  }

  handleClickButton = (name, value) => {
    const explanation = {};
    if (value === 'no') {
      explanation[`${name}Explanation`] = '';
    }
    this.setState({ [name]: value, ...explanation });
  }

  handleTextFieldChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  shouldDisableContinueButton = () => {
    const {
      regression,
      regressionExplanation,
      primaryLanguage,
      toiletTrained,
      toiletTrainedExplanation,
      ongoingConcerns,
    } = this.state;

    return !regression
      || (regression === 'yes' && !regressionExplanation)
      || !primaryLanguage
      || !toiletTrained
      || (toiletTrained === 'yes' && !toiletTrainedExplanation)
      || !ongoingConcerns;
  }

  render() {
    const { classes, router } = this.props;
    const { state } = this;
    const {
      regression,
      regressionExplanation,
      primaryLanguage,
      toiletTrained,
      toiletTrainedExplanation,
      ongoingConcerns,
    } = state;

    const continueButtonDisabled = this.shouldDisableContinueButton();

    return (
      <BloomkidzLayout
        primaryBtnOnTouchTap={this.handleContinue}
        primaryBtnLabel={this.getContinueButtonText()}
        primaryBtnDisabled={continueButtonDisabled}
      >
        <AppBar
          headerNode="Growth"
          backButtonOnClick={() => router.goBack()}
        />
        <IconWrapper>
          <ChildReaching />
        </IconWrapper>
        <div className={classes.pageContent}>
          <div>
            Has your child ever experienced regression.
          </div>
          <div className={classes.buttonRow}>
            <button
              className={`${classes.button} ${regression === 'yes' ? classes.buttonSelected : classes.buttonUnselected}`}
              onClick={() => this.handleClickButton('regression', 'yes')}
              type="button"
            >
              Yes
            </button>
            <button
              className={`${classes.button} ${regression === 'no' ? classes.buttonSelected : classes.buttonUnselected}`}
              onClick={() => this.handleClickButton('regression', 'no')}
              type="button"
            >
              No
            </button>
          </div>
          {regression === 'yes' ? (
            <div className={classes.textFieldWrapper}>
              <div>Please explain:</div>
              <TextField
                placeholder="Tap here to type"
                value={regressionExplanation}
                onChange={this.handleTextFieldChange}
                name="regressionExplanation"
                variant="standard"
                fullWidth
                inputProps={{ style: { fontSize: fontSizing.body, fontFamily: bloomkidzStyles.fontFamily } }}
              />
            </div>
          ) : null}
          <div className={classes.marginTop20}>What is your child&apos;s primary language?</div>
          <div className={classes.paddingHorizontal}>
            <div className={classes.textFieldWrapper}>
              <TextField
                placeholder="Tap here to type"
                value={primaryLanguage}
                onChange={this.handleTextFieldChange}
                name="primaryLanguage"
                variant="standard"
                fullWidth
                inputProps={{ style: { fontSize: fontSizing.body, fontFamily: bloomkidzStyles.fontFamily } }}
              />
            </div>
          </div>
          <div className={classes.marginTop20}>What is your child&apos;s primary mode of communication?</div>
          <div className={classes.subtext}><i>Check all that apply.</i></div>
          <FormGroup>
            {Object.entries(nameLabelMap).map(([name, label]) => (
              <>
                <FormControlLabel
                  control={(
                    <Checkbox
                      checked={state[name]}
                      onChange={this.handleChangeConcerns}
                      name={name}
                      icon={<CheckBoxOutlineBlankIcon fontSize="large" />}
                      checkedIcon={<CheckBoxIcon fontSize="large" />}
                      className={classes.checkbox}
                    />
                  )}
                  label={label}
                  classes={{
                    label: classes.checkboxLabel,
                    root: classes.formControlLabel,
                  }}
                />
              </>
            ))}
          </FormGroup>
          <div className={classes.marginTop20}>
            Is your child toilet trained?
          </div>
          <div className={classes.buttonRow}>
            <button
              className={`${classes.button} ${toiletTrained === 'yes' ? classes.buttonSelected : classes.buttonUnselected}`}
              onClick={() => this.handleClickButton('toiletTrained', 'yes')}
              type="button"
            >
              Yes
            </button>
            <button
              className={`${classes.button} ${toiletTrained === 'no' ? classes.buttonSelected : classes.buttonUnselected}`}
              onClick={() => this.handleClickButton('toiletTrained', 'no')}
              type="button"
            >
              No
            </button>
          </div>
          {toiletTrained === 'yes' ? (
            <div className={classes.textFieldWrapper}>
              <div>When were they trained?</div>
              <TextField
                placeholder="Tap here to type"
                value={toiletTrainedExplanation}
                onChange={this.handleTextFieldChange}
                name="toiletTrainedExplanation"
                variant="standard"
                fullWidth
                inputProps={{ style: { fontSize: fontSizing.body, fontFamily: bloomkidzStyles.fontFamily } }}
              />
            </div>
          ) : null}
          <div className={classes.marginTop20}>Do you have any ongoing toileting concerns?</div>
          <div className={classes.paddingHorizontal}>
            <div className={classes.textFieldWrapper}>
              <TextField
                placeholder="Tap here to type"
                value={ongoingConcerns}
                onChange={this.handleTextFieldChange}
                name="ongoingConcerns"
                variant="standard"
                fullWidth
                inputProps={{ style: { fontSize: fontSizing.body, fontFamily: bloomkidzStyles.fontFamily } }}
              />
            </div>
          </div>
        </div>
      </BloomkidzLayout>
    );
  }
}

BloomkidzMedicalHistory10.propTypes = {
  bloomkidzClientWellnessProfile: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  updatePRO: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const {
    proForms: { bloomkidzClientWellnessProfile },
    user,
  } = state;

  return { user, bloomkidzClientWellnessProfile };
};

export default connect(mapStateToProps, { updatePRO })(withStyles(styles)(BloomkidzMedicalHistory10));
