import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get } from 'lodash';

import { colors } from '../lib/styles';
import Page from './page';
import { PersonalCare } from '../lib/icons';
import Question from '../components/question';
import { updatePRO } from '../state/pro-forms';

const options = [
  {
    label: 'I can look after myself normally without causing extra pain',
    value: 0,
  },
  {
    label: 'I can look after myself normally but it causes extra pain',
    value: 1,
  },
  {
    label: 'It is painful to look after myself and I am slow and careful',
    value: 2,
  },
  {
    label: 'I need some help but manage most of my personal care',
    value: 3,
  },
  {
    label: 'I need help every day in most aspects of self-care',
    value: 4,
  },
  {
    label: 'I do not get dressed, I wash with difficulty and stay in bed',
    value: 5,
  },
];
const { questionBackground } = colors;
class NdiPersonalCare extends Page {
  onClickBackButton = () => {
    this.backward();
  }

  onSubmission = () => {
    this.forwardWithQuery(this.props.location.query);
  }

  render() {
    const value = get(this.props.ndi, this.getTrackIndex(), null);
    return (
      <Question
        backgroundColor={questionBackground}
        currentPage={this.getCurrentPage()}
        image={PersonalCare}
        onClickBackButton={() => this.props.router.goBack()}
        onChange={this.props.updatePRO}
        onSubmission={this.onSubmission}
        options={options}
        title="Personal Care"
        totalPages={this.getTotalPages()}
        trackIndex={this.getTrackIndex()}
        type="ndi"
        value={value}
      />
    );
  }
}


function mapStateToProps(state) {
  const { proForms: { ndi } } = state;
  return { ndi };
}

NdiPersonalCare.propTypes = {
  ndi: PropTypes.array.isRequired,
  updatePRO: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { updatePRO })(NdiPersonalCare);
