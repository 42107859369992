export function drawNumber(ctx, countdown, pvW, pvH, fontSize, inverted = false) {
    ctx.fillStyle = 'red';
    ctx.font = `${fontSize}px serif`;
    ctx.save();
    // translate context to center of canvas
    ctx.translate(pvW / 2, pvH / 2);
    // flip context horizontally
    ctx.scale(inverted ? 1 : -1, 1);
    ctx.fillText(countdown, -(fontSize / 4), -(fontSize / 2) + fontSize);
    ctx.restore();
}


export function drawReticle(ctx, pvW, pvH, length = 30) {
    ctx.setLineDash([]);
    ctx.moveTo(((pvW / 4) + 50) + (((pvW / 2) - 100) / 2) - (length / 2), ((pvH / 4) + 50) + (((pvH / 2) - 100) / 2));
    ctx.lineTo(((pvW / 4) + 50) + (((pvW / 2) - 100) / 2) + (length / 2), ((pvH / 4) + 50) + (((pvH / 2) - 100) / 2));
    ctx.moveTo(((pvW / 4) + 50) + (((pvW / 2) - 100) / 2), ((pvH / 4) + 50) + (((pvH / 2) - 100) / 2) - (length / 2));
    ctx.lineTo(((pvW / 4) + 50) + (((pvW / 2) - 100) / 2), ((pvH / 4) + 50) + (((pvH / 2) - 100) / 2) + (length / 2));
    ctx.stroke();
}

export function drawFaceBox(ctx, pvW, pvH, style) {
    ctx.strokeStyle = style;
    ctx.lineWidth = 8;
    ctx.setLineDash([15, 15]);
    ctx.strokeRect((pvW / 4) + 50, (pvH / 4) + 50, (pvW / 2) - 100, (pvH / 2) - 100);

}