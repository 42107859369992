/* eslint-disable react/require-default-props */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { assign, isNumber, map, forEach, isNull } from 'lodash';

import { colors } from '../lib/styles';

const baseStyles = {
  button: {
    display: 'flex',
    justifyContent: 'center',
    flexGrow: 1,
    backgroundColor: colors.primaryColor,
    color: colors.white,
    marginRight: '5px',
    minWidth: '50px',
    height: '40px',
  },
  center: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: '12px',
  },
};

class ButtonSelectGroup extends Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.buildButtons = this.buildButtons.bind(this);
    const currentValue = this.props.pro[this.props.groupIndex];
    this.state = {
      buttonHighlight: map(this.props.options.label, (label, index) => {
        if (!isNull(currentValue) && currentValue === index) {
          if (props.buttonStyles && props.buttonStyles.selected) {
            return props.buttonStyles.selected;
          }

          return { backgroundColor: colors.primaryColor };
        }

        if (props.buttonStyles && props.buttonStyles.unselected) {
          return props.buttonStyles.unselected;
        }

        return { backgroundColor: colors.darkGrayText };
      }),
    };
  }

  handleChange = (label, val, highlightIndex) => {
    // eslint-disable-next-line react/no-access-state-in-setstate
    const { buttonStyles } = this.props;
    const highlightVal = isNumber(highlightIndex) ? highlightIndex : val;
    const buttonHighlight = map(this.state.buttonHighlight, (button, index) => {
      if (index === highlightVal) {
        if (buttonStyles && buttonStyles.selected) {
          return buttonStyles.selected;
        }

        return { backgroundColor: colors.primaryColor };
      }

      if (buttonStyles && buttonStyles.unselected) {
        return buttonStyles.unselected;
      }

      return { backgroundColor: colors.darkGrayText };
    });
    this.setState({ buttonHighlight });
    const value = this.props.pro;
    value[this.props.groupIndex] = val;
    this.props.onChange({
      type: this.props.type,
      position: this.props.trackIndex,
      value,
    });
  };

  buildButtons(buttonStyles) {
    const buttons = [];
    const listLength = this.props.options.label.length - 1;
    const baseButtonStyles = buttonStyles && buttonStyles.base ? buttonStyles.base : baseStyles.button;
    forEach(this.props.options.label, (label, index) => {
      if (this.props.options.reverseValues) {
        const value = listLength - index;
        buttons.push(
          <Button
            key={value}
            value={value}
            style={assign({}, baseButtonStyles, this.state.buttonHighlight[index])}
            onClick={() => this.handleChange(label, value, index)}
          >
            {label}
          </Button>,
        );
      } else {
        buttons.push(
          <Button
            key={index}
            value={index}
            style={assign({}, baseButtonStyles, this.state.buttonHighlight[index])}
            onClick={() => this.handleChange(label, index)}
          >
            {label}
          </Button>,
        );
      }
    });
    return buttons;
  }

  render() {
    const { buttonStyles, options } = this.props;
    const buttons = this.buildButtons(buttonStyles);
    const buttonRowStyles = buttonStyles && buttonStyles.buttonRow ? buttonStyles.buttonRow : baseStyles.center;
    const questionStyles = buttonStyles && buttonStyles.buttonRowQuestion ? buttonStyles.buttonRowQuestion : {};
    return (
      <div style={baseStyles.container}>
        <p style={questionStyles}>{options.question}</p>
        <div style={buttonRowStyles}>
          {buttons}
        </div>
      </div>
    );
  }
}

ButtonSelectGroup.defaultProps = {
  buttonStyles: {
    base: null,
    selected: null,
    unselected: null,
    buttonRow: null,
    buttonRowQuestion: null,
  },
};

ButtonSelectGroup.propTypes = {
  onChange: PropTypes.func,
  buttonStyles: PropTypes.object,
};

export default ButtonSelectGroup;
