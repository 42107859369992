import React from 'react';
import { LinearProgress, TextField } from '@material-ui/core';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import AppBar from '../components/app-bar';
import Page from './page';
import QuestionLayout from '../layouts/question';
import YesNoButtonSet from '../components/yes-no-button-set';
import { colors } from '../lib/styles';
import { updatePRO } from '../state/pro-forms';
import { WineGlass } from '../lib/icons';

const styles = {
  contentWrapper: {
    flexGrow: 1,
    overflowX: 'hidden',
    position: 'relative',
  },
  imgRow: {
    display: 'flex',
    justifyContent: 'center',
    padding: '15px 60px',
  },
  imageWrapper: {
    width: 100,
  },
  inputField: {
    width: '6rem',
    '& input': {
      textAlign: 'center',
    },
  },
  marginTop40: {
    marginTop: 40,
  },
  questionnaireContainer: {
    paddingLeft: 20,
    paddingRight: 20,
  },
  questionText: {
    fontSize: '2rem',
  },
  textFieldContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  textFieldWrapper: {
    alignItems: 'center',
    display: 'flex',
    paddingTop: 20,
  },
  unitsWrapper: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    marginLeft: 5,
  },
};

class HypertensionAssessment9 extends Page {
  constructor(props) {
    super(props);

    this.state = {
      drinks: null,
      numOfDrinks: '',
    };
  }

  handleChangeDrinks = (value) => {
    this.setState({ drinks: value });
  };

  handleChangeNumOfDrinks = (e) => {
    this.setState({ numOfDrinks: e.target.value });
  };

  handleClickNext = () => {
    const {
      drinks,
      numOfDrinks,
    } = this.state;

    const value = { drinks };

    if (drinks === 'Y') value.numOfDrinks = numOfDrinks;

    this.props.updatePRO({
      type: 'hypertensionAssessment',
      position: this.getTrackIndex(),
      value,
    });

    this.forwardWithQuery(this.props.location.query);
  }

  render() {
    const { classes } = this.props;
    const {
      drinks,
      numOfDrinks,
    } = this.state;

    const continueDisabled = drinks === null
      || (drinks === 'Y' && numOfDrinks === '');

    return (
      <QuestionLayout
        primaryBtnLabel="Next"
        primaryBtnOnTouchTap={this.handleClickNext}
        primaryBtnDisabled={continueDisabled}
        style={{ backgroundColor: colors.questionBackground }}
      >
        <AppBar
          headerNode="Fluid Intake"
          backButtonOnClick={() => this.props.router.goBack()}
        />
        <LinearProgress
          variant="determinate"
          value={(this.getCurrentPage() / this.getTotalPages()) * 100}
          classes={{ bar: classes.bar }}
        />
        <div className={classes.contentWrapper}>
          <div className={classes.imgRow}>
            <div className={classes.imageWrapper}>
              <WineGlass />
            </div>
          </div>
          <div className={classes.questionnaireContainer}>
            <YesNoButtonSet
              isYes={drinks === 'Y'}
              isNo={drinks === 'N'}
              onClickNo={() => this.handleChangeDrinks('N')}
              onClickYes={() => this.handleChangeDrinks('Y')}
              questionText="Do you drink alcohol?"
            />
            {drinks === 'Y' ? (
              <>
                <div className={`${classes.questionText} ${classes.marginTop40}`}>How many drinks per week?</div>
                <div className={classes.textFieldContainer}>
                  <div className={classes.textFieldWrapper}>
                    <TextField
                      className={classes.inputField}
                      onChange={this.handleChangeNumOfDrinks}
                      type="number"
                      inputProps={{
                        inputMode: 'decimal',
                        pattern: '[0-9]*',
                        type: 'tel',
                        style: { fontSize: '16px' } }}
                      value={numOfDrinks}
                      placeholder="0"
                    />
                    <span className={classes.unitsWrapper}>drinks</span>
                  </div>
                </div>
              </>
            ) : null}
          </div>
        </div>
      </QuestionLayout>
    );
  }
}

export default connect(null, { updatePRO })(withStyles(styles)(HypertensionAssessment9));
