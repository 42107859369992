import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { assign, get, noop, toLower } from 'lodash';

import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

import { colors } from '../lib/styles';

const painHeads = ['FRONT', 'RIGHT', 'BACK', 'LEFT'];
const baseStyles = {
  btnContainer: {
    display: 'flex',
    flexDirection: 'row',
    position: 'absolute',
    top: '170px',
  },
  bigArrows: {
    width: 60,
    height: 100,
    color: colors.highlightDark,
  },
  container: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    minWidth: '200px',
  },
  rightIcon: {
    marginLeft: '100px',
  },
  location: {
    TOP: {
      left: '82px',
      top: '15px',
    },
    LEFT: {
      left: '45px',
      top: '35px',
    },
    MIDDLE: {
      left: '82px',
      top: '35px',
    },
    RIGHT: {
      left: '120px',
      top: '35px',
    },
    BOTTOM: {
      left: '82px',
      top: '70px',
    },
    NECK: {
      left: '82px',
      top: '135px',
    },
  },
  painIndicator: {
    backgroundColor: colors.errorRed,
    borderRadius: '50%',
    height: '18px',
    position: 'absolute',
    width: '18px',
  },
};

class PatientPainHead extends Component {
  constructor(props) {
    super(props);

    const currentHead = get(props, 'pain[0].brain_quadrant', 'FRONT');
    this.state = { currentHead };
    this.nextHead = this.nextHead.bind(this);
    this.prevHead = this.prevHead.bind(this);
    this.handlePainClick = this.handlePainClick.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    const { currentView } = this.props;
    const pain = get(nextProps, 'pain[0]', null);
    if (pain && pain.brain_quadrant) {
      this.setState({
        currentHead: pain.brain_quadrant,
      });
    }

    if (nextProps.currentView !== currentView) {
      this.setState({ currentHead: nextProps.currentView });
    }
  }

  nextHead() {
    const { currentHead } = this.state;
    const idx = painHeads.indexOf(currentHead);
    this.setState({
      currentHead: painHeads[(idx + 1) % 4],
    });
  }

  prevHead() {
    const { currentHead } = this.state;
    const idx = painHeads.indexOf(currentHead);
    let newIndex = idx - 1;
    if (newIndex < 0) {
      newIndex = 3;
    }
    this.setState({
      currentHead: painHeads[newIndex],
    });
  }

  handlePainClick(e) {
    let x = get(e, 'nativeEvent.offsetX', null);
    let y = get(e, 'nativeEvent.offsetY', null);
    // Not entirely sure about using changedTouches here
    // SO seems to think we should be using targetTouches[0] or touches[0]
    // However those are empty at the point this is fired so.. ¯\_(ツ)_/¯
    if (e.nativeEvent.changedTouches && e.nativeEvent.changedTouches.length) {
      x = e.nativeEvent.changedTouches[0].pageX - e.nativeEvent.changedTouches[0].target.offsetLeft;
      y = e.nativeEvent.changedTouches[0].pageY - e.nativeEvent.changedTouches[0].target.offsetTop;
    }
    this.props.onPainSelect(x, y, this.state.currentHead);
  }

  render() {
    const viewLocked = this.props.lockView;
    const { currentHead } = this.state;
    const pain = this.props.pain[0] || {};
    const { brain_locations, brain_quadrant } = pain;
    const hideDot = (brain_quadrant !== currentHead) || !brain_locations;

    let { sex } = this.props;
    sex = toLower(sex) === 'female' ? 'female' : 'male';

    const painIndicatorStyle = Object.assign({
      display: hideDot ? 'none' : 'inline',
    }, baseStyles.location[brain_locations], baseStyles.painIndicator);
    const containerStyle = assign({}, baseStyles.container, this.props.style);
    return (
      <div style={containerStyle}>
        {viewLocked ? null : <KeyboardArrowLeftIcon onClick={this.prevHead} style={baseStyles.bigArrows} />}
        <div>
          <div onClick={this.handlePainClick} style={{ height: '180px', position: 'absolute', width: '180px' }}>
            <div style={painIndicatorStyle} />
          </div>
          <img
            alt="Pain Head"
            src={`/img/${sex}-${this.state.currentHead}.png`}
            onClick={this.handlePainClick}
            height="180px"
            width="180px"
          />
        </div>
        {viewLocked ? null : <KeyboardArrowRightIcon onClick={this.nextHead} style={baseStyles.bigArrows} />}
      </div>
    );
  }
}

PatientPainHead.defaultProps = {
  currentView: 'FRONT',
  lockView: false,
  onPainSelect: noop,
  pain: [],
  sex: 'male', // Male front facing image is very androgynous
  style: {},
};

PatientPainHead.propTypes = {
  currentView: PropTypes.string,
  lockView: PropTypes.bool,
  onPainSelect: PropTypes.func,
  pain: PropTypes.array,
  sex: PropTypes.string,
  style: PropTypes.object,
};

export default PatientPainHead;
