import React from 'react';
import { LinearProgress, TextField } from '@material-ui/core';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import AppBar from '../components/app-bar';
import Page from './page';
import QuestionLayout from '../layouts/question';
import { colors } from '../lib/styles';
import { updatePRO } from '../state/pro-forms';
import { CoffeeSolid } from '../lib/icons';
import YesNoButtonSet from '../components/yes-no-button-set';

const styles = {
  contentWrapper: {
    flexGrow: 1,
    overflowX: 'hidden',
    position: 'relative',
  },
  imgRow: {
    display: 'flex',
    justifyContent: 'center',
    padding: '15px 60px',
  },
  imageWrapper: {
    width: 100,
  },
  inputField: {
    width: '6rem',
    '& input': {
      textAlign: 'center',
    },
  },
  marginTop40: {
    marginTop: 40,
  },
  questionnaireContainer: {
    paddingLeft: 20,
    paddingRight: 20,
  },
  questionText: {
    fontSize: '2rem',
  },
  textFieldContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  textFieldWrapper: {
    alignItems: 'center',
    display: 'flex',
    paddingTop: 20,
  },
  unitsWrapper: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    marginLeft: 5,
  },
};

class HeartFailureInitial8 extends Page {
  constructor(props) {
    super(props);

    this.state = {
      fluidRestriction: null,
      followingFluidPlan: null,
      ounces: '',
    };
  }

  handleChangeFluidRestriction = (value) => {
    this.setState({ fluidRestriction: value });
  }

  handleChangeFollowingFluidPlan = (value) => {
    this.setState({ followingFluidPlan: value });
  }

  handleChangeOunces = (e) => {
    this.setState({ ounces: e.target.value });
  };

  handleClickNext = () => {
    const {
      fluidRestriction,
      followingFluidPlan,
      ounces,
    } = this.state;

    const value = { fluidRestriction };

    if (fluidRestriction === 'Y') {
      value.followingPlan = followingFluidPlan;
      value.ounces = ounces;
    }

    this.props.updatePRO({
      type: 'heartFailureInitial',
      position: this.getTrackIndex(),
      value,
    });

    this.forwardWithQuery(this.props.location.query);
  }

  render() {
    const { classes } = this.props;
    const {
      fluidRestriction,
      followingFluidPlan,
      ounces,
    } = this.state;

    const continueDisabled = fluidRestriction === null
      || (fluidRestriction === 'Y' && !ounces);

    return (
      <QuestionLayout
        primaryBtnLabel="Next"
        primaryBtnOnTouchTap={this.handleClickNext}
        primaryBtnDisabled={continueDisabled}
        style={{ backgroundColor: colors.questionBackground }}
      >
        <AppBar
          headerNode="Fluid Intake"
          backButtonOnClick={() => this.props.router.goBack()}
        />
        <LinearProgress
          variant="determinate"
          value={(this.getCurrentPage() / this.getTotalPages()) * 100}
          classes={{ bar: classes.bar }}
        />
        <div className={classes.contentWrapper}>
          <div className={classes.imgRow}>
            <div className={classes.imageWrapper}>
              <CoffeeSolid />
            </div>
          </div>
          <div className={classes.questionnaireContainer}>
            <YesNoButtonSet
              isYes={fluidRestriction === 'Y'}
              isNo={fluidRestriction === 'N'}
              onClickNo={() => this.handleChangeFluidRestriction('N')}
              onClickYes={() => this.handleChangeFluidRestriction('Y')}
              questionText="Are you on a fluid intake restriction?"
              questionSubText="(1.5 - 2.0L of fluid)"
            />
            {fluidRestriction === 'Y' ? (
              <>
                <YesNoButtonSet
                  isYes={followingFluidPlan === 'Y'}
                  isNo={followingFluidPlan === 'N'}
                  onClickNo={() => this.handleChangeFollowingFluidPlan('N')}
                  onClickYes={() => this.handleChangeFollowingFluidPlan('Y')}
                  questionText="Are you following the plan?"
                />
                <div className={`${classes.questionText} ${classes.marginTop40}`}>How many ounces are you restricted to?</div>
                <div className={classes.textFieldContainer}>
                  <div className={classes.textFieldWrapper}>
                    <TextField
                      className={classes.inputField}
                      onChange={this.handleChangeOunces}
                      type="number"
                      inputProps={{
                        inputMode: 'decimal',
                        pattern: '[0-9]*',
                        type: 'tel',
                        style: { fontSize: '16px' } }}
                      value={ounces}
                      placeholder="0"
                    />
                    <span className={classes.unitsWrapper}>fluid ounces</span>
                  </div>
                </div>
              </>
            ) : null}
          </div>
        </div>
      </QuestionLayout>
    );
  }
}

export default connect(null, { updatePRO })(withStyles(styles)(HeartFailureInitial8));
