import React, { Component } from 'react';
import PropTypes from 'prop-types';

import BottomNavigationSection from './bottom-navigation-section';
import icons from './icons';
import { mainDashboard } from '../lib/styles';
import { logEvent } from '../lib/amplitude';

const { DashboardIcon, PainEntryIcon, InformedIcon } = icons;
const styles = {
  container: {
    position: 'fixed',
    bottom: 0,
    width: '100%',
  },
};

class BottomNavigation extends Component {
  constructor(props) {
    super(props);

    this.onTouchDashboard = this.onTouchDashboard.bind(this);
    this.onTouchPainHistory = this.onTouchPainHistory.bind(this);
    this.onTouchPainEntry = this.onTouchPainEntry.bind(this);
    this.onTouchSupport = this.onTouchSupport.bind(this);
  }

  onTouchDashboard() {
    logEvent('Dashboard - Report Cloud');
    this.props.router.push('/');
  }

  onTouchPainHistory() {
    logEvent('Dashboard - Form Icon');
    this.props.router.push('/pain-history');
  }

  onTouchPainEntry() {
    logEvent('Dashboard - Pain Tracker Icon');
    this.props.router.push('/pain');
  }

  onTouchSupport() {
    logEvent('Dashboard - Settings (wheel icon)');
    this.props.router.push('/the-better-way-back-overview');
  }

  render() {
    const { highlightedIcon } = this.props;

    return (
      <div style={styles.container}>
        <footer style={mainDashboard.footer}>
          <BottomNavigationSection
            active={highlightedIcon === 'informed'}
            icon={InformedIcon}
            label="Pain History"
            onClick={this.onTouchPainHistory}
          />
          <BottomNavigationSection
            active={highlightedIcon === 'dashboard'}
            icon={DashboardIcon}
            label="Dashboard"
            onClick={this.onTouchDashboard}
          />
          <BottomNavigationSection
            active={highlightedIcon === 'painEntry'}
            icon={PainEntryIcon}
            label="Pain Entry"
            onClick={this.onTouchPainEntry}
          />
        </footer>
      </div>
    );
  }
}

BottomNavigation.propTypes = {
  highlightedIcon: PropTypes.oneOf(['dashboard', 'painEntry', 'informed', 'none']).isRequired,
  router: PropTypes.object.isRequired,
};

export default BottomNavigation;
