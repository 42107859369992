import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  TextareaAutosize,
} from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { connect } from 'react-redux';

import Page from './page';
import AppBar from '../components/app-bar';
import IconWrapper from '../components/icon-wrapper';
import BloomkidzLayout from '../layouts/bloomkidz';
import { UserGroup } from '../lib/icons';
import { updatePRO } from '../state/pro-forms';
import { bloomkidzStylesObj } from '../lib/bloomkidz';

const styles = bloomkidzStylesObj;

const nameLabelMap = {
  learningDifficultiesFamily: 'Learning Difficulties',
  alcoholismFamily: 'Alcoholism',
  ocdFamily: 'OCD',
  adhdFamily: 'ADHD',
  seizuresFamily: 'Seizures',
  drugAbuseFamily: 'Drug Abuse',
  personalityDisorderFamily: 'Personality Disorder',
  autismSpectrumDisorderFamily: 'Autism Spectrum Disorder',
  depressionFamily: 'Depression',
  anxietyFamily: 'Anxiety',
  mthfrGeneMutationFamily: 'MTHFR Gene Mutation',
};

class BloomkidzMedicalHistory11 extends Page {
  constructor(props) {
    super(props);

    this.state = {
      learningDifficultiesFamily: false,
      alcoholismFamily: false,
      ocdFamily: false,
      adhdFamily: false,
      seizuresFamily: false,
      drugAbuseFamily: false,
      personalityDisorderFamily: false,
      autismSpectrumDisorderFamily: false,
      depressionFamily: false,
      anxietyFamily: false,
      mthfrGeneMutationFamily: false,
      familyHistoryElaboration: '',
    };
  }

  handleChangeConcerns = (e) => {
    this.setState({ [e.target.name]: e.target.checked });
  };

  handleContinue = () => {
    const { location, updatePRO } = this.props;

    updatePRO({
      type: 'bloomkidzMedicalHistory',
      position: this.getTrackIndex(),
      value: { ...this.state },
    });

    this.forwardWithQuery(location.query);
  }

  getContinueButtonText = () => {
    const { state } = this;

    const stateVals = Object.values(state);

    for (let i = 0; i < stateVals.length; i++) {
      if (stateVals[i] === true) return 'Next';
    }

    return 'Skip';
  }

  handleTextFieldChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  render() {
    const { classes, router } = this.props;
    const { state } = this;
    const { familyHistoryElaboration } = state;
    const continueButtonText = this.getContinueButtonText();

    return (
      <BloomkidzLayout
        primaryBtnOnTouchTap={this.handleContinue}
        primaryBtnLabel={continueButtonText}
        primaryBtnDisabled={continueButtonText === 'Next' && !familyHistoryElaboration}
      >
        <AppBar
          headerNode="Family Medical History"
          backButtonOnClick={() => router.goBack()}
        />
        <IconWrapper>
          <UserGroup />
        </IconWrapper>
        <div className={classes.pageContent}>
          <div>Do family members have any of the following?</div>
          <div className={classes.subtext}><i>Check all that apply.</i></div>
          <FormGroup>
            {Object.entries(nameLabelMap).map(([name, label]) => (
              <>
                <FormControlLabel
                  control={(
                    <Checkbox
                      checked={state[name]}
                      onChange={this.handleChangeConcerns}
                      name={name}
                      icon={<CheckBoxOutlineBlankIcon fontSize="large" />}
                      checkedIcon={<CheckBoxIcon fontSize="large" />}
                      className={classes.checkbox}
                    />
                  )}
                  label={label}
                  classes={{
                    label: classes.checkboxLabel,
                    root: classes.formControlLabel,
                  }}
                />
              </>
            ))}
          </FormGroup>
          {continueButtonText === 'Next' ? (
            <div className={classes.textFieldWrapper}>
              <div>Please elaborate on any checked boxes:</div>
              <TextareaAutosize
                value={familyHistoryElaboration}
                onChange={this.handleTextFieldChange}
                minRows={10}
                className={classes.textAreaAutosize}
                placeholder="Tap here to type"
                name="familyHistoryElaboration"
              />
            </div>
          ) : null}
        </div>
      </BloomkidzLayout>
    );
  }
}

BloomkidzMedicalHistory11.propTypes = {
  bloomkidzClientWellnessProfile: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  updatePRO: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const {
    proForms: { bloomkidzClientWellnessProfile },
    user,
  } = state;

  return { user, bloomkidzClientWellnessProfile };
};

export default connect(mapStateToProps, { updatePRO })(withStyles(styles)(BloomkidzMedicalHistory11));
