import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import TextField from '@material-ui/core/TextField';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import AppBar from '../components/app-bar';
import Page from './page';
import QuestionLayout from '../layouts/question';
import YesNoButtonSet from '../components/yes-no-button-set';
import { colors } from '../lib/styles';
import { submitPRO, updatePRO } from '../state/pro-forms';
import { ThermometerHalfFull } from '../lib/icons';

const styles = {
  answerButtonContainer: {
    display: 'flex',
    marginLeft: -10,
    marginTop: 8,
  },
  bar: {
    background: colors.lightBrightBlue,
  },
  contentWrapper: {
    flexGrow: 1,
    overflowX: 'hidden',
    position: 'relative',
  },
  imageWrapper: {
    height: 100,
    padding: '15px 60px',
    textAlign: 'center',
  },
  instructions: {
    color: 'grey',
    fontSize: '1.5rem',
    margin: '20px 40px 0px 40px',
    textAlign: 'center',
  },
  questionnaireContainer: {
    paddingLeft: 20,
    paddingRight: 20,
  },
  breathingText: {
    fontSize: '2rem',
    fontWeight: 'bold',
  },
  questionText: {
    fontSize: '2rem',
    marginTop: 25,
  },
  continueButtonWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 80,
  },
  inputField: {
    width: '6rem',
  },
  temperatureWrapper: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: 20,
  },
  fWrapper: {
    fontSize: '1.25rem',
    fontWeight: 'bold',
    marginLeft: 5,
  },
  leaveBlankText: {
    fontSize: '1.25rem',
    fontStyle: 'italic',
    marginTop: 10,
  },
  temperatureContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
};

class ThoracicCancerSymptoms9 extends Page {
  constructor(props) {
    super(props);

    this.state = {
      hadFever: null,
      temperature: null,
      shakyChills: null,
    };
  }

  handleChangeHadFever = (selected) => {
    this.setState({ hadFever: selected });
  };

  handleChangeTemperature = (e) => {
    this.setState({ temperature: e.target.value });
  };

  handleChangeShakyChills = (selected) => {
    this.setState({ shakyChills: selected });
  }

  handleClickNext = () => {
    const {
      location,
      submitPRO,
      thoracicCancerSymptoms,
      updatePRO,
      user,
    } = this.props;
    const { hadFever, temperature, shakyChills } = this.state;
    const { id } = location.query;

    const value = {
      hadFever,
      shakyChills,
    };

    let lowOxygen = false;
    let cantControlBody = false;
    let highTemp = false;

    const { oxygenSaturationPercent } = thoracicCancerSymptoms[2];
    const { falls, newWeakness } = thoracicCancerSymptoms[6];
    const { cantControl } = thoracicCancerSymptoms[7];

    if (oxygenSaturationPercent) {
      const oxygenSatPrctValue = parseFloat(oxygenSaturationPercent, 10);
      if (oxygenSatPrctValue <= 90) lowOxygen = true;
    }

    if (newWeakness || falls || cantControl) cantControlBody = true;

    if (parseFloat(temperature, 10) >= 100.4) highTemp = true;

    if (temperature) value.temperature = temperature;

    submitPRO(
      { userId: user.id, id },
      {
        pro_data: { data: [...thoracicCancerSymptoms, value] },
        pro_type: 'THORACIC-CANCER-SYMPTOMS',
      },
      user,
    )
      .then(() => {
        if (!lowOxygen && !cantControlBody && !highTemp) {
          this.finishTrack();
        } else {
          updatePRO({
            type: 'thoracicCancerSymptoms',
            position: this.getTrackIndex(),
            value: {
              lowOxygen,
              cantControlBody,
              highTemp,
              newWeakness,
              falls,
              cantControlBowelsBladder: cantControl,
            },
          });
          this.forwardWithQuery(this.props.location.query);
        }
      });
  };

  render() {
    const { classes } = this.props;
    const { hadFever, temperature, shakyChills } = this.state;

    const continueDisabled = hadFever === null || shakyChills === null;

    return (
      <QuestionLayout
        primaryBtnDisabled={continueDisabled}
        primaryBtnLabel="Next"
        primaryBtnOnTouchTap={this.handleClickNext}
        style={{ backgroundColor: colors.questionBackground }}
      >
        <AppBar
          headerNode="Fever"
          backButtonOnClick={() => this.props.router.goBack()}
        />
        <LinearProgress
          variant="determinate"
          value={(this.getCurrentPage() / this.getTotalPages()) * 100}
          classes={{ bar: this.props.classes.bar }}
        />
        <div className={classes.contentWrapper}>
          <div className={classes.instructions}>
            Please select the option that best answers the question relating to fever
          </div>
          <div className={classes.imageWrapper}>
            <ThermometerHalfFull />
          </div>
          <div className={classes.questionnaireContainer}>
            <div className={classes.breathingText}>In the last 12 hours...</div>
            <YesNoButtonSet
              isNo={hadFever === false}
              isYes={hadFever}
              onClickNo={() => this.handleChangeHadFever(false)}
              onClickYes={() => this.handleChangeHadFever(true)}
              questionText="Have you had a fever (temperature greater than 100.4)?"
            />
            {hadFever ? (
              <>
                <div className={classes.questionText}>What is your temperature?</div>
                <div className={classes.temperatureContainer}>
                  <div className={classes.leaveBlankText}>Leave blank if you don't know your temperature.</div>
                  <div className={classes.temperatureWrapper}>
                    <TextField
                      className={classes.inputField}
                      onChange={this.handleChangeTemperature}
                      type="number"
                      inputProps={{
                        inputMode: 'decimal',
                        pattern: '[0-9]*',
                        type: 'tel',
                        style: { fontSize: '16px' } }}
                      value={temperature}
                    />
                    <span className={classes.fWrapper}>F</span>
                  </div>
                </div>
              </>
            ) : null}
            <YesNoButtonSet
              isNo={shakyChills === false}
              isYes={shakyChills}
              onClickNo={() => this.handleChangeShakyChills(false)}
              onClickYes={() => this.handleChangeShakyChills(true)}
              questionText="Have you had shaking chills?"
            />
          </div>
        </div>
      </QuestionLayout>
    );
  }
}

function mapStateToProps(state) {
  const {
    proForms: {
      thoracicCancerSymptoms,
    },
    user,
  } = state;
  return { thoracicCancerSymptoms, user };
}

export default connect(mapStateToProps, { submitPRO, updatePRO })(withStyles(styles)(ThoracicCancerSymptoms9));
