import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Page from './page';
import { colors } from '../lib/styles';
import AppBar from '../components/app-bar';
import Continue from '../components/continue';
import Logo from '../components/logo';
import { submitPRO, updatePRO } from '../state/pro-forms';

const styles = {
  instructions: {
    fontSize: '1.3rem',
  },
  image: {
    height: 120,
    padding: '15px 60px',
    textAlign: 'center',
  },
  linearProgress: {
    borderRadius: 0,
  },
  pageIndicator: {
    color: 'grey',
    display: 'inline-block',
    fontSize: 13,
  },
  questionnaire: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    position: 'relative',
    minHeight: '350px',
  },
  title: {
    color: 'black',
    fontSize: '1.5rem',
    fontWeight: 800,
    textTransform: 'uppercase',
    alignSelf: 'center',
  },
  date: {
    width: '150px',
    alignSelf: 'center',
    margin: '20px',
  },
  titleGroup: {
    margin: 25,
    textAlign: 'center',
  },
  error: {
    color: colors.errorRed,
    alignSelf: 'center',
  },
  text: {
    fontSize: '1.25rem',
  },
};

class HeadachePainPro1 extends Page {
  render() {
    const { id } = this.props.location.query;
    const { user } = this.props;

    return (
      <div>
        <AppBar
          headerNode="Headache Pain"
          noLeftNode={true}
        />
        <Logo />
        <div style={styles.questionnaire}>
          <div style={styles.titleGroup}>
            <p style={styles.text}>Do you experience headache pain?</p>
          </div>
          <Continue
            text="No"
            onClick={() => {
              this.props.submitPRO(
                { userId: user.id, id },
                {
                  pro_type: 'HEADACHE-PAIN',
                  pro_data: { data: [false] },
                },
                user,
              );
              this.finishTrack();
            }}
            btnStyle={{ marginTop: '50px', alignSelf: 'center' }}
          />
          <Continue
            text="Yes"
            onClick={() => {
              this.props.updatePRO({ type: 'headachePain', position: this.getTrackIndex(), value: true });
              this.forwardWithQuery(this.props.location.query)
            }}
            btnStyle={{ marginTop: '50px', alignSelf: 'center' }}
          />
        </div>
      </div>
    );
  }
}


function mapStateToProps(state) {
  const { user } = state;
  return { user };
}

HeadachePainPro1.proptypes = {
  user: PropTypes.object.isRequired,
  route: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, { submitPRO, updatePRO })(HeadachePainPro1);
