import { promiseHandler, resetReducer } from 'cooldux';
import { get, isUndefined, omit, omitBy } from 'lodash';

import { apiFetch } from '../lib/fetch';

const { browseStart, browseEnd, browseError, browseHandler } = promiseHandler('browse', 'employer');
const { editStart, editEnd, editHandler, editError } = promiseHandler('edit', 'employer');
const { addStart, addEnd, addError, addHandler } = promiseHandler('add', 'employer');
const { deleteStart, deleteEnd, deleteError, deleteHandler } = promiseHandler('delete', 'employer');

export function browseEmployers() {
  return function dispatcher(dispatch, getState) {
    const selfId = get(getState(), 'user.id', null);
    const promise = apiFetch(`/users/${selfId}/employer`);
    return browseHandler(promise, dispatch);
  };
}

export function editEmployer(update) {
  return function dispatcher(dispatch, getState) {
    const selfId = get(getState(), 'user.id', null);
    const body = omit(update, 'id');
    const options = {
      method: 'PUT',
      body: omitBy(body, isUndefined),
    };
    const promise = apiFetch(`/users/${selfId}/employer/${update.id}`, options);
    return editHandler(promise, dispatch);
  };
}

export function addEmployer(newEmployer) {
  return function dispatcher(dispatch, getState) {
    const selfId = get(getState(), 'user.id', null);
    const options = {
      method: 'POST',
      body: omitBy(newEmployer, isUndefined),
    };
    const promise = apiFetch(`/users/${selfId}/employer`, options);
    return addHandler(promise, dispatch);
  };
}

export function deleteEmployer(employerId) {
  return function dispatcher(dispatch, getState) {
    const selfId = get(getState(), 'user.id', null);
    const options = {
      method: 'DELETE',
    };
    const promise = apiFetch(`/users/${selfId}/employer/${employerId}`, options)
      .then(() => employerId);

    return deleteHandler(promise, dispatch);
  };
}

const initialState = {
  browseError: null,
  addError: null,
  editError: null,
  deleteError: null,
  isFetching: false,
  isAdding: false,
  isEditing: false,
  isDeleting: false,
  data: {},
};

function finishBrowse(state, employer) {
  const data = {};
  employer.forEach((u) => {
    data[u.id] = u;
  });
  return { ...state, data, isFetching: false, error: null };
}

function finishEdit(state, item) {
  const data = { ...state.data, [item.id]: item };
  return { ...state, isEditing: false, editError: null, data };
}

function finishAdd(state, item) {
  const data = { ...state.data, [item.id]: item };
  return { ...state, isAdding: false, addError: null, data };
}

function finishDelete(state, employerId) {
  return { ...state, isDeleting: false, deleteError: null, data: omit(state.data, employerId) };
}

const employer = resetReducer(initialState, (state = initialState, action) => {
  switch (action.type) {
    case browseStart.type:
      return { ...state, isFetching: true };
    case browseEnd.type:
      return finishBrowse(state, action.payload);
    case browseError.type:
      return { ...state, isFetching: false, browseError: action.payload };
    case addStart.type:
      return { ...state, isAdding: true };
    case addEnd.type:
      return finishAdd(state, action.payload);
    case addError.type:
      return { ...state, isAdding: false, addError: action.payload };
    case deleteStart.type:
      return { ...state, isDeleting: true };
    case deleteEnd.type:
      return finishDelete(state, action.payload);
    case deleteError.type:
      return { ...state, isDeleting: false, deleteError: action.payload };
    case editStart.type:
      return { ...state, isEditing: true };
    case editEnd.type:
      return finishEdit(state, action.payload);
    case editError.type:
      return { ...state, isEditing: false, editError: action.payload };
    default:
      return state;
  }
});

export default employer;
