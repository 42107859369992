import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import NoSleep from 'nosleep.js';

import { withStyles } from '@material-ui/core/styles';
import { Button, LinearProgress } from '@material-ui/core';

import AppBar from '../components/app-bar';
import Continue from '../components/continue';

import { Camera } from '../lib/icons';

import Page from './page';

import { updatePRO, submitPRO } from '../state/pro-forms';

const styles = {
  button: {
    margin: 5,
    width: 150,
    height: 40,
    fontSize: 16,
  },
  buttonGroup: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  iconContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    flexGrow: 0,
    flexShrink: 0,
    width: '100%',
    color: '#000',
    paddingTop: 10,
  },
  iconWrapper: {
    width: 100,
  },
  titleGroup: {
    margin: 25,
  },
  text: {
    fontSize: '1.75rem',
  },
  nextBtn: {
    textAlign: 'center',
    width: '100%',
    top: '90%',
    position: 'fixed',
  },
};

class ContactSpo2DataCollection1 extends Page {
  constructor(props) {
    super(props);

    this.state = {
      cameraPermissions: null,
    };
  }

  handleContinue = () => {
    const { location: { query }, updatePRO } = this.props;

    const noSleep = new NoSleep();
    noSleep.enable();

    updatePRO({
      type: 'contactSpo2DataCollection',
      position: this.getTrackIndex(),
      value: this.state });
    this.forwardWithQuery(query);
  }

  handleComplete = async () => {
    const { cameraPermissions } = this.state;
    const { user, submitPRO, location } = this.props;
    const { id } = location.query;

    await submitPRO({ userId: user.id, id }, {
      pro_type: 'CONTACT-SPO2-DATA-COLLECTION',
      pro_data: {
        data: { cameraPermissions },
      },
    }, user);
    this.finishTrack();
  }

  render() {
    const { cameraPermissions } = this.state;
    const { classes } = this.props;

    let btn = (
      <div className={classes.nextBtn}>
        <Continue
          disabled={cameraPermissions === null}
          text="Next"
          onClick={this.handleComplete}
        />
      </div>
    );

    if (cameraPermissions === true) {
      btn = (
        <div className={classes.nextBtn}>
          <Continue
            disabled={cameraPermissions === null}
            text="Next"
            onClick={this.handleContinue}
          />
        </div>
      );
    }

    return (
      <Fragment>
        <AppBar headerNode="Lighting Conditions" noLeftNode={true} />
        <LinearProgress
          variant="determinate"
          value={(this.getCurrentPage() / this.getTotalPages()) * 100}
        />
        <div className={classes.iconContainer}>
          <div className={classes.iconWrapper}>
            <Camera />
          </div>
        </div>

        <div className={classes.titleGroup}>
          <p className={classes.text}>
            We&apos;re interested in the lighting conditions while you take your
            vitals. Can we take photos of you?
          </p>
          <div className={classes.buttonGroup}>
            <Button
              className={classes.button}
              variant="contained"
              color={cameraPermissions ? 'primary' : 'default'}
              onClick={() => this.setState({ cameraPermissions: true })}
            >
              Yes
            </Button>
            <Button
              className={classes.button}
              variant="contained"
              color={cameraPermissions === false ? 'primary' : 'default'}
              onClick={() => this.setState({ cameraPermissions: false })}
            >
              No
            </Button>
          </div>
        </div>
        {btn}
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const {
    user,
    proForms: { contactSpo2DataCollection },
  } = state;
  return { user, contactSpo2DataCollection };
}

ContactSpo2DataCollection1.proptypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  updatePRO: PropTypes.func.isRequired,
  submitPRO: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, {
  updatePRO,
  submitPRO,
})(withStyles(styles)(ContactSpo2DataCollection1));
