import { fontSizing } from './styles';

export const bloomkidzPros = [
  'BLOOMKIDZ-MEAL-AND-NUTRITION',
  'BLOOMKIDZ-CLIENT-WELLNESS-PROFILE',
  'BLOOMKIDZ-NEW-CLIENT',
  'BLOOMKIDZ-MEDICAL-HISTORY',
  'BLOOMKIDZ-SIGNATURES',
  'BLOOMKIDZ-PARENT-GUARDIAN-INFORMATION',
];

export const bloomkidzStyles = {
  errorRed: '#f44336',
  fontFamily: "'Josefin Sans', 'Helvetica', 'Arial', 'sans-serif'",
  greyText: '#707070',
  primaryColor: '#2a9a0c',
  primaryColorLight: '#bfdbb8',
};

export const bloomkidzStylesObj = {
  button: {
    border: 'none',
    borderRadius: 5,
    flexGrow: 1,
    fontFamily: bloomkidzStyles.fontFamily,
    fontSize: fontSizing.body,
    paddingBottom: 15,
    paddingTop: 15,
    '& + &': {
      marginLeft: 10,
    },
  },
  buttonGreenBackground: { // for buttons that are not in a row
    background: bloomkidzStyles.primaryColor,
    border: `2px solid ${bloomkidzStyles.primaryColor}`,
    borderRadius: 5,
    color: 'white',
    fontFamily: bloomkidzStyles.fontFamily,
    fontSize: fontSizing.body,
    paddingBottom: 15,
    paddingTop: 15,
    width: '100%',
  },
  buttonSelected: {
    background: bloomkidzStyles.primaryColor,
    color: 'white',
  },
  buttonUnselected: {
    background: '#d8d8d8',
    color: 'black',
  },
  buttonWithBorder: {
    border: `2px solid ${bloomkidzStyles.primaryColor}`,
    borderRadius: 5,
    color: bloomkidzStyles.primaryColor,
    fontFamily: bloomkidzStyles.fontFamily,
    fontSize: fontSizing.body,
    paddingBottom: 15,
    paddingTop: 15,
    width: '100%',
  },
  buttonRow: {
    display: 'flex',
    marginTop: 15,
  },
  checkbox: {
    paddingTop: 3,
  },
  checkboxLabel: {
    fontSize: fontSizing.body,
    marginTop: 3,
    fontFamily: 'Josefin Sans',
  },
  errorHelperText: {
    color: bloomkidzStyles.errorRed,
    fontSize: fontSizing.small,
    lineHeight: 1.6,
    marginBottom: 3,
    marginTop: 3,
  },
  inputSectionTitle: {
    marginTop: 30,
  },
  formControlLabel: {
    alignItems: 'start',
    marginTop: 10,
    '& .Mui-checked': {
      color: bloomkidzStyles.primaryColor,
    },
    '& svg': {
      height: 35,
      width: 35,
    },
  },
  formControlLabelRadio: {
    '& .Mui-checked': {
      color: bloomkidzStyles.primaryColor,
    },
    '& svg': {
      height: 35,
      width: 35,
    },
  },
  greyText: {
    color: bloomkidzStyles.greyText,
  },
  keypadDateWrapper: {
    marginTop: 10,
    '& > div > div': {
      margin: 0,
      width: '100%',
    },
    '& input': {
      margin: 0,
      fontFamily: bloomkidzStyles.fontFamily,
      fontSize: `${fontSizing.body}px !important`,
    },
  },
  keypadDateWrapperError: {
    '& input': {
      borderBottom: `2px ${bloomkidzStyles.errorRed} solid`,
    },
  },
  paddingHorizontal: {
    paddingLeft: 20,
    paddingRight: 20,
  },
  pageContent: {
    fontSize: fontSizing.body,
    paddingLeft: 20,
    paddingRight: 20,
  },
  radio: {
    '& .MuiRadio-colorSecondary.Mui-checked': {
      color: bloomkidzStyles.primaryColor,
    },
  },
  radioGroup: {
    marginTop: 10,
  },
  root: {
    fontFamily: bloomkidzStyles.fontFamily,
  },
  subtext: {
    marginTop: 5,
  },
  textAreaAutosize: {
    boxSizing: 'border-box',
    fontFamily: 'inherit',
    fontSize: fontSizing.body,
    marginTop: 20,
    padding: 10,
    resize: 'none',
    width: '100%',
  },
  textFieldWrapper: {
    marginBottom: 10,
    marginTop: 10,
    '& .MuiInput-underline:after': {
      borderBottom: '2px solid black',
    },
    '& .Mui-error': {
      fontFamily: bloomkidzStyles.fontFamily,
      fontSize: fontSizing.small,
    },
  },
};
