import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isNumber } from 'lodash';
import LinearProgress from '@material-ui/core/LinearProgress';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withStyles } from '@material-ui/core/styles';

import AppBar from './app-bar';
import { colors } from '../lib/styles';
import QuestionLayout from '../layouts/question';

const { lightBrightBlue } = colors;

const styles = {
  contentWrapper: {
    flexGrow: 1,
    overflowX: 'hidden',
    position: 'relative',
  },
  image: {
    height: 200,
    padding: '15px 60px',
    textAlign: 'center',
  },
  instructions: {
    color: 'grey',
    fontSize: 22,
    margin: '20px 40px 0px 40px',
    textAlign: 'center',
  },
  nextButton: {
    bottom: 30,
    left: 30,
    position: 'absolute',
    right: 30,
  },
  pageIndicator: {
    fontSize: 14,
  },
  questionnaire: {
    marginLeft: 30,
    position: 'relative',
  },
  title: {
    color: 'black',
    fontSize: '22px',
    fontWeight: 'strong',
    textTransform: 'uppercase',
  },
  bar: {
    backgroundColor: lightBrightBlue,
  },
};

const StyledFormControlLabel = withStyles(() => ({
  label: {
    fontSize: 22,
  },
  root: {
    alignItems: 'start',
  },
}))(FormControlLabel);

class Question extends Component {
  handleChange = (event, value) => {
    this.props.onChange({
      type: this.props.type,
      position: this.props.trackIndex,
      value: Number.parseInt(value, 10),
    });
  };

  handleSubmission = () => {
    this.props.onSubmission();
  }

  render() {
    const {
      backgroundColor,
      buttonLabel,
      currentPage,
      image: Image,
      instructions,
      options,
      onClickBackButton,
      title,
      totalPages,
      value,
      classes,
      subtitle,
    } = this.props;
    const background = backgroundColor ? { backgroundColor } : {};
    const primaryBtnDisabled = !isNumber(value);
    return (
      <QuestionLayout
        primaryBtnDisabled={primaryBtnDisabled}
        primaryBtnLabel={buttonLabel}
        primaryBtnOnTouchTap={this.handleSubmission}
        style={background}
      >
        <AppBar
          backButtonOnClick={onClickBackButton}
          headerNode={title}
          rightNode={
            <div style={styles.pageIndicator}>{`${currentPage} of ${totalPages}`}</div>
          }
        />
        <LinearProgress
          variant="determinate"
          value={(currentPage / totalPages) * 100}
          classes={{ bar: classes.bar }}
        />
        <div style={styles.contentWrapper}>
          <div style={styles.instructions}>
            {instructions}
          </div>
          {!Image ? null : (
            <div style={styles.image}>
              <Image />
            </div>
          )}
          <div style={styles.questionnaire}>
            <p style={styles.title}>{subtitle || title}</p>
            <RadioGroup
              name={title}
              onChange={this.handleChange}
              value={value}
            >
              {options.map(option => (
                <StyledFormControlLabel key={option.value} value={option.value} control={<Radio />} label={option.label} />
              ))}
            </RadioGroup>
          </div>
        </div>
      </QuestionLayout>
    );
  }
}

Question.defaultProps = {
  backgroundColor: undefined,
  bodyText: null,
  buttonLabel: 'Next',
  image: undefined,
  instructions: 'Please select the option that best answers the question relating to your pain.',
  type: '',
  subtitle: null,
};

Question.propTypes = {
  backgroundColor: PropTypes.string,
  bodyText: PropTypes.string,
  buttonLabel: PropTypes.string,
  currentPage: PropTypes.number.isRequired,
  image: PropTypes.func,
  instructions: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  onClickBackButton: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmission: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  totalPages: PropTypes.number.isRequired,
  trackIndex: PropTypes.number.isRequired,
  type: PropTypes.string,
  value: PropTypes.number.isRequired,
};

export default withStyles(styles)(Question);
