import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { forEach, noop, sortBy } from 'lodash';
import {
  Divider,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import SimpleListItem from './simple-list-item';

import { colors } from '../lib/styles';

const { questionBackground } = colors;

const baseStyles = {
  category: {
    color: colors.greyText,
    fontSize: '1rem !important',
    textAlign: 'center',
    textTransform: 'capitalize',
  },
  list: {
    backgroundColor: questionBackground,
  },
};

class SimpleList extends Component {
  render() {
    const {
      categoryField,
      classes,
      items,
      selectedItems,
    } = this.props;

    let sortedItems = items;

    if (categoryField) {
      sortedItems = sortBy(items, this.props.categoryField);
    } else if (this.props.sortBy) {
      sortedItems = sortBy(items, this.props.sortBy);
    }
    const rows = [];
    let lastCategory = '';
    forEach(sortedItems, (obj) => {
      if (categoryField && (lastCategory !== obj[categoryField])) {
        lastCategory = obj[categoryField];
        const prettyCategory = lastCategory.replace(/_/g, ' ');
        rows.push(<ListItem
          key={lastCategory}
          primaryText={prettyCategory}
        />);
        rows.push(
          <ListItem>
            <ListItemText primary={prettyCategory} classes={{ primary: classes.category }} />
          </ListItem>
        );
        rows.push(<Divider key={`${lastCategory}-divider`} />);
      }
      const itemSelected = !!selectedItems[obj.id];
      rows.push(<SimpleListItem
        checked={itemSelected}
        item={obj}
        key={obj.id}
        onRemoveItem={this.props.onRemoveItem}
        onAddItem={this.props.onAddItem}
      />);
      rows.push(<Divider key={`${obj.id}-divider`} />);
    });
    return (
      <List classes={{ root: classes.list }}>
        {rows}
      </List>
    );
  }
}
SimpleList.defaultProps = {
  categoryField: '', // Optionally groups and titles items by a category
  onAddItem: noop,
  onRemoveItem: noop,
};

SimpleList.propTypes = {
  categoryField: PropTypes.string,
  classes: PropTypes.object.isRequired,
  items: PropTypes.object.isRequired,
  selectedItems: PropTypes.object.isRequired,
  onAddItem: PropTypes.func,
  onRemoveItem: PropTypes.func,
};

export default withStyles(baseStyles)(SimpleList);
