import React from 'react';
import PropTypes from 'prop-types';

import Continue from './continue';

class ContinueWithDisable extends React.Component {
  constructor(props) {
    super(props);

    this.state = { disabled: false };
  }

  handleClick = () => {
    const { onClick } = this.props;

    this.setState({ disabled: true });

    onClick()
      .then(() => { this.setState({ disabled: false }); });
  }

  render() {
    const { disabled } = this.state;

    return (
      <Continue
        {...this.props}
        disabled={disabled}
        onClick={this.handleClick}
      />
    );
  }
}

ContinueWithDisable.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default ContinueWithDisable;
