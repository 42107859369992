import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';
import { connect } from 'react-redux';

import Page from './page';
import AppBar from '../components/app-bar';
import IconWrapper from '../components/icon-wrapper';
import BloomkidzLayout from '../layouts/bloomkidz';
import { UserGroup } from '../lib/icons';
import { fontSizing } from '../lib/styles';
import { updatePRO } from '../state/pro-forms';
import { bloomkidzStyles, bloomkidzStylesObj } from '../lib/bloomkidz';

const styles = bloomkidzStylesObj;

class BloomkidzParentGuardianInformation6 extends Page {
  constructor(props) {
    super(props);

    const stateFromRedux = props.bloomkidzParentGuardianInformation[this.getTrackIndex()] || {};

    this.state = {
      siblingsDiagnosis: '',
      siblingsServices: '',
      ...stateFromRedux,
    };
  }

  handleTextFieldChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleContinue = () => {
    const { location, updatePRO } = this.props;

    updatePRO({
      type: 'bloomkidzParentGuardianInformation',
      position: this.getTrackIndex(),
      value: { ...this.state },
    });

    this.forwardWithQuery(location.query);
  }

  handleClickButton = (gender) => {
    this.setState({ gender });
  }

  render() {
    const { classes, router } = this.props;
    const {
      siblingsDiagnosis,
      siblingsServices,
    } = this.state;

    const disableContinueButton = !siblingsDiagnosis
      || !siblingsServices;

    return (
      <BloomkidzLayout
        primaryBtnOnTouchTap={this.handleContinue}
        primaryBtnLabel="Next"
        primaryBtnDisabled={disableContinueButton}
      >
        <AppBar
          headerNode="Family Information"
          backButtonOnClick={() => router.goBack()}
        />
        <IconWrapper>
          <UserGroup />
        </IconWrapper>
        <div className={classes.pageContent}>
          <div>Do any siblings have a diagnosis?</div>
          <div className={classes.paddingHorizontal}>
            <div className={classes.textFieldWrapper}>
              <TextField
                placeholder="Please list"
                value={siblingsDiagnosis}
                onChange={this.handleTextFieldChange}
                name="siblingsDiagnosis"
                variant="standard"
                fullWidth
                inputProps={{ style: { fontSize: fontSizing.body, fontFamily: bloomkidzStyles.fontFamily } }}
              />
            </div>
          </div>
          <div className={classes.inputSectionTitle}>
            Do any siblings receive services?
          </div>
          <div className={classes.paddingHorizontal}>
            <div className={classes.textFieldWrapper}>
              <TextField
                placeholder="Please List"
                value={siblingsServices}
                onChange={this.handleTextFieldChange}
                name="siblingsServices"
                variant="standard"
                fullWidth
                inputProps={{ style: { fontSize: fontSizing.body, fontFamily: bloomkidzStyles.fontFamily } }}
              />
            </div>
          </div>
        </div>
      </BloomkidzLayout>
    );
  }
}

BloomkidzParentGuardianInformation6.propTypes = {
  classes: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  updatePRO: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const {
    proForms: { bloomkidzParentGuardianInformation },
  } = state;

  return { bloomkidzParentGuardianInformation };
};

export default connect(mapStateToProps, { updatePRO })(withStyles(styles)(BloomkidzParentGuardianInformation6));
