import React from 'react';
import {
  FormControlLabel,
  LinearProgress,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import AppBar from '../components/app-bar';
import Page from './page';
import QuestionLayout from '../layouts/question';
import { title, instructions, options, nextBtn, noLabel, yesLabel } from '../lib/i18next';
import { colors } from '../lib/styles';
import { updatePRO } from '../state/pro-forms';

const styles = {
  descriptionText: {
    fontSize: 25,
    fontStyle: 'italic',
  },
  formControlLabelRoot: {
    alignItems: 'start',
    marginTop: 5,
    '& .MuiSvgIcon-root': {
      height: 20,
      width: 20,
    },
  },
  questionText: {
    fontSize: 29,
    fontWeight: 'bold',
    marginTop: 20,
  },
  questionnaireContainer: {
    paddingLeft: 20,
    paddingRight: 20,
  },
  radio: {
    paddingTop: 10,
  },
  radioLabel: {
    fontSize: 29,
  },
  textField: {
    marginLeft: 28,
    '& input': {
      fontSize: 29,
    },
    '& label': {
      fontSize: 29,
    },
  },
};

class PatientDemographics5 extends Page {
  constructor(props) {
    super(props);

    this.state = {
      canStayAtHousing: '',
      housingSituation: '',
    };
  }

  handleChangeCanStayAtHousing = (e) => {
    this.setState({ canStayAtHousing: e.target.value });
  }

  handleChangeHousingSituation = (e) => {
    this.setState({ housingSituation: e.target.value });
    // eslint-disable-next-line no-unused-expressions
    e.target.value !== 'Shared housing not owned' && this.setState({ canStayAtHousing: '' });
  };

  handleClickNext = () => {
    const { canStayAtHousing, housingSituation } = this.state;

    this.props.updatePRO({
      type: 'patientDemographics',
      position: this.getTrackIndex(),
      value: { canStayAtHousing, housingSituation },
    });

    this.forwardWithQuery(this.props.location.query);
  }

  render() {
    const { classes } = this.props;
    const { canStayAtHousing, housingSituation } = this.state;

    let continueDisabled = true;

    if (housingSituation && housingSituation !== 'Shared housing not owned') {
      continueDisabled = false;
    } else if (housingSituation === 'Shared housing not owned' && canStayAtHousing) {
      continueDisabled = false;
    }

    return (
      <QuestionLayout
        primaryBtnLabel={nextBtn()}
        primaryBtnOnTouchTap={this.handleClickNext}
        primaryBtnDisabled={continueDisabled}
        style={{ backgroundColor: colors.questionBackground }}
      >
        <AppBar
          headerNode={title('demographics5')}
          backButtonOnClick={() => this.props.router.goBack()}
        />
        <LinearProgress
          variant="determinate"
          value={(this.getCurrentPage() / this.getTotalPages()) * 100}
          classes={{ bar: classes.bar }}
        />
        <div className={classes.questionnaireContainer}>
          <div className={classes.questionText}>
            {instructions('demographics5')}
          </div>
          <RadioGroup value={housingSituation} onChange={this.handleChangeHousingSituation}>
            <FormControlLabel
              value="Emergency shelter"
              label={options('shelter', 'demographics5')}
              control={<Radio classes={{ root: classes.radio }} />}
              classes={{ root: classes.formControlLabelRoot, label: classes.radioLabel }}
            />
            <FormControlLabel
              value="Institution"
              label={(
                <div>
                  <div>{options('institution', 'demographics5')}</div>
                  <div className={classes.descriptionText}>
                    ({options('institutionDetails', 'demographics5')})
                  </div>
                </div>
              )}
              control={<Radio classes={{ root: classes.radio }} />}
              classes={{ root: classes.formControlLabelRoot, label: classes.radioLabel }}
            />
            <FormControlLabel
              value="Place not meant for habitation"
              label={(
                <div>
                  <div>{options('uninhabitable', 'demographics5')}</div>
                  <div className={classes.descriptionText}>
                    ({options('uninhabitableDetails', 'demographics5')})
                  </div>
                </div>
              )}
              control={<Radio classes={{ root: classes.radio }} />}
              classes={{ root: classes.formControlLabelRoot, label: classes.radioLabel }}
            />
            <FormControlLabel
              value="Shared housing not owned"
              label={options('houseShare', 'demographics5')}
              control={<Radio classes={{ root: classes.radio }} />}
              classes={{ root: classes.formControlLabelRoot, label: classes.radioLabel }}
            />
            <FormControlLabel
              value="Housing rented"
              label={options('houseRent', 'demographics5')}
              control={<Radio classes={{ root: classes.radio }} />}
              classes={{ root: classes.formControlLabelRoot, label: classes.radioLabel }}
            />
            <FormControlLabel
              value="Housing owned"
              label={options('houseOwn', 'demographics5')}
              control={<Radio classes={{ root: classes.radio }} />}
              classes={{ root: classes.formControlLabelRoot, label: classes.radioLabel }}
            />
          </RadioGroup>
          {housingSituation === 'Shared housing not owned' ? (
              <>
                <div className={classes.questionText}>
                  {options('question', 'demographics5')}
                  <RadioGroup value={canStayAtHousing} onChange={this.handleChangeCanStayAtHousing}>
                    <FormControlLabel
                      value="Yes"
                      label={yesLabel()}
                      control={<Radio classes={{ root: classes.radio }} />}
                      classes={{ root: classes.formControlLabelRoot, label: classes.radioLabel }}
                    />
                    <FormControlLabel
                      value="No"
                      label={noLabel()}
                      control={<Radio classes={{ root: classes.radio }} />}
                      classes={{ root: classes.formControlLabelRoot, label: classes.radioLabel }}
                    />
                  </RadioGroup>
                </div>

              </>
          ) : null}
        </div>
      </QuestionLayout>
    );
  }
}

export default connect(null, { updatePRO })(withStyles(styles)(PatientDemographics5));
