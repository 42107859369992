import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import { connect } from 'react-redux';
import Page from './page';
import AppBar from '../components/app-bar';
import BloomkidzLayout from '../layouts/bloomkidz';
import { apiFetch, makeShadowOptions } from '../lib/fetch';
import { bloomkidzStylesObj, bloomkidzStyles } from '../lib/bloomkidz';
import { fontSizing } from '../lib/styles';

const styles = {
  ...bloomkidzStylesObj,
  centerText: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
  },
  btn: {
    border: `1px solid ${bloomkidzStyles.primaryColor}`,
    borderRadius: '0.3125rem',
    minWidth: '17.1875rem',
    color: bloomkidzStyles.primaryColor,
    marginBottom: '0.625rem',
    marginTop: '0.0625rem',
    width: '85vw',
    minHeight: '4rem',
    fontFamily: bloomkidzStyles.fontFamily,
    fontSize: fontSizing.body,
    padding: '10px',
  },
  button: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: 20,
  },
  emphasis: {
    fontWeight: 600,
  },
  spacing: {
    margin: '20px 0 10px',
  },
};

class BloomkidzSignatures21 extends Page {
  constructor(props) {
    super(props);

    this.state = {
      hipaaUrl: '',
      consentUrl: '',
      mediaUrl: '',
      illnessUrl: '',
      rightsUrl: '',
    };
  }

  async componentDidMount() {
    const { user, location } = this.props;
    const { id } = location.query;
    const options = makeShadowOptions({}, this.props.user.token, 'GET');
    const res = await apiFetch(`/users/${user.id}/pro_submissions/${id}/releases/BLOOMKIDZ-SIGNATURES`, options);
    this.setState({ ...res.urls });
  }

  handleContinue = () => {
    this.forward();
  }

  render() {
    const { classes, router } = this.props;
    const {
      hipaaUrl,
      consentUrl,
      mediaUrl,
      illnessUrl,
      rightsUrl,
    } = this.state;

    return (
      <BloomkidzLayout
        primaryBtnOnTouchTap={this.handleContinue}
        primaryBtnLabel="FINISH"
      >
        <AppBar
          headerNode=""
          backButtonOnClick={() => router.goBack()}
        />
        <div className={classes.pageContent}>
          <div className={`${classes.emphasis} ${classes.spacing} ${classes.centerText}`}>Download Signed Forms</div>
          <div>
            Click below to download the following forms you have signed:
          </div>
          <div style={styles.button}>
            <Button
              style={styles.btn}
              type="button"
              href={hipaaUrl}
              target="_blank"
            >
              HIPAA Notice of Privacy Practices
            </Button>
          </div>
          <div style={styles.button}>
            <Button
              style={styles.btn}
              type="button"
              href={consentUrl}
              target="_blank"
            >
              Informed Consent - Assessments and Services
            </Button>
          </div>
          <div style={styles.button}>
            <Button
              style={styles.btn}
              type="button"
              href={mediaUrl}
              target="_blank"
            >
              BloomKidz Media Release
            </Button>
          </div>
          <div style={styles.button}>
            <Button
              style={styles.btn}
              type="button"
              href={illnessUrl}
              target="_blank"
            >
              BloomKidz Illness Policy
            </Button>
          </div>
          <div style={styles.button}>
            <Button
              style={styles.btn}
              type="button"
              href={rightsUrl}
              target="_blank"
            >
              Client Rights
            </Button>
          </div>
        </div>
      </BloomkidzLayout>
    );
  }
}

BloomkidzSignatures21.propTypes = {
  classes: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const {
    proForms: { bloomkidzSignatures },
    user,
  } = state;

  return { bloomkidzSignatures, user };
};

export default connect(mapStateToProps, {})(withStyles(styles)(BloomkidzSignatures21));
