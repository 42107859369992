import appointmentConfirmation1 from './appointment-confirmation-1';
import appointmentConfirmation2 from './appointment-confirmation-2';
import appointmentConfirmation3 from './appointment-confirmation-3';
import appointmentConfirmation4 from './appointment-confirmation-4';
import appointmentConfirmationNotificationPage from './appointment-confirmation-notification-page';
import appointmentReminder from './appointment-reminder';
import bundledEventNotifications from './bundled-event';
import demographicsNotificationPage from './demographics-notification-page';
import demographics1 from './demographics-1';
import demographics2 from './demographics-2';
import demographics3 from './demographics-3';
import demographics4 from './demographics-4';
import demographics5 from './demographics-5';
import demographics6 from './demographics-6';
import demographics7 from './demographics-7';
import demographics8 from './demographics-8';
import demographics9 from './demographics-9';
import education1 from './education-1';
import education2 from './education-2';
import eqAnxietyDepression from './eq-anxiety-depression';
import eqDisclaimer from './eq-disclaimer';
import eqFunctionality from './eq-functionality';
import eqHealthState from './eq-health-state';
import eqMobility from './eq-mobility';
import eqPainDiscomfort from './eq-pain-discomfort';
import eqSelfCare from './eq-self-care';
import eqUsualActivities from './eq-usual-activities';
import eqNotificationPage from './eq-notification-page';
import general from './general';
import notificationIntro from './notification-intro';
import pisaInjectionNotificationPage from './pisa-injection-notification-page';
import pisaInjection1 from './pisa-injection-1';
import pisaInjection2 from './pisa-injection-2';
import pisaInjection3 from './pisa-injection-3';
import pisaInjection4 from './pisa-injection-4';
import telemedicineAppointmentNotificationPage from './telemedicine-appointment-notification-page';
import videoBroadcast1 from './video-broadcast-1';
import videoBroadcast2 from './video-broadcast-2';
import videoCallPro1 from './video-call-pro-1';
import videoCallPro2 from './video-call-pro-2';
import videoBroadcastNotificationPage from './video-broadcast-notification-page';
import woundCasaColinaNotificationPage from './wound-casa-colina-notification-page';
import woundCasaColinaPro1 from './wound-casa-colina-pro-1';
import woundCasaColinaPro2 from './wound-casa-colina-pro-2';
import woundCasaColinaPro3 from './wound-casa-colina-pro-3';
import woundCasaColinaPro4 from './wound-casa-colina-pro-4';
import woundCasaColinaPro5 from './wound-casa-colina-pro-5';
import woundPro1 from './wound-pro-1';
import woundPro2 from './wound-pro-2';
import woundPro3 from './wound-pro-3';

const resources = [
  appointmentConfirmation1,
  appointmentConfirmation2,
  appointmentConfirmation3,
  appointmentConfirmation4,
  appointmentConfirmationNotificationPage,
  appointmentReminder,
  bundledEventNotifications,
  demographicsNotificationPage,
  demographics1,
  demographics2,
  demographics3,
  demographics4,
  demographics5,
  demographics6,
  demographics7,
  demographics8,
  demographics9,
  education1,
  education2,
  eqAnxietyDepression,
  eqDisclaimer,
  eqFunctionality,
  eqHealthState,
  eqMobility,
  eqPainDiscomfort,
  eqSelfCare,
  eqUsualActivities,
  eqNotificationPage,
  general,
  notificationIntro,
  pisaInjectionNotificationPage,
  pisaInjection1,
  pisaInjection2,
  pisaInjection3,
  pisaInjection4,
  telemedicineAppointmentNotificationPage,
  videoBroadcast1,
  videoBroadcast2,
  videoCallPro1,
  videoCallPro2,
  videoBroadcastNotificationPage,
  woundCasaColinaNotificationPage,
  woundCasaColinaPro1,
  woundCasaColinaPro2,
  woundCasaColinaPro3,
  woundCasaColinaPro4,
  woundCasaColinaPro5,
  woundPro1,
  woundPro2,
  woundPro3,
];

const en = {};
const es = {};

const addResourcesToLang = (resources, language) => {
  Object.entries(resources).forEach(([key, text]) => {
    language[key] = text;
  });
};

resources.forEach((resource) => {
  addResourcesToLang(resource.en, en);
  addResourcesToLang(resource.es, es);
});

export default { en, es };
