const en = {
  eqFunctionality: {
    title: 'Function',
    instructions: 'What is your current function level?',
    options: {
      healthy: 'I can walk in the community',
      mild: 'I can walk at home',
      medium: 'I can transfer',
      mediumDetails: 'ie. bed to chair',
      severe: 'I am bed bound',
    },
  },
};

const es = {
  eqFunctionality: {
    title: 'Función',
    instructions: '¿Cuál es su nivel de función actual?',
    options: {
      healthy: 'Puede caminar en la comunidad',
      mild: 'Puede caminar en casa',
      medium: 'Puede transferir',
      mediumDetails: 'es decir, de la cama a la silla',
      severe: 'Atado a la cama',
    },
  },
};

export default { en, es };
