import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get, includes } from 'lodash';

import { colors } from '../lib/styles';
import Page from './page';
import ButtonPRO from '../components/button-pro';
import { updatePRO } from '../state/pro-forms';

const buttonLabels = ['None', 'Mild', 'Moderate', 'Severe', 'Extreme'];

const options = [
  {
    question: 'Walking on a flat surface',
    label: buttonLabels,
  },
  {
    question: 'Going up or down stairs',
    label: buttonLabels,
  },
  {
    question: 'At night while in bed',
    label: buttonLabels,
  },
  {
    question: 'Sitting or lying',
    label: buttonLabels,
  },
  {
    question: 'Standing upright',
    label: buttonLabels,
  },
];
const { questionBackground } = colors;

class WOMAC1 extends Page {
  onClickBackButton = () => {
    this.backward();
  }

  onSubmission = () => {
    this.forwardWithQuery(this.props.location.query);
  }

  render() {
    const page = get(this.props.womac, this.getTrackIndex(), [null, null, null, null, null]);
    const value = includes(page, null) ? null : 0;
    return (
      <ButtonPRO
        backgroundColor={questionBackground}
        currentPage={this.getCurrentPage()}
        onClickBackButton={() => this.props.router.goBack()}
        onChange={this.props.updatePRO}
        onSubmission={this.onSubmission}
        options={options}
        instructions={`The following questions concern the amount of pain you are currently experiencing in your ${this.props.route.type}. For each situation, please enter the amount of pain you have experienced in the last 48 hours.`}
        header="Pain"
        totalPages={this.getTotalPages()}
        trackIndex={this.getTrackIndex()}
        value={value}
        pro={page}
        type={this.props.type}
      />
    );
  }
}

function mapStateToProps(state, ownProps) {
  const { proForms, user } = state;
  const { type } = ownProps.route;
  const womacType = `womac${type}`;
  return { womac: proForms[womacType], user, type: womacType };
}

WOMAC1.propTypes = {
  womac: PropTypes.array.isRequired,
  updatePRO: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { updatePRO })(WOMAC1);
