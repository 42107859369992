import React from 'react';
import { connect } from 'react-redux';
import { toLower, toUpper, upperFirst } from 'lodash';

import {
  Ache,
  Burning,
  Constant,
  Cramping,
  Dull,
  Numbness,
  Pinching,
  PinsAndNeedles,
  Sharp,
  Shooting,
  Stabbing,
  Throbbing,
  Tingling,
} from '../lib/pain-entry-icons';
import AppBar from '../components/app-bar';
import { colors } from '../lib/styles';
import Page from './page';
import PainTypeEntryOption from '../components/pain-type-entry-option';
import QuestionLayout from '../layouts/question';
import { submitPRO, updatePRO } from '../state/pro-forms';

const painTypes = [
  'ache',
  'numbness',
  'pinching',
  'dull',
  'constant',
  'stabbing',
  'cramping',
  'burning',
  'shooting',
  'tingling',
  'sharp',
  'throbbing',
  'pins_and_needles',
];

const styles = {
  appBar: {
    height: '8.75rem',
    paddingLeft: '1.5rem',
    paddingRight: '1.5rem',
  },
  backButton: {
    left: '-1.25rem',
    height: '3.6875rem',
    padding: 0,
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    width: '3.6875rem',
  },
  backButtonIcon: {
    height: '3.6875rem',
    width: '3.6875rem',
  },
  continueButton: {
    background: colors.secondaryColor,
    marginBottom: '7.5vw',
  },
  continueWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
  header: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    width: '100%',
  },
  headerTitle: {
    color: colors.black,
    display: 'inline-block',
    fontWeight: 'bold',
    fontSize: '1.8rem',
  },
  marginRight: {
    marginRight: '1.25rem',
  },
  nextButton: {
    position: 'absolute',
    right: 5,
    textAlign: 'right',
    top: '50%',
    transform: 'translateY(-50%)',
  },
  nextButtonLabel: {
    color: colors.black,
    fontSize: '1.6rem',
    padding: 0,
    textTransform: 'none',
  },
  option: {
    flexBasis: 1,
    flexGrow: 1,
    flexShrink: 1,
  },
  options: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  PainTypeEntryOptions: {
    margin: '2.5rem',
  },
  painSelectionHeader: {
    background: colors.painSelectionHeaderBackground,
    color: colors.white,
    fontSize: '1.5rem',
    height: '3.5rem',
    lineHeight: '3.5rem',
    textAlign: 'center',
  },
  rootContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minHeight: '100%',
  },
  row: {
    display: 'flex',
    marginBottom: '3rem',
    width: '100%',
  },
};

class PainTypeEntry extends Page {
  constructor(props) {
    super(props);

    this.state = {
      currSelection: 'none',
    };

    painTypes.forEach((type) => {
      const funcName = `handle${upperFirst(type)}`;
      this[funcName] = this.handleSelection.bind(this, type);
    });
    this.handleClickContinue = this.handleClickContinue.bind(this);
    this.handleSelection = this.handleSelection.bind(this);

    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  componentWillReceiveProps(nextProps) {
    const { data, pain_hx_id } = nextProps;
    const { pain_type } = data[pain_hx_id];

    if (pain_type) {
      this.setState({ currSelection: toLower(pain_type) });
    }
  }

  handleClickContinue() {
    const {
      location,
      painLog,
      submitPRO,
      user,
    } = this.props;
    const { currSelection } = this.state;
    const { id } = location.query;

    submitPRO(
      { userId: user.id, id },
      {
        pro_data: { data: [...painLog, toUpper(currSelection)] },
        pro_type: 'PAIN-LOG',
      },
      user,
    )
      .then(() => {
        this.finishTrack();
      });
  }

  handleSelection(type) {
    let { currSelection } = this.state;
    currSelection = (currSelection === type) ? 'none' : type;

    this.setState({ currSelection });
  }

  render() {
    const { currSelection } = this.state;

    return (
      <QuestionLayout
        primaryBtnLabel="Next"
        primaryBtnOnTouchTap={this.handleClickContinue}
      >
        <div style={styles.rootContainer}>
          <AppBar
            backButtonOnClick={() => this.props.router.goBack()}
            headerNode="Pain Type"
          />
          <div style={styles.painSelectionHeader}>
            Select the type of pain you are experiencing below:
          </div>
          <div style={styles.PainTypeEntryOptions}>
            <div style={styles.row}>
              <div style={{ ...styles.marginRight, ...styles.option }}>
                <PainTypeEntryOption
                  icon={Ache}
                  label="ache"
                  onClick={this.handleAche}
                  selected={currSelection === 'ache'}
                />
              </div>
              <div style={{ ...styles.marginRight, ...styles.option }}>
                <PainTypeEntryOption
                  icon={Numbness}
                  label="numbness"
                  onClick={this.handleNumbness}
                  selected={currSelection === 'numbness'}
                />
              </div>
              <div style={styles.option}>
                <PainTypeEntryOption
                  icon={Pinching}
                  label="pinching"
                  onClick={this.handlePinching}
                  selected={currSelection === 'pinching'}
                />
              </div>
            </div>
            <div style={styles.row}>
              <div style={{ ...styles.marginRight, ...styles.option }}>
                <PainTypeEntryOption
                  changeBackground={true}
                  icon={Dull}
                  label="dull"
                  onClick={this.handleDull}
                  selected={currSelection === 'dull'}
                />
              </div>
              <div style={{ ...styles.marginRight, ...styles.option }}>
                <PainTypeEntryOption
                  icon={Constant}
                  label="constant"
                  onClick={this.handleConstant}
                  selected={currSelection === 'constant'}
                />
              </div>
              <div style={styles.option}>
                <PainTypeEntryOption
                  icon={Stabbing}
                  label="stabbing"
                  onClick={this.handleStabbing}
                  selected={currSelection === 'stabbing'}
                />
              </div>
            </div>
            <div style={styles.row}>
              <div style={{ ...styles.marginRight, ...styles.option }}>
                <PainTypeEntryOption
                  icon={Cramping}
                  label="cramping"
                  onClick={this.handleCramping}
                  selected={currSelection === 'cramping'}
                />
              </div>
              <div style={{ ...styles.marginRight, ...styles.option }}>
                <PainTypeEntryOption
                  icon={Burning}
                  label="burning"
                  onClick={this.handleBurning}
                  selected={currSelection === 'burning'}
                />
              </div>
              <div style={styles.option}>
                <PainTypeEntryOption
                  icon={Shooting}
                  label="shooting"
                  onClick={this.handleShooting}
                  selected={currSelection === 'shooting'}
                />
              </div>
            </div>
            <div style={styles.row}>
              <div style={{ ...styles.marginRight, ...styles.option }}>
                <PainTypeEntryOption
                  icon={Tingling}
                  label="tingling"
                  onClick={this.handleTingling}
                  selected={currSelection === 'tingling'}
                />
              </div>
              <div style={{ ...styles.marginRight, ...styles.option }}>
                <PainTypeEntryOption
                  icon={Sharp}
                  label="sharp"
                  onClick={this.handleSharp}
                  selected={currSelection === 'sharp'}
                />
              </div>
              <div style={styles.option}>
                <PainTypeEntryOption
                  icon={Throbbing}
                  label="throbbing"
                  onClick={this.handleThrobbing}
                  selected={currSelection === 'throbbing'}
                />
              </div>
            </div>
            <div style={styles.row}>
              <div style={{ ...styles.marginRight, ...styles.option }} /> {/* added for left padding */}
              <div style={{ ...styles.marginRight, ...styles.option }}>
                <PainTypeEntryOption
                  icon={PinsAndNeedles}
                  label="pins and needles"
                  onClick={this.handlePins_and_needles}
                  selected={currSelection === 'pins_and_needles'}
                />
              </div>
              <div style={styles.option} /> {/* added for right padding */}
            </div>
          </div>
        </div>
      </QuestionLayout>
    );
  }
}

function mapStateToProps(state) {
  const { user, proForms: { painLog } } = state;
  return { user, painLog };
}

export default connect(mapStateToProps, { submitPRO, updatePRO })(PainTypeEntry);
