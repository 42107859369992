import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';

import Page from './page';
import AppBar from '../components/app-bar';
import IconWrapper from '../components/icon-wrapper';
import BloomkidzLayout from '../layouts/bloomkidz';
import { Information } from '../lib/icons';
import { updatePRO } from '../state/pro-forms';
import { bloomkidzStylesObj } from '../lib/bloomkidz';

const styles = {
  ...bloomkidzStylesObj,
  centerText: {
    textAlign: 'center',
  },
};

class BloomkidzClientWellnessProfile2 extends Page {
  handleContinue = () => {
    const { location, updatePRO } = this.props;

    updatePRO({
      type: 'bloomkidzClientWellnessProfile',
      position: this.getTrackIndex(),
      value: { ...this.state },
    });

    this.forwardWithQuery(location.query);
  }

  render() {
    const { classes, router } = this.props;

    return (
      <BloomkidzLayout
        primaryBtnOnTouchTap={this.handleContinue}
        primaryBtnLabel="Next"
      >
        <AppBar
          headerNode="Information"
          backButtonOnClick={() => router.goBack()}
        />
        <IconWrapper>
          <Information />
        </IconWrapper>
        <div className={classes.pageContent}>
          <div className={classes.centerText}>
            BloomKidz creates client wellness profiles to be able to make better decisions toward the overall health and well being of your child during their time with us. Any information you could provide would be very helpful!
          </div>
        </div>
      </BloomkidzLayout>
    );
  }
}

BloomkidzClientWellnessProfile2.propTypes = {
  classes: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  updatePRO: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
};

export default connect(null, { updatePRO })(withStyles(styles)(BloomkidzClientWellnessProfile2));
