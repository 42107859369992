import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';

import Page from './page';
import AppBar from '../components/app-bar';
import BloomkidzLayout from '../layouts/bloomkidz';
import { updatePRO } from '../state/pro-forms';
import { bloomkidzStylesObj, bloomkidzStyles } from '../lib/bloomkidz';
import { fontSizing } from '../lib/styles';

const styles = {
  ...bloomkidzStylesObj,
  centerText: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
  },
  emphasis: {
    fontWeight: 600,
    color: bloomkidzStyles.primaryColor,
  },
  documentCount: {
    margin: '150px 0 20px',
    fontWeight: 600,
    fontSize: fontSizing.h3,
  }
};

class BloomkidzSignatures2 extends Page {
  handleContinue = () => {
    const { location, updatePRO } = this.props;

    updatePRO({
      type: 'bloomkidzSignatures',
      position: this.getTrackIndex(),
      value: { ...this.state },
    });

    this.forwardWithQuery(location.query);
  }

  render() {
    const { classes, router } = this.props;

    return (
      <BloomkidzLayout
        primaryBtnOnTouchTap={this.handleContinue}
        primaryBtnLabel="Start"
      >
        <AppBar
          headerNode=""
          backButtonOnClick={() => router.goBack()}
        />
        <div className={classes.pageContent}>
          <div className={classes.centerText}>
            <div className={classes.documentCount}>1/5</div>
            <div className={classes.emphasis}>HIPAA Notice of Privacy Practices</div>
          </div>
        </div>
      </BloomkidzLayout>
    );
  }
}

BloomkidzSignatures2.propTypes = {
  classes: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  updatePRO: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
};

export default connect(null, { updatePRO })(withStyles(styles)(BloomkidzSignatures2));
