import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get, includes } from 'lodash';

import { custom } from '../lib/styles';
import AppBar from '../components/app-bar';
import Page from './page';
import Layout from '../layouts/common';
import MigrainePainPresentation from '../components/migraine-pain';
import { logEvent } from '../lib/amplitude';

import { addMigraineHx, browseMigraineHx, readMigraineHx } from '../state/migraine-hx';
import { addMigrainePainHx, editMigrainePainHx } from '../state/migraine-pain-hx';
import { updateAvatarUrl } from '../state/user';
import { submitPRO } from '../state/pro-forms';

class MigrainePain extends Page {
  constructor(props) {
    super(props);
    this.handleHeadView = this.handleHeadView.bind(this);
    this.handlePainLevel = this.handlePainLevel.bind(this);
    this.handlePainType = this.handlePainType.bind(this);
    this.handleSliderUpdate = this.handleSliderUpdate.bind(this);
    this.handleContinue = this.handleContinue.bind(this);
    this.handlePainLocationUpdate = this.handlePainLocationUpdate.bind(this);
    const { migraine_hx_id, migraineHx } = props;
    const migraine = migraine_hx_id ? { ...migraineHx } : {};

    this.state = {
      sliderError: '',
      painLocationError: '',
      currentView: get(migraine, 'pain[0].brain_quadrant', 'FRONT'),
      brain_quadrant: get(migraine, 'pain[0].brain_quadrant', 'FRONT'),
      brain_locations: get(migraine, 'pain[0].brain_locations', ''),
      pain_type: get(migraine, 'pain[0].pain_type', 'ACHE'),
      severity: get(migraine, 'pain[0].severity', 0),
      migraine,
    };
  }

  componentWillMount() {
    logEvent('Enter Headache Pain (Continue)');

    browseMigraineHx();
  }

  componentWillReceiveProps(nextProps) {
    const { migraineHx } = nextProps;
    if (migraineHx !== this.props.migraineHx) {
      this.setState({
        currentView: get(migraineHx, 'pain[0].brain_quadrant', 'FRONT'),
        brain_quadrant: get(migraineHx, 'pain[0].brain_quadrant', 'FRONT'),
        brain_locations: get(migraineHx, 'pain[0].brain_locations', ''),
        pain_type: get(migraineHx, 'pain[0].pain_type', 'ACHE'),
        severity: get(migraineHx, 'pain[0].severity', 0),
      });
    }
  }

  handleSliderUpdate(e, severity) {
    this.setState({
      severity,
      sliderError: '',
    });
  }

  handleHeadView(e) {
    this.setState({ currentView: e.currentTarget.value });
  }

  handlePainLevel(e) {
    this.setState({
      painLevel: e.currentTarget.value,
    });
  }

  handlePainType(pain_type) {
    this.setState({ pain_type });
  }

  handlePainLocationUpdate(brain_quadrant, brain_locations) {
    this.setState({
      painLocationError: '',
      brain_quadrant,
      brain_locations,
    });
  }

  handleContinue() {
    const { addMigraineHx, editMigrainePainHx, migraine_hx_id, migraineHx, user } = this.props;
    const { brain_locations, brain_quadrant, pain_type, severity, submitting } = this.state;
    if (submitting) return;
    this.setState({ submitting: true });
    let painLocationError = '';
    let sliderError = '';
    if (!brain_locations || !brain_quadrant) {
      this.setState({ submitting: false });
      painLocationError = (
        <div style={custom.redErrorText}>Please enter a pain location</div>
      );
    }
    if (!severity) {
      this.setState({ submitting: false });
      sliderError = (
        <div style={custom.redErrorText}>Please select a pain level</div>
      );
    }
    if (painLocationError || sliderError) {
      return this.setState({ painLocationError, sliderError, submitting: false });
    }
    if (includes(this.props.location.pathname, 'notification')) {
      const { id } = this.props.location.query;
      return this.props.submitPRO(
        { userId: user.id, id },
        {
          pro_type: 'HEADACHE',
          pro_data: { data: [this.state.brain_quadrant, this.state.brain_locations, this.state.pain_type, this.state.severity] },
        },
        user,
      )
        .then(() => this.forward())
        .catch(() => this.setState({ submitting: false }));
    }
    if (migraine_hx_id) {
      return editMigrainePainHx({
        brain_quadrant,
        brain_locations,
        severity,
        pain_type,
        migraine_hx_id,
        id: migraineHx.pain[0].id,
      })
        .then(({ id, migraine_hx_id }) => {
          this.forwardWithQuery({ migraine_hx_id, migraine_pain_hx_id: id });
        })
        .catch(() => this.setState({ submitting: false }));
    } else {
      return addMigraineHx()
        .then(({ id }) => {
          const newPain = {
            brain_quadrant,
            brain_locations,
            severity,
            pain_type,
            migraine_hx_id: id,
          };
          return this.props.addMigrainePainHx(newPain);
        })
        .then(({ id, migraine_hx_id }) => {
          this.forwardWithQuery({ migraine_hx_id, migraine_pain_hx_id: id });
        })
        .catch(() => this.setState({ submitting: false }));
    }
  }

  onClickBackButton = () => {
    this.backward();
  }

  render() {
    const { brain_quadrant, brain_locations, severity, pain_type, sliderError, painLocationError } = this.state;
    const { addError, editError, readError, personalInfo } = this.props;

    return (
      <Layout>
        <AppBar
          backButtonOnClick={() => this.props.router.goBack()}
          headerNode="Headache Pain"
        />
        <MigrainePainPresentation 
          brain_quadrant={brain_quadrant}
          brain_locations={brain_locations}
          currentView={this.state.currentView}
          severity={severity}
          pain_type={pain_type}
          sliderError={sliderError}
          painLocationError={painLocationError}
          error={addError || editError || readError}
          personalInfo={personalInfo}
          onHeadView={this.handleHeadView}
          onSliderUpdate={this.handleSliderUpdate} 
          onContinue={this.handleContinue}
          onUpdatePainLocation={this.handlePainLocationUpdate}
          onPainType={this.handlePainType}
          submitting={this.state.submitting}
        />
      </Layout>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const { user, personalInfo, migraineHx } = state;
  const migraine_hx_id = get(ownProps, 'location.query.id', null);
  return {
    user,
    personalInfo,
    migraineHx: migraineHx.data[migraine_hx_id],
    migraine_hx_id,
  };
}
MigrainePain.defaultProps = {
};

MigrainePain.propTypes = {
  addMigrainePainHx: PropTypes.func.isRequired,
  addMigraineHx: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, {
  addMigrainePainHx,
  browseMigraineHx,
  readMigraineHx,
  editMigrainePainHx,
  addMigraineHx,
  updateAvatarUrl,
  submitPRO,
})(MigrainePain);
