import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { toLower, upperFirst } from 'lodash';
import LinearProgress from '@material-ui/core/LinearProgress';


import {
  Ache,
  Burning,
  Constant,
  Cramping,
  Dull,
  Numbness,
  Pinching,
  PinsAndNeedles,
  Sharp,
  Shooting,
  Stabbing,
  Throbbing,
  Tingling,
} from '../lib/pain-entry-icons';
import AppBar from '../components/app-bar';
import { colors } from '../lib/styles';
import Continue from '../components/continue';
import { updatePRO } from '../state/pro-forms';
import Page from './page';
import PainTypeEntryOption from '../components/pain-type-entry-option';

const painTypes = [
  'ache',
  'numbness',
  'pinching',
  'dull',
  'constant',
  'stabbing',
  'cramping',
  'burning',
  'shooting',
  'tingling',
  'sharp',
  'throbbing',
  'pins_and_needles',
];

const styles = {
  appBar: {
    height: '8.75rem',
    paddingLeft: '1.5rem',
    paddingRight: '1.5rem',
  },
  backButton: {
    left: '-1.25rem',
    height: '3.6875rem',
    padding: 0,
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    width: '3.6875rem',
  },
  backButtonIcon: {
    height: '3.6875rem',
    width: '3.6875rem',
  },
  continueButton: {
    background: colors.secondaryColor,
    marginBottom: '7.5vw',
  },
  continueWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
  header: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    width: '100%',
  },
  headerTitle: {
    color: colors.black,
    display: 'inline-block',
    fontWeight: 'bold',
    fontSize: '1.8rem',
  },
  marginRight: {
    marginRight: '1.25rem',
  },
  nextButton: {
    position: 'absolute',
    right: 5,
    textAlign: 'right',
    top: '50%',
    transform: 'translateY(-50%)',
  },
  nextButtonLabel: {
    color: colors.black,
    fontSize: '1.6rem',
    padding: 0,
    textTransform: 'none',
  },
  option: {
    flexBasis: 1,
    flexGrow: 1,
    flexShrink: 1,
  },
  options: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  PainTypeEntryOptions: {
    margin: '2.5rem',
  },
  painSelectionHeader: {
    background: colors.painSelectionHeaderBackground,
    color: colors.white,
    fontSize: '1.5rem',
    height: '100%',
    lineHeight: '2rem',
    textAlign: 'center',
  },
  rootContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minHeight: '100%',
  },
  row: {
    display: 'flex',
    marginBottom: '3rem',
    width: '100%',
  },
};

const AppBarContinueButton = props => (
  <Button
    onClick={props.onClick}
    style={{ ...styles.nextButton, ...styles.nextButtonLabel }}
  >Next
  </Button>
);
AppBarContinueButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

class BodyPainPro3 extends Page {
  constructor(props) {
    super(props);

    this.state = {
      currSelection: [],
    };

    painTypes.forEach((type) => {
      const funcName = `handle${upperFirst(type)}`;
      this[funcName] = this.handleSelection.bind(this, type);
    });

    this.handleClickBack = this.handleClickBack.bind(this);
    this.handleClickContinue = this.handleClickContinue.bind(this);
    this.handleSelection = this.handleSelection.bind(this);

    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  componentWillMount() {
    const { bodyPain } = this.props;
    const pain_types = bodyPain[this.getTrackIndex()];

    if (pain_types) {
      this.setState({ currSelection: toLower(pain_types) });
    }
  }

  handleClickBack() {
    this.backwardWithQuery(this.props.location.query);
  }

  handleClickContinue() {
    const { currSelection } = this.state;

    this.props.updatePRO({ type: 'bodyPain', position: this.getTrackIndex(), value: currSelection });
    this.forwardWithQuery(this.props.location.query);
  }

  handleSelection(type) {
    const { currSelection } = this.state;
    const index = currSelection.indexOf(type);
    index > -1 ? currSelection.splice(index, 1) : currSelection.push(type);

    this.setState({ currSelection });
  }

  render() {
    const { currSelection } = this.state;

    return (
      <div style={styles.rootContainer}>
        <div>
          <div>
            <AppBar
              backButtonOnClick={() => this.props.router.goBack()}
              headerNode="Pain Type"
              rightNode={(
                <AppBarContinueButton
                  onClick={this.handleClickContinue}
                />
              )}
            />
            <LinearProgress
              variant="determinate"
              value={(this.getCurrentPage() / this.getTotalPages()) * 100}
            />
            <div style={styles.painSelectionHeader}>
              Tap the type of pain you are experiencing. <br />
              You can tap multiple pain types.
            </div>
            <div style={styles.PainTypeEntryOptions}>
              <div style={styles.row}>
                <div style={{ ...styles.marginRight, ...styles.option }}>
                  <PainTypeEntryOption
                    icon={Ache}
                    label="ache"
                    onClick={this.handleAche}
                    selected={currSelection.includes('ache')}
                  />
                </div>
                <div style={{ ...styles.marginRight, ...styles.option }}>
                  <PainTypeEntryOption
                    icon={Numbness}
                    label="numbness"
                    onClick={this.handleNumbness}
                    selected={currSelection.includes('numbness')}
                  />
                </div>
                <div style={styles.option}>
                  <PainTypeEntryOption
                    icon={Pinching}
                    label="pinching"
                    onClick={this.handlePinching}
                    selected={currSelection.includes('pinching')}
                  />
                </div>
              </div>
              <div style={styles.row}>
                <div style={{ ...styles.marginRight, ...styles.option }}>
                  <PainTypeEntryOption
                    changeBackground={true}
                    icon={Dull}
                    label="dull"
                    onClick={this.handleDull}
                    selected={currSelection.includes('dull')}
                  />
                </div>
                <div style={{ ...styles.marginRight, ...styles.option }}>
                  <PainTypeEntryOption
                    icon={Constant}
                    label="constant"
                    onClick={this.handleConstant}
                    selected={currSelection.includes('constant')}
                  />
                </div>
                <div style={styles.option}>
                  <PainTypeEntryOption
                    icon={Stabbing}
                    label="stabbing"
                    onClick={this.handleStabbing}
                    selected={currSelection.includes('stabbing')}
                  />
                </div>
              </div>
              <div style={styles.row}>
                <div style={{ ...styles.marginRight, ...styles.option }}>
                  <PainTypeEntryOption
                    icon={Cramping}
                    label="cramping"
                    onClick={this.handleCramping}
                    selected={currSelection.includes('cramping')}
                  />
                </div>
                <div style={{ ...styles.marginRight, ...styles.option }}>
                  <PainTypeEntryOption
                    icon={Burning}
                    label="burning"
                    onClick={this.handleBurning}
                    selected={currSelection.includes('burning')}
                  />
                </div>
                <div style={styles.option}>
                  <PainTypeEntryOption
                    icon={Shooting}
                    label="shooting"
                    onClick={this.handleShooting}
                    selected={currSelection.includes('shooting')}
                  />
                </div>
              </div>
              <div style={styles.row}>
                <div style={{ ...styles.marginRight, ...styles.option }}>
                  <PainTypeEntryOption
                    icon={Tingling}
                    label="tingling"
                    onClick={this.handleTingling}
                    selected={currSelection.includes('tingling')}
                  />
                </div>
                <div style={{ ...styles.marginRight, ...styles.option }}>
                  <PainTypeEntryOption
                    icon={Sharp}
                    label="sharp"
                    onClick={this.handleSharp}
                    selected={currSelection.includes('sharp')}
                  />
                </div>
                <div style={styles.option}>
                  <PainTypeEntryOption
                    icon={Throbbing}
                    label="throbbing"
                    onClick={this.handleThrobbing}
                    selected={currSelection.includes('throbbing')}
                  />
                </div>
              </div>
              <div style={styles.row}>
                <div style={{ ...styles.marginRight, ...styles.option }} /> {/* added for left padding */}
                <div style={{ ...styles.marginRight, ...styles.option }}>
                  <PainTypeEntryOption
                    icon={PinsAndNeedles}
                    label="pins and needles"
                    onClick={this.handlePins_and_needles}
                    selected={currSelection.includes('pins_and_needles')}
                  />
                </div>
                <div style={styles.option} /> {/* added for right padding */}
              </div>
            </div>
          </div>
        </div>
        <div style={styles.continueWrapper}>
          <Continue
            onClick={this.handleClickContinue}
            btnStyle={styles.continueButton}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { user, proForms: { bodyPain } } = state;
  return { user, bodyPain };
}

BodyPainPro3.proptypes = {
  user: PropTypes.object.isRequired,
  bodyPain: PropTypes.array.isRequired,
  route: PropTypes.object.isRequired,
};
export default connect(mapStateToProps, { updatePRO })(BodyPainPro3);
