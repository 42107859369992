import React from 'react';
import { connect } from 'react-redux';
import { IconButton, Paper } from '@material-ui/core';
import AlertWarning from '@material-ui/icons/Clear';
import NavigationCheck from '@material-ui/icons/Check';
import { get } from 'lodash';
import moment from 'moment';

import { browseFormCompletion } from '../state/form-completion';
import { fetchUserReport, updateAvatarUrl } from '../state/user';
import DashboardPain from './dashboard-pain';
import Continue from './continue';
import EditIconGray from './icons/edit-gray';
import { custom, colors, mainDashboard } from '../lib/styles';
import { logEvent } from '../lib/amplitude';
import Page from '../pages/page';
import { personalInfoIfNeeded } from '../state/personal-info';
import { fetchCalories, fetchSteps } from '../state/cordova';
import { readMostRecentPainHx } from '../state/pain-hx';

const { optionsBorder, titles, titlesMuted } = mainDashboard.colors;

const baseStyles = {
  alert: {
    color: colors.errorRed,
  },
  btn: {
    margin: '10px 0px',
  },
  btns: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    margin: '10px 0px 5px 0px',
  },
  check: {
    color: colors.successGreen,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  healthKit: {
    backgroundColor: colors.questionBackground,
    display: 'flex',
    flexDirection: 'column',
    marginBottom:'4.5rem',
  },
  label: {
    fontSize: '10.5px',
    fontWeight: 'bold',
  },
  labelStyle: {
    textTransform: 'capitalize',
  },
  leftIcon: {
    display: 'none',
  },
  calories: {
    marginRight: '2em',
  },
  caloriesAndStepsWrapper: {
    display: 'flex',
    margin: '2rem',
  },
  calendarWrapper: {
    padding: '40px 30px',
  },
  chart: {
    color: colors.black,
    marginLeft: '20px',
    flex: '.5',
    textAlign: 'left',
    fontSize: '1.4rem',
  },
  charts: {
    margin: '2rem',
  },
  paragraph2: {
    marginBottom: '0px',
    textAlign: 'center',
    marginLeft: '15px',
    marginRight: '15px',
    fontSize: '1.4rem',
    marginTop: '2rem',
  },
  sectionOptions: {
    borderBottom: `solid 1px ${optionsBorder}`,
    display: 'flex',
    justifyContent: 'space-around',
  },
  sectionTitle: {
    height: '8.9rem',
    width: '100%',
    textTransform: 'none',
  },
  sectionTitleLabel: {
    color: titles,
    fontSize: '1.875rem',
    fontWeight: 'bold',
    letterSpacing: '-0.05rem',
    paddingLeft: 0,
    paddingRight: 0,
    textTransform: 'none',
  },
  sectionTitleLabelMuted: {
    color: titlesMuted,
  },
  shareHealthBtn: {
    marginTop: '1rem',
    marginBottom: '1rem',
    borderRadius: '25px',
    height: '5rem',
    alignSelf: 'center',
  },
  shareHealthLabel: {
    fontSize: '1.4rem',
  },
  wrapper: {
    backgroundColor: colors.questionBackground,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minHeight: '100%',
    width: '100%',
  },
};

function formatCompletion(data, type) {
  if (!data || !data[type]) {
    return '';
  }
  const { percentage } = data[type];
  return (percentage < 1
    ? (
      <span>
        <AlertWarning
          style={baseStyles.alert}
        />
      </span>)
    : (
      <span>
        <NavigationCheck
          style={baseStyles.check}
        />
      </span>
    ));
}

class DashboardView extends Page {
  constructor(props) {
    super(props);

    const currDate = new Date();
    const { month, year } = get(props.location.state, 'informedDashboard', {});
    const calendarMonth = month ? parseInt(month, 10) : currDate.getMonth();
    const calendarYear = year ? parseInt(year, 10) : currDate.getFullYear();
    const section = month ? 'painLogs' : 'painOverview';

    this.state = {
      calendarMonth,
      calendarYear,
      intensity: 0,
      painDuration: 0,
      painHxId: undefined,
      pain_end_date: undefined,
      pain_start_date: undefined,
      section,
    };

    this.handlePainLogsClick = this.handlePainLogsClick.bind(this);
    this.handlePainOverviewClick = this.handlePainOverviewClick.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.mostRecentPainEntry.length !== 0) {
      const [mostRecentPainEntry] = nextProps.mostRecentPainEntry;
      const {
        id: painHxId,
        intensity,
        pain_end_date,
        pain_start_date,
        created_at,
      } = mostRecentPainEntry;

      // Formatted date and time displays for carousel
      const painDisplayDate = moment(created_at).format('MM/DD/YYYY');
      const painDisplayTime = moment(created_at).format('h:mm a');

      const startHours = new Date(pain_start_date).getHours();
      const endHours = new Date(pain_end_date).getHours();
      let painDuration;
      if (endHours >= startHours) {
        painDuration = endHours - startHours;
      } else {
        painDuration = (23 - startHours) + endHours;
      }

      this.setState({
        created_at,
        painDisplayIntensity: intensity,
        painDisplayDate,
        painDisplayTime,
        painDuration,
        painHxId,
        pain_end_date,
        pain_start_date,
      });
    }
  }

  componentWillMount() {
    const {
      browseFormCompletion,
      fetchCalories,
      fetchSteps,
      personalInfoIfNeeded,
      readMostRecentPainHx,
    } = this.props;
    const today = moment().format('YYYY-MM-DD');
    browseFormCompletion();
    personalInfoIfNeeded();
    fetchCalories(today);
    fetchSteps(today);
    readMostRecentPainHx();
    this.setState({ today });
  }

  handlePainLogsClick() {
    this.setState({ section: 'painLogs' });
  }

  handlePainOverviewClick() {
    this.setState({ section: 'painOverview' });
  }

  render() {
    const {
      painDisplayIntensity,
      painDisplayDate,
      painDisplayTime,
      painHxId,
    } = this.state;
    const { data: formData } = this.props.formCompletion;

    return (
      <section style={baseStyles.wrapper}>
        <section style={baseStyles.healthKit}>
          <DashboardPain
            headData={this.props.headData}
            imTooBusyHandler={this.props.readMostRecentPainHx}
            painDisplayIntensity={painDisplayIntensity}
            painDisplayDate={painDisplayDate}
            painDisplayTime={painDisplayTime}
            painHxId={painHxId}
            router={this.props.router}
            mostRecentPain={this.props.mostRecentPain}
            painType={this.props.painType}
          />
          <Continue
            btnStyle={baseStyles.shareHealthBtn}
            text="Share My Health Record"
            labelStyle={baseStyles.shareHealthLabel}
            onClick={() => this.props.router.push('/share-data')}
          />
          <Paper
            onClick={() => {
              logEvent('Dashboard - About Me');
              this.setTrack('ABOUT_ME');
            }}
            style={mainDashboard.paperRowWhite}
            elevation={4}
            square={true}
          >
            <div style={baseStyles.chart}>About Me</div>
            <div>{formatCompletion(formData, 'ABOUT_ME')}</div>
            <IconButton
              style={custom.marginRight15}
            >
              <EditIconGray />
            </IconButton>
          </Paper>
          <Paper
            onClick={() => { this.props.router.push('/reason'); }}
            style={mainDashboard.paperRowWhite}
            elevation={4}
            square={true}
          >
            <div style={baseStyles.chart}>Reason for Visit</div>
            {/* Need to change the following to 'REASON FOR VISIT' */}
            <div>{formatCompletion(formData, 'MEDICATION')}</div>
            <IconButton
              style={custom.marginRight15}
            >
              <EditIconGray />
            </IconButton>
          </Paper>
          <Paper
            onClick={() => {
              logEvent('Dashboard - Symptoms');
              this.setTrack('SYMPTOMS');
            }}
            style={mainDashboard.paperRowWhite}
            elevation={4}
            square={true}
          >
            <div style={baseStyles.chart}>Symptoms</div>
            <div>{formatCompletion(formData, 'CURRENT_STATUS')}</div>
            <IconButton
              style={custom.marginRight15}
            >
              <EditIconGray />
            </IconButton>
          </Paper>
          <Paper
            onClick={() => {
              logEvent('Dashboard - Medications');
              this.setTrack('MEDICATION');
            }}
            style={mainDashboard.paperRowWhite}
            elevation={4}
            square={true}
          >
            <div style={baseStyles.chart}>Medications</div>
            <div>{formatCompletion(formData, 'MEDICATION')}</div>
            <IconButton
              style={custom.marginRight15}
            >
              <EditIconGray />
            </IconButton>
          </Paper>
          <Paper
            onClick={() => {
              logEvent('Dashboard - Medical History');
              this.setTrack('MEDICAL_HISTORY');
            }}
            style={mainDashboard.paperRowWhite}
            elevation={4}
            square={true}
          >
            <div style={baseStyles.chart}>Medical History</div>
            <div>{formatCompletion(formData, 'MEDICAL_HISTORY')}</div>
            <IconButton
              style={custom.marginRight15}
            >
              <EditIconGray />
            </IconButton>
          </Paper>
          <Continue
            btnStyle={{ ...baseStyles.shareHealthBtn, backgroundColor: colors.calendarGreen }}
            labelStyle={baseStyles.shareHealthLabel}
            text="Update Reason For Visit"
            onClick={() => this.props.router.push('/reason')}
          />
        </section>
      </section>
    );
  }
}

function mapStateToProps(state) {
  const {
    cordova,
    formCompletion,
    migrainePainHx,
    painHx: { mostRecentPainEntry },
    migrainePainHx: { mostRecentMigraineEntry },
    personalInfo,
    user,
    recentPain,
  } = state;

  return {
    calories: cordova.caloriesData,
    formCompletion,
    migrainePainHx,
    mostRecentMigraineEntry,
    mostRecentPainEntry,
    personalInfo,
    recentPain,
    steps: cordova.stepsData,
    user,
  };
}

export default connect(mapStateToProps, {
  fetchCalories,
  fetchSteps,
  fetchUserReport,
  browseFormCompletion,
  personalInfoIfNeeded,
  readMostRecentPainHx,
  updateAvatarUrl,
})(DashboardView);
