import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import LinearProgress from '@material-ui/core/LinearProgress';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { withStyles } from '@material-ui/core/styles';

import AppBar from '../components/app-bar';
import Continue from '../components/continue';

import Page from './page';

import { updatePRO } from '../state/pro-forms';

const styles = {
  button: {
    margin: 5,
    width: 150,
    height: 40,
    fontSize: 16,
  },
  buttonGroup: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  formControlLabelRoot: {
    alignItems: 'start',
    marginTop: 5,
    '& .MuiSvgIcon-root': {
      height: 20,
      width: 20,
    },
  },
  iconContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    flexGrow: 0,
    flexShrink: 0,
    width: '100%',
    color: '#000',
    paddingTop: 10,
  },
  iconImage: {
    float: 'left',
    backgroundImage: 'none',
    width: '100%',
    height: '100%',
  },
  iconWrapper: {
    width: 100,
  },
  radio: {
    paddingTop: 4,
  },
  radioLabel: {
    fontSize: '2rem',
  },
  titleGroup: {
    margin: 25,
  },
  text: {
    fontSize: '2rem',
  },
  nextBtn: {
    textAlign: 'center',
    width: '100%',
    top: '90%',
  },
};

class FullDataCollection6 extends Page {
  constructor(props) {
    super(props);

    this.state = {
      makeup: null,
      makeupAmount: null,
    };
  }

  handleContinue = () => {
    const { updatePRO, location: { query } } = this.props;

    updatePRO({
      type: 'fullDataCollection',
      position: this.getTrackIndex(),
      value: this.state });
    this.forwardWithQuery(query);
  }

  handleChange = (e) => {
    const { value } = e.target;
    this.setState({
      makeupAmount: value,
    });
  };

  render() {
    const { classes } = this.props;
    const { makeup, makeupAmount } = this.state;

    let disabled = true;

    if (makeup === false) {
      disabled = false;
    } else if (makeup === true && makeupAmount !== null) {
      disabled = false;
    }

    return (
      <Fragment>
        <AppBar
          headerNode="Make Up"
          noLeftNode={true}
        />
        <LinearProgress
          variant="determinate"
          value={(this.getCurrentPage() / this.getTotalPages()) * 100}
        />
        <div className={classes.iconContainer}>
          <div className={classes.iconWrapper}>
            <img className={classes.iconImage} src="//pic.onlinewebfonts.com/svg/img_279826.png" alt="Talent Show Makeup" />
          </div>
        </div>

        <div className={classes.titleGroup}>
          <p className={classes.text}>Are you wearing makeup (foundation, concealer, or blush)?</p>
          <div className={classes.buttonGroup}>
            <Button className={classes.button} variant="contained" color={makeup ? 'primary' : 'default'} onClick={() => this.setState({ makeup: true })}>Yes</Button>
            <Button className={classes.button} variant="contained" color={makeup === false ? 'primary' : 'default'} onClick={() => this.setState({ makeup: false, makeupAmount: null })}>No</Button>
          </div>
        </div>

        <div className={`${makeup ? '' : 'hidden'} ${classes.titleGroup}`}>
          <FormControl component="fieldset">
            <p className={classes.text}>How much?</p>
            <RadioGroup aria-label="makeupAmount" name="makeupAmount" value={makeupAmount} onChange={this.handleChange}>
              <FormControlLabel
                value="light"
                control={<Radio classes={{ root: classes.radio }} />}
                label="Light Coverage (powder or tinted moisturizer)"
                classes={{ root: classes.formControlLabelRoot, label: classes.radioLabel }}
              />
              <FormControlLabel
                value="medium"
                control={<Radio classes={{ root: classes.radio }} />}
                label="Medium Coverage (foundation)"
                classes={{ root: classes.formControlLabelRoot, label: classes.radioLabel }}
              />
              <FormControlLabel
                value="heavy"
                control={<Radio classes={{ root: classes.radio }} />}
                label="Heavy Coverage (combination of the above plus contouring or blush)"
                classes={{ root: classes.formControlLabelRoot, label: classes.radioLabel }}
              />
            </RadioGroup>
          </FormControl>
        </div>

        <div className={classes.nextBtn}>
          <Continue
            disabled={disabled}
            text="Next"
            onClick={this.handleContinue}
          />
        </div>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { user, proForms: { fullDataCollection } } = state;
  return { user, fullDataCollection };
}

FullDataCollection6.proptypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  updatePRO: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { updatePRO })(withStyles(styles)(FullDataCollection6));
