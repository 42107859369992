import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { IconButton, TextField } from '@material-ui/core';
import { connect } from 'react-redux';

import Page from './page';
import AppBar from '../components/app-bar';
import IconWrapper from '../components/icon-wrapper';
import BloomkidzLayout from '../layouts/bloomkidz';
import { Pills, XInCircle } from '../lib/icons';
import { fontSizing } from '../lib/styles';
import { updatePRO } from '../state/pro-forms';
import { bloomkidzStyles, bloomkidzStylesObj } from '../lib/bloomkidz';

const styles = {
  ...bloomkidzStylesObj,
  medicationButton: {
    marginBottom: 30,
    marginTop: 10,
  },
  medicationListText: {
    marginBottom: 10,
  },
  medicationListItem: {
    alignItems: 'center',
    background: 'white',
    display: 'flex',
    marginBottom: 10,
    marginTop: 10,
  },
  medicationListItemText: {
    flexGrow: 1,
    paddingLeft: 10,
  },
  iconButton: {
    height: 50,
    width: 50,
  },
};

class BloomkidzMedicalHistory3 extends Page {
  constructor(props) {
    super(props);

    const stateFromRedux = props.bloomkidzMedicalHistory[this.getTrackIndex()] || {};

    this.state = {
      medications: [],
      curMedication: {
        medication: '',
        dose: '',
        reason: '',
        physician: '',
        length: '',
      },
      ...stateFromRedux,
    };
  }

  handleTextFieldChange = (e) => {
    const { name, value } = e.target;
    this.setState((curState) => {
      return {
        curMedication: {
          ...curState.curMedication,
          [name]: value,
        },
      };
    });
  };


  handleContinue = () => {
    const { location, updatePRO } = this.props;

    updatePRO({
      type: 'bloomkidzMedicalHistory',
      position: this.getTrackIndex(),
      value: { ...this.state },
    });

    this.forwardWithQuery(location.query);
  }

  addMedication = () => {
    const { medications, curMedication } = this.state;
    if (!curMedication.medication || !curMedication.dose || !curMedication.reason || !curMedication.physician || !curMedication.length) {
      return;
    }
    if (curMedication) {
      this.setState({
        medications: [...medications, curMedication],
        curMedication: { medication: '', dose: '', reason: '', physician: '', length: '' },
      });
    }
  }

  removeMedication = (medication) => {
    this.setState(curState => ({
      medications: curState.medications.filter(curMedication => curMedication !== medication),
    }));
  }

  render() {
    const { classes, router } = this.props;
    const { medications, curMedication } = this.state;

    const buttonClass = curMedication
      ? `${classes.buttonGreenBackground} ${classes.medicationButton}`
      : `${classes.buttonWithBorder} ${classes.medicationButton}`;

    return (
      <BloomkidzLayout
        primaryBtnOnTouchTap={this.handleContinue}
        primaryBtnLabel={medications.length ? 'Next' : 'Skip'}
      >
        <AppBar
          headerNode="Medications/Supplements"
          backButtonOnClick={() => router.goBack()}
        />
        <IconWrapper>
          <Pills />
        </IconWrapper>
        <div className={classes.pageContent}>
          <div>Medications/Supplements</div>
          <div className={classes.paddingHorizontal}>
            <div className={classes.textFieldWrapper}>
              <TextField
                placeholder="Medication/Supplement"
                value={curMedication.medication}
                onChange={this.handleTextFieldChange}
                name="medication"
                variant="standard"
                fullWidth
                inputProps={{ style: { fontSize: fontSizing.body, fontFamily: bloomkidzStyles.fontFamily } }}
              />
            </div>
            <div className={classes.textFieldWrapper}>
              <TextField
                placeholder="Dose"
                value={curMedication.dose}
                onChange={this.handleTextFieldChange}
                name="dose"
                variant="standard"
                fullWidth
                inputProps={{ style: { fontSize: fontSizing.body, fontFamily: bloomkidzStyles.fontFamily } }}
              />
            </div>
            <div className={classes.textFieldWrapper}>
              <TextField
                placeholder="Reason"
                value={curMedication.reason}
                onChange={this.handleTextFieldChange}
                name="reason"
                variant="standard"
                fullWidth
                inputProps={{ style: { fontSize: fontSizing.body, fontFamily: bloomkidzStyles.fontFamily } }}
              />
            </div>
            <div className={classes.textFieldWrapper}>
              <TextField
                placeholder="Prescribing Physician"
                value={curMedication.physician}
                onChange={this.handleTextFieldChange}
                name="physician"
                variant="standard"
                fullWidth
                inputProps={{ style: { fontSize: fontSizing.body, fontFamily: bloomkidzStyles.fontFamily } }}
              />
            </div>
            <div className={classes.textFieldWrapper}>
              <TextField
                placeholder="How long has your child taken this?"
                value={curMedication.length}
                onChange={this.handleTextFieldChange}
                name="length"
                variant="standard"
                fullWidth
                inputProps={{ style: { fontSize: fontSizing.body, fontFamily: bloomkidzStyles.fontFamily } }}
              />
            </div>
          </div>
          <button
            className={buttonClass}
            onClick={this.addMedication}
            type="button"
          >
            + Add Medication
          </button>
          <div className={classes.medicationListText}>
            Medication List
          </div>
          {medications.length ? (
            <div className={classes.paddingHorizontal}>
              {medications.map(medication => (
                <div className={classes.medicationListItem}>
                  <div className={classes.medicationListItemText} key={medication.medication}>{`${medication.medication} | ${medication.dose}`}</div>
                  <IconButton
                    className={classes.iconButton}
                    onClick={() => this.removeMedication(medication)}
                  >
                    <XInCircle />
                  </IconButton>
                </div>
              ))}
            </div>
          ) : (
            <div className={classes.greyText}><i>No Medications/Supplements</i></div>
          )}
        </div>
      </BloomkidzLayout>
    );
  }
}

BloomkidzMedicalHistory3.propTypes = {
  classes: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  updatePRO: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const {
    proForms: { bloomkidzMedicalHistory },
  } = state;

  return { bloomkidzMedicalHistory };
};

export default connect(mapStateToProps, { updatePRO })(withStyles(styles)(BloomkidzMedicalHistory3));
