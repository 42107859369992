import React from 'react';
import { connect } from 'react-redux';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
import { map, omit } from 'lodash';

import AppBar from '../components/app-bar';
import Page from './page';
import Layout from '../layouts/common';
import { fetchClinicsIfNecessary } from '../state/app-data';
import { colors, custom } from '../lib/styles';
import { addUserClinic } from '../state/patient-clinics';

const baseStyles = {
  center: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  flexStart: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  marginLeft: {
    marginLeft: '15px',
  },
  marginTop: {
    marginTop: '30px',
  },
  menuItem: {
    color: colors.primaryColor,
  },
  highlightDark: {
    color: colors.highlightDark,
  },
  clinicDetails: {
    fontSize: '10px',
    color: colors.primaryColor,
    textAlign: 'left',
    marginLeft: '15px',
  },
  redErrorText: {
    color: colors.errorRed,
    marginLeft: '15px',
    marginRight: '15px',
    textAlign: 'center',
  },
  style: {
    width: '90%',
    margin: '15px',
  },
  text: {
    color: colors.primaryColor,
  },
  whiteBackgroundSection: {
    backgroundColor: colors.white,
    textAlign: 'left',
  },
};

class UserClinics extends Page {
  constructor(props) {
    super(props);
    this.handleSelectItem = this.handleSelectItem.bind(this);
  }

  componentWillMount() {
    this.props.fetchClinicsIfNecessary();
    const { clinic_id } = this.props.location.query;
    this.setState({
      clinicNotFound: false,
    });
    if (clinic_id) {
      this.props.addUserClinic(clinic_id)
        .then(() => this.props.router.push(`/clinics/${clinic_id}/splash`));
    }
  }

  handleSelectItem(e, clinic) {
    if (typeof clinic === 'string') {
      return this.setState({
        clinicNotFound: true,
      });
    }
    this.props.addUserClinic(clinic.id)
      .then(() => this.props.router.push(`/clinics/${clinic.id}/splash`));
  }

  render() {
    return (
      <Layout>
        <AppBar
          backButtonOnClick={() => this.props.router.push('/personalinfo')}
          headerNode="Clinics"
        />
        <section>
          <p style={baseStyles.clinicDetails}>
            CLINIC SELECTION
          </p>
        </section>
        <section className="form" style={baseStyles.whiteBackgroundSection}>
          <Autocomplete
            renderInput={params => <TextField {...params} label="Search Clinics" />}
            options={this.props.clinics}
            getOptionLabel={option => option.name}
            style={baseStyles.style}
            fullWidth={true}
            onChange={this.handleSelectItem}
          />
        </section>
        {this.state.clinicNotFound
          ? (
            <div style={custom.redErrorText}>
            The clinic you entered is not a Mindset clinic! Please press the back arrow or select a different clinic.
            </div>
          )
          : null
        }
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  const { user, appData, patientClinics } = state;
  const myClinicIds = Object.keys(patientClinics.data);
  const validClinics = omit(appData.clinics, myClinicIds);

  return {
    user,
    clinics: map(validClinics, clinic => clinic),
  };
}

UserClinics.defaultProps = { clinics: [] };

export default connect(mapStateToProps, { fetchClinicsIfNecessary, addUserClinic })(UserClinics);
