import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { withTranslation } from 'react-i18next';
import { title, instructions, healthy, mild, severe, nextBtn } from '../lib/i18next';

import Page from './page';
import { PersonalCare } from '../lib/icons';
import Question from '../components/question';
import { colors } from '../lib/styles';
import { updatePRO } from '../state/pro-forms';

const options = [
  {
    label: healthy('eqSelfCare'),
    value: 1,
  },
  {
    label: mild('eqSelfCare'),
    value: 2,
  },
  {
    label: severe('eqSelfCare'),
    value: 3,
  },
];
const { questionBackground } = colors;

class EqSelfCare extends Page {
  onClickBackButton = () => {
    this.backward();
  }

  onSubmission = () => {
    this.forwardWithQuery(this.props.location.query);
  }

  render() {
    const value = get(this.props.eq5d3l, this.getTrackIndex(), null);
    return (
      <Question
        backgroundColor={questionBackground}
        currentPage={this.getCurrentPage()}
        image={PersonalCare}
        onClickBackButton={() => this.props.router.goBack()}
        onChange={this.props.updatePRO}
        onSubmission={this.onSubmission}
        options={options}
        title={title('eqSelfCare')}
        buttonLabel={nextBtn()}
        instructions={instructions('eqSelfCare')}
        totalPages={this.getTotalPages()}
        trackIndex={this.getTrackIndex()}
        type="eq5d3l"
        value={value}
      />
    );
  }
}

function mapStateToProps(state) {
  const { proForms: { eq5d3l } } = state;
  return { eq5d3l };
}

EqSelfCare.propTypes = {
  eq5d3l: PropTypes.array.isRequired,
  updatePRO: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { updatePRO })(withTranslation('eqSelfCare')(EqSelfCare));
