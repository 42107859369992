import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { IconButton } from '@material-ui/core/';
import MenuIcon from '@material-ui/icons/Menu';
import { browserHistory } from 'react-router';

import { colors, fontSizing } from '../lib/styles';
import { CircleExclamationSolid } from '../lib/icons';
import { throttledReset } from '../initializers/activity';
import { removeProInNewTabListener } from '../lib/pro-in-new-tab';
import AppBar from '../components/app-bar';

const styles = {
  continueButtonWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
  exclamationWrapper: {
    marginTop: 25,
    marginBottom: 5,
    width: '8rem',
    height: '8rem',
    display: 'inline-block',
    marginRight: 10,
    '& path': {
      fill: colors.errorRed,
    },
  },
  header: {
    fontSize: fontSizing.h3,
    fontWeight: 'bold',
  },
  page: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 700,
    marginLeft: 'auto',
    marginRight: 'auto',
    minHeight: '100%',
    textAlign: 'center',
  },
  pageContent: {
    flexGrow: 1,
    fontSize: fontSizing.body,
    paddingLeft: 10,
    paddingRight: 10,
  },
};

class InvalidPage extends React.Component {
  constructor(props) {
    super(props);

    this.backButtonHandler = () => {
      window.history.go(1);
    };
    window.addEventListener('popstate', this.backButtonHandler, true);

    this.activityTimer = setInterval(throttledReset, 10000);
    removeProInNewTabListener();
  }

  componentWillUnmount = () => {
    clearInterval(this.activityTimer);
  }

  render() {
    const { classes, trackType } = this.props;

    const showAppBar = trackType === 'notification-VITAL-CORE';

    return (
      <div className={classes.page}>
        {showAppBar ? (
          <AppBar
            noLeftNode={true}
            headerNode="Page Not Found"
            rightNode={(
              <IconButton onClick={() => browserHistory.push('/core-vital-user-manual-menu')}>
                <MenuIcon fontSize="large" />
              </IconButton>
            )}
          />
        ) : null}
        <div className={classes.pageContent}>
          <div className={classes.exclamationWrapper}>
            <CircleExclamationSolid />
          </div>
          <p><strong>Invalid Page</strong></p>
          <p>This page does not exist.</p>
          <p>Please <strong>CLOSE</strong> this browser window.</p>
        </div>
      </div>
    );
  }
}

InvalidPage.propTypes = {
  classes: PropTypes.object.isRequired,
  trackType: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => {
  const { user: { trackType } } = state;

  return { trackType };
};

export default connect(mapStateToProps)(withStyles(styles)(InvalidPage));
