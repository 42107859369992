import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MaterialAppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import { noop } from 'lodash';

import { colors } from '../lib/styles';

const styles = {
  appBar: {
    backgroundColor: colors.white,
    minHeight: '6.8rem',
    paddingLeft: '1.5rem',
    paddingRight: '1.5rem',
  },
  header: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    width: '100%',
  },
  headerTitle: {
    color: colors.black,
    display: 'inline-block',
    fontWeight: 'bold',
    fontSize: 22,
    maxWidth: '75%',
    textAlign: 'center',
  },
  leftNodeWrapper: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
  },
  rightNodeWrapper: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
  },
};

const backButtonStyles = {
  backButton: {
    left: '-1.25rem',
    height: '3.6875rem',
    padding: 0,
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    width: '3.6875rem',
  },
  backButtonIcon: {
    height: '3.6875rem',
    width: '3.6875rem',
  },
};

const BackButton = props => (
  <IconButton
    onClick={props.onClick}
    style={backButtonStyles.backButton}
  >
    <KeyboardArrowLeft style={backButtonStyles.backButtonIcon} />
  </IconButton>
);
BackButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

/*
 * The AppBar is designed to take a headerNode prop that will be centered in the app bar
 * via flexbox. The leftNode is positioned absolutley all the way to the left, but can be moved to the left or right
 * with the leftNodeOffset prop. The rightNode is positioned absolutely all the way to the right, and can be moved
 * left or right with the rightNodeOffset prop. If the headerNode is a long enough string, the text
 * will start overlapping the leftNode and/or the rightNode. To prevent this, add left and right padding
 * to the app bar by using the headerPadding prop, making sure that the padding is wider than both
 * leftNode and rightNode. Because many pages have the back arrow in their app bars, a back arrow will render
 * by default unless a leftNode prop is passed in. The backButtonClick prop is the function that will
 * be called when the default back arrow is pressed/clicked.
 */

class AppBar extends Component {
  render() {
    const {
      appBarStyle,
      backButtonOnClick,
      headerPadding,
      headerNode,
      leftNode,
      leftNodeOffset,
      noLeftNode,
      rightNode,
      rightNodeOffset,
    } = this.props;

    const headerStyles = {
      ...styles.header,
      paddingLeft: headerPadding,
      paddingRight: headerPadding,
    };
    const leftNodeWrapper = { ...styles.leftNodeWrapper, left: leftNodeOffset };
    const rightNodeWrapper = { ...styles.rightNodeWrapper, right: rightNodeOffset };

    return (
      <MaterialAppBar
        style={appBarStyle}
        color="transparent"
        position="static"
      >
        <Toolbar style={headerStyles}>
          {!leftNode && !noLeftNode
            && (
            <BackButton
              onClick={backButtonOnClick}
            />
            )
          }
          {!!leftNode
            && (
            <div style={leftNodeWrapper}>
              {leftNode}
            </div>
            )
          }
          {!!headerNode
            && (
            <div style={styles.headerTitle}>
              {headerNode}
            </div>
            )
          }
          {!!rightNode
            && (
            <div style={rightNodeWrapper}>
              {rightNode}
            </div>
            )
          }
        </Toolbar>
      </MaterialAppBar>
    );
  }
}

AppBar.defaultProps = {
  appBarStyle: styles.appBar,
  backButtonOnClick: noop,
  headerPadding: 0,
  headerNode: undefined,
  leftNode: undefined,
  leftNodeOffset: 0,
  noLeftNode: false,
  rightNode: undefined,
  rightNodeOffset: 0,
};

AppBar.propTypes = {
  appBarStyle: PropTypes.object,
  backButtonOnClick: PropTypes.func,
  headerPadding: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  headerNode: PropTypes.node,
  leftNode: PropTypes.node,
  leftNodeOffset: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  noLeftNode: PropTypes.bool,
  rightNode: PropTypes.node,
  rightNodeOffset: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default AppBar;
