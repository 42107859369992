import React from 'react';
import { connect } from 'react-redux';

import AppBar from '../components/app-bar';
import Page from './page';
import Layout from '../layouts/common';
import { addPhoto } from '../state/user';
import ImageUpload from '../components/image-upload';
import Continue from '../components/continue';

const styles = {
  fabReverseLocation: {
    marginTop: '75px',
    position: 'absolute',
    top: '0px',
    right: '0px',
  },
  fabTakePicture: {
    position: 'absolute',
    bottom: '20px',
    marginRight: 'auto',
    marginLeft: 'auto',
    width: '100%',
  },
  fabButton: {
    marginRight: '20px',
  },
  box: {
    fontSize: '1em',
    width: '100%',
  },
  button: {
    fontSize: '1em',
  },
  hidden: {
    visibility: 'collapse',
  },
  imageUpload: {
    margin: '20px auto',
  },
  visible: {
    visibility: 'visible',
  },
};

class Picture extends Page {
  constructor(props) {
    super(props);
    this.handleAddPhoto = this.handleAddPhoto.bind(this);
    this.state = {
      errorMessage: '',
      photoAdded: false,
    };
  }

  componentWillUnmount() {
    this.setState({
      photoAdded: false,
    });
  }

  handleAddPhoto(img) {
    this.props.addPhoto(img);
    this.setState({
      photoAdded: true,
    });
  }

  render() {
    const { errorMessage } = this.state;
    return (
      <Layout>
        <AppBar
          backButtonOnClick={() => this.props.router.goBack()}
          headerNode="User Picture"
        />
        {errorMessage}
        <ImageUpload
          alt="Profile Photo"
          aspectRatio={1}
          containerStyle={styles.imageUpload}
          handleUpload={this.handleAddPhoto}
          imageHeight="25rem"
          imageTypeName="Profile"
          displayImage={this.props.user.avatarUrl || '/img/profile.png'}
          uploadText="Set a new Profile Picture"
        />
        <section>
          {this.state.photoAdded && (<Continue onClick={() => this.forward()} />)}
        </section>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  const {
    user,
  } = state;

  return {
    user,
  };
}

export default connect(mapStateToProps, { addPhoto })(Picture);
