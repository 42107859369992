const en = {
  eqPainDiscomfort: {
    title: 'Pain / Discomfort',
    instructions: 'Please select the option that best answers the question relating to your pain.',
    healthy: 'I have no pain or discomfort',
    mild: 'I have moderate pain or discomfort',
    severe: 'I have extreme pain or discomfort',
  },
};

const es = {
  eqPainDiscomfort: {
    title: 'Dolor / Malestar',
    instructions: 'Seleccione la opción que mejor responda a la pregunta relacionada con su dolor.',
    healthy: 'No tengo dolor o malestar',
    mild: 'Tengo dolor o malestar',
    severe: 'Tengo dolor o malestar extremo',
  },
};

export default { en, es };
