import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get, includes } from 'lodash';

import { colors } from '../lib/styles';
import { Headaches } from '../lib/icons';
import Page from './page';
import ButtonPRO from '../components/button-pro';
import { updatePRO } from '../state/pro-forms';

const buttonLabels = ['Never', 'Rarely', 'Sometimes', 'Very Often', 'Always'];

const options = [
  {
    question: 'When you have headaches, how often is the pain severe?',
    label: buttonLabels,
  },
  {
    question: 'How often do headaches limit your ability to do usual daily activities including household work, work, school or social activities?',
    label: buttonLabels,
  },
  {
    question: 'When you have a headache, how often do you wish you could lie down?',
    label: buttonLabels,
  },
];
const { questionBackground } = colors;

class Hit6HeadacheImpactP1 extends Page {
  onClickBackButton = () => {
    this.backward();
  }

  onSubmission = () => {
    this.forwardWithQuery(this.props.location.query);
  }

  render() {
    const page = get(this.props.hit6, this.getTrackIndex(), [null, null, null]);
    const value = includes(page, null) ? null : 0;
    return (
      <ButtonPRO
        backgroundColor={questionBackground}
        currentPage={this.getCurrentPage()}
        image={Headaches}
        onClickBackButton={() => this.props.router.goBack()}
        onChange={this.props.updatePRO}
        onSubmission={this.onSubmission}
        options={options}
        title=""
        header="Headache Impact"
        totalPages={this.getTotalPages()}
        trackIndex={this.getTrackIndex()}
        value={value}
        pro={page}
        type="hit6"
      />
    );
  }
}


function mapStateToProps(state) {
  const {
    proForms: {
      hit6,
      confirmId,
    },
  } = state;
  return {
    hit6,
    confirmId,
  };
}

Hit6HeadacheImpactP1.propTypes = {
  hit6: PropTypes.array.isRequired,
  updatePRO: PropTypes.func.isRequired,
  confirmId: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps, { updatePRO })(Hit6HeadacheImpactP1);
