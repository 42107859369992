import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ListItem, ListItemSecondaryAction, ListItemText } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Checkbox from './round-checkbox';

import { colors } from '../lib/styles';

const baseStyles = {
  item: {
    color: colors.black,
    fontSize: '1.5rem',
    textAlign: 'left',
    textTransform: 'uppercase',
  },
};


class SurgeryRow extends Component {
  constructor(props) {
    super(props);
    this.handleCheck = this.handleCheck.bind(this);
  }

  handleCheck(checked) {
    this.props.onChange(this.props.surgery.id, checked, this.props.hx);
  }

  render() {
    return (
      <ListItem>
        <ListItemText primary={this.props.surgery.name} classes={{ primary: this.props.classes.item }} />
        <ListItemSecondaryAction>
          <Checkbox
            key={this.props.surgery.id}
            checked={!!this.props.hx}
            disabled={this.props.disabled}
            onChange={(cb, checked) => {
              this.handleCheck(checked);
            }}
          />
        </ListItemSecondaryAction>
      </ListItem>
    );
  }
}

SurgeryRow.defaultProps = {
  hx: null,
};

SurgeryRow.propTypes = {
  disabled: PropTypes.bool.isRequired,
  hx: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  surgery: PropTypes.object.isRequired,
};

export default withStyles(baseStyles)(SurgeryRow);
