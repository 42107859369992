import React from 'react';
import PropTypes from 'prop-types';
import {
  Checkbox,
  FormControlLabel,
  IconButton,
  LinearProgress,
} from '@material-ui/core/';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import MenuIcon from '@material-ui/icons/Menu';
import { withStyles } from '@material-ui/core/styles';
import { browserHistory } from 'react-router';
import { connect } from 'react-redux';

import AppBar from '../components/app-bar';
import Page from './page';
import { colors, desktopMaxWidth, fontSizing } from '../lib/styles';
import Logo from '../components/logo';
import { saveSkipVitalCoreInstructions } from '../state/user';
import QuestionLayout from '../layouts/question';

const styles = {
  bodyText: {
    marginTop: 20,
  },
  checkbox: {
    paddingTop: 10,
  },
  formControlLabel: {
    marginTop: 15,
  },
  header: {
    fontSize: fontSizing.h3,
    fontWeight: 'bold',
    marginTop: -20,
    textAlign: 'center',
    backgroundColor: colors.questionBackground,
  },
  instructionImage: {
    width: 245,
  },
  instructionImages: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    margin: 10,
    backgroundColor: colors.questionBackground,
  },
  instructionImageContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    marginTop: 20,
    backgroundColor: colors.questionBackground,
  },
  linearProgress: {
    background: colors.lightBrightBlue,
  },
  pageContainer: {
    backgroundColor: colors.questionBackground,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  pageContent: {
    flexGrow: 1,
    fontSize: fontSizing.body,
    overflowX: 'scroll',
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: desktopMaxWidth,
    paddingLeft: 10,
    paddingRight: 10,
    backgroundColor: colors.questionBackground,
  },
  skipInstructionsText: {
    fontSize: fontSizing.body,
  },
  subText: {
    fontSize: fontSizing.small,
  },
};

class VitalCore6 extends Page {
  constructor(props) {
    super(props);

    const vitalCoreVisitedInstructions = window.localStorage.getItem('vitalCoreVisitedInstructions');
    const historyAction = props.router.location.action;

    if (props.user.skip_vital_core_instructions && !vitalCoreVisitedInstructions && historyAction === 'PUSH') {
      this.forwardWithQuery(props.location.query);
    } else if (props.user.skip_vital_core_instructions && !vitalCoreVisitedInstructions && historyAction === 'POP') {
      this.props.router.goBack();
    }
  }

  handleChangeSkipInstructions = (e) => {
    const { saveSkipVitalCoreInstructions, user } = this.props;
    saveSkipVitalCoreInstructions(e.target.checked, user.id);
  };

  render() {
    const { classes, location, user } = this.props;

    return (
      <QuestionLayout
        primaryBtnLabel="Next"
        primaryBtnOnTouchTap={() => {
          window.localStorage.setItem('vitalCoreVisitedInstructions', true);
          this.forwardWithQuery(location.query);
        }}
        style={{ backgroundColor: colors.questionBackground }}
      >
        <AppBar
          headerNode="Instructions"
          backButtonOnClick={() => this.props.router.goBack()}
          rightNode={(
            <IconButton onClick={() => browserHistory.push('/core-vital-user-manual-menu')}>
              <MenuIcon fontSize="large" />
            </IconButton>
          )}
        />
        <LinearProgress
          variant="determinate"
          value={(this.getCurrentPage() / this.getTotalPages()) * 100}
          classes={{ bar: classes.linearProgress }}
        />
        <div className={classes.pageContent}>
          <Logo />
          <div className={classes.header}>
            How to use Informed Vital Core:
          </div>
          <p className={classes.bodyText}>
            Informed Vital Core must be used indoors in typical home lighting conditions.
          </p>
          <p className={classes.bodyText}>
            Make sure there is nothing behind you while the device is in use (examples: TV in the background, pets, or people walking behind you).
          </p>
          <div><strong>Instructions:</strong></div>
          <ol>
            <li>
              <div className={classes.bodyText}>
                <div>Remove any hats, clothing, or anything that may cover your face.</div>
                <div className={classes.subText}>(We need to see your forehead, cheeks, and chin. You can leave your glasses on).</div>
              </div>
              <div className={classes.instructionImageContainer}>
                <img
                  src="/img/vitals-1.jpg"
                  alt="person smiling"
                  className={classes.instructionImage}
                />
              </div>
            </li>
            <li>
              <div className={classes.bodyText}>
                Remove makeup from your cheeks, forehead, and chin.
              </div>
            </li>
            <li>
              <div className={classes.bodyText}>
                <div>Find a place with good lighting so your face is well and evenly lit.</div>
                <div className={classes.subText}>No shadow or bright spots should be on your face.</div>
              </div>
              <div className={classes.instructionImages}>
                <img
                  src="/img/vitals-(lighting).jpg"
                  alt="person and red guide box"
                  className={classes.instructionImage}
                />
              </div>
            </li>
            <li>
              <div className={classes.bodyText}>
                <div>Relax and sit comfortably in a chair for 5 minutes.</div>
                <div className={classes.subText}>Legs uncrossed, feet on the floor, back pressed lightly against the chair.</div>
              </div>
            </li>
            <li>
              <div className={classes.bodyText}>
                <div>Place mobile device on a stand.</div>
                <div className={classes.subText}>Note: If you do not have a stand, then rest your elbows on a table and hold the device steady.</div>
              </div>
              <div className={classes.instructionImageContainer}>
                <div className={classes.instructionImages}>
                  <img
                    src="/img/vitals-(movement).jpg"
                    alt="person and green guide box"
                    className={classes.instructionImage}
                  />
                </div>
              </div>
            </li>
            <li>
              <div className={classes.bodyText}>
                <div>Place your face within the center of the guide box.</div>
                <div className={classes.subText}>Look directly at the + sign in the center of the guide box until Informed Vital Core is complete.</div>
              </div>
              <div className={classes.instructionImageContainer}>
                <div className={classes.instructionImages}>
                  <img
                    src="/img/vitals-(good).jpg"
                    alt="person and green guide box"
                    className={classes.instructionImage}
                  />
                </div>
              </div>
            </li>
            <li>
              <div className={classes.bodyText}>
                <div>Stay still during vital measurements.</div>
                <div className={classes.subText}>Note: Do not talk or move your head while your vitals are measured.</div>
              </div>
              <div className={`${classes.instructionImages}`}>
                <img
                  src="/img/vitals-(bad).jpg"
                  alt="person and red guide box"
                  className={classes.instructionImage}
                />
              </div>
            </li>
            <li>
              <div className={classes.bodyText}>
                {'Tap the "START" button when you are ready to measure your vitals.'}
              </div>
              <div className={classes.instructionImageContainer}>
                <img
                  src="/img/vitals-Start-Button.jpg"
                  alt="person in green guide box ready for measurements to be taken"
                  className={classes.instructionImage}
                />
              </div>
            </li>
          </ol>
          <FormControlLabel
            control={(
              <Checkbox
                checked={user.skip_vital_core_instructions}
                onChange={this.handleChangeSkipInstructions}
                name="changeInHealth"
                icon={<CheckBoxOutlineBlankIcon fontSize="large" />}
                checkedIcon={<CheckBoxIcon fontSize="large" />}
                className={classes.checkbox}
              />
            )}
            label="Skip instructions in the future."
            classes={{
              label: classes.skipInstructionsText,
              root: classes.formControlLabel,
            }}
          />
        </div>
      </QuestionLayout>
    );
  }
}

VitalCore6.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const { user } = state;

  return { user };
};

export default connect(mapStateToProps, { saveSkipVitalCoreInstructions })(withStyles(styles)(VitalCore6));
