import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Continue from './continue';

import { colors } from '../lib/styles';

const baseStyle = {
  top: {
    margin: '0 auto',
    color: colors.primaryColor,
  },
  bottom: {
    position: 'absolute',
    bottom: '0',
    width: '100%',
  },
};


const x = (action) => {
  return (
    <div>
      <div style={baseStyle.top}>
        <h1>Disclaimer</h1>
        <img src="/img/eula-icon.png" alt="Eula Icon" />
        <p>
          This app is not meant to provide medical care which can only be done by your healthcare provider.
        </p>
      </div>
      <div style={baseStyle.bottom}>
        <Continue onClick={action} />
      </div>
    </div>
  );
};


class Disclaimer extends Component {
  render() {
    const { continueAction } = this.props;
    return x(continueAction);
  }
}


Disclaimer.propTypes = {
  continueAction: PropTypes.func.isRequired,
};


export default Disclaimer;
