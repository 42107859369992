import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { TextField } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import FormLabel from '@material-ui/core/FormLabel';
import LinearProgress from '@material-ui/core/LinearProgress';
import { withStyles } from '@material-ui/core/styles';
import { PhoneAndroid } from '@material-ui/icons';

import AppBar from '../components/app-bar';
import Continue from '../components/continue';
import Page from './page';
import { submitPRO } from '../state/pro-forms';

const StyledInput = withStyles(() => ({
  input: {
    textAlign: 'center',
  },
}))(Input);

const styles = {
  formControlLabelRoot: {
    alignItems: 'start',
    marginTop: 5,
    '& .MuiSvgIcon-root': {
      height: 20,
      width: 20,
    },
  },
  iconContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    flexGrow: 0,
    flexShrink: 0,
    width: '100%',
    color: '#000',
    paddingTop: 10,
  },
  iconWrapper: {
    width: 100,
    marginTop: 10,
  },
  radio: {
    paddingTop: 4,
  },
  radioLabel: {
    fontSize: '2rem',
  },
  titleGroup: {
    margin: 25,
  },
  text: {
    fontSize: '2rem',
  },
  textArea: {
    marginTop: 20,
  },
  nextBtn: {
    textAlign: 'center',
    width: '100%',
    top: '90%',
  },
};

class FullDataCollection11 extends Page {
  constructor(props) {
    super(props);

    this.state = {
      ui_device: '',
      notes: '',
    };
  }

  handleChange = (e) => {
    const { value } = e.target;
    this.setState({
      ui_device: value,
    });
  };

  handleChangeNotes = (e) => {
    const { value } = e.target;

    this.setState({
      notes: value,
    });
  };

  handleContinue = async () => {
    const {
      user,
      userId,
      location,
      fullDataCollection,
      submitPRO,
    } = this.props;
    const { ui_device, notes } = this.state;
    const { id } = location.query;

    const data = [
      ...fullDataCollection,
      ui_device,
      notes,
    ];

    try {
      await submitPRO({ userId, id }, {
        pro_type: 'FULL-DATA-COLLECTION',
        pro_data: { data },
      }, user);
      this.finishTrack();
    } catch (e) {
      console.error(e); // eslint-disable-line no-console
    }
  }

  render() {
    const { ui_device, notes } = this.state;
    const { classes } = this.props;

    return (
      <Fragment>
        <AppBar
          headerNode="Device"
          noLeftNode={true}
        />
        <LinearProgress
          variant="determinate"
          value={(this.getCurrentPage() / this.getTotalPages()) * 100}
        />
        <div className={classes.iconContainer}>
          <div className={classes.iconWrapper}>
            <PhoneAndroid style={{ fontSize: 100 }} />
          </div>
        </div>

        <div className={classes.titleGroup}>
          <p className={classes.text}>
            What kind of device did you use?
          </p>
          <FormControl component="fieldset">
            <FormLabel className={classes.text} component="legend">Device</FormLabel>
            <StyledInput
              type="text"
              value={ui_device}
              name="ui_device"
              placeholder="tap here to enter"
              onChange={this.handleChange}
              inputProps={{
                'aria-label': 'other',
              }}
            />
          </FormControl>
        </div>

        <div className={classes.titleGroup}>
          <p className={classes.text}>
            Notes
          </p>
          <FormControl component="fieldset" fullWidth>
            <TextField
              className={classes.textArea}
              placeholder="Please enter any notes or observations related to this data collection session. Tap to enter"
              multiline
              minRows={3}
              maxRows={10}
              onChange={this.handleChangeNotes}
              value={notes}
              InputLabelProps={{ shrink: true }}
            />
          </FormControl>
        </div>

        <div className={classes.nextBtn}>
          <Continue
            disabled={(!ui_device)}
            text="Next"
            onClick={this.handleContinue}
          />
        </div>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { user, proForms: { fullDataCollection } } = state;
  return {
    user,
    userId: user.id,
    fullDataCollection,
  };
}

FullDataCollection11.proptypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  submitPRO: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { submitPRO })(withStyles(styles)(FullDataCollection11));
