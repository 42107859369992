import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import {
  Checkbox,
  FormControlLabel,
  TextField,
} from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import Page from './page';
import AppBar from '../components/app-bar';
import BloomkidzLayout from '../layouts/bloomkidz';
import { updatePRO } from '../state/pro-forms';
import { bloomkidzStylesObj, bloomkidzStyles } from '../lib/bloomkidz';
import { fontSizing } from '../lib/styles';

const styles = {
  ...bloomkidzStylesObj,
  centerText: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
  },
  emphasis: {
    fontWeight: 600,
  },
  spacing: {
    margin: '20px 0 10px',
  },
};

class BloomkidzSignatures8 extends Page {
  constructor(props) {
    super(props);

    const stateFromRedux = props.bloomkidzSignatures[this.getTrackIndex()] || {};

    this.state = {
      consentAcknowledgement1: '',
      consentGuardian2: '',
      consentRelationship2: '',
      ...stateFromRedux,
    };
  }

  handleCheck = (e) => {
    this.setState({ [e.target.name]: e.target.checked });
  };

  handleTextFieldChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleContinue = () => {
    const { location, updatePRO } = this.props;

    updatePRO({
      type: 'bloomkidzSignatures',
      position: this.getTrackIndex(),
      value: { ...this.state },
    });

    this.forwardWithQuery(location.query);
  }

  render() {
    const { classes, router } = this.props;
    const { consentAcknowledgement1, consentGuardian2, consentRelationship2 } = this.state;

    const continueDisabled = !consentAcknowledgement1 || !consentGuardian2 || !consentRelationship2;

    return (
      <BloomkidzLayout
        primaryBtnOnTouchTap={this.handleContinue}
        primaryBtnLabel="Next"
        primaryBtnDisabled={continueDisabled}
      >
        <AppBar
          headerNode="Signature"
          backButtonOnClick={() => router.goBack()}
        />
        <div className={classes.pageContent}>
          <div className={`${classes.emphasis} ${classes.spacing}`}>Informed Consent -Assessments and Services</div>
          <FormControlLabel
            control={(
              <Checkbox
                checked={consentAcknowledgement1}
                onChange={this.handleCheck}
                name="consentAcknowledgement1"
                icon={<CheckBoxOutlineBlankIcon />}
                checkedIcon={<CheckBoxIcon />}
                className={classes.checkbox}
              />
            )}
            label={`
              I understand that all assessment results will be shared with me and the child's professional team and I may choose to end the assessment at any time.
              BloomKidz is committed to maintaining confidentiality. I understand that state laws may required that confidentiality be broken under certain circumstances, specifically, if I am judged by the behavior analyst to be of danger to myself and/or others, gravely disabled, or if there is suspected child abuse.
              I also understand that BloomKidz specializes in the observation, evaluation and treatment of problem behaviors, and that if BloomKidz is unable to meet my particular needs, I will be referred to as an appropriate agency or individual.
            `}
            classes={{
              label: classes.checkboxLabel,
              root: classes.formControlLabel,
            }}
          />
          <div className={`${classes.emphasis} ${classes.spacing}`}>Parent/Guardian Printed Name</div>
          <div className={classes.paddingHorizontal}>
            <div className={classes.textFieldWrapper}>
              <TextField
                placeholder="Full Name"
                value={consentGuardian2}
                onChange={this.handleTextFieldChange}
                name="consentGuardian2"
                variant="standard"
                fullWidth
                inputProps={{ style: { fontSize: fontSizing.body, fontFamily: bloomkidzStyles.fontFamily } }}
              />
            </div>
          </div>
          <div className={`${classes.emphasis} ${classes.spacing}`}>Relationship to the Client</div>
          <div className={classes.paddingHorizontal}>
            <div className={classes.textFieldWrapper}>
              <TextField
                placeholder="Relationship to client"
                value={consentRelationship2}
                onChange={this.handleTextFieldChange}
                name="consentRelationship2"
                variant="standard"
                fullWidth
                inputProps={{ style: { fontSize: fontSizing.body, fontFamily: bloomkidzStyles.fontFamily } }}
              />
            </div>
          </div>
        </div>
      </BloomkidzLayout>
    );
  }
}

BloomkidzSignatures8.propTypes = {
  classes: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  updatePRO: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const {
    proForms: { bloomkidzSignatures },
  } = state;

  return { bloomkidzSignatures };
};

export default connect(mapStateToProps, { updatePRO })(withStyles(styles)(BloomkidzSignatures8));
