import React from 'react';
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  LinearProgress,
  TextField,
} from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import AppBar from '../components/app-bar';
import Page from './page';
import QuestionLayout from '../layouts/question';
import { colors } from '../lib/styles';
import { updatePRO } from '../state/pro-forms';
import { Frown } from '../lib/icons';

const styles = {
  checkbox: {
    paddingTop: 3,
  },
  contentWrapper: {
    flexGrow: 1,
    overflowX: 'hidden',
    position: 'relative',
  },
  formControlLabel: {
    alignItems: 'start',
  },
  imgRow: {
    display: 'flex',
    justifyContent: 'center',
    padding: '15px 60px',
  },
  imageWrapper: {
    width: 100,
  },
  marginTop20: {
    marginTop: 20,
  },
  questionnaireContainer: {
    paddingLeft: 20,
    paddingRight: 20,
  },
  questionText: {
    fontSize: '2rem',
  },
  textFieldRoot: {
    marginTop: 10,
    width: 256,
    '& input': {
      fontSize: 18,
    },
    '& label': {
      fontSize: 18,
    },
  },
};

class HeartFailureInitial15 extends Page {
  constructor(props) {
    super(props);

    this.state = {
      changeInHealth: false,
      family: false,
      financial: false,
      other: false,
      otherSymptom: '',
      work: false,
    };
  }

  handleChangeAnotherSymptom = (e) => {
    this.setState({ otherSymptom: e.target.value });
  }

  handleChangeSourceOfStress = (e) => {
    this.setState({ [e.target.name]: e.target.checked });
  };

  handleClickNext = () => {
    const {
      changeInHealth,
      family,
      financial,
      other,
      otherSymptom,
      work,
    } = this.state;

    const sourcesOfStress = [];

    if (changeInHealth) sourcesOfStress.push('Change in health condition');
    if (family) sourcesOfStress.push('Family');
    if (financial) sourcesOfStress.push('Financial');
    if (work) sourcesOfStress.push('Work');
    if (other && otherSymptom) sourcesOfStress.push(`Other: ${otherSymptom}`);

    this.props.updatePRO({
      type: 'heartFailureInitial',
      position: this.getTrackIndex(),
      value: { sourcesOfStress },
    });

    this.forwardWithQuery(this.props.location.query);
  }

  render() {
    const { classes } = this.props;
    const {
      changeInHealth,
      family,
      financial,
      other,
      otherSymptom,
      work,
    } = this.state;

    return (
      <QuestionLayout
        primaryBtnLabel="Next"
        primaryBtnOnTouchTap={this.handleClickNext}
        style={{ backgroundColor: colors.questionBackground }}
      >
        <AppBar
          headerNode="Stress"
          backButtonOnClick={() => this.props.router.goBack()}
        />
        <LinearProgress
          variant="determinate"
          value={(this.getCurrentPage() / this.getTotalPages()) * 100}
          classes={{ bar: classes.bar }}
        />
        <div className={classes.contentWrapper}>
          <div className={classes.imgRow}>
            <div className={classes.imageWrapper}>
              <Frown />
            </div>
          </div>
          <div className={classes.questionnaireContainer}>
            <div className={classes.questionText}>What is the source of your stress?</div>
            <FormGroup className={classes.marginTop20}>
              <FormControlLabel
                control={(
                  <Checkbox
                    checked={changeInHealth}
                    onChange={this.handleChangeSourceOfStress}
                    name="changeInHealth"
                    icon={<CheckBoxOutlineBlankIcon fontSize="large" />}
                    checkedIcon={<CheckBoxIcon fontSize="large" />}
                    className={classes.checkbox}
                  />
                )}
                label="A change in your health condition"
                classes={{
                  label: classes.questionText,
                  root: classes.formControlLabel,
                }}
              />
              <FormControlLabel
                control={(
                  <Checkbox
                    checked={family}
                    onChange={this.handleChangeSourceOfStress}
                    name="family"
                    icon={<CheckBoxOutlineBlankIcon fontSize="large" />}
                    checkedIcon={<CheckBoxIcon fontSize="large" />}
                    className={classes.checkbox}
                  />
                )}
                label="Family"
                classes={{
                  label: classes.questionText,
                  root: classes.formControlLabel,
                }}
              />
              <FormControlLabel
                control={(
                  <Checkbox
                    checked={work}
                    onChange={this.handleChangeSourceOfStress}
                    name="work"
                    icon={<CheckBoxOutlineBlankIcon fontSize="large" />}
                    checkedIcon={<CheckBoxIcon fontSize="large" />}
                    className={classes.checkbox}
                  />
                )}
                label="Work"
                classes={{
                  label: classes.questionText,
                  root: classes.formControlLabel,
                }}
              />
              <FormControlLabel
                control={(
                  <Checkbox
                    checked={financial}
                    onChange={this.handleChangeSourceOfStress}
                    name="financial"
                    icon={<CheckBoxOutlineBlankIcon fontSize="large" />}
                    checkedIcon={<CheckBoxIcon fontSize="large" />}
                    className={classes.checkbox}
                  />
                )}
                label="Financial"
                classes={{
                  label: classes.questionText,
                  root: classes.formControlLabel,
                }}
              />
              <FormControlLabel
                control={(
                  <Checkbox
                    checked={other}
                    onChange={this.handleChangeSourceOfStress}
                    name="other"
                    icon={<CheckBoxOutlineBlankIcon fontSize="large" />}
                    checkedIcon={<CheckBoxIcon fontSize="large" />}
                    className={classes.checkbox}
                  />
                )}
                label="Other"
                classes={{
                  label: classes.questionText,
                  root: classes.formControlLabel,
                }}
              />
            </FormGroup>
            {other ? (
              <TextField
                label="Another Symptom"
                onChange={this.handleChangeAnotherSymptom}
                classes={{ root: classes.textFieldRoot }}
                value={otherSymptom}
              />
            ) : null}
          </div>
        </div>
      </QuestionLayout>
    );
  }
}

export default connect(null, { updatePRO })(withStyles(styles)(HeartFailureInitial15));
