
import React from 'react';
import PropTypes from 'prop-types';
import LinearProgress from '@material-ui/core/LinearProgress';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import AppBar from '../components/app-bar';
import Page from './page';
import QuestionLayout from '../layouts/question';

import { updatePRO } from '../state/pro-forms';

import { colors, fontSizing } from '../lib/styles';

const styles = {
  bar: {
    backgroundColor: colors.lightBrightBlue,
  },
  contentWrapper: {
    flexGrow: 1,
    fontSize: fontSizing.body,
    overflowX: 'hidden',
    paddingLeft: 20,
    paddingRight: 20,
    position: 'relative',
    textAlign: 'start',
  },
  title: {
    fontWeight: 'bold',
    textAlign: 'center',
  },
};

class HypertensionAssessment2 extends Page {
  handleClickNext = async () => {
    this.props.updatePRO({
      type: 'hypertensionAssessmentDaily',
      position: this.getTrackIndex(),
      value: {},
    });

    this.forwardWithQuery(this.props.location.query);
  }

  render() {
    const { classes } = this.props;

    return (
      <QuestionLayout
        primaryBtnLabel="Next"
        primaryBtnOnTouchTap={this.handleClickNext}
        style={{ backgroundColor: colors.questionBackground }}
      >
        <AppBar
          backButtonOnClick={() => this.props.router.goBack()}
          headerNode="Instructions"
        />
        <LinearProgress
          variant="determinate"
          value={(this.getCurrentPage() / this.getTotalPages()) * 100}
          classes={{ bar: classes.bar }}
        />

        <div className={classes.contentWrapper}>
          <p className={classes.title}>Prepare to take your Blood Pressure</p>
          <ol>
            <li>Sit in a comfortable chair with your feet uncrossed, flat on the ground, and your lower back pressed into the seat back.</li>
            <li>Do not take your blood pressure immediately after any physical activity.</li>
            <li>Be sure you have been seated and relaxed for <strong>at least five minutes</strong> before taking your blood pressure.</li>
          </ol>

        </div>
      </QuestionLayout>
    );
  }
}

HypertensionAssessment2.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect(null, { updatePRO })(withStyles(styles)(HypertensionAssessment2));
