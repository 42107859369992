import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get } from 'lodash';

import { colors } from '../lib/styles';
import Page from './page';
import Question from '../components/question';
import { Walking } from '../lib/icons';
import { updatePRO } from '../state/pro-forms';

const options = [
  {
    label: 'Pain does not prevent me from walking any distance',
    value: 0,
  },
  {
    label: 'Pain prevents me from walking more than 1 mile (1mile = 1.6km)',
    value: 1,
  },
  {
    label: 'Pain prevents me from walking more than 1/2 mile',
    value: 2,
  },
  {
    label: 'Pain prevents me from walking more than 1/4 mile',
    value: 3,
  },
  {
    label: 'I can only walk with crutches or a cane',
    value: 4,
  },
  {
    label: 'I am in bed most of the time and have to crawl to the toilet',
    value: 5,
  },
];
const { questionBackground } = colors;

class OdiWalking extends Page {
  onClickBackButton = () => {
    this.backward();
  }

  onSubmission = () => {
    this.forwardWithQuery(this.props.location.query);
  }

  render() {
    const value = get(this.props.odi, this.getTrackIndex(), null);
    return (
      <Question
        backgroundColor={questionBackground}
        currentPage={this.getCurrentPage()}
        image={Walking}
        onClickBackButton={() => this.props.router.goBack()}
        onChange={this.props.updatePRO}
        onSubmission={this.onSubmission}
        options={options}
        title="Walking"
        totalPages={this.getTotalPages()}
        trackIndex={this.getTrackIndex()}
        type="odi"
        value={value}
      />
    );
  }
}

function mapStateToProps(state) {
  const { proForms: { odi } } = state;
  return { odi };
}

OdiWalking.propTypes = {
  odi: PropTypes.array.isRequired,
  updatePRO: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { updatePRO })(OdiWalking);
