import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get } from 'lodash';

import { colors } from '../lib/styles';
import Page from './page';
import Question from '../components/question';
import { updatePRO } from '../state/pro-forms';

const options = [
  {
    label: 'Complete loss of feelings in my hands.',
    value: 0,
  },
  {
    label: 'Severe loss of feeling, or have pain in my hands.',
    value: 1,
  },
  {
    label: 'Mild loss of feeling in my hands.',
    value: 2,
  },
  {
    label: 'No loss of feeling in my hands.',
    value: 3,
  },
];
const { questionBackground } = colors;

class Pmjoa3 extends Page {
  onClickBackButton = () => {
    this.backward();
  }

  onSubmission = () => {
    this.forwardWithQuery(this.props.location.query);
  }

  render() {
    const value = get(this.props.pmjoa, this.getTrackIndex(), [null, null, null, null]);
    return (
      <Question
        backgroundColor={questionBackground}
        currentPage={this.getCurrentPage()}
        onClickBackButton={() => this.props.router.goBack()}
        onChange={this.props.updatePRO}
        onSubmission={this.onSubmission}
        options={options}
        title="Sensory  Dysfunction"
        totalPages={this.getTotalPages()}
        trackIndex={this.getTrackIndex()}
        type="pmjoa"
        value={value}
      />
    );
  }
}


function mapStateToProps(state) {
  const { proForms: { pmjoa } } = state;
  return { pmjoa };
}

Pmjoa3.propTypes = {
  pmjoa: PropTypes.array.isRequired,
  updatePRO: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { updatePRO })(Pmjoa3);
