import React from 'react';
import {
  LinearProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
} from '@material-ui/core';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { get, debounce } from 'lodash';

import AppBar from '../components/app-bar';
import Page from './page';
import QuestionLayout from '../layouts/question';
import YesNoButtonSet from '../components/yes-no-button-set';
import { colors } from '../lib/styles';
import { updatePRO } from '../state/pro-forms';
import { Pills } from '../lib/icons';
import { apiFetch, makeShadowOptions } from '../lib/fetch';

const styles = {
  autoComplete: {
    width: 300,
    margin: '20px auto 0 auto',
  },
  autoCompleteInput: {
    fontSize: 16,
  },
  contentWrapper: {
    flexGrow: 1,
    overflowX: 'hidden',
    position: 'relative',
  },
  imgRow: {
    display: 'flex',
    justifyContent: 'center',
    padding: '15px 60px',
  },
  imageWrapper: {
    width: 100,
  },
  listItem: {
    borderBottom: `1px solid ${colors.primaryHighlight}`,
    textAlign: 'left',
  },
  listItemText: {
    fontSize: '2rem',
  },
  questionnaireContainer: {
    paddingLeft: 20,
    paddingRight: 20,
  },
};

class HeartFailureInitial2 extends Page {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      medicationData: [],
      open: false,
    };

    this.handleUpdateInput = debounce(this.handleUpdateInput.bind(this), 250, { trailing: true });
  }

  handleAddMedication = () => {
    this.forwardWithQuery(this.props.location.query);
  }

  handleChangeTakingMedications = (value) => {
    const { heartFailureInitial } = this.props;

    this.props.updatePRO({
      type: 'heartFailureInitial',
      position: this.getTrackIndex(),
      value: {
        ...heartFailureInitial[1],
        takingMedications: value,
      },
    });
  };

  handleClickNext = () => {
    this.goToIndexWithQuery(3, this.props.location.query);
  }

  handleUpdateInput(e, name) {
    if (!name) {
      return this.setState({
        loading: false,
        medicationData: [],
      });
    }
    const options = makeShadowOptions({ name }, this.props.user.token, 'GET');
    this.setState({ loading: true });
    apiFetch('/goodrx', options)
      .then((response) => {
        this.setState({
          loading: false,
          medicationData: get(response, 'data.candidates', []),
        });
      });
  }

  handleRemoveMedication(name) {
    const { heartFailureInitial, medications, updatePRO } = this.props;

    const filteredMedications = medications.filter(medication => medication.name !== name);

    updatePRO({
      type: 'heartFailureInitial',
      position: this.getTrackIndex(),
      value: { ...heartFailureInitial[1], medications: filteredMedications },
    });
  }

  handleSelectItem = (e, name) => {
    this.forwardWithQuery({ ...this.props.location.query, name });
  }

  render() {
    const { classes, heartFailureInitial, medications } = this.props;
    const { loading } = this.state;

    const takingMedications = heartFailureInitial[1] && heartFailureInitial[1].takingMedications
      ? heartFailureInitial[1].takingMedications
      : null;

    const continueDisabled = takingMedications === null;

    return (
      <QuestionLayout
        primaryBtnLabel="Next"
        primaryBtnOnTouchTap={this.handleClickNext}
        primaryBtnDisabled={continueDisabled}
        style={{ backgroundColor: colors.questionBackground }}
      >
        <AppBar
          headerNode="Medication"
          backButtonOnClick={() => this.props.router.goBack()}
        />
        <LinearProgress
          variant="determinate"
          value={(this.getCurrentPage() / this.getTotalPages()) * 100}
          classes={{ bar: classes.bar }}
        />
        <div className={classes.contentWrapper}>
          <div className={classes.imgRow}>
            <div className={classes.imageWrapper}>
              <Pills />
            </div>
          </div>
          <div className={classes.questionnaireContainer}>
            <YesNoButtonSet
              isYes={takingMedications === 'Y'}
              isNo={takingMedications === 'N'}
              onClickNo={() => this.handleChangeTakingMedications('N')}
              onClickYes={() => this.handleChangeTakingMedications('Y')}
              questionText="Are you taking any medications?"
            />
          </div>
          {takingMedications === 'Y' ? (
            <div>
              <Autocomplete
                freeSolo
                onChange={this.handleSelectItem}
                onInputChange={this.handleUpdateInput}
                options={this.state.medicationData}
                loading={loading}
                className={classes.autoComplete}
                classes={{
                  input: classes.autoCompleteInput,
                }}
                renderInput={params => <TextField {...params} placeholder="Tap to search" />}
              />
              <List>
                {
                  medications.map((m) => {
                    return (
                      <ListItem
                        button
                        style={styles.listItem}
                      >
                        <ListItemText
                          primary={m.name}
                          classes={{
                            primary: classes.listItemText
                          }}
                        />
                        <ListItemIcon onClick={() => this.handleRemoveMedication(m.name)}>
                          <RemoveCircleIcon
                            fontSize="large"
                            style={{ color: 'red' }}
                          />
                        </ListItemIcon>
                      </ListItem>
                    );
                  })
                }
              </List>
            </div>
          ) : null}
        </div>
      </QuestionLayout>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    proForms: {
      heartFailureInitial,
    },
    user,
  } = state;

  const medications = heartFailureInitial[1] && heartFailureInitial[1].medications
    ? heartFailureInitial[1].medications
    : [];

  return { heartFailureInitial, medications, user };
};

export default connect(mapStateToProps, { updatePRO })(withStyles(styles)(HeartFailureInitial2));
