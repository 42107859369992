import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import TextField from '@material-ui/core/TextField';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import AppBar from '../components/app-bar';
import Page from './page';
import QuestionLayout from '../layouts/question';
import { colors } from '../lib/styles';
import { updatePRO } from '../state/pro-forms';
import { WeightScale } from '../lib/icons';

const styles = {
  contentWrapper: {
    flexGrow: 1,
    overflowX: 'hidden',
    position: 'relative',
  },
  imgRow: {
    display: 'flex',
    justifyContent: 'center',
    padding: '15px 60px',
  },
  imageWrapper: {
    width: 100,
  },
  inputField: {
    width: '6rem',
  },
  instructions: {
    color: 'grey',
    fontSize: '1.5rem',
    margin: '20px 40px 0px 40px',
    textAlign: 'center',
  },
  leaveBlankText: {
    fontSize: '1.25rem',
    fontStyle: 'italic',
    marginTop: 10,
  },
  mainQuestionText: {
    fontSize: '2rem',
    fontWeight: 'bold',
  },
  marginRight40: {
    marginRight: 40,
  },
  marginTop40: {
    marginTop: 40,
  },
  questionnaireContainer: {
    paddingLeft: 20,
    paddingRight: 20,
  },
  textFieldContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  textFieldRow: {
    display: 'flex',
  },
  textFieldWrapper: {
    alignItems: 'center',
    display: 'flex',
    paddingTop: 20,
  },
  unitsWrapper: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    marginLeft: 5,
  },
};

class HeadAndNeckCancerSymptoms4 extends Page {
  constructor(props) {
    super(props);

    this.state = {
      feet: '',
      inches: '',
      weight: '',
    };
  }

  handleChangeFeet = (e) => {
    this.setState({ feet: e.target.value });
  };

  handleChangeInches = (e) => {
    this.setState({ inches: e.target.value });
  }

  handleChangeWeight = (e) => {
    this.setState({ weight: e.target.value });
  };

  handleClickNext = () => {
    const {
      feet,
      inches,
      weight,
    } = this.state;

    this.props.updatePRO({
      type: 'headAndNeckCancerSymptoms',
      position: this.getTrackIndex(),
      value: {
        feet,
        inches,
        weight,
      },
    });
    this.forwardWithQuery(this.props.location.query);
  };

  render() {
    const { classes } = this.props;
    const {
      feet,
      inches,
      weight,
    } = this.state;

    return (
      <QuestionLayout
        primaryBtnLabel="Next"
        primaryBtnOnTouchTap={this.handleClickNext}
        style={{ backgroundColor: colors.questionBackground }}
      >
        <AppBar
          headerNode="Weight Change"
          backButtonOnClick={() => this.props.router.goBack()}
        />
        <LinearProgress
          variant="determinate"
          value={(this.getCurrentPage() / this.getTotalPages()) * 100}
          classes={{ bar: classes.bar }}
        />
        <div className={classes.contentWrapper}>
          <div className={classes.instructions}>
            Please select the option that best answers the questions relating to your weight
          </div>
          <div className={classes.imgRow}>
            <div className={classes.imageWrapper}>
              <WeightScale />
            </div>
          </div>
          <div className={classes.questionnaireContainer}>
            <div className={classes.mainQuestionText}>What is your weight today?</div>
            <div className={classes.textFieldContainer}>
              <div className={classes.leaveBlankText}>Leave blank if you don't know your weight.</div>
              <div className={classes.textFieldWrapper}>
                <TextField
                  className={classes.inputField}
                  onChange={this.handleChangeWeight}
                  type="number"
                  inputProps={{
                    inputMode: 'decimal',
                    pattern: '[0-9]*',
                    type: 'tel',
                    style: { fontSize: '16px' } }}
                  value={weight}
                />
                <span className={classes.unitsWrapper}>lbs</span>
              </div>
            </div>
            <div className={`${classes.mainQuestionText} ${classes.marginTop40}`}>What is your height?</div>
            <div className={classes.textFieldContainer}>
              <div className={classes.leaveBlankText}>Leave blank if you don't know your height.</div>
              <div className={classes.textFieldRow}>
                <div className={`${classes.textFieldWrapper} ${classes.marginRight40}`}>
                  <TextField
                    className={classes.inputField}
                    onChange={this.handleChangeFeet}
                    type="number"
                    inputProps={{
                      inputMode: 'decimal',
                      pattern: '[0-9]*',
                      type: 'tel',
                      style: { fontSize: '16px' } }}
                    value={feet}
                  />
                  <span className={classes.unitsWrapper}>ft</span>
                </div>
                <div className={classes.textFieldWrapper}>
                  <TextField
                    className={classes.inputField}
                    onChange={this.handleChangeInches}
                    type="number"
                    inputProps={{
                      inputMode: 'decimal',
                      pattern: '[0-9]*',
                      type: 'tel',
                      style: { fontSize: '16px' } }}
                    value={inches}
                  />
                  <span className={classes.unitsWrapper}>in</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </QuestionLayout>
    );
  }
}

export default connect(null, { updatePRO })(withStyles(styles)(HeadAndNeckCancerSymptoms4));
