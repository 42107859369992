const en = {
  eqMobility: {
    title: 'Mobility',
    instructions: 'Please select the option that best answers the question relating to your pain.',
    healthy: 'I have no problem in walking about',
    mild: 'I have some problems in walking about',
    severe: 'I am confined to bed',
  },
};

const es = {
  eqMobility: {
    title: 'Movilidad',
    instructions: 'Seleccione la opción que mejor responda a la pregunta relacionada con su dolor.',
    healthy: 'No tengo problema en caminar',
    mild: 'Tengo algunos problemas para caminar',
    severe: 'Estoy confinado a la cama',
  },
};

export default { en, es };
