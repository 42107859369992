import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Button, LinearProgress } from '@material-ui/core';

import AppBar from '../components/app-bar';
import Continue from '../components/continue';

import { Utensils } from '../lib/icons';

import Page from './page';

import { updatePRO } from '../state/pro-forms';

const styles = {
  button: {
    margin: 5,
    width: 150,
    height: 40,
    fontSize: 16,
  },
  buttonGroup: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  iconContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    flexGrow: 0,
    flexShrink: 0,
    width: '100%',
    color: '#000',
    paddingTop: 10,
  },
  iconWrapper: {
    width: 100,
    marginTop: 10,
  },
  instructions: {
    fontSize: '1.3rem',
  },
  titleGroup: {
    margin: 25,
  },
  text: {
    fontSize: '22px',
  },
  nextBtn: {
    textAlign: 'center',
    width: '100%',
    top: '90%',
    position: 'fixed',
  },
};

class Diabetes6 extends Page {
  constructor(props) {
    super(props);

    this.state = {
      healthyDiet: null,
    };

    this.handleContinue = this.handleContinue.bind(this);
  }

  handleContinue() {
    this.props.updatePRO({
      type: 'diabetesInitial',
      position: this.getTrackIndex(),
      value: this.state });
    this.forwardWithQuery(this.props.location.query);
  }

  render() {
    const { healthyDiet } = this.state;

    return (
      <Fragment>
        <AppBar
          headerNode="Diet"
          noLeftNode={true}
        />
        <LinearProgress
          variant="determinate"
          value={(this.getCurrentPage() / this.getTotalPages()) * 100}
        />
        <div style={styles.iconContainer}>
          <div style={styles.iconWrapper}>
            <Utensils />
          </div>
        </div>
        <div style={styles.titleGroup}>
          <p style={styles.text}>Do you follow a heart healthy diet consisting of plenty of fruits, vegetables, lean meats, low fat dairy, low sugar, low sodium, healthy fats, and whole grains?</p>
          <div style={styles.buttonGroup}>
            <Button style={styles.button} variant="contained" color={healthyDiet ? 'primary' : 'default'} onClick={() => this.setState({ healthyDiet: true })}>Yes</Button>
            <Button style={styles.button} variant="contained" color={healthyDiet === false ? 'primary' : 'default'} onClick={() => this.setState({ healthyDiet: false })}>No</Button>
          </div>
        </div>

        <div style={styles.nextBtn}>
          <Continue
            disabled={healthyDiet === null}
            text="Next"
            onClick={this.handleContinue}
          />
        </div>

      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { user, proForms: { diabetesInitial } } = state;
  return { user, diabetesInitial };
}

Diabetes6.proptypes = {
  user: PropTypes.object.isRequired,
  route: PropTypes.object.isRequired,
  updatePRO: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { updatePRO })(Diabetes6);
