let CordovaData = {};
let isCordova;
let isAndroid;

export function getCordovaData(platform) {
  if (!isCordova) {
    isCordova = true;
  }

  if (platform === 'Android') {
    isAndroid = true;
  }
  const data = CordovaData;
  CordovaData = {};
  return data;
}

export function cordovaOpen(url) {
  if (!isCordova) {
    window.open(url);
  }
  CordovaData.IABLink = url;
}

export function setStepDate(date) {
  CordovaData.stepDate = date;
}

export function setCalorieDate(date) {
  CordovaData.calorieDate = date;
}

export function setActivityDate(date) {
  CordovaData.activityDate = date;
}

export function takePicture() {
  CordovaData.takePicture = true;
}

export function getCordovaEnvironment() {
  return {
    isCordova,
    isAndroid,
  };
}
