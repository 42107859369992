import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { colors, mainDashboard } from '../lib/styles';

const styles = {
  text: {
    fontSize: '10px',
    color: colors.darkGrayText,
    padding: '0px',
    margin: '0px',
  },
};

class BottomNavigationSection extends Component {
  render() {
    const {
      active,
      icon: Icon,
      label,
      onClick,
    } = this.props;

    const iconColor = (active) ? colors.bottomNavigationHighlighted : colors.darkGrayText;

    return (
      <div
        style={mainDashboard.icon}
        onClick={onClick}
        tabIndex={0}
        onKeyPress={onClick}
        role="button"
      >
        <Icon color={iconColor} />
        <p style={{ ...styles.text, color: iconColor }}>{label}</p>
      </div>
    );
  }
}

BottomNavigationSection.propTypes = {
  active: PropTypes.bool.isRequired,
  icon: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default BottomNavigationSection;
