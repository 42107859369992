const en = {
  woundCasaColinaNotificationPage: {
    introHeaderText: 'Patient Information',
    introBodyText: 'is requesting details and an image of your wound.',
    loggedInSuccessText: '',
    loggedOutSuccessText: `
    <p>Thank you for submitting your response.</p>

    <p>This information is only used for research purposes to learn more about the population at risk for lower limb wound complications.</p>

    <p>If you experience extreme pain or discomfort please contact your primary care physician or seek emergency medical care.</p>
    
    <p>You may now close this window.</p>
    `,
  },
};

const es = {
  woundCasaColinaNotificationPage: {
    introHeaderText: 'Acceso',
    introBodyText: 'solicita detalles y una imagen de su herida.',
    loggedOutSuccessText: `
    <p>Gracias por enviar su cuestionario de calidad de vida relacionada con la salud.</p>

    <p>Esta información solo se utiliza con fines de investigación para obtener más información sobre la población en riesgo de sufrir complicaciones por heridas en las extremidades inferiores.</p>

    <p>Si tienes dolor o malestar extremo, comuníquese con su médico de atención primaria o busque atención médica de emergencia.</p>
    
    <p>Ahora puedes cerrar esta ventana.</p>
    `,
    loggedInSuccessText: '',
  },
};

export default { en, es };
