import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Page from './page';
import AppBar from '../components/app-bar';
import Continue from '../components/continue';

import config from '../config';
import { title, instructions, nextBtn } from '../lib/i18next';
import brand from '../lib/brand';

const styles = {
  continueBtn: {
    textAlign: 'center',
    alignSelf: 'center',
    margin: '10px',
  },
  continueBtnWrapper: {
    textAlign: 'center',
    width: '100%',
    bottom: 0,
    position: 'absolute',
    height: '8rem',
  },
  titleGroup: {
    margin: 25,
    textAlign: 'center',
    height: '100%',
  },
  text: {
    fontSize: '22px',
  },
  logoContainer: {
    padding: '15px 60px',
    textAlign: 'center',
    margin: 'auto',
  },
  logo: {
    maxHeight: '150px',
  },
  mainContainer: {
    position: 'relative',
    minHeight: '100vh',
    marginBottom: '50px',
  },
  wrapper: {
    paddingBottom: '8rem',
    height: '100%',
  },
};

class VideoDisclaimer extends Page {
  render() {
    const { clinic_id } = this.props;
    return (
      <div style={styles.mainContainer}>
        <AppBar
          headerNode={title('videoBroadcast1')}
          noLeftNode={true}
        />
        <div style={styles.logoContainer}>
          <img
            alt="Clinic Logo"
            style={styles.logo}
            src={`${config.API_URL}/clinics/${clinic_id}/logo/400`}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = clinic_id === null ? '' : `/img/${brand.name}/${brand.loginLogo}`;
            }}
          />
        </div>
        <div style={styles.wrapper}>
          <div style={styles.titleGroup}>
            <p style={styles.text}>{instructions('videoBroadcast1')}</p>
          </div>
        </div>
        <div style={styles.continueBtnWrapper}>
          <Continue
            text={nextBtn()}
            onClick={() => {
              this.forward();
            }}
            btnStyle={styles.continueBtn}
          />
        </div>
      </div>
    );
  }
}


function mapStateToProps(_, ownProps) {
  const { id, type, clinic_id } = ownProps.location.state;
  return {
    id,
    type,
    clinic_id,
  };
}

VideoDisclaimer.proptypes = {
  route: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, {})(VideoDisclaimer);
