import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { colors } from '../lib/styles';

const baseStyles = {
  h4: {
    color: colors.black,
    fontWeight: 400,
    margin: '0px',
    fontSize: '1.875rem',
    textAlign: 'center',
    paddingTop: '0.9375rem',
  },
  paragraph: {
    textAlign: 'center',
    marginLeft: '15px',
    marginRight: '15px',
    color: colors.almostBlack,
    fontSize: '1.5rem',
  },
  top: {
    backgroundColor: colors.lightGrayText,
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignSelf: 'center',
  },
};

class DashboardTop extends Component {
  render() {
    const { title, paragraph, children } = this.props;
    return (
      <section style={baseStyles.top}>
        <h4 style={baseStyles.h4}>{title}</h4>
        <p style={baseStyles.paragraph}>{paragraph}</p>
        {children}
      </section>
    );
  }
}

DashboardTop.defaultProps = {
  title: 'How Are You Feeling?',
  paragraph: '',
};

DashboardTop.propTypes = {
  title: PropTypes.string,
  paragraph: PropTypes.string,
};

export default DashboardTop;
