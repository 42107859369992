const en = {
  woundPro2: {
    addImages: 'Add Image(s)',
    pleaseTakePictureOfWoundToUpload: 'Please take a picture of your wound or upload one from your camera roll.',
    saveImages: 'Save Images',
    selectImage: 'Select Image',
  },
};

const es = {
  woundPro2: {
    addImages: 'Add Image(s)',
    pleaseTakePictureOfWoundToUpload: 'Please take a picture of your wound or upload one from your camera roll.',
    saveImages: 'Save Images',
    selectImage: 'Select Image',
  },
};

export default { en, es };
