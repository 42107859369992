const en = {
  eqHealthState: {
    title: 'Your Health State Today',
    instructions: 'To help people say how good or bad a health state is, we have drawn a scale (rather like a thermometer) on which the best state you can imagine is marked 100 and the worst state you can imagine is marked by 0.',
    secondaryInstructions: 'Please Indicate your own health state today.',
    sliderTopLabel: 'Best imaginable health state',
    sliderBottomLabel: 'Worst imaginable health state',
  },
};

const es = {
  eqHealthState: {
    title: 'Su estado de Salud Hoy',
    instructions: 'Para ayudar a las personas a decir qué tan bueno o malo es un estado de salud, hemos dibujado una escala (más bien como un termómetro) en la que el mejor estado que puede imaginar es el con 100 y el peor estado que puedas imaginar está marcado con 0.',
    secondaryInstructions: 'Indeque su propio estado de salud hoy.',
    sliderTopLabel: 'El mejor estado salud imaginable',
    sliderBottomLabel: 'La peor salud imaginable',
  },
};

export default { en, es };
