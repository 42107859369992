import React from 'react';
import PropTypes from 'prop-types';
import LinearProgress from '@material-ui/core/LinearProgress';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { get, round } from 'lodash';

import AppBar from '../components/app-bar';
import Page from './page';
import { colors, painScaleReverseColors } from '../lib/styles';
import { submitPRO, updatePRO } from '../state/pro-forms';
import HealthSliderHorizontal from '../components/health-slider-horizontal';
import QuestionLayout from '../layouts/question';

const styles = {
  answerButtonContainer: {
    display: 'flex',
    marginLeft: -10,
    marginTop: 15,
  },
  contentWrapper: {
    flexGrow: 1,
    overflowX: 'hidden',
    position: 'relative',
    paddingLeft: 20,
    paddingRight: 20,
  },
  questionText: {
    fontSize: '1.5rem',
    marginTop: 25,
  },
  healthSliderWrapper: {
    marginTop: 60,
    paddingLeft: 40,
    paddingRight: 40,
  },
  boldText: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    marginTop: 30,
  },
  instructionsText: {
    color: colors.darkGrayText,
    marginTop: 10,
  },
};

const answerButtonStyles = {
  button: {
    border: 'none',
    borderRadius: 5,
    flexGrow: 1,
    marginLeft: 10,
    paddingBottom: 8,
    paddingTop: 8,
  },
  selected: {
    background: colors.primaryColor,
    color: 'white',
  },
  unselected: {
    background: '#d8d8d8',
    color: 'black',
  },
};

const AnswerButton = (props) => {
  const { children, onClick, selected } = props;

  const style = selected
    ? { ...answerButtonStyles.button, ...answerButtonStyles.selected }
    : { ...answerButtonStyles.button, ...answerButtonStyles.unselected };

  return (
    <button
      onClick={onClick}
      style={style}
      type="button"
    >
      {children}
    </button>
  );
};

AnswerButton.propTypes = {
  children: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
};

class PainLog1 extends Page {
  constructor(props) {
    super(props);

    this.state = {
      experiencingBodyPain: null,
    };
  }

  handleChangeExperiencingBodyPain = (selected) => {
    this.setState({ experiencingBodyPain: selected });
  }

  getSliderTheme = (value) => {
    return {
      color: painScaleReverseColors[round(value / 10)],
    };
  }

  handleClickContinue = () => {
    const { experiencingBodyPain } = this.state;
    const {
      location,
      submitPRO,
      user,
    } = this.props;
    const { id } = location.query;

    if (!experiencingBodyPain) {
      submitPRO(
        { userId: user.id, id },
        {
          pro_data: { data: [experiencingBodyPain] },
          pro_type: 'PAIN-LOG',
        },
        user,
      )
        .then(() => {
          this.finishTrack();
        });
    } else {
      this.forwardWithQuery(location.query);
    }
  }

  render() {
    const { classes } = this.props;
    const { experiencingBodyPain } = this.state;
    const value = get(this.props.painLog, this.getTrackIndex(), null);

    return (
      <QuestionLayout
        primaryBtnLabel="Next"
        primaryBtnOnTouchTap={this.handleClickContinue}
      >
        <AppBar
          headerNode="Body Pain"
          backButtonOnClick={() => this.props.router.goBack()}
        />
        <LinearProgress
          variant="determinate"
          value={(this.getCurrentPage() / this.getTotalPages()) * 100}
          classes={{ bar: this.props.classes.bar }}
        />
        <div className={classes.contentWrapper}>
          <div className={classes.questionText}>Are you currently experiencing body pain?</div>
          <div className={classes.answerButtonContainer}>
            <AnswerButton
              onClick={() => this.handleChangeExperiencingBodyPain(true)}
              selected={experiencingBodyPain}
            >
              Yes
            </AnswerButton>
            <AnswerButton
              onClick={() => this.handleChangeExperiencingBodyPain(false)}
              selected={experiencingBodyPain === null ? experiencingBodyPain : !experiencingBodyPain}
            >
              No
            </AnswerButton>
          </div>
          {experiencingBodyPain ? (
            <>
              <div className={classes.boldText}>Rate this pain</div>
              <div className={classes.instructionsText}>
                Use the slider to give a pain intensity on a scale from 1 - 10
              </div>
              <div className={classes.healthSliderWrapper}>
                <HealthSliderHorizontal
                  value={value || 1}
                  onChange={this.props.updatePRO}
                  max={10}
                  min={1}
                  trackIndex={this.getTrackIndex()}
                  type="painLog"
                  sliderLeftLabel="No pain"
                  sliderRightLabel="Worst imaginable pain"
                />
              </div>
            </>
          ) : null}
        </div>
      </QuestionLayout>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    proForms: {
      painLog,
    },
    user,
  } = state;
  return { painLog, user };
};

export default connect(mapStateToProps, { submitPRO, updatePRO })(withStyles(styles)(PainLog1));
