import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  Checkbox,
  FormGroup,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { connect } from 'react-redux';

import Page from './page';
import AppBar from '../components/app-bar';
import IconWrapper from '../components/icon-wrapper';
import BloomkidzLayout from '../layouts/bloomkidz';
import { BedSolid } from '../lib/icons';
import { updatePRO } from '../state/pro-forms';
import { bloomkidzStylesObj } from '../lib/bloomkidz';

const styles = bloomkidzStylesObj;

const supplementNameLabelMap = {
  melatonin: 'Melatonin',
  lTheanine: 'L-Theonine',
  magnesium: 'Magnesium',
  noSleepSupplements: 'My child does not need anything to fall alseep',
};

class BloomkidzClientWellnessProfile4 extends Page {
  constructor(props) {
    super(props);

    const stateFromRedux = props.bloomkidzClientWellnessProfile[this.getTrackIndex()] || {};

    this.state = {
      napsDuringDay: '',
      troubleWithSleep: '',
      melatonin: false,
      lTheanine: false,
      magnesium: false,
      noSleepSupplements: false,
      ...stateFromRedux,
    };
  }

  handleClickButton = (napsDuringDay) => {
    this.setState({ napsDuringDay });
  }

  handleChangleTroubleWithSleep = (e) => {
    this.setState({ troubleWithSleep: e.target.value });
  }

  handleContinue = () => {
    const { location, updatePRO } = this.props;

    updatePRO({
      type: 'bloomkidzClientWellnessProfile',
      position: this.getTrackIndex(),
      value: { ...this.state },
    });

    this.forwardWithQuery(location.query);
  }

  handleChangeSupplements = (e) => {
    this.setState({ [e.target.name]: e.target.checked });
  };

  render() {
    const { classes, router } = this.props;
    const { state } = this;
    const {
      napsDuringDay,
      troubleWithSleep,
    } = state;

    const continueButtonDisabled = !napsDuringDay || !troubleWithSleep;

    return (
      <BloomkidzLayout
        primaryBtnOnTouchTap={this.handleContinue}
        primaryBtnLabel="Next"
        primaryBtnDisabled={continueButtonDisabled}
      >
        <AppBar
          headerNode="Naps"
          backButtonOnClick={() => router.goBack()}
        />
        <IconWrapper>
          <BedSolid />
        </IconWrapper>
        <div className={classes.pageContent}>
          <div>Does your child nap during the day?</div>
          <div className={classes.buttonRow}>
            <button
              className={`${classes.button} ${napsDuringDay === 'yes' ? classes.buttonSelected : classes.buttonUnselected}`}
              onClick={() => this.handleClickButton('yes')}
              type="button"
            >
              Yes
            </button>
            <button
              className={`${classes.button} ${napsDuringDay === 'no' ? classes.buttonSelected : classes.buttonUnselected}`}
              onClick={() => this.handleClickButton('no')}
              type="button"
            >
              No
            </button>
          </div>
          <div className={classes.inputSectionTitle}>
            Do they have trouble falling asleep or staying asleep?
          </div>
          <RadioGroup
            name="troubleWithSleep"
            onChange={this.handleChangleTroubleWithSleep}
            value={troubleWithSleep}
            className={classes.radioGroup}
          >
            <FormControlLabel
              control={<Radio />}
              label="Yes"
              value="yes"
              className={classes.radio}
            />
            <FormControlLabel
              control={<Radio />}
              label="No"
              value="no"
              className={classes.radio}
            />
            <FormControlLabel
              control={<Radio />}
              label="Sometimes"
              value="sometimes"
              className={classes.radio}
            />
          </RadioGroup>
          <div className={classes.inputSectionTitle}>
            Do you give any of the following to your child to help them fall asleep at night?
          </div>
          <FormGroup>
            {Object.entries(supplementNameLabelMap).map(([name, label]) => (
              <>
                <FormControlLabel
                  control={(
                    <Checkbox
                      checked={state[name]}
                      onChange={this.handleChangeSupplements}
                      name={name}
                      icon={<CheckBoxOutlineBlankIcon fontSize="large" />}
                      checkedIcon={<CheckBoxIcon fontSize="large" />}
                      className={classes.checkbox}
                    />
                  )}
                  label={label}
                  classes={{
                    label: classes.checkboxLabel,
                    root: classes.formControlLabel,
                  }}
                />
              </>
            ))}
          </FormGroup>
        </div>
      </BloomkidzLayout>
    );
  }
}

BloomkidzClientWellnessProfile4.propTypes = {
  classes: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  updatePRO: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const {
    proForms: { bloomkidzClientWellnessProfile },
    user,
  } = state;

  return { user, bloomkidzClientWellnessProfile };
};

export default connect(mapStateToProps, { updatePRO })(withStyles(styles)(BloomkidzClientWellnessProfile4));
