import React from 'react';
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  LinearProgress,
} from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { omit } from 'lodash';

import AppBar from '../components/app-bar';
import Page from './page';
import QuestionLayout from '../layouts/question';
import YesNoButtonSet from '../components/yes-no-button-set';
import { colors } from '../lib/styles';
import { submitPRO, updatePRO } from '../state/pro-forms';
import { Heart } from '../lib/icons';
import { apiFetch, makeShadowOptions } from '../lib/fetch';

const styles = {
  checkbox: {
    paddingTop: 3,
  },
  contentWrapper: {
    flexGrow: 1,
    overflowX: 'hidden',
    position: 'relative',
  },
  formControlLabel: {
    alignItems: 'start',
  },
  imgRow: {
    display: 'flex',
    justifyContent: 'center',
    padding: '15px 60px',
  },
  imageWrapper: {
    width: 100,
  },
  marginTop40: {
    marginTop: 40,
  },
  questionnaireContainer: {
    paddingLeft: 20,
    paddingRight: 20,
  },
  questionText: {
    fontSize: '2rem',
  },
  redHeart: {
    color: '#bd433d',
  },
};

class HeartFailure16 extends Page {
  constructor(props) {
    super(props);

    this.state = {
      experiencingNewSymptoms: null,
      chestDiscomfort: false,
      shortnessOfBreath: false,
      swellingOfAnkles: false,
      newWeightGain: false,
      newCough: false,
      fatigue: false,
      sleepAtIncline: false,
    };
  }

  handleChangeExperiencingNewSymptoms = (value) => {
    this.setState({ experiencingNewSymptoms: value });
  };

  handleChangeSymptom = (e) => {
    this.setState({ [e.target.name]: e.target.checked });
  };

  handleClickNext = () => {
    const { heartFailureInitial, user } = this.props;
    const {
      experiencingNewSymptoms,
      chestDiscomfort,
      shortnessOfBreath,
      swellingOfAnkles,
      newWeightGain,
      newCough,
      fatigue,
      sleepAtIncline,
    } = this.state;

    const symptoms = [];

    if (experiencingNewSymptoms === 'Y') {
      if (chestDiscomfort) symptoms.push('Chest Discomfort/Tightness');
      if (shortnessOfBreath) symptoms.push('Shortness of Breath');
      if (swellingOfAnkles) symptoms.push('Swelling ankles, feet, or abdomen');
      if (newWeightGain) symptoms.push('Weight Gain');
      if (newCough) symptoms.push('New Cough');
      if (fatigue) symptoms.push('Fatigue');
      if (sleepAtIncline) symptoms.push('Sleep at Incline');
    }

    const value = { experiencingNewSymptoms, symptoms };

    if (heartFailureInitial[1].medications && heartFailureInitial[1].medications.length) {
      const { medications } = heartFailureInitial[1];
      const formattedMedications = medications.map((med) => {
        return omit(med, ['id', 'user_id']);
      });

      const options = makeShadowOptions({ medication_hx: formattedMedications }, user.token, 'POST');

      apiFetch(`/users/${user.id}/medication_hx_bulk`, options)
        .then(() => { this.handleSubmitPro([...heartFailureInitial, value]); });
    } else {
      this.handleSubmitPro([...heartFailureInitial, value]);
    }
  };

  handleSubmitPro = (heartFailureInitial) => {
    const { user } = this.props;
    const { id } = this.props.location.query;

    delete heartFailureInitial[1].medications;

    this.props.submitPRO({ userId: user.id, id }, {
      pro_type: 'HEART-FAILURE-INITIAL',
      pro_data: { data: heartFailureInitial },
    }, user)
      .then(() => this.finishTrack());
  };

  render() {
    const { classes } = this.props;
    const {
      experiencingNewSymptoms,
      chestDiscomfort,
      shortnessOfBreath,
      swellingOfAnkles,
      newWeightGain,
      newCough,
      fatigue,
      sleepAtIncline,
    } = this.state;

    const continueDisabled = experiencingNewSymptoms === null;

    return (
      <QuestionLayout
        primaryBtnLabel="Next"
        primaryBtnOnTouchTap={this.handleClickNext}
        primaryBtnDisabled={continueDisabled}
        style={{ backgroundColor: colors.questionBackground }}
      >
        <AppBar
          headerNode="Heart Failure"
          backButtonOnClick={() => this.props.router.goBack()}
        />
        <LinearProgress
          variant="determinate"
          value={(this.getCurrentPage() / this.getTotalPages()) * 100}
          classes={{ bar: classes.bar }}
        />
        <div className={classes.contentWrapper}>
          <div className={classes.imgRow}>
            <div className={`${classes.imageWrapper} ${classes.redHeart}`}>
              <Heart />
            </div>
          </div>
          <div className={classes.questionnaireContainer}>
            <YesNoButtonSet
              isYes={experiencingNewSymptoms === 'Y'}
              isNo={experiencingNewSymptoms === 'N'}
              onClickNo={() => this.handleChangeExperiencingNewSymptoms('N')}
              onClickYes={() => this.handleChangeExperiencingNewSymptoms('Y')}
              questionText="Are you experiencing and new symptoms?"
            />
            {experiencingNewSymptoms === 'Y' ? (
              <>
                <div className={`${classes.questionText} ${classes.marginTop40}`}>Please select all that apply</div>
                <FormGroup>
                  <FormControlLabel
                    control={(
                      <Checkbox
                        checked={chestDiscomfort}
                        onChange={this.handleChangeSymptom}
                        name="chestDiscomfort"
                        icon={<CheckBoxOutlineBlankIcon fontSize="large" />}
                        checkedIcon={<CheckBoxIcon fontSize="large" />}
                        className={classes.checkbox}
                      />
                    )}
                    label="Chest discomfort/tightness"
                    classes={{
                      label: classes.questionText,
                      root: classes.formControlLabel,
                    }}
                  />
                  <FormControlLabel
                    control={(
                      <Checkbox
                        checked={shortnessOfBreath}
                        onChange={this.handleChangeSymptom}
                        name="shortnessOfBreath"
                        icon={<CheckBoxOutlineBlankIcon fontSize="large" />}
                        checkedIcon={<CheckBoxIcon fontSize="large" />}
                        className={classes.checkbox}
                      />
                    )}
                    label="Shortness of breath at rest or with activity"
                    classes={{
                      label: classes.questionText,
                      root: classes.formControlLabel,
                    }}
                  />
                  <FormControlLabel
                    control={(
                      <Checkbox
                        checked={swellingOfAnkles}
                        onChange={this.handleChangeSymptom}
                        name="swellingOfAnkles"
                        icon={<CheckBoxOutlineBlankIcon fontSize="large" />}
                        checkedIcon={<CheckBoxIcon fontSize="large" />}
                        className={classes.checkbox}
                      />
                    )}
                    label="Swelling of ankles, feet, or abdomen"
                    classes={{
                      label: classes.questionText,
                      root: classes.formControlLabel,
                    }}
                  />
                  <FormControlLabel
                    control={(
                      <Checkbox
                        checked={newWeightGain}
                        onChange={this.handleChangeSymptom}
                        name="newWeightGain"
                        icon={<CheckBoxOutlineBlankIcon fontSize="large" />}
                        checkedIcon={<CheckBoxIcon fontSize="large" />}
                        className={classes.checkbox}
                      />
                    )}
                    label="New weight gain"
                    classes={{
                      label: classes.questionText,
                      root: classes.formControlLabel,
                    }}
                  />
                  <FormControlLabel
                    control={(
                      <Checkbox
                        checked={newCough}
                        onChange={this.handleChangeSymptom}
                        name="newCough"
                        icon={<CheckBoxOutlineBlankIcon fontSize="large" />}
                        checkedIcon={<CheckBoxIcon fontSize="large" />}
                        className={classes.checkbox}
                      />
                    )}
                    label="New cough"
                    classes={{
                      label: classes.questionText,
                      root: classes.formControlLabel,
                    }}
                  />
                  <FormControlLabel
                    control={(
                      <Checkbox
                        checked={fatigue}
                        onChange={this.handleChangeSymptom}
                        name="fatigue"
                        icon={<CheckBoxOutlineBlankIcon fontSize="large" />}
                        checkedIcon={<CheckBoxIcon fontSize="large" />}
                        className={classes.checkbox}
                      />
                    )}
                    label="Fatigue"
                    classes={{
                      label: classes.questionText,
                      root: classes.formControlLabel,
                    }}
                  />
                  <FormControlLabel
                    control={(
                      <Checkbox
                        checked={sleepAtIncline}
                        onChange={this.handleChangeSymptom}
                        name="sleepAtIncline"
                        icon={<CheckBoxOutlineBlankIcon fontSize="large" />}
                        checkedIcon={<CheckBoxIcon fontSize="large" />}
                        className={classes.checkbox}
                      />
                    )}
                    label="Needing more pillows to sleep at an incline"
                    classes={{
                      label: classes.questionText,
                      root: classes.formControlLabel,
                    }}
                  />
                </FormGroup>
              </>
            ) : null}
          </div>
        </div>
      </QuestionLayout>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    proForms: {
      heartFailureInitial,
    },
    user,
  } = state;

  return { heartFailureInitial, user };
};

export default connect(mapStateToProps, { submitPRO, updatePRO })(withStyles(styles)(HeartFailure16));
