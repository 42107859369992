import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  TextField,
} from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { connect } from 'react-redux';

import Page from './page';
import AppBar from '../components/app-bar';
import IconWrapper from '../components/icon-wrapper';
import BloomkidzLayout from '../layouts/bloomkidz';
import { Doctor } from '../lib/icons';
import { submitPRO } from '../state/pro-forms';
import { bloomkidzStylesObj } from '../lib/bloomkidz';
import { fontSizing } from '../lib/styles';

const styles = bloomkidzStylesObj;

const nameLabelMap = {
  behavioralDevelopmentTherapy: 'ABA - Behavioral/Development Therapy',
  speechTherapy: 'Speech Therapy',
  physicalTherapy: 'Physical Therapy',
  occupationalTherapy: 'Occupational Therapy',
  feedingTherapy: 'Feeding Therapy',
  otherInterventions: 'Other',
};

class BloomkidzNewClient8 extends Page {
  constructor(props) {
    super(props);

    const stateFromRedux = props.bloomkidzNewClient[this.getTrackIndex()] || {};

    this.state = {
      behavioralDevelopmentTherapy: false,
      speechTherapy: false,
      physicalTherapy: false,
      occupationalTherapy: false,
      feedingTherapy: false,
      otherInterventions: false,
      otherInterventionsList: '',
      ...stateFromRedux,
    };
  }

  handleChangeConcerns = (e) => {
    this.setState({ [e.target.name]: e.target.checked });
  };

  handleContinue = () => {
    const {
      bloomkidzNewClient,
      location,
      submitPRO,
      user,
    } = this.props;
    const { id } = location.query;

    submitPRO({ userId: user.id, id }, {
      pro_type: 'BLOOMKIDZ-NEW-CLIENT',
      pro_data: {
        data: {
          ...this.getDataMap(bloomkidzNewClient),
          ...this.state,
        },
        recorded_at: new Date().toISOString(),
      },
    }, user)
      .then(() => {
        this.forwardWithQuery(this.props.location.query);
      });
  }

  getContinueButtonText = () => {
    const { state } = this;

    const stateVals = Object.values(state);

    for (let i = 0; i < stateVals.length; i++) {
      if (stateVals[i] === true) return 'Next';
    }

    return 'Skip';
  }

  handleTextFieldChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  render() {
    const { classes, router } = this.props;
    const { state } = this;

    return (
      <BloomkidzLayout
        primaryBtnOnTouchTap={this.handleContinue}
        primaryBtnLabel={this.getContinueButtonText()}
      >
        <AppBar
          headerNode="Therapy"
          backButtonOnClick={() => router.goBack()}
        />
        <IconWrapper>
          <Doctor />
        </IconWrapper>
        <div className={classes.pageContent}>
          <div>Has your child previously received any of the following interventions?</div>
          <div className={classes.subtext}><i>Check all that apply</i></div>
          <FormGroup>
            {Object.entries(nameLabelMap).map(([name, label]) => (
              <>
                <FormControlLabel
                  control={(
                    <Checkbox
                      checked={state[name]}
                      onChange={this.handleChangeConcerns}
                      name={name}
                      icon={<CheckBoxOutlineBlankIcon fontSize="large" />}
                      checkedIcon={<CheckBoxIcon fontSize="large" />}
                      className={classes.checkbox}
                    />
                  )}
                  label={label}
                  classes={{
                    label: classes.checkboxLabel,
                    root: classes.formControlLabel,
                  }}
                />
                {(name === 'otherInterventions') && state[name] ? (
                  <div
                    className={classes.textFieldWrapper}
                    style={{ marginTop: 0, paddingLeft: 33 }}
                  >
                    <TextField
                      placeholder="Please exlain"
                      value={state.otherInterventionsList}
                      onChange={this.handleTextFieldChange}
                      name="otherInterventionsList"
                      variant="standard"
                      fullWidth
                      inputProps={{ style: { fontSize: fontSizing.body } }}
                    />
                  </div>
                ) : null}
              </>
            ))}
          </FormGroup>
        </div>
      </BloomkidzLayout>
    );
  }
}

BloomkidzNewClient8.propTypes = {
  bloomkidzNewClient: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  submitPRO: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const {
    proForms: { bloomkidzNewClient },
    user,
  } = state;

  return { user, bloomkidzNewClient };
};

export default connect(mapStateToProps, { submitPRO })(withStyles(styles)(BloomkidzNewClient8));
