import { promiseHandler, resetReducer } from 'cooldux';
import { get, isUndefined, omit, omitBy } from 'lodash';

import { apiFetch } from '../lib/fetch';

const { browseStart, browseEnd, browseError, browseHandler } = promiseHandler('browse', 'painHxAlleviatingFactors');
const { addStart, addEnd, addError, addHandler } = promiseHandler('add', 'painHxAlleviatingFactors');
const { deleteStart, deleteEnd, deleteError, deleteHandler } = promiseHandler('delete', 'painHxAlleviatingFactors');

export function browsePainHxAlleviatingFactors(painHxId) {
  return function dispatcher(dispatch, getState) {
    const selfId = get(getState(), 'user.id', null);
    const promise = apiFetch(`/users/${selfId}/pain_hx/${painHxId}/alleviating_factors`);
    return browseHandler(promise, dispatch);
  };
}

export function addPainHxAlleviatingFactors(newPainHxAlleviatingFactors) {
  return function dispatcher(dispatch, getState) {
    const selfId = get(getState(), 'user.id', null);
    const body = omit(newPainHxAlleviatingFactors, 'pain_hx_id');
    const fetchUrl = `/users/${selfId}/pain_hx/${newPainHxAlleviatingFactors.pain_hx_id}/alleviating_factors`;
    const options = {
      method: 'POST',
      body: omitBy(body, isUndefined),
    };
    const promise = apiFetch(fetchUrl, options);
    return addHandler(promise, dispatch);
  };
}

export function deletePainHxAlleviatingFactors(painHxId, painHxAlleviatingFactorsId) {
  return function dispatcher(dispatch, getState) {
    const selfId = get(getState(), 'user.id', null);
    const fetchUrl = `/users/${selfId}/pain_hx/${painHxId}/alleviating_factors/${painHxAlleviatingFactorsId}`;
    const options = {
      method: 'DELETE',
    };
    const promise = apiFetch(fetchUrl, options)
      .then(() => painHxAlleviatingFactorsId);

    return deleteHandler(promise, dispatch);
  };
}

const initialState = {
  browseError: null,
  addError: null,
  deleteError: null,
  browsePending: false,
  addPending: false,
  deletePending: false,
  data: {},
};

function finishBrowse(state, painHxAlleviatingFactors) {
  const data = {};
  painHxAlleviatingFactors.forEach((u) => {
    data[u.id] = u;
  });
  return { ...state, data, browsePending: false, browseError: null };
}

function finishAdd(state, item) {
  const data = { ...state.data, [item.alleviating_factors_id]: item };
  return { ...state, addPending: false, addError: null, data };
}

function finishDelete(state, painHxAlleviatingFactorsId) {
  return { ...state, deletePending: false, deleteError: null, data: omit(state.data, painHxAlleviatingFactorsId) };
}

const painHxAlleviatingFactors = resetReducer(initialState, (state = initialState, action) => {
  switch (action.type) {
    case browseStart.type:
      return { ...state, browsePending: true };
    case browseEnd.type:
      return finishBrowse(state, action.payload);
    case browseError.type:
      return { ...state, browsePending: false, browseError: action.payload };
    case addStart.type:
      return { ...state, addPending: true };
    case addEnd.type:
      return finishAdd(state, action.payload);
    case addError.type:
      return { ...state, addPending: false, addError: action.payload };
    case deleteStart.type:
      return { ...state, deletePending: true };
    case deleteEnd.type:
      return finishDelete(state, action.payload);
    case deleteError.type:
      return { ...state, deletePending: false, deleteError: action.payload };
    default:
      return state;
  }
});

export default painHxAlleviatingFactors;
