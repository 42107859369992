import EventEmitter from 'events';
import { startRearVideo, startVideo } from './createAnswer';
import config from '../config';
import { sendImages } from './fetch';

const FRAMES_IN_PAYLOAD = 20; // we multiple this by a constant as we fall behind
const MAX_IN_FLIGHT = 1; // low bandiwdth users seem harmed by more than 1

export class CameraBuddy extends EventEmitter {
  rear; // constructor

  patientVideo; // constructor

  userId; // constructor

  cropFace; // constructor

  imgEncoding; // constructor

  jpegQuality; // constructor

  recordMilliseconds; // constructor

  timeBetweenFrames; // constructor

  FPS; // constructor

  sendTimer; // startCamera inits

  timestamps; // startCamera inits

  framesToSend; // startCamera inits

  offscreenCanvas; // startCamera inits

  offCtx; // startCamera inits

  startTimestamp; // onFrame inits and mutates

  finishedRecording = false; // onFrame mutates

  saveRequested = 0; // onFrame mutates

  batchInProgress = 0; // drainFrames mutates

  savedCount = 0; // drainFrames mutates

  failedCount = 0; // drainFrames mutates

  saving = false; // setter

  sendImages = null; // setter

  emitImgData = false; //setter

  constructor(patientVideo, userId, cropFace, imgEncoding, jpegQuality, recordMilliseconds, FPS, rear = false) {
    super();
    this.patientVideo = patientVideo;
    this.recordMilliseconds = recordMilliseconds;
    this.FPS = FPS;
    if (FPS !== 'S-METHOD') {
      this.timeBetweenFrames = Math.round(1000 / this.FPS);
    }
    this.userId = userId;
    this.cropFace = cropFace;
    this.imgEncoding = imgEncoding;
    this.jpegQuality = jpegQuality;
    this.rear = rear;
  }

  startSaving() {
    this.saving = true;
  }

  startEmittingImgData() {
    this.emitImgData = true;
  }

  // use a user supplied sendImages, presumably from a worker
  setSendImages(sendImages) {
    this.sendImages = sendImages;
  }

  drainFrames = async () => {
    const { userId, sendTimer, framesToSend, patientVideo, imgEncoding, startTimestamp,
      jpegQuality, saveRequested, finishedRecording } = this;

    if (saveRequested === 0) return;

    if (finishedRecording && (this.savedCount + this.failedCount === saveRequested)) {
      clearInterval(sendTimer);
      this.emit('finishedSaving');
    }

    // if were falling behind send larger batches
    let frames_in_payload;
    if (framesToSend.length > (FRAMES_IN_PAYLOAD * 6)) {
      frames_in_payload = FRAMES_IN_PAYLOAD * 6;
    } else if (framesToSend.length > (FRAMES_IN_PAYLOAD * 4)) {
      frames_in_payload = FRAMES_IN_PAYLOAD * 4;
    } else if (framesToSend.length > (FRAMES_IN_PAYLOAD * 2)) {
      frames_in_payload = FRAMES_IN_PAYLOAD * 2;
    } else {
      frames_in_payload = FRAMES_IN_PAYLOAD;
    }

    const sizedToSend = framesToSend.length >= frames_in_payload;
    const underSizedtoSend = finishedRecording && framesToSend.length > 0 && framesToSend.length < frames_in_payload;

    if ((this.batchInProgress < MAX_IN_FLIGHT) && (underSizedtoSend || sizedToSend)) {
      this.batchInProgress += 1;
      const frames = framesToSend.splice(0, frames_in_payload);
      const patientVideoCurrent = patientVideo.current;
      const pvH = patientVideoCurrent.videoHeight;
      const pvW = patientVideoCurrent.videoWidth;

      let fn;
      let retryCallback;

      // we cant use the retryCallback for worker based sendImages
      if (this.sendImages) {
        fn = this.sendImages;
      } else {
        retryCallback = () => { this.emit('retry') };
        fn = sendImages;
      }

      let res;
      let error;
      try {
        res = await fn(
          frames,
          pvW / 2,
          pvH / 2,
          null,
          `${config.API_URL}/users/${userId}/vital_images/${startTimestamp}`,
          imgEncoding,
          jpegQuality,
          retryCallback
        );
      } catch (e) {
        error = e;
      }
      this.batchInProgress -= 1;

      if (error || !res.ok) {
        this.failedCount += frames.length;
        this.emit('failedCount', this.failedCount, res, error);
      } else {
        this.savedCount += frames.length;
        this.emit('savedCount', this.savedCount, res);
      }
    }
  };

  startCamera = async (colorSpace = undefined) => {
    const { patientVideo } = this;

    this.timestamps = [];
    this.framesToSend = [];
    this.offscreenCanvas = document.createElement('canvas');
    this.offCtx = this.offscreenCanvas.getContext('2d', { willReadFrequently: true, colorSpace });
    return new Promise((resolve, reject) => {
      const patientVideoCurrent = patientVideo.current; // document.createElement('video');
      const cameraObject = {};

      let cameraFn;
      if (this.rear) {
        cameraFn = async () => { return startRearVideo(cameraObject, 'spo2', false); };
      } else {
        cameraFn = async () => { return startVideo(cameraObject); };
      }

      return cameraFn()
        .then(() => {
          const stream = this.rear ? cameraObject.rearStream : cameraObject.stream;
          patientVideoCurrent.srcObject = stream;
          patientVideoCurrent.addEventListener('loadeddata', () => {
            this.offscreenCanvas.height = patientVideoCurrent.videoHeight;
            this.offscreenCanvas.width = patientVideoCurrent.videoWidth;

            // todo what rate wont slow down?, should this be elsewhere?
            this.sendTimer = setInterval(this.drainFrames, 100);

            // wait half a second because first few frames are always black when turning on camera.
            setTimeout(this.onframe, 500);

            resolve(cameraObject);
          }, { once: true });
          return patientVideoCurrent.play();
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getImageData = (ctx, pvW, pvH, cropFace) => {
    if (cropFace) {
      return ctx.getImageData(pvW / 4, pvH / 4, pvW / 2, pvH / 2);
    }
    return ctx.getImageData(0, 0, pvW, pvH);
  };

  onframe = async () => {
    const { saving, recordMilliseconds, timeBetweenFrames, FPS, patientVideo, cropFace, framesToSend, timestamps } = this;
    const patientVideoCurrent = patientVideo.current;
    if (!patientVideoCurrent || patientVideoCurrent.videoWidth === 0 || this.finishedRecording) {
      return;
    }

    if (FPS !== 'S-METHOD') {
      setTimeout(this.onframe, timeBetweenFrames);
    } else {
      patientVideoCurrent.requestVideoFrameCallback(this.onframe);
    }

    const pvH = patientVideoCurrent.videoHeight;
    const pvW = patientVideoCurrent.videoWidth;

    const timestamp = Date.now();
    this.emit('frame', patientVideoCurrent, pvW, pvH, timestamp);

    if (saving || this.emitImgData) {
      this.offCtx.drawImage(patientVideoCurrent, 0, 0, pvW, pvH);

      const imgData = this.getImageData(this.offCtx, pvW, pvH, cropFace);

      if (this.emitImgData) {
        this.emit('imgData', { patientVideoCurrent, pvW, pvH, timestamp, imgData });
      }

      if (saving) {
        if (!this.startTimestamp) {
          this.startTimestamp = timestamp;
        }
        if ((timestamp - this.startTimestamp) <= recordMilliseconds) {
          framesToSend.push({ imgData, timestamp });
          this.saveRequested += 1;
          timestamps.push(timestamp);
          this.emit('saveRequested', this.saveRequested, timestamp);
        } else {
          this.finishedRecording = true;
          this.emit('finishedRecording', this.timestamps);
        }
      }
    }
  };

  cleanup() {
    clearInterval(this.sendTimer);
    const videoElement = this.patientVideo.current;
    let stream = videoElement.srcObject;
    if (stream) {
      stream.getTracks().forEach(t => t.stop());
      stream = null;
    }
  }
}
