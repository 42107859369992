import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { KeypadDate } from 'common-ui';

import { colors } from '../lib/styles';

const baseStyles = {
  text: {
    color: colors.primaryColor,
    fontSize: 18,
  },
  underline: {
    display: 'none',
  },
  select: {
    color: colors.greyText,
    fontSize: 18,
    minWidth: 100,
  },
  selectWrapper: {
    '& .MuiInput-underline:before': {
      borderBottom: 'none',
    },
  },
  textField: {
    '& input': {
      color: colors.greyText,
      fontSize: 18,
    },
    '& label': {
      color: colors.greyText,
      fontSize: 18,
    },
    '& .MuiInput-underline:before': {
      borderBottom: 'none',
    },
  },
};

const heights = [];
for (let i = 50; i < 108; i++) {
  heights.push(<MenuItem value={`${i}"`} key={i}>{`${i}"`}</MenuItem>);
}

class BasicInfo extends Component {
  render() {
    const {
      classes,
      hideDob,
      hidePhone,
      patientContactInfo,
      personalInfo,
    } = this.props;
    return (
      <Table selectable={false}>
        <TableBody displayRowCheckbox={false}>
          {hideDob ? null : (
            <TableRow>
              <TableCell
                style={baseStyles.text}
              >
                Date of Birth
              </TableCell>
              <TableCell>
                <KeypadDate onChange={this.props.onChangeDob} value={personalInfo.birth_date} />
              </TableCell>
            </TableRow>
          )}
          <TableRow>
            <TableCell style={baseStyles.text}>Gender</TableCell>
            <TableCell style={baseStyles.text}>
              <div className={classes.selectWrapper}>
                <InputLabel>Select Gender</InputLabel>
                <Select
                  value={personalInfo.sex}
                  onChange={this.props.onChangeSex}
                  underlineStyle={baseStyles.underline}
                  style={baseStyles.select}
                >
                  <MenuItem value="MALE">Male</MenuItem>
                  <MenuItem value="FEMALE">Female</MenuItem>
                  <MenuItem value="OTHER">Other</MenuItem>
                </Select>
              </div>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={baseStyles.text}>Primary Language</TableCell>
            <TableCell style={baseStyles.text}>
              <div className={classes.selectWrapper}>
                <InputLabel>Select Primary Language</InputLabel>
                <Select
                  value={personalInfo.primary_language}
                  onChange={this.props.onChangeLanguage}
                  underlineStyle={baseStyles.underline}
                  style={baseStyles.select}
                >
                  <MenuItem value="en">English</MenuItem>
                  <MenuItem value="es">Spanish</MenuItem>
                </Select>
              </div>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={baseStyles.text}>Marital Status</TableCell>
            <TableCell style={baseStyles.text}>
              <div className={classes.selectWrapper}>
                <InputLabel>Select Marital Status</InputLabel>
                <Select
                  value={personalInfo.marital_status}
                  onChange={this.props.onChangeMaritalStatus}
                  underlineStyle={baseStyles.underline}
                  style={baseStyles.select}
                >
                  <MenuItem value="SINGLE">Single</MenuItem>
                  <MenuItem value="MARRIED">Married</MenuItem>
                  <MenuItem value="DIVORCED">Divorced</MenuItem>
                </Select>
              </div>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={baseStyles.text}>Weight</TableCell>
            <TableCell style={baseStyles.text}>
              <TextField
                placeholder="Example: 185"
                type='tel'
                onChange={this.props.onChangeWeight}
                classes={{ root: classes.textField }}
                value={personalInfo.weight}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={baseStyles.text}>Height</TableCell>
            <TableCell style={baseStyles.text}>
              <div className={classes.selectWrapper}>
                <InputLabel>Select Height</InputLabel>
                <Select
                  value={personalInfo.height}
                  onChange={this.props.onChangeHeight}
                  underlineStyle={baseStyles.underline}
                  style={baseStyles.select}
                  maxHeight={200}
                >
                  <MenuItem value="none" primaryText="Scroll for Height" />
                  {heights}
                </Select>
              </div>
            </TableCell>
          </TableRow>
          {hidePhone ? null : (
            <TableRow>
              <TableCell style={baseStyles.text}>Cell Phone</TableCell>
              <TableCell style={baseStyles.text}>
                <TextField
                  placeholder="Cell Phone"
                  type='tel'
                  onChange={this.props.onChangePhone}
                  classes={{ root: classes.textField }}
                  value={patientContactInfo.cell_phone}
                />
              </TableCell>
            </TableRow>)
          }
        </TableBody>
      </Table>
    );
  }
}

BasicInfo.defaultProps = {
  hidePhone: false,
  hideDob: false,
};

BasicInfo.propTypes = {
  hideDob: PropTypes.bool,
  hidePhone: PropTypes.bool,
  onChangeDob: PropTypes.func.isRequired,
  onChangeLanguage: PropTypes.func.isRequired,
  onChangeSex: PropTypes.func.isRequired,
  onChangeHeight: PropTypes.func.isRequired,
  onChangeWeight: PropTypes.func.isRequired,
  onChangeMaritalStatus: PropTypes.func.isRequired,
  onChangePhone: PropTypes.func.isRequired,

  patientContactInfo: PropTypes.object.isRequired,
  personalInfo: PropTypes.object.isRequired,
};

export default withStyles(baseStyles)(BasicInfo);
