import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import AppBar from '../components/app-bar';
import Page from './page';
import QuestionLayout from '../layouts/question';
import YesNoButtonSet from '../components/yes-no-button-set';
import { colors } from '../lib/styles';
import { updatePRO } from '../state/pro-forms';
import { Swallowing } from '../lib/icons';

const styles = {
  breathingText: {
    fontSize: '2rem',
    fontWeight: 'bold',
  },
  contentWrapper: {
    flexGrow: 1,
    overflowX: 'hidden',
    position: 'relative',
  },
  imgRow: {
    display: 'flex',
    justifyContent: 'center',
    padding: '15px 60px',
  },
  imageWrapper: {
    width: 100,
  },
  instructions: {
    color: 'grey',
    fontSize: '1.5rem',
    margin: '20px 40px 0px 40px',
    textAlign: 'center',
  },
  questionnaireContainer: {
    paddingLeft: 20,
    paddingRight: 20,
  },
};

class HeadAndNeckCancerSymptoms1 extends Page {
  constructor(props) {
    super(props);

    this.state = {
      difficultySwallowing: null,
      worseToday: null,
    };
  }

  handleChangeDifficultySwallowing = (value) => {
    this.setState({ difficultySwallowing: value });
  }

  handleChangeWorseToday = (value) => {
    this.setState({ worseToday: value });
  }

  handleClickNext = () => {
    const { difficultySwallowing, worseToday } = this.state;

    const value = { difficultySwallowing };

    if (difficultySwallowing) value.worseToday = worseToday;

    this.props.updatePRO({
      type: 'headAndNeckCancerSymptoms',
      position: this.getTrackIndex(),
      value,
    });

    if (difficultySwallowing === 'N') {
      return this.goToIndexWithQuery(3, this.props.location.query);
    }

    this.forwardWithQuery(this.props.location.query);
  }

  render() {
    const { classes } = this.props;
    const { difficultySwallowing, worseToday } = this.state;

    const continueDisabled = difficultySwallowing === null;

    return (
      <QuestionLayout
        primaryBtnLabel="Next"
        primaryBtnOnTouchTap={this.handleClickNext}
        primaryBtnDisabled={continueDisabled}
        style={{ backgroundColor: colors.questionBackground }}
      >
        <AppBar
          headerNode="Swallowing"
          backButtonOnClick={() => this.props.router.goBack()}
        />
        <LinearProgress
          variant="determinate"
          value={(this.getCurrentPage() / this.getTotalPages()) * 100}
          classes={{ bar: classes.bar }}
        />
        <div className={classes.contentWrapper}>
          <div className={classes.instructions}>
            Please select the option that best answers the questions relating to swallowing
          </div>
          <div className={classes.imgRow}>
            <div className={classes.imageWrapper}>
              <Swallowing />
            </div>
          </div>
          <div className={classes.questionnaireContainer}>
            <YesNoButtonSet
              isYes={difficultySwallowing === 'Y'}
              isNo={difficultySwallowing === 'N'}
              onClickNo={() => this.handleChangeDifficultySwallowing('N')}
              onClickYes={() => this.handleChangeDifficultySwallowing('Y')}
              questionText="Did you have difficulty swallowing today?"
            />
            {difficultySwallowing === 'Y' ? (
              <YesNoButtonSet
                isYes={worseToday === 'Y'}
                isNo={worseToday === 'N'}
                onClickNo={() => this.handleChangeWorseToday('N')}
                onClickYes={() => this.handleChangeWorseToday('Y')}
                questionText="Is it worse today than yesterday?"
              />
            ) : null}
          </div>
        </div>
      </QuestionLayout>
    );
  }
}

export default connect(null, { updatePRO })(withStyles(styles)(HeadAndNeckCancerSymptoms1));
