import React from 'react';
import { connect } from 'react-redux';
import { forEach } from 'lodash';
import { List } from '@material-ui/core';

import AppBar from '../components/app-bar';
import Page from './page';
import Layout from '../layouts/common';
import { surgeryIfNeeded } from '../state/app-data';
import { browseSurgeryHx, addSurgeryHx, deleteSurgeryHx, editSurgeryHx } from '../state/surgery-hx';
import Continue from '../components/continue';
import Subtitle from '../components/subtitle';
import SurgeryRow from '../components/surgery-row';
import SurgeryYearRow from '../components/surgery-year-row';
import QuestionHeader from '../components/question-header';

class SurgeryHx extends Page {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.getHx = this.getHx.bind(this);
    this.handleContinue = this.handleContinue.bind(this);
  }

  componentWillMount() {
    this.props.surgeryIfNeeded();
    this.props.browseSurgeryHx();
  }

  onChange(surgery_id, checked, hx) {
    if (checked && !hx) {
      return this.props.addSurgeryHx({ surgery_id, year: String(new Date().getFullYear()) })
        .then(() => this.props.browseSurgeryHx());
    }
    if (hx && !checked) {
      return this.props.deleteSurgeryHx(hx.id)
        .then(() => this.props.browseSurgeryHx());
    }
    if (hx && checked) {
      hx.surgeon_name = '-';
      return this.props.editSurgeryHx(hx)
        .then(() => this.props.browseSurgeryHx());
    }
  }

  getHx(id) {
    const { data } = this.props.surgeryHx;
    return data[id];
  }

  handleContinue() {
    this.forward();
  }

  render() {
    const { surgery: surgeries } = this.props.appData;
    const { addPending, deletePending } = this.props.surgeryHx;

    const listItems = [];

    forEach(surgeries, (surgery) => {
      const hx = this.getHx(surgery.id);
      if (surgery.surgery_categories === 'HEART') {
        listItems.push(<SurgeryRow
          key={`surgery-${surgery.id}`}
          onChange={this.onChange}
          hx={hx}
          surgery={surgery}
          disabled={addPending || deletePending}
        />);

        if (hx && surgery.id !== 1) {
          listItems.push(<SurgeryYearRow
            key={`year-${surgery.id}`}
            onChange={this.onChange}
            hx={hx}
            surgery={surgery}
            disabled={addPending || deletePending}
          />);
        }
      }
    });

    return (
      <Layout>
        <div>
          <AppBar
            backButtonOnClick={() => this.props.router.goBack()}
            headerNode="Heart Surgery"
          />
          <QuestionHeader>Have You Ever Had Heart Surgery?</QuestionHeader>
          <Subtitle label="SCROLL TO SEE ALL OPTIONS" />
          <List>
            {listItems}
          </List>
          <section>
            <Continue onClick={this.handleContinue} />
          </section>
        </div>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  const { user, appData, surgeryHx } = state;
  return {
    user,
    appData,
    surgeryHx,
  };
}

export default connect(mapStateToProps, {
  surgeryIfNeeded,
  browseSurgeryHx,
  addSurgeryHx,
  deleteSurgeryHx,
  editSurgeryHx,
})(SurgeryHx);
