import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';

import AppBar from '../components/app-bar';
import Continue from '../components/continue';

import { User } from '../lib/icons';

import Page from './page';

import { updatePRO } from '../state/pro-forms';

const styles = {
  button: {
    margin: 5,
    width: 150,
    height: 40,
    fontSize: 16,
  },
  buttonGroup: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  iconContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    flexGrow: 0,
    flexShrink: 0,
    width: '100%',
    color: '#000',
    paddingTop: 10,
  },
  iconWrapper: {
    width: 100,
  },
  titleGroup: {
    margin: 25,
  },
  text: {
    fontSize: '1.75rem',
  },
  nextBtn: {
    textAlign: 'center',
    width: '100%',
    top: '90%',
  },
};

class VitalsDataCollection2 extends Page {
  constructor(props) {
    super(props);

    this.state = {
      facialHair: null,
      facialTattoos: null,
      glasses: null,
    };

    this.handleContinue = this.handleContinue.bind(this);
  }

  handleContinue() {
    this.props.updatePRO({
      type: 'vitalsDataCollection',
      position: this.getTrackIndex(),
      value: this.state });
    this.forwardWithQuery(this.props.location.query);
  }

  render() {
    const { facialHair, facialTattoos, glasses } = this.state;

    return (
      <Fragment>
        <AppBar
          headerNode="Patient Information"
          noLeftNode={true}
        />
        <LinearProgress
          variant="determinate"
          value={(this.getCurrentPage() / this.getTotalPages()) * 100}
        />
        <div style={styles.iconContainer}>
          <div style={styles.iconWrapper}>
            <User />
          </div>
        </div>

        <div style={styles.titleGroup}>
          <p style={styles.text}>Facial Hair</p>
          <div style={styles.buttonGroup}>
            <Button style={styles.button} variant="contained" color={facialHair ? 'primary' : 'default'} onClick={() => this.setState({ facialHair: true })}>Yes</Button>
            <Button style={styles.button} variant="contained" color={facialHair === false ? 'primary' : 'default'} onClick={() => this.setState({ facialHair: false })}>No</Button>
          </div>
        </div>

        <div style={styles.titleGroup}>
          <p style={styles.text}>Facial Tattoos</p>
          <div style={styles.buttonGroup}>
            <Button style={styles.button} variant="contained" color={facialTattoos ? 'primary' : 'default'} onClick={() => this.setState({ facialTattoos: true })}>Yes</Button>
            <Button style={styles.button} variant="contained" color={facialTattoos === false ? 'primary' : 'default'} onClick={() => this.setState({ facialTattoos: false })}>No</Button>
          </div>
        </div>

        <div style={styles.titleGroup}>
          <p style={styles.text}>Glasses</p>
          <div style={styles.buttonGroup}>
            <Button style={styles.button} variant="contained" color={glasses ? 'primary' : 'default'} onClick={() => this.setState({ glasses: true })}>Yes</Button>
            <Button style={styles.button} variant="contained" color={glasses === false ? 'primary' : 'default'} onClick={() => this.setState({ glasses: false })}>No</Button>
          </div>
        </div>

        <div style={styles.nextBtn}>
          <Continue
            disabled={facialHair === null || facialTattoos === null || glasses === null}
            text="Next"
            onClick={this.handleContinue}
          />
        </div>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { user, proForms: { vitalsDataCollection } } = state;
  return { user, vitalsDataCollection };
}

VitalsDataCollection2.proptypes = {
  user: PropTypes.object.isRequired,
  route: PropTypes.object.isRequired,
  updatePRO: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { updatePRO })(VitalsDataCollection2);
