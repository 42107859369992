import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import {
  TextField,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { KeypadDate } from 'common-ui';
import Joi from 'joi-browser';

import Page from './page';
import AppBar from '../components/app-bar';
import BloomkidzLayout from '../layouts/bloomkidz';
import { updatePRO } from '../state/pro-forms';
import { bloomkidzStylesObj, bloomkidzStyles } from '../lib/bloomkidz';
import { fontSizing } from '../lib/styles';

const formSchema = Joi.object({
  illnessDOB: Joi.date(),
});

const styles = {
  ...bloomkidzStylesObj,
  centerText: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
  },
  emphasis: {
    fontWeight: 600,
  },
  spacing: {
    margin: '20px 0 10px',
  },
  paragraph: {
    textAlign: 'left',
    width: '90vw',
    maxWidth: '700px',
    height: '80vh',
    fontSize: '16px',
    margin: '20px auto',
    backgroundColor: '#FFF',
    overflowY: 'scroll',
    boxShadow: 'inset 1px 1px 5px #9B9B9B',
    padding: '10px',
    borderRadius: '2px',
    display: 'flex',
    flexDirection: 'column',
  },
};

class BloomkidzSignatures16 extends Page {
  constructor(props) {
    super(props);

    const stateFromRedux = props.bloomkidzSignatures[this.getTrackIndex()] || {};

    this.state = {
      formBottom: false,
      illnessGuardian1: '',
      illnessChild1: '',
      illnessDOB: '',
      illnessRelationship1: '',
      illnessAcknowledgement1: false,
      error: false,
      ...stateFromRedux,
    };
  }

  handleScroll = (e) => {
    if (e.target.scrollTop / e.target.scrollHeight > 0.90) {
      this.setState({ formBottom: true });
    }
  }

  handleTextFieldChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleChangeDOB = (illnessDOB) => {
    this.setState({ illnessDOB, error: '' });
  }

  handleCheck = (e) => {
    this.setState({ [e.target.name]: e.target.checked });
  }

  handleContinue = () => {
    const { location, updatePRO } = this.props;
    const { illnessDOB } = this.state;

    const { error } = formSchema.validate({ illnessDOB }, { abortEarly: false });

    if (error) {
      this.setState({ error: 'Invalid value' });
      return;
    }

    updatePRO({
      type: 'bloomkidzSignatures',
      position: this.getTrackIndex(),
      value: { ...this.state },
    });

    this.forwardWithQuery(location.query);
  }

  render() {
    const { classes, router } = this.props;
    const { illnessGuardian1, illnessChild1, illnessRelationship1, illnessAcknowledgement1, illnessDOB, error } = this.state;

    const continueDisabled = !illnessGuardian1 || !illnessChild1 || !illnessRelationship1 || !illnessAcknowledgement1 || !illnessDOB || error;

    const keypadDateWrapperClass = error
      ? `${classes.keypadDateWrapper} ${classes.keypadDateWrapperError}`
      : classes.keypadDateWrapper;

    return (
      <BloomkidzLayout
        primaryBtnOnTouchTap={this.handleContinue}
        primaryBtnLabel="Next"
        primaryBtnDisabled={continueDisabled}
      >
        <AppBar
          headerNode="Bloomkidz Illness Policy"
          backButtonOnClick={() => router.goBack()}
        />
        <div className={classes.pageContent}>
          <div className={classes.inputSectionTitle}>
            Child&apos;s Name
          </div>
          <div className={classes.paddingHorizontal}>
            <div className={classes.textFieldWrapper} style={{ display: 'flex', alignItems: 'center' }}>
              <TextField
                placeholder="Full Name"
                value={illnessChild1}
                onChange={this.handleTextFieldChange}
                name="illnessChild1"
                variant="standard"
                fullWidth
                inputProps={{ style: { fontSize: fontSizing.body, fontFamily: bloomkidzStyles.fontFamily } }}
              />
            </div>
          </div>
          <div className={classes.inputSectionTitle}>
            Date of Birth
          </div>
          <div className={classes.paddingHorizontal}>
            <div className={keypadDateWrapperClass}>
              <KeypadDate onChange={this.handleChangeDOB} value={illnessDOB} />
            </div>
            {error ? (
              <div className={classes.errorHelperText}>
                {error}
              </div>
            ) : null}
          </div>
          <div style={{ backgroundColor: '#FFF', height: '80vh', marginBottom: '20px' }}>
            <div
              ref={(form) => {
                this.myForm = form;
              }}
              onScroll={this.handleScroll}
              className={classes.paragraph}
            >
              <img alt="logo" style={{ width: '90%', alignSelf: 'center', maxWidth: '400px', padding: '15px' }} src="/img/Bloomkidz.png" />
              <h4 color={bloomkidzStyles.primaryColor}>Bloomkidz Illness Policy</h4>
              <div>
                Child&apos;s Name: {illnessChild1}
              </div>
              <div>
                Date of Birth: {illnessDOB}
              </div>
              <div>
                Please notify the clinic and keep your child at home if they are exhibiting any of the following symptoms:
              </div>
              <ul>
                <li>
                  Fever greater than 99.6
                </li>
                <li>
                  Coughing, sneezing, or runny nose
                </li>
                <li>
                  Loss of appetite or fatigue
                </li>
                <li>
                  Vomiting or diarrhea
                </li>
              </ul>
              <div>
                Children will be allowed to return to BloomKidz after the child has been:
              </div>
              <ul>
                <li>
                  Free of vomiting and/or diarrhea for 48 hours
                </li>
                <li>
                  Fever free for 24hrs without the help of fever reducing medications
                </li>
                <li>
                  The child is symptom free at the time of return.
                </li>
              </ul>
              <div>
                Follow up with your child&apos;s pediatrician if you feel that these symptoms are not resolving or have become worse.
              </div>
              <div>
                If the child is exhibiting these symptoms while at the clinic, a parent or guardian will be notified by a staff member and the child will be sent home in order to keep our staff and other clients healthy.
              </div>
              <h4 color={bloomkidzStyles.primaryColor}>COVID Policy</h4>
              <div>
                Due to COVID-19, we are taking extra precautions to keep both our staff and patients safe. Parents/guardians must sign this form prior to participating/resuming in-clinic therapy services.
              </div>
              <div>
                Below are the precautions our office is taking to protect our staff and patients:
              </div>
              <ul>
                <li>
                  A brief health check may take place outside or in the lobby. We will ask the parent/caregiver a few questions and then take your child&apos;s temperature with a contactless thermometer. If the child&apos;s temperature is 99.6 or greater, they will not be able to enter our office at that time.
                </li>
                <li>
                  If your child is coming for an initial evaluation or for his/her first therapy visit, the evaluating/treating therapist will meet you and your child in the lobby and transition with you to the office for the evaluation/first session.
                </li>
                <li>
                  Therapy start times will be staggered to allow for sanitizing between sessions. We will follow the CDC and WHO Guidelines to clean therapy workspaces and equipment.
                </li>
                <li>
                  Each therapist and child will follow CDC and WHO protocol for hand-washing before and after each session.
                </li>
                <li>
                  Therapists will clean and disinfect all toys/equipment after each activity and/or session.
                </li>
              </ul>
              <div>
                We ask that you do not bring your child to his/her therapy sessions under the following circumstances. Your child&apos;s therapist will also abide by the following:
              </div>
              <ul>
                <li>
                  If your child or anyone living in your home has experienced or come in close contact with someone with the following:
                  <ul>
                    <li>
                      Fever or chills or cough
                    </li>
                    <li>
                      Shortness of breath/difficulty breathing or fatigue
                    </li>
                    <li>
                      Muscle or body aches
                    </li>
                    <li>
                      Headache
                    </li>
                    <li>
                      New loss of taste of smell or sore throat
                    </li>
                    <li>
                      Congestion or runny nose or nausea or vomiting
                    </li>
                    <li>
                      Diarrhea
                    </li>
                  </ul>
                </li>
                <li>
                  If your child or anyone living in your home has come in contact with someone with a confirmed COVID-19 diagnosis.
                </li>
                <li>
                  If your child or anyone living in your home has traveled domestically or internationally to a region heavily affected by COVID-19 in the past two weeks.
                </li>
              </ul>
            </div>
          </div>
          <FormControlLabel
            control={(
              <Checkbox
                checked={illnessAcknowledgement1}
                onChange={this.handleCheck}
                name="illnessAcknowledgement1"
                icon={<CheckBoxOutlineBlankIcon />}
                checkedIcon={<CheckBoxIcon />}
                className={classes.checkbox}
              />
            )}
            label="In the event that the therapist and/or patient fall ill following therapy sessions, neither party will be held liable or responsible for the illness, including COVID-19. Both parties agree not to come to therapy sessions if they feel ill in any way that would indicate they might be coming down with an illness. Should symptoms of illness onset while your child is at BloomKidz, one of our nurses will conduct a wellness check to document symptoms and will follow protocol to contact parents and send children home with additional instructions."
            classes={{
              label: classes.checkboxLabel,
              root: classes.formControlLabel,
            }}
          />
          <div className={`${classes.emphasis} ${classes.spacing}`}>Parent/Guardian Printed Name</div>
          <div className={classes.paddingHorizontal}>
            <div className={classes.textFieldWrapper}>
              <TextField
                placeholder="Full Name"
                value={illnessGuardian1}
                onChange={this.handleTextFieldChange}
                name="illnessGuardian1"
                variant="standard"
                fullWidth
                inputProps={{ style: { fontSize: fontSizing.body, fontFamily: bloomkidzStyles.fontFamily } }}
              />
            </div>
          </div>
          <div className={`${classes.emphasis} ${classes.spacing}`}>Relationship to the Client</div>
          <div className={classes.paddingHorizontal}>
            <div className={classes.textFieldWrapper}>
              <TextField
                placeholder="Relationship to Client"
                value={illnessRelationship1}
                onChange={this.handleTextFieldChange}
                name="illnessRelationship1"
                variant="standard"
                fullWidth
                inputProps={{ style: { fontSize: fontSizing.body, fontFamily: bloomkidzStyles.fontFamily } }}
              />
            </div>
          </div>
        </div>
      </BloomkidzLayout>
    );
  }
}

BloomkidzSignatures16.propTypes = {
  classes: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  updatePRO: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const {
    proForms: { bloomkidzSignatures },
  } = state;

  return { bloomkidzSignatures };
};

export default connect(mapStateToProps, { updatePRO })(withStyles(styles)(BloomkidzSignatures16));
