import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get } from 'lodash';

import { colors } from '../lib/styles';
import Page from './page';
import Question from '../components/question';
import { Work } from '../lib/icons';
import { updatePRO, submitPRO } from '../state/pro-forms';

const options = [
  {
    label: 'My normal homemaking/job activities do not cause pain',
    value: 0,
  },
  {
    label: 'My normal homemaking/job activities increase my pain, but I can still perform all that is required of me.',
    value: 1,
  },
  {
    label: 'I can perform most of my homemaking/job duties, but pain pain prevents me from performing more physically stressful activities (e.e lifting, vacuuming)',
    value: 2,
  },
  {
    label: 'Pain prevents me from doing anything but light duties',
    value: 3,
  },
  {
    label: 'Pain prevents me from doing even light duties',
    value: 4,
  },
  {
    label: 'Pain prevents me from performing any job or homemaking chores.',
    value: 5,
  },
];
const { questionBackground } = colors;

class OdiEmployementHomemaking extends Page {
  state = {
    submitting: false,
  }
  onClickBackButton = () => {
    this.backward();
  }

  onSubmission = () => {
    if(this.state.submitting) return;
    const { user } = this.props;
    const { id } = this.props.location.query;
    this.setState({ submitting: true });

    this.props.submitPRO({ userId: user.id, id }, {
      pro_type: 'ODI',
      pro_data: { data: this.props.odi },
    }, user)
      .then(() => this.forward())
      .catch(() => this.setState({ submitting: false }));
  }

  render() {
    const value = get(this.props.odi, this.getTrackIndex(), null);
    return (
      <Question
        backgroundColor={questionBackground}
        currentPage={this.getCurrentPage()}
        image={Work}
        onChange={this.props.updatePRO}
        onClickBackButton={() => this.props.router.goBack()}
        onSubmission={this.onSubmission}
        options={options}
        primaryBtnDisabled={this.state.submitting}
        title="Work"
        totalPages={this.getTotalPages()}
        trackIndex={this.getTrackIndex()}
        type="odi"
        value={value}
      />
    );
  }
}

function mapStateToProps(state) {
  const {
    user,
    proForms: {
      odi,
    },
  } = state;

  return {
    user,
    odi,
  };
}

OdiEmployementHomemaking.propTypes = {
  odi: PropTypes.array.isRequired,
  updatePRO: PropTypes.func.isRequired,
  submitPRO: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { updatePRO, submitPRO })(OdiEmployementHomemaking);
