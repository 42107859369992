import React from 'react';
import { connect } from 'react-redux';
import { get, omit, pickBy } from 'lodash';

import Page from './page';
import Layout from '../layouts/common';
import AppBar from '../components/app-bar';
import MigrainePreventivePresentation from '../components/migraine-preventive-presentation';

import { browseMigraineRx } from '../state/app-data';
import {
  browseMigraineQuestions,
  editMigraineQuestions,
  addMigraineQuestions,
} from '../state/migraine-questions';
import {
  browseMigrainePreventiveHx,
  deleteMigrainePreventiveHx,
  addMigrainePreventiveHx,
  editMigrainePreventiveHx,
} from '../state/migraine-preventive-hx';
import { updateAvatarUrl } from '../state/user';

class MigrainePreventive extends Page {
  constructor(props) {
    super(props);

    const { migraine_hx_id, migrainePreventiveHx } = props;
    const selectedMigrainePreventiveHx = migraine_hx_id ? { ...migrainePreventiveHx.data } : {};

    this.state = {
      migraineQuestions: {
        has_migraines: true,
        other_preventive_rx: '',
        ...props.migraineQuestions.data,
        selectedMigrainePreventiveHx,
      },
    };

    this.handleAddItem = this.handleAddItem.bind(this);
    this.handleRemoveItem = this.handleRemoveItem.bind(this);
    this.handleContinue = this.handleContinue.bind(this);
    this.handleOtherPreventiveUpdate = this.handleTextFieldUpdate.bind(this, 'other_preventive_rx');
  }

  componentWillMount() {
    this.props.browseMigrainePreventiveHx();
    this.props.browseMigraineQuestions();
    if (!this.props.migraineRx) {
      this.props.browseMigraineRx();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.migraineQuestions !== this.props.migraineQuestions) {
      this.setState({
        migraineQuestions: {
          has_migraines: true,
          other_preventive_rx: '',
          ...nextProps.migraineQuestions.data,
        },
      });
    }
    if (nextProps.migrainePreventiveHx !== this.props.migrainePreventiveHx) {
      const { migrainePreventiveHx } = nextProps;
      this.setState({
        selectedMigrainePreventiveHx: get(migrainePreventiveHx, this.props.migrainePreventiveHx.data, null),
      });
    }
  }

  handleTextFieldUpdate(field, e) {
    this.setState({
      migraineQuestions: {
        ...this.state.migraineQuestions,
        [field]: e.target.value,
      },
    });
  }

  handleAddItem(rx_id) {
    const newItem = {
      rx_id,
    };
    this.props.addMigrainePreventiveHx(newItem);
  }

  handleRemoveItem(migraineMedId) {
    this.props.deleteMigrainePreventiveHx(migraineMedId);
  }

  handleContinue() {
    const { migraineQuestions } = this.state;

    const update = omit(migraineQuestions, ['id', 'user_id']);
    if (!migraineQuestions.id) {
      this.props.addMigraineQuestions(update)
        .then(() => this.forwardWithQuery(this.props.location.query));
    } else {
      this.props.editMigraineQuestions(update)
        .then(() => this.forwardWithQuery(this.props.location.query));
    }
  }

  onClickBackButton = () => {
    this.backward();
  }

  render() {
    const { migraineRx } = this.props;

    return (
      <Layout>
        <AppBar
          backButtonOnClick={() => this.props.router.goBack()}
          headerNode="Migraine Preventive Medications"
        />
        <MigrainePreventivePresentation
          migraineRx={migraineRx}
          selectedItems={this.props.migrainePreventiveHx.data}
          onAddItem={this.handleAddItem}
          onRemoveItem={this.handleRemoveItem}
          onChangeOtherPreventive={this.handleOtherPreventiveUpdate}
          otherPreventiveValue={this.state.migraineQuestions.other_preventive_rs}
          onContinue={this.handleContinue}
        />
      </Layout>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const {
    migraineQuestions,
    migrainePreventiveHx,
    appData: {
      migraineRx,
    },
    user,
    migraine_hx_id = get(ownProps, 'location.query.migraine_hx_id', null),
  } = state;

  return {
    migraineRx: migraineRx ? pickBy(migraineRx, { type: 'ABATIVE' }) : null,
    migrainePreventiveHx,
    migraineQuestions,
    user,
    migraine_hx_id,
  };
}

MigrainePreventive.defaultProps = {
  aggravating_factors: {},
  MigrainePreventiveHx: {},
};

export default connect(mapStateToProps, {
  browseMigraineRx,
  browseMigraineQuestions,
  editMigraineQuestions,
  addMigraineQuestions,
  browseMigrainePreventiveHx,
  editMigrainePreventiveHx,
  deleteMigrainePreventiveHx,
  addMigrainePreventiveHx,
  updateAvatarUrl,
})(MigrainePreventive);
