const en = {
  woundCasaColina4: {
    title: 'Symptoms',
    subtitle: 'Pain',
    instructions: 'Use the slider to give a pain intensity on a scale of 0 to 10.',
    instructions2: 'Check the symptoms of your wound.',
    instructions3: 'you may check multiple boxes',
    options: {
      noPain: 'No pain',
      worstPain: 'Worst imaginable pain',
      discharge: 'Discharge/Puss',
      redness: 'Redness around the wound',
      none: 'None of the above',
    },
  },
};

const es = {
  woundCasaColina4: {
    title: 'Síntomas',
    subtitle: 'Dolor',
    instructions: 'Utilice el control deslizante paa dar una intensidad de dolor en una escala del 0 al 10.',
    instructions2: 'Revisa los síntomas de tu herida.',
    instructions3: 'puede marcar varias casillas',
    options: {
      noPain: 'Sin dolor',
      worstPain: 'El peor dolor imaginable',
      discharge: 'Descarga/Pus',
      redness: 'Enrojecimiento alrededor de la herida',
      none: 'Ninguna de las anteriores',
    },
  },
};

export default { en, es };
