import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Slider } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import NoSleep from 'nosleep.js';

import Page from './page';
import { PainIntensity } from '../lib/icons';
import QuestionLayout from '../layouts/question';
import AppBar from '../components/app-bar';
import { update } from '../state/pro-forms';
import { painScaleColors, colors } from '../lib/styles';

const { lightBrightBlue } = colors;

const styles = {
  contentWrapper: {
    flexGrow: 1,
    overflowX: 'hidden',
    position: 'relative',
    justifyContent: 'space-between',
    backgroundColor: colors.highlightBright,
  },
  slider: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '50px',
    fontSize: '14px',
  },
  sliderStyle: {
    flexGrow: 1,
    marginBottom: 24,
  },
  sliderLabel: {
    fontSize: 14,
    maxWidth: 100,
    textAlign: 'center',
  },
  image: {
    height: 100,
    padding: '15px 60px',
    textAlign: 'center',
  },
  instructions: {
    color: 'grey',
    fontSize: '1.8rem',
    margin: '20px 40px 0px 40px',
    textAlign: 'center',
  },
  nextButton: {
    bottom: 30,
    left: 30,
    position: 'absolute',
    right: 30,
  },
  pageIndicator: {
    fontSize: '1.8rem',
  },
  questionnaire: {
    margin: '0px 30px',
    position: 'relative',
  },
  title: {
    color: 'black',
    fontSize: '1.8rem',
    fontWeight: 'strong',
    textTransform: 'uppercase',
  },
  bar: {
    backgroundColor: lightBrightBlue,
  },
};

class SixMWT1 extends Page {
  constructor(props) {
    super(props);

    this.state = {
      theme: this.getSliderTheme(0),
      value: 0,
    };
  }

  getSliderTheme = (value) => {
    return {
      color: painScaleColors[value],
    };
  }

  handleValueChange = (event, value) => {
    const theme = this.getSliderTheme(value);
    this.setState({ theme });
    this.props.update({ pre_test_pain: value });
  }

  render() {
    const { theme } = this.state;
    const value = this.props.proForms.pre_test_pain || this.state.value;
    return (
      <div style={{ height: '100%' }}>
        <QuestionLayout
          primaryBtnLabel="Next"
          primaryBtnOnTouchTap={() => {
            const noSleep = new NoSleep();
            noSleep.enable();
            this.forwardWithQuery(this.props.location.query);
          }}
          primaryBtnStyle={styles.continueBtn}
          style={{ backgroundColor: colors.highlightBright }}
        >
          <AppBar
            headerNode="Pain Intensity"
            noLeftNode={true}
          />
          <LinearProgress
            variant="determinate"
            value={(this.getCurrentPage() / this.getTotalPages()) * 100}
            classes={{ bar: this.props.classes.bar }}
          />
          <div style={styles.contentWrapper}>
            <div style={styles.instructions}>
              Please select the option that best answers the question relating to your pain.
            </div>
            <div style={styles.image}>
              <PainIntensity />
            </div>
            <div style={styles.questionnaire}>
              <p style={styles.title}>What is your current pain level?</p>
              <Slider
                min={0}
                max={10}
                value={value}
                valueLabelDisplay="on"
                onChange={this.handleValueChange}
                style={{ ...styles.slider, ...theme }}
              />
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <div style={{ ...styles.sliderLabel, marginBottom: '50px' }}>
                  No pain
                </div>
                <div style={{ ...styles.sliderLabel, marginBottom: '50px' }}>
                  Worst imaginable pain
                </div>
              </div>
            </div>
          </div>
        </QuestionLayout>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { user, proForms } = state;
  return { user, proForms };
}

SixMWT1.proptypes = {
  user: PropTypes.object.isRequired,
  proForms: PropTypes.array.isRequired,
  route: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, { update })(withStyles(styles)(SixMWT1));
