import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import PropTypes from 'prop-types';

const styles = {
  noDataMessage: {
    width: '100%',
    textAlign: 'center',
  },
};
const LoadingContainer = (props) => {
  const { isFetching, noData, noDataMessage, size } = props;
  if (isFetching) {
    return (
      <CircularProgress
        size={size || 200}
      />
    );
  }

  if (noData) {
    return (
      <div style={styles.noDataMessage}>
        <h2>{noDataMessage || 'No Data Found'}</h2>
      </div>
    );
  }
  return props.children;
};
LoadingContainer.propTypes = {
  size: PropTypes.number,
  noDataMessage: PropTypes.string,
  noData: PropTypes.bool,
};

export default LoadingContainer;
