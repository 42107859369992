import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import AppBar from '../components/app-bar';
import Page from './page';
import QuestionLayout from '../layouts/question';
import YesNoButtonSet from '../components/yes-no-button-set';
import { colors } from '../lib/styles';
import { updatePRO } from '../state/pro-forms';
import { Heart } from '../lib/icons';

const styles = {
  contentWrapper: {
    flexGrow: 1,
    overflowX: 'hidden',
    position: 'relative',
  },
  imgRow: {
    display: 'flex',
    justifyContent: 'center',
    padding: '15px 60px',
  },
  imageWrapper: {
    width: 100,
  },
  questionnaireContainer: {
    paddingLeft: 20,
    paddingRight: 20,
  },
  redHeart: {
    color: '#bd433d',
  },
};

class HypertensionAssessment1 extends Page {
  constructor(props) {
    super(props);

    this.state = {
      bloodPressureCurrentlyTreated: null,
      familyHistoryOfCardioDisease: null,
      highCholesterol: null,
    };
  }

  handleChangeBloodPressureCurrentlyTreated = (value) => {
    this.setState({ bloodPressureCurrentlyTreated: value });
  }

  handleChangeFamilyHistoryOfCardioDisease = (value) => {
    this.setState({ familyHistoryOfCardioDisease: value });
  }

  handleChangeHighCholesterol = (value) => {
    this.setState({ highCholesterol: value });
  }

  handleClickNext = () => {
    const {
      bloodPressureCurrentlyTreated,
      familyHistoryOfCardioDisease,
      highCholesterol,
    } = this.state;

    this.props.updatePRO({
      type: 'hypertensionAssessment',
      position: this.getTrackIndex(),
      value: {
        bloodPressureCurrentlyTreated,
        familyHistoryOfCardioDisease,
        highCholesterol,
      },
    });

    this.forwardWithQuery(this.props.location.query);
  }

  render() {
    const { classes } = this.props;
    const {
      bloodPressureCurrentlyTreated,
      familyHistoryOfCardioDisease,
      highCholesterol,
    } = this.state;

    const continueDisabled = bloodPressureCurrentlyTreated === null
      || familyHistoryOfCardioDisease === null
      || highCholesterol === null;

    return (
      <QuestionLayout
        primaryBtnLabel="Next"
        primaryBtnOnTouchTap={this.handleClickNext}
        primaryBtnDisabled={continueDisabled}
        style={{ backgroundColor: colors.questionBackground }}
      >
        <AppBar
          headerNode="Family History"
          backButtonOnClick={() => this.props.router.goBack()}
        />
        <LinearProgress
          variant="determinate"
          value={(this.getCurrentPage() / this.getTotalPages()) * 100}
          classes={{ bar: classes.bar }}
        />
        <div className={classes.contentWrapper}>
          <div className={classes.imgRow}>
            <div className={`${classes.imageWrapper} ${classes.redHeart}`}>
              <Heart />
            </div>
          </div>
          <div className={classes.questionnaireContainer}>
            <YesNoButtonSet
              isYes={familyHistoryOfCardioDisease === 'Y'}
              isNo={familyHistoryOfCardioDisease === 'N'}
              onClickNo={() => this.handleChangeFamilyHistoryOfCardioDisease('N')}
              onClickYes={() => this.handleChangeFamilyHistoryOfCardioDisease('Y')}
              questionText="Do you have a family history of cardiovascular disease?"
            />
            <YesNoButtonSet
              isYes={bloodPressureCurrentlyTreated === 'Y'}
              isNo={bloodPressureCurrentlyTreated === 'N'}
              onClickNo={() => this.handleChangeBloodPressureCurrentlyTreated('N')}
              onClickYes={() => this.handleChangeBloodPressureCurrentlyTreated('Y')}
              questionText="Are you currently being treated for high blood pressure?"
            />
            <YesNoButtonSet
              isYes={highCholesterol === 'Y'}
              isNo={highCholesterol === 'N'}
              onClickNo={() => this.handleChangeHighCholesterol('N')}
              onClickYes={() => this.handleChangeHighCholesterol('Y')}
              questionText="Do you have high cholesterol?"
            />
          </div>
        </div>
      </QuestionLayout>
    );
  }
}

export default connect(null, { updatePRO })(withStyles(styles)(HypertensionAssessment1));
