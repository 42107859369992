import { bloodPressureServiceId, deviceName, writeCharId, readCharId, resetReadingCommand, requestReadingCommand, BPError, bpEvent, BpData, BpIntermediateData } from './qardiobp';

export class BpReader {
  device;

  server;

  bloodPressureService;

  readChar;

  writeChar;

  readInProgress;

  dirty;

  // throws bluetooth errors
  constructor() {
    return (async () => {
      try {
      // throws if couldnt get bluetooth peripheral? perhaps no ble present or permissions problem
        this.device = await navigator.bluetooth.requestDevice({
          filters: [
            {
              namePrefix: [deviceName],
              services: [bloodPressureServiceId],
            },
          ],
        });

        // throws if couldnt connect, perhaps low battery or device too far
        this.server = await this.device.gatt.connect();

        // these throw if couldnt talk to device during initial setup
        this.service = await this.server.getPrimaryService(bloodPressureServiceId);
        this.writeChar = await this.service.getCharacteristic(writeCharId);
        this.readChar = await this.service.getCharacteristic(readCharId);
      } catch (e) {
        const error = new BPError("Couldn't connect", e);
        error.code = 902;
        throw error;
      }

      this.dirty = false;
      this.readInProgress = false;
      return this;
    })();
  }

  // triggers remote blood pressure reading and resolves a single succcessful reading or rejects
  // if returns an error, device will be in disconnected state
  // throws if reading already in progress or if device is disconnected
  async takeReading(intermediateFn) {
    if (this.readInProgress) {
      throw new BPError('Reading already in progress');
    }

    if (this.device && this.device.gatt && !this.device.gatt.connected) {
      const error = new BPError('Device Disconnected');
      error.code = 901;
      throw error;
    }

    this.readInProgress = true;
    return this.readChar.startNotifications()
      .then(() => {
        // .. they could still error and disconnect and leave it dirty? but this is what the app does..though it does it twice..
        if (this.dirty) {
          this.dirty = false;
          return this.writeChar.writeValueWithResponse(resetReadingCommand);
        }
      })
      .then(() => {
        return this.writeChar.writeValueWithResponse(requestReadingCommand);
      })
      .then(() => {
        return new Promise((resolve, reject) => {
          this.readChar.addEventListener('characteristicvaluechanged', async (evt) => {
            // prevent being called multiple times on success
            if (!this.readInProgress) { return; }

            const notificationVal = new Uint8Array(evt.target.value.buffer);

            const result = bpEvent(notificationVal);


            if (result instanceof BpData) {
              this.readChar.removeEventListener('characteristicvaluechanged', this);

              // DOMException: Failed to execute 'stopNotifications' on 'BluetoothRemoteGATTCharacteristic': GATT Server is disconnected. Cannot perform GATT operations. (Re)connect first with `device.gatt.connect`.
              // uncatchable? just guard
              if (this.readChar && this.device && this.device.gatt && this.device.gatt.connected) {
                try {
                  await this.readChar.stopNotifications();
                } catch (e) {
                  // stopNotifications finicky and also not in firefox
                }
              }

              this.readInProgress = false;
              return resolve(result);
            }

            if (result instanceof BpIntermediateData) {
              if (intermediateFn) {
                intermediateFn(result);
              }
              return;
            }

            if (result instanceof BPError) {
              this.dirty = true;

              this.readChar.removeEventListener('characteristicvaluechanged', this);

              // DOMException: Failed to execute 'stopNotifications' on 'BluetoothRemoteGATTCharacteristic': GATT Server is disconnected. Cannot perform GATT operations. (Re)connect first with `device.gatt.connect`.
              // uncatchable? just guard
              if (this.readChar && this.device && this.device.gatt && this.device.gatt.connected) {
                try {
                  await this.readChar.stopNotifications();
                } catch (e) {
                  // stopNotifications finicky and also not in firefox
                }
              }

              this.readInProgress = false;
              return reject(result);
            }

            // else?  beats me
          });
        });
      });
  }

  disconnect() {
    // checking for connected isnt good enough, it can still fail between the check???
    try {
      this.device.gatt.disconnect();
    } catch (e) {
      // who cares
    }
  }
}
