import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get, includes } from 'lodash';

import { colors } from '../lib/styles';
import Page from './page';
import ButtonPRO from '../components/button-pro';
import { updatePRO } from '../state/pro-forms';

const buttonLabels = ['Very Satisfied', 'Somewhat Satisfied', 'Neutral', 'Somewhat Dissatisfied', 'Very Dissatisfied'];

const options = [
  {
    question: 'If you had to spend the rest of your life with your current symptoms, how would you feel about it?',
    label: buttonLabels,
    reverseValues: true,
  },
  {
    question: 'How would you rate the overall results of your treatment for pain?',
    label: buttonLabels,
    reverseValues: true,
  },
  {
    question: 'How satisfied are you with the outcomes of your procedure?',
    label: buttonLabels,
    reverseValues: true,
  },
];
const { questionBackground } = colors;

class ANSPE1 extends Page {
  onClickBackButton = () => {
    this.backward();
  }

  onSubmission = () => {
    this.forwardWithQuery(this.props.location.query);
  }

  render() {
    const page = get(this.props.anspe6, this.getTrackIndex(), [null, null, null]);
    const value = includes(page, null) ? null : 0;
    return (
      <ButtonPRO
        backgroundColor={questionBackground}
        currentPage={this.getCurrentPage()}
        onClickBackButton={() => this.props.router.goBack()}
        onChange={this.props.updatePRO}
        onSubmission={this.onSubmission}
        options={options}
        header="ANSPE-6"
        totalPages={this.getTotalPages()}
        trackIndex={this.getTrackIndex()}
        value={value}
        pro={page}
        type="anspe6"
      />
    );
  }
}


function mapStateToProps(state) {
  const { proForms: { anspe6 } } = state;
  return { anspe6 };
}

ANSPE1.propTypes = {
  anspe6: PropTypes.array.isRequired,
  updatePRO: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { updatePRO })(ANSPE1);
