import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import {
  Checkbox,
  FormControlLabel,
  TextField,
} from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import Page from './page';
import AppBar from '../components/app-bar';
import IconWrapper from '../components/icon-wrapper';
import BloomkidzLayout from '../layouts/bloomkidz';
import { submitPRO, updatePRO } from '../state/pro-forms';
import { bloomkidzStylesObj, bloomkidzStyles } from '../lib/bloomkidz';
import { fontSizing } from '../lib/styles';
import { Signature } from '../lib/icons';

const styles = {
  ...bloomkidzStylesObj,
  centerText: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
  },
  emphasis: {
    fontWeight: 600,
  },
  spacing: {
    margin: '20px 0 10px',
  },
};

class BloomkidzSignatures20 extends Page {
  constructor(props) {
    super(props);

    const stateFromRedux = props.bloomkidzSignatures[this.getTrackIndex()] || {};

    this.state = {
      finalGuardian1: '',
      finalRelationship1: '',
      finalInitial1: '',
      finalInitial2: '',
      finalInitial3: '',
      finalAcknowledgement1: false,
      finalAcknowledgement2: false,
      finalAcknowledgement3: false,
      submitting: false,
      ...stateFromRedux,
    };
  }

  handleCheck = (e) => {
    this.setState({ [e.target.name]: e.target.checked });
  };

  handleTextFieldChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleContinue = () => {
    this.setState({ submitting: true });
    const {
      bloomkidzSignatures,
      location,
      submitPRO,
      user,
    } = this.props;
    const { id } = location.query;

    const date = { year: 'numeric', month: 'numeric', day: 'numeric' };

    submitPRO({ userId: user.id, id }, {
      pro_type: 'BLOOMKIDZ-SIGNATURES',
      pro_data: {
        data: {
          ...this.getDataMap(bloomkidzSignatures),
          ...this.state,
          signatureDate: new Date().toLocaleDateString('en-US', date),
        },
        recorded_at: new Date().toISOString(),
      },
    }, user)
      .then(() => {
        this.forwardWithQuery(this.props.location.query);
      });
  }

  render() {
    const { classes, router } = this.props;
    const {
      finalGuardian1,
      finalRelationship1,
      finalInitial1,
      finalInitial2,
      finalInitial3,
      finalAcknowledgement1,
      finalAcknowledgement2,
      finalAcknowledgement3,
      submitting,
    } = this.state;

    const continueDisabled = submitting || !finalGuardian1 || !finalRelationship1 || !finalInitial1 || !finalInitial2 || !finalInitial3 || !finalAcknowledgement1 || !finalAcknowledgement2 || !finalAcknowledgement3;

    return (
      <BloomkidzLayout
        primaryBtnOnTouchTap={this.handleContinue}
        primaryBtnLabel="Next"
        primaryBtnDisabled={continueDisabled}
      >
        <AppBar
          headerNode="Signature"
          backButtonOnClick={() => router.goBack()}
        />
        <IconWrapper>
          <Signature />
        </IconWrapper>
        <div className={classes.pageContent}>
          <FormControlLabel
            control={(
              <Checkbox
                checked={finalAcknowledgement1}
                onChange={this.handleCheck}
                name="finalAcknowledgement1"
                icon={<CheckBoxOutlineBlankIcon />}
                checkedIcon={<CheckBoxIcon />}
                className={classes.checkbox}
              />
            )}
            label="I acknowledge that I am responsible for providing meals and snacks for my child during their time at BloomKidz."
            classes={{
              label: classes.checkboxLabel,
              root: classes.formControlLabel,
            }}
          />
          <div className={classes.paddingHorizontal}>
            <div className={classes.textFieldWrapper}>
              <TextField
                placeholder="Initials"
                value={finalInitial1}
                onChange={this.handleTextFieldChange}
                name="finalInitial1"
                variant="standard"
                fullWidth
                inputProps={{ style: { fontSize: fontSizing.body, fontFamily: bloomkidzStyles.fontFamily } }}
              />
            </div>
          </div>
          <FormControlLabel
            control={(
              <Checkbox
                checked={finalAcknowledgement2}
                onChange={this.handleCheck}
                name="finalAcknowledgement2"
                icon={<CheckBoxOutlineBlankIcon />}
                checkedIcon={<CheckBoxIcon />}
                className={classes.checkbox}
              />
            )}
            label="I have provided all relevant nutritional and allergy information to BloomKidz for my child."
            classes={{
              label: classes.checkboxLabel,
              root: classes.formControlLabel,
            }}
          />
          <div className={classes.paddingHorizontal}>
            <div className={classes.textFieldWrapper}>
              <TextField
                placeholder="Initials"
                value={finalInitial2}
                onChange={this.handleTextFieldChange}
                name="finalInitial2"
                variant="standard"
                fullWidth
                inputProps={{ style: { fontSize: fontSizing.body, fontFamily: bloomkidzStyles.fontFamily } }}
              />
            </div>
          </div>
          <FormControlLabel
            control={(
              <Checkbox
                checked={finalAcknowledgement3}
                onChange={this.handleCheck}
                name="finalAcknowledgement3"
                icon={<CheckBoxOutlineBlankIcon />}
                checkedIcon={<CheckBoxIcon />}
                className={classes.checkbox}
              />
            )}
            label="I have provided information about any other health or medical concerns that pertain to the care and well. being of my child while at BloomKidz."
            classes={{
              label: classes.checkboxLabel,
              root: classes.formControlLabel,
            }}
          />
          <div className={classes.paddingHorizontal}>
            <div className={classes.textFieldWrapper}>
              <TextField
                placeholder="Initials"
                value={finalInitial3}
                onChange={this.handleTextFieldChange}
                name="finalInitial3"
                variant="standard"
                fullWidth
                inputProps={{ style: { fontSize: fontSizing.body, fontFamily: bloomkidzStyles.fontFamily } }}
              />
            </div>
          </div>
          <div className={`${classes.emphasis} ${classes.spacing}`}>Parent/Guardian Printed Name</div>
          <div className={classes.paddingHorizontal}>
            <div className={classes.textFieldWrapper}>
              <TextField
                placeholder="Full Name"
                value={finalGuardian1}
                onChange={this.handleTextFieldChange}
                name="finalGuardian1"
                variant="standard"
                fullWidth
                inputProps={{ style: { fontSize: fontSizing.body, fontFamily: bloomkidzStyles.fontFamily } }}
              />
            </div>
          </div>
          <div className={`${classes.emphasis} ${classes.spacing}`}>Relationship to the Client</div>
          <div className={classes.paddingHorizontal}>
            <div className={classes.textFieldWrapper}>
              <TextField
                placeholder="Relationship to Client"
                value={finalRelationship1}
                onChange={this.handleTextFieldChange}
                name="finalRelationship1"
                variant="standard"
                fullWidth
                inputProps={{ style: { fontSize: fontSizing.body, fontFamily: bloomkidzStyles.fontFamily } }}
              />
            </div>
          </div>
        </div>
      </BloomkidzLayout>
    );
  }
}

BloomkidzSignatures20.propTypes = {
  classes: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  submitPRO: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const {
    proForms: { bloomkidzSignatures },
    user,
  } = state;

  return { bloomkidzSignatures, user };
};

export default connect(mapStateToProps, { submitPRO, updatePRO })(withStyles(styles)(BloomkidzSignatures20));
