const en = {
  demographics6: {
    title: 'About Me',
    instructions: 'In the last 12 months',
    options: {
      question: "Did you ever eat less than you felt you should because there wasn't enough money for food?",
    },
  },
};

const es = {
  demographics6: {
    title: 'Sobre Mí',
    instructions: 'En los últimos 12 meses',
    options: {
      question: '¿Alguna vez comiste menos de lo que creías que debías porque no había suficiente dinero para comprar comida?',
    },
  },
};

export default { en, es };
