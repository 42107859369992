import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { concat } from 'lodash';

import Page from './page';
import { colors } from '../lib/styles';
import AppBar from '../components/app-bar';
import Continue from '../components/continue';
import { submitPRO, updatePRO } from '../state/pro-forms';
import brand from '../lib/brand';

const styles = {
  instructions: {
    fontSize: '1.3rem',
  },
  image: {
    height: 120,
    padding: '15px 60px',
    textAlign: 'center',
  },
  linearProgress: {
    borderRadius: 0,
  },
  pageIndicator: {
    color: 'grey',
    display: 'inline-block',
    fontSize: 13,
  },
  questionnaire: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    position: 'relative',
    minHeight: '350px',
  },
  title: {
    color: 'black',
    fontSize: '1.5rem',
    fontWeight: 800,
    textTransform: 'uppercase',
    alignSelf: 'center',
  },
  date: {
    width: '150px',
    alignSelf: 'center',
    margin: '20px',
  },
  titleGroup: {
    margin: 25,
    textAlign: 'center',
  },
  error: {
    color: colors.errorRed,
    alignSelf: 'center',
  },
  text: {
    fontSize: '1.25rem',
  },
};

class PainPROBack extends Page {
  state = {
    submtting: false,
  }

  handleSubmit = () => {
    if (this.state.submitting) return;
    const { id } = this.props.location.query;
    const { user, pain } = this.props;

    this.setState({ submitting: true });

    this.props.submitPRO(
      { userId: user.id, id },
      {
        pro_type: 'PAIN',
        pro_data: { data: concat(pain, ['No', 'N/A', 'N/A', 'N/A']) },
      },
      user,
    )
      .then(() => {
        this.finishTrack();
      })
      .catch(() => this.setState({ submitting: false }));
  }

  render() {
    return (
      <div>
        <AppBar
          headerNode="Neck Pain"
          noLeftNode={true}
        />
        <div style={styles.image}>
          <img src={`/img/${brand.name}/${brand.loginLogo}`} alt="Informed Logo" />
        </div>
        <div style={styles.questionnaire}>
          <div style={styles.titleGroup}>
            <p style={styles.text}>Do you experience neck pain?</p>
          </div>
          <Continue
            disabled={this.state.submitting}
            text="No"
            onClick={this.handleSubmit}
            btnStyle={{ marginTop: '50px', alignSelf: 'center' }}
          />
          <Continue
            disabled={this.state.submitting}
            text="Yes"
            onClick={() => {
              this.props.updatePRO({ position: 4, value: 'Yes', type: 'pain' });
              this.forwardWithQuery(this.props.location.query);
            }}
            btnStyle={{ marginTop: '50px', alignSelf: 'center' }}
          />
        </div>
      </div>
    );
  }
}


function mapStateToProps(state) {
  const { user, proForms: { pain } } = state;
  return { user, pain };
}

PainPROBack.proptypes = {
  pain: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
  route: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, { submitPRO, updatePRO })(PainPROBack);
