/* eslint-disable no-console */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import rawr from 'rawr';
import transport from 'rawr/transports/worker';
import { LinearProgress } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '../components/app-bar';
import Continue from '../components/continue';
import { throttledReset } from '../initializers/activity';
import { colors } from '../lib/styles';
import Page from './page';
import { updatePRO } from '../state/pro-forms';
import { CameraBuddy } from '../lib/cameraBuddy';
import { Graph, GRAPH_POINT_DELAY, getLightingStatus, getImageDataFunc } from '../lib/vitals-spo2';

const styles = {
  titleGroup: {
    margin: 25,
  },
  text: {
    fontSize: '2rem',
  },
  nextBtn: {
    textAlign: 'center',
    width: '100%',
    top: '80%',
  },
  graphCanvas: {
    width: '40%',
    position: 'absolute',
    zIndex: 100,
  },
  patientVideo: {
    minHeight: '100px',
    height: '50%',
    objectFit: 'cover',
    transform: 'scaleX(1)',
    width: '80%',
    display: 'none',
  },
  patientCanvas: {
    minHeight: '100px',
    height: '50%',
    objectFit: 'cover',
    transform: 'scaleX(1)',
    width: '80%',
  },
  videoContainer: {
    margin: 20,
    width: '50%',
  },
  nonVideoContentContainer: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
  },
  successText: {
    color: colors.successGreen,
    fontSize: '2rem',
  },
  errorText: {
    color: colors.errorRed,
    fontSize: '2rem',
  },
};

const TARGET_FPS = 30;
const COLOR_SPACE = 'display-p3'; // default is 'srgb'
class ContactSpo2DataCollection4 extends Page {
  constructor(props) {
    super(props);
    this.state = {
      workerReady: false,
      stream: undefined,
      cameraError: '',
    };

    this.ctx = null;
    this.greenChannelCollection = [];
    this.lightingStatus = 'ok';
    this.lastLightingTimestamp = Date.now();

    this.patientCanvas = React.createRef();
    this.graphCanvas = React.createRef();
    this.patientVideo = React.createRef();
    this.videoContainer = React.createRef();
  }

    componentDidMount = async () => {
      const { contactSpo2DataCollection, userId } = this.props;
      const { patientVideo } = this;
      const {
        FPS,
        imgEncoding,
        jpegQuality,
        cropFace,
      } = this.getDataMap(contactSpo2DataCollection);

      // not using camerabuddy to save any images, just for its onframe ticker
      const cameraBuddy = new CameraBuddy(patientVideo, userId, cropFace, imgEncoding, jpegQuality, 0, FPS || TARGET_FPS, true);
      this.cameraBuddy = cameraBuddy;
      try {
        const cameraObject = await this.cameraBuddy.startCamera(COLOR_SPACE);
        this.setState({ cameraObject });
      } catch (err) {
        return this.setState({ failed: true, cameraError: err });
      }

      const offscreenCanvas = new OffscreenCanvas(patientVideo.current.videoWidth / 4, patientVideo.current.videoHeight / 4);
      cameraBuddy.getImageData = getImageDataFunc(offscreenCanvas);
      cameraBuddy.startEmittingImgData();

      // set up our canvas
      const canvas = this.patientCanvas.current;
      canvas.height = this.patientVideo.current.videoHeight;
      canvas.width = this.patientVideo.current.videoWidth;
      this.ctx = canvas.getContext('2d', { willReadFrequently: true, colorSpace: COLOR_SPACE });

      const graphCv = this.graphCanvas.current;
      graphCv.height = this.patientVideo.current.videoHeight;
      graphCv.width = this.patientVideo.current.videoWidth;

      this.greenChannelCollection = [];
      const greenChannelGraph = new Graph(graphCv);
      this.greenChannelGraph = greenChannelGraph;

      this.cameraBuddy.on('frame', (patientVideoCurrent, pvW, pvH) => {
        // draw the camera image in our canvas
        this.ctx.drawImage(patientVideoCurrent, 0, 0, pvW, pvH);
      });
  
      this.cameraBuddy.on('imgData', async ({ imgData, pvW }) => {
        const now = Date.now();
  
        if ((now - this.lastLightingTimestamp) > GRAPH_POINT_DELAY) {
          this.lastLightingTimestamp = now;
  
          // pull image data and find average red, green, blue values, and standard deviation for red
          // const imgData = this.ctx.getImageData(pvW / 4, pvH / 4, pvW / 2, pvH / 2);
          this.findValues(imgData.data).then((rgbaAvgValues) => {
            const { red, green, blue, redSD } = rgbaAvgValues;
  
            this.lightingStatus = getLightingStatus(red, green, blue, redSD);
  
            let yValue = 0;
            if (green > 10) {
              yValue = green;
            }
            // add the green value to our array
            this.greenChannelCollection.push({ x: now, y: yValue });
            // remove any items older than 5s from the array
            this.greenChannelCollection.every((g) => {
              if ((now - g.x) > greenChannelGraph.duration) {
                this.greenChannelCollection.shift();
                return true;
              }
              return false;
            });
          });
        }
        // update our graph
        greenChannelGraph.draw(this.greenChannelCollection, { content: this.lightingStatus, font: Math.round(pvW / 8) });
      });

      this.activityTimer = setInterval(throttledReset, 5000);

      // this all has to travel together or you'll miss the onready
      // eslint-disable-next-line no-undef
      const webWorker = new Worker('/workers/vitals-data-worker.js');
      webWorker.onerror = (err) => {
        console.error('webworker error', err);
      };
      const rawrPeer = rawr({ transport: transport(webWorker) });
      const { findValues } = rawrPeer.methods;
      this.findValues = findValues;
      rawrPeer.notifications.onready(() => {
        this.setState({ workerReady: true });
      });
      // end
    }

    componentWillUnmount = () => {
      this.endCall();
    }

    setDevMode = (e) => {
      // mobile workaround for double clicks
      const touchTap = {
        time: Date.now(),
        target: e.currentTarget,
      };
      const { latestTouchTap: ltt } = this.state;
      if (ltt && (ltt.target === touchTap.target) && (touchTap.time - ltt.time < 300)) {
        if (this.greenChannelGraph) {
          this.greenChannelGraph.toggleDevMode();
        }
      }
      this.setState({ latestTouchTap: touchTap });
    };

    handleContinue = () => {
      const { location: { query }, updatePRO } = this.props;
      const value = {...this.state};
      delete value.latestTouchTap;
      updatePRO({
        type: 'contactSpo2DataCollection',
        position: this.getTrackIndex(),
        value,
      });
      this.forwardWithQuery(query);
    }

    componentWillUnmount = () => {
      clearInterval(this.activityTimer);
      this.cameraBuddy.cleanup();
    }

    render() {
      const { classes, contactSpo2DataCollection } = this.props;
      const { seconds } = this.getDataMap(contactSpo2DataCollection);
      const {
        cameraError,
      } = this.state;


      return (
        <Fragment>
          <AppBar
            headerNode="Rear Camera Conditions"
            noLeftNode={true}
          />
          <LinearProgress
            variant="determinate"
            value={(this.getCurrentPage() / this.getTotalPages()) * 100}
          />
          <div id="videoContainer" className={classes.videoContainer} ref={this.videoContainer} >
            <video id="patientVideo" ref={this.patientVideo} playsInline autoPlay className={classes.patientVideo} muted />
            <canvas id="graphCanvas" ref={this.graphCanvas} className={classes.graphCanvas} onClick={this.setDevMode} />
            <canvas id="patientCanvas" ref={this.patientCanvas} className={classes.patientCanvas} />
          </div>

          <div className={classes.nonVideoContentContainer}>
            {cameraError ? (
              <div><span>Unable to access camera: {cameraError.toString()}</span><br /></div>
            ) : ''}
          </div>

          <div className={classes.titleGroup}>
            <p className={classes.text}>Please place your index finger on the rear camera lens.</p>
            <p className={classes.text}>When your finger is placed properly on the rear lens, the border will turn green. If the border turns red, reposition the phone to allow more or less light to the rear lens or adjust your finger placement until the border turns green.</p>
            <p className={classes.text}>When you have good peaks and valleys in the wave form, tap the I&apos;M READY Button.</p>
            <div className={classes.text}>We will be recording for <span>{seconds} seconds.</span>
              <br />please make sure to do the following:
            </div>
            <ul className={classes.text}>
              <li>Sit comfortably</li>
              <li>Stay as still as possible</li>
              <li>Keep your finger steady on the read lens</li>
              <li>Ensure you have good peaks and valleys in the wave form</li>
            </ul>
          </div>

          <div className={classes.nextBtn}>
            <Continue
              text="I'm Ready"
              onClick={this.handleContinue}
            />
          </div>
        </Fragment>
      );
    }
}

function mapStateToProps(state) {
  const { proForms: { contactSpo2DataCollection } } = state;
  return { contactSpo2DataCollection };
}

ContactSpo2DataCollection4.proptypes = {
  classes: PropTypes.object.isRequired,
  updatePRO: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { updatePRO })(withStyles(styles)(ContactSpo2DataCollection4));
