import React from 'react';

import AppBar from '../components/app-bar';
import Page from './page';
import Eula from '../components/eula';
import PrivacyPolicy from '../components/privacy-policy';
import AboutMindset from '../components/about-mindset';
import StickyHeader from '../components/sticky-header';
import Layout from '../layouts/common';
import { colors } from '../lib/styles';

export default class About extends Page {
  render() {
    let title = 'EULA';
    let displayPage = <Eula />;
    switch (this.props.params.pageSelection) {
      case 'eula':
        title = 'EULA';
        displayPage = <Eula />;
        break;
      case 'privacy':
        title = 'Privacy Policy';
        displayPage = <PrivacyPolicy />;
        break;
      case 'about':
        title = 'About Mindset';
        displayPage = <AboutMindset />;
        break;
      default:
        return displayPage;
    }
    return (
      <Layout>
        <div style={{ padding: '60px 5px 5px 5px', color: colors.primaryColor, backgroundColor: 'white' }}>
          <StickyHeader>
            <AppBar
              backButtonOnClick={() => this.props.router.goBack()}
              headerNode={title}
            />
          </StickyHeader>
          {displayPage}
        </div>
      </Layout>
    );
  }
}
