/* eslint max-len: 0 */
import { promiseHandler, resetReducer } from 'cooldux';
import { uniqueId, forEach, get, isUndefined, omitBy } from 'lodash';

import config from '../config';

import { apiFetch } from '../lib/fetch';

const handlerOpts = {
  throwErrors: true,
  namespace: 'insurance',
};

const { browseStart, browseEnd, browseError, browseHandler } = promiseHandler('browse', handlerOpts);
const { editStart, editEnd, editError, editHandler } = promiseHandler('edit', 'insurance');
const { addStart, addEnd, addError, addHandler } = promiseHandler('add', 'insurance');
const { deleteStart, deleteEnd, deleteError, deleteHandler } = promiseHandler('delete', 'insurance');
const {
  insurancePrimaryFrontHandler,
  insurancePrimaryFrontReducer,
  insurancePrimaryFrontInitialState } = promiseHandler('insurancePrimaryFront', 'insurance');
const {
  insurancePrimaryBackHandler,
  insurancePrimaryBackReducer,
  insurancePrimaryBackInitialState } = promiseHandler('insurancePrimaryBack', 'insurance');
const {
  insuranceSecondaryFrontHandler,
  insuranceSecondaryFrontReducer,
  insuranceSecondaryFrontInitialState } = promiseHandler('insuranceSecondaryFront', 'insurance');
const {
  insuranceSecondaryBackHandler,
  insuranceSecondaryBackReducer,
  insuranceSecondaryBackInitialState } = promiseHandler('insuranceSecondaryBack', 'insurance');

const {
  primaryFrontUrlHandler,
  primaryFrontUrlReducer,
  primaryFrontUrlInitialState } = promiseHandler('primaryFrontUrl', 'insurance');

const {
  primaryBackUrlHandler,
  primaryBackUrlReducer,
  primaryBackUrlInitialState } = promiseHandler('primaryBackUrl', 'insurance');

const {
  secondaryFrontUrlHandler,
  secondaryFrontUrlReducer,
  secondaryFrontUrlInitialState } = promiseHandler('secondaryFrontUrl', 'insurance');

const {
  secondaryBackUrlHandler,
  secondaryBackUrlReducer,
  secondaryBackUrlInitialState } = promiseHandler('secondaryBackUrl', 'insurance');

export function browseInsurance() {
  return function dispatcher(dispatch, getState) {
    const selfId = get(getState(), 'user.id', null);
    const promise = apiFetch(`/users/${selfId}/insurance`);
    return browseHandler(promise, dispatch);
  };
}

export function setPrimaryFrontUrl(insuranceId, imageId) {
  return function dispatcher(dispatch, getState) {
    const selfId = get(getState(), 'user.id', null);
    const imgUrl = `${config.API_URL}/users/${selfId}/insurance/${insuranceId}/images/${imageId}`;
    return primaryFrontUrlHandler(Promise.resolve(imgUrl), dispatch);
  };
}
export function setSecondaryFrontUrl(insuranceId, imageId) {
  return function dispatcher(dispatch, getState) {
    const selfId = get(getState(), 'user.id', null);
    const imgUrl = `${config.API_URL}/users/${selfId}/insurance/${insuranceId}/images/${imageId}`;
    return secondaryFrontUrlHandler(Promise.resolve(imgUrl), dispatch);
  };
}
export function setPrimaryBackUrl(insuranceId, imageId) {
  return function dispatcher(dispatch, getState) {
    const selfId = get(getState(), 'user.id', null);
    const imgUrl = `${config.API_URL}/users/${selfId}/insurance/${insuranceId}/images/${imageId}`;
    return primaryBackUrlHandler(Promise.resolve(imgUrl), dispatch);
  };
}
export function setSecondaryBackUrl(insuranceId, imageId) {
  return function dispatcher(dispatch, getState) {
    const selfId = get(getState(), 'user.id', null);
    const imgUrl = `${config.API_URL}/users/${selfId}/insurance/${insuranceId}/images/${imageId}`;
    return secondaryBackUrlHandler(Promise.resolve(imgUrl), dispatch);
  };
}

export function getImageUrl() {
  return function dispatcher(dispatch, getState) {
    const selfId = get(getState(), 'user.id', null);
    const promise = apiFetch(`/users/${selfId}/insurance`)
      .then((res) => {
        apiFetch(`/users/${selfId}/insurance/${res.id}/images`)
          .then((res2) => {
            forEach(res2, (image) => {
              let imgUrl;
              switch (image.image_type) {
                case 'PRIMARY_INSURANCE_CARD_BACK':
                  imgUrl = `${config.API_URL}/users/${selfId}/insurance/${res.id}/images/${image.image_id}?cache_id=${uniqueId('logo')}`;
                  return primaryBackUrlHandler(Promise.resolve(imgUrl), dispatch);
                case 'PRIMARY_INSURANCE_CARD_FRONT':
                  imgUrl = `${config.API_URL}/users/${selfId}/insurance/${res.id}/images/${image.image_id}?cache_id=${uniqueId('logo')}`;
                  return primaryFrontUrlHandler(Promise.resolve(imgUrl), dispatch);
                case 'SECONDARY_INSURANCE_CARD_FRONT':
                  imgUrl = `${config.API_URL}/users/${selfId}/insurance/${res.id}/images/${image.image_id}?cache_id=${uniqueId('logo')}`;
                  return secondaryFrontUrlHandler(Promise.resolve(imgUrl), dispatch);
                case 'SECONDARY_INSURANCE_CARD_BACK':
                  imgUrl = `${config.API_URL}/users/${selfId}/insurance/${res.id}/images/${image.image_id}?cache_id=${uniqueId('logo')}`;
                  return secondaryBackUrlHandler(Promise.resolve(imgUrl), dispatch);
                default:
                  break;
              }
            });
          });
      });
    return promise;
  };
}

export function editInsurance(update) {
  return function dispatcher(dispatch, getState) {
    const selfId = get(getState(), 'user.id', null);
    const options = {
      method: 'PUT',
      body: omitBy(update, isUndefined),
    };
    const promise = apiFetch(`/users/${selfId}/insurance`, options);
    return editHandler(promise, dispatch);
  };
}

export function storeInsurancePrimaryFront(image) {
  return (dispatch) => {
    const promise = Promise.resolve(image);
    return insurancePrimaryFrontHandler(promise, dispatch);
  };
}

export function storeInsurancePrimaryBack(image) {
  return (dispatch) => {
    const promise = Promise.resolve(image);
    return insurancePrimaryBackHandler(promise, dispatch);
  };
}

export function storeInsuranceSecondaryFront(image) {
  return (dispatch) => {
    const promise = Promise.resolve(image);
    return insuranceSecondaryFrontHandler(promise, dispatch);
  };
}

export function storeInsuranceSecondaryBack(image) {
  return (dispatch) => {
    const promise = Promise.resolve(image);
    return insuranceSecondaryBackHandler(promise, dispatch);
  };
}

export function addInsurance(newInsurance) {
  return function dispatcher(dispatch, getState) {
    const selfId = get(getState(), 'user.id', null);
    const options = {
      method: 'POST',
      body: omitBy(newInsurance, isUndefined),
    };
    const promise = apiFetch(`/users/${selfId}/insurance`, options);
    return addHandler(promise, dispatch);
  };
}

export function deleteInsurance() {
  return function dispatcher(dispatch, getState) {
    const selfId = get(getState(), 'user.id', null);
    const options = {
      method: 'DELETE',
    };
    const promise = apiFetch(`/users/${selfId}/insurance`, options);

    return deleteHandler(promise, dispatch);
  };
}

const initialState = {
  ...insurancePrimaryFrontInitialState,
  ...insurancePrimaryBackInitialState,
  ...insuranceSecondaryFrontInitialState,
  ...insuranceSecondaryBackInitialState,
  ...primaryFrontUrlInitialState,
  ...primaryBackUrlInitialState,
  ...secondaryFrontUrlInitialState,
  ...secondaryBackUrlInitialState,
  browseError: null,
  addError: null,
  editError: null,
  deleteError: null,
  browsePending: false,
  addPending: false,
  editPending: false,
  deletePending: false,
  data: {},
};

function finishBrowse(state, item) {
  const data = item;
  return { ...state, data, browseError: null, browsePending: false };
}

function finishEdit(state, item) {
  const data = { ...item };
  return { ...state, editError: null, editPending: false, data };
}

function finishAdd(state, item) {
  const data = item;
  return { ...state, addError: null, addPending: false, data };
}

function finishDelete(state) {
  return { ...state, deleteError: null, deletePending: false, data: {} };
}

const insurance = resetReducer(initialState, (state = initialState, action) => {
  state = insurancePrimaryFrontReducer(state, action);
  state = insurancePrimaryBackReducer(state, action);
  state = insuranceSecondaryFrontReducer(state, action);
  state = insuranceSecondaryBackReducer(state, action);
  state = primaryFrontUrlReducer(state, action);
  state = primaryBackUrlReducer(state, action);
  state = secondaryFrontUrlReducer(state, action);
  state = secondaryBackUrlReducer(state, action);
  switch (action.type) {
    case browseStart.type:
      return { ...state, browsePending: true, browseError: null };
    case browseEnd.type:
      return finishBrowse(state, action.payload);
    case browseError.type:
      return { ...state, browsePending: false, browseError: action.payload };

    case addStart.type:
      return { ...state, addPending: true, addError: null };
    case addEnd.type:
      return finishAdd(state, action.payload);
    case addError.type:
      return { ...state, addPending: false, addError: action.payload };

    case deleteStart.type:
      return { ...state, deletePending: true, deleteError: null };
    case deleteEnd.type:
      return finishDelete(state, action.payload);
    case deleteError.type:
      return { ...state, deletePending: false, deleteError: action.payload };

    case editStart.type:
      return { ...state, editPending: true, editError: null };
    case editEnd.type:
      return finishEdit(state, action.payload);
    case editError.type:
      return { ...state, editPending: false, editError: action.payload };
    default:
      return state;
  }
});

export default insurance;
