const en = {
  appointmentReminder: {
    title: 'Virtual Consultation Informed Consent',
    instructions: "The video will close and continue to the next screen automatically when complete.  If you need to stop before then, click the <strong>I'm Finished</strong> button.",
  },
};

const es = {
  appointmentReminder: {
    title: 'Instrucciones',
    instructions: 'Recuerde hacer clic en el botón <strong>"Terminé"</strong> después de completar el video.',
  },
};

export default { en, es };
