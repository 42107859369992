import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import TextField from '@material-ui/core/TextField';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import AppBar from '../components/app-bar';
import Page from './page';
import QuestionLayout from '../layouts/question';
import YesNoButtonSet from '../components/yes-no-button-set';
import { colors } from '../lib/styles';
import { updatePRO } from '../state/pro-forms';
import { Lungs } from '../lib/icons';

const styles = {
  answerButtonContainer: {
    display: 'flex',
    marginLeft: -10,
    marginTop: 8,
  },
  bar: {
    background: colors.lightBrightBlue,
  },
  contentWrapper: {
    flexGrow: 1,
    overflowX: 'hidden',
    position: 'relative',
  },
  imgRow: {
    display: 'flex',
    justifyContent: 'center',
    padding: '15px 60px',
  },
  imageWrapper: {
    width: 100,
  },
  instructions: {
    color: 'grey',
    fontSize: '1.5rem',
    margin: '20px 40px 0px 40px',
    textAlign: 'center',
  },
  questionnaireContainer: {
    paddingLeft: 20,
    paddingRight: 20,
  },
  breathingText: {
    fontSize: '2rem',
    fontWeight: 'bold',
  },
  questionText: {
    fontSize: '2rem',
    marginTop: 25,
  },
  continueButtonWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 80,
  },
  inputField: {
    width: '6rem',
  },
  oxygenSaturationPercentContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  percentWrapper: {
    fontSize: '1.25rem',
    fontWeight: 'bold',
    marginLeft: 5,
  },
  leaveBlankText: {
    fontSize: '1.25rem',
    fontStyle: 'italic',
    marginTop: 10,
  },
  oxygenSaturationPercentWrapper: {
    alignItems: 'center',
    display: 'flex',
    paddingTop: 20,
  },
};

class ThoracicCancerSymptoms3 extends Page {
  constructor(props) {
    super(props);

    this.state = {
      onHomeOxygen: null,
      oxygenSaturationPercent: '',
    };
  }

  handleChangeOnHomeOxygen = (selected) => {
    this.setState({ onHomeOxygen: selected });
  };

  handleChangeOxygenSaturationPercent = (e) => {
    this.setState({ oxygenSaturationPercent: e.target.value });
  };

  handleClickNext = () => {
    const { onHomeOxygen, oxygenSaturationPercent } = this.state;
    this.props.updatePRO({
      type: 'thoracicCancerSymptoms',
      position: this.getTrackIndex(),
      value: { onHomeOxygen, oxygenSaturationPercent },
    });
    this.forwardWithQuery(this.props.location.query);
  };

  render() {
    const { classes } = this.props;
    const { onHomeOxygen, oxygenSaturationPercent } = this.state;

    return (
      <QuestionLayout
        primaryBtnDisabled={onHomeOxygen === null}
        primaryBtnLabel="Next"
        primaryBtnOnTouchTap={this.handleClickNext}
        style={{ backgroundColor: colors.questionBackground }}
      >
        <AppBar
          headerNode="Breathing"
          backButtonOnClick={() => this.props.router.goBack()}
        />
        <LinearProgress
          variant="determinate"
          value={(this.getCurrentPage() / this.getTotalPages()) * 100}
          classes={{ bar: this.props.classes.bar }}
        />
        <div className={classes.contentWrapper}>
          <div className={classes.instructions}>
            Please select the option that best answers the question relating to breathing
          </div>
          <div className={classes.imgRow}>
            <div className={classes.imageWrapper}>
              <Lungs />
            </div>
          </div>
          <div className={classes.questionnaireContainer}>
            <div className={classes.breathingText}>Breathing</div>
            <YesNoButtonSet
              isNo={onHomeOxygen === false}
              isYes={onHomeOxygen}
              onClickNo={() => this.handleChangeOnHomeOxygen(false)}
              onClickYes={() => this.handleChangeOnHomeOxygen(true)}
              questionText="Are you on home oxygen?"
            />
            <div className={classes.questionText}>What is your oxygen saturation?</div>
            <div className={classes.oxygenSaturationPercentContainer}>
              <div className={classes.leaveBlankText}>Leave blank if you don't know your oxygen saturation.</div>
              <div className={classes.oxygenSaturationPercentWrapper}>
                <TextField
                  className={classes.inputField}
                  onChange={this.handleChangeOxygenSaturationPercent}
                  type="number"
                  inputProps={{
                    inputMode: 'decimal',
                    pattern: '[0-9]*',
                    type: 'tel',
                    style: { fontSize: '16px' } }}
                  value={oxygenSaturationPercent}
                />
                <span className={classes.percentWrapper}>%</span>
              </div>
            </div>
          </div>
        </div>
      </QuestionLayout>
    );
  }
}

export default connect(null, { updatePRO })(withStyles(styles)(ThoracicCancerSymptoms3));
