import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  LinearProgress,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '../components/app-bar';
import Continue from '../components/continue';

import Page from './page';
import { updatePRO } from '../state/pro-forms';

const styles = {
  titleGroup: {
    margin: 25,
  },
  text: {
    fontSize: '2rem',
  },
  nextBtn: {
    textAlign: 'center',
    width: '100%',
    top: '80%',
  },
};

class ContinuousBPDataCollectionInstructions extends Page {
  handleContinue = () => {
    this.props.updatePRO({
      type: 'continuousBPDataCollection',
      position: this.getTrackIndex(),
      value: {},
    });

    this.forwardWithQuery(this.props.location.query);
  }

  render() {
    return (
      <Fragment>
        <AppBar
          headerNode="Instructions"
          noLeftNode={true}
        />
        <LinearProgress
          variant="determinate"
          value={(this.getCurrentPage() / this.getTotalPages()) * 100}
        />

        <div style={styles.titleGroup}>
          <ul style={styles.text}>
            <li>Sit comfortably for five minutes, with your feet planted on the ground and your back pressed against the back of your chair.</li>
            <li>Relax.</li>
            <li>Make sure you have good lighting on your face and it&apos;s fully illuminated - no dark shadows or bright spots falling across your forehead, cheeks, or chin.</li>
            <li><strong>Refrain from talking or moving your face or head while images are recorded.</strong></li>
            <li>Place the Vital Stream finger cuff on your middle finger - on the middle of the finger segment above your nail bed.</li>
            <li>Make sure the air tube on the Vital Stream finger cuff is facing towards the body.</li>
            <li>Wrap the Velcro on the finger cuff snuggly around the finger.</li>
            <li>Place your arm and the Vital Stream on a table or close to even with your belly button.  Stay relaxed.</li>
          </ul>

          <div style={styles.nextBtn}>
            <Continue
              text="Next"
              onClick={this.handleContinue}
            />
          </div>
        </div>

      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { user, proForms: { continuousBPDataCollection } } = state;
  return {
    user,
    userId: user.id,
    continuousBPDataCollection,
  };
}

ContinuousBPDataCollectionInstructions.proptypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  route: PropTypes.object.isRequired,
  updatePRO: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { updatePRO })(withStyles(styles)(ContinuousBPDataCollectionInstructions));
