import React from 'react';
import PropTypes from 'prop-types';

const styles = {
  fill: 'none',
  strokeLinejoin: 'round',
  strokeWidth: 6,
  width: '5vh',
  height: '2em',
};

const DashboardIcon = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 75.08 79.98"
    style={{
      stroke: color,
      ...styles,
    }}
  >
    <title>Dashboard1x</title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <circle cx="62.33" cy="49.07" r="9.75" />
        <path d="M26,49.07v9.75a18.17,18.17,0,1,0,36.33,0" />
        <path d="M12.75,3A9.75,9.75,0,0,0,3,12.75V26.18a23,23,0,1,0,46,0V12.57l0,.18A9.75,9.75,0,0,0,39.29,3" />
      </g>
    </g>
  </svg>
);

DashboardIcon.defaultProps = {
  color: '#b2b2b2',
};

DashboardIcon.propTypes = {
  color: PropTypes.string,
};

export default DashboardIcon;
