import React from 'react';
import PropTypes from 'prop-types';
import { Button, LinearProgress } from '@material-ui/core/';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import AppBar from '../components/app-bar';
import Page from './page';
import { colors, fontSizing } from '../lib/styles';
import { submitPRO, updatePRO } from '../state/pro-forms';
import i18nTranslator, { title } from '../lib/i18next';

const disabledBackground = '#d9d8d8';
const disabledText = '#9b9b9b';

const styles = {
  agreeButton: {
    background: colors.primaryColor,
    borderRadius: 5,
    borderWidth: 0,
    color: 'white',
    flexGrow: 1,
    fontSize: fontSizing.body,
    marginRight: 5,
    padding: 10,
    '&:disabled:': {
      background: disabledBackground,
      color: disabledText,
    },
    '@media (hover: none)': {
      '&:hover': {
        background: colors.primaryColor,
        color: 'white',
      },
    },
  },
  bodyText: {
    fontSize: fontSizing.body,
    margin: 20,
  },
  button: {
    borderWidth: 0,
    flexGrow: 1,
  },
  buttonRow: {
    display: 'flex',
    paddingBottom: 20,
    paddingTop: 20,
    width: 'calc(100% - 20px)',
  },
  declineButton: {
    background: '#707070',
    borderRadius: 5,
    borderWidth: 0,
    color: 'white',
    flexGrow: 1,
    fontSize: fontSizing.body,
    padding: 10,
    '&:disabled:': {
      background: disabledBackground,
      color: disabledText,
    },
    '@media (hover: none)': {
      '&:hover': {
        background: '#707070',
        color: 'white',
      },
    },
  },
  emptyUl: {
    listStyleType: 'none',
    fontWeight: 'normal',
    margin: 0,
    padding: 0,
  },
  emptyUlMB: {
    listStyleType: 'none',
    fontWeight: 'normal',
    marginBottom: 10,
    padding: 0,
  },
  header: {
    fontSize: fontSizing.h3,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  li: {
    display: 'table',
    counterIncrement: 'item',
    marginBottom: '0.6em',
    '&:before': {
      content: 'counters(item, ".") ". "',
      display: 'table-cell',
      paddingRight: '0.6em',
    },
  },
  linearProgress: {
    background: colors.lightBrightBlue,
  },
  nestedLi: {
    margin: 0,
    padding: 0,
    display: 'table',
    counterIncrement: 'item',
    '&:before': {
      content: 'counters(item, ".") ". "',
      display: 'table-cell',
      paddingRight: '0.6em',
    },
  },
  nestedOl: {
    margin: 0,
    padding: 0,
    counterReset: 'item',
    listStyleType: 'none',
    fontWeight: 'normal',
  },
  ol: {
    margin: 0,
    padding: 0,
    counterReset: 'item',
    listStyleType: 'none',
    fontWeight: 'bold',
  },
  pageContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  pageContent: {
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: 700,
    paddingLeft: 10,
    paddingRight: 10,
  },
  pageContentContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    overflowX: 'scroll',
  },
  scrollContainer: {
    overflowY: 'scroll',
  },
  termsContainer: {
    display: 'flex',
    flexDirection: 'column',
    overFlowY: 'scroll',
  },
  ul: {
    listStyleType: 'circle',
    paddingLeft: 20,
  },
};

class EqDisclaimer extends Page {
  constructor(props) {
    super(props);

    this.scrollContainerRef = React.createRef();
    this.state = { buttonsDisabled: true };
  }

  componentDidMount() {
    this.detectScrolledToBottom();
  }

  detectScrolledToBottom = () => {
    const el = this.scrollContainerRef.current;

    if (Math.abs(el.scrollHeight - el.clientHeight - el.scrollTop) < 5) {
      this.setState({ buttonsDisabled: false });
    }
  };

  render() {
    const { classes } = this.props;
    const { buttonsDisabled } = this.state;

    const handleContinue = async (answer) => {
      const { user, location } = this.props;
      const { id } = location.query;

      if (answer === true) {
        this.props.updatePRO({ type: 'eq5d3l', position: this.getTrackIndex(), value: answer });
        this.forwardWithQuery(this.props.location.query);
      } else {
        this.props.submitPRO(
          { userId: user.id, id },
          {
            pro_type: 'EQ5D-3L-CASA-COLINA',
            pro_data: { data: [false] },
          },
          user,
        );
        this.finishTrack();
      }
    };

    return (
      <div className={classes.pageContainer}>
        <div className={classes.pageContentContainer}>
          <AppBar
            headerNode={title('eqDisclaimer')}
            backButtonOnClick={() => this.props.router.goBack()}
          />
          <LinearProgress
            variant="determinate"
            value={(this.getCurrentPage() / this.getTotalPages()) * 100}
            classes={{ bar: classes.linearProgress }}
          />
          <div
            className={`${classes.pageContent} ${classes.scrollContainer}`}
            ref={this.scrollContainerRef}
            onScroll={this.detectScrolledToBottom}
          >
            <div className={classes.bodyText}>
              {i18nTranslator('disclaimer', 'eqDisclaimer')}
            </div>
          </div>
        </div>
        <div className={`${classes.buttonRow} ${classes.pageContent}`}>
          <Button
            className={classes.agreeButton}
            variant="contained"
            disabled={buttonsDisabled}
            onClick={() => handleContinue(true)}
          >
            {i18nTranslator('accept', 'general')}
          </Button>
          <Button
            className={classes.declineButton}
            variant="contained"
            onClick={() => handleContinue(false)}
            disabled={buttonsDisabled}
          >
            {i18nTranslator('decline', 'general')}
          </Button>
        </div>
      </div>
    );
  }
}

EqDisclaimer.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const {
    proForms: { vitalCore },
    user,
  } = state;

  return { user, vitalCore };
};

export default connect(mapStateToProps, { submitPRO, updatePRO })(withStyles(styles)(EqDisclaimer));
