import { promiseHandler, resetReducer } from 'cooldux';
import { get, isUndefined, omit, omitBy } from 'lodash';

import { apiFetch } from '../lib/fetch';
import { logEvent } from '../lib/amplitude';

const handlerOpts = {
  namespace: 'patientProvider',
  throwErrors: true,
};
const { browseStart, browseEnd, browseError, browseHandler } = promiseHandler('browse', 'patientProvider');
const { editStart, editEnd, editError, editHandler } = promiseHandler('edit', handlerOpts);
const { addStart, addEnd, addError, addHandler } = promiseHandler('add', handlerOpts);
const { deleteStart, deleteEnd, deleteError, deleteHandler } = promiseHandler('delete', handlerOpts);

export function browsePatientProviders() {
  return function dispatcher(dispatch, getState) {
    const selfId = get(getState(), 'user.id', null);
    const promise = apiFetch(`/users/${selfId}/patient_provider`);
    return browseHandler(promise, dispatch);
  };
}

export function editPatientProvider(update) {
  return function dispatcher(dispatch, getState) {
    const selfId = get(getState(), 'user.id', null);
    const body = omit(update, 'id');
    const options = {
      method: 'PUT',
      body: omitBy(body, isUndefined),
    };
    const promise = apiFetch(`/users/${selfId}/patient_provider/${update.id}`, options);
    return editHandler(promise, dispatch);
  };
}

export function addPatientProvider(newPatientProvider) {
  return function dispatcher(dispatch, getState) {
    const selfId = get(getState(), 'user.id', null);
    const options = {
      method: 'POST',
      body: omitBy(newPatientProvider, isUndefined),
    };
    logEvent('Added new Physician');
    const promise = apiFetch(`/users/${selfId}/patient_provider`, options);
    return addHandler(promise, dispatch);
  };
}

export function deletePatientProvider(patientProviderId) {
  return function dispatcher(dispatch, getState) {
    const selfId = get(getState(), 'user.id', null);
    const options = {
      method: 'DELETE',
    };
    const promise = apiFetch(`/users/${selfId}/patient_provider/${patientProviderId}`, options)
      .then(() => patientProviderId);

    return deleteHandler(promise, dispatch);
  };
}

const initialState = {
  browseError: null,
  addError: null,
  editError: null,
  deleteError: null,
  browsePending: false,
  addPending: false,
  editPending: false,
  deletePending: false,
  data: {},
};

function finishBrowse(state, patientProvider) {
  const data = {};
  patientProvider.forEach((u) => {
    data[u.id] = u;
  });
  return { ...state, data, browsePending: false, browseError: null };
}

function finishEdit(state, item) {
  const data = { ...state.data, [item.id]: item };
  return { ...state, editPending: false, editError: null, data };
}

function finishAdd(state, item) {
  const data = { ...state.data, [item.id]: item };
  return { ...state, addPending: false, addError: null, data };
}

function finishDelete(state, patientProviderId) {
  return { ...state, deletePending: false, deleteError: null, data: omit(state.data, patientProviderId) };
}

const patientProvider = resetReducer(initialState, (state = initialState, action) => {
  switch (action.type) {
    case browseStart.type:
      return { ...state, browsePending: true };
    case browseEnd.type:
      return finishBrowse(state, action.payload);
    case browseError.type:
      return { ...state, browsePending: false, browseError: action.payload };
    case addStart.type:
      return { ...state, addPending: true };
    case addEnd.type:
      return finishAdd(state, action.payload);
    case addError.type:
      return { ...state, addPending: false, addError: action.payload };
    case deleteStart.type:
      return { ...state, deletePending: true };
    case deleteEnd.type:
      return finishDelete(state, action.payload);
    case deleteError.type:
      return { ...state, deletePending: false, deleteError: action.payload };
    case editStart.type:
      return { ...state, editPending: true };
    case editEnd.type:
      return finishEdit(state, action.payload);
    case editError.type:
      return { ...state, editPending: false, editError: action.payload };
    default:
      return state;
  }
});

export default patientProvider;
