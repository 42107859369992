import { promiseHandler, resetReducer } from 'cooldux';
import { get, isNil, isUndefined, omit, omitBy, pick } from 'lodash';

import { apiFetch } from '../lib/fetch';

const handlerOptions = { namespace: 'painHx', throwErrors: true };
const { browseStart, browseEnd, browseError, browseHandler } = promiseHandler('browse', 'painHx');
const { readStart, readEnd, readError, readHandler } = promiseHandler('read', 'painHx');
const { readMostRecentStart, readMostRecentEnd, readMostRecentError, readMostRecentHandler } = promiseHandler('readMostRecent', 'painHx');
const { editStart, editEnd, editError, editHandler } = promiseHandler('edit', handlerOptions);
const { addStart, addEnd, addError, addHandler } = promiseHandler('add', handlerOptions);
const { deleteStart, deleteEnd, deleteError, deleteHandler } = promiseHandler('delete', 'painHx');

export function browsePainHx(pain_start_date, pain_end_date, opts = {}) {
  return function dispatcher(dispatch, getState) {
    const selfId = get(getState(), 'user.id', null);
    const { limit } = opts;
    const queryOptions = {
      query: omitBy({ limit, pain_start_date, pain_end_date, populate: true }, isNil),
    };
    const promise = apiFetch(`/users/${selfId}/pain_hx`, queryOptions);
    return browseHandler(promise, dispatch);
  };
}

export function readPainHx(painHxId) {
  return function dispatcher(dispatch, getState) {
    const selfId = get(getState(), 'user.id', null);
    const promise = apiFetch(`/users/${selfId}/pain_hx/${painHxId}`);
    return readHandler(promise, dispatch);
  };
}

export function readMostRecentPainHx() {
  return function dispatcher(dispatch, getState) {
    const selfId = get(getState(), 'user.id', null);
    const promise = apiFetch(`/users/${selfId}/pain_hx_most_recent`);
    return readMostRecentHandler(promise, dispatch);
  };
}

export function editPainHx(update) {
  return function dispatcher(dispatch, getState) {
    const selfId = get(getState(), 'user.id', null);
    const body = pick(update, [
      'pain_locations',
      'intensity',
      'pain_types',
      'other_functional_limitations',
      'other_aggravating_factors',
      'other_alleviating_factors',
      'pain_start_date',
      'pain_end_date',
      'pain_cause',
      'pain_limitations',
      'triggers',
    ]);
    const options = {
      method: 'PUT',
      body: omitBy(body, isUndefined),
    };
    const promise = apiFetch(`/users/${selfId}/pain_hx/${update.id}`, options);
    return editHandler(promise, dispatch);
  };
}

export function addPainHx(newPainHx) {
  return function dispatcher(dispatch, getState) {
    const selfId = get(getState(), 'user.id', null);
    const options = {
      method: 'POST',
      body: omitBy(newPainHx, isUndefined),
    };
    const promise = apiFetch(`/users/${selfId}/pain_hx`, options);
    return addHandler(promise, dispatch);
  };
}

export function deletePainHx(painHxId) {
  return function dispatcher(dispatch, getState) {
    const selfId = get(getState(), 'user.id', null);
    const options = {
      method: 'DELETE',
    };
    const promise = apiFetch(`/users/${selfId}/pain_hx/${painHxId}`, options)
      .then(() => painHxId);

    return deleteHandler(promise, dispatch);
  };
}

const initialState = {
  browseError: null,
  addError: null,
  editError: null,
  deleteError: null,
  readMostRecentError: null,
  browsePending: false,
  addPending: false,
  editPending: false,
  deletePending: false,
  readMostRecentPending: false,
  data: {},
  mostRecentPainEntry: [],
};

function finishBrowse(state, painHx) {
  const data = { ...state.data };
  painHx.forEach((u) => {
    data[u.id] = u;
  });
  return { ...state, data, browsePending: false, browseError: null };
}

function finishRead(state, painHx) {
  const data = {
    ...state.data,
    [painHx.id]: painHx,
  };

  return { ...state, data, readPending: false, readError: null };
}

function finishReadMostRecent(state, painHx) {
  const mostRecentPainEntry = painHx;

  return { ...state, mostRecentPainEntry, readMostRecentPending: false, readMostRecentError: null };
}

function finishEdit(state, item) {
  const data = { ...state.data, [item.id]: item };
  return { ...state, editPending: false, editError: null, data };
}

function finishAdd(state, item) {
  const data = { ...state.data, [item.id]: item };
  return { ...state, addPending: false, addError: null, data };
}

function finishDelete(state, painHxId) {
  return { ...state, deletePending: false, deleteError: null, data: omit(state.data, painHxId) };
}

const painHx = resetReducer(initialState, (state = initialState, action) => {
  switch (action.type) {
    case browseStart.type:
      return { ...state, browsePending: true };
    case browseEnd.type:
      return finishBrowse(state, action.payload);
    case browseError.type:
      return { ...state, browsePending: false, browseError: action.payload };
    case readStart.type:
      return { ...state, readPending: true };
    case readEnd.type:
      return finishRead(state, action.payload);
    case readError.type:
      return { ...state, readPending: false, readError: action.payload };
    case readMostRecentStart.type:
      return { ...state, readMostRecentPending: true };
    case readMostRecentEnd.type:
      return finishReadMostRecent(state, action.payload);
    case readMostRecentError.type:
      return { ...state, readMostRecentPending: false, readMostRecentError: action.payload };
    case addStart.type:
      return { ...state, addPending: true };
    case addEnd.type:
      return finishAdd(state, action.payload);
    case addError.type:
      return { ...state, addPending: false, addError: action.payload };
    case deleteStart.type:
      return { ...state, deletePending: true };
    case deleteEnd.type:
      return finishDelete(state, action.payload);
    case deleteError.type:
      return { ...state, deletePending: false, deleteError: action.payload };
    case editStart.type:
      return { ...state, editPending: true };
    case editEnd.type:
      return finishEdit(state, action.payload);
    case editError.type:
      return { ...state, editPending: false, editError: action.payload };
    default:
      return state;
  }
});

export default painHx;
