const en = {
  appointmentConfirmation3: {
    title: 'Connection Check',
    instructions: 'We will be checking your camera, microphone, and network connectivity and will need access.',
  },
};

const es = {
  appointmentConfirmation3: {
    title: 'Comprobación de conexión',
    instructions: 'Comprobaremos su cámara, micrófono y conectividad de red y necesitaremos acceso',
  },
};

export default { en, es };
