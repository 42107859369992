import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Switch from '@material-ui/core/Switch';
import { noop } from 'lodash';
import { colors } from '../lib/styles';

const baseStyles = {
  label: {
    textAlign: 'left',
    flex: 6,
    margin: '5px 7px',
    width: '100px',
  },
  subtitle: {
    backgroundColor: colors.darkGrayText,
    color: colors.white,
    display: 'flex',
    padding: '2px',
    fontSize: '1.6rem',
  },
};

class ToggleTitle extends Component {
  render() {
    return (
      <div style={baseStyles.subtitle}>
        <div style={baseStyles.label}>{this.props.label}</div>
        <Switch
          onChange={this.props.onToggle}
          checked={this.props.toggled}
          color="primary"
        />
      </div>
    );
  }
}

ToggleTitle.defaultProps = {
  label: 'ToggleTitle',
  onToggle: noop,
  toggled: false,
};

ToggleTitle.propTypes = {
  label: PropTypes.string,
  onToggle: PropTypes.func,
  toggled: PropTypes.bool,
};

export default ToggleTitle;
