import React from 'react';
import { connect } from 'react-redux';

import AppBar from '../components/app-bar';
import Page from './page';
import Continue from '../components/continue';
import { fetchUserReport } from '../state/user';
import { colors } from '../lib/styles';

const baseStyles = {
  checkin: {
    height: '5rem',
    marginBottom: '2rem',
  },
  checkinLabel: {
    fontSize: '1.2rem',
  },
  clinicShare: {
    height: '5rem',
    backgroundColor: colors.secondaryColor,
    marginBottom: '2rem',
  },
  clinicShareLabel: {
    fontSize: '1.2rem',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  instructions: {
    fontSize: '1.8rem',
    margin: '5rem 5rem 8rem 5rem',
    textAlign: 'center',
  },
  report: {
    height: '5rem',
    backgroundColor: colors.darkGrayText,
    marginBottom: '2rem',
  },
  reportLabel: {
    fontSize: '1.2rem',
  },
};

class ShareData extends Page {
  render() {
    return (
      <div>
        <AppBar
          backButtonOnClick={() => this.props.router.push('/')}
          headerNode="Share Your Data"
        />
        <div style={baseStyles.container}>
          <p style={baseStyles.instructions}>Share access to your data:</p>
          <Continue
            text="Check In To Your Clinic"
            btnStyle={baseStyles.checkin}
            labelStyle={baseStyles.checkinLabel}
            onClick={() => this.props.router.push('/qr')}
          />
          <Continue
            text="Share Data with Your Clinic"
            btnStyle={baseStyles.clinicShare}
            labelStyle={baseStyles.clinicShareLabel}
            onClick={() => this.props.router.push('/personalinfo')}
          />
          <Continue
            text="Download Your Patient Report"
            btnStyle={baseStyles.report}
            labelStyle={baseStyles.reportLabel}
            onClick={this.props.fetchUserReport}
            user={this.props.user}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state;
  return {
    user,
  };
}

export default connect(mapStateToProps, { fetchUserReport })(ShareData);
