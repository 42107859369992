const en = {
  education1: {
    title: 'Feedback',
    instructions: 'Do you feel the education provided has been:',
    options: {
      question1: 'Helpful?',
      question2: 'Appropriate?',
    },
  },
};

const es = {
  education1: {
    title: 'Respuesta',
    instructions: '¿Cree que la educación brindada ha sido:',
    options: {
      question1: '¿Servicial?',
      question2: '¿Apropiada?',
    },
  },
};

export default { en, es };
